import moment from "moment";
import { ContentStack, TDocumentDefinitions } from "pdfmake/interfaces";

import { getDataURLFromURL } from "react-lib/apps/HISV3/common/CommonInterface";
import {
  HeaderLogoWithPatientDetail,
  HeaderLogoWithPatientProps,
} from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

// Types
export type FormEMRSummaryProps = {
  allergyText: string;
  assessment: {
    alcoholName?: string;
    alcoholSpecificName?: string;
    tobaccoName?: string;
    tobaccoSpecificName?: string;
  };
  currentMedication: string;
  detail: HeaderLogoWithPatientProps["detail"];
  diagnosis: {
    principal_diagnosis: {
      icd_code: string;
      icd_term: string;
    }[];
    secondary_diagnosis: {
      icd_code: string;
      icd_term: string;
    }[];
  } | null;
  discharge: { note: string; preDischargeConditionName: string; statusName: string } | null;
  doctorNoteItems: { name: string }[];
  drugItems: {
    for_mixing: string;
    full_name: string;
    isSolvent: boolean;
    label: string;
  }[];
  galleryItems: { description: string; image: string }[];
  imagingItems: {
    name: string;
  }[];
  labItems: {
    name: string;
  }[];
  medicalRecord: {
    exam_template: {
      active: boolean;
      items: {
        active: boolean;
        description: string;
        organ_name: string;
        result: string;
      }[];
      template_name: string;
    }[];
    patient_exam_other?: {
      other_detail: string;
    };
  } | null;
  painScore: string;
  physicianNote: {
    chiefComplaints: string;
    familyHistory: string;
    pastIllness: string;
    personalHistory: string;
    presentIllness: string;
  };
  pregnancy: {
    periodName?: string;
    statusName?: string;
  };
  reassessment: {
    barriers: boolean;
    barriers_detail?: string;
    education_evaluation_detail?: string;
    educationEvaluationName?: string;
    educationMethodName?: string;
    educationName?: string;
    educationWayName?: string;
    follow_up_detail?: string;
    readiness: boolean;
    readiness_detail?: string;
  } | null;
  supplyOrders: {
    name: string;
    quantity_request: string;
    stock_unit: string;
  }[];
  treatmentOrders: {
    complications: string;
    core_procedure: {
      icd9cm_code: string;
      icd9cm_term: string;
    }[];
    equipment_detail: string;
    order_detail: string;
    order_perform_by_name: string;
    order_summary_detail: string;
    result: string;
    status: string;
  }[];
  triage: {
    arrive_status: string | null;
    triageLevelName: string | null;
  };
  underlyingDisease: string;
  vitalsign: {
    bmi: string;
    bp: string;
    height: string;
    o2sat: string;
    pr: string;
    ps: string;
    rr: string;
    temp: string;
    weight: string;
  };
};

interface ContentStackWithId extends Omit<ContentStack, "stack"> {
  id?: string;
  stack: ContentStack["stack"];
}

interface ITDocumentDefinitions extends TDocumentDefinitions {
  content: (ContentStackWithId | TDocumentDefinitions["content"])[];
}

const HTML_TAGS_REGEX = /<[^>]*>/g; // To match HTML tags
const SQUARE_BRACKETS_REGEX = /\[[^\]]*\]\s*/; // To match content in square brackets

// <b>[15010005] การประคบด้วยความเย็น (Cold pack)</b><br/><font color=\"gray\">ประคบเย็นเป็นเวลา 15 - 20 นาที เพื่อลดบวม</font>
export const extractMainText = (htmlString: string): string[] => {
  // แยกข้อความตาม <b> tag
  const items = htmlString.split("<b>").filter((item) => item.trim() !== "");

  return items
    .map((item) => {
      // ลบ HTML tags
      let text = item.replaceAll(HTML_TAGS_REGEX, "");

      // ลบ Square bracket
      text = text.replace(SQUARE_BRACKETS_REGEX, "");

      // ตัดข้อความหลังจากวงเล็บปิด (ถ้ามี)
      const closingParenIndex = text.indexOf(")");

      if (closingParenIndex !== -1) {
        text = text.slice(0, closingParenIndex + 1);
      }

      // ตัดช่องว่างที่อาจเหลืออยู่ที่ต้นและท้ายข้อความ
      return text.trim();
    })
    .filter((text) => text !== ""); // กรองออกกรณีที่เป็นข้อความว่าง
};

const groupAndResizeImageColumns = async (data: FormEMRSummaryProps["galleryItems"]) => {
  const result: {
    id?: string;
    description: string;
    height?: number;
    image: string;
    marginTop?: number;
    width?: number;
  }[][] = [];

  const images = await getDataURLFromURL(data);

  const processedImages = images
    .map((item) => {
      const { description, image } = item;

      let { height, width } = item;

      if (width > GALLERY_MAX_WIDTH) {
        const resize = 1 - (width - GALLERY_MAX_WIDTH) / width;

        height *= resize;
        width = GALLERY_MAX_WIDTH;
      }

      if (height > GALLERY_MAX_HEIGHT) {
        const resize = 1 - (height - GALLERY_MAX_HEIGHT) / height;

        width *= resize;
        height = GALLERY_MAX_HEIGHT;
      }

      return {
        description,
        height,
        image,
        marginTop: (GALLERY_MAX_HEIGHT + 5 - height) / 2,
        width,
      };
    })
    .filter((item) => item.image);

  const emptyItem = {
    description: "",
    height: undefined,
    image: "",
    marginTop: 0,
    width: undefined,
  };

  // จัดกลุ่มรูปภาพเป็นคู่ๆ
  for (const [index, image] of processedImages.entries()) {
    if (index % 2 === 0) {
      // สร้างคู่รูปภาพ: รูปภาพปัจจุบัน, ตัวคั่น, รูปภาพถัดไป (หรือ emptyItem ถ้าไม่มี)
      const pair = [
        image,
        emptyItem,
        index + 1 < processedImages.length ? processedImages[index + 1] : emptyItem,
      ];

      result.push(pair);
    }
  }

  return result;
};

const GALLERY_MAX_WIDTH = 270;

const GALLERY_MAX_HEIGHT = 140;

const FONT_SIZE = 14;

const FORM_EMR_SUMMARY = "FormEMRSummary";

const FormEMRSummary = async (props: FormEMRSummaryProps): Promise<ITDocumentDefinitions> => {
  const currentDate = moment();

  const headerForm = await HeaderLogoWithPatientDetail({
    detail: props.detail,
    font: "KanitLM",
    form: FORM_EMR_SUMMARY,
    headerMargins: [10, 10, 10, 0],
    logoHeight: 38,
    pageMargins: [12.5, 108.5, 12.5, 20],
    titleContent: [],
  });
  const { font, fontSizes, images, lineHeights, styles } = headerForm;

  const filteredTreatmentOrders = props.treatmentOrders.filter(
    (item) => item.status !== "CANCELED"
  );

  const barriersName = props.reassessment?.barriers ? "Yes" : "No";
  const readinessName = props.reassessment?.readiness ? "Yes" : "No";

  const hasDiagnosis =
    props.diagnosis &&
    (props.diagnosis.principal_diagnosis.length > 0 ||
      props.diagnosis.secondary_diagnosis.length > 0);

  const principalDx = props.diagnosis?.principal_diagnosis[0];
  const secondaryDx = props.diagnosis?.secondary_diagnosis;

  const hasMedicalRecord =
    props.medicalRecord &&
    (props.medicalRecord.exam_template.length > 0 ||
      props.medicalRecord.patient_exam_other?.other_detail);

  const examTemplate = props.medicalRecord?.exam_template || [];
  const patientExamOther = props.medicalRecord?.patient_exam_other;

  const periodName =
    props.pregnancy.statusName === "กำลังตั้งครรภ์" ? props.pregnancy.periodName : "";

  const galleries = await groupAndResizeImageColumns(props.galleryItems);

  console.log("FormEMRSummary", props);

  return {
    defaultStyle: {
      font,
      fontSize: fontSizes[FONT_SIZE],
      lineHeight: lineHeights[1],
    },
    pageSize: "A4",
    ...headerForm,
    images: { ...images },
    styles: {
      ...styles,
      fieldKey: { bold: true, font: "PoppinsLM", fontSize: fontSizes[13.25] },
      fieldKeyBolder: { bold: true, font: "Poppins", fontSize: fontSizes[13.25] },
      fieldNote: {
        font: "PoppinsLM",
        fontSize: fontSizes[12],
        italics: true,
      },
      miniField: { fontSize: fontSizes[11] },
    },
    content: [
      {
        margin: [15, 0, 15, 0],
        stack: [
          {
            columns: [
              {
                text: [
                  { style: "fieldKey", text: "Arrival status: " },
                  { text: props.triage.arrive_status || "-" },
                ],
                width: "*",
              },
              {
                text: [
                  { style: "fieldKey", text: "Triage level: " },
                  { text: props.triage.triageLevelName || "-" },
                ],
                width: "*",
              },
              {
                text: [
                  { style: "fieldKey", text: "Pregnancy: " },
                  {
                    text: `${props.pregnancy.statusName || "-"} ${periodName}`,
                  },
                ],
                width: "*",
              },
            ],
          },
          {
            text: [
              { style: "fieldKey", text: "Underlying disease: " },
              { text: props.underlyingDisease || "-" },
            ],
          },
          {
            text: [{ style: "fieldKey", text: "Allergy: " }, { text: props.allergyText }],
          },
          {
            text: [
              {
                label: "BW",
                value: props.vitalsign.weight ? `${props.vitalsign.weight} kg.` : "",
              },
              {
                label: "HT",
                value: props.vitalsign.height ? `${props.vitalsign.height} cm.` : " ",
              },
              { label: "BMI", value: props.vitalsign.bmi || " " },
              { label: "T", value: props.vitalsign.temp ? `${props.vitalsign.temp}°C` : " " },
              { label: "BP", value: props.vitalsign.bp ? `${props.vitalsign.bp} mmHg` : " " },
              { label: "PR", value: props.vitalsign.pr ? `${props.vitalsign.pr}/min` : " " },
              { label: "RR", value: props.vitalsign.rr ? `${props.vitalsign.rr}/min` : " " },
              { label: "O2", value: props.vitalsign.o2sat || " " },
              { label: "Pain score", value: props.vitalsign.ps || " " },
            ].map((item, index) => ({
              text: [
                { style: "fieldKey", text: index === 0 ? item.label : ` ${item.label}` },
                { text: `-${item.value}` },
              ],
            })),
          },
          {
            text: [
              { style: "fieldKey", text: "Smoking: " },
              { text: props.assessment.tobaccoName || "-" },
              {
                text: props.assessment.tobaccoSpecificName
                  ? ` ${props.assessment.tobaccoSpecificName}`
                  : "",
              },
              { style: "fieldKey", text: " Alcohol: " },
              { text: props.assessment.alcoholName || "-" },
              {
                text: props.assessment.alcoholSpecificName
                  ? ` ${props.assessment.alcoholSpecificName}`
                  : "",
              },
            ],
          },
          {
            text: [
              { style: "fieldKey", text: "Current Medication: " },
              { text: props.currentMedication || "-" },
            ],
          },
        ],
      },
      {
        marginTop: 2.5,
        table: {
          widths: ["100%"],
          body: [[{ border: [false, true, false, false], text: "" }]],
        },
        layout: {
          hLineColor: () => "#606060",
          hLineStyle: () => ({ dash: { length: 1, space: 1 } }),
          hLineWidth: () => 1,
          vLineWidth: () => 0,
        },
      },
      props.physicianNote.chiefComplaints
        ? {
            marginTop: -1.5,
            stack: [
              { style: "fieldKeyBolder", text: "Physician Note" },
              {
                margin: [15, 0, 0, 0],
                stack: [
                  { label: "Chief complaint", value: props.physicianNote.chiefComplaints },
                  {
                    label: "Present illness",
                    value: props.physicianNote.presentIllness,
                  },
                  { label: "Past illness", value: props.physicianNote.pastIllness },
                  {
                    label: "Personal History",
                    value: props.physicianNote.personalHistory,
                  },
                  { label: "Family History", value: props.physicianNote.familyHistory },
                ].map((item) => ({
                  columns: [
                    { style: "fieldKey", text: `${item.label}: `, width: 90 },
                    { marginTop: -1, text: item.value, width: "*" },
                  ],
                  lineHeight: lineHeights[1.05],
                })),
              },
            ],
          }
        : { text: "" },
      hasMedicalRecord
        ? {
            marginTop: 2.5,
            stack: [
              { style: "fieldKeyBolder", text: "Physical Examination" },
              ...examTemplate
                .filter((item) => item.active)
                .map((item) => ({
                  stack: [
                    { text: item.template_name },
                    {
                      marginLeft: 5,
                      ul: item.items
                        .filter((acc) => acc.active)
                        .map((acc) => ({
                          text: [
                            { bold: true, text: `${acc.organ_name} ` },
                            { text: `-${acc.result} ` },
                            { text: acc.description },
                          ],
                        })),
                    },
                  ],
                })),
              ...(patientExamOther?.other_detail
                ? [
                    {
                      text: "Other findings:",
                    },
                    {
                      stack: patientExamOther.other_detail
                        .split("\n")
                        .map((text) => ({ text: `-${text}` })),
                    },
                  ]
                : []),
            ],
          }
        : { text: "" },
      filteredTreatmentOrders.length > 0
        ? {
            id: "treatment",
            marginTop: 2.5,
            stack: [
              { style: "fieldKeyBolder", text: "Treatment" },
              ...filteredTreatmentOrders.map((item) => ({
                stack: [
                  {
                    marginLeft: 5,
                    ul: extractMainText(item.order_summary_detail),
                  },
                  { text: item.order_detail ? `- ${item.order_detail}` : "" },
                  { text: item.equipment_detail ? `- ${item.equipment_detail}` : "" },
                  {
                    text: item.core_procedure[0]
                      ? [
                          { style: "fieldKey", text: "IC9 CM: " },
                          {
                            text: `${item.core_procedure[0].icd9cm_code}-${item.core_procedure[0].icd9cm_term}`,
                          },
                        ]
                      : "",
                  },
                  {
                    text: item.result
                      ? [{ style: "fieldKey", text: "Detail: " }, { text: item.result }]
                      : "",
                  },
                  {
                    text: item.complications
                      ? [
                          { style: "fieldKey", text: "Complication: " },
                          { text: item.complications },
                        ]
                      : "",
                  },
                  {
                    text: item.order_perform_by_name
                      ? [
                          { style: "fieldKey", text: "Performed by: " },
                          { text: item.order_perform_by_name },
                        ]
                      : "",
                  },
                ],
              })),
            ],
          }
        : { text: "" },
      hasDiagnosis
        ? {
            id: "diagnosis",
            marginTop: 2.5,
            stack: [
              { style: "fieldKeyBolder", text: "Diagnosis" },
              principalDx
                ? {
                    text: [
                      {
                        style: "fieldKey",
                        text: "Principal Dx. ",
                      },
                      {
                        text: `${principalDx.icd_code}-${principalDx.icd_term}`,
                      },
                    ],
                  }
                : { text: "" },
              secondaryDx?.length
                ? {
                    text: [
                      { style: "fieldKey", text: "Secondary Dx. " },
                      {
                        text: secondaryDx
                          .map((item) => `${item.icd_code}-${item.icd_term}`)
                          .join(", "),
                      },
                    ],
                  }
                : { text: "" },
            ],
          }
        : { text: "" },
      props.labItems.length > 0 || props.imagingItems.length > 0
        ? {
            id: "investigation",
            marginTop: 2.5,
            stack: [
              { style: "fieldKeyBolder", text: "Investigation" },
              ...(props.labItems.length > 0
                ? [
                    {
                      columns: [
                        { text: "Lab", width: 57.5 },
                        {
                          ul: props.labItems.map((item) => item.name),
                          width: "*",
                        },
                      ],
                    },
                    {
                      style: "fieldNote",
                      text: "* See Result in Laboratory report",
                    },
                  ]
                : []),
              ...(props.imagingItems.length > 0
                ? [
                    {
                      columns: [
                        { text: "Imaging", width: 57.5 },
                        {
                          ul: props.imagingItems.map((item) => item.name),
                          width: "*",
                        },
                      ],
                    },
                    {
                      style: "fieldNote",
                      text: "* See Result in Radiology report",
                    },
                  ]
                : []),
            ],
          }
        : { text: "" },
      props.drugItems.length > 0
        ? {
            marginTop: 2.5,
            stack: [
              { style: "fieldKeyBolder", text: "Medication" },
              {
                marginLeft: 5,
                ul: props.drugItems.map((item) => ({
                  listType: item.isSolvent ? "none" : "disc",
                  text: [
                    { style: "fieldKey", text: `${item.full_name}- ` },
                    { text: item.label.replaceAll("\n", "") },
                    { text: item.for_mixing ? ` สำหรับผสม ${item.for_mixing}` : "" },
                  ],
                })),
              },
            ],
          }
        : { text: "" },
      props.supplyOrders.length > 0
        ? {
            id: "supply_equipments",
            marginTop: 2.5,
            stack: [
              { style: "fieldKeyBolder", text: "Medical Supply/Equipments" },
              {
                marginLeft: 5,
                ul: props.supplyOrders.map(
                  (item) => `${item.name} ${item.quantity_request} ${item.stock_unit}`
                ),
              },
            ],
          }
        : { text: "" },
      props.doctorNoteItems.length > 0
        ? {
            id: "doctor_note",
            marginTop: 2.5,
            stack: [
              { style: "fieldKeyBolder", text: "Doctor Note" },
              {
                marginLeft: 5,
                ul: props.doctorNoteItems.map((item) => item.name),
              },
            ],
          }
        : { text: "" },
      props.reassessment
        ? {
            id: "patient_family_education",
            marginTop: 2.5,
            stack: [
              { style: "fieldKeyBolder", text: "Patient & Family Education" },
              {
                marginLeft: 5,
                ul: [
                  `Barrier of data receiver: ${props.reassessment.barriers_detail || barriersName}`,
                  `Readiness and willingness: ${
                    props.reassessment.readiness_detail || readinessName
                  }`,
                  `Teaching method: ${props.reassessment.educationMethodName}`,
                  `Education to: ${props.reassessment.educationWayName}`,
                  `Topic: ${props.reassessment.educationName}`,
                  `Education Evaluation: ${props.reassessment.educationEvaluationName} ${
                    props.reassessment.education_evaluation_detail || ""
                  }`,
                ],
              },
            ],
          }
        : { text: "" },
      props.reassessment?.follow_up_detail
        ? {
            columns: [
              { style: "fieldKeyBolder", text: "Follow up", width: 60 },
              { marginTop: -1, text: props.reassessment.follow_up_detail, width: "*" },
            ],
            marginTop: 2.5,
          }
        : { text: "" },
      props.discharge?.preDischargeConditionName
        ? {
            columns: [
              { style: "fieldKeyBolder", text: "Discharge", width: 60 },
              {
                marginTop: -1,
                text: `${props.discharge.preDischargeConditionName}/ ${props.discharge.statusName} ${props.discharge.note}`,
                width: "*",
              },
            ],
            marginTop: 2.5,
          }
        : { text: "" },
      galleries.length > 0
        ? {
            id: "imaging_gallery",
            marginTop: 2.5,
            stack: [
              { marginBottom: 2.5, style: "fieldKeyBolder", text: "Image Gallery" },
              galleries.map((items, pIndex) => ({
                stack: [
                  { id: `image_gallery_${pIndex}`, marginBottom: -FONT_SIZE - 4, text: "\u00A0" },
                  {
                    marginBottom: 10,
                    table: {
                      dontBreakRows: true,
                      widths: ["49%", "2%", "49%"],
                      body: [
                        items.map((item) =>
                          item.image
                            ? {
                                stack: [
                                  {
                                    alignment: "center",
                                    height: item.height,
                                    image: item.image,
                                    marginTop: item.marginTop,
                                    width: item.width,
                                  },
                                  {
                                    lineHeight: lineHeights[0.85],
                                    margin: [5, 0, 0, 2.5],
                                    text: item.description,
                                  },
                                ],
                              }
                            : {
                                border: [false, false, false, false],
                                fontSize: fontSizes[9],
                                text: " ",
                              }
                        ),
                      ],
                    },
                    layout: {
                      hLineColor: () => "#606060",
                      hLineWidth: () => 0.5,
                      paddingBottom: () => 0,
                      paddingLeft: () => 0,
                      paddingRight: () => 0,
                      paddingTop: () => 0,
                      vLineWidth: () => 0.5,
                    },
                  },
                  {
                    id: `image_gallery_${pIndex}_last_content`,
                    marginTop: -FONT_SIZE - 4,
                    text: "\u00A0",
                  },
                ],
              })),
            ],
          }
        : { text: "" },
    ],
    footer: (currentPage, pageCount) => ({
      stack: [
        {
          alignment: "right",
          margin: [0, 0, 10, 0],
          style: "miniField",
          text: `วันที่พิมพ์/Printed date :${currentDate.format(
            "DD/MM/YYYY"
          )}, ${currentDate.format("HH:mm")}   หน้า ${currentPage}/${pageCount}`,
        },
      ],
    }),
    pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage) => {
      const { id, pageNumbers, pages } = currentNode;

      const isPageBreakClass = id?.includes("image_gallery_");

      if (
        [
          "diagnosis",
          "doctor_note",
          "imaging_gallery",
          "investigation",
          "patient_family_education",
          "supply_equipments",
        ].includes(id || "")
      ) {
        return pageNumbers.length === pages && pageNumbers[0] !== pages;
      } else if (isPageBreakClass && !id?.includes("last_content")) {
        const lastContent = [...followingNodesOnPage, ...nodesOnNextPage].find((node: any) => {
          const nId: string = node.id || "";

          return nId.includes("last_content") && nId.includes(id || "");
        });

        const lPageNumber: number[] = lastContent?.pageNumbers || [];

        return lPageNumber.toString() !== pageNumbers.toString();
      }

      return false;
    },
  };
};

export default FormEMRSummary;
