import React, { useState } from "react";
import PropTypes from "prop-types";

import { Dimmer, Header, Icon, Segment } from "semantic-ui-react";

const style = {
  noMargin: { margin: "0px" },
  addButton: { margin: "0px", padding: "35px 0px 0px 0px" }
};

const SubAddButton = props => {
  const [active, setActive] = useState(false);

  const handleShow = () => {
    setActive(true);
  };
  const handleHide = () => {
    setActive(false);
  };

  return (
    <Dimmer.Dimmable
      blurring
      as={Segment}
      dimmed={active}
      onMouseEnter={handleShow}
      onMouseLeave={handleHide}
      onClick={props.onClicked}
    >
      <Header as="h1" disabled textAlign="center" style={style.noMargin}>
        {props.header}
      </Header>
      <Dimmer active={active} style={style.addButton}>
        <Header as="h4" icon>
          <Icon name="plus" inverted />
        </Header>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

SubAddButton.defaultProps = {
  onClicked: () => {},

  header: ""
};

SubAddButton.propTypes = {
  onClicked: PropTypes.func,

  header: PropTypes.string
};

export default React.memo(SubAddButton);
