import React, { useMemo } from "react";
import { FormGroup, FormField, Button } from "semantic-ui-react";

// Common
// import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey"

// Types
export type SubAssistantsInputProps = {
  onEvent: (e: any) => any;
  // data
  index: number;
  type: string;
  field?: string;
  role?: string;
  items: (number | null | Record<string, any>)[];
  isDetail?: boolean;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  // callback
  setSelected?: (index: number, value: any) => any;
  onClick?: (index: number, action: string) => any;
  onUpdated?: (items: any[]) => any;
  isReadOnly?: boolean;
};

const SubAssistantsInput = (props: SubAssistantsInputProps) => {
  const field = useMemo(() => {
    return props.field ? props.field + "_" : "";
  }, [props.field]);

  const handleSelected = (index: number) => (value: any) => {
    props.setSelected?.(index, value);

    handleUpdated(index, value);
  };

  const handleClick = (action: string, index: number) => (e: any) => {
    props.onClick?.(index, action);

    handleUpdated(index, action);
  };

  const handleUpdated = (cIndex: number, value: any) => {
    const items = props.items || [];

    if (value === "add") {
      if (items.every((item: any) => item)) {
        items.push(null);
      } else {
        return;
      }
    } else if (value === "remove") {
      items.splice(cIndex, 1);
    } else {
      items[cIndex] = value;
    }

    props.onUpdated?.([...items]);
  };

  return (
    <>
      {(props.items?.length ? props.items : [null]).map(
        (item: any, cIndex: number) => (
          <FormGroup key={`assistant-${props.index}-` + cIndex} inline={true}>
            <FormField inline={true} width={10}>
              <SearchBoxWithKey
                // callback
                onEvent={props.onEvent}
                // data
                type={props.type}
                id={`${field}${props.index + 1}_${cIndex + 1}`}
                role={props.role}
                isDetail={props.isDetail}
                searchedItemListWithKey={props.searchedItemListWithKey}
                selectedItem={props.isDetail ? item?.id : item}
                setSelected={handleSelected(cIndex)}
                disabled={props.isReadOnly}
              />
            </FormField>
            <FormField inline={true}>
              {cIndex !== 0 && (
                <Button
                  color={"red"}
                  icon="minus"
                  onClick={handleClick("remove", cIndex)}
                  disabled={props.isReadOnly}
                ></Button>
              )}
              <Button
                color={"green"}
                icon="plus"
                onClick={handleClick("add", cIndex)}
                disabled={props.isReadOnly}
              ></Button>
            </FormField>
          </FormGroup>
        )
      )}
    </>
  );
};

SubAssistantsInput.displayName = "SubAssistantsInput";

export default React.memo(SubAssistantsInput);
