import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";

import { Button, Form, Icon, Input, Label, Segment } from "semantic-ui-react";

const style = {
  paddingTop: { paddingTop: "20px" },
};

const ProblemComponent = React.memo(
  forwardRef((props, ref) => {
    const [value, setValue] = useState("");

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          id: props.index,
          value: value,
        };
      },
    }));

    useEffect(() => {
      if (props.value) {
        setValue(props.value);
      }
    }, [props.value]);

    const handleValue = (e, data) => {
      setValue(data.value);
    };

    const handleRemove = () => {
      props.onRemoveComponent(props.index);
    };

    return (
      <Form.Group inline>
        <Form.Field width={1}>
          <label>{props.index + 1}.</label>
        </Form.Field>
        <Form.Field width={14}>
          <Input
            readOnly={props.isReadOnly}
            value={value}
            onChange={handleValue}
          />
        </Form.Field>
        <Form.Field width={1}>
          <Button icon color="red" type="button" onClick={handleRemove}>
            <Icon name="minus" />
          </Button>
        </Form.Field>
      </Form.Group>
    );
  })
);

const SubProblemList = forwardRef((props, ref) => {
  const problemInfoRef = React.useRef({});

  const [problemInfoList, setProblemInfoList] = useState([]);

  const { titleCard, isReadOnly } = props;

  useImperativeHandle(ref, () => ({
    getSaveData: () => {
      let result = "";
      for (let key in problemInfoRef.current) {
        result = `${result}${problemInfoRef.current[key].getData().value}\n`;
        if (result === "\n") {
          result = "";
        }
      }
      return { problem_info: result };
    },
    setData: (data) => {
      setProblemInfoList(data.problem_info.split("\n"));
    },
  }));

  // -------------------------------------------------- component
  const addBlankComponent = () => {
    let newList = [];
    for (let key in problemInfoRef.current) {
      try {
        newList.push(problemInfoRef.current[key].getData().value);
      } catch (err) {}
    }
    newList.push("");
    setProblemInfoList(newList);
  };

  const handleRemoveComponent = (index) => {
    let newList = [];
    for (let key in problemInfoRef.current) {
      try {
        newList.push(problemInfoRef.current[key].getData().value);
      } catch (err) {}
    }
    newList.splice(index, 1);
    setProblemInfoList(newList);
  };

  return (
    <Segment raised secondary>
      <Label color="grey" ribbon size="big">
        {titleCard}
      </Label>

      <Form style={style.paddingTop}>
        {problemInfoList.map((data, index) => {
          const content = (
            <ProblemComponent
              ref={(el) => (problemInfoRef.current[index] = el)}
              key={index}
              index={index}
              isReadOnly={isReadOnly}
              value={data}
              onRemoveComponent={handleRemoveComponent}
            />
          );
          return content;
        })}

        <Form.Group inline>
          <Form.Field width={2}>
            <Button
              color="blue"
              type="button"
              onClick={() => {
                addBlankComponent();
              }}
            >
              <Icon name="add" />
              เพิ่ม
            </Button>
          </Form.Field>
          <Form.Field width={1}>
            <Button
              color="green"
              type="button"
              onClick={props.onSaveAllCallback}
            >
              {props.saveText ? <Icon className="check"></Icon> : "บันทึก"}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
});

SubProblemList.defaultProps = {
  titleCard: "Problem Lists",

  onSaveAllCallback: () => {},

  isReadOnly: false,
};

SubProblemList.propTypes = {
  onSaveAllCallback: PropTypes.func,

  isReadOnly: PropTypes.bool,
};

export default React.memo(SubProblemList);
