import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Radio,
  Input,
  Checkbox
} from 'semantic-ui-react'

const CardORRequestUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "0.5rem"}}>
        <div
          style={{display: "flex", width: "100%", alignItems: "baseline"}}>

          <div
            style={{fontSize: "1.25rem", fontWeight: "bold", marginBottom: "1rem", minWidth: "max-content"}}>
            นัดหมายผ่าตัด
          </div>
          <div
            style={{marginLeft: "2rem"}}>
            {props.ORRequestMessage}
          </div>
          <div
            style={{ display: props.checkin_code ? "" : "none", marginLeft: "auto", minWidth: "max-content" }}>
            {}
            <label
              style={{ background: "#77e0eb", padding: "3px 10px", fontWeight: "bold", marginLeft: "1rem" }}>
              {`เลขที่ผ่าตัด: ${props.checkin_code || ""}`}
            </label>
          </div>
        </div>
        <div
          style={{display: "grid", gridTemplateColumns: "74.5% 25.5%", columnGap: "10px", padding: "1rem 0"}}>

          <div
            className={`ui form ${props.isEncounterOPD?"--readOnly":""}`}
            style={{padding: "1.5rem 0.25rem 1rem 1rem", backgroundColor: "#D6ECF33B",}}>

            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "max-content"}}>
                <label
                  style={{margin: 0}}>
                  ประเภทผ่าตัด
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Dropdown
                  disabled={props.config?.allowedEdit}
                  fluid={true}
                  name="type"
                  onChange={props.onChangeValue}
                  options={props.orTypeOptions || []}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.type || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                style={{minWidth: "max-content"}}>
                <label
                  style={{margin: 0}}>
                  ประเภทคลินิก
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Dropdown
                  disabled={props.config?.allowedEdit}
                  fluid={true}
                  name="clinic_type"
                  onChange={props.onChangeValue}
                  options={props.orClinicTypeOptions}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.clinicType || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "max-content"}}>
                <label
                  style={{margin: 0}}>
                  Case
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </FormField>
              <FormField>
                <Radio
                  checked={props.isOpdCase || false}
                  disabled={props.config?.allowedEdit}
                  label="OPD"
                  name="case.is_opd_case"
                  onChange={props.onChangeValue}>
                </Radio>
              </FormField>
              <FormField>
                <Radio
                  checked={props.isOneDayCase || false}
                  disabled={props.config?.allowedEdit}
                  label="One day"
                  name="case.is_one_day_case"
                  onChange={props.onChangeValue}>
                </Radio>
              </FormField>
              <FormField>
                <Radio
                  checked={props.isIpdCase || false}
                  disabled={props.isEncounterOPD || props.config?.allowedEdit || false}
                  label="IPD"
                  name="case.is_ipd_case"
                  onChange={props.onChangeValue}>
                </Radio>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Dropdown
                  clearable={true}
                  disabled={props.config.disabledIpdCase || props.isEncounterOPD || props.config?.allowedEdit || false}
                  fluid={true}
                  name="case.ipd_case"
                  onChange={props.onChangeValue}
                  options={props.admitCaseOptions}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.ipdCase || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "max-content"}}>
                  นอน รพ.
                </label>
                <Input
                  disabled={props.config.disabledIpdCase || props.isEncounterOPD || props.config?.allowedEdit || false}
                  name="case.ipd_day"
                  onChange={props.onChangeValue}
                  type="number"
                  value={props.ipdDay || ""}>
                </Input>
                <label
                  style={{marginLeft: "10px"}}>
                  Day
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              widths={10}>
              <FormField>
                <label>
                  Admit
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Checkbox
                  checked={props.isAppAdmit || false}
                  disabled={props.isEncounterOPD || props.config?.allowedEdit || false}
                  label="นัดหมาย"
                  name="case.is_app_admit"
                  onChange={props.onChangeValue}
                  style={{minWidth: "max-content"}}>
                </Checkbox>
                <div
                  style={{width: "100%"}}>
                  {props.DateTextBoxApp}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <label
                  style={{width: "100%", maxWidth: "max-content"}}>
                  เลือก Admit Order
                </label>
                <Dropdown
                  clearable={true}
                  disabled={props.isEncounterOPD || props.config?.allowedEdit || false}
                  fluid={true}
                  name="admit_order"
                  onChange={props.onChangeValue}
                  options={props.admitOrderOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.admitOrder || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Checkbox
                  checked={props.isRequestIcu || false}
                  disabled={props.isEncounterOPD || props.config?.allowedEdit || false}
                  label="Request ICU"
                  name="case.is_request_icu"
                  onChange={props.onChangeValue}
                  style={{minWidth: "max-content"}}>
                </Checkbox>
                <Input
                  disabled={props.config.disabledRequestIcu || props.isEncounterOPD || props.config?.allowedEdit || false}
                  fluid={true}
                  name="case.icu_day"
                  onChange={props.onChangeValue}
                  style={{width: "100%"}}
                  type="number"
                  value={props.icuDay || ""}>
                </Input>
                <label
                  style={{fontWeight: "normal", marginLeft: "0.5rem"}}>
                  Day
                </label>
              </FormField>
            </FormGroup>
          </div>
          <div
            style={{padding: "1.5rem 0.25rem 1rem 1rem", backgroundColor: "#D6ECF33B"}}>

            <div
              className="ui form">

              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={7}>
                  <Checkbox
                    checked={props.isMedBlock || false}
                    label="Med- block"
                    name="is_med_block"
                    onChange={props.onChangeValue}
                    style={{minWidth: "max-content"}}>
                  </Checkbox>
                </FormField>
                <FormField
                  inline={true}
                  width={9}>
                  <div
                    style={{width: "100%"}}>
                    {props.DateTextBoxMed}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={7}>
                  <Checkbox
                    checked={props.isAnesthesia || false}
                    label="วิสัญญี"
                    name="is_anesthesia"
                    onChange={props.onChangeValue}>
                  </Checkbox>
                </FormField>
                <FormField
                  inline={true}
                  width={9}>
                  <div
                    style={{width: "100%"}}>
                    {props.DateTextBoxAnes}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={7}>
                  <Checkbox
                    checked={props.isOther || false}
                    name="is_other"
                    onChange={props.onChangeValue}>
                  </Checkbox>
                  <Input
                    disabled={props.config.disabledOther || false}
                    name="other"
                    onChange={props.onChangeValue}
                    placeholder="อื่นๆ"
                    value={props.other || ""}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}
                  width={9}>
                  <div
                    style={{width: "100%"}}>
                    {props.DateTextBoxOther}
                  </div>
                </FormField>
              </FormGroup>
            </div>
          </div>
        </div>
        <div
          style={{width: "100%"}}>
          {props.CardSurgeryTeam}
        </div>
        <div
          style={{width: "100%"}}>
          {props.CardAnesthesiaTeam}
        </div>
        <div
          style={{width: "100%"}}>
          {props.CardORDetail}
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem"}}>

          <div>
            {props.ClearElement}
          </div>
          <div
            style={{marginRight: props.config?.allowedPrint ? "1rem" : ""}}>
            {props.PrintElement}
          </div>
          <div
            style={{marginRight: props.config?.allowedConfirm ? "1rem" : ""}}>
            {props.ConfirmElement}
          </div>
          <div
            style={{marginRight: props.config?.allowedCancelConfirm ? "1rem" : ""}}>
            {props.CancelConfirmElement}
          </div>
          <div
            style={{marginRight: props.config?.allowedCancel ? "1rem" : ""}}>
            {props.CancelElement}
          </div>
          <div
            style={{marginRight: "1rem"}}>
            {props.SaveElement}
          </div>
        </div>
      </div>
    )
}


export default CardORRequestUX

export const screenPropsDefault = {}

/* Date Time : Mon Oct 07 2024 12:14:31 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"0.5rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width: \"100%\", alignItems: \"baseline\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"74.5% 25.5%\", columnGap: \"10px\", padding: \"1rem 0\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form ${props.isEncounterOPD?\"--readOnly\":\"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1.5rem 0.25rem 1rem 1rem\", backgroundColor: \"#D6ECF33B\",}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1.5rem 0.25rem 1rem 1rem\", backgroundColor: \"#D6ECF33B\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Dropdown",
      "parent": 7,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config?.allowedEdit"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.orTypeOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.type || \"\""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทคลินิก"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Dropdown",
      "parent": 9,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config?.allowedEdit"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "clinic_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.orClinicTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.clinicType || \"\""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 16,
      "props": {
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 16,
      "props": {
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 16,
      "props": {
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Case"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Radio",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOpdCase || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.allowedEdit"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "name": {
          "type": "value",
          "value": "case.is_opd_case"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Radio",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOneDayCase || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.allowedEdit"
        },
        "label": {
          "type": "value",
          "value": "One day"
        },
        "name": {
          "type": "value",
          "value": "case.is_one_day_case"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Radio",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isIpdCase || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEncounterOPD || props.config?.allowedEdit || false"
        },
        "label": {
          "type": "value",
          "value": "IPD"
        },
        "name": {
          "type": "value",
          "value": "case.is_ipd_case"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.config.disabledIpdCase || props.isEncounterOPD || props.config?.allowedEdit || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "case.ipd_case"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.admitCaseOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ipdCase || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน รพ."
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "Day"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 29,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config.disabledIpdCase || props.isEncounterOPD || props.config?.allowedEdit || false"
        },
        "name": {
          "type": "value",
          "value": "case.ipd_day"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.ipdDay || \"\""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Admit"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxApp"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Dropdown",
      "parent": 37,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "defaultValue": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.isEncounterOPD || props.config?.allowedEdit || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "admit_order"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.admitOrderOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.admitOrder || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Input",
      "parent": 39,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config.disabledRequestIcu || props.isEncounterOPD || props.config?.allowedEdit || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "case.icu_day"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.icuDay || \"\""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "Day"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormGroup",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormGroup",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormGroup",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Checkbox",
      "parent": 52,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isMedBlock || false"
        },
        "label": {
          "type": "value",
          "value": "Med- block"
        },
        "name": {
          "type": "value",
          "value": "is_med_block"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Checkbox",
      "parent": 54,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isAnesthesia || false"
        },
        "label": {
          "type": "value",
          "value": "วิสัญญี"
        },
        "name": {
          "type": "value",
          "value": "is_anesthesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Checkbox",
      "parent": 56,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOther || false"
        },
        "label": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "is_other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxMed"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxAnes"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxOther"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CardSurgeryTeam"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CardAnesthesiaTeam"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CardORDetail"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Input",
      "parent": 56,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config.disabledOther || false"
        },
        "name": {
          "type": "value",
          "value": "other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "placeholder": {
          "type": "value",
          "value": "อื่นๆ"
        },
        "value": {
          "type": "code",
          "value": "props.other || \"\""
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"1rem\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SaveElement"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ConfirmElement"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: props.config?.allowedConfirm ? \"1rem\" : \"\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CancelConfirmElement"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: props.config?.allowedCancelConfirm ? \"1rem\" : \"\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CancelElement"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: props.config?.allowedCancel ? \"1rem\" : \"\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดหมายผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.25rem\", fontWeight: \"bold\", marginBottom: \"1rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ORRequestMessage"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"2rem\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PrintElement"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: props.config?.allowedPrint ? \"1rem\" : \"\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClearElement"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.checkin_code ? \"\" : \"none\", marginLeft: \"auto\", minWidth: \"max-content\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "code",
          "value": "`เลขที่ผ่าตัด: ${props.checkin_code || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{ background: \"#77e0eb\", padding: \"3px 10px\", fontWeight: \"bold\", marginLeft: \"1rem\" }"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก Admit Order"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Checkbox",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isRequestIcu || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEncounterOPD || props.config?.allowedEdit || false"
        },
        "label": {
          "type": "value",
          "value": "Request ICU"
        },
        "name": {
          "type": "value",
          "value": "case.is_request_icu"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Checkbox",
      "parent": 35,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isAppAdmit || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEncounterOPD || props.config?.allowedEdit || false"
        },
        "label": {
          "type": "value",
          "value": "นัดหมาย"
        },
        "name": {
          "type": "value",
          "value": "case.is_app_admit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 42,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardORRequestUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
