import React, { useMemo } from "react";
import { Input, Modal } from "semantic-ui-react";
import ModDrugAllVerifyUX from "./ModDrugAllVerifyUX";
import { SEVERITY_IMAGE_MAP, SEVERITY_TEXT_MAP } from "./ModDrugInteraction";

const ModDrugAllVerify = (props: any) => {

	const adrData = useMemo(() => {
		return <div style={{ padding: "10px", fontSize: "14px", lineHeight: "2rem" }}>
			{props.modAllVerifyDrug?.adverseDrugs.map((item: any, index: number) => {
				return (
          <div key={`adr${index}`}>
            <div>{`${index + 1}. ${item.drug_name}`}</div>
            <div style={{ fontWeight: "bold" }}>รายละเอียด:</div>
            <div style={{ paddingLeft: "10px" }}>
              {item.notes
                .split("\n")
                .slice(1)
                .map((text: string) => {
                  return <div>{text}</div>;
                })}
            </div>
            <div
              style={{
                color: "red",
                fontSize: "12px",
                display: props.modAllVerifyDrug?.requireNote?.includes(
                  `drug_adverse_note_${index}`
                )
                  ? ""
                  : "none",
              }}
            >
              * require
            </div>
            <div style={{ padding: "10px 20px", width: "100%" }}>
              {" "}
              {/* status == 1 คือห้ามสั่ง */}
              {item.status !== 1 ? (
                <Input
                  style={{ width: "100%" }}
                  value={
                    props.modAllVerifyDrug?.note?.[`drug_adverse_note_${index}`]
                  }
                  error={props.modAllVerifyDrug?.requireNote?.includes(
                    `drug_adverse_note_${index}`
                  )}
                  onChange={(e: any, data: any) => {
                    props.onEvent({
                      message: "AllVerifyDrug",
                      params: {
                        action: "change_note",
                        name: `drug_adverse_note_${index}`,
                        value: data.value,
                      },
                    });
                  }}
                />
              ) : (
                <div style={{ fontWeight: "bold" ,color: "red"}}> ระบบจะไม่นำใบยานี้ไปสร้างเป็นรายการ </div>
              )}
            </div>
          </div>
        );
			})}
		</div>
	}, [
		props.modAllVerifyDrug?.adverseDrugs,
		props.modAllVerifyDrug?.requireNote,
		props.modAllVerifyDrug?.note
	])

	const interactionDrugs = useMemo(() => {
		const presentDrugIds = props.modAllVerifyDrug?.presentDrugs?.map((item: any) => item.id)
		const orderDrugIds = props.modAllVerifyDrug?.drugItems?.map((item: any) => item.product)

		return <div style={{ padding: "10px", fontSize: "14px" }}>
			{props.modAllVerifyDrug?.interactionDrugs.map((item: any, index: number) => {

				return <div key={`interaction${index}`}>
					<div style={{ padding: "10px", backgroundColor: "#ffffdd" }}>{
						item.members.map((drug_id: number, d_index: number) => {
							if (orderDrugIds.includes(drug_id) || presentDrugIds.includes(drug_id)) {
								return <div key={`interaction${index}-${d_index}`} style={{ fontWeight: "bold", lineHeight: "2rem" }}>
									{item.members_name[d_index]}
								</div>
							} else {
								return <></>
							}
						})
					}</div>
					<div style={{ padding: "10px 0px", display: "flex", fontWeight: "bold", alignItems: "center" }}>
						<span style={{ marginRight: "10px" }}>ความรุนแรง:</span>
						{item.severity !== 5 && item.severity !== undefined &&
							(<img src={SEVERITY_IMAGE_MAP[item.severity as (1 | 2 | 3 | 4)]} height={30} width={30} />)}
						<span style={{ marginLeft: item.severity !== 5 && item.severity !== undefined ? "10px" : "0px" }}>
							{SEVERITY_TEXT_MAP[item.severity as (1 | 2 | 3 | 4 | 5)]}
						</span>
					</div>
					<div style={{ padding: "10px 0px" }}><b>ผลของการเกิดอันตรกิริยา: </b>{item.affect_summary}</div>
					<div style={{ padding: "10px 0px" }}><b>การจัดการ: </b>{item.management}</div>
					<span style={{
						color: "red",
						fontSize: "12px",
						display: props.modAllVerifyDrug?.requireNote?.includes(`drug_interaction_note_${index}`) ? "" : "none"
					}}>* require</span>
					<div style={{ padding: "10px 20px", width: "100%" }}>
						<Input
							style={{ width: "100%" }}
							value={props.modAllVerifyDrug?.note?.[`drug_interaction_note_${index}`]}
							error={props.modAllVerifyDrug?.requireNote?.includes(`drug_interaction_note_${index}`)}
							onChange={(e: any, data: any) => {
								props.onEvent({ message: "AllVerifyDrug", params: { action: "change_note", name: `drug_interaction_note_${index}`, value: data.value } })
							}}
						/></div>
				</div>
			})}
		</div>
	}, [
		props.modAllVerifyDrug?.interactionDrugs,
		props.modAllVerifyDrug?.requireNote,
		props.modAllVerifyDrug?.note
	])

	const interactionLabDrugs = useMemo(() => {
		return <div style={{ padding: "10px" }}>
			{props.modAllVerifyDrug?.interactionLabDrugs?.map((item: any, index: number) => {

				return <div key={`interactionLab${index}`}>
					<div style={{ display: "flex", fontSize: "14px", lineHeight: "2rem" }}>
						<div style={{ width: "25px", textAlign: "right", paddingRight: "10px" }}>{`${index + 1}. `}</div>
						<div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
							<div style={{ color: "red", fontWeight: "bold" }}>{item.drug_name}</div>
							<div>{item.lab_warning_summary}</div>
							<div>{item.detail}</div>
						</div>
					</div>
					<div style={{
						color: "red",
						fontSize: "10px",
						display: props.modAllVerifyDrug?.requireNote?.includes(`drug_lab_interaction_note_${index}`) ? "" : "none"
					}}>* require</div>
					<div style={{ padding: "10px 20px", width: "100%" }}>
						<Input
							style={{ width: "100%" }}
							value={props.modAllVerifyDrug?.note?.[`drug_lab_interaction_note_${index}`]}
							error={props.modAllVerifyDrug?.requireNote?.includes(`drug_lab_interaction_note_${index}`)}
							onChange={(e: any, data: any) => {
								props.onEvent({ message: "AllVerifyDrug", params: { action: "change_note", name: `drug_lab_interaction_note_${index}`, value: data.value } })
							}}
						/>
					</div>
				</div>
			})}
		</div>
	}, [
		props.modAllVerifyDrug?.interactionLabDrugs,
		props.modAllVerifyDrug?.requireNote,
		props.modAllVerifyDrug?.note
	])

	const diseaseDrugs = useMemo(() => {
		return <div style={{ padding: "10px" }}>
			{props.modAllVerifyDrug?.diseaseDrugs.map((item: any, index: number) => {
				return (<div key={`disease${index}`}>
					<div style={{ display: "flex", fontSize: "14px", lineHeight: "2rem" }}>
						<div style={{ width: "25px", textAlign: "right", paddingRight: "10px" }}>{`${index + 1}. `}</div>
						<div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
							<span style={{ color: "red", fontWeight: "bold" }}>{item.drug_name}</span>
							<span style={{ fontWeight: "bold" }}>โรคประจำตัว: {item.segment_group_name}</span>
							<span>{item.message}</span>
						</div>
					</div>
					<div style={{
						color: "red",
						fontSize: "12px",
						display: props.modAllVerifyDrug?.requireNote?.includes(`drug_disease_note_${index}`) ? "" : "none"
					}}>* require</div>
					<div style={{ padding: "10px 20px", width: "100%" }}>
						<Input
							style={{ width: "100%" }}
							value={props.modAllVerifyDrug?.note?.[`drug_disease_note_${index}`]}
							error={props.modAllVerifyDrug?.requireNote?.includes(`drug_disease_note_${index}`)}
							onChange={(e: any, data: any) => {
								props.onEvent({ message: "AllVerifyDrug", params: { action: "change_note", name: `drug_disease_note_${index}`, value: data.value } })
							}}
						/>
					</div>
				</div>)
			})}

		</div>
	}, [
		props.modAllVerifyDrug?.diseaseDrugs,
		props.modAllVerifyDrug?.requireNote,
		props.modAllVerifyDrug?.note
	])

	// console.log("MOdDrugAllVeryfy props:", props)
	return (
		<Modal open={props.modAllVerifyDrug?.open}>
			<ModDrugAllVerifyUX
				showADR={!!(props.modAllVerifyDrug?.adverseDrugs?.length)}
				showDI={!!(props.modAllVerifyDrug?.interactionDrugs?.length)}
				showDIL={!!(props.modAllVerifyDrug?.interactionLabDrugs?.length)}
				showDD={!!(props.modAllVerifyDrug?.diseaseDrugs?.length)}
				adrDrugs={adrData}
				interactionDrugs={interactionDrugs}
				interactionLabDrugs={interactionLabDrugs}
				diseaseDrugs={diseaseDrugs}
				onApprove={() => {
					props.onEvent({ message: "AllVerifyDrug", params: { action: "confirm_note" } })
				}}
				onCancel={() => {
					props.onEvent({ message: "AllVerifyDrug", params: { action: "cancel" } })
				}}
			/>
		</Modal>
	)
}

export default ModDrugAllVerify;