import React, { useMemo, useState, useEffect } from "react";
import { Button, Modal, Tab, TabPane } from "semantic-ui-react";
import moment from "moment";
// UI
import CardORDetailUX from "./CardORDetailUX";
import CardRiskOfBleedingUX from "./CardRiskOfBleedingUX";
import CardRiskOfDVTUX from "./CardRiskOfDVTUX";
import ModSearchDoctorNote from "react-lib/apps/APP/ModSearchDoctorNote";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

import { ADM_FIBRINOLYTIC_USED, mapOptions, PRE_ASSESSMENT_OPTIONS } from "./sequence/ORRequest";

// Common
import { DateTextBox } from "../../common";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

// Utils
import { beStringToAdString, formatDate, adToBe } from "../../../utils/dateUtils";

const BLOOD_REQUEST_OPTIONS = [
  { key: "is_na", value: "is_na", text: "N/A" },
  { key: "is_yes", value: "is_yes", text: "YES" },
  { key: "is_yes_ts", value: "is_yes_ts", text: "YES(T/S)" },
];

const CARD_OR_DETAIL = "CardORDetail";

const CardORDetail = (props: any) => {
  const [riskTab, setRiskTab] = useState<string>("DVT");
  const [openModDoctorNote, setOpenModDoctorNote] = useState<boolean>(false);
  const [openModRisk, setOpenModRisk] = useState<boolean>(false);

  useEffect(() => {
    const date = beStringToAdString(props.ORRequestSequence?.start_date);
    const addDate = beStringToAdString(formatDate(moment().clone().add(1, "days"))) || "";
    const beDate = adToBe(addDate);

    if (props.isNewEncounter) {
      if (date && date < addDate) {
        props.onChangeDate("start_date")(beDate);
      }
    } else {
      if (date && date > addDate) {
        props.onChangeDate("start_date")(beDate);
      }
    }
  }, [props.isNewEncounter]);

  // Option
  const cmOptions = useMemo(() => {
    return mapOptions(props.ORRequestSequence?.clinicalTerm?.CM?.items || [], "id", "name");
  }, [props.ORRequestSequence?.clinicalTerm]);

  const asaClassOptions = useMemo(() => {
    return mapOptions(props.ORRequestSequence?.clinicalTerm?.asa_class?.items || [], "id", "name");
  }, [props.ORRequestSequence?.clinicalTerm]);

  // Note
  const handleAddNote = () => {
    setOpenModDoctorNote(true);
  };

  const handleOnSelectNote = (noteList: string[]) => {
    const orderNote = props.ORRequestSequence?.remark;

    const note: (string | number)[] = orderNote ? [orderNote, ...noteList] : noteList;

    props.onChangeValue(null, { name: "remark", value: note.join("\n") });
    setOpenModDoctorNote(false);
  };

  // Risk
  const handleOpenModRisk = () => {
    setOpenModRisk(true);
  };

  const handleChangeRiskTab = (_e: any, data: any) => {
    setRiskTab(data.name);
  };

  const handleSaveDVTRisk = () => {
    props.runSequence({
      sequence: "ORRequest",
      action: "SAVE_FORM_DVT",
      card_action: `${CARD_OR_DETAIL}_SAVE_FORM_DVT`,
      encounterId: props.encounterId,
    });
  };

  const handleSaveBleedingRisk = () => {
    props.runSequence({
      sequence: "ORRequest",
      action: "SAVE_FORM_BLEEDING",
      card_action: `${CARD_OR_DETAIL}_SAVE_FORM_BLEEDING`,
      encounterId: props.encounterId,
    });
  };

  return (
    <div>
      <CardORDetailUX
        ADM_FIBRINOLYTIC_USED={ADM_FIBRINOLYTIC_USED}
        // data
        isAppointment={props.isNewEncounter}
        location={props.ORRequestSequence?.location}
        anesthesiaMethod={props.ORRequestSequence?.anesthesia_method}
        operatingRoom={props.ORRequestSequence?.operating_room}
        procedureType={props.ORRequestSequence?.procedure_type}
        frozenSection={props.ORRequestSequence?.frozen_section}
        mammogram={props.ORRequestSequence?.mammogram}
        other={props.ORRequestSequence?.blood_request?.other}
        isOther={props.ORRequestSequence?.blood_request?.is_other}
        plt={props.ORRequestSequence?.blood_request?.plt}
        isPlt={props.ORRequestSequence?.blood_request?.is_plt}
        ffp={props.ORRequestSequence?.blood_request?.ffp}
        isFfp={props.ORRequestSequence?.blood_request?.is_ffp}
        prc={props.ORRequestSequence?.blood_request?.prc}
        isPrc={props.ORRequestSequence?.blood_request?.is_prc}
        estimateOperationHour={props.ORRequestSequence?.estimate_operation_hour}
        estimateOperationMinute={props.ORRequestSequence?.estimate_operation_minute}
        bloodRequest={
          BLOOD_REQUEST_OPTIONS.find(
            (item: any) => props.ORRequestSequence?.blood_request?.[item.value]
          )?.value
        }
        blood_component={props.ORRequestSequence?.blood_request?.blood_component || ""}
        cross_match_detail={props.ORRequestSequence?.blood_request?.cross_match_detail || ""}
        cross_match_date={
          props.ORRequestSequence?.blood_request?.cross_match_date
            ? adToBe(
                moment(props.ORRequestSequence?.blood_request?.cross_match_date).format(
                  "YYYY-MM-DD"
                ),
                "YYYY-MM-DD"
              )
            : "-"
        }
        isLess_500={props.ORRequestSequence?.anticipated_blood_loss?.is_less_500}
        isMore_500={props.ORRequestSequence?.anticipated_blood_loss?.is_more_500}
        define={props.ORRequestSequence?.anticipated_blood_loss?.define}
        antiPlateletValue={props.ORRequestSequence?.anti_platelet_coagulant?.value}
        antiPlateletCoagulant={props.ORRequestSequence?.anti_platelet_coagulant}
        preOpMedicalAssessment={props.ORRequestSequence?.pre_op_medical_assessment}
        remark={props.ORRequestSequence?.anti_platelet_coagulant?.remark}
        contrastMedia={props.ORRequestSequence?.contrast_media}
        asaClass={props.ORRequestSequence?.asa_class}
        asaClassText={
          asaClassOptions.filter(
            (item: any) => item.value === props.ORRequestSequence?.asa_class
          )?.[0]?.text || ""
        }
        specialEquipments={props.ORRequestSequence?.special_equipments}
        detail={props.ORRequestSequence?.detail}
        remarkDetail={props.ORRequestSequence?.remark}
        // options
        anesthesiaMethodOptions={props.masterOptions.anesthesiaMethod}
        orLocationOptions={props.ORRequestSequence?.orLocationOptions}
        procedureTypeOptions={props.masterOptions.orProcedureType}
        operatingRoomOptions={props.ORRequestSequence?.operatingRoomOptions}
        bloodRequestOptions={BLOOD_REQUEST_OPTIONS}
        preAssessmentOptions={PRE_ASSESSMENT_OPTIONS}
        cmOptions={cmOptions}
        asaClassOptions={asaClassOptions}
        specialEquipmentOptions={props.masterOptions.specialEquipment}
        // config
        config={{
          disabledRoom: true, //!props.ORRequestSequence?.location,
          showBRCheck:
            props.ORRequestSequence?.blood_request?.is_yes ||
            props.ORRequestSequence?.blood_request?.is_yes_ts,
          enabledPrc: props.ORRequestSequence?.blood_request?.is_prc,
          enabledFfp: props.ORRequestSequence?.blood_request?.is_ffp,
          enabledPlt: props.ORRequestSequence?.blood_request?.is_plt,
          enabledOther: props.ORRequestSequence?.blood_request?.is_other,
          disabledAntiPlatelet:
            props.ORRequestSequence?.anti_platelet_coagulant?.value !==
            ADM_FIBRINOLYTIC_USED.ANTI_PALETELET,
          disableLocation: true,
          allowedEdit: props.ORRequestSequence?.allowed_action?.includes("EDIT"),
          // hideFFP: isNa,
          // hidePlt: isNa,
          // hideOther: isNa,
        }}
        // callback
        onChangeValue={
          props.isReadOnly
            ? () => {
                console.log("block");
              }
            : props.onChangeValue
        }
        onAddNote={props.isReadOnly ? () => {} : handleAddNote}
        onOrderBloodRequest={props.isReadOnly ? () => {} : props.onOrderBloodRequest}
        onOpenModRisk={props.isReadOnly ? () => {} : handleOpenModRisk}
        // Element
        DateTextBox={
          <DateTextBox
            inputRef={(instance: any) => {
              instance && (instance.ref.inputRef.current.style.opacity = "1");
            }}
            value={props.ORRequestSequence?.start_date || "ไปเลือกที่วันเวลาผ่าตัด"}
            onChange={props.onChangeDate("start_date")}
            minDate={
              props.isNewEncounter
                ? formatDate(moment().clone().add(1, "days"))
                : formatDate(moment())
            }
            maxDate={props.isNewEncounter ? "" : formatDate(moment().clone().add(1, "days"))}
            disabled={true}
            // style={{ width: "13rem" }}
            // inputStyle={{ opacity: 1 }}
          />
        }
        DateTextBoxAntiPlatelet={
          <DateTextBox
            value={props.ORRequestSequence?.anti_platelet_coagulant?.date || ""}
            onChange={props.onChangeDate("anti_platelet_coagulant.date")}
            disabled={!props.ORRequestSequence?.anti_platelet_coagulant?.drug || false}
          />
        }
        DateTextBoxAntiPlateletOther={
          <DateTextBox
            value={props.ORRequestSequence?.anti_platelet_coagulant?.other_drug_date || ""}
            onChange={props.onChangeDate("anti_platelet_coagulant.other_drug_date")}
            disabled={!props.ORRequestSequence?.anti_platelet_coagulant?.other_drug || false}
          />
        }
        DateTextBoxNPO={
          <DateTextBox
            value={props.ORRequestSequence?.anti_platelet_coagulant?.npo_date || ""}
            onChange={props.onChangeDate("anti_platelet_coagulant.npo_date")}
          />
        }
        TimeTextBoxNPO={
          <TimeComboBox
            ref={(instance: any) => {
              instance && (instance.inputRef.current.style.opacity = "1");
            }}
            defaultValue={props.ORRequestSequence?.anti_platelet_coagulant?.npo_time || ""}
            onTextChange={(time: any) =>
              props.onChangeValue(null, {
                value: time,
                name: "anti_platelet_coagulant.npo_time",
              })
            }
            // inputStyle={{ opacity: 1 }}
          />
        }
        StartTime={
          <TimeComboBox
            ref={(instance: any) => {
              instance && (instance.inputRef.current.style.opacity = "1");
            }}
            defaultValue={props.ORRequestSequence?.start_time || ""}
            // onTextChange={props.onChangeDate("start_time")}
            disabled={true}
            // inputStyle={{ opacity: 1 }}
          />
        }
        EndTime={
          <TimeComboBox
            ref={(instance: any) => {
              instance && (instance.inputRef.current.style.opacity = "1");
            }}
            defaultValue={
              props.ORRequestSequence?.end_date_time
                ? moment(props.ORRequestSequence?.end_date_time).format("HH:mm")
                : ""
            }
            // onTextChange={props.onChangeDate("start_time")}
            disabled={true}
            // inputStyle={{ opacity: 1 }}
          />
        }
      />

      <Modal open={openModDoctorNote} onClose={() => setOpenModDoctorNote(false)}>
        <ModSearchDoctorNote
          controller={props.proxyController}
          division={props.divisionId}
          onClose={() => setOpenModDoctorNote(false)}
          onSelectNote={handleOnSelectNote}
        />
      </Modal>

      <Modal
        closeIcon
        open={openModRisk}
        onClose={() => setOpenModRisk(false)}
        style={{ padding: "20px" }}
      >
        <Modal.Header style={{ color: "#3D9BB0" }}>Pre-operative Medical Assessment</Modal.Header>
        <div style={{ padding: "10px 0px" }}>
          <Button
            name="DVT"
            onClick={handleChangeRiskTab}
            style={{ color: "#FFF", background: riskTab === "DVT" ? "#5DBCD2" : "" }}
          >
            Risk of DVT
          </Button>
          <Button
            name="BLEEDING"
            onClick={handleChangeRiskTab}
            style={{ color: "#FFF", background: riskTab === "BLEEDING" ? "#5DBCD2" : "" }}
          >
            Risk of Bleeding
          </Button>
        </div>
        {riskTab === "DVT" && (
          <CardRiskOfDVTUX
            // function
            onChangeValue={props.onChangeValue}
            // data
            data={props.ORRequestSequence?.pre_op_medical_assessment?.data?.risk_dvt}
            // component
            ButtonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleSaveDVTRisk}
                // data
                paramKey={`${CARD_OR_DETAIL}_SAVE_FORM_DVT`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_OR_DETAIL}_SAVE_FORM_DVT`]}
                // config
                color={
                  props.ORRequestSequence?.pre_op_medical_assessment?.data?.risk_dvt?.edit_by
                    ? "yellow"
                    : "green"
                }
                fluid={true}
                // size
                title={
                  props.ORRequestSequence?.pre_op_medical_assessment?.data?.risk_dvt?.edit_by
                    ? "Edit & Save"
                    : "SAVE"
                }
              />
            }
          />
        )}
        {riskTab === "BLEEDING" && (
          <CardRiskOfBleedingUX
            // function
            onChangeValue={props.onChangeValue}
            // options
            preAssessmentOptions={PRE_ASSESSMENT_OPTIONS}
            // data
            data={props.ORRequestSequence?.pre_op_medical_assessment?.data?.risk_bleeding}
            // component
            ButtonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleSaveBleedingRisk}
                // data
                paramKey={`${CARD_OR_DETAIL}_SAVE_FORM_BLEEDING`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_OR_DETAIL}_SAVE_FORM_BLEEDING`]}
                // config
                color={
                  props.ORRequestSequence?.pre_op_medical_assessment?.data?.risk_bleeding?.edit_by
                    ? "yellow"
                    : "green"
                }
                fluid={true}
                // size
                title={
                  props.ORRequestSequence?.pre_op_medical_assessment?.data?.risk_bleeding?.edit_by
                    ? "Edit & Save"
                    : "SAVE"
                }
              />
            }
          />
        )}
      </Modal>
    </div>
  );
};

export default React.memo(CardORDetail);
