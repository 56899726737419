import React, { useRef, useImperativeHandle } from "react";
import { InputProps } from "semantic-ui-react";

// UI
import { ChatList } from "../../../../patient-lib/Chat";

type TFChatListProps = {
  apiToken?: string;
  userId?: string | number;
  isLoading?: boolean;
  channelList?: any[];
  chatSearchValue?: string;
  chatListController: any;
  searchChannelList: () => any;
  noApiToken: (props: any) => any;
  onChannelSelected: (path: string) => any;
  onRedirectedChannelSelected: (path: string) => any;
  loadChannelList: () => any;
  onSearchChange: (e: any, v: InputProps) => any;
  onUnfollowChannelSelected: (item: any) => any;
};

const TFChatList = React.forwardRef<any, TFChatListProps>((props, ref) => {
  const chatListRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    chatListRef: chatListRef.current,
  }));

  return (
    <div className="tf-chat-list">
      <ChatList
        ref={chatListRef}
        // @ts-ignore
        apiToken={props.apiToken}
        userId={props.userId}
        controller={props.chatListController}
        noApiToken={props.noApiToken}
        // noApiToken={this.handleGoToLogin}
        isLoading={props.isLoading}
        onChannelSelected={props.onChannelSelected}
        onRedirectedChannelSelected={props.onRedirectedChannelSelected}
        loadChannelList={props.loadChannelList}
        channelList={props.channelList}
        onSearchChange={props.onSearchChange}
        searchChannelList={props.searchChannelList}
        chatSearchValue={props.chatSearchValue}
        onUnfollowChannelSelected={props.onUnfollowChannelSelected}
      />
    </div>
  );
});

TFChatList.defaultProps = {
  apiToken: "",
  isLoading: false,
  channelList: [],
  chatSearchValue: "",
  chatListController: {},
  searchChannelList: () => {},
  noApiToken: () => {},
  onChannelSelected: () => {},
  onRedirectedChannelSelected: () => {},
  loadChannelList: () => {},
  onSearchChange: () => {},
  onUnfollowChannelSelected: () => {},
} as TFChatListProps;

export default React.memo(TFChatList);
