import React, { forwardRef, PropsWithChildren, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  Dimmer,
  Header,
  Button,
  Dropdown,
  Form,
  Input,
  Loader,
  Label,
  Icon,
} from "semantic-ui-react";
import ChatListRow from "react-lib/apps/IsHealth/Chat/ChatListRow";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { useInterval } from "../../../utils/hooksUtils";
import config from "../../../../config/config";
import DateTextBox from "../../common/DateTextBox";
import moment from "moment";
import { formatDate, convertToADDate } from "../../../utils";
import { ChatControllerInterface } from "react-lib/apps/IsHealth/Chat/ChatControllerInterface";
import "./ChatList.scss";

interface ChatListProp {
  data: any;
  error: any;
  isLoading: boolean;
  hideReclassify: boolean;
  controller?: ChatControllerInterface;
  onRefresh: () => void;
  onSelect: (id: number) => void;
  chatChannelId: string | number | null;
  division: string | number;
  onSetAppointmentId: (args: { appointmentId: number }) => void;
  usePatientId: boolean;
  notFilterDivision: boolean;
  onReceiveChatList: (args: { chatList: any[] }) => void;
  unsignedOnly: boolean;
  unsignedFilter: boolean;
  hideFilterSearch: boolean;
  filterTriageAppByAppDate: boolean;
  userState?: boolean;
  setProps?: any;
  signedOnly?: boolean;
  handleSetChatChannel?: (channelId: string) => void;
}

const MOCK_DATA = [
  {
    full_name: "นายมาโนช มานะ",
    age: "35 ปี 3 เดือน 2 วัน",
    hn: "123456",
  },
  {
    full_name: "นายมานี มานะ",
    age: "35 ปี 3 เดือน 2 วัน",
    hn: "123456",
  },
  {
    full_name: "นางสาววิสุธินี สาเละ",
    age: "35 ปี 3 เดือน 2 วัน",
    hn: "123456",
    classify: "1 ปรึกษาพยาบาล",
  },
];

 const defaultProps = {
  data: {},
  error: null,
  isLoading: false,
  onRefresh: () => {},
  onSelect: () => {},
  chatChannelId: null,
  division: null,
  onSetAppointmentId: () => {},
  hideReclassify: false,
  usePatientId: false,
  onReceiveChatList: () => {},
  notFilterDivision: false,
  hideFilterDate: false,
  unsignedOnly: true,
  unsignedFilter: false,
  hideFilterSearch: false,
  filterTriageAppByAppDate: false,
};

const ChatList = forwardRef((props: ChatListProp & typeof defaultProps, ref: Ref<any>) => {

  const history = useHistory();
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [cloneChatList, setCloneChatList] = useState([]);
  const [chatChannelId, setChatChannelId] = useState(null);
  const [encounterId, setEncounterId] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [filterValue, setFilterValue] = useState("default");
  const [signedFilterValue, setSignedFilterValue] = useState("signed");
  const [textSearch, setTextSearch] = useState("");
  const [hasWSReclassify, setHasWSReclassify] = useState(false);
  const [filterDate, setFilterDate] = useState(formatDate(moment()));

  const signedFilterOptions = [
    { key: "signed", text: "ผูก HN แล้ว", value: "signed" },
    { key: "unsigned", text: "ยังไม่ผูก HN", value: "unsigned" },
  ];

  const getCurrentPageURL = () => {
    const re = new RegExp(/^(\/[^/]*).*/);
    let match = re.exec(history.location.pathname);
    if (match) {
      return match[1] + "/";
    }
    return "/Chat/";
  };

  useImperativeHandle(ref, () => ({
    getChatList: () => {
      setHasWSReclassify(true);
    },
    getFilterValue: () => {
      return filterValue;
    },
  }));

  const getChatList = async ({ type } = {}) => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      return;
    }
    setStateError(null);
    let hasPatientApp = null;
    let triageLevel = filterValue;
    if (filterValue === "noApp") {
      hasPatientApp = false;
      triageLevel = "all";
    } else if (filterValue === "app") {
      hasPatientApp = true;
      triageLevel = "all";
    }

    let params = {
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      filter: triageLevel === "default" ? null : triageLevel,
      orderBy: config.LATEST_ENCOUNTER_TOP && !props.userState ? "-created" : null,
      hasPatientApp,
      date:
        !config.HIDE_QUEUE_FILTER_DATE && filterDate
          ? moment(convertToADDate({ date: filterDate }), "DD/MM/YYYY").format("YYYY-MM-DD")
          : "",
      unsignedOnly: props.unsignedOnly,
      signedOnly: props.signedOnly,
    };

    if (filterValue === "app" && props.filterTriageAppByAppDate) {
      params.appDate = params.date;
      delete params.date;
    }

    if (props.unsignedFilter) {
      params.unsignedOnly = signedFilterValue === "unsigned";
      params.signedOnly = signedFilterValue === "signed";
    }

    if (props.division && !props.notFilterDivision) {
      params.divisionIds = props.division;
    }

    if (props.userState) {
      params.divisionGroupCode = "REG";
    }

    if (textSearch) {
      params.search = textSearch;
    }

    params.limit = 100


    let [response, error, network]: [any, any,any] = [null, null,null];

    if (props.userState) {
      // [response, error, network] = await props.controller.getChatList(
      //   params
      // );
      [response, error, network] = await props.controller.prxManager.getPatientQueue(
        params
      );
    } else {
      [response, error, network] = await props.controller.prxManager.getPatientQueue(
        params
      );
    }

    if (!isMounted.current) {
      return;
    }
    // console.log('response: ', response);
    if (response) {
      setHasWSReclassify(false);
      setChatList(response.items);
      setCloneChatList(response.items);
      props.onReceiveChatList({ chatList: response.items });
      if (response.items.length === 0 && type === "RECLASSIFY" && props.match.params.encounterId) {
        history.push(getCurrentPageURL());
      }
    } else if (error) {
      if (typeof error === "string" && error.length > 100) {
        return setStateError("เกิดข้อผิดพลาด");
      } else {
        return setStateError(error);
      }
    }
  };

  useEffect(() => {
    if (props.match && props.match.params) {
      setEncounterId(props.match.params.encounterId);
      setChatChannelId(props.match.params.chatChannelId);
    }
  }, [props.match.params.chatChannelId, props.match.params.encounterId]);

  const genRow = React.useCallback(() => {
    let triageDes = "";
    let triageColor = "black";
    console.log(chatList, "chTList");
    return (
      chatList
        // .filter((item) => {
        //   const lowerTextSearch = textSearch.toLowerCase();
        //   return (
        //     (item.patient_name && item.patient_name.toLowerCase().includes(lowerTextSearch)) ||
        //     (item.hn && item.hn.split("-").join("").includes(textSearch.split("-").join(""))) ||
        //     (item.appointment_doctor_full_name &&
        //         item.appointment_doctor_full_name.toLowerCase().includes(lowerTextSearch))
        //   );
        // })
        .map((item, itemIndex) => {
          const index = props.triageLevelList.findIndex(
            (triage) => triage.triage_level === item.triage_level
          );
          if (index > -1) {
            triageDes = props.triageLevelList[index].text;
            triageColor = props.triageLevelList[index].code_color;
          }
          // console.log(item.triage_level, "props.triageLevelList");
          return (
            <ChatListRow
              hideReclassify={props.hideReclassify}
              triageDes={item.classify_description}
              triageColor={item.classify_code_color}
              className={
                parseInt(encounterId) === item.id || parseInt(chatChannelId) === item.chat_channel
                  ? "selected"
                  : ""
              }
              showAppointmentDetail={filterValue === "app"}
              chat={item}
              key={item.id}
              onSelect={(id) => {
                console.log("props.userState", props.userState)
                if (props.userState) {
                  props.handleSetChatChannel?.(item.chat_channel);
                  if (item.unread_message_count > 0) {
                    let newArr = [...chatList];
                    newArr[itemIndex].unread_message_count = 0;
                    setChatList(newArr);
                  }
      
                } else {
                  const page = getCurrentPageURL();
                  let path = page + item.id + "/";
                  if (props.usePatientId && item.patient) {
                    path = page + item.patient + "/";
                  }
                  props.onSetAppointmentId({ appointmentId: item.appointment_id });
                  if (item.chat_channel) {
                    path = path + item.chat_channel + "/";
                  }
                  if (props.usePatientId && item.patient) {
                    path = path + "?hn=" + item.hn;
                  }
                  if (item.unread_message_count > 0) {
                    let newArr = [...chatList];
                    newArr[itemIndex].unread_message_count = 0;
                    setChatList(newArr);
                  }
                  history.push(path);
                }
              }}
              isListChatSupport={props.userState}
            />
          );
        })
    );
  }, [chatList, chatChannelId, encounterId]);
  // }, [chatList, chatChannelId, encounterId, textSearch]);

  const handleSearch = (e) => {
    const { value } = e.target;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setTextSearch(value);
      }, 800)
    );
  };

  useInterval(() => {
    if (hasWSReclassify) {
      getChatList({ type: "RECLASSIFY" });
    }
  }, 10000);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const getEncounterTriage = async () => {
      setIsLoading(true);
      await getChatList();
      if (!isMounted.current) {
        return;
      }
      setIsLoading(false);
    };
    if (props.division) {
      getEncounterTriage();
    }
  }, [filterValue, props.division, filterDate, signedFilterValue]);

  useEffect(() => {
    const filterChatList = () => {
      const filter = cloneChatList.filter((list) => {
        return (
          list.patient_name?.toUpperCase()?.includes(textSearch?.toUpperCase()) ||
          list.hn?.includes(textSearch)
        );
      });
      setHasWSReclassify(false);
      setChatList(filter);
      props.onReceiveChatList({ chatList: filter });
    };

    if (props.unsignedFilter) {
      getChatList();
    } else {
      filterChatList();
    }
  }, [textSearch]);

  if (stateError) {
    return (
      <div
        className="chatListError"
        // style={chatStyle.chatListError}
      >
        <Dimmer.Dimmable dimmed={isLoading}>
          <Header>An Error occurred</Header>
          {!!stateError && !!stateError.detail ? stateError.detail : stateError}
          <br />
          <br />
          <div>
            <Button size="big" circular icon="redo" onClick={getChatList} loading={isLoading} />
          </div>
        </Dimmer.Dimmable>
      </div>
    );
  }

  return (
    <>
      <Dimmer.Dimmable dimmed={isLoading}>
        <Form
          style={{
            borderBottom: "1px solid #F2F2F2",
            //height: (props.hideReclassify?52:104) + (config.HIDE_QUEUE_FILTER_DATE || props.hideFilterDate?0:52) + "px"
          }}
        >
          {!props.hideReclassify && (
            <Form.Field width={16} style={{ padding: "0.5em", margin: 0 }}>
              <Form.Dropdown
                style={{ width: "100%" }}
                inline
                search
                selection
                options={props.triageLevelList}
                value={filterValue}
                onChange={(e, { value }) => {
                  setFilterValue(value);
                  if (value !== filterValue) {
                    history.push(getCurrentPageURL());
                  }
                }}
              />
            </Form.Field>
          )}
          {props.unsignedFilter && (
            <Form.Field width={16} style={{ padding: "0.5em 1em 0", margin: 0 }}>
              <label style={{ display: "inline-block", marginRight: "8px" }}>Filter</label>
              <div style={{ display: "inline-block" }}>
                <Form.Dropdown
                  inline
                  options={signedFilterOptions}
                  value={signedFilterValue}
                  onChange={(e, { value }) => {
                    setSignedFilterValue(value);
                  }}
                />
              </div>
            </Form.Field>
          )}
          {!props.hideFilterSearch && (
            <Form.Field width={16} style={{ padding: "0.5em", margin: 0 }}>
              <Input fluid placeholder="Search..." onChange={handleSearch} icon="search" />
            </Form.Field>
          )}
          {!props.hideFilterDate && !config.HIDE_QUEUE_FILTER_DATE && (
            <Form.Field width={16} style={{ padding: "0.5em", margin: 0 }}>
              <DateTextBox onChange={(date) => setFilterDate(date)} value={filterDate} />
            </Form.Field>
          )}
        </Form>
        <div
          className={`ChatList ${
            props.hideFilterDate || config.HIDE_QUEUE_FILTER_DATE ? "hideFilter" : ""
          }`}
        >
          {genRow()}
          {true ? (
            <></>
          ) : (
            <div className="chat-classify-list">
              <div>
                {["รุนแรง", "ปานกลาง", "น้อย", "ไม่มีอาการ", "รอการฉีก"].map((item, index) => (
                  <div className={`classify-group ${index === 0 ? " is_active" : ""}`}>
                    <div style={{ display: "grid", gridTemplateColumns: "auto 20px" }}>
                      <div>{item}</div>
                      <div
                        style={{
                          transform: "rotate(45deg)",
                          marginTop: "1px",
                        }}
                      >
                        <Icon name="pin" style={{ color: "white" }} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="items">
                {MOCK_DATA.map((item) => (
                  <div className="item">
                    <label>{item.full_name}</label>
                    <div style={{ marginTop: "5px" }}>อายุ : {item.age}</div>
                    <div>{item.classify ? `Classify : ${item.classify}` : `HN : ${item.hn}`}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading....</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    </>
  );
})

ChatList.defaultProps = {
  data: {},
  error: null,
  isLoading: false,
  onRefresh: () => {},
  onSelect: () => {},
  chatChannelId: null,
  division: null,
  onSetAppointmentId: () => {},
  hideReclassify: false,
  usePatientId: false,
  onReceiveChatList: () => {},
  notFilterDivision: false,
  hideFilterDate: false,
  unsignedOnly: true,
  unsignedFilter: false,
  hideFilterSearch: false,
  filterTriageAppByAppDate: false,
};

export default React.memo(ChatList);
