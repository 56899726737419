import React, {
  CSSProperties,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from "react";
import { Button, Divider, Icon, Input, Modal } from "semantic-ui-react";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// types
type ModLinkPackageProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: any;
  // data
  open: boolean;
  data?: Record<string, any>;
  items?: any[];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // callback
  onClose?: () => any;
};

// Const
const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

const MOD_LINK_PACKAGE = "ModLinkPackage";

const ModLinkPackage = (props: ModLinkPackageProps) => {
  const [items, setItems] = useState<any[]>([]);

  // Callback Effect
  const handleClose = useCallback(() => {
    props.setProp("invoiceItemPackageList", []);
    props.setProp(`buttonLoadCheck.${MOD_LINK_PACKAGE}_SAVE`, []);

    props.onClose?.();
  }, []);

  useEffect(() => {
    if (!!props.data?.id) {
      props.onEvent({
        message: "GetInvoiceItemPackageApply",
        params: { invoiceItemId: props.data.id, card: MOD_LINK_PACKAGE },
      });
    }
  }, [props.data?.id]);

  useEffect(() => {
    if (props.buttonLoadCheck?.[`${MOD_LINK_PACKAGE}_SAVE`] === "SUCCESS") {
      handleClose();
    }
  }, [props.buttonLoadCheck]);

  useEffect(() => {
    const data = (props.items || []).map((item) => {
      return {
        ...item,
        qty: item.quantity_self_applied,
      };
    });

    setItems([
      {
        package_name: "Package",
        quantity_total: "จำนวนทั้งหมด",
        quantity_left: "คงเหลือหลังนำมาใช้",
        header: true,
      },
      ...data,
    ]);
  }, [props.items]);

  const handleChange = useCallback(
    (index: number, item: any) => (e: any, data: any) => {
      const cloneItems = [...items];
      let value = Number(data.value);
      const quantity_left = Number(item.quantity_left);
      const quantity = Number(props.data?.quantity);

      value =
        // type number value ไม่น้อยกว่า 0
        value < 0
          ? 0
          : // ต้องน้อยกว่าจำนวนคงเหลือหลังนำมาใช้
          value > quantity_left
          ? quantity_left
          : // ต้องน้อยกว่าจำนวนที่สั่ง
          value > quantity
          ? quantity
          : value;

      (cloneItems as any)[index][data.name] = value;

      const sumQty = cloneItems.reduce(
        (result, item) => result + Number(item.qty || 0),
        0
      );

      setItems(sumQty > quantity ? items : cloneItems);
    },
    [items, props.data?.quantity]
  );

  // Handler
  const handleSave = () => {
    props.onEvent({
      message: "CreateInvoiceItemPackageApply",
      params: {
        invoiceItemId: props.data?.id,
        card: MOD_LINK_PACKAGE,
        data: {
          items: items.slice(1).map((item) => ({
            package_order_item_id: item.package_order_item_id,
            quantity_self_applied: item.qty,
          })),
        },
      },
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${MOD_LINK_PACKAGE}`, null);
        }}
        error={props.errorMessage?.[MOD_LINK_PACKAGE]}
        success={null}
      />

      <Modal open={props.open} closeOnDimmerClick={true} onClose={handleClose}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "18px 10px 3px 25px",
          }}
        >
          <div
            style={{
              color: "#0072BC",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            {props.data?.product || ""}
          </div>
          <Icon
            name="close"
            color="red"
            size="large"
            link={true}
            onClick={handleClose}
          />
        </div>

        <Divider style={{ margin: "1rem 1rem 0" }} />

        <div style={{ padding: "1rem" }}>
          {items.map((item, index) => (
            <div
              style={{
                display: "grid",
                gap: "20px",
                fontWeight: "normal",
                borderTop: item.header ? "1px dashed #CCCCCC" : "",
                borderBottom:
                  items.length - 1 === index ? "1px dashed #CCCCCC" : "",
                ...(index === 0
                  ? {
                      backgroundColor: "#D0EEF2",
                      border: "1px solid #979797",
                    }
                  : {}),
                padding: "8px 25px",
                gridTemplateColumns: "1fr 15% 15% 15% 15%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontWeight: "bold",
                  fontSize: item.header ? "1.1rem" : "",
                  alignItems: "center",
                }}
              >
                {item.package_name}
              </div>
              <div style={GridCenter}> {item.quantity_total}</div>
              <div style={GridCenter}> {item.quantity_left}</div>

              <div style={GridCenter}>
                {index === 0 ? (
                  "นำมาใช้"
                ) : !item.header ? (
                  <Input
                    value={item.qty}
                    type="number"
                    fluid={true}
                    name="qty"
                    style={{ width: "100px", opacity: 1 }}
                    onChange={handleChange(index, item)}
                  />
                ) : null}
              </div>
              <div style={GridCenter}>
                {index === 0
                  ? "คงเหลือหลังใช้"
                  : (Number(item.quantity_total) - Number(item.qty)).toFixed(2)}
              </div>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 1rem 1rem",
          }}
        >
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${MOD_LINK_PACKAGE}_SAVE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${MOD_LINK_PACKAGE}_SAVE`]
            }
            // config
            color={"green"}
            name="SAVE"
            size="medium"
            title="ยืนยัน"
            // style
            style={{ width: "7.5rem", marginRight: "0.5rem" }}
          />
          <Button
            color="red"
            // style
            style={{ width: "7.5rem" }}
            onClick={handleClose}
          >
            ปิด
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ModLinkPackage);
