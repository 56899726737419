import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import SubSystemicReviewItem from "./SubSystemicReviewItem";
import SubAddButton from "../common/SubAddButton";
import SubIVSelection from "../../../_qmljs/DPI/SubIVSelection";
import SubIVSelected from "./SubIVSelected";
import {
  Modal,
  Button,
  Form,
  Label,
  Segment,
  TextArea,
  Icon,
} from "semantic-ui-react";
import _ from "lodash";

// import { } from "semantic-ui-react";

const defaultPatientData = {
  patient_id: null,
  EMR: { emr_id: null },
};

const SubIV = React.forwardRef((props, ref) => {
  const [subIVSelectOpen, setSubIVSelectOpen] = useState(false);
  const [patientData, setPatientData] = useState(defaultPatientData);
  const [selectedData, setSelectedData] = useState([]);
  const [investigationDetail, setInvestigationDetail] = useState("");

  const { titleCard, isReadOnly, patientId, emrId, PATIENT_DATA, version } =
    props;

  const focusRef = useRef(null);
  const subSelectRef = useRef(null);

  const requestFocus = () => {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        focusRef.current.scrollIntoView({ behaviour: "smooth" });
      }, 1500);
    });
  };

  React.useImperativeHandle(ref, () => ({
    getSaveData: () => {
      return { items: selectedData, investigation_detail: investigationDetail };
    },
    setData: (data) => {
      setSelectedData(data.items);
      setInvestigationDetail(data.investigation_detail);
    },
  }));

  const handleOpenSelection = () => {
    setSubIVSelectOpen(!subIVSelectOpen);

    new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log(subSelectRef);
        subSelectRef.current.qml.clear();
        subSelectRef.current.qml.filterAndShow();
      }, 1500);
    });

    // const newComponent = defaultSecondary(default_diagnosis);
    // setSecondaryComponent([...secondaryComponent, newComponent]);
  };

  const handleAddIV = (newItem) => {
    console.log("add:", newItem);
    console.log(selectedData);
    // merge without duplicate
    var ids = new Set(selectedData.map((x) => x.id));
    var lists = [...selectedData, ...newItem.filter((x) => !ids.has(x.id))];
    setSelectedData(lists);
  };

  const handleDeleteIV = (item) => {
    console.log("delete:", item);
    console.log(selectedData);
    var lists = selectedData.filter((x) => {
      return x.id != item.id;
    });
    setSelectedData(lists);
  };

  return (
    <Segment raised secondary>
      <div ref={focusRef} />
      <Label color="grey" ribbon size="big">
        {titleCard}
      </Label>

      {selectedData.map((item, subIndex) => {
        const subContent = (
          <SubIVSelected item={item} onDelete={handleDeleteIV} />
        );
        return subContent;
      })}

      {version === 1 ? (
        <SubAddButton
          header="เพิ่ม Initial Investigation"
          onClicked={handleOpenSelection}
        />
      ) : (
        // <Form.Group inline>
        <Form.Field width={16} style={{ margin: "20px 0px" }}>
          <TextArea
            name="investigation_detail"
            style={{ resize: "none", width: "100%", padding: "10px" }}
            rows={2}
            readOnly={isReadOnly}
            placeholder={"Investigation Results"}
            onChange={(e) => {
              setInvestigationDetail(e.target.value);
            }}
            value={investigationDetail}
          />
        </Form.Field>
        // {/* </Form.Group> */}
      )}

      <Form.Group inline>
        <Form.Field width={16}>
          <Button color="green" type="button" onClick={props.onSaveAllCallback}>
            {props.saveText ? <Icon className="check"></Icon> : "บันทึก"}
          </Button>
        </Form.Field>
      </Form.Group>

      <Modal
        open={subIVSelectOpen}
        size="large"
        onClose={() => {
          setSubIVSelectOpen(false);
        }}
        closeIcon
      >
        <Modal.Content>
          <SubIVSelection
            ref={subSelectRef}
            inModal={true}
            PATIENT_DATA={PATIENT_DATA}
            onSelected={async (items) => {
              console.log(items);
              handleAddIV(items);
              setSubIVSelectOpen(false);
            }}
          />
        </Modal.Content>
      </Modal>
    </Segment>

    // Modal {
    //   id: modSelect
    //   className: 'large'
    //   SubIVSelection {
    //       id: subSelect
    //       inModal: true
    //       onSelected: {
    //           rptSelected.setItems(items)
    //           modSelect.hide()
    //       }
    //   }
    // }
  );
});

SubIV.defaultProps = {
  titleCard: "Initial Investigations",

  onSaveAllCallback: () => {},
  version: 2,
  isReadOnly: false,
};

SubIV.propTypes = {
  onSaveAllCallback: PropTypes.func,
  patientId: PropTypes.number,
  emrId: PropTypes.number,
  PATIENT_DATA: PropTypes.object,
  isReadOnly: PropTypes.bool,
  version: PropTypes.number,
};

export default React.memo(SubIV);
