import React from "react";
import { Icon, Modal } from "semantic-ui-react";

const ModalSwallowingRemark = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      closeOnDimmerClick={true}
      size="small"
    >
      <Modal.Content>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: "100%", textAlign: "end" }}>
            <Icon
              name="times"
              color="red"
              onClick={() => {
                props.handleClose();
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            Remark : Put the number of score screening test in the box, test 2
            times use the lowest score as a result
            <br />
            <br />
            score 1 = 1 : No swallow
            <br />
            score 2 = 2 : Swallow with frequent breathing
            <br />
            score 3 = 3 : Swallow with normal breath, cough and/or wet-hoarse
            voice
            <br />
            score 4 = 4 : Swallow with normal breath, no cough and/or wet-hoarse
            voice
            <br />
            score 5 = 5 : If score 4 + 2 additional swallow in 30 seconds
            <br />
            <br />
            (ที่มา :
            การประชุมการพัฒนาตัวชี้วัดทางด้านการกลืนและกำหนดแนวทางการวิจัย,
            พหุสถาบัน)
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ModalSwallowingRemark;
