import React, { useState, useEffect, useRef, useImperativeHandle } from "react";

const TabPenta = React.forwardRef<any, any>((props, forwardedRef) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showTab, setShowTab] = useState(false);
  const activeItem = useRef<HTMLDivElement | null>(null);

  // console.log('TabPenta selectedTabIndex: ', selectedTabIndex);
  // console.log('TabPenta props.selectedTabIndex: ', props.selectedTabIndex);
  useImperativeHandle(forwardedRef, () => ({
    changeTab(idx: any) {
      setSelectedTabIndex(idx);
      props.onChangeSelectedTabName?.(props?.data[idx]?.name, idx);
    },
    changeTabKey(toKey: any) {
      let idx = (props.data || []).findIndex((i: any) => i.key === toKey)
      if (idx !== -1) {
        console.log('setSelectedTabIndex: ', idx);
        setSelectedTabIndex(idx)
        props.onChangeSelectedTabName?.(props?.data[idx]?.name, idx);
      }
    }
  }));

  useEffect(() => {
    if (Number.isInteger(props.selectedTabIndex)) {
      console.log(" 1 ", props.selectedTabIndex)
      setSelectedTabIndex(props.selectedTabIndex);
      props.onChangeSelectedTabName?.(props?.data[props.selectedTabIndex]?.name, props.selectedTabIndex);
    } else if (props.history && props.history.location.pathname !== "/") {
      try {
        const keys = props.history.location.pathname.split("/");
        console.log(props.history.location.pathname);
        console.log(keys, keys.length, props.level);
        if (keys.length > props.level) {
          const key = keys[props.level];
          const index = props.data?.map((item: any) => item.key).indexOf(key);
          console.log(" 2 ", index)
          setSelectedTabIndex(index);
          props.onChangeSelectedTabName?.(props?.data[index]?.name, index);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(() => {
    // console.log('useEffect: rops.selectedTabIndex ', props.selectedTabIndex);
    // console.log('useEffect: rops.selectedTabIndex ', typeof props.selectedTabIndex);
    if (Number.isInteger(props.selectedTabIndex)) {
      // console.log(" 3 selectedTabIndex: ", selectedTabIndex)
      // console.log(" 3 props.selectedTabIndex: ", props.selectedTabIndex)
      setSelectedTabIndex(props.selectedTabIndex);
    }
  }, [props.selectedTabIndex]);

  // console.log("TabPenta selectedTabIndex", selectedTabIndex)
  return (
    <div
      id="TabPenta"
      style={{
        display: "flex",
        flexDirection: props.mobileScreen ? "column" : "row",
        ...(props.mobileScreen ? { overflow: "scroll" } : { height: "100%" }),
        width: "100%",
      }}
    >
      <div
        style={{
          width: props.mobileScreen ? "100%" : props.tabWidth || "250px",
          // maxHeight: "95vh",
          display: "flex",
          flexDirection: "column",
          border: "solid #cccccc 1px",
          ...(props.style || {}),
        }}
      >
        {(props?.topTab ? [props.topTab] : []).concat(
          props?.data.map(
            (item: any, index: number) =>
              (!props.mobileScreen ||
                showTab ||
                (!showTab && selectedTabIndex === index)) && (
                <div
                  id={`tab-${item.key}`}
                  key={index}
                  style={{
                    ...(props.data[selectedTabIndex]?.key === item.key
                      ? {
                          backgroundColor: "#d6ecf3",
                          ...(props.activeStyle || {}),
                        }
                      : { backgroundColor: "white" }),
                    padding: props.padding || "5px",
                    cursor: "pointer",
                    ...(props.itemStyle || {}),
                  }}
                  className={props.data[selectedTabIndex]?.key === item.key ? "active" : ""}
                  ref={
                    props.data[selectedTabIndex]?.key === item.key
                      ? activeItem
                      : null
                  }
                  onClick={(e) => {
                    if (props.disabled) {
                      return;
                    }

                    if (props.mobileScreen) {
                      setShowTab(!showTab);
                    }

                    setSelectedTabIndex(index);
                    props.onChangeSelectedTabName?.(props?.data[index]?.name, index);

                    if (props.history && props.data) {
                      try {
                        let keys = props.history.location.pathname.split("/");
                        if (keys.length > props.level) {
                          keys[props.level] = props.data?.[index].key;
                          keys.splice(props.level + 1);
                          props.history.push(
                            keys.join("/") + window.location.search
                          );
                        } else if (keys.length == props.level) {
                          keys.push(props.data?.[index].key);
                          props.history.push(
                            keys.join("/") + window.location.search
                          );
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }
                    props?.onTabChange?.(index);
                  }}
                >
                  <span
                    style={{
                      ...(props.data[selectedTabIndex]?.key === item.key
                        ? { color: "black", ...(props.activeItemStyle || {}) }
                        : { color: "var(--primary-theme-color-dark)" }),
                    }}
                  >
                    {item.name}
                  </span>
                </div>
              )
          )
        )}
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        {props?.data[selectedTabIndex]?.render() || ""}
      </div>
    </div>
  );
});

TabPenta.displayName = "TabPenta";
export default TabPenta;
