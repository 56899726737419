import JsBarcode from "jsbarcode";
import moment from "moment";
import { beToAd, formatDate } from "react-lib/utils/dateUtils";
import {
  HeaderLogoWithTitleContent,
  HeaderLogoWithAddress,
} from "../HISV3/common/HeaderPdfFormTemplate";
import CONFIG from "config/config";

const FORM_NAME = "FormAppointmentDetail";

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

const FormAppointmentDetail = async (props: any) => {
  console.log(props.data);

  // issue 65040 Header CU กับ Sapiens ใช้ไม่เหมือนกัน
  const headerForm =
    CONFIG.COMPANY === "SAPIENS"
      ? await HeaderLogoWithAddress({
          form: FORM_NAME,
          font: "THSarabunNew",
          logoHeight: 30,
          pageMargins: [10, 110, 10, CONFIG.HIDE_APPOINTMENT_BARCODE ? 25 : 55],
          headerMargins: [10, 5, 10, 5],
          styles: {
            facultyHeader: {
              fontSize: 20,
              bold: true,
            },
            fieldKey: {
              bold: true,
              fontSize: 15,
            },
            fieldValue: {
              fontSize: 15,
            },
            miniFieldKey: {
              bold: true,
              fontSize: 12.5,
            },
            miniFieldValue: {
              fontSize: 13,
            },
          },
          titleContent: [{ text: "ใบนัดหมาย", style: "facultyHeader", alignment: "center" }],
        })
      : await HeaderLogoWithTitleContent({
          form: FORM_NAME,
          font: "THSarabunNew",
          logoHeight: 40,
          logoPosition: { x: 10, y: 8 },
          pageMargins: [10, 60, 10, 55],
          titleMargins: [80, 3, 0, 0],
          headerMargins: [10, 5, 10, 5],
          styles: {
            facultyHeader: {
              fontSize: 20,
              bold: true,
            },
            fieldKey: {
              bold: true,
              fontSize: 15,
            },
            fieldValue: {
              fontSize: 15,
            },
            miniFieldKey: {
              bold: true,
              fontSize: 12.5,
            },
            miniFieldValue: {
              fontSize: 13,
            },
          },
          titleName: `โรงพยาบาลคณะทันตแพทย์ศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย`,
          titleContent: [{ text: "ใบนัดหมาย", style: "facultyHeader", alignment: "center" }],
        });
  const { font, images, styles } = headerForm;

  const doctorData = () => {
    let doctorName = [
      { width: "14.5%", text: "แพทย์", style: "fieldKey" },
      { width: "4%", text: ":", style: "fieldValue" },
      {
        width: "42%",
        text: props.data?.display_info?.provider_name || "",
        style: "fieldValue",
      },
    ];

    if (CONFIG.SHOW_DOCTOR_CHAIR) {
      doctorName.push(
        {
          width: "8%",
          text: "เก้าอี้",
          style: "fieldKey",
        },
        { width: "4%", text: ":", style: "fieldValue" },
        {
          width: "21%",
          text: props.data?.extra?.chair || "",
          style: "fieldValue",
        }
      );
    }

    return doctorName;
  };

  const showDoctorNote = () => {
    let doctorNote = {
      margin: [0, 5, 0, 0],
      stack: [
        { text: "คำแนะนำ", style: "fieldKey" },
        {
          margin: [5.5, 0, 0, 0],
          ul: (props.data.doctor_note || []).map((note: string) => ({
            text: note,
            style: "miniFieldValue",
          })),
        },
      ],
    };

    if (CONFIG.SHOW_DOCTOR_CHAIR) {
      return [
        {
          margin: [0, 5, 0, 0],
          columns: [
            { width: "14.5%", text: "นัดเพื่อ", style: "fieldKey" },
            { width: "4%", text: ":", style: "fieldValue" },
            {
              width: "42%",
              text: props.data?.extra?.appointmentFor || "-",
              style: "fieldValue",
            },
          ],
        },
        doctorNote,
      ];
    } else {
      return [doctorNote];
    }
  };

  const contentId = () => {
    let content = [
      { width: "14.5%", text: "เลขที่นัด", style: "fieldKey" },
      { width: "4%", text: ":", style: "fieldValue" },
      {
        width: "auto",
        text: `P01${props.data?.content_id || ""}`,
        style: "fieldValue",
      },
    ];

    if (CONFIG.SHOW_DOCTOR_CHAIR) {
      content.push({
        margin: [20, 0, 0, 0],
        image: textToBase64BarcodeBlob(`P01${props.data?.content_id || ""}`),
        width: "40",
        height: 17.5,
      });
    }

    return content;
  };

  return {
    pageSize: "A5",
    pageOrientation: "landscape",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 13,
    },
    ...styles,
    ...headerForm,
    // header: {
    //   margin: [10, 5, 10, 5],
    //   stack: [
    //     {
    //       columns: [
    //         ...(!CONFIG.HIDE_COMPANY_LOGO_PRINT ? [{ margin: [0, 0, 0, 0], width: 60, image: "logo", alignment: "left" }] : [] ),
    //         {
    //           margin: [-10, 5, 0, 0],
    //           width: "*",
    //           stack: [
    //             !CONFIG.HIDE_COMPANY_LOGO_PRINT ? {
    //               text: "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY",
    //               style: "facultyHeader",
    //               alignment: "center",
    //             } : null,
    //             { text: "ใบนัดหมาย", style: "facultyHeader", alignment: "center" },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
    content: [
      {
        table: {
          widths: ["100%"],
          body: [
            [
              {
                margin: [20, 3, 10, 3],
                stack: [
                  {
                    columns: [
                      { width: "14.5%", text: "คลินิก", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      { width: "*", text: props.data?.division_name, style: "fieldValue" },
                      // {
                      //   width: "21%",
                      //   text: "โทร 02-2188733",
                      //   style: "fieldValue",
                      //   alignment: "right",
                      // },
                    ],
                  },
                  {
                    columns: [
                      { width: "14.5%", text: "ชื่อ-นามสกุล", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      { width: "42%", text: props.data?.full_name || "", style: "fieldValue" },
                    ],
                  },
                  {
                    columns: [
                      { width: "14.5%", text: "HN", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      { width: "42%", text: props.data?.patient_hn || "", style: "fieldValue" },
                      { width: "14.5%", text: "อายุ", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue", alignment: "right" },
                      {
                        width: "21%",
                        text: props.data?.full_age || "",
                        style: "fieldValue",
                        alignment: "right",
                      },
                    ],
                  },
                  {
                    columns: contentId(),
                  },
                  {
                    margin: [0, 5, 0, 0],
                    columns: [
                      { width: "14.5%", text: "วันที่นัด", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      {
                        width: "42%",
                        text: props.data?.date
                          ? `${beToAd(props.data.date)
                              ?.clone()
                              .locale("th")
                              ?.format("วันdddd ที่ DD MMMM")} ${moment(
                              props.data.date,
                              "DD/MM/YYYY"
                            ).format("YYYY")}`
                          : "",
                        style: "fieldValue",
                      },
                      { width: "8%", text: "เวลานัด", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      {
                        width: "21%",
                        text: props.data.appointment_time
                          ? `${props.data.appointment_time} น.`
                          : `${props.data.start || ""} - ${props.data.end || ""} น.`,
                        style: "fieldValue",
                      },
                    ],
                  },
                  {
                    margin: [0, 5, 0, 0],
                    columns: doctorData(),
                  },
                  ...showDoctorNote(),
                  // {
                  //   margin: [0, 5, 0, 0],
                  //   columns: [
                  //     { width: "14.5%", text: "นัดเพื่อ", style: "fieldKey" },
                  //     { width: "4%", text: ":", style: "fieldValue" },
                  //     {
                  //       width: "42%",
                  //       text: props.data?.extra?.appointmentFor || "-",
                  //       style: "fieldValue",
                  //     },
                  //   ],
                  // },
                  // {
                  //   margin: [0, 5, 0, 0],
                  //   stack: [
                  //     { text: "คำแนะนำ", style: "fieldKey" },
                  //     {
                  //       margin: [5.5, 0, 0, 0],
                  //       ul: (props.data.doctor_note || []).map((note: string) => ({
                  //         text: note,
                  //         style: "miniFieldValue",
                  //       })),
                  //     },
                  //   ],
                  // },
                ],
              },
            ],
            // [
            // {
            //   margin: [0, 3, 0, 3],
            //   stack: [
            //     {
            //       columns: [
            //         { width: "46.5%", text: "เอกสารและขั้นตอนการใช้สิทธิข้าราชการของผู้ป่วย", style: "miniFieldKey" },
            //         { width: "auto", text: "หมายเหตุ", style: "miniFieldKey" },
            //       ],
            //     },
            //     {
            //       columns: [
            //         { width: "46.5%", text: "สิทธิเบิกจ่ายตรง", style: "miniFieldValue" },
            //         { width: "auto", text: "ปัญหาที่เกิดขึ้นเกี่ยวกับสิทธิราชการคือ", style: "miniFieldValue" },
            //       ],
            //     },
            //     {
            //       columns: [
            //         {
            //           // to treat a paragraph as a bulleted list, set an array of items under the ul key
            //           width: "46.5%",
            //           margin: [2.5, 0, 0, 0],
            //           ul: [
            //             {
            //               stack: [
            //                 { text: "ผู้ป่วยใหม่-เก่า ติดต่อประชาสัมพันธ์เพื่อแจ้งสิทธิ", style: "miniFieldValue" },
            //                 { text: "พร้อมบัตรประชาชนก่อนพบทันตแพทย์", style: "miniFieldValue" },
            //               ],
            //             },
            //             { text: "ผู้ป่วยใหม่-เก่า นำบัตรประชาชนมาด้วยทุกครั้งที่ต้องชำระเงิน", style: "miniFieldValue" },
            //           ],
            //         },
            //         {
            //           width: "auto",
            //           margin: [2.5, 0, 0, 0],
            //           ul: [
            //             { text: "สิทธิหมดอายุ", style: "miniFieldValue" },
            //             { text: "สิทธิถูกยกเลิก", style: "miniFieldValue" },
            //             { text: "บัตรประชาชนหมดอายุ", style: "miniFieldValue" },
            //             { text: "ผู้ป่วยลืมบัตรประชาชน", style: "miniFieldValue" },
            //           ],
            //         },
            //       ],
            //     },
            //   ],
            // },
            //   {
            //     margin: [20, 3, 0, 3],
            //     stack: [
            //       { text: "คำแนะนำ", style: "fieldKey" },
            //       {
            //         margin: [5.5, 0, 0, 0],
            //         ul: (props.data.doctor_note || []).map((note: string) => ({
            //           text: note,
            //           style: "miniFieldValue",
            //         })),
            //       },
            //     ],
            //   },
            // ],
          ],
        },
      },
    ],
    footer: {
      columns: [
        ...(CONFIG.HIDE_APPOINTMENT_BARCODE
          ? [
            {
              margin: [20, 2.5, 0, 0],
              text: [
                { text: `วัน-เวลาที่พิมพ์ ${formatDate(moment())} `, style: "miniFieldValue" },
                { text: moment().format("HH:mm"), style: "miniFieldValue" },
                { text: `  ผู้ทำนัดหมาย ${props.data?.staff || ""}`, style: "miniFieldValue" },
              ],
            },
            ]
          : [
              {
                width: 80,
                margin: [20, 2.5, 0, 0],
                stack: [
                  {
                    image: textToBase64BarcodeBlob(props.data?.patient_hn),
                    width: 80,
                    height: 35,
                  },
                  {
                    margin: [20, -2.5, 0, 0],
                    text: "HN",
                    style: "fieldValue",
                    alignment: "center",
                    bold: true,
                  },
                ],
              },
              {
                width: "40%",
                margin: [40, 4, 0, 0],
                stack: [
                  {
                    text: [
                      { text: `วัน-เวลาที่พิมพ์ ${formatDate(moment())} `, style: "miniFieldValue" },
                      { text: moment().format("HH:mm"), style: "miniFieldValue" },
                    ],
                  },
                  { text: `ผู้ทำนัดหมาย ${props.data?.staff || ""}`, style: "miniFieldValue" },
                ],
              },
            ]),
        // {
        //   width: "*",
        //   margin: [40, 21, 0, 0],
        //   stack: [
        //     { text: "เลื่อนนัดกรุณาติดต่อในวันและเวลาราชการเท่านั้น", style: "miniFieldValue" },
        //     {
        //       text: "สอบถามค่ารักษาพยาบาล, นัดหมาย, ตอบแบบสอบถาม ถามทางไลน์",
        //       style: "miniFieldValue",
        //     },
        //   ],
        // },
        // {
        //   margin: [-50, -4, 0, 0],
        //   width: 40,
        //   stack: [
        //     { text: "ไลน์นัดหมาย", style: "miniFieldValue", alignment: "center" },
        //     {
        //       margin: [28, -5, 0, 0],
        //       image: "qrcode_line",
        //       width: 32.5,
        //       height: 32.5,
        //     },
        //   ],
        // },
      ],
    },
    images: {
      ...images,
      qrcode_line: origin + "/static/images/qrcode_appointment_line.png",
    },
  };
};

export default FormAppointmentDetail;
