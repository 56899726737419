import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Icon, Input } from "semantic-ui-react";

// Types
type CardTransferListProps = {
  textKey: "name" | "text";
  valueKey: "id" | "value";
  list: ListType[];
  selected: (string | number)[];
  // callback
  onSelected?: (selected: (string | number)[]) => any;
};

type ListType = {
  name?: string;
  text?: string;
  id?: number;
  value?: string;
  [key: string]: any;
};

const CardTransferList = (props: CardTransferListProps) => {
  const [checkedLeft, setCheckedLeft] = useState<number[]>([]);
  const [checkedRight, setCheckedRight] = useState<number[]>([]);

  const list = useMemo(() => {
    return (props.list || []).map((item, index) => ({ ...item, index }));
  }, [props.list]);

  const leftList = useMemo(() => {
    return list.filter(
      (item) => !props.selected.includes(item[props.valueKey] || "")
    );
  }, [list, props.selected, props.valueKey]);

  const rightList = useMemo(() => {
    return list.filter((item) =>
      props.selected.includes(item[props.valueKey] || "")
    );
  }, [list, props.selected, props.valueKey]);

  const handleTransfer = (action: "right" | "left") => {
    if (action === "right") {
      props.onSelected?.([
        ...props.selected,
        ...list.flatMap((item, index) =>
          checkedLeft.includes(index) ? [item[props.valueKey] || ""] : []
        ),
      ]);
    } else if (action === "left") {
      const checked = list.flatMap((item, index) =>
        checkedRight.includes(index) ? [item[props.valueKey] || ""] : []
      );

      props.onSelected?.(props.selected.filter((id) => !checked.includes(id)));
    }

    setCheckedLeft([]);
    setCheckedRight([]);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ListBox
        list={leftList}
        textKey={props.textKey}
        valueKey={props.valueKey}
        checked={checkedLeft}
        // callback
        onChecked={setCheckedLeft}
      />
      <div style={{ padding: "0 1rem" }}>
        <TransferIcon
          action="right"
          disabled={!checkedLeft.length}
          onClickTransfer={handleTransfer}
        />
        <TransferIcon
          action="left"
          disabled={!checkedRight.length}
          onClickTransfer={handleTransfer}
        />
      </div>
      <ListBox
        list={rightList}
        textKey={props.textKey}
        valueKey={props.valueKey}
        checked={checkedRight}
        // config
        hideSearch={true}
        // callback
        onChecked={setCheckedRight}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                       TransferIco                      */

/* ------------------------------------------------------ */
type TransferIconProps = {
  action: "right" | "left";
  disabled?: boolean;
  // callback
  onClickTransfer: (action: "right" | "left") => any;
};

const TransferIcon = (props: TransferIconProps) => {
  const handleClick = () => {
    if (!props.disabled) {
      props.onClickTransfer(props.action);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        height: "50%",
        marginBottom: "10px",
        opacity: props.disabled ? "0.5" : "",
        cursor: props.disabled ? "" : "pointer",
      }}
    >
      <Icon
        name={`arrow circle ${props.action}`}
        size="large"
        onClick={handleClick}
      ></Icon>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                        ListBox;                        */

/* ------------------------------------------------------ */
type ListBoxProps = {
  list: (ListType & { index: number })[];
  checked: number[];
  // config
  hideSearch?: boolean;
  // callback
  onChecked: (checked: number[]) => any;
} & Pick<CardTransferListProps, "textKey" | "valueKey">;

const ListBox = (props: ListBoxProps) => {
  const [currentChecked, setCurrentChecked] = useState<number>(-1);

  const [list, setList] = useState<ListType[]>([]);

  const [search, setSearch] = useState<string>("");

  const currentKeyPressRef = useRef<string | null>(null);

  useEffect(() => {
    setList(
      props.list.filter((item) =>
        item[props.textKey as any].toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [props.list, search]);

  // Callback Effect
  const handleKeyup = useCallback((ev) => {
    currentKeyPressRef.current = "";
  }, []);

  const handleKeydown = useCallback((ev) => {
    currentKeyPressRef.current = ev.key;
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyup);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyup);
    };
  }, []);

  const handleSearch = (e: any, data: any) => {
    props.onChecked([]);

    setSearch(data.value);
  };

  const handleSelected = (index: number) => {
    const checked = props.checked;

    if (currentKeyPressRef.current === "Control") {
      let checkedIndexes = [...checked];

      if (checked.includes(index)) {
        checkedIndexes = checkedIndexes.filter(
          (checkedId) => checkedId !== index
        );
      } else {
        checkedIndexes.push(index);
      }

      setCurrentChecked(index);
      props.onChecked(checkedIndexes);
    } else if (currentKeyPressRef.current === "Shift" && checked.length) {
      props.onChecked(
        Array(Math.abs(currentChecked - index) + 1)
          .fill("")
          .map((_, idx) => Math.min(currentChecked, index) + idx)
      );
    } else {
      props.onChecked(
        checked.length === 1 && checked.includes(index) ? [] : [index]
      );
      setCurrentChecked(index);
    }
  };

  return (
    <div
      style={{
        border: "1px solid rgba(34, 36, 38, 0.25)",
        borderRadius: "15px",
        height: "180px",
        width: "235px",
        backgroundColor: "white",
      }}
    >
      <div style={{ margin: "10px" }}>
        {!props.hideSearch && (
          <div style={{ display: "flex", width: "100%" }}>
            <Input
              placeholder="Filter"
              icon="search"
              value={search}
              fluid={true}
              style={{ width: "100%" }}
              onChange={handleSearch}
            ></Input>
          </div>
        )}
        <div
          className="box-list"
          style={{
            marginTop: "5px",
            overflow: "auto",
            paddingBottom: "10px",
            height: props.hideSearch ? "168px" : "125px",
          }}
        >
          {list.map((item, index) => (
            <div
              key={"list" + item.index}
              aria-hidden="true"
              style={{
                backgroundColor: props.checked.includes(item.index)
                  ? "#C6EBF3"
                  : "",
                padding: "1px 3px",
              }}
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                handleSelected(item.index);
              }}
            >
              {item[props.textKey]}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CardTransferList);
