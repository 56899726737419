import React, {
  MutableRefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button, Modal, Popup } from "semantic-ui-react";

import moment from "moment";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

import { PreventClickEvent } from "../ORM/CardORRequest";

import CardMedicationErrorWorkingUX from "./CardMedicationErrorWorkingUX";
import ModSelectQuarter from "./ModSelectQuarter";

// Interface
import {
  DIVISION_SEARCH_MEWS,
  MED_ERROR_CATEGORY,
  RunSequence,
  State,
  USER_SEARCH_MEW,
  USER_SEARCH_MEWS,
} from "./sequence/MedErrorList";

// Utils
import { beToAd, formatDate, formatDatetime } from "react-lib/utils/dateUtils";

// Types
type CardMedicationErrorWorkingProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // seq
  runSequence: RunSequence;
  MedErrorListSequence: State["MedErrorListSequence"];
  // data
  medErrId?: number;
  defaultStakeholder?: Record<string, any>;
  drugName?: string;
  drugCode?: string;
  title?: string;
  // options
  masterOptions?: Record<string, any>;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  selectedDivision?: Record<string, any>;
  // config
  readOnly?: boolean;
  isOrderItem?: boolean;
  isActionComplete?: boolean;
  showSolution?: boolean;
  editMode?: boolean;
  disabledEdit?: boolean;
  hideEditQuarter?: boolean;
  // callback
  onEdited?: () => any;
};

const CardMedicationErrorWorking = (props: CardMedicationErrorWorkingProps) => {
  // Mod
  const [openModInfo, setOpenModInfo] = useState<any>(null);
  const [openModSelectQuarter, setOpenModSelectQuarter] = useState(false);
  const [stakeholderId, setStakeholderId] = useState<any>(null);

  const medErrorRef = useRef() as MutableRefObject<HTMLDivElement>;

  // Callback
  const handleSetCorrectiveSearchItems = useCallback((detail: any) => {
    const isClinic = detail.editor_by === "CLINIC";
    const searchKey = isClinic ? DIVISION_SEARCH_MEWS : USER_SEARCH_MEWS;

    const searchItems = [
      {
        id: detail.corrective_user || detail.corrective_division,
        full_name: detail.corrective_user_name || detail.corrective_division_name,
      },
    ];

    if (detail.editor_by) {
      props.setProp(`searchedItemListWithKey.${searchKey}`, searchItems);
    }
  }, []);

  // Memo Effect
  const medErrorWorking = useMemo(() => {
    return props.MedErrorListSequence?.medErrorWorking;
  }, [props.MedErrorListSequence?.medErrorWorking]);

  // Effect
  useEffect(() => {
    PreventClickEvent(!props.readOnly, medErrorRef.current);
  }, [props.readOnly, medErrorRef.current]);

  useEffect(() => {
    if (props.medErrId) {
      props.runSequence({
        sequence: "MedErrorList",
        restart: props.isOrderItem,
        id: props.medErrId,
        isOrderItem: props.isOrderItem,
        nextIndex: "EditMedError",
      });
    }
  }, [props.medErrId]);

  useEffect(() => {
    const user = medErrorWorking?.user;

    if (!user) {
      // * update เมื่อ medErrorWorking set ค่าเริ่มต้นแล้ว
      return;
    }

    const update = async () => {
      if (user?.id) {
        setStakeholderId(user.id);

        const searchItems = [{ id: user.id, full_name: user.full_name_code }];

        await props.setProp(`searchedItemListWithKey.${USER_SEARCH_MEW}`, searchItems);

        handleSetCorrectiveSearchItems(detail);
      } else if (props.defaultStakeholder?.id) {
        const id = props.defaultStakeholder.id;

        setStakeholderId(id);

        const searchItems = [{ id, full_name: props.defaultStakeholder.name }];

        props.setProp(`searchedItemListWithKey.${USER_SEARCH_MEW}`, searchItems);

        // Initial Date
        handleChangeDate("risk_date")(formatDate(moment()));
      }
    };

    update();
  }, [medErrorWorking?.user, props.defaultStakeholder?.id]);

  const mapOptions = (list: any[], valueKey = "id") => {
    return list.map((item: any) => ({
      key: item.id,
      value: item[valueKey],
      text: item.name,
    }));
  };

  const mapUserOptions = useCallback((items: any[]) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name ?? item.name,
    }));
  }, []);

  const handleSelectedItem = useCallback(
    async (value: any, key: any) => {
      const detail = medErrorWorking?.detail;
      const isClinic = detail.editor_by === "CLINIC";
      const searchKey = isClinic ? DIVISION_SEARCH_MEWS : USER_SEARCH_MEWS;

      const items: any[] = props.searchedItemListWithKey?.[searchKey] || [];

      const search = items.find((item: any) => item.id === key);

      handleChange(null, {
        name: "corrective",
        value: search?.id ? { id: search.id, name: search.full_name ?? search.name } : null,
        suffix: isClinic ? "division" : "user",
      });
    },
    [props.searchedItemListWithKey, medErrorWorking?.detail]
  );

  const handleSelectedStakeholder = useCallback((value: any) => {
    setStakeholderId(value || null);
  }, []);

  const typeOptions = useMemo(() => {
    return mapOptions(medErrorWorking?.typeList || []);
  }, [medErrorWorking?.typeList]);

  const causeOptions = useMemo(() => {
    return mapOptions(medErrorWorking?.causeList || [], "name");
  }, [medErrorWorking?.causeList]);

  const detail = useMemo(() => {
    return medErrorWorking?.detail || {};
  }, [medErrorWorking?.detail]);

  const categoryMessage = useMemo(() => {
    return (
      <>
        {MED_ERROR_CATEGORY.map((text) => (
          <div key={text}>{text}</div>
        ))}
      </>
    );
  }, []);

  const quarterDetail = useMemo(() => {
    return {
      quarter: detail.quarter_no,
      year: detail.quarter_year,
      startDate: detail.quarter_start_date ? formatDate(moment(detail.quarter_start_date)) : "",
      endDate: detail.quarter_end_date ? formatDate(moment(detail.quarter_end_date)) : "",
    };
  }, [detail]);

  const incidentDate = useMemo(() => {
    return detail.request_time ? formatDatetime(moment(detail.request_time), true) : "";
  }, [detail.request_time]);

  const handleChange = (e: any, v: any) => {
    const data: any = {};
    switch (v.name) {
      case "type": {
        props.runSequence({
          sequence: "MedErrorList",
          action: "change_type",
          type: v.value,
        });

        data.type_detail = [];

        break;
      }
      case "cause": {
        props.runSequence({
          sequence: "MedErrorList",
          action: "change_cause",
          cause: v.value,
        });

        break;
      }
      case "editor_by": {
        data.corrective_user = null;
        data.corrective_user_name = "";

        break;
      }
      // No default
    }

    data[v.name] = v.value;

    if (v.name === "editor_by" && props.selectedDivision?.id) {
      const division = v.value === "CLINIC" ? props.selectedDivision : {};

      data.corrective_division = division.id || null;
      data.corrective_division_name = division.name || "";
    }

    if (data.corrective_division) {
      props.setProp(`searchedItemListWithKey.${DIVISION_SEARCH_MEWS}`, [
        { id: data.corrective_division, name: data.corrective_division_name },
      ]);
    }

    props.runSequence({
      sequence: "MedErrorList",
      action: "update",
      detail: {
        ...detail,
        ...data,
        ...(v.name === "corrective"
          ? {
              [`corrective_${v.suffix}`]: v.value?.id || null,
              [`corrective_${v.suffix}_name`]: v.value?.name || null,
            }
          : {}),
      },
    });
  };

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    const list = (
      {
        type_detail: "typeDetailOptions",
        cause_detail: "causeDetailOptions",
      } as any
    )[v.name];

    const arrayUniqueByKey = (items: any[]) => {
      return [...new Map(items.map((item) => [item["value"], item])).values()];
    };

    props.runSequence({
      sequence: "MedErrorList",
      action: "update",
      [list]: arrayUniqueByKey([
        { key: v.value, text: v.value, value: v.value },
        ...(medErrorWorking?.[list] || []),
      ]),
      detail: {
        ...detail,
        [v.name]: [...new Set([...detail[v.name], v.value])],
      },
    });
  };

  const handleEdit = () => {
    const division = detail.corrective_division;

    props.runSequence({
      sequence: "MedErrorList",
      action: "edit",
      data: {
        ...medErrorWorking?.detail,
        drug_name: detail.drug_name || props.drugName,
        drug_code: detail.drug_code || props.drugCode,
        order_item: detail.order_item || props.medErrId,
        stakeholder: stakeholderId,
        corrective: detail.editor_by === "CLINIC" ? division : detail.corrective_user,
      },
      isActionComplete: props.isActionComplete,
      callback: (status: string, message: any) => {
        setOpenModInfo({ status, message });
      },
    });
  };

  const handleCloseModSuccess = () => {
    setOpenModInfo(null);

    props.onEdited?.();
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChange(null, { value, name });
  };

  const handleEditQuarter = (data: any) => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "update",
      detail: {
        ...detail,

        quarter_no: data.quarter,
        quarter_year: data.year,
        quarter_start_date: data.startDate ? beToAd(data.startDate)?.format("YYYY-MM-DD") : "",
        quarter_end_date: data.endDate ? beToAd(data.endDate)?.format("YYYY-MM-DD") : "",
      },
    });

    handleCloseModSelectQuarter();
  };

  const handleOpenModSelectQuarter = () => {
    setOpenModSelectQuarter(true);
  };

  const handleCloseModSelectQuarter = () => {
    setOpenModSelectQuarter(false);
  };

  console.log("CardMedicationErrorWorkingUX", props);

  return (
    <div ref={medErrorRef}>
      <CardMedicationErrorWorkingUX
        // Data
        title={props.title}
        detail={detail}
        drugName={detail.drug_name || props.drugName}
        drugCode={detail.drug_code || props.drugCode}
        riskType={detail.risk_type}
        type={detail.type}
        category={detail.category}
        typeDetail={detail.type_detail || []}
        cause={detail.cause || []}
        causeDetail={detail.cause_detail || []}
        editorBy={detail.editor_by}
        solutionNote={detail.solution_note}
        patientName={detail.patient_name}
        divisionName={detail.order_division}
        incidentDate={incidentDate}
        quarterDetail={quarterDetail}
        // options
        riskTypeOptions={props.masterOptions?.medErrorRiskType}
        typeOptions={typeOptions}
        categoryOptions={props.masterOptions?.medErrorCategory}
        typeDetailOptions={medErrorWorking?.typeDetailOptions}
        causeOptions={causeOptions}
        causeDetailOptions={medErrorWorking?.causeDetailOptions}
        // config
        showButtonSave={
          !["CANCELED", "COMPLETED"].includes(detail.status)
          // detail.status !== "CANCELED" &&
          // (detail.status === "COMPLETED" ? props.showSolution : true)
        }
        // Callback
        onChange={handleChange}
        onAddOptions={handleAddOptions}
        onEdit={handleEdit}
        onOpenModSelectQuarter={handleOpenModSelectQuarter}
        // config
        showSolution={props.showSolution}
        editMode={props.editMode}
        disabledEdit={props.disabledEdit}
        hideEditQuarter={props.hideEditQuarter}
        // Element
        userSearch={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type={"User"}
            id="MEW"
            fluid
            useWithKey
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={stakeholderId || null}
            setSelectedItem={handleSelectedStakeholder}
            // options
            mapOptions={mapUserOptions}
            style={{ width: "100%" }}
          />
        }
        staffSearch={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type={detail.editor_by === "CLINIC" ? "Division" : "User"}
            id="MEWS"
            // fluid={true}
            useWithKey
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              (detail.editor_by === "CLINIC"
                ? detail.corrective_division
                : detail.corrective_user) || null
            }
            setSelectedItem={handleSelectedItem}
            // options
            mapOptions={mapUserOptions}
            style={{
              marginTop: "-0.95rem",
              marginLeft: "1rem",
              width: "100%",
            }}
            // dropdownStyle={{ height: "3rem" }}
          />
        }
        errorMessage={<ErrorMessage error={openModInfo?.message} />}
        StartDate={
          <DateTextBox value={detail.risk_date || ""} onChange={handleChangeDate("risk_date")} />
        }
        Tooltip={
          <Popup
            content={categoryMessage}
            position="right center"
            style={{ minWidth: "22.25rem" }}
            trigger={
              <Button
                className="ignore-prevent"
                icon="info"
                circular
                color="blue"
                size="tiny"
                style={{
                  padding: "2.5px 3px",
                  fontSize: "0.75rem",
                  marginLeft: "5px",
                }}
              />
            }
          />
        }
      />

      <ModInfo
        open={openModInfo?.status === "success"}
        titleColor="green"
        titleName={"บันทึกรายการสำเร็จ"}
        btnText="ตกลง"
        onApprove={handleCloseModSuccess}
        style={{ top: "calc(50vh - 130px)" }}
      />

      <Modal
        open={openModSelectQuarter}
        size="small"
        closeOnDimmerClick
        // callback
        onClose={handleCloseModSelectQuarter}
      >
        <ModSelectQuarter
          detail={quarterDetail}
          onSave={handleEditQuarter}
          // callback
          onClose={handleCloseModSelectQuarter}
        />
      </Modal>
    </div>
  );
};

CardMedicationErrorWorking.displayName = "CardMedicationErrorWorking";

export default React.memo(CardMedicationErrorWorking);
