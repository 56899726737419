import React, { useState, useEffect, useMemo } from "react";
// Interface
import { State } from "./sequence/FinancialReport";
import { Form, Label, Modal } from "semantic-ui-react";
// ADM
import { MenuItem } from "react-lib/apps/HISV3/ADM/CardADM";
// BIL
import ModReceiptCodeAr from "react-lib/apps/HISV3/BIL/ModReceiptCodeAr";
import CardBillingPaymentAr from "react-lib/apps/HISV3/BIL/CardBillingPaymentAr";
import CardBillingHistoryAr from "react-lib/apps/HISV3/BIL/CardBillingHistoryAr";
import CardBillingSummaryAr from "react-lib/apps/HISV3/BIL/CardBillingSummaryAr";

// Props
type CardReceiptArProps = {
  onEvent: (e: any) => any;
  setProp: any;
  runSequence: any;
  // Sequence
  BillPaymentArSequence?: Record<string, any>;
  billingHistorySequence?: Record<string, any>;
  BillPaymentSequence?: Record<string, any>;
  bilReceiptCodeDetail?: Record<string, any>;
  searchedItemList?: any;
  masterOptions?: Record<string, any>;
  selectedDevice?: any;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
};

// Const

const CardReceiptAr = (props: CardReceiptArProps) => {
  const [mode, setMode] = useState("");

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "BillPaymentAr",
      restart: true,
    });
  }, []);

  useEffect(() => {
    // * Get Receipt Code เพื่อแสดงเลขที่ใบเสร็จ
    props.onEvent({
      message: "HandleGetReceiptCode",
      params: {
        isAr: true,
      },
    });
  }, [props.selectedDevice]);

  useEffect(() => {
    if (props.bilReceiptCodeDetail?.arReceipt?.require_set_receipt_code === true && props.bilReceiptCodeDetail?.arReceipt?.isShift !== true ) {
      setMode("receipt_number");
    }
  }, [props.bilReceiptCodeDetail?.arReceipt]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "10px",
        overflow: "auto",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", flex: "1" }}>
          <MenuItem
            menu="receipt_number"
            mode={mode}
            name="ตั้งเลขที่ใบเสร็จ"
            setMode={() => {
              setMode("receipt_number");
            }}
          />
          <MenuItem menu="payment_save" mode={mode} name="บันทึกรับชำระเงิน" setMode={setMode} />
          <MenuItem menu="payment_history" mode={mode} name="ประวัติการรับชำระ" setMode={setMode} />
          <MenuItem menu="shift_balance" mode={mode} name="สรุปยอดปิดกะ" setMode={setMode} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "20px",
            flex: "1",
          }}
        >
          <Label
            size="large"
            style={{
              backgroundColor: "#FDC38E",
              color: "black",
              fontWeight: "normal",
            }}
          >
            เลขที่ใบเสร็จปัจจุบัน :{" "}
            {typeof props.bilReceiptCodeDetail?.arReceipt?.receipt_code === "undefined"
              ? "-"
              : props.bilReceiptCodeDetail?.arReceipt?.receipt_code || "ยังไม่ได้ตั้งเลขที่ใบเสร็จ"}
          </Label>
        </div>
      </div>

      {mode === "receipt_number" ? (
        <ModReceiptCodeAr
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          bilReceiptCodeDetail={props.bilReceiptCodeDetail?.arReceipt}
          // CommonInterface
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          // config
          isDefault={true}
        />
      ) : mode === "payment_save" ? (
        <CardBillingPaymentAr
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          BillPaymentArSequence={props.BillPaymentArSequence}
          bilReceiptCodeDetail={props.bilReceiptCodeDetail?.arReceipt}
          BillPaymentSequence={props.BillPaymentSequence}
          // CommonInterface
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          runSequence={props.runSequence}
          buttonLoadCheck={props.buttonLoadCheck}
          masterOptions={props.masterOptions}
          searchedItemList={props.searchedItemList}
        />
      ) : mode === "payment_history" ? (
        <CardBillingHistoryAr
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          BillPaymentArSequence={props.BillPaymentArSequence}
          BillPaymentSequence={props.BillPaymentSequence}
          // CommonInterface
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          runSequence={props.runSequence}
          buttonLoadCheck={props.buttonLoadCheck}
          masterOptions={props.masterOptions}
          searchedItemList={props.searchedItemList}
        />
      ) : mode === "shift_balance" ? (
        <CardBillingSummaryAr
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          BillPaymentArSequence={props.BillPaymentArSequence}
          BillPaymentSequence={props.BillPaymentSequence}
          // CommonInterface
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          runSequence={props.runSequence}
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemList={props.searchedItemList}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CardReceiptAr;
