import React, {
  SyntheticEvent,
  useEffect,
  useMemo,
  useCallback,
  CSSProperties,
  useState,
} from "react";
import { Button, Dimmer, Icon, Loader } from "semantic-ui-react";

// UX
import CardApplyPolicyPackageUX from "./CardApplyPolicyPackageUX";
import ModApplyPackage, { MOD_APPLY_PACKAGE_SAVE } from "./ModApplyPackage";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModInfo from "react-lib/appcon/common/ModInfo";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

// Interface
import {
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/ApplyPolicyPackage";

// Types
type CardApplyPolicyPackageProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  ApplyPolicyPackageSequence: State["ApplyPolicyPackageSequence"];
} & PickedProps; // CommonInterface

const BUTTON_ACTIONS = {
  confirm: "CONFIRM",
  delete: "DELETE",
  sequence: "UPDATE_SEQUENCE",
};

const AlignCenter = {
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
} as CSSProperties;

const IconStyle = {
  margin: "-0.15rem 0 0 0.25rem",
  fontSize: "1.25rem",
  cursor: "pointer",
};

const CARD_APPLY_POLICY_PACKAGE = "CardApplyPolicyPackage";

const ACTION_CONFIRM = `${CARD_APPLY_POLICY_PACKAGE}_${BUTTON_ACTIONS.confirm}`;
const ACTION_DELETE = `${CARD_APPLY_POLICY_PACKAGE}_${BUTTON_ACTIONS.delete}`;
const ACTION_SEQUENCE = `${CARD_APPLY_POLICY_PACKAGE}_${BUTTON_ACTIONS.sequence}`;

const CardApplyPolicyPackage = (props: CardApplyPolicyPackageProps) => {
  const [openModSuccess, setOpenModSuccess] = useState<boolean>(false);
  const [modDelete, setModDelete] = useState<any>(null);

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "ApplyPolicyPackage", restart: true });

    return () => {
      props.runSequence({ sequence: "ApplyPolicyPackage", clear: true });
    };
  }, []);

  useEffect(() => {
    if (props.buttonLoadCheck?.[ACTION_CONFIRM] === "SUCCESS") {
      setOpenModSuccess(true);
    }
  }, [props.buttonLoadCheck]);

  // Callback
  const handleEdit = useCallback((data: any) => {
    props.runSequence({
      sequence: "ApplyPolicyPackage",
      action: "EDIT",
      data,
    });
  }, []);

  const handleDelete = useCallback((data: any, index: number) => {
    setModDelete({ ...data, index });
  }, []);

  const handleUpdateSequence = useCallback((data: any) => {
    props.runSequence({
      sequence: "ApplyPolicyPackage",
      action: "UPDATE_SEQUENCE",
      card: CARD_APPLY_POLICY_PACKAGE,
      data,
    });
  }, []);

  // Memo
  const applyPolicyItems = useMemo(() => {
    const items = props.ApplyPolicyPackageSequence?.applyPolicyList || [];

    return items.map((item, index) => {
      const packageName =
        props.ApplyPolicyPackageSequence?.packageOrderList?.find(
          (acc) => acc.id === item.package_order
        )?.product_name;

      return {
        ...item,
        seq: (
          <RenderSequenceAction
            index={index}
            items={items}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            // callback
            onChange={handleUpdateSequence}
          />
        ),
        name: packageName,
        encounters: item.target_encounter_displays.join(", "),
        action: (
          <RenderButtonAction
            data={item}
            index={index}
            // CommonInterface
            // buttonLoadCheck={props.buttonLoadCheck}
            // callback
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        ),
      };
    });
  }, [
    props.ApplyPolicyPackageSequence?.applyPolicyList,
    props.ApplyPolicyPackageSequence?.packageOptions,
    props.buttonLoadCheck,
  ]);

  // Handler
  const handleConfirm = () => {
    props.runSequence({
      sequence: "ApplyPolicyPackage",
      action: "CONFIRM",
      card: CARD_APPLY_POLICY_PACKAGE,
    });
  };

  const handleAddItem = () => {
    props.runSequence({ sequence: "ApplyPolicyPackage", action: "ADD_ITEM" });
  };

  const handleCloseModApplyPackage = () => {
    props.setProp("ApplyPolicyPackageSequence.applyPackageDetail", null);

    props.setProp(`errorMessage.${MOD_APPLY_PACKAGE_SAVE}`, null);
  };

  const handleCloseError = () => {
    props.setProp(`errorMessage.${CARD_APPLY_POLICY_PACKAGE}`, null);
  };

  const handleCloseModSuccess = () => {
    setOpenModSuccess(false);
  };

  const handleConfirmDelete = () => {
    if (modDelete) {
      props.runSequence({
        sequence: "ApplyPolicyPackage",
        action: "DELETE",
        orderId: modDelete.id,
        card: CARD_APPLY_POLICY_PACKAGE,
        index: modDelete.index,
        onSuccess: handleCloseModDeleteOrder,
      });
    }
  };

  const handleCloseModDeleteOrder = () => {
    setModDelete(null);
  };

  console.log("CardApplyPolicyPackage", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={handleCloseError}
        error={props.errorMessage?.[CARD_APPLY_POLICY_PACKAGE]}
        success={null}
      />

      <CardApplyPolicyPackageUX
        // data
        applyPolicyList={applyPolicyItems}
        // config
        disabledAdd={!props.ApplyPolicyPackageSequence?.packageOptions?.[0]}
        // callback
        onClickAdd={handleAddItem}
        // Element
        ButtonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirm}
            // data
            paramKey={ACTION_CONFIRM}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_CONFIRM]}
            // config
            color="orange"
            name={BUTTON_ACTIONS.confirm}
            size="medium"
            title="ยืนยันการใช้แพ็กเกจ"
          />
        }
      />

      {!!props.ApplyPolicyPackageSequence?.applyPackageDetail && (
        <ModApplyPackage
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          open={true}
          data={props.ApplyPolicyPackageSequence?.applyPackageDetail}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          errorMessage={props.errorMessage}
          // options
          packageOptions={props.ApplyPolicyPackageSequence?.packageOptions}
          // callback
          onClose={handleCloseModApplyPackage}
        />
      )}

      <ModInfo
        open={openModSuccess}
        titleColor={"green"}
        titleName="บันทึกสำเร็จ"
        onApprove={handleCloseModSuccess}
        onClose={handleCloseModSuccess}
      >
        <div style={{ padding: "1rem 0", fontWeight: "bold" }}>
          <div>บันทึกใช้แพ็กเกจสำเร็จ</div>
        </div>
      </ModInfo>

      <ModConfirm
        openModal={!!modDelete}
        titleName="แจ้งเตือน"
        titleColor="red"
        size="mini"
        denyButtonText="ยกเลิก"
        // callback
        onDeny={handleCloseModDeleteOrder}
        onCloseWithDimmerClick={handleCloseModDeleteOrder}
        // ELement
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmDelete}
            // data
            paramKey={`${ACTION_DELETE}_${modDelete?.index}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${ACTION_DELETE}_${modDelete?.index}`]
            }
            // config
            color={"green"}
            name="DELETE"
            size="medium"
            title="ตกลง"
            basic={true}
          />
        }
        content={
          <div
            style={{
              margin: "0rem 1.5rem -1rem 0rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            ต้องการลบรายการใช่หรือไม่
          </div>
        }
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                   RenderButtonAction;                  */

/* ------------------------------------------------------ */
const RenderButtonAction = (props: any) => {
  return (
    <div style={{ paddingLeft: "0.5rem" }}>
      <Button
        size="mini"
        icon="edit"
        color="yellow"
        style={{ padding: "0.5rem", marginRight: "0.5rem" }}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();

          props.onEdit?.(props.data, props.index);
        }}
      />
      <Button
        size="mini"
        icon="trash alternate"
        color="red"
        loading={!!props.buttonLoadCheck?.[`${ACTION_DELETE}_${props.index}`]}
        style={{ padding: "0.5rem" }}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();

          props.onDelete?.(props.data, props.index);
        }}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                  RenderSequenceAction                  */

/* ------------------------------------------------------ */
const RenderSequenceAction = (props: any) => {
  const data = useMemo(() => {
    return props.items[props.index];
  }, [props.items, props.index]);

  const isDownLoading = useMemo(() => {
    return props.buttonLoadCheck?.[`${ACTION_SEQUENCE}_DOWN_${props.index}`];
  }, [props.buttonLoadCheck]);

  const isUpLoading = useMemo(() => {
    return props.buttonLoadCheck?.[`${ACTION_SEQUENCE}_UP_${props.index}`];
  }, [props.buttonLoadCheck]);

  const disabled = useMemo(() => {
    const key = Object.keys(props.buttonLoadCheck || {}).find((key) =>
      key.includes(ACTION_SEQUENCE)
    );
    return !!props.buttonLoadCheck?.[key || ""];
  }, [props.buttonLoadCheck]);

  const handleChange = (value: number, type: "DOWN" | "UP") => {
    const toSeq = data.sequence + value;

    const toData = props.items[props.index + value];

    props.onChange?.({
      data: [
        { id: data.id, sequence: toSeq },
        { id: toData.id, sequence: data.sequence },
      ],
      type,
      index: props.index,
    });
  };

  const handleDown = () => {
    handleChange(1, "DOWN");
  };

  const handleUp = () => {
    handleChange(-1, "UP");
  };

  return (
    <div style={AlignCenter}>
      {data.sequence}{" "}
      <div style={{ display: "grid" }}>
        {props.index !== 0 && (
          <div style={{ position: "relative" }}>
            <Icon
              name="caret up"
              disabled={disabled}
              style={IconStyle}
              // callback
              onClick={handleUp}
            />
            <Dimmer
              active={isUpLoading}
              size="mini"
              inverted
              style={{
                background: "transparent",
                margin: "-1px 0 0 1.5px",
                padding: 0,
              }}
            >
              <Loader size="mini" inline={true}></Loader>
            </Dimmer>
          </div>
        )}
        {props.index !== props.items.length - 1 && (
          <div style={{ position: "relative" }}>
            <Icon
              name="caret down"
              disabled={disabled}
              style={IconStyle}
              // callback
              onClick={handleDown}
            />
            <Dimmer
              active={isDownLoading}
              size="mini"
              inverted
              style={{
                background: "transparent",
                margin: "-1px 0 0 1.5px",
                padding: 0,
              }}
            >
              <Loader size="mini" inline={true}></Loader>
            </Dimmer>
          </div>
        )}
      </div>
    </div>
  );
};

CardApplyPolicyPackage.displayName = "CardApplyPolicyPackage";

export default React.memo(CardApplyPolicyPackage);
