import React from "react";
import PropTypes from "prop-types";
import CardLayout from '../common/CardLayout';

const CardDrugOPDStatOrder = React.forwardRef((props, ref) => {
  return (
    <CardLayout 
      titleText="สั่งยา STAT" 
      onClose={props.onClose}
      toggleable={props.toggleable}
    >
      asdasdasd
    </CardLayout>
  )
})

CardDrugOPDStatOrder.defaultProps = {
  onClose: () => {},
  toggleable: true,
};

CardDrugOPDStatOrder.propTypes = {
  onClose: PropTypes.func,
  toggleable: PropTypes.bool,
};


export default React.memo(CardDrugOPDStatOrder);