import React, { useEffect, useRef, useState } from "react";
// UX
import CardQuarantineCertificateUX from "./CardQuarantineCertificateUX";
// Common

// Interface
import { State } from "./sequence/DoctorCertificate";
// Utils
import moment from "moment";

// Main Props
type CardQuarantineCertificateProps = {
  // Function
  setProp: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

// Current Date
const currentDate = new Date().toISOString().split("T")[0];
const christYear = moment(currentDate).format("YYYY");
const buddhistYear = (parseInt(christYear) + 543).toString();
const formattedDate = moment(currentDate).format(
  "DD/MM/YYYY".replace("YYYY", buddhistYear)
);

// Main
const CardQuarantineCertificate = (props: CardQuarantineCertificateProps) => {
  // log Props
  // console.log("CardQuarantineCertificate Props: ", props.DoctorCertificateSequence);

  // Call Sequence ( Quarantine Certificate )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "FETCH_CERTIFICATE",
      fetchType: "CardQuarantineCertificate",
    });
  }, []);

  // Change Date
  const handleChangeDate = (name: any, date: string) => {
    // console.log("Key: ", name + " Date: ", date);
    props.setProp(`DoctorCertificateSequence.quarantineReport.${name}`, date);
  };

  // Change Text
  const handleChangeText = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (name) {
      case "comment":
      case "absenceDuration":
        props.setProp(
          `DoctorCertificateSequence.quarantineReport.${name}`,
          e.target.value
        );
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Change Checkbox
  const handleChangeCheckbox = (e: any, data: any) => {
    if (data.type === "checkbox") {
      var value = data.checked;
      props.setProp(
        `DoctorCertificateSequence.quarantineReport.shouldAbsence`,
        value
      );
    }
  };

  // Enable/Disable Absence Duration
  const handleDurationDisable = () => {
    if (
      props.DoctorCertificateSequence?.quarantineReport?.shouldAbsence === true
    ) {
      return false;
    } else if (
      props.DoctorCertificateSequence?.quarantineReport?.shouldAbsence === false
    ) {
      if (
        props.DoctorCertificateSequence?.quarantineReport?.absenceDuration ||
        (props.DoctorCertificateSequence?.quarantineReport?.absenceStartDate &&
          props.DoctorCertificateSequence?.quarantineReport?.absenceEndDate)
      ) {
        props.setProp(
          `DoctorCertificateSequence.quarantineReport.absenceDuration`,
          ""
        );
        props.setProp(
          `DoctorCertificateSequence.quarantineReport.absenceStartDate`,
          ""
        );
        props.setProp(
          `DoctorCertificateSequence.quarantineReport.absenceEndDate`,
          ""
        );
      }
      return true;
    } else {
      return false;
    }
  };

  // Condition Close Button
  // const clearSelectedData = () => {
  //   // Set selectedReport = null
  //   props.setProp(`DoctorCertificateSequence.selectedReport`, null);

  //   // Re-Fetch
  //   props.runSequence({
  //     sequence: "DoctorCertificate",
  //     action: "CLEAR_REPORT",
  //     callReport: "CardQuarantineCertificate",
  //   });
  // };

  // const closeSelectedCheck = (reportSelected: any) => {
  //   if (reportSelected != null) {
  //     return (
  //       <Button
  //         color="red"
  //         style={{ width: "8em" }}
  //         onClick={clearSelectedData}
  //       >
  //         ปิด
  //       </Button>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Disable when select FiveForm
  const handleDisableForm = (selected: any, reportType?: any) => {
    if (selected == null) {
      return false;
    } else if (
      selected !== null &&
      reportType === "ใบรับรองการกักตัวหลังตรวจพบเชื้อโควิด"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CardQuarantineCertificateUX
        // Detect Date
        onDetectDate={
          props.DoctorCertificateSequence?.quarantineReport?.detectDate ||
          formattedDate
        }
        onHandleChangeDate={handleChangeDate}
        onDetectMinDate={moment().subtract(1, "days")}
        // Comment
        onComment={
          props.DoctorCertificateSequence?.quarantineReport?.comment || ""
        }
        onHandleChangeText={handleChangeText}
        // Absence Checkbox
        onShouldAbsence={
          props.DoctorCertificateSequence?.quarantineReport?.shouldAbsence ||
          false
        }
        onHandleChangeCheckbox={handleChangeCheckbox}
        // Absence Duration (days)
        onAbsenceDuration={
          props.DoctorCertificateSequence?.quarantineReport?.absenceDuration ||
          ""
        }
        onHandleDurationDisable={handleDurationDisable}
        // Absence Start Date
        onAbsenceStartDate={
          props.DoctorCertificateSequence?.quarantineReport?.absenceStartDate
        }
        // Absence End Date
        onAbsenceEndDate={
          props.DoctorCertificateSequence?.quarantineReport?.absenceEndDate
        }
        // Absence Min Date
        onAbsenceMinDate={
          props.DoctorCertificateSequence?.quarantineReport?.detectDate
        }
        // Close Button When Selected Report
        // onCloseSelected={closeSelectedCheck(
        //   null
        // )}
        // Disable Input
        // onHandleDisableForm={handleDisableForm(
        //   null,
        //   props.DoctorCertificateSequence?.selectedReport?.data?.reportType
        // )}
      />
    </>
  );
};

export default CardQuarantineCertificate;
