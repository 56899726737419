import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Segment, Dropdown } from 'semantic-ui-react';

const ModChangeTheme = props => {

  const [theme, setTheme] = React.useState("")

  React.useEffect(() => {
    setTheme(props.value)
  }, [props.value])

  const handleSetTheme = (e, { value }) => {
    setTheme(value)
  }

  return (
    <Modal
      open={props.open}
      size={props.size}
    >
      <Segment padded align="center" className="modContent">
        <Dropdown selection options={props.options} value={theme} onChange={handleSetTheme}/>
        <div style={{ marginTop: 20 }}>
          <Button  
            loading={props.buttonLeftLoading} 
            disabled={props.buttonRightLoading}
            color={props.buttonLeftColor} 
            onClick={props.onButtonLeftClick}>
            {props.buttonLeft}
          </Button>
          <Button 
            loading={props.buttonRightLoading} 
            disabled={props.buttonLeftLoading}
            color={props.buttonRightColor} 
            onClick={() => props.onButtonRightClick(theme)}>
            {props.buttonRight}
          </Button>
        </div>
      </Segment>
    </Modal >
  )
} 

ModChangeTheme.defaultProps = {
  open: false,
  size: "mini",
  buttonLeft: "ยกเลิก",
  buttonLeftColor: "grey",
  onButtonLeftClick: () => {},
  buttonRight: "ตกลง",
  buttonRightColor: "blue",
  onButtonRightClick: () => {},
};

ModChangeTheme.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.string,
  buttonLeft: PropTypes.string,
  buttonLeftColor: PropTypes.string,
  onButtonLeftClick: PropTypes.func,
  buttonRight: PropTypes.string,
  buttonRightColor: PropTypes.string,
  onButtonRightClick: PropTypes.func,
};

export default ModChangeTheme;