import React, { SyntheticEvent, useState, useMemo, useEffect } from "react";
import { Button, Divider, Icon, Modal, TextAreaProps } from "semantic-ui-react";

import moment from "moment";

// UX
import CardAppointmentDetailUX from "./CardAppointmentDetailUX";
import ModSearchDoctorNote from "react-lib/apps/APP/ModSearchDoctorNote";
import CardTreatmentOrder from "react-lib/apps/HISV3/DPO/CardTreatmentOrder";
import CardImagingOrder from "../HISV3/IME/CardImagingOrder";
import CardLabRequest from "react-lib/apps/HISV3/LAB/CardLabRequest";

// Common
import ModConfirmAuthen from "react-lib/apps/common/ModConfirmAuthen";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Utils
import { dateToStringAD } from "react-lib/utils/dateUtils";
import ModDuplicateAppointment from "./ModDuplicateAppointment";

// Config
import CONFIG from "config/config";

// Types
type CardAppointmentDetailProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // controller
  proxyController: any;
  // seq
  runSequence: any;
  // data
  estimatedAt?: any;
  estimatedDuration?: any;
  card: string;
  availableSlots?: any[];
  blockList?: any[];
  preOrderList?: any[];
  selectedBlock?: any;
  selectedWaitingListItem?: Record<string, any> | null;
  selectedWaitingList?: Record<string, any> | null;
  openModOrder?: {
    id?: number;
    type?: string;
  } | null;
  doctorOrderFilter?: string[];
  reservedText?: string;
  duplicateAppointment?: any;
  reoccureDuplicateAppointment?: any;

  // treatment order
  OrderTreatmentSequence: any;
  procedureItems: any[];
  secondaryItems: any[];
  treatmentTemplateList: any[];
  treatmentOrderList: any[];

  // imaging order
  ImagingOrderSequence: any;
  modXrayDetail: any;
  clinicalFindingId: any;
  clinicalFindingIndex: any;
  clinicalFindingList: any;
  organ: any;
  clinicaltags: any;
  filterClinicalFindingIds: any;

  // lab order
  selectedEncounter: any[];
  selectedEmr: any[];
  selectedProgressCycle: any[];
  OrderCentralLabSequence: any;
  userLabPermission: any;
  doctorLabOrderList: any;

  // CommonInterface
  userTokenize: any;
  selectedAppointment?: Record<string, any> | null;
  selectedPatient?: Record<string, any> | null;
  loadingStatus?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  searchedItemList?: any[];
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions: any;
  cancelOptions: any[];
  postponeOptions: any[];
  // config
  showOrderAppointment?: boolean;
  hideDrugAllergy?: boolean;
  isAppointment?: boolean;
  hideGeneralDetail?: boolean;
  useNoteTemplate?: boolean;
  isEditable?: boolean;
  hideButtonSave?: boolean;
  hidePrintAppointmentDoc?: boolean;
  config?: Record<string, any>;
  toggleOrRequest?: boolean;
  hideConfirmButton?: boolean;
  showEmergencyContact?: boolean;
  // callback
  setTab?: (tab: string) => any;
  setEstimatedAt?: (data: any) => any;
  setEstimatedDuration?: (data: any) => any;
  setOpenModOrder: (data: any) => any;
  toCalendar?: () => any;
  onRefreshAppointment?: () => any;
  refreashCardAppointmentSummary?: () => any;
};

const CardAppointmentDetail = (props: CardAppointmentDetailProps) => {
  // Mod
  const [openModDoctorNote, setOpenModDoctorNote] = useState<boolean>(false);

  // ModConfirm
  const [questionDetail, setQuestionDetail] = useState<any>(null);
  const [openModMessage, setOpenModMessage] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [currentValue, setCurrentValue] = useState([]);

  // #const [complete, setComplete] = useState<string>("");
  const [orderNote, setOrderNote] = useState("");

  // Duplicated make appointment
  const [duplicateReason, setDuplicateReason] = useState("");

  // #useEffect(() => {
  //   const loading = props.loadingStatus?.[props.card];

  //   if (loading) {
  //     setComplete("check");

  //     setTimeout(() => {
  //       setComplete("");
  //       props.setProp(`loadingStatus.${props.card}`, false);
  //     }, 2000);
  //   }
  // }, [props.loadingStatus]);

  useEffect(() => {
    if (props.openModOrder?.type) {
      props.setOpenModOrder(props.openModOrder);
    }
  }, [props.openModOrder]);

  useEffect(() => {
    if (props.duplicateAppointment) {
      setDuplicateReason("");
    }
  }, [props.duplicateAppointment]);

  useEffect(() => {
    if (!orderNote) {
      setOrderNote(props.selectedAppointment?.order_note || "");
    }
  }, [props.selectedAppointment?.order_note]);

  // ---------------------------------------------------------------
  //  เลือก Appointment , เปิด Tab ด้านขวา
  // ---------------------------------------------------------------
  useEffect(() => {
    // เลือก Appointment

    if (!props.selectedAppointment?.id || props.selectedAppointment?.main_patient_appointment) {
      if (props.selectedAppointment?.estimated_at_iso) {
        props.setEstimatedAt?.(moment(props.selectedAppointment?.estimated_at_iso).format("HH:mm"));
      }

      return;
    }

    // have Appointment
    if (
      props.selectedAppointment?.division_service_block == null &&
      props.selectedBlock?.dsb_id == null
    ) {
      // ---
    } else {
      const estimatedTime = props.selectedAppointment?.estimated_at?.split(" ")?.[1] || "";
      const newEstimatedAt = estimatedTime.substring(1, estimatedTime.length - 1);

      if (props.selectedAppointment?.status === 4 || props.selectedAppointment?.status === 3) {
        // กรณี แก้ไข appointment เดิมก็จะ โชว์ อันเดิม => setEstimatedAt(estimatedAt || newEstimatedAt);
        // แต่ Issue 56255 มันคือ การเลื่อน ต้อง เลือก estimatedAt ใหม่ ซึ่งคือ
        props.setEstimatedAt?.(newEstimatedAt);
      } else {
        // กรณี แก้ไข appointment เดิมก็จะ โชว์ อันเดิม
        props.setEstimatedAt?.(props.estimatedAt || newEstimatedAt);
      }

      // กำหนด slotLength จาก template  หรือ เลือกจาก selectedAppointment
      if (!props.estimatedDuration) {
        if (props.selectedAppointment?.estimated_duration) {
          props.setEstimatedDuration?.(props.selectedAppointment?.estimated_duration.toString());
        } else {
          props.setEstimatedDuration?.(props.selectedAppointment?.slotLength?.toString() || 5);
        }
      }
    }
  }, [props.selectedAppointment, props.availableSlots]);

  // Memo
  // ---------------------------------------------------------------
  // เลือก selectedAppointment จะ คำนวน หา รายชื่อคนไข้อื่นๆในคาบเดียวกัน
  // ---------------------------------------------------------------
  const blockAppointments = useMemo(() => {
    return (
      props.blockList
        ?.flatMap((block: any) => (block?.providers || []).map((provider: any) => provider?.dsb))
        .find((dsb: any) =>
          props.selectedBlock?.doctor_dsb_id
            ? dsb?.doctor_dsb_id === props.selectedBlock?.doctor_dsb_id
            : dsb?.doctor_dsb_id === props.selectedAppointment?.division_service_block
        )
        ?.appointments?.filter((item: any) => item.status === 1)
        .map((app: any) => ({
          patient_name: `${app?.first_name} ${app?.last_name}`,
          estimated_at_display: app?.estimated_at_display,
          estimated_duration: app?.estimated_duration,
          // edit_user: app?.edit_user,
          edit_user_name: app?.edit_user_name,
          updated: app?.updated ? dateToStringAD(app?.updated) : "",
        })) || []
    );
  }, [props.selectedAppointment?.division_service_block, props.selectedBlock, props.blockList]);

  const preOrderList = useMemo(() => {
    return (props.preOrderList || [])
      .filter((item: any) => !(props.doctorOrderFilter || []).includes(item.type))
      .map((item: any) => ({
        ...item,
        order_detail: (
          <div
            dangerouslySetInnerHTML={{
              __html: ["treatmentorder", "centrallaborder", "imagingorder"].includes(item.type)
                ? item.summary_detail ||
                  item.order_summary_detail ||
                  item.order_name ||
                  item.name ||
                  item.order_summary
                : "",
            }}
          />
        ),
        specific_type_name:
          item.type === "treatmentorder"
            ? "Treatment"
            : item.type === "centrallaborder" && item.system === "bloodbank"
            ? "Blood Bank"
            : item.type === "centrallaborder"
            ? "Central Lab"
            : item.type === "imagingorder"
            ? "Imaging"
            : item.type,
        del: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="red"
              icon="minus"
              disabled={!props.isEditable}
              onClick={(e) => {
                props.onEvent({
                  message: "HandleActionPreOrderList",
                  params: {
                    action: "delete",
                    id: item.id,
                  },
                });
              }}
              size={"mini"}
            ></Button>
          </div>
        ),
        edit: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="blue"
              icon="edit"
              disabled={!props.isEditable}
              onClick={(e) => {
                props.setOpenModOrder({
                  id: item.id,
                  type:
                    item.type === "treatmentorder"
                      ? "TREATMENT"
                      : item.type === "centrallaborder"
                      ? "CENTRAL_LAB"
                      : item.type === "imagingorder"
                      ? "IMAGING"
                      : "",
                });
              }}
              size={"mini"}
            ></Button>
          </div>
        ),
      }));
  }, [props.preOrderList, props.doctorOrderFilter, props.isEditable]);

  // Handler
  const handleOnSelectNote = (noteList: string[]) => {
    const note: (string | number)[] = orderNote ? [orderNote, ...noteList] : noteList;

    setOrderNote?.(note.join("\n"));

    setOpenModDoctorNote(false);
  };

  const handleSetOrderNote = (e: SyntheticEvent, data: any) => {
    setOrderNote?.(data?.value?.toString());
  };

  const handleSaveAppointment = (e: any) => {
    if (props.toggleOrRequest) {
      props.setTab?.("or_request");
    } else {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "SaveAppointment",
          estimatedAt: props.estimatedAt,
          estimatedDuration: props.estimatedDuration,
          orderNote: orderNote,
          full: props.selectedAppointment?.dsb_full,
          card: props.card,
          isAppointment: props.isAppointment,
        },
      });
    }
  };

  const handleOnCancelAppointment = (e: any) => {
    setOpenModMessage(true);
    setOptions(props.cancelOptions);
    setQuestionDetail({
      type: "cancel",
      title: "ต้องการยกเลิกนัดหมายจากแพทย์ใช่หรือไม่",
      subTitle: "",
      questionDetail: "ระบุเหตุผลในการยกเลิก",
      titleColor: "red",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      isUserEditQuesion: true,
      isRequiredQuestionDetail: true,
    });
  };

  const handleOnCancelAppointmentByPatient = (e: any) => {
    setOpenModMessage(true);
    setOptions(props.cancelOptions);
    setQuestionDetail({
      type: "cancelbypatient",
      title: "ต้องการยกเลิกนัดหมายจากจากผู้ป่วยใช่หรือไม่",
      subTitle: "",
      questionDetail: "ระบุเหตุผลในการยกเลิก",
      titleColor: "red",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      isUserEditQuesion: true,
      isRequiredQuestionDetail: true,
    });
  };

  const handleOnPostponeAppointment = (e: any) => {
    console.log("CardPatientAppointment handleOnPostponeAppointment ");
    setOpenModMessage(true);
    setOptions(props.postponeOptions);
    setQuestionDetail({
      type: "postpone",
      title: "ต้องการเลื่อนนัดผู้ป่วยใช่หรือไม่",
      subTitle: "กรณีที่เลื่อนนัดผู้ป่วยเจ้าหน้าที่จะต้องทำการติดนามนัดหมายผู้ป่วยใหม่",
      questionDetail: "ระบุเหตุผลเลื่อนนัดผู้ป่วย",
      isRequiredQuestionDetail: true,
      titleColor: "orange",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      isUserEditQuesion: true,
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
    });
    //
  };

  const handlePrintAppointment = (doctorNote: any) => {
    props.onEvent({
      message: "PrintScheduling",
      params: {
        form: "FormAppointmentDetail",
        data: {
          ...props.selectedAppointment,
          doctor_note: orderNote?.match(/^.*[^\n\r]/gm) || "",
          description: props.selectedWaitingListItem?.note || "",
        },
      },
    });
  };

  const handleConfirmAppointment = (e: any) => {
    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "confirmAppointment",
        card: props.card,
        buttonLoadCheck: `${props.card}_CONFIRM`,
        callback: () => {
          props.onRefreshAppointment?.();
        },
      },
    });
  };

  const handleOpenModOrder = (e: any, v: any) => {
    props.setOpenModOrder({ type: v.name });
  };

  const handleConfirmedButton = () => {
    if (questionDetail?.type === "postpone") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "PostponeAppointment",
          reason: currentValue,
          waitingList: props.selectedWaitingList,
          callback: props.refreashCardAppointmentSummary,
        },
      });
    } else if (questionDetail?.type === "cancel") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "CancelAppointment",
          reason: currentValue,
          waitingList: props.selectedWaitingList,
          callback: props.refreashCardAppointmentSummary,
        },
      });
    } else if (["cancelbypatient", "remove"].includes(questionDetail?.type || "")) {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "CancelAppointmentByPatient",
          reason: currentValue,
          waitingList: props.selectedWaitingList,
          callback: props.refreashCardAppointmentSummary,
        },
      });
    } else if (questionDetail?.type === "operatingPostpone") {
      props.runSequence({
        sequence: "OperatingDateTime",
        action: "operatingPostpone",
        reason: currentValue,
        // waitingList: selectedWaitingList,
        callback: questionDetail?.callback,
      });
    }

    setCurrentValue([]);
    setOpenModMessage(false);
    setQuestionDetail(null);
  };

  const handleCancelButton = () => {
    setOpenModMessage(false);
    setOptions([]);
    setCurrentValue([]);
    setQuestionDetail(null);

    props.setProp("userTokenize", null);
  };

  const handleAddition = (e: any, { value }: any) => {
    setOptions([{ text: value, value }, ...options]);
  };

  const handleChange = (e: any, { value }: any) => setCurrentValue(value);

  const handleCloseModOrder = () => {
    if (props.openModOrder?.type === "IMAGING") {
      props.onEvent({
        message: "ImagingRequestOrder",
        params: {
          action: "clear_data",
        },
      });
    }

    props.setOpenModOrder(null);

    props.setProp("errorMessage", {
      ...props.errorMessage,
      OrderCentralLab: null,
    });
  };

  const handleConfirmDuplicate = () => {
    props.onEvent({
      message: props.duplicateAppointment.message || "SetScheduling",
      params: {
        ...props.duplicateAppointment.payload,
        repetition_note: duplicateReason,
      },
    });
  };

  const handleCancelDuplicate = () => {
    props.setProp("duplicateAppointment", null);
    props.setProp(`buttonLoadCheck.${props.card}_CONFIRM`, null);
  };

  console.log("CardAppointmentDetailUX", props);

  return (
    <div>
      <CardAppointmentDetailUX
        // data
        appointmentFor={props.selectedAppointment?.extra?.appointmentFor}
        jobToDo={props.selectedAppointment?.extra?.jobToDo}
        treatmentType={props.selectedAppointment?.type}
        allergy={props.selectedAppointment?.extra?.allergy || ""}
        premedication={props.selectedAppointment?.extra?.premedication || ""}
        orderLab={props.selectedAppointment?.extra?.orderLab || ""}
        // Ishealth-v3 port into CUDent ---^
        appointmentedDate={props.selectedAppointment?.date}
        selectedPatient={props.selectedPatient}
        selectedAppointment={props.selectedAppointment}
        minHour={parseInt(props.selectedAppointment?.start) || 0}
        maxHour={parseInt(props.selectedAppointment?.end) || 23}
        estimatedAt={props.estimatedAt || ""}
        setEstimatedAt={props.setEstimatedAt}
        estimatedDuration={props.estimatedDuration || ""}
        intervalTimeSelected={
          // props.selectedAppointment?.slotLength ||
          props.selectedAppointment?.estimated_duration
        }
        setEstimatedDuration={props.setEstimatedDuration}
        orderNote={orderNote || ""}
        full={props.selectedAppointment?.dsb_full}
        availableOptions={props.availableSlots}
        reservedText={props.reservedText ?? "(มีนัดหมาย)"}
        manualTime={props.selectedAppointment?.manual_time}
        blockAppointments={blockAppointments}
        // Ishealth-v3 port into CUDent ---v
        onClickOrder={handleOpenModOrder}
        preOrderList={preOrderList}
        activeOrder={props.openModOrder?.type || ""}
        // flag to show appointment order
        isLoading={!!props.loadingStatus?.[props.card]}
        // options
        treatmentTypeOptions={props.masterOptions.patientAppType}
        // config
        isShowChair={CONFIG.SHOW_DOCTOR_CHAIR}
        showOrderAppointment={props.showOrderAppointment}
        hideDrugAllergy={props.hideDrugAllergy}
        showConfirmButton={
          props.hideDrugAllergy &&
          props.selectedAppointment?.status === 1 &&
          props.selectedAppointment?.estimated_at_iso &&
          props.selectedAppointment?.display_info?.id
        }
        hideGeneralDetail={props.hideGeneralDetail}
        isEditable={props.isEditable}
        useNoteTemplate={true}
        isAppointment={props.isAppointment}
        hidePrintAppointmentDoc={props.hidePrintAppointmentDoc}
        config={props.config}
        showEmergencyContact={props.showEmergencyContact}
        // callback
        onChangeAppointmentFor={(e: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
          props.setProp("selectedAppointment.extra.appointmentFor", data.value);
        }}
        onChangeJobToDo={(e: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
          props.setProp("selectedAppointment.extra.jobToDo", data.value);
        }}
        onDateFocus={() => {
          props.setTab?.("calendar");
        }}
        onChangeTreatmentType={(e: any, data: any) => {
          props.setProp("selectedAppointment.type", data.value);
        }}
        onChangeSelectedAppointmentDate={(dateString: any) => {
          props.setProp("selectedAppointment.date", dateString);
        }}
        onTimeAppointmentChange={(e: any, data: any) => {
          console.log(" set Estiamteat data", data);
          props.setEstimatedAt?.(data);
        }}
        onAddNote={() => setOpenModDoctorNote(true)}
        onAllergyChange={(e: any, data: any) => {
          props.setProp("selectedAppointment.extra.allergy", data.value);
        }}
        onPreMedicationChange={(e: any, data: any) => {
          props.setProp("selectedAppointment.extra.premedication", data.value);
        }}
        onOrderLabChange={(e: any, data: any) => {
          props.setProp("selectedAppointment.extra.orderLab", data.value);
        }}
        orderXray={props.selectedAppointment?.extra?.orderXray || ""}
        onOrderXrayChange={(e: any, data: any) => {
          props.setProp("selectedAppointment.extra.orderXray", data.value);
        }}
        setOrderNote={handleSetOrderNote}
        toggleFull={() => {
          props.setProp("selectedAppointment.dsb_full", !props.selectedAppointment?.dsb_full);
        }}
        onCancelAppointment={handleOnCancelAppointment}
        onCancelAppointmentByPatient={handleOnCancelAppointmentByPatient}
        onPostponeAppointment={handleOnPostponeAppointment}
        printAppointment={handlePrintAppointment}
        // Element
        divider={<Divider />}
        setManualTime={() => {
          props.setProp("selectedAppointment.manual_time", !props.selectedAppointment?.manual_time);
        }}
        confirmButton={
          !props.hideConfirmButton && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleConfirmAppointment}
              // data
              paramKey={`${props.card}_CONFIRM`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${props.card}_CONFIRM`]}
              // config
              color="green"
              title="ยืนยัน"
            />
          )
        }
        buttonSave={
          !props.hideButtonSave && (
            <ButtonLoadCheck
              setProp={props.setProp}
              className="ignore-prevent"
              color={
                props.selectedAppointment?.estimated_at_iso &&
                props.selectedAppointment?.display_info?.id &&
                !props.hideGeneralDetail
                  ? "yellow"
                  : "green"
              }
              size="medium"
              disabled={props.isEditable === undefined ? false : !props.isEditable}
              paramKey={props.card}
              title={
                props.selectedAppointment?.estimated_at_iso &&
                props.selectedAppointment?.display_info?.id &&
                !props.hideGeneralDetail
                  ? "แก้ไข"
                  : "บันทึก"
              }
              buttonLoadCheck={props.buttonLoadCheck?.[props.card]}
              onClick={handleSaveAppointment}
            />
          )
        }
      />

      <Modal open={openModDoctorNote} onClose={() => setOpenModDoctorNote(false)}>
        <ModSearchDoctorNote
          controller={props.proxyController}
          onClose={() => setOpenModDoctorNote(false)}
          onSelectNote={handleOnSelectNote}
        />
      </Modal>

      <ModConfirmAuthen
        {...questionDetail}
        options={options}
        onEvent={props.onEvent}
        setProp={props.setProp}
        userTokenize={props.userTokenize}
        onButtonLeftClick={handleConfirmedButton}
        onButtonRightClick={handleCancelButton}
        open={openModMessage}
        onClose={handleCancelButton}
        isButtonBasic={true}
        handleAddition={handleAddition}
        handleChange={handleChange}
        currentValue={currentValue}
        isErrMsg={true}
        multiple={questionDetail?.multiple}
      />

      <Modal
        open={props.openModOrder?.type === "TREATMENT"}
        onClose={handleCloseModOrder}
        closeOnDimmerClick
        style={{ width: "70%" }}
      >
        <CardTreatmentOrder
          titleName="Treatment Request"
          setProp={props.setProp}
          onEvent={props.onEvent}
          runSequence={props.runSequence}
          isVerbalOrder={false}
          claimOptions={props.masterOptions.claimTreatment}
          OrderTreatmentSequence={props.OrderTreatmentSequence}
          searchedItemList={props.searchedItemList}
          treatmentOrderList={props.treatmentOrderList}
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          procedureItems={props.procedureItems}
          secondaryItems={props.secondaryItems}
          treatmentTemplateList={props.treatmentTemplateList}
          hidePrincipalDiagnosis={true}
          hideSecondaryDiagnosis={true}
          encounterId={props.selectedAppointment?.order_encounter || null}
          save="SAVE"
          isAppointment={props.isAppointment}
          isNullEmr={true}
          checkOutPerformDiv={true}
          divisionOpdOptions={props.masterOptions.divisionOpd}
          onSuccess={handleCloseModOrder}
          editId={props.openModOrder?.id || null}
        />
      </Modal>
      <Modal
        open={props.openModOrder?.type === "IMAGING"}
        onClose={handleCloseModOrder}
        closeOnDimmerClick
        style={{ width: "auto" }}
      >
        <CardImagingOrder
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          ImagingOrderSequence={props.ImagingOrderSequence}
          // data
          orderId={props.openModOrder?.id || null}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
          buttonLoadCheck={props.buttonLoadCheck}
          // selectedEncounter={props.selectedEncounter}
          userTokenize={props.userTokenize}
          errorMessage={props.errorMessage}
          modXrayDetail={props.modXrayDetail}
          // options
          masterOptions={props.masterOptions}
          // config
          isAppointment={props.isAppointment}
          editMode={true}
          // callback
          onClose={handleCloseModOrder}
          // ----- Dental Diagram
          clinicalFindingId={props.clinicalFindingId}
          clinicalFindingIndex={props.clinicalFindingIndex}
          clinicalFindings={props.clinicalFindingList || []}
          organ={props.organ}
          clinicaltags={props.clinicaltags}
          filterClinicalFindingIds={props.filterClinicalFindingIds || []}
          // -----#
        />
      </Modal>
      <Modal
        open={["CENTRAL_LAB", "BLOOD_BANK"].includes(props.openModOrder?.type || "")}
        onClose={handleCloseModOrder}
        closeOnDimmerClick
        style={{ width: "70%", padding: "10px" }}
      >
        <CardLabRequest
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          OrderCentralLabSequence={props.OrderCentralLabSequence}
          // data
          userLabPermission={props.userLabPermission}
          doctorLabOrderList={props.doctorLabOrderList}
          editId={props.openModOrder?.id || null}
          // options
          divisionOpdOptions={props.masterOptions.divisionOpd}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedEncounter={props.selectedEncounter}
          selectedEmr={props.selectedEmr}
          selectedProgressCycle={props.selectedProgressCycle}
          loadingStatus={props.loadingStatus}
          // style
          doctorLabOrderTableStyle={{ height: "300px", display: "none" }}
          saveLabOrderStyle={{ marginTop: "0.5rem" }}
          // config
          isRestart={true}
          isNullEmr={true}
          checkOutPerformDiv={true}
          isBloodBank={props.openModOrder?.type === "BLOOD_BANK"}
          isAppointment={props.isAppointment}
          // callback
          onSuccess={handleCloseModOrder}
        />
      </Modal>

      <ModDuplicateAppointment
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        buttonLeftColor={"red"}
        buttonLeftLabel={"ใช่"}
        buttonRightColor={"green"}
        buttonRightLabel={"ไม่"}
        duplicateAppointment={props.duplicateAppointment}
        duplicateClinicName={props.selectedAppointment?.division_name}
        duplicateDate={`${props.selectedAppointment?.date} [${props.estimatedAt}]`}
        duplicateDoctorName={props.selectedAppointment?.display_info?.provider_name}
        open={!!props.duplicateAppointment}
        reason={duplicateReason}
        reoccureDuplicateAppointment={props.reoccureDuplicateAppointment}
        size={"large"}
        // options={options}
        title={"กรุณาตรวจสอบนัดหมาย"}
        isButtonBasic
        userTokenize={props.userTokenize}
        // callback
        onButtonLeftClick={handleConfirmDuplicate}
        onButtonRightClick={handleCancelDuplicate}
        onChangeReason={(event, data) => {
          setDuplicateReason(data?.value);
        }}
        onClose={handleCancelDuplicate}
      />
    </div>
  );
};

export default React.memo(CardAppointmentDetail);
