import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Label,
  TextArea
} from 'semantic-ui-react'

const CardSurgicalSafetyChecklistSigninUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px", padding: "20px", backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <label
              style={{ fontWeight: "bold", fontSize: "18px" }}>
              Surgical Safety Checklist - Sign in
            </label>
            <Label>
              {props.status}
            </Label>
          </div>
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px", fontWeight: "bold" }}>
          
          <label
            style={{ width: "30%", margin: "5px 10px 0px 0px" }}>
            Does Patient have a:
          </label>
          <label>
            {props.previous_operating}
          </label>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Patient Identify
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.patient_identify}
            label={props.surgicalSafety?.data?.patient_identify ? "Yes" : "No"}
            onChange={props.handleChangeValue("patient_identify")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Site Marked
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.site_marked}
            label={props.surgicalSafety?.data?.site_marked ? "Applicable" : "Not applicable"}
            onChange={props.handleChangeValue("site_marked")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Known allergy
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.know_allergy}
            label={props.surgicalSafety?.data?.know_allergy ? "Yes" : "No"}
            onChange={props.handleChangeValue("know_allergy")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Anesthesia safety check complete
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.anesthesia_check}
            label={props.surgicalSafety?.data?.anesthesia_check ? "Yes" : "No"}
            onChange={props.handleChangeValue("anesthesia_check")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Difficult airway/aspiration risk
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.airway_risk}
            label={props.surgicalSafety?.data?.airway_risk ? "Yes" : "No"}
            onChange={props.handleChangeValue("airway_risk")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Pulse Oximeter on patient and functioning
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.pulse_oximeter}
            label={props.surgicalSafety?.data?.pulse_oximeter ? "Yes" : "No"}
            onChange={props.handleChangeValue("pulse_oximeter")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              {"Risk of > 500ml blood loss (7 ml/kg in children)"}
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.risk}
            label={props.surgicalSafety?.data?.risk ? "Yes" : "No"}
            onChange={props.handleChangeValue("risk")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Special instrument and Implant check completed
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.special_instrument}
            label={props.surgicalSafety?.data?.special_instrument ? "Yes" : "No"}
            onChange={props.handleChangeValue("special_instrument")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          className="ui form"
          style={{ display: "flex", paddingBottom: "5px" , width: "100%"}}>
          
          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Blood component
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.blood}
            label={props.surgicalSafety?.data?.blood ? "Yes" : "No"}
            onChange={props.handleChangeValue("blood")}
            toggle={true}>
          </Checkbox>
          <TextArea
            name="bloodRemark"
            onChange={props.handleChangeValue("bloodRemark")}
            style={{ width: "30%", margin: "0px 30px", ...(!props.surgicalSafety?.data?.blood && { display: "none" }) }}
            value={props.surgicalSafety?.data?.bloodRemark}>
          </TextArea>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px", fontWeight: "bold" }}>
          
          <label
            style={{ width: "30%", margin: "5px 10px 0px 0px" }}>
            Blood component available
          </label>
          <label>
            {props.previous_operating}
          </label>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px", display: "flex", justifyContent: "flex-end" }}>
          
          <div>
            {props.buttonSave}
          </div>
          <div
            style={{margin: "0px 10px"}}>
            {props.buttonConfirm}
          </div>
          <div>
            {props.buttonUnconfirm}
          </div>
        </div>
      </div>
    )
}


export default CardSurgicalSafetyChecklistSigninUX

export const screenPropsDefault = {}

/* Date Time : Thu Jun 27 2024 09:45:46 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 125,
      "void": true
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Does Patient have a:"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.previous_operating"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\", display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient Identify"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Checkbox",
      "parent": 77,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.patient_identify"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.patient_identify ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"patient_identify\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "Site Marked"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Checkbox",
      "parent": 82,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.site_marked"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.site_marked ? \"Applicable\" : \"Not applicable\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"site_marked\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "Known allergy"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Checkbox",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.know_allergy"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.know_allergy ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"know_allergy\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia safety check complete"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Checkbox",
      "parent": 92,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.anesthesia_check"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.anesthesia_check ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"anesthesia_check\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Difficult airway/aspiration risk"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Checkbox",
      "parent": 97,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.airway_risk"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.airway_risk ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"airway_risk\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "Pulse Oximeter on patient and functioning"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Checkbox",
      "parent": 102,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.pulse_oximeter"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.pulse_oximeter ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"pulse_oximeter\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 108,
      "props": {
        "children": {
          "type": "code",
          "value": "\"Risk of > 500ml blood loss (7 ml/kg in children)\""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Checkbox",
      "parent": 107,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.risk"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.risk ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"risk\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "Special instrument and Implant check completed"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Checkbox",
      "parent": 112,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.special_instrument"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.special_instrument ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"special_instrument\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" , width: \"100%\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood component"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "label",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Checkbox",
      "parent": 117,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.blood"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.blood ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"blood\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood component available"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "code",
          "value": "props.previous_operating"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical Safety Checklist - Sign in"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Label",
      "parent": 125,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "TextArea",
      "parent": 117,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "bloodRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"bloodRemark\")"
        },
        "rows": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", margin: \"0px 30px\", ...(!props.surgicalSafety?.data?.blood && { display: \"none\" }) }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.bloodRemark"
        }
      },
      "seq": 134,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSurgicalSafetyChecklistSigninUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
