import React, { useEffect, useMemo, useState } from "react";
import { Image, Modal } from "semantic-ui-react";
import { mapOptions } from "react-lib/apps/HISV3/common/CommonInterface";
import { makeStyles } from "@mui/styles";
import CardSystemicEvaluationUX from "react-lib/apps/HISV3/ANE/CardSystemicEvaluationUX";
import CardAirwayTeethHeadNeckUX from "react-lib/apps/HISV3/ANE/CardAirwayTeethHeadNeckUX";
import CardHistoryCentralLab from "react-lib/apps/HISV3/LAB/CardHistoryCentralLab";

const useStyles = makeStyles(() => ({
  bgCard: {
    backgroundColor: "#EDF6F9",
    width: "100%",
    padding: "20px 10px 10px",
    borderRadius: 3,
    border: "solid 0.5px ",
    borderColor: "#5DBCD2",
    overflow: "auto",
  },
  labIcon: {
    width: "31px",
    height: "28px",
    borderRadius: "3px",
    border: "0.5px solid #BED0E8",
    background: "#56B3EF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  drugIcon: {
    width: "60px",
    height: "31px",
    flexShrink: "0",
    borderRadius: "7px",
    border: "1px solid #F3C7F4",
    background: "#C66ACE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const IMAGES = {
  ClassI: "/static/images/mallampati/mallampatiClass1.png",
  ClassII: "/static/images/mallampati/mallampatiClass2.png",
  ClassIII: "/static/images/mallampati/mallampatiClass3.png",
  ClassIV: "/static/images/mallampati/mallampatiClass4.png",
  lab: "/static/images/mallampati/icomoon-free_lab.png",
  drugs: "/static/images/mallampati/mdi_drugs.png",
};

type CardPhysicianEvaluationProps = {
  // function
  onEvent: any;
  setProp: any;
  runSequence: any;
  buttonLoadCheck: any;

  // options
  masterOptions?: any;
  masterData?: any;
  PreAnestheticSequence?: any;
};

const CardPhysicianEvaluation = (props: CardPhysicianEvaluationProps) => {
  const classes = useStyles();
  const [endrocrineOptions, setEndrocrineOptions] = useState<any[]>([]);
  const [respiratoryOptions, setRespiratoryOptions] = useState<any[]>([]);
  const [openModHistoryCentralLab, setOpenModHistoryCentralLab] = useState<boolean>(false);

  useEffect(() => {
    props.runSequence({
      sequence: "PreAnesthetic",
      action: "getPhysicianEvaluation",
    });
  }, []);

  const asaClassOptions = useMemo(() => {
    return mapOptions(
      props.PreAnestheticSequence?.clinicalTerm?.asa_class?.items || [],
      "id",
      "name"
    );
  }, [props.PreAnestheticSequence?.clinicalTerm]);

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      if (systemicEvaluationCheck.includes(data?.name) && !data.checked) {
        let name = data?.name.split("Check");
        props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation`, {
          ...props.PreAnestheticSequence?.anesPhysicianEvaluation,
          [`${data.name}`]: data.checked,
          [`${name?.[0]}Detail`]: "",
          ...(data.name === "ekgCheck" && { ekgDate: ""})
        });
      } else {
        props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation.${data.name}`, data.checked);
      }
    } else {
      if (data.name === "systemicEvaluationType" && data.value === "Normal") {
        props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation`, {
          ...props.PreAnestheticSequence?.anesPhysicianEvaluation,
          [`${data.name}`]: data.value,
          ...systemicEvaluationReset,
        });
      } else {
        props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation.${data.name}`, data.value);
      }
    }
  };

  const handleAddEndrocrineOptions = (e: any, { value }: any) => {
    setEndrocrineOptions([{ text: value, value }, ...endrocrineOptions]);
  };

  const handleAddRespiratoryOptions = (e: any, { value }: any) => {
    setRespiratoryOptions([{ text: value, value }, ...respiratoryOptions]);
  };

  return (
    <div
      id="CardPreAnestheticAirwayEvaluation"
      style={{ width: "100%", height: "100%", padding: "10px" }}
    >
      <div className={classes.bgCard}>
        <CardSystemicEvaluationUX
          systemicEvaluation={props.PreAnestheticSequence?.anesPhysicianEvaluation}
          handleChangeData={handleChangeData}
          handleChangeLabDate={(date: any) =>
            props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation.labDataDate`, date)
          }
          handleChangeEKGDate={(date: any) =>
            props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation.ekgDate`, date)
          }
          handleAddRespiratoryOptions={handleAddRespiratoryOptions}
          handleAddEndrocrineOptions={handleAddEndrocrineOptions}
          respiratoryOptions={respiratoryOptions}
          endrocrineOptions={endrocrineOptions}
          systemicEvaluationOptions={[
            { key: "Normal", text: "Normal", value: "Normal" },
            { key: "Abnormal", text: "Abnormal", value: "Abnormal" },
          ]}
          logoLab={
            <div className={classes.labIcon} onClick={() => setOpenModHistoryCentralLab(true)}>
              <div>
                <Image height="100%" src={IMAGES?.lab} />
              </div>
            </div>
          }
          onSaveSystemicEvaluation={() => {
            props.runSequence({
              sequence: "PreAnesthetic",
              action: "savAnesPhysicianEvaluation",
            });
          }}
        />
        <CardAirwayTeethHeadNeckUX
          masterOptions={props.masterOptions}
          systemicEvaluation={props.PreAnestheticSequence?.anesPhysicianEvaluation}
          handleChangeData={handleChangeData}
          asaClassOptions={asaClassOptions}
          dentalOptions={[
            { key: 1, text: "Yes", value: "Yes" },
            { key: 2, text: "No", value: "No" },
          ]}
          mallampatiGradeOptions={[
            {
              key: 1,
              text: "Class I: Soft palate, uvala, fauces, pillars visible.",
              value: "ClassI",
            },
            {
              key: 2,
              text: "Class II: Soft palate, uvula, fauces visible.",
              value: "ClassII",
            },
            {
              key: 3,
              text: "Class III: Soft palate, base of uvula visible.",
              value: "ClassIII",
            },
            {
              key: 4,
              text: "Class IV:Only hard palate visible.",
              value: "ClassIV",
            },
          ]}
          mallampatiImg={
            <Image
              height="100%"
              src={
                IMAGES?.[props.PreAnestheticSequence?.anesPhysicianEvaluation?.mallampatiGradeType]
              }
            />
          }
          medication={
            <div className={classes.drugIcon}>
              <div>
                <Image height="100%" src={IMAGES?.drugs} />
              </div>
            </div>
          }
        />
      </div>

      {/* {openModHistoryCentralLab && (
        <Modal
          size="large"
          closeIcon
          open={openModHistoryCentralLab}
          onClose={() => setOpenModHistoryCentralLab(false)}
        >
        </Modal>
      )} */}
    </div>
  );
};

export default React.memo(CardPhysicianEvaluation);

const systemicEvaluationReset = {
  respiratoryCheck: false,
  cardiovascularCheck: false,
  neurologicCheck: false,
  eentCheck: false,
  giCheck: false,
  genitourinaryCheck: false,
  endrocrineCheck: false,
  skinCheck: false,
  immunologyCheck: false,
  respiratoryDetail: "",
  cardiovascularDetail: "",
  neurologicDetail: "",
  eentDetail: "",
  giDetail: "",
  genitourinaryDetail: "",
  endrocrineDetail: "",
  skinDetail: "",
  immunologyDetail: "",
};

const systemicEvaluationCheck = [
  "respiratoryCheck",
  "cardiovascularCheck",
  "neurologicCheck",
  "eentCheck",
  "giCheck",
  "genitourinaryCheck",
  "endrocrineCheck",
  "skinCheck",
  "immunologyCheck",
  // Other Investigations
  "xRayCheck",
  "imagingCheck",
  "ekgCheck",
  "otherCheck",
  // Planned anesthesia
  "plannedOtherCheck",
  "airwayOtherCheck",
  "intubationOtherCheck",
  "emergencyCheck",
];
