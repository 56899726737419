import React, {
  ReactElement,
  useMemo,
  useCallback,
  useState,
  useEffect,
  SyntheticEvent,
} from "react";
import {
  Dropdown,
  DropdownProps,
  Form,
  FormField,
  FormGroup,
  Modal,
  ModalContent,
} from "semantic-ui-react";

import moment from "moment";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

// Types
type ModFinancialReportTemplateProps = {
  onEvent: (e: any) => any;
  setProp: (key: any, value: any, callback?: Function) => any;

  // data
  open: boolean;
  title: string;
  templates: Templates;
  isLoadingStationLog?: boolean;
  // options
  masterOptions?: Record<string, any>;
  stationLogOptions?: any[];
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // callback
  onPreview: (data: TemplateDetail, btnAction: string) => void;
  onPrint: (data: TemplateDetail, btnAction: string) => void;
  onClose: () => void;
  onGetStationLog?: GetStationLogHandler;
};

export type GetStationLogHandler = (
  data: Pick<TemplateDetail, "start_date" | "end_date" | "station">
) => void;

export type Templates = {
  type: TemplateTypes;
  label?: string;
  width: number;
  required?: boolean;
  labelWidth?: number;
  default?: string | number;
  options?: Record<string, any>[];
}[][];

type TemplateTypes =
  | "start_date"
  | "end_date"
  | "coverage"
  | "payer"
  | "station"
  | "station_log"
  | "encounter_type"
  | "division"
  | "status";

type TemplateConfig = {
  labelWidth?: number;
  label?: string;
  key?: keyof TemplateDetail;
  options?: any[];
  loading?: boolean;
};

export type TemplateDetail = Partial<
  {
    start_date: string;
    end_date: string;
    encounter_type: string;
  } & {
    [key in "coverage" | "payer" | "station" | "station_log" | "division" | "status"]: number;
  }
>;

// Const
const PATIENT_TYPE_OPTIONS = [
  { key: 1, value: "OPD", text: "OPD" },
  { key: 1, value: "IPD", text: "IPD" },
  { key: 1, value: "ALL", text: "OPD และ IPD" },
];

const LABEL_KEYS: Record<TemplateTypes, { label: string; key: keyof TemplateDetail }> = {
  start_date: { label: "วันที่ออกเอกสาร", key: "start_date" },
  end_date: { label: "ถึง", key: "end_date" },
  coverage: { label: "สิทธิ", key: "coverage" },
  payer: { label: "ต้นสังกัด", key: "payer" },
  station: { label: "Station", key: "station" },
  station_log: { label: "Shift", key: "station_log" },
  encounter_type: { label: "ประเภทผู้ป่วย", key: "encounter_type" },
  division: { label: "คลิกนิกที่รับบริการ", key: "division" },
  status: { label: "สถานะส่งเบิก", key: "status" },
};

const BUTTON_ACTIONS = {
  preview: "PREVIEW_REPORT",
  print: "PRINT_REPORT",
};

const MOD_FINANCIAL_REPORT_TEMPLATE = "ModFinancialReportTemplate";

export const ACTION_PREVIEW = `${MOD_FINANCIAL_REPORT_TEMPLATE}_${BUTTON_ACTIONS.preview}`;
export const ACTION_PRINT = `${MOD_FINANCIAL_REPORT_TEMPLATE}_${BUTTON_ACTIONS.print}`;

const ModFinancialReportTemplate = (props: ModFinancialReportTemplateProps) => {
  const [detail, setDetail] = useState<TemplateDetail>({});

  // Callback Effect
  const initialTemplateData = useCallback(() => {
    const flattedTemplates = templates.flat() as Templates[number];

    const data: TemplateDetail = flattedTemplates.reduce<any>((acc, template) => {
      const { type } = template;
      const key = LABEL_KEYS[type].key;

      if (template.default) {
        acc[key] = template.default;
      } else if (["start_date", "end_date"].includes(type)) {
        acc[key] = formatDate(moment());
      } else if (type === "encounter_type") {
        acc[key] = "ALL";
      } else if (["coverage", "payer", "station", "division", "station_log"].includes(type)) {
        acc[key] = 0;
      }

      return acc;
    }, {});

    setDetail({ ...data });
  }, []);

  // Effect
  useEffect(() => {
    if (props.open) {
      initialTemplateData();
    }
  }, [props.open]);

  useEffect(() => {
    if (Object.keys(detail).length) {
      props.onGetStationLog?.({
        start_date: detail.start_date,
        end_date: detail.end_date,
        station: detail.station,
      });
    }
  }, [detail.start_date, detail.end_date, detail.station]);

  useEffect(() => {
    if (detail.station_log) {
      const stationLog = props.stationLogOptions?.find(
        (option) => option.value === detail.station_log
      );

      if (!stationLog) {
        setDetail((prevDetail) => ({ ...prevDetail, station_log: 0 }));
      }
    }
  }, [props.stationLogOptions, detail.station_log]);

  // Callback
  const handleChangeDate = useCallback(
    (key?: keyof TemplateDetail) => (date: string) => {
      if (key) {
        setDetail((detail) => ({ ...detail, [key]: date }));
      }
    },
    []
  );

  const handleChangeDropdown = useCallback(
    (key?: keyof TemplateDetail) => (e: SyntheticEvent, data: DropdownProps) => {
      if (key) {
        setDetail((detail) => ({ ...detail, [key]: data.value }));
      }
    },
    []
  );

  const renderDate = useCallback(
    (props: TemplateConfig) => {
      const value = props.key ? detail[props.key] : "";

      return (
        <DateComponent
          label={props.label || ""}
          labelWidth={props.labelWidth}
          key={props.key}
          value={value}
          onChange={handleChangeDate(props.key)}
        />
      );
    },
    [detail]
  );

  const renderDropdown = useCallback(
    (props: TemplateConfig) => {
      const value = props.key ? detail[props.key] : "";

      return (
        <DropdownComponent
          label={props.label || ""}
          options={props.options || []}
          labelWidth={props.labelWidth}
          key={props.key}
          loading={props.loading}
          value={value}
          onChange={handleChangeDropdown(props.key)}
        />
      );
    },
    [detail]
  );

  // Use Memo
  const coverageOptions = useMemo(() => {
    return [{ key: 0, value: 0, text: "ทุกสิทธิ" }, ...(props.masterOptions?.coverage || [])];
  }, [props.masterOptions?.coverage]);

  const stationOptions = useMemo(() => {
    return [{ key: 0, value: 0, text: "ทุก Station" }, ...(props.masterOptions?.bilStation || [])];
  }, [props.masterOptions?.bilStation]);

  const payerOptions = useMemo(() => {
    return [{ key: 0, value: 0, text: "ทุกต้นสังกัด" }, ...(props.masterOptions?.payer || [])];
  }, [props.masterOptions?.payer]);

  const divisionOptions = useMemo(() => {
    return [{ key: 0, value: 0, text: "ทุกคลินิก" }, ...(props.masterOptions?.division || [])];
  }, [props.masterOptions?.division]);

  const stationLogOptions = useMemo(() => {
    return [{ key: 0, value: 0, text: "ทุกกะ" }, ...(props.stationLogOptions || [])];
  }, [props.stationLogOptions]);

  const templates = useMemo(() => {
    return props.templates.map((template) =>
      template.map((component) => ({
        ...component,
        label: component.label || LABEL_KEYS[component.type].label,
      }))
    );
  }, [props.templates]);

  const components = useMemo(() => {
    const renderedDate =
      (data: { key: keyof Pick<TemplateDetail, "start_date" | "end_date"> }) =>
      (props: TemplateConfig) =>
        renderDate({ ...data, ...props });

    const renderedDropdown =
      (data: {
        key: keyof Omit<TemplateDetail, "startDate" | "endDate">;
        options: any[];
        loading?: boolean;
      }) =>
      (props: TemplateConfig) =>
        renderDropdown({ ...data, ...props });

    return {
      start_date: renderedDate({ key: "start_date" }),
      end_date: renderedDate({ key: "end_date" }),
      coverage: renderedDropdown({ key: "coverage", options: coverageOptions }),
      payer: renderedDropdown({ key: "payer", options: payerOptions }),
      station: renderedDropdown({ key: "station", options: stationOptions }),
      station_log: renderedDropdown({
        key: "station_log",
        options: stationLogOptions,
        loading: props.isLoadingStationLog,
      }),
      encounter_type: renderedDropdown({
        key: "encounter_type",
        options: PATIENT_TYPE_OPTIONS,
      }),
      division: renderedDropdown({
        key: "division",
        options: divisionOptions,
      }),
      status: renderedDropdown({ key: "status", options: [] }),
    } as Record<TemplateTypes, (props: TemplateConfig) => ReactElement>;
  }, [
    coverageOptions,
    stationOptions,
    payerOptions,
    divisionOptions,
    stationLogOptions,
    renderDate,
    renderDropdown,
    props.isLoadingStationLog,
  ]);

  // Handler
  const getPrepareData = () => {
    const flattedTemplates = templates.flat() as Templates[number];

    const requiredEnd = !!flattedTemplates.find(
      (item) => item.required && item.type === "start_date" && !detail.end_date
    );

    const filteredRequired = flattedTemplates
      .filter((template) => {
        const value = detail[LABEL_KEYS[template.type].key];

        return template.required && !detail[LABEL_KEYS[template.type].key] && value !== 0;
      })
      .map((item) => item.label);

    if (requiredEnd) {
      filteredRequired.push(LABEL_KEYS.start_date.label);
    }

    const errors = Array.from(new Set(filteredRequired));

    const filteredDetail = Object.entries(detail).filter(
      ([key, value]) => value && value !== "ALL"
    );

    return {
      error: errors.length ? { กรอกข้อมูลที่จำเป็น: errors } : null,
      data: Object.fromEntries(filteredDetail),
    };
  };

  const handlePreview = () => {
    const prepareData = getPrepareData();

    if (prepareData.error) {
      props.setProp(`errorMessage?.${ACTION_PREVIEW}`, prepareData.error);
    } else {
      props.onPreview(prepareData.data, ACTION_PREVIEW);
    }
  };

  const handlePrint = () => {
    const prepareData = getPrepareData();

    if (prepareData.error) {
      props.setProp(`errorMessage?.${ACTION_PRINT}`, prepareData.error);
    } else {
      props.onPrint(prepareData.data, ACTION_PRINT);
    }
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${ACTION_PREVIEW}`, null);
        }}
        error={props.errorMessage?.[ACTION_PREVIEW]}
        success={null}
      />

      <Modal open={props.open} closeOnDimmerClick onClose={props.onClose}>
        <ModalContent style={{ padding: "1.25rem" }}>
          <div style={{ padding: "0.25rem 0 1.25rem", fontSize: "1.05rem" }}>
            <strong>{props.title}</strong>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
            <div>
              {/* @ts-ignore */}
              <Form size="">
                {templates.map((template, index) => (
                  <FormGroup key={"template-" + index} inline>
                    {template.map((component) => (
                      <FormField
                        className={component.required ? "required" : ""}
                        key={"component-" + component.type}
                        width={component.width}
                      >
                        {components[component.type]?.(component)}
                      </FormField>
                    ))}
                  </FormGroup>
                ))}
              </Form>
            </div>
            <div style={{ display: "flex", flexDirection: "column", paddingLeft: "2rem" }}>
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handlePreview}
                // data
                paramKey={ACTION_PREVIEW}
                buttonLoadCheck={props.buttonLoadCheck?.[ACTION_PREVIEW]}
                // config
                color="yellow"
                name={BUTTON_ACTIONS.preview}
                size="small"
                style={{ width: "10rem" }}
                title="Preview"
              />
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handlePrint}
                // data
                paramKey={ACTION_PRINT}
                buttonLoadCheck={props.buttonLoadCheck?.[ACTION_PRINT]}
                // config
                color="blue"
                name={BUTTON_ACTIONS.print}
                size="small"
                style={{ marginTop: "1.45rem", width: "10rem" }}
                title="พิมพ์"
              />
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

/* ------------------------------------------------------ */

/*                     Date Component                     */

/* ------------------------------------------------------ */
type DateComponentProps = {
  label: string;
  value?: string | number;
  labelWidth?: number;
  // callback
  onChange: (date: string) => void;
};

const DateComponent = (props: DateComponentProps) => {
  return (
    <>
      <label
        htmlFor="financial-date-text-box"
        style={{
          minWidth: "max-content",
          ...(props.labelWidth ? { minWidth: props.labelWidth, maxWidth: props.labelWidth } : {}),
        }}
      >
        {props.label}
      </label>
      <div style={{ width: "100%", color: "rgba(0,0,0,.87)" }}>
        <DateTextBox id="financial-date-text-box" value={props.value} onChange={props.onChange} />
      </div>
    </>
  );
};

/* ------------------------------------------------------ */

/*                   DropdownComponent;                   */

/* ------------------------------------------------------ */
type DropdownComponentProps = {
  label: string;
  value?: string | number;
  labelWidth?: number;
  loading?: boolean;
  // options
  options: any[];
  // callback
  onChange: (e: SyntheticEvent, data: DropdownProps) => void;
};

const DropdownComponent = (props: DropdownComponentProps) => {
  return (
    <>
      <label
        htmlFor="financial-dropdown"
        style={{
          minWidth: "max-content",
          ...(props.labelWidth ? { minWidth: props.labelWidth, maxWidth: props.labelWidth } : {}),
        }}
      >
        {props.label}
      </label>
      <Dropdown
        id="financial-dropdown"
        className="inline-label"
        fluid
        selection
        search
        value={props.value}
        options={props.options}
        loading={props.loading}
        style={{ width: "100%", fontSize: "1em" }}
        // callback
        onChange={props.onChange}
      />
    </>
  );
};

ModFinancialReportTemplate.displayName = "ModFinancialReportTemplate";

export default React.memo(ModFinancialReportTemplate);
