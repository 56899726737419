import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const CardProgressNoteListUX = (props: any) => {
    return(
      <div>
        <div
          className="accordion ui fluid blue raised segment"
          style={{boxShadow: "none", border: "none", borderRadius: 0}}>
          
          <label
            style={{fontSize: "1.25rem", fontWeight: "bold"}}>
            Progress Note
          </label>
          <div
            className="ui divider">
            
          </div>
          <Table
            data={props.progressionNoteItems}
            headers="วัน-เวลาที่แก้ไข,แพทย์ผู้บันทึก,Subjective,Objective,Assessment,Plan,Menu"
            keys="last_edit,reporter,s_txt,o_txt,a_txt,p_txt,menu"
            showPagination={false}
            style={{height: "65vh"}}>
          </Table>
          <div
            style={{display: "flex", justifyContent: "flex-end", marginTop: "15px"}}>
            
            <Button
              color="green"
              disabled={props.hideButtonCreate || false}
              onClick={props.onCreate}
              style={{display: props.hideButtonCreate ? "none" : ""}}>
              สร้าง Progress Note
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardProgressNoteListUX

export const screenPropsDefault = {"by":"order","title":"MEDICAL FEE","titleDescription":"ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"}

/* Date Time : Fri Nov 10 2023 07:58:05 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "accordion ui fluid blue raised segment"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", border: \"none\", borderRadius: 0}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Progress Note"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.25rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 1,
      "props": {
        "data": {
          "type": "code",
          "value": "props.progressionNoteItems"
        },
        "headers": {
          "type": "value",
          "value": "วัน-เวลาที่แก้ไข,แพทย์ผู้บันทึก,Subjective,Objective,Assessment,Plan,Menu"
        },
        "keys": {
          "type": "value",
          "value": "last_edit,reporter,s_txt,o_txt,a_txt,p_txt,menu"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"65vh\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"15px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้าง Progress Note"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.hideButtonCreate || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCreate"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideButtonCreate ? \"\" : \"\"}"
        }
      },
      "seq": 6,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "CardProgressNoteListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "by": "order",
    "title": "MEDICAL FEE",
    "titleDescription": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
  },
  "width": 80
}

*********************************************************************************** */
