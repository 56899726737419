import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Button, Segment } from 'semantic-ui-react';
import { Table } from "react-lib/frameworks/Table";

/**
 * A modal display the settings of shift on the given division.
 */
export default class ModAliasName extends React.Component<any, any> {
  static propTypes = {
    textContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.element]),
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.element]),
    titleName:  PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    size: PropTypes.string,
    approveButtonText: PropTypes.string,
    denyButtonText: PropTypes.string,
    approveButtonColor: PropTypes.string,
    denyButtonColor: PropTypes.string,
    buttonClassName: PropTypes.string,
    loading: PropTypes.bool,
    openModal: PropTypes.bool,
    onApprove: PropTypes.func,
    onDeny: PropTypes.func, 
    basic: PropTypes.bool,
    // loading: PropTypes.bool,
    // Handle closeOnDimmerClick event
    onCloseWithDimmerClick: PropTypes.func,
  };

  static defaultProps = {
    textContent: null,
    content: null,
    data: [],
    backgroundColor: null,
    titleName: 'แจ้งเตือน!!! ชื่อคล้าย',
    size: 'large',
    approveButtonText: 'ใช่',
    denyButtonText: 'ไม่',
    approveButtonColor: 'green',
    denyButtonColor: 'red',
    buttonClassName: 'centered five wide',
    loading: false,
    openModal: false,
    basic: true,
    // loading: false,
    testId: '',
    // Handle closeOnDimmerClick event
    onCloseWithDimmerClick: () => {},
  };

  render() {
    const {
      textContent,
      content,
      titleName,
      size,
      basic,
      titleColor,
      approveButtonText,
      denyButtonText,
      approveButtonColor,
      denyButtonColor,
      buttonClassName,
      loading,
      openModal,
      backgroundColor,
      testId,
      className,
      data
    } = this.props;

    let headerColor = titleColor

    if(!titleColor && !backgroundColor){
      headerColor = 'orange'
    }

    return (
      <Modal
        open={openModal}
        size={size}
        testid={'modConfirm' + testId}
        closeOnDimmerClick={true}
        onClose={this.props.onCloseWithDimmerClick}
        className={className}
      >
        <Segment className={'inverted ' + headerColor} style={{ ...styles.header, backgroundColor: backgroundColor }}>{titleName}</Segment>
        <Segment basic loading={this.props.loading} style={styles.content}>
          {textContent}
          <Table
            data={data}
            defaultPageSize={5}
            headers="HN, Citizen ID / Passport No., ชื่อ นามสกุล เดิม (ไทย), ชื่อ นามสกุล เดิม (อังกฤษ), วัน/เดือน/ปีเกิด, ชื่อมารดา, ชื่อเดิม"
            keys="hn,citizen_passport,full_name_th,full_name_en,birthdate,mother,old_name"
            pageSize={5}
            showPagination={false}
            style={{ height: "200px" }}>
          </Table>
          <div style={{ margin:"1.5em 0px 1em ", fontSize:"18px", fontWeight:"bold" }}>
            {content}
          </div>
          <Grid>
            <Grid.Column
              style={{ display: !approveButtonText ? 'none' : null }}
              className={buttonClassName}
            >
              <Button
                basic={basic}
                fluid
                color={approveButtonColor}
                onClick={this.props.onApprove}
              >
                {approveButtonText}
              </Button>
            </Grid.Column>
            <Grid.Column
              style={{ display: !denyButtonText ? 'none' : null }}
              className={buttonClassName}
            >
              <Button
                basic={basic}
                fluid
                color={denyButtonColor}
                onClick={this.props.onDeny}
              >
                {denyButtonText}
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>
    );
  }
}

const styles = {
  header: {
    borderRadius: 0,
    fontWeight: 'bold'
  },
  content: {
    lineHeight: '1.5em'
  }
}
