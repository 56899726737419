import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Menu, Grid, Input, Modal } from "semantic-ui-react";
import PureReactTable, { alignCenter } from "../common/PureReactTable";
import { formatComma } from "../../utils";
import * as CONSTANT from "../../utils/constant";
import * as Common from "../common";
import * as TPD from '../TPD';
import {
  ModConfirm as ModConfirmDeleteProgression,
  ModConfirm as ModConfirmEditProgressionNote,
  ModConfirm as ModConfirmDeleteOrder,
  ModInfo as ModError,
  ModInfo as ModSuccess
} from "../common";

const CardProgressCycle = React.forwardRef((props, ref) => {
  const [progressionData, setProgressionData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openModConfirmDelete, setOpenModConfirmDelete] = React.useState(false);
  const [openModConfirmEdit, setOpenModConfirmEdit] = React.useState(false);
  const [openModError, setOpenModError] = React.useState(false);
  const [openModSuccess, setOpenModSuccess] = React.useState(false);
  const [modInfoAlertText, setModInfoAlertText] = React.useState(null);
  const [selectedCell, setSelectedCell] = React.useState({});
  const [doctorOrderData, setDoctorOrderData] = React.useState([]);
  const [doctorOrderSelectedRow, setDoctorOrderSelectedRow] = React.useState(
    {}
  );
  const [totalPrice, setTotalPrice] = React.useState(100000);
  const [claimablePrice, setClaimablePrice] = React.useState(0);
  const [nonClaimablePrice, setNonClaimablePrice] = React.useState(0);

  const [openModOPDStatOrder, setOpenModOPDStatOrder] = React.useState(false)

  const [
    modDoctorOrderDeleteText,
    setModDoctorOrderDeleteText
  ] = React.useState("");
  const [
    openModDoctorOrderDelete,
    setOpenModDoctorOrderDelete
  ] = React.useState(false);

  const inputRef = React.useRef([]);

  React.useEffect(() => {
    getProgressionCycle();
  }, []);

  React.useEffect(() => {
    getDoctorOrder();
  }, [selectedRow]);

  React.useEffect(() => {
    sumPrice();
  }, [doctorOrderData]);

  ////////////////////////////////////////////////// API //////////////////////////////////////////////////

  const getProgressionCycle = async () => {
    if (!props.patientData.EMR.emr_id) {
      return;
    }
    setIsLoading(true);
    const [data, error] = await props.controller.getProgressionCycle({
      emrId: props.patientData.EMR.emr_id
    });
    if (data && data.items) {
      setProgressionData(data.items);
      setSelectedRow(data.items[0]);
    } else if (error) {
      setOpenModError(true);
    }
    setIsLoading(false);
  };

  const createProgressionCycle = async () => {
    if (!props.patientData.EMR.emr_id) {
      return;
    }
    setIsLoading(true);
    const [data, error] = await props.controller.createProgressionCycle({
      emrId: props.patientData.EMR.emr_id
    });
    if (error) {
      if (error.non_field_errors !== undefined) {
        setModInfoAlertText(error.non_field_errors[0]);
      } else {
        setModInfoAlertText(error[0]);
      }
      setOpenModError(true);
    } else {
      getProgressionCycle();
    }
    setIsLoading(false);
  };

  const deleteProgressionCycle = async () => {
    setOpenModConfirmDelete(false);
    if (!props.patientData.EMR.progression_cycle_id) {
      return;
    }
    setIsLoading(true);
    const [data, error] = await props.controller.deleteProgressionCycle({
      progressionId: selectedRow.id
    });
    if (error) {
      setOpenModError(true);
    } else {
      getProgressionCycle();
    }
    setIsLoading(false);
  };

  const putEditProgression = async ({
    emrId,
    progressionNote,
    progressionId
  }) => {
    if (!props.patientData.EMR.progression_cycle_id) {
      return;
    }
    setIsLoading(true);
    const [data, error] = await props.controller.putProgressionCycle({
      emrId,
      progressionNote,
      progressionId
    });
    if (error) {
      setOpenModError(true);
    } else {
      getProgressionCycle();
    }
    setIsLoading(false);
  };

  const getDoctorOrder = async () => {
    if (
      !props.patientData.EMR.progression_cycle_id ||
      !props.patientData.EMR.emr_id ||
      !selectedRow
    ) {
      return;
    }
    setIsLoading(true);
    const [data, error] = await props.controller.getDoctorOrder({
      emrId: selectedRow.emr,
      progressionId: selectedRow.id
    });
    if (error) {
      setOpenModError(true);
    } else if (data) {
      setDoctorOrderData(data);
    }
    setIsLoading(false);
  };

  const deleteDoctorOrder = async () => {
    setOpenModDoctorOrderDelete(false);
    if (!doctorOrderSelectedRow.id) {
      return;
    }
    setIsLoading(true);
    const [data, error] = await props.controller.putDoctorOrder({
      orderId: doctorOrderSelectedRow.id,
      action: "REQUEST"
    });
    if (error) {
      setOpenModError(true);
    } else {
      setOpenModSuccess(true);
      getDoctorOrder();
    }
    setIsLoading(false);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleEditProgressionNote = async ({ progressionNote, index }) => {
    setSelectedCell({});
    let note = progressionNote;
    if (!progressionNote) {
      note = inputRef.current[index].getValue();
    }
    if (note === progressionData[index].progression_note) {
      return;
    }
    handleSetProgressionData({ field: "progression_note", index, value: note });
    setOpenModConfirmEdit(true);
  };

  const editProgressionCycle = () => {
    setOpenModConfirmEdit(false);
    let index = progressionData.findIndex(item => item.id === selectedRow.id);
    putEditProgression({
      emrId: selectedRow.emr,
      progressionNote: progressionData[index].progression_note,
      progressionId: selectedRow.id
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleDoctorOrderRemoveButton = ({ type }) => {
    setModDoctorOrderDeleteText(type);
    setOpenModDoctorOrderDelete(true);
  };

  const handleEditDoctorOrder = ({ row }) => {
    if (row.specific_type_code === SPECIFIC_TYPE_CODE.DRUG_STAT_ORDER) {
      handleOpenModDrugOPDStatOrder({ row });
    } else if (
      row.specific_type_code === SPECIFIC_TYPE_CODE.DRUG_ONE_DOSE_ORDER
    ) {
      handleOpenModDrugOPDOneDoseOrder({ row });
    } else if (
      row.specific_type_code === SPECIFIC_TYPE_CODE.CENTRAL_LAB_ORDER
    ) {
      handleOpenModCentralLabOrder({ row });
    } else if (row.specific_type_code === SPECIFIC_TYPE_CODE.IME) {
    } else if (row.specific_type_code === SPECIFIC_TYPE_CODE.TREATMENT_ORDER) {
      handleOpenModTreatmentOrder({ row });
    } else if (row.specific_type_code === SPECIFIC_TYPE_CODE.MSD) {
    }
  };

  ////////////////////////////////////////// OPEN MENU CARD //////////////////////////////////////////////////

  const handleOpenModDrugOPDStatOrder = ({ row } = {}) => {
    setOpenModOPDStatOrder(true)
    // crdStatOrder.displayNone = false
    // modStatOrder.show()
  };

  const handleOpenModDrugOPDOneDoseOrder = ({ row } = {}) => {
    // crdOneDoseOrder.displayNone = false
    // modOneDoseOrder.show()
  };

  const handleOpenModCentralLabOrder = ({ row } = {}) => {
    // crdLabRequest.displayNone = false
    // crdLabRequest.allow_add = grdRequest.selectedRow.can_cancel
    // crdLabRequest.order_id = grdRequest.selectedRow.id
    // modLabRequest.show()
  };

  const handleOpenModTreatmentOrder = ({ row } = {}) => {
    // crdTreatmentOrder.displayNone = false
    // modTreatmentOrder.show()
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleSetProgressionData = async ({ field, index, value }) => {
    let newArr = progressionData;
    let data = { ...newArr[index] };
    data[field] = value;
    newArr[index] = data;
    setProgressionData(newArr);
  };

  const sumPrice = () => {
    setIsLoading(true);
    let priceTotal = 0.0;
    let priceNonClaimable = 0.0;
    let priceClaimable = 0.0;
    doctorOrderData.forEach(function(item) {
      if (item.price) {
        priceTotal += parseFloat(item.price.price_total);
        priceNonClaimable += parseFloat(item.price.price_non_claimable);
        priceClaimable += parseFloat(item.price.price_claimable);
      }
    });
    setTotalPrice(priceTotal);
    setNonClaimablePrice(priceNonClaimable);
    setClaimablePrice(priceClaimable);
    setIsLoading(false);
  };

  return (
    <Common.CardLayout titleText="Progression / order" loading={isLoading}>
      <ModConfirmEditProgressionNote
        testid="modEditProgressionNote"
        titleName="ต้องการแก้ไข Progression Note ใช่หรือไม่"
        onDeny={() => setOpenModConfirmEdit(false)}
        openModal={openModConfirmEdit}
        onApprove={editProgressionCycle}
      />
      <ModConfirmDeleteProgression
        testid="modDeleteProgression"
        openModal={openModConfirmDelete}
        titleColor="orange"
        titleName="ต้องการลบ Progression / order นี้ใช่หรือไม่"
        onDeny={() => setOpenModConfirmDelete(false)}
        onApprove={deleteProgressionCycle}
      />
      <ModConfirmDeleteOrder
        testid="modDeleteDoctorOrder"
        openModal={openModDoctorOrderDelete}
        titleColor="orange"
        titleName={`ต้องการลบ ${modDoctorOrderDeleteText} นี้ใช่หรือไม่`}
        onDeny={() => setOpenModDoctorOrderDelete(false)}
        onApprove={deleteDoctorOrder}
      />
      <ModError
        open={openModError}
        titleColor="red"
        onApprove={() => {
          setModInfoAlertText(null);
          setOpenModError(false);
        }}
        alertText={modInfoAlertText}
      />
      <ModSuccess
        open={openModSuccess}
        titleColor="green"
        onApprove={() => {
          setOpenModSuccess(false);
        }}
        titleName="บันทึกสำเร็จ"
      />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field>
            <Button
              color="green"
              content="เพิ่ม Progression / order"
              testid="btnAddProgression"
              onClick={createProgressionCycle}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <PureReactTable
        testid="progressionTable"
        showPagination={false}
        className=""
        pageSize={
          progressionData
            ? progressionData.length < 4
              ? 4
              : progressionData.length
            : 4
        }
        data={progressionData}
        selectedClassName="blueSelectedRow"
        onSelect={originalRow => setSelectedRow(originalRow)}
        selectedRow={selectedRow}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (props.readOnly) {
                return;
              }
              if (rowInfo && selectedCell[column.id] !== rowInfo.index) {
                setSelectedCell({ [column.id]: rowInfo.index });
              }
            }
          };
        }}
        columns={[
          {
            Header: "เวลาบันทึก",
            accessor: "created",
            width: 210
          },
          {
            Header: "แพทย์",
            accessor: "edit_user",
            width: 210
          },
          {
            Header: "สถานะ",
            accessor: "checkout_time",
            width: 210,
            getProps: (state, rowInfo, column) => {
              if (rowInfo && rowInfo.original.checkout_time) {
                if (rowInfo.original.is_rejected) {
                  return {
                    style: { background: "#D44C4C" }
                  };
                } else {
                  return {
                    style: { background: "#7CD44C" }
                  };
                }
              }
              return {
                style: { background: "transparent" }
              };
            },
            Cell: row => {
              if (row && row.value) {
                if (row.original.is_rejected) {
                  return <label style={styles.boldText}>REJECTED</label>;
                } else {
                  return <label style={styles.boldText}>CONFIRMED</label>;
                }
              } else {
                return <></>;
              }
            }
          },
          {
            Header: "Progression Note",
            accessor: "progression_note",
            minWidth: 570,
            Cell: row => {
              if (row.index === selectedCell.progression_note) {
                return (
                  <Common.CustomTextArea
                    ref={el => (inputRef.current[row.index] = el)}
                    style={{ width: "-webkit-fill-available" }}
                    onBlur={e => {
                      handleEditProgressionNote({
                        index: row.index
                      });
                    }}
                    value={row.value}
                    onKeyDown={e => {
                      if (e.keyCode === CONSTANT.KEY_CODE.ESCAPE) {
                        setSelectedCell({});
                      }
                    }}
                  />
                );
              } else {
                return row.value;
              }
            }
          },
          {
            Header: "",
            accessor: "checkout_time",
            minWidth: 40,
            Cell: row => {
              if (!row.value) {
                return alignCenter(
                  <Button
                    icon="minus"
                    color="red"
                    onClick={() => {
                      setOpenModConfirmDelete(true);
                    }}
                  />
                );
              } else {
                return <></>;
              }
            }
          }
        ]}
      />
      <br />
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <Menu vertical fluid>
              <Menu.Item onClick={handleOpenModDrugOPDStatOrder}>
                Drug [STAT]
              </Menu.Item>
              <Menu.Item onClick={() => console.log("Drug [One Dose]")}>
                Drug [One Dose]
              </Menu.Item>
              <Menu.Item
                name="Central Lab"
                onClick={() => console.log("Central Lab")}
              />
              <Menu.Item
                name="Treatment"
                onClick={() => console.log("Treatment")}
              />
              <Menu.Item
                name="Imaging"
                onClick={() => console.log("Imaging")}
              />
              <Menu.Item
                name="Pathology"
                onClick={() => console.log("Pathology")}
              />
            </Menu>
          </Grid.Column>
          <Grid.Column width={13}>
            <PureReactTable
              testid="doctorOrderTable"
              style={styles.doctorOrderTable}
              showPagination={false}
              className=""
              pageSize={
                doctorOrderData
                  ? doctorOrderData.length < 7
                    ? 7
                    : doctorOrderData.length
                  : 7
              }
              data={doctorOrderData}
              selectedClassName="blueSelectedRow"
              onSelect={originalRow => setDoctorOrderSelectedRow(originalRow)}
              selectedRow={doctorOrderSelectedRow}
              columns={[
                {
                  Header: "Type",
                  accessor: "specific_type_name",
                  width: 210
                },
                {
                  Header: "Details",
                  accessor: "detail",
                  width: 550,
                  Cell: row => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: row.original.detail
                        }}
                      />
                    );
                  }
                },
                {
                  Header: "Del",
                  accessor: "can_cancel",
                  width: 100,
                  Cell: row => {
                    if (
                      row.value &&
                      row.original.creator_user_id === props.django.user.id
                    ) {
                      return alignCenter(
                        <Button
                          icon="minus"
                          color="red"
                          size="mini"
                          onClick={() =>
                            handleDoctorOrderRemoveButton({
                              type: row.original.specific_type_name
                            })
                          }
                        />
                      );
                    } else {
                      return <div />;
                    }
                  }
                },
                {
                  Header: "Edit",
                  accessor: "can_edit",
                  width: 100,
                  Cell: row => {
                    if (
                      row.value &&
                      row.original.creator_user_id === props.django.user.id
                    ) {
                      return alignCenter(
                        <Button
                          icon="write"
                          color="blue"
                          size="mini"
                          onClick={() =>
                            handleEditDoctorOrder({ row: row.original })
                          }
                        />
                      );
                    } else {
                      return <div />;
                    }
                  }
                },
                {
                  Header: "Status",
                  accessor: "order_status",
                  minWidth: 50,
                  getProps: (state, row, column) => {
                    if (row && row.original.order_status) {
                      if (row.original.is_rejected) {
                        return {
                          style: { background: "#D44C4C" }
                        };
                      }
                      if (row.original.order_status === "CANCEL") {
                        return {
                          style: { background: "#F78181" }
                        };
                      } else if (row.original.order_status === "DRAFT") {
                        return {
                          style: { background: "#F2F5A9" }
                        };
                      }
                    }
                    return {
                      style: { background: "transparent" }
                    };
                  },
                  Cell: row => {
                    if (row && row.original.is_rejected) {
                      return <label style={styles.boldText}>REJECTED</label>;
                    } else {
                      return <label style={styles.boldText}>{row.value}</label>;
                    }
                  }
                }
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field style={styles.boldText}>รวมค่ารักษา</Form.Field>
          <Form.Field>
            <Input
              className="priceInput"
              readOnly
              value={formatComma(totalPrice)}
            />
          </Form.Field>
          <Form.Field style={styles.boldText}>เบิกได้</Form.Field>
          <Form.Field>
            <Input
              className="priceInput"
              readOnly
              value={formatComma(claimablePrice)}
            />
          </Form.Field>
          <Form.Field style={styles.boldText}>เบิกไม่ได้</Form.Field>
          <Form.Field>
            <Input
              className="priceInput"
              readOnly
              value={formatComma(nonClaimablePrice)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Modal open={openModOPDStatOrder} size='large' onClose={() => setOpenModOPDStatOrder(false)}>
        <TPD.CardDrugOPDStatOrder 
          onClose={() => setOpenModOPDStatOrder(false)}
          toggleable={false}
        />
      </Modal>
    </Common.CardLayout>
  );
});

const SPECIFIC_TYPE_CODE = {
  DRUG_STAT_ORDER: "drugstatorder",
  DRUG_ONE_DOSE_ORDER: "drugonedoseorder",
  CENTRAL_LAB_ORDER: "centrallaborder",
  IME: "TODO:IME",
  TREATMENT_ORDER: "treatmentorder",
  MSD: "TODO:MSD"
};

const styles = {
  progressionTable: {
    height: "200px"
  },
  doctorOrderTable: {
    height: "300px"
  },
  boldText: {
    fontWeight: "bold"
  }
};

CardProgressCycle.defaultProps = {
  patientData: {},
  controller: {},
  django: {},
  getProgressionCycle: () => [null, null],
  deleteProgressionCycle: () => [null, null],
  createProgressionCycle: () => [null, null]
};

CardProgressCycle.propTypes = {
  controller: PropTypes.object,
  patientData: PropTypes.object,
  django: PropTypes.object,
  getProgressionCycle: PropTypes.func,
  deleteProgressionCycle: PropTypes.func,
  createProgressionCycle: PropTypes.func
};

export default React.memo(CardProgressCycle);
