import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Button } from "semantic-ui-react";

// UX
import CardStockManagementTabTransferUX from "./CardStockManagementTabTransferUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModInfo from "react-lib/apps/common/ModInfo";

// Interface
import {
  AggStockSerializer,
  BUTTON_ACTIONS,
  PermissionsType,
  RunSequence,
  StockStorageDetailType,
  TransferDetailType,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";

// Types
type CardStockManagementTabTransferProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // data
  data: Partial<AggStockSerializer>;
  divisionId?: number;
  permissions?: PermissionsType;
  stockStorageDetail?: StockStorageDetailType;
  // options
  storageOptions?: Record<string, any>[];
  divisionTypeDrugOptions?: Record<string, any>[];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
};

const CARD_STOCK_MANAGEMENT_TAB_TRANSFER = "CardStockManagementTabTransfer";

const CardStockManagementTabTransfer = (
  props: CardStockManagementTabTransferProps
) => {
  // data
  const [transferDetail, setTransferDetail] = useState<
    Partial<TransferDetailType>
  >({});
  const [currentDetail, setCurrentDetail] = useState<
    Partial<TransferDetailType>
  >({});
  const [transferList, setTransferList] = useState<
    Partial<TransferDetailType>[]
  >([]);
  // mod
  const [openModInfo, setOpenModInfo] = useState<boolean>(false);

  useEffect(() => {
    if (
      !!transferDetail.product?.id &&
      props.stockStorageDetail?.product_id !== transferDetail.product?.id
    ) {
      props.runSequence({
        sequence: "StockManagement",
        action: "GET_STOCK_STORAGE",
        product: transferDetail.product,
      });
    }
  }, [transferDetail]);

  useEffect(() => {
    const checkPType =
      !!transferList.length &&
      !transferList.find(
        (item) => item.product?.p_type_name === props.data.product?.p_type_name
      );
    const detail = {
      requester: props.divisionId, //props.data.storage?.id
      product: props.data.product,
      storage: props.data.storage,
    };

    if (!props.data.storage?.id) {
      setCurrentDetail({});
      return setTransferDetail({});
    } else if (checkPType) {
      setCurrentDetail(detail);
      setTransferDetail({});

      return setOpenModInfo(true);
    } else {
      setCurrentDetail(detail);
    }

    setTransferDetail(detail);
  }, [props.data]);

  // Use Callback
  const handleGetTheadThProps = useCallback(
    (_state: any, _rowInfo: any, column: any, _instance: any) => {
      return {
        style: ["จำนวนขอ"].includes(column?.Header || "")
          ? { backgroundColor: "#2185D0" }
          : {},
      };
    },
    []
  );

  const handleRemove = useCallback(
    (index: number) => () => {
      const list = [...transferList];

      const filter = list.filter((_: any, idx: number) => idx !== index);

      if (!filter.length) {
        setTransferDetail(currentDetail);
      }

      setTransferList(filter);
    },
    [transferList, currentDetail]
  );

  // Use Memo
  const stockTransferItems = useMemo(() => {
    return transferList.map((item, index) => ({
      ...item,
      code: item.product?.code,
      name: item.product?.name,
      unit: item.product?.unit_name,
      action: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="mini"
            icon="minus"
            color="red"
            onClick={handleRemove(index)}
          />
        </div>
      ),
    }));
  }, [transferList, currentDetail]);

  const titleName = useMemo(() => {
    const product = transferDetail.product;

    return product?.id ? `[${product?.code || ""}]-${product?.name || ""}` : "";
  }, [transferDetail]);

  const allowAdd = useMemo(() => {
    // provider ต้องเป็น provider เดียวกัน
    const isProvider =
      !!transferList.length &&
      !!transferList.find((item) => item.provider === transferDetail.provider);
    // requester ต้องเป็น requester เดียวกัน
    const isRequester =
      !!transferList.length &&
      !!transferList.find(
        (item) => item.requester === transferDetail.requester
      );

    const isAllow =
      transferDetail.product?.id &&
      !!transferDetail.provider &&
      !!transferDetail.quantity &&
      ((isProvider && isRequester) || !transferList.length);

    return isAllow && props.permissions?.TAB_TRANSFER_TRANSFER;
  }, [transferDetail, transferList, props.permissions]);

  const providerDivisionOptions = useMemo(() => {
    const storageIds = (props.stockStorageDetail?.items || []).map(
      (item) => item.id
    );

    return (props.divisionTypeDrugOptions || []).filter(
      (item: any) =>
        item.value !== transferDetail.requester && storageIds.includes(item.key)
    );
  }, [props.divisionTypeDrugOptions, props.stockStorageDetail, transferDetail]);

  const handleChangeValue = (_e: any, data: any) => {
    if (!props.permissions?.TAB_TRANSFER_TRANSFER) {
      return;
    }

    let value = data.value;
    const detail = { ...transferDetail };

    if (data.name === "quantity") {
      value = Number(value) < 0 ? 0 : value;
    } else if (data.name === "requester") {
      if (value === transferDetail.provider) {
        detail.provider = "";
      }
    }

    (detail as any)[data.name] = value;

    setTransferDetail(detail);
  };

  const handleAdd = () => {
    const list = [...transferList];
    const detail = transferDetail;

    const index = list.findIndex(
      (item) =>
        item.product?.id === detail.product?.id &&
        item.provider === detail.provider
    );

    if (index >= 0) {
      list[index].quantity = (
        Number(list[index].quantity) + Number(detail.quantity)
      ).toString();
    } else {
      list.push({
        ...transferDetail,
      });
    }

    setTransferList(list);
    setTransferDetail({
      requester: props.divisionId, //props.data.storage?.id,
      product: props.data.product,
      storage: props.data.storage,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "SAVE_TRANSFER",
      transferList,
      card: CARD_STOCK_MANAGEMENT_TAB_TRANSFER,
      errorKey: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.SAVE,
      onSuccess: () => {
        setTransferList([]);
        setTransferDetail(currentDetail);
      },
    });
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(false);
  };

  return (
    <div>
      <CardStockManagementTabTransferUX
        // data
        name={titleName}
        requester={transferDetail.requester}
        provider={transferDetail.provider}
        quantity={transferDetail.quantity}
        // table
        stockTransferList={stockTransferItems}
        // options
        requesterDivisionOptions={props.divisionTypeDrugOptions}
        providerDivisionOptions={providerDivisionOptions}
        // config
        disabledAdd={!allowAdd}
        readOnly={
          !transferDetail.product?.id ||
          !props.permissions?.TAB_TRANSFER_TRANSFER
        }
        // callback
        getTheadThProps={handleGetTheadThProps}
        onChangeValue={handleChangeValue}
        onAdd={handleAdd}
        // Element
        ButtonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirm}
            // data
            paramKey={`${CARD_STOCK_MANAGEMENT_TAB_TRANSFER}_${BUTTON_ACTIONS.SAVE}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_STOCK_MANAGEMENT_TAB_TRANSFER}_${BUTTON_ACTIONS.SAVE}`
              ]
            }
            // config
            disabled={!transferList.length}
            color={"green"}
            name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title="Confirm"
          />
        }
      />

      <ModInfo
        open={openModInfo}
        titleColor={"red"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <div
          style={{ padding: "0.5rem 0", fontWeight: "bold", lineHeight: 1.5 }}
        >
          <div>กรุณาเลือก Product Type ประเภทเดียวกัน</div>
        </div>
      </ModInfo>
    </div>
  );
};

export default React.memo(CardStockManagementTabTransfer);
