import React, { useEffect, useRef, useState } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
// ui ADM
import CardReAssessmentHistory from "./CardReAssessmentHistory";
import CardReAssessmentUX from "./CardReAssessmentUX";
import { Button, Icon, Input, Modal, Segment } from "semantic-ui-react";
import moment from "moment";

const CARD_RE_ASSESSMENT_ID: string = "CardReAssessment";

type CardReAssessmentProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  showForm?: any;

  // seq
  runSequence: any;
  ReAssessmentSequence?: any;

  // options
  masterOptions?: any;
  reAssessmentOptions?: any;
};

const CardReAssessmentInitial: CardReAssessmentProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // seq
  runSequence: null,

  // options
  masterOptions: {},
  reAssessmentOptions: {},
};

const CardReAssessment: React.FC<CardReAssessmentProps> = (props: any) => {
  const [openForm, setOpenForm] = useState(true);

  useEffect(() => {
    props.runSequence({ sequence: "ReAssessment", restart: true });
  }, []);

  useEffect(() => {
    if (!openForm) {
      props.runSequence({
        sequence: "ReAssessment",
        action: "getDataHistory",
      });
    }
  }, [openForm]);

  const handleChangeData = (_event: any, data: any) => {
    if (data?.type === "checkbox") {
      props.setProp(`ReAssessmentSequence.${data.name}`, data.checked);
    } else {
      props.setProp(`ReAssessmentSequence.${data.name}`, data.value);
    }
  };

  const handleActionForm = (_event: any, data: any) => {
    props.runSequence({
      sequence: "ReAssessment",
      action: data.name,
      card: CARD_RE_ASSESSMENT_ID,
    });
  };

  return (
    <>
      {(props.errorMessage?.[CARD_RE_ASSESSMENT_ID] ||
        props.successMessage?.[CARD_RE_ASSESSMENT_ID]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_RE_ASSESSMENT_ID}`, null);
            props.setProp(`successMessage.${CARD_RE_ASSESSMENT_ID}`, null);
          }}
          error={props.errorMessage?.[CARD_RE_ASSESSMENT_ID]}
          success={props.successMessage?.[CARD_RE_ASSESSMENT_ID]}
        />
      )}

      <div style={{ ...(openForm && { minWidth: "max-content", whiteSpace: "nowrap" }) }}>
        <div style={{ margin: "20px", ...(props.showForm && { display: "none" }) }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "22px", fontWeight: "bold" }}>Nursing Re-assessment</div>
            <div style={{ margin: "0px 20px" }}>
              <Button onClick={() => setOpenForm(true)} {...(openForm && { color: "blue" })}>
                แบบฟอร์มการประเมิน
              </Button>
            </div>
            <div>
              <Button onClick={() => setOpenForm(false)} {...(!openForm && { color: "blue" })}>
                ประวัติการบันทึก
              </Button>
            </div>
          </div>
        </div>

        {openForm ? (
          <CardReAssessmentUX
            // function
            onChangeData={handleChangeData}
            // seq
            ReAssessmentSequence={props.ReAssessmentSequence}
            // options
            masterOptions={props.masterOptions}
            reAssessmentOptions={props.reAssessmentOptions}
            //onChange
            onChangDatePain={(date: any) =>
              props.setProp(`ReAssessmentSequence.painData.saveData`, date)
            }
            onChangDateRiskFall={(date: any) =>
              props.setProp(`ReAssessmentSequence.riskFallData.saveData`, date)
            }
            onChangDateSleep={(date: any) =>
              props.setProp(`ReAssessmentSequence.sleepData.saveData`, date)
            }
            onChangTimePain={(value: any) =>
              props.setProp(`ReAssessmentSequence.painData.saveTime`, value)
            }
            onChangTimeRiskFall={(value: any) =>
              props.setProp(`ReAssessmentSequence.riskFallData.saveTime`, value)
            }
            onChangTimeSleep={(value: any) =>
              props.setProp(`ReAssessmentSequence.sleepData.saveTime`, value)
            }
            buttonSavePain={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleActionForm}
                // data
                paramKey={`${CARD_RE_ASSESSMENT_ID}_savePain`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_savePain`]}
                // config
                color={"green"}
                name="savePain"
                size="small"
                title={"บันทึก"}
              />
            }
            buttonSaveRiskFall={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleActionForm}
                // data
                paramKey={`${CARD_RE_ASSESSMENT_ID}_saveRiskFall`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveRiskFall`]}
                // config
                color={"green"}
                name="saveRiskFall"
                size="small"
                title={"บันทึก"}
              />
            }
            buttonSaveSleep={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleActionForm}
                // data
                paramKey={`${CARD_RE_ASSESSMENT_ID}_saveSleep`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveSleep`]}
                // config
                color={"green"}
                name="saveSleep"
                size="small"
                title={"บันทึก"}
              />
            }
            // check input number
            onKeyDown={(e: any) => {
              if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            showForm={props.showForm}
          />
        ) : (
          <>
            <CardReAssessmentHistory
              // seq
              runSequence={props.runSequence}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              // data
              masterOptions={props.masterOptions}
              title={{ code: "PAIN", name: "การประเมินความเจ็บปวด" }}
              data={props.ReAssessmentSequence?.painDataHistory}
            />

            <CardReAssessmentHistory
              // seq
              runSequence={props.runSequence}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              // data
              masterOptions={props.masterOptions}
              title={{ code: "RISK_FALL", name: "การประเมินความเสี่ยงต่อการพลัดตกหกล้ม" }}
              data={props.ReAssessmentSequence?.riskFallDataHistory}
            />

            <CardReAssessmentHistory
              // seq
              runSequence={props.runSequence}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              // data
              masterOptions={props.masterOptions}
              title={{ code: "SLEEP", name: "การพักผ่อนนอนหลับ" }}
              data={props.ReAssessmentSequence?.sleepDataHistory}
            />
          </>
        )}
      </div>
    </>
  );
};

CardReAssessment.defaultProps = CardReAssessmentInitial;

export default React.memo(CardReAssessment);
