import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardPackingUx = (props: any) => {
    return(
      <div
        id="CardPacking"
        style={{margin: "10px", backgroundColor: "rgba(198, 235, 243, 0.2)", height:"90%"}}>
        <div
          style={{display: "flex", alignItems: "center", margin: "10px 10px"}}>
          
          <label
            className="header"
            style={{fontWeight: "bold", fontSize: "18px", marginLeft: "4px"}}>
            Packing
          </label>
        </div>
        <div>
          
          <hr>
          </hr>
        </div>
        <div
          style={{height:"90%",margin: "14px 14px",backgroundColor: "#FFFFFF"}}>
          
          <div
            style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
            
            <label
              style={{fontWeight: "bold", fontSize: "16px", marginLeft: "14px"}}>
              
            </label>
            <div
              style={{display:"flex", width:"auto", marginLeft: "auto"}}>
              
              <Button
                color="yellow"
                onClick={props.addItem}
                size="small">
                Add
              </Button>
            </div>
          </div>
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.items}
            headers="No,Site,Item,Pieces,Remark,-"
            keys="no,site_name,item_name,quantity,remark,delete"
            showPagination={false}
            style={{height: "35vh"}}
            widths="50,150,auto,150,350,100">
          </Table>
          <div
            style={{border:"1px solid #ccc", borderRadius: "4px", padding: "0px 8px 4px 10px", margin:"20px 0px"}}>
            
            <div
              style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
              
              <label
                style={{width:"180px",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
                Nursing Diagnosis
              </label>
              <Input
                name="packingFormData.nursing_diagnosis"
                onChange={props.handleChangeData}
                style={{width:"300px"}}
                value={props.ORCountsSequence?.packingFormData?.nursing_diagnosis || ""}>
              </Input>
              <label
                style={{width:"120px",fontWeight: "bold", fontSize: "14px", marginLeft: "14px"}}>
                Goal
              </label>
              <Input
                name="packingFormData.goal"
                onChange={props.handleChangeData}
                style={{width:"300px"}}
                value={props.ORCountsSequence?.packingFormData?.goal || ""}>
              </Input>
            </div>
            <div
              style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
              
              <label
                style={{width:"180px",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
                Plan and implementation
              </label>
              <Dropdown
                name="packingFormData.plan_and_implementation"
                onChange={props.handleChangeData}
                options={props.orPlanNImplPackingOpt || []}
                selection={true}
                style={{width:"300px"}}
                value={props.ORCountsSequence?.packingFormData?.plan_and_implementation || ""}>
              </Dropdown>
              <label
                style={{width:"120px",fontWeight: "bold", fontSize: "14px", marginLeft: "14px"}}>
                Evaluation
              </label>
              <Input
                name="packingFormData.evaluation"
                onChange={props.handleChangeData}
                style={{width:"300px"}}
                value={props.ORCountsSequence?.packingFormData?.evaluation || ""}>
              </Input>
            </div>
            <div
              style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
              
              <div
                style={{display:"flex", width:"auto", marginLeft: "auto"}}>
                
                <div>
                  {props.save}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{width: "100%"}}>
            {props.ErrorMessage}
          </div>
        </div>
      </div>
    )
}


export default CardPackingUx

export const screenPropsDefault = {}

/* Date Time : Wed Dec 27 2023 17:40:55 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPacking"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\", height:\"90%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 3,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 6,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Packing"
        },
        "className": {
          "type": "value",
          "value": "header"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"18px\", marginLeft: \"4px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"90%\",margin: \"14px 14px\",backgroundColor: \"#FFFFFF\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"16px\", marginLeft: \"14px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Add"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.addItem"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", width:\"auto\", marginLeft: \"auto\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 12,
      "name": "Table",
      "parent": 7,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.items"
        },
        "headers": {
          "type": "value",
          "value": "No,Site,Item,Pieces,Remark,-"
        },
        "keys": {
          "type": "value",
          "value": "no,site_name,item_name,quantity,remark,delete"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"35vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "50,150,auto,150,350,100"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border:\"1px solid #ccc\", borderRadius: \"4px\", padding: \"0px 8px 4px 10px\", margin:\"20px 0px\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursing Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{width:\"180px\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Input",
      "parent": 67,
      "props": {
        "name": {
          "type": "value",
          "value": "packingFormData.nursing_diagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"300px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCountsSequence?.packingFormData?.nursing_diagnosis || \"\""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Goal"
        },
        "style": {
          "type": "code",
          "value": "{width:\"120px\",fontWeight: \"bold\", fontSize: \"14px\", marginLeft: \"14px\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Input",
      "parent": 67,
      "props": {
        "name": {
          "type": "value",
          "value": "packingFormData.goal"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"300px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCountsSequence?.packingFormData?.goal || \"\""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", width:\"auto\", marginLeft: \"auto\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Button",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "Save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.save"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan and implementation"
        },
        "style": {
          "type": "code",
          "value": "{width:\"180px\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "Evaluation"
        },
        "style": {
          "type": "code",
          "value": "{width:\"120px\",fontWeight: \"bold\", fontSize: \"14px\", marginLeft: \"14px\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Input",
      "parent": 76,
      "props": {
        "name": {
          "type": "value",
          "value": "packingFormData.evaluation"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"300px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCountsSequence?.packingFormData?.evaluation || \"\""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Dropdown",
      "parent": 76,
      "props": {
        "name": {
          "type": "value",
          "value": "packingFormData.plan_and_implementation"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.orPlanNImplPackingOpt || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"300px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCountsSequence?.packingFormData?.plan_and_implementation || \"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "code",
          "value": "props.save"
        }
      },
      "seq": 83,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardPackingUx",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
