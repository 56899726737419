import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  Modal,
  Icon,
  Image,
  Grid,
  Checkbox,
  ItemMeta,
} from "semantic-ui-react";
import "./ModalTemplateFile.css";

const ITEMPERLINE = 4;

const TemplateElement = ({
  currentRow,
  imagesUrls,
  selectedIndex,
  toggleSelected,
}) => {
  let elementItem = [];

  for (var i = 0; i < ITEMPERLINE; i++) {
    let elementIndex = currentRow * ITEMPERLINE + i;
    // console.log("(row * ITEMPERLINE) + i",(currentRow * ITEMPERLINE) + i);
    // console.log('imagesUrls.length: ',    imagesUrls.length);
    if (imagesUrls.length > elementIndex) {
      elementItem.push(
        <Grid.Column
          className="template-file"
          verticalAlign="middle"
          onClick={() => {
            toggleSelected(elementIndex);
          }}
        >
          <Image
            className={
              selectedIndex.includes(elementIndex) ? "image selected" : "image"
            }
            centered
            size="large"
            bordered
            src={process.env.PUBLIC_URL + imagesUrls[elementIndex]}
          />
          <div
            className={
              selectedIndex.includes(elementIndex)
                ? "middle selected"
                : "middle"
            }
          >
            <Icon size="large" color="green" name="check circle outline" />
          </div>
        </Grid.Column>
      );
    }
  }

  return <> {elementItem} </>;
};
const TempleteRows = ({ imagesUrls, selectedIndex, toggleSelected }) => {
  const line = Math.floor(imagesUrls.length / ITEMPERLINE);
  const remainder = imagesUrls.length % ITEMPERLINE;
  var rowCount = line;
  if (remainder > 0) {
    rowCount++;
  }

  let rowItems = [];
  for (var i = 0; i < rowCount; i++) {
    rowItems.push(
      <Grid.Row centered columns={ITEMPERLINE}>
        <TemplateElement
          currentRow={i}
          imagesUrls={imagesUrls}
          selectedIndex={selectedIndex}
          toggleSelected={toggleSelected}
        />
      </Grid.Row>
    );
  }

  return <> {rowItems} </>;
};

const ModalTempleteFile = ({
  openTempleteFile,
  onCloseModal,
  onUploadMultipleFile,
  templateUrl,
}) => {
  
  // const fileUploaderRef = useRef();
  // const [imagesUrls, setImagesUrls] = useState([
  //   "/static/images/blank-muscle-anatomy.jpg",
  //   "/static/images/bodyoutline.jpg",
  //   "/static/images/foot1.png",
  //   "/static/images/foot2.png",
  // ]);

  const imagesUrls = templateUrl.map(item => item.image)
  const [selectedIndex, setSelectedIndex] = useState([]);

  const handleToggleSelected = (index) => {
    if (selectedIndex.includes(index)) {
      // remove
      setSelectedIndex(selectedIndex.filter((item) => item !== index));
    } else {
      // add
      setSelectedIndex([...selectedIndex, index]);
    }
  };

  const handleUploadImage = () => {
    let imageListBase64 = [];
    let promiseList = [];
    selectedIndex.forEach((item) => {
      if (imagesUrls[item]) {
        promiseList.push(
          new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();
            request.open("GET", imagesUrls[item], true);
            request.responseType = "blob";
            request.onload = function () {
              var reader = new FileReader();
              reader.readAsDataURL(request.response);
              reader.onload = function (e) {
                imageListBase64.push(e.target.result);
                resolve();
              };
              reader.onerror = function (e) {
                reject();
              };
            };
            request.send();
          })
        );
      }
    });

    Promise.all(promiseList)
      .then(() => {
        console.log("Success");
        onUploadMultipleFile(imageListBase64);
        onCloseModal(false);
      })
      .catch(() => {
        console.log("Failed Some");
        onUploadMultipleFile(imageListBase64);
        onCloseModal(false);
      });
  };

  useEffect(() => {
    if (selectedIndex.length !== 0) {
      setSelectedIndex([]);
    }
  }, [openTempleteFile]);

  return (
    <Modal
      open={openTempleteFile}
      size="fullscreen"
      onClose={() => {
        onCloseModal(false);
      }}
      closeOnDimmerClick={false}
      closeIcon={true}
    >
      <Modal.Header>
        <Grid>
          <TempleteRows
            imagesUrls={imagesUrls}
            selectedIndex={selectedIndex}
            toggleSelected={handleToggleSelected}
          />
        </Grid>
      </Modal.Header>

      <Modal.Actions>
        <Button color="green" onClick={handleUploadImage}>
          <Icon name="check" />
          Choose selected images
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalTempleteFile;
