import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Button
} from 'semantic-ui-react'

const ModUDSegmentGroupUX = (props: any) => {
    return(
      <div>
        <div
          style={{backgroundColor: "#0072BC", padding: "1rem", display: props.hideHeader ? "none" : "" }}>
          
          <label
            style={{color:"white", fontSize: "1.2rem"}}>
            สร้าง Segment Group ใหม่
          </label>
        </div>
        <div
          className="ui form"
          style={{padding: "30px 0rem 30px 1rem", ...(props.contentStyle || {})}}>
          
          <div
            style={{width: "100%"}}>
            {props.ErrorMessage}
          </div>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", justifyContent: "flex-end", width: "230px"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  Group Code
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
                <label
                  style={{paddingLeft: "2.5px"}}>
                  :
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Input
                name="code"
                onChange={props.onChangeValue}
                readOnly={props.readOnly || false}
                value={props.data?.code || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", justifyContent: "flex-end", width: "230px"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  ชื่อ Segment group [ภาษาไทย]
                </label>
                <label
                  style={{color: "red"}}>
                  * 
                </label>
                <label
                  style={{paddingLeft: "2.5px"}}>
                  :
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Input
                name="name"
                onChange={props.onChangeValue}
                readOnly={props.readOnly || false}
                value={props.data?.name || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", justifyContent: "flex-end", width: "230px"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  ชื่อ Segment group [ภาษาอังกฤษ] :
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Input
                name="name_en"
                onChange={props.onChangeValue}
                readOnly={props.readOnly || false}
                value={props.data?.name_en || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <div
            style={{display: props.hideAction ? "none" : "flex",  justifyContent: "center", marginTop: "2rem"}}>
            
            <div>
              {props.ButtonSave}
            </div>
            <Button
              color="red"
              onClick={props.onCancel}
              style={{width: "200px", marginLeft: "3rem"}}>
              ยกเลิอก
            </Button>
          </div>
        </div>
      </div>
    )
}

export default React.memo(ModUDSegmentGroupUX)

export const screenPropsDefault = {}

/* Date Time : Mon Jan 23 2023 13:16:31 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#0072BC\", padding: \"1rem\", display: props.hideHeader ? \"none\" : \"\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้าง Segment Group ใหม่"
        },
        "style": {
          "type": "code",
          "value": "{color:\"white\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"30px 0rem 30px 1rem\", ...(props.contentStyle || {})}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"230px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ Segment group [ภาษาอังกฤษ] :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"230px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ Segment group [ภาษาไทย]"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "* "
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"2.5px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"230px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "Group Code"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"2.5px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Input",
      "parent": 8,
      "props": {
        "name": {
          "type": "value",
          "value": "code"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "value": {
          "type": "code",
          "value": "props.data?.code || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Input",
      "parent": 10,
      "props": {
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "value": {
          "type": "code",
          "value": "props.data?.name || \"\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Input",
      "parent": 12,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "name_en"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.data?.name_en || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideAction ? \"none\" : \"flex\",  justifyContent: \"center\", marginTop: \"2rem\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Button",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิอก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\", marginLeft: \"3rem\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 33,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": true,
  "name": "ModUDSegmentGroupUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
