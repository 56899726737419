import React, { CSSProperties, useState, useMemo, useEffect } from "react";

// @ts-ignore
import Cookies from "js-cookie";

// CSS
import {
  Button,
  Icon,
  Input,
  Modal,
  InputProps,
  Form,
  Dimmer,
  Loader,
} from "semantic-ui-react";

// Interface
import {
  Event as MobEvent,
  State as MobState,
  StateInitial as MobStateInitial,
} from "../../../../patient-lib/MobTransformInterface";

// UI
import TFHead from "./TFHead";
import { DisplayTitle, SpecifyPassword } from "./TFRegister";
import { TFModInfo } from "./TFVerifyIdentity";
import ModMessage from "./ModMessage";

// Common
import { ModInfo, ErrorMessage, DateTextBox } from "../../common";

// Styles
import "./Transform.scss";

type TransformForgetPasswordProps = {
  onEvent: (e: MobEvent) => any;
  history: any;
  onHaveApiToken?: (history: any) => any;
} & Pick<
  MobState,
  | "inputEmailRequest"
  | "openModCheckEmail"
  | "registerInfo"
  | "changePasswordData"
  | "successMessage"
  | "errorMessage"
  | "modMessageType"
  | "loadingMainTransform"
>;

type EditPasswordProps = {
  onConfirm: () => any;
} & TransformForgetPasswordProps;

type ChangePasswordProps = {
  onSubmit?: () => any;
} & TransformForgetPasswordProps;

const COLOR = {
  primary: "var(--primary-theme-color)",
  secondary: "#F2C94C",
  violet_blue: "#2D247F",
  font_white: "white",
  dark_grey: "#333333",
  orange_light: "#EB5757",
};

const styles = {
  label: {
    color: COLOR.primary,
    fontSize: "16px",
    marginBottom: "10px",
    textAlign: "left",
    fontWeight: "bold",
  } as CSSProperties,
  description: {
    fontSize: "16px",
    textAlign: "center",
    color: COLOR.primary,
    marginTop: "50px",
    marginBottom: "45px",
    padding: "0 35px",
    lineHeight: "24px",
  } as CSSProperties,
  box_modal_detail: {
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "26px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "155px",
  } as CSSProperties,
};

const HEADER_NAME = {
  change: "เปลี่ยนรหัส",
  request: "ลืมรหัสผ่าน",
  create: "สร้างรหัสผ่าน",
};

const EditPassword: React.FunctionComponent<EditPasswordProps> = (props) => {
  return (
    <>
      <div className="tf-main">
        <div style={{ height: "25rem" }}>
          <DisplayTitle
            title="แก้ไขรหัสผ่าน"
            description={[
              "กรุณาระบุอีเมลที่ลงทะเบียนไว้",
              "กับโรงพยาบาล เพื่อเข้าใช้งาน",
              "ระบบ TUH-Transform",
            ]}
          />

          <div style={{ display: "grid" }}>
            <label className="label-required" style={styles.label}>
              Email
            </label>
            <Input
              value={props.inputEmailRequest}
              icon
              placeholder=""
              style={{ fontSize: "16px" }}
              onChange={(e: any, v: InputProps) =>
                props.onEvent({
                  message: "HandleSetInputEmailRequest",
                  params: { value: v.value },
                })
              }
            >
              <input />
              <Icon name="mail outline" />
            </Input>
          </div>
        </div>
        <Button
          fluid
          className="button-submit-primary"
          onClick={props.onConfirm}
        >
          ยืนยัน
        </Button>
      </div>

      <div style={{ marginTop: "60px" }}>
        <DisplayTitle
          descriptStyle={{
            fontSize: "13px",
            fontWeight: "bold",
            color: COLOR.dark_grey,
          }}
          description={[
            "หากท่านไม่ทราบ E-mail",
            "กรุณาติดต่อทางโรงพยาบาล",
            "เพื่อดำเนินการต่อ",
          ]}
        />
      </div>
    </>
  );
};

const ChangePassword: React.FunctionComponent<ChangePasswordProps> = (
  props
) => {
  return (
    <div>
      <Form onSubmit={props.onSubmit} style={{ marginTop: "30px" }}>
        <Form.Field
          error={props.changePasswordData?.error.currentPassword || false}
        >
          <label className="label-required" style={styles.label}>
            รหัสผ่านปัจจุบัน
          </label>
          <Input
            value={props.changePasswordData?.currentPassword || ""}
            icon
            placeholder=""
            onChange={(e: any, v: InputProps) =>
              props.onEvent({
                message: "HandleSetValueChangePassword",
                params: { key: "currentPassword", value: v.value },
              })
            }
          />
        </Form.Field>
        <Form.Field
          error={props.changePasswordData?.error.newPassword || false}
        >
          <label className="label-required" style={styles.label}>
            รหัสผ่านใหม่
          </label>
          <Input
            value={props.changePasswordData?.newPassword || ""}
            icon
            placeholder=""
            onChange={(e: any, v: InputProps) =>
              props.onEvent({
                message: "HandleSetValueChangePassword",
                params: { key: "newPassword", value: v.value },
              })
            }
          />
        </Form.Field>
        <Form.Field
          error={props.changePasswordData?.error.confirmNewPassword || false}
        >
          <label className="label-required" style={styles.label}>
            ยืนยันรหัสผ่านใหม่
          </label>
          <Input
            value={props.changePasswordData?.confirmNewPassword || ""}
            icon
            placeholder=""
            onChange={(e: any, v: InputProps) =>
              props.onEvent({
                message: "HandleSetValueChangePassword",
                params: { key: "confirmNewPassword", value: v.value },
              })
            }
          />
        </Form.Field>

        <input type="submit" hidden />

        {props.changePasswordData?.error.message && (
          <div
            style={{
              textAlign: "right",
              marginTop: "5px",
              color: COLOR.orange_light,
            }}
          >
            {props.changePasswordData?.error.message || ""}
          </div>
        )}
      </Form>
    </div>
  );
};

const initialHeight = window.innerHeight;

const TransformForgetPassword: React.FunctionComponent<
  TransformForgetPasswordProps
> = (props) => {
  useEffect(() => {
    if (Cookies.get("apiToken")) {
      props.onHaveApiToken?.(props.history);
    }
  }, []);

  /* ----------------------- // Memo ---------------------- */
  const contentHeight = useMemo(() => {
    return `${(initialHeight * 63) / 100}px`;
  }, []);

  const ACTION_TYPE = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("action") as "change" | "request" | "create";
  }, [window.location.search]);

  /* ----------------------- Handle ----------------------- */
  const handleConfirm = () => {
    props.onEvent({
      message: "HandleEmailRequest",
      params: { email: props.inputEmailRequest || "" },
    });
  };

  const handleCloseModal = () => {
    props.onEvent({
      message: "HandleSetOpenModCheckEmail",
      params: { value: false },
    });
  };

  const handleLeftIconClick = () => {
    props.history.goBack();
  };

  const handleNextView = (
    current: "username" | "password" | "hn" | "info",
    next: string
  ) => {
    props.onEvent({
      message: "HandleNextViewRegister",
      params: { current, next, history: props.history },
    });
  };

  const handleConfirmChangePassword = () =>
    props.onEvent({
      message: "HandleConfirmChangePassword",
      params: { history: props.history },
    });

  const handleClose = () => {
    props.onEvent({ message: "HandleCloseMessage", params: {} });
  };

  return (
    <div className="tf-register">
      <Dimmer
        active={props.loadingMainTransform}
        inverted
        style={{ zIndex: 1100 }}
      >
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <TFHead
        title={HEADER_NAME[ACTION_TYPE]}
        leftIconClick={handleLeftIconClick}
      />

      {ACTION_TYPE === "request" ? (
        <EditPassword {...props} onConfirm={handleConfirm} />
      ) : ACTION_TYPE === "change" ? (
        <div className="tf-main">
          <ChangePassword {...props} onSubmit={handleConfirmChangePassword} />
          <Button
            fluid
            className="button-submit-primary"
            style={{ marginTop: "12vh" }}
            onClick={handleConfirmChangePassword}
          >
            บันทึก
          </Button>
        </div>
      ) : ACTION_TYPE === "create" ? (
        <div className="tf-main">
          <SpecifyPassword
            {...props}
            onSubmit={() => handleNextView("password", "info")}
            height={contentHeight}
          />
          <Button
            fluid
            className="button-submit-primary"
            onClick={() => handleNextView("password", "info")}
          >
            เสร็จสิ้น
          </Button>
        </div>
      ) : null}

      {/* Modal */}
      {typeof props.modMessageType !== "string" &&
        props.modMessageType?.type === "RESET_PASSWORD" && (
          <TFModInfo
            open={true}
            buttonName="ปิด"
            onApprove={handleClose}
            onClose={handleClose}
            titleName="กรุณาตรวจสอบอีเมล"
            style={{ height: "135px" }}
          >
            <div>{props.modMessageType.message}</div>
          </TFModInfo>
        )}

      <ModMessage
        onEvent={props.onEvent}
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};

TransformForgetPassword.defaultProps = {
  onEvent: () => {},
  history: {},
  inputEmailRequest: "",
  openModCheckEmail: false,
  registerInfo: MobStateInitial.registerInfo,
  changePasswordData: MobStateInitial.changePasswordData,
} as TransformForgetPasswordProps;

export default React.memo(TransformForgetPassword);
