import CONFIG from "config/config";

const COLORS = {
  blue: "#00147E",
  light_blue: "#0447b5",
  bg: "#ced8ea",
};

const SvgCheckbox = (check?: boolean) => {
  return `<svg width="12" height="12">
  <rect x="1" y="5" width="12" height="12" style="fill:transparent;stroke:${
    COLORS.blue
  };stroke-width:1.5;fill-opacity:1;stroke-opacity:0.75" />
  ${
    check &&
    `<polygon style="fill:${COLORS.light_blue};stroke-width:1.5;stroke:${COLORS.light_blue};transform:scale(0.075) translate(10 20)" points="191.268,26.967 59.541,158.683 5.615,104.76 0,110.386 59.541,169.92 196.887,32.585"/>`
  }
  </svg>`;
};

const formatPrice = (number: number) => {
  return Number(number).toLocaleString("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

const BORDER_BLUE = [COLORS.blue, COLORS.blue, COLORS.blue, COLORS.blue];
const HEIGHT = 763.46456693;
const BOTTOM = 130;
// const PAGE_TOP = 763.46456693 - (BOTTOM + 50); // BOTTOM * 2 + 10 ไม่แน่ใจ;

const FormReceipt = (props: any) => {
  console.log("FormReceipt", props);

  return {
    pageSize: {
      width: 623.62204724,
      height: HEIGHT,
    },
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 12,
      color: COLORS.blue, //#0447b5//#13389d
    },
    pageMargins: [0, 223.5, 10, BOTTOM], // [10, 223.5, 10, BOTTOM]
    styles: {
      facultyHeader: {
        fontSize: 21,
        bold: true,
      },
      tableHeader: {
        fontSize: 22,
        bold: true,
      },
      fieldNumber: {
        bold: true,
        fontSize: 34,
        color: COLORS.light_blue,
      },
      fieldKey: {
        bold: true,
        fontSize: 18,
      },
      fieldValue: {
        fontSize: 18,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 14,
      },
      miniFieldValue: {
        fontSize: 14,
      },
      footerValue: {
        fontSize: 16,
      },
    },
    header: (currentPage: number, pageCount: number) => {
      return {
        stack: [
          {
            margin: [60, 12.5, 60, 0],
            columns: [
              !CONFIG.HIDE_COMPANY_LOGO_PRINT
                ? {
                    width: "20%",
                    stack: [{ image: "logochula", width: 85 }],
                  }
                : null,
              {
                width: "60%",
                lineHeight: 0.7,
                stack: [
                  {
                    text: "ใบเสร็จรับเงิน",
                    alignment: "center",
                    fontSize: 26,
                    bold: true,
                    characterSpacing: 0.5,
                  },
                  !CONFIG.HIDE_COMPANY_LOGO_PRINT
                    ? {
                        text: "คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                        alignment: "center",
                        style: "facultyHeader",
                        characterSpacing: 0.45,
                        lineHeight: 0.8,
                      }
                    : null,
                  !CONFIG.HIDE_COMPANY_LOGO_PRINT
                    ? {
                        text: "34 ถนนอังรีดูนังต์ ปทุมวัน กรุงเทพฯ 10330 โทร 02-2188705, 02-4306533, 02-2188642",
                        alignment: "center",
                        lineHeight: 0.9,
                      }
                    : null,
                  !CONFIG.HIDE_COMPANY_LOGO_PRINT
                    ? {
                        margin: [6.75, 0, 0, 0],
                        text: [
                          "เลขประจำตัวผู้เสียภาษีอากร ",
                          {
                            text: " 0994000158831",
                            characterSpacing: 1.5,
                            fontSize: 12.5,
                          },
                        ],
                        alignment: "left",
                        characterSpacing: 0.4,
                      }
                    : null,
                ],
              },
              {
                width: "20%",
                stack: [
                  // {
                  // absolutePosition: { x: 440, y: 10 },
                  // text: ["เลขที่การเงิน", "0009-255500115"],
                  // color: COLORS.light_blue,
                  // style: "miniFieldKey"
                  // },
                  {
                    text: "เลขที่",
                    margin: [15, 20, 0, 0],
                    style: "fieldValue",
                  },
                  {
                    text: "เลขที่",
                    margin: [15, 10, 0, 0],
                    style: "fieldValue",
                  },
                  {
                    absolutePosition: { x: 522.5, y: 20.5 },
                    text: props.prefix,
                    style: "fieldNumber",
                  },
                  {
                    absolutePosition: { x: 522.5, y: 55.5 },
                    text: props.running,
                    style: "fieldNumber",
                  },
                ],
              },
            ],
          },
          {
            margin: [60, 0, 60, 0],
            stack: [
              {
                absolutePosition: { x: 40, y: 0 },
                layout: "noBorders",
                table: {
                  heights: 763.46456693,
                  widths: [533.62204724],
                  body: [[{ text: "" }]],
                },
              },
            ],
          },
          {
            margin: [0, -10, 0, -17.5],
            text: props.station_name,
            alignment: "center",
            style: "facultyHeader",
            color: COLORS.light_blue,
          },
          {
            margin: [50, 0, 50, 0],
            stack: [
              {
                margin: [0, 10, 0, 0],
                table: {
                  widths: ["6%", "38.5%", "16%", "10%", "10%", "*"],
                  body: [
                    [
                      { text: "", style: "fieldValue" },
                      { text: "", style: "fieldValue" },
                      {
                        columns: [
                          {
                            text: "วันที่",
                            style: "fieldValue",
                          },
                          {
                            absolutePosition: { x: 325, y: 105 },
                            text: props.day?.toString() || "",
                            style: "facultyHeader",
                            color: COLORS.light_blue,
                          },
                        ],
                      },
                      {
                        columns: [
                          { text: "เดือน", style: "fieldValue" },
                          {
                            absolutePosition: { x: 402.5, y: 105 },
                            text: props.month,
                            style: "facultyHeader",
                            color: COLORS.light_blue,
                          },
                        ],
                      },
                      { text: "", style: "fieldValue" },
                      {
                        columns: [
                          { text: "พ.ศ.", style: "fieldValue" },
                          {
                            absolutePosition: { x: 510, y: 105 },
                            text: props.year,
                            style: "facultyHeader",
                            color: COLORS.light_blue,
                          },
                        ],
                      },
                    ],
                    [
                      { text: "ได้รับ", style: "fieldValue" },
                      {
                        columns: [
                          {
                            svg: SvgCheckbox(props.is_cash),
                            width: 19,
                          },
                          { text: "เงินสด", style: "fieldValue" },
                          {
                            absolutePosition: { x: 150, y: 128.5 },
                            text: props.cash_value,
                            style: "facultyHeader",
                            color: COLORS.light_blue,
                          },
                        ],
                      },
                      {
                        columns: [
                          {
                            svg: SvgCheckbox(props.is_transfer),
                            width: 19,
                          },
                          { text: "QR Code.", style: "fieldValue" },
                        ],
                      },
                      {
                        absolutePosition: { x: 362.5, y: 128.5 },
                        text: props.transfer_value,
                        style: "facultyHeader",
                        color: COLORS.light_blue,
                      },
                      { text: "", style: "fieldValue" },
                      { text: "", style: "fieldValue" },
                    ],
                    [
                      { text: "", style: "fieldValue" },
                      {
                        columns: [
                          {
                            svg: SvgCheckbox(props.is_credit_card),
                            width: 19,
                          },
                          { text: "บัตรเครดิต", style: "fieldValue" },
                          {
                            absolutePosition: { x: 170, y: 152.5 },
                            text: props.credit_card_value,
                            style: "facultyHeader",
                            color: COLORS.light_blue,
                          },
                        ],
                      },
                      {
                        columns: [
                          { text: "เลขที่", style: "fieldValue" },
                          {
                            absolutePosition: { x: 315, y: 152.5 },
                            text: props.ref_no,
                            style: "facultyHeader",
                            color: COLORS.light_blue,
                          },
                        ],
                      },
                      { text: "", style: "fieldValue" },
                      {
                        columns: [
                          { text: "ธนาคาร", style: "fieldValue" },
                          {
                            absolutePosition: { x: 470, y: 152.5 },
                            text: props.bank_name,
                            style: "facultyHeader",
                            color: COLORS.light_blue,
                          },
                        ],
                      },
                      { text: "", style: "fieldValue" },
                    ],
                    [
                      { text: "จาก", style: "fieldValue" },
                      {
                        absolutePosition: { x: 90, y: 175 },
                        text: props.patient_name,
                        style: "facultyHeader",
                        color: COLORS.light_blue,
                      },
                      {
                        columns: [
                          { text: "HN", style: "fieldValue" },
                          {
                            absolutePosition: { x: 300, y: 180 },
                            columns: [props.hn, "", ""].map((value) => ({
                              width: "auto",
                              margin: [15, -4, 0, 0],
                              text: value,
                              style: "facultyHeader",
                              alignment: "left",
                              color: COLORS.light_blue,
                            })),
                            style: "facultyHeader",
                            color: COLORS.light_blue,
                          },
                        ],
                      },
                      {
                        text: "",
                      },
                      { text: "", style: "fieldValue" },
                      { text: "", style: "fieldValue" },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i: number, node: any) {
                    return 0;
                  },
                  vLineWidth: function (i: number, node: any) {
                    return 0;
                  },
                  paddingBottom: function (i: number, node: any) {
                    return 0;
                  },
                  paddingTop: function (i: number, node: any) {
                    return 0;
                  },
                },
              },
              {
                lineHeight: 0,
                margin: [4, 0, 0, 0],
                columns: [
                  {
                    text: "หน่วยงาน",
                    style: "fieldValue",
                  },
                  {
                    absolutePosition: { x: 115, y: 201 },
                    text: props.payer_name || "",
                    style: "fieldKey",
                    color: "black",
                  },
                  {
                    absolutePosition: { x: 56, y: 219 },
                    text: props.duration || "",
                    style: "fieldKey",
                    color: "black",
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    watermark: {
      text: props.isWatermark ? "สำเนา" : "",
      opacity: 0.2,
      bold: true,
      angle: -30,
      fontSize: 120,
    },
    content: [
      {
        absolutePosition: { x: 30, y: 250 },
        // layout: "noBorders",
        table: {
          heights: 257.5,
          widths: [553.5], // 503.55
          body: [
            [
              {
                text: "",
                borderColor: BORDER_BLUE,
              },
            ],
          ],
        },
      },
      {
        absolutePosition: { x: 377, y: 250 }, // x: 369.65, y: 250
        // layout: "noBorders",
        table: {
          heights: 257.5,
          widths: [1],
          body: [
            [
              {
                text: "",
                border: [0, 0, 1, 0],
                borderColor: BORDER_BLUE,
              },
            ],
          ],
        },
      },
      {
        absolutePosition: { x: 480, y: 250 }, // x: 463.65, y: 250
        // layout: "noBorders",
        table: {
          heights: 257.5,
          widths: [1],
          body: [
            [
              {
                text: "",
                border: [0, 0, 1, 0],
                borderColor: BORDER_BLUE,
              },
            ],
          ],
        },
      },
      {
        margin: [30, 0, 20, 0], // [45, 0, 45, 0]
        table: {
          headerRows: 2,
          widths: ["65%", "17.5%", "17.5%"], // ["*", "17.5%", "17.5%"]
          lineHeight: 2,

          body: [
            [
              {
                borderColor: BORDER_BLUE,
                margin: [0, 11, 0, 0],
                text: "รายการ",
                rowSpan: 2,
                alignment: "center",
                style: "tableHeader",
                fillColor: COLORS.bg,
              },
              {
                borderColor: BORDER_BLUE,
                text: "จำนวนเงิน (บาท)",
                colSpan: 2,
                alignment: "center",
                fontSize: 18,
                bold: true,
                fillColor: COLORS.bg,
              },
              {},
            ],
            [
              "",
              {
                borderColor: BORDER_BLUE,
                text: "เบิกไม่ได้ตามระเบียบ กระทรวงการคลัง",
                alignment: "center",
                style: "miniFieldValue",
                lineHeight: 0.65,
                fillColor: COLORS.bg,
                bold: false,
              },
              {
                borderColor: BORDER_BLUE,
                margin: [0, 2, 0, 0],
                text: "เบิกได้",
                alignment: "center",
                fontSize: 18,
                fillColor: COLORS.bg,
                bold: false,
              },
            ],
          ].concat(
            props.fields.map((item: any, index: number) => [
              {
                border: [0, 0, 0, 0],
                margin: [7.5, 0, 0, 0],
                text: `${index + 1}. ${item.name}`,
                style: "fieldValue",
                color: COLORS.light_blue,
              },
              {
                border: [0, 0, 0, 0],
                text: item.non_claimable,
                style: "fieldValue",
                color: COLORS.light_blue,
                alignment: "right",
              },
              {
                border: [0, 0, 0, 0],
                text: item.self_reimbursement,
                style: "fieldValue",
                color: COLORS.light_blue,
                alignment: "right",
              },
            ])
          ),
        },
        layout: {
          paddingBottom: function (i: number, node: any) {
            return [0, 1].includes(i) ? 0 : 3;
          },
          paddingTop: function (i: number, node: any) {
            return [0, 1].includes(i) ? 0 : 3;
          },
          hLineColor: function (i: number, node: any) {
            return COLORS.blue;
          },
          hLineWidth: function (i: number, node: any) {
            return [0, 1, 2].includes(i) ? 1 : 0;
          },
        },
      },
      {
        absolutePosition: { x: 30, y: 502.5 },
        stack: [
          {
            margin: [0, 0, 20, 0], // [5, 0, 45, 0]
            table: {
              headerRows: 2,
              widths: ["65%", "17.5%", "17.5%"],
              lineHeight: 2,
              body: [
                [
                  {
                    border: [0, 0, 0, 0],
                    rowSpan: 2,
                    text: "",
                  },
                  {
                    border: [0, 0, 0, 0],
                    text: "",
                    colSpan: 2,
                  },
                  {},
                ],
                [
                  "",
                  {
                    text: "",
                    border: [0, 0, 0, 0],
                  },
                  {
                    text: "",
                    border: [0, 0, 0, 0],
                  },
                ],
              ].concat([
                [
                  {
                    text: "รวม",
                    style: "fieldKey",
                    alignment: "right",
                    border: [0, 0, 0, 0],
                  },
                  {
                    border: [1, 0, 1, 1],
                    borderColor: BORDER_BLUE,
                    text: Number(props.sum_non_claimable)
                      ? formatPrice(props.sum_non_claimable)
                      : "",
                    style: "fieldValue",
                    alignment: "right",
                  },
                  {
                    border: [1, 0, 1, 1],
                    borderColor: BORDER_BLUE,
                    text: Number(props.sum_self_reimbursement)
                      ? formatPrice(props.sum_self_reimbursement)
                      : "",
                    style: "fieldValue",
                    alignment: "right",
                  },
                ],
                [
                  {
                    text: "รวมเงินทั้งสิ้น",
                    style: "fieldKey",
                    alignment: "right",
                    border: [0, 0, 0, 0],
                  },
                  {
                    borderColor: BORDER_BLUE,
                    text: formatPrice(
                      Number(props.sum_non_claimable) + Number(props.sum_self_reimbursement)
                    ),
                    colSpan: 2,
                    style: "fieldValue",
                    alignment: "center",
                  },
                ],
              ] as any[]),
            },
          },
        ],
      },
      {
        absolutePosition: { x: 55, y: 543 },
        stack: [
          {
            margin: [0, 0, 0, 0],
            text: props.round_off_discount,
            style: "facultyHeader",
            color: COLORS.light_blue,
          },
        ],
      },
      {
        absolutePosition: { x: 10, y: 576.5 },
        stack: [
          {
            margin: [0, 0, 0, 0],
            table: {
              widths: ["15.5%", "*", "15.5%"],
              heights: 25.5,
              headerRows: 1,
              body: [
                [
                  { border: [0, 0, 0, 0], text: "" },
                  {
                    margin: [0, 0, 0, -2],
                    text: `(${props.total_amount_text})`,
                    fillColor: COLORS.bg,
                    border: [0, 0, 0, 0],
                    style: "facultyHeader",
                    color: COLORS.light_blue,
                    alignment: "center",
                  },
                  {
                    border: [0, 0, 0, 0],
                    text: "",
                    margin: [0, 0, 200, 0],
                  },
                ],
              ],
            },
          },
          {
            text: "(ตัวอักษร)",
            alignment: "center",
            style: "footerValue",
          },
        ],
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        columns: [
          {
            margin: [50, 0, 0, 0],
            width: "47%",
            text: "",
            stack: [
              {
                margin: [0, 42.5, 0, 0],
                text: "( นางสาวสิริกัลยา อรรถศิริ )",
                alignment: "center",
                style: "footerValue",
              },
              {
                margin: [0, 0, 0, 0],
                text: "ผู้อำนวยการฝ่ายบริหาร",
                alignment: "center",
                style: "footerValue",
              },
              {
                margin: [0, 9.5, 0, 0],
                text: "(แบบพิมพ์หมายเลข 3265/01 พิมพ์ครั้งที่ 02 จำนวน 500 เล่ม)",
                alignment: "center",
                style: "footerValue",
                fontSize: 14.5,
              },
            ],
          },
          {
            width: "42.5%",
            margin: [0, 0, 0, 0],
            stack: [
              {
                text: "ได้รับเงินไว้ถูกต้องแล้ว",
                alignment: "center",
                style: "footerValue",
              },
              {
                margin: [0, 15, 0, 0],
                text: `ลายมือชื่อผู้${Array(65).fill(".").join("")}รับเงิน`,
                alignment: "center",
                style: "footerValue",
              },
              {
                margin: [20, 7.5, 0, 0],
                text: `(${Array(65).fill(".").join("")})`,
                alignment: "center",
                style: "footerValue",
              },
              {
                margin: [-12.5, 7.5, 0, 0],
                text: `ตำแหน่ง${Array(65).fill(".").join("")}`,
                alignment: "center",
                style: "footerValue",
              },
            ],
          },
        ],
      };
    },
    images: {
      logochula: origin + "/static/images/logochula-blue.png",
    },
  };
};

export default FormReceipt;
