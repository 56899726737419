import React, { useMemo } from "react"
import ModDrugDiseaseUX from "./ModDrugDiseaseUX"

const ModDrugDisease = (props: any) => {

    const drugLabInteractionList = useMemo(() => {
        return <div style={{ padding: "15px 20px" }}>
            {(props.modDrugDisease?.diseaseItems || []).map((item: any, index: any) => {
                return (<div style={{ display: "flex", fontSize: "18px", lineHeight: "2.4rem" }}>
                    <div style={{ width: "25px", textAlign: "right", paddingRight: "10px" }}>{`${index + 1}. `}</div>
                    <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
                        <span style={{ color: "red", fontWeight: "bold" }}>{item.drug_name}</span>
                        <span style={{fontWeight: "bold"}}>โรคประจำตัว: {item.segment_group_name}</span>
                        <span>{item.message}</span>
                    </div>
                </div>)
            })}</div>
    }, [props.modDrugDisease?.diseaseItems])

    console.log("ModDrugDisease: ", props, drugLabInteractionList)
    return (
        <ModDrugDiseaseUX
            // data
            drugDiseaseList={drugLabInteractionList}
            orderItemId={props.modDrugDisease?.orderItem?.id || props.modDrugDisease?.orderItem?.note_drug_disease}
            isEditable={!!props.currentDoctor?.id}
            note={props.modDrugDisease?.note}
            // function
            onNoteChange={(e: any, data: any) => { props.setProp("modDrugDisease.note", data.value) }}
            onApprove={() => {
                if (props.modDrugDisease.orderItem?.id || props.modDrugDisease?.orderItem?.note_drug_disease) {
                    props.onEvent({
                        message: "AddToOrder",
                        params: {
                            editItem: {
                                note_drug_disease: props.modDrugDisease?.note,
                            },
                            drugIndex: props.modDrugDisease?.orderItem.drugIndex,
                            orderType: props.orderType,
                            card: "CardDrugOrder",
                        },
                    });
                    props.onEvent({
                        message: "HandleModDrugDisease",
                        params: { action: "close" },
                    })
                } else {
                    props.onEvent({
                        message: "RunSequence",
                        params: { sequence: "DrugSelect", feedback: "confirm", note: props.modDrugDisease?.note },
                    });
                }
            }}
            onDeny={() => {
                if (props.modDrugDisease.orderItem?.id || props.modDrugDisease?.orderItem?.note_drug_disease) {
                    props.onEvent({
                        message: "HandleModDrugDisease",
                        params: { action: "close" },
                    })
                } else {
                    props.onEvent({
                        message: "RunSequence",
                        params: { sequence: "DrugSelect", feedback: "cancel" },
                    });
                }
            }}
        />
    )
}

export default ModDrugDisease