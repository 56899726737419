import { TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

const FormPaidCompensationWorkingType = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props Form Paid Compensation: ", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.paidCompensationData?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน และการรักษา",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `ประเภทการรักษา : ${props.paidCompensationData?.params?.examination_type_label}`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `คลินิก : ${props.paidCompensationData?.params?.division_name} วันที่ ${props.paidCompensationData?.params?.start_date} ถึง ${props.paidCompensationData?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const localeStringOption = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const generateTableBody = (data: any, columns: any) => {
    let body = [];

    // Header of Table
    body.push([
      { text: "ลำดับ", bold: true, alignment: "center" },
      { text: "แพทย์", bold: true, alignment: "center" },
      { text: "สาขาแพทย์", bold: true, alignment: "center" },
      { text: "รายรับ", bold: true, alignment: "center" },
      { text: "ค่า Lab", bold: true, alignment: "center" },
      { text: "รายได้\nหลังหักค่า Lab", bold: true, alignment: "center" },
      { text: "หักค่าบริการ\n20%", bold: true, alignment: "center" },
      { text: "คงเหลือ", bold: true, alignment: "center" },
      { text: "ส่วนแบ่ง", bold: true, alignment: "center" },
      { text: "รวมสุทธิ", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row !== undefined) {
            switch (column) {
              case "revenue_price":
              case "lab_price":
              case "revenue_after_lab":
              case "management_fee":
              case "leftover_revenue":
              case "compensation_percent":
              case "final_revenue_price":
                dataRow.push({
                  text: row[column].toLocaleString("th-TH", localeStringOption),
                  alignment: "right",
                });
                break;
              default:
                dataRow.push({ text: row[column].toString() });
                break;
            }
          } else {
            dataRow.push({ text: "-" });
          }
        });
        body.push(dataRow);
      });
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        // widths: ["auto", "*", "*", "8%", "8%", "8%", "8%", "8%", "auto", "8%"],
        widths: ["auto", "*", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
      layout: {
        hLineWidth: (i: number, node: any) => {
          return i === 0 || i === 1 || i === node.table.body.length ? 1 : 0;
        },
        vLineWidth: (i: number, node: any) => {
          return i === 0 || i === node.table.widths.length ? 1 : 0;
        },
        hLineColor: (i: number, node: any) => {
          return "black";
        },
        vLineColor: (i: number, node: any) => {
          return "black";
        },
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.paidCompensationData?.fields || [], [
        "seq",
        "doctor_name",
        "doctor_specialty",
        "revenue_price",
        "lab_price",
        "revenue_after_lab",
        "management_fee",
        "leftover_revenue",
        "compensation_percent",
        "final_revenue_price",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
  };
};

export default FormPaidCompensationWorkingType;
