import React, { CSSProperties, useEffect, useMemo, useState } from "react";

import { Button, Checkbox, Icon, Modal } from "semantic-ui-react";

import { useIntl } from "react-intl";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";
import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import CardSettingDivisionUX from "./CardSettingDivisionUX";
import ModSelectDivision from "./ModSelectDivision";

// Types
type CardSettingDivisionProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // data
  organizationFilter?: boolean;
  profileDivision?: {
    data: Record<string, any>[];
    raw: Record<string, any>[];
  };
  tableHeaders?: "รหัสองค์กร,ชื่อองค์กร,ประเภทองค์กร,Active,Delete";
  tableKeys?: "code,name,type_label,active,delete";
  user?: Record<string, any> | null;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // options
  divisionOptions?: Record<string, any>[];
};

// styles
const styles = {
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginTop: "5px",
  } as CSSProperties,
  header: {
    backgroundColor: "#C6EBF3",
    fontSize: "1.2rem",
    padding: "15px",
  } as CSSProperties,
};

// Const
const CARD_SETTING_DIVIDION = "CardSettingDivision";

const CardSettingDivision = (props: CardSettingDivisionProps) => {
  const intl = useIntl();

  const [openDivision, setOpenDivision] = useState<boolean>(false);
  const [openAddExtra, setOpenAddExtra] = useState<boolean>(false);
  const [modConfirmDelete, setModConfirmDelete] = useState<Record<string, any> | null>(null);
  const [modInfo, setModInfo] = useState<{
    color: "green" | "yellow";
    msg: string;
  } | null>(null);

  const [index, setIndex] = useState<number>();
  const [selectedExtra, setSelectedExtra] = useState<string[]>([]);
  const [divisions, setDivisions] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    props.onEvent({ message: "SettingDivision", params: {} });

    return () => {
      props.setProp(`errorMessage.${CARD_SETTING_DIVIDION}`, {
        error: null,
      });
    };
  }, []);

  useEffect(() => {
    const divisionHasUser = props.profileDivision?.data.map((item: any) => item.division.id);
    const divisions = (props.divisionOptions || []).filter(
      (item: any) => !divisionHasUser?.includes(item.value)
    );

    setDivisions(divisions);
  }, [props.divisionOptions, props.profileDivision]);

  const titleAddDivision = useMemo(
    () => (props.organizationFilter ? intl.formatMessage({ id: "key871" }) : "เพิ่มแผนก"),
    [props.organizationFilter]
  );

  const dataDivision = useMemo(() => {
    const data = props.profileDivision?.data || [];

    return data.map((item: any, index: number) => {
      const useRights: string[] = item.extra?.useRights || [];

      return {
        ...item,
        active: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={!!item.extra?.is_Active}
              onChange={(e: any, v: any) => {
                props.setProp(`profileDivision.data.${index}.extra.is_Active`, v.checked);
              }}
            />
          </div>
        ),
        code: item.division.code,
        delete: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Icon
              color="red"
              name="trash alternate"
              link
              onClick={() => {
                setModConfirmDelete(item);
              }}
            />
          </div>
        ),
        name: item.division.name,
        type_label: item.division.type_label,
        extra: (
          <div style={{ display: "flex" }}>
            <div> {useRights.join(", ")}</div>
            <div style={{ flex: "1" }}> </div>
            <Button
              color="yellow"
              icon="edit"
              size="mini"
              style={{ height: "fit-content" }}
              onClick={() => {
                setOpenAddExtra(true);
                setIndex(index);
                setSelectedExtra(item.extra?.useRights || []);
              }}
            />
          </div>
        ),
      };
    });
  }, [props.profileDivision]);

  // Hanler
  const handleGetTdProps = () => ({
    style: {
      paddingLeft: "1rem",
    },
  });

  const handleSave = () => {
    props.onEvent({
      message: "SettingDivision",
      params: {
        action: "SAVE",
        id: props.user?.id,
        buttonLoadKey: `${CARD_SETTING_DIVIDION}_SAVE`,
        card: CARD_SETTING_DIVIDION,
        onSuccess: () => {
          setModInfo({ color: "green", msg: "บันทึกข้อมูลสำเร็จ" });
        },
      },
    });
  };

  const handleSelect = (divisions: any[]) => {
    props.onEvent({
      message: "SettingDivision",
      params: {
        action: "ADD_DIVISION",
        id: props.user?.id,
        card: CARD_SETTING_DIVIDION,
        selectedDivision: divisions,
      },
    });

    setOpenDivision(false);
  };

  const handleChangeRight = (e: any, v: any) => {
    let useRight = selectedExtra;

    if (v.checked === false) {
      useRight = useRight.filter((value: any) => value !== v.name);
    } else if (v.checked === true) {
      useRight.push(v.name);
    }

    setSelectedExtra([...useRight]);
  };

  const handleSaveRight = () => {
    if (index === undefined) {
      return;
    }

    const { extra } = props.profileDivision?.data[index] || {};

    extra.useRights = selectedExtra;

    props.setProp(`profileDivision.data.${index}.extra`, { ...extra });

    setOpenAddExtra(false);
  };

  const handleCancel = () => {
    props.setProp("profileDivision", {
      data: JSON.parse(JSON.stringify(props.profileDivision?.raw || [])),
      raw: props.profileDivision?.raw || [],
    });
  };

  const handleCloseModConfirmDelete = () => {
    setModConfirmDelete(null);

    props.setProp(`buttonLoadCheck.${CARD_SETTING_DIVIDION}_DELETE`, null);
  };

  const handleConfirmDelete = () => {
    props.onEvent({
      message: "SettingDivision",
      params: {
        action: "DELETE_DIVISION",
        buttonLoadKey: `${CARD_SETTING_DIVIDION}_DELETE`,
        card: CARD_SETTING_DIVIDION,
        data: modConfirmDelete,
        onSuccess: handleCloseModConfirmDelete,
      },
    });
  };

  const handleCloseModInfo = () => {
    setModInfo(null);
  };

  const handleOpenDivision = () => {
    setOpenDivision(true);
  };

  // #console.log("CardSettingDivision", props);

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_SETTING_DIVIDION]?.error}
        success={null}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_SETTING_DIVIDION}`, null);
        }}
      />

      <CardSettingDivisionUX
        // data
        dataDivision={dataDivision}
        headers={props.tableHeaders}
        keys={props.tableKeys}
        titleAddDivision={titleAddDivision}
        showTable
        // callback
        getTdProps={handleGetTdProps}
        onCancel={handleCancel}
        onOpenDivision={handleOpenDivision}
        // Element
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={props.profileDivision?.raw.length ? "yellow" : "green"}
            disabled={!(props.profileDivision?.data.length || props.profileDivision?.raw.length)}
            name="SAVE"
            // data
            paramKey={`${CARD_SETTING_DIVIDION}_SAVE`}
            size="small"
            style={{ width: "100%" }}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SETTING_DIVIDION}_SAVE`]}
            onClick={handleSave}
            title={
              props.profileDivision?.raw.length
                ? intl.formatMessage({
                    id: "key805",
                    defaultMessage: "บันทึกการแก้ไข",
                  })
                : intl.formatMessage({ id: "key180", defaultMessage: "บันทึก" })
            }
          />
        }
      />

      <ModSelectDivision
        open={openDivision}
        organizationFilter
        divisionOptions={divisions}
        onClose={() => {
          setOpenDivision(false);
        }}
        onSelect={handleSelect}
      />

      <Modal
        open={openAddExtra}
        onClose={() => {
          setOpenAddExtra(false);
        }}
      >
        <>
          <div style={styles.header}>
            <div style={styles.title}>เลือกสิทธิ์การใช้งาน</div>
          </div>

          {["รักษา", "ตรวจงาน", "ตั้งค่าตารางออกตรวจ", "แก้ไขระดับราคาและส่วนลดค่าบริการ"].map(
            (value) => (
              <div key={`Radio-${value}`}>
                <Checkbox
                  checked={selectedExtra.includes(value)}
                  label={value}
                  name={value}
                  style={{ margin: "10px" }}
                  onChange={handleChangeRight}
                />
              </div>
            )
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <Button color="green" style={{ marginRight: "20px" }} onClick={handleSaveRight}>
              บันทึก
            </Button>
            <Button
              color="red"
              style={{ marginRight: "20px" }}
              onClick={() => {
                setOpenAddExtra(false);
              }}
            >
              ยกเลิก
            </Button>
          </div>
        </>
      </Modal>

      <ModConfirmCNMI
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        openModal={!!modConfirmDelete}
        size="mini"
        titleColor="red"
        onCloseWithDimmerClick={handleCloseModConfirmDelete}
        onDeny={handleCloseModConfirmDelete}
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"green"}
            name="DELETE"
            // data
            paramKey={`${CARD_SETTING_DIVIDION}_DELETE`}
            size="medium"
            title="ตกลง"
            basic
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SETTING_DIVIDION}_DELETE`]}
            onClick={handleConfirmDelete}
          />
        }
        content={
          <div
            style={{
              fontWeight: "bold",
              margin: "0rem 0 -1rem",
              textAlign: "center",
            }}
          >
            ต้องการลบข้อมูลหรือไม่
          </div>
        }
      />

      <ModInfo
        open={!!modInfo}
        titleColor={modInfo?.color}
        titleName={modInfo?.msg}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      />
    </>
  );
};

export default React.memo(CardSettingDivision);
