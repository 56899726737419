import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Icon,
  Button
} from 'semantic-ui-react'

const ModEditDoctorFeeUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{backgroundColor: "#FFFFF",width: "100%",height:"100%",padding:"10px 1rem"}}>
        <div
          style={{display: "flex", alignItems: "center", margin: "0 0 -0.35rem"}}>
          
          <label
            className="ui blue ribbon label large">
            
            <label>
              {props.detail?.doctor_name||"-"}
            </label>
          </label>
          <label
            className="ui blue tag label large"
            style={{marginLeft: "4rem"}}>
            
            <label>
              {props.detail?.bank_branch_name||"-"}
            </label>
            <label
              style={{marginLeft: "2rem"}}>
              {`เลขที่บัญชี : ${props.detail?.bank_account_no||"-"}`}
            </label>
          </label>
          <div
            style={{flex:1}}>
            
          </div>
          <Icon
            className="large red"
            link={true}
            name="close"
            onClick={props.onClose}>
          </Icon>
        </div>
        <div
          className="ui divider">
          
        </div>
        <Form
          className="--readOnly --grey">
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                รายรับ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput"
                disabled={true}
                fluid={true}
                value={props.formattedPrice?.revenue_price||""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                ค่าแลป
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput"
                fluid={true}
                name="lab_price"
                onChange={props.onChangeValue}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                value={props.detail?.lab_price || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                รายได้หลังหักค่าแลป
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput"
                disabled={true}
                fluid={true}
                value={props.formattedPrice?.revenue_after_lab_price||""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                หักค่าบริหาร 20 %
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput"
                disabled={true}
                fluid={true}
                value={props.formattedPrice?.management_fee_price||""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                คงเหลือ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput"
                disabled={true}
                fluid={true}
                value={props.formattedPrice?.leftover_revenue_price||""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                ส่วนแบ่ง (%)
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput"
                disabled={true}
                fluid={true}
                value={props.formattedPrice?.compensation_percent||""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                รายได้หลังหักส่วนแบ่ง
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput"
                disabled={true}
                fluid={true}
                value={props.formattedPrice?.final_revenue_price||""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                ปรับเพิ่ม
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput"
                name="adjust_up_price"
                onChange={props.onChangeValue}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                value={props.detail?.adjust_up_price||""}>
              </Input>
            </FormField>
            <FormField
              className={`required ${props.showRequiredField?.remark_adjust_up_price? "error" : ""}`}
              inline={true}
              style={{marginLeft: "1rem"}}
              width={9}>
              <label
                style={{minWidth: "max-content"}}>
                ระบุเหตุผล
              </label>
              <Input
                fluid={true}
                name="remark_adjust_up_price"
                onChange={props.onChangeValue}
                value={props.detail?.remark_adjust_up_price}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                ปรับลด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput"
                name="adjust_down_price"
                onChange={props.onChangeValue}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                value={props.detail?.adjust_down_price||""}>
              </Input>
            </FormField>
            <FormField
              className={`required ${props.showRequiredField?.remark_adjust_down_price? "error" : ""}`}
              inline={true}
              style={{marginLeft: "1rem"}}
              width={9}>
              <label
                style={{minWidth: "max-content"}}>
                ระบุเหตุผล
              </label>
              <Input
                fluid={true}
                name="remark_adjust_down_price"
                onChange={props.onChangeValue}
                value={props.detail?.remark_adjust_down_price}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              style={{minWidth: "11rem", maxWidth: "11rem"}}>
              <label>
                รวมสุทธิ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                className="priceInput --green"
                disabled={true}
                fluid={true}
                value={props.formattedPrice?.net_price||""}>
              </Input>
            </FormField>
          </FormGroup>
        </Form>
        <Form>
          <FormGroup
            inline={true}
            style={{marginTop: "2rem"}}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField>
              <div>
                {props.ButtonSave}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="red"
                onClick={props.onClickCancel}>
                ยกเลิก
              </Button>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default ModEditDoctorFeeUX

export const screenPropsDefault = {}

/* Date Time : Fri Apr 05 2024 12:02:07 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#FFFFF\",width: \"100%\",height:\"100%\",padding:\"10px 1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"0 0 -0.35rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui blue ribbon label large"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui blue tag label large"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"4rem\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.bank_branch_name||\"-\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "`เลขที่บัญชี : ${props.detail?.bank_account_no||\"-\"}`"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"2rem\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.doctor_name||\"-\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--readOnly --grey"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 70,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 70,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "รายรับ"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "ค่าแลป"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "รายได้หลังหักค่าแลป"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "หักค่าบริหาร 20 %"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "คงเหลือ"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนแบ่ง (%)"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "รายได้หลังหักส่วนแบ่ง"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Input",
      "parent": 88,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedPrice?.final_revenue_price||\"\""
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Input",
      "parent": 86,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedPrice?.compensation_percent||\"\""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Input",
      "parent": 84,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedPrice?.leftover_revenue_price||\"\""
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Input",
      "parent": 82,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedPrice?.management_fee_price||\"\""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Input",
      "parent": 80,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedPrice?.revenue_after_lab_price||\"\""
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Input",
      "parent": 78,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "lab_price"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.lab_price || \"\""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Input",
      "parent": 76,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.formattedPrice?.revenue_price||\"\""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 103,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 103,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 103,
      "props": {
        "className": {
          "type": "code",
          "value": "`required ${props.showRequiredField?.remark_adjust_up_price? \"error\" : \"\"}`"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "ปรับเพิ่ม"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุเหตุผล"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Input",
      "parent": 105,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput"
        },
        "name": {
          "type": "value",
          "value": "adjust_up_price"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.adjust_up_price||\"\""
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Input",
      "parent": 106,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "remark_adjust_up_price"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.remark_adjust_up_price"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Icon",
      "parent": 58,
      "props": {
        "className": {
          "type": "value",
          "value": "large red"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 114,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 114,
      "props": {
        "className": {
          "type": "code",
          "value": "`required ${props.showRequiredField?.remark_adjust_down_price? \"error\" : \"\"}`"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "label",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "ปรับลด"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุเหตุผล"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Input",
      "parent": 117,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "remark_adjust_down_price"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.remark_adjust_down_price"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Input",
      "parent": 116,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput"
        },
        "name": {
          "type": "value",
          "value": "adjust_down_price"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.adjust_down_price||\"\""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormGroup",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 122,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 122,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "รวมสุทธิ"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "Input",
      "parent": 124,
      "props": {
        "className": {
          "type": "value",
          "value": "priceInput --green"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedPrice?.net_price||\"\""
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "FormGroup",
      "parent": 127,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "FormField",
      "parent": 128,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "FormField",
      "parent": 128,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Button",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickCancel"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormField",
      "parent": 128,
      "props": {
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 132,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 133,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 35,
  "isMounted": true,
  "memo": false,
  "name": "ModEditDoctorFeeUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
