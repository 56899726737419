import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import {Button, Form,Icon, Label, Segment } from "semantic-ui-react";

import CardLayout from "../common/CardLayout";
import ErrorMessage from "../common/ErrorMessage";

import SubAdmission from "./SubAdmission";
import SubSystemicReview from "./SubSystemicReview";
import SubPhysicalExamination from "./SubPhysicalExamination";
import SubIV from "./SubIV";
import SubProblemList from "./SubProblemList";
import SubProvisionDiag from "./SubProvisionDiag";
import SubAssessmentAndPlan from "./SubAssessmentAndPlan";

import CardPhysicalExam from '../HISV3/DPO/CardPhysicalExam'

import _ from "../../compat/lodashplus";

const CardAdmissionForm = React.forwardRef((props, ref) => {
  const isMounted = useRef(true);
  const subAdmissionRef = useRef();
  const subSystemicReviewRef = useRef();
  const subPhysicalExaminationRef = useRef();
  const subIVRef = useRef();
  const subProblemListRef = useRef();
  const subProvisionDiagRef = useRef();
  const subAssessmentAndPlanRef = useRef();

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSubAdmission, setLoadingSubAdmission] = useState(false);
  const [saveSubAdmission, setSaveSubAdmission] = useState(false);
  const [loadingSystemic, setLoadingSystemic] = useState(false);
  const [saveSystemic, setSaveSystemic] = useState(false);
  const [loadingPhysical, setLoadingPhysical] = useState(false);
  const [savePhysical, setSavePhysical] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [saveInitial, setSaveInitial] = useState(false);
  const [loadingProblem, setLoadingProblem] = useState(false);
  const [saveProblem, setSaveProblem] = useState(false);
  const [loadingProvisional, setLoadingProvisional] = useState(false);
  const [saveProvisional, setSaveProvisional] = useState(false);
  const [loadingAssessment, setLoadingAssessment] = useState(false);
  const [saveAssessment, setSaveAssessment] = useState(false);
  const [admissionFormResponse, setAdmissionFormResponse] = useState(null) // use for check edit only
  const [physicalOrganList, setPhysicalOrganList] = useState([]);
  const [physicalTemplateList, setPhysicalTemplateList] = useState([]);
  const [patientExamOther, setPatientExamOther] = useState({});

  const { titleCard, emrId, patientId, isReadOnly, PATIENT_DATA } = props;

  useImperativeHandle(ref, () => ({
    checkEdit: () => {
      return checkEdit()
    },
  }));


  useEffect(() => {
    props.runSequence({ sequence: "PhysicalExam", restart: true });
    refresh();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const checkEdit = () => {
    if(!admissionFormResponse){
      return false
    }
    let editAdmissionForm = checkEditAdmissionForm()
    let editSystemicReview = checkEditSystemicReview()
    // let editPhysicalExamination = checkEditPhysicalExamination()
    let editInitialInvestigation = checkEditInitialInvestigation()
    let editProblemList = checkEditProblemList()
    let editProvisionalDiagnosis = checkEditProvisionalDiagnosis()
    let editAssessmentPlan = checkEditAssessmentPlan()
    return (
      editAdmissionForm ||
      editSystemicReview || 
      // editPhysicalExamination || 
      editInitialInvestigation ||
      editProblemList ||
      editProvisionalDiagnosis ||
      editAssessmentPlan
    )
  }

  const checkEditAdmissionForm = () => {
    let admissionForm = _.cloneDeep(subAdmissionRef.current.getSaveData())
    let prevAdmissionForm = _.cloneDeep(admissionFormResponse.admission_form)
    delete prevAdmissionForm.id
    delete prevAdmissionForm.created
    delete prevAdmissionForm.created_by
    return JSON.stringify(admissionForm) !== JSON.stringify(prevAdmissionForm) //! beware of ordering
  }

  const checkEditSystemicReview = () => {
    let systemicReview = subSystemicReviewRef.current.getSaveData()
    let prevSystemicReview = admissionFormResponse.systemic_review
    return JSON.stringify(systemicReview) !== JSON.stringify(prevSystemicReview) //! beware of ordering
  }

  const checkEditPhysicalExamination = () => {
    let physicalExamination = subPhysicalExaminationRef.current.getSaveData()
    let prevPhysicalExamination = _.cloneDeep(admissionFormResponse.physical_examination)
    if(prevPhysicalExamination.items){
      prevPhysicalExamination.items.forEach((item) => {
        delete item.organ_name
        delete item.status_full
      })
    }
    return JSON.stringify(physicalExamination) !== JSON.stringify(prevPhysicalExamination) //! beware of ordering
  }

  const checkEditInitialInvestigation = () => {
    let initialInvestigation = subIVRef.current.getSaveData()
    let prevInitialInvestigation = admissionFormResponse.initial_investigation
    return JSON.stringify(initialInvestigation) !== JSON.stringify(prevInitialInvestigation) //! beware of ordering
  }

  const checkEditProblemList = () => {
    let problemList = subProblemListRef.current.getSaveData()
    let prevProblemList = admissionFormResponse.problem_list
    return JSON.stringify(problemList) !== JSON.stringify(prevProblemList) //! beware of ordering
  }

  const checkEditProvisionalDiagnosis = () => {
    let provisionalDiagnosis = subProvisionDiagRef.current.getSaveData()
    let prevProvisionalDiagnosis = _.cloneDeep(admissionFormResponse.provisional_diagnosis);
    if(prevProvisionalDiagnosis.primary){
      prevProvisionalDiagnosis.primary.icd_term = prevProvisionalDiagnosis.primary.icd10_term
      prevProvisionalDiagnosis.primary.icd_code = prevProvisionalDiagnosis.primary.icd10_code
    } 
    if (prevProvisionalDiagnosis.secondary){
      prevProvisionalDiagnosis.secondary.forEach(function(item) {
        item["icd_term"] = item.icd10_term;
        item["icd_code"] = item.icd10_code;
      });
    }
    return JSON.stringify(provisionalDiagnosis) !== JSON.stringify(prevProvisionalDiagnosis) //! beware of ordering
  }

  const checkEditAssessmentPlan = () => {
    let assessmentPlan = subAssessmentAndPlanRef.current.getSaveData()
    let prevAssessmentPlan = admissionFormResponse.assessment_plan
    return JSON.stringify(assessmentPlan) !== JSON.stringify(prevAssessmentPlan) //! beware of ordering
  }

  const refresh = async () => {
    setIsLoading(true);
    const [response, error, network] = await props.controller.getAdmissionForm(
      emrId
    );
    setAdmissionFormResponse(response)
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        subAdmissionRef.current.setData(response.admission_form);
        // if not cloneDeep, data in admissionFormResponse will change when data in subSystemicReviewRef change
        subSystemicReviewRef.current.setData(_.cloneDeep(response.systemic_review));
        subIVRef.current.setData(response.initial_investigation);
        subProblemListRef.current.setData(response.problem_list);
        subProvisionDiagRef.current.setData(response.provisional_diagnosis);
        subAssessmentAndPlanRef.current.setData(response.assessment_plan);

        setPatientExamOther(response.physical_examination?.patient_exam_other);
        
        if (response.physical_examination?.items?.length>0){
          setPhysicalOrganList(response.physical_examination?.items);
        } else {
          getPhysicalExamOrgan();
        }
        if (response.physical_examination?.exam_template?.length > 0) {
          setPhysicalTemplateList(response.physical_examination?.exam_template);
        } else {
          setPhysicalTemplateList([false]);
        }
      }
      setIsLoading(false);
    }
  };

  const getPhysicalExamOrgan = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getPhysicalExamOrgan();
    if (isMounted.current) {
      setIsLoading(false);
      if (data?.items?.length > 0) {
        setPhysicalOrganList(data?.items);
        
      }
    }
  };


  const saveAll = async (id:any) => {
    // setIsLoading(true);
    if (id === "subAdmission") {
      setLoadingSubAdmission(true);
    } else if (id === "subSystemicReview") {
      setLoadingSystemic(true);
    } else if (id === "subPhysicalExamination") {
      setLoadingPhysical(true);
    } else if (id === "subIV") {
      setLoadingInitial(true);
    } else if (id === "subProblemList") {
      setLoadingProblem(true);
    } else if (id === "subProvisionDiagRef") {
      setLoadingProvisional(true);
    } else if (id === "subAssessmentAndPlan") {
      setLoadingAssessment(true);
    }

    // Physical Examination
    const organ: any[] = props.PhysicalExamSequence?.organList?.map(
      (item: any) => {
        let result: any = {
          organ_id: item.organ_id,
          status: item.status,
          result: item.result,
          description: item.description,
          emr: item.emr,
        };
        if (item.result === "") {
          result.result = "N/A";
          result.description = "";
        } else if (item.result === "N/A") {
          result.description = "";
        }
        return result;
      }
    );

    const template: any[] = [];
    props.PhysicalExamSequence.selectedTemplateList?.map((temp: any) => {
      return temp.items.map((item: any) => {
        let result: any = {
          organ_id: item.organ,
          status: item.status || null,
          result: item.result || "",
          description: item.description || "",
          template_item: item.id,
          emr: temp.emr,
        };
        if (result.result === "") {
          result.result = "N/A";
          result.description = "";
        } else if (result.result === "N/A") {
          result.description = "";
        }
        template.push(result);
      });
    });

    // Data
    const data = {
      admission_form: subAdmissionRef?.current?.getSaveData(),
      systemic_review: subSystemicReviewRef?.current?.getSaveData(),
      physical_examination: {
        items: [...organ, ...template],
        patient_exam_other: { ...props.PhysicalExamSequence.otherExam },
      },
      initial_investigation: subIVRef?.current?.getSaveData(),
      problem_list: subProblemListRef?.current?.getSaveData(),
      provisional_diagnosis: subProvisionDiagRef?.current?.getSaveData(),
      assessment_plan: subAssessmentAndPlanRef?.current?.getSaveData()
    };
    const [response, error, network] = await props.controller.putAdmissionForm(
      emrId,
      data
    );
    if (isMounted.current) {
      if (error) {
        if (id === "subAdmission") {
          setLoadingSubAdmission(false);
        } else if (id === "subSystemicReview") {
          setLoadingSystemic(false);
        } else if (id === "subPhysicalExamination") {
          setLoadingPhysical(false);
        } else if (id === "subIV") {
          setLoadingInitial(false);
        } else if (id === "subProblemList") {
          setLoadingProblem(false);
        } else if (id === "subProvisionDiag") {
          setLoadingProvisional(false);
        } else if (id === "subAssessmentAndPlan") {
          setLoadingAssessment(false);
        }
        // setErrors(error);
        // toast.error("บันทึกไม่สำเร็จ");
      } else {
        if (id === "subAdmission") {
          setLoadingSubAdmission(false);
          setSaveSubAdmission(true);
          setTimeout(() => {
            setSaveSubAdmission(false);
          }, 2000)
        } else if (id === "subSystemicReview") {
          setLoadingSystemic(false);
          setSaveSystemic(true);
          setTimeout(() => {
            setSaveSystemic(false);
          }, 2000)
        } else if (id === "subPhysicalExamination") {
          setLoadingPhysical(false);
          setSavePhysical(true);
          setTimeout(() => {
            setSavePhysical(false);
          }, 2000)
        } else if (id === "subIV") {
          setLoadingInitial(false);
          setSaveInitial(true);
          setTimeout(() => {
            setSaveInitial(false);
          }, 2000)
        } else if (id === "subProblemList") {
          setLoadingProblem(false);
          setSaveProblem(true);
          setTimeout(() => {
            setSaveProblem(false);
          }, 2000)
        } else if (id === "subProvisionDiag") {
          setLoadingProvisional(false);
          setSaveProvisional(true);
          setTimeout(() => {
            setSaveProvisional(false);
          }, 2000)
        } else if (id === "subAssessmentAndPlan") {
          setLoadingAssessment(false);
          setSaveAssessment(true);
          setTimeout(() => {
            setSaveAssessment(false);
          }, 2000)
        }
        // toast.success("บันทึกสำเร็จ");
        refresh();
      }
      setIsLoading(false);
    }
  };

  return (
    <CardLayout
      titleText={titleCard}
      headerColor="teal"
      // loading={isLoading}
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.hideCallback}
    >
      <Form>
        <ErrorMessage error={errors} />
      </Form>
      <SubAdmission
        ref={subAdmissionRef}
        onSaveAllCallback={() => saveAll("subAdmission")}
        isReadOnly={isReadOnly}
        loading={loadingSubAdmission}
        saveText={saveSubAdmission}
      />
      <SubSystemicReview
        ref={subSystemicReviewRef}
        onSaveAllCallback={() => saveAll("subSystemicReview")}
        isReadOnly={isReadOnly}
        loading={loadingSystemic}
        saveText={saveSystemic}
      />
      <Segment raised secondary>
        <Label color="grey" ribbon size="big">
          Physical Examination
        </Label>
        <CardPhysicalExam
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // seq
          runSequence={props.runSequence}
          PhysicalExamSequence={props.PhysicalExamSequence}
          // data
          emrId={props.emrId}
          physicalOrganList={physicalOrganList}
          physicalTemplateList={physicalTemplateList}
          patientExamOther={patientExamOther}
        />
        <Form>
          <Form.Group inline>
            <Form.Field width={16}>
              <Button
                color="green"
                type="button"
                onClick={() => saveAll("subPhysicalExamination")}
              >
                {savePhysical ? <Icon className="check"></Icon> : "บันทึก"}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
      <SubIV
        ref={subIVRef}
        PATIENT_DATA={PATIENT_DATA}
        patientId={patientId}
        onSaveAllCallback={() => saveAll("subIV")}
        isReadOnly={isReadOnly}
        loading={loadingInitial}
        saveText={saveInitial}
      />
      <SubProblemList
        ref={subProblemListRef}
        onSaveAllCallback={() => saveAll("subProblemList")}
        isReadOnly={isReadOnly}
        loading={loadingProblem}
        saveText={saveProblem}
      />
      <SubProvisionDiag
        ref={subProvisionDiagRef}
        subICDController={props.subICDController}
        onSaveAllCallback={() => saveAll("subProvisionDiag")}
        isReadOnly={isReadOnly}
        loading={loadingProvisional}
        saveText={saveProvisional}
      />
      <SubAssessmentAndPlan
        ref={subAssessmentAndPlanRef}
        onSaveAllCallback={() => saveAll("subAssessmentAndPlan")}
        isReadOnly={isReadOnly}
        loading={loadingAssessment}
        saveText={saveAssessment}
      />
    </CardLayout>
  );
});

CardAdmissionForm.defaultProps = {
  titleCard: "Admission Form",
  controller: null,
  subICDController: null,

  hideCallback: () => {},

  emrId: null,
  patientId: null,
  isReadOnly: false,
  closeable: true,
  toggleable: true
};

CardAdmissionForm.propTypes = {
  controller: PropTypes.object,
  subICDController: PropTypes.object,

  hideCallback: PropTypes.func,

  emrId: PropTypes.number,
  patientId: PropTypes.number,
  PATIENT_DATA: PropTypes.object,
  isReadOnly: PropTypes.bool,
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool
};

export default React.memo(CardAdmissionForm);
