import React, { useCallback, useState, useEffect } from "react";
import { Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import ModExecuteRadiologyOrderUX from "./ModExecuteRadiologyOrderUX";

// Common
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import { RunSequence } from "./sequence/ImagingWorkList";
import { beToAd, formatDate } from "react-lib/utils/dateUtils";

// Types
type ModExecuteRadiologyOrderProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: ()=> any) => any;
  // seq
  runSequence: RunSequence;
  // data
  open: boolean;
  userName?: string;
  userId?: number;
  orderItemIds?: number[];
  card: string;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose: () => any;
};

type ExecuteOrderDetailType = Partial<{
  date: string;
  time: string;
  radiologist: number;
  note: string;
}>;

// Const
const MOD_EXECUTE_RADIOLOGY_ORDER = "ModExecuteRadiologyOrder";

const SEARCH_DOCTOR_ID = "Doctor_ERO";

const BUTTON_ACTIONS = {
  execute: "EXECUTE",
};

const ModExecuteRadiologyOrder = (props: ModExecuteRadiologyOrderProps) => {
  const [detail, setDetail] = useState<ExecuteOrderDetailType>({});

  // Effect
  useEffect(() => {
    if (props.open) {
      const momentDate = moment();

      setDetail({
        date: formatDate(momentDate),
        time: momentDate.format("HH:mm"),
      });
    }
  }, [props.open]);

  // callback
  const handleSelectedItem = useCallback(
    async (value: any, key: any) => {
      handleChangeValue(null, { name: "radiologist", value });
    },
    [props.searchedItemListWithKey]
  );

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;

    setDetail({
      ...detail,
      [data.name]: value,
    });
  };

  const handleSave = () => {
    const doctor = props.searchedItemListWithKey?.[SEARCH_DOCTOR_ID].find(
      (item: any) => item.id === detail.radiologist
    );

    props.runSequence({
      sequence: "ImagingWorkList",
      action: "UPDATE_STATUS",
      errorKey: props.card,
      card: MOD_EXECUTE_RADIOLOGY_ORDER,
      orderItems: (props.orderItemIds || []).map((id) => ({
        id,
        executed_by: props.userId,
        executed_datetime: moment(
          `${beToAd(detail.date || "")?.format("YYYY-MM-DD")} ${detail.time}`
        ).toISOString(),
        radiologist: detail.radiologist || undefined,
        radiologist_name: doctor.full_name || "",
        action_note: detail.note || "",
      })),
      actionType: "EXECUTE",
      onSuccess: handleClose,
    });
  };

  const handleClose = () => {
    props.setProp(`searchedItemListWithKey.${SEARCH_DOCTOR_ID}`, []);

    setDetail({});

    props.onClose();
  };

  const handleChangeDate = (name: string) => (value: string) => {
    if ((!!value && value.length !== 10) || !value) {
      return;
    }

    handleChangeValue(null, { name, value });
  };

  const handleTimeChange = (time: string) => {
    if (!time) {
      return;
    }

    handleChangeValue(null, { name: "time", value: time });
  };

  return (
    <div>
      <Modal
        open={props.open}
        closeOnDimmerClick={true}
        style={{ width: "40%" }}
        onClose={handleClose}
      >
        <ModExecuteRadiologyOrderUX
          // data
          executeBy={props.userName}
          detail={detail}
          // callback
          onCancel={handleClose}
          onClose={handleClose}
          onChangeDate={handleChangeDate}
          onChangeValue={handleChangeValue}
          // Element
          TimeComboBox={
            <TimeComboBox
              defaultValue={detail.time}
              onTextChange={handleTimeChange}
            />
          }
          SearchBoxRadiologist={
            <SearchBoxDropdown
              onEvent={props.onEvent}
              // config
              type="Doctor"
              id="ERO"
              style={{ width: "100%" }}
              fluid={true}
              useWithKey={true}
              icon="search"
              limit={20}
              // Select
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={detail.radiologist || null}
              setSelectedItem={handleSelectedItem}
            />
          }
          ButtonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              paramKey={`${MOD_EXECUTE_RADIOLOGY_ORDER}_${BUTTON_ACTIONS.execute}`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[
                  `${MOD_EXECUTE_RADIOLOGY_ORDER}_${BUTTON_ACTIONS.execute}`
                ]
              }
              // config
              disabled={!detail.radiologist}
              color={"green"}
              name={BUTTON_ACTIONS.execute}
              size="medium"
              title="CONFIRM"
              basic={true}
              style={{ width: "130px", margin: "0 2rem" }}
            />
          }
        />
      </Modal>
    </div>
  );
};

export default React.memo(ModExecuteRadiologyOrder);
