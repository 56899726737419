import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Button, Icon, Input, InputProps } from "semantic-ui-react";

import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

import CardOperativeNoteTemplateUX from "./CardOperativeNoteTemplateUX";

// Type
type TemDataItemType = {
  id: number | string;
  isEditing: boolean;
  name: string;
  note: string;
};

type CardOperativeNoteTemplateProps = {
  items: TemDataItemType[];
  onClose: () => void;
  onDelete: (item: TemDataItemType) => void;
  onSave: SaveHandler;
  onSearch: (searchTerm: string) => void;
  onUpdate: UpdateHandler;
};

export type SaveHandler = (template: { name: string; note: string }) => void;

export type UpdateHandler = (data: TemDataItemType, isEditing: boolean) => void;

type Styles = Record<"btnEdit" | "btnSelect", CSSProperties>;

// Constant
const styles: Styles = {
  btnEdit: { margin: "0px 5px", width: "150px" },
  btnSelect: { width: "150px" },
};

const CardOperativeNoteTemplate = (props: CardOperativeNoteTemplateProps) => {
  const [openFormTem, setOpenFormTem] = useState(false);
  const [searchTem, setSearchTem] = useState("");
  const [nameTem, setNameTem] = useState("");
  const [noteTem, setNoteTem] = useState("");

  // Callback
  const handleSaveForm = useCallback(() => {
    props.onSave({ name: nameTem, note: noteTem });

    setOpenFormTem(false);
    setNameTem("");
    setNoteTem("");
  }, [nameTem, noteTem]);

  const handleUpdateTem = useCallback((data: TemDataItemType, isEditing: boolean) => {
    props.onUpdate(data, isEditing);
  }, []);

  const handleDeleteTem = useCallback((item: TemDataItemType) => {
    props.onDelete(item);
  }, []);

  const handleChangeNoteName = useCallback((e: any) => {
    setNoteTem(e.target.value);
  }, []);

  const handleChangeSearchTem = useCallback((e: any) => {
    setSearchTem(e.target.value);
  }, []);

  const handleChangeTemName = useCallback((e: any) => {
    setNameTem(e.target.value);
  }, []);

  const handleCloseForm = useCallback(() => {
    setOpenFormTem(false);
  }, []);

  const handleOpenFormTem = useCallback(() => {
    setOpenFormTem(true);
  }, []);

  const handleSearch = useCallback(() => {
    props.onSearch(searchTem);
  }, [searchTem]);

  // Memo
  const iconClose = useMemo(
    () => (
      <div aria-hidden="true" onClick={props.onClose}>
        <Icon className="close" color="red" size="large" />
      </div>
    ),
    []
  );

  const temData = useMemo(
    () => (
      <div>
        {props.items.map((item: TemDataItemType) => (
          <TemDataItem
            key={`item-${item.id}`}
            item={item}
            onDeleteTem={handleDeleteTem}
            onUpdateTem={handleUpdateTem}
          />
        ))}
      </div>
    ),
    [handleDeleteTem, handleUpdateTem, props.items]
  );

  return (
    <CardOperativeNoteTemplateUX
      iconClose={iconClose}
      noteName={noteTem}
      searchTem={searchTem}
      styleFormTem={openFormTem}
      temData={temData}
      temName={nameTem}
      // callback
      onChangeNoteName={handleChangeNoteName}
      onChangeSearchTem={handleChangeSearchTem}
      onChangeTemName={handleChangeTemName}
      onCloseForm={handleCloseForm}
      onOpenFormTem={handleOpenFormTem}
      onSaveForm={handleSaveForm}
      onSearchData={handleSearch}
    />
  );
};

/* ====================================================== */
/*                       TemDataItem                      */
/* ====================================================== */
type TemDataItemProps = {
  item: TemDataItemType;
  // callback
  onDeleteTem: (item: TemDataItemType) => void;
  onUpdateTem: (data: TemDataItemType, isEditing: boolean) => void;
};

const TemDataItem = React.memo((props: TemDataItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [openDeleteTem, setOpenDeleteTem] = useState(false);
  const [data, setData] = useState({ name: "", note: "" });

  useEffect(() => {
    setData({ name: props.item.name, note: props.item.note });
  }, [props.item.name, props.item.note]);

  // Callback
  const handleEditing = useCallback(() => {
    setIsEditing(!isEditing);

    if (isEditing) {
      setData({ name: props.item.name, note: props.item.note });
    }
  }, [isEditing, props.item]);

  const handleConfirmDelete = useCallback(() => {
    props.onDeleteTem(props.item);

    setOpenDeleteTem(false);
  }, [props.item]);

  const handleUpdateTem = useCallback(() => {
    props.onUpdateTem({ ...props.item, ...data }, isEditing);

    if (isEditing) {
      setIsEditing(false);
    }
  }, [data, isEditing, props.item]);

  const handleEditValue = useCallback(
    (e: SyntheticEvent, v: InputProps) => {
      setData({ ...data, [v.name]: v.value });
    },
    [data]
  );

  const handleOpenDeleteTem = useCallback(() => {
    setOpenDeleteTem(true);
  }, []);

  const handleCloseDeleteTem = useCallback(() => {
    setOpenDeleteTem(false);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgba(255,255,255,255)",
        marginBottom: "10px",
        padding: "15px",
      }}
    >
      <div style={{ marginBottom: "10px" }}>
        {isEditing ? (
          <Input name="name" value={data.name} fluid onChange={handleEditValue} />
        ) : (
          <label style={{ fontSize: "18px" }}>{data.name}</label>
        )}
      </div>
      <div style={{ marginBottom: "20px" }}>
        {isEditing ? (
          <Input name="note" value={data.note} fluid onChange={handleEditValue} />
        ) : (
          <div>{data.note}</div>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button
            color="green"
            size="mini"
            style={styles.btnSelect}
            basic
            onClick={handleUpdateTem}
          >
            {isEditing ? "บันทึกการแก้ไข" : "เลือก"}
          </Button>
          <Button
            color={isEditing ? "red" : "blue"}
            size="mini"
            style={styles.btnEdit}
            basic
            onClick={handleEditing}
          >
            {isEditing ? "ยกเลิกการแก้ไข" : "แก้ไข Template"}
          </Button>
        </div>
        <div style={{ display: isEditing ? "block" : "none" }}>
          <Button
            color="red"
            icon="trash alternate"
            size="mini"
            basic
            onClick={handleOpenDeleteTem}
          />
        </div>
      </div>

      <ModConfirm
        content={<>ต้องการลบรายการใช่หรือไม่</>}
        openModal={openDeleteTem}
        titleColor="yellow"
        onApprove={handleConfirmDelete}
        onDeny={handleCloseDeleteTem}
      />
    </div>
  );
});

TemDataItem.displayName = "TemDataItem";

CardOperativeNoteTemplate.displayName = "CardOperativeNoteTemplate";

export default React.memo(CardOperativeNoteTemplate);
