import React, {
  useRef,
  useState,
  useMemo,
  CSSProperties,
  MutableRefObject,
  useEffect,
} from "react";

// Framework
import TabPenta from "react-lib/frameworks/TabPenta";
import { Modal } from "semantic-ui-react";

// UX
import CardDiseaseSegmentGroupUX from "./CardDiseaseSegmentGroupUX";
import ModDrugDiseaseISegmentGroup from "./ModDrugDiseaseISegmentGroup";
import CardDrugDiseaseISegmentResult from "./CardDrugDiseaseISegmentResult";

// Interface
import { MasterOptionsType, RunSequence, State } from "./sequence/DrugDiseaseInteraction";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Types
type CardDrugDiseaseInteractionProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  DrugDiseaseInteractionSequence?: State["DrugDiseaseInteractionSequence"];
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
};

// Constant
const ICONS = {
  segment: "/static/images/segment-icon.png",
};

const COLORS = {
  grey: "rgb(204, 204, 204)",
  divider: "rgba(228, 218, 218, 0.5)",
  label: "#979797",
  blue: "#2185d0",
  card_active: "#d6ecf3",
};

const styles = {
  card: {
    padding: "15px 10px",
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: "pointer",
  } as CSSProperties,
  icon_button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
};

export const CARD_DRUG_DISEASE_INTERACTION = "CardDrugDiseaseInteraction";

const CardDrugDiseaseInteraction = (props: CardDrugDiseaseInteractionProps) => {
  const [openModNewSegment, setOpenModNewSegment] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const segmentListRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    props.runSequence({
      sequence: "DrugDiseaseInteraction",
      restart: true,
      action: "FETCH_SEGMENT_GROUP",
    });
  }, []);

  // Use Memo

  // Handler
  const handleSelected = (data: any) => (e: any) => {
    props.runSequence({
      sequence: "DrugDiseaseInteraction",
      data,
      action: "SELECT_SEGMENT_GROUP",
    });
  };

  const handleNewSegment = () => {
    setOpenModNewSegment(true);
  };

  const handleCloseModNewSegment = () => {
    setOpenModNewSegment(false);
  };

  const handleSuccess = () => {
    handleCloseModNewSegment();
  };

  const handleChangeInput = (e: any, data: any) => {
    setSearchText(data.value);
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "DrugDiseaseInteraction",
      action: "FETCH_SEGMENT_GROUP",
      name: searchText,
    });
  };

  console.log("CardDrugDiseaseInteraction", props);

  return (
    <div className="DrugDiseaseInteraction" style={{ height: "95vh" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_DRUG_DISEASE_INTERACTION}`, null);
        }}
        error={props.errorMessage?.[CARD_DRUG_DISEASE_INTERACTION]}
        success={null}
      />

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "22.5% 1fr",
          height: "100%",
        }}
      >
        {/* Segment Group List */}
        <div style={{ borderRight: `1px solid ${COLORS.grey}` }}>
          <CardDiseaseSegmentGroupUX
            // data
            searchText={searchText}
            // callback
            onNewSegment={handleNewSegment}
            onChangeInput={handleChangeInput}
            onSearch={handleSearch}
            // Element
            SegmentList={
              <div
                ref={segmentListRef}
                style={{
                  width: "100%",
                  overflow: "auto",
                  height: "calc(100vh - 185px)",
                }}
              >
                {(props.DrugDiseaseInteractionSequence?.items || []).map(
                  (item) => {
                    const detail =
                      props.DrugDiseaseInteractionSequence
                        ?.segmentGroupDetail || {};
                    return (
                      <>
                        <div
                          style={{
                            ...styles.card,
                            backgroundColor:
                              detail &&
                              `${detail.drug_id}-${detail.drug_type}-${detail.id}` ===
                                `${item.drug_id}-${item.drug_type}-${item.id}`
                                ? COLORS.card_active
                                : "",
                          }}
                          onClick={handleSelected(item)}
                        >
                          {item.drug_name} [
                          {
                            props.DrugDiseaseInteractionSequence?.drugTypeDic?.[
                              item.drug_type
                            ]
                          }
                          ]
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <TabPenta
            // selectedTabIndex={1}
            key={"segment-tab"}
            tabWidth="75px"
            disabled={!props.DrugDiseaseInteractionSequence?.segmentGroupDetail}
            activeStyle={
              !!props.DrugDiseaseInteractionSequence?.segmentGroupDetail
                ? { backgroundColor: COLORS.blue }
                : { backgroundColor: "" }
            }
            activeItemStyle={
              !!props.DrugDiseaseInteractionSequence?.segmentGroupDetail && {
                filter: "brightness(0) invert(1)",
              }
            }
            data={[
              {
                key: "Segment",
                name: (
                  <div style={styles.icon_button}>
                    <img
                      src={ICONS.segment}
                      alt={`segment.icon`}
                      style={{ width: "100%", marginRight: "-0.5rem" }}
                    />
                    <label
                      style={{ color: COLORS.label, marginTop: "-0.25rem" }}
                    >
                      Segment
                    </label>
                  </div>
                ),
                render: () => (
                  <>
                    <CardDrugDiseaseISegmentResult
                      onEvent={props.onEvent}
                      setProp={props.setProp}
                      // seq
                      runSequence={props.runSequence}
                      // CommonInterface
                      buttonLoadCheck={props.buttonLoadCheck}
                      // data
                      segment={
                        props.DrugDiseaseInteractionSequence?.segmentGroupDetail
                      }
                      subSegmentList={
                        props.DrugDiseaseInteractionSequence?.subSegmentList
                      }
                      segmentGroupUDList={
                        props.DrugDiseaseInteractionSequence?.segmentGroupUDList
                      }
                      subGroupDict={
                        props.DrugDiseaseInteractionSequence?.subGroupDict
                      }
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>

      <Modal
        open={openModNewSegment}
        closeOnDimmerClick={true}
        closeIcon={true}
        size="tiny"
        // callback
        onClose={handleCloseModNewSegment}
      >
        <ModDrugDiseaseISegmentGroup
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          errorKey={CARD_DRUG_DISEASE_INTERACTION}
          drugTypeDict={props.DrugDiseaseInteractionSequence?.drugTypeDic}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          masterOptions={props.masterOptions}
          // callback
          onSuccess={handleSuccess}
          onCancel={handleCloseModNewSegment}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardDrugDiseaseInteraction);
