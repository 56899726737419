import PropTypes from "prop-types";
import React, { useState, useEffect, SyntheticEvent } from "react";
import { Menu, Button, Form, Input, Checkbox } from "semantic-ui-react";
import ReactTable from "react-table-6";
import CardLayout from "react-lib/apps/common/CardLayout";
import _ from "react-lib/compat/lodashplus";

var searchText = "";
var selectNote: any = [];
const ModSearchDoctorNote = (props: any) => {
	const [orderData, setOrderData] = useState([]);
	const [selectType, setSelectType] = useState(ORDER_TYPE.SINGLE);
	const [selectLang, setSelectLang] = useState(LANGUAGE.THAI);

	const searchOrder = async (type: string, params: any) => {
		selectNote = [];
		var data = [];
		switch (type) {
			case ORDER_TYPE.SINGLE:
				data = await props.controller.getDoctorNoteList(params);
				break;

			case ORDER_TYPE.GROUP:
				data = await props.controller.getDoctorNoteGroupList(params);
				break;

			default:
				break;
		}
		setOrderData(data.filter((item: any) => item.result));
	};

	/**
	 * Handle 'Search' button
	 */
	const handleSearchOrder = () => {
		let params = {
      language: selectLang,
      searchText: searchText,
      division: props.division,
    };
		// Search
		searchOrder(selectType, params);
	};

	/**
	 * Handle 'Checkbox' of order type
	 * @param {*} event
	 * @param {*} data
	 */
	const handleChangeCheckbox = (event: SyntheticEvent, data: any) => {
		setSelectType(data.value);
	};

	/**
	 * Handle 'Input' change
	 * @param {*} event
	 * @param {*} data
	 */
	const handleSearchChange = (event: SyntheticEvent, data: any) => {
		// set search text
		searchText = data.value;
	};

	/**
	 * Handle 'Menu.Item' of language
	 * @param {*} event
	 * @param {*} data
	 */
	const handleSelectLanguage = (event: SyntheticEvent, data: any) => {
		setSelectLang(data.name);
	};

	const getButtonSelectColor = (name: string) => {
		var tColor = "black";
		if (name === selectLang) {
			tColor = "purple";
		}
		return tColor;
	};

	const handleFormSubmit = () => {
		handleSearchOrder();
	};

	/**
	 * Handle 'Checkbox' of order table
	 * @param {*} selected
	 * @param {*} event
	 * @param {*} data
	 */
	const handleSelectOrder = (selected: any, isGroup: boolean, event: any, data: any) => {
		if (data.checked) {
			addOrderNote(selected, isGroup);
		} else {
			removeOrderNote(selected, isGroup);
		}
	};

	const addOrderNote = (note: any, isGroup: boolean) => {
		if (isGroup) {
			// Loop through template data
			for (const item of note.note_templates) {
				selectNote.push(item.result);
			}
		} else {
			selectNote.push(note.result);
		}
	};

	const removeOrderNote = (note: any, isGroup: boolean) => {
		// Search for to be removed data and add to outputs
		var removeList = [];
		if (isGroup && note.note_template) {
			// Loop through template data
			for (const item of note.note_template) {
				removeList.push(item.result);
			}
		} else {
			removeList.push(note.result);
		}

		// Remove data
		for (const target of removeList) {
			var index = selectNote.indexOf(target);
			if (index > -1) {
				selectNote.splice(index, 1);
			}
		}
	};

	/**
	 * Handle 'Select' Button
	 */
	const handleSelect = () => {
		props.onSelectNote(selectNote);
		props.onClose();
	};

	const columns = [
		{
			Header: "..",
			style: { whiteSpace: "unset" },
			width: 70,
			Cell: (props: any) => {
				const data = props.original;
				let isGroup = selectType === ORDER_TYPE.GROUP;
				return <Checkbox onChange={handleSelectOrder.bind(this, data, isGroup)} />;
			},
		},
		{
			Header: "คำสั่ง",
			style: { whiteSpace: "unset" },
			accessor: "result",
		},
	];

	useEffect(() => {
		// Clear data
		searchText = "";
		selectNote = [];
	}, []);

	useEffect(() => {
		if (props.controller != null) {
			let params = { language: selectLang, division: props.division, searchText: "" };
			if (searchText !== "") {
				params.searchText = searchText;
			}
			// Search
			searchOrder(selectType, params);
		}
	}, [props.controller, selectType, selectLang]);

	console.log("orderData, ", orderData)
	return (
		<CardLayout
			hideTitleText={true}
			hideTitleContent={true}
			hideDivider={true}
			hideHeaderIcon={true}
			toggleable={false}
			closeable={false}
		>
			<Form onSubmit={handleFormSubmit.bind(this)}>
				<Form.Group inline widths={"equal"} style={{ marginBottom: "16px", marginLeft: "16px" }}>
					<Form.Field style={{ width: "12%" }}>
						<Checkbox
							radio
							label={"ปกติ"}
							value={ORDER_TYPE.SINGLE}
							checked={selectType === ORDER_TYPE.SINGLE}
							onChange={handleChangeCheckbox.bind(this)}
						/>
					</Form.Field>
					<Form.Field style={{ width: "12%" }}>
						<Checkbox
							radio
							label={"ชุด"}
							value={ORDER_TYPE.GROUP}
							checked={selectType === ORDER_TYPE.GROUP}
							onChange={handleChangeCheckbox.bind(this)}
						/>
					</Form.Field>
					<Form.Field style={{ width: "60%" }}>
						<Input
							fluid
							placeholder="คำแนะนำในการปฏิบัติตัวเพิ่มเติม"
							onChange={handleSearchChange.bind(this)}
						/>
					</Form.Field>
					<Form.Field style={{ width: "16%" }}>
						<Button fluid color={"blue"} type={"submit"} onClick={handleSearchOrder.bind(this)}>
							ค้นหา
						</Button>
					</Form.Field>
				</Form.Group>
			</Form>

			<ReactTable
				style={{ height: "450px" }}
				noDataText="ไม่มีข้อมูล"
				showPagination={false}
				defaultPageSize={150}
				minRows={14}
				columns={columns}
				data={orderData}
			/>

			<Form style={{ marginTop: "16px" }}>
				<Form.Group inline>
					<Form.Field style={{ width: "24%" }}>
						<Menu inverted widths={2} size={"small"}>
							<Menu.Item
								name={LANGUAGE.THAI}
								active={selectLang === LANGUAGE.THAI}
								color={(getButtonSelectColor(LANGUAGE.THAI) as "black" | "purple")}
								onClick={handleSelectLanguage.bind(this)}
							>
								Thai
							</Menu.Item>
							<Menu.Item
								name={LANGUAGE.ENG}
								active={selectLang === LANGUAGE.ENG}
								color={(getButtonSelectColor(LANGUAGE.ENG) as "black" | "purple")}
								onClick={handleSelectLanguage.bind(this)}
							>
								Eng
							</Menu.Item>
						</Menu>
					</Form.Field>
					<Form.Field style={{ width: "46%" }} />
					<Form.Field style={{ width: "15%" }}>
						<Button fluid type={"button"} color={"red"} onClick={props.onClose}>
							ยกเลิก
						</Button>
					</Form.Field>
					<Form.Field style={{ width: "15%" }}>
						<Button fluid type={"button"} color={"green"} onClick={handleSelect.bind(this)}>
							เลือก
						</Button>
					</Form.Field>
				</Form.Group>
			</Form>
		</CardLayout>
	);
};

const ORDER_TYPE = {
	SINGLE: "single",
	GROUP: "group",
};

const LANGUAGE = {
	THAI: "TH",
	ENG: "EN",
};

ModSearchDoctorNote.defaultProps = {
	controller: null,
	onClose: () => { },
	onSelectNote: () => { },
};

ModSearchDoctorNote.propTypes = {
	controller: PropTypes.object,
	division: PropTypes.number,
	onClose: PropTypes.func,
	onSelectNote: PropTypes.func,
};

export default ModSearchDoctorNote;
