import React, { useMemo, useEffect } from "react";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { Modal, Input, Button, Loader, Dimmer } from "semantic-ui-react";

// UI
import CardLaboratoryExaminationSummaryUX from "./CardLaboratoryExaminationSummaryUX";

// Interface
import { State, RunSequence } from "./sequence/OPDLabSummary";

// Utils
import { handleGetTrProps, handleGetTheadThProps } from "./CardOPDLabSummary";

// Types
type ModalLabExamSummaryProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any) => any;
  // seq
  runSequence: RunSequence;
  // data
  django?: any;
  open?: boolean;
  labResultList?: any[];
  errorMessage?: Record<string, any>;
  labDetailList?: any[];
  chatDetail?: any;
  doctorRecommends?: Record<string, string>;
  buttonLoadCheck?: Record<string, any>;
  // config
  byDate?: boolean;
  // callback
  onClose?: () => any;
  onApprove?: () => any;
};

// Constant
const ICONS = {
  check: "/static/images/check-box-fill.png",
  chat: "/static/images/chat-dots.png",
  download: "/static/images/document-download.png",
  cancel: "/static/images/cancel-presentation.png",
};

const MODAL_LAB_EXAM_SUMMARY = "ModalLabExamSummary";

const ModalLabExamSummary = (props: ModalLabExamSummaryProps) => {
  /* ------------------------------------------------------ */
  /*                       Memo Effect                      */
  /* ------------------------------------------------------ */
  const isDoctor = useMemo(() => {
    return props.django?.user?.role_types?.includes("DOCTOR");
  }, [props.django]);

  const labResultList = useMemo(() => {
    const items = (props.labResultList || []).filter(
      (item: any) => !item.header
    );

    return items.map((item: any) => {
      const status = {
        H: "high",
        L: "low",
        "": "normal",
      }[item.result_status as "" | "H" | "L"];

      return {
        ...item,
        recommend: (
          <Input
            ref={(ref: any) =>
              ref &&
              !isDoctor &&
              (ref.inputRef.current.style.backgroundColor =
                "rgba(232,232,232,0.45)")
            }
            value={item[`recommendation_${status}`]}
            style={{ width: "100%", padding: "0 15px" }}
            readOnly={true}
          />
        ),
        recommendation_text: item[`recommendation_${status}`],
      };
    });
  }, [props.labResultList, isDoctor]);

  const groupByParentLab = useMemo((): Record<
    string,
    { id: number; items: any[] }
  > => {
    const group = labResultList.reduce((result, item) => {
      const code = item.parent_code;
      if (code in result) {
        result[code].items.push(item);
      } else {
        result[code] = { id: item.id, items: [item] };
      }
      return result;
    }, {});

    return !!labResultList.length
      ? group
      : { default: { id: null, items: [] } };
  }, [labResultList]);
  /*                           END                          */

  useEffect(() => {
    if (props.open) {
      props.runSequence({
        sequence: "OPDLabSummary",
        action: "GET_LAB_DETAIL_LIST",
        byDate: props.byDate,
        groupByParentLab,
      });
    }
  }, [props.open, groupByParentLab]);

  useEffect(() => {
    const updateBtnLoadCheck = async () => {
      if (
        props.buttonLoadCheck?.[`${MODAL_LAB_EXAM_SUMMARY}_SEND_PDF`] ===
        "SUCCESS"
      ) {
        await props.setProp(
          `buttonLoadCheck.${MODAL_LAB_EXAM_SUMMARY}_SEND_PDF`,
          null
        );

        const tab = document.querySelector(".tab-menu.--framework");
        const tabs = Array.from(tab?.children || []);

        const index = tabs.findIndex((elm) =>
          elm.textContent?.includes("Telehealth")
        );

        props.setProp("selectedRecordViewIndex", index);
      }
    };

    updateBtnLoadCheck();
  }, [props.buttonLoadCheck]);

  const approvedList = useMemo(() => {
    return props.labDetailList?.filter((item) => item.approved) || [];
  }, [props.labDetailList]);

  const handleTdProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return handleGetTrProps({
      state,
      rowInfo,
      column,
      instance,
    });
  };

  const getTheadThProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return handleGetTheadThProps(
      state,
      rowInfo,
      { ...column, HeaderColor: "#0C86A3" },
      instance
    );
  };

  const handleApprove = () => {
    props.runSequence({
      sequence: "OPDLabSummary",
      action: "APPROVE",
      groupByParentLab,
      card: MODAL_LAB_EXAM_SUMMARY,
      approved: approvedList.length ? false : true,
      byDate: props.byDate,
    });
  };

  const handleDownloadPDF = () => {
    props.runSequence({
      sequence: "OPDLabSummary",
      action: "DOWNLOAD_PDF",
      parentLabID: Object.keys(groupByParentLab),
      groupByParentLab: groupByParentLab,
      doctorRecommends: props.doctorRecommends,
    });
  };

  const handleSendPDF = () => {
    props.runSequence({
      sequence: "OPDLabSummary",
      action: "SEND_PDF",
      parentLabID: Object.keys(groupByParentLab),
      groupByParentLab: groupByParentLab,
      doctorRecommends: props.doctorRecommends,
      btnKey: `${MODAL_LAB_EXAM_SUMMARY}_SEND_PDF`,
    });
  };

  const handleCloseModError = () => {
    props.setProp(`errorMessage.${MODAL_LAB_EXAM_SUMMARY}`, null);
  };

  const handleChangeValue = (key: number) => (e: any, data: any) => {
    props.setProp(`OPDLabSummarySequence.doctorRecommends.${key}`, data.value);
  };

  return (
    <>
      <Modal
        open={props.open}
        size="large"
        closeOnDimmerClick
        onClose={props.onClose}
      >
        {Object.entries(groupByParentLab).map(([key, value], index) => (
          <CardLaboratoryExaminationSummaryUX
            labResultList={value.items || []}
            recommendation={props.doctorRecommends?.[value.id]}
            // config
            config={{
              disabledCreatePDF: true,
              disabledSendToChat: true,
              hideCloseIcon: index !== 0,
              hideHeader: index !== 0,
              disabledRecommend:
                !!approvedList.length ||
                value.items.every((item) => !item.result_value) ||
                !isDoctor,
              isDoctor,
            }}
            // callback
            getTdProps={handleTdProps}
            getTheadThProps={getTheadThProps}
            onClose={props.onClose}
            onChangeValue={handleChangeValue(value.id)}
            // Element
            approveButton={
              <ButtonCustom
                disabled={key === "default"}
                color="green"
                padding="7px 8px"
                title="Approve"
                hide={!!approvedList.length}
                onClick={handleApprove}
              >
                <img
                  src={ICONS.check}
                  alt="check.icon"
                  style={{ width: "31px", marginRight: "10px" }}
                />
              </ButtonCustom>
            }
            cancelButton={
              <ButtonCustom
                disabled={!isDoctor}
                color="red"
                padding="4.5px 13px"
                title="Cancel"
                hide={!approvedList.length || !isDoctor}
                onClick={handleApprove}
              >
                <img
                  src={ICONS.cancel}
                  alt="cancel.icon"
                  style={{ width: "35px", margin: "1px 10px 0 0" }}
                />
              </ButtonCustom>
            }
            downloadButton={
              <ButtonCustom
                disabled={!approvedList.length}
                color="orange"
                padding="4px 8px"
                title="Download"
                onClick={handleDownloadPDF}
              >
                <img
                  src={ICONS.download}
                  alt="download.icon"
                  style={{ width: "36.75px", marginRight: "10px" }}
                />
              </ButtonCustom>
            }
            sendToButton={
              <ButtonCustom
                disabled={
                  !approvedList.length || !props.chatDetail?.chat_channel
                }
                color="blue"
                padding="4px 19px"
                margin="0.5rem 0 0.5rem 0"
                title="Send To"
                right={true}
                hide={true}
                onClick={handleSendPDF}
              >
                {props.buttonLoadCheck?.[
                  `${MODAL_LAB_EXAM_SUMMARY}_SEND_PDF`
                ] !== "LOADING" ? (
                  <img
                    src={ICONS.chat}
                    alt="chat.icon"
                    style={{ width: "35.48px", marginLeft: "10px" }}
                  />
                ) : (
                  <div
                    style={{
                      width: 35.48,
                      height: 35.48,
                      position: "relative",
                      marginLeft: 10,
                    }}
                  >
                    <Dimmer active style={{ background: "transparent" }}>
                      <Loader />
                    </Dimmer>
                  </div>
                )}
              </ButtonCustom>
            }
          />
        ))}
      </Modal>

      <SnackMessage
        onEvent={props.onEvent}
        onClose={handleCloseModError}
        error={props.errorMessage?.[MODAL_LAB_EXAM_SUMMARY]}
        success={null}
      />
    </>
  );
};

/* ------------------------------------------------------ */

/*                      ButtonCustom                      */

/* ------------------------------------------------------ */
const ButtonCustom = (props: any) => {
  return (
    <>
      {!props.hide && (
        <Button
          color={props.color}
          style={{
            marginRight: "1rem",
            borderRadius: "8px",
            padding: props.padding,
            width: "138px",
            margin: props.margin ?? "0.5rem 1.2rem 0.5rem 0",
          }}
          disabled={props.disabled}
          onClick={props.onClick}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {!props.right && props.children}
            <label style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              {props.title}
            </label>
            {props.right && props.children}
          </div>
        </Button>
      )}
    </>
  );
};

export default React.memo(ModalLabExamSummary);
