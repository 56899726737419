import React, { useRef, useEffect, useCallback, useState, SyntheticEvent, useMemo } from "react";
import {
  Button,
  ButtonProps,
  DropdownProps,
  Popup,
  // Icon,
  // Pagination,
  // PaginationProps,
} from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";

// UX
import CardPHVWrapper from "./CardPHVWrapper";
import CardPHVListUX from "./CardPHVListUX";
import SubDoctorOrderDetail, {
  getDoctorOrders,
} from "react-lib/apps/HISV3/common/SubDoctorOrderDetail";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";

// Utils
import { splitStringNewLine } from "react-lib/apps/HISV3/common/CommonInterface";

// Types
type CardPHVListProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  AssessmentSequence: any;
  HistoryCentralLabSequence: any;
  ORPostOperationSequence: any;
  ORHistorySequence: any;
  ORRequestSequence: any;
  PerioperativeNursingSequence: any;
  PreOperationSequence: any;
  // Controller
  proxyController: any;
  // data
  apiToken?: string | null;
  selectedPatient?: any;
  selectedEncounter?: any;
  division?: any;
  selectedMainOrOrder?: any;
  // CommonInterface
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  loadingStatus?: Record<string, any>;
  masterData: any;
  userTokenize: any;
  // options
  ChoiceTriage: any;
  masterOptions: any;
};

type FilterType = Partial<{
  doctorId: number | null;
  startDate: string;
  endDate: string;
  divisionId: number | null;
  investigation: string[];
}>;

const DOCTOR_ORDER_OPTIONS = [
  {
    key: 1,
    value: "admitorder",
    text: "คำสั่งนอน รพ.",
    values: ["ADMIT_ORDER"],
    types: "admitorder",
  },
  {
    key: 2,
    value: "drugorder",
    text: "คำสั่งยา",
    values: [
      "DRUG_ORDER",
      "DRUG_ORDER_HOME_OPD",
      "DRUG_ORDER_SELLING",
      "DRUG_ORDER_STAT",
      "DRUG_ORDER_ONE_DOSE",
      "DRUG_ORDER_HOME_IPD",
      "DRUG_ORDER_CONTINUE_PLAN",
      "DRUG_ORDER_ONE_DAY",
    ],
    types:
      "drugorder,drugopdhomeorder,drugsellingorder,drugstatorder,drugonedoseorder,drugipdhomeorder,drugcontinueplan,drugonedayorder",
  },
  {
    key: 3,
    value: "laborder",
    text: "คำสั่งแลป",
    values: ["LAB_ORDER"],
    types: "centrallaborder",
  },
  {
    key: 4,
    value: "treatmentorder",
    text: "คำสั่งหัตถการ",
    values: ["TREATMENT_ORDER"],
    types: "treatmentorder",
  },
  {
    key: 5,
    value: "ororder",
    text: "คำสั่งผ่าตัด",
    values: ["OR_ORDER"],
    types: "operatingorder",
  },
  {
    key: 6,
    value: "supplyorder",
    text: "คำสั่งเวชภัณฑ์",
    values: [
      "SUPPLY_HOME_OPD",
      "SUPPLY_HOME_IPD",
      "SUPPLY_REFILL_OPD",
      "SUPPLY_REFILL_IPD",
      "SUPPLY_STAT",
      "SUPPLY_COST_CENTER",
      "SUPPLY_ONE_DAY",
    ],
    types:
      "supplyopdhomeorder,supplyipdhomeorder,supplyopdrefillorder,supplyipdrefillorder,supplystatorder,supplycostcenterorder",
  },
  {
    key: 7,
    value: "imagingorder",
    text: "คำสั่งทางรังสี",
    values: ["IMAGING_ORDER"],
    types: "supplyonedayorder",
  },
];

const SEARCH_LIMIT = 999999;

const BUTTON_ACTIONS = {
  search: "SEARCH",
};

const CARD_PHV_LIST = "CardPHVList";

const ACTION_SEARCH = `${CARD_PHV_LIST}_${BUTTON_ACTIONS.search}`;

const CardPHVList = (props: CardPHVListProps) => {
  const [activePage, setActivePage] = useState<number>(1);
  const [selectedRow, setSelectedRow] = useState<Record<string, any> | null>(null);
  const [encounterType, setEncounterType] = useState<string[]>(["IPD", "OPD"]);
  const [filter, setFilter] = useState<FilterType>({});

  const phvRef = useRef<any>(null);
  const isMounted = useRef<boolean>(false);

  // Callback Effect
  const getUrlParams = useCallback(
    ({ activePage }: any = {}) => {
      const offset = (activePage - 1) * SEARCH_LIMIT;
      let selectEncounterType: string[] = [];

      if (encounterType.length === 2) {
        selectEncounterType = ["IPD", "OPD", "ER", "SS"];
      } else if (encounterType[0] === "IPD") {
        selectEncounterType = ["IPD"];
      } else if (encounterType[0] === "OPD") {
        selectEncounterType = ["OPD", "ER", "SS"];
      }

      const investigation =
        DOCTOR_ORDER_OPTIONS.filter((option) => filter.investigation?.includes(option.value)) || [];

      return {
        patientId: props.selectedPatient?.id,
        doctorId: filter.doctorId,
        startDate: filter.startDate,
        endDate: filter.endDate,
        divisionId: filter.divisionId,
        filter: { encounterType: selectEncounterType, noteType: "" },
        limit: SEARCH_LIMIT,
        offset: offset,
        investigation: investigation.length ? investigation.flatMap((option) => option.values) : "",
      };
    },
    [props.selectedPatient?.id, encounterType, filter]
  );

  const handleSearch = useCallback(async () => {
    props.setProp(`buttonLoadCheck.${ACTION_SEARCH}`, "LOADING");

    await phvRef.current.handleOnFilterDate(getUrlParams({ activePage }));

    props.setProp(`buttonLoadCheck.${ACTION_SEARCH}`, "SUCCESS");
  }, [props.selectedPatient?.id, encounterType, filter]);

  // Memo Effect
  const patientPanelData = useMemo(() => {
    return phvRef.current?.state?.patientPanelData || {};
  }, [props.buttonLoadCheck, phvRef.current]);

  const filterEncounterItems = useMemo((): Record<string, any>[] => {
    const items: Record<string, any>[] = patientPanelData.encounterHistory?.items || [];

    const investigation =
      DOCTOR_ORDER_OPTIONS.filter((option) => filter.investigation?.includes(option.value)) || [];

    const specificTypes = investigation.length
      ? investigation.flatMap((option) => option.types.split(","))
      : [];

    return items.flatMap((item) => {
      const drOrders = getDoctorOrders(item.doctor_orders, item.type, item.extra);

      const filterDrOrders = drOrders.filter(
        (item) =>
          item.order_status?.includes("PERFORMED") && specificTypes?.includes(item.order_type)
      );

      return specificTypes?.length && !filterDrOrders.length
        ? []
        : [{ ...item, doctorOrders: drOrders }];
    });
  }, [patientPanelData.encounterHistory]);

  // Effect
  useEffect(() => {
    const patientId = props.selectedPatient?.id;

    if (patientId) {
      props.setProp(`buttonLoadCheck.${ACTION_SEARCH}`, "LOADING");

      if (phvRef.current) {
        handleSearch();
      }
    }
  }, [props.selectedPatient?.id]);

  // Effect
  useEffect(() => {
    if (props.selectedEncounter?.id) {
      const id = isMounted.current ? selectedRow?.original?.id : props.selectedEncounter?.id;

      const index = filterEncounterItems.findIndex((item) => item.id === id);

      if (index !== -1) {
        props.setProp("selectedOrOrder", null);

        setSelectedRow({ index, original: filterEncounterItems[index] });

        isMounted.current = true;
      } else {
        setSelectedRow(null);
      }
    }
  }, [filterEncounterItems, props.selectedEncounter]);

  useEffect(() => {
    return () => {
      if (isMounted.current) {
        props.setProp("selectedOrOrder", props.selectedMainOrOrder);
      }
    };
  }, []);

  // #const totalPages = useMemo(() => {
  //   return (
  //     Math.ceil(patientPanelData.encounterHistory?.total / SEARCH_LIMIT) || 0
  //   );
  // }, [patientPanelData.encounterHistory]);

  const encounterItems = useMemo(() => {
    return filterEncounterItems.map((item) => {
      let principalDiag = item.principal_diagnosis?.icd_code
        ? `[${item.principal_diagnosis.icd_code}] ${item.principal_diagnosis.icd_term}`
        : "";
      let principalDiagFull = principalDiag;

      if (!principalDiag) {
        let examinations: string[] = item.treatmentIssue.map(
          (issue: any) => issue.treatment_plan?.examination_principal_diagnosis
        );

        examinations = Array.from(new Set(examinations)).filter(Boolean);
        principalDiagFull = examinations.join(", ");

        const lines = splitStringNewLine(principalDiagFull, {
          width: 275,
          fontSize: 14,
          max: 1,
          ellipse: `...${Array(8).fill("\u00a0").join("")}`,
        });

        principalDiag = (lines[0] || "").trim();
      }

      return {
        ...item,
        doctor: (
          <div style={{ lineHeight: "19px" }}>
            <div>
              {`${item?.doctor_name}  ${item?.student && item?.student !== null ? " /" : ""}`}
            </div>

            {item?.student ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/static/images/order_images/student.png"}
                  style={{ width: "1.3em", height: "1.3em", marginRight: "5px" }}
                />
                {item?.student_name}
              </div>
            ) : null}
          </div>
        ),
        type: `${item.type} (${item.number})`,
        diagnosis: (
          <div>
            {principalDiag}
            {principalDiag.search(/\.\.\.$/g) >= 0 && (
              <Popup
                content={
                  <div style={{ width: "max-content" }}>
                    {principalDiagFull.split(", ").map((text) => (
                      <div key={text}>{text}</div>
                    ))}
                  </div>
                }
                trigger={
                  <Button
                    icon="info"
                    circular={true}
                    size="mini"
                    color="blue"
                    style={{ transform: "scale(0.75)", padding: "0.35rem" }}
                  />
                }
                position="top right"
                offset={12.5}
              />
            )}
          </div>
        ),
        investigation: (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              transform: "scale(0.8)",
              margin: "-7.5px -20px",
            }}
          >
            <SubDoctorOrderDetail
              setProp={props.setProp}
              onEvent={props.onEvent}
              // data
              data={item}
              doctorOrders={item.doctorOrders}
              // options
              masterOptions={props.masterOptions}
              // config
              performedOnly={true}
            />
          </div>
        ),
      };
    });
  }, [filterEncounterItems, props.masterOptions]);

  // Callback
  // #const handlePageChange = useCallback(
  //   async (event: SyntheticEvent, data: PaginationProps) => {
  //     const page = Number(data.activePage);
  //     setActivePage(page);
  //     props.setProp(`buttonLoadCheck.${ACTION_SEARCH}`, "LOADING");
  //     await phvRef.current.handleGetEncounterList(
  //       getUrlParams({ activePage: page })
  //     );
  //     props.setProp(`buttonLoadCheck.${ACTION_SEARCH}`, "SUCCESS");
  //   },
  //   [props.selectedPatient, encounterType, filter]
  // );

  const handleGetTrProps = useCallback(
    (state: any, rowInfo: RowInfo, column: Column) => {
      return {
        onClick: (e: SyntheticEvent) => {
          if (rowInfo) {
            rowInfo.original = filterEncounterItems[rowInfo.index];

            props.setProp("selectedOrOrder", null);

            setSelectedRow(rowInfo);
          }
        },
        className:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.original?.id
            ? "blueSelectedRow"
            : "",
      };
    },
    [selectedRow, filterEncounterItems]
  );

  const handleChangeEncounterType = useCallback(
    (e: SyntheticEvent, data: ButtonProps) => {
      const name = data.name;

      if (encounterType.includes(name)) {
        setEncounterType((enType) => enType.filter((type) => type !== name));
      } else {
        setEncounterType((enType) => [...enType, name]);
      }
    },
    [encounterType]
  );

  const handleSelectedItem = useCallback((value: any, key: any) => {
    handleChangeFilter(null, { name: "doctorId", value: value || null });
  }, []);

  const handleChangeDate = useCallback(
    (key: string) => (value: any) => {
      handleChangeFilter(null, { name: key, value: value });
    },
    []
  );

  const handleChangeFilter = useCallback((e: SyntheticEvent | null, data: DropdownProps) => {
    setFilter((filter) => ({ ...filter, [data.name]: data.value }));
  }, []);

  const handleClear = useCallback((e: SyntheticEvent) => {
    setFilter({});
  }, []);

  console.log("CardPHVList props", props, filter);

  return (
    <div>
      <CardPHVListUX
        // data
        encounterList={encounterItems}
        encounterType={encounterType}
        filter={filter}
        // options
        divisionOptions={props.masterOptions?.division}
        doctorOrderOptions={DOCTOR_ORDER_OPTIONS}
        // callback
        onGetTrProps={handleGetTrProps}
        onChangeEncounterType={handleChangeEncounterType}
        onChangeFilter={handleChangeFilter}
        onChangeDate={handleChangeDate}
        onClickClear={handleClear}
        // Element
        DoctorSearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Doctor"
            id="PHVL"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            inline={true}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={filter.doctorId || null}
            setSelectedItem={handleSelectedItem}
            // options
            iconStyle={{ marginTop: "-0.85rem" }}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={ACTION_SEARCH}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            size="mini"
            title="Search"
          />
        }
        DoctorOrderOptions={
          <DropdownOptions
            value={filter.investigation || []}
            name="investigation"
            multiple={true}
            search={true}
            inline={true}
            placeholder="เลือก รายการคำสั่งแพทย์"
            checked={true}
            options={DOCTOR_ORDER_OPTIONS}
            onChange={handleChangeFilter}
            fluid={true}
            style={{ width: "100%" }}
          />
        }
        // Pagination={
        //   <Pagination
        //     activePage={activePage}
        //     ellipsisItem={{
        //       content: <Icon name="ellipsis horizontal" />,
        //       icon: true,
        //     }}
        //     firstItem={{
        //       content: <Icon name="angle double left" />,
        //       icon: true,
        //     }}
        //     lastItem={{
        //       content: <Icon name="angle double right" />,
        //       icon: true,
        //     }}
        //     prevItem={{ content: <Icon name="angle left" />, icon: true }}
        //     nextItem={{ content: <Icon name="angle right" />, icon: true }}
        //     totalPages={totalPages}
        //     size="mini"
        //     // callback
        //     onPageChange={handlePageChange}
        //     style={{ transform: "scale(0.95)" }}
        //   />
        // }
      />

      <div style={{ marginTop: "-1rem" }}>
        <CardPHVWrapper
          ref={phvRef}
          // @ts-ignore
          onEvent={props.onEvent}
          setProp={props.setProp}
          // controller
          proxyController={props.proxyController}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          loadingStatus={props.loadingStatus}
          userTokenize={props.userTokenize}
          // options
          masterOptions={props.masterOptions}
          masterData={props.masterData}
          ChoiceTriage={props.ChoiceTriage}
          // seq
          runSequence={props.runSequence}
          AssessmentSequence={props.AssessmentSequence}
          HistoryCentralLabSequence={props.HistoryCentralLabSequence}
          ORPostOperationSequence={props.ORPostOperationSequence}
          ORHistorySequence={props.ORHistorySequence}
          ORRequestSequence={props.ORRequestSequence}
          PerioperativeNursingSequence={props.PerioperativeNursingSequence}
          PreOperationSequence={props.PreOperationSequence}
          // SearchBox
          searchedItemListWithKey={props.searchedItemListWithKey}
          // data
          selectedPatient={props.selectedPatient}
          apiToken={props.apiToken}
          division={props.division}
          selectedRowEncounter={selectedRow}
          // config
          showContentOnly={true}
          // config
          encounterPatientOptimize={true}
          notRenameTitle={true}
        />
      </div>
    </div>
  );
};

CardPHVList.displayName = "CardPHVList";
export default React.memo(CardPHVList);
