import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { Button, Pagination, PaginationProps, Modal, Header } from "semantic-ui-react";
import ReactTable from 'react-table-6';
import CardCSDivisionSearch from "./CardCSDivisionSearch";
import CardCSDoctorSearch from "./CardCSDoctorSearch";

import "./CardCS.scss";

interface CardCSEncounterProps {
  handleCSSearchEncounterTable: any;
  allDoctorList: any;
  allDivisionList: any;
  encounterList: any;
  patientDataAPI: any;
}


const CardCSEncounter = forwardRef((props: CardCSEncounterProps, ref) => {

  const [divisionSearch, setDivisionSearch] = useState("")
  const [doctorSearch, setDoctorSearch] = useState("")
  const [isCSDivisionSearchLoading, setIsCSDivisionSearchLoading] = useState(false)
  const [isCSDoctorSearchLoading, setIsCSDoctorSearchLoading] = useState(false)
  const [isCSEncounterTableLoading, setIsCSEncounterTableLoading] = useState(false)
  const [isOPD, setIsOPD] = useState(true)
  const [isIPD, setIsIPD] = useState(false)
  const [activePage, setActivePage] = useState(1)

  const [openClosureModal, setOpenClosureModal] = useState(false)

  const timerForClick = useRef(null)



  const limit = 5

  const columns = [{
    Header: 'Post Visit',
    // accessor: 'queue_date', // String-based value accessors!
    resizable: false,
    width: 90,
    Cell: props => <> {props.original.queue_date} <br /> {props.original.queue_time.substring(0, 5)} </>

  }, {
    Header: 'Type',
    // accessor: 'type',
    resizable: false,
    width: 60,
    Cell: props => {
      let color = props.value === "IPD" ? "#2F80ED" : "green"
      return <> <span style={{ color: color }}>{props.original.type}</span><br /><span style={{ fontWeight: "lighter", fontSize: "smaller" }}> {props.original.number}  </span> </>
    } // Custom cell components!
  }, {
    Header: 'Order Type',
    // accessor: 'status',
    Cell: props => {
      const listString = [...new Set(props.original.doctor_orders.map(item => item.specific_type))].join(", ")
      if (listString) {
        return <>{listString}</>
      } else {
        return <>-</>
      }
    },
    resizable: false,
  }, {
    Header: "Division/Doctor",
    Cell: props => (<><div className="division-label">{props.original.division_code}</div>{props.original.doctor_name}</>),
    resizable: false,
    width: 150,
  }]

  useImperativeHandle(ref, () => ({
    getData: () => {
      // console.log(" useImperativeHandle CardCSEncounter")
      return {
        division: divisionSearch,
        doctor: doctorSearch,
        isOPD: isOPD,
        isIPD: isIPD,
        page: activePage - 1,
        limit: limit,
      }
    },
  })
  )

  useEffect(() => {
    const searchEncounter = async () => {
      setIsCSEncounterTableLoading(true)
      await props.handleCSSearchEncounterTable()
      setIsCSEncounterTableLoading(false)
    }

    searchEncounter()
  }, [divisionSearch, doctorSearch, props.patientDataAPI, isOPD, isIPD, activePage])

  useEffect(() => {
    if (props.encounterList && props.encounterList?.items?.length > 0) {
      console.log(" Prepare Data")
    }
  }, [props.encounterList])


  const handleSelectedIsOPDEncounter = () => {
    if (isOPD === true && isIPD === false) {
      setIsIPD(true)
      setIsOPD(false)
    } else {
      setIsOPD(!isOPD)
    }
  }

  const handleSelectedIsIPDEncounter = () => {
    if (isIPD === true && isOPD === false) {
      setIsOPD(true)
      setIsIPD(false)
    } else {
      setIsIPD(!isIPD)
    }
  }
  // console.log(" props.encounterList", props.encounterList)

  const totalPages = Math.ceil(props.encounterList?.total / limit)

  return (
    <div className="card-cs card-encounter">
      {openClosureModal && (<Modal
        onClose={() => setOpenClosureModal(false)}
        onOpen={() => setOpenClosureModal(true)}
        open={openClosureModal}
      >
        <Modal.Header>ไม่สามารถเปิด Encounter นี้ได้</Modal.Header>
        <Modal.Content>

          <Modal.Description>
            <Header> Encounter นี้อยู่ในสถานะ ปกปิด</Header>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => setOpenClosureModal(false)}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      )}

      <div className="card-cs-header">
        <div className="card-cs-header-group-button">
          <Button
            disabled={isCSEncounterTableLoading}
            size="tiny"
            active={isOPD}
            onClick={handleSelectedIsOPDEncounter}
          >
            OPD
          </Button>

          <Button
            disabled={isCSEncounterTableLoading}
            size="tiny"
            active={isIPD}
            onClick={handleSelectedIsIPDEncounter}
          >
            IPD
          </Button>
        </div>
        {/* <div className="card-cs-header-title">Division</div> */}
        <CardCSDivisionSearch
          disabled={isCSEncounterTableLoading}
          allDivisionList={props.allDivisionList}
          setDivisionSearch={(search) => {
            setDivisionSearch(search)
            setActivePage(1)
          }}
          isLoading={isCSDivisionSearchLoading} />
        {/* <div className="card-cs-header-title">Doctor</div> */}
        <CardCSDoctorSearch
          disabled={isCSEncounterTableLoading}
          allDoctorList={props.allDoctorList}
          setDoctorSearch={(search) => {
            setDoctorSearch(search)
            setActivePage(1)
          }}
          isLoading={isCSDoctorSearchLoading}
        />
      </div>

      <div className="card-wrap-cs-table">
        <ReactTable className="card-cs-table"
          loading={isCSEncounterTableLoading}
          data={props.encounterList?.items?.length > 0 ? props.encounterList?.items : []}
          columns={columns}
          showPagination={false}
          style={{ height: "100%" }}
          minRows={5}
          NoDataComponent={() => null}
          getTheadThProps={(state, rowInfo, column, instance) => {
            // console.log(" getTdProps", state, rowInfo, column, instance)
            return {
              style: {
                fontSize: "12px",
                border: "none",
                boxShadow: "none",
                textAlign: "left",
              },
            };
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            // console.log(" getTdProps", state, rowInfo, column, instance)

            let style = {
              fontSize: "12px",
              border: "none",
              boxShadow: "none",
              fontWeight: "bolder",
              color: "#4F4F4F",
            }
            if (column.Header === "Order Type" || column.Header === "Division") {
              style = { ...style, ...({ fontWeight: "normal" }) }
            }
            return {
              style: style
            };
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  // console.log( "Click Row ", e, "rowInfo", rowInfo)
                  if (timerForClick.current !== null) {

                    console.log(" Open PHV with Patient", rowInfo.original.patient, " and Encounter: ", rowInfo.original.id)
                    if (rowInfo.original.patient && rowInfo.original.id) {

                      if (rowInfo.original.is_secret) {
                        setOpenClosureModal(true)
                      } else {
                        window.open("/cnmicro/?app=MainPHV&patient=" + rowInfo.original.patient + "&encounterId=" + rowInfo.original.id);
                      }


                    }
                    clearTimeout(timerForClick.current)
                    timerForClick.current = null
                  } else {
                    // console.log('single click')  
                    timerForClick.current = setTimeout(() => {
                      // console.log('first click executes Do nothing')
                      clearTimeout(timerForClick.current)
                      timerForClick.current = null
                    }, 500)
                  }
                }
              }
            } else {
              return {}
            }
          }}

        />
      </div>
      {totalPages > 1 ?
        <div style={{ textAlign: "center" }}>
          <Pagination
            disabled={(totalPages === 1)}
            onPageChange={(e: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
              setActivePage(data.activePage as number)
            }}
            activePage={activePage}
            firstItem={null}
            lastItem={null}
            ellipsisItem={null}
            pointing
            secondary
            siblingRange={1}
            totalPages={totalPages}
          />
        </div> : null
      }


    </div>
  );
});


export default React.memo(CardCSEncounter);
