import React, { useRef, useState, useEffect, useCallback, useMemo, MutableRefObject } from "react";
import { Button, Icon, Menu, Modal, Pagination, Popup } from "semantic-ui-react";

// UX
import CardStockManagementUX from "./CardStockManagementUX";
import ModProductDetail from "./ModProductDetail";
import CardStockManagementTabLot from "./CardStockManagementTabLot";
import CardStockManagementTabHistory from "./CardStockManagementTabHistory";
import CardStockManagementTabAdd from "./CardStockManagementTabAdd";
import CardStockManagementTabTransfer from "./CardStockManagementTabTransfer";
import CardStockManagementTabIssue from "./CardStockManagementTabIssue";
import CardProductInfo from "./CardProductInfo";
import CountingSwitch from "./CountingSwitch";

// Interface
import {
  RunSequence,
  State,
  AggStockSerializer,
  MasterOptionsType,
  BUTTON_ACTIONS,
  stockExpired,
  PermissionsType,
  OptionType,
} from "./sequence/StockManagement";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { ModInfo } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

type CardStockManagementProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  StockManagementSequence?: State["StockManagementSequence"];
  // data
  hiddenTabs?: TabMenuType[];
  selectedDivision?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // CommonInterface
  errorMessage?: Record<string, any>;
  masterData?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
};

type TabMenuType = (typeof TAB_MENU)[number] | "";

type SelectType = AggStockSerializer | null;

// Const
const ICONS = {
  expire: "/static/images/expire-icon.png",
  export: "/static/images/export-excel.png",
  min_qty: "/static/images/min-qty-icon.png",
};

const ALL_OPTION = { key: "ALL", text: "ทั้งหมด", value: "ALL" };

const STATUS_OPTIONS = [
  { key: 1, text: "แสดงทั้งหมด", value: "ALL" },
  { key: 2, text: "Active", value: "ACTIVE" },
  { key: 3, text: "Inactive", value: "INACTIVE" },
];

const COUNTING_STATUS_OPTIONS = [
  { key: 1, text: "แสดงทั้งหมด", value: "ALL" },
  { key: 2, text: "Uncounting", value: "UNCOUNTING" },
  { key: 3, text: "Counting", value: "COUNTING" },
];

const MSG_LABEL = {
  base_unit: "Unit",
  code: "Code",
  dosage_form: "Dosage",
  full_pricings: "Full_Pricings",
  name: "Trade Name",
  bill_mode: "Bill Mode",
  price_normal: "ราคาขายปกติ",
  stock_size: "Stock size",
  stock_unit: "Stock unit",
  strength: "Strength",
} as any;

const TAB_MENU = ["Lot", "History", "Add", "Transfer", "Issue stock"] as const;

export const CARD_STOCK_MANAGEMENT = "CardStockManagement";

const CardStockManagement = (props: CardStockManagementProps) => {
  const [activeItem, setActiveItem] = useState<TabMenuType>("");
  const [openModAddNewProduct, setOpenModAddNewProduct] = useState<boolean>(false);
  const [modEditProduct, setModEditProduct] = useState<SelectType>(null);
  const [openModNotFound, setOpenModNotFound] = useState<boolean>(false);

  // Use Ref
  const dividerRef = useRef() as MutableRefObject<HTMLAnchorElement>;

  // Callback Effect

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "StockManagement",
      restart: true,
      card: CARD_STOCK_MANAGEMENT,
    });

    return () => {
      props.setProp("searchedItemListWithKey.Product_1", []);
    };
  }, [props.selectedDivision?.id]);

  // useEffect(() => {
  //   const stockList = props.StockManagementSequence?.stockList || [];
  //   const selected = props.StockManagementSequence?.selectedStock;

  //   const isNotFound =
  //     !!selected &&
  //     !stockList.find(
  //       (item) =>
  //         item.product.id === selected.product.id &&
  //         item.product.p_type_name === selected.product.p_type_name
  //     );
  // }, [props.StockManagementSequence]);

  useEffect(() => {
    // * แสดง divider แสดง item ที่มีรายการของ stock อยู่
    const pagination = props.StockManagementSequence?.pagination || [];

    const apis = Array.from(new Set(pagination.flatMap((item) => item.api)));

    // * มีทั้ง api product แล้ว stock
    if (apis.length === 2) {
      const stockIndex = pagination.findIndex((item) => item.api.includes("stock"));

      // * ใช้ querySelector เนื่องจาก pagination ไม่มี element ref ให้ใช้
      const children = document.querySelector(".StockManagement-pagination")?.children || [];

      const target = Array.from(children).find(
        (elm) => elm.textContent === `${stockIndex + 1}`
      ) as HTMLAnchorElement;

      if (target) {
        dividerRef.current?.setAttribute("style", "");

        dividerRef.current = target;

        target.style.borderBottom = "2px solid #d8ea5c";
        target.style.paddingBottom = "0.75em";
      }
    }
  }, [props.StockManagementSequence?.pagination, props.StockManagementSequence?.activePage]);

  // Effect Memo
  const isNotFound = useMemo(() => {
    return props.errorMessage?.[`${CARD_STOCK_MANAGEMENT}_SEARCH`] === "NOT_FOUND";
  }, [props.errorMessage]);

  useEffect(() => {
    if (isNotFound) {
      setOpenModNotFound(true);

      props.setProp(`errorMessage.${CARD_STOCK_MANAGEMENT}_SEARCH`, null);
    }
  }, [isNotFound]);

  // Callback
  const mapProductOptions = useCallback((items: any) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: `[${item.code}] ${item.name}`,
    }));
  }, []);

  const handleSelectedItem = useCallback(
    async (_value: any, key: any) => {
      let product = props.searchedItemListWithKey?.Product_1?.find((item: any) => item.id === key);

      await handleChangeValue(null, {
        name: "product",
        value: product?.id || null,
      });
      await handleChangeValue(null, {
        name: "productName",
        value: product?.name || "",
      });
    },
    [props.searchedItemListWithKey]
  );

  const handleDropdownBlur = useCallback(async (_e: any, data: any) => {
    if (!data.value) {
      await handleChangeValue(null, {
        name: "product",
        value: null,
      });
    }
    await handleChangeValue(null, {
      name: "productName",
      value: data.value,
    });
  }, []);

  const handleSelectRow = useCallback(
    (rowInfo: any) => {
      const row = props.StockManagementSequence?.stockList?.[rowInfo?.index] || null;

      props.runSequence({
        sequence: "StockManagement",
        action: "SELECT_PRODUCT",
        stock: row || null,
        errorKey: CARD_STOCK_MANAGEMENT,
      });
    },
    [props.StockManagementSequence?.stockList]
  );

  const handleGetTrProps = useCallback(
    (_state: any, rowInfo: any, _column: any, _instance: any) => {
      const data = props.StockManagementSequence?.selectedStock;

      return {
        className: data?.id && rowInfo?.original?.id === data?.id ? "blueSelectedRow" : "",
      };
    },
    [props.StockManagementSequence?.selectedStock, props.StockManagementSequence?.stockList]
  );

  const handleGetTdProps = useCallback(
    (_state: any, rowInfo: any, column: any, _instance: any) => {
      return {
        onClick: () => {
          if (column.Header !== "Counting") {
            handleSelectRow(rowInfo);
          }
        },
      };
    },
    [props.StockManagementSequence?.selectedStock, props.StockManagementSequence?.stockList]
  );

  const handleCloseModAddNewProduct = useCallback(() => {
    setOpenModAddNewProduct(false);

    // clear search box dropdown
    const listWithKey = props.searchedItemListWithKey || {};
    const ingredients = props.StockManagementSequence?.productDetail?.drug?.drug_ingredients || [];

    const clearSearchBoxDropdown = (key: string) => {
      if (key === "ADRingredient") {
        // @ts-ignore
        for (const [i] of ingredients.entries()) {
          delete listWithKey[`${key}_${i + 1}`];
        }
      } else {
        delete listWithKey[key];
      }
    };

    const keys = ["Unit_1", "Unit_2", "Manufacturer_1", "ADRingredient"];

    keys.forEach(clearSearchBoxDropdown);

    props.setProp("searchedItemListWithKey", { ...listWithKey });
  }, [
    props.searchedItemListWithKey,
    props.StockManagementSequence?.productDetail?.drug?.drug_ingredients,
  ]);

  const getMsgLabel = useCallback((error: any) => {
    return Object.entries(error).reduce((result, [key, value]: any) => {
      const labelKey = MSG_LABEL[key] || key;

      if (key === "full_pricings") {
        value = getMsgLabel(value[0]);
      }

      return {
        ...result,
        [labelKey]: value,
      };
    }, {});
  }, []);

  // Use Memo
  const permissions = useMemo(() => {
    return props.StockManagementSequence?.permissions;
  }, [props.StockManagementSequence?.permissions]);

  const productTypeOptions = useMemo(() => {
    return props.StockManagementSequence?.productTypeOptions || [];
  }, [props.StockManagementSequence?.productTypeOptions]);

  const productTypeDrugSupplyOptions = useMemo(() => {
    return productTypeOptions; //.filter((option) => option.value !== "EQUIP");
  }, [productTypeOptions]);

  const unitOptions = useMemo(() => {
    return (props.masterOptions?.unitCore || []).filter((option) => !!option.text);
  }, [props.masterOptions?.unitCore]);

  const errorMsgLabel = useMemo(() => {
    const error = props.errorMessage?.[CARD_STOCK_MANAGEMENT];

    return !error ? null : getMsgLabel(error);
  }, [props.errorMessage]);

  const allProductTypeOptions = useMemo(() => {
    return [ALL_OPTION, ...productTypeOptions];
  }, [productTypeOptions]);

  const allStorageOptions = useMemo(() => {
    return [ALL_OPTION, ...(props.masterOptions?.storage || [])];
  }, [props.masterOptions?.storage]);

  const stockItems = useMemo(() => {
    const list = props.StockManagementSequence?.stockList || [];

    return list.map((item) => {
      // 1,000,000
      const formattedPrice = Object.assign(
        {},
        ...(["min_qty", "max_qty"] as const).map((key) => ({
          [key]: item[key] ? Number(item[key]).toLocaleString("en-US") : "",
        }))
      );

      // แสดง product type
      const option = productTypeOptions.find((acc) => acc.text === item.product.p_type_name);

      // Expiry date ภายใน 6 เดือน
      const isExp = stockExpired(item.min_exp || "");

      return {
        ...item,
        type: option ? `${option.value}: ${option.text}` : "",
        code: item.product.code,
        name: item.product.name,
        storage: item.storage.name,
        active: (!!item.active_count || (item.active_flag && item.active_flag !== 5)) && (
          <div
            style={{
              display: "grid",
              placeContent: "center",
              height: "100%",
            }}
          >
            <Icon name="check circle" color="green" size="large" />
          </div>
        ),
        action: (
          <div style={{ display: "grid", placeContent: "center", height: "100%" }}>
            <Button
              icon="edit"
              color="yellow"
              size="mini"
              disabled={!item.storage.id || !permissions?.STOCK_VIEW}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setModEditProduct(item);
              }}
            />
          </div>
        ),
        remark: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {Number(item.unexp_qty) < Number(item.min_qty) && (
              <Popup
                trigger={
                  <img
                    src={ICONS.min_qty}
                    alt="min_qty.icon"
                    style={{ width: "25px", margin: "0.35rem" }}
                  />
                }
                content="มีสินค้าน้อยกว่าจำนวนขั้นต่ำของคลัง"
                position="right center"
              />
            )}
            {isExp && (
              <Popup
                trigger={
                  <img
                    src={ICONS.expire}
                    alt="expire.icon"
                    style={{ width: "23px", margin: "0.35rem" }}
                  />
                }
                content="มี Lot ของสินค้าจะหมดอายุภายใน 6 เดือน"
                position="right center"
              />
            )}
          </div>
        ),
        total_qty: (
          <div>{item.total_qty ? Number(item.total_qty || "").toLocaleString("en-US") : ""}</div>
        ),
        unexp_qty: (
          <div>{item.unexp_qty ? Number(item.unexp_qty || "").toLocaleString("en-US") : ""}</div>
        ),
        grand_qty: (
          <div>{item.grand_qty ? Number(item.grand_qty || "").toLocaleString("en-US") : ""}</div>
        ),
        counting: (
          <CountingSwitch
            runSequence={props.runSequence}
            data={item}
            card={CARD_STOCK_MANAGEMENT}
            // config
            disabled={!permissions?.COUNTING_EDIT}
          />
        ),
        ...formattedPrice,
      };
    });
  }, [props.StockManagementSequence?.stockList, productTypeOptions, permissions]);

  // Handler
  const handleChangeTab = (_e: any, data: any) => {
    setActiveItem(data.name);
  };

  // Handler
  // Mod
  const handleOpenModAddNewProduct = async () => {
    await props.setProp("StockManagementSequence.showRequiredField", null);

    props.setProp("StockManagementSequence.productDetail", {
      product_type: "DRUG",
      drug: { drug_ingredients: [{}] },
      is_outside_drug: true,
    });

    setOpenModAddNewProduct(true);
  };

  const handleCloseModNotFound = () => {
    setOpenModNotFound(false);
  };

  const handleCloseModEditProduct = () => {
    setModEditProduct(null);
  };

  const handleChangeValue = async (_e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;

    await props.setProp(`StockManagementSequence.filterStock.${data.name}`, value);
  };

  const handleClearFilter = () => {
    props.setProp(`searchedItemListWithKey.Product_1`, []);

    props.setProp(`StockManagementSequence.filterStock`, {
      status: "ALL",
      productType: "ALL",
      storage: "ALL",
    });
  };

  const handleSearch = () => {
    handleSelectRow({});

    props.runSequence({
      sequence: "StockManagement",
      action: "SEARCH",
      card: CARD_STOCK_MANAGEMENT,
    });
  };

  const handlePageChange = (_e: any, data: any) => {
    props.runSequence({
      sequence: "StockManagement",
      action: "SEARCH_PAGINATION",
      card: CARD_STOCK_MANAGEMENT,
      activePage: data.activePage,
      btnAction: BUTTON_ACTIONS.SEARCH,
    });
  };

  const handleExportExcel = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "STOCK_REPORT",
      card: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.STOCK_REPORT,
    });
  };

  console.log("CardStockManagement", props);

  return (
    <div style={{ paddingBottom: "2rem", minWidth: "max-content" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_STOCK_MANAGEMENT}`, null);
        }}
        error={errorMsgLabel}
        success={null}
      />

      <CardStockManagementUX
        // data
        stockItems={stockItems}
        filterStock={props.StockManagementSequence?.filterStock || {}}
        // options
        productTypeDrugSupplyOptions={allProductTypeOptions}
        storageOptions={allStorageOptions}
        statusOptions={STATUS_OPTIONS}
        countingStatusOptions={COUNTING_STATUS_OPTIONS}
        // config
        disabledClear={!permissions?.STOCK_MANAGEMENT_VIEW}
        disabledAdd={!permissions?.PRODUCT_ADD}
        // callback
        onAddNewProduct={handleOpenModAddNewProduct}
        getTrProps={handleGetTrProps}
        getTdProps={handleGetTdProps}
        onChangeValue={handleChangeValue}
        onClear={handleClearFilter}
        // Element
        ProductSearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Product"
            id="1"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.StockManagementSequence?.filterStock?.product || null}
            defaultText={props.StockManagementSequence?.filterStock?.productName}
            setSelectedItem={handleSelectedItem}
            onBlur={handleDropdownBlur}
            // options
            mapOptions={mapProductOptions}
          />
        }
        TabPans={
          <Menu tabular>
            {TAB_MENU.filter((menu) => !props.hiddenTabs?.includes(menu)).map((menu) => (
              <Menu.Item
                key={"menu-" + menu}
                name={menu}
                active={activeItem === menu}
                style={
                  activeItem === menu
                    ? {
                        color: "#0370B7",
                      }
                    : {}
                }
                onClick={handleChangeTab}
              />
            ))}
          </Menu>
        }
        TabContent={
          <RenderTabContent
            setProp={props.setProp}
            onEvent={props.onEvent}
            // seq
            runSequence={props.runSequence}
            StockManagementSequence={props.StockManagementSequence}
            // options
            masterOptions={props.masterOptions}
            // CommonInterface
            searchedItemListWithKey={props.searchedItemListWithKey}
            buttonLoadCheck={props.buttonLoadCheck}
            selectedDivision={props.selectedDivision}
            // data
            permissions={permissions}
            activeItem={activeItem}
            productTypeOptions={productTypeOptions}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_STOCK_MANAGEMENT}_SEARCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_STOCK_MANAGEMENT}_SEARCH`]}
            // config
            disabled={!permissions?.STOCK_MANAGEMENT_VIEW}
            color={"blue"}
            name="SEARCH"
            size="medium"
            title="ค้นหา"
          />
        }
        ButtonExportExcel={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleExportExcel}
            // data
            paramKey={`${CARD_STOCK_MANAGEMENT}_STOCK_REPORT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_STOCK_MANAGEMENT}_STOCK_REPORT`]}
            // config
            style={{ padding: "0.35em 1em" }}
            color={"green"}
            name="STOCK_REPORT"
            size="small"
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <img alt="export excel" src={ICONS.export} style={{ width: "30px" }} />
                <span>รายงานการเติมเต็มสินค้า</span>
              </div>
            }
          />
        }
        // ButtonCounting={
        //   <ButtonLoadCheck
        //     // function
        //     setProp={props.setProp}
        //     onClick={handleCounting}
        //     // data
        //     paramKey={`${CARD_STOCK_MANAGEMENT}_${BUTTON_ACTIONS.COUNTING}`}
        //     buttonLoadCheck={
        //       props.buttonLoadCheck?.[
        //         `${CARD_STOCK_MANAGEMENT}_${BUTTON_ACTIONS.COUNTING}`
        //       ]
        //     }
        //     // config
        //     name={BUTTON_ACTIONS.COUNTING}
        //     size="medium"
        //     title={
        //       !!props.StockManagementSequence?.stockReconcile?.length
        //         ? "Stop Counting"
        //         : "Start Counting"
        //     }
        //     disabled={!props.StockManagementSequence?.filterStock?.storage}
        //     style={{
        //       backgroundColor: props.StockManagementSequence?.isCounting
        //         ? "#EC6D6D"
        //         : "#CB4F9A",
        //       color: "white",
        //     }}
        //   />
        // }
        Pagination={
          <Pagination
            className="StockManagement-pagination"
            activePage={props.StockManagementSequence?.activePage}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={props.StockManagementSequence?.pagination?.length || 0}
            size="mini"
            // callback
            onPageChange={handlePageChange}
          />
        }
      />

      <Modal
        open={openModAddNewProduct}
        style={{
          margin: "0 2.5vw",
          width: "90%",
          maxHeight: "95vh",
          overflow: "auto",
        }}
        closeIcon={true}
        closeOnDimmerClick={true}
        // callback
        onClose={handleCloseModAddNewProduct}
      >
        <CardProductInfo
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          drugListingData={props.masterData?.drugListing}
          // data
          productDetail={props.StockManagementSequence?.productDetail}
          showRequiredField={props.StockManagementSequence?.showRequiredField}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          billModeOptions={props.StockManagementSequence?.billModeOptions}
          dosageOptions={props.masterOptions?.dosageForm}
          modeOptions={props.StockManagementSequence?.modeOptions}
          productTypeDrugSupplyOptions={productTypeDrugSupplyOptions}
          unitOptions={unitOptions}
          // callback
          onClose={handleCloseModAddNewProduct}
        />
      </Modal>

      <Modal
        open={!!modEditProduct}
        style={{
          margin: "0 2.5vw",
          width: "80%",
          maxHeight: "95vh",
          overflow: "auto",
          overflowX: "hidden",
        }}
        closeIcon={true}
        closeOnDimmerClick={true}
        callback
        onClose={handleCloseModEditProduct}
      >
        <ModProductDetail
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          data={modEditProduct || {}}
          permissions={permissions}
          // options
          productTypeOptions={productTypeOptions}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          // callback
          onClose={handleCloseModEditProduct}
        />
      </Modal>

      <ModInfo
        open={openModNotFound}
        titleColor={"red"}
        onApprove={handleCloseModNotFound}
        onClose={handleCloseModNotFound}
      >
        <div style={{ padding: "0.5rem 0", fontWeight: "bold", lineHeight: 1.5 }}>
          <div>ไม่พบรายการค้นหา</div>
          <div>กรุณาสร้างรหัสใหม่ สำหรับการเติมสินค้า</div>
        </div>
      </ModInfo>
    </div>
  );
};

type RenderTabContentProps = {
  activeItem: TabMenuType;
  permissions?: PermissionsType;
  productTypeOptions: OptionType[];
} & Pick<
  CardStockManagementProps,
  | "setProp"
  | "onEvent"
  | "runSequence"
  | "StockManagementSequence"
  | "masterOptions"
  | "selectedDivision"
  | "searchedItemListWithKey"
  | "buttonLoadCheck"
>;

const RenderTabContent = (props: RenderTabContentProps) => {
  let tabContent = null;

  switch (props.activeItem) {
    case "Lot":
      tabContent = (
        <CardStockManagementTabLot
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          data={props.StockManagementSequence?.selectedStock || {}}
          productStockList={props.StockManagementSequence?.productStockList}
          productLotList={props.StockManagementSequence?.productLotList}
          productStockByLotList={props.StockManagementSequence?.productStockByLotList}
          auditLogList={props.StockManagementSequence?.auditLogList}
          stockReconcileList={props.StockManagementSequence?.stockReconcileList}
          permissions={props.permissions}
          // options
          statusOptions={STATUS_OPTIONS}
          productTypeOptions={props.productTypeOptions}
          stockReconcileNoteOptions={props.masterOptions?.stockReconcileNote}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
        />
      );
      break;

    case "History":
      tabContent = (
        <CardStockManagementTabHistory
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          data={props.StockManagementSequence?.selectedStock || {}}
          stockLogList={props.StockManagementSequence?.stockLogList}
          filterHistory={props.StockManagementSequence?.filterHistory}
          permissions={props.permissions}
          productLotList={props.StockManagementSequence?.productLotList}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          movementTypeOptions={props.StockManagementSequence?.movementTypeOptions}
        />
      );
      break;

    case "Add":
      tabContent = (
        <CardStockManagementTabAdd
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          data={props.StockManagementSequence?.selectedStock || {}}
          productLotList={props.StockManagementSequence?.productLotList}
          permissions={props.permissions}
          // options
          storageOptions={props.masterOptions?.storage}
          productTypeOptions={props.productTypeOptions}
          referenceTextOptions={props.masterOptions?.stockAddRef}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
        />
      );
      break;

    case "Transfer":
      tabContent = (
        <CardStockManagementTabTransfer
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          data={props.StockManagementSequence?.selectedStock || {}}
          divisionId={props.selectedDivision?.id}
          permissions={props.permissions}
          stockStorageDetail={props.StockManagementSequence?.stockStorageDetail}
          // options
          storageOptions={props.masterOptions?.storage}
          divisionTypeDrugOptions={props.StockManagementSequence?.divisionTypeDrugOptions}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
        />
      );
      break;

    case "Issue stock":
      tabContent = (
        <CardStockManagementTabIssue
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          data={props.StockManagementSequence?.selectedStock || {}}
          divisionId={props.selectedDivision?.id}
          permissions={props.permissions}
          productStockList={props.StockManagementSequence?.productStockList}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          divisionTypeDrugOptions={props.StockManagementSequence?.divisionTypeDrugOptions}
          divisionOptions={props.masterOptions?.division}
        />
      );
      break;
  }

  return <>{tabContent}</>;
};

CardStockManagement.displayName = "CardStockManagement";

export default React.memo(CardStockManagement);
