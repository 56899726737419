import React, { useEffect, useState, useMemo } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { EmployeeToken, ErrorMessage } from "react-lib/apps/common";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
// ui ORM
import CardBasicInfoUX from "./CardBasicInfoUX";
import CardBasicInfoSurgeryTeam from "./CardBasicInfoSurgeryTeam";
import CardBasicInfoAnesthesiaTeam from "./CardBasicInfoAnesthesiaTeam";
import moment from "moment";

const CARD_BASIC_INFO: string = "CardBasicInfo";

type CardBasicInfoProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;
  errorMessage?: any;
  successMessage?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
  searchedItemListWithKey?: any;
};

const CardBasicInfoInitial: CardBasicInfoProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PerioperativeNursingSequence: {},
};

const CardBasicInfo: React.FC<CardBasicInfoProps> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "FETCH_DATA_BASIC_INFO",
      card: CARD_BASIC_INFO,
    });
  }, []);

  let basicInfoData = props.PerioperativeNursingSequence?.basicInfoData;

  const totalTimeData = useMemo(() => {
    let newEndDate = moment(
      `${basicInfoData?.patient_out_date} ${basicInfoData?.patient_out_time}`,
      "DD/MM/YYYY HH:mm"
    );
    let newStartDate = moment(
      `${basicInfoData?.patient_in_date} ${basicInfoData?.patient_in_time}`,
      "DD/MM/YYYY HH:mm"
    );

    let hoursTime = newEndDate.diff(newStartDate, "hours") || 0;
    let minutesTime = newEndDate.diff(newStartDate, "minutes") || 0;

    props.setProp(
      "PerioperativeNursingSequence.basicInfoData.total_time",
      `${hoursTime}:${minutesTime - hoursTime * 60}` || 0
    );
  }, [
    basicInfoData?.patient_out_date,
    basicInfoData?.patient_out_time,
    basicInfoData?.patient_in_date,
    basicInfoData?.patient_in_time,
  ]);

  const operationTimeData = useMemo(() => {
    let newEndDate = moment(
      `${basicInfoData?.closure_date} ${basicInfoData?.closure_time}`,
      "DD/MM/YYYY HH:mm"
    );
    let newStartDate = moment(
      `${basicInfoData?.incision_date} ${basicInfoData?.incision_time}`,
      "DD/MM/YYYY HH:mm"
    );

    let hoursTime = newEndDate.diff(newStartDate, "hours") || 0;
    let minutesTime = newEndDate.diff(newStartDate, "minutes") || 0;

    props.setProp(
      "PerioperativeNursingSequence.basicInfoData.operation_time",
      `${hoursTime}:${minutesTime - hoursTime * 60}` || 0
    );
  }, [
    basicInfoData?.closure_date,
    basicInfoData?.closure_time,
    basicInfoData?.incision_date,
    basicInfoData?.incision_time,
  ]);

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(`PerioperativeNursingSequence.basicInfoData.${key}`, date);
  };

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    console.log("key", v, key);
    props.setProp(`PerioperativeNursingSequence.basicInfoData.${key}`, v.value);    
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE_BASIC_INFO",
      card: CARD_BASIC_INFO,
      buttonLoadKey: `${CARD_BASIC_INFO}_SAVE`,
    });
  };

  const handleSplitTime = (key: string, type: string) => {
    let timeData;

    if (key === "total_time") {
      timeData = basicInfoData?.total_time;
    } else if (key === "operation_time") {
      timeData = basicInfoData?.operation_time;
    } else {
      return;
    }

    let newTime = timeData?.split(":");

    return type === "h" ? newTime?.[0] : type === "m" ? newTime?.[1] : "";
  };

  return (
    <>
      <CardBasicInfoUX
        basicInfoData={basicInfoData}
        splitTime={handleSplitTime}
        errorMessage={<ErrorMessage error={props?.errorMessage?.[CARD_BASIC_INFO]?.error} />}
        anesthesiaOptions={props.masterOptions?.anesthesiaMethod}
        handleChangeDate={handleChangeDate}
        handleChangeValue={handleChangeValue}
        // component
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_BASIC_INFO}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_BASIC_INFO}_SAVE`]}
            // config
            color="green"
            size="small"
            title="บันทึก"
          />
        }
        surgeryTeam={
          <CardBasicInfoSurgeryTeam
            onEvent={props.onEvent}
            setProp={props.setProp}
            selectedOrOrder={props.selectedOrOrder}
            masterOptions={props.masterOptions}
            runSequence={props.runSequence}
            PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            surgery_teams={basicInfoData?.surgery_teams}
            searchedItemListWithKey={props.searchedItemListWithKey}
            activeIndex={basicInfoData?.activeSurgeonTeam || 0}
          />
        }
        anesthesiaTeam={
          <CardBasicInfoAnesthesiaTeam
            onEvent={props.onEvent}
            setProp={props.setProp}
            selectedOrOrder={props.selectedOrOrder}
            masterOptions={props.masterOptions}
            runSequence={props.runSequence}
            PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            anesthesia_teams={basicInfoData?.anesthesia_teams}
            searchedItemListWithKey={props.searchedItemListWithKey}
            activeIndex={basicInfoData?.activeAnesthesiaTeam || 0}
          />
        }
        patientInTime={
          <TimeComboBox
            fluid={true}
            style={{ display: "flex", minWidth: "5rem" }}
            defaultValue={basicInfoData?.patient_in_time || ""}
            onTextChange={(time) => {
              props.setProp("PerioperativeNursingSequence.basicInfoData.patient_in_time", time);
            }}
          />
        }
        patientOutTime={
          <TimeComboBox
            fluid={true}
            style={{ display: "flex", minWidth: "5rem" }}
            defaultValue={basicInfoData?.patient_out_time || ""}
            onTextChange={(time) => {
              props.setProp("PerioperativeNursingSequence.basicInfoData.patient_out_time", time);
            }}
          />
        }
        incisionTime={
          <TimeComboBox
            fluid={true}
            noMinWidth={true}
            style={{ display: "flex", minWidth: "5rem" }}
            defaultValue={basicInfoData?.incision_time || ""}
            onTextChange={(time) => {
              props.setProp("PerioperativeNursingSequence.basicInfoData.incision_time", time);
            }}
          />
        }
        closureTime={
          <TimeComboBox
            fluid={true}
            noMinWidth={true}
            style={{ display: "flex", minWidth: "5rem" }}
            defaultValue={basicInfoData?.closure_time || ""}
            onTextChange={(time) => {
              props.setProp("PerioperativeNursingSequence.basicInfoData.closure_time", time);
            }}
          />
        }
      />
    </>
  );
};

CardBasicInfo.defaultProps = CardBasicInfoInitial;

export default React.memo(CardBasicInfo);
