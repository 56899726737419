import WasmController from "react-lib/frameworks/WasmController";

// APIs

// SEQ
import * as PreAnestheticI from "./sequence/PreAnesthetic";

export type State = {} & PreAnestheticI.State;

export const StateInitial: State = {
  ...PreAnestheticI.StateInitial,
};

export type Event = PreAnestheticI.Event;

export type Data = {
  division?: number;
} & PreAnestheticI.Data;

export const DataInitial = {
  ...PreAnestheticI.DataInitial,
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {};
