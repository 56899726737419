import React, { CSSProperties, useMemo } from "react";

// Framework
import { Table } from "react-lib/frameworks/Table";

type TableUploadExcelProps = {
  data: any[];
  style?: CSSProperties;
  headers: string[];
  // config
  displayError?: boolean;
};

const TableUploadExcel = (props: TableUploadExcelProps) => {
  // UseMemo
  const tableHeaders = useMemo(() => {
    const headers = props.headers.map((value) =>
      value.length > 28 ? `${value.substring(0, 28)}...` : value
    );

    if (props.displayError) {
      headers.unshift("Error");
    }

    return headers.join(",");
  }, [props.displayError, props.headers]);

  const tableKeys = useMemo(() => {
    const keys = Object.values(props.headers);

    if (props.displayError) {
      keys.unshift("errors");
    }

    return keys.join(",");
  }, [props.displayError, props.headers]);

  const data = useMemo(() => {
    return (props.data || []).map((item) => ({
      ...item,
      errors: item.errors?.map((error: string) => <div key={error}>- {error}</div>),
    }));
  }, [props.data]);

  return (
    <Table
      className="--rt-tbody-overflow-y-hidden"
      headers={tableHeaders}
      keys={tableKeys}
      widths={props.displayError ? "200" : ""}
      data={data}
      showPagination={false}
      minRows={12}
      style={{
        ...(props.style || {}),
      }}
    />
  );
};

TableUploadExcel.displayName = "TableUploadExcel";

export default React.memo(TableUploadExcel);
