import React, { useState, useEffect, useMemo } from "react";
// UX
import CardBillingHistoryArUX from "./CardBillingHistoryArUX";
import SearchBox from "react-lib/appcon/common/SearchBox";
import { STATUS_OPTIONS } from "react-lib/apps/HISV3/BIL/CardBillingHistory";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import moment from "moment";
import { ModConfirm } from "react-lib/apps/common";
import { Dropdown, Input } from "semantic-ui-react";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import formatComma from "react-lib/utils/formatComma";

// Props
type CardBillingHistoryArProps = {
  onEvent: (e: any) => any;
  setProp: any;
  runSequence: any;
  // Sequence
  BillPaymentArSequence?: Record<string, any>;
  BillPaymentSequence?: Record<string, any>;
  masterOptions?: Record<string, any>;
  selectedDevice?: any;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  searchedItemList?: any;
};

const CARD_KEY = "CardBillingHistoryAr";

const CardBillingHistoryAr = (props: CardBillingHistoryArProps) => {
  const [orderBy, setOrderBy] = useState<string>("Item");
  const [arReceiptData, setARReceiptData] = useState<any>();
  const [openCancelReceipt, setOpenCancelReceipt] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cancelReceiptNote, setCancelReceiptNote] = useState("");

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "BillPaymentAr",
      restart: true,
      action: "getARHistory",
      card: CARD_KEY,
    });
  }, []);

  const arReceiptList = useMemo(() => {
    return (props.BillPaymentArSequence?.arReceiptList || []).map((item: any, index: number) => {
      let splitDate = moment(item.edited_at).format("DD/MM/YYYY").split("/");
      return {
        ...item,
        created_by: (
          <div>
            <div>{`${item.station_number}: ${item.created_by_name}`}</div>
            <div>{`: ${splitDate[0]}/${splitDate[1]}/${+splitDate[2] + 543} [${moment(
              item.edited_at
            ).format("HH:mm")}]`}</div>
          </div>
        ),
        price: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.price))}
          </div>
        ),
      };
    });
  }, [props.BillPaymentArSequence?.arReceiptList]);

  const paymentARItem = useMemo(() => {
    return (props.BillPaymentArSequence?.paymentARItem || []).map((item: any, index: number) => {
      return {
        ...item,
        quantity: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>{item.quantity}</div>
        ),
        total_send_claim_price: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.total_send_claim_price))}
          </div>
        ),
        total_paid_price: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.total_paid_price))}
          </div>
        ),
      };
    });
  }, [props.BillPaymentArSequence?.paymentARItem]);

  const paymentARMode = useMemo(() => {
    return (props.BillPaymentArSequence?.paymentARMode || []).map((item: any, index: number) => {
      return {
        ...item,
        total_send_claim_price: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.total_send_claim_price))}
          </div>
        ),
        total_paid_price: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.total_paid_price))}
          </div>
        ),
      };
    });
  }, [props.BillPaymentArSequence?.paymentARMode]);

  const selectARReceipt = (_state: any, rowInfo: any, _column: any, _instance: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === arReceiptData?.id
            ? "rgba(93, 188, 210, 0.41)"
            : "white",
      },
      onClick: () => {
        setARReceiptData(rowInfo?.original);

        props.runSequence({
          sequence: "BillPaymentAr",
          action: "getARReceiptData",
          card: CARD_KEY,
          data: rowInfo?.original,
          orderBy: orderBy,
        });
      },
    };
  };

  const handleChangeOrderBy = (_event: any, data: any) => {
    setOrderBy(data.name);
  };

  const handleChangeSearchData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`BillPaymentArSequence.searchHistory.${data.name}`, data.checked);
    } else {
      props.setProp(`BillPaymentArSequence.searchHistory.${data.name}`, data.value);
    }
  };

  const handleChangeStartDate = (date: any) => {
    props.setProp(`BillPaymentArSequence.searchHistory.startDate`, date);
  };

  const handleChangeEndDate = (date: any) => {
    props.setProp(`BillPaymentArSequence.searchHistory.endDate`, date);
  };

  const onSearchHistory = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "getARHistory",
      card: CARD_KEY,
    });
  };

  const onPrintARReceiptCopy = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "printARReceiptCopy",
      card: CARD_KEY,
    });
  };

  const onPrintARReport = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "printARReport",
      card: CARD_KEY,
    });
  };

  const onCancelReceipt = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "deleteARReceiptData",
      card: CARD_KEY,
      receipt: arReceiptData?.id,
      username: username,
      password: password,
      note: cancelReceiptNote,
      closedCancelReceipt: setOpenCancelReceipt,
      clearReceiptData: setARReceiptData,
    });
  };

  const clearDataCancelReceipt = () => {
    setUsername("");
    setPassword("");
    setCancelReceiptNote("");
    setOpenCancelReceipt(false);
  };

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={null}
      />

      <CardBillingHistoryArUX
        orderBy={orderBy}
        arReceiptList={arReceiptList}
        arModeData={paymentARMode}
        arItemData={paymentARItem}
        bilStatusOptions={STATUS_OPTIONS}
        invoiceTotalSendClaimPrice={formatComma(
          Number(props.BillPaymentArSequence?.invoiceArItem?.total_send_claim_price)
        )}
        invoiceTotalRemainPrice={formatComma(
          Number(props.BillPaymentArSequence?.invoiceArItem?.total_paid_price)
        )}
        onChangeOrderBy={handleChangeOrderBy}
        onChangeSearch={handleChangeSearchData}
        onChangeStartDate={handleChangeStartDate}
        onChangeEndDate={handleChangeEndDate}
        selectARReceipt={selectARReceipt}
        cancelReceipt={() => setOpenCancelReceipt(true)}
        searchHistory={props.BillPaymentArSequence?.searchHistory}
        invoiceArItem={props.BillPaymentArSequence?.invoiceArItem}
        disabledButton={
          !props.BillPaymentArSequence?.selectPaymentAr?.id ||
          props.BillPaymentArSequence?.selectPaymentAr?.status === "CANCELED"
        }
        buttonCancelStyle={{
          ...(props.BillPaymentArSequence?.selectPaymentAr?.status === "CANCELED" && {
            display: "none",
          }),
        }}
        searchBoxPayer={
          <SearchBox
            vertical={true}
            onEvent={props.onEvent}
            setProp={props.setProp}
            searchedItemList={props.searchedItemList}
            selectedItem={props.BillPaymentArSequence?.searchHistory?.payer || null}
            setSelectedItem={(item: any) => {
              props.setProp(`BillPaymentArSequence.searchHistory.payer`, item);
            }}
            type="Payer"
            placeholder="กรอกชื่อหน่วยงานต้นสังกัดที่ต้องการค้นหา"
            toDisplay={(item: any) => `${item.name_with_id}`}
            fluid={true}
            style={{ width: "100%" }}
            useWithKey={false}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={onSearchHistory}
            // data
            paramKey={`${CARD_KEY}_getARHistory`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_getARHistory`]}
            // config
            color="blue"
            size="small"
            title="ค้นหา"
          />
        }
        searchBoxPayerReceipt={
          <div style={{ width: "100%" }}>
            <SearchBox
              vertical={true}
              onEvent={props.onEvent}
              setProp={props.setProp}
              searchedItemList={props.searchedItemList}
              selectedItem={props.BillPaymentArSequence?.selectPaymentAr?.payer || null}
              setSelectedItem={(item: any) => {
                props.setProp(`BillPaymentArSequence.selectPaymentAr.payer`, item);
              }}
              type="Payer"
              placeholder="กรอกชื่อหน่วยงานบนใบเสร็จที่ต้องการค้นหา"
              toDisplay={(item: any) => `${item.name_with_id}`}
              fluid={true}
              style={{ width: "100%" }}
              useWithKey={false}
              disabled={true}
            />
          </div>
        }
        printARReceiptCopy={
          props.BillPaymentArSequence?.selectPaymentAr?.status !== "CANCELED" && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={onPrintARReceiptCopy}
              // data
              paramKey={`${CARD_KEY}_printARReceiptCopy`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_printARReceiptCopy`]}
              // config
              disabled={
                !props.BillPaymentArSequence?.selectPaymentAr?.id ||
                props.BillPaymentArSequence?.selectPaymentAr?.status === "CANCELED"
              }
              color="blue"
              size="small"
              title="พิมพ์สำเนาใบเสร็จ"
            />
          )
        }
        printPaymentARReport={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={onPrintARReport}
            // data
            paramKey={`${CARD_KEY}_printARReport`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_printARReport`]}
            // config
            style={{ margin: "0px 10px" }}
            disabled={!props.BillPaymentArSequence?.selectPaymentAr?.id}
            color="teal"
            size="small"
            title="พิมพ์ใบแสดงรายละเอียด"
          />
        }
      />

      <ModConfirm
        closeIcon={true}
        titleColor={"blue"}
        openModal={openCancelReceipt}
        onCloseWithDimmerClick={clearDataCancelReceipt}
        titleName={"ระบุเหตุผลการยกเลิกใบเสร็จ"}
        content={
          <div style={{ fontWeight: "bold" }}>
            <div>Username</div>
            <div style={{ width: "100%" }}>
              <Input
                placeholder="Username"
                value={username}
                onChange={(e: any, { value }: any) => setUsername(value)}
                style={{ width: "100%", marginBottom: "15px" }}
              />
            </div>
            <div>Password</div>
            <div style={{ width: "100%" }}>
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e: any, { value }: any) => setPassword(value)}
                style={{ width: "100%", marginBottom: "15px" }}
              />
            </div>
            <div>เหตุผลการยกเลิกใบเสร็จ</div>
            <div style={{ width: "100%" }}>
              <Input
                style={{ width: "100%", marginBottom: "15px" }}
                value={cancelReceiptNote}
                onChange={(e: any, data: any) => {
                  setCancelReceiptNote(data.value);
                }}
              ></Input>
              {/* <Dropdown
                selection
                allowAdditions
                options={props.masterOptions?.cancel_receipt}
                value={cancelReceiptNote}
                onAddItem={handleAddition}
                onChange={(e: any, data: any) => {
                  setCancelReceiptNote(data.value);
                }}
                style={{ width: "100%", marginBottom: "15px" }}
              /> */}
            </div>
          </div>
        }
        onDeny={clearDataCancelReceipt}
        onApprove={onCancelReceipt}
        onCloseWithDimmerClick={clearDataCancelReceipt}
        approveButtonText={"ใช่"}
        approveButtonColor={"green"}
        denyButtonText={"ไม่"}
        denyButtonColor={"red"}
      />
    </div>
  );
};

export default CardBillingHistoryAr;
