import React, { useEffect } from 'react';
import CardLayout from '../common/CardLayout';
import { Header, Grid, Divider } from 'semantic-ui-react'

const styles = {
  gridHeader: {
    fontWeight: 'bold'
  }
}

const CardProgressNoteView = (props) => {

  useEffect(() => {
    fetchProgressionNote()
  }, [])

  const fetchProgressionNote = () => {
    props.getProgressionNote({})
  }

  const generateData = () => {
    if(props.progressionNote.length === 0){
      return <div>No Data.</div>
    }
    if(props.encounterType !== "IPD") {
      return (
        props.progressionNote.map((items, index) => {
          return (
            <div key={index}>
              <Header as='h3'>{items.last_edit} &ensp; {items.edit_user}</Header>
              <Grid celled style={{ backgroundColor: '#F2F2F2' }}>
                <Grid.Row >
                  <Grid.Column width={2} style={styles.gridHeader}>
                    Progression Note
                  </Grid.Column>
                  <Grid.Column width={14}>
                    {items.progression_note}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
              <Divider />
            </div>
          )
      }))
    }
    return (
      props.progressionNote.map((items, index) => {
        return (
          <div key={index}>
            <Header as='h3'>{items.last_edit} &ensp; {items.reporter}</Header>
            <Grid celled style={{ backgroundColor: '#F2F2F2' }}>
              <Grid.Row >
                <Grid.Column width={2} style={styles.gridHeader}>
                  S: Subjective
                </Grid.Column>
                <Grid.Column width={14}>
                  {items.s_txt}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={2} style={styles.gridHeader}>
                  O: Objective
                </Grid.Column>
                <Grid.Column width={14}>
                  {items.o_txt}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={2} style={styles.gridHeader}>
                  A: Assessment
                </Grid.Column>
                <Grid.Column width={14}>
                  {items.a_txt}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={2} style={styles.gridHeader}>
                  P: Plan
                </Grid.Column>
                <Grid.Column width={14}>
                  {items.p_txt}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <Divider />
          </div>
        )
      })
    )
  }

  return (
    <CardLayout
      titleText='Progress Note'
      headerColor='orange'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.progressNoteLoading}
      enableMargin={props.enableMargin}
    >
      <div style={{ maxHeight: 480, overflowY: 'auto' }}>
        {generateData()}
      </div>
    </CardLayout>
  )
}

CardProgressNoteView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  progressionNote: [],
  progressNoteLoading: false,
  enableMargin: true,
  getProgressionNote: () => {},
};

export default CardProgressNoteView;