import React, { useEffect, useState } from "react";
// UX
import CardDeathCertificateUX from "./CardDeathCertificateUX";
// Common

// Interface
import { State } from "./sequence/DoctorCertificate";
// Utils
import moment from "moment";
import { Icon, Popup } from "semantic-ui-react";

// Main Props
type CardDeathCertificateProps = {
  // Function
  setProp: any;
  languageUX?: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

// Current Date
const currentDate = new Date().toISOString().split("T")[0];
const christYear = moment(currentDate).format("YYYY");
const buddhistYear = (parseInt(christYear) + 543).toString();
const formattedDate = moment(currentDate).format(
  "DD/MM/YYYY".replace("YYYY", buddhistYear)
);

// Options
// Date Type Options
const dateTypeOptions = [
  { key: 1, value: "นาที", text: "นาที" },
  { key: 2, value: "วัน", text: "วัน" },
  { key: 3, value: "สัปดาห์", text: "สัปดาห์" },
  { key: 4, value: "เดือน", text: "เดือน" },
  { key: 5, value: "ปี", text: "ปี" },
];
const dateTypeOptionsEN = [
  { key: 1, value: "minute", text: "minute" },
  { key: 2, value: "day", text: "day" },
  { key: 3, value: "week", text: "week" },
  { key: 4, value: "month", text: "month" },
  { key: 5, value: "year", text: "year" },
];

// Manner of Death Options
const mannerOptions = [
  { key: 1, value: "การตายโดยธรรมชาติ", text: "การตายโดยธรรมชาติ" },
  { key: 2, value: "การตายโดยอุบัติเหตุ", text: "การตายโดยอุบัติเหตุ" },
  { key: 3, value: "การตายโดยฆ่าตัวตาย", text: "การตายโดยฆ่าตัวตาย" },
  { key: 4, value: "การตายโดยถูกฆาตกรรม", text: "การตายโดยถูกฆาตกรรม" },
  {
    key: 5,
    value: "การตายโดยยังไม่ทราบสาเหตุ",
    text: "การตายโดยยังไม่ทราบสาเหตุ",
  },
];
const mannerOptionsEN = [
  { key: 1, value: "Natural", text: "Natural" },
  { key: 2, value: "Accident", text: "Accident" },
  { key: 3, value: "Suicide", text: "Suicide" },
  { key: 4, value: "Homicide", text: "Homicide" },
  { key: 5, value: "Undetermined", text: "Undetermined" },
];

// Main
const CardDeathCertificate = (props: CardDeathCertificateProps) => {
  // All Props Value
  // console.log("DeathCertificate Props: ", props.DoctorCertificateSequence);

  // Call Sequence ( Death Certificate )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "FETCH_CERTIFICATE",
      fetchType: "CardDeathCertificate",
    });
  }, []);

  // set State
  const [newMannerOptions, setNewMannerOptions] = useState(mannerOptions);
  const [currentManner, setCurrentManner] = useState("");

  // Change Hospital Name for testing
  const handleChangeHospital = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log("Hospital: ", e.target.value);
    props.setProp(
      `DoctorCertificateSequence.deathReport.deathLocate`,
      e.target.value
    );
  };

  // Change Date
  const handleChangeDate = (name: any, date: string) => {
    //console.log("Key: ", name + " Date: ", date)
    props.setProp(`DoctorCertificateSequence.${name}`, date);
  };

  // Change Date Type
  const handleChangeDateType = (name: any, dateType: string) => {
    //console.log("Key: ", name + " Value: ", dateType);
    /*
    if (["amountTypeA", "amountTypeB" ,...].includes(name) {
      props.setProp(`DoctorCertificateSequence.deathReport.${name}`, dateType);
    } else {
      console.log("Doesn't Exist!!");
    })
    */
    switch (name) {
      case "amountTypeA":
      case "amountTypeB":
      case "amountTypeC":
      case "amountTypeD":
      case "amountTypeCondition1":
      case "amountTypeCondition2":
        props.setProp(
          `DoctorCertificateSequence.deathReport.${name}`,
          dateType
        );
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Value ICD10 Options
  const icd10TypeValue = (name: any) => {
    const sequenceDeathReport = props.DoctorCertificateSequence?.deathReport;
    switch (name) {
      case "icd10TypeA":
        return sequenceDeathReport?.icd10TypeA || "";
      case "icd10TypeB":
        return sequenceDeathReport?.icd10TypeB || "";
      case "icd10TypeC":
        return sequenceDeathReport?.icd10TypeC || "";
      case "icd10TypeD":
        return sequenceDeathReport?.icd10TypeD || "";
      case "condition1Type":
        return sequenceDeathReport?.condition1Type || "";
      case "condition2Type":
        return sequenceDeathReport?.condition2Type || "";
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Value Amount Type Options
  const amountTypeValue = (name: any) => {
    const sequenceDeathReport = props.DoctorCertificateSequence?.deathReport;
    switch (name) {
      case "amountTypeA":
        return sequenceDeathReport?.amountTypeA || "";
      case "amountTypeB":
        return sequenceDeathReport?.amountTypeB || "";
      case "amountTypeC":
        return sequenceDeathReport?.amountTypeC || "";
      case "amountTypeD":
        return sequenceDeathReport?.amountTypeD || "";
      case "amountTypeCondition1":
        return sequenceDeathReport?.amountTypeCondition1 || "";
      case "amountTypeCondition2":
        return sequenceDeathReport?.amountTypeCondition2 || "";
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Input Free Text
  const amountInput = (name: any) => {
    const sequenceDeathReport = props.DoctorCertificateSequence?.deathReport;
    switch (name) {
      case "amountA":
        return sequenceDeathReport?.amountA || "";
      case "amountB":
        return sequenceDeathReport?.amountB || "";
      case "amountC":
        return sequenceDeathReport?.amountC || "";
      case "amountD":
        return sequenceDeathReport?.amountD || "";
      case "amountCondition1":
        return sequenceDeathReport?.amountCondition1 || "";
      case "amountCondition2":
        return sequenceDeathReport?.amountCondition2 || "";
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Change Free Text
  const handleChangeAmount = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    //console.log("Name: ", name + " Value: ", e.target.value) // Name: "A", "B", ... Value: "-"
    switch (name) {
      case "amountA":
      case "amountB":
      case "amountC":
      case "amountD":
      case "amountCondition1":
      case "amountCondition2":
        props.setProp(
          `DoctorCertificateSequence.deathReport.${name}`,
          e.target.value
        );
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Add new Manner
  const handleAddManner = (e: any, { value }: { value: string }) => {
    setNewMannerOptions((prevOptions: any) => [
      { text: value, value },
      ...prevOptions,
    ]);
  };

  // Change Manner of Death
  const handleChangeManner = (selectedManner: string) => {
    //console.log("Manner: ", selectedManner);
    props.setProp(
      `DoctorCertificateSequence.deathReport.deathManner`,
      selectedManner
    );
  };

  // get Value from Manner Dropdown
  const handleSelectedManner = (e: any, { value }: { value: string }) => {
    //console.log(value)
    handleChangeManner(value);
    setCurrentManner(value);
  };

  // Change ICD-10 Code
  const handleChangeICD10 = (name: any, selectedICD10: string) => {
    //console.log("Key: ", name + " ICD-10 Code: ", selectedICD10)
    switch (name) {
      case "icd10TypeA":
      case "icd10TypeB":
      case "icd10TypeC":
      case "icd10TypeD":
        props.setProp(
          `DoctorCertificateSequence.deathReport.${name}`,
          selectedICD10
        );
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Change Condition
  const handleChangeCondition = (name: any, selectedCondition: string) => {
    //console.log("Key: ", name + " Condition: ", selectedCondition)
    switch (name) {
      case "condition1Type":
      case "condition2Type":
        props.setProp(
          `DoctorCertificateSequence.deathReport.${name}`,
          selectedCondition
        );
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Condition Close Button
  // const clearSelectedData = () => {
  //   // Set selectedReport = null
  //   props.setProp(`DoctorCertificateSequence.selectedReport`, null);

  //   // Re-Fetch
  //   props.runSequence({
  //     sequence: "DoctorCertificate",
  //     action: "CLEAR_REPORT",
  //     callReport: "CardDeathCertificate",
  //   });
  // };

  // const closeSelectedCheck = (reportSelected: any) => {
  //   if (reportSelected != null) {
  //     return (
  //       <Button
  //         color="red"
  //         style={{ width: "8em" }}
  //         onClick={clearSelectedData}
  //       >
  //         ปิด
  //       </Button>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Disable when select FiveForm
  const handleDisableForm = (selected: any, reportType?: any) => {
    if (selected == null) {
      return false;
    } else if (
      selected !== null &&
      reportType === "ใบรับรองแพทย์สำหรับการเสียชีวิต"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CardDeathCertificateUX
        // Death Date Props
        deathDate={
          props.DoctorCertificateSequence?.deathReport?.deathDate ||
          formattedDate
        }
        handleDeathDate={(date: string) =>
          handleChangeDate("deathReport.deathDate", date)
        }
        // Death Place Props
        deathPlace={
          props.DoctorCertificateSequence?.deathReport?.deathLocate || ""
        }
        onHandleHospital={handleChangeHospital}
        // Tooltip One
        onToolTipOne={
          <Popup
            trigger={<Icon name="info circle" size="large" color="blue" />}
            content="ระบุโรค การบาดเจ็บ หรือภาวะแทรกซ้อน ที่ทำให้เกิดการเสียชีวิต โดยมิใช่รูปแบบการเสียชีวิต เช่น หัวใจล้มเหลว ภาวะขาดเลือด เป็นต้น"
            size="tiny"
          />
        }
        // Tooltip Two
        onToolTipTwo={
          <Popup
            trigger={<Icon name="info circle" size="large" color="blue" />}
            content="ภาวะผิดปกติ (ถ้ามี) ซึ่งก่อให้เกิดสาเหตุข้างต้น โดยระบุเงื่อนไขพื้นฐานเป็นลำดับสุดท้าย"
            size="tiny"
          />
        }
        // Tooltip Three
        onToolTipThree={
          <Popup
            trigger={<Icon name="info circle" size="large" color="blue" />}
            content="ส่วนที่ทำให้เสียชีวิตแต่ไม่เกี่ยวกับโรคหรือภาวะที่ทำให้เกิดโรค"
            size="tiny"
          />
        }
        // ICD10 Props
        icd10Options={
          props.DoctorCertificateSequence?.deathReport?.icd10Options
        }
        onHandleICD10={handleChangeICD10}
        onTypeValue={icd10TypeValue}
        onAmountTypeValue={amountTypeValue}
        // Condition Props
        conditionOptions={
          props.DoctorCertificateSequence?.deathReport?.icd10Options
        }
        onHandleCondition={handleChangeCondition}
        // Amount Props
        onAmount={amountInput}
        onHandleAmount={handleChangeAmount}
        // Date Type Props
        dateTypeOptions={
          props.languageUX === "EN" ? dateTypeOptionsEN : dateTypeOptions
        }
        onHandleDateType={handleChangeDateType}
        // Manner Props
        mannerOptions={newMannerOptions}
        mannerValue={
          currentManner ||
          props.DoctorCertificateSequence?.deathReport?.deathManner
        }
        onHandleManner={handleSelectedManner}
        onAdditionManner={handleAddManner}
        // Close Button When Selected Report
        // onCloseSelected={closeSelectedCheck(
        //   null
        // )}
        // Disable Input
        // onHandleDisableForm={handleDisableForm(
        //   null,
        //   props.DoctorCertificateSequence?.selectedReport?.data?.reportType
        // )}
      />
    </>
  );
};

export default CardDeathCertificate;
