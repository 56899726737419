import React, { useEffect, useState } from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
// UI
import { ErrorMessage } from ".";

// ---------- SnackMessage
type SnackMessageProps = {
  onEvent: any;
  onClose?: any;
  error: any;
  success: any;
};

const SnackMessageInitial: SnackMessageProps = {
  onEvent: () => null,
  onClose: null,
  error: null,
  success: null,
};

const useStyles = makeStyles((theme) => ({
  box_snack_message: {
    "& .MuiPaper-root.MuiAlert-root": {
      width: "100%",
      marginTop: "2px",
      minWidth: "285px",
      borderRadius: "12px",
      boxShadow: "0 0 8px 1px rgba(133,133,133,0.1)",
      padding: "20px 20px",
      "& .MuiAlert-message": {
        width: "100%",
      },
    },
    "& .MuiAlert-icon": {
      fontSize: "2.75rem",
      borderRadius: "50rem",
      width: "1.8rem",
      height: "1.8rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiAlert-standardSuccess": {
      color: "#67cf9d !important",
      "& .MuiAlert-icon": {
        background: "#67cf9d",
      },
      "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit": {
        color: "rgb(237, 247, 237)",
      },
    },
    "& .MuiAlert-standardError": {
      color: "#f0555a !important",
      "& .MuiAlert-icon": {
        background: "#f0555a",
      },
      "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit": {
        color: "rgb(253, 236, 234)",
      },
    },
  },
}));

const SnackMessage: React.FC<SnackMessageProps> = (props) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<any>(null);

  useEffect(() => {
    if (
      props.error &&
      typeof props.error === "string" &&
      props.error.length > 1000 &&
      props.error.includes("Page not found")
    ) {
      setErrorMessage("API not found");
    } else if (
      props.error &&
      typeof props.error === "object" &&
      typeof props.error.length === "number"
    ) {
      let errorData = props.error.map((e: any) => {
        if (e?.length > 1000 && e.includes("Page not found")) {
          return "API not found";
        }
        return e;
      });
      setErrorMessage(errorData);
    } else {
      setErrorMessage(props.error);
    }
  }, [props.error]);

  const handleCloseSnackbar = (event: any, reason?: any) => {
    if (reason === "clickaway") {
      return;
    }
    if (props.onClose) {
      props.onClose?.();
    } else {
      props.onEvent({ message: "handleClearMessage" });
    }
  };

  return (
    <>
      {(!!props.error || !!props.success) && (
        <Snackbar
          className={classes.box_snack_message}
          open={true}
          autoHideDuration={3500}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
        >
          <MuiAlert
            style={{ alignItems: "center" }}
            severity={props.error ? "error" : "success"}
            elevation={4}
            onClose={handleCloseSnackbar}
          >
            <ErrorMessage error={errorMessage} success={props.success} />
          </MuiAlert>
        </Snackbar>
      )}
    </>
  );
};

SnackMessage.defaultProps = SnackMessageInitial;

export default React.memo(SnackMessage);
