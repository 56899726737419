import React, { useCallback, useEffect, useState } from "react";

import moment from "moment";
import { toast } from "react-toastify";

type CardNotiMessageProps = {
  notificationMessageList: {
    id: number;
    time: string;
    idDelete?: string;
    title: string;
    description: string;
    image?: string;
    update?: boolean;
  }[];
  setProp: any;
};

const IMAGES = {
  noti: "/static/images/icon-noti.png",
};

const CardNotiMessage = (props: CardNotiMessageProps) => {
  // Callback
  const handleCloseNoti = useCallback(
    (id: number, time: string) => {
      toast.dismiss(time);

      const notificationMessageList = props.notificationMessageList.filter(
        (item) => item.id !== id
      );

      props.setProp("notificationMessageList", notificationMessageList);
    },
    [props.notificationMessageList]
  );

  // Effect
  useEffect(() => {
    let listDisableUpdate: any[] = [];
    const lists = props.notificationMessageList || [];

    for (const item of lists) {
      const toastOption = {
        autoClose: false,
        position: toast.POSITION.TOP_RIGHT,
        bodyClassName: "CardTelepharToast",
        className: "CardTelepharToast",
        toastId: item.time,
        closeOnClick: false,
        onClose: (e: any) => handleCloseNoti(item.id, item.time),
      } as any;

      if (item.update) {
        toast.dismiss(item.idDelete);

        setTimeout(
          () =>
            toast(
              <CardNoti
                time={item.time}
                title={item.title}
                description={item.description}
                image={item.image}
              />,
              toastOption
            ),
          1000 * 3
        );

        listDisableUpdate = props.notificationMessageList.map(
          (itemValue: any) => {
            if (itemValue.appointmentId === item.id) {
              return { ...itemValue, update: false };
            } else {
              return { ...itemValue };
            }
          }
        );
      } else {
        toast(
          <CardNoti
            time={item.time}
            title={item.title}
            description={item.description}
            image={item.image}
          />,
          toastOption
        );
      }
    }

    if (lists.length && listDisableUpdate.length) {
      props.setProp("notificationMessageList", listDisableUpdate);
    }
  }, [props.notificationMessageList]);

  return (
    <div>
      {!!props.notificationMessageList?.length && (
        <div
          aria-hidden="true"
          style={{
            background: "white",
            position: "fixed",
            top: "0.5em",
            right: "1.5em",
            padding: "5px 10px",
            borderRadius: "15px",
            cursor: "pointer",
            zIndex: "10000",
          }}
          onClick={() => {
            props.setProp("notificationMessageList", []);
            toast.dismiss();
          }}
        >
          ปิดทั้งหมด
        </div>
      )}
    </div>
  );
};

const handleCalculateTime = (dateTime: string) => {
  const dateTimeNow = moment();
  const timestamp = moment(dateTime);
  const duration = moment.duration(dateTimeNow.diff(timestamp));

  if (duration.days() < 1) {
    if (duration.hours() >= 1) {
      return `${duration.hours().toFixed(0)} ชั่วโมงที่แล้ว`;
    } else if (duration.minutes() < 60 && duration.minutes() > 1) {
      return `${duration.minutes().toFixed(0)} นาทีที่แล้ว`;
    } else {
      return `now`;
    }
  } else {
    return `${duration.asDays().toFixed(0)} วันที่แล้ว`;
  }
};

/* ------------------------------------------------------ */

/*                        CardNoti;                       */

/* ------------------------------------------------------ */
type CardNotiProps = {
  time: string;
  title: string;
  description: string;
  image?: string;
};

const CardNoti = (props: CardNotiProps) => {
  const [showTime, setShowTime] = useState<string>("");

  // Effect
  useEffect(() => {
    setShowTime(handleCalculateTime(props.time));
  }, [props.time]);

  useEffect(() => {
    setInterval(() => {
      setShowTime(handleCalculateTime(props.time));
    }, 1000 * 60);
  }, []);

  return (
    <div style={{ display: "flex", position: "relative" }}>
      <div
        style={{
          padding: "5px 5px 10px 10px",
          marginRight: "10px",
          display: "flex",
        }}
      >
        {/* @ts-ignore */}
        <img
          src={props.image || IMAGES.noti}
          style={{ width: "25px", height: "25px" }}
        />
      </div>
      <div>
        <div style={{ color: "black" }}>{props.title}</div>
        <div style={{ color: props.image ? "black" : "red" }}>
          {props.description}
        </div>
        <div>{showTime}</div>
      </div>
    </div>
  );
};

export default React.memo(CardNotiMessage);
