import React from 'react';
import { toast } from 'react-toastify';
import _ from "react-lib/compat/lodashplus";
import CardUploadSentClaimResponseFile from '../../../react-lib/component-js/hbill/CardUploadSentClaimResponseFile';
import BillService from '../../../react-lib/services/BillService';

const INITIAL_STATE = {
  isLoading: false,
  items: [],
  hospitalCode: '',
  sentNo: '',
  responseNo: '',
  responseAt: '',
  error: null,
  coverageGroupName: '',
};

const PAYER_CODE = '50257';
const PATIENT_TYPE = 'OPD';
const COVERAGE_CODE = 'GGO';

class CardUploadSentClaimResponseFileContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.billService = new BillService();
  }
  
  handleUploadFile = (accepted, rejected) => {
    const acceptedFile = _.get(accepted, '[0]');
    const rejectedFile = _.get(rejected, '[0]');

    if (rejectedFile instanceof File) {
      this.setState({
        isLoading: false,
        errors: `ประเภทไฟล์ไม่ถูกต้อง: ${rejectedFile.name}`
      });
      return;
    }
    if (!(acceptedFile instanceof File)) {
      throw new Error('file is not an instance of File');
    }

    const formData = new FormData();
    formData.append('file', acceptedFile);
    this.setState({ isLoading: true, errors: null });

    this.billService.uploadResponseFile({
      formData,
    })
      .then((data) => {
        this.setState({
          isLoading: false,
          items: [...data.items],
          sentNo: data.sent_no,
          responseNo: data.response_no,
          responseAt: data.response_at,
          hospitalCode: data.hospital_code,
          coverageGroupName : data.coverage_payer_sent_claim_group_name,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }
  
  handleConfirmItems = () => {
    const { 
      items, 
      hospitalCode,
      sentNo,
      responseNo,
      responseAt,
    } = this.state;

    this.setState({ isLoading: true, errors: null });
    this.billService.confirmImportedResponseFile({
      /* BODY part */
      items,
      sentNo,
      hospitalCode,
      responseNo,
      responseAt,
      /* URL part */
      payerCode: PAYER_CODE,
      coverageCode: COVERAGE_CODE,
      patientType: PATIENT_TYPE,
    })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        toast.success('บันทึกสำเร็จ');
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
        toast.error('บันทึกไม่สำเร็จ');
      });
  }

  render() {
    let { items, ...rest } = this.state;
    items = _.map(items, (item) => {
      const reason = _.map(item.failed_reasons, (item) => `[${item.code}] ${item.message}`).join('\n');
      return {
        ...item,
        formatted_success: item.success ? 'สำเร็จ' : 'ไม่สำเร็จ',
        formatted_reason: reason,
      };
    });

    return (
      <CardUploadSentClaimResponseFile 
        {...rest}
        items={items}
        onUploadFile={this.handleUploadFile}
        onConfirmItems={this.handleConfirmItems}
      />
    );
  }
}

export default CardUploadSentClaimResponseFileContainer;