import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Icon,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardUploadPatientCoverageUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div
          style={{display: "flex", marginBottom: "0.75rem"}}>
          
          <Button
            color={props.coverageGroup=== "group"? "blue":""}
            name="group"
            onClick={props.onChangeCoverage}
            size="small"
            style={{marginRight: "0.75rem"}}>
            สิทธิ์สวัสดิการคณะ 10000
          </Button>
          <Button
            color={props.coverageGroup=== "crown_property"? "blue":""}
            name="crown_property"
            onClick={props.onChangeCoverage}
            size="small"
            style={{marginRight: "0.75rem"}}>
            สิทธิทรัพย์สินพระมหากษัตริย์
          </Button>
          <Button
            color={props.coverageGroup=== "gsb"? "blue":""}
            name="gsb"
            onClick={props.onChangeCoverage}
            size="small"
            style={{marginRight: "0.75rem"}}>
            สิทธิธนาคารออมสิน
          </Button>
          <Button
            color={props.coverageGroup=== "bot"? "blue":""}
            name="bot"
            onClick={props.onChangeCoverage}
            size="small"
            style={{marginRight: "0.75rem"}}>
            สิทธิสวัสดิการธนาคารแห่งประเทศไทย
          </Button>
        </div>
        <div
          className="ui form"
          style={{position: "relative"}}>
          {}
          <div>
            {props.tabPans}
          </div>
          <div>
            {props.buttonDownload}
          </div>
        </div>
        <div
          className="ui form"
          style={{ display: props.activeTab==="UPLOAD"?"":"none"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Button
                onClick={props.onChooseFile}
                style={{minWidth: "max-content"}}>
                Choose file
              </Button>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}
              width="1">
              <label
                style={{marginRight: "0.5rem", fontWeight: "normal"}}>
                {props.fileName || "No File chosen"}
              </label>
              <div
                style={{display: props.fileName? "": "none"}}>
                
                <Icon
                  link={true}
                  name="close"
                  onClick={props.onClearFile}>
                </Icon>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{display: props.coverageGroup==="group"?"":"none"}}
              width={3}>
              <label
                style={{minWidth: "max-content"}}>
                ปีงบประมาณ
              </label>
              <Dropdown
                fluid={true}
                name="fiscalYearId"
                onChange={props.onChangeValue}
                options={props.yearOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.fiscalYearId  || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{display: props.coverageGroup==="group"?"":"none"}}
              width={3}>
              <label
                style={{minWidth: "max-content"}}>
                วงเงิน
              </label>
              <Input
                fluid={true}
                name="maxReimb"
                onChange={props.onChangeValue}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.maxReimb}>
              </Input>
            </FormField>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField>
              <Button
                color="green"
                onClick={props.onUpload}
                style={{minWidth: "max-content"}}>
                <label
                  style={{marginRight: "0.75rem"}}>
                  Upload
                </label>
                <Icon
                  name="upload">
                </Icon>
              </Button>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardUploadPatientCoverageUX

export const screenPropsDefault = {}

/* Date Time : Wed Apr 17 2024 23:16:17 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tabPans"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.activeTab===\"UPLOAD\"?\"\":\"none\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 10,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Choose file"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChooseFile"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonDownload"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "code",
          "value": "props.fileName || \"No File chosen\""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", fontWeight: \"normal\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.fileName? \"\": \"none\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Icon",
      "parent": 46,
      "props": {
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearFile"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Button",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิ์สวัสดิการคณะ 10000"
        },
        "color": {
          "type": "code",
          "value": "props.coverageGroup=== \"group\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "group"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeCoverage"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Button",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิทรัพย์สินพระมหากษัตริย์"
        },
        "color": {
          "type": "code",
          "value": "props.coverageGroup=== \"crown_property\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "crown_property"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeCoverage"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Button",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิธนาคารออมสิน"
        },
        "color": {
          "type": "code",
          "value": "props.coverageGroup=== \"gsb\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "gsb"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeCoverage"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิสวัสดิการธนาคารแห่งประเทศไทย"
        },
        "color": {
          "type": "code",
          "value": "props.coverageGroup=== \"bot\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "bot"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeCoverage"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.coverageGroup===\"group\"?\"\":\"none\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.coverageGroup===\"group\"?\"\":\"none\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 53,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "fiscalYearId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.yearOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.fiscalYearId  || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Input",
      "parent": 54,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "maxReimb"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.maxReimb"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 11,
      "props": {
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Button",
      "parent": 58,
      "props": {
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onUpload"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "Upload"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Icon",
      "parent": 59,
      "props": {
        "name": {
          "type": "value",
          "value": "upload"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "ปีงบประมาณ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "วงเงิน"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 56,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": true,
  "memo": false,
  "name": "CardUploadPatientCoverageUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 100
}

*********************************************************************************** */
