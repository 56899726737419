import React, { CSSProperties, useMemo } from "react";

// UX
import ModImagingAuditLogUX from "./ModImagingAuditLogUX";
import { Modal } from "semantic-ui-react";

// Interface
import {
  ImagingOrderItemActionLogSerializer,
  ImagingQueueSerializer,
} from "./sequence/ImagingWorkList";
import { LABEL_STATUS } from "./sequence/ImagingResult";

// Types
type ModImagingAuditLogProps = {
  // data
  data: Partial<ImagingQueueSerializer>;
  open: boolean;
  auditLogList?: ImagingOrderItemActionLogSerializer[];
  // callback
  onClose: () => any;
};

// Const
const GridCenter = {
  display: "grid",
  placeContent: "center",
  textAlign: "center",
} as CSSProperties;

const ModImagingAuditLog = (props: ModImagingAuditLogProps) => {
  // Use Memo
  const filterAuditLogList = useMemo(() => {
    const items = (props.auditLogList || []).reduce(
      (result: ImagingOrderItemActionLogSerializer[], item, index, self) => {
        const isEditResult =
          self[index + 1]?.action_name === "EDIT_REPORT" &&
          item.action_name === "EDIT";

        if (!isEditResult) {
          result.push(item);
        }

        return result;
      },
      []
    );

    return items;
  }, [props.auditLogList]);

  const auditLogItems = useMemo(() => {
    return filterAuditLogList.map((item) => ({
      ...item,
      datetime_formatted: (
        <div style={GridCenter}>{item.datetime_formatted}</div>
      ),
      action_name: (
        <div style={GridCenter}>
          {(LABEL_STATUS as any)[item.action_name] || item.action_name}
        </div>
      ),
    }));
  }, [filterAuditLogList]);

  // Handler
  const handleCloseModLog = () => {
    props.onClose();
  };

  return (
    <div>
      <Modal
        open={!!props.open}
        size="small"
        style={{ paddingBottom: "1rem", overflow: "hidden" }}
        closeOnDimmerClick={true}
        // callback
        onClose={handleCloseModLog}
      >
        <ModImagingAuditLogUX
          // data
          name={props.data?.name_code || ""}
          auditLogList={auditLogItems}
          // callback
          onClose={props.onClose}
        />
      </Modal>
    </div>
  );
};

export default React.memo(ModImagingAuditLog);
