import React, { useEffect, useMemo } from "react";

// UX
import CardReceiveOrderUX from "./CardReceiveOrderUX";
import CardDrugOrderWorking from "react-lib/apps/HISV3/TPD/CardDrugOrderWorking";
import CardSupplyOrderWorkflow from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyOrderWorkflow";
import CardSupplyDeliveryManager from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyDeliveryManager";

// Common
import { ModInfo } from "react-lib/apps/common";

type ModInfoType = {
  open: boolean;
  error?: any;
  title?: string;
  color?: string;
};

const CARD_RECEIVE_ORDER = "CardReceiveOrder";

const CardReceiveOrder = (props: any) => {
  // Effect
  useEffect(() => {
    const fetchData = () => {
      props.setProp("selectedDrugOrderWorking", "");

      props.setProp("EncounterReceive", {});

      props.runSequence({ sequence: "ReceiveOrder", restart: true });
      props.runSequence({ sequence: "DispensingOrderSupply", restart: true });
      props.runSequence({ sequence: "SupplyDelivery", restart: true });
    };

    fetchData();

    return () => {
      const clearData = () => {
        props.setProp("selectedDrugOrderWorking", "");
        props.setProp("EncounterReceive", {});

        props.runSequence({ sequence: "ReceiveOrder", clear: true });
        props.runSequence({ sequence: "DispensingOrderSupply", clear: true });
        props.runSequence({ sequence: "SupplyDelivery", clear: true });
      };

      clearData();
    };
  }, []);

  useEffect(() => {
    props.runSequence({ sequence: "DrugOrderAction", restart: true });

    return () => {
      props.runSequence({ sequence: "DrugOrderAction", clear: true });
    };
  }, []);

  // Memo
  const choiceBarcode = useMemo(() => {
    return [
      { id: "auto", name: "auto" },
      ...(props.ChoiceBarcode?.items || []),
    ].map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }, [props.ChoiceBarcode]);

  // Handler
  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`ReceiveOrderSequence.${key}`, v.value);
  };

  const handleCloseModInfo = () => {
    props.setProp("ReceiveOrderSequence.selectedCode", "");
    props.setProp("errorMessage", false);
  };

  const handleSearch = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "ReceiveOrder",
        action: "search",
        card: CARD_RECEIVE_ORDER,
      },
    });
  };

  return (
    <>
      <div id="CardReceiveOrder" style={{ padding: "10px" }}>
        <CardReceiveOrderUX
          options={choiceBarcode}
          selectedType={props.ReceiveOrderSequence?.selectedType || ""}
          selectedCode={props.ReceiveOrderSequence?.selectedCode || ""}
          handleChangeValue={handleChangeValue}
          nameAuto={props.ReceiveOrderSequence?.selectedType === "auto"}
          onhandleSearch={handleSearch}
          hn={props.EncounterReceive?.hn || ""}
          name={props.EncounterReceive?.patient_name || ""}
          gender={props.EncounterReceive?.patient_gender_name || ""}
          birthdate={props.EncounterReceive?.patient_birthdate || ""}
          age={props.EncounterReceive?.patient_age || ""}
          encounter={props.EncounterReceive?.hn}
        />

        {props.selectedDrugOrderWorking && (
          <CardDrugOrderWorking
            isCardReceive={true}
            runSequence={props.runSequence}
            onEvent={props.onEvent}
            setProp={props.setProp}
            django={props.django}
            isDoctor={props.isDoctor}
            isPharmacist={props.isPharmacist}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedDivision={props.selectedDivision}
            drugOrderQueue={props.drugOrderQueue}
            drugOrder={props.selectedDrugOrderWorking}
            drugOrderLog={props.drugOrderLog}
            modNoteReject={props.modNoteReject}
            layout={props.layout}
            forward={props.forward}
            DrugSelectSequence={props.DrugSelectSequence}
            selectedPatient={props.selectedPatient}
            selectedDrug={props.selectedDrug}
            drugDelivery={props.drugDelivery}
            drugPermission={props.drugPermission}
            modConfirmAction={props.modConfirmAction}
            modDrugRecommendation={props.modDrugRecommendation}
            modDrugInteraction={props.modDrugInteraction}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            MedErrorListSequence={props.MedErrorListSequence}
            errorMessage={props.errorMessage}
            loadingStatus={props.loadingStatus}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            DrugOrderActionSequence={props.DrugOrderActionSequence}
            // DrugTransferRequest
            drugTransferRequestSequence={props.DrugTransferRequestSequence}
            drugOrderHistoryController={props.drugOrderHistoryController}
            drugRequestList={props.drugRequestList}
            selectedDevice={props.selectedDevice}
            drugTransferRequestlist={props.drugTransferRequestlist}
            drugTransferLog={props.drugTransferLog}
            drugTransferRequestDetail={props.drugTransferRequestDetail}
            ReturnOrderSequence={props.ReturnOrderSequence}
            OrderReturnList={props.OrderReturnList}
            DrugReturnList={props.DrugReturnList}
            OrderHistory={props.OrderHistory}
            OrderReturnLog={props.OrderReturnLog}
            // Allergy
            AllergySequence={props.AllergySequence}
            // StockManagement
            lotNoExpList={props.lotNoExpList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // MedReconcile
            // TODO: Check is need MedReconcile Alert modal
            // divisionType={props.divisionType}
            // medReconcileIndex={props.medReconcileIndex}
            // django={props.django}
            openVideoCallModal={props.openVideoCallModal}
            onOpenVideoCall={(url: string) => {
              props.setProp("openVideoCallModal", true);
              props.setProp("closeOverriding", false);
              props.setProp("videoCallRoom", url);
            }}
          />
        )}

        {props.DispensingOrderSupplySequence?.selectedOrderId && (
          <CardSupplyOrderWorkflow
            onEvent={props.onEvent}
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            DispensingOrderSupplySequence={props?.DispensingOrderSupplySequence}
            ReturnSupplySequence={props?.ReturnSupplySequence}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            selectedEncounter={props.selectedEncounter}
            loadingStatus={props.loadingStatus}
            // options
            masterOptions={props.masterOptions}
            supplyOrderEligibilityOptions={props.supplyOrderEligibilityOptions}
            // style
            cardStyle={{ width: "calc(100vw - 5.5rem)" }}
            // config
            isReceiveReturn={true}
            parentCard={"CardReceiveOrder"}
            django={props.django}
          />
        )}
        {props.SupplyDeliverySequence?.selectedTransferId && (
          <CardSupplyDeliveryManager
            onEvent={props.onEvent}
            forward={props.forward}
            layout={props.layout}
            runSequence={props.runSequence}
            SupplyDeliverySequence={props.SupplyDeliverySequence}
            setProp={props.setProp}
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            isCardReceive={true}
          />
        )}
      </div>

      <ModInfo
        open={props?.errorMessage?.[CARD_RECEIVE_ORDER]}
        titleColor="red"
        titleName="ไม่พบข้อมูล"
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      ></ModInfo>
    </>
  );
};

CardReceiveOrder.displayName = "CardReceiveOrder";

export default React.memo(CardReceiveOrder);
