import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Input, Icon, Modal, Segment, Grid, Button, FormField } from "semantic-ui-react";

import moment from "moment";

// UI
import CardSettingEmployeeUX from "./CardSettingEmployeeUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { ErrorMessage, DateTextBox } from "react-lib/apps/common";

// Utils
import { beToAd, adToBe } from "react-lib/utils/dateUtils";
import ModChangePassword from "./ModChangePassword";

// Types
export type RequiredFieldType = Partial<
  Record<
    | "code"
    | "birth_date"
    | "position"
    | "flag_start_date"
    | "certificate_no"
    | "specialties"
    | "codeDoctor"
    | "citizen_passport"
    | "email"
    | "username"
    | "password"
    | "confirm_password"
    | "pre_name"
    | "first_name"
    | "last_name"
    | "first_name_en"
    | "last_name_en"
    | "gender"
    | "pre_name_en"
    | "flag_end_date",
    string
  >
>;

// Const
export const getAge = (birthdate: any) => {
  if (birthdate.isValid()) {
    var today = moment().startOf("day");
    var years = today.diff(birthdate, "year");
    birthdate.add(years, "years");
    var months = today.diff(birthdate, "months");
    birthdate.add(months, "months");
    var days = today.diff(birthdate, "days");

    return years + " ปี " + months + " เดือน " + days + " วัน";
  }
  return "";
};

export const getRequireKeyError = (requiredKey: RequiredFieldType, profile: any, user?: any) => {
  return Object.entries(requiredKey).reduce((result, [key, msg]) => {
    const value = profile?.[key];
    if (!value && value !== 0 && msg) {
      // เมื่อ key เป็น citizen_passport ต้องกรอกให้ครบ 13 หลัก
      result[key] = msg;
    } else if (key === "position" && profile?.isCreateDoctor) {
      const requiredKey: RequiredFieldType =
        profile.positionFlag?.teacher_flag ||
        (profile.isCreateDoctor && !profile.positionFlag?.student_flag)
          ? {
              certificate_no: "กรุณาระบุ เลขใบประกอบวิชาชีพ",
              specialties: "กรุณาระบุ Specialty",
              codeDoctor: "กรุณาระบุ รหัสแพทย์",
            }
          : { codeDoctor: "กรุณาระบุ รหัสแพทย์" };
      // check certificate_no, specialties, codeDoctor ต้องระบุข้อมูล
      Object.entries(requiredKey).forEach(([key, msg]) => {
        const isEmpty = Array.isArray(user?.[key]) ? !user?.[key]?.length : !user?.[key];

        if (isEmpty) {
          result[key] = msg;
        }
      });
    } else if (key === "citizen_passport" && value.length !== 13) {
      result[key] = "กรุณาระบุ เลขบัตรประจำตัวประชาชนให้ครบ 13 หลัก";
    } else if (
      ["birth_date", "flag_start_date", "flag_end_date"].includes(key) &&
      value &&
      value.search(/\d{2}\/\d{2}\/\d{4}/g) === -1
    ) {
      result[key] = "กรุณาระบุในรูปแบบ dd/mm/yyyy";
    }

    return result;
  }, {} as Record<string, any>);
};

const STATUS_OPTIONS = [
  { key: 1, value: 1, text: "Active" },
  { key: 2, value: 2, text: "Inactive" },
];

const CARD_SETTING_EMPLOYEE = "CardSettingEmployee";

const CardSettingEmployee = (props: any) => {
  const [roleTotal, setRoleTotal] = useState<any>();
  const [roles, setRoles] = useState<any>();
  const [selectedRoles, setSelectedRoles] = useState<string>("");
  const [selectedRoleTotal, setSelectedRoleTotal] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState(false);
  // password
  const [openPass1, setOpenPass1] = useState<boolean>(false);
  const [openConPass1, setOpenConPass1] = useState<boolean>(false);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);

  const [showRequiredField, setShowRequiredField] = useState<RequiredFieldType>({});

  useEffect(() => {
    props.onEvent({ message: "SettingStaff", params: {} });

    return () => {
      props.setProp(`errorMessage.${CARD_SETTING_EMPLOYEE}`, { error: null });
    };
  }, []);

  useEffect(() => {
    const filterRoles: any[] = roleList?.filter((item: any) =>
      props.user?.roles?.includes(item.id)
    );
    const filterRoleTotal: any[] = props.roleOptions?.filter(
      (item: any) => !props.user?.roles?.includes(item.id)
    );

    setRoleTotal(filterRoleTotal);
    setRoles(filterRoles);
  }, [props.user?.roles, props?.roleOptions]);

  const age = useCallback(() => {
    const birthdate = adToBe(props.profileEmployee?.birth_date);
    const birthdate_ad = beToAd(birthdate)?.startOf("day");
    const age = typeof birthdate_ad === "undefined" ? "" : getAge(birthdate_ad);

    return age;
  }, [props.profileEmployee?.birth_date]);

  const roleList = useMemo(() => {
    return (props.masterOptions?.role || [])?.map((item: any) => ({
      id: item.key,
      name: item.text,
    }));
  }, [props.masterOptions?.role]);

  const doctorTypeOptions = useMemo(() => {
    return props.masterOptions?.doctorType || [];
  }, [props.masterOptions?.doctorType]);

  const changeValue = (key: string) => async (e: any, v: any) => {
    if (
      [
        "first_name",
        "pre_name",
        "last_name",
        "pre_name_en",
        "first_name_en",
        "last_name_en",
        "doctor_type",
        "username",
        "password",
        "confirm_password",
        "citizen_passport",
        "phone_no",
        "email",
      ].includes(key)
    ) {
      await props.setProp(`profileUser.${key}`, v.value);
      props.setProp(`user.${key}`, v.value);
    } else if (["certificate_no", "specialties", "codeDoctor"].includes(key)) {
      props.setProp(`user.${key}`, v.value);
    } else if (["password1", "confirm_password1"].includes(key)) {
      props.setProp(`changePassword.${key}`, v.value);
    } else {
      props.setProp(`profileEmployee.${key}`, v.value);
    }
  };
  const changeDate = (key: string) => (date: any) => {
    props.setProp(`profileEmployee.${key}`, moment(beToAd(date))?.format("DD/MM/YYYY"));
  };

  const handleSaveValue = () => {
    const userId = props.user?.id;

    if (!userId && !props.showUserForm) {
      return setOpen(true);
    }

    let requiredKey: RequiredFieldType = {
      code: "กรุณาระบุ รหัสพนักงาน",
      birth_date: "กรุณาระบุ วัน/เดือน/ปีเกิด",
      position: "กรุณาระบุ ตำแหน่งงาน", //ตามเงื่อนไข
      flag_start_date: "กรุณาระบุ วันที่เริ่มงาน",
    };

    let error = getRequireKeyError(requiredKey, props.profileEmployee);

    if (!!props.showUserForm) {
      requiredKey = {
        // citizen_passport: "กรุณาระบุ เลขบัตรประจำตัวประชาชน", // ตามเงื่อนไข
        email: "กรุณาระบุ Email",
        username: "กรุณาระบุ ชื่อผู้ใช้",
        pre_name: "กรุณาระบุ คำนำหน้า",
        first_name: "กรุณาระบุ ชื่อ",
        last_name: "กรุณาระบุ นามสกุล",
        ...(!userId
          ? { password: "กรุณาระบุ รหัสผ่าน", confirm_password: "กรุณา ยืนยันรหัสผ่าน" }
          : {}),
      };

      error = {
        ...error,
        ...getRequireKeyError(requiredKey, props.profileUser),
      };
    }

    // check ว่า password ตรงกันหรือไม่
    if (props.selectedRow === null && !error.confirm_password && !error.password) {
      if (props.profileUser.password !== props.profileUser.confirm_password) {
        error.confirm_password = "รหัสผ่านยืนยันไม่ตรงกับรหัสผ่าน กรุณากรอกใหม่";
      }
    }

    setShowRequiredField(error);

    if (!Object.keys(error).length) {
      props.onEvent({
        message: "SettingStaff",
        params: {
          action: "SAVE_EMPLOYEE",
          card: CARD_SETTING_EMPLOYEE,
          buttonLoadKey: `${CARD_SETTING_EMPLOYEE}_SAVE`,
          id: props.user?.id,
        },
      });
    }
  };

  const handleSelectRole = () => {
    let role: any[] = props?.user?.roles || [];

    role.push(selectedRoleTotal);

    props.setProp("user.roles", role);

    const filterRoles = roleList.filter((item: any) => props.user?.roles?.includes(item.id));
    const filterRoleTotal = props.roleOptions?.filter(
      (item: any) => !props.user?.roles?.includes(item.id)
    );

    setRoleTotal(filterRoleTotal);
    setRoles(filterRoles);
  };

  const handleUnSelectRole = () => {
    let role = props?.user?.roles || [];

    role = role.filter((value: any) => value !== selectedRoles);

    props.setProp("user.roles", role);

    const filterRoles = roleList?.filter((item: any) => props.user?.roles?.includes(item.id));
    const filterRoleTotal = props.roleOptions?.filter(
      (item: any) => !props.user?.roles?.includes(item.id)
    );

    setRoleTotal(filterRoleTotal);
    setRoles(filterRoles);
  };

  const handleSearchRole = (e: any, v: any) => {
    setSearch(v.value);
    props.onEvent({
      message: "SettingStaff",
      params: {
        action: "SEARCH",
        name: v.value,
      },
    });
  };

  const handleClose = () => {
    setOpenChangePassword(false);
  };

  const handleResetPassword = () => {
    setOpenChangePassword(true);

    props.onEvent({
      message: "SettingStaff",
      params: {
        action: "OPEN_CHANGE",
        id: props.profileUser?.user,
      },
    });
  };

  console.log("props.profileEmployee", props.profileEmployee, props);

  return (
    <div style={{ height: "calc(100vh - 8rem)", overflow: "auto" }}>
      <CardSettingEmployeeUX
        // employee
        flagEndDate={adToBe(props.profileEmployee?.flag_end_date)}
        flagStartDate={adToBe(props.profileEmployee?.flag_start_date)}
        code={props.profileEmployee?.code}
        inactiveReason={props.profileEmployee?.inactive_reason}
        position={props.profileEmployee?.position}
        status={props.profileEmployee?.status}
        //profile  user
        preNameTh={props.profileUser?.pre_name}
        firstNameTh={props.profileUser?.first_name}
        lastNameTh={props.profileUser?.last_name}
        preNameEn={props.profileUser?.pre_name_en}
        firstNameEn={props.profileUser?.first_name_en}
        lastNameEn={props.profileUser?.last_name_en}
        doctorType={props.profileUser?.doctor_type}
        // cid={props.profileUser?.citizen_passport}
        email={props.profileUser?.email}
        phoneNo={props.profileUser?.phone_no}
        username={props.profileUser?.username}
        password={props.profileUser?.password}
        confirmPassword={props.profileUser?.confirm_password}
        // user
        specialty={props.user?.specialty}
        specialties={props.user?.specialties}
        certificateNo={props.user?.certificate_no}
        codeDoctor={props.user?.codeDoctor}
        //require
        showRequiredField={showRequiredField}
        // options
        prenameThOptions={props.masterOptions?.prenameTh || []}
        prenameEnOptions={props.masterOptions?.prenameEn || []}
        positionOptions={props.masterOptions?.positionAll || []}
        specialtyOptions={props.masterOptions?.specialty}
        doctorTypeOptions={doctorTypeOptions}
        statusOptions={STATUS_OPTIONS}
        // config
        showUserForm={props.showUserForm}
        hideDoctorForm={props.hideDoctorForm}
        allowedResetPassword={props.user?.id}
        // Callback
        changeValue={changeValue}
        changeDate={changeDate}
        resetPassword={handleResetPassword}
        // Element
        ErrorMessages={
          <ErrorMessage
            error={props?.errorMessage?.[CARD_SETTING_EMPLOYEE]?.error}
            style={{ margin: "-1rem -0.5rem 1.5rem" }}
          />
        }
        age={
          <Input
            value={age()}
            readOnly={true}
            fluid={true}
            style={{ height: "fit-content", width: "100%" }}
          />
        }
        birthDate={
          <FormField className={showRequiredField.birth_date ? "error" : ""}>
            <DateTextBox
              value={
                props.profileEmployee?.birth_date ? adToBe(props.profileEmployee?.birth_date) : ""
              }
              inputFluid={true}
              style={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              onChange={changeDate("birth_date")}
            ></DateTextBox>
            {showRequiredField.birth_date && (
              <div style={{ position: "absolute", bottom: -17, color: "red", fontSize: "0.8rem" }}>
                {showRequiredField.birth_date}
              </div>
            )}
          </FormField>
        }
        eyePass1={
          <Icon
            name={openPass1 ? "eye" : "eye slash"}
            link
            onClick={() => {
              setOpenPass1(!openPass1);
            }}
          />
        }
        typePassword1={openPass1 ? "" : "password"}
        eyeConfirm1={
          <Icon
            name={openConPass1 ? "eye" : "eye slash"}
            link
            onClick={() => {
              setOpenConPass1(!openConPass1);
            }}
          />
        }
        typeConfirm1={openConPass1 ? "" : "password"}
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSaveValue}
            // data
            paramKey={`${CARD_SETTING_EMPLOYEE}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SETTING_EMPLOYEE}_SAVE`]}
            disabled={props.disableCreate}
            // config
            color="green"
            name="SAVE"
            size="small"
            style={{ width: "100%" }}
            title="บันทึก"
          />
        }
        icon={
          <>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                height: "50%",
                marginBottom: "10px",
              }}
            >
              <Icon
                name="arrow circle right"
                size="large"
                link={true}
                onClick={handleSelectRole}
              ></Icon>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                height: "50%",
                marginBottom: "10px",
              }}
            >
              <Icon
                name="arrow circle left"
                size="large"
                link={true}
                onClick={handleUnSelectRole}
              ></Icon>
            </div>
          </>
        }
        roleTotal={
          <div
            style={{
              border: "1px solid rgba(34, 36, 38, 0.25)",
              borderRadius: "15px",
              height: "180px",
              width: "235px",
            }}
          >
            <div style={{ margin: "10px" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <Input
                  placeholder="Filter"
                  icon="search"
                  value={search}
                  fluid={true}
                  style={{ width: "100%" }}
                  onChange={handleSearchRole}
                ></Input>
              </div>
              <div
                className="box-list"
                style={{
                  marginTop: "5px",
                  overflow: "auto",
                  paddingBottom: "10px",
                  height: "125px",
                }}
              >
                {roleTotal?.map((item: any) => (
                  <div
                    style={{ backgroundColor: selectedRoleTotal === item.id ? "#C6EBF3" : "" }}
                    onClick={() => {
                      setSelectedRoleTotal(item.id);
                    }}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
        roles={
          <>
            <div
              style={{
                border: "1px solid rgba(34, 36, 38, 0.25)",
                borderRadius: "15px",
                height: "180px",
                width: "235px",
              }}
            >
              <div
                className="box-list"
                style={{ margin: "10px", overflow: "auto", paddingBottom: "10px", height: "125px" }}
              >
                {roles?.map((item: any) => (
                  <div
                    style={{ backgroundColor: selectedRoles === item.id ? "#C6EBF3" : "" }}
                    onClick={() => {
                      setSelectedRoles(item.id);
                    }}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </>
        }
      />

      <Modal open={openChangePassword} size="small" onClose={handleClose}>
        <ModChangePassword
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          user={props.user}
          changePassword={props.changePassword}
          // CommonInterface
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // callback
          onClose={handleClose}
        />
      </Modal>

      <Modal open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} size="mini">
        <Segment inverted className="red" style={{ margin: 0 }}>
          แจ้งเตือน
        </Segment>
        <Modal.Content>
          <Grid>
            <Grid.Column textAlign="center">
              <p>กรุณา กรอกข้อมูลผู้ใช้งานระบบ</p>
              <Button basic color="red" size="small" onClick={() => setOpen(false)}>
                ตกลง
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default React.memo(CardSettingEmployee);
