import React, { useEffect, useState, useMemo, useCallback } from "react";
import CardVerbalOrderHistoryUX from "./CardVerbalOrderHistoryUX";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SearchBox from "react-lib/appcon/common/SearchBox";
import { ModConfirm } from "react-lib/apps/common";
import htmlParse from "html-react-parser";
import { Icon } from "semantic-ui-react";

import { VERBAL_ORDER_TYPES } from "./PTMInterface";

const CARD_VERBAL_ORDER_HISTORY = "CardVerbalOrderHistory";

const CardVerbalOrderHistory = (props: any) => {
  const [modConfirm, setModConfirm] = useState<any>({
    open: false,
    selectedItem: null,
  });

  // Callback Effect
  const handleSearch = useCallback(() => {
    props.onEvent({
      message: "HandleGetVerbalHistory",
      params: { card: CARD_VERBAL_ORDER_HISTORY },
    });
  }, []);

  // Effect
  useEffect(() => {
    if (
      props.errorMessage?.[CARD_VERBAL_ORDER_HISTORY] !== null &&
      props.errorMessage?.[CARD_VERBAL_ORDER_HISTORY] !== undefined
    ) {
      setTimeout(() => {
        props.setProp(`errorMessage.${CARD_VERBAL_ORDER_HISTORY}`, null);
      }, 2000);
    }
    if (
      props.successMessage?.[CARD_VERBAL_ORDER_HISTORY] !== null &&
      props.successMessage?.[CARD_VERBAL_ORDER_HISTORY] !== undefined
    ) {
      setTimeout(() => {
        props.setProp(`successMessage.${CARD_VERBAL_ORDER_HISTORY}`, null);
      }, 2000);
    }
  }, [
    props.successMessage?.CardVerbalOrderHistory,
    props.errorMessage?.CardVerbalOrderHistory,
  ]);

  useEffect(() => {
    const init = async () => {
      await props.setProp("verbalOrderHistoryFilter", {
        ...props.verbalOrderHistoryFilter,
        en: { ...props.selectedEncounter },
        selectedSearch: "EN",
      });

      handleSearch();
    };

    if (props.selectedEncounter?.id) {
      init();
    }
  }, [props.selectedEncounter?.id]);

  const handleOnChangeChecked = (e:any, data: any) => {
    let selectedOrder = [
      ...(props.verbalOrderHistoryFilter?.checkedTypes || []),
    ];
    if (!data.checked) {
      selectedOrder = selectedOrder.filter((item) => item !== data.name);
    } else {
      selectedOrder.push(data.name);
    }
    props.setProp("verbalOrderHistoryFilter.checkedTypes", selectedOrder);
  };

  const handleRemoveVerbalOrder = () => {
    props.onEvent({
      message: "HandleVerbalOrderAction",
      params: {
        card: CARD_VERBAL_ORDER_HISTORY,
        action: "remove",
        pendingTaskOrderId: modConfirm.selectedItem?.id,
      },
    });
    setModConfirm({ open: false, selectedItem: null });
  };

  const convertToTitleCaseAndSpace =(input: string)=> {
    const stringWithSpaces = input?.replace(/_/g, " ") || "";

    return stringWithSpaces
      .toLowerCase()
      .split(" ")
      .map(function (word: string) {
        return word.replace(word[0], word[0]?.toUpperCase());
      })
      .join(" ");
  }

  const verbalItems = useMemo(() => {
    return (props.verbalOrderHistoryList || []).map((item: any) => {
      // คำสั่งอื่นๆ กับ imaging ให้ดู cancelable จาก editable แทน
      const isCancelable = ["IMAGING_ORDER", "DOCTOR_NOTE_ORDER"].includes(item.order_specific_type)
        ? item.editable
        : item.cancelable;

      return {
        ...item,
        description: (
          <>
            {item.status === "CANCELED" ? (
              <del>{htmlParse(item?.description)}</del>
            ) : (
              <>{htmlParse(item?.description)}</>
            )}
          </>
        ),
        order_receive_by_name: htmlParse(item?.order_receive_by_name),
        patient_summary: htmlParse(item?.patient_summary),
        order_specific_label: (
          <>
            {item?.order_specific_label} {convertToTitleCaseAndSpace(item.drug_order_type)}
          </>
        ),
        _action: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Icon
              style={{ cursor: "pointer" }}
              name="pencil square"
              size="big"
              color="yellow"
              disabled={!props.actionable || !item.editable}
              onClick={() => {
                props.setEditItem(item);
              }}
            />
            <Icon
              style={{ cursor: "pointer" }}
              name="trash alternate"
              size="big"
              color="red"
              disabled={!props.actionable || !isCancelable}
              onClick={() => {
                setModConfirm({ open: true, selectedItem: { ...item } });
              }}
            />
          </div>
        ),
      };
    });
  }, [props.verbalOrderHistoryList]);

  // console.log("CardVerbalOrderHistory modConfirm", modConfirm)
  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_VERBAL_ORDER_HISTORY}`, null);
        }}
        error={props.errorMessage?.[CARD_VERBAL_ORDER_HISTORY]?.error}
        success={props.successMessage?.[CARD_VERBAL_ORDER_HISTORY]}
      />

      <CardVerbalOrderHistoryUX
        loadingButton={props.loadingStatus?.[CARD_VERBAL_ORDER_HISTORY]}
        // data
        selectedSearch={props.verbalOrderHistoryFilter?.selectedSearch}
        optionsSearch={props.verbalOrderHistoryFilter?.optionsSeach}
        selectedOrder={props.verbalOrderHistoryFilter?.checkedTypes}
        startDate={props.verbalOrderHistoryFilter?.startDate}
        endDate={props.verbalOrderHistoryFilter?.endDate}
        haveEncounter={props.selectedEncounter?.id}
        historyData={verbalItems}
        // event
        onChangeStartDate={(date) => {
          props.setProp("verbalOrderHistoryFilter.startDate", date);
        }}
        onChangeEndDate={(date) => {
          props.setProp("verbalOrderHistoryFilter.endDate", date);
        }}
        changeSelectedOrder={handleOnChangeChecked}
        onSearch={handleSearch}
        changeSelectedSearch={(e: any, data: any) => {
          props.setProp("verbalOrderHistoryFilter", {
            ...props.verbalOrderHistoryFilter,
            selectedSearch: data.value,
            hn: null,
            en: null,
          });
        }}
        // component
        optionSearch={
          props.verbalOrderHistoryFilter?.selectedSearch === "HN" ? (
            <SearchBox
              type="Patient"
              style={{ width: "100%" }}
              fluid={true}
              placeholder="ค้นหาชื่อคนไข้"
              toDisplay={(item: any) => `[${item.hn}] ${item.full_name}`}
              onEvent={props.onEvent}
              searchedItemList={props.searchedItemList}
              selectedItem={props.verbalOrderHistoryFilter?.hn}
              setSelectedItem={(data) => {
                props.setProp("verbalOrderHistoryFilter.hn", data);
              }}
            />
          ) : props.verbalOrderHistoryFilter?.selectedSearch === "EN" ? (
            <SearchBox
              type="Encounter"
              searchLength={1}
              style={{ width: "100%" }}
              fluid={true}
              placeholder="ค้นหาด้วยหมายเลข Encounter"
              toDisplay={(item: any) => `[${item.number}] ${item.patient_name}`}
              onEvent={props.onEvent}
              searchedItemList={props.searchedItemList}
              selectedItem={props.verbalOrderHistoryFilter?.en}
              setSelectedItem={(data) => {
                props.setProp("verbalOrderHistoryFilter.en", data);
              }}
            />
          ) : (
            ""
          )
        }
        editorSearch={
          <SearchBox
            type="User"
            style={{ width: "100%" }}
            fluid={true}
            placeholder="ค้นหาด้วยชื่อผู้บันทึก"
            toDisplay={(item: any) => `[${item.username}] ${item.full_name}`}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.verbalOrderHistoryFilter?.editor}
            setSelectedItem={(data) => {
              props.setProp("verbalOrderHistoryFilter.editor", data);
            }}
          />
        }
      />

      <ModConfirm
        openModal={modConfirm.open}
        titleColor="blue"
        titleName="ยืนยัน"
        content={`ต้องการลบรายการ รคส. ${
          VERBAL_ORDER_TYPES[modConfirm.selectedItem?.order_specific_type]?.name || ""
        } ของผู้ป่วย ${modConfirm.selectedItem?.patient_name || ""}
            คำสั่งจาก ${modConfirm.selectedItem?.co_signed || ""} ใช่หรือไม่`}
        approveButtonColor="red"
        approveButtonText="ใช่"
        onApprove={handleRemoveVerbalOrder}
        denyButtonColor="blue"
        denyButtonText="ไม่ใช่"
        onDeny={() => {
          setModConfirm({ open: false, selectedItem: null });
        }}
      />
    </>
  );
};

export default CardVerbalOrderHistory;
