import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Icon, Input, Modal, Segment } from "semantic-ui-react";
import ReactTable from "react-table-6";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
// ui ADM
import CardReAssessmentHistoryUX from "./CardReAssessmentHistoryUX";

const CARD_RE_ASSESSMENT_HISTORY: string = "CardReAssessmentHistory";

type CardReAssessmentHistoryProps = {
  // seq
  runSequence: null;

  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;

  // data
  masterOptions?: any[];

  title?: { code?: string; name?: string };
  data?: any; // {startDate, endDate, data, ...}
};

const CardReAssessmentHistory = (props: CardReAssessmentHistoryProps) => {
  // Table
  const [sliceData, setSliceData] = useState<number>(0);
  const [tableData, setTableData] = useState<any>({ pageSize: 0, row: [], column: [] });

  // Modal Delete
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [itemDelete, setItemDelete] = useState<any>({});
  const [textReason, setTextReason] = useState<string>("");

  useEffect(() => {
    createTableHeader();
  }, [props.data, sliceData]);

  const handleChangeStartDate = (date: string) => {
    let key = "";
    if (props.title?.code === "PAIN") {
      key = "painDataHistory";
    } else if (props.title?.code === "RISK_FALL") {
      key = "riskFallDataHistory";
    } else if (props.title?.code === "SLEEP") {
      key = "sleepDataHistory";
    }
    props.setProp(`ReAssessmentSequence.${key}.startDate`, date);
  };

  const handleChangeEndDate = (date: string) => {
    let key = "";
    if (props.title?.code === "PAIN") {
      key = "painDataHistory";
    } else if (props.title?.code === "RISK_FALL") {
      key = "riskFallDataHistory";
    } else if (props.title?.code === "SLEEP") {
      key = "sleepDataHistory";
    }
    props.setProp(`ReAssessmentSequence.${key}.endDate`, date);
  };

  const handleBackward = () => {
    if (sliceData - 1 < 0) {
      setSliceData(0);
    } else {
      setSliceData(sliceData - 1);
    }
  };

  const handleForward = () => {
    if (sliceData + 1 >= props.data?.data?.length) {
      setSliceData(sliceData);
    } else {
      setSliceData(sliceData + 1);
    }
  };

  const handleChangeDataForm = (dateString: string) => {
    if (dateString) {
      let date = dateString.split("/");
      let year = date[2].split("[");
      return `${parseInt(year[0], 10) - 543}-${date[1]}-${date[0]}`;
    }
  };

  const createTableHeader = () => {
    // formatDate to use isBetween()
    let startDate = handleChangeDataForm(props.data?.startDate);
    let endDate = handleChangeDataForm(props.data?.endDate);

    let itemData =
      props.data?.data?.filter((item: any) =>
        moment(handleChangeDataForm(item?.saveData)).isBetween(startDate, endDate, undefined, "[]")
      ) || [];

    const items = itemData.slice(0 + sliceData, 7 + sliceData).map((item: any, _index: any) => {
      if (props.title?.code === "PAIN") {
        let assessment;
        let location;
        let characteristic;
        props.masterOptions?.pain?.filter((data: any) => {
          if (data.value === item?.assessPainLevel) {
            assessment = data.text;
          }
        });

        props.masterOptions?.location?.filter((data: any) => {
          if (data.value === item?.painArea) {
            location = data.text;
          }
        });

        props.masterOptions?.characteristic?.filter((data: any) => {
          if (data.value === item?.painNature) {
            characteristic = data.text;
          }
        });

        return {
          ...item,
          ความเจ็บปวด: item?.painStatus,
          มาตรฐานการประเมิน: assessment,
          ระดับความเจ็บปวด: item?.painLevel,
          บริเวณที่ปวด: location,
          ลักษณะการปวด: characteristic,
          การให้การพยาบาลผู้ป่วย: item?.sensesNursingCare,
          ผู้บันทึก: item.editUserName,
          type: props.title?.code,
        };
      } else if (props.title?.code === "RISK_FALL") {
        return {
          ...item,
          ความเสี่ยง: item?.riskFall,
          ระดับความเสี่ยง: item?.riskFallRemark,
          การให้การพยาบาลผู้ป่วย: item?.nursingCare,
          ผู้บันทึก: item.editUserName,
          type: props.title?.code,
        };
      } else if (props.title?.code === "SLEEP") {
        return {
          ...item,
          ชั่วโมงการนอน: item?.sleepingPerDay,
          ความเพียงพอ: item?.sleepingEnough,
          ปัญหาการนอน:
            item?.sleepingProblem === "มี"
              ? `${item?.sleepingProblem} ${item?.sleepingProblemRemark}`
              : item?.sleepingProblem,
          ใช้ยานอนหลับ:
            item?.sleepPill === "ใช้"
              ? `${item?.sleepPill} ${item?.sleepPillRemark}`
              : item?.sleepPill,
          ผู้บันทึก: item?.editUserName,
          type: props.title?.code,
        };
      }
    });

    const dictionary = Object.assign(
      {},
      ...items.map((item: any, index: any) => ({ [index]: item }))
    );

    let headerColumn = [
      {
        Header: " ",
        accessor: "name",
        fixed: "left",
        resizable: false,
        width: 200,
      },
      ...items.map((item: any, index: any) => ({
        accessor: `${index}`,
        // Header: "",
        Header: () => {
          return (
            <div style={{ margin: "5px" }}>
              <div style={{ display: "flex", justifyContent: "left" }}>
                <label>{item.saveData}</label>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>{item.saveTime}</label>
                <div>
                  <Icon
                    name="trash alternate"
                    inverted
                    color="red"
                    link={true}
                    onClick={() => {
                      setItemDelete({ ...item, index: index });
                      setOpenDelete(true);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        },
      })),
    ];

    if (headerColumn.length < 8) {
      let i = headerColumn.length;
      for (i; i < 8; i++) {
        headerColumn.push({
          accessor: " ",
          Header: () => {
            return <div></div>;
          },
        });
      }
    }

    let data: any[] = [];
    let pageSize: number = 0;
    if (props.title?.code === "PAIN") {
      pageSize = 7;
      data = [
        { header: "ความเจ็บปวด" },
        { header: "มาตรฐานการประเมิน" },
        { header: "ระดับความเจ็บปวด" },
        { header: "บริเวณที่ปวด" },
        { header: "ลักษณะการปวด" },
        { header: "การให้การพยาบาลผู้ป่วย" },
        { header: "ผู้บันทึก" },
      ];
    } else if (props.title?.code === "RISK_FALL") {
      pageSize = 4;
      data = [
        { header: "ความเสี่ยง" },
        { header: "ระดับความเสี่ยง" },
        { header: "การให้การพยาบาลผู้ป่วย" },
        { header: "ผู้บันทึก" },
      ];
    } else if (props.title?.code === "SLEEP") {
      pageSize = 5;
      data = [
        { header: "ชั่วโมงการนอน" },
        { header: "ความเพียงพอ" },
        { header: "ปัญหาการนอน" },
        { header: "ใช้ยานอนหลับ" },
        { header: "ผู้บันทึก" },
      ];
    }

    const rowData: any[] = data.map((item) => ({
      ...item,
      ...Object.assign(
        {},
        ...headerColumn.map((column: any, index: any) => ({
          [index]: dictionary[index]?.[item.header],
        }))
      ),
      name: item.header,
    }));

    setTableData({ row: rowData, column: headerColumn, pageSize: pageSize });
  };

  const handleDelete = () => {
    let result = [...props.data?.data];
    result.splice(itemDelete?.index, 1);

    props.runSequence({
      sequence: "ReAssessment",
      action: "deleteDataHistory",
      card: CARD_RE_ASSESSMENT_HISTORY,
      formCode: props.title?.code,
      data: { item: result, remark: textReason },
    });

    handleCloseModalDelete();
  };

  const handleCloseModalDelete = () => {
    setItemDelete([]);
    setTextReason("");
    setOpenDelete(false);
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_RE_ASSESSMENT_HISTORY}`, null);
          props.setProp(`successMessage.${CARD_RE_ASSESSMENT_HISTORY}`, null);
        }}
        error={props.errorMessage?.[CARD_RE_ASSESSMENT_HISTORY]}
        success={props.successMessage?.[CARD_RE_ASSESSMENT_HISTORY]}
      />

      <CardReAssessmentHistoryUX
        title={props.title?.name}
        startDate={props.data?.startDate}
        endDate={props.data?.endDate}
        onChangeStartDate={handleChangeStartDate}
        onChangeEndDate={handleChangeEndDate}
        sliceA={handleBackward}
        sliceB={handleForward}
        tableData={
          <ReactTable
            showPagination={false}
            data={tableData?.row}
            columns={tableData?.column}
            pageSize={tableData?.pageSize}
            sortable={false}
          />
        }
        onSearch={createTableHeader}
      />

      <Modal size="tiny" open={openDelete} onClose={handleCloseModalDelete}>
        <Segment inverted className="red" style={{ margin: "0px" }}>
          ข้อความแจ้งเตือน
        </Segment>
        <div style={{ margin: "10px 20px" }}>
          <div style={{ fontWeight: "bold" }}>กรุณาระบุเหตุผล เพื่อยืนยันการลบข้อมูลการบันทึก</div>
          <div style={{ margin: "10px 0px" }}>
            <Input
              fluid={true}
              value={textReason}
              onChange={(e, { value }) => setTextReason(value)}
            ></Input>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="green" onClick={handleDelete} style={{ margin: "0px 20px" }}>
              ยืนยัน
            </Button>
            <Button color="red" onClick={handleCloseModalDelete} style={{ margin: "0px 20px" }}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

CardReAssessmentHistory.displayName = "CardReAssessmentHistory";

export default React.memo(CardReAssessmentHistory);
