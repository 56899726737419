import WasmController from "react-lib/frameworks/WasmController";
import * as PlantransferI from "./sequence/PlanTransfer";
import moment from "moment";

// APIS
import IPDContinueDayByEMR from "issara-sdk/apis/IPDContinueDayByEMR_apps_DPI";
import IPDContinueHistory from "issara-sdk/apis/IPDContinueHistory_apps_DPI";
import IPDContinueHistorySerializer_apps_DPI from "issara-sdk/types/IPDContinueHistorySerializer_apps_DPI";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import PlanItemDetail from "issara-sdk/apis/PlanItemDetail_core";
import IPDOneDayByEMR from "issara-sdk/apis/IPDOneDayByEMR_apps_DPI";
import IPDOneDayByEMRSerializer from "issara-sdk/types/IPDOneDayByEMRSerializer_apps_DPI";
import DoctorOrderCancel from "issara-sdk/apis/DoctorOrderCancel_apps_DPO";
import ProgressionNoteList from "issara-sdk/apis/ProgressionNoteList_apps_DPI";

// Utils
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import { doc, getDoc, updateDoc, serverTimestamp, setDoc } from "firebase/firestore";

export type State = {
  errorMessage?: any;
  // Prerequisite
  selectedEncounter?: any | null;
  selectedEmr?: any | null;
  orderSummary?: {
    emr?: number;
    items: any[];
  };
  orderSummaryOneDay?: IPDOneDayByEMRSerializer;
  filterOrderSummary?: {
    date: moment.Moment;
    nurseStatus: string | null;
    isNurseStatus: boolean;
    isOutPerformDivision: boolean;
    isPharmacy: boolean;
    isTreatmentOrder: boolean;
    isLab: boolean;
    isFood: boolean;
    isNoteOrder: boolean;
    isProcedure: boolean;
    isHD: boolean;
    isImaging: boolean;
  };
  continueHistoryList?: IPDContinueHistorySerializer_apps_DPI | null;
  orderSummaryPermission?: {
    config_BLB_BLOOD_ORDER_DETAIL: string;
    role_DOCTOR: boolean;
  };
  openModSuccessOrderSummary?: {
    open: boolean;
    message: any;
  };
  progressionNoteList?: any[];
} & PlantransferI.State;

export const StateInitial: State = {
  orderSummary: {
    items: [],
  },
  filterOrderSummary: {
    date: moment(),
    nurseStatus: null,
    isNurseStatus: false,
    isOutPerformDivision: true,
    isPharmacy: true,
    isTreatmentOrder: true,
    isLab: true,
    isFood: true,
    isNoteOrder: true,
    isProcedure: true,
    isHD: true,
    isImaging: true,
  },
  continueHistoryList: null,
  openModSuccessOrderSummary: {
    open: false,
    message: null,
  },
  // ...PlantransferI.StateInitial
};

export type Event =
  // GET
  | { message: "GetIPDOrderSummary"; params: any }
  | { message: "GetIPDContinueHistory"; params: { id: number } }
  | { message: "GetUserPermissionOrderSummary"; params: { id: number } }
  | { message: "GetListProgressionNote"; params: { emrId: number } }
  // UPDATE
  | { message: "HandleUpdatePlanItem"; params: { action: string; allowed_actions: string[] } }
  | { message: "HandleSetContinueHistoryList"; params: { data: any } }
  | { message: "HandleSetOpenModSuccessOrderSummary"; params: { open: boolean } }
  | { message: "HandleSetFilterOrderSummary"; params: { key: string; value: string | boolean } }
  | { message: "UpdateDoctorOrderCancel"; params: { id: number; note: string } }
  | {
      message: "SetIPDOrderSummaryFirebase";
      params: { checked: boolean; orderId: number; labId: number };
    }
  | PlantransferI.Event;

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

/* ------------------------- GET ------------------------ */
export const GetIPDOrderSummary: Handler = async (controller, params) => {
  const state = controller.getState();

  const filter = state.filterOrderSummary;

  const apiParams = {
    date: dateToStringWithoutTimeBE(filter?.date),
    flag_treatment_order: filter?.isTreatmentOrder,
    flag_note_order: filter?.isNoteOrder,
    flag_out_perform_div: filter?.isOutPerformDivision,
    flag_pharmacy: filter?.isPharmacy,
    flag_lab: filter?.isLab,
    flag_food: filter?.isFood,
    flag_or: filter?.isProcedure,
    flag_hd: filter?.isHD,
    flag_imaging: filter?.isImaging,
    nurse_status: filter?.isNurseStatus ? filter.nurseStatus : "",
  } as any;

  for (const key in apiParams) {
    if (!apiParams[key]) {
      delete apiParams[key];
    }
  }

  await controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [
        ["unit", {}],
        ["route", {}],
        ["site", {}],
        ["frequency", {}],
        ["method", {}],
      ],
    },
  });

  GetIPDOrderSummaryContinue(controller, { emrId: state.selectedEmr?.id, ...apiParams });
  GetIPDOrderSummaryOneDay(controller, { emrId: state.selectedEmr?.id, ...apiParams });
};

export const SetIPDOrderSummaryFirebase: Handler = async (controller, params) => {

  let state = controller.getState();
  let items = state.orderSummaryOneDay?.items ? [...state.orderSummaryOneDay?.items] : []
  let idx = items.findIndex((i: any) => i.id === params.orderId);
  console.log('idx: ', idx);

  if (idx !== -1) {
    items[idx] = {
      ...items[idx],
      orderSummaryFirebase: {
        ...items[idx].orderSummaryFirebase,
        [params.labId]: { loading: true }
      },
    };

    console.log('items: ', items);
    controller.setState({
      orderSummaryOneDay: {
        ...state.orderSummaryOneDay,
        items: items,
      },
    });
  } else {
    console.error("can't find element")
    return
  }

  const docRef = doc(controller.db, "OrderSummary", params.orderId.toString());
  const docSnap = await getDoc(docRef);
  state = controller.getState();
  if (docSnap.exists()) {

    let a = await updateDoc(doc(controller.db, "OrderSummary", params.orderId.toString()), {
      [params.labId]: { checked: params.checked , edited: state?.userId, edited: serverTimestamp(), divisionId: state.selectedDivision?.id, divisionNameCode: state.selectedDivision?.name_code}
    }).catch((error) => console.error(error))

  } else {
    console.log("createDoc")
    let a = await setDoc(doc(controller.db, "OrderSummary", params.orderId.toString()), {
      [params.labId]: { checked: params.checked, edited: state?.userId, edited: serverTimestamp(), created: serverTimestamp()}
    }).catch((error) => console.error(error))

  }

  state = controller.getState();

  items = state.orderSummaryOneDay?.items ? [...state.orderSummaryOneDay?.items] : []
  idx = items.findIndex((i: any) => i.id === params.orderId);

  if (idx !== -1) {
    items[idx] = {
      ...items[idx],
      orderSummaryFirebase: {
        ...items[idx].orderSummaryFirebase,
        [params.labId]: { checked: params.checked, loading: false}
      },
    };

    console.log('items: ', items);
    controller.setState({
      orderSummaryOneDay: {
        ...state.orderSummaryOneDay,
        items: items,
      },
    });
  }
};

export const GetIPDOrderSummaryContinue: Handler = async (controller, params) => {
  const orderSummary = await IPDContinueDayByEMR.retrieve({
    pk: params.emrId,
    params: {
      date: params.date,
      flag_treatment_order: params.flag_treatment_order,
      flag_note_order: params.flag_note_order,
      flag_out_perform_div: params.flag_out_perform_div,
      flag_pharmacy: params.flag_pharmacy,
      flag_lab: params.flag_lab,
      flag_food: params.flag_food,
      flag_or: params.flag_or,
      flag_hd: params.flag_hd,
      nurse_status: params.nurse_status,
    },
    apiToken: controller.apiToken,
  });
  if (orderSummary[1]) {
    controller.setState({
      orderSummary: undefined,
    });
  } else {
    let items = orderSummary[0]?.items || [];
    // for (const [index, item] of items?.entries()) {
    //   const docRef = doc(controller.db, "OrderSummary", item.id.toString());
    //   const docSnap = await getDoc(docRef);
    //     if (docSnap.exists()) {
    //       console.log("Document data:", docSnap.data());
    //       items[index].orderSummaryFirebase = docSnap.data()
    //     } else {
    //       // docSnap.data() will be undefined in this case
    //       console.log("No such document!");
    //       items[index].orderSummaryFirebase = {}
    //     }
    // }
    controller.setState({
      orderSummary: { ...orderSummary[0], items },
    });
  }
};

// Add Firebase 68288
export const GetIPDOrderSummaryOneDay: Handler = async (controller, params) => {
  const orderSummary = await IPDOneDayByEMR.retrieve({
    pk: params.emrId,
    params: {
      date: params.date,
      flag_treatment_order: params.flag_treatment_order,
      flag_note_order: params.flag_note_order,
      flag_out_perform_div: params.flag_out_perform_div,
      flag_pharmacy: params.flag_pharmacy,
      flag_lab: params.flag_lab,
      flag_food: params.flag_food,
      flag_or: params.flag_or,
      flag_hd: params.flag_hd,
      flag_imaging: params.flag_imaging,
      nurse_status: params.nurse_status,
    },
    apiToken: controller.apiToken,
  });

  // const docRef = doc(controller.db, "OrderSummary", );
  // const docSnap = await getDoc(docRef);
  if (orderSummary[1]) {
    controller.setState({
      orderSummaryOneDay: undefined,
    });
  } else {
    let items = orderSummary[0]?.items || [];
    for (const [index, item] of items?.entries()) {
      const docRef = doc(controller.db, "OrderSummary", item.id.toString());
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        items[index].orderSummaryFirebase = docSnap.data();
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
        items[index].orderSummaryFirebase = {};
      }
    }
    controller.setState({
      orderSummaryOneDay: { ...orderSummary[0], items },
    });
  }

  console.log("GetIPDOrderSummaryOneDay: ", orderSummary);
  controller.setState({
    orderSummaryOneDay: orderSummary[0],
  });
};

export const GetUserPermissionOrderSummary: Handler = async (controller, params) => {
  const permission = await UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_BLB_BLOOD_ORDER_DETAIL: "",
      role_DOCTOR: false,
      role_REGISTERED_NURSE: false,
    },
  });

  controller.setState({
    orderSummaryPermission: permission[0] || {},
  });
};

export const GetIPDContinueHistory: Handler = async (controller, params) => {
  const continueHistory = await IPDContinueHistory.retrieve({
    pk: params.id,
    apiToken: controller.apiToken,
  });

  controller.setState({
    continueHistoryList: continueHistory[0],
  });
};

/* ----------------------- UPDATE ----------------------- */
export const HandleUpdatePlanItem: Handler = async (controller, params) => {
  const response = await PlanItemDetail.update({
    pk: params.id,
    apiToken: controller.apiToken,
    data: {
      action: params.action,
      allowed_actions: params.allowed_actions,
      note: params.note || "",
      user_token: "",
      end_time: params.end_time,
      end_date: params.end_date,
    },
  });

  if (!response[1]) {
    HandleSetOpenModSuccessOrderSummary(controller, { open: true });
  }

  GetIPDOrderSummary(controller, params);
};

const PutDoctorOrderCancel: Handler = async (controller, params) => {
  const response = await DoctorOrderCancel.put({
    pk: params.id,
    data: {
      action: "CANCEL",
      note: params.note,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  return response;
};

export const UpdateDoctorOrderCancel: Handler = async (controller, params) => {
  const response = await PutDoctorOrderCancel(controller, params);

  if (!response[1]) {
    HandleSetOpenModSuccessOrderSummary(controller, { open: true, message: "ยกเลิกสำเร็จ" });
  }

  GetIPDOrderSummary(controller, params);
};

/* ------------------------- SET ------------------------ */
export const HandleSetContinueHistoryList: Handler = (controller, params) => {
  controller.setState({
    continueHistoryList: params.data,
  });
};

export const HandleSetOpenModSuccessOrderSummary: Handler = (controller, params) => {
  controller.setState({ openModSuccessOrderSummary: params });
};

export const HandleSetFilterOrderSummary: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState(
    {
      filterOrderSummary: {
        ...(state.filterOrderSummary as any),
        [params.key]: params.value,
      },
    },
    () => {
      GetIPDOrderSummary(controller, {});
    }
  );

  controller.handleEvent({
    message: "HandleNurseOrderPerformed" as any,
    params: { action: "select_all", isChecked: false },
  });
};

export const GetListProgressionNote: Handler = async (controller, params) => {
  const result = await ProgressionNoteList.list({
    apiToken: controller.apiToken,
    params: {
      emr: params.emrId,
      sort: "menu",
      offset: 0,
      limit: 40,
    },
  });

  controller.setState({ progressionNoteList: result[0]?.items || [] });
};
