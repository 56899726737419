import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { Button, Checkbox, Divider, Dropdown, Icon, Input } from "semantic-ui-react";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

// Interface
import { MasterOptionsType } from "./sequence/PackagePurchase";
import { PackageItemDetailType } from "./sequence/PackageDateTime";
import { USAGE_LIMIT_TYPES, UsageLimitType } from "./sequence/SettingPackage";

// Types
type CardAppointmentPackageProps = {
  setProp: (key: string, value: any, callback?: () => any) => any;
  // data
  data: DataType[];
  headerName?: string;
  hideHistory?: boolean; // * ซ่อนปุ่ม history;
  isCancel?: boolean; // * mode ยกเลิก package;
  isEdit?: boolean; // * mode แก้ไข;

  // config
  selectable?: boolean; // * select product ได้;
  showQty?: boolean;
  usageLimit?: UsageLimitType;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
  // callback
  onCancel?: (reason: string, callback?: () => any) => any;
  onClose?: () => any;
  onHistory?: (data: Partial<HistoryDataType>) => any;
  onSelect?: (data: Record<string, any>[]) => any;
};

type HistoryDataType = {
  product_code: string;
  product_id: number;
  product_name: string;
};

type DataType = Pick<
  PackageItemDetailType,
  | "active"
  | "balance"
  | "p_type_code"
  | "product"
  | "product_code"
  | "product_name"
  | "quantity_appointment"
>;

type ItemType = {
  balance?: number | string | null;
  code?: string;
  disabled?: boolean;
  header?: boolean;
  original?: DataType;
  qty?: number | string;
  table?: boolean;
  title: string;
  type?: string;
};

const BUTTON_ACTIONS = {
  cancel: "CANCEL_PACKAGE",
};

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

export const CARD_APPOINTMENT_PACKAGE = "CardAppointmentPackage";

const CardAppointmentPackage = (props: CardAppointmentPackageProps) => {
  // Checked
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [items, setItems] = useState<ItemType[]>([]);

  const [checkableByType, setCheckableByType] = useState<
    Record<string, { header: number; items: number[] }>
  >({});

  const [cancelReason, setCancelReason] = useState<Partial<{
    open: boolean;
    reason: any;
  }> | null>(null);

  // options
  const [cancelPackageOrderOptions, setCancelPackageOrderOptions] = useState<
    MasterOptionsType["cancelPackageOrder"]
  >([]);

  // Memo Effect
  const isHideQty = useMemo(
    () =>
      // เป็น encounter เดียว และ เป็นการเลือกจากหน้า Select Package
      props.usageLimit === USAGE_LIMIT_TYPES.ONLY_ONCE_IN_ENCOUNTER && props.selectable,
    [props.selectable, props.usageLimit]
  );

  const checkableAll = useMemo(
    () =>
      Object.values(checkableByType).flatMap((item) => [
        ...(item.header ? [item.header] : []),
        ...item.items,
      ]),
    [checkableByType]
  );

  // Effect
  useEffect(() => {
    // group by type {[type]: any[]}
    const group = {} as Record<string, DataType[]>;

    for (const item of props.data) {
      group[item.p_type_code] ??= [];
      group[item.p_type_code].push(item);
    }

    // flatMap any[] ออกมา โดย add header ไว้บนสุด {title, header}
    const flatList: ItemType[] = Object.entries(group).flatMap(([key, value]) => [
      {
        disabled: value.every((item) => !item.balance),
        title: key,
        type: key,
        header: true,
      },
      ...value.map((item) => {
        let quantity: number | string = "";

        if (item.quantity_appointment !== undefined && item.quantity_appointment !== null) {
          quantity = item.quantity_appointment;
        } else if (item.balance !== undefined && item.balance !== null) {
          quantity = isHideQty ? item.balance : 1;
        }

        return {
          balance: item.balance,
          code: item.product_code,
          original: item,
          qty: quantity,
          title: item.product_name,
          type: key
        };
      }),
    ]);

    const list = [{ balance: "จำนวนคงเหลือ", code: "Code", title: "รายการ" }, ...flatList];

    setItems(list);

    handleGetCheckableByType(list);
  }, [isHideQty, props.data]);

  useEffect(() => {
    // default checkedids เมื่อเป็นการ edit เท่านั้น
    if (!props.isEdit) {
      return;
    }

    const activeIndex = items.flatMap((item, index) => (item.original?.active ? [index] : []));
    // check header เมื่อเลือก children ทั้งหมด
    const headerIndex = Object.values(checkableByType).flatMap((item) =>
      item.items.every((acc) => activeIndex.includes(acc)) ? [item.header] : []
    );

    setCheckedIds([...activeIndex, ...headerIndex]);
  }, [checkableByType, props.isEdit]);

  useEffect(() => {
    setCancelPackageOrderOptions(props.masterOptions?.cancelPackageOrder || []);
  }, [props.masterOptions?.cancelPackageOrder]);

  // Default select all checkboxes
  useEffect(() => {
    if (!props.isEdit && props.selectable && checkableAll.length > 0) {
      handleCheckedAll(null, { checked: true });
    }
  }, [checkableAll, props.isEdit, props.selectable]);

  // Callback
  const handleGetCheckableByType = useCallback((list: ItemType[]) => {
    // group index ที่สามารถ check ได้
    const checkableByType: Record<string, { header: number; items: number[] }> = {};

    for (const [index, item] of list.entries()) {
      if (index === 0) {
        continue;
      } // ข้ามรายการแรก

      const isCheckable = (!!item.balance || item.header) && !item.disabled;
      const type = item.type || "";

      if (isCheckable) {
        if (type in checkableByType) {
          checkableByType[type].items.push(index);
        } else {
          checkableByType[type] = { items: [], header: index };
        }
      }
    }

    setCheckableByType(checkableByType);
  }, []);

  const handleChange = useCallback(
    (index: number, item: ItemType) => (e: any, data: any) => {
      let value = Number(data.value);

      const balance = Number(item.balance);

      // type number value ไม่น้อยกว่า 0 และ น้อยกว่าเท่ากับ balance
      if (value < 0) {
        value = 0;
      } else if (value > balance) {
        value = balance;
      }

      (items as any)[index][data.name] = value;

      setItems([...items]);
    },
    [items]
  );

  // Memo
  const isCheckedAll = useMemo(
    () => checkedIds.length > 0 && checkedIds.length === checkableAll.length,
    [checkableAll, checkedIds]
  );

  const minWidthStyle = useMemo(() => {
    if (isHideQty) {
      return "50rem";
    } else if (props.selectable) {
      return "53rem";
    }

    return "";
  }, [isHideQty, props.selectable]);

  // Handler
  const handleCheckedAll = (e: any, data: any) => {
    setCheckedIds(data.checked ? checkableAll : []);
  };

  const getChildrenIndexes = (items: any[], data: any) =>
    items.flatMap((item, index) =>
      item.type === data.type && !item.header && !!item.balance ? [index] : []
    );

  const getHeaderIndex = (items: any[], data: any) =>
    items.findIndex((item) => item.type === data.type && item.header);

  const updateCheckedIds = (
    checked: number[],
    indexList: number[],
    headerIndex: number,
    isChecked: boolean
  ) => {
    if (isChecked) {
      checked.push(...indexList);
    } else {
      return checked.filter((index) => !indexList.includes(index));
    }

    return checked;
  };

  const handleChecked = (index: number) => (e: any, v: any) => {
    let checked = [...checkedIds];

    const data = items[index];
    const childrenIndex = getChildrenIndexes(items, data);
    const headerIndex = getHeaderIndex(items, data);
    const indexList = [headerIndex, ...childrenIndex];

    if (data.header) {
      checked = updateCheckedIds(checked, indexList, headerIndex, v.checked);
    } else if (v.checked) {
      checked.push(index);
    } else {
      checked = checked.filter((cIndex) => index !== cIndex);
    }

    const childrenLength = checked.filter((index) => childrenIndex.includes(index)).length;

    if (childrenLength === childrenIndex.length && !checked.includes(headerIndex)) {
      checked = [...checked, headerIndex];
    } else if (!childrenLength && checked.includes(headerIndex)) {
      checked = checked.filter((index) => headerIndex !== index);
    }

    setCheckedIds(checked);
  };

  const handleAppointment = () => {
    const activeItems = props.data.map((item) => {
      const index = items.findIndex((acc) => acc.original?.product_code === item.product_code);

      return {
        ...item,
        active: !!checkedIds.includes(index) && !!Number(items[index].qty),
        quantity_appointment: items[index].qty,
      };
    });

    props.onSelect?.(activeItems);
  };

  const handleCancelPackage = () => {
    setCancelReason({ open: true });
  };

  const handleChangeReason = (e: any, v: any) => {
    setCancelReason({ ...cancelReason, reason: v.value });
  };

  const handleCloseModCancel = () => {
    setCancelReason(null);
  };

  const handleConfirmCancel = () => {
    props.onCancel?.(cancelReason?.reason, () => {
      // callback success
      handleCloseModCancel();
    });
  };

  const handleAddition = (e: any, v: any) => {
    setCancelPackageOrderOptions([
      { key: v.value, text: v.value, value: v.value },
      ...(cancelPackageOrderOptions || []),
    ]);
  };

  console.log("CardAppointmentPackage", props, items, checkedIds, checkableByType);

  return (
    <div style={{ minWidth: minWidthStyle }}>
      {!!props.headerName && (
        <>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              padding: "18px 10px 3px 25px",
            }}
          >
            <div
              style={{
                color: "#0072BC",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {props.headerName}
            </div>
            <Icon color="red" name="close" size="large" link onClick={props.onClose} />
          </div>
          <Divider style={{ margin: "1rem 1rem 0" }} />
        </>
      )}
      <div style={{ padding: "1.5rem 2rem 0" }}>
        <div
          style={{
            fontWeight: "bold",
            marginBottom: "0.75rem",
          }}
        >
          รายการที่สามารถนัดหมายได้
        </div>

        <ItemList
          checkedIds={checkedIds}
          hideHistory={props.hideHistory}
          isCheckedAll={isCheckedAll}
          isHideQty={isHideQty}
          items={items}
          selectable={props.selectable}
          showQty={props.showQty}
          onChecked={handleChecked}
          onCheckedAll={handleCheckedAll}
          onHistory={props.onHistory}
          onInputChange={handleChange}
        />

        <div style={{ display: "flex", margin: "1rem -0.5rem" }}>
          <div style={{ flex: 1 }} />
          {props.selectable && (
            <Button
              color={props.isEdit ? "yellow" : "green"}
              disabled={checkedIds.length === 0}
              style={{ marginRight: "1rem" }}
              onClick={handleAppointment}
            >
              {props.isEdit ? "แก้ไขรายการนัดหมาย" : "เพิ่มรายการรอลงนัดหมาย"}
            </Button>
          )}

          {props.isCancel && (
            <Button color={"red"} style={{ marginRight: "1rem" }} onClick={handleCancelPackage}>
              ยกเลิก Package
            </Button>
          )}

          {!props.headerName && (
            <Button color={"blue"} style={{ marginRight: "1rem" }} onClick={props.onClose}>
              ปิดหน้าต่าง
            </Button>
          )}
        </div>
      </div>

      <ModConfirm
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        openModal={!!cancelReason?.open}
        size="mini"
        titleColor="blue"
        titleName="กรุณาระบุเหตุผลการยกเลิก Package"
        onCloseWithDimmerClick={handleCloseModCancel}
        onDeny={handleCloseModCancel}
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"green"}
            name={BUTTON_ACTIONS.cancel}
            // data
            paramKey={`${CARD_APPOINTMENT_PACKAGE}_${BUTTON_ACTIONS.cancel}`}
            size="medium"
            title="ยืนยัน"
            basic
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_APPOINTMENT_PACKAGE}_${BUTTON_ACTIONS.cancel}`]
            }
            onClick={handleConfirmCancel}
          />
        }
        content={
          <div style={{ margin: "-2rem 0px -1rem" }}>
            <div style={{ textAlign: "left" }}>
              <div style={{ margin: "20px 0px 10px 0px" }}>สาเหตุ</div>

              <Dropdown
                value={cancelReason?.reason || ""}
                allowAdditions
                fluid
                search
                selection
                options={cancelPackageOrderOptions}
                onAddItem={handleAddition}
                // callback
                onChange={handleChangeReason}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                        ListItem                        */

/* ------------------------------------------------------ */
type ItemListProps = {
  checkedIds: number[];
  hideHistory?: boolean;
  isCheckedAll: boolean;
  isHideQty?: boolean;
  items: ItemType[];
  selectable?: boolean;
  showQty?: boolean;
  onChecked: (index: number) => (e: any, v: any) => void;
  onCheckedAll: (e: any, data: any) => void;
  onHistory?: (data: Partial<HistoryDataType>) => any;
  onInputChange: (index: number, item: ItemType) => (e: any, data: any) => void;
};

const ItemList = (props: ItemListProps) => {
  const handleInputRef = useCallback(
    (isActive: boolean) => (ref: any) => {
      if (!ref?.inputRef?.current) {
        return;
      }

      const inputElement = ref.inputRef.current;

      if (isActive) {
        inputElement.style.border = "none";
        inputElement.style.opacity = "0.75";
        inputElement.style.background = "#EAEAEA";
      } else {
        inputElement.style.border = "";
        inputElement.style.opacity = "";
        inputElement.style.background = "";
      }

      inputElement.style.textAlign = "right";
    },
    []
  );

  const getItemStyle = (item: ItemType, index: number) => ({
    borderBottom: props.items.length - 1 === index ? "1px dashed #CCCCCC" : "",
    borderTop: item.header ? "1px dashed #CCCCCC" : "",
    display: "grid",
    fontWeight: "normal",
    gap: "20px",
    ...(index === 0 && {
      backgroundColor: "#D0EEF2",
      border: "1px solid #979797",
    }),
    ...(props.showQty
      ? {
          gridTemplateColumns: "15% 1fr 15% 15% 15%",
          padding: item.header ? "7px 25px 7px 5px" : "5px 25px 5px 5px",
        }
      : {
          gridTemplateColumns: "15% 1fr 15% 15%",
          padding: "5px 25px",
        }),
    ...(props.hideHistory && { gridTemplateColumns: "1fr 15% 15% 2.5%" }),
    ...(props.isHideQty && { gridTemplateColumns: "1fr 15% 2.5%" }),
  });

  const renderCode = (item: ItemType, index: number) => (
    <div
      style={{
        display: "flex",
        ...(item.header && { color: "#2185D0", fontSize: "1.1rem", fontWeight: "bold" }),
      }}
    >
      {props.selectable && (
        <Checkbox
          checked={index === 0 ? props.isCheckedAll : props.checkedIds.includes(index)}
          disabled={!(item.balance || item.header) || item.disabled}
          style={{ marginRight: "1rem" }}
          onChange={index === 0 ? props.onCheckedAll : props.onChecked(index)}
        />
      )}
      {item.header ? item.title : item.code}
    </div>
  );

  const renderTitle = (item: ItemType) => (
    <div style={{ display: "flex" }}>{item.header ? "" : item.title}</div>
  );

  const renderQuantity = (item: ItemType, index: number) =>
    props.showQty &&
    !props.isHideQty && (
      <div style={GridCenter}>
        {index === 0
          ? "จำนวนนัดหมาย"
          : !item.header && (
              <Input
                ref={handleInputRef(!item.balance)}
                disabled={!item.balance}
                name="qty"
                style={{ opacity: 1, width: "100px" }}
                type="number"
                value={item.qty}
                fluid
                onChange={props.onInputChange(index, item)}
              />
            )}
      </div>
    );

  console.log("xxxx", props.items)

  return (
    <>
      {props.items.map((item, index) => (
        <div key={`item-${item.title}-${index}`} style={getItemStyle(item, index)}>
          {renderCode(item, index)}
          {renderTitle(item)}
          <div style={GridCenter}>{item.balance}</div>
          {renderQuantity(item, index)}
          <div style={GridCenter}>
            <RenderRightColumn
              data={item}
              hideHistory={props.hideHistory}
              index={index}
              onHistory={props.onHistory}
            />
          </div>
        </div>
      ))}
    </>
  );
};

/* ------------------------------------------------------ */

/*                    RenderRightColumn                   */

/* ------------------------------------------------------ */
type RenderRightColumnProps = {
  data: Record<string, any>;
  hideHistory?: boolean;
  index: number;
  onHistory?: (data: Record<string, any>) => void;
};

const RenderRightColumn = (props: RenderRightColumnProps) => {
  if (props.hideHistory) {
    return null;
  } else if (props.index === 0) {
    return <>ประวัติ</>;
  } else if (!props.data.header) {
    return (
      <Button
        color="grey"
        icon="history"
        size="mini"
        style={{ fontSize: "0.75rem" }}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();

          props.onHistory?.({
            product_code: props.data.original?.product_code,
            product_id: props.data.original?.product,
            product_name: props.data.original?.product_name,
          });
        }}
      />
    );
  }

  return null;
};

CardAppointmentPackage.displayName = "CardAppointmentPackage";

export default React.memo(CardAppointmentPackage);
