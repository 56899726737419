/* eslint-disable react/display-name */
/* eslint-disable jsx-quotes */
import React, {
  FC,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  ChangeEvent,
  useImperativeHandle
} from "react";
import {
  Header,
  Dropdown,
  Form,
  Dimmer,
  Loader,
  Button,
  Input,
  Icon,
  Divider,
  SemanticCOLORS,
  Popup
} from "semantic-ui-react";
import Tab from "react-lib/frameworks/Tab"
import moment from "moment";
import ReactTable from "react-table-6";
import * as Util from "react-lib/utils";

// Controller
import DashboardController from "react-lib/apps/IsHealth/Dashboard/DashboardController";
import PRXManager from "react-lib/apis/manager/PRXManager"
import CoreManager from "react-lib/apis/manager/CoreManager"

// Common
import RightLabel from "react-lib/apps/common/RightLabel";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import { ErrorMessage, ModInfo } from "react-lib/apps/common"

import { toast } from "react-toastify";

interface PendingDetail {
  id: number;
  fullname: string;
  role: string;
  division: string;
  request_date: string;
}

interface MemberDetail {
  id: number;
  fullname: string;
  role: string;
  division: string;
  request_date: string;
}
interface SettingsProps {
  apiToken: string;
  division: number;
  controller: DashboardController & {
    prxManager: PRXManager;
    coreManager: CoreManager;
  };
  readonly: boolean;

  onEvent: (args: { message: string; params: any }) => void;

  isLoadingSettings: boolean;
  onGetDivision: () => any
}

interface PendingTableProps {
  lists: PendingDetail[];
  onApproveJoinOARequest: Function;
  onDeleteJoinOARequest: Function;
}
interface MemberProps {
  lists: MemberDetail[];
  roleOptions: any[];
  onRoleChange: Function;
  selectRow: any
  onDeleteDivisionHasUserOA: Function
}

type ManagePermissionsProps = {
  pendingList: any[]
  onApproveJoinOARequest: Function
  getInvitationUrl: () => {}
  memberList: any[]
  roleOptions: any[]
  onChangeRole: Function
  selectedRow: any
  onDeleteDivisionHasUserOA: Function
  onSaveRoleChange: () => {}
  onDeleteJoinOARequest: Function
  readOnly: boolean
  division: number
}

type SettingOAAccountProps = {
  divisionProfile: any
  onEditProfile: (data?: any) => void | {}
  division: number 
}

type ModRemove = {
  open: boolean,
  id?: number,
  error?: any
}

type ModInfoType = {
  open: boolean,
  error?: any,
  title?: string,
  color?: SemanticCOLORS
}

const modInfoInitial: ModInfoType = {
  open: false,
  error: null,
  title: "",
  color: undefined
}

const HOSPITAL_IMAGE_URL = process.env.PUBLIC_URL + "/static/images/hospital.png"

/**
  * @example "a.b", "c"
  * @example (data) => `${data.key1} ${data.key2}`
*/
// field 

type FilterMemberKey = "fullName" | "position" | "division" | "managePermission" | "schedulePermission"
type FieldType = string | ((data: any) => string | number)
type FilterMemberType = {
  [key in FilterMemberKey]: {
    value: string,
    field: FieldType,
    operator: "eq" | "in"
  }
}

const filterMemberInitial: FilterMemberType = {
  fullName: {
    value: "",
    field: (data) => `${data.user_profile?.first_name} ${data.user_profile?.last_name}`,
    operator: "in"
  },
  position: {
    value: "",
    field: "user_position.name",
    operator: "eq"
  },
  division: {
    value: "",
    field: "division.name",
    operator: "in"

  },
  managePermission: {
    value: "",
    field: "role",
    operator: "eq"

  },
  schedulePermission: {
    value: "",
    field: "",
    operator: "eq"
  }
}

const PendingTable: React.FunctionComponent<PendingTableProps> = (props) => {
  const headerStyle = {
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columns = [
    {
      Header: "ชื่อ",
      accessor: "_full_name",
      headerStyle: headerStyle,
      Cell: ({ original }) =>
        <div>{original?.user_profile?.first_name} {original?.user_profile?.last_name}</div>
    },
    {
      Header: "ตำแหน่ง",
      accessor: " ",
      headerStyle: headerStyle,
      Cell: ({ original }) =>
        <div>{original?.employee_position?.name}</div>
    },
    {
      Header: "แผนก",
      accessor: "_division",
      headerStyle: headerStyle,
      Cell: ({ original }) =>
        <div>{original?.division?.name}</div>
    },
    {
      Header: "Request date",
      accessor: " ",
      headerStyle: headerStyle,
      Cell: ({ original }) => {
        const value = original?.created || ""
        return <span className="dob">
          {value.indexOf("/") >= 0
            ? Util.formatDate(moment(value, "DD/MM/YYYY [[HH:mm]]"))
            : Util.formatDate(moment(value))}
        </span>
      },
    },
    {
      Header: "Approve",
      accessor: "_approve",
      headerStyle: headerStyle,
      Cell: ({ original }) => (
        <Button basic color="green" onClick={() => props.onApproveJoinOARequest(original.id)}>
          Approve
        </Button>
      ),
    },
    {
      Header: "Delete",
      accessor: "_remove",
      headerStyle: headerStyle,
      Cell: ({ original }) => <Button
        color="red"
        icon="minus"
        onClick={() => props.onDeleteJoinOARequest(original.id)}>
      </Button>,
    },
  ];

  return (
    <div className="main-table">
      <ReactTable
        className="noHover"
        style={{ height: "250px" }}
        data={props.lists}
        columns={columns}
        pageSize={props.lists.length > 5 ? props.lists.length : 5}
        showPagination={false}
        noDataText="ไม่พบข้อมูลที่ค้นหา"
      />
    </div>
  );
};

const MemberTable: FC<MemberProps> = (props) => {

  const headerStyle = {
    padding: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columns = [
    {
      Header: "ชื่อ",
      accessor: "fullname",
      headerStyle: headerStyle,
      Cell: ({ original }) =>
        <div>{original?.user_profile?.first_name} {original?.user_profile?.last_name}</div>
    },
    {
      Header: "ตำแหน่ง",
      accessor: " ",
      headerStyle: headerStyle,
      Cell: ({ original }) =>
        <div>{original?.user_position?.name}</div>
    },
    {
      Header: "สิทธิ์การลงตารางออกตรวจ",
      accessor: " ",
      headerStyle: headerStyle,
    },
    {
      Header: "แผนก",
      accessor: " ",
      headerStyle: headerStyle,
      Cell: ({ original }) =>
        <div>{original?.division?.name}</div>
    },
    {
      Header: "สิทธิ์การจัดการระบบ",
      accessor: " ",
      headerStyle: headerStyle,
      Cell: ({ original }) =>
        <div>
          <Dropdown
            // selection
            search
            options={props.roleOptions}
            value={original.role}
            // fluid
            onChange={(e, { value }) => props.onRoleChange(original, value)}
          />
        </div>
    },
    {
      Header: "Delete",
      headerStyle: headerStyle,
      accessor: "_remove",
      Cell: ({ original }) => <Button
        color="red"
        icon="minus"
        onClick={() => props.onDeleteDivisionHasUserOA(original.id)}
      >
      </Button>,
    },
  ];

  const handleGetTdProps = (state: any, rowInfo: any, cellInfo: any) => {
    return {
      style: {
        overflow: "inherit"
      },
    };
  };

  const handleGetRowProps = (state: any, rowInfo: { original: { id: any; }; }) => {
    return {
      onClick: () => {

      },
      className: rowInfo?.original && rowInfo?.original?.id === props.selectRow?.id ? "selected" : "",
      style: {
        cursor: "pointer",
      },
    };
  };

  return (
    <div className="main-table">
      <ReactTable
        className="noHover"
        style={{ height: "250px" }}
        data={props.lists}
        columns={columns}
        pageSize={props.lists.length > 5 ? props.lists.length : 5}
        showPagination={false}
        noDataText="ไม่พบข้อมูลที่ค้นหา"
        getTdProps={(state: any, rowInfo: any, column: any) => {
          return handleGetTdProps(state, rowInfo, column)
        }}
        getTrProps={
          (state: any, rowInfo: any, column: any, instance: any) => { return handleGetRowProps(state, rowInfo) }
        }
      />
    </div>
  );
};

export const ManagePermissions = (props: ManagePermissionsProps) => {

  const [filterMember, setFilterMember] = useState<FilterMemberType>(filterMemberInitial)
  const [memberList, setMemberList] = useState<any[]>([])
  // const [position, setposition] = useState(initialState)
  // const [fullName, setfullName] = useState(initialState)

  useEffect(() => {
    if (props.division) {
      setFilterMember(filterMemberInitial)
    }
  }, [props.division])

  useEffect(() => {
    setMemberList(props.memberList)
  }, [props.memberList])

  useEffect(() => {
    const onFilterMemberList = (key?: FilterMemberKey, value?: string) => {
      const option: FilterMemberType = { ...filterMember }

      if (key) {
        option[key].value = value
      }

      const filter = props.memberList.filter((list) => {
        let isCompare = true
        const objKeys = Object.keys(option)

        objKeys.map((key) => {
          const { value, field, operator } = option[key as keyof FilterMemberType]
          const data = typeof field === "function" ? field(list) : objGet(list, field)

          if (value) {
            if (data === undefined) {
              objKeys.splice(0)
            }

            switch (operator) {
              case "eq":
                data === value ? (isCompare = true) : (isCompare = false)
                break;
              case "in":
                data.toString().toUpperCase().includes(value.toString().toUpperCase())
                  ? (isCompare = true) : (isCompare = false)
                break;

              default:
                break;
            }
          }

          if (!isCompare) {
            objKeys.splice(0)
          }
          return []
        })
        return isCompare
      })

      setMemberList(filter)
    }

    onFilterMemberList()
  }, [filterMember])

  // const updateValue: <T extends keyof FilterMemberType, K extends FilterMemberType[T]> = (name: T, value: K): void => {

  // }
  // key: FilterMemberType, value
  const handleSetInputFilterMember = (key: FilterMemberKey, value: string | number | boolean | (string | number | boolean)[]) => {
    setFilterMember({
      ...filterMember,
      [key]: {
        ...filterMember[key],
        value
      }
    })
  }

  const handleKeyPressFullName = (ev: any) => {
    // let fullName = ev.target.value
    // if (ev.key === "Enter") {
    //   onFilterMemberList("fullName", fullName)
    // }
  }

  const handleKeyPressDivision = (ev: any) => {
    // let division = ev.target.value
    // if (ev.key === "Enter") {
    //   onFilterMemberList("division", division)
    // }
  }

  const objGet = (obj: any, keyString: string) => {
    let keys = keyString.split('.')
    return keys.reduce((result: { [x: string]: any; }, key: string | number) => result?.[key], obj)
  }



  const createOption = (data: any[], key: string) => {
    const hasOption = new Map();
    const options = [{
      key: 0,
      text: "ไม่ระบุ",
      value: ""
    }]

    for (const [index, list] of data.entries()) {
      const name = list?.[key]?.name
      if (!hasOption.has(name) && name) {
        options.push({
          key: index,
          text: name,
          value: name
        })
        hasOption.set(name, hasOption.size);
      }
    }

    return options
  }

  const positionOptions = useMemo(() => {
    return createOption(props.memberList, "user_position")
  }, [props.memberList])

  const roleOptions = useMemo(() => {
    return [
      {
        key: 0,
        text: "ไม่ระบุ",
        value: ""
      },
      ...(props.roleOptions || [])
    ]
  }, [props.roleOptions])

  return <>
    <div style={{ height: "100vh", overflowY: "auto" }}>
      <div className="dashboard" style={{ paddingBottom: "40px" }}>
        <Header as="h2" dividing style={{ color: "gray" }}>
          การจัดการสิทธิ
        </Header>
        <Header as="h2" dividing style={{ color: "gray" }}>
          คำร้องขอ ({props.pendingList.length})
        </Header>
        <PendingTable
          lists={props.pendingList}
          onApproveJoinOARequest={props.onApproveJoinOARequest}
          onDeleteJoinOARequest={props.onDeleteJoinOARequest}
        />
        <Form>
          <Form.Group inline>
            <Form.Field width={14}></Form.Field>
            <Form.Field width={2}>
              <Button
                basic
                color="blue"
                onClick={props.getInvitationUrl}
                style={{ marginTop: "15px" }}
              >
                เชิญ User staff
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>

        <Header as="h2" dividing style={{ color: "gray" }}>
          สมาชิก ({props.memberList.length})
        </Header>
        <Form className="filter-input">
          <Form.Group inline>
            <Form.Field width={2}>
              <RightLabel> ชื่อ-นามสกุล </RightLabel>
            </Form.Field>
            <Form.Field width={4}>
              <Input
                className="width100"
                placeholder="Search"
                value={filterMember.fullName.value}
                onChange={(e, { value }) => handleSetInputFilterMember("fullName", value)}
                onKeyPress={handleKeyPressFullName}
              />
            </Form.Field>
            <Form.Field width={2}>
              <RightLabel> ตำแหน่ง </RightLabel>
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                className="fluidDropdown"
                disabled={props.readOnly}
                selection
                value={filterMember.position.value}
                options={positionOptions}
                onChange={(e, { value }) => handleSetInputFilterMember("position", value)}
              />
            </Form.Field>
            <Form.Field width={2}>
              <RightLabel> สิทธิ์การลงตารางออกตรวจ </RightLabel>
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                className="fluidDropdown"
                disabled={props.readOnly}
                selection
                value={filterMember.schedulePermission.value}
                options={[]}
                onChange={(e, { value }) => handleSetInputFilterMember("schedulePermission", value)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}>
              <RightLabel> แผนก </RightLabel>
            </Form.Field>
            <Form.Field width={4}>
              <Input
                className="width100"
                placeholder="Search"
                value={filterMember.division.value}
                onChange={(e, { value }) => handleSetInputFilterMember("division", value)}
                onKeyPress={handleKeyPressDivision}
              />
            </Form.Field>
            <Form.Field width={2}>
              <RightLabel> สิทธิ์จัดการระบบ </RightLabel>
            </Form.Field>
            <Form.Field width={7}>
              <Dropdown
                className="fluidDropdown"
                disabled={props.readOnly}
                selection
                value={filterMember.managePermission.value}
                options={roleOptions}
                onChange={(e, { value }) => handleSetInputFilterMember("managePermission", value)}

              />
            </Form.Field>
            ``
          </Form.Group>
        </Form>
        <MemberTable
          lists={memberList}
          roleOptions={props.roleOptions}
          onRoleChange={props.onChangeRole}
          selectRow={props.selectedRow}
          onDeleteDivisionHasUserOA={props.onDeleteDivisionHasUserOA}
        />

        <Form >
          <Form.Group inline>
            <Form.Field width={14}></Form.Field>
            <Form.Field width={2}>
            <Button
                color="blue"
                className="right aligned"
                type="button"
                onClick={props.onSaveRoleChange}
                disabled={props.selectedRow?.id ? false : true}
                style={{ marginTop: "15px" }}
              >
                บันทึก
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    </div>
  </>
}

// https://github.com/facebook/create-react-app/pull/8177
export const SettingOAAccount = (props: SettingOAAccountProps) => {
  const [profileImageSrc, setProfileImageSrc] = useState(HOSPITAL_IMAGE_URL);
  const [basicUrl, setBasicUrl] = useState("")

  const [divisionProfile, setDivisionProfile] = useState<any>({})
  const [fileImage, setFileImage] = useState(null);

  const inputFile = useRef(null);


  const handleClearImage = useCallback(() => {
    if (inputFile?.current) {
      inputFile.current.value = ""
    }
    setFileImage(null);
    setProfileImageSrc(props.divisionProfile.image_file || HOSPITAL_IMAGE_URL)
  }, [props.divisionProfile])

  useEffect(() => {
    if (props.division) {
      handleClearImage()
    }
  }, [handleClearImage, props.division])

  useEffect(() => {
    setDivisionProfile(props.divisionProfile)
    if (props.divisionProfile?.token) {
      const origin = globalThis.location.origin
      setBasicUrl(origin + "/?requestOA=" + props.divisionProfile?.token);
      setProfileImageSrc(props.divisionProfile.image_file || HOSPITAL_IMAGE_URL)
    }
  }, [props.divisionProfile])

  const handleSetValueDivisionProfile = (key: string, value: string) => {
    setDivisionProfile({
      ...divisionProfile,
      [key]: value
    })
  }

  const imageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files[0]) {
      return
    }
    let file = URL.createObjectURL(event.target.files[0]);
    console.log(" event.target.files[0])", event.target.files);
    setFileImage(event.target.files[0]);
    setProfileImageSrc(file);
  };



  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile?.current?.click();
  };

  return <React.Fragment >
    <div style={{ padding: "35px" }}>
      <Header as="h2" style={{ color: "#4F4F4F" }}>ตั้งค่าบัญชี OA</Header>
      <Divider style={{ marginBottom: "25px" }} />
      <div className="register-new-user">
        <div className="profile-image-section">
          <img className="profile-image" src={profileImageSrc} alt="division_image" />
          <div className="profile-button">
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={imageChange}

            />
            <Button
              style={{ backgroundColor: "#5DBCD2", color: "white" }}
              icon
              labelPosition="right"
              onClick={onButtonClick}
            >
              แก้ไขรูปภาพ <Icon name="picture" />
            </Button>

            {
              fileImage &&
              <Popup
                trigger={<Button icon="close" color="red" onClick={handleClearImage} />}
                content='Remove image'
                hideOnScroll
              />
            }
          </div>

          <span className="profile-text">
            กรุณาเลือกไฟล์ .jpg, .jpeg หรือ .png
          </span>
          <span className="profile-text">ขนาดไม่เกิน 2 Mb เท่านั้น</span>
        </div>
        <div className="vl"></div>
        <div className="profile-info" style={{ borderLeft: 0 }}>
          <Form >
            <Form.Field inline>
              <label className="reg">ชื่อบัญชี</label>
              <Input
                className="reg"
                // icon='edit'
                placeholder='แผนกอายุรกรรม'
                value={divisionProfile.name}
                onChange={(e, { value }) => handleSetValueDivisionProfile("name", value)}
              />
            </Form.Field>

            <Form.Field inline >
              <label className="reg">โรงพยาบาล/องค์กร</label>
              <Input
                className="reg"
                value={divisionProfile?.hospital_name || ""}
                onChange={(e, { value }) => handleSetValueDivisionProfile("hospital_name", value)}

              />
            </Form.Field>
            <Form.Field className="agency-code">
              <div >
                <label className="reg" style={{ fontWeight: "bold" }}>รหัสหน่วยงาน</label>
                <Input
                  className="reg"
                  fluid
                  value={divisionProfile.code || ""}
                  onChange={(e, { value }) => handleSetValueDivisionProfile("code", value)}

                />
                <label className="reg">*รหัสมาตราฐาน 5 หลัก</label>
              </div>
            </Form.Field>
            <Form.Field inline>
              <label className="reg">ชื่อระบบที่ใช้งาน</label>
              <Input
                className="reg"
                value={divisionProfile.system_name || ''}
                onChange={(e, { value }) => handleSetValueDivisionProfile("system_name", value)}

              />
            </Form.Field>

            <Form.Field inline>
              <label className="reg">สถานะการรับรอง</label>
              {
                !divisionProfile.verified ?
                  <label style={{ color: "red", marginRight: "10px", fontSize: "15px", marginLeft: "5px" }}>
                    Unverify
                  </label> :
                  <label style={{ color: "green", marginRight: "10px", fontSize: "15px", marginLeft: "5px" }}>
                    verify
                  </label>
              }

              {/* <Button
                className="btn-green"
                onClick={(e) => handleSetValueDivisionProfile("verified", true)}
              >
                รับรองบัญชี
              </Button> */}
            </Form.Field>

            <Form.Field inline >
              <label className="reg">ตำแหน่งที่ตั้ง</label>
              <Input
                className="reg"
                icon="map marker alternate"
                value={divisionProfile.address || ""}
                onChange={(e, { value }) => handleSetValueDivisionProfile("address", value)}

              />
            </Form.Field>

            <Form.Field inline >
              <label className="reg">เบสิค URL</label>
              <Input className="reg" readOnly={true} value={basicUrl}
              />
            </Form.Field>

            <Form.Field inline >
              <label className="reg">พรีเมียม URL</label>
              <Input
                className="reg"
                value={divisionProfile.premium_url}
                onChange={(e, { value }) => handleSetValueDivisionProfile("premium_url", value)}
              // readOnly={true}
              />
              <Button
                className="btn-green"
                onClick={(e) => {

                }}
              >
                ซื้อพรีเมียม ID
              </Button>
            </Form.Field>

            <Form.Field inline >
              <label className="reg">แพ็กเกจ</label>
              <Input className="reg" placeholder="Free trial" />
              <Button
                className="btn-green"
                onClick={(e) => {

                }}
              >
                เปลี่ยนแพ็กเกจ
              </Button>
            </Form.Field>
            <br />
            <div className="last-div" style={{ margin: "20px 0 0 260px", paddingLeft: 0 }}>
              <Button
                // loading={isSaveLoading}
                style={{ backgroundColor: "#5DBCD2", color: "white" }}
                type="submit"
                onClick={() => {
                  props.onEditProfile({
                    ...divisionProfile,
                    image_file: fileImage || null
                  })
                }}
              >
                บันทึกการแก้ไข
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </React.Fragment>
}

const Settings = React.forwardRef((props: SettingsProps, ref) => {
  // list
  const [pendingList, setPendingList] = useState<PendingDetail[]>([]);
  const [memberList, setMemberList] = useState<MemberDetail[]>([]);

  // loading
  const [isLoading, setIsLoading] = useState(false);

  // selected
  const [selectedRow, setSelectedRow] = useState(null);
  const [activeIndex, setActiveIndex] = useState<number | string>(0)

  //Detail
  const [divisionProfile, setDivisionProfile] = useState<any>({})

  //Open
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [openModMemberRemove, setOpenModMemberRemove] = useState<ModRemove>({
    open: false,
    error: null,
    id: null
  })
  const [openModConfirmRemove, setOpenModConfirmRemove] = useState<ModRemove>({
    open: false,
    id: null,
    error: null
  })
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial)

  const [inviteLink, setInviteLink] = useState("");
  const [roleId, setRoleId] = useState(null)

  //options
  const [roleOptions, setRoleOptions] = useState([])

  // Ref
  const inviteInputBox = useRef<Input>();

  useImperativeHandle(ref, () => ({
    getPendingList: () => getPendingList(),
    getMemberList: () => getMemberList(),
    getUserRole: () => getUserRole()
  }));

  // ---------- API GET
  const getPendingList = useCallback(async () => {
    const [res, error] = await props.controller.prxManager.getListJoinOARequest({
      apiToken: props.apiToken,
      divisionId: props.division
    })
    setPendingList(res?.items || [])
  }, [props.apiToken, props.division, props.controller.prxManager])

  const getMemberList = useCallback(async () => {
    const [res, error] = await props.controller.prxManager.getListDivisionHasUserOA({
      apiToken: props.apiToken,
      divisionId: props.division
    })

    setMemberList(res?.items || [])
  }, [props.apiToken, props.division, props.controller.prxManager])

  const getUserRole = useCallback(async () => {
    const [res, error] = await props.controller.prxManager.getListUserRoleOA({
      apiToken: props.apiToken,
      divisionId: props.division
    })
    const options = res?.items?.map((item: { id: any; display_name: any; }, index: any) => ({ key: index, value: item.id, text: item.display_name })) || []
    setRoleOptions(options)
  }, [props.apiToken, props.division, props.controller.prxManager])

  const getOADivisionProfile = useCallback(async () => {
    setIsLoading(true)
    const [res] = await props.controller.prxManager.getOADivisionProfile({
      apiToken: props.apiToken,
      divisionId: props.division
    })
    setIsLoading(false)
    setDivisionProfile(res || {})
  }, [props.apiToken, props.division, props.controller.prxManager])

  useEffect(() => {
    const getDataTable = async () => {
      setIsLoading(true);

      await getUserRole()

      await Promise.all([getPendingList(), getMemberList()])
      setIsLoading(false);
    }

    if (props.division && activeIndex === 1) {
      getDataTable()
    }
  }, [props.division, activeIndex, getPendingList, getMemberList, getUserRole]);

  useEffect(() => {
    if (props.division && activeIndex === 0) {
      getOADivisionProfile()
    }
  }, [props.division, activeIndex, getOADivisionProfile])


  const handleApproveJoinOARequest = async (id: number) => {
    const [res, error] = await props.controller.prxManager.postApproveJoinOARequest({
      apiToken: props.apiToken,
      divisionId: props.division,
      id
    })
    if (error) {
      return
    }

    setIsLoading(true)
    await Promise.all([getPendingList(), getMemberList()])
    setIsLoading(false)
  }
  const handleDeleteJoinOARequest = (id: number) => {
    setOpenModConfirmRemove({
      open: true,
      id
    })
  }

  const handleConfirmDeleteJoinOARequest = async ({ id }) => {
    const [res, error] = await props.controller.prxManager.deleteJoinOARequest({
      apiToken: props.apiToken,
      divisionId: props.division,
      id,
      detail: null
    })
    if (error) {
      setOpenModConfirmRemove({
        ...openModConfirmRemove,
        open: true,
        error
      })
      return
    }
    setOpenModConfirmRemove({
      open: false,
      error: null,
      id: null
    })

    setIsLoading(true)
    await getPendingList()
    setIsLoading(false)
  }

  const handleDeleteDivisionHasUserOA = (id: number) => {
    setOpenModMemberRemove({
      id,
      open: true
    })
  }

  const handleConfirmDeleteDivisionHasUserOA = async (id: number) => {
    const [res, error] = await props.controller.prxManager.deleteDivisionHasUserOA({
      apiToken: props.apiToken,
      divisionId: props.division,
      id
    })
    if (error) {
      setOpenModMemberRemove({
        ...openModMemberRemove,
        open: true,
        error
      })
      return
    }

    handleCloseMod()
    setIsLoading(true)
    await getMemberList()
    setIsLoading(false)
  }

  const handleChangeRole = async (row: any, roleId: any) => {
    const setRole = memberList.map((list) => {
      if (list.id === row.id) {
        return {
          ...list,
          role: roleId
        }
      } else return list
    })

    setMemberList(setRole)
    setSelectedRow(row)
    setRoleId(roleId)

  }

  const handleSaveRoleChange = async () => {
    if (selectedRow?.id) {
      const [res, error] = await props.controller.prxManager.patchDivisionHasUserOA({
        apiToken: props.apiToken,
        id: selectedRow.id,
        divisionId: props.division,
        roleId
      })

      if (error) {
        setOpenModInfo({
          open: true,
          error
        })
        return
      }

      setSelectedRow(null)
      setRoleId(null)
      setIsLoading(true)
      await getMemberList()
      setIsLoading(false)
    }
  }

  const handleEditProfile = async (data: any) => {
    const [res, error] = await props.controller.prxManager.patchOADivisionProfile({
      apiToken: props.apiToken,
      divisionId: props.division,
      ...data,
      premiumUrl: data.premium_url,
      imageFile: data.image_file,
      divisionName: data.division_name,
      hospitalName: data.hospital_name,
      systemName: data.system_name
    })

    if (error) {
      setOpenModInfo({
        open: true,
        error
      })
      return
    }

    setOpenModInfo({
      open: true,
      color: "green",
      title: "บันทึกสำเร็จ"
    })
    props.onGetDivision?.()

    getOADivisionProfile()

  }

  const handleCloseMod = () => {
    setOpenModMemberRemove({
      open: false,
      error: null,
      id: null
    })
  }

  const handleCloseModInfo = () => [
    setOpenModInfo(modInfoInitial)
  ]

  const getInvitationUrl = async () => {
    //{"division_id":11038,"division_name":"IsHealth Admin","token":"8fc96d37-1420-4faf-a863-5a5b4509c3af"}
    const [data] = await props.controller.getOAInviteLink({ apiToken: props.apiToken, divisionId: props.division })
    console.log(" data ", data)

    if (data?.token) {
      let origin = window.location.origin
      setInviteLink(origin + "/?requestOA=" + data?.token);
      setOpenInviteDialog(true);
    } else {
      alert("ไม่สามารถเรียก URL จาก server ได้กรุณาลองอีกครั้ง")
    }
  }

  return (
    <div style={{ width: "100%", }} className="setting-oa">
      <Dimmer active={isLoading || props.isLoadingSettings} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>


      {/* <Tab
        menu={{ fluid: true, vertical: true }}
        grid={{ paneWidth: 14, tabWidth: 2 }}
        onTabChange={(e, { activeIndex }) => {
          setActiveIndex(activeIndex)
        }}
        panes={[
          {
            menuItem: <Menu.Item>
              <Icon name='user' />   ตั้งค่าบัญชี OA
            </Menu.Item>,
            render: () => (
              <Tab.Pane style={{ width: "100%", overflowY: "auto" }}>
                <SettingOAAccount
                  divisionProfile={divisionProfile}
                  onEditProfile={handleEditProfile}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item>
              <Icon name="users" /> การจัดการสิทธิ
              </Menu.Item>,
            render: () => (
              <Tab.Pane style={{ width: "100%", overflowY: "auto", paddingBottom: "35px" }}>
                <ManagePermissions
                  pendingList={pendingList}
                  onApproveJoinOARequest={handleApproveJoinOARequest}
                  getInvitationUrl={getInvitationUrl}
                  memberList={memberList}
                  roleOptions={roleOptions}
                  onChangeRole={handleChangeRole}
                  selectedRow={selectedRow}
                  onDeleteDivisionHasUserOA={handleDeleteDivisionHasUserOA}
                  onSaveRoleChange={handleSaveRoleChange}
                  onDeleteJoinOARequest={handleDeleteJoinOARequest}
                  readOnly={false}
                  division={props.division}
                />
              </Tab.Pane>
            ),
          },
          // {
          //   menuItem: <Menu.Item>ซื้อแพ็คเกจ</Menu.Item>,
          //   render: () => (
          //     <Tab.Pane style={{ width: "100%", overflowY: "auto" }}>buy package</Tab.Pane>
          //   ),
          // },
        ]}
      /> */}

      <Tab
        header={["ตั้งค่าบัญชี OA", "การจัดการสิทธิ"]}
        tabWidth={"150px"}
        onTabChange={(index: React.SetStateAction<string | number>) => {
          setActiveIndex(index)
        }}
        data={[
          {
            key: "ตั้งค่าบัญชี OA",
            name: (<div style={{ display: "flex", justifyContent: "center", color: "#5dbcd2" }}>
              <Icon name="user" />
              {" "}ตั้งค่าบัญชี OA
            </div>),
            render: () => {
              return (
                <SettingOAAccount
                  divisionProfile={divisionProfile}
                  onEditProfile={handleEditProfile}
                  division={props.division}
                />
              )
            }
          },
          {
            key: "การจัดการสิทธิ",
            name: (<div style={{ display: "flex", justifyContent: "center", color: "#5dbcd2" }}>
              <Icon name="group" />
              {" "}การจัดการสิทธิ
            </div>),
            render: () => {
              return (
                <ManagePermissions
                  pendingList={pendingList}
                  onApproveJoinOARequest={handleApproveJoinOARequest}
                  getInvitationUrl={getInvitationUrl}
                  memberList={memberList}
                  roleOptions={roleOptions}
                  onChangeRole={handleChangeRole}
                  selectedRow={selectedRow}
                  onDeleteDivisionHasUserOA={handleDeleteDivisionHasUserOA}
                  onSaveRoleChange={handleSaveRoleChange}
                  onDeleteJoinOARequest={handleDeleteJoinOARequest}
                  readOnly={false}
                  division={props.division}
                />
              )
            }
          }
        ]}

      />

      <ModConfirm
        openModal={openInviteDialog}
        titleName="Copy Link"
        size="small"
        denyButtonColor="orange"
        denyButtonText="ยกเลิก"
        approveButtonColor="blue"
        approveButtonText="คัดลอก"
        content={
          <Form>
            <Form.Field width={16}>
              <Input ref={inviteInputBox} value={inviteLink} />
            </Form.Field>
          </Form>
        }
        onApprove={() => {
          inviteInputBox.current.select();
          document.execCommand("copy");

          setTimeout(function () {
            setOpenInviteDialog(false);
            toast.success("copied");
          }, 1000);
        }}
        onDeny={() => {
          setOpenInviteDialog(false);
        }}
      />

      <ModConfirm
        openModal={openModConfirmRemove.open}
        titleName="ต้องการลบหรือไม่"
        size="mini"

        denyButtonColor="orange"
        denyButtonText="ยกเลิก"
        approveButtonColor="blue"
        approveButtonText="ตกลง"
        content={
          <ErrorMessage error={openModMemberRemove.error} />
        }
        onApprove={() => {
          handleConfirmDeleteJoinOARequest({
            id: openModConfirmRemove.id,
          })

        }}
        onDeny={() => {
          setOpenModConfirmRemove({
            open: false,
            error: null,
            id: null
          })
        }}
      />

      <ModConfirm
        openModal={openModMemberRemove.open}
        titleName="ต้องการลบหรือไม่"
        size="mini"
        denyButtonColor="orange"
        denyButtonText="ยกเลิก"
        approveButtonColor="blue"
        approveButtonText="ตกลง"
        content={
          <ErrorMessage error={openModMemberRemove.error} />
        }
        onApprove={() => {
          handleConfirmDeleteDivisionHasUserOA(openModMemberRemove.id)
        }}
        onDeny={handleCloseMod}
        onCloseWithDimmerClick={handleCloseMod}
      />

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color || "red"}
        titleName={openModInfo.title}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <ErrorMessage error={openModInfo.error} />
      </ModInfo>
    </div>
  );
});

Settings.displayName = "Settings";
export default React.memo(Settings);
