import React, { useState } from "react";
import { Input, Icon } from "semantic-ui-react";
type PasswordRecheck = {
  id?: string;
  password: string;
  setPassword: any;
  placeholder?: string;
  fluid?: boolean;
};

const PasswordRecheck = (props: PasswordRecheck) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      id={props.id}
      placeholder={props.placeholder || "จำเป็นต้องระบุรหัสผ่าน"}
      type={showPassword? "text": "password" }
      value={props.password}
      onChange={(event, data) => {
        props.setPassword(data.value);
      }}
      fluid={props.fluid}
      icon={
        <Icon
          disabled={!props.password}
          link
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          name={showPassword ? "eye" : "eye slash"}
        />
      }
    />
  );
};

export default PasswordRecheck;
