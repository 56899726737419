import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Checkbox, Icon, Input, Modal } from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";

// UX
import CardPurchaseListUX from "./CardPurchaseListUX";
import LoadingIcon from "./LoadingIcon";
import ModPackageDescription from "./ModPackageDescription";

// BIL
import CardMedicalFee from "../BIL/CardMedicalFee";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { ModConfirm } from "react-lib/apps/common";

// Interface
import {
  PackageOrderSerializer,
  RunSequence,
  State,
  BUTTON_ACTIONS,
  MasterOptionsType,
} from "./sequence/PackagePurchase";

// Types
type CardPurchaseListProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  PackagePurchaseSequence?: State["PackagePurchaseSequence"];
  BillPaymentSequence?: any;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  patientInfo?: any;
  selectedEncounter?: Record<string, any> | null;
  selectedDevice?: Record<string, any> | null;
  // options
  masterOptions?: MasterOptionsType;
};
// Const
const PACKAGE_STATUS_OPTIONS = [
  { key: 1, value: "ACTIVE", text: "Active" },
  { key: 2, value: "INACTIVE", text: "Inactive" },
];

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

const CARD_PURCHASE_LIST = "CardPurchaseList";

export const CARD_BILLING_HISTORY = "CardBillingHistory";

const CardPurchaseList = (props: CardPurchaseListProps) => {
  // Checked
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  // Mod
  const [modDelete, setModDelete] = useState<PackageOrderSerializer | null>(
    null
  );

  // Use Effect
  useEffect(() => {
    // use action PurchaseList
    props.runSequence({
      sequence: "PackagePurchase",
      restart: true,
      nextIndex: "PurchaseList",
      card: CARD_PURCHASE_LIST,
    });

    // get เลขที่ใบเสร็จ
    props.onEvent({
      message: "HandleGetReceiptCode",
      params: {},
    });
  }, []);

  // Use Callback
  const mapPackageOptions = useCallback((items: any[]) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: `[${item.code}] ${item.name}`,
    }));
  }, []);

  const handleSelectedItem = useCallback(
    async (value: any, key: any) => {
      props.setProp(
        `PackagePurchaseSequence.filterPurchaseList.product`,
        value || null
      );
    },
    [props.searchedItemListWithKey]
  );

  const handleEditQty = useCallback(
    (item: PackageOrderSerializer) => (_e: any, data: any) => {
      let value = data.value;

      // type number value ไม่น้อยกว่า 1
      value = Number(value) < 1 ? 1 : value;

      if (Number(item.quantity) === value) {
        return;
      }

      props.runSequence({
        sequence: "PackagePurchase",
        action: "UPDATE",
        card: CARD_PURCHASE_LIST,
        data: { quantity: value },
        packageId: item.product,
        orderId: item.id,
      });
    },
    [props.PackagePurchaseSequence?.purchaseList]
  );

  const handleChecked = useCallback(
    (id: number) => (_e: any, data: any) => {
      setCheckedIds((checked) =>
        data.checked
          ? [...checked, id]
          : checked.filter((value) => value !== id)
      );
    },
    []
  );

  const handleOpenInfo = useCallback(
    (id?: number) => () => {
      if (id) {
        props.runSequence({
          sequence: "PackagePurchase",
          action: "DESCRIPTION",
          card: CARD_PURCHASE_LIST,
          packageId: id,
        });
      }
    },
    []
  );

  // Use Memo
  const filterPurchaseList = useMemo(() => {
    return props.PackagePurchaseSequence?.filterPurchaseList || {};
  }, [props.PackagePurchaseSequence?.filterPurchaseList]);

  const purchaseList = useMemo(() => {
    return props.PackagePurchaseSequence?.purchaseList || [];
  }, [props.PackagePurchaseSequence?.purchaseList]);

  const checkedItems = useMemo(() => {
    return purchaseList.filter((item) => checkedIds.includes(item.id));
  }, [purchaseList, checkedIds]);

  const totalPayment = useMemo(() => {
    return checkedItems.length
      ? checkedItems
          .reduce((result, item) => result + Number(item.price || "0"), 0)
          .toFixed(2)
      : "-";
  }, [checkedItems]);

  const isCheckAll = useMemo(() => {
    const list = purchaseList.flatMap((item) =>
      !item.inactive ? [item.id] : []
    );

    return !!checkedIds.length && checkedIds.length === list.length;
  }, [checkedIds, purchaseList]);

  const showPayment = useMemo(() => {
    const options = props.masterOptions?.bilStation || [];

    return (
      !!options.length &&
      !!options.find((option) => option.relate === props.selectedDevice?.id)
    );
  }, [props.masterOptions?.bilStation, props.selectedDevice]);

  const purchaseItems = useMemo(() => {
    return (purchaseList || []).map((item, _index) => {
      const packageType =
        props.masterOptions?.packageType?.find(
          (option) => option.value === item.package_type
        )?.text || "";

      const serviceType =
        props.masterOptions?.packageServiceType?.find(
          (option) => option.value === item.package_service_type
        )?.text || "";

      return {
        ...item,
        check: (
          <div style={GridCenter}>
            <Checkbox
              checked={checkedIds.includes(item.id)}
              disabled={item.inactive}
              onChange={handleChecked(item.id)}
            />
          </div>
        ),
        code: (
          <div style={{ display: "flex" }}>
            {!item.inactive ? (
              <Icon name="check circle" color="green" />
            ) : (
              <Icon name="times circle" color="grey" />
            )}

            <label style={{ marginLeft: "2px" }}>{item.product_code}</label>
          </div>
        ),
        price: <div style={GridCenter}>{item.price}</div>,
        service_type: <div>{serviceType}</div>,
        product_type: <div>{packageType}</div>,
        qty: (
          <Input
            ref={(ref: any) =>
              ref && (ref.inputRef.current.style.textAlign = "right")
            }
            value={item.quantity}
            type="number"
            name="qty"
            disabled={item.inactive}
            onChange={handleEditQty(item)}
          />
        ),
        description: (
          <div style={GridCenter}>
            <LoadingIcon
              name="eye"
              loading={
                !!props.buttonLoadCheck?.[
                  `${CARD_PURCHASE_LIST}_${BUTTON_ACTIONS.desc}_${item.id}`
                ]
              }
              onClick={handleOpenInfo(item.product)}
            />
          </div>
        ),
        delete: (
          <div style={GridCenter}>
            <Icon
              name="trash alternate"
              color="red"
              link={true}
              style={{ fontSize: "1.2rem" }}
              onClick={() => setModDelete(item)}
            />
          </div>
        ),
      };
    });
  }, [
    purchaseList,
    checkedIds,
    props.masterOptions?.packageServiceType,
    props.masterOptions?.packageType,
  ]);

  // Handler
  const handleChangeValue = (_e: any, data: any) => {
    let value = typeof data.value === "undefined" ? data.checked : data.value;

    const detail = {
      ...(props.PackagePurchaseSequence?.filterPurchaseList || {}),
    };

    (detail as any)[data.name] = value;

    props.setProp(`PackagePurchaseSequence.filterPurchaseList`, { ...detail });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "PackagePurchase",
      action: "SEARCH",
      card: CARD_PURCHASE_LIST,
    });
  };

  const handleGetTdProps = (
    state: any,
    rowInfo?: RowInfo,
    column?: Column,
    instance?: any
  ) => {
    return {
      style: {
        color:
          !!rowInfo?.original && rowInfo?.original.inactive ? "#BDBDBD" : "",
      },
    };
  };

  const handleCheckedAll = (_e: any, data: any) => {
    setCheckedIds(
      data.checked
        ? purchaseList.flatMap((item) => (!item.inactive ? [item.id] : []))
        : []
    );
  };

  const handleCloseModInfo = () => {
    props.setProp("PackagePurchaseSequence.packageInfo", null);
  };

  const handleConfirmDelete = () => {
    if (modDelete) {
      props.runSequence({
        sequence: "PackagePurchase",
        action: "DELETE",
        card: CARD_PURCHASE_LIST,
        data: { active: false, quantity: modDelete.quantity },
        packageId: modDelete.product,
        orderId: modDelete.id,
        onSuccess: handleCloseModDelete,
      });
    }
  };

  const handleCloseModDelete = () => {
    setModDelete(null);
  };

  const handlePayment = () => {
    props.runSequence({
      sequence: "PackagePurchase",
      action: "PAYMENT",
      card: CARD_PURCHASE_LIST,
      items: checkedItems,
    });
  };

  const handleCloseModPayment = async () => {
    await props.setProp("PackagePurchaseSequence.openCardPaying", false);

    props.setProp("PackagePurchaseSequence.invoiceItemByItems", []);

    props.setProp("BillPaymentSequence", null);
  };

  const handleSuccessPayment = async () => {
    await handleCloseModPayment();

    handleSearch();

    props.onEvent({
      message: "HandleGetPurchaseOrderList",
      params: {},
    });

    setCheckedIds([]);
  };

  console.log("CardPurchaseListUX", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`BillPaymentSequence.payErrorMessage`, null);
          props.setProp(`errorMessage.${CARD_BILLING_HISTORY}`, null);
        }}
        error={
          props.BillPaymentSequence?.payErrorMessage ||
          props.errorMessage?.[CARD_BILLING_HISTORY]
        }
        success={null}
      />

      <CardPurchaseListUX
        // data
        purchaseItems={purchaseItems}
        filterPurchaseList={filterPurchaseList}
        totalPayment={totalPayment}
        // options
        packageTypeOptions={props.masterOptions?.packageType}
        packageServiceTypeOptions={props.masterOptions?.packageServiceType}
        packageStatusOptions={PACKAGE_STATUS_OPTIONS}
        // callback
        onChangeValue={handleChangeValue}
        // table
        getTdProps={handleGetTdProps}
        // Element
        CheckAll={<Checkbox checked={isCheckAll} onChange={handleCheckedAll} />}
        SearchBoxPackageName={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Package"
            id="PL"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={filterPurchaseList.product || null}
            setSelectedItem={handleSelectedItem}
            onAdditionalUrlParams={() => ({ active: true })}
            // options
            mapOptions={mapPackageOptions}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_PURCHASE_LIST}_${BUTTON_ACTIONS.search}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_PURCHASE_LIST}_${BUTTON_ACTIONS.search}`
              ]
            }
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            size="medium"
            title="ค้นหา"
          />
        }
        ButtonPayment={
          showPayment && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handlePayment}
              // data
              paramKey={`${CARD_PURCHASE_LIST}_${BUTTON_ACTIONS.payment}`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[
                  `${CARD_PURCHASE_LIST}_${BUTTON_ACTIONS.payment}`
                ]
              }
              // config
              disabled={!checkedIds.length}
              color={"green"}
              name={BUTTON_ACTIONS.payment}
              size="medium"
              title={`ชำระเงิน${
                checkedIds.length ? ` (${checkedIds.length})` : ""
              }`}
            />
          )
        }
      />

      <ModPackageDescription
        open={!!props.PackagePurchaseSequence?.packageInfo}
        data={props.PackagePurchaseSequence?.packageInfo || null}
        onClose={handleCloseModInfo}
      />

      <ModConfirm
        openModal={!!modDelete}
        titleName="ยืนยันการลบแพ็กเกจ"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmDelete}
            // data
            paramKey={`${CARD_PURCHASE_LIST}_${BUTTON_ACTIONS.delete}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_PURCHASE_LIST}_${BUTTON_ACTIONS.delete}`
              ]
            }
            // config
            color={"green"}
            name={BUTTON_ACTIONS.delete}
            size="medium"
            title="ตกลง"
            basic={true}
          />
        }
        content={
          <div
            style={{
              margin: "-0.5rem 0rem -1rem 0rem",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          >
            “{modDelete?.product_name}”
          </div>
        }
        onDeny={handleCloseModDelete}
        onCloseWithDimmerClick={handleCloseModDelete}
      />

      <Modal
        closeOnDimmerClick={true}
        style={{ maxHeight: "80%", width: "80%", overflow: "auto" }}
        open={!!props?.PackagePurchaseSequence?.openCardPaying}
        onClose={handleCloseModPayment}
      >
        <CardMedicalFee
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          BillPaymentSequence={props.BillPaymentSequence}
          // data
          patientInfo={props.patientInfo}
          invoiceItemByItems={
            props.PackagePurchaseSequence?.invoiceItemByItems || []
          }
          invoiceHistoryItems={
            props.PackagePurchaseSequence?.invoiceItemByItems || []
          }
          // CommonInterface
          selectedEncounter={props.selectedEncounter}
          errorMessage={props.errorMessage}
          // config
          openPaying={true}
          simpleReceipt={true}
          // invoiceId={receiptId}
          orderBy={"Item"}
          // callback
          onSuccess={handleSuccessPayment}
          // // Bill Report Print
          // billReportPrint={props.billReportPrint}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardPurchaseList);
