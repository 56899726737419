import React from "react";
import PropTypes from "prop-types";
import * as Common from "../common";
import { Segment, Form, Input, Button } from "semantic-ui-react";
import PureReactTable from "../common/PureReactTable";
import ReactTableModifyPagination from "../common/ReactTableModifyPagination";

const CardSearchDialog = React.forwardRef((props, ref) => {
  const [limit, setLimit] = React.useState(40);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState(null);

  const isMounted = React.useRef(false);
  const inputRef = React.useRef();

  React.useEffect(() => {
    isMounted.current = true;
    getData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    getData();
  }, [currentPage]);

  const getData = async ({ searchText } = {}) => {
    setIsLoading(true);
    const [data, error] = await props.onGetData({
      searchText: searchText ? searchText : search,
      limit: limit,
      offset: currentPage * limit
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setPages(parseInt(data.total / limit) + 1);
        setData(data.items);
      }
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  return (
    <Segment raised className="defaultBackground" loading={isLoading}>
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={10}>
            <label>ค้นหา</label>
            <Input ref={inputRef} />
          </Form.Field>
          <Form.Field width={2}>
            <Button
              content="ค้นหา"
              fluid
              color="blue"
              onClick={() => {
                setCurrentPage(0);
                setSearch(inputRef.current.inputRef.current.value);
                if (!currentPage) {
                  getData({
                    searchText: inputRef.current.inputRef.current.value
                  });
                }
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <PureReactTable
        style={styles.table}
        minRows={10}
        pages={pages}
        page={currentPage}
        showPageJump={true}
        onPageChange={handlePageChange}
        PaginationComponent={ReactTableModifyPagination}
        onSelect={originalRow => setSelectedRow(originalRow)}
        selectedClassName="blueSelectedRow"
        onEnter={props.onSelectItem}
        selectedRow={selectedRow}
        columns={[
          {
            Header: "Code",
            accessor: props.codeField
          },
          {
            Header: "Name",
            accessor: props.nameField
          }
        ]}
        data={data}
        showPageSizeOptions={false}
      />
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={4}>
            <Button
              content="ยกเลิก"
              color="red"
              fluid
              onClick={props.onClose}
            />
            <Button
              content="เลือก"
              color="green"
              fluid
              onClick={() => props.onSelectItem(selectedRow)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
});

const styles = {
  table: {
    height: "400px"
  }
};

CardSearchDialog.defaultProps = {
  codeField: "code",
  nameField: "name",
  onClose: () => {},
  onSelectItem: () => {}
};

CardSearchDialog.propTypes = {
  codeField: PropTypes.string,
  nameField: PropTypes.string,
  onClose: PropTypes.func,
  onSelectItem: PropTypes.func
};

export default React.memo(CardSearchDialog);
