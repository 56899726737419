import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { Button, Container, Dropdown, Header } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

// UI
import { MenuItem } from "../common/CardPatientList";
import CardConsultDetail from "react-lib/apps/Scheduling/CardConsultDetail";
import CardConsultRequestListUX from "./CardConsultRequestListUX";
import CardConsultAccepttListUX from "./CardConsultAccepttListUX";
import CardConsultCancelDetail from "./CardConsultCancelDetail";

import {
  APPOINTMENT_STATUS,
  APPOINTMENT_STATUS_NAME,
  CONSULT_STATUS,
  CONSULT_STATUS_NAME,
} from "./sequence/ConsultationList";

const CARD_KEY: string = "CardConsultationList";
const CONSULT_TYPE: any = {
  REQUEST: "REQUEST",
  ACCEPT: "ACCEPT",
};

type CardConsultationListProps = {
  // function
  onEvent: any;
  setProp: any;
  forward?: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  django?: any;
  currentDoctorId?: number;
  searchedItemListWithKey?: Record<string, any>;

  // seq
  runSequence?: any;
  ConsultationListSequence?: any;

  // options
  searchedItemList?: any;
  consultData?: any;
  divisionList?: any;
  masterOptions?: any;

  // data
  patientId?: number | null;
  encounterId?: number | null;
  isWard?: boolean;
  showList?: boolean;
  selectedConsultOrder?: any;
  consultDetail?: any;
};

const styles = {
  not_found: {
    display: "grid",
    textAlign: "center",
    whiteSpace: "pre-line",
    lineHeight: "1.35",
    margin: "0 -0.75rem",
  } as CSSProperties,
};

const CardConsultationList = (props: CardConsultationListProps) => {
  const [tab, setTab] = useState<string>(CONSULT_TYPE.REQUEST);
  const [selectedConsult, setSelectedConsult] = useState<any>(null);
  const [selectedAppointment, setSelectedAppointment] = useState<any>({});
  const [consultDetailConfig, setConsultDetailConfig] = useState<any>({});
  const [openModCancelNote, setOpenModCancelNote] = useState<any>({
    open: false,
    item: null,
  });

  useEffect(() => {
    props.runSequence({ sequence: "ConsultationList", restart: true });
  }, []);

  useEffect(() => {
    if (tab === CONSULT_TYPE.ACCEPT && !props.showList) {
      setSelectedConsult({
        ...props.selectedConsultOrder,
        consult_id:
          props.selectedConsultOrder?.consult_id ||
          props.selectedConsultOrder?.id,
      });
    } else {
      hideConsultDetail();
    }
    handleSearch();
  }, [tab]);

  useEffect(() => {
    if (
      props.currentDoctorId === props.selectedConsultOrder?.consulted_doctor &&
      (props.selectedConsultOrder?.consult_id || props.selectedConsultOrder?.id)
    ) {
      setTab(CONSULT_TYPE.ACCEPT);
      setSelectedConsult({
        ...props.selectedConsultOrder,
        consult_id:
          props.selectedConsultOrder?.consult_id ||
          props.selectedConsultOrder?.id,
      });
    }
  }, [props.selectedConsultOrder]);

  useEffect(() => {
    if (props.consultDetail?.consult_id) {
      setSelectedAppointment({
        id: props.consultDetail?.appointment_id,
        patientAppointment: props.consultDetail?.patient_appointment_id,
        status: props.consultDetail?.app_status,
        patient_name: props.consultDetail?.patient_fullname,
        patient_hn: props.consultDetail?.hn,
      });

      let showSaveButton: boolean = false;
      if (
        props.consultDetail?.consult_status_label ===
        CONSULT_STATUS_NAME.FINISHED
      ) {
        showSaveButton = false;
      } else if (
        props.consultDetail?.en_type === "IPD" &&
        [parseInt(CONSULT_STATUS.ORDERED), parseInt(CONSULT_STATUS.REPORTED)].includes(
          parseInt(props.consultDetail?.status)
        )
      ) {
        showSaveButton = true;
      } else {
        showSaveButton =
          APPOINTMENT_STATUS.FINISHED === props.consultDetail?.app_status;
      }
      let config: any = {
        isSource: true,
        sourceReadOnly: true,
        isDestination: true,
        destinationReadOnly: true,
        showExtraButton: true,
        hideEditDateTimeButton: true,
        hideSaveButton: !(
          tab === CONSULT_TYPE.ACCEPT &&
          !props.consultDetail?.consult_response_id &&
          showSaveButton
        ),
        hideEditButton: !(
          tab === CONSULT_TYPE.ACCEPT &&
          props.consultDetail?.consult_response_id &&
          showSaveButton &&
          parseInt(props.consultDetail?.status) !==
            parseInt(CONSULT_STATUS.FINISHED)
        ),
        hideDestinationNote:
          props.consultDetail?.en_type === "IPD" &&
          CONSULT_STATUS_NAME.CANCELED !== props.consultDetail?.consult_status_label &&
          ![APPOINTMENT_STATUS.WAIT_CONFIRMED, APPOINTMENT_STATUS.CANCELED].includes(
            props.consultDetail?.app_status
          )
            ? false
            : props.consultDetail?.en_type !== "IPD" &&
              props.consultDetail?.app_status === APPOINTMENT_STATUS.FINISHED
            ? false
            : true,
        hideCancelButton:
          props.consultDetail?.app_status !== APPOINTMENT_STATUS.WAIT_CONFIRMED,
        hideFinishButton:
          !(selectedConsult?.en_type == "IPD" && props.consultDetail?.app_status === APPOINTMENT_STATUS.CONFIRMED)||
          props.consultDetail?.consult_status_label ===
            CONSULT_STATUS_NAME.FINISHED,
        hideReverseFinishButton:
          selectedConsult?.en_type !== "IPD" ||
          parseInt(props.consultDetail?.status) !==
            parseInt(CONSULT_STATUS.FINISHED),
      };
      if (
        tab === CONSULT_TYPE.REQUEST ||
        (tab === CONSULT_TYPE.ACCEPT && props.showList)
      ) {
        config = {
          ...config,
          hideCloseIcon: false,
          hideEditSourceNote: !(
            tab === CONSULT_TYPE.REQUEST &&
            props.consultDetail?.app_status ===
              APPOINTMENT_STATUS.WAIT_CONFIRMED 
          ),
          hideEditDestinationNote: !(
            tab === CONSULT_TYPE.ACCEPT &&
            showSaveButton &&
            ![
              parseInt(CONSULT_STATUS.CANCELED),
              parseInt(CONSULT_STATUS.FINISHED),
            ].includes(props.consultDetail?.status)
          ),
          hideActionBar:
            props.consultDetail?.app_status === APPOINTMENT_STATUS.CANCELED &&
            parseInt(props.consultDetail?.status) ===
              parseInt(CONSULT_STATUS.CANCELED),
          hideConfirmButton: !(
            tab === CONSULT_TYPE.ACCEPT &&
            props.consultDetail?.status === parseInt(CONSULT_STATUS.ORDERED) &&
            props.consultDetail?.app_status ===
              APPOINTMENT_STATUS.WAIT_CONFIRMED
          ),
        };
        setConsultDetailConfig(config);
      } else {
        config = {
          ...config,
          hideCloseIcon: true,
          hideEditSourceNote: true,
          hideEditDestinationNote: false,
          hideActionBar: false,
          hideConfirmButton:
            props.consultDetail?.app_status !==
            APPOINTMENT_STATUS.WAIT_CONFIRMED,
        };
        setConsultDetailConfig(config);
      }
    } else {
      hideConsultDetail();
    }
  }, [props.consultDetail]);

  const encounterType = useMemo(() => {
    return [
      { key: "OPD", value: "OPD", text: "OPD" },
      { key: "IPD", value: "IPD", text: "IPD" },
    ];
  }, []);

  const handleChangeRequestValue = (_event: any, data: any) => {
    props.setProp(
      `ConsultationListSequence.filterRequestList.${data.name}`,
      data.value
    );
  };

  const handleChangeAcceptValue = (_event: any, data: any) => {
    props.setProp(
      `ConsultationListSequence.filterAcceptList.${data.name}`,
      data.value
    );
  };

  const handleSearch = () => {
    const action: string = `SEARCH_${tab}`; // SEARCH_REQUEST || SEARCH_ACCEPT

    props.runSequence({
      sequence: "ConsultationList",
      action: action,
      patientId: props.patientId,
      encounterId: props.encounterId,
      cardKey: CARD_KEY,
      order_user:
        tab === CONSULT_TYPE.REQUEST && props.showList
          ? props.django?.user?.id
          : null,
    });
  };

  const handleConfirmAppointmentSuccess = () => {
    props.onEvent({
      message: "HandleInitialConsultData",
      params: {
        action: "INITIAL",
        consultId: selectedConsult?.consult_id || null,
      },
    });
    handleSearch();
  };

  const hideConsultDetail = () => {
    setSelectedConsult(null);
  };

  const mapProviderOptions = (items: any) => {
    return items.map((item: any) => ({
      key: item.provider_id,
      value:
        (item?.pre_name || "") +
        " " +
        item.first_name +
        " " +
        item.last_name +
        " [" +
        item.code +
        "]",
      text:
        (item?.pre_name || "") +
        " " +
        item.first_name +
        " " +
        item.last_name +
        " [" +
        item.code +
        "]",
    }));
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
      />

      <div
        style={{
          display: "flex",
          padding: "15px 15px 0 10px",
        }}
      >
        <MenuItem
          tab={tab}
          name={CONSULT_TYPE.REQUEST}
          title="รายการส่ง Consult"
          setTab={setTab}
        />
        <MenuItem
          tab={tab}
          name={CONSULT_TYPE.ACCEPT}
          title="รายการรับ Consult"
          setTab={setTab}
        />
      </div>

      {tab === CONSULT_TYPE.ACCEPT ? (
        <>
          {props.showList && (
            <CardConsultAccepttListUX
              // function
              onChangeValue={handleChangeAcceptValue}
              // options
              encounterType={encounterType}
              appointmentStatusOptions={
                props.ConsultationListSequence?.appointmentStatusOptions
              }
              consultStatusOptions={
                props.ConsultationListSequence?.consultStatusOptions
              }
              // data
              filterParams={props.ConsultationListSequence?.filterAcceptList}
              tableData={props.ConsultationListSequence?.consultAcceptList?.map(
                (item: any) => ({
                  ...item,
                  doctor_lbl: (
                    <>
                      {item.ordered_doctor_name === "-" ? (
                        <label style={{ color: "red" }}>ไม่ระบุแพทย์ </label>
                      ) : (
                        <>{item.ordered_doctor_name || ""} </>
                      )}
                      [{item.ordered_div_name || ""}]
                    </>
                  ),
                  app_status_lbl: (
                    <>
                      {item.app_status === APPOINTMENT_STATUS.WAIT_CONFIRMED ? (
                        APPOINTMENT_STATUS_NAME.WAIT_CONFIRMED
                      ) : item.app_status === APPOINTMENT_STATUS.FINISHED ? (
                        APPOINTMENT_STATUS_NAME.CONFIRMED
                      ) : item.app_status === APPOINTMENT_STATUS.CANCELED ? (
                        <Container textAlign="right">
                          <Button
                            size="mini"
                            icon="sticky note"
                            color="red"
                            onClick={() => {
                              setOpenModCancelNote({ open: true, item: item });
                            }}
                          />
                        </Container>
                      ) : (
                        item.app_status_label
                      )}
                    </>
                  ),
                  status_lbl: (
                    <>
                      {item.consult_status_label === "ยกเลิก"
                        ? "-"
                        : item.consult_status_label}
                    </>
                  ),
                  action: (
                    <>
                      {item.app_status !== APPOINTMENT_STATUS.CANCELED &&
                      item.consult_status_label !==
                        CONSULT_STATUS_NAME.FINISHED ? (
                        <Button
                          icon="pencil"
                          color="blue"
                          size="small"
                          onClick={() => {
                            setSelectedConsult(item);
                          }}
                        />
                      ) : (
                        <Button
                          icon="eye"
                          color="grey"
                          size="small"
                          onClick={() => {
                            setSelectedConsult(item);
                          }}
                        />
                      )}
                    </>
                  ),
                })
              )}
              // component
              patientSearchBox={
                <SearchBoxDropdown
                  onEvent={props.onEvent}
                  // config
                  type="HNFirstLast"
                  id="CLA"
                  style={{ width: "100%" }}
                  fluid={true}
                  useWithKey={true}
                  icon="search"
                  limit={20}
                  inline={true}
                  placeholder={"HN, First name, Last name"}
                  noResultsMessage={
                    <div style={styles.not_found}>
                      <span>ไม่พบข้อมูล</span>
                      <span>กรุณาทำการค้นหาใหม่อีกครั้ง</span>
                    </div>
                  }
                  // Select
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={
                    props.ConsultationListSequence?.filterAcceptList?.patient ||
                    null
                  }
                  iconStyle={{ marginTop: "-1rem" }}
                  setSelectedItem={(value) => {
                    props.setProp(
                      "ConsultationListSequence.filterAcceptList.patient",
                      value
                    );
                  }}
                />
              }
              divisionSearchBox={
                <Dropdown
                  className="inline-label"
                  value={
                    props.ConsultationListSequence?.filterAcceptList?.order_div
                      ?.id || ""
                  }
                  options={props.masterOptions?.division}
                  selection={true}
                  search={true}
                  fluid={true}
                  clearable={true}
                  // style
                  style={{ width: "100%" }}
                  onChange={(e, data) => {
                    props.setProp(
                      "ConsultationListSequence.filterAcceptList.order_div",
                      { id: data.value }
                    );
                  }}
                />
              }
              doctorSearchBox={
                <SearchBoxDropdown
                  onEvent={props.onEvent}
                  // config
                  type="Doctor"
                  id="CLA"
                  style={{ width: "100%" }}
                  fluid={true}
                  useWithKey={true}
                  icon="search"
                  limit={20}
                  inline={true}
                  noResultsMessage={
                    <div style={styles.not_found}>
                      <span>ไม่พบข้อมูล</span>
                      <span>กรุณาทำการค้นหาใหม่อีกครั้ง</span>
                    </div>
                  }
                  // Select
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={
                    props.ConsultationListSequence?.filterAcceptList
                      ?.order_doctor || null
                  }
                  iconStyle={{ marginTop: "-1rem" }}
                  setSelectedItem={(value) => {
                    props.setProp(
                      "ConsultationListSequence.filterAcceptList.order_doctor",
                      value
                    );
                  }}
                />
              }
              buttonSearch={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleSearch}
                  // data
                  paramKey={`${CARD_KEY}_SEARCH_ACCEPT`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${CARD_KEY}_SEARCH_ACCEPT`]
                  }
                  // config
                  color="blue"
                  title="ค้นหา"
                />
              }
            />
          )}
        </>
      ) : (
        <CardConsultRequestListUX
          // function
          onChangeValue={handleChangeRequestValue}
          // options
          encounterType={encounterType}
          appointmentStatusOptions={
            props.ConsultationListSequence?.appointmentStatusOptions
          }
          consultStatusOptions={
            props.ConsultationListSequence?.consultStatusOptions
          }
          // data
          filterParams={props.ConsultationListSequence?.filterRequestList}
          isCardQueue={props.showList}
          isWard={props.isWard}
          tableHeader={
            !props.showList && props.isWard
              ? `วันที่ส่งคำปรึกษา, วันที่นัดส่งปรึกษา, แพทย์ผู้ส่งปรึกษา, แพทย์/แผนก ผู้รับปรึกษา, ประเภท Encounter, สถานะการนัดหมาย, สถานะการ Consult, `
              : props.showList
              ? `วันที่ส่งคำปรึกษา, วันที่นัดส่งปรึกษา, ชื่อ-นามสกุล ผู้ป่วย, แพทย์/แผนก ผู้รับปรึกษา, ประเภท Encounter, สถานะการนัดหมาย, สถานะการ Consult, `
              : `วันที่ส่งคำปรึกษา, วันที่นัดส่งปรึกษา, แพทย์/แผนก ผู้รับปรึกษา, ประเภท Encounter, สถานะการนัดหมาย, สถานะการ Consult, `
          }
          tableKeys={
            !props.showList && props.isWard
              ? `created_lbl, app_date_lbl, user_order_lbl, consulted_doctor_lbl, en_type, app_status_lbl, status_lbl, action`
              : props.showList
              ? `created_lbl, app_date_lbl, patient_lbl, consulted_doctor_lbl, en_type, app_status_lbl, status_lbl, action`
              : `created_lbl, app_date_lbl, consulted_doctor_lbl, en_type, app_status_lbl, status_lbl, action`
          }
          tableData={props.ConsultationListSequence?.consultRequestList?.map(
            (item: any) => ({
              ...item,
              user_order_lbl: (
                <label
                  style={{
                    color:
                      item.user_order === props.django?.user?.id
                        ? "#0D71BB"
                        : "black",
                    fontWeight:
                      item.user_order === props.django?.user?.id
                        ? "bold"
                        : "normal",
                  }}
                >
                  {item.ordered_doctor_name}
                </label>
              ),
              consulted_doctor_lbl: (
                <>
                  {item.consulted_doctor_name === "-" ? (
                    <label style={{ color: "red" }}>ไม่ระบุแพทย์ </label>
                  ) : (
                    <>{item.consulted_doctor_name || ""} </>
                  )}
                  [{item.division_name || ""}]
                </>
              ),
              app_status_lbl: (
                <>
                  {item.app_status === APPOINTMENT_STATUS.WAIT_CONFIRMED
                    ? APPOINTMENT_STATUS_NAME.WAIT_CONFIRMED
                    : [
                        APPOINTMENT_STATUS.CONFIRMED,
                        APPOINTMENT_STATUS.FINISHED,
                      ].includes(item.app_status)
                    ? APPOINTMENT_STATUS_NAME.CONFIRMED
                    : item.app_status === APPOINTMENT_STATUS.CANCELED
                    ? APPOINTMENT_STATUS_NAME.CANCELED
                    : item.app_status_label}
                  {item.app_status === APPOINTMENT_STATUS.CANCELED && (
                    <Container textAlign="right">
                      <Button
                        size="mini"
                        icon="sticky note"
                        color="red"
                        onClick={() => {
                          setOpenModCancelNote({ open: true, item: item });
                        }}
                      />
                    </Container>
                  )}
                </>
              ),
              status_lbl: (
                <>
                  {item.consult_status_label === "ยกเลิก"
                    ? "-"
                    : item.consult_status_label}
                </>
              ),
              action: (
                <>
                  {![
                    CONSULT_STATUS_NAME.CANCELED,
                    CONSULT_STATUS_NAME.FINISHED,
                  ].includes(item.consult_status_label) &&
                  item.app_status === APPOINTMENT_STATUS.WAIT_CONFIRMED ? (
                    <Button
                      icon="pencil"
                      color="blue"
                      size="small"
                      onClick={() => {
                        setSelectedConsult(item);
                      }}
                    />
                  ) : (
                    <Button
                      icon="eye"
                      color="grey"
                      size="small"
                      onClick={() => {
                        setSelectedConsult(item);
                      }}
                    />
                  )}
                </>
              ),
            })
          )}
          // component
          patientSearchBox={
            <SearchBoxDropdown
              onEvent={props.onEvent}
              // config
              type="HNFirstLast"
              id="CLR"
              style={{ width: "100%" }}
              fluid={true}
              useWithKey={true}
              icon="search"
              limit={20}
              inline={true}
              placeholder={"HN, First name, Last name"}
              noResultsMessage={
                <div style={styles.not_found}>
                  <span>ไม่พบข้อมูล</span>
                  <span>กรุณาทำการค้นหาใหม่อีกครั้ง</span>
                </div>
              }
              // Select
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={
                props.ConsultationListSequence?.filterRequestList?.patient ||
                null
              }
              iconStyle={{ marginTop: "-1rem" }}
              setSelectedItem={(value) => {
                props.setProp(
                  "ConsultationListSequence.filterRequestList.patient",
                  value
                );
              }}
            />
          }
          divisionSearchBox={
            <Dropdown
              className="inline-label"
              value={
                props.ConsultationListSequence?.filterRequestList?.division
                  ?.id || ""
              }
              options={props.masterOptions?.division}
              selection={true}
              search={true}
              fluid={true}
              clearable={true}
              // style
              style={{ width: "100%" }}
              onChange={(e, data) => {
                props.setProp(
                  "ConsultationListSequence.filterRequestList.division",
                  { id: data.value }
                );
              }}
            />
          }
          doctorRequestSearchBox={
            <SearchBoxDropdown
              onEvent={props.onEvent}
              // config
              type="Doctor"
              id="CLRR"
              style={{ width: "100%" }}
              fluid={true}
              useWithKey={true}
              icon="search"
              limit={20}
              inline={true}
              noResultsMessage={
                <div style={styles.not_found}>
                  <span>ไม่พบข้อมูล</span>
                  <span>กรุณาทำการค้นหาใหม่อีกครั้ง</span>
                </div>
              }
              // Select
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={
                props.ConsultationListSequence?.filterRequestList
                  ?.order_doctor || null
              }
              iconStyle={{ marginTop: "-1rem" }}
              setSelectedItem={(value) => {
                props.setProp(
                  "ConsultationListSequence.filterRequestList.order_doctor",
                  value
                );
              }}
            />
          }
          doctorAcceptSearchBox={
            <SearchBoxDropdown
              onEvent={props.onEvent}
              // config
              type="Doctor"
              id="CLRA"
              style={{ width: "100%" }}
              fluid={true}
              useWithKey={true}
              icon="search"
              limit={20}
              inline={true}
              noResultsMessage={
                <div style={styles.not_found}>
                  <span>ไม่พบข้อมูล</span>
                  <span>กรุณาทำการค้นหาใหม่อีกครั้ง</span>
                </div>
              }
              disabled={
                props.ConsultationListSequence?.filterRequestList?.noDoctor
              }
              // Select
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={
                props.ConsultationListSequence?.filterRequestList
                  ?.consulted_doctor || null
              }
              iconStyle={{ marginTop: "-1rem" }}
              setSelectedItem={(value) => {
                props.setProp(
                  "ConsultationListSequence.filterRequestList.consulted_doctor",
                  value
                );
              }}
            />
          }
          buttonSearch={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSearch}
              // data
              paramKey={`${CARD_KEY}_SEARCH_REQUEST`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_KEY}_SEARCH_REQUEST`]
              }
              // config
              color="blue"
              title="ค้นหา"
            />
          }
        />
      )}

      {selectedConsult?.consult_id && (
        <div>
          <Header
            as="h3"
            style={{ color: "#2185D0", padding: "30px 0px 0px 30px" }}
          >
            รายละเอียดคำขอส่งผู้ป่วยปรึกษา
          </Header>
          <CardConsultDetail
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            onClose={hideConsultDetail}
            masterOptions={props.masterOptions}
            onSelectDateTime={() => {}}
            onConfirmAppointmentSuccess={handleConfirmAppointmentSuccess}
            onRefreshAppointment={handleSearch}
            forward={props.forward}
            // options
            divisionList={props.divisionList}
            mapProviderOptions={mapProviderOptions}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // data
            emrId={selectedConsult?.emr || null}
            refreshId={null}
            selectedConsultId={selectedConsult?.consult_id}
            selectedAppointment={selectedAppointment}
            consultData={props.consultData}
            consultDetail={props.consultDetail}
            config={consultDetailConfig}
          />
        </div>
      )}

      <CardConsultCancelDetail
        onCloseMod={() => setOpenModCancelNote({ open: false, item: null })}
        isOpen={openModCancelNote?.open}
        cancel_note={openModCancelNote?.item?.cancel_note || ""}
        cancel_user={openModCancelNote?.item?.cancel_user || ""}
      />
    </>
  );
};

CardConsultationList.displayName = "CardConsultationList";

export default React.memo(CardConsultationList);
