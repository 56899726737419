import React, { useState, useEffect, useCallback, SyntheticEvent } from "react";
import { Input, Modal } from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";

// UX
import CardDFPayrollGroupSearchBoxUX from "./CardDFPayrollGroupSearchBoxUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Utils
import moment from "moment";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";

// Types
type CardDFPayrollGroupSearchBoxProps = {
  setProp: (key: any, value: any, callback?: Function) => any;
  // data
  items?: Record<string, any>[];
  defaultId?: number | string;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onSearch: (data: SearchParams) => any;
  onSelected: (data: Record<string, any>) => any;
};

type FilterType = {
  createDate: string;
  isDate: boolean;
  year: string;
  isYear: boolean;
  lotNo: string;
  isLotNo: boolean;
};

export type SearchParams = {
  data: Partial<{ created_date: string; year: string; lot_no: string }>;
  btnAction: string;
};

const BUTTON_ACTIONS = {
  SEARCH: "SEARCH_DF_PAYROLL_GROUP",
};

const CARD_DF_PAYROLL_GROUP_SEARCH_BOX = "CardDFPayrollGroupSearchBox";

const ACTION_SEARCH = `${CARD_DF_PAYROLL_GROUP_SEARCH_BOX}_${BUTTON_ACTIONS.SEARCH}`;

const NOW_DATE = dateToStringWithoutTimeBE(moment());

const CardDFPayrollGroupSearchBox = (props: CardDFPayrollGroupSearchBoxProps) => {
  // Mod
  const [openModSearch, setOpenModSearch] = useState(false);

  const [filter, setFilter] = useState<Partial<FilterType>>({});
  const [selectRow, setSelectRow] = useState<Record<string, any> | null>(null);
  const [selectedLotNo, setSelectedLotNo] = useState<Record<string, any> | null>(null);

  // Callback Effect
  const handleSearch = useCallback(() => {
    const formatParams = (isKey: any, value: any) => {
      return ((filter as any)[isKey] || undefined) && value;
    };

    props.onSearch({
      data: {
        created_date: formatParams("isDate", filter.createDate),
        year: formatParams("isYear", filter.year),
        lot_no: formatParams("isLotNo", filter.lotNo),
      },
      btnAction: ACTION_SEARCH,
    });
  }, [filter]);

  // Effect
  useEffect(() => {
    if (openModSearch) {
      filter.createDate = NOW_DATE; // issue 67672 67692
      filter.isDate = true; // fix issue 67692
      handleSearch();
    }
  }, [openModSearch]);

  useEffect(() => {
    const data = props.items?.find((item) => item.id === props.defaultId);

    if (data) {
      setSelectedLotNo(data);
    }
  }, []);

  const handleSelected = useCallback((data: Record<string, any>) => {
    setSelectedLotNo(data);

    handleCloseModSearch();

    props.onSelected(data);
  }, []);

  const handleOpenModSearch = useCallback(() => {
    setOpenModSearch(true);
  }, []);

  const handleCloseModSearch = useCallback(() => {
    setOpenModSearch(false);
    setFilter({});

    props.setProp(`buttonLoadCheck.${ACTION_SEARCH}`, null);
  }, []);

  const handleChangeFilter = useCallback(
    (e: any, data: { name: keyof FilterType; value: string | boolean; checked?: boolean }) => {
      const value = typeof data.checked !== "undefined" ? data.checked : data.value;

      setFilter((filter) => ({ ...filter, [data.name]: value }));
    },
    []
  );

  const handleSelect = useCallback(() => {
    if (selectRow) {
      handleSelected(selectRow);
    }
  }, [selectRow, handleSelected]);

  const handleGetTrProps = useCallback(
    (state: any, rowInfo: RowInfo, column: Column) => {
      const original = rowInfo?.original;

      return {
        onClick: (e: SyntheticEvent) => {
          if (original) {
            setSelectRow(original);
          }
        },
        onDoubleClick: () => {
          if (original) {
            handleSelected(original);
          }
        },
        className: original?.id && original?.id === selectRow?.id ? "blueSelectedRow" : "",
      };
    },
    [selectRow]
  );

  const handleGetTdProps = useCallback((state: any, rowInfo: RowInfo, column: Column) => {
    return {
      style: { textAlign: "center" },
    };
  }, []);

  return (
    <div>
      <Input
        action={{ icon: "search", onClick: handleOpenModSearch }}
        value={selectedLotNo?.lot_no || ""}
        readOnly={true}
        fluid={true}
        onClick={handleOpenModSearch}
      />

      <Modal open={openModSearch} closeOnDimmerClick={true} onClose={handleCloseModSearch}>
        <CardDFPayrollGroupSearchBoxUX
          // data
          data={props.items}
          filter={filter}
          // config
          disabledSelect={!selectRow}
          // callback
          onChangeFilter={handleChangeFilter}
          onClickClose={handleCloseModSearch}
          onSelect={handleSelect}
          // table
          onGetTrProps={handleGetTrProps}
          onGetTdProps={handleGetTdProps}
          // Element
          ButtonSearch={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSearch}
              // data
              paramKey={ACTION_SEARCH}
              buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
              // config
              color={"blue"}
              name={BUTTON_ACTIONS.SEARCH}
              size="small"
              title="ค้นหา"
            />
          }
        />
      </Modal>
    </div>
  );
};

CardDFPayrollGroupSearchBox.displayName = "CardDFPayrollGroupSearchBox";

export default React.memo(CardDFPayrollGroupSearchBox);
