import React, { useEffect, useMemo, useState } from "react";
// UI Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBox from "react-lib/appcon/common/SearchBox";
// UI ORM
import CardORCaseListUX from "./CardORCaseListUX";
// UI TPD
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";

const CARD_KEY: string = "CardORCaseList";

type CardORCaseListProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  ORCaseListSequence?: any;

  // controller
  drugOrderQueueController?: any;

  // SearchBox
  searchedItemList?: any;
};

const CardORCaseList = (props: CardORCaseListProps) => {
  const [showStatistic, setShowStatistic] = useState<boolean>(false);

  useEffect(() => {
    props.runSequence({ sequence: "ORCaseList", restart: true });
  }, []);

  const locationOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.masterOptions?.orLocation || []),
    ];
  }, [props.masterOptions?.orLocation]);

  const roomOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.ORCaseListSequence?.roomOptions || []),
    ];
  }, [props.ORCaseListSequence?.roomOptions]);

  // ----- filter
  const handleChangePatient = (
    id: any,
    hn: string,
    full_name: string,
    birthdate: string
  ) => {
    props.runSequence({
      sequence: "ORCaseList",
      action: "SET_FILTER",
      data: { emr__encounter__patient__hn: hn, patientFullName: full_name },
    });
  };

  const handleSelectedDoctor = (item: any) => {
    props.runSequence({
      sequence: "ORCaseList",
      action: "SET_FILTER",
      data: { selectedDoctor: item, chief_surgeon: item?.id || null },
    });
  };

  const handleChangeLocation = (_event: any, data: any) => {
    props.runSequence({
      sequence: "ORCaseList",
      action: "FILTER_LOCATION_AND_FETCH_ROOM_ITEM",
      location: data.value,
    });
  };

  const handleChangeFilter = (_event: any, data: any) => {
    let value: any = data.value;
    if (data.type === "checkbox") {
      value = data.checked;
    }
    props.runSequence({
      sequence: "ORCaseList",
      action: "SET_FILTER",
      data: { [data.name]: value },
    });
  };

  // button
  const handleSearch = () => {
    setShowStatistic(false);
    props.runSequence({
      sequence: "ORCaseList",
      action: "FETCH",
      cardKey: CARD_KEY,
    });
  };

  const handleSearchStat = () => {
    setShowStatistic(true);
    props.runSequence({
      sequence: "ORCaseList",
      action: "FETCH_STAT",
      cardKey: CARD_KEY,
    });
  };

  const handleClear = () => {
    props.runSequence({ sequence: "ORCaseList", action: "CLEAR_FILTER" });
  };

  const handlePrint = () => {
    props.runSequence({
      sequence: "ORCaseList",
      action: "PRINT",
      cardKey: CARD_KEY,
    });
  };

  const handlePrintStat = () => {
    props.runSequence({
      sequence: "ORCaseList",
      action: "PRINT_STAT",
      cardKey: CARD_KEY,
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
      />

      <CardORCaseListUX
        // function
        onChangeLocation={handleChangeLocation}
        onChangeFilter={handleChangeFilter}
        onClear={handleClear}
        // options
        locationOptions={locationOptions}
        roomOptions={roomOptions}
        // seq
        ORCaseListSequence={props.ORCaseListSequence}
        // data
        showStatistic={showStatistic}
        // component
        patientSearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            defaultValue={
              props.ORCaseListSequence?.orFilter?.emr__encounter__patient__hn ||
              ""
            }
            // inputStyle={{ width: "150px", marginRight: "35px" }}
            disabled={!props.ORCaseListSequence?.orFilter?.checkedHn}
            onEnterPatientSearch={handleChangePatient}
          />
        }
        doctorSearchBox={
          <SearchBox
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={
              props.ORCaseListSequence?.orFilter?.selectedDoctor || null
            }
            setSelectedItem={handleSelectedDoctor}
            type="Doctor"
            toDisplay={(item: any) => item.name_code}
            disabled={!props.ORCaseListSequence?.orFilter?.checkedDoctor}
            style={{ width: "100%" }}
            fluid={true}
          />
        }
        startDateTextBox={
          <DateTextBox
            value={props.ORCaseListSequence?.orFilter?.start_date}
            // fluid={true}
            // inputStyle={{ width: "130px" }}
            disabled={!props.ORCaseListSequence?.orFilter?.checkedDate}
            onChange={(date: string) => {
              handleChangeFilter(null, { name: "start_date", value: date });
            }}
          />
        }
        endDateTextBox={
          <DateTextBox
            value={props.ORCaseListSequence?.orFilter?.end_date}
            // fluid={true}
            // inputStyle={{ width: "130px" }}
            disabled={!props.ORCaseListSequence?.orFilter?.checkedDate}
            onChange={(date: string) => {
              handleChangeFilter(null, { name: "end_date", value: date });
            }}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_KEY}_FETCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_FETCH`]}
            // config
            color="blue"
            size="small"
            title="ค้นหา"
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrint}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="green"
            disabled={
              !(
                !showStatistic &&
                props.ORCaseListSequence?.caseList?.items?.length > 0
              )
            }
            size="small"
            title="พิมพ์รายงาน"
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
        buttonStat={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearchStat}
            // data
            paramKey={`${CARD_KEY}_FETCH_STAT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_FETCH_STAT`]}
            // config
            color="orange"
            size="small"
            title="สถิติ"
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
        buttonPrintStat={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrintStat}
            // data
            paramKey={`${CARD_KEY}_PRINT_STAT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT_STAT`]}
            // config
            color="green"
            disabled={
              !(
                showStatistic &&
                props.ORCaseListSequence?.statCaseList?.length > 0
              )
            }
            size="small"
            title="พิมพ์รายงานสถิติ"
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
      />
    </>
  );
};

export default React.memo(CardORCaseList);
