import React, { useState, useEffect, useMemo, useRef } from "react";
import { Button } from "semantic-ui-react";

import moment from "moment";

// Framework
import Tab from "react-lib/frameworks/Tab";

// UX
import CardReportPatientIPDList from "./CardReportPatientIPDList";
import CardAnesDoctorFeeList from "react-lib/apps/HISV3/ANE/CardAnesDoctorFeeList";
import CardFoodReport from "react-lib/apps/HISV3/FOD/CardFoodReport";
import CardORQueue from "react-lib/apps/HISV3/ORM/CardORQueue";
import CardORCancelList from "react-lib/apps/HISV3/ORM/CardORCancelList";
import CardORCaseList from "react-lib/apps/HISV3/ORM/CardORCaseList";
import CardMedicationErrorList from "../TPD/CardMedicationErrorList";
import CardDrugResuscitation from "../TPD/CardDrugResuscitation";
import CardReportDrugUseRecord from "../TPD/CardReportDrugUseRecord";
import CardReserveBedQueue from "./CardReserveBedQueue";
import CardBed from "react-lib/apps/HISV3/ADM/CardBed";
import CardInpatientDiagnosis from "react-lib/apps/HISV3/ADM/CardInpatientDiagnosis";
import CardWardQueue from "react-lib/apps/HISV3/ADM/CardWardQueue";
import CardReportPatientList from "./CardReportPatientList";
import CardAnesthesiaStatReport from "./CardAnesthesiaStatReport";

// Utils
import { beToAd } from "react-lib/utils/dateUtils";

import CONFIG from "config/config";

const CardReportQueue = (props: any) => {
  console.log("CardReportQueue props: ", props);

  const patientInfo = useMemo(() => {
    const data = props.SetBedSequence?.selectedReserveBedQueue || {};
    const birthdate = data.patient_birthdate;
    const a = moment();
    const b = moment(beToAd(birthdate));
    const diff = a.diff(b, "years");

    return {
      hn: data.hn,
      full_name_th: data.patient_name,
      full_name: data.patient_name,
      gender: data.patient_gender,
      birthdate,
      age: diff,
      encounter_no: data.encounter,
      encounter_id: data.encounter,
      id: data.patient,
    };
  }, [props.SetBedSequence?.selectedReserveBedQueue]);

  return (
    <div style={{ height: "90vh", paddingTop: "5px" }}>
      <Tab
        tabWidth="150px"
        data={[
          {
            key: "รายการอาหาร",
            name: "รายการอาหาร",
            hide: !["หอผู้ป่วย", "หอผู้ป่วยพรีเมี่ยม"].includes(props.divisionType),
            render: () => (
              <CardFoodReport
                runSequence={props.runSequence}
                FoodListSequence={props.FoodListSequence}
                FoodRequestSequence={props.FoodRequestSequence}
                // function
                onEvent={props.handleEvent}
                setProp={props.setProp}
                // CommonInterface
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                buttonLoadCheck={props.buttonLoadCheck}
                DJANGO={props.django}
                // options
                foodRequestOptions={props.foodRequestOptions}
                masterOptions={props.masterOptions}
              />
            ),
          },
          {
            key: "ตารางผ่าตัด",
            name: "ตารางผ่าตัด",
            render: () => (
              <CardORQueue
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                forward={props.forward}
                // common
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                buttonLoadCheck={props.buttonLoadCheck}
                // seq
                runSequence={props.runSequence}
                ORQueueSequence={props.ORQueueSequence}
                ZoneLogSequence={props.ZoneLogSequence}
                ORRequestSequence={props.ORRequestSequence}
                // controller
                drugOrderQueueController={props.drugOrderQueueController}
                proxyController={props.proxyController}
                // SearchBox
                searchedItemList={props.searchedItemList}
                searchedItemListWithKey={props.searchedItemListWithKey}
                // options
                masterOptions={props.masterOptions}
                masterData={props.masterData}
                // data
                selectedOrOrder={props.selectedOrOrder}
                loadingStatus={props.loadingStatus}
                userTokenize={props.userTokenize}
                divisionId={props.divisionId}
                // config={props.config}
                config={{
                  showFilterORQueue: true,
                  showFilterDrugQueue: false,
                }}
                // style
                cardStyle={{ width: "calc(100vw - 15.5rem)" }}
              />
            ),
          },
          {
            key: "รายงานการยกเลิกการผ่าตัด",
            name: "รายงานการยกเลิกการผ่าตัด",
            render: () => (
              <CardORCancelList
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // CommonInterface
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                buttonLoadCheck={props.buttonLoadCheck}
                // options
                masterOptions={props.masterOptions}
                // seq
                runSequence={props.runSequence}
                ORCancelListSequence={props.ORCancelListSequence}
                // controller
                drugOrderQueueController={props.drugOrderQueueController}
                // SearchBox
                searchedItemList={props.searchedItemList}
              />
            ),
          },
          {
            key: "รายงานสถิติการใช้บริการห้องผ่าตัด",
            name: "รายงานสถิติการใช้บริการห้องผ่าตัด",
            render: () => (
              <CardORCaseList
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // CommonInterface
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                buttonLoadCheck={props.buttonLoadCheck}
                // options
                masterOptions={props.masterOptions}
                // seq
                runSequence={props.runSequence}
                ORCaseListSequence={props.ORCaseListSequence}
                // controller
                drugOrderQueueController={props.drugOrderQueueController}
                // SearchBox
                searchedItemList={props.searchedItemList}
              />
            ),
          },
          {
            key: "รายงานค่าธรรมเนียมวิสัญญีแพทย์",
            name: "รายงานค่าธรรมเนียมวิสัญญีแพทย์",
            hide: CONFIG.HIDE_DOCTOR_FEE_ALL_MENU, // 67071 hide 
            render: () => (
              <CardAnesDoctorFeeList
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // CommonInterface
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                buttonLoadCheck={props.buttonLoadCheck}
                // seq
                runSequence={props.runSequence}
                AnesDoctorFeeListSequence={props.AnesDoctorFeeListSequence}
                // SearchBox
                searchedItemList={props.searchedItemList}
              />
            ),
          },
          {
            key: "รายงาน Med Error",
            name: "รายงาน Med Error",
            hide: !props.django?.user?.role_types?.find((role: string) =>
              ["DOCTOR", "REGISTERED_NURSE"].includes(role)
            ),
            render: () => (
              <CardMedicationErrorList
                onEvent={props.onEvent}
                setProp={props.setProp}
                // seq
                runSequence={props.runSequence}
                // options
                masterOptions={props.masterOptions}
                // data
                django={props.django}
                MedErrorListSequence={props.MedErrorListSequence}
                userId={props.django?.user?.id || null}
                selectedDivision={props.selectedDivision}
                // CommonInterface
                buttonLoadCheck={props.buttonLoadCheck}
                searchedItemListWithKey={props.searchedItemListWithKey}
              />
            ),
          },
          {
            key: "กล่องยากู้ชีพฉุกเฉิน",
            name: "กล่องยาสำรอง",
            render: () => (
              <CardDrugResuscitation
                // function
                django={props.django}
                onEvent={props.onEvent}
                setProp={props.setProp}
                controller={props.drugOrderHistoryController}
                // CommonInterface
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                loadingStatus={props?.loadingStatus}
                buttonLoadCheck={props.buttonLoadCheck}
                masterOptions={props.masterOptions}
                // seq
                runSequence={props.runSequence}
                DrugResuscitationSequence={props.DrugResuscitationSequence}
                isNurseScreen={true}
              />
            ),
          },
          {
            key: "รายงานการใช้/ทิ้ง ยาเสพติด",
            name: "รายงานการใช้/ทิ้ง ยาเสพติด",
            render: () => (
              <CardReportDrugUseRecord
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // CommonInterface
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                buttonLoadCheck={props.buttonLoadCheck}
                // options
                masterOptions={props.masterOptions}
                masterData={props.masterData}
                // seq
                runSequence={props.runSequence}
                ReportDrugUseRecordSequence={props.ReportDrugUseRecordSequence}
              />
            ),
          },
          // {
          //   key: "รายงานสถิติผู้ป่วยในแยกตามประเภท",
          //   name: "รายงานสถิติผู้ป่วยในแยกตามประเภท",
          //   hide: !["หอผู้ป่วย"].includes(props.divisionType),
          //   render: () => (
          //     <CardReportPatientIPDList
          //       // function
          //       onEvent={props.onEvent}
          //       setProp={props.setProp}
          //       // CommonInterface
          //       errorMessage={props.errorMessage}
          //       successMessage={props.successMessage}
          //       buttonLoadCheck={props.buttonLoadCheck}
          //       // options
          //       masterOptions={props.masterOptions}
          //       // seq
          //       runSequence={props.runSequence}
          //       ReportPatientIPDListSequence={
          //         props.ReportPatientIPDListSequence
          //       }
          //     />
          //   ),
          // },
          {
            key: "รายงานสถิติผู้ป่วยนอก/ใน แยกตามประเภท",
            name: "รายงานสถิติผู้ป่วยนอก/ใน แยกตามประเภท",
            hide: !["หอผู้ป่วย", "หอผู้ป่วยพรีเมี่ยม"].includes(props.divisionType),
            render: () => (
              <CardReportPatientList
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // seq
                runSequence={props.runSequence}
                ReportPatientListSequence={props.ReportPatientListSequence}
                // controller
                drugOrderQueueController={props.drugOrderQueueController}
                // CommonInterface
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                buttonLoadCheck={props.buttonLoadCheck}
                searchedItemListWithKey={props.searchedItemListWithKey}
                // options
                masterOptions={props.masterOptions}
              />
            ),
          },
          {
            key: "รายงานสถิติวันนอนเฉลี่ยใน แต่ละ Diagnosis (สำหรับผู้ป่วยใน)",
            name: "รายงานสถิติวันนอนเฉลี่ยใน แต่ละ Diagnosis (สำหรับผู้ป่วยใน)",
            render: () => (
              <CardInpatientDiagnosis
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // CommonInterface
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                buttonLoadCheck={props.buttonLoadCheck}
                // seq
                runSequence={props.runSequence}
                InpatientDiagnosisSequence={props.InpatientDiagnosisSequence}
                // SearchBox
                searchedItemList={props.searchedItemList}
              />
            ),
          },
          {
            key: "รายชื่อผู้ป่วยในหออภิบาล",
            name: "รายชื่อผู้ป่วยในหออภิบาล",
            hide: !["หอผู้ป่วย", "หอผู้ป่วยพรีเมี่ยม"].includes(props.divisionType),
            render: () => (
              <CardWardQueue
                onEvent={props.onEvent}
                layout={props.layout}
                forward={props?.forward}
                setProp={props.setProp}
                searchedItemList={props.searchedItemList}
                masterOptions={props.masterOptions}
                runSequence={props.runSequence}
                drugOrderQueueController={props.drugOrderQueueController}
                // data
                WardQueueSequence={props.WardQueueSequence}
                AdmitChangeSequence={props.AdmitChangeSequence}
                SetBedSequence={props.SetBedSequence}
                modAdmit={props.modAdmit}
                intraTransferForm={props.intraTransferForm}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                buttonLoadCheck={props.buttonLoadCheck}
                intraHospitalTransferOptions={
                  props.intraHospitalTransferOptions
                }
                loadingStatus={props.loadingStatus}
                selectVitalSignLoading={props.selectVitalSignLoading}
                getEncounterVitalSignListErr={
                  props.getEncounterVitalSignListErr
                }
                encounterVitalSignList={props.encounterVitalSignList}
                django={props.django}
                // Report
                isReport={true}
                // labOrderQueue={props.labOrderQueue}
                // selectedLabOrderWorking={props.selectedLabOrderWorking}
              />
            ),
          },
          {
            key: "รายชื่อผู้ป่วยที่จองห้องพัก / เตียงผู้ป่วยใน",
            name: "รายชื่อผู้ป่วยที่จองห้องพัก / เตียงผู้ป่วยใน",
            hide: !["หอผู้ป่วย", "หอผู้ป่วยพรีเมี่ยม"].includes(props.divisionType),
            render: () => (
              <CardReserveBedQueue
                key="รายชื่อผู้ป่วยที่จองห้องพัก / เตียงผู้ป่วยใน"
                // report
                showPrintReserveBedReportBtn={true}
                hideBtnCardBed={true}
                hiddenCardBedAction={true}
                // controller
                drugOrderQueueController={props.drugOrderQueueController}
                crdAdmitOrderController={props.crdAdmitOrderController}
                subICD10DetailController={props.subICD10DetailController}
                printListController={props.printListController}
                scannedDocumentController={props.scannedDocumentController}
                // callback
                setProp={props.setProp}
                onEvent={props.onEvent}
                runSequence={props.runSequence}
                // data
                masterOptions={props.masterOptions}
                SetBedSequence={props.SetBedSequence}
                filterReserveBedQueue={
                  props.SetBedSequence?.filterReserveBedQueue || {}
                }
                django={props.django}
                eocIsLoading={props.eocIsLoading}
                successMessage={props.successMessage}
                errorMessage={props.errorMessage}
              />
            ),
          },
          {
            key: "รายงานเตียง",
            name: "รายงานเตียง",
            hide: !["หอผู้ป่วย", "หอผู้ป่วยพรีเมี่ยม"].includes(props.divisionType),
            render: () => (
              <CardBed
                // callback
                setProp={props.setProp}
                onEvent={props.onEvent}
                runSequence={props.runSequence}
                masterOptions={props.masterOptions}
                // data
                SetBedSequence={props.SetBedSequence}
                patientInfo={patientInfo}
                hideAdmitOrder={true}
                successMessage={props.successMessage}
                errorMessage={props.errorMessage}
                reserveCode={
                  props.SetBedSequence?.selectedReserveBedQueue?.code || ""
                }
                // controller
                drugOrderQueueController={props.drugOrderQueueController}
                // ปุ่มพิมพ์ใน รายงานเตียง
                showPrintBtn={true}
                hiddenCardBedAction={true}
              />
            ),
          },
          {
            key: "งานการยกเลิกการจองเตียง/จองห้อง",
            name: "งานการยกเลิกการจองเตียง/จองห้อง",
            hide: !["หอผู้ป่วย", "หอผู้ป่วยพรีเมี่ยม"].includes(props.divisionType),
            render: () => (
              <CardReserveBedQueue
                // report
                key="งานการยกเลิกการจองเตียง/จองห้อง"
                showPrintReserveBedReportBtn={true}
                hideBtnCardBed={true}
                defaultCancel={true}
                hiddenCardBedAction={true}
                // controller
                drugOrderQueueController={props.drugOrderQueueController}
                crdAdmitOrderController={props.crdAdmitOrderController}
                subICD10DetailController={props.subICD10DetailController}
                printListController={props.printListController}
                scannedDocumentController={props.scannedDocumentController}
                // callback
                setProp={props.setProp}
                onEvent={props.onEvent}
                runSequence={props.runSequence}
                // data
                masterOptions={props.masterOptions}
                SetBedSequence={props.SetBedSequence}
                filterReserveBedQueue={
                  props.SetBedSequence?.filterReserveBedQueue || {}
                }
                django={props.django}
                eocIsLoading={props.eocIsLoading}
                successMessage={props.successMessage}
                errorMessage={props.errorMessage}
              />
            ),
          },
          {
            key: "รายงาน/สถิติ งานวิสัญญี",
            name: "รายงาน/สถิติ งานวิสัญญี",
            render: () => (
              <CardAnesthesiaStatReport
                // Function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // CommonInterface
                buttonLoadCheck={props.buttonLoadCheck}
                // Sequence
                runSequence={props.runSequence}
                AnesthesiaStatReportSequence={props.AnesthesiaStatReportSequence}
              />
            ),
          },
        ].filter((item) => !item.hide)}
      />
    </div>
  );
};

export default React.memo(CardReportQueue);
