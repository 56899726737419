import React, { useCallback, useEffect, useState } from "react";

import { Modal } from "semantic-ui-react";

import moment from "moment";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import CardInputDateForCreateUX from "./CardInputDateForCreateUX";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

// Types
type ModInputDateForCreateProps = {
  onEvent: (e: any) => any;
  setProp: (key: any, value: any, callback?: () => any) => any;
  // data
  open: boolean;
  coveragePayer?: ARData["coveragePayer"];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  // options
  coveragePayerOptions: Record<string, any>[];
  // callback
  onClose: () => any;
  onSave: SaveEventHandler;
};

export type SaveEventHandler = (data: { data: ARData; btnAction: string }) => any;

type ARData = {
  date: string;
  coveragePayer: string | null | number;
};

const DataInitial = {
  date: "",
  coveragePayer: "",
};

const BUTTON_ACTIONS = {
  SAVE: "create_ar",
};

const CARD_INPUT_DATE_FOR_CREATE = "CardInputDateForCreate";

const ACTION_SAVE = `${CARD_INPUT_DATE_FOR_CREATE}_${BUTTON_ACTIONS.SAVE}`;

const ModInputDateForCreate = (props: ModInputDateForCreateProps) => {
  const [arDetail, setARDetail] = useState<ARData>(DataInitial);

  // Effect
  useEffect(() => {
    if (props.open) {
      setARDetail({ date: formatDate(moment()), coveragePayer: props.coveragePayer || null });
    }
  }, [props.open]);

  // Callback
  const handleSave = useCallback(() => {
    props.onSave({
      data: arDetail,
      btnAction: ACTION_SAVE,
    });
  }, [arDetail]);

  const handleCloseModMessage = useCallback(() => {
    props.setProp(`errorMessage.${ACTION_SAVE}`, null);
    props.setProp(`successMessage.${ACTION_SAVE}`, null);
  }, []);

  return (
    <>
      {props.errorMessage?.[ACTION_SAVE] === "error_occurs" ? (
        <ModInfo
          open
          titleColor={"red"}
          onApprove={handleCloseModMessage}
          onClose={handleCloseModMessage}
        >
          <div style={{ padding: "1rem 0", fontWeight: "bold" }}>
            <div>พบข้อผิดพลาดในการสร้างข้อมูลส่งเบิก</div>
          </div>
        </ModInfo>
      ) : (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={handleCloseModMessage}
          error={props.errorMessage?.[ACTION_SAVE]}
          success={null}
        />
      )}

      {props.successMessage?.[ACTION_SAVE] === "create_successful" && (
        <ModInfo
          open
          titleColor={"green"}
          onApprove={handleCloseModMessage}
          onClose={handleCloseModMessage}
        >
          <div style={{ padding: "1rem 0", fontWeight: "bold" }}>
            <div>สร้างข้อมูลส่งเบิกสำเร็จ</div>
          </div>
        </ModInfo>
      )}

      <Modal open={props.open} closeOnDimmerClick={false} size="small">
        <CardInputDateForCreateUX
          // data
          date={arDetail.date}
          coveragePayer={arDetail.coveragePayer}
          // options
          coveragePayerOptions={props.coveragePayerOptions}
          // callback
          onChangeDate={(date: string) => {
            setARDetail({ ...arDetail, date });
          }}
          onCoveragePayerChange={(e: any, data: any) => {
            setARDetail({ ...arDetail, coveragePayer: data.value });
          }}
          onClose={props.onClose}
          // Component
          buttonConfirm={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              paramKey={ACTION_SAVE}
              buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SAVE]}
              // config
              color={"green"}
              name={BUTTON_ACTIONS.SAVE}
              size="medium"
              title={"บันทึก"}
            />
          }
        />
      </Modal>
    </>
  );
};

ModInputDateForCreate.displayName = "ModInputDateForCreate";

export default React.memo(ModInputDateForCreate);
