import React, { useEffect, useState, useMemo, useCallback, CSSProperties } from "react";
import { Icon, Button, Dropdown, Modal, Checkbox, Input } from "semantic-ui-react";

// UX
import CardLabRequestUX from "./CardLabRequestUX";
import CardLabGroupUX from "./CardLabGroupUX";

// Common
import { ErrorMessage, ModAuthen, ModConfirm } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";

// Interface
import { checkAllowEditOrder } from "./sequence/OrderCentralLab";

// Config
import config from "config/config";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModError from "react-lib/appcon/common/ModInfo";
import { formatDate } from "react-lib/utils/dateUtils";

// Types
type CardLabRequestProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  buttonLoadCheck?: any;
  // seq
  runSequence: any;
  OrderCentralLabSequence?: Record<string, any> | null;
  // CommonInterface
  selectedEmr?: Record<string, any>;
  selectedProgressCycle?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  selectedEncounter?: Record<string, any>;
  loadingStatus?: Record<string, any>;
  django?: any;
  isStudentUser?: boolean;
  // data
  masterOptions?: Record<string, any>;
  divisionType?: string | null;
  doctorLabOrderList?: { items: any[] };
  userLabPermission?: Record<string, any>;
  medReconcileCheck?: Record<string, any>;
  medReconcileIndex?: any;
  // style
  saveLabOrderStyle?: CSSProperties;
  doctorLabOrderTableStyle?: CSSProperties;
  // options
  divisionOpdOptions?: any[];
  // callback
  onSuccess?: () => any;
  // config
  isRestart?: boolean;
  isNullEmr?: boolean;
  checkOutPerformDiv?: boolean;
  editId?: number | null;
  isBloodBank?: boolean;
  isAppointment?: boolean;
  hideActionButton?: boolean;
  hidePreOrder?: boolean;
};

// Const
const HOST = `${config.API_HOST}`;

const URGENCY_OPTIONS = [
  { key: "ROUTINE", value: "ROUTINE", text: "ROUTINE" },
  { key: "STAT", value: "STAT", text: "STAT" },
  {
    key: "FAST_TRACK",
    value: "FAST_TRACK",
    text: "FAST_TRACK",
  },
];

const CARD_LAB_REQUEST = "CardLabRequest";

const CardLabRequest = (props: CardLabRequestProps) => {
  const [openLabGroup, setOpenLabGroup] = useState(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [modDelete, setModDelete] = useState<Record<string, any> | null>(null);

  const [isOrderTime, setIsOrderTime] = useState(false);

  const [appointmentDate, setAppointmentDate] = useState<string>("");
  const [performDivBloodBank, setPerformDivBloodBank] = useState<any>(null);
  const [completed, setCompleted] = useState(false);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [openModAuthen, setOpenModAuthen] = useState<any>({
    open: false,
    isApproveOrder: false,
  });

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "OrderCentralLab",
      restart: props.isRestart,
      isNullEmr: props.isNullEmr,
      isOutPerformDiv: props.checkOutPerformDiv,
      editId: props.editId,
    });
  }, []);

  useEffect(() => {
    if (props.successMessage?.["CardLabRequest_education_approve"]) {
      setOpenModAuthen({ open: false, isApproveOrder: false });
      props.setProp(`successMessage.${"CardLabRequest_education_approve"}`, null);
    }
  }, [props.successMessage?.["CardLabRequest_education_approve"]]);

  useEffect(() => {
    if (props.divisionOpdOptions?.length) {
      const labDivision = props.divisionOpdOptions.find((item: any) =>
        item.text.includes("ห้องแลป")
      );

      if (labDivision) {
        setPerformDivBloodBank(labDivision.value);
      } else {
        setPerformDivBloodBank(null);
      }
    }
    // console.log("divisionOpdOptions: ", props.divisionOpdOptions)
  }, [props.divisionOpdOptions]);

  useEffect(() => {
    if (!!props.selectedEmr || !!props.selectedProgressCycle) {
      props.onEvent({
        message: "GetDoctorLabOrderList",
        params: {
          progression_cycle: props.selectedProgressCycle?.id || null,
          emr: props.selectedEmr?.id || null,
        },
      });
    }
  }, [props.selectedEmr, props.selectedProgressCycle]);

  useEffect(() => {
    setIsOrderTime(props.OrderCentralLabSequence?.labOrder?.isOrderTime || false);
  }, [props.OrderCentralLabSequence?.labOrder?.isOrderTime]);

  useEffect(() => {
    if (props.OrderCentralLabSequence?.duplicateLabDetected === true) {
      setOpenConfirm(true);
    }
  }, [props.OrderCentralLabSequence?.duplicateLabDetected]);

  useEffect(() => {
    if (props.checkOutPerformDiv) {
      return;
    }

    if (
      props.errorMessage?.OrderCentralLab !== null &&
      props.errorMessage?.OrderCentralLab !== undefined
    ) {
      setTimeout(() => {
        props.runSequence({
          sequence: "OrderCentralLab",
          action: "clear",
          isNullEmr: props.isNullEmr,
        });
      }, 2000);
    }

    if (
      props.successMessage?.OrderCentralLab !== null &&
      props.successMessage?.OrderCentralLab !== undefined
    ) {
      setCompleted(true);

      setTimeout(() => {
        setCompleted(false);
      }, 2000);

      props.runSequence({
        sequence: "OrderCentralLab",
        action: "clear",
        isNullEmr: props.isNullEmr,
      });
    }
  }, [props.successMessage?.OrderCentralLab, props.errorMessage?.OrderCentralLab]);

  // Callback
  const handleDelete = useCallback(
    (idx: number) => () => {
      if (props.OrderCentralLabSequence?.labOrder?.id) {
        setDataChanged(true);
      }

      const labOrderItems: any[] = props.OrderCentralLabSequence?.labOrder?.order_items || [];

      labOrderItems.splice(idx, 1);

      props.setProp("OrderCentralLabSequence.labOrder.order_items", labOrderItems);
      // clear previous order id
      if (labOrderItems.length <= 0) {
        props.setProp("OrderCentralLabSequence.labOrder", {
          order_items: [],
        });
      }

      props.runSequence({
        sequence: "OrderCentralLab",
        action: "delete",
        isNullEmr: props.isNullEmr,
      });
    },
    [props.OrderCentralLabSequence?.labOrder, props.isNullEmr]
  );

  const handleCheck = useCallback(
    (idx: number) => (e: any, data: any) => {
      const tmp: any[] = [...(props.OrderCentralLabSequence?.labGroupItems || [])];

      tmp[idx].selected = data.checked;

      if (tmp[idx].count === 0) {
        tmp[idx].count = 1;
      }

      props.setProp("OrderCentralLabSequence.labGroupItems", tmp);
    },
    [props.OrderCentralLabSequence?.labGroupItems]
  );

  // Memo
  const labDivisions = useMemo(() => {
    return [
      { key: "All Division", value: "All Division", text: "All Division" },
      ...(props.isBloodBank
        ? (props.OrderCentralLabSequence?.labDivisions || []).filter((item: any) =>
            props.userLabPermission?.config_LAB_BLOOD_BANK_LAB_DIVISION_CODES.includes(item.code)
          )
        : props.OrderCentralLabSequence?.labDivisions || []),
    ].map((item: any) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [props.OrderCentralLabSequence?.labDivisions]);

  const hideButtonConfirm = useMemo(() => {
    const order = props.OrderCentralLabSequence?.labOrder || {};

    const hide = !props.isStudentUser && order.id && order.need_approve === false;

    return hide || props.hideActionButton;
  }, [props.isStudentUser, props.OrderCentralLabSequence, props.hideActionButton]);

  const hideButtonEdit = useMemo(() => {
    const order = props.OrderCentralLabSequence?.labOrder || {};

    const hide = !order.id || (!props.isStudentUser && order.need_approve === true);

    return hide || props.hideActionButton;
  }, [props.isStudentUser, props.OrderCentralLabSequence, props.hideActionButton]);

  const showPreOrder = useMemo(() => {
    // * แสดงเมื่อเป็น encounter IPD หรือ เป็นคำสั่งนอกแผนกที่ไม่ใช่ Blood bank
    return (
      props.selectedEncounter?.type !== "IPD" || !(props.checkOutPerformDiv && !props.isBloodBank)
    );
  }, [props.selectedEncounter, props.checkOutPerformDiv, props.isBloodBank]);

  const actionLogData = useMemo(
    () =>
      (props.OrderCentralLabSequence?.labTests || [])?.map((item: any) => ({
        ...item,
        addButton: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              icon="plus"
              color="green"
              size="mini"
              // onClick={handleDelete(idx)}
              onClick={() => {
                if (props.OrderCentralLabSequence?.labOrder?.id) {
                  setDataChanged(true);
                }
                props.runSequence({
                  sequence: "OrderCentralLab",
                  action: "add",
                  addedItem: item,
                  isNullEmr: props.isNullEmr,
                });
              }}
            />
          </div>
        ),
      })),
    [props.OrderCentralLabSequence?.labTests]
  );

  const labOrderItems = useMemo(
    () =>
      props.OrderCentralLabSequence?.labOrder?.order_items?.map((item: any, idx: any) => {
        return {
          ...item,
          specimen_time: (
            <Input
              value={item.specimen_time}
              fluid={true}
              onChange={(e) => {
                props.setProp(
                  `OrderCentralLabSequence.labOrder.order_items.${idx}.specimen_time`,
                  e.target.value
                );

                if (props.OrderCentralLabSequence?.labOrder?.id) {
                  setDataChanged(true);
                }
              }}
              style={{ border: "none" }}
            />
          ),
          urgency: (
            <div className="dropdown overflow">
              <Dropdown
                fluid={true}
                selection
                options={URGENCY_OPTIONS}
                value={item.urgency}
                onChange={(e: any, { value }: any) => {
                  props.setProp(
                    `OrderCentralLabSequence.labOrder.order_items.${idx}.urgency`,
                    value
                  );

                  if (props.OrderCentralLabSequence?.labOrder?.id) {
                    setDataChanged(true);
                  }
                }}
              />
            </div>
          ),
          note: (
            <Input
              value={item.note}
              fluid={true}
              onChange={(e) => {
                props.setProp(
                  `OrderCentralLabSequence.labOrder.order_items.${idx}.note`,
                  e.target.value
                );

                if (props.OrderCentralLabSequence?.labOrder?.id) {
                  setDataChanged(true);
                }
              }}
              style={{ border: "none" }}
            />
          ),
          delete: (
            <Button circular icon="trash alternate" color="red" onClick={handleDelete(idx)} />
          ),
        };
      }),
    [props.OrderCentralLabSequence, props.OrderCentralLabSequence?.labOrder?.order_items]
  );

  const labGroupItems = useMemo(
    () =>
      props.OrderCentralLabSequence?.labGroupItems?.map((item: any, idx: any) => {
        return {
          ...item,
          detail: item.detail.map((d: any) => d).join(),
          check: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Checkbox id={`CardLabRequest-Checkbox-Selected-${idx}`} key={item.id} checked={item.selected} onChange={handleCheck(idx)} />
            </div>
          ),
        };
      }),
    [props.OrderCentralLabSequence, props.OrderCentralLabSequence?.labGroupItems]
  );

  const findDoctor = (order_by: number) => {
    let doctor = props.masterOptions?.doctor?.find((data: any) => data.value === order_by);

    let doctorName = doctor?.text.split("(");
    return doctorName?.[0] || doctor;
  };

  const doctorLabOrderItems = useMemo(
    () =>
      (props.doctorLabOrderList?.items || []).map((item: any, idx: any) => {
        const disabledButton = !checkAllowEditOrder(item);
        return {
          ...item,
          specific_type_name: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{item.specific_type_name}</div>
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "50%",
                  width: "1em",
                  height: "1em",
                  margin: "0px 5px",
                  ...(!(item?.need_approve && item?.student_name) && {
                    display: "none",
                  }),
                }}
              />
            </div>
          ),
          careprovider: item?.order_status !== "CANCEL" && (
            <div>
              {item?.order_by === item?.approved_by ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {item?.approved_by_name}
                  {/* {props.masterOptions?.doctor.map((data: any) => {
                    if (data.value === item?.order_by) {
                      return data.text;
                    }
                  })} */}
                  {/* {item?.doctor_name} */}
                </div>
              ) : (
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {findDoctor(item?.order_by)}
                  </div>
                  {item?.need_approve && item?.student_name ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0px",
                      }}
                    >
                      <ButtonLoadCheck
                        // function
                        id="btn-confirmMedOrder"
                        setProp={props.setProp}
                        onClick={() => {
                          props.setProp("OrderCentralLabSequence.orderId", item?.id);

                          let fullNameSplit = props.django?.user?.full_name.split(" ");

                          if (
                            props.selectedEmr?.doctor_name ===
                              `${fullNameSplit?.[0]}${fullNameSplit?.[1]} ${fullNameSplit?.[2]}` &&
                            item?.student_name
                          ) {
                            handleSaveLabOrder({
                              action: "education_approve",
                              approved_by_username: "",
                              approved_by_password: "",
                              orderId: item?.id,
                            });
                          } else {
                            setOpenModAuthen({
                              open: true,
                              isApproveOrder: true,
                            });
                          }
                        }}
                        // data
                        paramKey={`${CARD_LAB_REQUEST}_CONFIRM_${item?.id}`}
                        buttonLoadCheck={
                          props.buttonLoadCheck?.[`${CARD_LAB_REQUEST}_CONFIRM_${item?.id}`]
                        }
                        // config
                        color={"green"}
                        title={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="/static/images/drugOrder/doctor_approve.png"
                              style={{ width: "20px", marginRight: "7px" }}
                            ></img>
                            <label>APPROVE</label>
                          </div>
                        }
                        style={{ borderRadius: "10px" }}
                      />
                    </div>
                  ) : (
                    item?.student_name && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px 0px",
                          }}
                        >
                          <img
                            src="/static/images/drugOrder/approve_check.png"
                            style={{ width: "10px 0px", marginRight: "7px" }}
                          ></img>
                          <label style={{ color: "green" }}>APPROVE</label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            color: "gray",
                          }}
                        >
                          ตรวจสอบโดย
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "10px 0px",
                          }}
                        >
                          {item?.approved_by_name}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          ),
          detail: (
            <div
              style={{
                textDecoration: item?.order_status !== "CANCEL" ? "" : "line-through",
              }}
              dangerouslySetInnerHTML={{
                __html: item?.order_status !== "CANCEL" ? item.detail : item.cancel_detail,
              }}
            />
          ),
          edit: !(
            props.isStudentUser &&
            item.order_by === item.approved_by &&
            item.order_by !== item.student
          ) && (
            <Button
              circular
              icon="edit alternate"
              color="blue"
              disabled={disabledButton}
              onClick={() => {
                setDataChanged(false);
                let items: any[] = props.doctorLabOrderList?.items || [];

                props.runSequence({
                  sequence: "OrderCentralLab",
                  action: "edit",
                  selected: items[idx].id || null,
                  isNullEmr: props.isNullEmr,
                });
              }}
            />
          ),
          delete: !(
            props.isStudentUser &&
            item.order_by === item.approved_by &&
            item.order_by !== item.student
          ) && (
            <Button
              circular
              icon="trash alternate"
              color="red"
              disabled={disabledButton}
              onClick={() => {
                const items: any[] = props.doctorLabOrderList?.items || [];

                setModDelete(items[idx]);
              }}
            />
          ),
        };
      }),
    [props.doctorLabOrderList, props.doctorLabOrderList?.items]
  );

  // Handler
  const handleSaveDupLab = () => {
    props.setProp("OrderCentralLabSequence.labOrder.allow_duplicate_flag", true);
    props.setProp("OrderCentralLabSequence.duplicateLabDetected", false);

    props.runSequence({
      sequence: "OrderCentralLab",
      action: props.OrderCentralLabSequence?.labOrder?.id ? "edit_save" : "save",
      isNullEmr: props.isNullEmr,
      isOrderTime,
      isOutPerformDiv: props.checkOutPerformDiv,
      cardKey: CARD_LAB_REQUEST,
      onSuccess: props.onSuccess,
    });

    handleCloseModConfirm();
  };

  const handleCloseModConfirm = () => {
    props.setProp("OrderCentralLabSequence.duplicateLabDetected", false);

    setOpenConfirm(false);
  };

  const handleSaveLabOrder = (params: any) => {
    if (params?.action === "edit_save") {
      props.runSequence({
        ...params,
        sequence: "OrderCentralLab",
        isNullEmr: props.isNullEmr,
        isOrderTime,
        isOutPerformDiv: props.checkOutPerformDiv,
        cardKey: CARD_LAB_REQUEST,
        isAppointment: props.isAppointment || false,
        onSuccess: props.onSuccess,
        data_changed: dataChanged,
      });
    } else if (params?.action === "save" || params?.action === "confirm") {
      props.runSequence({
        ...params,
        sequence: "OrderCentralLab",
        isNullEmr: props.isNullEmr,
        isOrderTime,
        isOutPerformDiv: props.checkOutPerformDiv,
        isAppointment: props.isAppointment || false,
        isBloodBank: props.isBloodBank || false,
        isAdvanceAppointment: appointmentDate === "beforeAppointment",
        performDivBloodBank,
        cardKey: CARD_LAB_REQUEST,
        onSuccess: props.onSuccess,
        data_changed: dataChanged,
      });
    } else if (params?.action === "education_approve") {
      props.runSequence({
        ...params,
        sequence: "OrderCentralLab",
        cardKey: CARD_LAB_REQUEST,
      });
    }

    setDataChanged(false);
  };

  const handleCloseLabGroup = async () => {
    setOpenLabGroup(false);

    const tmp: any[] = [...(props.OrderCentralLabSequence?.labGroupItems || [])].map((item) => ({
      ...item,
      selected: false,
    }));

    await props.setProp("OrderCentralLabSequence.labGroupItems", tmp);
  };

  const handleSelected = async () => {
    let selectedGroup = props.OrderCentralLabSequence?.labGroupItems.filter(
      (it: any) => it.selected === true
    );
    let selectedItems = selectedGroup.map((it: any) => it.items).flat();

    await handleCloseLabGroup();

    props.runSequence({
      sequence: "OrderCentralLab",
      action: "group_add",
      items: selectedItems,
      isNullEmr: props.isNullEmr,
    });
  };

  const handleGetTdPropsLabRequest = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      style: {
        cursor: "pointer",
        overflow: "visible",
      },
    };
  };

  const handleGetTrPropsDoctorLabOrder = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      className:
        !!props.OrderCentralLabSequence?.labOrder?.id &&
        rowInfo?.original?.id === props.OrderCentralLabSequence?.labOrder?.id
          ? "blueSelectedRow"
          : "",
    };
  };

  const handleLabTestRowProps = (state: any, rowInfo: any, column: any, instance: any) => ({
    onDoubleClick: () => {
      if (props.OrderCentralLabSequence?.labOrder?.id) {
        setDataChanged(true);
      }
      props.runSequence({
        sequence: "OrderCentralLab",
        action: "add",
        addedItem: rowInfo?.original,
        isNullEmr: props.isNullEmr,
      });
    },
  });

  const handleCancelEdit = () => {
    props.setProp("OrderCentralLabSequence.labOrder", {});
    setDataChanged(false);
  };

  const handleAdvanceChange = (e: any, data: any) => {
    if (props.OrderCentralLabSequence?.labOrder?.id) {
      setDataChanged(true);
    }

    setIsOrderTime(data.checked);

    if (data.checked === false) {
      props.setProp("OrderCentralLabSequence.labOrder.order_time", null);
      props.setProp("OrderCentralLabSequence.labOrder.out_perform_div", null);
    }
  };

  const handleChangeOutPerformDiv = (e: any, v: any) => {
    props.setProp("OrderCentralLabSequence.labOrder.out_perform_div", v.value);
  };

  const handleSearchLabTest = () =>
    props.runSequence({
      sequence: "OrderCentralLab",
      action: "search",
      isNullEmr: props.isNullEmr,
      isBloodBank: props.isBloodBank,
    });

  const handleConfirmDelete = () => {
    props.runSequence({
      sequence: "OrderCentralLab",
      action: "cancel",
      item: modDelete,
      isNullEmr: props.isNullEmr,
    });

    setModDelete(null);
  };

  const handleCloseModDelete = () => {
    setModDelete(null);
  };

  const handleOpenModAuthen = () => {
    let fullNameSplit = props.django?.user?.full_name.split(" ");

    if (
      props.selectedEmr?.doctor_name ===
        `${fullNameSplit?.[0]}${fullNameSplit?.[1]} ${fullNameSplit?.[2]}` &&
      props.OrderCentralLabSequence?.labOrder?.student_name
    ) {
      handleSaveLabOrder({
        action: "education_approve",
        approved_by_username: "",
        approved_by_password: "",
      });
    } else if (props.isStudentUser || props.OrderCentralLabSequence?.labOrder?.need_approve) {
      setOpenModAuthen({ open: true, isApproveOrder: false });
    } else {
      const isEdit = dataChanged && !!props.OrderCentralLabSequence?.labOrder?.id;

      handleSaveLabOrder({ action: isEdit ? "edit_save" : "confirm" });
    }
  };

  const handleOrderTimeChange = (value: any) => {
    if (props.OrderCentralLabSequence?.labOrder?.id) {
      setDataChanged(true);
    }

    props.setProp("OrderCentralLabSequence.labOrder.order_time", value);
  };

  return (
    <div style={{ padding: "10px" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_LAB_REQUEST}`, null);
        }}
        error={props.errorMessage?.[CARD_LAB_REQUEST]}
        success={null}
      />

      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
      />

      <CardLabRequestUX
        setProp={props.setProp}
        onEvent={props.onEvent}
        minLabDate={formatDate(new Date())}
        // data
        approveStatus={
          (props.OrderCentralLabSequence?.labOrder?.need_approve === true &&
            props.OrderCentralLabSequence?.labOrder?.student_name) ||
          (props.isStudentUser &&
            !props.OrderCentralLabSequence?.labOrder?.id &&
            props.OrderCentralLabSequence?.labOrder?.order_items) ? (
            <div
              style={{
                color: "gray",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={"/static/images/drugOrder/need_approve.png"}
                style={{ marginRight: "5px" }}
              />
              <label>NEED APPROVE</label>
            </div>
          ) : (
            props.isStudentUser &&
            props.OrderCentralLabSequence?.labOrder?.need_approve === false &&
            props.OrderCentralLabSequence?.labOrder?.approved_by_name && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/static/images/drugOrder/approve_check.png"}
                  style={{ marginRight: "5px" }}
                />
                <label style={{ color: "green", marginRight: "10px" }}>APPROVE</label>
                <div style={{ display: "flex", color: "black" }}>
                  <label>
                    {"[ "}
                    <span style={{ color: "#898383" }}>{`โดย `}</span>
                    {props.OrderCentralLabSequence?.labOrder?.approved_by_name} {" ]"}
                  </label>
                </div>
              </div>
            )
          )
        }
        hidePreOrder={
          // * ซ่อนตามเงื่อนไข หรือ ซ่อนโดย property 
          // #70616 ซ่อนโดย division type เท่ากับ ห้องผ่าตัด หรือ หน่วยตรวจ
          !showPreOrder || props.hidePreOrder || ["ห้องผ่าตัด","หน่วยตรวจ"].includes(props.divisionType || "")
        }
        isBloodBank={
          props.isBloodBank &&
          props.isAppointment &&
          props.userLabPermission?.config_QUE_ADVANCE_APPOINTMENT_DATE_BEFORE
        }
        doctorLabOrderList={doctorLabOrderItems || []}
        labDivision={props.OrderCentralLabSequence?.labDivision}
        outPerformDiv={props.OrderCentralLabSequence?.labOrder?.out_perform_div || ""}
        configAppointmentDateText={
          props.userLabPermission?.config_QUE_ADVANCE_APPOINTMENT_DATE_BEFORE
            ? `นัดหมายล่วงหน้า ${props.userLabPermission.config_QUE_ADVANCE_APPOINTMENT_DATE_BEFORE} วัน`
            : ""
        }
        performDiv={performDivBloodBank}
        isOrderTime={isOrderTime}
        orderTime={props.OrderCentralLabSequence?.labOrder?.order_time}
        labOrderItems={labOrderItems || []}
        message={props.OrderCentralLabSequence?.labOrder?.note || ""}
        price_claimable={props.OrderCentralLabSequence?.price_claimable || 0}
        price_non_claimable={props.OrderCentralLabSequence?.price_non_claimable || 0}
        price_total={props.OrderCentralLabSequence?.price_total || 0}
        appointmentDate={appointmentDate}
        labCode={props.OrderCentralLabSequence?.labCode}
        labName={props.OrderCentralLabSequence?.labName}
        labTests={actionLogData}
        loadingSave={props?.loadingStatus?.["OrderCentralLab"]}
        // style
        saveLabOrderStyle={{ ...props.saveLabOrderStyle, display: "none" }}
        doctorLabOrderTableStyle={props.doctorLabOrderTableStyle}
        // options
        labDivisions={labDivisions}
        divisionOpdOptions={props.divisionOpdOptions}
        // config
        disableOrderTime={!isOrderTime}
        checkOutPerformDiv={props.checkOutPerformDiv}
        isEdit={!!props.editId || !!props.OrderCentralLabSequence?.labOrder?.id}
        showCancelEdit={!props.editId && !!props.OrderCentralLabSequence?.labOrder?.id}
        // callback
        isAdvanceChange={handleAdvanceChange}
        onChangeOutPerformDiv={handleChangeOutPerformDiv}
        searchLabTest={handleSearchLabTest}
        // labTestRowProps={handleLabTestRowProps}
        saveLabOrder={handleSaveLabOrder}
        onCancelEdit={handleCancelEdit}
        onChangePerformDiv={(e: any, data: any) => {
          setPerformDivBloodBank(data.value);
        }}
        openLabGroup={() => setOpenLabGroup(true)}
        orderTimeChange={handleOrderTimeChange}
        changeLabDivision={(e: any, v: any) =>
          props.setProp("OrderCentralLabSequence.labDivision", v.value)
        }
        changeLabCode={(e: any) => props.setProp("OrderCentralLabSequence.labCode", e.target.value)}
        changeLabName={(e: any) => props.setProp("OrderCentralLabSequence.labName", e.target.value)}
        onChangeMessage={(e: any) => {
          props.setProp("OrderCentralLabSequence.labOrder.note", e.target.value);
          setDataChanged(true);
        }}
        onCheckedBeforeAppointment={() => {
          setAppointmentDate(appointmentDate === "beforeAppointment" ? "" : "beforeAppointment");
        }}
        // Table
        getTdPropsLabRequest={handleGetTdPropsLabRequest}
        getTrPropsDoctorLabOrder={handleGetTrPropsDoctorLabOrder}
        // Element
        button_save={
          completed ? (
            <Icon name="check"></Icon>
          ) : !!props.editId || !!props.OrderCentralLabSequence?.labOrder?.id ? (
            "EDIT"
          ) : (
            "CONFIRM ORDER"
          )
        }
        ErrorMessage={<ErrorMessage error={props?.errorMessage?.["OrderCentralLab"]} />}
        buttonEditOrder={
          <ButtonLoadCheck
            // function
            id="btn-confirmMedOrder"
            setProp={props.setProp}
            onClick={() => handleSaveLabOrder({ action: "edit_save" })}
            // data
            paramKey={`${CARD_LAB_REQUEST}_edit_save`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_LAB_REQUEST}_edit_save`]}
            // config
            disabled={!dataChanged ? true : false}
            color="yellow"
            title="EDIT ORDER"
            style={{
              margin: "0px 5px",
              ...(hideButtonEdit && {
                display: "none",
              }),
            }}
          />
        }
        buttonSaveOrder={
          <ButtonLoadCheck
            // function
            id="btn-confirmMedOrder"
            setProp={props.setProp}
            onClick={() => handleSaveLabOrder({ action: "save" })}
            // data
            paramKey={`${CARD_LAB_REQUEST}_save`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_LAB_REQUEST}_save`]}
            // config
            color="blue"
            title="SAVE ORDER"
            style={{
              margin: "0px 5px",
              ...(!props.isStudentUser &&
                props.OrderCentralLabSequence?.labOrder?.id !== null && {
                  display: "none",
                }),
            }}
          />
        }
        buttonConfirmOrder={
          <ButtonLoadCheck
            // function
            id="btn-confirmMedOrder"
            setProp={props.setProp}
            onClick={handleOpenModAuthen}
            // data
            paramKey={`${CARD_LAB_REQUEST}_confirm`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_LAB_REQUEST}_confirm`] ||
              props.buttonLoadCheck?.[`${CARD_LAB_REQUEST}_edit_save`]
            }
            // config
            disabled={
              props.buttonLoadCheck?.[`${CARD_LAB_REQUEST}_confirm`] === "LOADING" ||
              props.buttonLoadCheck?.[`${CARD_LAB_REQUEST}_edit_save`] === "LOADING" ||
              (props.isStudentUser &&
                (props.OrderCentralLabSequence?.labOrder?.approved_by === undefined
                  ? false
                  : !dataChanged
                  ? true
                  : false))
            }
            color="green"
            title="CONFIRM ORDER"
            style={{
              margin: "0px 5px",
              ...(hideButtonConfirm && {
                display: "none",
              }),
            }}
          />
        }
      />

      <Modal
        closeIcon
        style={{ left: "unset !important" }}
        size={"large"}
        open={openLabGroup}
        onClose={handleCloseLabGroup}
      >
        <CardLabGroupUX
          setProp={props.setProp}
          onEvent={props.onEvent}
          labGroupSearch={props.OrderCentralLabSequence?.labGroupSearch}
          labGroupSearchChange={(e: any) =>
            props.setProp("OrderCentralLabSequence.labGroupSearch", e.target.value)
          }
          labGroupSearchBtn={() =>
            props.runSequence({
              sequence: "OrderCentralLab",
              action: "group_search",
              isNullEmr: props.isNullEmr,
            })
          }
          labGroupManageBtn={() => window.open(`${HOST}/manage/LAB/centrallabtemplate/`)}
          labGroupSelectedBtn={handleSelected}
          labGroupItems={labGroupItems || []}
        />
      </Modal>

      <ModConfirm
        openModal={openConfirm}
        titleName="กรุณายืนยัน"
        // @ts-ignore
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: props.OrderCentralLabSequence?.promptMessage,
            }}
          />
        }
        onApprove={handleSaveDupLab}
        onDeny={handleCloseModConfirm}
      />

      <ModConfirm
        openModal={!!modDelete}
        titleName="ต้องการลบ Lab Center Laboratory นี้ใช่หรือไม่"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ไม่"
        approveButtonText="ใช่"
        content={
          <div
            style={{
              margin: "-0.5rem 0rem -1rem 0rem",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          ></div>
        }
        onDeny={handleCloseModDelete}
        onApprove={handleConfirmDelete}
        onCloseWithDimmerClick={handleCloseModDelete}
      />

      <ModAuthen
        titleName={"ยืนยันการตรวจสอบ"}
        titleColor={"blue"}
        open={openModAuthen?.open}
        onDeny={() => setOpenModAuthen({ open: false, isApproveOrder: false })}
        onApprove={({ username, password }: any) => {
          // save
          if (openModAuthen?.isApproveOrder) {
            handleSaveLabOrder({
              action: "education_approve",
              approved_by_username: username,
              approved_by_password: password,
            });
          } else {
            handleSaveLabOrder({
              action: props.OrderCentralLabSequence?.labOrder?.id ? "edit_save" : "save",
              approved_by_username: username,
              approved_by_password: password,
            });
          }
          // setOpenModAuthen({ open: false, isApproveOrder: false });
          setDataChanged(false);
        }}
      />

      <ModError
        open={props.errorMessage?.["CardLabRequest_education_approve"]}
        titleColor="red"
        onApprove={() => {
          props.setProp(`errorMessage.${"CardLabRequest_education_approve"}`, null);

          setDataChanged(false);
        }}
        alertText={
          props.errorMessage?.["CardLabRequest_education_approve"]?.error?.approved_by?.[0]
        }
      />
    </div>
  );
};

export default React.memo(CardLabRequest);
