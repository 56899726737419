import React, { useEffect, useState } from "react";
import CardLayout from "../common/CardLayout";
import {
  Segment,
  Label,
  List,
  Image,
  Grid,
  Divider,
  Message,
} from "semantic-ui-react";
import { formatToBEDatetime } from "../../utils";

const CardImageGalleryView = (props: any) => {
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    props.onGetImageGallery({});
  }, []);

  let titleContent = null;
  if (props.imageGallery) {
    titleContent = (
      <div>
        {props.imageGallery.edit_user} &ensp;
        <Label
          style={{ color: "black" }}
          content={formatToBEDatetime({ date: props.imageGallery.edited })}
        />
      </div>
    );
  }

  //   const formatSensitiveNote = (text) => {
  //     console.log(props.sensitiveNote);
  //     let note = props.sensitiveNote.sensitive_note.replace(/\u21B5/g, '<br/>')
  //     return (
  //       <div style={{ whiteSpace: 'pre-line' }}>
  //         {note}
  //       </div>
  //     )
  //   }

  const handleImageClick = (item: any) => {
    setSelectedItem(item);
  };

  return (
    <CardLayout
      titleText="Gallery"
      headerColor="teal"
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      titleContent={titleContent}
      loading={props.imageGalleryLoading}
      enableMargin={props.enableMargin}
    >
      <div style={{ height: "100%", overflowY: "auto" }}>
        {/* Top layout with horizontal list view or grid */}
        <List horizontal>
          {props.imageGallery?.items.map((item: {}, index: number) => (
            <List.Item key={index} onClick={() => handleImageClick(item)}>
              <Image
                src={item?.image}
                size="small"
                style={{
                  cursor: "pointer",
                  border: item === selectedItem ? "2px solid #2185d0" : "none",
                }}
              />
            </List.Item>
          ))}
        </List>
        <Divider clearing />
        {/* Center layout with image view */}
        <Segment>
          <Grid centered style={{ marginTop: "20px" }}>
            <Grid.Row>
              <Image
                size="large"
                src={selectedItem?.image}
                fluid
              />
            </Grid.Row>
          </Grid>
        </Segment>
        <br />
        <Label>รายละเอียด</Label>
        {/* <Segment>{selectedItem?.description}</Segment> */}
        <Message>
          <p>{selectedItem?.description}</p>
        </Message>
      </div>
    </CardLayout>
  );
};

CardImageGalleryView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  imageGallery: {},
  imageGalleryLoading: false,
  onGetImageGallery: () => {},
  enableMargin: true,
};

export default CardImageGalleryView;
