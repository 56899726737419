import React from "react";

export interface CardBodyOutlineType {
  width: number;
  height: number;
  viewBox: string;
  organs: [{ x: number; y: number; width: number; height: number }];
}

const CardBodyOutline = (props) => {
  // console.log(props.value)
  return (
    <svg width={props.width} height={props.height} viewBox={props.viewBox}>
      
      <g
        transform="translate(0.000000,560.000000) scale(0.08,-0.08)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1306 5208 c-25 -7 -57 -28 -86 -58 -63 -62 -85 -126 -85 -245 0
-101 17 -163 65 -239 27 -42 30 -55 30 -126 l0 -78 -127 -42 c-235 -78 -308
-120 -346 -202 -39 -83 -55 -151 -76 -325 -19 -157 -21 -164 -61 -230 -51 -84
-65 -134 -85 -293 -28 -220 -47 -270 -118 -319 -24 -16 -56 -46 -71 -65 -15
-20 -40 -43 -56 -51 -53 -27 0 -72 56 -46 33 15 31 5 -20 -98 -43 -85 -44 -89
-31 -127 12 -36 17 -39 52 -42 27 -2 44 2 53 13 7 8 18 15 25 15 18 0 120 114
153 170 22 37 26 56 25 108 -2 61 2 71 97 270 104 218 131 293 149 408 11 72
50 204 61 204 3 0 15 -55 25 -122 25 -154 26 -378 2 -606 -26 -253 -11 -430
67 -772 49 -216 61 -351 41 -482 -31 -211 -14 -351 81 -636 24 -72 19 -253
-11 -369 -39 -155 -37 -167 45 -223 124 -84 135 -72 135 139 1 86 6 186 13
223 14 76 9 106 -25 160 -29 47 -30 108 -5 268 16 109 19 207 11 415 -3 70 1
100 15 135 15 37 22 108 37 380 10 184 20 378 21 430 2 55 7 95 13 95 9 0 14
-75 44 -645 7 -150 14 -202 35 -272 l25 -86 -16 -111 c-15 -107 -15 -118 6
-269 29 -216 28 -289 -5 -352 -23 -45 -26 -58 -20 -113 4 -34 10 -143 14 -242
4 -99 11 -186 15 -192 13 -20 35 -16 93 18 99 56 109 86 74 234 -30 130 -28
328 5 432 82 263 96 370 70 548 -23 155 -15 278 30 490 93 436 101 568 60 930
-19 168 -16 324 8 445 9 41 18 92 22 114 4 22 10 35 15 30 14 -14 50 -153 60
-233 11 -81 48 -174 167 -420 70 -144 76 -160 74 -214 -1 -39 5 -72 18 -100
22 -49 137 -187 156 -187 7 0 18 -7 25 -15 15 -18 84 -20 98 -2 28 35 23 71
-23 165 -25 52 -45 95 -45 97 0 2 14 -1 31 -7 22 -7 37 -7 55 1 27 12 33 41 8
41 -8 0 -44 28 -78 63 -34 34 -72 69 -84 77 -39 26 -71 128 -93 288 -24 181
-37 229 -88 313 -38 64 -41 73 -61 230 -47 370 -72 402 -407 514 l-143 47 0
78 c0 63 4 82 20 103 85 108 109 311 50 431 -12 25 -42 64 -67 86 -66 57 -128
71 -217 48z m138 -20 c120 -36 190 -208 152 -371 -20 -84 -42 -129 -95 -188
-52 -59 -104 -82 -156 -68 -51 14 -116 83 -156 165 -32 66 -34 74 -33 179 0
122 11 158 66 221 58 66 135 88 222 62z m41 -792 c-20 -49 -53 -96 -67 -96 -8
0 -9 26 -5 80 6 81 32 156 65 193 16 18 17 16 20 -58 2 -55 -2 -90 -13 -119z
m-198 167 c31 -40 45 -92 51 -183 5 -87 4 -94 -11 -81 -9 7 -30 40 -47 74 -25
51 -30 72 -30 134 0 75 9 90 37 56z m128 -63 c-30 -86 -31 -230 -1 -230 17 0
49 37 74 85 12 22 21 35 22 28 0 -7 14 -28 31 -47 l30 -35 -43 -11 c-24 -7
-58 -23 -76 -36 -52 -40 -62 -70 -62 -190 l0 -107 43 -42 c33 -33 59 -48 114
-65 69 -21 72 -23 78 -58 5 -33 -4 -222 -21 -463 -10 -126 13 -155 80 -104 41
31 96 44 96 23 0 -9 -13 -18 -30 -22 -49 -9 -87 -45 -155 -146 -35 -53 -89
-122 -119 -153 -49 -51 -58 -57 -96 -57 -35 0 -48 7 -90 46 -26 25 -81 93
-121 150 -78 113 -117 150 -167 160 -35 7 -44 34 -12 34 26 0 72 -23 89 -44 8
-9 24 -16 37 -16 37 0 50 58 34 157 -18 115 -26 449 -11 467 6 8 28 17 48 21
57 10 129 51 154 86 18 26 24 50 27 113 8 147 -27 211 -132 241 l-57 17 25 25
c14 14 28 34 31 44 5 16 14 9 46 -40 42 -63 64 -79 73 -55 11 29 6 140 -9 197
-8 32 -15 60 -15 62 0 3 22 5 50 5 l49 0 -14 -40z m-185 -82 c0 -33 -36 -87
-66 -98 -40 -15 -177 -12 -200 4 -10 8 -16 17 -13 20 6 6 256 94 272 95 4 1 7
-9 7 -21z m431 -23 c72 -25 135 -47 138 -51 3 -3 -3 -12 -13 -20 -13 -9 -47
-14 -103 -14 -80 0 -85 1 -118 33 -30 27 -60 97 -42 97 3 0 65 -20 138 -45z
m-721 -74 c0 -5 16 -15 35 -22 19 -7 35 -17 35 -22 0 -12 -47 -54 -110 -100
-25 -18 -52 -47 -61 -64 -8 -17 -27 -41 -42 -54 -15 -13 -38 -51 -51 -84 -49
-121 -57 -55 -15 113 27 108 64 171 124 209 49 32 85 42 85 24z m952 -20 c82
-52 118 -123 144 -278 21 -130 17 -136 -26 -47 -23 48 -49 85 -63 93 -13 7
-27 27 -33 44 -6 22 -25 42 -59 65 -89 59 -133 112 -91 112 8 0 24 9 37 20 31
26 37 25 91 -9z m-651 -36 c85 -30 109 -73 109 -198 0 -70 -4 -90 -22 -118
-30 -45 -114 -86 -206 -100 -96 -14 -111 -4 -162 107 -31 66 -47 89 -75 105
-19 12 -35 25 -35 29 0 24 19 45 74 82 67 46 92 70 102 101 6 18 12 19 89 13
45 -3 102 -13 126 -21z m496 -4 c6 -17 32 -45 59 -63 113 -79 131 -111 75
-136 -35 -16 -55 -44 -90 -131 -36 -90 -56 -99 -164 -78 -94 19 -161 51 -191
90 -29 40 -31 206 -2 253 34 57 125 91 246 93 53 1 56 -1 67 -28z m-853 -222
c23 -18 26 -27 26 -88 0 -47 -8 -90 -26 -141 -27 -79 -92 -183 -125 -201 -53
-28 -112 35 -91 95 6 17 16 62 22 100 8 49 17 74 30 83 11 8 29 40 41 73 35
98 70 120 123 79z m1063 6 c12 -8 33 -45 47 -82 14 -38 34 -73 44 -80 14 -8
21 -28 25 -77 3 -36 13 -79 22 -96 14 -27 14 -33 0 -60 -23 -44 -57 -57 -90
-36 -77 51 -145 208 -145 338 0 68 3 79 22 92 28 20 48 20 75 1z m-956 -188
c23 -27 33 -32 72 -32 l45 0 5 -190 c3 -104 11 -229 18 -276 12 -85 8 -139
-12 -139 -6 0 -25 11 -43 25 -18 14 -47 28 -64 31 l-32 7 0 118 c0 79 -8 169
-25 271 -14 84 -25 186 -24 228 l0 75 17 -43 c9 -24 29 -58 43 -75z m839 51
c0 -29 -14 -132 -30 -228 -24 -135 -30 -200 -29 -284 l3 -110 -28 -7 c-16 -5
-46 -20 -68 -35 -54 -38 -62 -26 -54 83 3 48 9 182 12 296 l7 208 27 -6 c16
-3 43 0 63 8 27 12 39 26 57 71 13 31 27 56 32 56 4 0 8 -24 8 -52z m-1175
-332 c15 -11 17 -22 13 -67 -8 -77 -104 -325 -136 -352 -13 -10 -14 -8 -9 18
3 17 13 82 22 145 20 153 44 258 58 264 21 8 33 6 52 -8z m1493 6 c18 -12 30
-60 52 -222 11 -80 24 -160 29 -178 11 -37 0 -42 -21 -9 -27 42 -119 292 -125
338 -7 61 25 96 65 71z m-1328 -37 c0 -35 -37 -127 -116 -293 -52 -110 -70
-139 -77 -126 -29 51 86 373 151 425 33 25 42 24 42 -6z m1163 -5 c56 -49 147
-288 146 -383 0 -51 0 -52 -16 -29 -43 62 -183 384 -183 422 0 28 14 25 53
-10z m-1354 -260 c-14 -45 -19 -89 -18 -145 1 -56 -4 -92 -16 -119 -11 -25
-14 -51 -10 -72 3 -19 1 -51 -4 -71 -13 -46 -138 -203 -161 -203 -10 0 -23 -7
-30 -15 -18 -22 -70 -20 -70 3 0 10 -5 23 -11 29 -7 7 4 40 40 112 28 55 51
107 51 116 0 19 -19 19 -44 0 -22 -17 -66 -20 -66 -6 0 5 9 13 20 16 11 3 32
22 47 42 15 20 51 53 79 74 29 21 55 48 59 59 3 11 12 20 20 20 22 0 50 44 91
140 21 49 39 88 41 87 1 -2 -7 -32 -18 -67z m1531 -48 c23 -60 40 -88 59 -100
15 -9 42 -34 60 -55 18 -20 46 -45 62 -54 16 -10 40 -34 54 -54 13 -21 33 -40
45 -44 11 -3 20 -11 20 -16 0 -14 -27 -11 -62 7 -52 27 -52 4 2 -109 31 -65
48 -112 43 -119 -4 -7 -8 -20 -8 -28 0 -21 -58 -25 -75 -6 -7 9 -21 16 -31 16
-11 0 -19 3 -19 8 0 4 -19 27 -41 52 -81 90 -99 126 -99 202 0 42 -6 81 -15
98 -11 21 -13 45 -9 87 6 51 -6 142 -24 193 -3 8 -3 13 0 10 3 -3 20 -42 38
-88z m-1135 -34 c14 -6 25 -14 25 -18 0 -4 -11 -38 -25 -75 -14 -37 -37 -110
-50 -163 -14 -53 -28 -98 -30 -101 -12 -11 7 311 21 357 4 15 25 15 59 0z
m758 5 c10 -16 28 -240 25 -303 l-3 -65 -24 100 c-13 55 -36 133 -51 173 l-28
72 21 15 c23 16 53 20 60 8z m-618 -163 c73 -103 135 -172 165 -182 10 -3 12
-41 11 -163 -2 -159 -11 -213 -26 -164 -14 43 -91 153 -144 204 -73 71 -92
126 -99 288 -3 77 -1 127 5 127 5 0 45 -50 88 -110z m513 -22 c-5 -162 -19
-198 -113 -300 -39 -42 -78 -97 -97 -139 -18 -37 -36 -68 -39 -68 -7 -1 -19
156 -19 259 0 76 1 78 33 100 41 29 104 103 170 202 29 43 56 77 61 78 4 0 6
-60 4 -132z m-608 -150 c17 -63 38 -99 76 -135 34 -32 34 -33 34 -120 -1 -174
-34 -343 -81 -419 -17 -25 -33 -43 -36 -40 -4 4 -17 55 -30 114 -13 59 -35
159 -49 222 -42 192 -34 328 34 535 l26 80 7 -97 c4 -53 13 -116 19 -140z
m670 151 c64 -237 69 -339 27 -539 -15 -69 -35 -167 -46 -219 -11 -51 -22 -96
-25 -99 -12 -11 -46 33 -66 85 -27 72 -47 192 -55 333 l-7 115 41 40 c58 56
82 125 88 258 3 59 9 107 13 107 4 0 18 -36 30 -81z m-460 -464 c17 -33 34
-82 39 -110 9 -46 6 -190 -5 -242 -10 -50 -90 -30 -115 29 -16 37 -17 123 -4
312 l8 129 24 -29 c13 -16 37 -56 53 -89z m289 -115 c15 -161 -4 -237 -66
-261 -54 -20 -60 -9 -68 120 -6 97 -4 129 10 177 19 64 46 123 79 167 l21 29
7 -59 c3 -32 11 -110 17 -173z m51 -227 c0 -6 16 -28 34 -48 l35 -37 -6 -116
c-4 -86 -1 -139 11 -201 20 -105 20 -210 0 -309 -22 -110 -25 -118 -34 -101
-5 8 -2 41 7 74 10 40 15 104 14 190 -2 228 -34 307 -85 210 -19 -36 -21 -62
-26 -265 -5 -226 -9 -250 -45 -280 -23 -19 -27 -8 -15 44 22 101 31 246 20
343 -9 87 -17 117 -64 241 -9 24 -17 77 -19 119 l-2 75 32 3 c48 4 78 28 99
80 l19 45 12 -27 c7 -16 12 -33 13 -40z m-442 24 c18 -49 55 -80 97 -81 l35
-1 0 -65 c0 -74 -12 -123 -46 -196 -33 -70 -44 -138 -44 -273 0 -103 4 -142
27 -268 7 -41 -10 -44 -34 -6 -15 23 -19 59 -23 253 -5 213 -7 227 -30 273
-30 59 -55 62 -70 10 -7 -21 -14 -103 -17 -183 -4 -113 -2 -160 11 -213 18
-78 20 -97 7 -97 -5 0 -16 41 -26 90 -15 75 -16 114 -10 218 5 70 9 195 10
279 1 150 2 152 28 178 14 15 34 47 43 71 9 24 20 44 23 44 4 0 12 -15 19 -33z
m-18 -415 c11 -41 12 -430 0 -477 l-7 -30 -12 30 c-7 16 -22 68 -33 115 -24
98 -27 272 -7 379 l12 65 18 -23 c10 -12 23 -39 29 -59z m503 -142 c2 -120 -1
-147 -27 -248 -16 -62 -32 -110 -37 -105 -5 5 -10 116 -11 248 -2 236 -2 241
22 285 l24 45 13 -45 c8 -26 15 -101 16 -180z m-434 -525 c21 -19 46 -53 56
-76 18 -39 18 -45 3 -137 -12 -69 -15 -132 -11 -217 4 -96 2 -124 -10 -139
-14 -19 -15 -19 -63 9 -104 59 -110 79 -73 215 25 87 37 295 21 352 -5 20 -8
39 -5 42 8 8 40 -11 82 -49z m373 -92 c-3 -122 0 -164 17 -240 24 -106 26
-153 9 -174 -22 -27 -117 -81 -132 -76 -11 4 -15 45 -20 189 -3 101 -8 209
-12 241 -5 50 -3 63 21 103 16 27 46 58 73 75 25 16 46 29 47 29 0 0 -1 -66
-3 -147z"
        />
        <path
          d="M1235 3837 c-68 -32 -77 -45 -73 -109 3 -46 6 -53 25 -56 29 -4 138
54 164 86 22 29 24 51 7 75 -22 28 -68 30 -123 4z m96 -7 c44 -24 6 -76 -92
-126 -47 -23 -49 -24 -59 -5 -24 47 0 93 64 122 48 22 60 23 87 9z"
        />
        <path
          d="M1420 3847 c-35 -18 -39 -68 -8 -97 36 -34 123 -80 151 -80 28 0 37
16 37 69 0 26 -7 44 -22 58 -58 52 -117 71 -158 50z m105 -35 c47 -25 50 -30
53 -71 2 -24 -1 -48 -5 -52 -18 -18 -173 80 -173 109 0 18 31 42 53 42 12 0
45 -13 72 -28z"
        />
        <path
          d="M1241 3661 c-79 -40 -97 -64 -81 -106 13 -35 49 -47 107 -35 73 13
82 19 93 60 16 59 -2 120 -35 120 -5 0 -43 -18 -84 -39z m103 3 c10 -27 7 -79
-6 -97 -15 -19 -104 -39 -137 -30 -22 6 -38 44 -26 63 7 12 134 79 151 80 7 0
15 -7 18 -16z"
        />
        <path
          d="M1402 3688 c-7 -7 -12 -35 -12 -64 0 -68 19 -90 88 -102 87 -15 122
0 122 54 0 37 -23 58 -112 99 -61 28 -69 30 -86 13z m118 -52 c42 -25 56 -38
58 -59 4 -35 -26 -47 -91 -37 -58 9 -81 29 -81 72 0 78 16 81 114 24z"
        />
        <path
          d="M1257 3496 c-91 -25 -113 -76 -56 -134 25 -25 40 -32 71 -32 54 0 75
14 88 58 15 54 12 80 -10 102 -23 23 -28 23 -93 6z m81 -18 c17 -17 15 -79 -4
-106 -35 -50 -144 -13 -144 48 0 32 16 45 77 59 53 13 57 13 71 -1z"
        />
        <path
          d="M1408 3499 c-14 -8 -18 -23 -18 -68 0 -73 24 -101 89 -101 36 0 49 6
77 34 59 59 37 106 -61 131 -69 18 -65 17 -87 4z m122 -38 c47 -22 50 -51 9
-85 -25 -22 -40 -26 -73 -24 -39 3 -42 5 -55 46 -10 33 -11 50 -2 68 10 22 15
24 48 18 21 -3 54 -13 73 -23z"
        />
        <path
          d="M1190 3285 c-13 -36 -1 -172 20 -220 25 -57 98 -145 121 -145 28 0
34 36 34 200 0 187 -2 190 -96 190 -65 0 -70 -2 -79 -25z m151 -22 c14 -37 8
-298 -7 -314 -9 -8 -22 2 -54 43 -56 70 -71 107 -77 194 -8 101 -6 104 67 104
57 0 61 -2 71 -27z"
        />
        <path
          d="M1406 3288 c-13 -18 -16 -53 -16 -170 0 -208 16 -236 85 -155 73 85
89 122 93 224 5 117 1 123 -84 123 -54 0 -65 -3 -78 -22z m139 -98 c0 -93 -1
-96 -38 -150 -64 -95 -76 -107 -86 -90 -15 23 -24 135 -18 224 7 117 8 117 81
114 l61 -3 0 -95z"
        />
        <path
          d="M4290 5140 c-58 -27 -96 -64 -125 -121 -66 -129 -49 -326 40 -456 20
-30 25 -50 25 -99 l0 -62 -127 -42 c-154 -51 -257 -97 -300 -134 -65 -55 -103
-171 -128 -396 -9 -80 -22 -156 -29 -170 -8 -14 -32 -59 -53 -100 -32 -61 -43
-94 -52 -165 -39 -298 -54 -344 -129 -402 -27 -21 -60 -53 -72 -71 -13 -18
-38 -39 -57 -47 -23 -10 -31 -18 -27 -30 9 -21 51 -30 81 -16 14 7 27 10 28 9
2 -2 -17 -43 -42 -93 -38 -78 -44 -96 -38 -132 3 -23 13 -44 21 -47 28 -11 72
-6 88 9 8 8 21 15 29 15 15 0 102 98 146 164 21 32 26 51 27 110 0 65 5 81 58
191 151 313 176 373 176 425 0 51 64 279 75 268 10 -10 55 -317 55 -374 0 -34
-9 -153 -21 -265 -31 -304 -18 -526 48 -804 62 -263 75 -403 52 -546 -29 -180
-13 -319 65 -578 34 -111 36 -292 6 -419 -27 -113 -26 -165 3 -192 30 -28 121
-80 140 -80 9 0 19 6 24 13 4 6 11 91 15 187 4 96 10 205 13 242 7 61 5 71
-22 125 -35 68 -35 84 -1 339 20 156 20 165 5 276 l-17 115 25 74 c21 61 28
105 36 249 12 223 38 532 44 538 3 3 5 -6 5 -21 0 -14 7 -99 15 -189 8 -90 19
-237 24 -328 8 -130 15 -182 35 -244 l25 -79 -16 -117 c-16 -114 -16 -122 5
-276 30 -216 29 -280 -4 -341 -23 -43 -25 -55 -19 -118 3 -38 9 -149 13 -245
4 -96 11 -181 15 -188 13 -19 35 -15 93 19 99 56 109 86 74 234 -15 63 -20
121 -20 218 0 118 3 143 30 232 76 251 93 406 61 553 -18 82 -9 273 19 402 73
335 88 426 96 605 5 99 1 176 -15 319 -28 247 -27 348 4 520 14 77 27 141 30
144 11 11 65 -182 65 -233 0 -66 25 -128 183 -457 61 -127 66 -141 60 -186 -4
-37 0 -60 15 -93 23 -50 138 -189 157 -189 7 0 18 -7 25 -15 9 -11 27 -15 59
-13 45 3 46 4 54 45 7 37 3 51 -36 135 -25 51 -44 94 -43 95 1 2 17 -3 34 -9
24 -9 37 -10 50 -2 27 18 26 41 -3 48 -14 4 -38 23 -52 44 -15 20 -50 55 -78
77 -67 54 -87 109 -114 305 -25 181 -37 221 -91 310 -38 63 -41 73 -56 205
-20 183 -37 261 -69 329 -44 93 -102 129 -334 207 l-146 48 -3 64 c-3 59 0 68
32 116 47 70 58 107 63 232 4 79 1 120 -11 161 -41 141 -196 224 -316 168z
m165 -19 c51 -23 118 -98 133 -149 17 -57 15 -218 -4 -282 -9 -29 -27 -71 -41
-93 -24 -37 -25 -38 -38 -19 -23 32 -68 45 -151 40 -69 -3 -78 -6 -101 -32
-13 -16 -25 -27 -27 -25 -73 107 -102 260 -72 376 41 162 173 242 301 184z
m-64 -871 c-4 -379 5 -495 50 -665 28 -109 50 -145 113 -193 68 -52 80 -72 72
-127 -8 -51 -2 -58 96 -121 37 -24 68 -51 68 -59 0 -12 -10 -9 -42 13 -107 73
-146 79 -201 30 -20 -18 -62 -78 -93 -133 -31 -55 -62 -108 -69 -118 -11 -15
-21 -2 -84 107 -40 69 -86 137 -104 152 -44 37 -96 30 -176 -25 -34 -22 -63
-41 -65 -41 -2 0 -2 8 0 18 3 9 32 33 65 52 89 52 104 71 104 138 l0 56 58 47
c73 58 82 72 112 157 52 152 60 235 60 662 0 217 1 399 3 403 1 5 10 7 20 5
16 -3 17 -27 13 -358z m-58 198 c18 -404 1 -794 -34 -773 -16 11 -56 79 -85
146 -22 52 -28 85 -33 180 -6 106 -9 121 -36 164 -18 29 -53 64 -87 88 -32 21
-58 42 -58 46 0 4 59 26 130 50 152 50 160 58 167 159 5 76 9 92 21 92 5 0 12
-68 15 -152z m118 68 c4 -43 12 -88 19 -100 8 -17 44 -33 146 -66 74 -24 134
-47 134 -50 0 -3 -27 -25 -60 -48 -33 -24 -72 -62 -87 -85 -24 -37 -27 -54
-30 -147 -2 -58 -9 -121 -15 -140 -15 -48 -71 -160 -96 -189 -18 -24 -20 -24
-25 -5 -25 85 -37 539 -20 808 7 127 25 138 34 22z m-430 -262 c75 -51 98 -73
120 -116 18 -35 26 -88 13 -88 -28 0 -112 68 -181 147 -54 62 -56 66 -38 79
26 19 25 19 86 -22z m794 22 c19 -13 17 -16 -29 -68 -87 -95 -162 -158 -187
-158 -28 0 11 110 52 146 30 27 129 94 138 94 4 0 16 -6 26 -14z m-780 -232
c-16 -17 -38 -43 -48 -58 -22 -32 -86 -66 -125 -66 -42 0 -121 -26 -144 -47
-20 -18 -20 -18 -13 47 18 155 63 259 132 308 l47 32 91 -92 90 -93 -30 -31z
m908 157 c49 -44 84 -145 102 -291 7 -53 7 -54 -11 -39 -29 23 -68 38 -126 45
-73 10 -112 28 -140 65 -12 17 -36 45 -53 62 l-30 32 90 92 c89 92 89 93 115
77 14 -9 38 -28 53 -43z m-816 -161 c29 -11 30 -14 36 -83 3 -40 15 -98 27
-130 12 -32 19 -61 16 -65 -13 -12 -125 -14 -162 -2 -44 14 -89 52 -110 92
-22 42 -17 62 17 77 16 7 42 32 57 55 43 68 60 76 119 56z m581 -8 c9 -11 25
-33 34 -51 9 -17 33 -41 53 -52 39 -24 40 -27 21 -72 -17 -41 -62 -81 -105
-96 -36 -12 -122 -13 -158 -3 l-21 7 25 60 c17 38 28 86 31 132 4 67 7 73 31
82 41 15 70 13 89 -7z m-810 -174 c2 -23 -11 -80 -32 -148 -20 -60 -36 -118
-37 -127 0 -13 -3 -11 -11 7 -10 26 -4 282 8 301 3 6 20 9 37 7 29 -3 32 -7
35 -40z m1027 10 c3 -18 5 -93 3 -166 l-3 -133 -39 123 c-52 168 -51 208 3
208 26 0 31 -4 36 -32z m-872 -129 c40 -8 73 -9 116 -2 34 5 61 6 61 2 0 -4
16 -29 36 -54 41 -55 41 -79 -3 -178 -20 -45 -40 -69 -84 -103 -31 -24 -63
-57 -71 -74 -7 -16 -15 -30 -18 -30 -12 0 -79 77 -95 109 -15 31 -57 253 -68
364 l-5 47 38 -34 c26 -23 56 -38 93 -47z m742 -145 c-29 -173 -48 -222 -107
-268 l-33 -25 -16 31 c-9 18 -41 50 -70 73 -59 44 -90 96 -110 182 -11 49 -11
53 14 82 14 17 29 40 32 52 6 19 12 20 80 14 88 -9 169 11 205 51 l25 26 3
-30 c2 -16 -9 -101 -23 -188z m-998 82 c0 -62 3 -122 7 -132 5 -15 -2 -24 -30
-39 -19 -10 -51 -36 -70 -57 l-34 -38 0 125 0 124 29 22 c16 12 44 41 61 65
17 24 32 44 33 44 1 0 3 -51 4 -114z m1238 19 l40 -40 1 -121 2 -121 -34 37
c-18 20 -49 45 -69 56 -28 14 -35 24 -31 39 3 11 6 72 6 135 l1 115 22 -30
c12 -16 40 -48 62 -70z m-1387 -220 c-4 -67 -12 -117 -26 -153 -20 -52 -81
-156 -88 -149 -5 5 23 223 36 281 10 47 41 117 64 145 16 21 19 -6 14 -124z
m1511 41 c17 -47 65 -335 57 -344 -7 -6 -69 100 -88 150 -23 61 -42 208 -33
258 l7 35 18 -23 c11 -13 28 -47 39 -76z m-1369 -120 c-28 -74 -128 -286 -137
-286 -3 0 -3 49 -1 110 7 139 33 202 106 250 46 31 47 31 50 9 2 -12 -6 -49
-18 -83z m1178 90 c43 -23 90 -77 108 -126 16 -45 33 -250 20 -250 -15 0 -166
340 -166 375 0 19 4 19 38 1z m-858 -248 c0 -6 -8 -17 -17 -25 -34 -27 -125
-85 -128 -81 -2 2 0 46 5 98 5 52 10 109 10 125 0 30 1 29 65 -38 36 -37 65
-73 65 -79z m673 -107 c-6 -10 -143 91 -143 105 0 9 28 45 63 81 l62 65 10
-123 c6 -68 9 -126 8 -128z m418 22 c19 -26 44 -69 55 -95 15 -31 40 -59 76
-85 29 -22 59 -51 67 -65 7 -14 24 -32 37 -39 13 -7 24 -16 24 -21 0 -13 -35
-9 -56 7 -26 20 -44 19 -44 -3 0 -10 20 -60 45 -111 38 -76 45 -98 39 -126 -6
-31 -10 -35 -39 -35 -18 0 -38 7 -45 15 -7 8 -18 15 -26 15 -18 0 -133 136
-151 180 -10 22 -13 52 -10 85 4 41 -1 62 -24 114 -27 59 -29 73 -29 180 l0
116 24 -42 c13 -23 39 -63 57 -90z m-1597 -155 c-20 -41 -27 -69 -28 -128 -1
-62 -6 -82 -26 -110 -43 -62 -123 -150 -135 -150 -7 0 -18 -7 -25 -15 -7 -8
-27 -15 -45 -15 -30 0 -33 3 -39 35 -6 29 1 50 39 126 25 51 45 100 45 110 0
23 -18 24 -44 4 -22 -17 -66 -20 -66 -5 0 6 6 10 12 10 7 0 37 25 68 54 30 30
74 73 96 94 23 22 44 52 48 66 3 14 32 64 64 111 l57 86 3 -110 c2 -102 1
-114 -24 -163z m598 103 c52 -61 158 -254 158 -288 0 -42 -58 -105 -143 -155
-40 -23 -87 -52 -104 -66 l-33 -24 0 83 c-1 125 -33 185 -112 204 -25 6 -28
12 -28 50 0 23 3 63 6 89 6 41 12 51 52 79 117 82 154 87 204 28z m521 -11
c74 -49 74 -50 80 -102 12 -95 9 -128 -10 -128 -32 0 -82 -39 -103 -79 -15
-31 -20 -59 -20 -127 l0 -86 -22 16 c-13 9 -54 34 -93 57 -78 46 -139 100
-161 142 -18 33 -9 57 78 205 101 174 128 185 251 102z m-714 -275 c44 -38 56
-84 51 -200 -4 -123 -4 -227 4 -415 7 -165 0 -163 -39 15 -53 244 -75 373 -81
498 -7 124 -7 127 14 127 11 0 34 -11 51 -25z m798 -77 c-3 -57 -13 -150 -22
-207 -18 -124 -83 -428 -96 -456 -6 -13 -7 35 -3 135 7 179 8 251 3 410 -3 94
0 121 14 147 21 39 63 73 90 73 19 0 20 -4 14 -102z m-467 -233 c-11 -127 -23
-294 -27 -372 -3 -79 -10 -143 -14 -143 -32 0 -42 252 -21 558 8 121 9 123 42
157 18 19 35 33 36 32 2 -2 -6 -106 -16 -232z m130 45 c20 -256 10 -560 -18
-560 -5 0 -14 91 -20 203 -7 111 -19 270 -27 352 -20 219 -22 207 20 165 35
-35 35 -36 45 -160z m-219 -179 c-11 -187 -12 -196 -42 -251 -16 -31 -52 -79
-79 -105 l-49 -48 2 159 c1 87 -2 211 -7 275 l-9 116 29 32 c16 17 58 49 94
71 l65 38 3 -46 c2 -26 -1 -134 -7 -241z m366 210 c60 -42 67 -66 57 -186 -5
-55 -8 -174 -8 -263 l2 -164 -47 46 c-26 25 -62 71 -81 102 l-34 56 -7 181
c-5 100 -10 208 -13 239 -2 31 -2 59 1 62 6 6 63 -26 130 -73z m-364 -506 c3
-30 15 -72 28 -94 21 -39 21 -41 5 -88 -9 -26 -22 -64 -28 -83 l-10 -35 -26
50 c-14 28 -42 72 -63 99 l-37 50 44 50 c24 28 50 63 57 79 6 15 15 27 18 27
3 0 9 -25 12 -55z m325 -42 c23 -29 42 -55 42 -58 0 -3 -24 -41 -52 -84 -29
-43 -58 -86 -65 -97 -11 -18 -12 -18 -12 -1 -1 11 -10 49 -22 87 l-20 68 20
33 c11 18 24 60 27 93 7 60 7 60 23 36 9 -14 35 -48 59 -77z m-478 -131 c0
-20 7 -82 15 -137 38 -253 47 -335 41 -359 -8 -32 -39 -50 -64 -37 -44 24 -63
199 -38 360 9 58 16 128 16 156 0 28 3 55 6 58 13 13 24 -5 24 -41z m581 -48
c4 -49 12 -116 18 -148 25 -136 0 -323 -46 -340 -25 -10 -52 13 -58 48 -4 19
5 116 19 217 14 101 29 215 32 252 10 96 28 81 35 -29z m-55 9 c-4 -43 -13
-118 -21 -168 -8 -49 -21 -148 -29 -218 -15 -130 -24 -157 -56 -157 -23 0 -25
6 -41 133 -16 134 -4 219 46 325 32 67 92 162 103 162 3 0 2 -35 -2 -77z
m-459 19 c48 -70 90 -171 104 -247 10 -53 10 -87 0 -168 -16 -134 -20 -147
-41 -147 -31 0 -40 24 -55 146 -8 66 -23 180 -35 254 -21 141 -25 210 -12 210
4 0 21 -21 39 -48z m13 -545 c11 -23 23 -33 43 -35 24 -3 28 -7 23 -25 -3 -12
-6 -64 -6 -114 0 -85 -2 -93 -19 -93 -22 0 -54 -38 -45 -52 4 -5 13 0 21 11
18 26 55 27 78 1 15 -16 16 -30 10 -97 -3 -43 -9 -154 -13 -248 -6 -139 -10
-170 -22 -172 -8 -2 -43 15 -77 37 -62 39 -63 40 -63 84 0 25 9 82 20 128 14
60 20 118 20 209 0 111 -4 138 -29 220 -16 52 -32 109 -36 127 -6 30 -5 33 12
28 10 -3 28 1 39 9 26 20 27 19 44 -18z m468 10 c12 0 22 -1 22 -3 0 -3 -16
-58 -35 -123 -32 -108 -35 -128 -35 -249 0 -96 5 -153 20 -214 23 -96 25 -148
8 -169 -22 -27 -117 -81 -132 -76 -11 4 -15 44 -20 184 -3 98 -9 206 -12 241
-4 48 -2 68 10 87 21 31 59 33 80 3 21 -30 33 -15 12 17 -9 14 -26 25 -39 25
-21 0 -22 3 -22 115 -1 114 0 115 23 115 15 0 29 10 40 30 13 23 22 28 37 23
11 -4 30 -7 43 -6z"
        />
        <path
          d="M4228 3398 c-108 -110 -109 -118 -33 -214 31 -38 72 -100 93 -137 61
-113 67 -100 70 171 2 230 -3 273 -31 271 -7 0 -51 -41 -99 -91z m110 -169
l-3 -224 -34 60 c-18 33 -58 90 -87 127 -30 37 -54 77 -54 89 0 25 160 190
173 179 4 -3 6 -107 5 -231z"
        />
        <path
          d="M4400 3470 c-7 -12 -9 -103 -8 -252 3 -273 9 -285 71 -168 21 39 63
100 93 136 76 93 74 101 -36 213 -50 50 -95 91 -100 91 -5 0 -14 -9 -20 -20z
m190 -191 c0 -14 -22 -49 -54 -88 -30 -35 -69 -91 -88 -125 l-33 -61 -3 220
c-1 121 0 225 2 232 3 7 40 -22 91 -71 61 -59 85 -89 85 -107z"
        />
      </g>
      {props.mode === "Add" ? 
      props.positionOptions.map((item, index) => (
        <circle
          key={index}
          cx={item.x}
          cy={item.y}
          r={10}
          style={{ fill: "grey", stroke: "black" }}
          onClick={(e) => { 
            props.onEvent({ 
              message: "click",
              params: {
                name: "position",
                value: index,
              }
            })}}
        />
      )) :
      props.position.map((item, index) => (
        <circle
          key={index}
          cx={item.x}
          cy={item.y}
          r={10}
          style={{ fill: (props.mode === "Edit" && index === props.editId) ? "green": "yellow", stroke: "red" }}
          onClick={(e) => { 
            props.onEvent({ 
              message: "click",
              params: {
                name: "position",
                value: index,
              }
            })}}
        />
      ))}
    </svg>
  );
  
};

export const CardBodyOutlineInitialData: CardBodyOutlineType = {
  width: 450,
  height: 500,
  viewBox: "0 0 450 500",
  organs: [
    {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
  ],
};

CardBodyOutline.defaultProps = CardBodyOutlineInitialData;

export default CardBodyOutline;
