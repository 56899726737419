import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Input
} from 'semantic-ui-react'

const ANES_ChargeItem_SetupTemplate = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{height:"100%",width:"100%",display:"flex",flexDirection: "column"}}>
        
        <div
          style={{flexGrow: 1,display:"flex",flexDirection: "column", marginTop: "20px" }}>
          
          <div
            style={{width:"100%", flexGrow:1}}>
            
            <label
              style={{fontSize: "250%", fontWeight:"bold"}}>
              Setup template
            </label>
          </div>
        </div>
        <div
          style={{width:"100%", flexGrow:1,display:"flex", margin: "15px 0px" }}>
          
          <div
            style={{flexGrow: 1,display:"flex",flexDirection:"column"}}>
            {props.buttonCreateTemplate}
          </div>
          <div
            style={{flexGrow: 24,display:"flex",flexDirection:"column"}}>
            
          </div>
          <div
            style={{flexGrow: 1,display:"flex",flexDirection:"column"}}>
            {props.buttonCreateItem}
          </div>
        </div>
        <div
          style={{flexGrow: 50,display:"flex"}}>
          
          <div
            style={{flexGrow: 1,display:"flex",flexDirection:"column"}}>
            
            <div
              style={{height:"100%",flexShrink:15}}>
              
              <Input
                icon="search"
                iconPosition="left"
                onChange={props.onChangeSearchTemplateName}
                onKeyDown={props.onSearchTemplateName}
                style={{width:"100%"}}
                value={props.searchTemplateName || ""}>
              </Input>
            </div>
            <div
              style={{height:"100%"}}>
              {props.listTemplateName}
            </div>
          </div>
          <div
            style={{flexGrow: 7, display:"flex",flexDirection: "column"}}>
            
            <div
              style={{flexGrow: 1, display:"flex"}}>
              
              <Button
                color={props.tab === "Drug" ? "blue" : "white"}
                name="Drug"
                onClick={props.onChangeTab}
                style={{ textalign: "center",  fontSize:"16px", width:"33%" }}>
                รายการยา
              </Button>
              <Button
                color={props.tab === "Supply" ? "blue" : "white"}
                name="Supply"
                onClick={props.onChangeTab}
                style={{ textalign: "center",  fontSize:"16px", width:"33%" }}>
                รายการเวชภัณฑ์
              </Button>
              <Button
                color={props.tab === "Operative" ? "blue" : "white"}
                name="Operative"
                onClick={props.onChangeTab}
                style={{ textalign: "center",  fontSize:"16px", width:"33%" }}>
                รายการหัตถการ
              </Button>
            </div>
            <Table
              columns={props.columnTemplate}
              data={props.templateData || []}
              minRows={10}>
            </Table>
          </div>
        </div>
      </div>
    )
}


export default ANES_ChargeItem_SetupTemplate

export const screenPropsDefault = {}

/* Date Time : Wed May 10 2023 10:14:08 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\",display:\"flex\",flexDirection: \"column\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flexGrow: 1,display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flexGrow: 7, display:\"flex\",flexDirection: \"column\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flexGrow: 1,display:\"flex\",flexDirection: \"column\", marginTop: \"20px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexGrow:1}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flexGrow: 50,display:\"flex\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 6,
      "name": "Table",
      "parent": 2,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnTemplate"
        },
        "data": {
          "type": "code",
          "value": "props.templateData || []"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flexGrow: 1, display:\"flex\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยา"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"Drug\" ? \"blue\" : \"white\""
        },
        "name": {
          "type": "value",
          "value": "Drug"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "style": {
          "type": "code",
          "value": "{ textalign: \"center\",  fontSize:\"16px\", width:\"33%\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการเวชภัณฑ์"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"Supply\" ? \"blue\" : \"white\""
        },
        "name": {
          "type": "value",
          "value": "Supply"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "style": {
          "type": "code",
          "value": "{ textalign: \"center\",  fontSize:\"16px\", width:\"33%\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการหัตถการ"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"Operative\" ? \"blue\" : \"white\""
        },
        "name": {
          "type": "value",
          "value": "Operative"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "style": {
          "type": "code",
          "value": "{ textalign: \"center\",  fontSize:\"16px\", width:\"33%\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexGrow:1,display:\"flex\", margin: \"15px 0px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Setup template"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"250%\", fontWeight:\"bold\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flexGrow: 24,display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",flexShrink:15}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.listTemplateName"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Input",
      "parent": 18,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "iconPosition": {
          "type": "value",
          "value": "left"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchTemplateName"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onSearchTemplateName"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchTemplateName || \"\""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCreateTemplate"
        },
        "style": {
          "type": "code",
          "value": "{flexGrow: 1,display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCreateItem"
        },
        "style": {
          "type": "code",
          "value": "{flexGrow: 1,display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 22,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ChargeItem_SetupTemplate",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
