import React, { useEffect } from 'react';
import CardLayout from '../common/CardLayout';
import { Table } from 'semantic-ui-react'

const YES_NO = {
  true: 'YES',
  false: 'NO',
}

const styles = {
  gridHeader: {
    backgroundColor: '#E0E0E0'
  }
}

const EDUCATION_EVALUATION = {
  EXP: 'เข้าใจดี/ทำได้ดี',
  EAM: 'ยังมีข้อสงสัย/ทำได้บางส่วน ทบทวน',
  VDO: 'ทำซ้ำในส่วนที่ยังไม่เข้าใจ หรือทำได้ไม่ดี',
  LLT: 'ไม่เข้าใจ/ทำไม่ได้ต้องสอนใหม่',
}

const CardDischargeTeachingView = (props) => {

  const reassessmentData = props.reassessmentData ? props.reassessmentData : {}

  useEffect(() => {
    if (props.encounterType === 'IPD') {
      return
    }
    props.onGetDischargeTeaching({})
  }, [])

  return (
    <CardLayout
      titleText='Discharge Teaching(Patient Education)'
      headerColor='violet'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.reassessmentLoading}
      enableMargin={props.enableMargin}
    >
      {props.encounterType === 'IPD' ? 'No Data.' :
        <div style={{ maxHeight: 480, overflowY: 'auto', overflowX: 'hidden' }}>
          <Table celled>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  Patient and Family Education Needed
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.data ? YES_NO[reassessmentData.data.education_need] : ''}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  วันที่ - เวลา
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.edited}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  ผู้บันทึก/แก้ไข
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.data ? reassessmentData.data.user_fullname : ''}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  ข้อจำกัดในการรับรู้
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.data ? YES_NO[reassessmentData.data.barriers] + ' ' + reassessmentData.data.barriers_detail : ''}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  Readiness and Willingness
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.data ? YES_NO[reassessmentData.data.readiness] + ' ' + reassessmentData.data.readiness_detail : ''}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  ผู้รับคำแนะนำ
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.data ? reassessmentData.data.education_way : ''}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  วิธีการให้ความรู้
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.data ? reassessmentData.data.education_method : ''}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  คำแนะนำที่ให้
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.data ? reassessmentData.data.education : ''}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  Education Evaluation
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.data ? EDUCATION_EVALUATION[reassessmentData.data.education_evaluation] + ' ' + reassessmentData.data.education_evaluation_detail : ''}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing style={styles.gridHeader}>
                  Follow up
              </Table.Cell>
                <Table.Cell>
                  {reassessmentData.data ? YES_NO[reassessmentData.data.follow_up] + ' ' + reassessmentData.data.follow_up_detail : ''}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      }
    </CardLayout>
  )
}

CardDischargeTeachingView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  onGetDischargeTeaching: () => { },
  reassessmentLoading: false,
  reassessmentData: {},
  enableMargin: true,
};

export default CardDischargeTeachingView;
