import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Header, Button, Form, Input, Checkbox } from 'semantic-ui-react';
import ReactTable from 'react-table-6'
import CardLayout from '../../../react-lib/apps/common/CardLayout';
import _ from '../../../react-lib/compat/lodashplus';

// Data selected
var selectData = []
const CardICD10Template = (props) => {
	const [templateData, setTemplateData] = useState([])
	const [triggerUI, setTriggerUI] = useState(false)

	const handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			// Handle 'Enter' Key
			searchTemplate(event.target.value)
		}
	}

	const searchTemplate = async (name) => {
		let params = {}
		if (name) {
			params.name = name
		}
		let data = await props.onSearchTemplate(params)
		setTemplateData(data)
	}

	const openManageTemplate = () => {
		window.open(props.manageTemplateUrl)
	}

	/**
	 * Handle 'Check Box'
	 * @param {*} selected 
	 * @param {*} ev 
	 * @param {*} data 
	 */
	const selectTemplate = (selected, ev, data) => {
		if (data.checked) {
			if (hasPrimary(selected.details)) {
				// Remove template that they are diagnosis type with primary
				var removeId = null
				for (var target of selectData) {
					if (hasPrimary(target.details)) {
						removeId = target.id
					}
				}
				if (removeId != null) { removeListWithId(removeId, selectData) }
			}
			selectData.push(selected)
		} else {
			removeListWithId(selected.id, selectData)
		}
		setTriggerUI(!triggerUI)
	}

	const removeListWithId = (targetId, dataList) => {
		_.remove(dataList, (value) => {
			if (targetId === value.id) {
				return true
			}
			return false
		})
	}

	const hasPrimary = (dataList) => {
		var result = false
		for (var data of dataList) {
			if (data.type === DIAGNOSIS_TYPE_PRIMARY) {
				result = true
				break
			}
		}
		return result
	}

	/**
	 * Handle 'Select' Button
	 */
	const handleSelect = () => {
		var primary = null
		var secondaries = []
		for (var data of selectData) {
			var diagnosisList = data.details
			for (var diagnosis of diagnosisList) {
				switch (diagnosis.type) {
					case DIAGNOSIS_TYPE_PRIMARY:
						if (!primary) {
							primary = diagnosis
						}
						break

					case DIAGNOSIS_TYPE_SECONDARY:
						secondaries.push(diagnosis)
						break

					default:
						break
				}
			}
		}
		props.onSelectTemplate(primary, secondaries)
		props.onClose()
	}

	const columns = [
		{
			Header: '..',
			style: { whiteSpace: 'unset' },
			accessor: 'check',
			width: 70,
			Cell: props => {
				const data = props.original
				var isCheck = false
				for (var s of selectData) {
					if (s.id === data.id) {
						isCheck = true
						break
					}
				}
				return (
					<Checkbox checked={isCheck} onChange={selectTemplate.bind(this, data)} />
				)
			}
		},
		{
			Header: 'ชื่อโรค Template',
			style: { 'whiteSpace': 'unset' },
			accessor: 'name',
			width: 290
		},
		{
			Header: 'ICD10',
			style: { 'whiteSpace': 'unset' },
			Cell: props => {
				const data = props.original
				return (
					<div dangerouslySetInnerHTML={{ __html: data.display_text }} />
				)
			}
		}
	]

	useEffect(() => {
		searchTemplate()
		selectData = []
	}, [])

	return (
		<CardLayout
			titleText={'เลือก Template การวินิจฉัย'}
			headerColor={null}
			toggleable={false}
			closeable={true}
			onClose={props.onClose}
			titleContent={
				<Form>
					<Form.Group inline>
						<Form.Field>
							<Header size={'tiny'} textAlign={'right'}>Filter</Header>
						</Form.Field>
						<Form.Field>
							<Input onKeyDown={handleKeyDown.bind(this)} />
						</Form.Field>
						<Form.Field>
							<Button type={'button'} color={'orange'} onClick={openManageTemplate.bind(this)}>จัดการ Template</Button>
						</Form.Field>
					</Form.Group>
				</Form>
			}>

			<ReactTable
				style={{ height: '450px' }}
				noDataText='ไม่มีข้อมูล'
				showPagination={false}
				defaultPageSize={150}
				minRows={14}
				columns={columns}
				data={templateData}
			/>

			<Form style={{ marginTop: '16px' }}>
				<Form.Group inline>
					<Form.Field style={{ width: '70%' }} />
					<Form.Field style={{ width: '15%' }}>
						<Button fluid color={'red'} onClick={props.onClose}>ยกเลิก</Button>
					</Form.Field>
					<Form.Field style={{ width: '15%' }}>
						<Button fluid type={'button'} color={'green'} onClick={handleSelect.bind(this)}>เลือก</Button>
					</Form.Field>
				</Form.Group>
			</Form>

		</CardLayout>
	)
};

const DIAGNOSIS_TYPE_PRIMARY = 1
const DIAGNOSIS_TYPE_SECONDARY = 2

CardICD10Template.defaultProps = {
	onClose: () => { },
	onSearchTemplate: () => { return [] },
	onSelectTemplate: (pSelected, sSelected) => { },
	manageTemplateUrl: ''
}

CardICD10Template.propTypes = {
	onClose: PropTypes.func,
	onSearchTemplate: PropTypes.func,
	onSelectTemplate: PropTypes.func,
	manageTemplateUrl: PropTypes.string
}

export default CardICD10Template