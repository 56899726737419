import React, { useEffect} from 'react'
import CardAllergyActionLogUX from './CardAllergyActionLogUX'


const CardAllergyActionLog = (props:any) => {


  useEffect(() => {
    
    if (props.id) {
      props.runSequence({ sequence: "Allergy" , action: "getActionLog", id: props.id});
    }
 
    }, [props.id])
  

  return (
    <CardAllergyActionLogUX 
      onDismiss={props.onDismiss}
      actionLogData={props.actionLogData} 
    />
  )
}

export default CardAllergyActionLog