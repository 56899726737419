import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Dropdown
} from 'semantic-ui-react'

const ModDrugDiseaseISegmentGroupUX = (props: any) => {
    return(
      <div>
        <div
          style={{backgroundColor: "#0072BC", padding: "1rem", display: props.hideHeader ? "none" : "" }}>
          
          <label
            style={{color:"white", fontSize: "1.2rem"}}>
            สร้าง Segment Group ใหม่
          </label>
        </div>
        <div
          className="ui form"
          style={{padding: "30px 2rem 30px 2rem", ...(props.contentStyle || {})}}>
          
          <div>
            {props.ErrorMessage}
          </div>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", justifyContent: "flex-end", width: "110px"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  ประเภทกลุ่มยา
                </label>
                <label
                  style={{color: "red"}}>
                  * 
                </label>
                <label
                  style={{paddingLeft: "2.5px"}}>
                  :
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={11}>
              <Dropdown
                fluid={true}
                name="drug_type"
                onChange={props.onChangeValue}
                options={props.drugTypeOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.drugType || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", justifyContent: "flex-end", width: "110px"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  ชื่อยา/กลุ่มยา
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
                <label
                  style={{paddingLeft: "2.5px"}}>
                  :
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={11}>
              <Dropdown
                disabled={props.disabledDrug || false}
                fluid={true}
                name="drug_id"
                onChange={props.onChangeValue}
                options={props.drugByTypeOptions|| []}
                search={true}
                selection={true}
                style={{width: "100%",display: props.drugType === "INGREDIENT" ? "none": ""}}
                value={props.drugId || ""}>
              </Dropdown>
              <div
                style={{display: props.drugType === "INGREDIENT" ? "": "none", width: "100%"}}>
                {props.SearchBox}
              </div>
            </FormField>
          </FormGroup>
          <div
            style={{display: props.hideAction ? "none" : "flex",  justifyContent: "center", marginTop: "2rem", padding: "0 4rem"}}>
            
            <div
              style={{width: "200px"}}>
              {props.ButtonSave}
            </div>
            <Button
              color="red"
              onClick={props.onCancel}
              size="small"
              style={{width: "200px", marginLeft: "3rem"}}>
              ยกเลิอก
            </Button>
          </div>
        </div>
      </div>
    )
}

export default React.memo(ModDrugDiseaseISegmentGroupUX)

export const screenPropsDefault = {}

/* Date Time : Wed Feb 15 2023 08:06:47 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#0072BC\", padding: \"1rem\", display: props.hideHeader ? \"none\" : \"\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้าง Segment Group ใหม่"
        },
        "style": {
          "type": "code",
          "value": "{color:\"white\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"30px 2rem 30px 2rem\", ...(props.contentStyle || {})}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"110px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อยา/กลุ่มยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"110px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทกลุ่มยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "* "
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"2.5px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideAction ? \"none\" : \"flex\",  justifyContent: \"center\", marginTop: \"2rem\", padding: \"0 4rem\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Button",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิอก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\", marginLeft: \"3rem\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"2.5px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.drugTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.drugType || \"\""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledDrug || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.drugByTypeOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",display: props.drugType === \"INGREDIENT\" ? \"none\": \"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.drugId || \"\""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBox"
        },
        "style": {
          "type": "code",
          "value": "{display: props.drugType === \"INGREDIENT\" ? \"\": \"none\", width: \"100%\"}"
        }
      },
      "seq": 40,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": true,
  "name": "ModDrugDiseaseISegmentGroupUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
