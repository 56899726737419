import React, { useState, useEffect, useMemo, SyntheticEvent } from "react";
import { Button, DropdownProps, Form, Label, Message, Modal, Popup } from "semantic-ui-react";
import { EmployeeToken, ErrorMessage } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import CardOPDOrderSummaryUX from "./CardOPDOrderSummaryUX";
import ModNursePerformOrderUX from "./ModNursePerformOrderUX";
import htmlParse from "html-react-parser";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

const STATUS_COLORS: Record<string, string> = {
  CANCEL: "#7f7f7f",
  PENDING: "#ffa500",
  PERFORMED: "#008000",
};

const CARD_OPD_SUMMARY = "cardOPDOrderSummary";

const CardOPDOrderSummary = (props: any) => {
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [oneDayCancelReason, setOneDayCancelReason] = useState<string>("");

  useEffect(() => {
    props.onEvent({
      message: "HandleOPDOrderSummary",
      params: { action: "search", card: CARD_OPD_SUMMARY },
    });
  }, []);

  const handleOnCheckedChange =
    (name: string) =>
    (e: any, { checked }: any) => {
      if (name === "isOrder") {
        props.setProp(`orderSummaryOPDFilter`, {
          ...props.orderSummaryOPDFilter,
          isOrder: checked,
          ...(!checked ? { order: null } : {}),
        });
      } else {
        props.setProp(`orderSummaryOPDFilter.${name}`, checked);
      }
    };

  const handleDeleteOPDOrderSummary = (item: any) => {
    setSelectedOrder(item);
  };

  const orderItems = useMemo(() => {
    return (props.orderSummaryOPDList || [])?.map((item: any) => {
      let orderStatus = item.order_status;

      // centrallaborder ต้องดูสถานะ report ถึงจะแสดงแถบสีเขียว
      if (item.specific_type_model_name === "centrallaborder") {
        if (item.order_status === "PERFORMED") {
          orderStatus = item.specific_status === "REPORTED" ? "PERFORMED" : "PENDING";
        }
      }

      const background = STATUS_COLORS[orderStatus] || "";

      return {
        ...item,
        order_time: (
          <div style={{ height: "100%", width: "100%", padding: "5px" }}>{item.order_time}</div>
        ),
        order_ack_time: (
          <div style={{ height: "100%", width: "100%", padding: "5px" }}>{item.order_ack_time}</div>
        ),
        order_ack_by_name: (
          <div style={{ height: "100%", width: "100%", padding: "5px" }}>
            {item.order_ack_by_name}
          </div>
        ),
        detail: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                height: "100%",
                width: "100%",
                padding: "5px",
                textAlign: "left",
              }}
            >
              {item.order_status === "CANCEL" ? (
                <del>
                  {item.order_summary_detail_list.map((i: any, index: number) => (
                    <div>{htmlParse(typeof i === "string" ? i : i.detail)}</div>
                  ))}
                  {item.verbal_description && (
                    <div>
                      <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
                      <span>{item.verbal_description}</span>
                    </div>
                  )}
                </del>
              ) : (
                <>
                  {item.order_summary_detail_list.map((i: any, index: number) => (
                    <>
                      <div style={{display: "flex"}}>
                        {i.to_be_performer_name && (<Label basic color="blue" style={{ marginRight: "10px", height: "30px" }}>
                          {i.to_be_performer_name}
                        </Label>)}
                        <div>{htmlParse(typeof i === "string" ? i : i.detail)}</div>
                      </div>
                      {i?.order_detail && (
                        <div style={{ color: "gray" }}>
                          <b>รายละเอียดเพิ่มเติม : </b>
                          {i.order_detail}
                        </div>
                      )}
                      {i?.equipment_detail && (
                        <div style={{ color: "gray" }}>
                          <b>อุปกรณ์ : </b>
                          {i.equipment_detail}
                        </div>
                      )}
                    </>
                  ))}
                  {item.verbal_description && (
                    <div>
                      <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
                      <span>{item.verbal_description}</span>
                    </div>
                  )}
                </>
              )}
            </div>

            {item.specific_type_name === "NORMAL" && item.specific_label !== "Cancel" && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  padding: "5px",
                  textAlign: "right",
                }}
              >
                <PopupButton
                  onEvent={props.onEvent}
                  item={item}
                  django={props.django}
                  setSelectedOrder={setSelectedOrder}
                  onDeleteOPDOrderSummary={handleDeleteOPDOrderSummary}
                />
              </div>
            )}
          </div>
        ),
        status: (
          <div
            style={{
              height: "100%",
              width: "100%",
              padding: "5px",
              background,
            }}
          >
            <div>{item.order_status === "PERFORMED" ? "Complete" : item.specific_label}</div>
            <div>{item.specific_order_by}</div>
          </div>
        ),
      };
    });
  }, [props.orderSummaryOPDList, props.django]);

  const handleOneDayCancelReasonChange = (e: any, v: any) => {
    setOneDayCancelReason(v.value as string);
  };

  const handleApproveOneDayCancel = () => {
    props.onEvent({
      message: "HandleDeleteOPDOrderSummary",
      params: {
        id: selectedOrder?.id,
        note: oneDayCancelReason,
        card: `${CARD_OPD_SUMMARY}_delete`,
        callback: (oneDayCancel: any, selectedOrder: any) => {
          setOneDayCancelReason(oneDayCancel);
          setSelectedOrder(selectedOrder);
        },
      },
    });
  };

  const handleOpenModNursePerform = (rowInfo: any) => () => {
    const isNurse = props.django?.user.role_types.includes("REGISTERED_NURSE");

    const order = props.orderSummaryOPDList?.[rowInfo?.index];

    if (!(isNurse && order)) {
      return;
    }

    props.onEvent({
      message: "HandleOPDOrderSummary",
      params: {
        action: "open",
        card: CARD_OPD_SUMMARY,
        order,
      },
    });
  };

  console.log(CARD_OPD_SUMMARY, " props:", props);

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_OPD_SUMMARY}`, null);
          props.setProp(`successMessage.${CARD_OPD_SUMMARY}`, null);
        }}
        error={props.errorMessage?.[CARD_OPD_SUMMARY]}
        success={props.successMessage?.[CARD_OPD_SUMMARY]}
      />
      <CardOPDOrderSummaryUX
        isOrder={props.orderSummaryOPDFilter?.isOrder}
        orderType={props.orderSummaryOPDFilter?.order}
        orderTypeOptions={props.orderSummaryOPDOptions}
        onOrderTypeChange={(e: any, data: any) => {
          props.setProp("orderSummaryOPDFilter.order", data.value);
        }}
        isLab={props.orderSummaryOPDFilter?.isLab}
        isTreatment={props.orderSummaryOPDFilter?.isTreatment}
        isImaging={props.orderSummaryOPDFilter?.isImaging}
        isOthers={props.orderSummaryOPDFilter?.isOthers}
        isOutPerformDiv={props.orderSummaryOPDFilter?.isOutPerformDiv}
        orderSummaryOPD={orderItems}
        onChangeChecked={handleOnCheckedChange}
        loadingSummary={props.loadingSummary}
        onCheckedAll={() => {
          props.setProp("orderSummaryOPDFilter", {
            ...props.orderSummaryOPDFilter,
            isLab: true,
            isTreatment: true,
            isImaging: true,
            isOthers: true,
            isOutPerformDiv: true,
          });
        }}
        onSearch={() => {
          props.onEvent({
            message: "HandleOPDOrderSummary",
            params: { action: "search", card: CARD_OPD_SUMMARY },
          });
        }}
        onSelectedOrder={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              props.nurseOPDOrderPerformed?.selectedOrder?.id &&
              rowInfo?.original?.id === props.nurseOPDOrderPerformed?.selectedOrder?.id
                ? "#cccccc"
                : "white",
          },
          onDoubleClick: handleOpenModNursePerform(rowInfo),
        })}
      />
      <Modal
        open={props.nurseOPDOrderPerformed?.modOpen}
        onClose={() => {
          props.setProp("nurseOPDOrderPerformed.modOpen", false);
        }}
      >
        <ModNursePerformOrderUX
          selectedDate={props.nurseOPDOrderPerformed?.performedDate}
          onChangeDate={(date: string) => {
            props.setProp("nurseOPDOrderPerformed.performedDate", date);
          }}
          selectedTime={props.nurseOPDOrderPerformed?.performedTime}
          onTimeChange={(time) => {
            props.setProp("nurseOPDOrderPerformed.performedTime", time);
          }}
          onSave={() => {
            props.onEvent({
              message: "HandleOPDOrderSummary",
              params: {
                action: "confirm_performed",
                card: CARD_OPD_SUMMARY,
              },
            });
          }}
          // component
          employeeCode={
            <EmployeeToken
              placeholder="ระบุ code employee"
              onEnterToken={(code) => {
                props.onEvent({
                  message: "HandleOPDOrderSummary",
                  params: { action: "tokenize", code: code },
                });
              }}
              onClearToken={() => {
                props.setProp("nurseOPDOrderPerformed", {
                  ...props.nurseOPDOrderPerformed,
                  nurseToken: "",
                  nurseName: "",
                  nurseLoading: false,
                  nurseError: null,
                });
              }}
              error={props.nurseOPDOrderPerformed.nurseError}
              loading={props.nurseOPDOrderPerformed.nurseLoading}
              employeeName={props.nurseOPDOrderPerformed.nurseName}
            />
          }
        />
      </Modal>

      <ModConfirm
        openModal={!!selectedOrder}
        titleName="ยืนยันการยกเลิก"
        content={
          <>
            {
              // @ts-ignore
              <Form>
                <Message>
                  {<div>{selectedOrder?.order_summary_detail_list?.[0]?.detail}</div>}
                </Message>
                <ErrorMessage error={props.errorMessage?.[`${CARD_OPD_SUMMARY}_delete`]} />
                <Form.Dropdown
                  onChange={handleOneDayCancelReasonChange}
                  label="ระบุเหตุผลในการยกเลิก"
                  options={[
                    { key: 1, text: "สั่งผิด", value: "สั่งผิด" },
                    {
                      key: 2,
                      text: "ต้องการเปลี่ยนรายการ",
                      value: "ต้องการเปลี่ยนรายการ",
                    },
                  ]}
                  value={oneDayCancelReason}
                  selection
                />
              </Form>
            }
          </>
        }
        onApprove={handleApproveOneDayCancel}
        onDeny={() => {
          setOneDayCancelReason("");
          setSelectedOrder(null);
          props.setProp("errorMessage", {
            ...props.errorMessage,
            [`${CARD_OPD_SUMMARY}_delete`]: null,
          });
        }}
      />
    </>
  );
};

const PopupButton = (props: any) => {
  const allowedAction = useMemo(() => {
    return props.django?.user.role_types.includes("DOCTOR");
  }, [props.django]);

  const handleDoubleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <Popup
      style={{ padding: "8px" }}
      on="click"
      hideOnScroll
      position="bottom right"
      trigger={<Button icon="list" size="mini" onDoubleClick={handleDoubleClick} />}
      content={
        <div>
          <Button
            color="yellow"
            size="mini"
            disabled={!allowedAction || !!props.item?.order_ack_by_name}
            // disabled={props.item?.specific_label === "Request" && props.item?.order_ack_by_name !== null}
            onClick={() => {
              props.onEvent({
                message: "HandleEditOPDOrderSummary",
                params: {
                  orderId: props.item?.id,
                },
              });
            }}
          >
            EDIT
          </Button>
          <Button
            color="red"
            size="mini"
            disabled={!allowedAction}
            // disabled={props.item?.specific_label === "Cancel"}
            onClick={() => props.onDeleteOPDOrderSummary(props.item)}
          >
            DELETE
          </Button>
        </div>
      }
    />
  );
};

export default CardOPDOrderSummary;
