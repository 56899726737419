import React, { useState, useEffect, useMemo } from "react";
import Tab from "react-lib/frameworks/Tab";
import { Button } from "semantic-ui-react";
import CardSummaryWaitingQueue from "./CardSummaryWaitingQueue";
import CardSummaryDoctor from "./CardSummaryDoctor";
import CardSummaryStatistics from "./CardSummaryStatistics";

const CardStatistics = (props: any) => {
  const [tab, setTab] = useState("queue");

  return (
    <>
      <div style={{ height: "100%", width: "100%", padding: "10px" }}>
        <div style={{ display: "flex" }}>
          <MenuItem menu="queue" tab={tab} name="ระยะเวลาการรอคิว (Patient Journey)" setTab={setTab} />
          <MenuItem menu="doctor" tab={tab} name="สรุปผลการปฏิบัติงานทันตแพทย์" setTab={setTab} />
          <MenuItem menu="statistics" tab={tab} name="สถิติการนัดหมาย" setTab={setTab} />
        </div>

        {tab === "queue" ? <CardSummaryWaitingQueue
         controller={props.controller}
         proxyController={props.proxyController}
         setProp={props.setProp}
         onEvent={props.onEvent}
          divisionList={props.divisionList}
           selectedDivision={props.selectedDivision}
           searchedItemListWithKey={props.searchedItemListWithKey}
          searchedItemList={props.searchedItemList}
          summaryWaitingQueueList={props.summaryWaitingQueueList}
         
        /> 
        : tab === "doctor" ? 
        <CardSummaryDoctor 
        controller={props.controller}
        proxyController={props.proxyController}
        setProp={props.setProp}
        onEvent={props.onEvent}
         divisionList={props.divisionList}
          selectedDivision={props.selectedDivision}
          searchedItemListWithKey={props.searchedItemListWithKey}
         searchedItemList={props.searchedItemList}
         summaryDoctorList={props.summaryDoctorList}
        
        
        /> 
        :  tab === "statistics" ? <CardSummaryStatistics 
        controller={props.controller}
        proxyController={props.proxyController}
        setProp={props.setProp}
        onEvent={props.onEvent}
        divisionList={props.divisionList}
        selectedDivision={props.selectedDivision}
        searchedItemListWithKey={props.searchedItemListWithKey}
        searchedItemList={props.searchedItemList}
        summaryStatisticsList={props.summaryStatisticsList}
        
        
        />
        : ""}
      </div>
    </>
  );
};

const MenuItem = (props: any) => (
  <Button size="tiny" onClick={(e: any) => props.setTab(props.menu)} color={props.menu === props.tab ? "blue" : undefined}>
    {props.name}
  </Button>
);

export default CardStatistics;
