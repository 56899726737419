import React, { useMemo, useState } from "react";

// Utils
import { Form, FormGroup, Dropdown, Radio } from "semantic-ui-react";

// Common
import { DateTextBox } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Constant
const PATIENT_TYPE: any[] = [
  { key: "OPD และ IPD", value: "OPD และ IPD", text: "OPD และ IPD" },
  { key: "OPD", value: "OPD", text: "OPD" },
  { key: "IPD", value: "IPD", text: "IPD" },
];

const CardPriceChangeOverviewSummaryFilter = (props: any) => {
  // useState
  const [requiredStartDate, setRequiredStartDate] = useState<boolean>(false);

  // useMemo
  const doctorOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แพทย์ทั้งหมด" },
      ...(props.masterOptions?.doctor || []),
    ];
  }, [props.masterOptions?.doctor]);

  const specialtyOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "ทุกสาขา" },
      ...(props.masterOptions?.specialty || []),
    ];
  }, [props.masterOptions?.specialty]);

  const handleSelectRadio = (optionSelected: any) => {
    switch (optionSelected) {
      case "daily":
      case "monthly":
      case "yearly":
        return (
          props.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport?.reportType ===
          `${optionSelected}`
        );
      default:
        console.warn("Option Doesn't Exist!!");
        break;
    }
  };

  const handleChangeRadio = (e: any, { value }: { value: string }) => {
    props.setProp(`ClinicAnnualReportSequence.priceChangeOverviewSummaryReport.reportType`, value);
  };

  const handlePreviewButton = () => {
    if (requiredStartDate) {
      alert("กรุณาระบุวันที่รับบริการ");
    } else {
      props.runSequence({
        sequence: "ClinicAnnualReport",
        action: "PREVIEW_REPORT",
        menu: "รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์",
      });
    }
  };

  const handlePrintButton = () => {
    if (requiredStartDate) {
      alert("กรุณาระบุวันที่รับบริการ");
    } else {
      props.runSequence({
        sequence: "ClinicAnnualReport",
        action: "PRINT_REPORT",
        menu: "รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์",
      });
    }
  };

  return (
    <Form>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label style={{ display: "flex", alignItems: "center" }}>
          ประเภทรายงาน<span style={{ color: "red" }}>*</span>
        </label>
        <Radio
          label="ประจำวัน"
          checked={handleSelectRadio("daily")}
          onChange={handleChangeRadio}
          value="daily"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <Radio
          label="ประจำเดือน"
          checked={handleSelectRadio("monthly")}
          onChange={handleChangeRadio}
          value="monthly"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <Radio
          label="ประจำปี"
          checked={handleSelectRadio("yearly")}
          onChange={handleChangeRadio}
          value="yearly"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 5.85rem 0rem 0rem",
          }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handlePreviewButton}
          paramKey={`ClinicAnnualReport_PREVIEW_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`ClinicAnnualReport_PREVIEW_REPORT`]}
          color="yellow"
          style={{ width: "11rem" }}
          title="Preview"
        />
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label style={{ display: "flex", alignItems: "center", padding: "0rem 0.5rem 0rem 0rem" }}>
          วันที่รับบริการ<span style={{ color: "red" }}>*</span>
        </label>
        <DateTextBox
          onChange={(date: string) => {
            props.setProp(
              `ClinicAnnualReportSequence.priceChangeOverviewSummaryReport.filterStartDate`,
              date
            )
            if (props.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport?.filterStartDate.length < 1) {
              setRequiredStartDate(true);
            } else {
              setRequiredStartDate(false);
            }
          }}
          style={{ width: "11rem" }}
          value={
            props.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport?.filterStartDate ||
            ""
          }
        ></DateTextBox>
        <label style={{ display: "flex", alignItems: "center" }}>ถึง</label>
        <DateTextBox
          onChange={(date: string) =>
            props.setProp(
              `ClinicAnnualReportSequence.priceChangeOverviewSummaryReport.filterEndDate`,
              date
            )
          }
          style={{ width: "11rem" }}
          value={
            props.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport?.filterEndDate || ""
          }
        ></DateTextBox>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 1rem 0rem 0rem",
          }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handlePrintButton}
          paramKey={`ClinicAnnualReport_PRINT_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`ClinicAnnualReport_PRINT_REPORT`]}
          color="blue"
          style={{ width: "11rem" }}
          title="พิมพ์"
        />
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 4.3rem 0rem 0rem",
          }}
        >
          แพทย์
        </label>
        <Dropdown
          value={
            props.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport?.doctorSelected || ""
          }
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.priceChangeOverviewSummaryReport.doctorSelected`,
              data.value
            )
          }
          placeholder="เลือกแพทย์"
          options={doctorOptions}
          search={true}
          selection={true}
          style={{ minWidth: "20rem" }}
        ></Dropdown>
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 1.9rem 0rem 0rem",
          }}
        >
          สาขาแพทย์
        </label>
        <Dropdown
          value={
            props.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport?.specialtySelected ||
            ""
          }
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.priceChangeOverviewSummaryReport.specialtySelected`,
              data.value
            )
          }
          placeholder="เลือกสาขาแพทย์"
          options={specialtyOptions}
          search={true}
          selection={true}
          style={{ minWidth: "15.5rem" }}
        ></Dropdown>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 0.95rem 0rem 0rem",
          }}
        >
          ประเภท
        </label>
        <Dropdown
          selection={true}
          value={
            props.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport?.encounterType ||
            "OPD และ IPD"
          }
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.priceChangeOverviewSummaryReport.encounterType`,
              data.value
            )
          }
          options={PATIENT_TYPE}
          style={{ minWidth: "10rem" }}
        ></Dropdown>
      </FormGroup>
    </Form>
  );
};

export default CardPriceChangeOverviewSummaryFilter;
