import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  FormGroup,
  FormField,
  Radio,
  Checkbox,
  Input,
  Dropdown
} from 'semantic-ui-react'

const CardByEquipmentUX = (props: any) => {
    return(
      <div
        id="CardByEquipment"
        style={{width:"100%", padding: "1rem"}}>
        <div
          id="CardTube"
          style={{backgroundColor: "rgba(214, 236, 243, 0.23)", borderRadius: "5px", padding: "1rem"}}>
          
          <div>
            
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              
              <div
                style={{fontWeight: "bold", fontSize: "1.2rem"}}>
                Urinary Catheterization
              </div>
              <div>
                
                <Label>
                  {props.status}
                </Label>
              </div>
            </div>
            <div
              className="ui divider">
              
            </div>
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                className="required"
                inline={true}>
                <label
                  style={{minWidth: "150px", maxWidth: "150px"}}>
                  Urinary Catheterization
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <Radio
                  checked={props.byEquipment?.data?.urinary}
                  label={props.byEquipment?.data?.urinary ? "Yes" : "No"}
                  onChange={props.handleChangeValue("byEquipment.data.urinary")}
                  toggle={true}>
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  {}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "150px", maxWidth: "150px",}}>
                  
                </label>
              </FormField>
              <FormField
                width={14}>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.byEquipment?.data?.retained_in_ward}
                      label="Retained in ward"
                      onChange={props.handleChangeValue("byEquipment.data.retained_in_ward")}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}>
                    <label>
                      
                    </label>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.byEquipment?.data?.change_in_or}
                      label="Change in OR"
                      onChange={props.handleChangeValue("byEquipment.data.change_in_or")}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}>
                    <label>
                      
                    </label>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.byEquipment?.data?.is_intermittent_cath}
                      label="Intermittent Cath"
                      onChange={props.handleChangeValue("byEquipment.data.is_intermittent_cath")}
                      style={{minWidth: "135px", maxWidth: "135px"}}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}
                    width={2}>
                    <Input
                      disabled={props.byEquipment?.data?.is_intermittent_cath ? false : true}
                      onChange={props.handleChangeValue("byEquipment.data.intermittent_cath")}
                      value={props.byEquipment?.data?.intermittent_cath}>
                    </Input>
                  </FormField>
                  <FormField>
                    <label>
                      ml
                    </label>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.byEquipment?.data?.insertOR}
                      label="Insert in OR"
                      onChange={props.handleChangeValue("byEquipment.data.insertOR")}
                      style={{minWidth: "135px", maxWidth: "135px"}}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}
                    width={2}>
                    <Input
                      disabled={props.byEquipment?.data?.insertOR ? false : true}
                      onChange={props.handleChangeValue("byEquipment.data.insert_in_or")}
                      value={props.byEquipment?.data?.insert_in_or}>
                    </Input>
                  </FormField>
                  <FormField
                    inline={true}>
                    <label>
                      
                    </label>
                  </FormField>
                  <FormField
                    inline={true}
                    width={5}>
                    <label
                      style={{width: "100%", maxWidth: "max-content"}}>
                       Foley Cath Size (Fr)
                    </label>
                    <Dropdown
                      fluid={true}
                      onChange={props.handleChangeValue("byEquipment.data.foley_cath_size")}
                      options={props.masterOptions?.orFoleyCathSize}
                      selection={true}
                      style={{width: "100%"}}
                      value={props.byEquipment?.data?.foley_cath_size}>
                    </Dropdown>
                  </FormField>
                  <FormField
                    inline={true}
                    width={6}>
                    <label
                      style={{width: "100%", maxWidth: "max-content"}}>
                      Catheterize Solution
                    </label>
                    <Dropdown
                      fluid={true}
                      onChange={props.handleChangeValue("byEquipment.data.catheterize_solution")}
                      options={props.masterOptions?.orClinicalTerm}
                      selection={true}
                      style={{width: "100%"}}
                      value={props.byEquipment?.data?.catheterize_solution}>
                    </Dropdown>
                  </FormField>
                </FormGroup>
              </FormField>
            </FormGroup>
          </div>
          <div
            style={{display: "flex", justifyContent: "flex-end"}}>
            
            <div>
              {props.buttonSave}
            </div>
            <div
              style={{margin: "0px 10px"}}>
              {props.buttonConfirm}
            </div>
            <div>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
        <div
          style={{backgroundColor: "rgba(214, 236, 243, 0.23)", borderRadius: "5px", padding: "1rem", marginTop: "1rem"}}>
          
          <div>
            
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              
              <div
                style={{fontWeight: "bold", fontSize: "1.2rem"}}>
                Implant
              </div>
              <div>
                
              </div>
            </div>
            <div
              className="ui divider">
              
            </div>
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "max-content"}}
                width={1}>
                <label>
                  Implant
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <Radio
                  checked={props.PerioperativeNursingSequence?.implantData?.is_active}
                  onChange={props.handleChangeValue("implantData.is_active")}
                  toggle={true}>
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  {props.PerioperativeNursingSequence?.implantData?.is_active ? "Yes" : "No"}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
                <label>
                  Type
                </label>
              </FormField>
              <FormField
                inline={true}
                width={10}>
                <Input
                  disabled={props.PerioperativeNursingSequence?.implantData?.is_active ? false : true}
                  onChange={props.handleChangeValue("implantData.type")}
                  value={props.PerioperativeNursingSequence?.implantData?.type}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup>
              <div
                style={{width: "100%"}}>
                {props.ImplantImage}
              </div>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={15}>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.buttonSaveImplant}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{backgroundColor: "rgba(214, 236, 243, 0.23)", borderRadius: "5px", padding: "1rem", marginTop: "1rem"}}>
          
          <div>
            
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              
              <div
                style={{fontWeight: "bold", fontSize: "1.2rem"}}>
                Drain
              </div>
              <div>
                
                <Label>
                  {props.statusDrain}
                </Label>
              </div>
            </div>
            <div
              className="ui divider">
              
            </div>
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "max-content"}}
                width={1}>
                <label>
                  Drain
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <Radio
                  checked={props.PerioperativeNursingSequence?.drainData?.data?.is_drain }
                  onChange={props.handleChangeValue("drainData.data.is_drain")}
                  toggle={true}>
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  {props.PerioperativeNursingSequence?.drainData?.data?.is_drain ? "Yes" : "No"}
                </label>
              </FormField>
              <FormField
                inline={true}
                width={10}>
                <Dropdown
                  disabled={props.PerioperativeNursingSequence?.drainData?.data?.is_drain ? false : true}
                  multiple={true}
                  onChange={props.handleChangeValue("drainData.data.drain_items")}
                  options={props.masterOptions?.orDrain}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PerioperativeNursingSequence?.drainData?.data?.drain_items}>
                </Dropdown>
              </FormField>
            </FormGroup>
          </div>
          <div
            style={{display: "flex", justifyContent: "flex-end"}}>
            
            <div>
              {props.buttonSaveDrain}
            </div>
            <div
              style={{ margin: "0px 10px" }}>
              {props.buttonConfirmDrain}
            </div>
            <div>
              {props.buttonUnconfirmDrain}
            </div>
          </div>
        </div>
        <div
          style={{backgroundColor: "rgba(214, 236, 243, 0.23)", borderRadius: "5px", padding: "1rem", marginTop: "1rem"}}>
          
          <div>
            
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              
              <div
                style={{fontWeight: "bold", fontSize: "1.2rem"}}>
                Dressing
              </div>
              <div>
                
                <Label>
                  {props.statusDressing}
                </Label>
              </div>
            </div>
            <div
              className="ui divider">
              
            </div>
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "max-content"}}
                width={1}>
                <label>
                  Dressing
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <Radio
                  checked={props.PerioperativeNursingSequence?.dressingData?.data?.is_dressing}
                  onChange={props.handleChangeValue("dressingData.data.is_dressing")}
                  toggle={true}>
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  {props.PerioperativeNursingSequence?.dressingData?.data?.is_dressing ? "Yes" : "No"}
                </label>
              </FormField>
              <FormField
                inline={true}
                width={10}>
                <Dropdown
                  disabled={props.PerioperativeNursingSequence?.dressingData?.data?.is_dressing ? false : true}
                  multiple={true}
                  onChange={props.handleChangeValue("dressingData.data.dressing_items")}
                  options={props.masterOptions?.orDressing}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PerioperativeNursingSequence?.dressingData?.data?.dressing_items}>
                </Dropdown>
              </FormField>
            </FormGroup>
          </div>
          <div
            style={{display: "flex", justifyContent: "flex-end"}}>
            
            <div>
              {props.buttonSaveDressing}
            </div>
            <div
              style={{ margin: "0px 10px" }}>
              {props.buttonConfirmDressing}
            </div>
            <div>
              {props.buttonUnconfirmDressing}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardByEquipmentUX

export const screenPropsDefault = {}

/* Date Time : Tue Jul 16 2024 10:02:08 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardByEquipment"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardTube"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(214, 236, 243, 0.23)\", borderRadius: \"5px\", padding: \"1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Urinary Catheterization"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Label",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 8,
      "props": {
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Urinary Catheterization"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"150px\", maxWidth: \"150px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Radio",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.byEquipment?.data?.urinary"
        },
        "label": {
          "type": "code",
          "value": "props.byEquipment?.data?.urinary ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"byEquipment.data.urinary\")"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 10,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 10,
      "props": {
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormGroup",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormGroup",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormGroup",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormGroup",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 32,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.byEquipment?.data?.retained_in_ward"
        },
        "label": {
          "type": "value",
          "value": "Retained in ward"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"byEquipment.data.retained_in_ward\")"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Checkbox",
      "parent": 36,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.byEquipment?.data?.change_in_or"
        },
        "label": {
          "type": "value",
          "value": "Change in OR"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"byEquipment.data.change_in_or\")"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Checkbox",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.byEquipment?.data?.is_intermittent_cath"
        },
        "label": {
          "type": "value",
          "value": "Intermittent Cath"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"byEquipment.data.is_intermittent_cath\")"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"135px\", maxWidth: \"135px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Checkbox",
      "parent": 40,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.byEquipment?.data?.insertOR"
        },
        "label": {
          "type": "value",
          "value": "Insert in OR"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"byEquipment.data.insertOR\")"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"135px\", maxWidth: \"135px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 30,
      "props": {
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 48,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.byEquipment?.data?.is_intermittent_cath ? false : true"
        },
        "min": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"byEquipment.data.intermittent_cath\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.byEquipment?.data?.intermittent_cath"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Input",
      "parent": 52,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.byEquipment?.data?.insertOR ? false : true"
        },
        "min": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"byEquipment.data.insert_in_or\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.byEquipment?.data?.insert_in_or"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Dropdown",
      "parent": 57,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"byEquipment.data.foley_cath_size\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orFoleyCathSize"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.byEquipment?.data?.foley_cath_size"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Dropdown",
      "parent": 59,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"byEquipment.data.catheterize_solution\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orClinicalTerm"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.byEquipment?.data?.catheterize_solution"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(214, 236, 243, 0.23)\", borderRadius: \"5px\", padding: \"1rem\", marginTop: \"1rem\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "Implant"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormGroup",
      "parent": 70,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormGroup",
      "parent": 70,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "Implant"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.implantData?.is_active ? \"Yes\" : \"No\""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Radio",
      "parent": 82,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.implantData?.is_active"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"implantData.is_active\")"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "Type"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Input",
      "parent": 88,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.implantData?.is_active ? false : true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"implantData.type\")"
        },
        "value": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.implantData?.type"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormGroup",
      "parent": 70,
      "props": {
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormGroup",
      "parent": 70,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ImplantImage"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(214, 236, 243, 0.23)\", borderRadius: \"5px\", padding: \"1rem\", marginTop: \"1rem\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Drain"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormGroup",
      "parent": 99,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "Drain"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 107,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.drainData?.data?.is_drain ? \"Yes\" : \"No\""
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Radio",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.drainData?.data?.is_drain "
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"drainData.data.is_drain\")"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Dropdown",
      "parent": 108,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.drainData?.data?.is_drain ? false : true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"drainData.data.drain_items\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orDrain"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.drainData?.data?.drain_items"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(214, 236, 243, 0.23)\", borderRadius: \"5px\", padding: \"1rem\", marginTop: \"1rem\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "Dressing"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormGroup",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "FormField",
      "parent": 124,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "FormField",
      "parent": 124,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "FormField",
      "parent": 124,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormField",
      "parent": 124,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "label",
      "parent": 129,
      "props": {
        "children": {
          "type": "value",
          "value": "Dressing"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "label",
      "parent": 131,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.dressingData?.data?.is_dressing ? \"Yes\" : \"No\""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Radio",
      "parent": 130,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.dressingData?.data?.is_dressing"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"dressingData.data.is_dressing\")"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Dropdown",
      "parent": 132,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.dressingData?.data?.is_dressing ? false : true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"dressingData.data.dressing_items\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orDressing"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PerioperativeNursingSequence?.dressingData?.data?.dressing_items"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Label",
      "parent": 123,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusDressing"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Label",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusDrain"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 146,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveDrain"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 146,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirmDrain"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 146,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirmDrain"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveDressing"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirmDressing"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirmDressing"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveImplant"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"150px\", maxWidth: \"150px\",}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": " Foley Cath Size (Fr)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "Catheterize Solution"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 63,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardByEquipmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
