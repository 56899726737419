import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { ButtonProps } from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck, { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";

// UX
import CardDoctorFeeReportUX from "./CardDoctorFeeReportUX";

// Interface
import {
  ACTIONS,
  BTN_ACTS,
  CARD_DF_REPORT,
  DFPayrollItemSerializer,
  DFPayrollOverview,
  FilterAllValues,
  FilterDFReport,
  MasterOptionsType,
  PAYMENT_STATUS_OPTIONS,
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/DoctorFeeReport";

// Types
type CardDoctorFeeReportProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  DoctorFeeReportSequence: State["DoctorFeeReportSequence"];
  // options
  masterOptions: MasterOptionsType;
} & PickedProps;

type TabMenuKeys = keyof typeof DF_REPORT;

// Const
const DESC_PRICE_FIELDS =
  "revenue_price,management_fee_price,credit_card_fee_price,leftover_revenue_price,compensation_percent,final_revenue_price,send_claim_price,patient_pay_price";

const OVERVIEW_PRICE_FIELDS =
  "revenue_price,management_fee,credit_card_fee,leftover_revenue_price,compensation_percent,final_revenue_price,total_net,paid_price,waiting_claim_price";

const DF_REPORT = {
  description: {
    data: "dfPayrollDesc",
    filter: "filterDesc",
    headers:
      "ประเภทการลงตรวจ,HN,VN,วันที่รับบริการ,ชื่อ - นามสกุล,คลินิก,สิทธิ,รหัส,รายการ,จำนวน,รายรับรวม,หักค่าบริหาร,หักค่าธรรมเนียมบัตรเครดิต,คงเหลือ,ส่วนแบ่ง(%),รายรับหลังหักส่วนแบ่ง,ส่งเบิก,ผู้ป่วยจ่าย,ประเภทรายรับ,สถานะการชำระเงินของผู้ป่วย",
    height: "calc(-33.5rem + 100dvh)",
    keys: `examination_type_label,hn,encounter_number,service_date,patient_name,division_name,coverage_name,product_code,item_name,quantity,${DESC_PRICE_FIELDS},payment_method,patient_pay_status_label`,
    title: "รายละเอียดรายได้และค่าตอบแทน",
    widths: "^55,^65,^37.5,^72.5,^70,^75,^50,^45,^90,^50,^65,^55,^70,^55,^50,^60,^50,^60,^50,^70",
  },
  overview: {
    data: "dfPayrollOverview",
    filter: "filterOverview",
    headers:
      "รหัส,รายการ,จำนวน (qty),รายรับรวม,หักค่าบริหาร 20%,หักค่าธรรมเนียม บัตรเครดิต,คงเหลือ,ส่วนแบ่ง (%),รายรับ หลังหักส่วนแบ่ง,รวมสุทธิ,ชำระแล้ว,รอเบิก,ประเภทรายรับ",
    height: "calc(-37.25rem + 100dvh)",
    keys: `item_code,item_name,quantity,${OVERVIEW_PRICE_FIELDS},payment_method`,
    title: "ภาพรวมค่าตอบแทนแพทย์",
    widths: "^60,^160,^60,^60,^60,^70,^60,^60,^60,^60,^60,^60,^60",
  },
} as const;

const ALL_OPTION = { key: "ALL", text: "ทั้งหมด", value: "ALL" };

const CardDoctorFeeReport = (props: CardDoctorFeeReportProps) => {
  const [activeTab, setActiveTab] = useState<TabMenuKeys>("description");

  // Effect
  useEffect(() => {
    setActiveTab("description");

    props.runSequence({
      sequence: "DoctorFeeReport",
      nextIndex: "Description",
    });
  }, []);

  // Callback memo
  const formatPrice = useCallback((value: number | string) => {
    if (typeof value !== "number") {
      return value;
    }

    return Number(value).toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "decimal",
    });
  }, []);

  const formatFieldsPrice = useCallback((item: Record<string, any>, fields = "") => {
    const keys = fields ? fields.split(",") : Object.keys(item);

    return Object.fromEntries(
      keys.flatMap((key) => [
        [key, formatPrice(item[key])],
        [`raw_${key}`, item[key]],
      ])
    );
  }, []);

  // Memo Callback
  const filterDesc = useMemo(
    () => props.DoctorFeeReportSequence?.filterDesc,
    [props.DoctorFeeReportSequence?.filterDesc]
  );

  const filterOverview = useMemo(
    () => props.DoctorFeeReportSequence?.filterOverview,
    [props.DoctorFeeReportSequence?.filterOverview]
  );

  const tableDetail = useMemo(() => {
    const data = props.DoctorFeeReportSequence?.[DF_REPORT[activeTab].data];

    const items = data?.items || [];
    const summary = data?.summary || {};

    const formattedSummary = formatFieldsPrice(summary);

    return {
      data: DF_REPORT[activeTab],
      items: items.map((item: DFPayrollItemSerializer | DFPayrollOverview["items"][number]) => ({
        ...item,
        ...formatFieldsPrice(item, DESC_PRICE_FIELDS),
        ...formatFieldsPrice(item, OVERVIEW_PRICE_FIELDS),
        patient_pay_status_label: (
          <div
            style={{
              color:
                "patient_pay_status_label" in item && item.patient_pay_status_label === "ค้างชำระ"
                  ? "red"
                  : "",
            }}
          >
            {"patient_pay_status_label" in item ? item.patient_pay_status_label : ""}
          </div>
        ),
        waiting_claim_price: (
          <div
            style={{
              color: "waiting_claim_price" in item && item.waiting_claim_price > 0 ? "red" : "",
            }}
          >
            {"waiting_claim_price" in item ? formatPrice(item.waiting_claim_price) : ""}
          </div>
        ),
      })),
      summary: formattedSummary,
    };
  }, [
    activeTab,
    formatFieldsPrice,
    props.DoctorFeeReportSequence?.dfPayrollDesc,
    props.DoctorFeeReportSequence?.dfPayrollOverview,
  ]);

  // Callback
  const handleGetTdProps = useCallback(
    (state: any, rowInfo: RowInfo, column?: Column) => ({
      style: {
        ...(`${DESC_PRICE_FIELDS},${OVERVIEW_PRICE_FIELDS}`.includes(column?.id || "") && {
          textAlign: "right",
        }),
        fontSize: ".925rem",
      },
    }),
    []
  );

  const handleGetTheadThProps = useCallback(
    () => ({
      style: {
        fontSize: ".925rem",
      },
    }),
    []
  );

  const allowedSearch = useMemo(() => {
    if (activeTab === "description") {
      return !!filterDesc?.startDate && !!filterDesc.endDate;
    }

    return !!filterOverview?.startDate && !!filterOverview.endDate;
  }, [activeTab, filterDesc, filterOverview]);

  // Handler
  const handleChangeFilter = useCallback(
    (e: SyntheticEvent, data: { name: keyof FilterDFReport; value: FilterAllValues }) => {
      const filterKey = tableDetail.data.filter;

      props.setProp(`DoctorFeeReportSequence.${filterKey}`, {
        ...props.DoctorFeeReportSequence?.[filterKey],
        [data.name]: data.value,
      });
    },
    [props.DoctorFeeReportSequence, tableDetail.data.filter]
  );

  const handleSearch: BLClickHandler<typeof ACTIONS.SEARCH> = (e, data) => {
    props.runSequence({
      sequence: "DoctorFeeReport",
      action: data.name,
    });
  };

  const handlePrintReport: BLClickHandler<typeof ACTIONS.DF_PAYROLL_REPORT> = (e, data) => {
    props.runSequence({
      sequence: "DoctorFeeReport",
      action: data.name,
      card: CARD_DF_REPORT,
    });
  };

  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${CARD_DF_REPORT}`, null);
  }, [props.setProp]);

  const handleChangeTab = useCallback(
    (e: SyntheticEvent, data: { name: TabMenuKeys } & ButtonProps) => {
      setActiveTab(data.name);

      props.setProp(`buttonLoadCheck.${BTN_ACTS.SEARCH}`, null);
      props.setProp(`errorMessage.${CARD_DF_REPORT}`, null);

      props.runSequence({
        sequence: "DoctorFeeReport",
        nextIndex: `${data.name[0].toUpperCase()}${data.name.slice(1)}` as any,
      });
    },
    []
  );

  // Memo
  const divisionOptions = useMemo(
    () => [
      { key: "ALL", text: "ทุกคลินิก", value: "ALL" },
      ...(props.masterOptions.division || []),
    ],
    [props.masterOptions.division]
  );

  const periodOptions = useMemo(() => {
    const options = props.masterOptions.period || [];

    return [
      ALL_OPTION,
      ...options.map((option) => ({ ...option, text: option.text.replace("คาบ", "") })),
    ];
  }, [props.masterOptions.period]);

  const workingTypeOptions = useMemo(() => {
    const options = props.masterOptions.workingType || [];

    return [ALL_OPTION, ...options];
  }, [props.masterOptions.workingType]);

  const paymentStatusOptions = useMemo(() => [ALL_OPTION, ...PAYMENT_STATUS_OPTIONS], []);

  const sentClaimStatusOptions = useMemo(() => [ALL_OPTION], [props.masterOptions]);

  const buttonSearch = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"blue"}
        disabled={!allowedSearch}
        name={ACTIONS.SEARCH}
        paramKey={BTN_ACTS.SEARCH}
        size="small"
        title="ค้นหา"
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEARCH]}
        // callback
        onClick={handleSearch}
      />
    ),
    [allowedSearch, handleSearch, props.buttonLoadCheck]
  );

  const buttonPrint = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"blue"}
        name={ACTIONS.DF_PAYROLL_REPORT}
        paramKey={BTN_ACTS.DF_PAYROLL_REPORT}
        title="พิมพ์รายงาน"
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DF_PAYROLL_REPORT]}
        // callback
        onClick={handlePrintReport}
      />
    ),
    [handlePrintReport, props.buttonLoadCheck]
  );

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_DF_REPORT]}
        success={null}
        onClose={handleCloseErrMsg}
      />

      <CardDoctorFeeReportUX
        // data
        activeTab={activeTab}
        buttonPrint={buttonPrint}
        buttonSearch={buttonSearch}
        data={tableDetail.items}
        filter={props.DoctorFeeReportSequence?.[tableDetail.data.filter]}
        summary={tableDetail.summary}
        // table
        table={tableDetail.data}
        // options
        divisionOptions={divisionOptions}
        paymentStatusOptions={paymentStatusOptions}
        periodOptions={periodOptions}
        sentClaimStatusOptions={sentClaimStatusOptions}
        workingTypeOptions={workingTypeOptions}
        // callback
        onChangeFilter={handleChangeFilter}
        onChangeTab={handleChangeTab}
        onGetTdProps={handleGetTdProps}
        onGetTheadThProps={handleGetTheadThProps}
      />
    </div>
  );
};

CardDoctorFeeReport.displayName = "CardDoctorFeeReport";

export default React.memo(CardDoctorFeeReport);
