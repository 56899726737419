import React from 'react';
import CardLayout from '../common/CardLayout';
import {  Segment, Form } from 'semantic-ui-react'
import ComboBox from '../common/ComboBox';

const CardNursingDiagnosisView = React.forwardRef((props, ref) => {

  const [image, setImage] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [choices, setChoices] = React.useState([])

  const documentNoRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    getNurseDiagnosisResultGroup: () => {
      getNurseDiagnosisResultGroup()
    },
  }));

  const FrameChrome = ({ file }) => {
    if (file) {
      let frame =
        '<iframe src="data:application/pdf;base64,' + file + '" width="100%" height="100%" ></iframe>';
      return (
        <div
          style={{ width: "100%", height: "100%" }}
          dangerouslySetInnerHTML={{ __html: frame }}
        />
      );
    } else {
      return <></>;
    }
  };

  React.useEffect(() => {
    getNurseDiagnosisResultGroup()
  }, [props.encounterId])

  const getNurseDiagnosisResultGroup = async () => {
    setImage('')
    documentNoRef.current.clear()
    if(!props.encounterId){
      return setChoices([])
    }
    setIsLoading(true)
    const [data, error] = await props.getNurseDiagnosisResultGroup({ encounterId: props.encounterId })
    if(data && data.items){
      let newData = [...data.items]
      newData.forEach((item) => {
        item.key = item.id
        item.value = item.id
        item.text = item.document_no
      })
      setChoices(newData)
    }
    setIsLoading(false)
  }

  const handleSelectDocument = async (value) => {
    setIsLoading(true)
    const [data, error] = await props.getNurseDiagnosisPDF({ documentNo: value, pdf: true })
    if(data){
      setImage(data.pdf_b64data)
    }
    setIsLoading(false)
  }

  return (
    <CardLayout
      titleText='Nursing Diagnosis'
      headerColor='red'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={isLoading}
      cardLayoutClassName={props.cardLayoutClassName}
    >
    <Form>
      <Form.Group inline>
        <Form.Field>
          <label>หมายเลขเอกสาร</label>
        </Form.Field>
        <Form.Field width={2}>
          <ComboBox
            options={choices}
            onValueChange={handleSelectDocument}
            ref={documentNoRef}
          />
        </Form.Field>
      </Form.Group>
    </Form>
      {<div style={{ height: 480, overflowY: 'auto', overflowX: 'hidden' }}>
      <Segment
        id="container"
        style={{ height: "480px" }}
        className="grey inverted"
      >
        <FrameChrome
          file={image}
        />
      </Segment>
      </div>}
    </CardLayout>
  )
})

CardNursingDiagnosisView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  imageList: [],
  getNurseDiagnosisResultGroup: () => [null, null],
  getNurseDiagnosisPDF: () => [null, null],
  encounterId: null,
  cardLayoutClassName: '',
  // encounterId: null
};

export default CardNursingDiagnosisView;