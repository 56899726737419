import React, { useCallback, useMemo, useRef } from "react";

import moment from "moment";

// Frameworks
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// UX
import CardReturnDrugWorkingUX from "./CardReturnDrugWorkingUX";
import { RenderLotRow } from "./CardStockOrderWorking";

// Common
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Interface
import {
  BACKWARD_ACTIONS,
  // ButtonActionType,
  FORWARD_ACTIONS,
} from "./sequence/StockTransferOrder";
import { SetProp } from "./sequence/DrugReturnRequestHistory";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";

const CARD_NAME = "CardStockTransferOrder";

// Types
type CardReturnDrugWorkingProps = {
  setProp: SetProp;
  onEvent: (e: any) => any;
  // seq
  runSequence: any;
  DrugReturnRequestHistorySequence?: any;
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
};

const CardReturnDrugWorking = (props: CardReturnDrugWorkingProps) => {
  const cardRef = useRef<any>(null);

  // Callback
  const handleEditValue = useCallback(
    (data: { id: number; name: string }) => (value: string) =>
      props.runSequence({
        sequence: "DrugReturnRequestHistory",
        action: "SELECTBOX_EDIT_VALUE",
        selectedItemId: data.id,
        name: data.name,
        card: CARD_NAME,
        value,
      }),
    []
  );

  const handleUpdateRow = useCallback(async () => {
    if (cardRef.current) {
      const className = "scrollbar-visible";
      const tbody = cardRef.current.querySelector(".ReactTable .rt-tbody");
      const isVisible = tbody.className?.includes(className);

      await tbody.classList.remove(className);

      if (tbody.scrollHeight > tbody.offsetHeight) {
        if (!isVisible) {
          tbody.classList.add(className);
        }
      }
    }
  }, []);

  // Use Memo
  const workItems = useMemo(() => {
    const items =
      props.DrugReturnRequestHistorySequence?.selectedBox?.items || [];

    return items.map((item: any, idx: number) => {
      const isDelivered =
        props.DrugReturnRequestHistorySequence?.selectedBox?.status_name ===
        "DELIVERED";
      const qtyRestock =
        item.quantity_restock !== undefined
          ? item.quantity_restock.toString()
          : "0";
      const qtyDiscard =
        item.quantity_discard !== undefined
          ? item.quantity_discard.toString()
          : "0";
      const reason = item.reason !== undefined ? item.reason : "";

      return {
        ...item,
        index: idx + 1,
        provide_quantity_input: (
          <RenderLotRow data={item} onDisplay={(row) => row.quantity * -1} />
        ),
        total_amount: (
          <RenderLotRow data={item} onDisplay={(row) => row.sum * -1 || "-"} />
        ),
        lot_no: (
          <RenderLotRow
            data={item}
            onDisplay={(row) => row.lot?.mfc_no || "\u00a0"}
          />
        ),
        expired_date: (
          <RenderLotRow
            data={item}
            onDisplay={(row) =>
              !!row.lot?.exp_datetime &&
              moment(row.lot?.exp_datetime, "YYYY-MM-DD HH:mm").format(
                "DD/MM/YYYY"
              )
            }
          />
        ),
        quantity: (
          <RenderLotRow
            data={item}
            onDisplay={(row) => row.lot?.get_quantity || "-"}
          />
        ),
        quantity_restock: isDelivered ? (
          <EditorColumn
            value={qtyRestock}
            backgroundColor={"#ffffc3"}
            align="left"
            onDataChanged={handleEditValue({
              id: item.id,
              name: "quantity_restock",
            })}
          />
        ) : (
          item.quantity_restock
        ),
        quantity_discard: isDelivered ? (
          <EditorColumn
            value={qtyDiscard}
            backgroundColor={"#ffffc3"}
            align="left"
            onDataChanged={handleEditValue({
              id: item.id,
              name: "quantity_discard",
            })}
          />
        ) : (
          item.quantity_discard
        ),
        reason: isDelivered ? (
          <EditorColumn
            value={reason}
            backgroundColor={"#ffffc3"}
            align="left"
            onDataChanged={handleEditValue({
              id: item.id,
              name: "reason",
            })}
          />
        ) : (
          item.reason
        ),
      };
    });
  }, [
    props.DrugReturnRequestHistorySequence,
    props.DrugReturnRequestHistorySequence?.selectedBox,
  ]);

  const handleGetTheadThProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => {
      return {
        style: ["เหตุผล"].includes(column?.Header || "")
          ? { backgroundColor: "#2185D0" }
          : {},
      };
    },
    []
  );

  const handleGetTdProps = useCallback(
    (state: any, rowInfo: any, column: any, _instance: any) => {
      if (state.columns?.slice(-1)?.[0]?.Header === column?.Header) {
        handleUpdateRow();
      }

      return {};
    },
    []
  );

  const getMsgLabel = useCallback((error: any) => {
    return Object.entries(error).reduce((result, [key, value]: any) => {
      const msgLabel = {
        reason: "เหตุผลการรับคืน",
      } as any;

      const labelKey = msgLabel[key] || key;

      if (key === "items") {
        value = value.map((item: any) => getMsgLabel(item));
      }

      return {
        ...result,
        [labelKey]: value,
      };
    }, {});
  }, []);

  const getButtonAction = useCallback(
    (
      actions: any
    ): { action: any; color: string; by: string } | null => {
      const selectedBox = props.DrugReturnRequestHistorySequence?.selectedBox;
      const status = selectedBox?.status_name;

      const button = actions[status];

      return button || null;
    },
    [props.DrugReturnRequestHistorySequence?.selectedBox]
  );

  // Use Memo
  const forwardAction = useMemo(() => {
    return getButtonAction(FORWARD_ACTIONS);
  }, [props.DrugReturnRequestHistorySequence?.selectedBox]);

  const backwardAction = useMemo(() => {
    return getButtonAction({
      ...BACKWARD_ACTIONS,
      DELIVERED: { action: "UNDELIVER", color: "red" },
      RECEIVED: { action: "UNRECEIVE", color: "red" },
    });
  }, [props.DrugReturnRequestHistorySequence?.selectedBox]);

  const orderDatetime = useMemo(() => {
    const selectedBox = props.DrugReturnRequestHistorySequence?.selectedBox;

    return {
      requested: formatDatetime(selectedBox?.requested?.datetime, true),
      approved: formatDatetime(selectedBox?.approved?.datetime, true),
      delivered: formatDatetime(selectedBox?.delivered?.datetime, true),
      received: formatDatetime(selectedBox?.received?.datetime, true),
    };
  }, [props.DrugReturnRequestHistorySequence?.selectedBox]);

  const errorMsgLabel = useMemo(() => {
    const error = props.errorMessage?.[CARD_NAME];

    return !error ? null : getMsgLabel(error);
  }, [props.errorMessage]);

  // Handler

  const handleButtonAction = (action: any) => () => {
    props.runSequence({
      sequence: "DrugReturnRequestHistory",
      action,
      card: CARD_NAME,
    });
  };

  console.log("CardReturnDrugWorking props: ", props);

  return (
    <div ref={cardRef}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_NAME}`, null);
        }}
        error={errorMsgLabel}
        success={null}
      />

      <CardReturnDrugWorkingUX
        DrugReturnRequestHistorySequence={props.DrugReturnRequestHistorySequence}
        // ErrorMessage={<ErrorMessage error={props?.errorMessage?.[CARD_NAME]} />}
        // data
        items={workItems}
        orderDatetime={orderDatetime}
        // table
        getTheadThProps={handleGetTheadThProps}
        getTdProps={handleGetTdProps}
        // Element
        buttonBackward={
          backwardAction && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleButtonAction(backwardAction.action)}
              // data
              paramKey={`${CARD_NAME}_${backwardAction.action}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_${backwardAction.action}`]}
              // config
              color={backwardAction.color}
              title={backwardAction.action}
            />
          )
        }
        buttonForward={
          forwardAction && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleButtonAction(forwardAction.action)}
              // data
              paramKey={`${CARD_NAME}_${forwardAction.action}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_${forwardAction.action}`]}
              // config
              color={forwardAction.color}
              title={forwardAction.action}
            />
          )
        }
      />
    </div>
  );
};

export default React.memo(CardReturnDrugWorking);
