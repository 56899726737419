import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, List } from "semantic-ui-react";
import { saveAs } from "file-saver";

// Interface
import { RunSequence } from "./sequence/HealthMember";

// UX
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import CardHealthMemberDetailUX from "./CardHealthMemberDetailUX";
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";

// Common
import { ModConfirm, ModInfo } from "react-lib/apps/common";

const CARD_HEALTH_MEMBER_DETAIL = "CardHealthMemberDetail";

type CardHealthMemberDetailProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  HealthMemberSequence?: any;
  // controller
  drugOrderQueueController: any;
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // data
};

type PatientType = Partial<{
  id: number;
  hn: string;
  full_name: string;
  birthdate: string;
}>;

const CardHealthMemberDetail = (props: CardHealthMemberDetailProps) => {
  // add segment
  const [segmentDetail, setSegmentDetail] = useState<any>({});
  // add patient
  const [selectedPatient, setSelectedPatient] = useState<PatientType>({});
  // list patient
  const [patientSegmentList, setPatientSegmentList] = useState<any[]>([]);
  // remove patient
  const [patientRemoveList, setPatientRemoveList] = useState<any[]>([]);
  // file
  const [patientFile, setPatientFile] = useState<File | null>(null);

  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    setSelectedPatient({});
    setPatientSegmentList([]);
    setPatientRemoveList([]);
    setSegmentDetail(props.HealthMemberSequence?.segmentGroupDetail);
  }, [props.HealthMemberSequence?.segmentGroupDetail]);

  // Table Member
  useEffect(() => {
    setPatientRemoveList([]);
    setPatientSegmentList(props.HealthMemberSequence?.patientSegmentList || []);
  }, [props.HealthMemberSequence?.patientSegmentList]);

  const handleChecked = (_e: any, data: any) => {
    setPatientSegmentList(
      patientSegmentList.map((item: any) =>
        item.id === data.name ? { ...item, chk: data.checked } : item
      )
    );
  };

  const patientSegmentItems = useMemo(() => {
    return patientSegmentList.map((item) => ({
      ...item,
      chk: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox checked={item.chk} name={item.id} onChange={handleChecked} />
        </div>
      ),
    }));
  }, [patientSegmentList]);

  // Segment
  const handleChangeSegment = (_event, data: any) => {
    setSegmentDetail({ ...segmentDetail, [data.name]: data.value });
  };

  const handleCreateSegment = () => {
    props.runSequence?.({
      sequence: "HealthMember",
      action: "CREATE_SEGMENT_GROUP",
      card: CARD_HEALTH_MEMBER_DETAIL,
      btnKey: `${CARD_HEALTH_MEMBER_DETAIL}_CREATE_SEGMENT_GROUP`,
      data: segmentDetail,
    });

    if (props.HealthMemberSequence?.segmentGroupDetail?.id){
      props.runSequence?.({
        sequence: "HealthMember",
        action: "DELETE_PATIENT_SEGMENT",
        card: CARD_HEALTH_MEMBER_DETAIL,
        btnKey: `${CARD_HEALTH_MEMBER_DETAIL}_DELETE_PATIENT_SEGMENT`,
        hnList: patientRemoveList,
      });
    }
  };

  const handleDeleteSegment = () => {
    props.runSequence?.({
      sequence: "HealthMember",
      action: "DELETE_SEGMENT_GROUP",
      card: CARD_HEALTH_MEMBER_DETAIL,
      btnKey: `${CARD_HEALTH_MEMBER_DETAIL}_DELETE_SEGMENT_GROUP`,
    });
  };

  // Add Member
  const handleChangePatient = (id: any, hn: string, full_name: string, birthdate: string) => {
    setSelectedPatient(id ? { id, hn, full_name, birthdate } : {});
  };

  const handleAddPatient = () => {
    props.runSequence?.({
      sequence: "HealthMember",
      action: "CREATE_PATIENT_SEGMENT",
      card: CARD_HEALTH_MEMBER_DETAIL,
      btnKey: `${CARD_HEALTH_MEMBER_DETAIL}_CREATE_PATIENT_SEGMENT`,
      hnList: [selectedPatient.hn],
      checkErrorByIndex: false,
    });
  };

  // Import Member
  const handleClickBrowse = () => {
    fileRef.current?.click?.();
  };

  const handleChangeFile = (event: any) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setPatientFile(file);
    } else {
      fileRef.current.value = "";
      setPatientFile(null);
    }
  };

  const handleImportFile = () => {
    if (props.HealthMemberSequence?.segmentGroupDetail?.code.trim() === "") {
      return console.log("errorMessage", "Segment code not found.");
    }

    if (!patientFile) {
      return console.log("errorMessage", "Please select a file.");
    }

    let fileReader = new FileReader();
    fileReader.readAsBinaryString(patientFile);
    fileReader.onload = async (event) => {
      if (!event.target) {
        return;
      }

      const XLSX = await import("xlsx");

      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const rowObject: any[] = (XLSX.utils as any).sheet_to_row_object_array(
        workbook.Sheets[workbook.SheetNames[0]]
      );

      let citizenList: any[] = [];
      let passportList: any[] = [];
      let hnList: any[] = [];
      rowObject.forEach((item: any, index: number) => {
        if (item?.ID_card_number) {
          citizenList.push({ index: index + 2, value: item.ID_card_number });
        } else if (item?.Passport_number) {
          passportList.push({ index: index + 2, value: item.Passport_number });
        } else if (item?.HN) {
          hnList.push({ index: index + 2, value: item.HN });
        }
      });

      if (citizenList.length === 0 && passportList.length === 0 && hnList.length === 0) {
        return props.setProp(`errorMessage.${CARD_HEALTH_MEMBER_DETAIL}`, "Patient not found.");
      }

      props.runSequence?.({
        sequence: "HealthMember",
        action: "CREATE_PATIENT_SEGMENT",
        card: CARD_HEALTH_MEMBER_DETAIL,
        btnKey: `${CARD_HEALTH_MEMBER_DETAIL}_IMPORT_PATIENT`,
        citizenList: citizenList,
        passportList: passportList,
        hnList: hnList,
        checkErrorByIndex: true,
      });
    };

    fileRef.current.value = "";
    setPatientFile(null);
  };

  // Download File
  const s2ab = (s: any) => {
    let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    let view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  };

  const handleDownloadTemplate = () => {
    import("xlsx").then((XLSX) => {
      let workbook = XLSX.utils.book_new();
      workbook.SheetNames.push("Segment");
      const sheet_data = [
        ["ID_card_number", "Passport_number", "HN", "Pre_name", "Name", "Surname"],
      ];
      const sheet = XLSX.utils.aoa_to_sheet(sheet_data);
      workbook.Sheets["Segment"] = sheet;

      const output = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      saveAs(new Blob([s2ab(output)], { type: "application/octet-stream" }), "segment.xlsx");
    });
  };

  // Remove Member
  const handleRemovePatient = () => {
    let removePat: any = [];
    let result: any = [];
    patientSegmentList.forEach((item: any) => {
      if (item.chk) {
        removePat.push(item.hn);
      } else {
        result.push({ ...item, chk: false });
      }
    });
    setPatientRemoveList(removePat);
    setPatientSegmentList(result);
  };
  console.log("----- l ", props.errorMessage);
  return (
    <div style={{ height: "100%" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_HEALTH_MEMBER_DETAIL}`, null);
        }}
        error={props.errorMessage?.[CARD_HEALTH_MEMBER_DETAIL]}
        success={null}
      />

      <CardHealthMemberDetailUX
        // function
        onChangeSegment={handleChangeSegment}
        onBrowse={handleClickBrowse}
        onDownloadTemplate={handleDownloadTemplate}
        onRemove={handleRemovePatient}
        // data
        segmentDetail={segmentDetail}
        fileName={patientFile?.name}
        patientSegmentList={patientSegmentItems}
        // component
        AddPatientSearchBox={
          <CardPatientSearchBox
            // controller
            controller={props.drugOrderQueueController}
            defaultValue={selectedPatient?.hn || ""}
            clearHNInput={selectedPatient?.hn === undefined ? true : false}
            // callback
            onEnterPatientSearch={handleChangePatient}
          />
        }
        SearchPatientSearchBox={
          <CardPatientSearchBox
            // controller
            controller={props.drugOrderQueueController}
            // defaultValue={selectedPatient?.hn || ""}
            // clearHNInput={selectedPatient?.hn === undefined ? true : false}
            // callback
            // onEnterPatientSearch={handleChangePatient}
          />
        }
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleCreateSegment}
            // data
            paramKey={`${CARD_HEALTH_MEMBER_DETAIL}_CREATE_SEGMENT_GROUP`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_HEALTH_MEMBER_DETAIL}_CREATE_SEGMENT_GROUP`]
            }
            // config
            color="green"
            fluid={true}
            title="SAVE"
          />
        }
        ButtonDelete={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleDeleteSegment}
            // data
            paramKey={`${CARD_HEALTH_MEMBER_DETAIL}_DELETE_SEGMENT_GROUP`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_HEALTH_MEMBER_DETAIL}_DELETE_SEGMENT_GROUP`]
            }
            // config
            color="red"
            fluid={true}
            title="Delete Segment"
          />
        }
        ButtonAdd={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleAddPatient}
            // data
            paramKey={`${CARD_HEALTH_MEMBER_DETAIL}_CREATE_PATIENT_SEGMENT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_HEALTH_MEMBER_DETAIL}_CREATE_PATIENT_SEGMENT`]
            }
            // config
            color="blue"
            fluid={true}
            title="ADD"
          />
        }
        ButtonImport={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleImportFile}
            // data
            paramKey={`${CARD_HEALTH_MEMBER_DETAIL}_IMPORT_PATIENT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_HEALTH_MEMBER_DETAIL}_IMPORT_PATIENT`]}
            // config
            color="blue"
            fluid={true}
            title="Import"
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // onClick={}
            // data
            paramKey={`${CARD_HEALTH_MEMBER_DETAIL}_FETCH_PATIENT_SEGMENT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_HEALTH_MEMBER_DETAIL}_FETCH_PATIENT_SEGMENT`]
            }
            // config
            color="blue"
            fluid={true}
            name="SEARCH_PATIENT"
            // size=''
            title="ค้นหา"
          />
        }
        ImportError={
          <div
            style={{
              display: props.errorMessage?.[`${CARD_HEALTH_MEMBER_DETAIL}_IMPORT`] ? "" : "none",
              background: "rgba(246, 118, 118, 0.33)",
              color: "#DA0000",
              border: "1px #DA0000 solid",
              padding: "20px",
              height: "145px",
              overflowY: "scroll",
            }}
          >
            <label style={{ fontWeight: "bold" }}>
              ไม่สามารถนำเข้ารายชื่อลำดับ ดังต่อไปนี้ได้ กรุณาตรวจสอบไฟล์
            </label>
            <List bulleted>
              {props.errorMessage?.[`${CARD_HEALTH_MEMBER_DETAIL}_IMPORT`]?.map((item: any) => {
                return <List.Item>{item.index}</List.Item>;
              })}
            </List>
          </div>
        }
      />

      <input ref={fileRef} type="file" accept=".xlsx" onChange={handleChangeFile} hidden />

      {/* <ModConfirm
        openModal={openModConfirmRemove}
        titleName="ลบสมาชิกกลุ่ม"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmRemove}
            // data
            paramKey={`${CARD_HEALTH_MEMBER_DETAIL}_DELETE_PATIENT_SEGMENT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_HEALTH_MEMBER_DETAIL}_DELETE_PATIENT_SEGMENT`]
            }
            // config
            color="green"
            title="ตกลง"
            basic={true}
          />
        }
        content={
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "0rem 0 -1rem",
            }}
          >
            ต้องการลบสมาชิกที่เลือกออกจากกลุ่ม
          </div>
        }
        // onApprove={handleConfirmRemove}
        onDeny={handleCloseModConfirmRemove}
        onCloseWithDimmerClick={handleCloseModConfirmRemove}
      /> */}
    </div>
  );
};

export default React.memo(CardHealthMemberDetail);
