import FormSymbolImages from "./AddSymbolPrintList";

export default function toPDFMakeData(props: any) {
  const injectImages = (haveHeader: boolean) => {
    let symbolUsage = {};
    if (!haveHeader) {
      symbolUsage = {
        checked: FormSymbolImages.checked,
        unchecked: FormSymbolImages.unchecked,
        squarebox: FormSymbolImages.squarebox,
        checkmark: FormSymbolImages.checkmark,
      };
    }
    return symbolUsage;
  };

  return {
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        bold: `true`,
        fontSize: `18`,
        alignment: `center`,
        color: ``,
        text: `Do Not Resuscitate Request`,
        decoration: ``,
        width: `auto`,
        decorationColor: ``,
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        color: ``,
        fontSize: 15,
        bold: false,
        text: ` `,
        alignment: `left`,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
        decorationColor: ``,
      },
      {
        columns: [
          {
            text: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: `*`,
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            bold: false,
          },
          {
            decorationStyle: ``,
            text: `Date`,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `right`,
            fontSize: 15,
            width: `auto`,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            width: 10,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `right`,
            margin: [0, 0, 0, 0],
            width: `auto`,
            text: `${props.items?.printDay} ${props.items?.printMonth} ${props.items?.printYear}`,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
          },
          {
            color: ``,
            decorationStyle: ``,
            width: 10,
            alignment: `left`,
            text: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            bold: false,
            width: `auto`,
            text: `Time`,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            alignment: `right`,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            width: 10,
            margin: [0, 0, 0, 0],
          },
          {
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            text: props.items?.printTime,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            alignment: `right`,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
        color: ``,
        alignment: `left`,
        width: `auto`,
        preserveLeadingSpaces: true,
        bold: false,
        decorationStyle: ``,
        pageBreak: ``,
        fontSize: 15,
        text: ` `,
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            width: `auto`,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `Patient's Name`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.full_name_en || props.items?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.............................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            width: 10,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            text: `HN :`,
            decoration: ``,
            alignment: `left`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.hn,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `........................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            text: `I ..........................................................................`,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
          {
            fontSize: 15,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            bold: false,
            width: 10,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `having relationship with patient as ...............................................................`,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            bold: false,
            width: `auto`,
          },
        ],
      },
      {
        fontSize: 15,
        width: `auto`,
        decorationColor: ``,
        color: ``,
        bold: false,
        decorationStyle: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
        text: `and being athorized person to Make decision on behalf of patient. I have been explained by attending physician`,
        preserveLeadingSpaces: true,
        alignment: `left`,
        pageBreak: ``,
      },
      {
        columns: [
          {
            stack: [
              {
                text: props.items?.formatDoctor || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...........................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            fontSize: 15,
            text: ` that the patient's conditions are as follows ....................................`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            bold: false,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            width: `auto`,
          },
        ],
      },
      {
        color: ``,
        decorationColor: ``,
        width: `auto`,
        alignment: `left`,
        bold: false,
        preserveLeadingSpaces: true,
        text: `.................................................................................................................................................................................................................`,
        pageBreak: ``,
        fontSize: 15,
        decorationStyle: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        color: ``,
        decorationStyle: ``,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        text: `.................................................................................................................................................................................................................`,
        width: `auto`,
        decorationColor: ``,
        decoration: ``,
        pageBreak: ``,
        bold: false,
      },
      {
        text: `After explained by attending physician, I have consulted with family's members (name attached) and agreed to`,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        color: ``,
        decoration: ``,
        alignment: `left`,
        bold: false,
        decorationColor: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
      },
      {
        decorationStyle: ``,
        bold: false,
        pageBreak: ``,
        width: `auto`,
        fontSize: 15,
        text: `request DNR while patient is and of life stage, unconscious and intended not to perform as follow :`,
        alignment: `left`,
        color: ``,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        columns: [
          {
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: 20,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            text: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: 5,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            text: `Cardiopulmonary resuscitation (CPR)`,
            bold: false,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 20,
            decorationColor: ``,
            text: ``,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            width: 5,
            margin: [0, 0, 0, 0],
            text: ``,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
          },
          {
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            text: `Intubation`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            width: 20,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            text: ``,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            bold: false,
            width: 5,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            fontSize: 15,
            text: `Tracheostomy`,
            bold: false,
            decorationColor: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            width: 20,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 5,
            color: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: `Life-support e.g. respirators, ventilators used in an effort to replace or support natural breathing.`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            color: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            width: 20,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            color: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 5,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            color: ``,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            text: `Tube feeding or any other artificial or invasive form of nutrition (food) or hydration (water).`,
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            width: 20,
            color: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decoration: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decoration: ``,
            width: 5,
            color: ``,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            text: `Stimulation of circulatory system`,
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            width: 20,
            fontSize: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            width: 5,
          },
          {
            fontSize: 15,
            text: `Admitted in Intensive Case Unit (ICU)`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            width: `auto`,
            alignment: `left`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            color: ``,
            bold: false,
            width: 20,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decorationColor: ``,
            width: 5,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `Treatment of complication with  any medication or any methods.`,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            decoration: ``,
            width: 20,
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
          },
          {
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            text: `Others ..............................................................................................................................................................................`,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            decoration: ``,
            color: ``,
          },
        ],
      },
      {
        decoration: ``,
        alignment: `left`,
        decorationStyle: ``,
        bold: false,
        preserveLeadingSpaces: true,
        fontSize: 15,
        width: `auto`,
        margin: [0, 0, 0, 0],
        text: ` `,
        color: ``,
        pageBreak: ``,
        decorationColor: ``,
      },
      {
        bold: false,
        decorationStyle: ``,
        width: `auto`,
        decoration: ``,
        text: ` `,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        color: ``,
        fontSize: 15,
      },
      {
        decoration: ``,
        pageBreak: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        bold: false,
        color: ``,
        fontSize: 15,
        text: `I have acknowledged and understood well that I am able to revoke the DNR at all time as written notice by`,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        alignment: `left`,
        width: `auto`,
      },
      {
        decoration: ``,
        decorationColor: ``,
        text: `notifying physician verbally once patient is conscious. This DNR will be revoked immediately. I would like to`,
        bold: false,
        fontSize: 15,
        preserveLeadingSpaces: true,
        color: ``,
        pageBreak: ``,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        alignment: `left`,
      },
      {
        decorationStyle: ``,
        fontSize: 15,
        text: `assure that this request belongs to family member and patient's close relatives.`,
        bold: false,
        decoration: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        alignment: `left`,
        decorationColor: ``,
        pageBreak: ``,
        color: ``,
      },
      {
        color: ``,
        pageBreak: ``,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationColor: ``,
        alignment: `left`,
        text: ` `,
        decorationStyle: ``,
        width: `auto`,
        fontSize: 15,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: `Signature ...........................................................`,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            width: `auto`,
          },
          {
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            text: ``,
            color: ``,
            decoration: ``,
            width: 5,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            width: `auto`,
            text: `Relative Signature`,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
          },
          {
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            text: ``,
            width: 20,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            margin: [0, 0, 0, 0],
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            width: 42,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            text: `(..........................................................)`,
            decoration: ``,
            bold: false,
          },
          {
            bold: false,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            width: 150,
          },
          {
            bold: false,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            text: `Dated`,
            width: `auto`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        color: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        decoration: ``,
        decorationColor: ``,
        fontSize: 15,
        decorationStyle: ``,
        text: ` `,
        bold: false,
        alignment: `left`,
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `Signature ...........................................................`,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
          },
          {
            text: ``,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            width: 5,
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            color: ``,
            text: `Witness`,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            width: 45,
            bold: false,
            decoration: ``,
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            text: `Signature ...........................................................`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 5,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            text: `Witness`,
            width: `auto`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 45,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            decorationColor: ``,
          },
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: `(..........................................................)`,
            fontSize: 15,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            alignment: `left`,
          },
          {
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            text: ``,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 125,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            text: `(..........................................................)`,
            margin: [0, 0, 0, 0],
            width: `auto`,
          },
        ],
      },
      {
        pageBreak: ``,
        bold: false,
        decoration: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decorationColor: ``,
        text: ` `,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        color: ``,
        width: `auto`,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: `Note : `,
            color: ``,
            fontSize: 12,
            decorationColor: ``,
            bold: `true`,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 12,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            width: 5,
            bold: false,
            text: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            bold: false,
            pageBreak: `after`,
            text: `Write the requesting relative name and his/her relationship to the patient (e.g. next of kin, legal representative, etc.)`,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 12,
            decorationColor: ``,
          },
        ],
      },
      {
        text: `Do Not Resuscitate Request`,
        decorationColor: ``,
        decoration: ``,
        bold: `true`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        width: `auto`,
        fontSize: `18`,
        alignment: `center`,
        pageBreak: ``,
        color: ``,
        margin: [0, 0, 0, 0],
      },
      {
        alignment: `left`,
        pageBreak: ``,
        text: ` `,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        width: `auto`,
        fontSize: 15,
        color: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            width: 15,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
            text: ``,
            margin: [0, 0, 0, 0],
          },
          {
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `1) ............................................................................................... Relationship Patient ............................................................`,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            color: ``,
            bold: false,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            width: 15,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            text: `2) ............................................................................................... Relationship Patient ............................................................`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            width: 15,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decoration: ``,
          },
          {
            width: `auto`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
            text: `3) ............................................................................................... Relationship Patient ............................................................`,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            width: 15,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            decoration: ``,
            text: ``,
          },
          {
            text: `4) ............................................................................................... Relationship Patient ............................................................`,
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            width: `auto`,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            width: 15,
          },
          {
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            bold: false,
            text: `5) ............................................................................................... Relationship Patient ............................................................`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationStyle: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 15,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            width: `auto`,
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            bold: false,
            text: `6) ............................................................................................... Relationship Patient ............................................................`,
            decorationStyle: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            width: 15,
            text: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            bold: false,
            text: `7) ............................................................................................... Relationship Patient ............................................................`,
          },
        ],
      },
    ],
    pageOrientation: `portrait`,
    pageSize: `A4`,
    images: injectImages(props.items?.haveHeader),
  };
}
