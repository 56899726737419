import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea
} from 'semantic-ui-react'

import DateTextBox from '../common/DateTextBox';
import TimeFreeTextBox24 from '../common/TimeFreeTextBox24';


import styles from './CardPORehabilitationTreatmentOrthoticFoot.module.css'

export interface PORehabilitationTreatmentOrthoticFootViewDataType {
    PoRehabilitationTreatmentOrthoticFoot2: string,
    PoRehabilitationTreatmentOrthoticFoot2Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot4: string,
    PoRehabilitationTreatmentOrthoticFoot4Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot6: string,
    PoRehabilitationTreatmentOrthoticFoot6Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot8: string,
    PoRehabilitationTreatmentOrthoticFoot8Disabled: boolean,
    Ct5PainScore: string,
    Ct5PainScoreDisabled: boolean,
    Ct5PainScoreOptions: any,
    PoRehabilitationTreatmentOrthoticFoot13: string,
    PoRehabilitationTreatmentOrthoticFoot13Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot16: string,
    PoRehabilitationTreatmentOrthoticFoot16Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot18: boolean,
    PoRehabilitationTreatmentOrthoticFoot18Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot20: boolean,
    PoRehabilitationTreatmentOrthoticFoot20Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot23: string,
    PoRehabilitationTreatmentOrthoticFoot23Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot24: boolean,
    PoRehabilitationTreatmentOrthoticFoot24Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot26: string,
    PoRehabilitationTreatmentOrthoticFoot26Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot28: boolean,
    PoRehabilitationTreatmentOrthoticFoot28Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot30: boolean,
    PoRehabilitationTreatmentOrthoticFoot30Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot33: string,
    PoRehabilitationTreatmentOrthoticFoot33Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot35: string,
    PoRehabilitationTreatmentOrthoticFoot35Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot37: string,
    PoRehabilitationTreatmentOrthoticFoot37Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot44: string,
    PoRehabilitationTreatmentOrthoticFoot44Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFoot45: string,
    PoRehabilitationTreatmentOrthoticFoot45Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const PORehabilitationTreatmentOrthoticFootInitialViewData: PORehabilitationTreatmentOrthoticFootViewDataType = {
    PoRehabilitationTreatmentOrthoticFoot2: "",
    PoRehabilitationTreatmentOrthoticFoot2Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot4: "",
    PoRehabilitationTreatmentOrthoticFoot4Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot6: "",
    PoRehabilitationTreatmentOrthoticFoot6Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot8: "",
    PoRehabilitationTreatmentOrthoticFoot8Disabled: false,
    Ct5PainScore: "",
    Ct5PainScoreDisabled: false,
    Ct5PainScoreOptions: [{key: 0, value: "A", text: "A"}],
    PoRehabilitationTreatmentOrthoticFoot13: "",
    PoRehabilitationTreatmentOrthoticFoot13Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot16: "",
    PoRehabilitationTreatmentOrthoticFoot16Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot18: false,
    PoRehabilitationTreatmentOrthoticFoot18Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot20: false,
    PoRehabilitationTreatmentOrthoticFoot20Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot23: "",
    PoRehabilitationTreatmentOrthoticFoot23Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot24: false,
    PoRehabilitationTreatmentOrthoticFoot24Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot26: "",
    PoRehabilitationTreatmentOrthoticFoot26Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot28: false,
    PoRehabilitationTreatmentOrthoticFoot28Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot30: false,
    PoRehabilitationTreatmentOrthoticFoot30Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot33: "",
    PoRehabilitationTreatmentOrthoticFoot33Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot35: "",
    PoRehabilitationTreatmentOrthoticFoot35Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot37: "",
    PoRehabilitationTreatmentOrthoticFoot37Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot44: "",
    PoRehabilitationTreatmentOrthoticFoot44Disabled: false,
    PoRehabilitationTreatmentOrthoticFoot45: "",
    PoRehabilitationTreatmentOrthoticFoot45Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot1}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot2}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFoot2}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFoot',
                            name: 'PoRehabilitationTreatmentOrthoticFoot2',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot3}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Problem*</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot4}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFoot4}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot4Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFoot',
                            name: 'PoRehabilitationTreatmentOrthoticFoot4',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Goal of Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot6}>
                <TextArea
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFoot6}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot6Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFoot',
                            name: 'PoRehabilitationTreatmentOrthoticFoot6',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot7}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Treatment*</strong></p>
                </div>
            </div>
            {/* <div className={styles.PoRehabilitationTreatmentOrthoticFoot8}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFoot8}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot8Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFoot',
                            name: 'PoRehabilitationTreatmentOrthoticFoot8',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Post of treatment</strong></p>
                </div>
            </div> */}
            {/* <div className={styles.Ct5PainScore}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct5PainScore}
                    disabled={ props.viewData.Ct5PainScoreDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFoot",
                                name: "Ct5PainScore",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct5PainScoreOptions}
                />
            </div> 
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot13}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFoot13}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot13Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFoot',
                            name: 'PoRehabilitationTreatmentOrthoticFoot13',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Pain score</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot15}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Other</strong></p>
                </div>
            </div> */}
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot16}>
                <TextArea
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFoot16}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot16Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFoot',
                            name: 'PoRehabilitationTreatmentOrthoticFoot16',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot17}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Note</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot18}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFoot18}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFoot",
                                name: "PoRehabilitationTreatmentOrthoticFoot18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot20}>
                <Radio 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFoot20}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot20Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFoot",
                                name: "PoRehabilitationTreatmentOrthoticFoot20"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note to doctor</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Yes, able to perform treatment follow doctor’s presciption</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot23}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFoot23}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot23Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFoot',
                            name: 'PoRehabilitationTreatmentOrthoticFoot23',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot24}>
                <Radio 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFoot24}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFoot",
                                name: "PoRehabilitationTreatmentOrthoticFoot24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>No,</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot26}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFoot26}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot26Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFoot',
                            name: 'PoRehabilitationTreatmentOrthoticFoot26',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot27}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Instruction*</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot28}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFoot28}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot28Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFoot",
                                name: "PoRehabilitationTreatmentOrthoticFoot28"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Patient and/or family was/were informed about instruction and plan of treatment</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot30}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFoot30}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFoot30Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFoot",
                                name: "PoRehabilitationTreatmentOrthoticFoot30"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Need to be reviewed</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFoot',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{ height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFoot49}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
