import React from "react";
import { Menu } from "semantic-ui-react";

const MobileMenuBar = props => {
  
  return (
    <Menu className={`menu-bar ${props.menuBarClassName}`} secondary borderless size="large">
      {!props.hideLeftItem && <Menu.Item>{props.leftItem}</Menu.Item>}
      <Menu.Item className="chat-channel-name">{props.middleItem}</Menu.Item>
      {!props.hideRightItem && <Menu.Item position={"right"} className="right-item">
        {props.rightItem}
      </Menu.Item>}
    </Menu>
  );
};

MobileMenuBar.defaultProps = {
  leftItem: null,
  middleItem: null,
  rightItem: null,
  menuBarClassName: "",
  hideLeftItem: false,
  hideRightItem: false,
};

export default MobileMenuBar;
