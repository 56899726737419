import React from "react";
import AppleLogin from "react-apple-login";
import AppleIDLoginManager from "./AppleIDLoginManager";

export default class AppleIDLoginButton extends React.PureComponent{

  constructor(prop) {
    super(prop);
    this.appleIDLoginManager = new AppleIDLoginManager();
  }

  render(){
    return (
      <>
      <div style={{cursor: "pointer"}} >
        <AppleLogin
          clientId={this.appleIDLoginManager.clientId}
          redirectURI={this.appleIDLoginManager.redirectURI}
          state={this.appleIDLoginManager.getCSRFState()}
          responseType={"code"}
          responseMode={"query"}
          /*scope={"name email"}
          responseType={"code id_token"}
          responseMode={"form_post"}*/
          designProp={{
            height: 30,
            width: 200,
            color: "black",
            border: false,
            type: "sign-in",
            border_radius: 15,
            scale: 1,
            // locale: "en_US",
          }}
        />
      </div>
      </>
    );
  }
}
