import React, { useMemo, useState, useEffect } from "react";

// UX
import SubDrugDiseaseISegmentResultDetailUX from "./SubDrugDiseaseISegmentResultDetailUX";

// Interface
import { RunSequence, SubSegmentType } from "./sequence/DrugDiseaseInteraction";
import { ModConfirm } from "react-lib/apps/common";

type SubDrugDiseaseISegmentResultDetailProps = {
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  // data
  segmentGroupUDList?: any[];
  data?: SubSegmentType;
  index: number;
  subGroupDict?: Record<string, any[]>;
};

const SubDrugDiseaseISegmentResultDetail = (
  props: SubDrugDiseaseISegmentResultDetailProps
) => {
  const [openModConfirmRemove, setOpenModConfirmRemove] =
    useState<boolean>(false);

  const segmentGroupUDOptions = useMemo(() => {
    return (props.segmentGroupUDList || []).map((item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }, [props.segmentGroupUDList]);

  const subSegmentGroupOptions = useMemo(() => {
    return props.subGroupDict?.[props.data?.segment_group_id || ""]?.map(
      (item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })
    );
  }, [props.subGroupDict, props.data?.segment_group_id]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    props.runSequence({
      sequence: "DrugDiseaseInteraction",
      action: "CHANGE_SUB_SEGMENT",
      index: props.index,
      method: "UPDATE",
      ...data,
    });
  };

  const handleRemove = (confirm?: boolean) => (e: any) => {
    props.runSequence({
      sequence: "DrugDiseaseInteraction",
      action: "CHANGE_SUB_SEGMENT",
      method: "DELETE",
      index: props.index,
      confirm,
      callback: (type: string, data?: Record<string, any>) => {
        if (type === "CONFIRM") {
          setOpenModConfirmRemove(true);
        } else {
          setOpenModConfirmRemove(false);
        }
      },
    });
  };

  // Mod
  const handleCloseModConfirm = () => {
    setOpenModConfirmRemove(false);
  };

  console.log("subSegmentList", subSegmentGroupOptions, props);

  return (
    <div>
      <SubDrugDiseaseISegmentResultDetailUX
        // options
        segmentGroupOptions={segmentGroupUDOptions}
        subSegmentGroupOptions={subSegmentGroupOptions}
        // data
        segmentGroup={props.data?.segment_group_id}
        subSegmentGroup={props.data?.sub_segment_group_id}
        message={props.data?.message}
        // config
        disabledSubgroup={!props.data?.segment_group_id}
        // callback
        onChangeValue={handleChangeValue}
        onRemove={handleRemove(false)}
      />

      <ModConfirm
        openModal={openModConfirmRemove}
        titleName="ลบความสัมพันธ์ที่เลือก"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButtonColor="green"
        approveButtonText="ตกลง"
        content={
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "0rem 0 -1rem",
            }}
          >
            ต้องการลบรายการความสัมพันธ์ที่เลือก
          </div>
        }
        onApprove={handleRemove(true)}
        onDeny={handleCloseModConfirm}
        onCloseWithDimmerClick={handleCloseModConfirm}
      />
    </div>
  );
};

export default React.memo(SubDrugDiseaseISegmentResultDetail);
