import React, { Component } from "react";
import '@fontsource/roboto';
import ErrorBoundary from "react-lib/apps/common/ErrorBoundary";

import "./App.css";
import "./App-tab.css";
import {
  withRouter,
  Route,
  Switch,
  Redirect 
} from "react-router-dom";
import { Menu, Icon, Image, Popup, List } from "semantic-ui-react";
import { createFormDisplay } from "react-lib/apps/IsHealth/Common/Diag";
import DiagForm from "./DiagForm";
import { rules, formdata, chatlist } from "./TestData";
import Login from "./PatientLogin"
import ProfileWrap from "./ProfileWrap";
import Profile from "./Profile";
import { ChatList } from "./Chat";
import { DiagRule, DiagRuleSearch } from "./DiagRule";
import OfficialAccount from "./OfficialAccount";
import OfficialAccountList from "./OfficialAccountList";
import PrivacyPolicy from "./PrivacyPolicy";
import LineCallBack from "react-lib/apps/IsHealth/Common/LineCallBack";
import AppleIDCallBack from "react-lib/apps/IsHealth/Common/AppleIDLogin/CallBack";
// Controller
import LoginController from "react-lib/apps/IsHealth/Common/LoginController";
import ChatListController from "./ChatController";
import DiagRuleController from "./DiagRuleController";
import DiagFormController from "./DiagFormController";
import ProfileController from "./ProfileController";
import OfficialAccountController from "./OfficialAccountController";
import PaymentController from "./PaymentController";
import AddressController from "./AddressController";
import VCAppointmentController from "react-lib/apps/IsHealth/Vaccine/VCAppointmentController";
import VCAppointmentSlotController from "react-lib/apps/IsHealth/Vaccine/VCAppointmentSlotController"
import MobTransform from "./MobTransform"

// Manager
import MixInManager from "react-lib/apis/manager/MixInManager";
import PRXManager from "react-lib/apis/manager/PRXManager";
import ChatManager from "../apis/ChatManager";
import DiagManager from "../apis/DiagManager";
import UserManager from "../apis/UserManager";
import QUEManager from "react-lib/apis/manager/QUEManager";
import CoreManager from "react-lib/apis/manager/CoreManager";
import BILManager from "react-lib/apis/manager/BILManager";
import LineLoginManager from "react-lib/apps/IsHealth/Common/LineLoginManager";
import Cookies from 'js-cookie';

import ChatBox from "react-lib/apps/IsHealth/Chat/ChatBox";
import ChatController from "react-lib/apps/IsHealth/Chat/PatientChatController";
import QueueController from "react-lib/apps/QUE/QueueController";
import MSGManager from "react-lib/apis/manager/MSGManager";

import { vcMessenger } from "react-lib/compat/vc-websocket";
import TimePatient from "react-lib/apps/QUE/TimePatient";
import VCAppointment from "react-lib/apps/IsHealth/Vaccine/VCAppointment";
import VCAppointmentSlot from "react-lib/apps/IsHealth/Vaccine/VCAppointmentSlot"
import VCAppointmentView from "react-lib/apps/IsHealth/Vaccine/VCAppointmentView"
import PatientSelectAppointment from "react-lib/apps/QUE/PatientSelectAppointment";
import EmailLoginMobile from "react-lib/apps/IsHealth/Common/EmailLogin/EmailLoginMobile"
import EmailRegisterMobile from "react-lib/apps/IsHealth/Common/EmailLogin/EmailRegisterMobile"
import RegisterEmailSuccess from "react-lib/apps/IsHealth/Common/EmailLogin/RegisterEmailSuccess"
import EmailCallback from "react-lib/apps/IsHealth/Common/EmailLogin/EmailCallback"

import MobileMenuBar from "react-lib/apps/IsHealth/Common/MobileMenuBar";
import ModConfirm from "react-lib/apps/common/ModConfirm";
import ModChangeTheme from "react-lib/apps/IsHealth/Common/ModChangeTheme"
import CONFIG from "../config/config";

import "../css/Default.css";

import * as serviceWorker from '../serviceWorker';
import Fingerprint2 from 'fingerprintjs2';
import {
  askUserPermission,
  createNotificationSubscription,
} from "react-lib/apps/IsHealth/Common/push-notifications";
import PatientPayment from "./PatientPayment";
import PatientPaymentDetail from "./PatientPaymentDetail";

import { IntlProvider, FormattedMessage } from 'react-intl';
import messages from '../react-lib/localization/IsHealth/messages';
import { flattenMessages } from '../react-lib/localization/IsHealth/util';
import DrugAddressConfirm from "../Address/DrugAddressConfirm"
import SelectAddress from "../Address/SelectAddress"
import EditAddress from "../Address/EditAddress"

import ConsentForm from "./ConsentForm";
import HeaderConsentForm from "./HeaderConsentForm";
import { AppProvider } from "./PatientContext";
import config from "../config/config";



export const facebookAppId = "3314489911914234";
export const versionFacebook = "v9.0";

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  DARK: "dark",
  PENGUIN: "penguin",
  TU: "tu",
}

const ThemeChoices = [{
  key: "DEFAULT",
  text: "Default",
  value: "default"
},
{
  key: "PENTA",
  text: "Penta",
  value: "penta"
},
// {
//   key: "DARK",
//   text: "Dark",
//   value: "dark"
// },
]

const LANGUAGE = {
  TH: "th-TH",
  EN_US: "en-US"
}

const URL_PREFIX = {
  PENGUIN: "penguin",
}

const PROJECT_NAME = {
  M_B_PLUS_TELEMED: "My B+ Telemed",
  TU: "TU",
}

const GoBackButton = (props) => {
  return (
    <Icon
      name={"arrow left"}
      size={"large"}
      onClick={props.onClick}
    />
  )
}

const MenuBarName = (props) => {

  return (
    <h2 className="menu-bar-name"
    onClick={props.onClick}
  >
    {Cookies.get("project_name") || props.name || "IsHealth"}
  </h2>
  )
}

export const SettingPopup = React.forwardRef((props, ref) => {
  

  React.useEffect(() => {
    return props.onClose()
  }, [])

  return (
    <Popup
      hideOnScroll
      content={
        <List divided relaxed>
          <List.Item onClick={props.handleUserProfile}>
            <List.Header><FormattedMessage id='profile.edit_profile'/>
              </List.Header>
          </List.Item>
          {CONFIG.BOOKING_FUNCTION && <List.Item
            onClick={props.goToAppointment}
          >
            <List.Header><FormattedMessage id='appoint.look_at_appointment'/></List.Header>
          </List.Item>}
          
          {CONFIG.BOOKING_VACCINE_FUNCTION && <List.Item
            onClick={props.goToVaccineAppointment}
          >
            <List.Header><FormattedMessage id='appoint.vaccine_appointment'/></List.Header>
          </List.Item>}

          {CONFIG.BOOKING_VACCINE_FUNCTION && <List.Item
            onClick={props.goToVaccineAppointmentView}
          >
            <List.Header><FormattedMessage id='appoint.my_vaccine_appointment'/></List.Header>
          </List.Item>}

          <List.Item onClick={props.handleGoToPayment}>
            <List.Header><FormattedMessage id='payment.payment'/></List.Header>
          </List.Item>
          {Cookies.get("project_name") !== PROJECT_NAME.M_B_PLUS_TELEMED && (
            <>
              {/* <List.Item onClick={props.handleChangeLanguage} >
                <List.Header><FormattedMessage id='settings.change_language'/></List.Header>
              </List.Item> */}
              {/* <List.Item onClick={props.handleChangeTheme}>
                <List.Header>เปลี่ยนธีม</List.Header>
              </List.Item> */}
              <List.Item
                onClick={ () => {
                  props.handleLogout()
                }}
              >
                <List.Header><FormattedMessage id='auth.logout'/></List.Header>
              </List.Item>
            </>
          )}
        </List>
      }
      on="click"
      onOpen={props.onOpen}
      onClose={props.onClose}
      position='bottom left'
      open={props.open}
      trigger={
        <Icon 
          name="list"
          size="large"
        />}
    />
  )
})



class MainPatient extends Component {
  
  constructor(props) {
    super(props);

    this.chatBoxRef = React.createRef();
    this.chatListRef = React.createRef();
    this.settingRef = React.createRef();
    this.selectAddressRef = React.createRef();
    this.drugAddressConfirmRef = React.createRef();
    // To support webview in mobile native
    // var apiToken = "217113441ee20896796e922d480a61dc68ed5a25"
    // var userId = "9987"
    var apiToken = Cookies.get("apiToken")
    var userId

    this.state = {
      projectName: Cookies.get("project_name"),
      login: apiToken !== null && apiToken !== undefined,
      profile: apiToken !== null && apiToken !== undefined,
      apiToken: apiToken,

      // profile create
      // isCompleteProfile: false,
      profileStepperIndex: 0,
      openAllergyProfile: false,

      userId: userId,
      formPath: null,
      oaPath: null,
      rule: { id: null, name: "", content: {} },
      form: {},
      chatlist: [],
      greeting: <></>,

      targetDiagRule: null,
      targetForm: {},

      chatName: "Unknown",
      chatDivision: 0,

      subscription: null,
      device_id: null,

      lineLoginError: null,
      patientData: {},
      theme: "",
      openLogoutMod: false,
      openDeleteMod: false,
      openChangeThemeMod: false,
      openSettingMod: false,

      // For Chat, to prevent loading when open chat page
      channelList: [],
      chatLoading: false,
      chatSearchValue: "",
      language: Cookies.get("language") ? Cookies.get("language") : LANGUAGE.TH,

      backToApp: false,
      landingLoading: false,
      consentFormLang: "th-TH",

      alreadyRedirect: false
      
    };
    var params = new URLSearchParams(window.location.search);
    this.ruleid = params.get("ruleid");

    // Set login, profile, window.location to debug individual page
    this.ruleid = null;
    // window.location = "#/";

    this.mixInManager = new MixInManager();
    this.msgManager = new MSGManager();
    this.prxManager = new PRXManager();
    this.diagManager = new DiagManager();
    this.chatManager = new ChatManager();
    this.userManager = new UserManager();
    this.queManager = new QUEManager();
    this.coreManager = new CoreManager();
    this.bilManager = new BILManager();
    this.lineLoginManager = new LineLoginManager();
    this.loginController = new LoginController({
      mixInManager: this.mixInManager
    });
    this.chatController = new ChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager
    });
    this.chatListController = new ChatListController({
      chatManager: this.chatManager
    });
    this.diagRuleController = new DiagRuleController({
      diagManager: this.diagManager
    });
    this.diagFormController = new DiagFormController({
      diagManager: this.diagManager
    });
    this.profileController = new ProfileController({
      userManager: this.userManager,
      prxManager: this.prxManager
    });
    this.officialAccountController = new OfficialAccountController({
      diagManager: this.diagManager,
      coreManager: this.coreManager,
    });
    this.queueController = new QueueController({
      queManager: this.queManager,
      prxManager: this.prxManager
    })
    this.paymentController = new PaymentController({
      bilManager: this.bilManager
    })
    this.addressController = new AddressController({
      coreManager: this.coreManager,
      prxManager: this.prxManager
    })
    this.vcAppController = new VCAppointmentController({
      queManager: this.queManager,
      prxManager: this.prxManager
    })
    this.vcAppointmentSlotController = new VCAppointmentSlotController({
      prxManager: this.prxManager
    })
    // Initial Path after Login

    if (apiToken && userId) {
      this.chatListController
        .getChatChanel(apiToken, userId)
        .then(response => {
          let data = response[0];
          let error = response[1];
          if (data) {
            
            if (CONFIG.ISHEALTH_PLATFORM_PROFILE) { 
              // ของใหม่ ไปกรอกข้อมูลส่วนตัว 
              if (data.items && data.items.length === 0) {
                this.props.history.push("/profile")
              }
            } else {
              // ของเดิม ไป ทำ แบบ ประเมิน 
              if (data.items && data.items.length === 0) {
                this.props.history.push("/diagrule")
              }
            }
          }
        })
        .catch(error => {
          console.log('error should not see me')
        });
    }

    this.lastReadMessage = null

    vcMessenger.onMessage(message => {
      console.log({ message })
      if (message["data_message_type"] === "MESSAGE") {
        if(this.chatListRef){
          this.loadChannelList()
        }
        if(this.chatBoxRef && this.chatBoxRef.receivedMessage){
          this.chatBoxRef.receivedMessage({
            chatChannelId: parseInt(message["channel_id"])
          });
        }
      } else if (message["data_message_type"] === "MESSAGE_READ") {
        if (this.lastReadMessage === message.message_id) {
          return
        }
        this.lastReadMessage = message.message_id
        if (this.chatBoxRef && this.chatBoxRef.receivedMessageRead) {
          this.chatBoxRef.receivedMessageRead({
            chatChannelId: parseInt(message["channel_id"]),
            messageId: parseInt(message["message_id"])
          });
        }
      }
      else if (message["event"] === "RECLASSIFY") {
        if (this.chatBoxRef && this.chatBoxRef.loadPatientEncounter) {
          this.chatBoxRef.loadPatientEncounter()
        }
      }
    });
  }

  getUrlParameter = name => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    let results = regex.exec(this.props.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  getDivisionChild = async ({ parent, apiToken } = {}) => {
    let childList = []
    const [res, error] = await this.officialAccountController.getDivision({
      parent,
      apiToken
    })
    if(res){
      childList = res.items
    }
    return childList
  }

  initFacebookSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: versionFacebook
            });
            window.FB.getLoginStatus(({ authResponse }) => {
            })
            window.FB.AppEvents.logPageView();
        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    });
  }

  componentDidMount() {
    
    console.log(" MainPatient CDM" )
    this.initFacebookSdk()

    // Set greetings for OA if requested
    // console.log(" CDM window.location", window.location)
    let currentURL = window.location.href;
    if(currentURL.includes(URL_PREFIX.PENGUIN)){
      this.setState({ theme: THEME.PENGUIN, projectName: PROJECT_NAME.M_B_PLUS_TELEMED })
      document.documentElement.setAttribute('data-theme', THEME.PENGUIN);
      Cookies.set("theme", THEME.PENGUIN, { path: "/" })
      Cookies.set("project_name", PROJECT_NAME.M_B_PLUS_TELEMED, { path: "/" })
    } else if (CONFIG.COMPANY === "TU") {
      document.documentElement.setAttribute("data-theme", THEME.TU);
      Cookies.set("theme", THEME.TU, { path: "/" });
      Cookies.set("project_name", PROJECT_NAME.TU, {path: "/"});
    } else {
      this.setState({ theme: THEME.PENTA })
      document.documentElement.setAttribute('data-theme', THEME.PENTA);
      Cookies.set("theme", THEME.PENTA, { path: "/" });
    }
    // let hashOA = parseInt(this.getUrlParameter("oa"));
    // let params = new URLSearchParams(window.location.search);
    // let oa = hashOA ? hashOA : parseInt(params.get("oa"));

    let pathArray = window.location.pathname.split('/');
    console.log(" pathArray", pathArray, window.location.pathname)
    let oaChannelId 
    if (pathArray.length === 3 && pathArray[1] === "LoginWithOA" && pathArray[0] === "") {
      oaChannelId = pathArray[2]
      if (oaChannelId && !(this.state.apiToken || Cookies.get("apiToken"))) {
        // Need Login then save into cookies
        console.log("Component DidMount oaChannelId: ", oaChannelId);
        if (!isNaN(oaChannelId)) {
          Cookies.set("oaChannelId", oaChannelId,  { path: "/" })
          // Cookies.set("oaChannelId", oaChannelId, { path: "/" });
        }
      } 
    } else {
      if (!window.location.pathname.includes("callback")) {
        Cookies.remove("oaChannelId", { path: "/" })
        // Cookies.remove("oaChannelId", { path: "/" });
      }
    }

    this.setState({ greeting: <DefaultGreeting /> });
    // Refer to P'Mahn ,Rong say it not use anymore 
    // if (oa !== null) {
    //   axios.get("https://admin.ishealth.app/master/", {}).then(async res => {
    //     if (
    //       Object.keys(res).includes("data") &&
    //       Object.keys(res.data).includes("greeting")
    //     ) {
    //       let greeting = res.data.greeting.find(
    //         item => parseInt(item.division_id) === oa
    //       );

    //       if (greeting !== null && greeting !== undefined) {
    //         Cookies.set("greetingDiv", greeting.division_id, { path: "" })
    //         Cookies.set("oaDetail", oa, { path: "" })
    //         // const divisionChild = await this.getDivisionChild({ parent: greeting.division_id })
    //         this.setState({
    //           greeting: (
    //             <OAGreeting
    //               name={greeting.name}
    //               greeting={greeting.greeting}
    //               image={greeting.image}
    //             />
    //           )
    //         });
    //         // Set cookies to shortcut to OA post-login
    //         // if(divisionChild.length > 0){
    //         //   Cookies.set("oahome", divisionChild, { path: "/" });
    //         // } else {
    //         //   Cookies.set("oahome", oa, { path: "/" });
    //         // }
    //       }
    //     }
    //   });
    // }

    // Goto form
    let isForm = currentURL.includes("/form/");
    if (!this.state.login && isForm) {
      var pathName = currentURL.split("/form/");
      pathName = "/form/" + pathName[pathName.length - 1];
      // Set cookies to form URL
      // Cookies.set("form", pathName, { path: "/" });
      Cookies.set("form", pathName, { path: "/" })
      console.log(" this.state.formPath", this.state.formPath, pathName)
      this.setState({ formPath: pathName })
    }
    
    let isOA = currentURL.includes("/OA/");
    if (!this.state.login && isOA) {
      var pathName = currentURL.split("/OA/");
      pathName = "/OA/" + pathName[pathName.length - 1];
      // Set cookies to OA URL
      Cookies.set("OA", pathName, { path: "/" });
      console.log(" this.state.oaPath", this.state.oaPath, pathName)
      this.setState({ oaPath: pathName })
    }

    var rule = rules[Object.keys(rules)[0]];
    var form = createFormDisplay(rule, formdata);

    this.setState({ rule: rule, form: form, chatlist: chatlist });
    if (this.state.apiToken || Cookies.get("apiToken")) {
      let token = this.state.apiToken ? this.state.apiToken : Cookies.get("apiToken")
      this.getPatientDetail()
      this.setWebsocket({ apiToken: token });
    }

    // Ask user for web notification and get subscription
    askUserPermission().then(consent => {
      if (consent !== "granted") {
        console.error({
          name: "Consent denied",
          message: "You denied the consent to receive notifications",
          code: 0
        });
      }
    });


    if (!window.location.pathname.includes("/callback")) {
      // รอให้ line callback ทำงานให้เสร็จก่อน
      if (window.requestIdleCallback) { 
        requestIdleCallback(() => {
          console.log("get fingerprint on requestIdleCallback");      
          this.getFingerPrint();
          this.getApiToken(this.props);
        })
      } else {
        console.log("fallback with timeout");
          setTimeout(() => {
            this.getFingerPrint();
            this.getApiToken(this.props);
        }, 500)
      }
    }

    if(config.LOGIN_WITH_TUH_TRANSFORM){
      if (this.state.apiToken || Cookies.get("apiToken")){
        if(!this.props.location.pathname.includes("/tuh-transform")){
          this.props.history.replace({
            pathname: `/tuh-transform${this.props.location.pathname}` + this.props.location.search,
            state: this.props.location.state
          });
        }
      } else{
        this.props.history.push({ pathname: "/tuh-transform/login" + document.location.search, state: {} });
      } 
    }
    
  }

  componentWillMount() {
    this.currentPathname = this.props.history.location.pathname;
    this.unlisten = this.props.history.listen(
      (location, action) => {
        if (CONFIG.LOGIN_WITH_TUH_TRANSFORM) {
          if (!location.pathname.includes("/tuh-transform")) {
            this.props.history.replace({
              pathname:
                `${"/tuh-transform"}${location.pathname}` + location.search,
              state: location.state,
            });
          }
        }
        
        this.currentPathname = location.pathname;
      }
    );
  }
  componentWillUnmount() {
    this.unlisten();
  }

  getApiToken = async (props) => {
    if(config.LOGIN_WITH_TUH_TRANSFORM){
      return
    }
    console.log(" getApiToken ", props)
    var apiToken
    var userId
    if (typeof(window.iosNative) !== 'undefined') {
      try {
        const _apiToken = await window.iosNative._apiToken
        const _userId = await window.iosNative._userId
        apiToken = _apiToken
        userId = _userId
        console.log(apiToken+" "+userId)
        Cookies.set("apiToken", _apiToken, { path: "/" });
        Cookies.set("userId", _userId, { path: "/" });
        this.setState({ apiToken: _apiToken, userId: _userId })
        this.setWebsocket({ apiToken: _apiToken });
        this.getPatientDetail({ token: _apiToken })
        let path = this.state.formPath ? this.state.formPath : Cookies.get("form")
        if(path){
          this.props.history.push({
            pathname: path,
          });
        }

        let oaPath = this.state.oaPath ? this.state.oaPath : Cookies.get("oa")
        if(oaPath){
          this.props.history.push({
            pathname: oaPath,
          });
        }
      } catch (e) {
        console.error(e)
        console.log(e.message)
      }
    } else if(typeof(window.MobNative) !== 'undefined') {
      let userProfile = window.MobNative.getUser()
      const user = JSON.parse(userProfile)
      apiToken = user.token;
      userId = user.profile.userId;
      Cookies.set("apiToken", user.token, { path: "/" });
      Cookies.set("userId", user.profile.userId, { path: "/" });
      this.getPatientDetail({ token: user.token })
      this.setWebsocket({ apiToken: user.token });
      this.setState({ apiToken: user.token, userId: user.profile.userId })
      let path = this.state.formPath ? this.state.formPath : Cookies.get("form")
      if(path){
        this.props.history.push({
          pathname: path,
        });
      }
    } else {
      if(props){
        // const { cookies } = props;
        apiToken = Cookies.get("apiToken");
        userId = Cookies.get("user_id");
      }
    }
    if(!apiToken){
      console.log("apiToken", apiToken)
      this.handleGoToLogin()
    }
    if(navigator){
      console.log(navigator, "navigator")
      // var userLang = navigator.language || navigator.userLanguage; 
      var userLang = navigator.languages[0]
      if(userLang.toLowerCase().includes("en")){
        userLang = LANGUAGE.EN_US
      } else if (userLang.toLowerCase().includes("th")){
        userLang = LANGUAGE.TH
      } else {
        userLang = LANGUAGE.EN_US
      }
      this.setState({ language: userLang })
    }
    this.setState({
      login: apiToken !== null && apiToken !== undefined,
      profile: apiToken !== null && apiToken !== undefined,
      apiToken: apiToken,
      userId: userId,
    })
  }
  
  getFingerPrint = () => {
    var options = {}
    Fingerprint2.getPromise(options).then( (components) => {
      // components is array of {key: 'foo', value: 'component value'}
      var values = components.map(function (component) { return component.value })
      var device_id = Fingerprint2.x64hash128(values.join(''), 31);
      this.setState({device_id: device_id})
      console.log("MP Fingerprint id:", device_id);
      // Get subscription endpoint object
      createNotificationSubscription()
      .then( (subscription) => {
        this.setState({subscription: subscription})
        
        console.log("MP Success get notification subscription..");
        console.log(subscription);
      })
      .catch((err) => {
        console.log('MP error getSubScriptionObejct',)
        console.error(
          "Couldn't create the notification subscription",
          err,
          "name:",
          err.name,
          "message:",
          err.message,
          "code:",
          err.code
        );
      });
    })
  }

  setWebsocket = ({ apiToken } = {}) => {
    console.log("setWebsocket apiToken: ", apiToken , "CONFIG.WS_HOST", CONFIG.WS_HOST, "vcMessenger", vcMessenger );
    vcMessenger.connect(
      "MSG",
      {
        token: apiToken
      },
      CONFIG.WS_HOST
    );
  };

  // Save only the selected field to save storage space (Questions, Answers, are all the same for this rule)
  saveForm = form => {
    var f = {};
    for (var [id, item] of Object.entries(form)) {
      if ("selected" in item) {
        f[id] = item.selected;
      } else {
        return null;
      }
    }
    // Replace this with API call to save form
    this.setState({ form: form });
  };

  handleLoginSuccess = async response => {
    console.log(" handleLoginSuccess response: ", response)
    let greetingdiv = Cookies.get("greetingDiv")
    let divisionChild = []
    if(greetingdiv){
      divisionChild = await this.getDivisionChild({ parent: greetingdiv, apiToken: response.token })
    }
    console.log("....... handle LoginSuccess: ", response)
    Cookies.set("apiToken", response.token, { path: "/" });
    Cookies.set("userId", response.profile.userId, { path: "/" });
    this.setWebsocket({ apiToken: response.token });

    this.setState({
      login: true,
      profile: true,
      apiToken: response.token,
      userId: response.profile.userId
    });

    let oaChannelId = Cookies.get("oaChannelId")
    if (oaChannelId) {
      this.setState({ alreadyRedirect : true})
    }



    // Initial Path after Login
    if (response.token && response.profile.userId) {
      this.getPatientDetail({ token: response.token })
      let oahome = null
      if(divisionChild.length > 0){
        oahome = divisionChild
      } else {
        oahome = Cookies.get("oaDetail")
      }
      let formURL = Cookies.get("form");
      if (oahome !== null && oahome !== undefined) {
        // Has cookies for shortcut to OA
        if(Array.isArray(oahome) && oahome.length > 0){
          Cookies.remove("oaDetail");
          Cookies.remove("greetingDiv");
          this.props.history.push({
            pathname: "/greetingList/" + oahome[0].parent + "/",
          })
          console.log("handleLoginSuccess Goto /greetingList")
        } else {
          this.setState({ chatDivision: oahome });
          Cookies.remove("oaDetail");
          Cookies.remove("greetingDiv");
          this.props.history.push({
            pathname: "/oa/greeting/" + oahome + "/",
          });
          console.log("handleLoginSuccess Goto oa/greeting/")
        }
      } else if (formURL !== null && formURL !== undefined) {
        Cookies.remove("form", {path: "/"});
        this.props.history.replace({ pathname: "/" })
        this.props.history.push({
          pathname: formURL,
        });
        console.log("handleLoginSuccess Goto fromURL ?", formURL)
      } else if ( oaChannelId )  {
        console.log("handleLoginSuccess Goto /OA/ ?", oaChannelId)
        this.props.history.push("/OA/" + oaChannelId)
        Cookies.remove("oaChannelId", { path: "/" })
      } else {
        console.log( "handleLoginSuccess Got Else ")
        this.chatListController
          .getChatChanel(response.token, response.profile.userId)
          .then(response => {
            let data = response[0];
            let error = response[1];
            if (data) {
              if (data.items && data.items.length === 0) { 
                if (CONFIG.ISHEALTH_PLATFORM_PROFILE) { 
                  // ของใหม่ ไปกรอกข้อมูลส่วนตัว 
                  this.props.history.replace({
                    pathname: "/profile",
                    state: {}
                  });
                  return;
                } else {
                  // ของเดิม ไป ทำ แบบ ประเมิน 
                  this.props.history.replace({
                    pathname: "/diagrule",
                    state: {}
                  });
                  return;
                }
              }
            }
          })
          .catch(error => {});
          console.log(" handleLoginSuccess push /")
          this.props.history.push("/")
      }
    }
  };

  handleSelectDiagRule = data => {
    var obj = JSON.parse(data.content);
    this.setState({ targetForm: obj });
    this.setState({ targetDiagRule: data });
    this.props.history.push({ pathname: "/form/" + data.id + "/", state: {} });
  };

  handleStartDiagRuleList = data => {
    this.props.history.push({ pathname: "/diagrulesearch", state: {} });
  };

  handleChatChannelSelected = path => {
    console.log("handleChatChannelSelected ", path)
    this.props.history.push({ pathname: path, state: {} });
  };

  handleRedirectChatChannelSelected = path => {
    window.location.assign(path)
  }

  handleUnfollowChannelSelected = item => {
    // 1 = dm,
    // 2 = mom and child 

    let channel = item === 1 ? "DM" : "MOM"
    // alert("Waiting API Follow " + channel )
    if (item === 1) {
      window.location.assign(CONFIG.DM_BPLUS_URL)
    } else if (item === 2) {
      window.location.assign(CONFIG.MOM_BPLUS_URL)
    }
    
  }

  gotoChatChannel = chatChannelId => {
    this.props.history.replace("/", { state: {} });
    if (chatChannelId) {
      let path = "/Chat/" + chatChannelId + "/";
      this.handleChatChannelSelected(path);
    }
  };


  handleDeleteUser = async () => {

    console.log("this.state", this.state)

    let userId = this.state.userId;
    let apiToken = this.state.apiToken ? this.state.apiToken : Cookies.get("apiToken")
    console.log('apiToken: ', apiToken);

    console.log("userId", userId);
    // Clear Data
    const [data, error] = await this.profileController.postDeleteUser({
      apiToken,
      userId: userId ? parseInt(userId) : ""
    });

    if(window.FB){
      window.FB.logout()
    }

    if (typeof(window.iosNative) !== "undefined") {
      window.iosNative.logout();
      return;
    }

    if (typeof(window.MobNative) !== "undefined") {
      window.MobNative.logout();
      return;
    }

    console.log("window.FB",window.FB)
    if(typeof(window.FB) !== "undefined" ){
      console.log("Logout FB")
      window.FB.logout()
    } 

    serviceWorker.unregister();
    console.log("unregister service worker");
    // Remove cookied
    Cookies.remove("apiToken", { path: "/" });
    Cookies.remove("project_name", { path: "/" });
    Cookies.remove("firstPageAns", { path: "/" });
    Cookies.remove("userId", { path: "/" });
    Cookies.remove('x-csrftoken');
    
    // Reset State
    this.setState({
      apiToken: null,
      userId: null,
      login: false,
      profile: false,
      openDeleteMod: false
    });
    // console.log(' remove all cookies', this.props.cookies)
    if(config.LOGIN_WITH_TUH_TRANSFORM){
      this.props.history.push({ pathname: "/tuh-transform/login" + document.location.search, state: {} });
    }else{
      this.props.history.push({ pathname: "/Login" + document.location.search, state: {} });
    }
  };


  // Handle Menu Bar
  handleLogout = () => {

    if(window.FB){
      window.FB.logout()
    }
    
    if (typeof(window.iosNative) !== "undefined") {
      window.iosNative.logout();
      return;
    }

    if (typeof(window.MobNative) !== "undefined") {
      window.MobNative.logout();
      return;
    }

    console.log("window.FB",window.FB)
    if(typeof(window.FB) !== "undefined" ){
      console.log("Logout FB")
      window.FB.logout()
    } 

    serviceWorker.unregister();
    console.log("unregister service worker");
    // Remove cookied
    Cookies.remove("apiToken", { path: "/" });
    Cookies.remove("project_name", { path: "/" });
    Cookies.remove("firstPageAns", { path: "/" });
    Cookies.remove("userId", { path: "/" });
    Cookies.remove('x-csrftoken');
    
    // Reset State
    this.setState({
      apiToken: null,
      userId: null,
      login: false,
      profile: false,
      openLogoutMod: false
    });
    console.log(' remove all cookies', this.props.cookies)
    if(config.LOGIN_WITH_TUH_TRANSFORM){
      this.props.history.push({ pathname: "/tuh-transform/login" + document.location.search, state: {} });
    }else{
      this.props.history.push({ pathname: "/Login" + document.location.search, state: {} });
    }
  };

  handleGoBack = () => {
    console.log(" handleGoBack ")

    // if display ProfileCreate then step back 
    // two case , 1 in last step with allergy 
    if (this.state.profileStepperIndex === 2 && this.state.openAllergyProfile) {
      this.setState({openAllergyProfile :false})
      return
    }
    // two case , 2 in stepper profilecreate
    if (this.state.profileStepperIndex > 0 && this.state.profileStepperIndex <= 2) {
      let next =  this.state.profileStepperIndex - 1
      this.setState({profileStepperIndex :next})
      return
    }

    if(typeof(window.iosNative) !== 'undefined' && this.state.backToApp){
      return window.iosNative.dismissWebView()
    } else if(typeof(window.MobNative) !== 'undefined' && this.state.backToApp){
      return window.MobNative.dismissWebView()
    }
    else {
      this.setState({ backToApp: false })
      if (this.props.location.pathname.includes("Chat")) {
        this.props.history.push("/");
      } else if (this.props.location.pathname.includes("profile")) {
        this.props.history.push("/");
      } else if (this.props.location.pathname.includes("/payment/")){
        this.props.history.push("/");
      }
      else {
        this.props.history.goBack();
      }
    }
  };

  handleGoToMain = () => {
    console.log("handleGoToMain ")
    this.props.history.push("/");
  }

  handleSetChatName = ({ name, division} = {}) => {
    this.setState({ chatName: name, chatDivision: division });
  };

  handleAddDiagRule = () => {
    this.props.history.push({ pathname: "/diagrulesearch", state: {} });
  };

  handleUserProfile = () => {
    this.props.history.push({ pathname: "/profile", state: {} });
  };

  handleGoToPayment = () => {
    this.props.history.push({ pathname: "/payment", state: {} });
  }

  handleAddHospitalProfile = () => {
    if(!this.chatBoxRef){
      return
    }
    let channelDetail = this.chatBoxRef.getChannelDetail();
    if (channelDetail && channelDetail.division) {
      this.setState(
        {
          chatDivision: channelDetail.division
        },
        this.props.history.push({
          pathname: "/oa/" + channelDetail.division + "/",
          state: {}
        })
      );
    }
  };

  getPatientDetail = async ({ token } = {}) => {
    let apiToken = this.state.apiToken ? this.state.apiToken : Cookies.get("apiToken")
    if(token){
      apiToken = token
    }
    const [data, error] = await this.profileController.getPatient({
      apiToken,
    });
    if (data) {
      this.setState({ patientData: data })
    }
  }

  /** Login with Line */
  handleLoginWithLine = () => {
    console.log(' handleLoginWithLine !!! ')
    this.setState({ lineLoginError: null })
    this.lineLoginManager.openLogin();
  }

  handleLineLoginSuccess = async (props) => {
    
    this.setState({ landingLoading: true })
    let greetingdiv = Cookies.get("greetingDiv")
    let formURL = Cookies.get("form");
    let divisionChild = []
    if(greetingdiv){
      divisionChild = await this.getDivisionChild({
        parent: greetingdiv, apiToken: props.pentaToken.token
      })
    }

  
    // Every login line force update avatar from line 
    if ( props.pentaToken.token && props.profile?.pictureUrl) {
      let params = {
        apiToken: props.pentaToken.token,
        image_url: props.profile.pictureUrl
      };
      this.profileController.patchMyProfile(params)
    }
  
  
    Cookies.set("apiToken", props.pentaToken.token, { path: "/" });
    Cookies.set("userId", props.pentaToken.profile.userId, { path: "/" });

    this.setWebsocket({ apiToken: props.pentaToken.token });
    this.setState({
      login: true,
      profile: true,
      apiToken: props.pentaToken.token,
      userId: props.pentaToken.profile.userId
    });

    // Initial Path after Login

    if (props.pentaToken.token && props.pentaToken.profile.userId) {
      this.getPatientDetail()
      
      let oahome = null
      if(divisionChild.length > 0){
        oahome = divisionChild
      } else {
        oahome = Cookies.get("oaDetail")
      }
      if (oahome !== null && oahome !== undefined) {
        // Has cookies for shortcut to OA
        if(Array.isArray(oahome) && oahome.length > 0){
          Cookies.remove("oaDetail");
          Cookies.remove("greetingDiv");
          this.props.history.replace({ pathname: "/" })
          this.props.history.push({
            pathname: "/greetingList/" + oahome[0].parent + "/",
          })
        } else{
          this.setState({ chatDivision: oahome });
          Cookies.remove("oaDetail");
          Cookies.remove("greetingDiv");
          this.props.history.replace({ pathname: "/" })
          this.props.history.push({
            pathname: "/oa/greeting/" + oahome + "/",
          });
        } 
      } else if (formURL !== null && formURL !== undefined) {
        Cookies.remove("form", {path: "/"});
        this.props.history.replace({ pathname: "/" })
        this.props.history.push({
          pathname: formURL,
        })
      } else {
        this.chatListController
          .getChatChanel(props.pentaToken.token, props.pentaToken.profile.userId)
          .then(response => {
            let data = response[0];
            let error = response[1];
            if (data) {
              if (data.items && data.items.length === 0) {
                if (CONFIG.ISHEALTH_PLATFORM_PROFILE) { 
                  // ของใหม่ ไปกรอกข้อมูลส่วนตัว 
                  this.props.history.replace({
                    pathname: "/profile",
                    state: {}
                  });
                  return;
                } else {
                  // ของเดิม ไป ทำ แบบ ประเมิน 
                  this.props.history.replace({
                    pathname: "/diagrule",
                    state: {}
                  });
                  return;
                }
              }
            }
            this.props.history.replace({ pathname: "/" })
          })
          .catch(error => {});
        }
      }
    }

  handleLineLoginFailed = ({ error } = {}) => {
    this.setState({ lineLoginError: error })
    this.props.history.push({ 
      pathname: "/",
    })
    console.log(error.message, "Line login error")
  }

  handleNavigationMessage = ({ content = "", contentPayload = "", contentType, channelId } = {}) => { 
    if(content.includes("{หน้าโปรไฟล์}")){
      return (
         <div dangerouslySetInnerHTML={{
          __html: content.replace(/{หน้าโปรไฟล์}/g, (
            "<a href='/profile'>หน้าโปรไฟล์</a>"
          ))
      }}/>
      )
    } else if (content.includes("{ตารางออกตรวจผู้ให้คำปรึกษา}")){
      return (
         <div onClick={() => this.props.history.push({
          pathname: "/Appointment",
          state: {
            channelId
          }
         })} dangerouslySetInnerHTML={{
          __html: content.replace(/{ตารางออกตรวจผู้ให้คำปรึกษา}/g, (
            "<a nohref>ตารางออกตรวจผู้ให้คำปรึกษา</a>"
            // "<a href='/Appointment'>ตารางออกตรวจผู้ให้คำปรึกษา</a>"
          ))
      }}/>
    )} else if (content.includes("{กดเพื่อยืนยันการจัดส่งยา}")){
      return (
         <div onClick={() => this.props.history.push({
          pathname: `/drug-address-confirm/${contentPayload.encounter}/`,
          channelId
         })} dangerouslySetInnerHTML={{
          __html: content.replace(/{กดเพื่อยืนยันการจัดส่งยา}/g, (
            "<a nohref>กดเพื่อยืนยันการจัดส่งยา</a>"
          ))
      }}
      />
    )} else if (content.includes("{ดูประวัติการนัดหมาย}")){
      return (
        <div onClick={() => this.props.history.push({
          pathname: "/VCAppointmentView",
          state: {
            channelId
          }
        })} dangerouslySetInnerHTML={{
          __html: content.replace(/{ดูประวัติการนัดหมาย}/g, (
            "<a nohref>ดูประวัติการนัดหมาย</a>"
            // "<a href='/VCAppointmentView'>ดูประวัติการนัดหมาย</a>"
          ))
      }}/>
    )}  else if (contentType === "bill"){
      let routePath = "/payment/"
      if(contentPayload.invoice_id){
        routePath = `/paymentDetail/${contentPayload.invoice_id}`
      }
      return (
        <div onClick={() => this.props.history.push({
          pathname: routePath,
         })}><a nohref>{content}</a></div>
    )}
    else {
      return content
    }
  }

  goToAppointment = () => {
    this.props.history.push("/Appointment")
  }

  goToVaccineAppointment = () => {
    this.props.history.push("/VCAppointment")
  }

  goToVaccineAppointmentView = () => {
    this.props.history.push("/VCAppointmentView")
  }


  goToVaccineAppointmentSlot = ({ divisionId, divisionName, year, month,appointmentId }) => {
    this.props.history.push({ 
      pathname: "/VCAppointmentSlot/", 
      state: { divisionId: divisionId, divisionName: divisionName, year: year, month: month, appointmentId }
    })
  }

  goToDiagRule = ({diag_url}) => {
    this.props.history.push(diag_url)
  }

  handleGoToLogin = () => {
    console.log(" handleGoToLogin /Login" )
    if(config.LOGIN_WITH_TUH_TRANSFORM){
      this.props.history.push("/tuh-transform/login" + document.location.search)
    }else{
      this.props.history.push("/Login" + document.location.search)
    }

  }

  handleOpenDeleteMod = () => {
    this.setState({ openSettingMod: false })  
    this.setState({ openDeleteMod: !this.state.openDeleteMod })
  }

  handleOpenLogoutMod = () => {
    this.setState({ openSettingMod: false })  
    this.setState({ openLogoutMod: !this.state.openLogoutMod })
  }

  handleOpenChangeThemeMod = () => {
    this.setState({ openChangeThemeMod: !this.state.openChangeThemeMod })
  }

  handleChangeTheme = (theme) => {
    let newTheme = theme
    this.setState({ theme, openChangeThemeMod: false })
    if(theme === "default"){
      newTheme = ""
    }
    document.documentElement.setAttribute('data-theme', newTheme);
  }

  handleOpenSettingMod = () => {
    this.setState({ openSettingMod: true })
  }

  handleCloseSettingMod = () => {
    this.setState({ openSettingMod: false })
  }

  showBadge = (sum_unread) => {
    try {
      console.log("sum_unread = "+sum_unread)
      if (typeof window.iosNative !== "undefined") {
        window.iosNative.showBadge(sum_unread.toString());
      } else if (typeof window.MobNative !== "undefined") {
        window.MobNative.showBadge(sum_unread.toString());
      }
    } catch(e) {
      console.error("showBadge is not function...")
    }
  }

  // for Chat --> dont want to fetch chat channel everytime
  loadChannelList = async () => {
    if(this.state.chatSearchValue){
      return
    }
    let apiToken = this.state.apiToken ? this.state.apiToken : Cookies.get("apiToken")
    let userId = this.state.userId ? this.state.userId : Cookies.get("userId")
    if(this.state.channelList.length === 0){
      this.setState({ chatLoading: true })
    }
    const [data, error] = await this.chatListController.getChatChanel(apiToken, userId);
    let sum_unread = 0
    if (data) {
      if ( data && data.items && Array.isArray(data.items) && data.items.length > 0) {
        console.log("data ", data)
        data.items.forEach((item) => {
          sum_unread += item.unread_message_count
        })
      }
      this.showBadge(sum_unread);
      this.setState({ channelList: data.items, chatLoading: false })
    } else {
      this.showBadge(sum_unread);
      this.setState({ channelList: [],chatLoading: false })
    }
  }

  searchChannelList = async () => {
    let apiToken = this.state.apiToken ? this.state.apiToken : Cookies.get("apiToken")
    let userId = this.state.userId ? this.state.userId : Cookies.get("userId")
    // let search = inputRef.current.inputRef.current.value;
    let search = this.state.chatSearchValue
    if(this.state.channelList.length === 0){
      this.setState({ chatLoading: true })
    }
    const [data, error] = await this.chatListController.searchChatChannel(apiToken, userId, search);
    this.setState({ chatLoading: false })
    if (data) {
      this.setState({ channelList: data.items })
    } else {
      this.setState({ channelList: [] })
    }
  }

  handleChatSearchChange = async (e, { value }) => {
    this.setState({ chatSearchValue: value })
  }

  handleChangeLanguage = async () => {
    if(this.state.language === LANGUAGE.TH){
      Cookies.set("language", LANGUAGE.EN_US)
      this.setState({ language: LANGUAGE.EN_US })
    } else {
      Cookies.set("language", LANGUAGE.TH)
      this.setState({ language: LANGUAGE.TH })
    }
  }

  handleSelectAddressBack = () => {
    if(this.selectAddressRef && this.selectAddressRef.goToDrugAddressConfirm){
      this.selectAddressRef.goToDrugAddressConfirm()
    }
  }

  handleDrugAddressConfirmBack = () => {
    if(this.drugAddressConfirmRef && this.drugAddressConfirmRef.goBack){
      this.drugAddressConfirmRef.goBack()
    }
  }

  // ProfileStepper 
  handleChangeStep = (idx) => {
    this.setState({profileStepperIndex: idx})
  }

  handleOpenAllergy = (isOpen) => {
    this.setState({openAllergyProfile: isOpen})
  }

  render() {
    return (
      <IntlProvider locale='th-TH' 
        // messages={flattenMessages(messages["en-US"])}
      messages={flattenMessages(messages[this.state.language])}
      >
      <AppProvider value={{ apiToken: this.state.apiToken, language: this.state.language }}>
        <div className={"App-plain"}>
        <ModConfirm 
          open={this.state.openLogoutMod} 
          // alertText="ต้องการออกจากระบบใช่หรือไม่"
          titleName={<FormattedMessage id='common.warning'/>}
          alertText={<FormattedMessage id='auth.ask_logout'/>}
          buttonLeft={<FormattedMessage id='common.cancel'/>}
          buttonRight={<FormattedMessage id='common.confirm'/>}
          onButtonLeftClick={this.handleOpenLogoutMod}
          onButtonRightClick={this.handleLogout}
        />

        <ModConfirm 
          open={this.state.openDeleteMod} 
          // alertText="ต้องการออกจากระบบใช่หรือไม่"
          titleName={<FormattedMessage id='common.warning'/>}
          alertText={<FormattedMessage id='auth.ask_delete'/>}
          buttonLeft={<FormattedMessage id='common.cancel'/>}
          buttonRight={<FormattedMessage id='common.confirm'/>}
          onButtonLeftClick={this.handleOpenDeleteMod}
          onButtonRightClick={this.handleDeleteUser}
        />

        <ModChangeTheme 
          open={this.state.openChangeThemeMod} 
          options={ThemeChoices}
          value={this.state.theme}
          onButtonLeftClick={this.handleOpenChangeThemeMod}
          onButtonRightClick={this.handleChangeTheme}
        />
            <Switch>
            <Route
                path="/email-callback"
                render={props => {
                  return (
                    <EmailCallback 
                      onLoginSuccess={this.handleLoginSuccess}
                      onLoginFailed={this.handleLineLoginFailed}
                    />
                  ) 
                }}
              ></Route>
              <Route
                path={["/Login","/LoginWithOA/:id"]}
                render={props => (
                  <Login
                    apiToken={this.state.apiToken}
                    onHaveApiToken={this.handleGoToMain}
                    subscription={this.state.subscription}
                    device_id={this.state.device_id}
                    loginLine={this.handleLoginWithLine}
                    loading={this.state.landingLoading}
                    detail={
                      <div>
                        {this.state.greeting}
                        <br />
                        {this.state.lineLoginError && (
                          <>
                            <div style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
                              {this.state.lineLoginError}
                            </div>
                            <br />
                          </>
                        )}
                      </div>
                    }
                    controller={this.loginController}
                    onLoginSuccess={this.handleLoginSuccess}
                />
                )}
              ></Route>
              <Route
                path="/RegisterEmailSuccess/"
                render={props => (
                  <RegisterEmailSuccess onHaveApiToken={this.handleGoToMain}/>
                )}
              />
              <Route
                path="/EmailLogin/"
                render={props => (
                  <EmailLoginMobile onHaveApiToken={this.handleGoToMain}/>
                )}
              />
              <Route
                path="/EmailRegister/"
                render={props => (
                  <EmailRegisterMobile onHaveApiToken={this.handleGoToMain}/>
                )}
              />
              <Route
                path="/Chat/:chatChannelId/"
                render={props => (
                  <div className="chat-box-wrapper" style={{ height: window.innerHeight }}>
                  <MobileMenuBar
                    menuBarClassName="chat-box"
                    leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                    middleItem={(
                      <h5> 
                        {this.state.chatName && this.state.chatName.length < 30 ? this.state.chatName : (this.state.chatName ? this.state.chatName.slice(0, 30) + "..." : "Unknown")}
                      </h5>
                    )}
                    rightItem={(
                      <Icon
                        name={"file alternate"}
                        size={"large"}
                        onClick={this.handleAddHospitalProfile}
                      />
                    )}
                  />
                <ChatBox
                    {...props}
                    division={this.state.chatDivision}
                    ref={ref => (this.chatBoxRef = ref)}
                    noApiToken={this.getApiToken}
                    apiToken={this.state.apiToken}
                    userId={this.state.userId}
                    patientData={this.state.patientData}
                    controller={this.chatController}
                    useNormalTextAreaKey={true}
                    onSetChannelName={this.handleSetChatName}
                    onNavigationMessage={this.handleNavigationMessage}
                    onSetBackToApp={(value) => {
                      this.setState({ backToApp: value })
                    }}
                  />
                </div>
                )}
              >
              </Route>
              <Route path="/diagrulesearch">
                <>
                  <MobileMenuBar
                    leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                    middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                  />
                  <DiagRuleSearch
                    noApiToken={this.handleGoToLogin}
                    apiToken={this.state.apiToken}
                    controller={this.diagRuleController}
                    onSeleted={this.handleSelectDiagRule}
                    handleGoBack={this.handleGoBack}
                  />
                </>
              </Route>
              <Route path="/diagrule">
                <>
                  <MobileMenuBar
                    leftItem={<SettingPopup 
                      onOpen={this.handleOpenSettingMod}
                      onClose={this.handleCloseSettingMod}
                      open={this.state.openSettingMod}
                      handleUserProfile={this.handleUserProfile}
                      handleGoToPayment={this.handleGoToPayment}
                      goToAppointment={this.goToAppointment}
                      goToVaccineAppointment={this.goToVaccineAppointment}
                      goToVaccineAppointmentView={this.goToVaccineAppointmentView}
                      handleLogout={this.handleOpenLogoutMod}
                      handleChangeTheme={this.handleOpenChangeThemeMod}
                      handleChangeLanguage={this.handleChangeLanguage}
                    />}
                    middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                  />
                  <DiagRule onStart={this.handleStartDiagRuleList} />
                </>
              </Route>
              <Route
                path="/form/:id/"
                render={props => (
                  <div className="App-main">
                    <div className="App-content">
                      <MobileMenuBar
                        leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                        middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                      />
                      <DiagForm
                        {...props}
                        noApiToken={this.handleGoToLogin}
                        apiToken={this.state.apiToken}
                        controller={this.diagFormController}
                        diagRule={this.state.targetDiagRule}
                        onFinished={this.gotoChatChannel}
                      />
                    </div>
                  </div>
                )}
              ></Route>
              <Route path="/profile">
                <>
                  <MobileMenuBar
                    leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                    middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                  />
                  { CONFIG.ISHEALTH_PLATFORM_PROFILE ? 
                    <ProfileWrap
                      // isCompleteProfile={this.state.isCompleteProfile}
                      hideLogout={this.state.projectName === PROJECT_NAME.M_B_PLUS_TELEMED}
                      noApiToken={this.handleGoToLogin} 
                      apiToken={this.state.apiToken}
                      controller={this.profileController}
                      onLogout={this.handleOpenLogoutMod}
                      handleGoBack={this.handleGoBack}
                      // saveComplete={()=>{this.setState({isCompleteProfile: true})}}
                      profileStepperIndex={this.state.profileStepperIndex}
                      onChangeProfileStepper={this.handleChangeStep}
                      openAllergyProfile={this.state.openAllergyProfile}
                      onSetOpenAllergy={this.handleOpenAllergy}
                      onDeleteUser={this.handleOpenDeleteMod}
                    /> : 
                    <Profile
                      hideLogout={this.state.projectName === PROJECT_NAME.M_B_PLUS_TELEMED}
                      noApiToken={this.handleGoToLogin} 
                      apiToken={this.state.apiToken}
                      controller={this.profileController}
                      onLogout={this.handleOpenLogoutMod}
                      handleGoBack={this.handleGoBack}
                      onDeleteUser={this.handleOpenDeleteMod}
                    />
                  }
                </>
              </Route>
              <Route
                exact
                path="/OA/:id"
                render={props => ( this.state.login ? 
                  <>
                    <MobileMenuBar
                      leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                      middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                    />
                    <OfficialAccount
                      {...props}
                      apiToken={this.state.apiToken}
                      division={this.state.chatDivision}
                      controller={this.officialAccountController}
                      handleGoBack={this.handleGoBack}
                      onSetBackToApp={(value) => {
                        this.setState({ backToApp: value })
                      }}
                    />
                  </> : <Redirect from="/OA/:id" to={`/LoginWithOA/${props.match.params.id}`}/>
                )}
              ></Route>
                <Route
                path="/oa/greeting/:id"
                render={props => (
                  <>
                    <MobileMenuBar
                      middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                      rightItem={<Menu.Item position={"right"} className="right-item">
                          <Icon
                            name="discussions"
                            size={"large"}
                            onClick={this.handleGoToMain}
                          />
                        </Menu.Item>}
                    />
                    <OfficialAccount
                      {...props}
                      apiToken={this.state.apiToken}
                      division={this.state.chatDivision}
                      controller={this.officialAccountController}
                      handleGoBack={this.handleGoBack}
                    />
                  </>
                )}
              ></Route>
              <Route
                path="/greetingList/:id"
                render={props => (
                  <>
                    <OfficialAccountList
                      {...props}
                      apiToken={this.state.apiToken}
                      controller={this.officialAccountController}
                    />
                  </>
                )}
              />
              <Route
                exact
                path="/callback"> 
                  <LineCallBack 
                    onLoginSuccess={this.handleLineLoginSuccess} 
                    onLoginFailed={this.handleLineLoginFailed} 
                    subscription={this.state.subscription}
                    device_id={this.state.device_id}
                  />
              </Route>
              <Route
                exact
                path="/apple-callback">
                  <AppleIDCallBack
                    onLoginSuccess={this.handleLineLoginSuccess}
                    onLoginFailed={this.handleLineLoginFailed}
                    subscription={this.state.subscription}
                    device_id={this.state.device_id}
                  />
              </Route>
              <Route exact path="/policy">
                <>
                  <MobileMenuBar
                    leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                    middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                  />
                  <PrivacyPolicy />
                </>
              </Route>
              <Route exact path="/Appointment" render={props => { 
                console.log(" Router  /Appointment/ props: ", props)
                
                return (
                <>
                  <TimePatient 
                    {...props}
                    header={<MobileMenuBar
                      leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                      middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                    />}
                    noApiToken={this.handleGoToLogin} 
                    apiToken={this.state.apiToken}
                    patientData={this.state.patientData}
                    controller={this.queueController}
                    handleGoBack={this.handleGoBack}
                  />
                </>
              )}}/>
              <Route exact path="/VCAppointment" render={props => { 
                console.log(" Router  /VCAppointment/ props: ", props)
                
                return (
                  <VCAppointment
                    header={<MobileMenuBar
                      leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                      middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                    />}
                    controller={this.vcAppController}
                    goToVaccineAppointmentSlot={this.goToVaccineAppointmentSlot}
                  />
              )}}/>
              <Route exact path="/VCAppointmentView" render={props => {
                return (
                  <VCAppointmentView
                    header={<MobileMenuBar
                      leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                      middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                    />}
                    patientData={this.state.patientData}
                    controller={this.vcAppController}
                    queueController={this.queueController}
                    goToVaccineAppointmentSlot={this.goToVaccineAppointmentSlot}
                  />
              )}}/>
              <Route exact path="/VCAppointmentSlot" render={props => { 
                console.log(" Router  /VCAppointmentSlot/ props: ", props)
                
                return (
                <>
                  <VCAppointmentSlot
                      {...props}
                      header={<MobileMenuBar
                        leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                        middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                      />}
                      noApiToken={this.handleGoToLogin} 
                      controller={this.vcAppController}
                      apiToken={this.state.apiToken}
                      params={props.location?.state}
                      goToDiagRule={this.goToDiagRule}
                      goToVaccineAppointmentView={this.goToVaccineAppointmentView}
                  ></VCAppointmentSlot>
                </>
              )}}/>
              <Route exact path="/VCAppointmentView" render={props => { 
                console.log(" Router  /VCAppointmentView/ props: ", props)
                
                return (
                <>
                  <VCAppointmentView
                      header={<MobileMenuBar
                      leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                      middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                    />}
                      noApiToken={this.handleGoToLogin} 
                      apiToken={this.state.apiToken}
                      patientData={this.state.patientData}
                      queueController={this.queueController}
                      goToVaccineAppointmentSlot={this.goToVaccineAppointmentSlot}
                  ></VCAppointmentView>
                </>
              )}}/>
              <Route exact path="/Appointment/:appointId/" render={props => { 
                console.log(" Router  /Appointment/:appointId/ props: ", props)
                
                return (
                <>
                  <MobileMenuBar
                    leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                    middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                  />
                  <PatientSelectAppointment
                    {...props}
                    noApiToken={this.handleGoToLogin} 
                    apiToken={this.state.apiToken}
                    controller={this.queueController}
                  />
                  {/* <TimePatientEdit
                    {...props}
                    noApiToken={this.handleGoToLogin} 
                    apiToken={this.state.apiToken}
                    patientData={this.state.patientData}
                    controller={this.queueController}
                    handleGoBack={this.handleGoBack}
                  /> */}
                </>
              )}}/>
              <Route exact path="/payment/:divisionId?/" render={props => (
                <PatientPayment 
                {...props}
                apiToken={this.state.apiToken}
                controller={this.paymentController}
                patientData={this.state.patientData}
                onSetBackToApp={(value) => {
                  this.setState({ backToApp: value })
                }}
                // onNoPatientId={this.getPatientDetail}
                noApiToken={this.getApiToken}
                menuBar={<MobileMenuBar
                      leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                      middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                    />}
                  /> 
              )}/>
              <Route exact path="/paymentDetail/:id/" render={props => (
                <PatientPaymentDetail 
                {...props}
                apiToken={this.state.apiToken}
                controller={this.paymentController}
                patientData={this.state.patientData}
                menuBar={<MobileMenuBar
                      leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                      middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                  />
                }/> 
              )}/>
              <Route exact path="/tuh-transform/:page?/:id?/:id2?" render={props => { 
                return (
                <>
                  <MobTransform
                    profileController={this.profileController}
                    chatListController={this.chatListController}
                    queueController={this.queueController}
                    paymentController={this.paymentController}
                    chatController={this.chatController}
                    officialAccountController={this.officialAccountController}
                    diagRuleController={this.diagRuleController}
                    diagFormController={this.diagFormController}
                    addressController={this.addressController}
                  />
                </>
              )}}/>
              <Route exact path="/consent-form/:id/:page?/" render={props => (
                <div 
                  className="background-consent" 
                  style={{
                    ...(this.state.theme === THEME.PENGUIN && { backgroundImage:  "url(" +  process.env.PUBLIC_URL + "/images/backgroundConcent.png" + ")"}),
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  }}> 
                  <ConsentForm
                    {...props}
                    onFormLanguageChange={(lang) => {
                      console.log(lang, "lang")
                      let language = "th-TH"
                      if(lang && typeof(lang) === "string" && ["en", "en-us", "eng", "english"].includes(lang.toLowerCase())){
                        language = "en-US"
                      }
                      this.setState({ consentFormLang: language })
                    }}
                    menuBar={<HeaderConsentForm
                      hideRightItem
                      hideLeftItem={!props.match.params.page || props.match.params.page < 1}
                      leftItem={<GoBackButton onClick={() => {
                        // if(!props.match.params.page || props.match.params.page < 2){
                        //   if (typeof(window.iosNative) !== 'undefined') {
                        //     window.iosNative.diagConfirm();
                        //   } else if(typeof(window.MobNative) !== 'undefined') {
                        //     window.MobNative.diagConfirm();
                        //   }
                        // } else {
                          this.handleGoBack()
                        // }
                      }}/>}
                      middleItem={<div className="" >{
                        this.state.consentFormLang === "en-US" ? "Consent Form" : "การยินยอมให้เปิดเผยข้อมูล"
                      }</div>}
                    />}
                    apiToken={this.state.apiToken}
                    patientData={this.state.patientData}
                    controller={this.diagFormController}
                    noApiToken={this.getApiToken}
                  />
                </div>
              )}/>
              <Route exact path="/drug-address-confirm/:encounterId/" render={props => (
                <DrugAddressConfirm
                  {...props}
                  ref={ref => (this.drugAddressConfirmRef = ref)}
                  menuBar={<MobileMenuBar
                    // hideLeftItem
                    leftItem={<GoBackButton
                      onClick={this.handleDrugAddressConfirmBack}
                      />}
                    middleItem={<h3>{<FormattedMessage id='drug_delivery.confirm_drug_address'/>}</h3>}
                  />}
                  apiToken={this.state.apiToken}
                  patientData={this.state.patientData}
                  controller={this.addressController}
                  noApiToken={this.getApiToken}
                  onCancel={this.handleDrugAddressConfirmBack}
                />
              )}/>
              <Route exact path="/select-address/:encounterId?/" render={props => (
                <SelectAddress
                  {...props}
                  ref={ref => (this.selectAddressRef = ref)}
                  menuBar={<MobileMenuBar
                    leftItem={<GoBackButton onClick={this.handleSelectAddressBack}/>}
                    middleItem={<h3>{<FormattedMessage id='drug_delivery.select_address'/>}</h3>}
                  />}
                  apiToken={this.state.apiToken}
                  patientData={this.state.patientData}
                  controller={this.addressController}
                  noApiToken={this.getApiToken}
              />
              )}/>
              <Route exact path="/edit-address/:addressId/" render={props => (
                <EditAddress
                  {...props}
                  menuBar={<MobileMenuBar
                    leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                    middleItem={<h3>{<FormattedMessage id='drug_delivery.edit_address'/>}</h3>}
                  />}
                  apiToken={this.state.apiToken}
                  patientData={this.state.patientData}
                  controller={this.addressController}
                  noApiToken={this.getApiToken}
              />
              )}/>
                <Route exact path="/edit-address/" render={props => (
                  <EditAddress
                    {...props}
                    menuBar={<MobileMenuBar
                      leftItem={<GoBackButton onClick={this.handleGoBack}/>}
                      middleItem={<h3>{<FormattedMessage id='drug_delivery.add_edit_address'/>}</h3>}
                    />}
                    apiToken={this.state.apiToken}
                    patientData={this.state.patientData}
                    controller={this.addressController}
                    noApiToken={this.getApiToken}
                />
              )}/>
              <Route
                path="/"
                render={props => (
                  <>
                    <MobileMenuBar
                      leftItem={<SettingPopup 
                        onOpen={this.handleOpenSettingMod}
                        onClose={this.handleCloseSettingMod}
                        open={this.state.openSettingMod}
                        handleUserProfile={this.handleUserProfile}
                        handleGoToPayment={this.handleGoToPayment}
                        goToAppointment={this.goToAppointment}
                        goToVaccineAppointment={this.goToVaccineAppointment}
                        goToVaccineAppointmentView={this.goToVaccineAppointmentView}
                        handleLogout={this.handleOpenLogoutMod}
                        handleChangeTheme={this.handleOpenChangeThemeMod}
                        handleChangeLanguage={this.handleChangeLanguage}
                      />}
                      middleItem={<MenuBarName onClick={this.handleGoToMain}/>}
                      rightItem={
                        <Icon
                          name={"hospital"}
                          size={"large"}
                          onClick={this.handleAddDiagRule}
                        />
                      }
                    />
                    <ChatList
                      ref={ref => (this.chatListRef = ref)}
                      apiToken={this.state.apiToken}
                      userId={this.state.userId}
                      controller={this.chatListController}
                      noApiToken={this.getApiToken}
                      // noApiToken={this.handleGoToLogin}
                      isLoading={this.state.chatLoading}
                      onChannelSelected={this.handleChatChannelSelected}
                      onRedirectedChannelSelected={this.handleRedirectChatChannelSelected}
                      loadChannelList={this.loadChannelList}
                      channelList={this.state.channelList}
                      onSearchChange={this.handleChatSearchChange}
                      searchChannelList={this.searchChannelList}
                      chatSearchValue={this.state.chatSearchValue}
                      onUnfollowChannelSelected={this.handleUnfollowChannelSelected}
                     
                    />
                  </>
                )}
              />
            </Switch>
        </div>
        </AppProvider>
      </IntlProvider>
    );
  }
}

const DefaultGreeting = props => (
  <div className="detail">
    <span className="header darkblue">Is</span>
    <span className="header">Health</span>
    <br />
    <br />
    <div>
      แพลตฟอร์มสำหรับคัดกรองอาการด้วยตนเอง โดยแบบฟอร์มมาตรฐานจากโรงพยาบาล
      และปรึกษาโรงพยาบาลผ่านระบบออนไลน์ กรณีตรวจเจอว่ามีความเสี่ยง
    </div>
    <br />
    <div>
      ลดการมาโรงพยาบาลโดยไม่จำเป็น ลดความเสี่ยงในการติดเชื้อ ในภาวะวิกฤต
    </div>
    <br />
  </div>
);

const OAGreeting = props => (
  <div className="detail">
    {props.image ? (
      <div style={{ textAlign: "-webkit-center" }}>
        <Image style={{ width: 120, height: 120 }} src={props.image} />
      </div>
    ) : null}
    <br />
    <br />
    <span className="header">{props.name}</span>
    <br />
    <br />
    <div>{props.greeting}</div>
    <br />
    <br />
    <div>Powered by IsHealth</div>
    <br />
    <br />
  </div>
);

export default withRouter(MainPatient);
