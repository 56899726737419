import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
// ui LAB
import CardHistoryCentralLab from "../LAB/CardHistoryCentralLab";
// ui ORM
import CardORRegistrationAreaUX from "./CardORRegistrationAreaUX";
import PreOPButtonAction from "./PreOPButtonAction";

const CARD_KEY: string = "orRegistrationAreaData";
const FORM_CODE: string = "CardORRegistrationArea";
const FORM_NAME: string = "OR Registration Area";
const FORM_VERSION: string = "1.0";

type CardORRegistrationAreaProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;
  selectedEncounter?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PreOperationSequence?: any;
  HistoryCentralLabSequence?: any;
};

const CardORRegistrationAreaInitial: CardORRegistrationAreaProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PreOperationSequence: {},
  HistoryCentralLabSequence: {},
};

const CardORRegistrationArea: React.FC<CardORRegistrationAreaProps> = (
  props: any
) => {
  const [openModHistoryCentralLab, setOpenModHistoryCentralLab] =
    useState<boolean>(false);

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }
    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  const handleShowLabData = () => {
    setOpenModHistoryCentralLab(true);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  return (
    <>
      {(props.errorMessage?.[CARD_KEY] || props.successMessage?.[CARD_KEY]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_KEY}`, null);
            props.setProp(`successMessage.${CARD_KEY}`, null);
          }}
          error={props.errorMessage?.[CARD_KEY]}
          success={props.successMessage?.[CARD_KEY]}
        />
      )}

      <CardORRegistrationAreaUX
        // function
        onChangeData={handleChangeData}
        onShowLabData={handleShowLabData}
        // options
        masterOptions={props.masterOptions}
        // seq
        PreOperationSequence={props.PreOperationSequence}
        // component
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
      />

      {openModHistoryCentralLab && (
        <Modal
          size="large"
          closeIcon
          open={openModHistoryCentralLab}
          onClose={() => setOpenModHistoryCentralLab(false)}
        >
          <CardHistoryCentralLab
            // function
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            HistoryCentralLabSequence={props.HistoryCentralLabSequence}
            // data
            buttonLoadCheck={props.buttonLoadCheck}
            patientId={props.selectedOrOrder?.patient_id}
            selectedEncounter={props.selectedEncounter}
            hidePrintButton={true}
          />
        </Modal>
      )}
    </>
  );
};

CardORRegistrationArea.defaultProps = CardORRegistrationAreaInitial;

export default React.memo(CardORRegistrationArea);
