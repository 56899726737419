import React, { useEffect, useState, useMemo } from "react";
import { EmployeeToken } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { formatDatetime } from "react-lib/utils/dateUtils";
import CardSupplyDeliveryHistoryUX from "./CardSupplyDeliveryHistoryUX";

const CARD_SUPPLY_DELIVERY_HISTORY = "CardSupplyDeliveryHistory";
const CardSupplyDeliveryHistory = (props: any) => {
    const [selectedRow, setSelectedRow] = useState<any>(null);

  useEffect(() => {
    props.runSequence({
      sequence: "SupplyDeliveryHistory",
      restart: true,
    });
    props.runSequence({ sequence: "SupplyDelivery", restart: true});

    return () => {
      props.runSequence({
        sequence: "SupplyDeliveryHistory",
        clear: true,
      });
      props.runSequence({ sequence: "SupplyDelivery", clear: true });
    };
  }, []);

  const deliveryHistoryList = useMemo(() => {
    return (props.SupplyDeliveryHistorySequence?.supplyDeliveryHistoryList || []).map(
      (item: any) => ({
        ...item,
        performDate: item.perform_datetime
          ? formatDatetime(item.perform_datetime)
          : "",
      })
    );
  }, [props.SupplyDeliveryHistorySequence?.supplyDeliveryHistoryList]);

  const handleChangeValue =
    (key: string, keyChecked: string) => (e: any, v: any) => {
      props.setProp("SupplyDeliveryHistorySequence", {
        ...props.SupplyDeliveryHistorySequence,
        [keyChecked]: true,
        [key]: v.value,
      });
    };
  const handleChangeChecked =
    (key: string) =>
    (e: any, { checked }) => {
      props.setProp(`SupplyDeliveryHistorySequence.${key}`, checked);
    };

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(`SupplyDeliveryHistorySequence.${key}`, date);
  };
  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.pk && rowInfo?.original?.pk === selectedRow?.pk
            ? "#cccccc"
            : "white",
      },
      onClick: () => setSelectedRow(rowInfo.original),
      onDoubleClick: (e: any) => {
        let item = rowInfo?.original;
        props.runSequence({
          sequence: "SupplyDelivery",
          action: "select",
          id: item?.pk
        });
        props.onSelected();
      },

    };
  };

  
  return (
    <>
    
      <CardSupplyDeliveryHistoryUX
      deliveryHistoryList={deliveryHistoryList}
        masterOptions={props.masterOptions}
        statusOptions={[
            {key:1,value:1,text:"PRINTED"},
            {key:2,value:2,text:"CHECKED"},
            {key:3,value:3,text:"DELIVERED"},
            {key:4,value:4,text:"TRANSPORTED"},
            {key:5,value:5,text:"RECEIVED"},
            {key:6,value:6,text:"CANCELED"},    
        ]}
        handleChangeValue={handleChangeValue}
        handleChangeChecked={handleChangeChecked}
        handleChangeDate={handleChangeDate}
        selectedRow={selectedRow}
        getTrProps={handleRowProps}
        selectedType={props.SupplyDeliveryHistorySequence?.selectedType}
        selectedStatus={props.SupplyDeliveryHistorySequence?.selectedStatus}
        selectedOrderDivision={
          props.SupplyDeliveryHistorySequence?.selectedOrderDivision
        }
        selectedPerformDivision={
          props.SupplyDeliveryHistorySequence?.selectedPerformDivision
        }
        selectedOrderStartDate={
          props.SupplyDeliveryHistorySequence?.selectedOrderStartDate
        }
        selectedOrderEndDate={
          props.SupplyDeliveryHistorySequence?.selectedOrderEndDate
        }
        selectedPerformStartDate={
          props.SupplyDeliveryHistorySequence?.selectedPerformStartDate
        }
        selectedPerformEndDate={
          props.SupplyDeliveryHistorySequence?.selectedPerformEndDate
        }
        selectedUser={props.SupplyDeliveryHistorySequence?.selectedUser}
        checkedType={props.SupplyDeliveryHistorySequence?.checkedType}
        checkedStatus={props.SupplyDeliveryHistorySequence?.checkedStatus}
        checkedOrderDivision={
          props.SupplyDeliveryHistorySequence?.checkedOrderDivision
        }
        checkedPerformDivision={
          props.SupplyDeliveryHistorySequence?.checkedPerformDivision
        }
        checkedOrderDate={props.SupplyDeliveryHistorySequence?.checkedOrderDate}
        checkedPerformDate={
          props.SupplyDeliveryHistorySequence?.checkedPerformDate
        }
        checkedUser={props.SupplyDeliveryHistorySequence?.checkedUser}
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "SupplyDeliveryHistory",
                action: "search",
                card: CARD_SUPPLY_DELIVERY_HISTORY,
                buttonLoadKey: `${CARD_SUPPLY_DELIVERY_HISTORY}_SEARCH`,
              });
            }}
            // data
            paramKey={`${CARD_SUPPLY_DELIVERY_HISTORY}_SEARCH`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_SUPPLY_DELIVERY_HISTORY}_SEARCH`]
            }
            // config
            style={{ with: "100%", height: "100%" }}
            size="small"
            name="SEARCH"
            title="ค้นหา"
            color="blue"
          />
        }
        employeeCode={
          <EmployeeToken
            onEnterToken={(code) => {
              props.runSequence({
                sequence: "SupplyDeliveryHistory",
                action: "searchToken",
                code: code,
              });
            }}
            onClearToken={() =>
              props.setProp("SupplyDeliveryHistorySequence", {
                ...props.SupplyDeliveryHistorySequence,
                selectedUserToken: "",
                selectedUser: "",
                loading: false,
                error: null,
              })
            }
            
            fluid={true}
            placeholder="Code"
            error={props.SupplyDeliveryHistorySequence?.error}
            loading={props.SupplyDeliveryHistorySequence?.loading}
            employeeName={
              props?.SupplyDeliveryHistorySequence?.selectedUser || null
            }
          />
        }
        onClear={() =>{
            props.runSequence({
                sequence:"SupplyDeliveryHistory",
                action:"clear"
            })
        }}
      />


      
      
  
    </>
  );
};

export default CardSupplyDeliveryHistory;
