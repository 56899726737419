import {
  CSSProperties,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Popup,
  TextArea,
} from "semantic-ui-react";
import { DateTextBox } from "react-lib/apps/common";
import { useInterval } from "react-lib/utils/hooksUtils";

import {
  patientTypeOptions,
  deliveryTypeOptions,
  deliveryStatusOptions,
} from "./telepharOptions";
import {
  formatDateToStringBe,
  formatDatetime,
} from "react-lib/utils/dateUtils";
import moment from "moment";
import CardPatientPharmaListUX from "../common/CardPatientPharmaListUX";
import CONFIG from "config/config";
import { useHistory } from "react-router-dom";

import "./CardDashboardTelephar.scss";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const styles = {
  button: {
    padding: "14px 0",
    minWidth: "150px",
    borderRadius: "10px",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
};

let interval = CONFIG.POLLING_TRANSPORT_TELEPHAR_SEC * 1000;

const CardDashboardTelephar = (props: any) => {
  const history = useHistory();

  // Telephar State Search Dashboard
  const [hn, setHn] = useState("");
  const [deliveryNo, setDeliveryNo] = useState("");
  const [en, setEn] = useState("");
  const [searchName, setSearchName] = useState("");
  const [displaySearch, setDisplaySearch] = useState("");
  const [patientType, setPatientType] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [consultStatus, setConsultStatus] = useState("");
  const [startDate, setStartDate] = useState(formatDateToStringBe(moment()));
  const [endDate, setEndDate] = useState(formatDateToStringBe(moment()));
  const [openRemark, setOpenRemark] = useState(false);
  const [rowSelete, setRowSelete] = useState<any>({});
  const [remarkStr, setRemarkStr] = useState("");

  const queryParams = useMemo(() => {
    return {
      patientName: searchName,
      hn,
      patientType,
      startDate,
      endDate,
      en,
      deliveryType,
      deliveryStatus,
      deliveryNo,
      consultStatus,
      // orderPerformDiv,
    };
  }, [
    searchName,
    hn,
    patientType,
    startDate,
    endDate,
    en,
    deliveryType,
    deliveryStatus,
    deliveryNo,
    consultStatus,
  ]);

  useEffect(() => {
    props.runSequence({ sequence: "DashboardTelephar", restart: true });
    return () => {
      props.runSequence({ sequence: "DashboardTelephar", clear: true });
    };
  }, []);

  // -useInterval(() => {
  //   console.debug("useInterval: called ");
  //   props.runSequence({
  //     sequence: "DashboardTelephar",
  //     action: "search",
  //     query: queryParams,
  //   });
  // }, interval);

  useEffect(() => {
    const lastItem = props.notificationMessageList?.slice(-1)?.[0];

    if (
      lastItem?.id?.includes("SHIPPING_UPDATE") &&
      props.DashboardTelepharSequence?.sequenceIndex
    ) {
      props.runSequence({
        sequence: "DashboardTelephar",
        action: "search",
        query: queryParams,
      });
    }
  }, [props.notificationMessageList]);

  const formattedDatetime = useCallback((date?: string) => {
    if (!date) {
      return { format: "", raw: null };
    }

    const addHours = moment(date).clone().add(0, "hours");

    // workaround issue 62441
    return {
      format: formatDatetime(addHours),
      raw: addHours,
    };
  }, []);

  const getDeliverColor = useCallback((original: any) => {
    const status = original.shipping_order?.delivery_status;
    let color = "";

    if (original.receivedDateTime && status === "DELIVERED") {
      const diff = moment().diff(
        moment(original.rawReceivedDateTime),
        "minutes"
      );

      if (diff > 60) {
        color = "rgba(255, 222, 222, 1)";
      } else if (diff > 30) {
        color = "rgba(255, 241, 199, 1)";
      }
    }

    return color;
  }, []);

  const isDoctor = useMemo(() => {
    return props.django?.user?.role_types?.includes("DOCTOR");
  }, [props.django]);

  const fastCompanyId = useMemo(() => {
    if (Array.isArray(props.masterData?.shippingCompany)) {
      let lalaCompanyId = props.masterData?.shippingCompany?.find(
        (item: any) => item.code === "Lala"
      )?.id;
      console.log("Found Lala Id");
      return lalaCompanyId;
    }
    return 1;
  }, [props.masterData?.shippingCompany]);

  const consultStatusOptions = useMemo(() => {
    return (props.DashboardTelepharSequence?.classifyList || []).map(
      (item: any) => ({
        key: item.triage_level,
        value: item.triage_level,
        text: item.description,
      })
    );
  }, [props.DashboardTelepharSequence?.classifyList]);

  const pharmacyList = useMemo(() => {
    return (props.DashboardTelepharSequence?.drugOrderQueue || []).map(
      (drugOrder: any) => {
        const deliveryStatus = deliveryStatusOptions.find(
          (item) => item.value === drugOrder?.shipping_order?.delivery_status
        );

        const sentDateTime = formattedDatetime(
          drugOrder?.shipping_order?.delivery_datetime
        );
        const receivedDateTime = formattedDatetime(
          drugOrder?.shipping_order?.delivery_received_datetime
        );

        const sentDateDiff = moment().diff(moment(sentDateTime.raw), "days");

        return {
          startDateTime: `${moment(drugOrder?.started)
            .zone("+0700")
            .format("DD/MM/YYYY HH:mm")}`,
          encounter: `${drugOrder?.encounter_number}`,
          patientType: drugOrder?.encounter_is_telemed
            ? "Telemed"
            : "OPD Onsite",
          fullname: `${drugOrder?.pre_name ? drugOrder?.pre_name + " " : ""}${
            drugOrder?.first_name
          } ${drugOrder?.middle_name ? drugOrder?.middle_name + " " : ""}${
            drugOrder?.last_name
          } \nHN: ${drugOrder?.hn}`,
          deliveryType:
            drugOrder?.shipping_order?.shipping_company?.id == fastCompanyId ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  alt="fast"
                  src="/static/images/transport/fast-delivery.png"
                  width="20px"
                />{" "}
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  ส่งด่วน
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  alt="fast"
                  src="/static/images/transport/normal-delivery.png"
                  width="20px"
                />{" "}
                <span
                  style={{ color: "rgba(1, 71, 163, 1)", fontWeight: "bolder" }}
                >
                  ส่งธรรมดา
                </span>
              </div>
            ),
          deliveryStatus: (
            <div
              style={{
                width: "100%",
                borderRadius: "5px",
                color: deliveryStatus?.color,
                backgroundColor: deliveryStatus?.bg,
                padding: "0.35rem 0.5rem",
                fontSize: ".9375rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {deliveryStatus?.text}
              {sentDateDiff > 2 && deliveryStatus?.value === "PICKED_UP" && (
                <div
                  style={{
                    backgroundColor: "rgba(218, 0, 0, 1)",
                    color: "white",
                    borderRadius: "5px",
                    padding: "0.05rem 0.25rem",
                    width: "fit-content",
                    marginLeft: "0.25rem",
                  }}
                >
                  {">"}2 วัน
                </div>
              )}
            </div>
          ),
          deliveryNo: drugOrder?.shipping_order?.tracking_number,
          // workaround issue 62441
          sentDateTime: sentDateTime.format,
          receivedDateTime: receivedDateTime.format,
          rawReceivedDateTime: receivedDateTime.raw,
          consultStatus:
            props.DashboardTelepharSequence?.classifyList?.find(
              (acc: any) =>
                acc.triage_level === drugOrder?.encounter_triage_level
            )?.description || drugOrder?.encounter_triage_level,
          address: (
            <Popup
              className="popup-shadow-custom"
              trigger={
                <div
                  style={{
                    display: "inline-block",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  {drugOrder?.shipping_order?.full_address}
                </div>
              }
              content={
                <div style={{ minWidth: "295px" }}>
                  <div style={{ color: "#070707" }}>
                    {drugOrder?.shipping_order?.full_address}
                  </div>
                  <div
                    style={{
                      borderBottom: `1px solid #e9e9e9`,
                      margin: "0.75rem 0",
                    }}
                  ></div>
                  <div style={{ color: "#939292" }}>{drugOrder?.note}</div>
                  <div style={{ color: "#939292", marginTop: "0.25rem" }}>
                    {drugOrder?.owner_name}:{" "}
                    {drugOrder?.shipping_order?.tel_mobile}
                  </div>
                </div>
              }
              position="bottom right"
            />
          ),
          remark: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                paddingLeft: "5px",
              }}
            >
              <label
                style={{
                  ...(!drugOrder?.shipping_order?.remark && {
                    color: "#C4C4C4",
                  }),
                  marginRight: "10px",
                }}
              >
                {drugOrder?.shipping_order?.remark
                  ? drugOrder?.shipping_order?.remark
                  : "ยังไม่ได้ระบุหมายเหตุ"}
              </label>
              <div style={{ flex: 1 }}></div>
              <img
                alt="remark"
                src="/static/images/transport/icon_remark.png"
                width="32px"
              />
            </div>
          ),
          telephone: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                alt="call"
                src="/static/images/transport/phone.png"
                width="20px"
              />
            </div>
          ),
          ...drugOrder,
        };
      }
    );
  }, [
    props.DashboardTelepharSequence?.drugOrderQueue,
    props.DashboardTelepharSequence?.classifyList,
  ]);

  const handleUpdateRemark = () => {
    props.runSequence({
      sequence: "DashboardTelephar",
      action: "updateRemark",
      pk: rowSelete?.shipping_order?.id,
      remarkStr: remarkStr,
      query: queryParams,
    });
  };

  return (
    <div id="dashboard-telephar" style={{ padding: "15px 10px 15px 10px" }}>
      <Form style={{ paddingButtom: "10px" }}>
        <Form.Group inline>
          <Form.Field width={3}>
            <label style={{ whiteSpace: "nowrap" }}>ชื่อ-สกุลคนไข้ / HN</label>
            <Input
              style={{ width: "80%" }}
              placeholder="Search..."
              icon="search"
              value={displaySearch}
              onChange={(e: any) => {
                if (e.target.value.includes("-")) {
                  setHn(e.target.value);
                  setSearchName("");
                  setDisplaySearch(e.target.value);
                } else {
                  setHn("");
                  setSearchName(e.target.value);
                  setDisplaySearch(e.target.value);
                }
              }}
            />
          </Form.Field>

          {/* <Form.Field width={3}>
            <label>HN</label>
            <Input
              placeholder="Search..."
              icon="search"
              value={hn}
              onChange={(e: any) => setHn(e.target.value)}
            />
          </Form.Field> */}
          <Form.Field>
            <label>ประเภทคนไข้</label>
            <Dropdown
              selection
              options={patientTypeOptions}
              onChange={(e: any, v: any) => setPatientType(v.value)}
              value={patientType}
              clearable
            />
          </Form.Field>
          <Form.Field width={6}>
            <label style={{ whiteSpace: "nowrap" }}>วันที่เข้ารับบริการ</label>

            <DateTextBox
              value={startDate}
              onChange={(date: any) => setStartDate(date)}
            ></DateTextBox>

            <label style={{ margin: "0px 10px" }}>ถึง</label>

            <DateTextBox
              value={endDate}
              onChange={(date: any) => setEndDate(date)}
            ></DateTextBox>
          </Form.Field>
          <Form.Field width={3}>
            <label>EN</label>
            <Input
              placeholder="Search..."
              icon="search"
              value={en}
              onChange={(e: any) => setEn(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={14}>
            <label style={{ minWidth: "max-content" }}>ประเภทการจัดส่ง</label>
            <div style={{ margin: "0 10px" }}>
              <Dropdown
                selection
                options={deliveryTypeOptions}
                onChange={(e: any, v: any) => setDeliveryType(v.value)}
                value={deliveryType}
                clearable
              />
            </div>
            {/* </Form.Field> */}
            {/* <Form.Field width={3}> */}
            <label style={{ minWidth: "max-content" }}>การจัดส่ง</label>
            <div style={{ margin: "0 10px" }}>
              <Dropdown
                selection
                options={deliveryStatusOptions}
                onChange={(e: any, v: any) => setDeliveryStatus(v.value)}
                value={deliveryStatus}
                clearable

                // fluid={true}
              />
            </div>
            {/* </Form.Field> */}
            {/* <Form.Field width={3}> */}
            <label style={{ minWidth: "max-content" }}>เลขที่จัดส่ง</label>
            <div style={{ minWidth: "200px", margin: "0 10px" }}>
              <Input
                placeholder="Search..."
                icon="search"
                value={deliveryNo}
                onChange={(e: any) => setDeliveryNo(e.target.value)}
              />
            </div>
            {/* </Form.Field> */}
            {/* <Form.Field width={3}> */}
            <label style={{ minWidth: "max-content" }}>
              สถานะการให้คำปรึกษา
            </label>
            <div style={{ margin: "0 10px" }}>
              <Dropdown
                selection
                options={consultStatusOptions}
                onChange={(e: any, v: any) => setConsultStatus(v.value)}
                value={consultStatus}
                clearable

                // fluid={true}
              />
            </div>
          </Form.Field>
          <Form.Field style={{ justifyContent: "flex-end" }}>
            <div style={{ display: "flex" }}>
              <Button
                content="ค้นหา"
                color="blue"
                onClick={() => {
                  // let patientName = searchName;
                  // let firstName = "";
                  // let lastName = "";
                  // let patientName = "";
                  // if (searchName) {
                  //   let i = searchName.indexOf(" ");
                  //   if (i > 0) {
                  //     firstName = searchName.substring(0, i);
                  //     lastName = searchName.substring(i + 1);
                  //   } else {
                  //     patientName = searchName;
                  //   }
                  // }

                  props.runSequence({
                    sequence: "DashboardTelephar",
                    action: "search",
                    query: queryParams,
                  });
                }}
                type="submit"
                loading={props.DashboardTelepharSequence?.searchLoading}
              />
              <Button
                content="ล้างข้อมูล"
                style={{ minWidth: "max-content" }}
                onClick={() => {
                  let current = formatDateToStringBe(moment());
                  setHn("");
                  setSearchName("");
                  setDisplaySearch("");
                  setPatientType("");
                  setDeliveryStatus("");
                  setConsultStatus("");
                  setStartDate(current);
                  setEndDate(current);
                  // setOrderPerformDiv("")
                  props.runSequence({
                    sequence: "DashboardTelephar",
                    action: "clear",
                  });
                }}
              />
            </div>
          </Form.Field>
        </Form.Group>
        {/* <Form.Group inline>
          <Form.Field width={5}>
            <label>สถานะการให้คำปรึกษา</label>
            <Dropdown
              selection
              options={consultStatusOptions}
              onChange={(e: any, v: any) => setConsultStatus(v.value)}
              value={consultStatus}
              clearable

              // fluid={true}
            />
          </Form.Field>
          <Form.Field inline={true}>
            <label style={{ textAlign: "right", width: "100%" }}>
              Location
            </label>
          </Form.Field>
          <Form.Field inline={true} width={2}>
            <Dropdown
              fluid={true}
              onChange={(e: any, v: any) => setOrderPerformDiv(v.value)}
              options={props.masterOptions?.divisionTypeDrug || []}
              search={true}
              selection={true}
              style={{ width: "100%" }}
              value={orderPerformDiv}
            ></Dropdown>
          </Form.Field>
          <Form.Field width={11} style={{ justifyContent: "flex-end" }}>
            <Button
              content="ค้นหา"
              color="blue"
              onClick={() => {
                // let patientName = searchName;
                // let firstName = "";
                // let lastName = "";
                // let patientName = "";
                // if (searchName) {
                //   let i = searchName.indexOf(" ");
                //   if (i > 0) {
                //     firstName = searchName.substring(0, i);
                //     lastName = searchName.substring(i + 1);
                //   } else {
                //     patientName = searchName;
                //   }
                // }

                props.runSequence({
                  sequence: "DashboardTelephar",
                  action: "search",
                  query: {
                    patientName: searchName,
                    hn,
                    patientType,
                    startDate,
                    endDate,
                    en,
                    deliveryType,
                    deliveryStatus,
                    deliveryNo,
                    consultStatus,
                    // orderPerformDiv,
                  },
                });
              }}
              type="submit"
              loading={props.DashboardTelepharSequence?.searchLoading}
            />
            <Button
              content="ล้างข้อมูล"
              onClick={() => {
                let current = formatDateToStringBe(moment());
                setHn("");
                setSearchName("");
                setPatientType("");
                setDeliveryStatus("");
                setConsultStatus("");
                setStartDate(current);
                setEndDate(current);
                // setOrderPerformDiv("")
                props.runSequence({
                  sequence: "DashboardTelephar",
                  action: "clear",
                });
              }}
            />
          </Form.Field>
        </Form.Group> */}
      </Form>
      <>
        <CardPatientPharmaListUX
          encounterList={pharmacyList}
          showDivision={isDoctor}
          encounterListRowProps={(
            state: any,
            rowInfo: any,
            column: any,
            instance: any
          ) => {
            const original = rowInfo?.original || {};
            let bg = "white";

            const color = getDeliverColor(original);

            if (color) {
              bg = color;
            }

            return {
              style: {
                backgroundColor:
                  original.id && original.id === props.selectedEncounter?.id
                    ? "#cccccc"
                    : original.triage_level?.props?.children === "1" ||
                      original.triage_level?.props?.children === "2"
                    ? "#fc8486"
                    : original.triage_level?.props?.children === "3"
                    ? "#feffa9"
                    : bg,
                ...(rowInfo && { cursor: "pointer" }),
              },
            };
          }}
          encounterListColumnsProps={(
            state: any,
            rowInfo: any,
            column: any,
            instance: any
          ) => {
            // console.log("rowInfo ", rowInfo);
            return {
              onClick: () => {
                if (column.id === "remark") {
                  setRowSelete(rowInfo?.original || null);
                  setRemarkStr(rowInfo?.original?.shipping_order?.remark);
                  setOpenRemark(true);
                } else if (rowInfo?.original?.id) {
                  console.log("onClick props?.layout: ", props?.layout);
                  props.onEvent({
                    message: "SelectDashboardTelephar",
                    params: {
                      drugOrder: rowInfo?.original || null,
                      history,
                      // isQueueScreen: props.layout === 0,
                    },
                  });
                  props?.layout === 0 && props?.forward?.();
                }
                // props.setProp("selectedRecordViewIndex", 0);
              },
            };
          }}
        />
      </>

      <Modal
        open={openRemark}
        style={{ maxWidth: "40%" }}
        onClose={() => setOpenRemark(false)}
        size={"small"}
      >
        <Form
          style={{ padding: "30px", display: "flex", flexDirection: "column" }}
        >
          <label
            style={{ margin: "0 0 20px", fontSize: "24px", fontWeight: "bold" }}
          >
            {"เพิ่ม / แก้ไขหมายเหตุ"}
          </label>
          <TextArea
            placeholder="ยังไม่ได้ระบุหมายเหตุ"
            rows={5}
            value={remarkStr}
            style={{ fontSize: "16px" }}
            onChange={(e, data: any) => {
              setRemarkStr(data.value);
            }}
          />
          <label
            style={{ color: "#C4C4C4", margin: "20px 0", fontSize: "18px" }}
          >
            {"โปรดตรวจสอบก่อนยืนยันการแก้ไข หรือ เพิ่มข้อมูล"}
          </label>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <ButtonLoadCheck
              style={{
                ...styles.button,
                color: "#0147A3",
                border: "1px solid #0147A3",
                background: "white",
              }}
              title={"ยกเลิก"}
              onClick={() => {
                setOpenRemark(false);
              }}
              setProp={props.setProp}
            />
            <ButtonLoadCheck
              style={{
                ...styles.button,
                background: "#0147A3",
                color: "white",
              }}
              title={"ยืนยัน"}
              buttonLoadCheck={props.DashboardTelepharSequence?.buttonLoadCheck}
              onClick={() => {
                handleUpdateRemark();
              }}
              setProp={props.setProp}
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default CardDashboardTelephar;
