import React, { useMemo, useState, useEffect } from "react";
import CardPediatricDoseUX from "./CardPediatricDoseUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { Container, Segment, Header, Icon } from "semantic-ui-react";

// types
type ModPediatricDoseProps = {
  open: boolean;
  // data
  drugDetail?: Record<string, any>;
  pediatricData?: Record<string, any>;
  // callback
  onClose: () => void;
  onEvent: any;
  setProp: any;
};

const ModPediatricDose = (props: ModPediatricDoseProps) => {
  const [ageValue, setAgeValue] = useState<string>("PEDIATRIC");
  const [inputWeight, setInputWeight] = useState<number>(0);

  useEffect(() => {
    if (props.drugDetail) {
      console.log("auto click calculate");
      handleCalculation({ action: "LIST" });
    }

    return () => {
      console.log("todo clear value");
      setInputWeight(0);
    };
  }, [props.drugDetail]);

  useEffect(() => {
    if (props.drugDetail) {
      handleCalculation({ action: "LIST" });
    }
  }, [ageValue]);

  const handleCalculation = (params: any = {}) => {
    let payload: any = {
      ...params,
      drugDetail: props.drugDetail,
      inputWeight: inputWeight,
      age: ageValue,
      card: "ModPediatricDose",
    };

    props.onEvent({
      message: "GetPediatricDose",
      params: payload,
    });
  };

  const ageOptions = useMemo(() => {
    return [
      { key: "PEDIATRIC", value: "PEDIATRIC", text: "เด็ก" },
      { key: "ADULT", value: "ADULT", text: "ผู้ใหญ่" },
    ];
  }, []);

  console.log("pediatricData === ", props.pediatricData);

  return (
    <Segment
      raised
      open={props.open}
      title="คำนวณขนาดยา"
      buttonStyle={{ display: "none" }}
      style={{ width: "100%", zIndex: 999 }}
      closeOnDimmerClick={true}
      onClose={props.onClose}
    >
      <Container>
        <div style={{ display: "flex" }}>
          <Header as="h3" style={{ width:"98%", color: "#0D71BB", margin: "0px" }}>
            คำนวณขนาดยา
          </Header>
          <Icon color="red" name="close" onClick={props.onClose} />
        </div>
        <hr></hr>
        <CardPediatricDoseUX
          pediatricData={props.pediatricData}
          ageOptions={ageOptions}
          ageValue={ageValue}
          handleChangeAge={(_event: any, data: any) => setAgeValue(data.value)}
          handleChangeSelectedItem={(_event: any, data: any) => {
            // const item = props.pediatricData?.items
            //   ?.filter((item: any) => item?.id === data.value)
            //   ?.slice(-1)?.[0];
            // props.setProp("pediatricData", {
            //   ...props.pediatricData,
            //   selectedItem: item,
            //   regimenSelectedId: data.value,
            // });
            handleCalculation({
              action: "CAL",
              regimen: data.value,
            });
          }}
          calculationButton={
            <ButtonLoadCheck
              // function
              //   id="btn-confirmMedOrder"
              setProp={props.setProp}
              onClick={() =>
                handleCalculation({
                  action: "CAL",
                  regimen: props.pediatricData?.regimenSelectedId,
                })
              }
              //   paramKey={`${CARD_DRUG_ORDER}_SAVE`}
              //   buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DRUG_ORDER}_SAVE`]}
              //   disabled={
              //     statusRequested
              //       ? orderName && !dataChanged
              //         ? true
              //         : false
              //       : true
              //   }
              color="blue"
              //   name="SAVE"
              //   // size="medium"
              title="คำนวณ"
              //   style={{ width: "100%" }}
            />
          }
          inputWeight={inputWeight}
          handleChangeWeight={(_event: any, data: any) => {
            setInputWeight(data.value);
            props.setProp("pediatricData", {
              ...props.pediatricData,
              inputWeight: data.value,
            });
          }}
        />
      </Container>
    </Segment>
  );
};

export default React.memo(ModPediatricDose);
