import React, { useState, useEffect, useMemo, CSSProperties } from "react";
import { Icon, List, ListItem } from "semantic-ui-react";

// Common
import { DateTextBox, ModConfirm, ModInfo } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import CardSettingQueueUX from "react-lib/apps/HISV3/HCU/CardSettingQueueUX";

// Interface
import { RunSequence } from "./sequence/SettingPackage";
import { State } from "./sequence/SettingQueue";

// Types
type CardSettingQueueProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  SettingQueueSequence?: State["SettingQueueSequence"];
  masterOptions?: Record<string, any>;
};

export const CARD_SETTING_QUEUE = "CardSettingQueue";

const CardSettingQueue = (props: CardSettingQueueProps) => {
  const [openSettingQueue, setOpenSettingQueue] = useState<boolean>(false);
  const [openMessageTemplate, setOpenMessageTemplate] =
    useState<boolean>(false);
  const [templateNoDivision, setTemplateNoDivision] = useState<boolean>(false);
  const notificationData = props.SettingQueueSequence?.notificationQueueOrder;
  const messageTemplateData = props.SettingQueueSequence?.messageTemplateOrder;

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "SettingQueue",
      restart: true,
    });
  }, []);

  useEffect(() => {
    if (props.SettingQueueSequence?.notificationQueueOrder?.closeModal) {
      setOpenSettingQueue(false);
      props.setProp(
        `SettingQueueSequence.notificationQueueOrder.closeModal`,
        false
      );
    }
  }, [props.SettingQueueSequence?.notificationQueueOrder?.closeModal]);

  const styles = {
    cardError: {
      padding: "5px 15px",
      background: "#FADEDE",
      border: "1px solid #F3AFAE",
      borderRadius: "5px",
      boxShadow: " 0px 1px 2px",
      margin: "10px",
    } as CSSProperties,
  };

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(
      `SettingQueueSequence.notificationQueueOrder.${key}`,
      v.value
    );
  };

  const handleChangeValuePatientCount = (_event: any, data: any) => {
    props.setProp(
      `SettingQueueSequence.notificationQueueOrder.patientCount`,
      data.value
    );
  };

  const handleChangeMessageTemplate = (key: string) => (e: any, v: any) =>
    props.setProp(`SettingQueueSequence.messageTemplateOrder.${key}`, v.value);

  const handleChangeSelectAllDivision = (_event: any, data: any) => {
    props.setProp(`SettingQueueSequence.notificationQueueOrder`, {
      ...notificationData,
      selectAllDivision: data.checked,
      selectDivision: [],
    });
  };

  const notificationQueue = useMemo(() => {
    return notificationData?.notificationOrder?.map((item: any) => ({
      ...item,
      division: (
        <div>
          {item?.divisions.map((division: any, number: any) => {
            if (item?.divisions.length === 1) {
              return props.masterOptions?.division?.find(
                (options: any) => options.value === division
              )?.text;
            } else {
              return `${
                props.masterOptions?.division?.find(
                  (options: any) => options.value === division
                )?.text
              } ${number + 1 !== item.divisions.length ? "," : ""} `;
            }
          })}
        </div>
      ),
      count: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {item?.slot_capacity}
        </div>
      ),
      typeText: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {item?.message_template_text}
        </div>
      ),
      edit: (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            setOpenSettingQueue(true);
            props.setProp(`SettingQueueSequence.notificationQueueOrder`, {
              ...notificationData,
              selectDivision: item?.divisions,
              patientCount: item?.slot_capacity,
              notificationDetail: item?.message_template_text,
              selectNotificationID: item?.id,
            });
          }}
        >
          <Icon name="edit outline" color="yellow" size="large" />
        </div>
      ),
      delete: (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            // setOpenSettingQueue(false);
            props.runSequence({
              sequence: "SettingQueue",
              action: "deleteNotificationMessage",
              selectNotificationID: item?.id,
            });
          }}
        >
          <Icon name="minus circle" color="red" size="large" />
        </div>
      ),
    }));
  }, [notificationData?.notificationOrder, props.masterOptions?.division]);

  const messageTemplateQueue = useMemo(() => {
    return (messageTemplateData?.messageTemplateOrder || []).map(
      (item: any) => ({
        ...item,
        division: (
          <div>
            {item?.divisions.map((division: any, number: any) => {
              if (item.divisions.length === 1) {
                return props.masterOptions?.division?.find(
                  (options: any) => options.value === division
                )?.text;
              } else {
                return `${
                  props.masterOptions?.division?.find(
                    (options: any) => options.value === division
                  )?.text
                } ${number + 1 !== item.divisions.length ? "," : ""} `;
              }
            })}
          </div>
        ),
        edit: (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            onClick={() => {
              setOpenMessageTemplate(true);
              props.setProp(`SettingQueueSequence.messageTemplateOrder`, {
                ...messageTemplateData,
                selectDivision: item?.divisions,
                detail: item?.detail,
                header: item?.header,
                selectMessageTemplateID: item?.id,
              });
            }}
          >
            <Icon name="edit outline" color="yellow" size="large" />
          </div>
        ),
        delete: (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            onClick={() => {
              setOpenMessageTemplate(false);
              props.runSequence({
                sequence: "SettingQueue",
                action: "deleteMessageTemplate",
                selectMessageTemplateID: item?.id,
              });
            }}
          >
            <Icon name="minus circle" color="red" size="large" />
          </div>
        ),
      })
    );
  }, [
    messageTemplateData?.messageTemplateOrder,
    props.masterOptions?.division,
  ]);

  const handleSearchSettingQueue = () => {
    props.runSequence({
      sequence: "SettingQueue",
      action: "searchNotificationMessage",
      divisions: notificationData?.searchDivision,
    });
  };

  const handleSaveSettingQueue = () => {
    props.setProp(
      `SettingQueueSequence.notificationQueueOrder.errorMessageQueue`,
      null
    );
    props.runSequence({
      sequence: "SettingQueue",
      action: "saveNotificationMessage",
      is_all_division: notificationData?.selectAllDivision,
      divisions: notificationData?.selectDivision,
      slot_capacity: notificationData?.patientCount,
      message_template_text: notificationData?.notificationDetail,
    });

    // setOpenSettingQueue(false);
  };

  const handleCloseSettingQueue = () => {
    setOpenSettingQueue(false);
    props.setProp(`SettingQueueSequence.notificationQueueOrder`, {
      ...notificationData,
      selectAllDivision: false,
      selectDivision: [],
      patientCount: 0,
      notificationDetail: "",
      selectNotificationID: null,
    });
  };

  const handleSearchMessageTemplate = () => {
    props.runSequence({
      sequence: "SettingQueue",
      action: "searchMessageTemplate",
      divisions: messageTemplateData?.searchDivision,
    });
  };

  const handleShowDivisionError = () => {
    const errorList: any =
      props.SettingQueueSequence?.notificationQueueOrder?.errorMessageQueue;

    const listName = (errorList || []).map(
      (item: any, index: any) => item.division_name
    );
    return listName;
  };

  const handleSaveMessageTemplate = () => {
    console.log(
      "saika ~ messageTemplateData?.selectDivision:",
      messageTemplateData?.selectDivision
    );
    if (!messageTemplateData?.selectDivision || (messageTemplateData?.selectDivision || []).length === 0) {
      setTemplateNoDivision(true);
      return;
    } else {
      setTemplateNoDivision(false);
    }

    props.runSequence({
      sequence: "SettingQueue",
      action: "saveMessageTemplate",
      divisions: messageTemplateData?.selectDivision,
      header: messageTemplateData?.header,
      detail: messageTemplateData?.detail,
    });

    setOpenMessageTemplate(false);
  };

  const handleShowError = (isError: boolean, isTemplate: boolean) => {
    return isTemplate ? (
      <div style={{ display: "flex", marginBottom: "2rem" }}>
        {isError && (
          <div style={{...styles.cardError, width: "50%"}}>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "red",
                  marginRight: "10px",
                }}
              >
                {"บันทึกไม่สำเสร็จ"}
              </div>
              <div style={{ fontSize: "12px" }}>{"จำเป็นต้องระบุแผนก"}</div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div style={{ display: "flex", marginBottom: "2rem" }}>
        {isError && (
          <div style={styles.cardError}>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "red",
                  marginRight: "10px",
                }}
              >
                {"บันทึกไม่สำเสร็จ"}
              </div>
              <div style={{ fontSize: "12px" }}>
                {"มีรายการแผนกที่บันทึกการตั้งค่าแจ้งเตือนไว้ในระบบแล้ว ได้แก่"}
              </div>
            </div>
            <div>
              <List bulleted>
                <ListItem style={{ fontSize: "12px" }}>
                  {handleShowDivisionError().join(" , ")}
                </ListItem>
              </List>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ height: "90vh", overflow: "auto" }}>
      <CardSettingQueueUX
        masterOptions={props.masterOptions}
        //notificationQueueOrder
        notificationQueueOrder={notificationData}
        notificationQueue={notificationQueue}
        openSettingQueue={openSettingQueue}
        handleChangeValue={handleChangeValue}
        handleChangeSelectAllDivision={handleChangeSelectAllDivision}
        handleChangeValuePatientCount={handleChangeValuePatientCount}
        onOpenSettingQueue={() => {
          setOpenSettingQueue(true);
        }}
        onCloseSettingQueue={handleCloseSettingQueue}
        onSaveSettingQueue={handleSaveSettingQueue}
        onSearchNotificationMessage={handleSearchSettingQueue}
        //messageTemplateOrder
        messageTemplateOrder={messageTemplateData}
        messageTemplateQueue={messageTemplateQueue}
        openMessageTemplate={openMessageTemplate}
        handleChangeMessageTemplate={handleChangeMessageTemplate}
        onSaveMessageTemplate={handleSaveMessageTemplate}
        onOpenMessageTemplate={() => {
          setOpenMessageTemplate(true);
        }}
        onCloseMessageTemplate={() => {
          setOpenMessageTemplate(false);
        }}
        onSearchMessageTemplate={handleSearchMessageTemplate}
        errorRemind={handleShowError(
          props.SettingQueueSequence?.notificationQueueOrder?.errorMessageQueue,
          false
        )}
        errorTemplate={handleShowError(templateNoDivision, true)}
      />
    </div>
  );
};

export default React.memo(CardSettingQueue);
