import React, { Fragment, useMemo } from "react";
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  List,
  Loader,
  Modal,
  Segment,
} from "semantic-ui-react";
import SubNarcoticUsage from "./SubNarcoticUsage";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

// Types
type ModConfirmNurse2Props = {
  open: boolean;
  isLoading: boolean;
  hardDrugOrder: Partial<Record<string, string[]>>;
  narcoticOrder?: Record<string, any>;
  errMessageNurse2: any;
  showInputNurse: Record<string, any>;
  onClose: () => void;
  onChangeInput: (key: string) => (data: any) => void;
  onChangeInputNurse: (key: any, data: any) => void;
  onSaveConfirm: () => void;
};

const ModConfirmNurse2 = (props: ModConfirmNurse2Props) => {
  const disabledSave = useMemo(() => {
    if (Object.keys(props.narcoticOrder || {}).length === 0) {
      return false;
    }

    return Object.entries(props.narcoticOrder || {}).some(([, item]) => {
      const isItemInvalid = !item.apply || !item.reject;
      const isItemQuantityZero = Number(item.apply) === 0 && Number(item.reject) === 0;

      return isItemInvalid || isItemQuantityZero;
    });
  }, [props.narcoticOrder]);

  return (
    <Modal open={props.open} size="tiny" style={{ margin: "auto" }} onClose={props.onClose}>
      <Segment>
        <Dimmer active={props.isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Form>
          <Form.Group style={{ marginBottom: 0 }}>
            <Form.Field inline width={16}>
              <Grid>
                <Grid.Row>
                  <Grid.Column textAlign="right" width={16}>
                    <Icon
                      name="close"
                      color="red"
                      style={{ cursor: "pointer" }}
                      onClick={props.onClose}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={16}>
              <Header size="medium" color="orange" textAlign="center">
                กรุณายืนยันการตรวจสอบโดยพยาบาล 2
              </Header>
              <Header
                size="tiny"
                color="grey"
                textAlign="center"
                style={{
                  marginTop: "0",
                  fontWeight: "normal",
                }}
              >
                {`พบรายการยา
                  ${Object.keys(props.hardDrugOrder)
                    .map((order) => order.replace(/_/g, " "))
                    .join(", ")}
                  จำเป็นต้องตรวจสอบโดยพยาบาล 2`}
              </Header>
            </Form.Field>
          </Form.Group>

          <Form.Group
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Form.Field width={Object.keys(props.narcoticOrder).length ? 14 : 11}>
              <p />
              {Object.entries(props.hardDrugOrder).map(([drugName, items]) => (
                <Fragment key={drugName}>
                  <label>{drugName.replace(/_/g, " ")}</label>
                  <List bulleted>
                    {items?.map((item, index) => (
                      <List.Item key={`list-item${item}`}>{item}</List.Item>
                    ))}
                  </List>
                </Fragment>
              ))}
              <p />
            </Form.Field>
          </Form.Group>
          {Object.entries(props.narcoticOrder).map(([key, item], index) => (
            <SubNarcoticUsage
              key={key}
              size="mini"
              drugName={item.info.name}
              unitName={item.info.code_unit.toLowerCase()}
              patientName={item.patient_name}
              // config
              hideHeader={index !== 0}
              style={{
                marginTop: index === 0 ? "-1rem" : "",
                marginBottom: 0,
              }}
              contentStyle={{ padding: "0 5px" }}
              onChange={props.onChangeInput(key)}
            />
          ))}
          {!!Object.keys(props.narcoticOrder).length && <Form.Group />}
          <Form.Group>
            <Form.Field width={16}>
              <ErrorMessage
                error={props.errMessageNurse2}
                style={{
                  width: "100%",
                  marginTop: Object.keys(props.narcoticOrder).length ? "1rem" : "",
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group
            inline
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <Form.Field width={4}>
                  <label>รหัสพยาบาล 2</label>
                </Form.Field>
                <Form.Field width={8}>
                  <SubUserToken
                    token={showInputNurse.nurse2.token}
                    controller={props.controller}
                    error={showInputNurse.nurse2.error}
                    onChangeToken={(token, error) => {
                      handleChangeToken("nurse2", token, error);
                    }}
                  />
                </Form.Field> */}
            <Form.Field width={8}>
              <label htmlFor="nurse2-username">Username</label>
              <Input
                id="nurse2-username"
                className="error-hard"
                error={!!props.showInputNurse.nurse2.error}
                value={props.showInputNurse?.nurse2?.username || ""}
                onChange={(e, data) =>
                  props.onChangeInputNurse("nurse2", { name: "username", value: data.value })
                }
              />
            </Form.Field>
            <Form.Field width={8} style={{ padding: 0 }}>
              <label htmlFor="nurse2-password">Password</label>
              <Input
                id="nurse2-password"
                className="error-hard"
                error={!!props.showInputNurse.nurse2.error}
                type="password"
                value={props.showInputNurse?.nurse2?.password || ""}
                onChange={(e, data) =>
                  props.onChangeInputNurse("nurse2", { name: "password", value: data.value })
                }
              />
            </Form.Field>
          </Form.Group>

          <Form.Group
            inline
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Form.Field>
              <Button color="green" disabled={disabledSave} onClick={props.onSaveConfirm}>
                SAVE
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    </Modal>
  );
};

ModConfirmNurse2.displayName = "ModConfirmNurse2";

export default React.memo(ModConfirmNurse2);
