import React, { useMemo, useState } from "react";
import { Dimmer, Icon, Loader } from "semantic-ui-react";

import moment from "moment";

// UX
import CardStockManagementTabHistoryUX from "./CardStockManagementTabHistoryUX";

// Common
import { DateTextBox } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  AggStockSerializer,
  BUTTON_ACTIONS,
  HistoryFilterType,
  PRODUCT_TYPES,
  PermissionsType,
  PrintFormActionType,
  ProductLotSerializer,
  RunSequence,
  StockLogSerializer,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";

// Utils
import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";

// Types
type CardStockManagementTabHistoryProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // data
  data: Partial<AggStockSerializer>;
  stockLogList?: StockLogSerializer[];
  filterHistory?: Partial<HistoryFilterType>;
  permissions?: PermissionsType;
  productLotList?: ProductLotSerializer[];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // options
  movementTypeOptions?: any[];
};

const CARD_STOCK_MANAGEMENT_TAB_HISTORY = "CardStockManagementTabHistory";

const CardStockManagementTabHistory = (
  props: CardStockManagementTabHistoryProps
) => {
  // Use Memo
  const stockHistoryItems = useMemo(() => {
    const filterList = (props.stockLogList || []).filter(
      (item) => item.type !== "DISPENSE_CANCEL"
    );

    return filterList.map((item) => {
      let operation: "eq" | "lt" | "gt" = item.quantity < 0 ? "lt" : "gt";

      if (item.quantity === 0) {
        operation = "eq";
      }

      const quantityMap = {
        eq: { text: "-", sign: "" },
        lt: { text: "ออก", sign: "-" },
        gt: { text: "เข้า", sign: "+" },
      };

      const quantity = quantityMap[operation];

      return {
        ...item,
        datetime: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {formatDatetime(item.datetime)}
          </div>
        ),
        in_out_bound: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {quantity.text}
          </div>
        ),
        ref_no: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label>{item.ref_code}</label>
            {!!item.ref_code && (
              <DisplayFile runSequence={props.runSequence} data={item} />
            )}
            {item.type === "ADD_PERFORM" && (
              <DisplayFile runSequence={props.runSequence} data={item} />
            )}
          </div>
        ),
        quantity: (
          <div
            style={{
              color: item.quantity < 0 ? "red" : "",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {quantity.sign} {Math.abs(item.quantity)}
          </div>
        ),
        balance: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {item.balance}
          </div>
        ),
        lot_no: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {item.lot?.mfc_no || ""}
          </div>
        ),
        reference_text: (
          <div>
            {
              props.movementTypeOptions?.find(
                (option) => option.key === item.type
              )?.text
            }
            {item.reference_text ? `: ${item.reference_text}` : ""}
          </div>
        ),
      };
    });
  }, [props.stockLogList, props.movementTypeOptions]);

  const movementTypeOptions = useMemo(() => {
    return [
      { key: 1, value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.movementTypeOptions || []),
    ];
  }, [props.movementTypeOptions]);

  // Memo Effect
  const productLotOptions = useMemo((): any[] => {
    const items = (props.productLotList || []).filter(
      (item) => item.mfc_no !== "N/A"
    );

    return items.map((item) => ({
      key: item.id,
      value: item.mfc_no,
      text: item.mfc_no,
    }));
  }, [props.productLotList]);

  const handleSearch = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "SEARCH_HISTORY",
      card: CARD_STOCK_MANAGEMENT_TAB_HISTORY,
      errorKey: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.SEARCH,
    });
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleChangeValue = (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;
    const filter = { ...(props.filterHistory || {}) };

    props.setProp("StockManagementSequence.filterHistory", {
      ...filter,
      [data.name]: value,
    });
  };

  const handleClear = () => {
    props.setProp("StockManagementSequence.filterHistory", {
      startDate: formatDate(moment()),
      endDate: formatDate(moment()),
      movementType: "ALL",
    });
  };

  const handlePrint = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "PRINT_REPORT",
      card: CARD_STOCK_MANAGEMENT_TAB_HISTORY,
      errorKey: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.PRINT,
    });
  };

  return (
    <div>
      <CardStockManagementTabHistoryUX
        // data
        storage={props.data.storage?.name}
        isMoveIn={props.filterHistory?.isMoveIn}
        isMoveOut={props.filterHistory?.isMoveOut}
        movementType={props.filterHistory?.movementType}
        // table
        stockHistoryList={stockHistoryItems}
        // options
        movementTypeOptions={movementTypeOptions}
        // config
        disabledClear={!props.data.product?.name}
        readOnly={!props.data.product?.name}
        // callback
        onChangeValue={handleChangeValue}
        onClear={handleClear}
        // Element
        StartDate={
          <DateTextBox
            inputRef={(instance: any) => {
              instance &&
                (instance.ref.inputRef.current.style.paddingLeft = "0.5rem");
            }}
            value={props.filterHistory?.startDate}
            disabled={!props.data.product?.name}
            style={{ width: "122px" }}
            inputStyle={{ width: "122px" }}
            // callback
            onChange={handleChangeDate("startDate")}
          />
        }
        EndDate={
          <DateTextBox
            inputRef={(instance: any) => {
              instance &&
                (instance.ref.inputRef.current.style.paddingLeft = "0.5rem");
            }}
            value={props.filterHistory?.endDate}
            disabled={!props.data.product?.name}
            style={{ width: "122px" }}
            inputStyle={{ width: "122px" }}
            onChange={handleChangeDate("endDate")}
          />
        }
        LotDropdown={
          <DropdownOptions
            value={props.filterHistory?.lotNo || []}
            options={productLotOptions}
            name={"lotNo"}
            fluid={true}
            multiple={true}
            search={true}
            disabled={
              !props.data.product?.name || !props.permissions?.TAB_HISTORY_VIEW
            }
            style={{
              width: "100%",
              maxWidth: "calc(100vw - 101rem)",
              minWidth: "125px",
            }}
            onChange={handleChangeValue}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_STOCK_MANAGEMENT_TAB_HISTORY}_${BUTTON_ACTIONS.SEARCH}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_STOCK_MANAGEMENT_TAB_HISTORY}_${BUTTON_ACTIONS.SEARCH}`
              ]
            }
            // config
            disabled={
              !props.data.product?.name || !props.permissions?.TAB_HISTORY_VIEW
            }
            color={"blue"}
            name={BUTTON_ACTIONS.SEARCH}
            size="small"
            title="ค้นหา"
            style={{ paddingRight: "1em", paddingLeft: "1em" }}
          />
        }
        ButtonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrint}
            // data
            paramKey={`${CARD_STOCK_MANAGEMENT_TAB_HISTORY}_${BUTTON_ACTIONS.PRINT}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_STOCK_MANAGEMENT_TAB_HISTORY}_${BUTTON_ACTIONS.PRINT}`
              ]
            }
            // config
            disabled={
              !props.data.product?.name || !props.permissions?.TAB_HISTORY_VIEW
            }
            color={"green"}
            name={BUTTON_ACTIONS.PRINT}
            size="mini"
            title={
              props.data.product?.p_type_name === PRODUCT_TYPES.DRUG
                ? "พิมพ์รายงานจ่ายยาผู้ป่วย"
                : "พิมพ์รายงานจ่ายเวชภัณฑ์ผู้ป่วย"
            }
            style={{
              width: "100px",
              lineHeight: 1.2,
              padding: "0.785rem 0.75rem",
            }}
          />
        }
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                       DisplayFile                      */

/* ------------------------------------------------------ */
// Types
type DisplayFileProps = {
  // seq
  runSequence: RunSequence;
  // data
  data: StockLogSerializer;
};
const DisplayFile = (props: DisplayFileProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOpenFile = (e: any) => {
    let actionType: PrintFormActionType = "REPRINT_DRUG_SUPPLY";

    if (props.data.type === "ADD_PERFORM") {
      actionType = "PRINT_ADD_STOCK_FORM";
    } else if (props.data.type === "DISPENSE_PERFORM") {
      actionType = "PRINT_ISSUE_STOCK_FORM";
    } else if (props.data.type.includes("TRANSFER")) {
      actionType =
        props.data.quantity < 0
          ? "PRINT_REQUEST_STOCK_FORM"
          : "PRINT_TRANSFER_STOCK_FORM";
    }

    props.runSequence({
      sequence: "StockManagement",
      action: actionType,
      data: props.data,
      code: props.data.ref_code,
      errorKey: CARD_STOCK_MANAGEMENT,
      onLoading: setIsLoading,
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Icon
        name="file pdf outline"
        color="blue"
        style={{ cursor: "pointer" }}
        onClick={handleOpenFile}
      />
      <Dimmer
        active={isLoading}
        size="mini"
        inverted
        style={{
          background: "transparent",
          margin: "-1px 0 0 -2px",
          padding: 0,
        }}
      >
        <Loader size="mini" inline={true}></Loader>
      </Dimmer>
    </div>
  );
};

export default React.memo(CardStockManagementTabHistory);
