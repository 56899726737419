import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardNursingDiagnosisTemplateUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%"}}>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(10, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
          <div
            style={{ gridRow: "1/2", gridColumn: "2/17", display: "flex",  alignItems: "center", fontWeight: "bold",fontSize:"large"}}>
             Nursing Diagnosis (New)
          </div>
          <div
            style={{ gridRow: "1/1", gridColumn: "38/41", display: "flex",  alignItems: "center", justifyContent: "center"}}>
            Template
          </div>
          <div
            style={{ gridRow: "1/2", gridColumn: "41/50",display: "flex",  alignItems: "center"}}>
            <Dropdown
              onChange={props.changeNursingDiagnosis("templateID")}
              options={props.templateOptions}
              selection={true}
              style={{width: "100%", height: "50%"}}
              value={props.templateID}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "1/2", gridColumn: "34/38", display: "flex",  alignItems: "center"}}>
            <Button
              color="green"
              onClick={props.onSave}
              style={{width: "100%", height: "45%"}}>
              SAVE
            </Button>
          </div>
          <div
            style={{ gridRow: "2/10", gridColumn: "2/50", paddingTop:"10px" }}>
            <Table
              data={props.nursingDiagnosisTemplate}
              getTrProps={props.rowProps}
              headers="ข้อวินิจฉัยทางการพยาบาล,แผนการพยาบาล,ผลลัพธ์ที่คาดหวัง,วันที่สิ้นสุดปัญหา"
              keys="diagnosis,plan,goal,end_date"
              minRows={2}
              showPagination={false}
              style={{height: "65vh", marginBottom: "10px"}}>
            </Table>
          </div>
        </div>
      </div>
    )
}

export default CardNursingDiagnosisTemplateUX

export const screenPropsDefault = {"roomDetail":"7C06 Ward  อายุรกรรม ห้อง Standard","searchLabel":"ค้นหา"}

/* Date Time : Thu Jun 30 2022 06:53:07 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 49,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(10, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": " Nursing Diagnosis (New)"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/17\", display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\",fontSize:\"large\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "Template"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/1\", gridColumn: \"38/41\", display: \"flex\",  alignItems: \"center\", justifyContent: \"center\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 49,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"41/50\",display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 49,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"34/38\", display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Dropdown",
      "parent": 56,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeNursingDiagnosis(\"templateID\")"
        },
        "options": {
          "type": "code",
          "value": "props.templateOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"50%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.templateID"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"45%\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 49,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/10\", gridColumn: \"2/50\", paddingTop:\"10px\" }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 63,
      "name": "Table",
      "parent": 62,
      "props": {
        "data": {
          "type": "code",
          "value": "props.nursingDiagnosisTemplate"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "ข้อวินิจฉัยทางการพยาบาล,แผนการพยาบาล,ผลลัพธ์ที่คาดหวัง,วันที่สิ้นสุดปัญหา"
        },
        "keys": {
          "type": "value",
          "value": "diagnosis,plan,goal,end_date"
        },
        "minRows": {
          "type": "code",
          "value": "2"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"65vh\", marginBottom: \"10px\"}"
        }
      },
      "seq": 63,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "CardNursingDiagnosisTemplateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "roomDetail": "7C06 Ward  อายุรกรรม ห้อง Standard",
    "searchLabel": "ค้นหา"
  },
  "width": 75
}

*********************************************************************************** */
