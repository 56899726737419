import React, { useEffect, useMemo } from "react";
// UI Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBox from "react-lib/appcon/common/SearchBox";
// UI ORM
import CardORCancelListUX from "./CardORCancelListUX";
// UI TPD
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";

const CARD_KEY: string = "CardORCancelList";

type CardORCancelListProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  ORCancelListSequence?: any;

  // controller
  drugOrderQueueController?: any;

  // SearchBox
  searchedItemList?: any;
};

const CardORCancelList = (props: CardORCancelListProps) => {
  useEffect(() => {
    props.runSequence({ sequence: "ORCancelList", restart: true });
  }, []);

  const locationOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.masterOptions?.orLocation || []),
    ];
  }, [props.masterOptions?.orLocation]);

  const roomOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.ORCancelListSequence?.roomOptions || []),
    ];
  }, [props.ORCancelListSequence?.roomOptions]);

  // ----- filter
  const handleChangePatient = (
    id: any,
    hn: string,
    full_name: string,
    birthdate: string
  ) => {
    props.runSequence({
      sequence: "ORCancelList",
      action: "SET_FILTER",
      data: { emr__encounter__patient__hn: hn, patientFullName: full_name },
    });
  };

  const handleSelectedDoctor = (item: any) => {
    props.runSequence({
      sequence: "ORCancelList",
      action: "SET_FILTER",
      data: { selectedDoctor: item, chief_surgeon: item?.id || null },
    });
  };

  const handleChangeLocation = (_event: any, data: any) => {
    props.runSequence({
      sequence: "ORCancelList",
      action: "FILTER_LOCATION_AND_FETCH_ROOM_ITEM",
      location: data.value,
    });
  };

  const handleChangeFilter = (_event: any, data: any) => {
    let value: any = data.value;
    if (data.type === "checkbox") {
      value = data.checked;
    }
    props.runSequence({
      sequence: "ORCancelList",
      action: "SET_FILTER",
      data: { [data.name]: value },
    });
  };

  // button
  const handleSearch = () => {
    props.runSequence({
      sequence: "ORCancelList",
      action: "FETCH",
      cardKey: CARD_KEY,
    });
  };

  const handleClear = () => {
    props.runSequence({ sequence: "ORCancelList", action: "CLEAR_FILTER" });
  };

  const handlePrint = () => {
    props.runSequence({
      sequence: "ORCancelList",
      action: "PRINT",
      cardKey: CARD_KEY,
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
      />

      <CardORCancelListUX
        // function
        onChangeLocation={handleChangeLocation}
        onChangeFilter={handleChangeFilter}
        onClear={handleClear}
        // options
        locationOptions={locationOptions}
        roomOptions={roomOptions}
        // seq
        ORCancelListSequence={props.ORCancelListSequence}
        // component
        patientSearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            defaultValue={
              props.ORCancelListSequence?.orFilter
                ?.emr__encounter__patient__hn || ""
            }
            disabled={!props.ORCancelListSequence?.orFilter?.checkedHn}
            onEnterPatientSearch={handleChangePatient}
          />
        }
        doctorSearchBox={
          <SearchBox
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={
              props.ORCancelListSequence?.orFilter?.selectedDoctor || null
            }
            setSelectedItem={handleSelectedDoctor}
            type="Doctor"
            toDisplay={(item: any) => item.name_code}
            disabled={!props.ORCancelListSequence?.orFilter?.checkedDoctor}
            fluid={true}
            label=""
            style={{ width: "100%" }}
          />
        }
        startDateTextBox={
          <DateTextBox
            value={props.ORCancelListSequence?.orFilter?.start_date}
            // fluid={true}
            // inputStyle={{ width: "155px" }}
            onChange={(date: string) => {
              handleChangeFilter(null, { name: "start_date", value: date });
            }}
          />
        }
        endDateTextBox={
          <DateTextBox
            value={props.ORCancelListSequence?.orFilter?.end_date}
            // fluid={true}
            // inputStyle={{ width: "155px" }}
            onChange={(date: string) => {
              handleChangeFilter(null, { name: "end_date", value: date });
            }}
          />
        }
        createdDateTextBox={
          <DateTextBox
            value={props.ORCancelListSequence?.orFilter?.created}
            onChange={(date: string) => {
              handleChangeFilter(null, { name: "created", value: date });
            }}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_KEY}_FETCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_FETCH`]}
            // config
            color="blue"
            size="small"
            title="ค้นหา"
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrint}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="green"
            disabled={
              !(props.ORCancelListSequence?.cancelOrder?.items?.length > 0)
            }
            size="small"
            title="พิมพ์รายงาน"
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
      />
    </>
  );
};

export default React.memo(CardORCancelList);
