import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Checkbox
} from 'semantic-ui-react'

const CardHomeMedDetailUX = (props: any) => {
    return(
      <div
        style={{padding: "5px 0"}}>
        <Form>
          <FormGroup
            inline={true}
            style={{alignItems: "flex-start"}}>
            <FormField
              inline={true}
              style={{minWidth: "max-content", paddingRight: "3.5rem", alignItems: "baseline"}}
              width={8}>
              <label>
                {`${props.drugDetail?.title || "-"} : `}
              </label>
              <div>
                {props.drugDetail?.code || "-"}
              </div>
            </FormField>
            <FormField
              className="--readOnly --grey"
              style={{display: "grid"}}
              width={8}>
              <Checkbox
                checked={props.telepharType ==="INTRA"}
                className="blue"
                disabled={props.disabledCheck || false}
                label="Intrahospital Telepharmacy"
                name="INTRA"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content", opacity: 1, textAlign: "left",...(props.showCheck?{}:{opacity:0, pointerEvents: "none"})}}>
              </Checkbox>
              <Checkbox
                checked={props.telepharType==="DELIVERY"}
                className="blue"
                disabled={props.disabledCheck || props.hasDrugTransportNotAllowed || false}
                label="Home delivery"
                name="DELIVERY"
                onChange={props.onChangeValue}
                style={{textAlign: "left", marginTop: "0.75rem",...(props.showCheck?{}:{opacity:0, pointerEvents: "none"})}}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginTop: "-1.7rem"}}>
            <FormField
              inline={true}
              width={16}>
              <label>
                สถานะ :
              </label>
              <div>
                {props.status || ""}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={16}>
              <div>
                {props.itemsElement}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              style={{flex: 1}}>
            </FormField>
            <FormField>
              <div>
                {props.buttonCall}
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}

CardHomeMedDetailUX.displayName = "CardHomeMedDetailUX";
export default React.memo(CardHomeMedDetailUX)

export const screenPropsDefault = {}

/* Date Time : Wed Oct 02 2024 14:55:38 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"5px 0\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"flex-start\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", paddingRight: \"3.5rem\", alignItems: \"baseline\"}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "--readOnly --grey"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\"}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-1.7rem\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 9,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 11,
      "props": {
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCall"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.drugDetail?.title || \"-\"} : `"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugDetail?.code || \"-\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ : "
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status || \"\""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.itemsElement"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Checkbox",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.telepharType ===\"INTRA\""
        },
        "className": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledCheck || false"
        },
        "label": {
          "type": "value",
          "value": "Intrahospital Telepharmacy"
        },
        "name": {
          "type": "value",
          "value": "INTRA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", opacity: 1, textAlign: \"left\",...(props.showCheck?{}:{opacity:0, pointerEvents: \"none\"})}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Checkbox",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.telepharType===\"DELIVERY\""
        },
        "className": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledCheck || props.hasDrugTransportNotAllowed || false"
        },
        "label": {
          "type": "value",
          "value": "Home delivery"
        },
        "name": {
          "type": "value",
          "value": "DELIVERY"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"left\", marginTop: \"0.75rem\",...(props.showCheck?{}:{opacity:0, pointerEvents: \"none\"})}"
        }
      },
      "seq": 23,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "memo": true,
  "name": "CardHomeMedDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
