/*eslint jsx-quotes: 0, arrow-parens: 0, semi: 0, indent: 0, prefer-const: 0, no-var: 0, no-unused-vars: 0*/
import React, { useRef, useState } from "react";
import ReactTable from "react-table-6";
// import { useWhatChanged } from "@simbathesailor/use-what-changed";
// import PureReactTable from 'react-lib/apps/common/PureReactTable'
import { Icon, Button, Checkbox } from "semantic-ui-react";
// import { SetPropSeparate } from "./SetProp";
// import "../css/DenTable.css" จะโดนทุก ที่ทุก project ให้ narrow ก่อนใช้

export const Table = (props: any) => {
  const columns = getColumns(props);

  // useWhatChanged([
  //   props.type,props.setProp, props.masterData, props.onFocusRow,props.data,props.filteredItem,props.keyPrefix,props.onFinishedEditing,props.removeRowById
  // ],
  //    'props.type,props.setProp, props.masterData, props.onFocusRow,props.data,props.filteredItem,props.keyPrefix,props.onFinishedEditing,props.removeRowById', "Table");
  // useEffect(() => {
  // }, [ props.type,props.setProp, props.masterData, props.onFocusRow,props.data,props.filteredItem,props.keyPrefix,props.onFinishedEditing,props.removeRowById])

  // console.log(" Table render props: ", props)
  return props.type === "Diagnosis" ? (
    <DiagnosisTable
      onFocusRow={props.onFocusRow}
      onOpenModalDrawing={props.onOpenModalDrawing}
      onSaveClinicalFindingById={props.onSaveClinicalFindingById}
      // onBlurRow={props.onBlurRow}
      columns={columns}
      data={props.data}
      filterClinicalFindingIds={props.filterClinicalFindingIds}
      // filteredItem={props.filteredItem}
      activeTab={props.activeTab}
      keyPrefix={props.keyPrefix}
      setProp={props.setProp}
      onEvent={props.onEvent}
      onFinishedEditing={props.onFinishedEditing}
      removeRowById={props.removeRowById}
      showRowDetailById={props.showRowDetailById}
      masterData={props.masterData}
      treatmentPlanList={props.treatmentPlanList}
      showDrawingChecked={props.showDrawingChecked}
      showDrawingCheckedAll={props.showDrawingCheckedAll}
      // style
      style={props.style}
    />
  ) : (
    <ReactTable
      manual
      className={props.className}
      columns={columns}
      data={props.data || []}
      style={props.style}
      defaultPageSize={props.defaultPageSize}
      showPagination={props.showPagination}
      getTrProps={
        typeof props.getTrProps === "function"
          ? props.getTrProps
          : () => {
              return {};
            }
      }
      getTableProps={props.getTableProps}
      getTheadThProps={props.getTheadThProps}
      getTheadProps={props.getTheadProps}
      getTheadTrProps={props.getTheadTrProps}
      getTbodyProps={props.getTbodyProps}
      getTrGroupProps={props.getTrGroupProps}
      getTdProps={props.getTdProps}
      minRows={props.minRows}
      NoDataComponent={props.NoDataComponent}
      expanded={props.expanded}
      expanderDefaults={props.expanderDefaults}
      onExpandedChange={props.onExpandedChange}
      subRowsKey={props.subRowsKey}
      resizable={props.resizable}
    />
  );
};

export const getColumns = (props: any) => {
  if (!props.headers && !props.keys && !props.widths && props.columns) {
    return props.columns;
  }

  let headers = Array.isArray(props.headers) ? props.headers : props.headers ? props.headers.split(",").map((header: string) => header.trim()) : [];
  let keys = props.keys ? props.keys.split(",").map((key: string) => key.trim()) : [];
  let widths = props.widths ? props.widths.split(",").map((width: string) => width.trim()) : [];

  // console.log(headers);

  const columnCount = Math.max(headers.length, keys.length, widths.length);
  // console.log('Framework Table keys: ', keys);
  // console.log('Framework Table headers: ', headers);
  // console.log("Framework Table widths: ", widths)
  // console.log("Framework Table columnCount: ", columnCount)
  let columns: any[] = [];
  if (columnCount > 0) {
    for (var i = 0; i < columnCount; i++) {
      let column: { [key: string]: number | undefined } = {};
      if (i < headers.length) {
        column["Header"] = headers[i];
      }
      if (i < keys.length) {
        column["accessor"] = keys[i];
      }
      if (i < widths.length) {
        const widthKey = widths[i][0] === "^" ? "minWidth" : "width"; // TODO: port into isMor[UI]
        let widthValue = parseInt(widths[i].match(/\d+/));

        if (["minWidth", "width"].includes(widthKey) && !widthValue) {
          // Do nothing
          // column["width"] = 100;
          // column["maxWidth"] = 200;
        } else {
          // "minWidth"
          if (widthKey === "minWidth") {
            column["maxWidth"] = widthValue * 5;
          }
          // "width"
          column[widthKey] = widthValue;
        }

        // Last Column
        if (i === columnCount - 1) {
          // "width"
          if ("width" in column) {
            // สลับ จาก width -> minWidth
            // delete column.width;
            // column["minWidth"] = widthValue;
          } else if (!("minWidth" in column)) {
            // ไม่ได้ เซ็ต minWidht'
            column["minWidth"] = 100;
          }
          column["maxWidth"] = 1000;
        }
      }
      columns.push(column);
    }
  } else if (props.columns) {
    columns = props.columns;
  }
  return columns;
};

const DiagnosisTable = (props: any) => {
  const [editedRow, setEditedRow] = useState<number | null>(null);
  const [editedColumn, setEditedColumn] = useState<number | null>(null);
  const [selectionStart, setSelectionStart] = useState<number | null>(null);
  const [, setSelectionEnd] = useState<number | null>(null);
  const [cursor, setCursor] = useState<number | null>(null);

  const textareaRef = useRef<any>(null)

  const borderStyle = "solid #dddddd 0.5px";
  const widths = props.columns.map((column: any) => column.width);
  const sumWidth = widths.reduce((acc: number, cur: number) => acc + cur, 0);
  const gridTemplateColumns = widths
    .map((width: number) => (100.0 * width) / sumWidth)
    .map((pct: number) => pct.toString() + "%")
    .join(" ");

  // useWhatChanged([
  //   props.onFocusRow,props.columns, props.data, props.filteredItem,props.keyPrefix,props.setProp,props.onFinishedEditing,props.removeRowById,props.masterData
  // ],
  //    'props.onFocusRow,props.columns, props.data, props.filteredItem,props.keyPrefix,props.setProp,props.onFinishedEditing,props.removeRowById,props.masterData', "DiagnosisTable");
  // useEffect(() => {
  // }, [ props.onFocusRow,props.columns, props.data, props.filteredItem,props.keyPrefix,props.setProp,props.onFinishedEditing,props.removeRowById,props.masterData ])
  // if (props.data?.length > 0) {
  //   console.log('DiagnosisTable -> props.data: ', props.data[0].location);
  // }

  // console.log("DiagnosisTable render props: ", props)
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "grid",
          overflowY: "scroll",
          gridTemplateColumns: gridTemplateColumns,
        }}
      >
        {props.columns.map((column: any, column_index: number) =>
          !["remove"].includes(column.type) ? (
            <div
              key={column_index}
              style={{
                border: borderStyle,
                textAlign: "center",
                gridColumn: column_index + 1,
                gridRow: 1,
                backgroundColor: "#2d9cdb",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "3px",
              }}
            >
              {column.type === "show_all" && (
                <Checkbox
                  checked={props.showDrawingCheckedAll}
                  onChange={(e, { checked }) => props.onEvent({ message: "SetToggleShowDrawingAll", params: { checked, data: props.data } })}
                  style={{ marginRight: "3px" }}
                />
              )}
              {column.Header}{" "}
              {column.type === "keyboard_shortcut" && (
                <Button
                  circular
                  size="tiny"
                  color="yellow"
                  onClick={() => props.setProp("showClinicalFindingTerms", true)}
                  style={{ padding: "5px", marginLeft: "10px" }}
                  icon="cog"
                />
              )}
            </div>
          ) : (
            <div
              key={column_index}
              style={{
                border: borderStyle,
                textAlign: "center",
                gridColumn: column_index + 1,
                gridRow: 1,
                backgroundColor: "#2d9cdb",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "3px",
              }}
            ></div>
          )
        )}
      </div>
      <div style={{ width: "100%", height: "300px", overflowY: "scroll", ...(props.style || {}) }}>
        {props.data.map((row: any, row_index: number) => {
          // console.log(" row", row, props)
          // console.log(" row_index", row_index)
          return (
            true && (
              <div
                key={row_index}
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: gridTemplateColumns,
                  margin: 0,
                  padding: 0,
                }}
              >
                {props.columns.map((column: any, column_index: number) => {
                  // console.log(" props.columns.map column_index", column_index, "column", column)
                  let suggestedTerms: {
                    group: string;
                    hide: boolean;
                    label: string;
                    value: string;
                  }[] = [];
                  if (
                    editedRow === row_index &&
                    editedColumn === column_index
                  ) {
                    suggestedTerms = getSuggestedTerms(
                      props.masterData,
                      column.masterDataSet,
                      column.masterDataKey,
                      column.masterDataGroup,
                      row[column.accessor],
                      selectionStart,
                      props.activeTab
                    );
                  }

                  const enableGroup = column.masterDataGroup;
                  const nextElmWidth = textareaRef.current?.parentElement.nextElementSibling.offsetWidth;
                  // if ( suggestedTerms?.length > 0 ){
                  //   console.log(" suggestedTerms: ", suggestedTerms)
                  // }
                  return column.type === "remove" ? (
                    <div
                      style={{
                        width: "100%",
                        border: "0.5px solid rgb(221, 221, 221)",
                        marginBottom: "4.5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                      }}
                      key={column_index}
                    >
                      <Icon
                        name={"trash alternate"}
                        size="large"
                        color="grey"
                        disabled={row?.have_performed_treatment_plan}
                        onClick={(e: any) => {
                          props.removeRowById(row.id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : column.type === "keyboard_shortcut" ? (
                    <div key={column_index} style={{ position: "relative", margin: 0, padding: 0, paddingBottom: "4px" }}>
                      <textarea
                        style={{
                          width: "100%",
                          padding: 0,
                          margin: 0,
                          border: borderStyle,
                          textAlign: "center",
                          gridColumn: column_index + 1,
                          gridRow: 2 + row_index,
                          height: "100%",
                          resize: "none",
                        }}
                        value={row[column.accessor]}
                        onFocus={(e) => props.onFocusRow(e, row_index, column_index)}
                        onChange={(e) => {
                          props.setProp(`clinicalFindingList.${row_index}.other_clinical_finding`, e.target.value);
                        }}
                        onKeyDown={(e) =>
                          props.onEvent({ message: "SetKeyboardShortcutToClinicalFinding", params: { event: e, clinicalFindingIndex: row_index } })
                        }
                        onBlur={() => props.onSaveClinicalFindingById()}
                      ></textarea>
                    </div>
                  ) : column.type === "detail" ? (
                    <div
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "0.5px solid rgb(221, 221, 221)",
                        marginBottom: "4.5px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "white",
                      }}
                      key={column_index}
                    >
                      <Icon
                        name={"folder outline"}
                        color={row[column.accessor] ? "green" : "grey"}
                        size="large"
                        onClick={(e: any) => {
                          props.showRowDetailById(row.id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <div
                        style={{
                          marginLeft: "5px",
                          width: "100%",
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {/* {
                        props.treatmentPlanList.length > 0 ? props.treatmentPlanList?.filter((plan: any) =>
                          plan.clinicalFindingId === row.id)?.map((acc: any) => acc.treatment)?.filter(Boolean)?.join(", ") : ''
                      } */}
                        {row[column.accessor]}
                      </div>
                    </div>
                  ) : column.type === "drawing" ? (
                    <div
                      style={{
                        width: "100%",
                        border: "0.5px solid rgb(221, 221, 221)",
                        marginBottom: "4.5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                      }}
                      key={column_index}
                    >
                      <Icon
                        name={"edit outline"}
                        color={row?.image && Object.keys(row?.image)?.length > 0 ? "green" : "grey"}
                        size="large"
                        onClick={(e: any) => {
                          props.onOpenModalDrawing({ location: row[column.accessor], organ: props.masterData?.organ?.items, data: props.data, row, row_index });
                          props.onFocusRow(e, row_index, column_index);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : column.type === "show_all" ? (
                    <div
                      style={{
                        width: "100%",
                        border: "0.5px solid rgb(221, 221, 221)",
                        marginBottom: "4.5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                      }}
                      key={column_index}
                    >
                      <Icon
                        name={props.showDrawingChecked?.includes(row.id) ? "eye" : "eye slash"}
                        color="grey"
                        size="large"
                        onClick={(e: any) => {
                          console.log(row);
                          props.onEvent({ message: "SetToggleShowDrawingOverlay", params: { row } });
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <div style={{ position: "relative", margin: 0, padding: 0 }} key={column_index}>
                      <textarea
                        className="diagnosis-cell"
                        ref={textareaRef}
                        readOnly={column.type !== "input"}
                        rows={4}
                        placeholder={column?.placeholder || ""}
                        key={column_index}
                        onKeyDown={(e: any) => {
                          if (e.key === "ArrowUp" && (cursor || 0) > 0) {
                            setCursor((cursor || 0) - 1);
                          } else if (e.key === "ArrowDown" && (cursor || 0) < suggestedTerms.length - 1) {
                            setCursor((cursor || 0) + 1);
                          } else if (e.key === "Enter") {
                            const indexKey = `${props.keyPrefix}.${row_index}`;
                            const key = `${indexKey}.${column.keySuffix}`;
                            const termItem = suggestedTerms[cursor || 0];

                            const newTerm = addTermToList(termItem.label, row[column.accessor], selectionStart);
                            console.log("suggestedTerms onClick , key: ", key, "newTerm: ", newTerm, "term_index", cursor, "term", suggestedTerms[cursor || 0]);

                            if (termItem.group) {
                              props.setProp(
                                indexKey,
                                { ...row, [column.keySuffix]: termItem.label, ...(termItem.value ? { [termItem.group]: termItem.value } : {}) },
                                () => {
                                  props.onFinishedEditing(row.id);
                                }
                              );
                            } else {
                              props.setProp(key, newTerm, () => {
                                props.onFinishedEditing(row.id);
                              });
                            }

                            setEditedRow(null);
                            setEditedColumn(null);
                            setSelectionStart(null);
                            setSelectionEnd(null);
                          }
                        }}
                        style={{
                          width: "100%",
                          padding: 0,
                          margin: 0,
                          border: borderStyle,
                          textAlign: "center",
                          gridColumn: column_index + 1,
                          gridRow: 2 + row_index,
                          resize: "none",
                          height: "calc(100% - 4px)",
                        }}
                        value={row[column.accessor]}
                        onChange={(e) => {
                          const key = `${props.keyPrefix}.${row_index}.${column.keySuffix}`;
                          // console.log(" ************** ")
                          // console.log(" onChange Table e: ", e, "column",  column, "props", props, "key", key, "e.target.value: ", e.target.value, "e.target.selectionStart: ", e.target.selectionStart, "e.target.selectionEnd: ", e.target.selectionEnd);
                          // console.log(" ************** ")
                          props.setProp(key, e.target.value);
                          setEditedRow(row_index);
                          setEditedColumn(column_index);
                          setSelectionStart(e.target.selectionStart);
                          setSelectionEnd(e.target.selectionEnd);
                          setCursor(0);
                        }}
                        onFocus={async (e) => {
                          const key = `${props.keyPrefix}.${row_index}.${column.keySuffix}`;
                          const value = e.target.value;

                          if (!!value && !column.selectOnFocus) {
                            props.setProp(key, `${value},`);
                          }
                          // console.log(" ************** onFocuse ************** row_index: " ,row_index, "column_index: ", column_index)
                          props.onFocusRow(e, row_index, column_index);
                          // Trigger to Muti-select mode
                          // console.log("onFocuse row_index", row_index)

                          setEditedRow(null);
                          setEditedColumn(null);
                          setSelectionStart(null);
                          setSelectionEnd(null);
                          setCursor(0);

                          if (column.selectOnFocus) {
                            e.target?.select?.();
                          }
                        }}
                        onBlur={(e) => {
                          // console.log(" ************** onBlur **************")
                          // console.log("onBlur row_index", row_index, "row", row);
                          props.onFinishedEditing(row.id);
                          setEditedRow(null);
                          setEditedColumn(null);
                          setSelectionStart(null);
                          setSelectionEnd(null);
                          setCursor(0);
                        }}
                      />
                      {editedRow === row_index && editedColumn === column_index && suggestedTerms.length > 0 && (
                        <div
                          className="suggested-terms"
                          style={{
                            position: "absolute",
                            top: textareaRef.current.offsetHeight || 0,
                            left: 0,
                            zIndex: 1000,
                            width: enableGroup ? `calc(100% - -${nextElmWidth}px)` : "100%",
                            backgroundColor: "#a5f2f3",
                            border: "solid #dddddd 1px",
                            maxHeight: "200px",
                            overflowY: "auto",
                          }}
                        >
                          {suggestedTerms.map((termItem, term_index: number) => (
                            <div
                              key={term_index}
                              className={"suggested-terms-item " + ((cursor || 0) === term_index ? "active" : "")}
                              style={{
                                ...(cursor === term_index
                                  ? {
                                      backgroundColor: "#a6a6a6",
                                      cursor: "pointer",
                                    }
                                  : { cursor: "pointer" }),
                                display: "grid",
                                gridTemplateColumns: enableGroup ? `1fr ${nextElmWidth - 10}px` : "1fr",
                                borderTop: !termItem.hide ? "1px solid rgba(34,36,38,.05)" : "",
                                padding: "1px 0"
                              }}
                              onMouseDown={(e) => {
                                const indexKey = `${props.keyPrefix}.${row_index}`;
                                const key = `${indexKey}.${column.keySuffix}`;

                                const newTerm = addTermToList(termItem.label, row[column.accessor], selectionStart);
                                console.log("suggestedTerms onClick , key: ", key, "newTerm: ", newTerm, "term_index", term_index, "term", termItem);

                                if (termItem.group) {
                                  props.setProp(
                                    indexKey,
                                    { ...row, [column.keySuffix]: termItem.label, ...(termItem.value ? { [termItem.group]: termItem.value } : {}) },
                                    () => {
                                      props.onFinishedEditing(row.id);
                                    }
                                  );
                                } else {
                                  props.setProp(key, newTerm, () => {
                                    props.onFinishedEditing(row.id);
                                  });
                                }

                                setEditedRow(null);
                                setEditedColumn(null);
                                setSelectionStart(null);
                                setSelectionEnd(null);
                              }}
                            >
                              <div>{!termItem.hide && termItem.label}</div>
                              <div style={{ paddingLeft: "10px" }}>{termItem.value}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

const getSuggestedTerms = (
  masterData: any,
  masterDataSet: string,
  masterDataKey: string,
  masterDataGroup: string,
  currentInput: string,
  selectionStart: number | null,
  tissue: string | null
): any[] => {
  console.log("getSuggestedTerms masterDataSet", masterDataSet, "masterDataKey", masterDataKey, "currentInput", currentInput, "selectionStart", selectionStart);
  if (currentInput && selectionStart && typeof selectionStart === "number") {
    console.log(currentInput);
    const terms = currentInput.split(",");
    let index = 0;
    let offset = 0;
    while (index < terms.length && offset + terms[index].length + 1 <= selectionStart) {
      offset += terms[index].length + 1;
      index += 1;
    }
    const currentTerm = terms[index];
    let items: any[] = masterDataSet.split("|").flatMap((set) => masterData[set]?.items || []);

    console.log("masterdataset: ", masterData, masterDataSet);
    if (masterDataSet === "organ" && tissue) {
      items = items.filter((o: any) => o.tissue === tissue);
    }

    const filterItems = items.filter((item: any) => {
      const term = item[masterDataKey];
      return term && term?.toLowerCase().includes(currentTerm?.toLowerCase().trim());
    });

    const sortedItems = filterItems.sort((a, b) => {
      const aValue = a[masterDataGroup] || "";
      const bValue = b[masterDataGroup] || "";
      // เรียงตาม term ก่อน
      const termComparison = a[masterDataKey].localeCompare(b[masterDataKey]);
      if (termComparison !== 0) {
        return termComparison;
      }
      // ถ้า term เท่ากัน ให้เรียงตาม masterDataGroup
      return !aValue && bValue ? 1 : aValue && !bValue ? -1 : 0;
    });

    return sortedItems
      .reduce((result, item) => {
        result.push({
          label: item[masterDataKey],
          group: masterDataGroup,
          value: item[masterDataGroup] || "",
          hide: !!result.some((acc: any) => acc.label === item[masterDataKey]) && !!item[masterDataGroup],
        });

        return result;
      }, [])
      .flat();
  } else {
    return [];
  }
};

const addTermToList = (term: string, currentInput: string, selectionStart: number | null) => {
  if (currentInput && selectionStart && typeof selectionStart === "number") {
    const terms = currentInput.split(",");
    let index = 0;
    let offset = 0;
    while (offset + terms[index].length + 1 <= selectionStart) {
      offset += terms[index].length + 1;
      index += 1;
    }
    terms[index] = " " + term;
    return terms.join(",");
  } else {
    return currentInput;
  }
};
