import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Label,
  TextArea,
  Dropdown,
  Input,
  Icon,
  Checkbox
} from 'semantic-ui-react'

const CardImagingResultUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div>

          <div
            id="CardImagingResult-Div-ImagingOrderListScreen"
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>

            <div
              style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0"}}>
              Imaging Order List
            </div>
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: "0.5rem"}}>

        </div>
        <div
          className="ui form">

          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.imagingOrderList || []}
            getTrProps={props.onGetTrProps}
            headers={`Orderdate/Time,Order No.,Imaging Type,Request,Item Status,Careprovider,Radiologistt${ props.hideColumnResult ? "" : ",Result"},Log`}
            keys={`datetime,order_no,type, request, order_status, doctor_name,radiologist${props.hideColumnResult ? "" : ",result"}, log`}
            minRows={11}
            showPagination={false}
            style={{height: !!props.selectedOrder? "195px": props.tableHeight? props.tableHeight :"calc(100vh - 14rem)"}}
            widths={`120,120,^120,^250,^100,^120,^120${props.hideColumnResult ? "" : ",70"},70`}>
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem"}}>
          {props.pagination}
        </div>
        <div
          className={`ui form ${props.readOnly ? "--readOnly" : ""}`}
          style={{marginTop: "1.5rem", display: !!props.selectedOrder?"":"none"}}>

          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{fontSize: "1.1rem"}}>
                Imaging Result Entry
              </label>
            </FormField>
            <FormField
              style={{flex: 1}}>
            </FormField>
            <FormField>
              <Icon
                className="red large"
                link={true}
                name="close"
                onClick={props.onClose}>
              </Icon>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{fontSize: "1.1rem", color: "#2185D0"}}>
                {props.selectedOrder?.group_name|| "-"}
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{fontSize: "1.1rem", color: "#2185D0"}}>
                {props.selectedOrder?.name_code|| "-"}
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{marginRight: "0.5rem"}}>
                Order No.:
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.selectedOrder?.order_number|| "-"}
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{marginRight: "0.5rem"}}>
                Order date/time:
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.selectedOrder?.created|| "-"}
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{marginRight: "0.5rem"}}>
                Accession No. :
              </label>
              <label
                style={{fontWeight: "normal", wordBreak: "break-all"}}>
                {props.selectedOrder?.accession_id || "-"}
              </label>
              <div>
                {props.iconPacs}
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{justifyContent: "flex-end", padding: 0}}
              width={3}>
              <label
                style={{marginRight: "0.5rem", minWidth: "max-content"}}>
                Status:
              </label>
              <Label
                style={{minWidth: "max-content", backgroundColor: props.statusDetail?.color || "", color: !!props.statusDetail?.color ? "white" : ""}}>
                {!!props.statusDetail?.label ? props.statusDetail?.label : "-"}
              </Label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{marginRight: "0.5rem"}}>
                Order department:
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.divisionName || "-"}
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{marginRight: "0.5rem"}}>
                Careprovider:
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.selectedOrder?.care_provider || "-"}
              </label>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{marginRight: "0.5rem"}}>
                Radiologist:
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.selectedOrder?.radiologist || "-"}
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{padding: 0}}>
              <div>
                {props.buttonPrintReport}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                Request detail :
              </label>
              <label>
                {!props.locationsName && !props.selectedOrder?.note ? "-" : `${props.locationsName} ${props.selectedOrder?.note || ""}`}
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                Execution comment :
              </label>
              <label>
                {props.executionNote || "-"}
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                Principal Diagnosis :
              </label>
              <label>
                {!!props.principalDiagnosis?.icd_code ? `[${props.principalDiagnosis?.icd_code}] ${props.principalDiagnosis?.icd_term}` : "-"}
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            className="--readOnly"
            inline={true}
            style={{backgroundColor: "#e5f4ff", padding: "0.75rem"}}>
            <FormField
              inline={true}
              width="10">
              <div
                className="label-suspected-diagnosis"
                style={{width: "190px"}}>

                <label>
                  Suspected Diagnosis
                </label>
                <label
                  style={{color:"red"}}>
                  *
                </label>
              </div>
              <div
                style={{width: "100%", marginRight: "0.75rem"}}>
                {props.principalDiagLabel}
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.out_time || false}
                label="นอกเวลา">
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.is_emergency|| false}
                label="Emergency">
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.secret|| false}
                label="ปกปิด">
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.lawsuit || false}
                label="คดีความ">
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.repeat_medication|| false}
                label="Repeat Medication">
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "0.5rem"}}>
            <FormField
              inline={true}>
              <label>
                Result
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={16}>
              <TextArea
                id="CardImagingResult-TextArea-Result"
                name="result"
                onChange={props.onChangeValue}
                readOnly={props.readOnly || false}
                rows="10"
                style={{opacity: 1}}
                value={props.reportDetail?.result || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "0.5rem"}}>
            <FormField
              inline={true}>
              <label>
                Impression
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={16}>
              <TextArea
                id="CardImagingResult-TextArea-Impression"
                name="impression"
                onChange={props.onChangeValue}
                readOnly={props.readOnly || false}
                rows="10"
                style={{opacity: 1}}
                value={props.reportDetail?.impression || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth: "155px"}}>
                Result summary
              </label>
              <Dropdown
                disabled={props.readOnly || false}
                fluid={true}
                id="CardImagingResult-Dropdown-ResultSummary"
                name="result_summary"
                onChange={props.onChangeValue}
                options={props.resultSummaryOptions || []}
                search={true}
                selection={true}
                style={{width: "100%",opacity: 1}}
                value={props.reportDetail?.result_summary || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{display: props.showEditReason? "":"none"}}
              width={10}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{minWidth: "max-content", fontWeight: "bold"}}>
                  Edit reason
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
              <Input
                id="CardImagingResult-Input-EditReason"
                name="edit_reason"
                onChange={props.onChangeValue}
                style={{opacity: 1}}
                value={props.reportDetail?.edit_reason || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth: "155px", display: "none"}}>
                ค่าแพทย์อ่านผล Imaging
              </label>
              <Input
                disabled={props.readOnly || false}
                fluid={true}
                name="doctor_summary_result"
                onChange={props.onChangeValue}
                style={{width: "100%", opacity: 1, display: "none"}}
                value={props.reportDetail?.doctor_summary_result|| ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              style={{flex: 1}}>
            </FormField>
            <FormField
              inline={true}
              style={{display: props.showApprove || props.showEdit ? "":"none"}}>
              <Input
                id="CardImagingResult-Input-Username"
                name={props.showApprove || props.showEdit ? "username":""}
                onChange={props.onChangeValue}
                placeholder={props.showApprove || props.showEdit ? "Username":""}
                ref={(ref: any)=>ref && (ref.inputRef.current.style.border="1px solid rgb(200 197 197)")}
                style={{width: "150px"}}
                value={props.reportDetail?.username|| ""}>
              </Input>
            </FormField>
            <FormField
              style={{display: props.showApprove || props.showEdit ? "":"none"}}>
              <Input
                id="CardImagingResult-Input-Password"
                name={props.showApprove || props.showEdit ? "password":""}
                onChange={props.onChangeValue}
                placeholder={props.showApprove || props.showEdit ? "Password":""}
                ref={(ref: any)=>ref && (ref.inputRef.current.style.border="1px solid rgb(200 197 197)")}
                style={{width: "150px"}}
                type={props.showApprove || props.showEdit ? "password":""}
                value={props.reportDetail?.password || ""}>
              </Input>
            </FormField>
            <FormField
              style={{display: props.showEdit? "":"none"}}>
              <div>
                {props.buttonEdit}
              </div>
            </FormField>
            <FormField
              style={{display:  !props.showApprove? "none":""}}>
              <div>
                {props.buttonApprove}
              </div>
            </FormField>
            <FormField
              style={{display:  "none"}}>
              <div
                style={{display: "none"}}>
                {props.buttonCancel}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

CardImagingResultUX.displayName = "CardImagingResultUX";
export default React.memo(CardImagingResultUX)

export const screenPropsDefault = {}

/* Date Time : Tue Oct 01 2024 09:31:52 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.5rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingResult-Div-ImagingOrderListScreen"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging Order List"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.imagingOrderList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "code",
          "value": "`Orderdate/Time,Order No.,Imaging Type,Request,Item Status,Careprovider,Radiologistt${ props.hideColumnResult ? \"\" : \",Result\"},Log`"
        },
        "keys": {
          "type": "code",
          "value": "`datetime,order_no,type, request, order_status, doctor_name,radiologist${props.hideColumnResult ? \"\" : \",result\"}, log`"
        },
        "minRows": {
          "type": "code",
          "value": "11"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: !!props.selectedOrder? \"195px\": props.tableHeight? props.tableHeight :\"calc(100vh - 14rem)\"}"
        },
        "widths": {
          "type": "code",
          "value": "`120,120,^120,^250,^100,^120,^120${props.hideColumnResult ? \"\" : \",70\"},70`"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pagination"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"1rem\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form ${props.readOnly ? \"--readOnly\" : \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\", display: !!props.selectedOrder?\"\":\"none\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 18,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 24,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging Result Entry"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedOrder?.group_name|| \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", color: \"#2185D0\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedOrder?.name_code|| \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", color: \"#2185D0\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{justifyContent: \"flex-end\", padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "Order No.:"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedOrder?.order_number|| \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "Order date/time:"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedOrder?.created|| \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "Accession No. :"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedOrder?.accession_id || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", wordBreak: \"break-all\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "Status:"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Label",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "!!props.statusDetail?.label ? props.statusDetail?.label : \"-\""
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", backgroundColor: props.statusDetail?.color || \"\", color: !!props.statusDetail?.color ? \"white\" : \"\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "Order department:"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionName || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Careprovider:"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedOrder?.care_provider || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "Radiologist:"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedOrder?.radiologist || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "Request detail :"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "Execution comment :"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "!props.locationsName && !props.selectedOrder?.note ? \"-\" : `${props.locationsName} ${props.selectedOrder?.note || \"\"}`"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.executionNote || \"-\""
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "Result"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "TextArea",
      "parent": 62,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingResult-TextArea-Result"
        },
        "name": {
          "type": "value",
          "value": "result"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "rows": {
          "type": "value",
          "value": "10"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.reportDetail?.result || \"\""
        }
      },
      "seq": 64,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Impression"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "TextArea",
      "parent": 68,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingResult-TextArea-Impression"
        },
        "name": {
          "type": "value",
          "value": "impression"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "rows": {
          "type": "value",
          "value": "10"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.reportDetail?.impression || \"\""
        }
      },
      "seq": 70,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showEditReason? \"\":\"none\"}"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Result summary"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"155px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Dropdown",
      "parent": 72,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardImagingResult-Dropdown-ResultSummary"
        },
        "name": {
          "type": "value",
          "value": "result_summary"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.resultSummaryOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.reportDetail?.result_summary || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 73,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingResult-Input-EditReason"
        },
        "name": {
          "type": "value",
          "value": "edit_reason"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.reportDetail?.edit_reason || \"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "Edit reason"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"bold\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 82,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 82,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 82,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showApprove || props.showEdit ? \"\":\"none\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "ค่าแพทย์อ่านผล Imaging"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"155px\", display: \"none\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Input",
      "parent": 83,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "doctor_summary_result"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: 1, display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.reportDetail?.doctor_summary_result|| \"\""
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 82,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.showApprove || props.showEdit ? \"\":\"none\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 82,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.showEdit? \"\":\"none\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 82,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:  !props.showApprove? \"none\":\"\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 82,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:  \"none\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Input",
      "parent": 85,
      "props": {
        "id": {
          "type": "value",
          "value": "CardImagingResult-Input-Username"
        },
        "name": {
          "type": "code",
          "value": "props.showApprove || props.showEdit ? \"username\":\"\""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "placeholder": {
          "type": "code",
          "value": "props.showApprove || props.showEdit ? \"Username\":\"\""
        },
        "ref": {
          "type": "code",
          "value": "(ref: any)=>ref && (ref.inputRef.current.style.border=\"1px solid rgb(200 197 197)\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.reportDetail?.username|| \"\""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Input",
      "parent": 88,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardImagingResult-Input-Password"
        },
        "name": {
          "type": "code",
          "value": "props.showApprove || props.showEdit ? \"password\":\"\""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "placeholder": {
          "type": "code",
          "value": "props.showApprove || props.showEdit ? \"Password\":\"\""
        },
        "ref": {
          "type": "code",
          "value": "(ref: any)=>ref && (ref.inputRef.current.style.border=\"1px solid rgb(200 197 197)\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\"}"
        },
        "type": {
          "type": "code",
          "value": "props.showApprove || props.showEdit ? \"password\":\"\""
        },
        "value": {
          "type": "code",
          "value": "props.reportDetail?.password || \"\""
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonApprove"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCancel"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 18,
      "props": {
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Icon",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.iconPacs"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "Principal Diagnosis :"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "code",
          "value": "!!props.principalDiagnosis?.icd_code ? `[${props.principalDiagnosis?.icd_code}] ${props.principalDiagnosis?.icd_term}` : \"-\""
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "className": {
          "type": "value",
          "value": "--readOnly"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#e5f4ff\", padding: \"0.75rem\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "10"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "code",
          "value": "props.principalDiagLabel"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"0.75rem\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Checkbox",
      "parent": 112,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.out_time || false"
        },
        "label": {
          "type": "value",
          "value": "นอกเวลา"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Checkbox",
      "parent": 113,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.is_emergency|| false"
        },
        "label": {
          "type": "value",
          "value": "Emergency"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Checkbox",
      "parent": 114,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.secret|| false"
        },
        "label": {
          "type": "value",
          "value": "ปกปิด"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Checkbox",
      "parent": 115,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.repeat_medication|| false"
        },
        "label": {
          "type": "value",
          "value": "Repeat Medication"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "Checkbox",
      "parent": 124,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.lawsuit || false"
        },
        "label": {
          "type": "value",
          "value": "คดีความ"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "label-suspected-diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{width: \"190px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "Suspected Diagnosis"
        },
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintReport"
        }
      },
      "seq": 133,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardImagingResultUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
