import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { ModConfirm } from "react-lib/apps/common";
import { Button, Grid, Icon, Label, Modal, TextArea } from "semantic-ui-react";
import WebcamComponent from "../common/WebcamProfile";
import ModalEditImage from "react-lib/apps/common/ModalEditImage";

// UI
import CardByEquipmentUX from "./CardByEquipmentUX";
import PreOPButtonAction from "./PreOPButtonAction";

const CARD_KEY = "byEquipment";
const FORM_CODE = "CardTube";
const FORM_NAME = "Tube";

const CARD_KEY_Drain = "drainData";
const FORM_CODE_Drain = "CardORDrain";
const FORM_NAME_Drain = "ORDrain";

const CARD_KEY_Dressing = "dressingData";
const FORM_CODE_Dressing = "CardORDressing";
const FORM_NAME_Dressing = "ORDressing";

const ACTION = {
  SAVE: "SAVE",
  CONFIRM: "CONFIRM",
  UNCONFIRM: "UNCONFIRM",
};

const IMAGE = {
  imageBgUpload: "images/picture.gif",
};

const FormUploadImg = {
  nurse_implant: "",
  image: "",
  detail: "",
  id: "",
  is_active: true,
};

const CardByEquipment = (props: any) => {
  const [selectImage, setSelectImage] = useState();
  const [editedImage, setEditedImage] = useState(FormUploadImg);
  const [uploadImage, setUploadImage] = useState(null);
  const [detailImage, setDetailImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(FormUploadImg);
  const [openUploadImg, setOpenUploadImg] = useState(false);
  const [openImageDetail, setOpenImageDetail] = useState(false);
  const [openModalEditImage, setOpenModalEditImage] = useState(false);
  const [openWebcam, setOpenWebCam] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);

  const fileUploaderRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "get_equipment",
    });
  }, []);

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    if (data.type === "checkbox" || data.type === "radio") {
      props.setProp(`PerioperativeNursingSequence.${key}`, data.checked);
    } else {
      props.setProp(`PerioperativeNursingSequence.${key}`, data.value);
    }
  };

  const handleSave = (type: any, action: any) => {
    if (type === "Tube") {
      props.runSequence({
        sequence: "PerioperativeNursing",
        action: action,
        cardKey: CARD_KEY,
        formCode: FORM_CODE,
        formName: FORM_NAME,
        formVersion: "0.1",
      });
    } else if (type === "Drain") {
      props.runSequence({
        sequence: "PerioperativeNursing",
        action: action,
        cardKey: CARD_KEY_Drain,
        formCode: FORM_CODE_Drain,
        formName: FORM_NAME_Drain,
        formVersion: "0.1",
      });
    } else if (type === "Dressing") {
      props.runSequence({
        sequence: "PerioperativeNursing",
        action: action,
        cardKey: CARD_KEY_Dressing,
        formCode: FORM_CODE_Dressing,
        formName: FORM_NAME_Dressing,
        formVersion: "0.1",
      });
    }
  };

  //UploadImage
  const readChooseFile = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setUploadImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const saveImg = (imgSrc: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "save_uploadImg",
      image: imgSrc === "" ? uploadImage : imgSrc,
      detail: detailImage,
    });
    setOpenUploadImg(false);
    setOpenWebCam(false);
    setUploadImage(null);
    setDetailImage("");
  };

  const handleUpdateImage = (is_active: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "update_uploadImg",
      image: editedImage?.image,
      detail: editedImage?.detail,
      id: editedImage?.id,
      is_active: is_active,
    });
    setOpenImageDetail(false);
    setDeleteImage(false);
  };

  const handleSelectedImage = (item: any) => {
    setSelectedImage(item);
    setOpenModalEditImage(true);
  };

  const handleEditDescription = (e: any) => {
    var newDescription = {
      ...editedImage,
      detail: e,
    };
    setEditedImage(newDescription);
  };

  const handleEditedImage = (dataURI: string) => {
    var newImage = {
      ...selectedImage,
      image: dataURI,
    };

    setEditedImage(newImage);
    setOpenImageDetail(true);
  };
  return (
    <div style={{ paddingBottom: "5rem" }}>
      <CardByEquipmentUX
        byEquipment={props.PerioperativeNursingSequence?.byEquipment}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
        masterOptions={props.masterOptions}
        handleChangeValue={handleChangeValue}
        status={props.PerioperativeNursingSequence?.byEquipment?.status}
        statusDrain={props.PerioperativeNursingSequence?.drainData?.status}
        statusDressing={
          props.PerioperativeNursingSequence?.dressingData?.status
        }
        saveImplant={() => {
          props.runSequence({
            sequence: "PerioperativeNursing",
            action: "put_nurse_implant",
          });
        }}
        ImplantImage={
          <div style={{ backgroundColor: "white", padding: "1rem" }}>
            <div style={{ fontWeight: "bold", marginBottom: "1rem" }}>
              ภาพประกอบการ Implant
            </div>
            <div>
              <ImgDetail
                uploadImg={props.PerioperativeNursingSequence?.implantData}
                selectImage={selectImage}
                openImageDetail={openImageDetail}
                editedImage={editedImage}
                handleUpdateImage={handleUpdateImage}
                handleEditDescription={handleEditDescription}
                setSelectImage={setSelectImage}
                handleSelectedImage={handleSelectedImage}
                deleteImage={deleteImage}
                setDeleteImage={setDeleteImage}
                setOpenWebCam={setOpenWebCam}
                setOpenUploadImg={setOpenUploadImg}
              />
            </div>
          </div>
        }
        // component Inplant
        buttonSaveImplant={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "PerioperativeNursing",
                action: "put_nurse_implant",
              });
            }}
            // data
            paramKey={`implantData_save`}
            buttonLoadCheck={props.buttonLoadCheck?.[`implantData_save`]}
            // config
            color="green"
            size="small"
            style={{
              display:
                props.PerioperativeNursingSequence?.implantData?.allowed_actions?.includes(
                  ACTION.SAVE
                )
                  ? "none"
                  : "",
            }}
            title="Save"
          />
        }
        // component Tube
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Tube", ACTION.SAVE)}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Tube", ACTION.CONFIRM)}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Tube", ACTION.UNCONFIRM)}
          />
        }
        // component Drain
        buttonSaveDrain={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY_Drain}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Drain", ACTION.SAVE)}
          />
        }
        buttonConfirmDrain={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY_Drain}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Drain", ACTION.CONFIRM)}
          />
        }
        buttonUnconfirmDrain={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY_Drain}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Drain", ACTION.UNCONFIRM)}
          />
        }
        // component Dressing
        buttonSaveDressing={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY_Dressing}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Dressing", ACTION.SAVE)}
          />
        }
        buttonConfirmDressing={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY_Dressing}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Dressing", ACTION.CONFIRM)}
          />
        }
        buttonUnconfirmDressing={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY_Dressing}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Dressing", ACTION.UNCONFIRM)}
          />
        }
      />
      {/* Webcam */}
      <WebcamComponent
        open={openWebcam}
        onClose={() => setOpenWebCam(false)}
        setNewImage={(imgSrc: any) => {
          saveImg(imgSrc);
        }}
      />

      {/* EditImage */}
      <ModalEditImage
        image={selectedImage}
        openEditModal={openModalEditImage}
        onCloseModal={() => setOpenModalEditImage(false)}
        cancelImage={() => {
          setOpenModalEditImage(false);
          setOpenImageDetail(false);
        }}
        editedImage={(dataURI: string) => handleEditedImage(dataURI)}
      />

      {/* Upload */}
      <Modal
        open={openUploadImg}
        size="small"
        onClose={() => {
          setOpenUploadImg(false);
          setDetailImage("");
        }}
      >
        <div style={{ padding: "15px" }}>
          <div>
            <div>
              <label style={{ fontWeight: "bold" }}>คำอธิบายภาพ</label>
            </div>
            <div style={{ margin: "15px 0px" }}>
              <TextArea
                rows={3}
                style={{
                  width: "100%",
                  resize: "none",
                  padding: "10px",
                }}
                value={detailImage}
                onChange={(e: any) => setDetailImage(e.target.value)}
              ></TextArea>
            </div>
          </div>
          <div>
            <Button
              color="blue"
              onClick={() => {
                fileUploaderRef.current.click();
              }}
            >
              Choose file
            </Button>
            <input
              type="file"
              id="file"
              ref={fileUploaderRef}
              style={{ display: "none" }}
              onChange={readChooseFile}
              accept="image/*"
            />
          </div>
          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Modal.Description style={{ textAlign: "center" }}>
              <img
                src={uploadImage ? uploadImage : IMAGE.imageBgUpload}
                style={{ maxWidth: 640, maxHeight: 480 }}
                onClick={() => {
                  fileUploaderRef.current.click();
                }}
              />
            </Modal.Description>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              color="green"
              icon
              disabled={!uploadImage}
              labelPosition="left"
              onClick={() => saveImg("")}
            >
              <Icon className="cloud upload"></Icon>
              Upload
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const ImgDetail = (props: any) => {
  return (
    <Grid divided>
      <Grid.Column width={11}>
        <Grid columns={3}>
          {props.uploadImg?.image.map((item: any, index: any) => {
            return (
              <Grid.Column style={{ padding: "10px", position: "relative" }}>
                <img
                  onClick={() => props.handleSelectedImage(item)}
                  onMouseEnter={() => props.setSelectImage(index)}
                  onMouseLeave={() => props.setSelectImage(undefined)}
                  src={item.image}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <Label
                  pointing
                  style={{
                    display: props.selectImage === index ? "block" : "none",
                    position: "absolute",
                    zIndex: 100,
                    backgroundColor: "white",
                  }}
                >
                  รายละเอียด: {item.detail}
                </Label>
              </Grid.Column>
            );
          })}
        </Grid>
        <div style={{ paddingTop: "20px" }}>
          <Button color="blue" onClick={() => props.setOpenWebCam(true)}>
            Take Photo
          </Button>
          <Button color="blue" onClick={() => props.setOpenUploadImg(true)}>
            Upload
          </Button>
        </div>
      </Grid.Column>
      <Grid.Column width={5}>
        <ModConfirm
          titleColor="red"
          openModal={props.deleteImage}
          content={<>ต้องการลบรูปภาพ ใช่หรือไม่</>}
          onApprove={() => props.handleUpdateImage(false)}
          onDeny={() => props.setDeleteImage(false)}
        />
        {props.openImageDetail && (
          <div>
            <div>
              <img
                src={props.editedImage?.image}
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
            <div>
              <label style={{ fontWeight: "bold" }}>รายละเอียด</label>
            </div>
            <div style={{ marginTop: "10px 0px" }}>
              <TextArea
                rows={3}
                style={{
                  width: "100%",
                  resize: "none",
                  padding: "10px",
                }}
                value={props.editedImage?.detail}
                onChange={(e: any) =>
                  props.handleEditDescription(e.target.value)
                }
              ></TextArea>
            </div>
            <div>
              <Button
                color="green"
                onClick={() => props.handleUpdateImage(true)}
              >
                Update
              </Button>
              <Button
                style={{ margin: "0px 10px" }}
                color="red"
                onClick={() => props.setDeleteImage(true)}
              >
                Remove
              </Button>
            </div>
          </div>
        )}
      </Grid.Column>
    </Grid>
  );
};
export default React.memo(CardByEquipment);
