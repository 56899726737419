import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import SubSystemicReviewItem from "./SubSystemicReviewItem";
import _, { isEmpty } from "lodash";

import {
  Button,
  Form,
  Label,
  Segment,
  TextArea,
  Radio,
  Input,
  Icon,
} from "semantic-ui-react";

const style = {
  paddingTop: { paddingTop: "20px" },
  segmentStyle: { paddingTop: "20px", backgroundColor: "#e8e8e8" },
};

const defaultData = {
  items: [],
};

const SubSystemicReview = React.forwardRef((props, ref) => {
  const [systemicReviewData, setSystemicReviewData] = useState(defaultData);

  const { titleCard, isReadOnly } = props;

  const focusRef = useRef(null);
  const subContentRef = useRef([]);

  const requestFocus = () => {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        focusRef.current.scrollIntoView({ behaviour: "smooth" });
      }, 1500);
    });
  };

  React.useImperativeHandle(ref, () => ({
    getSaveData: () => {
      let updatedChoices = subContentRef.current.map((item) =>
        item.getChoiceData()
      );
      _.map(systemicReviewData.items, (item, index) => {
        _.map(item.organ_choice_items, (subItem, subIndex) => {
          let tmp_choice_id = subItem.choice_id;
          if (tmp_choice_id) {
            let result = _.find(
              updatedChoices,
              (element) => _.get(element, "choice_id") === tmp_choice_id
            );
            systemicReviewData.items[index].organ_choice_items[subIndex] =
              result;
          }
        });
      });

      return systemicReviewData;
    },
    setData: (data) => {
      setSystemicReviewData(data);
      // requestFocus();
    },
  }));

  return (
    <Segment raised secondary>
      <div ref={focusRef} />
      <Label color="grey" ribbon size="big">
        {titleCard}
      </Label>

      {systemicReviewData.items.map((data, index) => {
        const content = (
          <Segment style={style.segmentStyle} key={index}>
            <Form key={data.id}>
              <Form.Group inline>
                <Form.Field width={16}>
                  <Label size="big">{data.organ}</Label>
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={1}>
                  <Label>N/A</Label>
                </Form.Field>
                <Form.Field width={1}>
                  <Label>มี</Label>
                </Form.Field>
                <Form.Field width={1}>
                  <Label>ไม่มี</Label>
                </Form.Field>
              </Form.Group>
              {data.organ_choice_items.map((choice, subIndex) => {
                const subContent = (
                  <SubSystemicReviewItem
                    key={subIndex}
                    ref={(el) =>
                      (subContentRef.current[choice.choice_id - 1] = el)
                    }
                    choice={choice}
                  />
                );
                return subContent;
              })}
            </Form>
          </Segment>
        );

        return content;
      })}
      <Form.Group inline>
        <Form.Field width={16}>
          <Button
            color="green"
            type="button"
            onClick={props.onSaveAllCallback}
            loading={props.loading}
          >
            {props.saveText ? <Icon className="check"></Icon> : "บันทึก"}
          </Button>
        </Form.Field>
      </Form.Group>
    </Segment>
  );
});

SubSystemicReview.defaultProps = {
  titleCard: "Systemic Review",

  onSaveAllCallback: () => {},

  isReadOnly: false,
};

SubSystemicReview.propTypes = {
  onSaveAllCallback: PropTypes.func,

  isReadOnly: PropTypes.bool,
};

export default React.memo(SubSystemicReview);
