import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Input,
  Label,
  Message,
  Radio,
  Grid,
  Icon
} from "semantic-ui-react";
import { useFormatDropdown } from "react-lib/utils/hooksUtils";
import CardLayout from "../common/CardLayout";
import DateTextBox from "../common/DateTextBox";
import ErrorMessage from "../common/ErrorMessage";
import RightLabel from "../common/RightLabel";
import TimeTextBox from "../common/TimeTextBox";
import SearchBox from "../common/SearchBox";
import ModConfirm from "../common/cnmi/ModConfirm";
import PureReactTable, {
  formatPrice
} from "../../component-js/common/PureReactTable";
import SubICDDetail from "../DPO/SubICDDetail";
import TimeFreeTextBox24 from "../common/TimeFreeTextBox24";
import { formatDateToStringBe } from "react-lib/utils/dateUtils";

const style = {
  hederFont: { color: "white" },
  headerBackground: {
    backgroundColor: "darkblue",
    padding: "10px",
    marginBottom: "20px"
  },
  noMargin: { margin: "0px" },
  marginTop: { marginTop: "20px" },
  gridCustom: { height: 150 },
  fullWidth: { width: "100%" }
};
const columns = [
  {
    Header: "Service Code",
    accessor: "code",
    width: 150
  },
  {
    Header: "รายการค่าใช้จ่ายประเมิน",
    accessor: "name"
  },
  {
    Header: "ราคา/หน่วย",
    accessor: "price_unit",
    width: 180,
    Cell: (row:any) => formatPrice(row.value)
  },
  {
    Header: "จำนวน",
    accessor: "quantity",
    width: 150,
    Cell: (row:any) => formatPrice(row.value)
  },
  {
    Header: "ราคา",
    accessor: "price_total",
    width: 180,
    Cell: (row:any) => formatPrice(row.value)
  },
  {
    Header: "Claimable",
    accessor: "price_claimable",
    width: 180,
    Cell: (row:any) => formatPrice(row.value)
  },
  {
    Header: "NonClaimable",
    accessor: "price_non_claimable",
    width: 180,
    Cell: (row:any) => formatPrice(row.value)
  }
];
const admitStatus = {
  REQUESTED: "REQUESTED",
  PROCESSED: "PROCESSED",
  CANCELED: "CANCELED",
  FINISHED: "FINISHED"
};
const admitAction = {
  REQUEST: "REQUEST",
  EDIT_REQUEST: "EDIT_REQUEST",
  CANCEL_REQUEST: "CANCEL_REQUEST",
  PROCESS: "PROCESS",
  EDIT_PROCESS: "EDIT_PROCESS",
  CANCEL_PROCESS: "CANCEL_PROCESS"
};
const default_diagnosis = {
  medical_description: "",
  icd_term: "",
  icd_code: ""
};
const today = moment();
const tomorrow = today.clone().add(1, "days");
// const beFormat = "DD/MM/YYYY";
const AdmitDateTimeCaseComponent = forwardRef((props, ref) => {
  const [minAdmitDate, setMinAdmitDate] = useState(formatDateToStringBe(today));
  const [maxAdmitDate, setMaxAdmitDate] = useState(formatDateToStringBe(tomorrow));
  const [isAppointment, setIsAppointment] = useState(false);
  const [admitDate, setAdmitDate] = useState(formatDateToStringBe(today));
  const [admitTime, setAdmitTime] = useState("00:00");
  const [admitType, setAdmitType] = useState("");
  const [patientCase, setPatientCase] = useState("");
  useImperativeHandle(ref, () => ({
    getData: () => {
      return {
        isAppointment: isAppointment,
        admitDate: admitDate,
        admitTime: admitTime,
        admitType: admitType,
        patientCase: patientCase
      };
    }
  }));
  useEffect(
    () => {
      setIsAppointment(props.isAppointment);
    },
    [props.isAppointment]
  );
  useEffect(
    () => {
      setAdmitDate(props.admitDate);
    },
    [props.admitDate]
  );
  useEffect(
    () => {
      setAdmitTime(props.admitTime);
    },
    [props.admitTime]
  );
  useEffect(
    () => {
      setAdmitType(props.admitType);
    },
    [props.admitType]
  );
  useEffect(
    () => {
      setPatientCase(props.patientCase);
    },
    [props.patientCase]
  );
  const handleCheckAppointment = (e:any, data:any) => {
    setIsAppointment(data.checked);
    if (data.checked) {
      props.patientCaseList.forEach(function iterator(item:any) {
        if (item.code === "SCHEDULE") {
          setPatientCase(item.id);
          return;
        }
      });
      setAdmitDate(formatDateToStringBe(tomorrow));
      setMinAdmitDate(formatDateToStringBe(tomorrow));
      setMaxAdmitDate("");
    } else {
      setPatientCase("");
      setAdmitDate(formatDateToStringBe(today));
      setMinAdmitDate(formatDateToStringBe(today));
      setMaxAdmitDate(formatDateToStringBe(tomorrow))
    }
  };
  const handleAdmitDate = (date:any) => {
    setAdmitDate(date);
  };
  const handleAdmitTime = (time:any) => {
    setAdmitTime(time);
  };
  const handleAdmitType = (e:any, data:any) => {
    setAdmitType(data.value);
  };
  const handlePatientCase = (e:any, data:any) => {
    setPatientCase(data.value);
  };

  return (
    <Form>
      <Form.Group inline>
        <Form.Field width={2} required>
          <RightLabel>วัน/เวลา Admit</RightLabel>
        </Form.Field>
        <Form.Field width={2}>
          <Checkbox
            label="นัดหมาย"
            checked={isAppointment}
            disabled={props.readOnly}
            onChange={handleCheckAppointment}
          />
        </Form.Field>
        <Form.Field width={7}>
          <DateTextBox
            disabled={props.readOnly}
            value={admitDate}
            minDate={minAdmitDate}
            maxDate={maxAdmitDate}
            onChange={handleAdmitDate}
          />
        </Form.Field>
        <Form.Field width={5}>
          {/* <TimeTextBox
            style={style.fullWidth}
            disabled={props.readOnly}
            value={admitTime}
            interval={10}
            onTimeChange={handleAdmitTime}
          /> */}
          <TimeFreeTextBox24
            style={{
              display: "flex",
              margin: "0 15px",
              width: "35%",
            }}
            className="time-custom-react-picker padding-input"
            value={admitTime}
            onChange={handleAdmitTime}
            autoFocus={false}
            checkTimeOverflow
          />
        </Form.Field>
      </Form.Group>

      <Form.Group inline>
        <Form.Field width={2} required>
          <RightLabel>ประเภทการรับ Admit</RightLabel>
        </Form.Field>
        <Form.Field width={6}>
          <Dropdown
            className="fluidDropdown"
            disabled={props.readOnly}
            selection
            value={admitType}
            options={props.admitTypeList}
            onChange={handleAdmitType}
          />
        </Form.Field>
        <Form.Field width={2} required>
          <RightLabel>ประเภทผู้ป่วย</RightLabel>
        </Form.Field>
        <Form.Field width={6}>
          <Dropdown
            className="fluidDropdown"
            disabled={props.readOnly}
            selection
            value={patientCase}
            options={props.patientCaseList}
            onChange={handlePatientCase}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
});
const WardTypeStayComponent = forwardRef((props, ref) => {
  const [wardType, setWardType] = useState("");
  const [wardTypeUnit, setWardTypeUnit] = useState("D");
  const [dayOfStay, setDayOfStay] = useState("");
  const [hourOfStay, setHourOfStay] = useState("");
  useImperativeHandle(ref, () => ({
    getData: () => {
      return {
        wardType: wardType,
        dayOfStay: dayOfStay,
        hourOfStay: hourOfStay
      };
    }
  }));
  useEffect(
    () => {
      setWardType(props.wardType);
    },
    [props.wardType]
  );
  useEffect(() => {
    props.wardTypeList.forEach((item: any) => {
      if (item["id"] === wardType) {
        setWardTypeUnit(item["length_of_stay_unit"]);
        setDayOfStay(item["length_of_stay_unit"] === "D" ? "1" : "");
        setHourOfStay(item["length_of_stay_unit"] === "D" ? "" : "1");
        return;
      }
    });
  }, [wardType]);
  useEffect(
    () => {
      setDayOfStay(props.dayOfStay);
    },
    [props.dayOfStay]
  );
  useEffect(
    () => {
      setHourOfStay(props.hourOfStay);
    },
    [props.hourOfStay]
  );
  const handleWardType = (_e:any, data:any) => {
    setWardType(data.value);
  };
  const handleDayOfStay = (e:any, data:any) => {
    setDayOfStay(data.value);
    setHourOfStay("");
  };
  const handleHourOfStay = (e:any, data:any) => {
    setDayOfStay("");
    setHourOfStay(data.value);
  };
  return (
    <Form.Group inline>
      <Form.Field width={2} required>
        <RightLabel>ประเภทหอผู้ป่วย</RightLabel>
      </Form.Field>
      <Form.Field width={9}>
        <Dropdown
          className="fluidDropdown"
          selection
          value={wardType}
          options={props.wardTypeList}
          onChange={handleWardType}
        />
      </Form.Field>
      <Form.Field width={2} required>
        <RightLabel>Length of Stay</RightLabel>
      </Form.Field>
      {wardTypeUnit === "D" ? (
        <Form.Field width={3}>
          <Input
            type="number"
            disabled={props.readOnly}
            value={dayOfStay}
            onChange={handleDayOfStay}
          />
          <RightLabel>days</RightLabel>
        </Form.Field>
      ) : (
        <Form.Field width={3}>
          <Input
            type="number"
            disabled={props.readOnly}
            value={hourOfStay}
            onChange={handleHourOfStay}
          />
          <RightLabel>hours</RightLabel>
        </Form.Field>
      )}
    </Form.Group>
  );
});
const TextInputComponent = forwardRef((props, ref) => {
  const [value, setValue] = useState("");
  useImperativeHandle(ref, () => ({
    getData: () => {
      return value;
    }
  }));
  useEffect(
    () => {
      setValue(props.value);
    },
    [props.value]
  );
  const handleValue = (e:any, data:any) => {
    setValue(data.value);
  };
  return <Input disabled={props.readOnly} value={value} onChange={handleValue} />;
});
const DropdownComponent = forwardRef((props, ref) => {
  const [value, setValue] = useState("");
  useImperativeHandle(ref, () => ({
    getData: () => {
      return value;
    }
  }));
  useEffect(
    () => {
      setValue(props.value);
    },
    [props.value]
  );
  const handleValue = (e:any, data:any) => {
    setValue(data.value);
  };

  // this do to remove active: true in data that make all field is selected
  const newOptions = props.optionList?.map((option:any) => {
    let copyOption = Object.assign({}, option)
    delete copyOption["active"]
    return {...copyOption}
  }) || []

  return (
    <Dropdown
      className="fluidDropdown"
      disabled={props.readOnly}
      selection
      value={value}
      options={newOptions}
      onChange={handleValue}
    />
  );
});
const DoctorComponent = forwardRef((props, ref) => {
  const [primaryDoctorNameCode, setPrimaryDoctorNameCode] = useState("");
  const [primaryDoctor, setPrimaryDoctor] = useState("");
  useImperativeHandle(ref, () => ({
    getData: () => {
      return primaryDoctor;
    }
  }));
  useEffect(
    () => {
      setPrimaryDoctorNameCode(props.primaryDoctorNameCode);
    },
    [props.primaryDoctorNameCode]
  );
  useEffect(
    () => {
      setPrimaryDoctor(props.primaryDoctor);
    },
    [props.primaryDoctor]
  );
  const handlePrimaryDoctor = ({ item, id }:any) => {
    setPrimaryDoctorNameCode(item["name_code"]);
    setPrimaryDoctor(id);
  };
  return (
    <Form.Group inline>
      <Form.Field width={2} required>
        <RightLabel>แพทย์เจ้าของไข้</RightLabel>
      </Form.Field>
      <Form.Field width={9}>
        <SearchBox
          disabled={props.readOnly}
          textField="name_code"
          defaultText={primaryDoctorNameCode}
          defaultId={primaryDoctor}
          onGetSearchOptions={props.controller.getDropdownSearchDoctorList}
          onSelectOption={handlePrimaryDoctor}
        />
      </Form.Field>
      <Form.Field width={2}>
        <RightLabel>แพทย์ผู้สั่ง Admit</RightLabel>
      </Form.Field>
      <Form.Field width={3}>
        <Label>{props.orderByName ?? props.DJANGO.user?.full_name}</Label>
      </Form.Field>
    </Form.Group>
  );
});
const FibrinolyticIcuComponent = forwardRef((props, ref) => {
  const [fibrinolytic, setFibrinolytic] = useState("");
  const [requestIcu, setRequestIcu] = useState(false);
  useImperativeHandle(ref, () => ({
    getData: () => {
      return { fibrinolytic: fibrinolytic, requestIcu: requestIcu };
    }
  }));
  useEffect(
    () => {
      setFibrinolytic(props.fibrinolytic);
    },
    [props.fibrinolytic]
  );
  useEffect(
    () => {
      setRequestIcu(props.requestIcu);
    },
    [props.requestIcu]
  );
  const generateFibrinolyticChoice = () => {
    const result: any[] = [];
    let index = 1;
    props.fibrinolyticChoice.forEach(function iterator(item:any) {
      result.push(
        <Form.Field key={index}>
          <Radio
            disabled={props.readOnly}
            label={item.text}
            checked={fibrinolytic === item.id}
            value={item.id}
            name="FibrinolyticGroup"
            style={{ minWidth: "max-content" }}
            onChange={handleFibrinolytic}
          />
        </Form.Field>
      );
      index += 1;
    });
    return result;
  };
  const handleFibrinolytic = (e:any, data:any) => {
    setFibrinolytic(data.value);
  };
  const handleCheckRequestIcu = (e:any, data:any) => {
    setRequestIcu(data.checked);
  };
  return (
    <Form.Group inline>
      <Form.Field width={2} required>
        <RightLabel>ยาละลายลิ่มเลือด</RightLabel>
      </Form.Field>
      <Form.Field width={9}>{generateFibrinolyticChoice()}</Form.Field>
      <Form.Field width={2} required>
        <RightLabel>Request ICU</RightLabel>
      </Form.Field>
      <Form.Field width={3}>
        <Checkbox
          className="toggle"
          disabled={props.readOnly}
          checked={requestIcu}
          onChange={handleCheckRequestIcu}
        />
      </Form.Field>
    </Form.Group>
  );
});
type CardAdmitOrderProps = {
  controller?: object,
  subICDController?: object,
  hideCallback?: (...args: any[]) => any,
  onAdmitSaved?: (...args: any[]) => any,
  onAdmitCancel?: (...args: any[]) => any,
  DJANGO?: object,
  PATIENT_DATA?: object,
  orderId?: number,
  showHeader?: boolean,
  allowClear?: boolean,
  hideSaveButton?: boolean,
  hideCancelButton?: boolean,
  closeable?: boolean,
  toggleable?: boolean,
  hideBottom?: boolean,
  statusLabelStyle?: object,
  verbalOrderSave?: boolean,
  orderByName?: string;
  labelFitContent?: boolean,
  onSaveVerbalOrder?:  (...args: any) => any,
};
const CardAdmitOrder: React.SFC<CardAdmitOrderProps> = props => {
  const isMounted = useRef(true);
  const admitDateTimeCaseRef = useRef();
  const wardTypeStayRef = useRef();
  const admitReasonRef = useRef();
  const doctorRef = useRef();
  const fibrinolyticIcuRef = useRef();
  const remarkRef = useRef();
  const reservationNameRef = useRef();
  const contactNumberRef = useRef();
  const contactEmailRef = useRef();
  const firstRoomTypeRef = useRef();
  const secondRoomTypeRef = useRef();
  const thirdRoomTypeRef = useRef();
  const cancelReasonRef = useRef();
  const [errors, setErrors] = useState([]);
  const [modErrors, setModErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [statusName, setStatusName] = useState("");
  const [statusLabel, setStatusLabel] = useState("");
  const [code, setCode] = useState("");
  const [orderByDivisionName, setOrderByDivisionName] = useState("");
  const [orderByName, setOrderByName] = useState<string | null>("");
  const [readOnly, setReadOnly] = useState(false);
  // admit order data
  const [diagnosis, setDiagnosis] = useState(default_diagnosis);
  const [isAppointment, setIsAppointment] = useState(false);
  const [admitDate, setAdmitDate] = useState(formatDateToStringBe(today));
  const [admitTime, setAdmitTime] = useState("00:00");
  const [admitType, setAdmitType] = useState("");
  const [patientCase, setPatientCase] = useState("");
  const [wardType, setWardType] = useState("");
  const [dayOfStay, setDayOfStay] = useState("");
  const [hourOfStay, setHourOfStay] = useState("");
  const [admitReason, setAdmitReason] = useState("");
  const [primaryDoctorNameCode, setPrimaryDoctorNameCode] = useState("");
  const [primaryDoctorName, setPrimaryDoctorName] = useState("");
  const [primaryDoctor, setPrimaryDoctor] = useState("");
  const [fibrinolytic, setFibrinolytic] = useState("");
  const [requestIcu, setRequestIcu] = useState(false);
  const [remark, setRemark] = useState("");
  // process data
  const [reservationName, setReservationName] = useState("");
  const [firstRoomType, setFirstRoomType] = useState(null);
  const [secondRoomType, setSecondRoomType] = useState(null);
  const [thirdRoomType, setThirdRoomType] = useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [estimatePrice, setEstimatePrice] = useState("");
  const [estimateList, setEstimateList] = useState([]);
  // choice
  const [admitTypeChoice, setAdmitTypeChoice] = useState({});
  const [patientCaseChoice, setPatientCaseChoice] = useState({});
  const [wardTypeChoice, setWardTypeChoice] = useState({});
  const [fibrinolyticChoice, setFibrinolyticChoice] = useState([]);
  // estimate
  const [roomType, setRoomType] = useState({});
  // modal
  const [openModConfirm, setOpenModConfirm] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  // permission
  const [actionRequest, setActionRequest] = useState(false);
  const [actionEditRequest, setActionEditRequest] = useState(false);
  const [actionCancelRequest, setActionCancelRequest] = useState(false);
  const [actionProcess, setActionProcess] = useState(false);
  const [actionEditProcess, setActionEditProcess] = useState(false);
  const [actionCancelProcess, setActionCancelProcess] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [completed, setCompleted] = useState(false);

  const { titleCard, DJANGO, PATIENT_DATA } = props;
  useEffect(() => {
    getUserPermission();
    getAdmitTypeChoice();
    getPatientCaseChoice();
    getWardTypeChoice();
    getFibrinolyticChoice();
    getRoomTypeChoice();
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(
    () => {
      if (props.PATIENT_DATA?.EMR && props.PATIENT_DATA?.EMR?.emr_id) {
        getDiagnosis(props.PATIENT_DATA.EMR.emr_id);
      }
    },
    [props.PATIENT_DATA]
  );
  useEffect(
    () => {
      refresh();
    },
    [props.orderId]
  );

  useEffect(
    () => {
      if (props.verbalOrderSave) {
        onSaveVerbalOrder();
      }
    },
    [props.verbalOrderSave]
  );


  // -------------------------------------------------- permission
  const getUserPermission = async () => {
    const [data, error] = await props.controller.getUserPermission();
    if (isMounted.current) {
      if (error) {
        toast.error(error);
      } else {
        setActionRequest(data.action_ADM_AdmitOrder_REQUEST);
        setActionEditRequest(data.action_ADM_AdmitOrder_EDIT_REQUEST);
        setActionCancelRequest(data.action_ADM_AdmitOrder_CANCEL_REQUEST);
        setActionProcess(data.action_ADM_AdmitOrder_PROCESS);
        setActionEditProcess(data.action_ADM_AdmitOrder_EDIT_PROCESS);
        setActionCancelProcess(data.action_ADM_AdmitOrder_CANCEL_PROCESS);

        if (props.orderId && data.action_ADM_AdmitOrder_PROCESS) {
          estimatedCost();
        }
      }
    }
  };

  // -------------------------------------------------- diagnosis
  const getDiagnosis = async (emrId:any) => {
    const [data, error] = await props.controller.getDiagnosis(emrId);
    if (isMounted.current) {
      if (error) {
        toast.error(error);
      } else {
        let principals = data.principal_diagnosis;
        if (principals.length > 0) {
          let primary = principals[0];
          const icd10 = {
            icd10: primary.icd10,
            type: primary.type,
            medical_description: primary.medical_description,
            icd_term: primary.icd_term,
            icd_code: primary.icd_code
          };
          setDiagnosis(icd10);
        }
      }
    }
  };
  const handleDiagnosis = (data:any) => {
    console.log("data",data)
    if (data.icd10_id === "") {
      setDiagnosis(default_diagnosis);
    } else {
      const icd10 = {
        icd10: data["icd10_id"],
        type: 1,
        medical_description: data["medterm"],
        icd_term: data["icdterm"],
        icd_code: data["icdcode"],
      };
      setDiagnosis(icd10);
    }
  };
  const handleDiagnosisErased = () => {
    setDiagnosis(default_diagnosis);
  };
  // -------------------------------------------------- admit type
  const admitTypeList = useFormatDropdown(
    admitTypeChoice ? admitTypeChoice : [],
    "text",
    "id"
  );
  const getAdmitTypeChoice = async () => {
    const [data, error] = await props.controller.getChoices({
      model: "ADM.AdmitOrder",
      field: "admit_type",
      nameAsId: true
    });
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      }
      setAdmitTypeChoice(data);
    }
  };
  // -------------------------------------------------- patient case
  const patientCaseList = useFormatDropdown(
    patientCaseChoice ? patientCaseChoice : [],
    "name",
    "id"
  );
  const getPatientCaseChoice = async () => {
    const [data, error] = await props.controller.getPatientCase();
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      }
      setPatientCaseChoice(data);
    }
  };
  // -------------------------------------------------- ward type
  const wardTypeList = useFormatDropdown(
    wardTypeChoice ? wardTypeChoice : [],
    "name",
    "id"
  );
  const getWardTypeChoice = async () => {
    const [data, error] = await props.controller.getWardType();
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      }
      setWardTypeChoice(data);
    }
  };
  // -------------------------------------------------- ยาละลายลิ่มเลือด fibrinolytic
  const getFibrinolyticChoice = async () => {
    const [data, error] = await props.controller.getChoices({
      model: "ADM.AdmitOrder",
      field: "fibrinolytic_drug_used",
      nameAsId: true
    });
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      }
      setFibrinolyticChoice(data);
    }
  };
  // -------------------------------------------------- room type
  const roomTypeList = useFormatDropdown(roomType ? roomType : [], "name", "id");
  const getRoomTypeChoice = async () => {
    const [data, error] = await props.controller.getRoomType();
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      }
      setRoomType(data);
    }
  };
  // -------------------------------------------------- fetch
  const refresh = async () => {
    if (props.orderId) {
      const [data, error] = await props.controller.getAdmitOrderById(props.orderId);
      if (isMounted.current) {
        if (error) {
          setErrors(error);
        }
        setAdmitData(data);
        if (data.status_name === admitStatus.CANCELED) {
          setEstimateList([]);
        } else if (actionProcess) {
          estimatedCost();
        }
      }
    } else {
      setAdmitData(null);
    }
  };
  const estimatedCost = async () => {
    const [data, error] = await props.controller.estimatedAdmitCost(props.orderId);
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        setErrors("");
      }
      let count = 1;
      let grid: any[] = [];
      if (data["items"]) {
        data["items"].forEach(function iterator(item:any) {
          grid.push({ ...item, id: count });
          count += 1;
        });
        setEstimatePrice(data["estimate"]["price_total"]);
        setEstimateList(grid);
      }
    }
  };
  const setAdmitData = (data:any) => {
    if (!data) {
      props.orderId = null;
      setAppointmentId(null);
      setStatusName("");
      setStatusLabel("");
      setCode("");
      // admit order data
      setDiagnosis(default_diagnosis);
      // admitDateTimeCaseRef
      setIsAppointment(false);
      setAdmitDate(formatDateToStringBe(today));
      setAdmitTime("00:00");
      setAdmitType("");
      setPatientCase("");
      // wardTypeStayRef
      setWardType("");
      setDayOfStay("");
      setHourOfStay("");
      // AdmitReasonRef
      setAdmitReason("");
      // DoctorRef
      setPrimaryDoctorNameCode("");
      setPrimaryDoctorName("")
      setPrimaryDoctor("");
      setOrderByDivisionName("");
      setOrderByName(null);
      // FibrinolyticIcuRef
      setFibrinolytic("A");
      setRequestIcu(false);
      // RemarkRef
      setRemark("");
      // process data
      // ReservationNameRef
      setReservationName("");
      // FirstRoomTypeRef
      setFirstRoomType(null);
      // SecondRoomTypeRef
      setSecondRoomType(null);
      // ThirdRoomTypeRef
      setThirdRoomType(null);
      // ContactNumberRef
      setContactNumber("");
      // ContactEmailRef
      setContactEmail("");
      setEstimatePrice("");
      setEstimateList([]);
    } else {
      setAppointmentId(data["appointment_id"]);
      setStatusName(data["status_name"]);
      setStatusLabel(data["status_label"]);
      setCode(data["code"]);
      const icd10 = {
        icd10: data["icd10"],
        type: 1,
        medical_description: data["diagnosis"],
        icd_term: data["icd10_detail"]["term"],
        icd_code: data["icd10_detail"]["code"]
      };
      setDiagnosis(icd10);
      // admitDateTimeCaseRef
      setIsAppointment(data["is_appointment"]);
      setAdmitDate(data["admit_date__date"]);
      setAdmitTime(data["admit_date__time"]);
      setAdmitType(data["admit_type"]);
      setPatientCase(data["patient_case"]);
      // wardTypeStayRef
      setWardType(data["ward_type"]);
      if (!!data["day_of_stay"]) {
        setDayOfStay(data["day_of_stay"]);
        setHourOfStay("");
      } else if (!!data["hour_of_stay"]) {
        setDayOfStay("");
        setHourOfStay(data["hour_of_stay"]);
      }
      // AdmitReasonRef
      setAdmitReason(data["admit_reason"]);
      // DoctorRef
      setPrimaryDoctorNameCode(data["primary_doctor_name_code"]);
      setPrimaryDoctorName(data["primary_doctor_name"])
      setOrderByDivisionName(data["order_by_division_name"]);
      setOrderByName(data["order_by_name"]);
      setPrimaryDoctor(data["primary_doctor"]);
      // FibrinolyticIcuRef
      setFibrinolytic(data["fibrinolytic_drug_used"]);
      setRequestIcu(data["request_icu"]);
      // RemarkRef
      setRemark(data["remark"]);
      // set process data
      // ReservationNameRef
      setReservationName(data["reservation_name"]);
      // FirstRoomTypeRef
      setFirstRoomType(data["first_room_type"]);
      // SecondRoomTypeRef
      setSecondRoomType(data["second_room_type"]);
      // ThirdRoomTypeRef
      setThirdRoomType(data["third_room_type"]);
      // ContactNumberRef
      setContactNumber(data["contact_number"]);
      // ContactEmailRef
      setContactEmail(data["contact_email"]);
    }

    handleReadOnly(data?.["status_name"]);
  };
  const handleReadOnly = (status:any) => {
    if (
      props.orderId &&
      ![admitStatus["REQUESTED"], admitStatus["PROCESSED"]].includes(status)
    ) {
      setReadOnly(true);
    }
    setReadOnly(false);
  };
  // -------------------------------------------------- clear
  const handleClear = () => {
    setAdmitData(null);
  };
  // -------------------------------------------------- action
  const handleAction = () => {
    setIsLoading(true);
    if (actionProcess && statusName === admitStatus.REQUESTED) {
      handleSave(admitAction.PROCESS);
    } else if (actionEditRequest && statusName === admitStatus.REQUESTED) {
      handleSave(admitAction.EDIT_REQUEST);
    } else if (actionEditProcess && statusName === admitStatus.PROCESSED) {
      handleSave(admitAction.EDIT_PROCESS);
    } else {
      handleSave(admitAction.REQUEST);
    }
  };
  const onSaveVerbalOrder = () => {
    const admitDateTimeCaseref = admitDateTimeCaseRef.current.getData();
    const wardTypeStayref = wardTypeStayRef.current.getData();
    const admitReasonref = admitReasonRef.current.getData();
    const doctorref = doctorRef.current.getData();
    const fibrinolyticIcuref = fibrinolyticIcuRef.current.getData();
    const remarkref = remarkRef.current.getData();
    let data = {
      action: "REQUEST",
      emr: PATIENT_DATA?.EMR?.emr_id,
      icd10: diagnosis["icd10"],
      diagnosis: diagnosis["medical_description"],
      is_appointment: admitDateTimeCaseref.isAppointment,
      admit_date:
        admitDateTimeCaseref.admitDate + "-" + admitDateTimeCaseref.admitTime,
      admit_type: admitDateTimeCaseref.admitType,
      patient_case: admitDateTimeCaseref.patientCase,
      ward_type: wardTypeStayref.wardType,
      day_of_stay: wardTypeStayref.dayOfStay ? wardTypeStayref.dayOfStay : 0,
      hour_of_stay: wardTypeStayref.hourOfStay ? wardTypeStayref.hourOfStay : 0,
      admit_reason: admitReasonref,
      primary_doctor: doctorref,
      fibrinolytic_drug_used: fibrinolyticIcuref.fibrinolytic,
      request_icu: fibrinolyticIcuref.requestIcu,
      remark: remarkref
    }
    console.log(data);
    props.onSaveVerbalOrder(data)
  };
  const handleSave = async action => {
    const admitDateTimeCaseref = admitDateTimeCaseRef.current.getData();
    const wardTypeStayref = wardTypeStayRef.current.getData();
    const admitReasonref = admitReasonRef.current.getData();
    const doctorref = doctorRef.current.getData();
    const fibrinolyticIcuref = fibrinolyticIcuRef.current.getData();
    const remarkref = remarkRef.current.getData();
    setLoadingSave(true);
    let params = {
      action: action,
      emr: PATIENT_DATA?.EMR?.emr_id,
      icd10: diagnosis["icd10"],
      diagnosis: diagnosis["medical_description"],
      is_appointment: admitDateTimeCaseref.isAppointment,
      admit_date:
        admitDateTimeCaseref.admitDate + "-" + admitDateTimeCaseref.admitTime,
      admit_type: admitDateTimeCaseref.admitType,
      patient_case: admitDateTimeCaseref.patientCase,
      ward_type: wardTypeStayref.wardType,
      day_of_stay: wardTypeStayref.dayOfStay ? wardTypeStayref.dayOfStay : 0,
      hour_of_stay: wardTypeStayref.hourOfStay ? wardTypeStayref.hourOfStay : 0,
      admit_reason: admitReasonref,
      primary_doctor: doctorref,
      fibrinolytic_drug_used: fibrinolyticIcuref.fibrinolytic,
      request_icu: fibrinolyticIcuref.requestIcu,
      remark: remarkref
    };
    if (actionProcess) {
      const reservationNameref = reservationNameRef.current.getData();
      const contactNumberref = contactNumberRef.current.getData();
      const contactEmailref = contactEmailRef.current.getData();
      const firstRoomTyperef = firstRoomTypeRef.current.getData();
      const secondRoomTyperef = secondRoomTypeRef.current.getData();
      const thirdRoomTyperef = thirdRoomTypeRef.current.getData();
      params = {
        ...params,
        reservation_name: reservationNameref,
        first_room_type: firstRoomTyperef,
        second_room_type: secondRoomTyperef,
        third_room_type: thirdRoomTyperef,
        contact_number: contactNumberref,
        contact_email: contactEmailref
      };
    }
    let errorsData = {}
    const requiredFieldSet = {
      "icd10": "Diagnosis",
      "admit_date": "วัน/เวลา Admit",
      "admit_type": "ประเภทการรับ Admit",
      "patient_case": "ประเภทผู้ป่วย",
      "ward_type": "ประเภทหอผู้ป่วย",
      "admit_reason": "เหตุผลในการ Admit",
      "primary_doctor": "แพทย์เจ้าของไข้",
      "fibrinolytic_drug_used": "ยาละลายลิ่มเลือด",
      "first_room_type": "ประเภทห้องลำดับ 1"
    }

    // check field value if exists and it value
    const requiredField = Object.keys(requiredFieldSet)
    Object.keys(params).forEach(item => {
      if(requiredField.includes(item) && !params[item]){
        errorsData[item] = "กรุณาระบุ \""+ requiredFieldSet[item] +"\""
      }
    })
    // only check length of stay
    if(!params["day_of_stay"] && !params["hour_of_stay"]){
      errorsData["LengthOfStay"] = "กรุณาระบุ \"Length of Stay\""
    }

    if(Object.keys(errorsData).length > 0){
      setErrors(errorsData)
      setIsLoading(false);
      setLoadingSave(false);
      return;
    }


    const [orderId, data, error] = await props.controller.saveAdmitOrder(
      props.orderId,
      params
    );
    if (isMounted.current) {
      if (error) {
        setErrors(error);
        setLoadingSave(false);
      } else {
        setErrors("");
        props.orderId = orderId;
        // toast.success("บันทึกข้อมูลสำเร็จ");
        refresh();
        props.onAdmitSaved();
        setLoadingSave(false);
        setCompleted(true);
        setTimeout(() => {
          setCompleted(false);
        }, 3000);
      }
      setIsLoading(false);
    }
  };
  const handleCancel = async () => {
    let cancelReason =  cancelReasonRef.current.getData()
    setIsLoading(true);
    let params = {
      cancel_reason:cancelReason
    };
    if (statusName === admitStatus["REQUESTED"]) {
      setEstimateList([]);
      params = {
        ...params,
        action: admitAction.CANCEL_REQUEST
      };

    } else if (statusName === admitStatus["PROCESSED"]) {
      params = {
        ...params,
        action: admitAction.CANCEL_PROCESS
      };
    }
    const [orderId, data, error] = await props.controller.saveAdmitOrder(
      props.orderId,
      params
    );
    if (isMounted.current) {
      if (error) {
        setModErrors(error);
      } else {
        setModErrors("");
        props.orderId = orderId;
        props.onAdmitCancel();
        toast.success("ยกเลิกสำเร็จ");
        setOpenModConfirm(false);
        refresh();
      }
      setIsLoading(false);
    }
  };
  // -------------------------------------------------- print
  const handlePrintReservation = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.printReservation(props.orderId);
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        setErrors("");
        toast.success("พิมพ์สำเร็จ");
      }
      setIsLoading(false);
    }
  };
  const handlePrintAppointment = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.printAppointment(appointmentId);
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        setErrors("");
        toast.success("พิมพ์สำเร็จ");
      }
      setIsLoading(false);
    }
  };
  // -------------------------------------------------- modal
  const handleOpenModConfirm = () => {
    setCancelReason("");
    setOpenModConfirm(true);
  };
  const handleCloseModConfirm = () => {
    setOpenModConfirm(false);
  };
  const handleCancelReason = (e:any, data:any) => {
    setCancelReason(data.value);
  };
  return (
    <CardLayout
      titleText={titleCard}
      headerColor="yellow"
      loading={isLoading}
      closeable={props.closeable}
      toggleable={props.toggleable}
      hideBottom={props.hideBottom}
      onClose={props.hideCallback}
      titleContent={
        <Form style={props.statusLabelStyle}>
          {statusName !== "" ? (
            <Form.Group inline>
              <Form.Field width={9}>
                <Label
                  className={
                    statusName === admitStatus["REQUESTED"] ? "red" : "yellow"
                  }
                >
                  {statusLabel}
                </Label>
              </Form.Field>
              <Form.Field width={7}>
                <Label>{code}</Label>
              </Form.Field>
            </Form.Group>
          ) : null}
        </Form>
      }
    >
      <React.Fragment>
        {props.showHeader ? (
          <Form style={style.headerBackground}>
            <Form.Group inline style={style.noMargin}>
              <Form.Field width={1}>
                <RightLabel style={style.hederFont}>ผู้ป่วย</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <Input disabled />
              </Form.Field>

              <Form.Field width={1}>
                <RightLabel style={style.hederFont}>HN</RightLabel>
              </Form.Field>
              <Form.Field width={2}>
                <Input disabled />
              </Form.Field>

              <Form.Field width={1}>
                <RightLabel style={style.hederFont}>เพศ</RightLabel>
              </Form.Field>
              <Form.Field width={2}>
                <Input disabled />
              </Form.Field>

              <Form.Field width={1}>
                <RightLabel style={style.hederFont}>วันเกิด</RightLabel>
              </Form.Field>
              <Form.Field width={2}>
                <Input disabled />
              </Form.Field>

              <Form.Field width={1}>
                <RightLabel style={style.hederFont}>สิทธิ์</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <Input disabled />
              </Form.Field>
            </Form.Group>
          </Form>
        ) : null}
      </React.Fragment>

      <Form>
        <Form.Group inline>
          <Form.Field width={2} required>
            <RightLabel>Diagnosis</RightLabel>
          </Form.Field>
          <Form.Field width={14}>
            <SubICDDetail
              controller={props.subICDController}
              marginTop={"0px"}
              marginBottom={"0px"}
              inputSize={"small"}
              disabled={readOnly}
              isShowDeleteButton={false}
              initialData={diagnosis}
              onSelected={handleDiagnosis}
              onErased={handleDiagnosisErased}
              card={props.card}
              labelFitContent={props.labelFitContent}
            />
          </Form.Field>
        </Form.Group>
      </Form>

      <AdmitDateTimeCaseComponent
        ref={admitDateTimeCaseRef}
        isAppointment={isAppointment}
        admitDate={admitDate}
        admitTime={admitTime}
        admitType={admitType}
        admitTypeList={admitTypeList}
        patientCase={patientCase}
        patientCaseList={patientCaseList}
        readOnly={readOnly}
      />

      <Form>
        <WardTypeStayComponent
          ref={wardTypeStayRef}
          wardType={wardType}
          wardTypeList={wardTypeList}
          dayOfStay={dayOfStay}
          hourOfStay={hourOfStay}
          readOnly={readOnly}
        />

        <Form.Group inline>
          <Form.Field width={2} required>
            <RightLabel>เหตุผลในการ Admit</RightLabel>
          </Form.Field>
          <Form.Field width={14}>
            <TextInputComponent
              ref={admitReasonRef}
              value={admitReason}
              readOnly={readOnly}
            />
          </Form.Field>
        </Form.Group>

        <DoctorComponent
          ref={doctorRef}
          controller={props.controller}
          primaryDoctorNameCode={primaryDoctorNameCode}
          primaryDoctor={primaryDoctor}
          orderDoctor={primaryDoctorName}
          readOnly={readOnly}
          DJANGO={props.DJANGO}
          orderByName={props.orderByName || orderByName}
        />

        <FibrinolyticIcuComponent
          ref={fibrinolyticIcuRef}
          fibrinolytic={fibrinolytic}
          fibrinolyticChoice={fibrinolyticChoice}
          requestIcu={requestIcu}
          readOnly={readOnly}
        />

        <Form.Group inline>
          <Form.Field width={2}>
            <RightLabel>หมายเหตุเพิ่มเติม</RightLabel>
          </Form.Field>
          <Form.Field width={14}>
            <TextInputComponent ref={remarkRef} value={remark} readOnly={readOnly} />
          </Form.Field>
        </Form.Group>
      </Form>

      {props.hideBottom ? null : <div>
        {actionProcess ? <Divider /> : null}

        {actionProcess ? (
          <Form>
            <Form.Group inline>
              <Form.Field width={2}>
                <RightLabel>ชื่อผู้จองห้อง</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <TextInputComponent
                  ref={reservationNameRef}
                  value={reservationName}
                  readOnly={readOnly}
                />
              </Form.Field>
              <Form.Field width={2} required>
                <RightLabel>ประเภทห้องลำดับ 1</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <DropdownComponent
                  ref={firstRoomTypeRef}
                  value={firstRoomType}
                  optionList={roomTypeList}
                  readOnly={readOnly}
                />
              </Form.Field>
              <Form.Field width={2}>
                <RightLabel>หน่วยที่ขอจอง</RightLabel>
              </Form.Field>
              <Form.Field width={2}>
                <Label>{orderByDivisionName}</Label>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field width={2}>
                <RightLabel>เบอร์โทรติดต่อ</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <TextInputComponent
                  ref={contactNumberRef}
                  value={contactNumber}
                  readOnly={readOnly}
                />
              </Form.Field>
              <Form.Field width={2}>
                <RightLabel>ประเภทห้องลำดับ 2</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <DropdownComponent
                  ref={secondRoomTypeRef}
                  value={secondRoomType}
                  optionList={roomTypeList}
                  readOnly={readOnly}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field width={2}>
                <RightLabel>E-mail</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <TextInputComponent
                  ref={contactEmailRef}
                  value={contactEmail}
                  readOnly={readOnly}
                />
              </Form.Field>
              <Form.Field width={2}>
                <RightLabel>ประเภทห้องลำดับ 3</RightLabel>
              </Form.Field>
              <Form.Field width={4}>
                <DropdownComponent
                  ref={thirdRoomTypeRef}
                  value={thirdRoomType}
                  optionList={roomTypeList}
                  readOnly={readOnly}
                />
              </Form.Field>
            </Form.Group>

            {!estimatePrice ? (
              <Message
                style={{ marginBottom: "8px" }}
                color="yellow"
                icon="warning sign"
                header="ประเภทห้องที่เลือกไม่มีราคาห้อง"
              />
            ) : null}
          </Form>
        ) : null}

        {actionProcess ? (
          <PureReactTable
            data={estimateList}
            style={style.gridCustom}
            columns={columns}
            showPagination={false}
            pageSize={10}
            manual
          />
        ) : null}

        <Form error={errors ? true : false}>
          <ErrorMessage error={errors} />
          <Form.Group inline className="rightAlign">
            <Form.Field style={style.marginTop}>
              {statusName === admitStatus.CANCELED &&
              actionRequest &&
              props.allowClear ? (
                <Button
                  color="teal"
                  className="right aligned"
                  type="button"
                  onClick={handleClear}
                >
                  ล้างหน้าจอ
                </Button>
              ) : null}

              {!(
                statusName === admitStatus.CANCELED ||
                readOnly ||
                props.hideSaveButton
              ) ? (
                <Button
                  color="green"
                  className="right aligned"
                  type="button"
                  onClick={handleAction}
                  loading={loadingSave}
                >
                  {completed ? <Icon name="check"></Icon> : "บันทึกรายการจอง"}
                </Button>
              ) : null}

              {appointmentId &&
              statusName !== admitStatus.FINISHED &&
              statusName !== admitStatus.CANCELED &&
              !actionRequest ? (
                <Button
                  color="blue"
                  className="right aligned"
                  type="button"
                  onClick={handlePrintAppointment}
                >
                  พิมพ์ใบนัด
                </Button>
              ) : null}

              {props.orderId &&
              statusName !== admitStatus.CANCELED &&
              actionProcess ? (
                <Button
                  color="blue"
                  className="right aligned"
                  type="button"
                  onClick={handlePrintReservation}
                >
                  พิมพ์ใบจอง
                </Button>
              ) : null}

              {actionCancelProcess && statusName === admitStatus.PROCESSED ? (
                <Button
                  color="red"
                  className="right aligned"
                  type="button"
                  onClick={handleOpenModConfirm}
                >
                  ยกเลิก Process
                </Button>
              ) : null}

              {(actionCancelRequest && statusName === admitStatus.REQUESTED) ||
              props.hideCancelButton ? (
                <Button
                  color="red"
                  className="right aligned"
                  type="button"
                  onClick={handleOpenModConfirm}
                >
                  ยกเลิก Request
                </Button>
              ) : null}
            </Form.Field>
          </Form.Group>
        </Form>
      </div>}

      <ModConfirm
        openModal={openModConfirm}
        titleName="ต้องการยกเลิกการจองหรือไม่"
        content={
          <Form>
            <ErrorMessage error={modErrors} />
            <Form.Field width={16}>เหตุผลในการยกเลิก</Form.Field>
            <Form.Field width={16}>
              <TextInputComponent
                ref={cancelReasonRef}
                value={cancelReason}
                readOnly={readOnly}
            />
            </Form.Field>
          </Form>
        }
        onApprove={handleCancel}
        onDeny={handleCloseModConfirm}
      />
    </CardLayout>
  );
};
CardAdmitOrder.defaultProps = {
  titleCard: "จองเตียง (ADMIT ORDER)",
  controller: null,
  subICDController: null,
  hideCallback: () => {},
  onAdmitSaved: () => {},
  onAdmitCancel: () => {},
  DJANGO: {},
  PATIENT_DATA: {},
  orderId: null,
  verbalOrderSave: false,
  showHeader: false,
  allowClear: true,
  hideSaveButton: false,
  hideCancelButton: false,
  closeable: true,
  toggleable: true,
  hideBottom: false,
  labelFitContent: false,
  statusLabelStyle: {},
  onSaveVerbalOrder: () => {},
};
export default React.memo(CardAdmitOrder);
