import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Segment,
  Icon,
  Dropdown,
  Input
} from 'semantic-ui-react'

const SubRepeaterPayMethodUX = (props: any) => {
    return(
      <div
        style={{ height: "77.5px", width: "100%" }}>
        <Segment>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              style={{cursor: "pointer"}}>
              
              <Icon
                className="close red"
                onClick={props.onClose}>
              </Icon>
            </div>
            <label
              style={{padding: "0 10px", fontWeight: "bold"}}>
              ประเภท
            </label>
            <Dropdown
              disabled={true}
              options={[ { key: 2, text: "เงินโอน", value: 2 }, { key: 3, text: "บัตร", value: 3 }, { key: 4, text: "เช็ค", value: 4 }, { key: 5, text: "คูปอง", value: 5 }, { key: 6, text: "กระเป๋าเงิน", value: 6 }, { key: 7, text: "ผ่อนชำระ", value: 7 }, ]}
              selection={true}
              value={props.type}>
            </Dropdown>
            <div
              style={{display:  props.type === 2 ? "flex" : "none", alignItems: "center"}}>
              
              <label
                style={{padding: "0 10px", fontWeight: "bold"}}>
                เลขที่อ้างอิง
              </label>
              <Input
                name="paymentNo"
                onChange={props.onChange}
                value={props.paymentNo || ""}>
              </Input>
            </div>
            <div
              style={{display:  props.type ===2 ? "flex" : "none", alignItems: "center"}}>
              
              <label
                style={{padding: "0 10px", fontWeight: "bold"}}>
                บัญชีรับ
              </label>
              <Dropdown
                name="paymentTarget"
                onChange={props.onChange}
                options={props.masterAccountOptions}
                selection={true}
                value={props.paymentTarget || ""}>
              </Dropdown>
            </div>
            <div
              style={{display:  props.type === 3 ? "flex" : "none", alignItems: "center"}}>
              
              <label
                style={{padding: "0 10px", fontWeight: "bold"}}>
                เลขบัตร
              </label>
              <Input
                className="payment_card_number-0"
                name="paymentNo.0"
                onChange={props.onChange}
                onKeyDown={props.handleKeyDownNumber}
                placeholder="xxxx"
                style={{width:"60px", marginRight: "10px"}}
                value={props.paymentNo?.[0] || ""}>
              </Input>
              <Input
                className="payment_card_number-1"
                name="paymentNo.1"
                onChange={props.onChange}
                onKeyDown={props.handleKeyDownNumber}
                placeholder="xxxx"
                style={{width:"60px", marginRight: "10px"}}
                value={props.paymentNo?.[1] || ""}>
              </Input>
              <Input
                className="payment_card_number-2"
                name="paymentNo.2"
                onChange={props.onChange}
                onKeyDown={props.handleKeyDownNumber}
                placeholder="xxxx"
                style={{width:"60px", marginRight: "10px"}}
                value={props.paymentNo?.[2] || ""}>
              </Input>
              <Input
                className="payment_card_number-3"
                name="paymentNo.3"
                onChange={props.onChange}
                onKeyDown={props.handleKeyDownNumber}
                placeholder="xxxx"
                style={{width:"60px", marginRight: "10px"}}
                value={props.paymentNo?.[3] || ""}>
              </Input>
            </div>
            <div
              style={{display:  props.type === 3 ? "flex" : "none",  alignItems: "center"}}>
              
              <label
                style={{padding: "0 10px", fontWeight: "bold"}}>
                เครื่องรูด
              </label>
              <Dropdown
                name="paymentTarget"
                onChange={props.onChange}
                options={props.masterEDCOptions}
                selection={true}
                value={props.paymentTarget || ""}>
              </Dropdown>
            </div>
            <div
              style={{display:   props.type ===4 ? "flex" : "none",  alignItems: "center", width: "100%"}}>
              
              <label
                style={{padding: "0 10px", fontWeight: "bold"}}>
                เลขเช็ค
              </label>
              <Input
                name="paymentNo"
                onChange={props.onChange}
                style={{width: "80%"}}
                value={props.paymentNo || ""}>
              </Input>
            </div>
            <div
              style={{display:   props.type === 5 ? "flex" : "none",  alignItems: "center", width: "100%"}}>
              
              <label
                style={{padding: "0 10px", fontWeight: "bold"}}>
                เลขคูปอง
              </label>
              <Input
                className={props.couponMessage === "error" ? "error" : ""}
                icon={props.couponMessage === "success" ? "checkmark green" : ""}
                name="paymentNo"
                onChange={props.onChange}
                onKeyDown={props.onKeyDownCouponNumber}
                placeholder="กรอกเลขที่คูปองแล้วกด Enter"
                style={{width: "80%"}}
                value={props.paymentNo || ""}>
              </Input>
            </div>
            <div
              style={{display:   props.type === 7 ? "flex" : "none",  alignItems: "center"}}>
              
              <label
                style={{padding: "0 10px", fontWeight: "bold"}}>
                เลขที่สัญญา
              </label>
              <Input
                name="paymentNo"
                onChange={props.onChange}
                value={props.paymentNo || ""}>
              </Input>
            </div>
            <div
              className="ui input"
              style={{display:   "flex" , alignItems: "center", width:  [4, 5].includes(props.type) ? "100%" : ""}}>
              
              <label
                style={{padding: "0 10px", fontWeight: "bold"}}>
                จำนวน
              </label>
              <Input
                disabled={props.type === 5}
                name="value"
                onChange={props.onChange}
                value={props.value || ""}>
              </Input>
            </div>
          </div>
        </Segment>
      </div>
    )
}


export default SubRepeaterPayMethodUX

export const screenPropsDefault = {}

/* Date Time : Mon Jul 10 2023 07:07:46 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ height: \"77.5px\", width: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Icon",
      "parent": 3,
      "props": {
        "className": {
          "type": "value",
          "value": "close red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Dropdown",
      "parent": 2,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "[ { key: 2, text: \"เงินโอน\", value: 2 }, { key: 3, text: \"บัตร\", value: 3 }, { key: 4, text: \"เช็ค\", value: 4 }, { key: 5, text: \"คูปอง\", value: 5 }, { key: 6, text: \"กระเป๋าเงิน\", value: 6 }, { key: 7, text: \"ผ่อนชำระ\", value: 7 }, ]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.type"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:  props.type === 2 ? \"flex\" : \"none\", alignItems: \"center\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่อ้างอิง"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Input",
      "parent": 7,
      "props": {
        "name": {
          "type": "value",
          "value": "paymentNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "value": {
          "type": "code",
          "value": "props.paymentNo || \"\""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:  props.type ===2 ? \"flex\" : \"none\", alignItems: \"center\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "บัญชีรับ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "paymentTarget"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.masterAccountOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.paymentTarget || \"\""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:  props.type === 3 ? \"flex\" : \"none\", alignItems: \"center\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขบัตร"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Input",
      "parent": 13,
      "props": {
        "className": {
          "type": "value",
          "value": "payment_card_number-0"
        },
        "name": {
          "type": "value",
          "value": "paymentNo.0"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.handleKeyDownNumber"
        },
        "placeholder": {
          "type": "value",
          "value": "xxxx"
        },
        "style": {
          "type": "code",
          "value": "{width:\"60px\", marginRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.paymentNo?.[0] || \"\""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Input",
      "parent": 13,
      "props": {
        "className": {
          "type": "value",
          "value": "payment_card_number-1"
        },
        "name": {
          "type": "value",
          "value": "paymentNo.1"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.handleKeyDownNumber"
        },
        "placeholder": {
          "type": "value",
          "value": "xxxx"
        },
        "style": {
          "type": "code",
          "value": "{width:\"60px\", marginRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.paymentNo?.[1] || \"\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Input",
      "parent": 13,
      "props": {
        "className": {
          "type": "value",
          "value": "payment_card_number-2"
        },
        "name": {
          "type": "value",
          "value": "paymentNo.2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.handleKeyDownNumber"
        },
        "placeholder": {
          "type": "value",
          "value": "xxxx"
        },
        "style": {
          "type": "code",
          "value": "{width:\"60px\", marginRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.paymentNo?.[2] || \"\""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Input",
      "parent": 13,
      "props": {
        "className": {
          "type": "value",
          "value": "payment_card_number-3"
        },
        "name": {
          "type": "value",
          "value": "paymentNo.3"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.handleKeyDownNumber"
        },
        "placeholder": {
          "type": "value",
          "value": "xxxx"
        },
        "style": {
          "type": "code",
          "value": "{width:\"60px\", marginRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.paymentNo?.[3] || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:  props.type === 3 ? \"flex\" : \"none\",  alignItems: \"center\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "เครื่องรูด"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Dropdown",
      "parent": 19,
      "props": {
        "name": {
          "type": "value",
          "value": "paymentTarget"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.masterEDCOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.paymentTarget || \"\""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:   props.type ===4 ? \"flex\" : \"none\",  alignItems: \"center\", width: \"100%\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขเช็ค"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Input",
      "parent": 22,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "paymentNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "style": {
          "type": "code",
          "value": "{width: \"80%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.paymentNo || \"\""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:   props.type === 5 ? \"flex\" : \"none\",  alignItems: \"center\", width: \"100%\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขคูปอง"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Input",
      "parent": 25,
      "props": {
        "className": {
          "type": "code",
          "value": "props.couponMessage === \"error\" ? \"error\" : \"\""
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "icon": {
          "type": "code",
          "value": "props.couponMessage === \"success\" ? \"checkmark green\" : \"\""
        },
        "name": {
          "type": "value",
          "value": "paymentNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDownCouponNumber"
        },
        "placeholder": {
          "type": "value",
          "value": "กรอกเลขที่คูปองแล้วกด Enter"
        },
        "style": {
          "type": "code",
          "value": "{width: \"80%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.paymentNo || \"\""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:   props.type === 7 ? \"flex\" : \"none\",  alignItems: \"center\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่สัญญา"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Input",
      "parent": 28,
      "props": {
        "name": {
          "type": "value",
          "value": "paymentNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "value": {
          "type": "code",
          "value": "props.paymentNo || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui input"
        },
        "style": {
          "type": "code",
          "value": "{display:   \"flex\" , alignItems: \"center\", width:  [4, 5].includes(props.type) ? \"100%\" : \"\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวน"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Input",
      "parent": 31,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.type === 5"
        },
        "name": {
          "type": "value",
          "value": "value"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.value || \"\""
        }
      },
      "seq": 33,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "SubRepeaterPayMethodUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
