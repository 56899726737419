import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Dimmer,
  Form,
  Header,
  Icon,
  Loader,
  Segment,
} from "semantic-ui-react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ReactTable from "react-table-6";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-lib/css/ReactTableFixedColumns.css";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const CARD_HISTORY_LAB_ID: string = "CardHistoryCentralLab";

type CardHistoryCentralLabProps = {
  // function
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // seq
  runSequence?: any;
  HistoryCentralLabSequence?: any;

  // data
  patientId?: number | null;
  selectedEncounter?: any;
  hidePrintButton?: boolean;
};

const CardHistoryCentralLabInitial: CardHistoryCentralLabProps = {
  // function
  setProp: () => null,

  // seq
  runSequence: null,
  HistoryCentralLabSequence: {},

  // data
  patientId: null,
  hidePrintButton: false,
};

const CardHistoryCentralLab: React.FC<CardHistoryCentralLabProps> = (
  props: any
) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectItemExpand, setSelectItemExpand] = useState<any>({});

  useEffect(() => {
    props.runSequence({
      sequence: "HistoryCentralLab",
      action: "FETCH",
      patientId: props.patientId,
    });
  }, []);

  const columns = [
    {
      Header: "Lab",
      width: 250,
      resizable: false,
      expander: true,
      style: { whiteSpace: "unset" }, // WordWrap

      Expander: ({ isExpanded, ...rest }) => {
        // test your condition for Sub-Component here
        // I am using the presence of no comments
        if (!rest.original.hasOwnProperty("children")) {
          return <div>&nbsp;&nbsp;&nbsp;{rest.original.product_name}</div>;
        } else if (
          rest.original.hasOwnProperty("children") &&
          rest.original.children.length === 0
        ) {
          return <div>{rest.original.product_name}</div>;
        } else {
          return (
            <div>
              {isExpanded ? (
                <>
                  <span
                    style={{
                      display: "inline-block",
                      transform: "rotate(90deg)",
                    }}
                  >
                    &#10148;
                  </span>
                  <span> {rest.original.product_name} </span>
                </>
              ) : (
                <span>&#10148; {rest.original.product_name} </span>
              )}
            </div>
          );
        }
      },
      getProps: (state: any, rowInfo: any, column: any) => {
        if (rowInfo) {
          // same test as above

          if (
            !rowInfo.original.children ||
            (rowInfo.original.children &&
              rowInfo.original.children.length === 0)
          ) {
            // hijack the onClick so it doesn't open

            return { onClick: () => {} };
          }
        }

        return { className: "show-pointer" };
      },
    },
    { Header: "การส่งตรวจ", accessor: "lab_type_label", width: 150 },
    {
      Header: "Value",
      accessor: "value",
      width: 100,
      Cell: ({ original }: any) => {
        var texOver = "";
        var textCritical = "";
        if (original?.ref_value !== null) {
          var ref = original?.ref_value?.split(" - ");
          if (ref.length === 2) {
            // 10 - 20
            if (parseFloat(original.value) > parseFloat(ref[1])) {
              texOver = "H";
            } else if (parseFloat(original.value) < parseFloat(ref[0])) {
              texOver = "L";
            }
          } else if (ref.length === 1) {
            // Female: >=50 ,Male: >=40
            const FLOAT_REGEX = "([+-]?\\d+(\\.\\d+|\\d*))";
            const GENDER_REGEX = new RegExp(
              `(Female|Male): *([<|>]=?) *${FLOAT_REGEX}`,
              "g"
            );

            if (!!original?.ref_value?.match(GENDER_REGEX)?.length) {
              const matches = Array.from(
                original?.ref_value.matchAll(GENDER_REGEX)
              );

              var gender: any = matches.find(
                (item: any) =>
                  item?.[1] === props.selectedEncounter?.patient_gender_name
              );

              if (gender?.[2] === "<") {
                if (parseFloat(original.value) < parseFloat(gender?.[3])) {
                  texOver = "L";
                }
              } else if (gender?.[2] === "<=") {
                if (parseFloat(original.value) <= parseFloat(gender?.[3])) {
                  texOver = "L";
                }
              } else if (gender?.[2] === ">") {
                if (parseFloat(original.value) > parseFloat(gender?.[3])) {
                  texOver = "H";
                }
              } else if (gender?.[2] === ">=") {
                if (parseFloat(original.value) >= parseFloat(gender?.[3])) {
                  texOver = "H";
                }
              }
            }
          }
        }

        if (
          original.critical_value_max !== null ||
          original.critical_value_min !== null
        ) {
          if (
            texOver === "H" ||
            parseFloat(original.value) > original.critical_value_max
          ) {
            textCritical = "!";
          } else if (
            texOver === "L" ||
            parseFloat(original.value) < original.critical_value_min
          ) {
            textCritical = "!";
          }
        }
        return (
          <div
            style={{ color: texOver ? "red" : "" }}
          >{`${original.value} ${texOver} ${textCritical}`}</div>
        );
      },
    },
    { Header: "Reference Value", accessor: "ref_value", width: 200 },
    { Header: "Unit", accessor: "unit", width: 100 },
    { Header: "หมายเหตุ", accessor: "comment", width: 150 },
    {
      Header: "สถานะ",
      accessor: "status_name",
      width: 100,
      Cell: ({ original }: any) => {
        let result: string = original.status_name;
        let bgColor: string = "";
        if (original?.status === 8) {
          if (original?.is_authorized) {
            bgColor = "#5dff5d";
          } else {
            result += "รอยืนยันผล";
          }
        }
        return (
          <div style={{ backgroundColor: bgColor, padding: "10px" }}>
            {result}
          </div>
        );
      },
    },
  ];

  const handleClick = (_event: any, titleProps: any) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <Accordion fluid styled>
      <Dimmer active={props.HistoryCentralLabSequence?.loading} inverted>
        <Loader />
      </Dimmer>

      {props.HistoryCentralLabSequence?.labResult?.length > 0 ? (
        <>
          {props.HistoryCentralLabSequence?.labResult?.map(
            (item: any, index: number) => {
              let scroll: boolean = false;
              if (
                item?.result &&
                item?.result.length &&
                item?.result.length > 20
              ) {
                scroll = true;
              }

              return (
                <div key={index}>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={handleClick}
                  >
                    <Segment style={{ background: "#d1edf2" }}>
                      <Form>
                        <Form.Group inline>
                          <Form.Field width={1}>
                            <Icon name="dropdown" size="huge" color="blue" />
                          </Form.Field>
                          <Form.Field width={5}>
                            <Header
                              as="h3"
                              style={{
                                margin: "0px",
                                color: activeIndex === index ? "black" : "grey",
                              }}
                            >
                              {item?.date || ""} - {item?.encounter_type}
                            </Header>
                          </Form.Field>
                          <Form.Field width={10}>
                            <Header
                              as="h3"
                              style={{
                                color: activeIndex === index ? "black" : "grey",
                              }}
                            >
                              สถานะ: {item?.status_name || ""}
                            </Header>
                          </Form.Field>
                        </Form.Group>

                        <Form.Group inline>
                          <Form.Field width={1}></Form.Field>
                          <Form.Field width={5}>
                            <Header
                              as="h4"
                              style={{
                                color: activeIndex === index ? "black" : "grey",
                              }}
                            >
                              แพทย์: {item?.doctor_name || ""}
                            </Header>
                          </Form.Field>
                          <Form.Field width={5}>
                            <Header
                              as="h4"
                              style={{
                                color: activeIndex === index ? "black" : "grey",
                              }}
                            >
                              แผนก: {item?.division_name || ""}
                            </Header>
                          </Form.Field>
                          <Form.Field width={5}>
                            <Header
                              as="h4"
                              style={{
                                color: activeIndex === index ? "black" : "grey",
                              }}
                            >
                              คลินิก: {item?.division_name || ""}
                            </Header>
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </Segment>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === index}>
                    <ReactTableFixedColumns
                      data={item?.result}
                      columns={columns}
                      expanded={selectItemExpand}
                      expanderDefaults={{
                        sortable: false,
                        resizable: true,
                        filterable: false,
                      }}
                      onExpandedChange={(
                        expanded: any,
                        index: any,
                        event: any
                      ) => {
                        console.log("onExpandedChange expanded", expanded);
                        console.log("onExpandedChange index", index);
                        console.log("onExpandedChange event", event);
                        // Find Expand
                        let parentCount =
                          item?.result && item?.result.length
                            ? item?.result.length
                            : 0;
                        let childCount = 0;
                        // Adjust PageSize and setHeight
                        Object.keys(expanded).forEach(function (key) {
                          if (expanded[key]) {
                            childCount += item?.result[key]?.children?.length;
                          }
                        });
                        if (parentCount + childCount > 20) {
                          scroll = true;
                        } else {
                          scroll = false;
                        }

                        setSelectItemExpand(expanded);
                      }}
                      pageSize={
                        item?.result && item?.result.length
                          ? item?.result.length
                          : 20
                      }
                      style={scroll ? { height: "500px" } : null}
                      subRowsKey={"children"}
                      showPagination={false}
                      getNoDataProps={() => {
                        return { style: { display: "none" } };
                      }}
                      minRows={1}
                    />
                    <Form style={{ margin: "20px 0px" }}>
                      <Form.Group inline>
                        <Form.Field width={14}></Form.Field>
                        <Form.Field width={2} style={{ padding: "0px" }}>
                        {!props.hidePrintButton && (
                          <ButtonLoadCheck
                            // function
                            setProp={props.setProp}
                            onClick={() =>
                              props.runSequence({
                                sequence: "HistoryCentralLab",
                                action: "PRINT",
                                labOrderId: item?.id,
                                buttonLoadCheckKey: `${CARD_HISTORY_LAB_ID}_PRINT${index}`,
                              })
                            }
                            // data
                            paramKey={`${CARD_HISTORY_LAB_ID}_PRINT${index}`}
                            buttonLoadCheck={
                              props.buttonLoadCheck?.[
                                `${CARD_HISTORY_LAB_ID}_PRINT${index}`
                              ]
                            }
                            // config
                            color="green"
                            fluid={true}
                            title="พิมพ์ผลแลป"
                          />
                        )}
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Accordion.Content>
                </div>
              );
            }
          )}
        </>
      ) : (
        <Header
          as="h2"
          style={{ color: "grey", textAlign: "center", padding: "50px" }}
        >
          Lab not found
        </Header>
      )}
    </Accordion>
  );
};

CardHistoryCentralLab.defaultProps = CardHistoryCentralLabInitial;

export default React.memo(CardHistoryCentralLab);
