import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "semantic-ui-react";

import moment from "moment";
import { Table } from "react-lib/frameworks/Table";

// UX
import CardReturnDrugUX from "./CardReturnDrugUX";

// Common
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import CardUserActionLog from "../common/CardUserActionLog";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import PasswordRecheck from "react-lib/apps/common/PasswordRecheck";

// Types
type CardReturnDrugProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  // data
  drugOrderId: number;
  card: string;
  drugPermission: Record<string, boolean>;
  DrugReturnList?: {
    [key: string]: any;
    alerts: Record<string, any>;
    items: Record<string, any>[];
  }[];
  OrderHistory?: OrderHistoryType[];
  OrderReturnLog: { items: Record<string, any>[] } | null;
  OrderReturnList: any;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  closeIcon?: boolean;
  onClose: () => any;
};

type OrderHistoryType = {
  [key: string]: any;
  items: Record<string, any>[];
  allowed_actions: string[];
  status_name: keyof typeof BUTTON_COLORS;
} & {
  [key in "approved" | "delivered" | "received" | "requested" | "rejected" | "canceled"]: Record<
    string,
    any
  > | null;
};

// Const
const BUTTON_COLORS = {
  REQUESTED: "red",
  APPROVED: "yellow",
  DELIVERED: "green",
  RECEIVED: "blue",
} as const;

const BUTTON_ACTIONS = [
  { action: "REQUEST", color: "green" },
  { action: "EDIT", color: "yellow" },
  // { action: "APPROVE", color: "green" },
  // { action: "DELIVER", color: "green" },
  // { action: "RECEIVE", color: "green" },
  // { action: "UNAPPROVE", color: "red" },
  // { action: "UNDELIVER", color: "red" },
  // { action: "UNRECEIVE", color: "red" },
  // { action: "REJECT", color: "red" },
  { action: "CANCEL", color: "red" },
];

const CardReturnDrug = (props: CardReturnDrugProps) => {
  const [openModLog, setOpenModLog] = useState<boolean>(false);
  const [password, setPassword] = useState("");

  // Effect
  useEffect(() => {
    return () => {
      props.runSequence({ sequence: "ReturnOrder" });
    };
  }, []);

  // Memo
  const drugOrderList = useMemo(() => {
    return (props.DrugReturnList || []).map((item: any, index: number) => ({
      ...item,
      quantity_request: (
        <EditorColumn
          value={item.quantity_request}
          onDataChanged={(value: string) =>
            handleChangeEdit({ value, index, type: "quantity_request" })
          }
        />
      ),
      no: index + 1,
    }));
  }, [props.DrugReturnList]);

  const buttonOrderCode = useMemo(() => {
    return (props.OrderHistory || []).map((item, index: number) => (
      <Button
        key={"button" + item.id}
        color={BUTTON_COLORS[item.status_name]}
        size="mini"
        content={`${item.status_name}:${item.code}`}
        onClick={() => {
          props.runSequence({
            sequence: "ReturnOrder",
            action: "HistoryRequest",
            orders: item,
          });
        }}
      />
    ));
  }, [props.OrderHistory, props.OrderReturnList]);

  const drugOrderLog = useMemo(() => {
    return (props.OrderReturnLog?.items || []).map((item: any) => ({
      ...item,
      datetime: moment(item.datetime).format("DD/MM/YY HH:mm"),
    }));
  }, [props.OrderReturnLog?.items]);

  const statusName = useMemo(() => {
    return props.OrderReturnList?.pk === props.drugOrderId
      ? "NEW"
      : props.OrderReturnList?.status_name;
  }, [props.OrderReturnList, props.drugOrderId]);

  const buttonActions = useMemo(() => {
    const allowedActions: string[] = props.OrderReturnList?.allowed_actions || [];

    const actions = allowedActions.flatMap((action) => {
      const btnAction = BUTTON_ACTIONS.find((item) => item.action === action);

      const permission =
        props.drugPermission?.[`action_TPD_DrugReturnRequest_${btnAction?.action}`];

      return permission && btnAction ? [btnAction] : [];
    });

    return actions;
  }, [props.OrderReturnList, props.drugPermission]);

  // handler
  const handleChangeEdit = (data: any) => {
    props.runSequence({
      sequence: "ReturnOrder",
      action: "Edit",
      data,
    });
  };

  const handleClose = () => {
    setOpenModLog(false);
  };

  const handleSave = (data: any) => () => {
    const pk = props.OrderReturnList?.pk;
    const order = props.OrderReturnList?.order;

    props.runSequence({
      sequence: "ReturnOrder",
      action: data.action === "REQUEST" ? "Request" : "Update",
      id: pk,
      btnAction: data.action,
      card: props.card,
      order,
      repassword: password,
    });
  };

  const handleOnNew = () => {
    props.runSequence({
      sequence: "ReturnOrder",
      action: "New",
      id: props.drugOrderId,
    });
  };

  const handleOnLog = () => {
    props.runSequence({
      sequence: "ReturnOrder",
      action: "Log",
      id: props.OrderReturnList?.id,
    });

    setOpenModLog(true);
  };

  console.log("CardReturnDrug", props);

  return (
    <div>
      <CardReturnDrugUX
        drugOrderList={drugOrderList}
        code={props.OrderReturnList?.order_code || props.OrderReturnList?.code}
        orderCode={props.OrderReturnList?.code}
        patientName={props.OrderReturnList?.patient_name_code || "-"}
        divisionOrder={
          props.OrderReturnList?.requester_name || props.OrderReturnList?.order_div || "-"
        }
        closeIcon={props.closeIcon}
        onClose={props.onClose}
        buttonOrderCode={buttonOrderCode}
        statusName={statusName}
        userActionLogReturnList={
          <CardUserActionLog data={props.OrderReturnList} timeLabel="วัน-เวลา" timeWidth="19%" />
        }
        handleOnNew={handleOnNew}
        handleOnLog={handleOnLog}
        passwordInput={
          buttonActions.length > 0 && (
            <div>
              <div style={{ alignItems: "center", display: "flex", gap: "5px" }}>
                <div>
                  Password <span style={{ color: "red" }}>*</span>
                </div>
                <PasswordRecheck password={password} setPassword={setPassword} />
              </div>
            </div>
          )
        }
        ButtonSave={buttonActions.map((button) => (
          <ButtonLoadCheck
            key={`button${button.action}`}
            // function
            setProp={props.setProp}
            onClick={handleSave(button)}
            // data
            paramKey={`${props.card}_${button.action}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${props.card}_${button.action}`]}
            // config
            color={button.color}
            // name="SEARCH"
            size="tiny"
            title={button.action}
          />
        ))}
      />

      <Modal open={openModLog} size="small" onClose={handleClose}>
        <div style={{ padding: "10px" }}>
          <Modal.Header as="h3">ประวัติการทำงาน</Modal.Header>
          <Table
            data={drugOrderLog}
            headers="User,Action,Date-Time"
            keys="name,action,datetime"
            minRows="10"
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
        </div>
      </Modal>
    </div>
  );
};

CardReturnDrug.displayName = "CardReturnDrug";

export default React.memo(CardReturnDrug);
