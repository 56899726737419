import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button
} from 'semantic-ui-react'

const ModDrugDiseaseUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%"}}>
        <div
          style={{backgroundColor: "#f69529eb", color: "white", fontWeight: "bold", fontSize: "22px", padding: "15px"}}>
          พบรายการสั่งยาที่มีผลต่อโรคที่เกี่ยวข้องกับผู้ป่วย
        </div>
        <div
          style={{width: "100%"}}>
          {props.drugDiseaseList}
        </div>
        <div
          style={{fontWeight: "bold", fontSize: "18px", padding: "15px 20px"}}>
          {props.orderItemId? "เหตุผลการสั่งใช้ยาจากแพทย์" : "หากต้องการยืนยันการสั่งกรุณาระบุเหตุผล"}
        </div>
        <div
          style={{width: "100%", padding: "10px 30px"}}>
          
          <Input
            onChange={props.onNoteChange}
            style={{width: "100%"}}
            value={props.note}>
          </Input>
        </div>
        <div
          style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          
          <div
            style={{padding: "15px 10px"}}>
            
            <Button
              className="inverted big"
              color="green"
              disabled={props.orderItemId && !props.note}
              onClick={props.onApprove}
              style={{display: props.isEditable? ""  : "none"}}>
              {props.orderItemId?"แก้ไข" : "ยืนยัน"}
            </Button>
          </div>
          <div
            style={{padding: "15px 10px"}}>
            
            <Button
              className="inverted big"
              color="red"
              onClick={props.onDeny}>
              {props.isEditable? "ยกเลิก" : "ปิดหน้าต่าง"}
            </Button>
          </div>
        </div>
      </div>
    )
}

export default ModDrugDiseaseUX

export const screenPropsDefault = {}

/* Date Time : Tue Jan 24 2023 19:47:40 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "พบรายการสั่งยาที่มีผลต่อโรคที่เกี่ยวข้องกับผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#f69529eb\", color: \"white\", fontWeight: \"bold\", fontSize: \"22px\", padding: \"15px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugDiseaseList"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderItemId? \"เหตุผลการสั่งใช้ยาจากแพทย์\" : \"หากต้องการยืนยันการสั่งกรุณาระบุเหตุผล\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"18px\", padding: \"15px 20px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", padding: \"10px 30px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Input",
      "parent": 4,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onNoteChange"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px 10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px 10px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderItemId?\"แก้ไข\" : \"ยืนยัน\""
        },
        "className": {
          "type": "value",
          "value": "inverted big"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.orderItemId && !props.note"
        },
        "onClick": {
          "type": "code",
          "value": "props.onApprove"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isEditable? \"\"  : \"none\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isEditable? \"ยกเลิก\" : \"ปิดหน้าต่าง\""
        },
        "className": {
          "type": "value",
          "value": "inverted big"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDeny"
        },
        "size": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 55,
  "isMounted": false,
  "memo": false,
  "name": "ModDrugDiseaseUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 60
}

*********************************************************************************** */
