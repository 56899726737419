import React from "react";
import { Modal, Button } from "semantic-ui-react";

// Types
type ModSaveAssessmentProps = {
  // data
  open: boolean;
  // config
  isSaveReassessment?: boolean;
  // callback
  onSave?: (params: {
    sequence: string;
    card: string;
    action: "SAVE";
    status: "CONFIRM" | "SAVE";
    isSaveReassessment?: boolean;
  }) => any;
  onClose?: () => any;
};
const ModSaveAssessment = (props: ModSaveAssessmentProps) => {
  const handleSaveConfirm = () => {
    props.onSave?.({
      sequence: "Assessment",
      card: "CardAssessment",
      action: "SAVE",
      status: "CONFIRM",
      isSaveReassessment: props.isSaveReassessment,
    });
  };

  const handleSaveDraft = () => {
    props.onSave?.({
      sequence: "Assessment",
      card: "CardAssessment",
      action: "SAVE",
      status: "SAVE",
      isSaveReassessment: props.isSaveReassessment,
    });
  };

  return (
    <Modal onClose={props.onClose} open={props.open} size="mini">
      <Modal.Header style={{ backgroundColor: "#2D9CDB", color: "#FFFFFF" }}>
        ต้องการยืนยันเอกสารใช่หรือไม่
      </Modal.Header>
      <Modal.Content>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div>
            <Button basic color="green" onClick={handleSaveConfirm}>
              Confirm
            </Button>
          </div>
          <div>
            <Button basic color="teal" onClick={handleSaveDraft}>
              Save draft
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default React.memo(ModSaveAssessment);
