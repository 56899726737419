import React, { useState } from "react";
import { Button, Dimmer, Icon, Loader, Modal } from "semantic-ui-react";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

// UX
import CardBindIdUX from "./CardBindIdUX";

// Types
type ButtonBindIdProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  chatDetail?: any;
  selectedEncounter?: Record<string, any>;
  // CommonInterface
  loadingStatus?: Record<string, any>;
  selectedPatient?: Record<string, any>;
  selectedAdmitOrderRoomItem?: Record<string, any>;
  errorMessage?: Record<string, any>;
  bindHn?: boolean;
};

const Styles = {
  bindHn: {},
  bindHnTelemed: {
    width: "140px",
    marginLeft: "-52px",
    minWidth: "max-content",
  },
};

const CARD_PATIENT_DISCHARGE = "CardPatientDischarge";

const ButtonBindId = (props: ButtonBindIdProps) => {
  // mod
  const [openMod, setOpenMod] = useState<boolean>(false);
  // data
  const [ihid, setIhid] = useState<any>("");

  const handleCloseModDischarge = async () => {
    setOpenMod(false);
    setIhid("");

    props.setProp(`errorMessage.${CARD_PATIENT_DISCHARGE}`, null);

    props.setProp("dischargeDoctor", {
      userId: null,
      username: "",
      password: "",
    });
  };

  const handleCheckout = (params: any = {}) => {
    return props.onEvent({
      message: "HandleSaveMedicalRecordDischarge",
      params: {
        ...params,
        encounter: props.selectedEncounter?.id,
        admitRoom: props.selectedAdmitOrderRoomItem,
        type: "BIND_ID",
        card: CARD_PATIENT_DISCHARGE,
        patientId: props.selectedPatient?.id,
        ihid,
        onSuccess: handleCloseModDischarge,
      },
    });
  };

  return (
    <>
      {props.bindHn ? (
        <div>
          <div
            style={{
              background: "#2185d0",
              gap: "5px",
              display: "flex",
              borderRadius: "5px",
              padding: "5px",
              width: "fit-content"
            }}
            onClick={() => setOpenMod(true)}
          >
            <div
              style={{
                color: "white",
              }}
            >
              {"ผูก HN"}
            </div>
            <Icon name="mobile alternate" inverted />
          </div>
        </div>
      ) : (
        <>
          {!props.chatDetail && !props.selectedEncounter?.id && (
            <Button
              color="blue"
              style={{
                width: "140px",
                marginLeft: "-52px",
                minWidth: "max-content",
              }}
              onClick={() => setOpenMod(true)}
            >
              ผูก HN Telemed
            </Button>
          )}
        </>
      )}

      <Modal
        open={openMod}
        style={{ width: "40%" }}
        size="small"
        closeOnDimmerClick
        onClose={handleCloseModDischarge}
      >
        <Dimmer active={props.loadingStatus?.[CARD_PATIENT_DISCHARGE]} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <CardBindIdUX
          hn={props.selectedPatient?.hn || ""}
          fullName={props.selectedPatient?.real_name || ""}
          email={props.selectedPatient?.present_address?.email || ""}
          onCancel={handleCloseModDischarge}
          onConfirm={() => {
            handleCheckout();
          }}
          onClose={handleCloseModDischarge}
          onChange={(e: any, v: any) => setIhid(v.value)}
          ihid={ihid}
          bindHn={props.bindHn}
          ErrorMessage={
            <ErrorMessage
              style={{ marginBottom: "1rem" }}
              error={props.errorMessage?.[CARD_PATIENT_DISCHARGE]}
            />
          }
        />
      </Modal>
    </>
  );
};

export default React.memo(ButtonBindId);
