import React, { CSSProperties, useMemo, useState, useCallback } from "react";

import moment from "moment";

// UX
import ModStockSelectLotNoUX from "./ModStockSelectLotNoUX";

// Interface
import {
  PermissionsType,
  ProductLotSerializer,
  ProductStockSerializer,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";
import { formatDate } from "react-lib/utils/dateUtils";
import { Radio } from "semantic-ui-react";

// Types
type ModStockSelectLotNoProps = {
  // data
  data: Partial<ProductStockSerializer>;
  productStockList?: ProductStockSerializer[];
  permissions?: PermissionsType;
  // callback
  onClose?: () => any;
  onSelect?: (productStock: ProductStockSerializer | null) => any;
};

// Const
const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

const ModStockSelectLotNo = (props: ModStockSelectLotNoProps) => {
  const [checked, setChecked] = useState<number | null>(null);

  // Use Callback
  const handleCheck = useCallback(
    (e: any, data: any) => {
      if (!props.permissions?.TAB_ISSUE_STOCK_ISSUE) {
        return;
      }

      setChecked(Number(data.name));
    },
    [props.permissions]
  );

  // Use Memo
  const titleName = useMemo(() => {
    return `[${props.data?.product?.code || ""}]-${
      props.data?.product?.name || ""
    }`;
  }, [props.data]);

  const lotItems = useMemo(() => {
    const list = (props.productStockList || []).filter(
      (item) => !!item.quantity
    );

    return list.map((item) => ({
      ...item,
      check: (
        <div
          style={{
            ...GridCenter,
            padding: "0.25rem 0",
          }}
        >
          <Radio
            name={item.id.toString()}
            checked={checked === item.id}
            disabled={!props.permissions?.TAB_ISSUE_STOCK_ISSUE}
            style={{ transform: "scale(1.2)" }}
            onChange={handleCheck}
          />
        </div>
      ),
      expire_date: (
        <div style={GridCenter}>
          {item.lot?.exp_datetime
            ? formatDate(moment(item.lot?.exp_datetime))
            : ""}
        </div>
      ),
      lot_no: <div style={GridCenter}>{item.lot?.mfc_no || ""}</div>,
      balance: <div style={GridCenter}>{item.quantity}</div>,
    }));
  }, [props.productStockList, checked]);

  // Handler
  const handleConfirm = () => {
    const data = props.productStockList?.find((item) => item.id === checked);

    props.onSelect?.(data || null);

    props.onClose?.();
  };

  return (
    <div>
      <ModStockSelectLotNoUX
        // data
        lotItems={lotItems}
        name={titleName}
        storageName={props.data.storage?.name || ""}
        // config
        disabledConfirm={!checked}
        // callback
        onClose={props.onClose}
        onCancel={props.onClose}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default React.memo(ModStockSelectLotNo);
