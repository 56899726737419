import React from "react";
import MainHIS from "./MainHIS";
import PatientLayout from "HIS/PatientLayout";
import Tab from "react-lib/frameworks/Tab";
import CardAppointmentList from "react-lib/apps/HISV3/APP/CardAppointmentList";
import CardPatientPanel from "react-lib/apps/HISV3/common/CardPatientPanel";
import CONFIG from "config/config";
import { renderDrugOrderItems } from "react-lib/apps/HISV3/TPD/TPDInterface";

// From IsHealth
import ChatBox from "react-lib/apps/MSG/ChatBox";
import CardOpenEncounter from "react-lib/apps/HISV3/REG/CardOpenEncounter";

const LAYOUT_SETTINGS = [
  {
    patientList: { display: true, flex: 1, size: "max" },
    patientPanel: { display: false, flex: 0, size: "none" },
    patientRecord: { display: false, flex: 0, size: "none" },
  },
  {
    patientList: { display: false, flex: 0, size: "none" },
    patientPanel: { display: true, flex: 1, size: "min" },
    patientRecord: { display: true, flex: 4, size: "min" },
    backward: true,
    forward: false,
  },
];

export const Main = (props: any) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <PatientLayout
        LAYOUT_SETTINGS={LAYOUT_SETTINGS}
        patientList={(layoutProps: any) => (
          <div
            style={{ backgroundColor: "white", height: "100%", width: "100%" }}
          >
            {props.listView(layoutProps)}
          </div>
        )}
        patientPanel={(layoutProps: any) => (
          <div
            style={{
              backgroundColor: "#eeeeee",
              height: "100%",
              width: "100%",
            }}
          >
            {props.panelView(layoutProps)}
          </div>
        )}
        patientRecord={(layoutProps: any) => (
          <div style={{ height: "100%", width: "100%" }}>
            {props.recordView(layoutProps)}
          </div>
        )}
        resetLayout={props.resetLayout}
      />
    </div>
  );
};

Main.displayName = "ViewAppointment.Main"
export const listView = (context: MainHIS) => (layoutProps: any) =>
  (
    <div style={{ height: "100%" }}>
      <CardAppointmentList
        // CardDrugOrder
        selectedDrugItemDetail={context.state.selectedDrugItemDetail}
        selectedDrugItemDetailOptions={
          context.state.selectedDrugItemDetailOptions
        }
        drugSearchText={context.state.drugSearchText}
        drugSearchResult={context.state.drugSearchResult}
        DrugSelectSequence={context.state.DrugSelectSequence}
        selectedDrug={context.state.selectedDrug}
        drugOrderList={context.state.drugOrderList}
        drugOrderListLoading={context.state.drugOrderListLoading}
        drugOrder={context.state.drugOrder}
        renderDrugOrderItems={renderDrugOrderItems}
        drugDose={context.state.drugDose}
        drugQty={context.state.drugQty}
        drugDuration={context.state.drugDuration}
        drugDescription={context.state.drugDescription}
        drugPrn={context.state.drugPrn}
        drugSearchLoading={context.state.drugSearchLoading}
        // modal doctor certificate check
        modDoctorCertificate={context.state.modDoctorCertificate}
        // modal drug order template
        DrugOrderTemplateSequence={context.state.DrugOrderTemplateSequence}
        modReMedication={context.state.modReMedication}
        modConfirmDeleteDrug={context.state.modConfirmDeleteDrug}
        modDrugLabInteraction={context.state.modDrugLabInteraction}
        modDrugDisease={context.state.modDrugDisease}
        modDrugInteraction={context.state.modDrugInteraction}
        currentDoctor={context.state.currentDoctor}
        drugOrderItemEstimate={context.state.drugOrderItemEstimate}
        drugPermission={context.state.drugPermission}
        // SolventSelectionConfig
        modSolventSelectionConfig={context.state.modSolventSelectionConfig}
        selectedVerbalDrugOrderWorking={
          context.state.selectedVerbalDrugOrderWorking
        }
        goToCreateAppointment={context.state.goToCreateAppointment}
        ref={(ref) => {
          context.tabSubAppointmentRef = ref;
        }}
        setProp={context.controller.setProp}
        onEvent={context.controller.handleEvent}
        runSequence={context.runSequence}
        patientList={context.state.patientList || []}
        patientListMyBplus={context.state.patientListMyBplus || []}
        appointmentList={context.state.appointmentList || []}
        rescheduleList={context.state.rescheduleList || []}
        reconfirmList={context.state.reconfirmList || []}
        waitingList={context.state.waitingList || []}
        selectedPatient={context.state.selectedPatient}
        selectedPatientId={context.state.selectedPatientId}
        selectedAppointment={context.state.selectedAppointment}
        selectedAppointmentId={context.state.selectedAppointmentId}
        selectedBlock={context.state.selectedBlock}
        blockList={context.state.blockList}
        divisionList={context.state.divisionList}
        divisionOptions={context.state.divisionOptions}
        selectedDivision={context.state.selectedDivision}
        searchedItemList={context.state.searchedItemList}
        availableSlots={context.state.availableSlots}
        patientSearchText={context.state.patientSearchText}
        layoutProps={layoutProps}
        masterOptions={context.state.masterOptions}
        OrderTreatmentSequence={context.state.OrderTreatmentSequence}
        treatmentOrderList={context.state.treatmentOrderList}
        successMessage={context.state.successMessage}
        errorMessage={context.state.errorMessage}
        procedureItems={context.state.procedureItems}
        secondaryItems={context.state.secondaryItems}
        treatmentTemplateList={context.state.treatmentTemplateList}
        preOrderList={context.state.preOrderList}
        // central lab
        OrderCentralLabSequence={context.state.OrderCentralLabSequence}
        selectedEncounter={context.state.selectedEncounter}
        selectedEmr={context.state.selectedEmr}
        selectedProgressCycle={context.state.selectedProgressCycle}
        doctorLabOrderList={context.state.doctorLabOrderList}
        userLabPermission={context.state.userLabPermission}
        // imaging
        imagingList={context.state.imagingList}
        orderImagingList={context.state.orderImagingList}
        loadingStatus={context.state.loadingStatus}
        buttonLoadCheck={context.state.buttonLoadCheck}
        appointmentDashboardController={context.appointmentDashboardController}
        selectedOperatingOption={context.state.selectedOperatingOption}
        // Ishealth-v3 port into CUDent (vice versa)
        controller={context.controller}
        proxyController={context.proxyController}
        patientAppointmentList={context.state.patientAppointmentList || []}
        searchedItemListWithKey={context.state.searchedItemListWithKey}
        userTokenize={context.state.userTokenize}
        patientAppType={context.state.masterOptions?.patientAppType}
        waitingListItemStatus={
          context.state.masterOptions?.waitingListItemStatus
        }
        allWaitingList={context.state.allWaitingList}
        waitingQueueList={context.state.waitingQueueList}
        staticCanCelQueueList={context.state.staticCanCelQueueList}
        duplicateAppointment={context.state.duplicateAppointment}
        reoccureDuplicateAppointment={
          context.state.reoccureDuplicateAppointment
        }
        chairList={context.state.chairList}
        divisionDict={context.controller.data.divisionDict}
        searchBlockList={context.state.searchBlockList}
        appointmentSummaryList={context.state.appointmentSummaryList}
        appointmentSummaryPage={context.state.appointmentSummaryPage}
        loadingFilterAppList={context.state.loadingFilterAppList}
        loadingPrintAppList={context.state.loadingPrintAppList}
        // OR Request
        ORRequestSequence={context.state.ORRequestSequence}
        masterData={context.controller.data.masterData}
        encounterPatientList={context.state.encounterPatientList}
        divisionId={context.controller.data.division}
        django={context.state.django}
        selectedOrOrder={context.state.selectedOrOrder}
        medReconcileCheck={context.state.medReconcileCheck}
        medReconcileIndex={context.state.medReconcileIndex}
        // package
        PackagePurchaseSequence={context.state.PackagePurchaseSequence}
        PackageDateTimeSequence={context.state.PackageDateTimeSequence}
        // Operating select datetime
        OperatingDateTimeSequence={context.state.OperatingDateTimeSequence}
        selectedOperatingDetail={context.state.selectedOperatingDetail}
        operatingBlock={context.state.operatingBlock}
        selectOperatingDSBChange={context.state.selectOperatingDSBChange}
        selectedDoctor={context.state.selectedDoctor}
        selectedAnesthesiologist={context.state.selectedAnesthesiologist}
        selectedOperatingRoom={context.state.selectedOperatingRoom}
        estimateTimeText={context.state.estimateTimeText}
        isSelectTabCalendar={context.state.isSelectTabCalendar}
        operatingLocationRoom={context.state.operatingLocationRoom}
        // Consult
        consultDetail={context.state.consultDetail}
        consultData={context.state.consultData}
        // Imaging
        ImagingOrderSequence={context.state.ImagingOrderSequence}
        modXrayDetail={context.state.modXrayDetail}
        // ----- Dental Diagram
        clinicalFindingId={context.state.clinicalFindingId}
        clinicalFindingIndex={context.state.clinicalFindingIndex}
        clinicalFindings={context.state.clinicalFindingList || []}
        organ={context.controller.data.organ}
        clinicaltags={context.controller.data.clinicaltags}
        filterClinicalFindingIds={context.state.filterClinicalFindingIds || []}
        // config
        cardPatientAppointmentConfig={
          {
            // disabledPostpone: true,
            // disabledWaitingList: true
          }
        }
        showOrderAppointment={true}
        hideDrugAllergy={true}
        myPermission={context.state.myPermission}
        userPosition={context.state.userPosition}
        providerInfo={context.state.providerInfo}
        waitingListLoading={context.state.waitingListLoading}
        // Feature 68560 Appointmen
        allDivisionList={context.controller.data?.allDivisionList}
        selectedAppointmentDivision={context.state.selectedAppointmentDivision}
      />
    </div>
  );

export const panelView = (context: MainHIS) => (layoutProps: any) =>
  (
    <div style={{ height: "100%" }}>
      <CardPatientPanel
        //Feature #54627
        onEvent={context.controller.handleEvent}
        runSequence={context.runSequence}
        patientADR={context.state.patientADR}
        selectedPatient={context.state.selectedPatient}
        chatDetail={context.state.chatDetail}
        errorMessage={context.state.errorMessage}
        loadingStatus={context.state.loadingStatus}
        buttonLoadCheck={context.state.buttonLoadCheck}
        divisionType={context.state.divisionType}
        // Suspending
        encounterSuspension={context.state.encounterSuspension}
        MedicalFeeHistorySequence={context.state.MedicalFeeHistorySequence}
        goToCreateAppointment={() => {
          // TODO: Need Implemented
          // context.tabPatientRef?.changeTab(3)
          // context.setState({goToCreateAppointment: { selectedPatient: context.state.selectedPatient }})
        }}
        hideTabMenu={layoutProps.hideTabMenu}
        setHideTabMenu={layoutProps.setHideTabMenu}
        SetPatientSequence={context.state.SetPatientSequence}
      />
    </div>
  );

export const recordView = (context: MainHIS) => (layoutProps: any) =>
  (
    <div style={{ height: "100%" }}>
      <Tab
        tabWidth={150}
        data={[
          {
            key: "เปิด Encounter ใหม่",
            name: "เปิด Encounter ใหม่",
            hide:
              (!Number.isInteger(context.state.selectedPatient?.id) ||
                ["การเงิน"].includes(context.state.divisionType || "")) &&
              !context.state.patientListMyBplus,
            render: () => (
              <CardOpenEncounter
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                runSequence={context.runSequence}
                patient={context.state.SetPatientSequence?.patient || {}}
                selectedPatient={context.state.selectedPatient}
                CreateUpdateEncounterSequence={context.state.CreateUpdateEncounterSequence}
                appointmentEncounterList={context.state.appointmentEncounterList}
                patientListMyBplus={context.state.patientListMyBplus}
                selectedAppointmentItems={context.state.selectedAppointmentItems}
                masterOptions={context.state.masterOptions}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                buttonLoadCheck={context.state.buttonLoadCheck}
                useApiDashboard={!!context.state.patientListMyBplus}
              />
            ),
          },
          {
            key: "Drug Order",
            name: "Drug Order",
            render: () => <div>To be implemented</div>,
          },
          {
            key: "Telehealth_Appointment",
            name: "Telehealth",
            hide:
              // !context.state.selectedEncounter ||
              context.state.divisionType !== "ห้องยา" &&
              context.state.divisionType !== "หน่วยตรวจ" &&
              context.state.divisionType !== "คลินิกพรีเมี่ยม",
            render: () => (
              <div className="chatBox">
                <ChatBox
                  ref={(ref: any) => (context.chatBoxRef = ref)}
                  // controller
                  controller={context.chatController}
                  classifyController={context.classifyController}
                  diagFormController={context.diagFormController}
                  // data
                  userId={context.controller.data.user?.toString() || ""}
                  apiToken={context.controller.apiToken}
                  division_id={context.state.selectedDivision?.id}
                  isPatient={false}
                  nullId={false}
                  readOnly={false}
                  fullname={"fullname"}
                  username={"username"}
                  encounterId={context.state.selectedEncounter?.id}
                  patientData={{}}
                  patientId={context.state.selectedPatient?.id}
                  match={{
                    params: {
                      chatChannelId: context.state.chatDetail?.chat_channel,
                    },
                  }}
                  // callback

                  onSetChannelName={(props: any) => {}}
                  onNavigationMessage={({ content }: any) => content}
                  onCallGetLastMessage={() => {}}
                  onCloseVideoCall={() => {}}
                  onOpenVideoCall={(url: string) => {
                    context.controller.setProp("openVideoCallModal", true);
                    context.controller.setProp("videoCallRoom", url);
                    context.controller.setProp("selectedEncounter", context.state.selectedEncounter);
                    context.controller.setProp(
                      "currChatChannelId",
                      context.state.chatDetail?.chat_channel
                    );
                  }}
                  enHasNoDiv={() => {}}
                  enHasDiv={() => {}}
                />
              </div>
            ),
          },
        ]
          ?.filter((item: any) =>
            CONFIG.SCREEN_ITEM?.find((conf: any) => conf.name === item.key)
          )
          ?.filter((item: any) => !item.hide)}
      />
    </div>
  );

// Old record view to be discarded
// {
//   key: "New Appointment",
//   name: "New Appointment",
//   render: () =>
//     <CardAppointmentCreateUX
//       setProp={context.controller.setProp}
//       onEvent={context.controller.handleEvent}
//       blockList={context.state.blockList}
//       createPatientAppointment={() =>
//         context.controller.handleEvent({
//           message: "CreatePatientAppointment", params: {}
//         })}
//       hideBlockTable={false}
//       blockRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
//         return {
//           style: {
//             backgroundColor: rowInfo?.original?.id === context.state.selectedBlockId ?
//                             "#cccccc" : "white"
//           },
//           onClick: () => {
//             context.controller.handleEvent({
//               message: "AssignAppointmentToBlock",
//               params: { id: rowInfo?.original?.id }
//             });
//           }
//         }
//       }}
//     />
// },
// {
//   key: "Appointment",
//   name: "Appointment",
//   render: () =>
//     <CardAppointmentRequestNew
//       titleCard="Appointment Request"
//       controller={context.crdAppointmentRequestNewController}
//       modSearchDoctorNoteController={context.modSearchDoctorNoteController}
//       subDoctorScheduleController={context.subDoctorScheduleController}
//       DJANGO={context.state.django}
//       PATIENT_DATA={context.state.patientData}
//       closeable={false}
//     />
// },
// {
//   key: "TimeNurse",
//   name: "TimeNurse",
//   render: () =>
//     <TimeNurse
//       match={context.state.matchCompat}
//       queueController={context.queueController}
//       division={context.controller.data.division || 0}
//       hideScheduleButton={true}
//     />
// },

{
  /* Old listview to be discarded */
}
{
  /* <CardAppointmentListUX
  setProp={context.controller.setProp}
  onEvent={context.controller.handleEvent}
  patientSearchText={context.state.patientSearchText}
  onSearchKey={(e: any) => {if (e.key === "Enter")
    context.controller.handleEvent({message: "SearchPatient", params: {}})}}
  patientList={context.state.patientList}
  appointmentList={context.state.appointmentList}
  patientRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
    return {
      style: {
        backgroundColor: rowInfo?.original?.id === context.state.selectedPatientId ?
                        "#cccccc" : "white"
      },
      onClick: () => {
        context.controller.handleEvent({
          message: "SelectPatientForAppointment",
          params: { id: rowInfo?.original?.id }
        });
        layoutProps.layout === 0 && layoutProps?.forward();
      }
    }
  }}
  hideAppointmentTable={context.state.selectedPatientId === null}
  appointmentRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
    return {
      style: {
        backgroundColor: rowInfo?.original?.id === context.state.selectedAppointmentId ?
                        "#cccccc" : "white"
      },
      onClick: () => {
        context.controller.handleEvent({
          message: "SelectAppointment",
          params: { id: rowInfo?.original?.id }
        });
      }
    }
  }}
/> */
}

