import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  CardLayout,
  ModOrderDetail,
  PureReactTable,
  RightLabel,
} from "../common";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Icon,
  Input,
  Label,
  Popup,
} from "semantic-ui-react";
import {
  DOCTOR_ORDER_BG_COLOR,
  DOCTOR_ORDER_ACRONYM,
} from "../../../_qmljs/Common/Util";
import Parser from "html-react-parser";

const style = {
  fluid: {
    width: "100%",
    minWidth: "auto",
  },
  textBoldWrap: {
    fontWeight: "bold",
    marginBottom: 0,
    whiteSpace: "normal",
  },
  textWrap: {
    whiteSpace: "normal",
  },
  text15px: {
    fontSize: "15px",
  },
};

const defaultCheckBoxData = {
  chkUnExpire: true,
  chkShowAllDiv: true,
  chkTreatmentLab: true,
  chkFinish: false,
  chkAppoint: false,
};

const CardDPOQueue = forwardRef((props, ref) => {
  const searchRef = useRef(null);

  const [checkBoxData, setCheckBoxData] = useState(defaultCheckBoxData);
  const [isLocalhost, setIsLocalhost] = useState(false);

  // ModOrderDetail
  const [openModOrderDetail, setOpenModOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const columns = [
    {
      Header: "เวลาสร้าง Encounter",
      style: { whiteSpace: "unset" },
      width: 150,
      Cell: (props) => {
        const { created } = props.original;
        return (
          <div>
            <p>{created}</p>
          </div>
        );
      },
    },
    {
      Header: "เวลารับเข้า Counter",
      style: { whiteSpace: "unset" },
      width: 150,
      Cell: (props) => {
        const { edited } = props.original;
        return (
          <div>
            <p>{edited}</p>
          </div>
        );
      },
    },
    {
      Header: "HN",
      style: { whiteSpace: "unset" },
      width: 120,
      Cell: (props) => {
        const { hn } = props.original;
        return (
          <div>
            <p>{hn}</p>
          </div>
        );
      },
    },
    {
      Header: "ชื่อ-สกุล",
      style: { whiteSpace: "unset" },
      Cell: (props) => {
        const { patient_name } = props.original;
        return (
          <div>
            <p style={style.textBoldWrap}>{patient_name}</p>
          </div>
        );
      },
    },
    {
      Header: "New",
      style: { whiteSpace: "unset" },
      width: 50,
      Cell: (props) => {
        const { is_new } = props.original;
        if (is_new) {
          return (
            <Popup
              position="bottom left"
              trigger={<Icon className={"teal"} name="star" />}
              content={"ผู้ป่วยใหม่"}
            />
          );
        }
        return <i></i>;
        // return <p style={{whiteSpace: 'normal', color: is_new ? 'red' : 'black'}}>{(is_new ? (is_new + ' new') : is_new)}</p>
      },
    },
    {
      Header: "level",
      style: { whiteSpace: "unset" },
      width: 50,
      Cell: (props) => {
        const { level, level_color, level_desc } = props.original;
        return (
          level ?
            <Popup
              position="bottom left"
              trigger={<Label color={level_color}>{level}</Label>}
              content={level_desc}
            /> :
            <></>
          // <div style={{textAlign: 'center'}}>
          //     <Label color={level_color}>{level}</Label>
          // </div>
        );
      },
    },
    {
      Header: "Encounter",
      style: { whiteSpace: "unset" },
      width: 90,
      Cell: (props) => {
        const { encounter } = props.original;
        return (
          <div>
            <p style={style.textWrap}>{encounter}</p>
          </div>
        );
      },
    },
    {
      Header: "สถานะ",
      style: { whiteSpace: "unset" },
      width: 180,
      Cell: (props) => {
        const { status_name, status_color } = props.original;
        return (
          <div style={{ textAlign: "center" }}>
            <Label color={status_color}>{status_name}</Label>
          </div>
        );
      },
    },
    {
      Header: "BMI",
      style: { whiteSpace: "unset" },
      width: 60,
      Cell: (props) => {
        const { vital_signs } = props.original;
        return (
          <div style={{ textAlign: "center" }}>
            <p style={style.textWrap}>{vital_signs.BMI}</p>
          </div>
        );
      },
    },
    {
      Header: "Weight",
      style: { whiteSpace: "unset" },
      width: 60,
      Cell: (props) => {
        const { vital_signs } = props.original;
        return (
          <div style={{ textAlign: "center" }}>
            <p style={style.textWrap}>{vital_signs.Weight}</p>
          </div>
        );
      },
    },
    {
      Header: "Height",
      style: { whiteSpace: "unset" },
      width: 60,
      Cell: (props) => {
        const { vital_signs } = props.original;
        return (
          <div style={{ textAlign: "center" }}>
            <p style={style.textWrap}>{vital_signs.Height}</p>
          </div>
        );
      },
    },
    {
      Header: "BT",
      style: { whiteSpace: "unset" },
      accessor: "BT",
      width: 50,
      Cell: (props) => {
        const { vital_signs } = props.original;
        return (
          <div style={{ textAlign: "center" }}>
            <p style={style.textWrap}>{vital_signs.BT}</p>
          </div>
        );
      },
    },
    {
      Header: "SP",
      style: { whiteSpace: "unset" },
      accessor: "SP",
      width: 50,
      Cell: (props) => {
        const { vital_signs } = props.original;
        return (
          <div style={{ textAlign: "center" }}>
            <p style={style.textWrap}>{vital_signs.SP}</p>
          </div>
        );
      },
    },
    {
      Header: "DP",
      style: { whiteSpace: "unset" },
      accessor: "DP",
      width: 50,
      Cell: (props) => {
        const { vital_signs } = props.original;
        return (
          <div style={{ textAlign: "center" }}>
            <p style={style.textWrap}>{vital_signs.DP}</p>
          </div>
        );
      },
    },
    {
      Header: "RR",
      style: { whiteSpace: "unset" },
      accessor: "RR",
      width: 50,
      Cell: (props) => {
        const { vital_signs } = props.original;
        return (
          <div style={{ textAlign: "center" }}>
            <p style={style.textWrap}>{vital_signs.RR}</p>
          </div>
        );
      },
    },
    {
      Header: "O2Sat",
      style: { whiteSpace: "unset" },
      accessor: "O2Sat",
      width: 60,
      Cell: (props) => {
        const { vital_signs } = props.original;
        return (
          <div style={{ textAlign: "center" }}>
            <p style={style.textWrap}>{vital_signs.O2Sat}</p>
          </div>
        );
      },
    },
    {
      Header: "Investigations",
      style: { whiteSpace: "unset" },
      accessor: "Investigations",
      width: 120,
      Cell: (props) => {
        const { investigations } = props.original;
        return (
          <div style={{ textAlign: "left" }}>
            {investigations.map((value, index) => {
              if (
                ["DRAFT", "PENDING", "PERFORMED", "APPOINTMENT"].indexOf(
                  value.order_status
                ) >= 0
              ) {
                return (
                  <Label
                    key={index}
                    circular
                    color={DOCTOR_ORDER_BG_COLOR[value.order_status]}
                    onClick={(e, data) => {
                      handleOpenModOrderDetail(value);
                    }}
                  >
                    {DOCTOR_ORDER_ACRONYM[value.specific_type]}
                  </Label>
                );
              }
            })}
          </div>
          // <div style={{textAlign: 'center'}}>
          //     <Label color={level_color}>{level}</Label>
          // </div>
        );
      },
    },
  ];

  useEffect(() => {
    checkLocalhost();
  }, []);

  useImperativeHandle(ref, () => ({
    clear: () => {
      searchRef.current.inputRef.current.value = "";
    },
    searchValue: () => {
      return searchRef.current.inputRef.current.value;
    },
    queryParam: () => {
      var params = {
        status: "IN_QUEUE",
        unexpired_only: checkBoxData.chkUnExpire,
        hn: searchRef.current.inputRef.current.value,
        first_name: searchRef.current.inputRef.current.value,
        appoint_doctor: checkBoxData.chkAppoint,
      };

      if (checkBoxData.chkShowAllDiv) {
        params.all_div = true;
      }

      if (checkBoxData.chkFinish) {
        params.status += "|CHECKOUT";
      }
      if (checkBoxData.chkTreatmentLab) {
        params.status += "|IN_TREAT";
      }

      return params;
    },
  }));

  const checkLocalhost = () => {
    if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
      setIsLocalhost(true);
    } else {
      setIsLocalhost(false);
    }
  };

  const handleOpenModOrderDetail = (order) => {
    setSelectedOrder(order);
    setOpenModOrderDetail(true);
  };

  const handleCloseModOrderDetail = () => {
    setSelectedOrder(null);
    setOpenModOrderDetail(false);
  };

  const handleCheckboxChange = (event, data) => {
    setCheckBoxData({ ...checkBoxData, [data.name]: data.checked });
  };

  const handleSelectQueue = (row) => {
    props.onSelected(row);
  };

  const handleSearch = async () => {
    await props.onSearch();
  };

  return (
    <CardLayout titleText={props.titleCard} headerColor="green">
      <Grid columns={2}>
        <Grid.Column width={5}>
          <Form>
            <Form.Group inline>
              <Form.Field width={7}>
                <RightLabel>ค้นหาตาม HN หรือ ชื่อ-สกุล</RightLabel>
              </Form.Field>
              <Form.Field width={9}>
                <Input
                  ref={searchRef}
                  placeholder="ระบุ HN หรือ ชื่อ-สกุล(ห้ามใส่คำนำหน้า)"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>

        <Grid.Column width={11}>
          <Form>
            <Form.Group inline>
              <Form.Field width={3}>
                <Checkbox
                  label="ตรวจเสร็จแล้ว"
                  defaultChecked={false}
                  name="chkFinish"
                  onChange={handleCheckboxChange}
                />
              </Form.Field>
              <Form.Field width={3}>
                <Checkbox
                  label="ผู้ป่วยนัดหมาย"
                  defaultChecked={false}
                  name="chkAppoint"
                  onChange={handleCheckboxChange}
                />
              </Form.Field>
              <Form.Field width={3}>
                <Checkbox
                  label="กำลังทำหัตถการ/แลป"
                  defaultChecked={true}
                  name="chkTreatmentLab"
                  onChange={handleCheckboxChange}
                />
              </Form.Field>
              <Form.Field width={2}>
                <Checkbox
                  label="ทุกหน่วยงาน"
                  defaultChecked={true}
                  name="chkShowAllDiv"
                  onChange={handleCheckboxChange}
                />
              </Form.Field>
              <Form.Field width={3}>
                <Checkbox
                  label={"un-expire (dev env.)"}
                  className={isLocalhost ? "" : "hidden"}
                  defaultChecked={true}
                  name="chkUnExpire"
                  onChange={handleCheckboxChange}
                />
              </Form.Field>
              <Form.Field width={2}>
                <Button primary fluid onClick={handleSearch}>
                  ค้นหา
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid>

      <PureReactTable
        data={props.data}
        loading={props.tableLoading}
        style={{ height: 600 }}
        columns={columns}
        showPagination={false}
        pageSize={10}
        onEnter={handleSelectQueue}
        manual
      />

      <ModOrderDetail
        controller={props.modOrderDetailController}
        hideCallback={handleCloseModOrderDetail}
        open={openModOrderDetail}
        orderId={selectedOrder ? selectedOrder.id : null}
      />
    </CardLayout>
  );
});

CardDPOQueue.defaultProps = {
  titleCard: "คิวผู้ป่วย",
  modOrderDetailController: null,
  tableLoading: false,
  data: [],
  onSearch: () => { },
  onSelected: () => { },
};

CardDPOQueue.propTypes = {
  titleCard: PropTypes.string,
  modOrderDetailController: PropTypes.object,
  data: PropTypes.array,
  tableLoading: PropTypes.bool,
  onSearch: PropTypes.func,
  onSelected: PropTypes.func,
};

export default React.memo(CardDPOQueue);
