import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";

import { Button, Divider, Icon, Label, Segment } from "semantic-ui-react";

import SubAddButton from "../common/SubAddButton";
import SubICDDetail from "../DPO/SubICDDetail";

const style = {
  paddingTop: { paddingTop: "20px" },
};

const default_diagnosis = {
  medical_description: "",
  icd_term: "",
  icd_code: "",
};

const SecondaryICDDetail = React.memo(
  forwardRef((props, ref) => {
    const [detail, setDetail] = useState(default_diagnosis);

    useImperativeHandle(ref, () => ({
      getData: () => {
        return { key: props.index, detail: detail };
      },
    }));

    useEffect(() => {
      if (props.data) {
        setDetail(props.data);
      }
    }, [props.data]);

    const handleSecondaryDiag = (data: any) => {
      const icd10 = {
        icd10: data["icd10_id"],
        type: data["type"],
        medical_description: data["medterm"],
        icd_term: data["icdterm"],
        icd_code: data["icdcode"],
        active_disease: data["active_disease"],
      };

      setDetail(icd10);
    };

    const handleSecondaryDiagErased = () => {
      setDetail(default_diagnosis);
    };

    const handleSecondaryDiagDelete = () => {
      props.onSecondaryDiagDelete(props.index);
    };

    const handleChangeSubType = (type: string) => {
      if (type) {
        setDetail({ ...detail, type } as any);
      }
    };

    const handleChecked = (checked: boolean) => {
      if (typeof checked === "boolean") {
        setDetail({ ...detail, active_disease: checked } as any);
      }
    };

    return (
      <Segment>
        <SubICDDetail
          controller={props.controller}
          disabled={props.isReadOnly}
          showSubType={true}
          isIPDProvisional={true}
          isShowDeleteButton={!props.isReadOnly}
          initialData={props.data}
          onSelected={handleSecondaryDiag}
          onErased={handleSecondaryDiagErased}
          onDelete={handleSecondaryDiagDelete}
          onChangeSubType={handleChangeSubType}
          onChecked={handleChecked}
        />
      </Segment>
    );
  })
);

const SubProvisionDiag = forwardRef((props: SubProvisionDiagProps, ref) => {
  const secondaryDiagRef = React.useRef({});

  const [primaryDiag, setPrimaryDiag] = useState(default_diagnosis);
  const [secondaryDiag, setSecondaryDiag] = useState([]);

  const { titleCard, isReadOnly } = props;

  useImperativeHandle(ref, () => ({
    getSaveData: () => {
      let data = [];
      for (let key in secondaryDiagRef.current) {
        if (secondaryDiagRef.current[key]) {
          data.push(secondaryDiagRef.current[key].getData().detail);
        }
      }
      return { primary: primaryDiag, secondary: data };
    },
    setData: (data) => {
      if (data.primary) {
        setPrimaryDiag({
          ...data.primary,
          icd_term: data.primary.icd10_term,
          icd_code: data.primary.icd10_code,
        });
      }

      let secondaryData = [];
      data.secondary.forEach(function (item) {
        item["icd_term"] = item.icd10_term;
        item["icd_code"] = item.icd10_code;
        secondaryData.push(item);
      });
      setSecondaryDiag(secondaryData);
    },
  }));

  // -------------------------------------------------- Primary
  const handlePrimaryDiag = (data) => {
    const icd10 = {
      icd10: data["icd10_id"],
      // type: data["type"],
      medical_description: data["medterm"],
      icd_term: data["icdterm"],
      icd_code: data["icdcode"],
      active_disease: data["active_disease"],
    };

    setPrimaryDiag(icd10);
  };

  const handlePrimaryDiagErased = () => {
    setPrimaryDiag(default_diagnosis);
  };

  // -------------------------------------------------- Secondary
  const handleAddSecondaryDiag = () => {
    let newList = [];
    for (let key in secondaryDiagRef.current) {
      try {
        newList.push(secondaryDiagRef.current[key].getData().detail);
      } catch (err) {}
    }
    newList.push(default_diagnosis);
    setSecondaryDiag(newList);
  };

  const handleSecondaryDiagDelete = (index) => {
    let newList = [];
    for (let key in secondaryDiagRef.current) {
      try {
        newList.push(secondaryDiagRef.current[key].getData().detail);
      } catch (err) {}
    }
    newList.splice(index, 1);
    setSecondaryDiag(newList);
  };

  const handleChecked = (checked: boolean) => {
    if (typeof checked === "boolean") {
      setPrimaryDiag({ ...primaryDiag, active_disease: checked } as any);
    }
  };

  return (
    <Segment raised={props.raised} secondary={props.secondary}>
      <Label color="grey" ribbon size="big" style={{...props.titleCardStyle}}>
        {titleCard}
      </Label>

      <Divider horizontal>PRIMARY DIAGNOSIS</Divider>
      <Segment>
        <SubICDDetail
          controller={props.subICDController}
          disabled={isReadOnly}
          isShowDeleteButton={false}
          initialData={primaryDiag}
          onSelected={handlePrimaryDiag}
          onErased={handlePrimaryDiagErased}
          onChecked={handleChecked}
        />
      </Segment>

      <Divider style={style.paddingTop} horizontal>
        SECONDARY DIAGNOSIS
      </Divider>

      {secondaryDiag.map((data, index) => {
        const content = (
          <SecondaryICDDetail
            ref={(el) => (secondaryDiagRef.current[index] = el)}
            key={index}
            controller={props.subICDController}
            index={index}
            data={data}
            isReadOnly={isReadOnly}
            onSecondaryDiagDelete={handleSecondaryDiagDelete}
          />
        );
        return content;
      })}

      <SubAddButton
        header="เพิ่ม Secondary Diagnosis"
        onClicked={handleAddSecondaryDiag}
      />

      <Button color="green" type="button" onClick={props.onSaveAllCallback} style={props.buttonSaveStyle}>
        {props.saveText ? <Icon className="check"></Icon> : "บันทึก"}
      </Button>
    </Segment>
  );
});

type SubProvisionDiagProps = {

  subICDController: object,
  titleCardStyle: object,
  raised: boolean,
  secondary: boolean,
  onSaveAllCallback: any,
  buttonSaveStyle: object,

  isReadOnly: boolean,
}

const SubProvisionDiagInitial: SubProvisionDiagProps = {
  titleCard: "Provisional Diagnosis",
  subICDController: null,
  titleCardStyle: {},
  raised: true,
  secondary: true,
  onSaveAllCallback: () => {},
  buttonSaveStyle: {},

  isReadOnly: false,
}

SubProvisionDiag.defaultProps = SubProvisionDiagInitial;

export default React.memo(SubProvisionDiag);