import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { CheckboxProps, Dropdown, DropdownProps } from "semantic-ui-react";

import { RowInfo } from "react-table-6";

// UX
import CardSupplyOrderQueueListUX from "./CardSupplyOrderQueueListUX";
import CardPatientSearchBox from "react-lib/apps/HISV3/TPD/CardPatientSearchBox";

// Common
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";

// Utils
import { formatUTCtoBEString } from "react-lib/utils/dateUtils";

const CardSupplyOrderQueueList = (props: any) => {
  const [orderQueue, setOrderQueue] = useState<any[]>([]);

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "DispensingOrderSupply", restart: true });
  }, []);

  useEffect(() => {
    const list = (props.DispensingOrderSupplySequence?.orderQueue || [])
      .filter((item: any) => {
        let isIPD = props.DispensingOrderSupplySequence?.isIPD;
        let isOPD = props.DispensingOrderSupplySequence?.isOPD;

        if (item.type?.includes("OPD")) {
          return isOPD;
        } else {
          return isIPD;
        }
      })
      .filter((item: any) =>
        props.DispensingOrderSupplySequence?.hn
          ? item.hn === props.DispensingOrderSupplySequence?.hn
          : true
      )
      .filter((item: any) =>
        props.DispensingOrderSupplySequence?.filterStatus.length !== 0
          ? props.DispensingOrderSupplySequence?.filterStatus?.includes(
              item.status || ""
            )
          : true
      )
      .filter((item: any) =>
        props.DispensingOrderSupplySequence?.filterDivision
          ? item.order_div ===
            props.DispensingOrderSupplySequence?.filterDivision
          : true
      );

    setOrderQueue(list);
  }, [props.DispensingOrderSupplySequence?.orderQueue]);

  // Memo
  const orderQueueItems = useMemo(
    () =>
      orderQueue.map((item: any) => ({
        ...item,
        type: item.type === "COST_CENTER" ? "Cost center" : item.type,
        clinic:
          props.masterOptions?.division?.find(
            (option: any) => option.value === item.order_div
          )?.text || "",
        requested: item.requested
          ? formatUTCtoBEString(item.requested).join(" ")
          : "",
        printed: item.printed
          ? formatUTCtoBEString(item.printed).join(" ")
          : "",
        checked: item.checked
          ? formatUTCtoBEString(item.checked).join(" ")
          : "",
        delivered: item.delivered
          ? formatUTCtoBEString(item.delivered).join(" ")
          : "",
      })),
    [orderQueue, props.masterOptions]
  );

  // Handler
  const handleChangeDivision = (e: SyntheticEvent, data: DropdownProps) => {
    props.setProp("DispensingOrderSupplySequence.filterDivision", data.value);
  };

  const handleChangeState = (e: SyntheticEvent, data: DropdownProps) => {
    props.setProp("DispensingOrderSupplySequence.filterStatus", data.value);
  };

  const handleChangePatient = (id: any, hn: any) => {
    handleChangeValue(null, { name: "hn", hn });
  };

  const handleChangeValue = (e: any, v: any) => {
    props.setProp(`DispensingOrderSupplySequence.${v.name}`, v.hn);
  };

  const handleSelect = (rowInfo: RowInfo) => {
    const { encounter, id } = rowInfo?.original;

    if (encounter) {
      props.runSequence({
        sequence: "DispensingOrderSupply",
        action: "select",
        encounter: encounter,
        orderId: id,
      });

      props?.forward();
    }
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {        
        color: rowInfo?.original?.type === "STAT"
            ? "#db2828"
            : "",
        fontWeight: rowInfo?.original?.type === "STAT" ? "bold" : "",
      },
      onDoubleClick: (e: any) => {
        handleSelect(rowInfo);
      },
      onClick: (e: any) => {
        handleSelect(rowInfo);
      },
    }
  };

  return (
    <div id="CardSupplyOrderQueueList" style={{flex: "auto 1 1"}}>
      <CardSupplyOrderQueueListUX
        cardPatientSearchBox={
          <div style={{ margin: "0px 10px" }}>
            <CardPatientSearchBox
              // controller
              controller={props.drugOrderQueueController}
              // data
              // defaultValue={props.DispensingOrderSupplySequence?.hn}
              clearHNInput={
                !props.DispensingOrderSupplySequence?.hn ? true : false
              }
              // callback
              onEnterPatientSearch={handleChangePatient}
            />
          </div>
        }
        divisionSearchBox={
          <Dropdown
            search={true}
            selection={true}
            clearable={true}
            fluid={true}
            options={props.masterOptions?.division || []}
            onChange={handleChangeDivision}
            value={props.DispensingOrderSupplySequence?.filterDivision}
            style={{ margin: "0px 10px" }}
          />
        }
        orderSearchBox={
          <DropdownOptions
            value={props.DispensingOrderSupplySequence?.filterStatus || ""}
            multiple={true}
            search={true}
            placeholder="เลือก Order Status"
            checked={true}
            searchBox={true}
            options={props.supplyOrderStatus || []}
            onChange={handleChangeState}
          />
        }
        supplyOrderQueueList={orderQueueItems}
        runSequence={props.runSequence}
        onFilter={() => {
          props.runSequence({
            sequence: "DispensingOrderSupply",
            action: "search",
          }); // for Search
        }}
        // filter
        isIPD={props.DispensingOrderSupplySequence?.isIPD}
        isOPD={props.DispensingOrderSupplySequence?.isOPD}
        onChangeIPD={(e: SyntheticEvent, data: CheckboxProps) => {
          props.setProp("DispensingOrderSupplySequence.isIPD", data?.checked);
        }}
        onChangeOPD={(e: SyntheticEvent, data: CheckboxProps) => {
          props.setProp("DispensingOrderSupplySequence.isOPD", data?.checked);
        }}
        getTrProps={handleGetTrProps}
      />
    </div>
  );
};

export default React.memo(CardSupplyOrderQueueList);
