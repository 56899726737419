import React, { useMemo, useState } from "react";

// Utils
import { Form, FormGroup, Dropdown, Radio } from "semantic-ui-react";

// Common
import { DateTextBox } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Constant
const PERIOD_OPTIONS: any[] = [
  { key: 0, value: 0, text: "ทุกคาบ" },
  { key: 1, value: "MORNING", text: "เช้า" },
  { key: 2, value: "AFTERNOON", text: "บ่าย" },
  { key: 3, value: "EVENING", text: "เย็น" },
];

const CardDoctorIncomeSummaryFilter = (props: any) => {
  // useState
  const [requiredStartDate, setRequiredStartDate] = useState<boolean>(false);

  // useMemo
  const doctorOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แพทย์ทั้งหมด" },
      ...(props.masterOptions?.doctor || []),
    ];
  }, [props.masterOptions?.doctor]);

  const specialtyOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "ทุกสาขา" },
      ...(props.masterOptions?.specialty || []),
    ];
  }, [props.masterOptions?.specialty]);

  const positionOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "ทุกสาขา" },
      ...(props.masterOptions?.positionAll || []),
    ];
  }, [props.masterOptions?.positionAll]);

  const handleSelectRadio = (optionSelected: any) => {
    switch (optionSelected) {
      case "daily":
      case "monthly":
      case "yearly":
        return (
          props.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.reportType ===
          `${optionSelected}`
        );
      default:
        console.warn("Option Doesn't Exist!!");
        break;
    }
  };

  const handleChangeRadio = (e: any, { value }: { value: string }) => {
    props.setProp(`ClinicAnnualReportSequence.doctorIncomeSummaryReport.reportType`, value);
  };

  const handlePreviewButton = () => {
    if (requiredStartDate) {
      alert("กรุณาระบุวันที่รับบริการ");
    } else {
      props.runSequence({
        sequence: "ClinicAnnualReport",
        action: "PREVIEW_REPORT",
        menu: "รายงานสรุปรายได้ และค่าตอบแทนแพทย์",
      });
    }
  };

  const handlePrintButton = () => {
    if (requiredStartDate) {
      alert("กรุณาระบุวันที่รับบริการ");
    } else {
      props.runSequence({
        sequence: "ClinicAnnualReport",
        action: "PRINT_REPORT",
        menu: "รายงานสรุปรายได้ และค่าตอบแทนแพทย์",
      });
    }
  };

  const handleDownloadButton = () => {
    if (requiredStartDate) {
      alert("กรุณาระบุวันที่รับบริการ");
    } else {
      props.runSequence({
        sequence: "ClinicAnnualReport",
        action: "DOWNLOAD_REPORT",
        menu: "รายงานสรุปรายได้ และค่าตอบแทนแพทย์",
      });
    }
  };

  return (
    <Form>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label style={{ display: "flex", alignItems: "center" }}>ประเภทรายงาน</label>
        <Radio
          label="ประจำวัน"
          checked={handleSelectRadio("daily")}
          onChange={handleChangeRadio}
          value="daily"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <Radio
          label="ประจำเดือน"
          checked={handleSelectRadio("monthly")}
          onChange={handleChangeRadio}
          value="monthly"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <Radio
          label="ประจำปี"
          checked={handleSelectRadio("yearly")}
          onChange={handleChangeRadio}
          value="yearly"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 7.25rem 0rem 0rem",
          }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handlePreviewButton}
          paramKey={`ClinicAnnualReport_PREVIEW_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`ClinicAnnualReport_PREVIEW_REPORT`]}
          color="yellow"
          style={{ width: "11rem" }}
          title="Preview"
        />
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label style={{ display: "flex", alignItems: "center", padding: "0rem 0.5rem 0rem 0rem" }}>
          วันที่ออกตรวจ<span style={{ color: "red" }}>*</span>
        </label>
        <DateTextBox
          onChange={(date: string) => {
            props.setProp(
              `ClinicAnnualReportSequence.doctorIncomeSummaryReport.filterStartDate`,
              date
            )
            if (props.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.filterStartDate.length < 1) {
              setRequiredStartDate(true);
            } else {
              setRequiredStartDate(false);
            }
          }}
          style={{ width: "11rem" }}
          value={props.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.filterStartDate || ""}
        ></DateTextBox>
        <label style={{ display: "flex", alignItems: "center" }}>ถึง</label>
        <DateTextBox
          onChange={(date: string) =>
            props.setProp(
              `ClinicAnnualReportSequence.doctorIncomeSummaryReport.filterEndDate`,
              date
            )
          }
          style={{ width: "11rem" }}
          value={props.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.filterEndDate || ""}
        ></DateTextBox>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 1rem 0rem 0rem",
          }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handlePrintButton}
          paramKey={`ClinicAnnualReport_PRINT_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`ClinicAnnualReport_PRINT_REPORT`]}
          color="blue"
          style={{ width: "11rem" }}
          title="พิมพ์"
        />
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 2.85rem 0rem 0rem",
          }}
        >
          ชื่อแพทย์
        </label>
        <Dropdown
          value={props.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.doctorSelected || ""}
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.doctorIncomeSummaryReport.doctorSelected`,
              data.value
            )
          }
          placeholder="เลือกแพทย์"
          options={doctorOptions}
          search={true}
          selection={true}
          style={{ minWidth: "20rem" }}
        ></Dropdown>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 5.85rem 0rem 0rem",
          }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handleDownloadButton}
          paramKey={`ClinicAnnualReport_DOWNLOAD_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`ClinicAnnualReport_DOWNLOAD_REPORT`]}
          color="green"
          style={{ width: "11rem" }}
          title="ดาวน์โหลด xlsx"
        />
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 1.9rem 0rem 0rem",
          }}
        >
          สาขาแพทย์
        </label>
        <Dropdown
          value={
            props.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.specialtySelected || ""
          }
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.doctorIncomeSummaryReport.specialtySelected`,
              data.value
            )
          }
          placeholder="เลือกสาขาแพทย์"
          options={specialtyOptions}
          search={true}
          selection={true}
          style={{ minWidth: "15.5rem" }}
        ></Dropdown>
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 0.6rem 0rem 0rem",
          }}
        >
          ตำแหน่งแพทย์
        </label>
        <Dropdown
          value={
            props.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.positionSelected || ""
          }
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.doctorIncomeSummaryReport.positionSelected`,
              data.value
            )
          }
          placeholder="เลือกตำแหน่งแพทย์"
          options={positionOptions}
          search={true}
          selection={true}
          style={{ minWidth: "15.5rem" }}
        ></Dropdown>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 0.95rem 0rem 0rem",
          }}
        >
          คาบ
        </label>
        <Dropdown
          selection={true}
          value={props.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.periodSelected || 0}
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.doctorIncomeSummaryReport.periodSelected`,
              data.value
            )
          }
          options={PERIOD_OPTIONS}
          style={{ minWidth: "10rem" }}
        ></Dropdown>
      </FormGroup>
    </Form>
  );
};

export default CardDoctorIncomeSummaryFilter;
