import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDoctorFeeOrderAndCompensationUX = (props: any) => {
    return(
      <div
        style={{ height: "80vh" }}>
        <div
          style={{padding: "10px"}}>

          <div
            className="ui form">

            <FormGroup
              inline={true}
              widths={16}>
              <FormField
                inline={true}
                width={2}>
                <label>
                  รายการค่าใช้จ่าย
                </label>
              </FormField>
              <FormField
                inline={true}
                width={12}>
                <div
                  style={{ width: "100%" }}>

                  <div
                    style={{ width: "100%" }}>

                    <Input
                      fluid={true}
                      icon="search"
                      id="txt-searchDFbyText"
                      onChange={props.onChangeSearchText}
                      size="small"
                      value={props.searchText}>
                    </Input>
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    {}
                    <div
                      style={{ display: props.showSearchResult ? "block" : "none", border: "solid #cccccc 1px", position: "absolute", zIndex: 100, minHeight: "100px" , backgroundColor: "white", maxHeight: "300px", overflow: "auto", width: "73%" }}>
                      {props.searchResult}
                    </div>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Button
                  color="blue"
                  id="btn-searchDFList"
                  onClick={props.open}
                  size="small"
                  style={{ width: "100%" }}>
                  ค้นหา
                </Button>
              </FormField>
            </FormGroup>
          </div>
          <Table
            className="card-doctor-fee-order-compensation --rt-tbody-overflow-y-hidden"
            data={props.dfPreviewItems}
            headers="No,Service Code,Product Name,จำนวนสั่ง,ราคาสูงสุดที่สามารถระบุได้, ราคาต่อหน่วย,ค่าตอบแทน,เบิกได้,เบิกไม่ได้,ราคารวม,ผู้ได้รับค่าตอบแทน,วันที่ค่าใช้จ่าย,ผู้บันทึกข้อมูล,"
            id="tb-dfListCompensation"
            keys="no,service_code,doctor_fee_rule_display,quantity,price_compensation_max,price_unit,compensation_price,price_claimable,price_non_claimable,price_total,received_employee_display,perform_datetime,edited_by, menu"
            minRows="8"
            showPagination={false}
            style={{ height: "40vh", ...(props.tableStyle || {} ) }}
            widths="^40,,,,,,,,,,^200,,,">
          </Table>
          <div
            className="ui form">

            <FormGroup
              inline={true}
              style={{marginTop: "1rem"}}
              widths={16}>
              <FormField
                inline={true}
                width={2}>
                <label>
                  รวม
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Input
                  readOnly={true}
                  value={props.priceTotal}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                style={{justifyContent: "center"}}
                width={2}>
                <label>
                  เบิกได้
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Input
                  readOnly={true}
                  value={props.priceClaimable}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                style={{justifyContent: "center"}}
                width={2}>
                <label>
                  เบิกไม่ได้
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Input
                  readonly={true}
                  value={props.priceNonClaimable}>
                </Input>
              </FormField>
              <div
                style={{flex: 1}}>

              </div>
              <FormField
                inline={true}
                width={2}>
                <div
                  id="btn-saveDF"
                  style={{width: "100%"}}>
                  {props.buttonSave}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default CardDoctorFeeOrderAndCompensationUX

export const screenPropsDefault = {"showSearchResult":false}

/* Date Time : Fri Jul 05 2024 11:40:21 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ height: \"80vh\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-searchDFList"
        },
        "onClick": {
          "type": "code",
          "value": "props.open"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 22,
      "props": {
        "className": {
          "type": "value",
          "value": "card-doctor-fee-order-compensation --rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.dfPreviewItems"
        },
        "headers": {
          "type": "value",
          "value": "No,Service Code,Product Name,จำนวนสั่ง,ราคาสูงสุดที่สามารถระบุได้, ราคาต่อหน่วย,ค่าตอบแทน,เบิกได้,เบิกไม่ได้,ราคารวม,ผู้ได้รับค่าตอบแทน,วันที่ค่าใช้จ่าย,ผู้บันทึกข้อมูล,"
        },
        "id": {
          "type": "value",
          "value": "tb-dfListCompensation"
        },
        "keys": {
          "type": "value",
          "value": "no,service_code,doctor_fee_rule_display,quantity,price_compensation_max,price_unit,compensation_price,price_claimable,price_non_claimable,price_total,received_employee_display,perform_datetime,edited_by, menu"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"40vh\", ...(props.tableStyle || {} ) }"
        },
        "widths": {
          "type": "value",
          "value": "^40,,,,,,,,,,^200,,,"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 32,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.priceTotal"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Input",
      "parent": 34,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.priceClaimable"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 36,
      "props": {
        "readonly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.priceNonClaimable"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormGroup",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการค่าใช้จ่าย"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{justifyContent: \"center\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{justifyContent: \"center\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "รวม"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Input",
      "parent": 59,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "txt-searchDFbyText"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchText"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.searchText"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchResult"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showSearchResult ? \"block\" : \"none\", border: \"solid #cccccc 1px\", position: \"absolute\", zIndex: 100, minHeight: \"100px\" , backgroundColor: \"white\", maxHeight: \"300px\", overflow: \"auto\", width: \"73%\" }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "btn-saveDF"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 63,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDoctorFeeOrderAndCompensationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "showSearchResult": false
  },
  "width": 80
}

*********************************************************************************** */
