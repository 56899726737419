import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Form,
  Icon,
  Label,
  Segment,
  TextArea,
} from "semantic-ui-react";

const style = {
  paddingTop: { paddingTop: "20px" },
};

const SubAssessmentAndPlan = forwardRef((props, ref) => {
  const [assessmentData, setAssessmentData] = useState(null);

  const { titleCard, isReadOnly } = props;

  useImperativeHandle(ref, () => ({
    getSaveData: () => {
      return assessmentData;
    },
    setData: (data) => {
      setAssessmentData(data);
    },
  }));

  const handleDataChange = (e, data) => {
    setAssessmentData({ ...assessmentData, [data.name]: data.value });
  };

  return (
    <Segment raised secondary>
      <Label color="grey" ribbon size="big">
        {titleCard}
      </Label>

      <Form style={style.paddingTop}>
        <Form.Group inline>
          <Form.Field width={2}>
            <label>Investigation Plan</label>
          </Form.Field>
          <Form.Field width={14}>
            <TextArea
              name="investigation_plan"
              readOnly={isReadOnly}
              value={assessmentData ? assessmentData.investigation_plan : ""}
              onChange={handleDataChange}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={2}>
            <label>Treatment Plan</label>
          </Form.Field>
          <Form.Field width={14}>
            <TextArea
              name="treatment_plan"
              readOnly={isReadOnly}
              value={assessmentData ? assessmentData.treatment_plan : ""}
              onChange={handleDataChange}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={2}>
            <label>Patient Education</label>
          </Form.Field>
          <Form.Field width={14}>
            <TextArea
              name="patient_education"
              readOnly={isReadOnly}
              value={assessmentData ? assessmentData.patient_education : ""}
              onChange={handleDataChange}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={16}>
            <Button
              color="green"
              type="button"
              onClick={props.onSaveAllCallback}
            >
              {props.saveText ? <Icon className="check"></Icon> : "บันทึก"}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
});

SubAssessmentAndPlan.defaultProps = {
  titleCard: "Assessment and Plan",

  onSaveAllCallback: () => {},

  isReadOnly: false,
};

SubAssessmentAndPlan.propTypes = {
  onSaveAllCallback: PropTypes.func,

  isReadOnly: PropTypes.bool,
};

export default React.memo(SubAssessmentAndPlan);
