import React from "react";
import CardAppointmentEncounterUX from "./CardAppointmentEncounterUX";

const CardAppointmentEncounter = (props: any) => {
  return(
    <CardAppointmentEncounterUX
    />
  )
}

export default CardAppointmentEncounter