import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardNursingDiagnosisSearchUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%"}}>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(32, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
          <div
            style={{ gridRow: "1/3", gridColumn: "2/17", display: "flex",  alignItems: "center", fontWeight: "bold",fontSize:"large"}}>
            ค้นหาเอกสาร Nursing Diagnosis
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "2/7", display: "flex",  alignItems: "center"}}>
            วันที่บันทึกตั้งแต่
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "7/12" }}>
            <DateTextBox
              onChange={props.changeDate("start_date")}
              style={{width: "100%", height: "100%"}}
              value={props.start_date}>
            </DateTextBox>
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "18/21", display: "flex",  alignItems: "center" }}>
            วันที่สิ้นสุด
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "21/26"}}>
            <DateTextBox
              onChange={props.changeDate("end_date")}
              style={{width: "100%", height: "100%"}}
              value={props.end_date}>
            </DateTextBox>
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "32/35", display: "flex",  alignItems: "center" }}>
            หน่วยงาน
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "35/44" }}>
            <Dropdown
              onChange={props.changeNursingDiagnosis("division")}
              options={props.divisionOptions}
              search={true}
              selection={true}
              style={{width: "100%"}}
              value={props.division}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "45/50"}}>
            <Button
              color="blue"
              onClick={props.onSearch}
              style={{width: "100%"}}>
              ค้นหา
            </Button>
          </div>
          <div
            style={{ gridRow: "7/32", gridColumn: "2/50" }}>
            <Table
              data={props.nursingDiagnosisSearch}
              getTrProps={props.rowProps}
              headers="วันที่บันทึก,เลขที่เอกสาร,หน่วยงานที่บันทึก,ผู้ที่บันทึก"
              keys="createdDate,document_no,encounter_division_name,edit_user_name"
              minRows={12}
              showPagination={false}
              style={{height: "550px", marginBottom: "10px"}}>
            </Table>
          </div>
        </div>
      </div>
    )
}


export default CardNursingDiagnosisSearchUX

export const screenPropsDefault = {"roomDetail":"7C06 Ward  อายุรกรรม ห้อง Standard","searchLabel":"ค้นหา"}

/* Date Time : Wed May 10 2023 10:42:58 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 49,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(32, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาเอกสาร Nursing Diagnosis"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/3\", gridColumn: \"2/17\", display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\",fontSize:\"large\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่บันทึกตั้งแต่"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"2/7\", display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 49,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"7/12\" }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"18/21\", display: \"flex\",  alignItems: \"center\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 49,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"21/26\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"32/35\", display: \"flex\",  alignItems: \"center\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 49,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"35/44\" }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 49,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"45/50\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 58,
      "name": "DateTextBox",
      "parent": 52,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"start_date\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.start_date"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 59,
      "name": "DateTextBox",
      "parent": 54,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"end_date\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.end_date"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Dropdown",
      "parent": 56,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeNursingDiagnosis(\"division\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.division"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 49,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/32\", gridColumn: \"2/50\" }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 63,
      "name": "Table",
      "parent": 62,
      "props": {
        "data": {
          "type": "code",
          "value": "props.nursingDiagnosisSearch"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "วันที่บันทึก,เลขที่เอกสาร,หน่วยงานที่บันทึก,ผู้ที่บันทึก"
        },
        "keys": {
          "type": "value",
          "value": "createdDate,document_no,encounter_division_name,edit_user_name"
        },
        "minRows": {
          "type": "code",
          "value": "12"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"550px\", marginBottom: \"10px\"}"
        }
      },
      "seq": 63,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "CardNursingDiagnosisSearchUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "roomDetail": "7C06 Ward  อายุรกรรม ห้อง Standard",
    "searchLabel": "ค้นหา"
  },
  "width": 75
}

*********************************************************************************** */
