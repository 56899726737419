import React, { useState, useEffect } from "react";

// UX
import CardBillingQueue from "./CardBillingQueue";
import CardBillingSummary from "./CardBillingSummary";
import CardBillingReport from "./CardBillingReport";
import CardReceiptNumber from "./CardReceiptNumber";

// ADM
import { MenuItem } from "../ADM/CardADM";
import { Label } from "semantic-ui-react";


const CardBilling = (props: any) => {
  const [mode, setMode] = useState("queue");

  // Use Effect
  useEffect(() => {
    // * Get Receipt Code เพื่อแสดงเลขที่ใบเสร็จ
    props.onEvent({
      message: "HandleGetReceiptCode",
      params: {},
    });
  }, [props.selectedDevice])

  console.log("CardBilling", props);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "10px",
        overflow: "auto",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", flex: "1" }}>
          <MenuItem
            menu="queue"
            mode={mode}
            name="รายการรอรับชำระ"
            setMode={setMode}
          />
          <MenuItem
            menu="receipt_number"
            mode={mode}
            name="ตั้งเลขที่ใบเสร็จ"
            setMode={setMode}
          />
          <MenuItem
            menu="shift_balance"
            mode={mode}
            name="สรุปยอดปิดกะ"
            setMode={setMode}
          />
          <MenuItem
            menu="billing_report"
            mode={mode}
            name="รายงานทางการเงิน"
            setMode={setMode}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flex: "1",
          }}
        >
          <Label
            size="large"
            style={{
              backgroundColor: "#FDC38E",
              color: "black",
              fontWeight: "normal",
            }}
          >
            เลขที่ใบเสร็จปัจจุบัน :{" "}
            {typeof props.bilReceiptCodeDetail?.code === "undefined"
              ? "-"
              : props.bilReceiptCodeDetail?.code ||
                "ยังไม่ได้ตั้งเลขที่ใบเสร็จ"}
          </Label>
          {/* <div>เลขที่ใบเสร็จปัจจุบัน: </div> */}
        </div>
      </div>

      {mode === "queue" ? (
        <CardBillingQueue
          onEvent={props.onEvent}
          setProp={props.setProp}
          // Controller
          drugOrderQueueController={props.drugOrderQueueController}
          // options
          masterOptions={props.masterOptions}
          // data
          billingFilterQueue={props.billingFilterQueue}
          billingQueuePagination={props.billingQueuePagination}
          billingQueueList={props.billingQueueList}
          loading={props.loadingData}
          selectedDevice={props.selectedDevice}
          // CommonInterface
          forward={props?.forward}
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          // Receipt Code
          bilReceiptCodeDetail={props.bilReceiptCodeDetail}
        />
      ) : mode === "receipt_number" ? (
        <CardReceiptNumber
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // data
          bilReceiptCodeDetail={props.bilReceiptCodeDetail}
        />
      ) : mode === "shift_balance" ? (
        <CardBillingSummary
          billingHistorySequence={props.billingHistorySequence}
          runSequence={props.runSequence}
          buttonLoadCheck={props.buttonLoadCheck}
          setProp={props.setProp}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
        />
      ) : mode === "billing_report" ? (
        <CardBillingReport
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          billingHistorySequence={props.billingHistorySequence}
          // CommonInterface
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemList={props.searchedItemList}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          masterOptions={props.masterOptions}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CardBilling;
