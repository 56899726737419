import React, { useRef, useState, useEffect, useCallback } from "react";
import _ from "react-lib/compat/lodashplus";
import {
  Dimmer,
  Header,
  Button,
  Dropdown,
  Form,
  Input,
  Loader,
  Label,
  Icon,
  Grid,
  Image,
  List,
  Divider,
} from "semantic-ui-react";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import moment from "moment";
import Tab from "react-lib/frameworks/Tab";
import * as Chat from "react-lib/apps/IsHealth/Chat";
// import { ChatControllerInterface } from "react-lib/apps/IsHealth/Chat/ChatControllerInterface";

// import ErrorMessages from "../../common/ErrorMessage";

import CardPatientInfo from "react-lib/apps/HISV3/REG/CardPatientInfo";
import ChatBox from "react-lib/apps/MSG/ChatBox";
import CardHealthMonitor from "react-lib/apps/MemberHealthKit/CardHealthMonitor";
import ErrorBoundary from "react-lib/apps/common/ErrorBoundary";

import DateTextBox from "react-lib/apps/common/DateTextBox";
import PatientLayout from "HIS/PatientLayout";
import CONFIG from "config/config";
import MainHIS from "./MainHIS";

const LAYOUT_SETTINGS = [
  {
    patientList: { display: false, flex: 0, size: "none" },
    patientPanel: { display: true, flex: 1, size: "min" },
    patientRecord: { display: true, flex: 4, size: "max" },
  },
];

interface Patient {
  patientId: number;
  fullname: string;
  age: string;
  hn: string;
  classify?: string;
}

const MOCK_DATA = [
  {
    fullname: "นายมาโนช มานะ",
    age: "35 ปี 3 เดือน 2 วัน",
    hn: "123456",
    patientId: 1416,
  },
  {
    fullname: "นายมานี มานะ",
    age: "35 ปี 3 เดือน 2 วัน",
    hn: "123456",
    patientId: 1417,
  },
  {
    fullname: "นางสาววิสุธินี สาเละ",
    age: "35 ปี 3 เดือน 2 วัน",
    hn: "123456",
    classify: "1 ปรึกษาพยาบาล",
    patientId: 1418,
  },
];

export const Main = (props: any) => {
  const [textSearch, setTextSearch] = useState("");
  const [patientList, setPatientList] = useState<Patient[]>([]);
  const [originalList, setOriginalList] = useState<Patient[]>([]);
  const [segmentOptions, setSegmentOptions] = useState<any>([]);
  const [selectedSegment, setSelectedSegment] = useState("");
  const [selectedPatient, setSelectedPatient] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setPatientList(MOCK_DATA);
    // setOriginalList(MOCK_DATA);

    // props.runSequence({
    //   sequence: "HealthMember",
    //   restart: true,
    //   action: "FETCH_SEGMENT_GROUP",
    // });

    props.onEvent({
      message: "GetHealthMemberSegment",
      params: {},
    });
  }, []);

  useEffect(() => {
    console.log("got segmentGroupList");
    console.log("patientSegmentList", props.HealthMemberSequence?.patientSegmentList);
    let segmentList = props.HealthMemberSequence?.segmentGroupList;
    if (segmentList?.length > 0) {
      let tmp = segmentList.map((item: any) => ({
        key: item.id,
        value: item.code,
        text: item.name,
      }));
      setSegmentOptions(tmp);
    }

    let patientSegmentList = props.HealthMemberSequence?.patientSegmentList
    if (patientSegmentList) {
      setPatientList(patientSegmentList);
      setOriginalList(patientSegmentList);
      setLoading(false)
    }

  }, [props.HealthMemberSequence]);

  console.log("MemberHealthKit props:", props);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTextSearch(value);

    const filteredList = originalList.filter(
      (patient) => patient.fullname.includes(value) || patient.hn.includes(value)
    );
    setPatientList(filteredList);
  };

  const onSelectedPatient = (row: any) => {
    console.log("onSelectedPatient ", row);
    setSelectedPatient(row)
    props.onEvent({
      message: "SelectPatientSegment",
      params: { selectedPatientSegment: row },
    });
  };

  const handleDropdownSegmentChange = (e, { value }) => {
    props.setProp("HealthMemberSequence.patientSegmentList", []);
    setLoading(true)
    setSelectedSegment(value);
    const selectedSegmentObject = props.HealthMemberSequence?.segmentGroupList.find(
      (item) => item.code === value
    );
    if (selectedSegmentObject) {
      props.onEvent({
        message: "SelectSegmentGroup",
        params: { segmentGroupDetail: selectedSegmentObject },
      });
    }
  };

  return (
    <>
      <Grid celled>
        {/* <Table.Body>
        <Table.Row> */}
        <Grid.Column width={2} style={{ padding: 0 }} className="">
          <Form
            style={{
              borderBottom: "1px solid #F2F2F2",
              //height: (props.hideReclassify?52:104) + (config.HIDE_QUEUE_FILTER_DATE || props.hideFilterDate?0:52) + "px"
            }}
          >
            {true && (
              <Form.Field width={16} style={{ padding: "0.5em", margin: 0 }}>
                <Form.Dropdown
                  style={{ width: "100%" }}
                  inline
                  search
                  selection
                  options={segmentOptions}
                  value={selectedSegment}
                  onChange={handleDropdownSegmentChange}
                />
              </Form.Field>
            )}
            <Divider />
            {true && (
              <Form.Field width={16} style={{ padding: "0.5em", margin: 0 }}>
                <Input fluid placeholder="Search..." onChange={handleSearch} icon="search" />
              </Form.Field>
            )}
            {true && (
              <Form.Field width={16} style={{ padding: "0.5em", margin: 0 }}>
                {/* <DateTextBox onChange={(date) => setFilterDate(date)} value={filterDate} /> */}
              </Form.Field>
            )}
          </Form>

          <div className="">
            {loading ? (
              <div style={{ padding: "10px", textAlign: "center" }}>
                <Loader active inline="centered" />
                Loading...
              </div>
            ) : patientList.length === 0 ? (
              <div style={{ padding: "10px", textAlign: "center" }}>
                <p>No patients found</p>
              </div>
            ) : (
              <List selection divided verticalAlign="middle">
                {patientList.map((item: any) => (
                  <List.Item key={item?.hn} onClick={() => onSelectedPatient(item)}>
                    <List.Content floated="left">
                      <List.Header style={{ fontSize: 16 }}>{item.fullname}</List.Header>
                      <List.Description> {`HN : ${item.hn}`} </List.Description>
                    </List.Content>

                    <List.Content floated="right">
                      {item.chat_channel?.unread_messages_count > 0 && (
                        <Label data-testid="msg-noti" circular color={"blue"} size="mini">
                          {item.chat_channel.unread_messages_count}
                        </Label>
                      )}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            )}
          </div>
        </Grid.Column>
        <Grid.Column width={14} style={{ padding: 0 }}>
          <div style={{ width: "100%", height: "100%" }}>
            <PatientLayout
              LAYOUT_SETTINGS={LAYOUT_SETTINGS}
              patientList={(layoutProps: any) => (
                <div style={{ backgroundColor: "green", height: "100%", width: "100%" }}>
                  {/* {props.listView(layoutProps)} */}
                  patientList
                </div>
              )}
              patientPanel={(layoutProps: any) => (
                <div
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    width: "100%",
                    borderRight: "solid #cccccc 1px",
                  }}
                >
                  {props.panelView(layoutProps)}
                </div>
              )}
              patientRecord={(layoutProps: any) => (
                <div style={{ height: "100%", width: "100%" }}>{props.recordView(layoutProps)}</div>
              )}
            // resetLayout={props.resetLayout}
            // bilReceiptCodeDetail={props.bilReceiptCodeDetail}
            // showReceiptCode={props.showReceiptCode}
            />
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export const panelView = (context: MainHIS) => (layoutProps: any) => {
  const patient = context.state.HealthMemberSequence?.selectedPatientSegment || {};

  return (
    <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
      <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        {/* Image Container */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            height: "250px",
            width: "auto",
          }}
        >
          <div className="Img-container">
            <Image
              src={patient.image_url || "/static/images/person.png"}
              size="small"
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = "/static/images/person.png";
              }}
            />
          </div>
        </div>

        {/* Patient Details */}
        <div style={{ width: "100%", height: "100%", position: "relative", paddingLeft: "10px" }}>
          <div style={{ display: "flex", width: "100%", margin: "10px 0px" }}>
            <div style={{ width: "35%", fontWeight: "bold" }}>HN</div>
            <div style={{ width: "65%" }}>{patient.hn || "-"}</div>
          </div>
          <div style={{ display: "flex", width: "100%", margin: "10px 0px" }}>
            <div style={{ width: "35%", fontWeight: "bold" }}>ชื่อ</div>
            <div style={{ width: "65%" }}>{patient.fullname || "-"}</div>
          </div>
          <div style={{ display: "flex", width: "100%", margin: "10px 0px" }}>
            <div style={{ width: "35%", fontWeight: "bold" }}>อายุ</div>
            <div style={{ width: "65%" }}>{patient.full_age || "-"}</div>
          </div>
          <div style={{ display: "flex", width: "100%", margin: "10px 0px" }}>
            <div style={{ width: "35%", fontWeight: "bold" }}>วันเกิด</div>
            <div style={{ width: "65%" }}>{patient.birthdate || "-"}</div>
          </div>
          <div style={{ display: "flex", width: "100%", margin: "10px 0px" }}>
            <div style={{ width: "35%", fontWeight: "bold" }}>เพศ</div>
            <div style={{ width: "65%" }}>{patient.gender_name || "-"}</div>
          </div>

          {/* Segment Group */}
          <div style={{ marginTop: "20px" }}>
            <div style={{ fontWeight: "bold", color: "#4183c4" }}>Segment group</div>
            <ul>
              <li>{patient.segment_group || "-"}</li>
              {/* Add more list items here if there are multiple segment groups */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const recordView = (context: MainHIS) => (layoutProps: any) => {
  return (
    <ErrorBoundary>
      <Tab
        tabWidth="150px"
        contentWidth="0"
        selectedTabIndex={context.state.selectedRecordViewIndex}
        // hideTabMenu={layoutProps.hideTabMenu}
        onTabChange={(index: number) => {
          context.controller.setProp("selectedRecordViewIndex", index);
        }}
        data={[
          {
            key: "HealthMonitor",
            name: "Health monitor",
            hide: false,
            render: () => (
              <CardHealthMonitor
                key="vitalSign"
                closeable={false}
                toggleable={false}
                buttonLoadCheck={context.state.buttonLoadCheck}
                setProp={context.controller.setProp}
                selectedPatient={context.state.HealthMemberSequence?.selectedPatientSegment}
              />
            ),
          },
          {
            key: "Telehealth_Appointment",
            name: "Telehealth",
            render: () => (
              <div className="chatBox">
                <ChatBox
                  ref={(ref: any) => (context.chatBoxRef = ref)}
                  // controller
                  controller={context.chatController}
                  classifyController={context.classifyController}
                  diagFormController={context.diagFormController}
                  // data
                  userId={context.controller.data.user?.toString() || ""}
                  apiToken={context.controller.apiToken}
                  division_id={context.state.selectedDivision?.id}
                  isPatient={false}
                  nullId={false}
                  readOnly={false}
                  fullname={"fullname"}
                  username={"username"}
                  patientData={{}}
                  chatBotLogic={false}
                  patientId={context.state.selectedPatient?.id}
                  selectedPatient={context.state.HealthMemberSequence?.selectedPatientSegment}
                  match={{
                    params: {
                      chatChannelId: context.state.HealthMemberSequence?.selectedPatientSegment?.chat_channel,
                    },
                  }}
                  // callback

                  onSetChannelName={(props: any) => { }}
                  onNavigationMessage={({ content }: any) => content}
                  onCallGetLastMessage={() => { }}
                  onCloseVideoCall={() => { }}
                  onOpenVideoCall={(url: string) => {
                    context.controller.setProp("closeOverriding", false);
                    context.controller.setProp("openVideoCallModal", true);
                    context.controller.setProp("videoCallRoom", url);
                    // context.controller.setProp(
                    //   "selectedEncounter",
                    //   context.state.selectedEncounter
                    // );
                    context.controller.setProp(
                      "currChatChannelId",
                      context.state.HealthMemberSequence?.selectedPatientSegment?.chat_channel
                    );
                  }}
                  enHasNoDiv={() => { }}
                  enHasDiv={() => { }}
                />
              </div>
            ),
          },
        ]
          ?.filter((item: any) => CONFIG.SCREEN_ITEM?.find((conf: any) => conf.name === item.key))
          ?.filter((item: any) => !item.hide)}
      />
    </ErrorBoundary>
  );
};
