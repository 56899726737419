import WasmController from "react-lib/frameworks/WasmController";
import PatientData from "react-lib/apps/common/PatientData";
// APIs
import CheckPermissionLabResultConfidential from "issara-sdk/apis/CheckPermissionLabResultConfidential_apps_LAB";
import LabDivisionList from "issara-sdk/apis/LabDivisionList_apps_LAB";
import CentralLabTestFilterResultList from "issara-sdk/apis/CentralLabTestFilterResultList_apps_LAB";
import CentralLabResultComparable from "issara-sdk/apis/CentralLabResultComparable_apps_LAB";
import InterfaceSummaryReportDetail from "issara-sdk/apis/InterfaceSummaryReportDetail_apps_LAB";
import CentralLabOrderSimplifiedList from "issara-sdk/apis/CentralLabOrderSimplifiedList_apps_LAB";
import CentralLabOrderSimplifiedDetail from "issara-sdk/apis/CentralLabOrderSimplifiedDetail_apps_LAB";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import SpecimenContainerPrintSticker from "issara-sdk/apis/SpecimenContainerPrintSticker_apps_LAB";
import SpecimenContainerEndCollectSpecimen from "issara-sdk/apis/SpecimenContainerEndCollectSpecimen_apps_LAB";
import SpecimenContainerDetail from "issara-sdk/apis/SpecimenContainerDetail_apps_LAB";
import SpecimenContainerActionLogList from "issara-sdk/apis/SpecimenContainerActionLogList_apps_LAB";
import CentralLabOrderDetail from "issara-sdk/apis/CentralLabOrderDetail_apps_LAB";
import CentralLabOrderItemEditResult from "issara-sdk/apis/CentralLabOrderItemEditResult_apps_LAB";
import CentralLabResultCreateList from "issara-sdk/apis/CentralLabResultCreateList_apps_LAB";
import CentralLabResult from "issara-sdk/apis/CentralLabResultList_apps_LAB";
import CentralLabResultConfidentialList from "issara-sdk/apis/CentralLabResultConfidentialList_apps_LAB";
import ChoiceView from "issara-sdk/apis/ChoiceView_core";
import DoctorLabOrderList from "issara-sdk/apis/DoctorLabOrderList_apps_LAB";
import DefaultBarcodeView from "issara-sdk/apis/DefaultBarcodeView_core";
import CentralLabOrderItemResultPrintView from "issara-sdk/apis/CentralLabOrderItemResultPrintView_apps_LAB";
import CentralLabResultByOrderGroupbyTestList from "issara-sdk/apis/CentralLabResultByOrderGroupbyTestList_apps_LAB";
import PatientDetailViewByHn from "issara-sdk/apis/PatientDetailViewByHn_apps_REG";
import CrossMatchLabScheduleList from "issara-sdk/apis/CrossMatchLabScheduleList_apps_ORM";
// seq
import * as OrderCentralLabI from "./sequence/OrderCentralLab";
import * as HistoryCentralLabI from "./sequence/HistoryCentralLab";
import * as LabReportI from "./sequence/LabReport";
import * as OPDLabSummaryI from "./sequence/OPDLabSummary";

import getPdfMake from "react-lib/appcon/common/pdfMake";

import _ from "react-lib/compat/lodashplus";
// FORM
import FormStickerSpecimen from "./FormStickerSpecimen";
import FormLabRequest from "./FormLabRequest";
import FormLabCrossMatchQueue from "./FormLabCrossMatchQueue";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { createPDFLabOrderQueue } from "react-lib/apps/HISV3/LAB/createPDFLabOrderQueue";
import moment from "moment";

export type State = {
  providerEmployeeInfo?: any;
  patientData?: PatientData | null;
  labCode?: any;
  labDetail?: any;
  labError?: any;
  reportDetail?: any;
  selectedPatient?: any;
  django?: any;
  labOrderQueue?: {
    selectedBarcode?: string | null;
    selectedHN?: string | null;
    selectedFirstname?: string | null;
    selectedLastname?: string | null;
    selectedStartDate?: string | null;
    selectedEndDate?: string | null;
    selectedStartDateCrossMatch?: string | null;
    selectedEndDateCrossMatch?: string | null;
    selectedEncounterType?: string | null;
    selectedApprove?: string;
    checkSpecimen?: boolean;
    selectedStatus?: string | null;
    selectedLabType?: string | null;
    selectedUrgency?: string | null;
    selectedDivision?: string | null;
    selectedDoctor?: string | null;
    labOrderQueueList?: any[];
    labCrossMatchOrderQueue?: any[];
    showCrossMatch?: boolean;
    checkSpecimenReject?: boolean;
    openLabReport?: boolean;
  };
  selectedLabOrderWorking?: any;
  selectedContainer?: any;
  labOrderItems?: any;
  doctorLabOrderList?: any;
  specimenLogs?: any[];
  centralLabResultList?: any;
  labNotify?: {
    rejection?: boolean;
    request?: boolean;
  };
  selectedLabOrderId?: number;
  errorMessage?: any;
  permissionLabResultConfidential?: boolean;
  // CardSpecimenCollector
  specimenCollectorMasterData?: {
    labDivisionOptions?: any[];
    labItemStatusOptions?: any[];
    specimenTube?: any[];
  };

  // To be deprecated
  selectedLabOrderItem?: any;
  selectedLabOrderItemChild?: any;
} & OrderCentralLabI.State &
  HistoryCentralLabI.State &
  LabReportI.State &
  OPDLabSummaryI.State;

export const StateInitial: State = {
  permissionLabResultConfidential: false,
  // CardSpecimenCollector
  specimenCollectorMasterData: {
    labDivisionOptions: [],
    labItemStatusOptions: [],
    specimenTube: [],
  },

  labOrderQueue: {
    selectedStartDate: formatDate(moment()),
    selectedEndDate: formatDate(moment()),
    selectedStartDateCrossMatch: formatDate(moment()),
    selectedEndDateCrossMatch: formatDate(moment().add(2, "weeks")),
    showCrossMatch: false,
    selectedApprove: "ALL"
  },

  labNotify: {
    rejection: false,
    request: false,
  },

  // ...OrderCentralLabI.StateInitial,
  // ...HistoryCentralLabI.StateInitial,
  // ...LabReportI.StateInitial,
  // ...OPDLabSummaryI.StateInitial,
};

export type Event =
  | { message: "GetLabCode"; params: any }
  | { message: "GetLabDetail"; params: any }
  | { message: "GetLabInterfaceSummary"; params: any }
  | { message: "GetLabOrderQueue"; params: any }
  | { message: "SelectLabOrderWorking"; params: any }
  | { message: "SelectEncounter"; params: any }
  | { message: "LabOrderArrive"; params: any }
  | { message: "SaveLabResult"; params: any }
  | { message: "AuthorizeLabResult"; params: any }
  | {
    message: "GetDoctorLabOrderList";
    params: { progression_cycle: number; emr: number };
  }
  | { message: "GetCentralLabOrder"; params: any }
  | { message: "CentralLabResultList"; params: any }
  | { message: "ListCentralLabResultConfidential"; params: any }
  | { message: "GetMasterData"; params: any }
  | { message: "CheckPermissionLabConfidential"; params: any }
  // ------------------------------ Set
  | { message: "SetLabOrderQueueData"; params: any }
  // ------------------------------ CardSpecimenCollector
  | { message: "SpecimenCollectorMasterData"; params: any }
  | { message: "SpecimenLogList"; params: any }
  | { message: "PrintLabSticker"; params: any }
  | { message: "ReceiveSpecimen"; params: any }
  | { message: "RejectSpecimen"; params: any }
  | { message: "EndCollectSpecimen"; params: any }
  | { message: "CancelCollectSpecimen"; params: any }
  | { message: "ScanBarcodeStickerSpecimen"; params: any }
  | { message: "HandlePrintStickerSpecimen"; params: any }
  | { message: "HandlePrintLabSummary"; params: any }
  | OrderCentralLabI.Event;

export type Data = {
  division?: number;
  device?: number;
  userProfile?: Record<string, any>
} & OrderCentralLabI.Data &
  HistoryCentralLabI.Data;

export const DataInitial = {
  ...OrderCentralLabI.DataInitial,
  ...HistoryCentralLabI.DataInitial,
  ...LabReportI.DataInitial,
  ...OPDLabSummaryI.DataInitial,
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetLabOrderQueue: Handler = async (controller, params) => {
  // Master data
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["doctor", {}],
      ],
    },
  });

  let state = controller.getState();

  if (params.action === "search") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const filterParams = {
      hn: params.hn || state.labOrderQueue?.selectedHN || "",
      first_name: state.labOrderQueue?.selectedFirstname || "",
      last_name: state.labOrderQueue?.selectedLastname || "",
      order_div:
        state.labOrderQueue?.selectedDivision === "ALL"
          ? ""
          : state.labOrderQueue?.selectedDivision,
      order_by:
        state.labOrderQueue?.selectedDoctor === "ALL"
          ? ""
          : state.labOrderQueue?.selectedDoctor,
      encounter_type:
        state.labOrderQueue?.selectedEncounterType === "ALL"
          ? ""
          : state.labOrderQueue?.selectedEncounterType,
      full_access_number: state.labOrderQueue?.selectedBarcode || "",
      status:
        state.labOrderQueue?.selectedStatus === "ALL"
          ? ""
          : state.labOrderQueue?.selectedStatus,
      start_date: state.labOrderQueue?.selectedStartDate || "",
      end_date: state.labOrderQueue?.selectedEndDate || "",
      urgency:
        state.labOrderQueue?.selectedUrgency === "ALL"
          ? ""
          : state.labOrderQueue?.selectedUrgency,
      not_collect_specimen: state?.labOrderQueue?.checkSpecimen || "",
      lab_type:
        state.labOrderQueue?.selectedLabType === "ALL"
          ? ""
          : state.labOrderQueue?.selectedLabType,
      specimen_rejected: state.labOrderQueue?.checkSpecimenReject,
      only_collect_specimen: true,
      sort: "asc"
    };

    const labOrderList = await CentralLabOrderSimplifiedList.list({
      params: {
        ...filterParams,
        exclude_blood_bank: true,
        approve_status: state.labOrderQueue?.selectedApprove
      },
      apiToken: controller.apiToken,
    });

    if (labOrderList[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: labOrderList[1] },
          },
        });
      }
      return controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: labOrderList[1] },
        },
      });
    }

    // #let crossMatchOrderData = [];
    // if (state.labOrderQueue?.showCrossMatch) {
    //   const labCrossMatchOrderList = await CentralLabOrderSimplifiedList.list({
    //     params: {
    //       ...filterParams,
    //       include_appointment: true,
    //       start_date_cross_match:
    //         state.labOrderQueue?.selectedStartDateCrossMatch || "",
    //       end_date_cross_match:
    //         state.labOrderQueue?.selectedEndDateCrossMatch || "",
    //       lab_speciality: "BB_CROSS_MATCH",
    //     },
    //     apiToken: controller.apiToken,
    //   });
    //   if (labCrossMatchOrderList[1]) {
    //     if (params.card) {
    //       controller.setState({
    //         errorMessage: {
    //           ...state.errorMessage,
    //           [params.card]: { error: labCrossMatchOrderList[1] },
    //         },
    //       });
    //     }
    //     return controller.setState({
    //       buttonLoadCheck: {
    //         ...state.buttonLoadCheck,
    //         [params.buttonLoadKey]: "ERROR",
    //       },
    //       errorMessage: {
    //         ...state.errorMessage,
    //         [params.card]: { error: labCrossMatchOrderList[1] },
    //       },
    //     });
    //   }
    //   crossMatchOrderData = labCrossMatchOrderList[0]?.items || [];
    // }

    return controller.setState({
      successMessage: {
        ...state.successMessage,
        [params?.card]: labOrderList[0],
      },
      errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
      labOrderQueue: {
        ...state.labOrderQueue,
        // labCrossMatchOrderQueue: crossMatchOrderData,
        labOrderQueueList: labOrderList[0]?.items || [],
      },
    });
  } else if (params?.action === "CROSS_MATCH_SEARCH") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const filterParams = {
      hn: params.hn || state.labOrderQueue?.selectedHN || "",
      first_name: state.labOrderQueue?.selectedFirstname || "",
      last_name: state.labOrderQueue?.selectedLastname || "",
      order_div:
        state.labOrderQueue?.selectedDivision === "ALL"
          ? ""
          : state.labOrderQueue?.selectedDivision,
      order_by:
        state.labOrderQueue?.selectedDoctor === "ALL"
          ? ""
          : state.labOrderQueue?.selectedDoctor,
      encounter_type:
        state.labOrderQueue?.selectedEncounterType === "ALL"
          ? ""
          : state.labOrderQueue?.selectedEncounterType,
      full_access_number: state.labOrderQueue?.selectedBarcode || "",
      status:
        state.labOrderQueue?.selectedStatus === "ALL"
          ? ""
          : state.labOrderQueue?.selectedStatus,
      urgency:
        state.labOrderQueue?.selectedUrgency === "ALL"
          ? ""
          : state.labOrderQueue?.selectedUrgency,
      not_collect_specimen: state?.labOrderQueue?.checkSpecimen || "",
      lab_type:
        state.labOrderQueue?.selectedLabType === "ALL"
          ? ""
          : state.labOrderQueue?.selectedLabType,
      specimen_rejected: state.labOrderQueue?.checkSpecimenReject,
      // only_collect_specimen: true,  // #69602
      only_blood_bank: true  // #69602
    };

    const labCrossMatchOrderList = await CentralLabOrderSimplifiedList.list({
      params: {
        ...filterParams,
        include_appointment: true,
        start_date_cross_match:
          state.labOrderQueue?.selectedStartDateCrossMatch || "",
        end_date_cross_match:
          state.labOrderQueue?.selectedEndDateCrossMatch || "",
        // lab_speciality: "BB_CROSS_MATCH",  // #69602
        approve_status: state.labOrderQueue?.selectedApprove
      },
      apiToken: controller.apiToken,
    });
    if (labCrossMatchOrderList[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: labCrossMatchOrderList[1] },
          },
        });
      }
      return controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: labCrossMatchOrderList[1] },
        },
      });
    }
    let crossMatchList: any[] = [];
    if (labCrossMatchOrderList[0]?.items?.length > 0) {
      crossMatchList = labCrossMatchOrderList[0]?.items.sort(
        (a: any, b: any) => {
          return moment(a.perform_date, "YYYY-MM-DD") >
            moment(b.perform_date, "YYYY-MM-DD")
            ? 1
            : -1;
        }
      );
    }

    return controller.setState({
      successMessage: {
        ...state.successMessage,
        [params?.card]: labCrossMatchOrderList[0],
      },
      errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
      labOrderQueue: {
        ...state.labOrderQueue,
        showCrossMatch: true,
        labCrossMatchOrderQueue: crossMatchList,
      },
    });
  } else if (params?.action === "PRINT") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });
    const filterParams = {
      hn: params.hn || state.labOrderQueue?.selectedHN || "",
      first_name: state.labOrderQueue?.selectedFirstname || "",
      last_name: state.labOrderQueue?.selectedLastname || "",
      order_div:
        state.labOrderQueue?.selectedDivision === "ALL"
          ? ""
          : state.labOrderQueue?.selectedDivision,
      order_by:
        state.labOrderQueue?.selectedDoctor === "ALL"
          ? ""
          : state.labOrderQueue?.selectedDoctor,
      encounter_type:
        state.labOrderQueue?.selectedEncounterType === "ALL"
          ? ""
          : state.labOrderQueue?.selectedEncounterType,
      full_access_number: state.labOrderQueue?.selectedBarcode || "",
      status:
        state.labOrderQueue?.selectedStatus === "ALL"
          ? ""
          : state.labOrderQueue?.selectedStatus,
      start_date: state.labOrderQueue?.selectedStartDate || "",
      end_date: state.labOrderQueue?.selectedEndDate || "",
      urgency:
        state.labOrderQueue?.selectedUrgency === "ALL"
          ? ""
          : state.labOrderQueue?.selectedUrgency,
      not_collect_specimen: state?.labOrderQueue?.checkSpecimen || "",
      lab_type:
        state.labOrderQueue?.selectedLabType === "ALL"
          ? ""
          : state.labOrderQueue?.selectedLabType,
      specimen_rejected: state.labOrderQueue?.checkSpecimenReject,
      only_collect_specimen: true,
    };

    const [res, error] = await CentralLabOrderSimplifiedList.list({
      extra: {
        division: controller.data.division,
      },
      params: {
        ...filterParams,
        approve_status: state.labOrderQueue?.selectedApprove
      },
      apiToken: controller.apiToken,
    });

    console.log("res", res);
    let selected = res.items?.filter((item: any) =>
      params.ids.includes(item.id)
    );
    let allHN = selected.map((item: any) => item.hn);
    let allLabId = selected.map((item: any) => item.id);
    let uniqueHN = [...new Set(allHN)];
    let promissHN = uniqueHN.map((hn) => {
      return PatientDetailViewByHn.get({
        extra: {
          division: controller.data.division,
        },
        params: params,
        apiToken: controller.apiToken,
        hn: hn,
      });
    });

    let promissLabDetail = allLabId.map((labId: any) => {
      return CentralLabOrderSimplifiedDetail.retrieve({
        pk: labId,
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
        params: params,
      });
      return;
    });

    console.log("promissLabDetail ", promissLabDetail);

    let dataHNs = await Promise.all(promissHN).catch((error) => {
      return error;
    });

    // 1 3 pages ; [ 1 of 3 , 2 of 3 , 3 of 3 ]
    // 2 1 pages
    // 3 2 pages

    let dataLabDetails = await Promise.all(promissLabDetail);
    console.log("promiss all ", dataLabDetails);

    let reports = selected.map((item: any) => {
      const lab = dataLabDetails.find((lab: any) => lab[0]?.id === item.id)?.[0];
      // console.log("item ", item);
      console.log("-------");
      console.log("item : ", item);
      console.log("promissLabDetail ", promissLabDetail);
      const data = Object.assign({
        data: { ...item, note: lab?.note },
        hn_data: dataHNs.find((dataHN: any) => dataHN?.[0]?.hn === item.hn)?.[0],
        lab_data: lab,
        start_date: state.labOrderQueue?.selectedStartDate,
        end_date: state.labOrderQueue?.selectedEndDate,
        urgency: state.labOrderQueue?.selectedUrgency,
        username: state.django?.user?.full_name,
        lab_type: item?.lab_type_label?.replaceAll("l", "L") || "",
      });

      return createPDFLabOrderQueue(data);
    });

    //merge pdf

    console.log("reports: ", reports);
    // let dataReport = await Promise.all(reports);
    // console.log("dataReport: ", dataReport);

    // let baseData =  dataReport?.map(async (item: any) => {

    //   let promiseObject = item?.getBase64((base: any) => {

    //     console.log("base :", base);
    //     console.log("item in : :", item);
    //     return base;
    //   });

    //   console.log("promiseObject : ", promiseObject);

    // });

    //   /// pdf-lib
    // const mergedPdf = await PDFDocument.create();

    // for (let document of bufferData) {
    //  document = await PDFDocument.load(document);

    //  const copiedPages = await mergedPdf.copyPages(document, document.getPageIndices());
    //  copiedPages.forEach((page) => mergedPdf.addPage(page));
    // }

    //  let merge = await mergedPdf.save();
    //  console.log("merge :",merge)

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    });
    return;

    // console.log(" patientData : ", state.providerEmployeeInfo);

    // const [hnRes, hnError] = await PatientDetailViewByHn.get({
    //   extra: {
    //     division: controller.data.division,
    //   },
    //   params: params,
    //   apiToken: controller.apiToken,
    //   hn: state.labOrderQueue?.labOrderQueueList[0]?.hn || " " || undefined,
    // });

    // const [labRes, labError] = await CentralLabResultByOrderGroupbyTestList.get({

    //   params: {
    //     order_id: 3471,
    //   },
    //   apiToken: controller.apiToken,

    // });
    // const [labRes, labError] = await CentralLabOrderSimplifiedDetail.retrieve({
    //   pk: state.labOrderQueue?.labOrderQueueList[0]?.id || " " || undefined,
    //   apiToken: controller.apiToken,
    //   extra: {
    //     division: controller.data.division,
    //   },
    //   params: params,
    // });
    // console.log(" CentralLabResultByOrderGroupbyTestList :", labRes);
    // if (res) {
    //   controller.setState({
    //     buttonLoadCheck: {
    //       ...state.buttonLoadCheck,
    //       [params.buttonLoadKey]: "SUCCESS",
    //     },
    //   });
    //   console.log("res ", res);

    //   const data = Object.assign({
    //     data: res?.items,
    //     hn_data: hnRes,
    //     lab_data: labRes,

    //     start_date: state.labOrderQueue?.selectedStartDate,
    //     end_date: state.labOrderQueue?.selectedEndDate,
    //     urgency: state.labOrderQueue?.selectedUrgency,
    //     username: state.providerEmployeeInfo?.employee_info?.full_name,
    //   });

    //   createPDFLabOrderQueue(data);
    // } else {
    //   controller.setState({
    //     buttonLoadCheck: {
    //       ...state.buttonLoadCheck,
    //       [params.buttonLoadKey]: "SUCCESS",
    //     },
    //   });
    // }
  } else if (params?.action === "CROSS_MATCH_PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    // PRINT PROCESS
    const crossMatchData = await CrossMatchLabScheduleList.get({
      apiToken: controller.apiToken,
      params: {
        start_date: state.labOrderQueue?.selectedStartDateCrossMatch || "",
        end_date: state.labOrderQueue?.selectedEndDateCrossMatch || "",
      },
    });

    if (crossMatchData[1]) {
      return controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: crossMatchData[1] },
        },
      });
    }

    let data = {
      start_date: crossMatchData[0].start_date,
      end_date: crossMatchData[0].end_date,
      labData: crossMatchData[0].items,
      printed_user: state.django?.user?.full_name || "",
    };

    let docDef: any = { content: [] };
    docDef = await FormLabCrossMatchQueue({ ...data });
    (await getPdfMake(true)).createPdf(docDef).open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    });
    return;
  } else if (params.action === "OPEN_LAB_REPORT") {
    const [patient] = await PatientDetailViewByHn.get({
      params: params,
      apiToken: controller.apiToken,
      hn: params.order.hn,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      labOrderQueue: {
        ...state.labOrderQueue,
        openLabReport: true,
      },
      selectedLabOrderWorking: params.order,
      selectedPatient: patient
    })
  }
};

export const SelectLabOrderWorking: Handler = async (controller, params) => {
  const encounter = await EncounterDetail.retrieve({
    pk: params?.order?.encounter,
    apiToken: controller.apiToken,
  });
  console.log(encounter[1] ? encounter[1] : encounter[0]);
  if (encounter[1])
    return console.log("Cannot find encounter for lab order working");

  controller.handleEvent({
    message: "SelectEncounter",
    params: { ...params, encounter: encounter[0] },
  });

  controller.setState({ selectedLabOrderId: params?.order?.id });

  refreshSpecimenCollection(controller, params?.order?.id);
};

export const SaveLabResult: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "saveLabResult") {
    const resultItems = (state.labOrderItems || [])
      .flatMap((item: any) => [item, ...item.children])
      .filter(
        (item: any) => item.id && (item.value !== "" || item.comment !== "")
      )
      .map((item: any) => ({
        id: item.result_id,
        order_item: item.id,
        value: item.value,
        comment: item.comment,
      }));
    const saveResult = await CentralLabResultCreateList.post({
      data: {
        items: resultItems,
        action: "EDIT",
        username: params?.username,
        password: params?.password,
      },
      apiToken: controller.apiToken,
    });
    console.log(saveResult[1] ? saveResult[1] : saveResult[0]);
    if (saveResult[0]) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          "SaveLabResult": saveResult[0],
        },
      });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          "SaveLabResult": saveResult[1],
        },
      });
    }

    // Sync lab result
    const labResult = await CentralLabOrderItemEditResult.get({
      params: { order_id: state.selectedLabOrderWorking?.id },
      apiToken: controller.apiToken,
    });
    console.log(labResult[1] ? labResult[1] : labResult[0]);
    const labOrderItems = labResult[0]?.items || [];
    controller.setState({
      labOrderItems: labOrderItems,
    });
  } else if (params.action === "clearMessage") {
    controller.setState({
      successMessage: { ...state.successMessage, "SaveLabResult": null },
      errorMessage: { ...state.errorMessage, "SaveLabResult": null },
    });
  } else if (params.action === "printLab") {
  }
};

export const AuthorizeLabResult: Handler = async (controller, params) => {
  if (params?.authorizeItems.length === 0)
    return console.log("No items to authorize");
  const state = controller.getState();
  if (params?.action === "saveAuthorizeLab") {
    const items = params?.authorizeItems.map((item: any) => ({
      id: item.result_id,
      order_item: item.id,
      value: item.value,
      comment: item.comment,
    }));
    const authorizeResult = await CentralLabResultCreateList.post({
      data: {
        items: items,
        action: "AUTHORIZE",
        username: params?.username,
        password: params?.password,
      },
      apiToken: controller.apiToken,
    });
    console.log(authorizeResult[1] ? authorizeResult[1] : authorizeResult[0]);
    if (authorizeResult[0]) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          "AuthorizeLab": authorizeResult[0],
        },
      });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          "AuthorizeLab": authorizeResult[1],
        },
      });
    }

    // Sync lab result
    const labResult = await CentralLabOrderItemEditResult.get({
      params: { order_id: state.selectedLabOrderWorking?.id },
      apiToken: controller.apiToken,
    });
    console.log(labResult[1] ? labResult[1] : labResult[0]);
    const labOrderItems = labResult[0]?.items || [];
    controller.setState({
      labOrderItems: labOrderItems,
    });
  } else if (params.action === "clearMessage") {
    controller.setState({
      successMessage: { ...state.successMessage, "AuthorizeLab": null },
      errorMessage: { ...state.errorMessage, "AuthorizeLab": null },
    });
  }
};

export const LabOrderArrive: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.selectedLabOrderWorking)
    return console.log("No selected lab order");
  if (params?.action === "getLabOrderArrive") {
    const orderArrive = await CentralLabOrderDetail.patch({
      pk: state.selectedLabOrderWorking.id,
      data: { action: "ARRIVE" },
      apiToken: controller.apiToken,
    });
    console.log(orderArrive[1] ? orderArrive[1] : orderArrive[0]);

    // const labOrderDetail = await CentralLabOrderSimplifiedDetail.retrieve({
    //   pk: params?.labOrderId || state.selectedLabOrderWorking.id,
    //   apiToken: controller.apiToken,
    // });

    if (orderArrive[0]) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          "LabOrderArrive": orderArrive[0],
        },
        // selectedLabOrderWorking: labOrderDetail[0],
      });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          "LabOrderArrive": orderArrive[1],
        },
        // selectedLabOrderWorking: labOrderDetail[0],
      });
    }

    controller.handleEvent({
      message: "GetLabOrderQueue",
      params: { action: "search", hn: state.selectedEncounter?.hn },
    });

    LabOrderArrive(controller, { action: "getDataLabOrderArrive" });
    console.log("orderArrive", orderArrive[0], orderArrive[1]);
  } else if (params?.action === "getDataLabOrderArrive") {
    const [labOrderDetail] = await GetCentralLabOrderSimplified(controller, {
      orderId: params?.labOrderId || state.selectedLabOrderWorking.id,
    });

    if (labOrderDetail) {
      updateStatusLabOrderQueue(controller, { order: labOrderDetail });

      controller.setState({
        selectedLabOrderWorking: { ...labOrderDetail },
      });
    }
  } else if (params.action === "clearMessage") {
    controller.setState({
      successMessage: { ...state.successMessage, "LabOrderArrive": null },
      errorMessage: { ...state.errorMessage, "LabOrderArrive": null },
    });
  }
};

export const GetLabInterfaceSummary: Handler = async (controller, { cloi }) => {
  if (cloi) {
    const result = InterfaceSummaryReportDetail.get({
      ono: cloi,
      apiToken: controller.apiToken,
    });
    controller.setState({ reportDetail: result[0] });
  } else {
    controller.setState({ reportDetail: null });
  }
};

export const GetLabCode: Handler = async (controller, params) => {
  let patientId = params.patientId;
  if (patientId) {
    controller.setState({ labDetail: {} });
    let query_params: any = {};
    if (patientId) {
      query_params.patient = patientId;
    }
    if (params.divisionList) {
      query_params.lab_division = params.divisionList;
    }
    const labDivision = await LabDivisionList.list({
      apiToken: controller.apiToken,
    });
    const filterResult = await CentralLabTestFilterResultList.list({
      params: query_params,
      apiToken: controller.apiToken,
    });
    controller.setState({
      labCode: {
        division: labDivision[0],
        filterResult: filterResult[0],
      },
    });
  } else {
    controller.setState({ labCode: {} });
  }
};

export const GetLabDetail: Handler = async (
  controller,
  { LabCode, startDate, stopDate, username, password }
) => {
  const state = controller.getState();
  let encounterId =
    state.patientData &&
    state.patientData.ENCOUNTER &&
    state.patientData.ENCOUNTER.encounter_id;
  let patientId = state.patientData && state.patientData.patient_id;
  if (encounterId) {
    controller.setState({ labDetail: {} });
    let params: any = {
      columns: [],
      items: [],
    };
    if (startDate) {
      params.from_date = startDate;
    }
    if (stopDate) {
      params.to_date = stopDate;
    }
    if (LabCode) {
      params.products = LabCode;
    }
    if (patientId) {
      params.patient = patientId;
    }
    if (username) {
      params.username = username;
    }
    if (password) {
      params.password = password;
    }
    const labResultComparable = await CentralLabResultComparable.post({
      data: params,
      apiToken: controller.apiToken,
    });
    const [labDetail, labDetailError] = [
      { labCompare: labResultComparable[0] },
      labResultComparable[1],
    ];
    if (labDetail && labDetail.labCompare && !_.isEmpty(labDetail.labCompare)) {
      controller.setState({ labDetail: labDetail });
    } else {
      controller.setState({ labError: labDetailError });
    }
  }
};

export const GetDoctorLabOrderList: Handler = async (controller, params) => {
  const [response, error, network] = await DoctorLabOrderList.list({
    params: params,
    apiToken: controller.apiToken,
  });

  if (response) {
    let labOrderList = response.items.map(async (item: any) => {
      const centralLabOrderDetail = await CentralLabOrderDetail.retrieve({
        pk: item.id,
        apiToken: controller.apiToken,
      });

      let labOrderDetail = await Promise.all(centralLabOrderDetail);

      return {
        ...item,
        order_by: labOrderDetail?.[0]?.order_by,
      };
    });

    let doctorLabOrderList = await Promise.all(labOrderList);

    controller.setState({
      doctorLabOrderList: { ...response, items: doctorLabOrderList },
    });
  }
  // controller.setState({ doctorLabOrderList: response });
};

export const GetCentralLabOrder: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!params.id) return console.log("No selected lab order");
  const [response, error, network] = await CentralLabOrderDetail.retrieve({
    pk: params.id,
    apiToken: controller.apiToken,
  });
  console.log(response.order_items);
  controller.setState({
    OrderCentralLabSequence: {
      ...state.OrderCentralLabSequence,
      sequenceIndex: "SelectLabTest",
      labOrder: response,
    },
  });
};

const GetCentralLabOrderSimplified: Handler = async (controller, params) => {
  const getLabOrderSimplified = CentralLabOrderSimplifiedDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.orderId,
  });

  const getLabOrderGroupByTest = CentralLabResultByOrderGroupbyTestList.get({
    params: {
      order_id: params.orderId,
      ab_type: "OUTLAB",
    },
    apiToken: controller.apiToken,
  });

  const [labOrderSimplified, labOrderGroupByTest] = await Promise.all([
    getLabOrderSimplified,
    getLabOrderGroupByTest,
  ]);

  if (labOrderSimplified[0]) {
    labOrderSimplified[0].specimen_containers =
      labOrderSimplified[0].specimen_containers.map((item: any) => ({
        ...item,
        is_authorized:
          labOrderGroupByTest[0]?.items?.find(
            (acc: any) =>
              acc.specimen_name === item.specimen_name &&
              item.lab_test.includes(acc.name) &&
              item.lab_code.includes(acc.lab_code) &&
              item.status_label === acc.central_lab_order_item_status
          )?.central_lab_result_is_authorized ?? null,
      }));
  }

  return labOrderSimplified;
};

export const CentralLabResultList: Handler = async (controller, params) => {
  if (!params.patient) return console.log("No patient_id");
  if (!params.product_id) return console.log("No product_id");
  const [response, error, network] = await CentralLabResult.list({
    params: params,
    apiToken: controller.apiToken,
  });
  controller.setState({ centralLabResultList: response || {} });
};

export const ListCentralLabResultConfidential: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  const [response, error, network] =
    await CentralLabResultConfidentialList.list({
      params: {
        product_id: params.product_id,
        patient: params.patient,
        username: params.username,
        password: params.password,
      }, //  product_id, patient, username, password
      apiToken: controller.apiToken,
    });

  if (error) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.cardKey]: error },
    });
  } else {
    params.onSuccess?.();

    controller.setState({ centralLabResultList: response || {} });
  }
};

export const CheckPermissionLabConfidential: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  const [response, error, network] =
    await CheckPermissionLabResultConfidential.post({
      apiToken: controller.apiToken,
      data: {
        username: params.username,
        password: params.password,
      },
    });

  if (error) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.cardKey]: error },
      permissionLabResultConfidential: false,
    });
  } else {
    params.onSuccess?.();

    controller.setState({
      errorMessage: { ...state.errorMessage, [params.cardKey]: null },
      permissionLabResultConfidential: true,
    });
  }
};

// ------------------------------ Set
export const SetLabOrderQueueData: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({ labOrderQueue: { ...state.labOrderQueue, ...params } });
};

// ------------------------------ CardSpecimenCollector
export const SpecimenCollectorMasterData: Handler = async (controller) => {
  const listLabDivision = LabDivisionList.list({
    apiToken: controller.apiToken,
  });

  const choiceLabItemStatus = ChoiceView.get({
    apiToken: controller.apiToken,
    params: {
      model: "LAB.CentralLabOrderItem",
      field: "status",
    },
  });

  const [labDivision, labItemStatus] = await Promise.all([
    listLabDivision,
    choiceLabItemStatus,
  ]);

  controller.setState({
    specimenCollectorMasterData: {
      labDivisionOptions: labDivision[1]
        ? []
        : mapOptions(labDivision[0]?.items, "name", "name"),
      labItemStatusOptions: labItemStatus[1]
        ? []
        : mapOptions(labItemStatus[0]?.items, "value", "label"),
    },
  });

  const state = controller.getState();

  if (state.selectedLabOrderId) {
    refreshSpecimenCollection(controller, state.selectedLabOrderId);
  }
};

export const SpecimenLogList: Handler = async (controller, params) => {
  if (!params.id) return console.log("No selected specimen container");
  const [response, error, network] = await SpecimenContainerActionLogList.list({
    pk: params.id,
    apiToken: controller.apiToken,
  });
  controller.setState({ specimenLogs: response?.items || [] });
};

export const PrintLabSticker: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "printLab") {
    // refreshSpecimenCollection(controller, state.selectedContainer.order);
    const specimenTube = state.specimenCollectorMasterData?.specimenTube || [];

    if (!specimenTube.length) {
      return;
    }
    const promiseArr = specimenTube.map((item: any) => {
      return SpecimenContainerPrintSticker.patch({
        pk: item.id,
        apiToken: controller.apiToken,
        data: {
          is_robo: params.isRobo || false,
        },
      });
    });

    const response = await Promise.all(promiseArr);
    const isError = response.every((item: any) => item[1]);

    if (isError) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          "PrintLabSticker": response[0][1],
          [params.cardKey]: response[0][1]
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          "PrintLabSticker": response.map((item: any) => item[0]),
        },
      });
    }

    if (!params.isRobo) {
      controller.handleEvent({
        message: "HandlePrintStickerSpecimen",
        params: {
          specimenList: response
            .map((item: any) => item[0])
            .filter((item) => !!item),
        },
      });
    }

    refreshSpecimenCollection(controller, specimenTube[0].order);
    // state.specimenCollectorMasterData?.specimenTube?.forEach(
    //   async (item: any) => {
    //     const printSticker = await SpecimenContainerPrintSticker.patch({
    //       pk: item.id,
    //       apiToken: controller.apiToken,
    //     });
    //     console.log(printSticker[1] ? printSticker[1] : printSticker[0]);

    //     if (printSticker[1]) {
    //       controller.setState({
    //         errorMessage: {
    //           ...state.errorMessage,
    //           ["PrintLabSticker"]: printSticker[1],
    //         },
    //       });
    //     } else {
    //       controller.setState({
    //         successMessage: {
    //           ...state.successMessage,
    //           ["PrintLabSticker"]: printSticker[0],
    //         },
    //       });
    //     }

    //     refreshSpecimenCollection(controller, item.order);
    //   }
    // );
  } else if (params.action === "clearMessage") {
    controller.setState({
      successMessage: { ...state.successMessage, "PrintLabSticker": null },
      errorMessage: { ...state.errorMessage, "PrintLabSticker": null },
    });
  }
};

export const ReceiveSpecimen: Handler = async (controller, params) => {
  const state = controller.getState();

  // if (!state.selectedContainer) {
  //   controller.setState({
  //     errorMessage: {
  //       ...state.errorMessage,
  //       [params.card_key]: "เลือก item ก่อนทำรายการ",
  //     },
  //   });
  //   return;
  // }
  const btnKey = `${params.card_key}_receive`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const specimenTube: any[] =
    state.specimenCollectorMasterData?.specimenTube || [];
  const isSpecimen = specimenTube.every((item) =>
    ["SPECIMEN_COLLECTED"].includes(item.status)
  );
  const order = specimenTube[0].order;

  // เมื่อทุกรายการเป็น speciment เสร็จสิ้น
  if (isSpecimen) {
    const usrPass = { username: controller.data.userProfile?.username, password: params.password };

    const isBlood = specimenTube.some((item) => item.is_blood);

    // เมื่อมีรายการที่เป็น blood และ ไม่ระบุ username or password
    if (isBlood) {
      const userId = await LabReportI.CheckUserCredentials(controller, {
        ...params,
        btnKey,
        cardKey: params.card_key,
      });

      if (!userId) {
        return;
      }
    }

    const lab = specimenTube.find((item) => item.is_blood);
    // check ความถูกต้องของ user pass
    const labErr = await checkoutSpecimen(
      controller,
      isBlood ? lab.id : specimenTube[0].id,
      usrPass
    );

    if (labErr?.__all__) {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "ERROR" },
        errorMessage: { ...state.errorMessage, [params.card_key]: labErr },
      });
    } else {
      const results: any[] = [];

      // deadlocked (SQLExecDirectW)'
      for (const item of specimenTube) {
        const response = await sendSpecimen(
          controller,
          item.id,
          params.card_key
        );

        results.push(response);
      }

      const isError = results.every((error: any) => error);

      // เมื่อ send specimen สำเร็จ
      if (!isError) {
        const promiseArr = specimenTube.map((item) =>
          checkoutSpecimen(controller, item.id, { ...params, ...usrPass })
        );

        const response = await Promise.all(promiseArr);

        const isError = response.every((error: any) => error);

        if (isError) {
          controller.setState({
            buttonLoadCheck: {
              ...state.buttonLoadCheck,
              [`${params.card_key}_reject`]: "ERROR",
            },
            errorMessage: {
              ...state.errorMessage,
              [params.card_key]: response[0],
            },
          });
        } else {
          refreshSpecimenCollection(controller, order);

          controller.setState({
            buttonLoadCheck: {
              ...state.buttonLoadCheck,
              [`${params.card_key}_reject`]: "SUCCESS",
            },
          });
        }
      }
    }
  } else {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "ERROR" },
      errorMessage: {
        ...state.errorMessage,
        [params.card_key]: 'ต้องมี Status เป็น "เก็บ specimen เสร็จสิ้น" จึงจะ receive ได้',
      },
    });
  }
  // if (state.selectedContainer?.status_name === "SPECIMEN_SENT") {
  //   const labErr = await checkoutSpecimen(
  //     controller,
  //     state.selectedContainer.id,
  //     params
  //   );
  //   if (labErr) {
  //     return;
  //   } else {
  //     refreshSpecimenCollection(controller, state.selectedContainer.order);
  //     controller.setState({
  //       buttonLoadCheck: {
  //         ...state.buttonLoadCheck,
  //         [`${params.card_key}_receive`]: "SUCCESS",
  //       },
  //     });
  //   }
  // }
};

export const RejectSpecimen: Handler = async (controller, params) => {
  const state = controller.getState();

  // if (!state.selectedContainer) {
  //   controller.setState({
  //     errorMessage: {
  //       ...state.errorMessage,
  //       [params.card_key]: "เลือก item ก่อนทำรายการ",
  //     },
  //   });
  //   return;
  // }
  const btnKey = `${params.card_key}_reject`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const userId = await LabReportI.CheckUserCredentials(controller, {
    ...params,
    btnKey,
    cardKey: params.card_key,
  });

  if (!userId) {
    return;
  }

  const specimenTube: any[] =
    state.specimenCollectorMasterData?.specimenTube || [];
  const order = specimenTube[0].order;

  const promiseArr = specimenTube.map((item) =>
    SpecimenContainerDetail.patch({
      apiToken: controller.apiToken,
      pk: item.id,
      data: { action: "REJECT_SPECIMEN_SENT", note: params?.note },
    })
  );

  const response = await Promise.all(promiseArr);

  const isError = response.every((item: any) => item[1]);

  if (isError) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "ERROR" },
      errorMessage: { ...state.errorMessage, [params.card_key]: response[0][1] },
    });
  } else {
    refreshSpecimenCollection(controller, order);

    controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" } });
  }

  // "Specimen Center ได้รับ Specimen" หรือ
};

export const EndCollectSpecimen: Handler = async (controller, params) => {
  const state = controller.getState();
  // if (!state.selectedContainer?.id) return console.log("No selected container");
  // if (
  //   params?.username && params?.username !== ""
  //   && params?.password && params?.password !== ""
  // ) {
  if (params.action === "postCollect") {
    const specimenTube = state.specimenCollectorMasterData?.specimenTube || [];
    const order = specimenTube[0].order;
    const isSticker = specimenTube.every((item) =>
      ["SPECIMEN_COLLECTING"].includes(item.status)
    );

    if (isSticker) {
      const userId = await LabReportI.CheckUserCredentials(controller, params);

      const state = controller.getState();

      if (!userId) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            OrderCentralLab: "กรุณาระบุรหัสผ่านให้ถูกต้อง",
          },
          selectedContainer: null,
        });

        return;
      }

      const response = await handlePostCollectSpecimen(controller, {
        ...params,
        username: controller.data.userProfile?.username,
      });

      const isError = response.every((item: any) => item[1]);

      if (isError) {
        const errMsg = response.map((item: any) =>
          !!item[1].message ? item[1].message : item[1]
        );

        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            "OrderCentralLab": errMsg,
            [params.cardKey]: errMsg
          },
          selectedContainer: null,
        });
      } else {
        refreshSpecimenCollection(controller, order);

        controller.setState({
          successMessage: {
            ...state.successMessage,
            "OrderCentralLab": "บันทึกสำเร็จ",
          },
          selectedContainer: null,
        });
      }
    } else {
      const errMsg =
        'ต้องมี Status เป็น "พิมพ์สติ๊กเกอร์แล้ว" จึงจะ collect ได้';

      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          "OrderCentralLab": errMsg,
          [params.cardKey]: errMsg
        },
      });
    }
  } else if (params.action === "clearMessage") {
    controller.setState({
      successMessage: { ...state.successMessage, "OrderCentralLab": null },
      errorMessage: { ...state.errorMessage, "OrderCentralLab": null },
    });
  }
};

export const CancelCollectSpecimen: Handler = async (controller, params) => {
  const state = controller.getState();

  const id = params.containerId;
  const order = params.containerOrder;

  if (!id) return console.log("No selected container");

  if (params?.note && params?.note !== "") {
    const [response, error] = await SpecimenContainerDetail.patch({
      pk: id,
      data: { action: "CANCEL_SPECIMEN_COLLECTED", note: params?.note },
      apiToken: controller.apiToken,
    });

    if (error) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          "OrderCentralLab": error,
          [params.cardKey]: error,
        },
      });
    }

    refreshSpecimenCollection(controller, order);
  }
};

export const ScanBarcodeStickerSpecimen: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  if (params.action === "scan") {
    const barcode = `100${params.searchText || ""}`.trim();

    const barcodeDetail = await DefaultBarcodeView.get({
      barcode: barcode,
      params: "",
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const specimenTube = state.specimenCollectorMasterData?.specimenTube || [];
    const specimen = params.specimenList?.find(
      (item: any) => item?.pk === barcodeDetail?.[0]?.pk
    );
    const checked = specimenTube.find(
      (item) => item?.pk === barcodeDetail?.[0]?.pk
    );

    const selectedContainer = specimen;

    if (!checked && !!specimen) {
      specimenTube.push(specimen);
    }

    controller.setState({
      specimenCollectorMasterData: {
        ...state.specimenCollectorMasterData,
        specimenTube: [...specimenTube],
      },
      selectedContainer: selectedContainer,
    });

    params.callback?.(selectedContainer ? "SUCCESS" : "ERROR", barcodeDetail);
  }
};

export const HandlePrintStickerSpecimen: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  const list: any[] = params.specimenList || [];

  if (!list.length) {
    return;
  }

  const data = list.map((item: any) => {
    const dob = item.birthdate?.replace(
      /\d{4}$/g,
      (year: string) => Number(year) + 543
    );

    return {
      hn: state.selectedEncounter?.hn || "",
      full_name: state.selectedEncounter?.patient_name || "",
      date: item.edited.split(" ")?.[0],
      time: moment(item.edited_utc).format("HH:mm"),
      name: item.specimen_name,
      code: item.barcode,
      age: "45",
      urgency: item.urgency[0],
      dob: dob || "",
    };
  });

  let docDef: any = { content: [] };
  docDef = await FormStickerSpecimen({ data });
  (await getPdfMake()).createPdf(docDef).print();

};

export const HandlePrintLabSummary: Handler = async (controller, params) => {
  const state = controller.getState();

  const specimenItems = params.specimenItems;
  const order: Record<string, any> = state.selectedLabOrderWorking || {};
  const patient: Record<string, any> = state.selectedPatient || state.selectedEncounter || {};
  const lab: Record<string, any> =
    state.labOrderQueue?.labOrderQueueList?.find(
      (item) => order.id === item.id
    ) || order;

  let docDef: any = { content: [] };

  // const data = {
  //   type: lab.lab_type_label,
  //   labId: lab.id,
  //   hn: lab.hn,
  //   fullName: lab.patient_name,
  //   gender: patient.gender === "M" ? "ชาย" : patient.gender === "F" ? "หญิง" : "ไม่ระบุ",
  //   birthdate: patient.birthdate,
  //   fullAge: patient.full_age,
  //   coverage: patient.coverage,
  //   datetime: lab.date,
  //   doctorName: lab.doctor_name,
  //   urgency: lab.highest_urgency === "STAT" ? "STAT" : " ",
  //   items: specimenItems.map((item: any) => {
  //     const labCode: string[] = item.lab_code.split(",");

  //     return {
  //       specimen: item.specimen_name,
  //       // extract lab code ออกมา
  //       ...(labCode.length > 1 ? {
  //         children: labCode.map((value, index) => {
  //           // const labTest: string[] = item.lab_test.match(/(?<=- ).*?(?=\\n|$)/gm)

  //           return {
  //             code: value,
  //             // labTest: labTest[index]
  //           }
  //         }),
  //       } : {
  //         code: item.lab_code,
  //         labTest: item.lab_test
  //       })
  //     }
  //   }).flatMap((item: any) =>
  //     // เพื่อ display table โดยใช้ rowSpan
  //     item.children
  //       ? item.children.map((acc: any, index: number) => ({
  //         ...acc,
  //         specimen: item.specimen,
  //         rowSpan: item.children.length,
  //         first: index === 0,
  //         merge: true,
  //         last: index === item.children.length - 1
  //       }))
  //       : [item]
  //   ),
  //   staff: state.django?.user?.full_name || ""
  // };

  // docDef = FormLabRequest({ ...data });

  // (await getPdfMake()).createPdf(docDef).open()
  await createPDFLabOrderQueue({
    data: { ...lab, note: order.note },
    hn_data: patient,
    lab_data: { specimen_containers: specimenItems },
    username: state.django?.user?.full_name,
    lab_type: lab?.lab_type_label?.replaceAll("l", "L") || "",
  });
};

// utilities
const mapOptions = (list: any[], valueKey = "id", textKey = "name") => {
  return list.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item[textKey],
  }));
};

const refreshSpecimenCollection: Handler = async (
  controller,
  orderId: number
) => {
  const getLabOrderSimplified = GetCentralLabOrderSimplified(controller, {
    orderId,
  });

  const getLabOrderItemEditResult = CentralLabOrderItemEditResult.get({
    apiToken: controller.apiToken,
    params: { order_id: orderId },
  });

  const [labOrderSimplified, labOrderItemEditResult] = await Promise.all([
    getLabOrderSimplified,
    getLabOrderItemEditResult,
  ]);

  const labOrderItems = labOrderItemEditResult[0]?.items || [];
  const selectedLabOrderItem =
    labOrderItems.length > 0 ? labOrderItems[0] : null;

  updateStatusLabOrderQueue(controller, { order: labOrderSimplified[0] });

  controller.setState({
    selectedLabOrderWorking: labOrderSimplified[0],
    labOrderItems: labOrderItems,
    selectedLabOrderItem: selectedLabOrderItem,
    selectedContainer: null,
  });
};

const updateStatusLabOrderQueue: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    labOrderQueue: {
      ...state.labOrderQueue,
      labOrderQueueList: (state.labOrderQueue?.labOrderQueueList || [])?.map(
        (item) =>
          item.id === params.order?.id
            ? {
              ...item,
              status_name: params.order.status_name,
              highest_urgency: params.order.highest_urgency,
            }
            : item
      ),
    },
  });
};

const sendSpecimen = async (controller: any, pk: number, cardKey: string) => {
  const [response, error, network] = await SpecimenContainerDetail.update({
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
    pk: pk,
    data: { action: "SEND_SPECIMEN" },
  });

  if (error) {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${cardKey}_receive`]: "ERROR",
      },
      errorMessage: { ...state.errorMessage, [cardKey]: error },
      selectedContainer: null,
    });
    return true;
  }

  return false;
};

const checkoutSpecimen = async (controller: any, pk: number, params: any) => {
  const [response, error, network] = await SpecimenContainerDetail.update({
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
    pk: pk,
    data: { ...params, action: "CHECKOUT_SPECIMEN" },
  });
  if (error) {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card_key}_receive`]: "ERROR",
      },
      errorMessage: { ...state.errorMessage, [params.card_key]: error },
      selectedContainer: null,
    });
    return error;
  }
  return false;
};

const postCollectSpecimen: Handler = async (controller, params) => {
  if (!params.items.length) {
    return [];
  }

  const results: any[] = [];
  for (const item of params.items) {
    const response = await SpecimenContainerEndCollectSpecimen.post({
      data: {
        username: params.username,
        password: params.password,
        is_blood: item.is_blood,
        // specimen_containers: [state.selectedContainer.id],
        specimen_containers: [item.id],
        auto_ack: false,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    results.push(response);
  }

  return results;
};

const handlePostCollectSpecimen: Handler = async (controller, params) => {
  const state = controller.getState();

  const specimenTube = state.specimenCollectorMasterData?.specimenTube || [];

  const [bloodItems, noBloodItems] = specimenTube.reduce(
    (result, item) => {
      result[item.is_blood ? 0 : 1].push(item);
      return result;
    },
    [[], []]
  );

  const bloodRes: any[] = await postCollectSpecimen(controller, {
    ...params,
    items: bloodItems,
  });

  const userPassErr = bloodRes.find(
    (item: any) => !!item[1]?.username || !!item[1]?.password
  );

  // เมื่อ error ให้ระบุ username passowrd
  if (!!userPassErr) {
    return [userPassErr];
  }

  let noBloodRes: any[] = await postCollectSpecimen(controller, {
    ...params,
    items: noBloodItems,
  });

  return Array.from(
    new Map(
      [...bloodRes, ...noBloodRes].map((item) => [item[1]?.message, item])
    ).values()
  );
};
