import React, { useMemo } from "react";

// UX
import CardStaffSearchUX from "./CardStaffSearchUX";
import { Icon, Pagination } from "semantic-ui-react";
import { FilterType } from "./CardStaffSetting";
import { STAFF_LIMIT } from "./StaffSettingInterface";

// Types
type CardStaffSearchProps = {
  onEvent: (e: any) => any;
  // data
  selectedRow?: any;
  filter: Partial<FilterType>;
  staffList?: {
    items: Record<string, any>[];
    total: number;
    activePage: number;
  };
  // options
  masterOptions?: Record<string, any[]>;
  // config
  organizationFilter?: boolean;
  // callback
  onAddStaff: () => any;
  onSelected: (row: any, isFetchDivision: boolean) => any;
  onChangeValue: (key: string) => (e: any, v: any) => any;
};

const CardStaffSearch = (props: CardStaffSearchProps) => {
  const staffItems = useMemo(() => {
    const divisions = props.masterOptions?.division || [];
    const prenamesTh = props.masterOptions?.prenameTh || [];

    return (props.staffList?.items || []).map((item: any, index: number) => {
      let statusName = "";

      const prenameTh = prenamesTh.find(
        (options: any) => options.value === item.pre_name
      )?.text;

      const divisionsName = item.division_has_users
        .map(
          (acc: any) =>
            divisions.find((option) => option.value === acc.division)?.text ||
            ""
        )
        .join(", ");

      if (item.employee.status === 1) {
        statusName = "Active";
      } else if (item.employee.status === 2) {
        statusName = "Inactive";
      }

      return {
        ...item,
        divisions: divisionsName,
        employee: {
          ...item.employee,
          status_name: statusName,
        },

        pre_nameText: prenameTh,
        profile: {
          ...item.profile,
          pre_name: item.pre_name,
          first_name: item.first_name,
          last_name: item.last_name,
          first_name_en: item.employee.first_name_en,
          pre_name_en: item.employee.pre_name_en,
          last_name_en: item.employee.last_name_en,
          cid: item.citizen_passport || "",
          citizen_passport: item.citizen_passport,
          birth_date: item.employee.birth_date,
          email: item.email,
        },
        name: `${item.first_name}  ${item.last_name}`,
      };
    });
  }, [
    props.staffList,
    props.masterOptions?.prenameTh,
    props.masterOptions?.division,
  ]);

  const handleSearch = (activePage: number) => {
    props.onEvent({
      message: "SearchStaff",
      params: {
        filter: props.filter,
        activePage,
      },
    });
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id === props.selectedRow?.id
            ? "#cccccc"
            : "white",
      },
      onDoubleClick: () => {
        props.onSelected(rowInfo.original, true);
      },
    };
  };

  const handlePageChange = (e: any, data: any) => {
    handleSearch(data.activePage);
  };

  const handleClickSearch = () => {
    handleSearch(1);
  };

  // #console.log("CardStaffSearch", props);

  return (
    <div>
      <CardStaffSearchUX
        // data
        searchCode={props.filter.searchCode}
        firstName={props.filter.firstName}
        lastName={props.filter.lastName}
        position={props.filter.position}
        organization={props.filter.organization}
        staffList={staffItems}
        // options
        positionOptions={props.masterOptions?.positionAll || []}
        divisionOptions={props.masterOptions?.division}
        // config
        organizationFilter={props.organizationFilter}
        // callback
        changeValue={props.onChangeValue}
        addStaff={props.onAddStaff}
        OnSearch={handleClickSearch}
        getTrProps={handleGetTrProps}
        // Element
        Pagination={
          <Pagination
            activePage={props.staffList?.activePage || 0}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={Math.ceil((props.staffList?.total || 0) / STAFF_LIMIT)}
            // size="mini"
            // callback
            onPageChange={handlePageChange}
          />
        }
      />
    </div>
  );
};

export default React.memo(CardStaffSearch);
