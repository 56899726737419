import { HeaderLogoWithTitleContent } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

const FORM_NAME = "FormFoodReport";

const FormFoodReport = async (props: any) => {
  const headerForm = await HeaderLogoWithTitleContent({
    form: FORM_NAME,
    font: "THSarabunNew",
    pageMargins: [10, 130, 10, 10],
    headerMargins: [10, 20, 10, 0],
    logoHeight: 60,
    styles: {
      tabelHeader: {
        fontSize: 12,
        bold: true,
      },
      tabelContent: {
        fontSize: 12,
      },
    },
    titleContent: [
      {
        text: [
          {
            text: `รายการอาหาร ประจําวัน ${props?.date}\n`,
            style: { fontSize: 18, bold: true },
          },
          {
            text: `หอผู้ป่วยใน : ${props?.division}`,
            style: { fontSize: 18 },
          },
        ],
        alignment: "center",
      },
    ],
  });
  const { font, images, styles } = headerForm;

  return {
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 13,
    },
    // pageMargins: [10, 130, 10, 10],
    pageSize: "A4",
    ...styles,
    // styles: {
    //   tabelHeader: {
    //     fontSize: 12,
    //     bold: true,
    //   },
    //   tabelContent: {
    //     fontSize: 12,
    //   },
    // },
    // Test
    // header: {
    //   margin: [10, 20, 20, 0],

    //   stack: [
    //     {
    //       layout: "noBorders",
    //       fillOpacity: 0.4,
    //       table: {
    //         widths: ["10%", "90%"],
    //         heights: [55, 55],
    //         body: [
    //           [
    //             !CONFIG.HIDE_COMPANY_LOGO_PRINT ?  {
    //               margin: [55, 0, 0, 0],
    //               layout: "noBorders",
    //               fillOpacity: 0.4,
    //               stack: [{ image: "logo", fillOpacity: 0.4, width: 90 }],
    //             } : null,
    //              {
    //               margin: [0, 10, 0, 0],
    //               text: [
    //                 ...(!CONFIG.HIDE_COMPANY_LOGO_PRINT ? [{
    //                   text: `CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY\n`,
    //                   style: { fontSize: 18, bold: true },
    //                 }] : []),
    //                 {
    //                   text: `รายการอาหาร ประจําวัน ${props?.date}\n`,
    //                   style: { fontSize: 18, bold: true },
    //                 },
    //                 {
    //                   text: `หอผู้ป่วยใน : ${props?.division}`,
    //                   style: { fontSize: 18 },
    //                 },
    //               ],
    //               alignment: "center",
    //             },
    //           ],
    //         ],
    //       },
    //     },
    //   ],
    // },
    ...headerForm,
    content: [
      {
        stack: props.foodData?.map((item: any) => ({
          margin: [0, 0, 0, 20], // left, top, right, bottom
          table: {
            headerRows: 2,
            widths: ["7%", "8%", "20%", "18%", "27%", "20%"],
            body: [
              [
                {
                  text: [{ text: `มื้อ ${item?.time} น.` }],
                  style: { fontSize: 16, bold: true },
                  colSpan: 6,
                  alignment: "center",
                },
                {},
                {},
                {},
                {},
                {},
              ],
              [
                {
                  text: "ห้อง/เตียง",
                  style: "tabelHeader",
                  alignment: "center",
                },
                { text: "HN", style: "tabelHeader", alignment: "center" },
                {
                  text: "ชื่อ นามสกุล",
                  style: "tabelHeader",
                  alignment: "center",
                },
                {
                  text: "รายการอาหาร",
                  style: "tabelHeader",
                  alignment: "center",
                },
                {
                  text: "ข้อมูลอาหารเฉพาะโรค/คำสั่งควบคุมต่อวัน",
                  style: "tabelHeader",
                  alignment: "center",
                },
                {
                  text: "รายละเอียด",
                  style: "tabelHeader",
                  alignment: "center",
                },
              ],
              ...(item?.data || []).map((d: any) => [
                {
                  text: d.room,
                  style: "tabelContent",
                  alignment: "center",
                },
                { text: d.hn, style: "tabelContent", alignment: "center" },
                {
                  text: d.patient_fullname,
                  style: "tabelContent",
                  alignment: "center",
                },
                {
                  text: d.food_type_label
                    .replaceAll("<br/>", "\n")
                    .replaceAll("<b>", "")
                    .replaceAll("</b>", ""),
                  style: "tabelContent",
                },
                {
                  text:
                    d.special_diet_label
                      .replaceAll("<br/>", "\n")
                      .replaceAll("<b>", "")
                      .replaceAll("</b>", "") +
                    "\n" +
                    d.nutrition_control_detail_label
                      .replaceAll("<br/>", "\n")
                      .replaceAll("<b>", "")
                      .replaceAll("</b>", ""),
                  style: "tabelContent",
                },
                {
                  text: d.detail_label
                    .replaceAll("<br/>", "\n")
                    .replaceAll("<b>", "")
                    .replaceAll("</b>", ""),
                  style: "tabelContent",
                },
              ]),
            ],
          },
        })),
      },
    ],
    images: {
      ...images,
      queue: origin + "/static/images/menus/queue.png",
    },
  };
};

export default FormFoodReport;
