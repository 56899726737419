import React, { useEffect, useState, useMemo } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { Icon, Message } from "semantic-ui-react";
import CardBillingSummaryUX from "react-lib/apps/HISV3/BIL/CardBillingSummaryArUX";
import formatComma from "react-lib/utils/formatComma";
import { ErrorMessage, ModInfo } from "react-lib/apps/common";

const CARD_KEY = "CardBillingSummaryAr";

const CardBillingSummaryAr = (props: any) => {
  const [openModSuccess, setOpenModSuccess] = useState<boolean>(false);

  useEffect(() => {
    props.runSequence({
      sequence: "BillPaymentAr",
      restart: true,
      action: "getARReceiptShift",
      card: CARD_KEY,
    });
  }, []);

  const billingSummaryData = useMemo(() => {
    return (props?.BillPaymentArSequence?.receiptShiftArList || []).map(
      (item: any, index: number) => ({
        ...item,
        sum_payment_cash_price: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.sum_payment_cash_price))}
          </div>
        ),
        sum_payment_transfer_price:(
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.sum_payment_transfer_price))}
          </div>
        ),
        sum_payment_cheque_price:(
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.sum_payment_cheque_price))}
          </div>
        ),
      })
    );
  }, [props?.BillPaymentArSequence?.receiptShiftArList]);

  const handleCloseARReceiptShift = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "closeARReceiptShift",
      card: CARD_KEY,
      callback: setOpenModSuccess,
    });
  };

  const handlePrintARReceiptShift = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "printARReceiptShift",
      card: CARD_KEY,
    });
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CardBillingSummaryUX
        style={{ zoom: "90%" }}
        billingSummaryData={billingSummaryData}
        buttonRefresh={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "BillPaymentAr",
                action: "getARReceiptShift",
                card: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_getARReceiptShift`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_getARReceiptShift`]}
            // config
            style={{ width: "100%" }}
            title={
              <div>
                <Icon name="refresh"></Icon>
                refresh
              </div>
            }
            color="blue"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrintARReceiptShift}
            // data
            paramKey={`${CARD_KEY}_printARReceiptShift`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_printARReceiptShift`]}
            // config
            style={{ width: "100%" }}
            title="พิมพ์ใบสรุปยอดระหว่างกะ"
            color="blue"
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleCloseARReceiptShift}
            // data
            paramKey={`${CARD_KEY}_closeARReceiptShift`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_closeARReceiptShift`]}
            // config
            style={{ width: "100%" }}
            title="ปิดกะและพิมพ์ใบสรุปยอด"
            color="green"
          />
        }
        errorMessage={
          <div>
            <ErrorMessage error={props.errorMessage?.[`${CARD_KEY}`]} />
          </div>
        }
      />

      <ModInfo
        open={openModSuccess}
        titleColor={"green"}
        titleName={"แจ้งเตือน"}
        onApprove={() => setOpenModSuccess(false)}
        onClose={() => setOpenModSuccess(false)}
        alertText={<div style={{ fontSize: "16px", fontWeight: "bold"}}>{"ปิดกะสำเร็จ"}</div>}
      />
    </div>
  );
};

export default CardBillingSummaryAr;
