import { SyntheticEvent, useEffect, useMemo } from "react";
import { CheckboxProps, DropdownProps, ButtonProps } from "semantic-ui-react";

// UX
import CardSupplyDeliveryQueueUx from "./CardSupplyDeliveryQueueUx";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";
import DateTextBox from "react-lib/apps/common/DateTextBox";

// Utils
import { formatUTCtoBEString } from "react-lib/utils/dateUtils";

// Const
const CARD_SUPPLY_DELIVERY_QUEUE = "CardSupplyDeliveryQueue";

const CardSupplyDeliveryQueue = (props: any) => {
  
  useEffect(() => {
    props.runSequence({ sequence: "SupplyDelivery", restart: true });
  }, []);

  const supplyDeliveryQueueData = useMemo(() => {
    return (
      (props.SupplyDeliverySequence?.deliveryQueue || [])
        // ?.filter((item: any) =>
        //   props.SupplyDeliverySequence?.filterStatus.length !== 0
        //     ? item.status_name ===
        //       (props.SupplyDeliverySequence?.filterStatus.filter(
        //         (status: any) => item.status_name === status
        //       ))[0]
        //     : true
        // )
        .map((item: any) => ({
          ...item,
          requested: item.requested
            ? formatUTCtoBEString(item.requested).join(" ")
            : "",
          printed: item.printed
            ? formatUTCtoBEString(item.printed).join(" ")
            : "",
          checked: item.checked
            ? formatUTCtoBEString(item.checked).join(" ")
            : "",
          delivered: item.delivered
            ? formatUTCtoBEString(item.delivered).join(" ")
            : "",
          received: item.received
            ? formatUTCtoBEString(item.received).join(" ")
            : "",
        }))
    );
  }, [
    props.SupplyDeliverySequence?.deliveryQueue,
    props.SupplyDeliverySequence?.filterStatus,
  ]);

  const handleChangeDate = (key: string, keyChecked: string) => (date: any) => {
    props.setProp("SupplyDeliverySequence", {
      ...props.SupplyDeliverySequence,
      [keyChecked]: false,
      [key]: date,
    });
    props.setProp(`SupplyDeliverySequence.${key}`, date);
  };

  const handleChangeDivision = (e: SyntheticEvent, data: DropdownProps) =>
    props.setProp("SupplyDeliverySequence.filterDivision", data?.value);

  const handleChangeValue = (e: any, v: any) =>
    props.setProp("SupplyDeliverySequence.idSearch", v?.value);

  const handleChangeChecked = (e: SyntheticEvent, data: CheckboxProps) =>
    props.setProp("SupplyDeliverySequence.checkedFilterDate", data?.checked);

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    onDoubleClick: (e: any) => {
      let item = rowInfo?.original;
      props.runSequence({
        sequence: "SupplyDelivery",
        action: "select",
        id: item?.id,
      });
      props.onSelected();
    },
    onClick: (e: any) => {
      let item = rowInfo?.original;
      console.log(item);
      props.runSequence({
        sequence: "SupplyDelivery",
        action: "select",
        id: item?.id,
      });
      props.onSelected();
    },
  });

  return (
    <CardSupplyDeliveryQueueUx
      // data
      supplyDeliveryQueueData={supplyDeliveryQueueData}
      checkedFilterDate={props?.SupplyDeliverySequence?.checkedFilterDate}
      filterDivision={props.SupplyDeliverySequence?.filterDivision}
      idSearch={props?.SupplyDeliverySequence?.idSearch}
      // options
      divisionOptions={props.masterOptions?.division}
      // callback
      handleChangeChecked={handleChangeChecked}
      handleChangeValue={handleChangeValue}
      handleChangeDate={handleChangeDate}
      handleChangeDivision={handleChangeDivision}
      getTrProps={handleGetTrProps}
      OnClear={() =>
        props.runSequence({
          sequence: "SupplyDelivery",
          action: "clear",
        })
      }
      // Element
      startDate={
        <DateTextBox
          value={props?.SupplyDeliverySequence?.selectedStartDate}
          fluid={true}
          inputStyle={{ width: "145px" }}
          onChange={handleChangeDate("selectedStartDate", "checkedFilterDate")}
        />
      }
      endDate={
        <DateTextBox
          value={props?.SupplyDeliverySequence?.selectedEndDate}
          fluid={true}
          inputStyle={{ width: "145px" }}
          onChange={handleChangeDate("selectedEndDate", "checkedFilterDate")}
        />
      }
      handleSearch={
        <ButtonLoadCheck
          // function
          setProp={props.setProp}
          onClick={() => {
            props.runSequence({
              sequence: "SupplyDelivery",
              action: "search",
              card: CARD_SUPPLY_DELIVERY_QUEUE,
              buttonLoadKey: `${CARD_SUPPLY_DELIVERY_QUEUE}_SEARCH`,
            });
          }}
          // data
          paramKey={`${CARD_SUPPLY_DELIVERY_QUEUE}_SEARCH`}
          buttonLoadCheck={
            props.buttonLoadCheck?.[`${CARD_SUPPLY_DELIVERY_QUEUE}_SEARCH`]
          }
          // config
          style={{ with: "100%", height: "100%" }}
          size="small"
          name="SEARCH"
          title="ค้นหา"
          color="blue"
        />
      }
      orderSearchBox={
        <DropdownOptions
          value={props.SupplyDeliverySequence?.filterStatus || ""}
          multiple={true}
          search={true}
          placeholder="เลือก Order Status"
          checked={true}
          options={props.masterOptions?.SupplyTransferStatus || []}
          onChange={(e: any, data: any) => {
            props.setProp("SupplyDeliverySequence.filterStatus", data?.value);
          }}
        />
      }
    />
  );
};

export default CardSupplyDeliveryQueue;
