import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import DivisionServiceBlockOperatingAndRoom from "issara-sdk/apis/DivisionServiceBlockOperatingAndRoom_apps_QUE";
import CreateUpdateDSBOperatingDetail from "issara-sdk/apis/CreateUpdateDSBOperatingDetail_apps_QUE";
import AnesthesiaMethodList from "issara-sdk/apis/AnesthesiaMethodList_apps_ORM";
import { adToBe, adToBeWithSetFormat, beToAd, formatDate } from "react-lib/utils/dateUtils";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import OperatingLocationList from "issara-sdk/apis/OperatingLocationList_apps_ORM";
import OperatingRoomList from "issara-sdk/apis/OperatingRoomList_apps_ORM";
import ConstanceView from "issara-sdk/apis/ConstanceView_core";
import OperatingDetailDetail from "issara-sdk/apis/OperatingDetailDetail_apps_ORM";

const IGNORE_VALUE = ["admitDate", "admitTime", "admitRequestICU", "admitStayDate", "admitICUStay"];

export type State = {
  // CommonInterface
  selectedPatient?: any;
  selectedAppointment?: any;
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  selectedOrOrder?: any;
  isSelectTabCalendar?: boolean;
  masterOptions?: any;
  selectedBlock?: any;

  // Operation datetime
  selectedOperatingDetail?: any;
  operatingBlock?: any;
  selectOperatingDSBChange?: boolean;
  selectedCalendarDoctor?: any;
  selectedCalendarAnesthesiologist?: any;
  selectedCalendarOperatingRoom?: any;
  anesthesiaMethodList?: any[];
  estimateTimeText?: any;
  operatingDSBStartDate?: any;
  operatingDSBEndDate?: any;
  operatingLocationRoom?: any[];

  // from Operating Request
  selectedDoctor?: any;
  selectedAnesthesiologist?: any;
  selectedOperatingRoom?: any;
  appointmentList?: any[];

  OperatingDateTimeSequence?: {
    sequenceIndex?: string | null;
    id?: any;
    patientHN?: any;
    patientName?: any;
    selectedDate?: any;
    selectedDateBackendFormat?: any;
    startTime?: any;
    endTime?: any;
    doctor?: any;
    anesthesia?: any;
    operatingRoom?: any;
    treatmentList?: any[];
    editable?: any;
    total_estimate_operation_time?: any; // readonly
    admitOrder?: any;
    admitCase?: string;
    admitDate?: string;
    admitTime?: string;
    admitRequestICU?: boolean;
    admitStayDate?: number;
    admitICUStay?: number;
    procedureType?: string;
    anesthesiaMethod?: string;
    order_status?: number;
    order_status_label?: string;
    is_doctor_schedule?: boolean;
    selectedDoctorSchedule?: Record<string, any>;
    dsbOperatingRoom?: any[];
    estimateEndDate?: string;
    constance?: Record<string, any>;
    allMinutes?: number | string;
  } | null;

  userTokenize?: {
    // UserTokenize
    token?: any;
    loading?: boolean;
    error?: any;
    employeeName?: any;
  } | null;
};

export const StateInitial: State = {
  // sequence
  OperatingDateTimeSequence: null,
  selectedOperatingDetail: null,
  operatingDSBStartDate: "",
  operatingDSBEndDate: "",
  isSelectTabCalendar: false,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} }
  | { message: "RefreshAppointment"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

// [porn] ถนอมพร สุขหรรษา
export const PREFIX_CODE_REGEX = /^.*?\]/;

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["doctor", {}],
        ["operatingRoom", {}],
      ],
    },
  });

  const [anesthesiaMethod, operatingLocation, operatingRoom, constance] = await Promise.all([
    AnesthesiaMethodList.list({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: (controller.data as any).device,
      },
    }),
    OperatingLocationList.list({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: (controller.data as any).device,
      },
    }),
    OperatingRoomList.list({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: (controller.data as any).device,
      },
    }),
    GetConstance(controller, params),
  ]);

  if (anesthesiaMethod[1] || operatingLocation[1] || operatingRoom[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: [anesthesiaMethod[1], operatingLocation[1], operatingRoom[1]],
      },
    });
  }

  let operatingLocationRoom =
    operatingLocation?.[0]?.items.map((item: any) => {
      let roomList =
        operatingRoom?.[0]?.items
          ?.filter((room: any) => room.location === item.id)
          ?.map((room: any) => ({ ...room, key: room.id, text: room.room_no, value: room.id })) ||
        [];
      return { ...item, key: item.id, text: item.name, value: item.id, subOptions: [...roomList] };
    }) || [];

  // console.log("OperatingDateTime Master: ", operatingLocation[0], operatingRoom[0])
  controller.setState({
    anesthesiaMethodList: anesthesiaMethod[0]?.items || [],
    operatingLocationRoom: operatingLocationRoom,
    OperatingDateTimeSequence: {
      sequenceIndex: "Action",
      constance: constance[0]?.result || {},
    },
  });
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();
  // console.log("OperatingDateTime params: ", params.action, params)

  if (params.action === "getDSBBlock") {
    const promiseArr = [
      DivisionServiceBlockOperatingAndRoom.list({
        apiToken: controller.apiToken,
        params: {
          doctor: params.doctor,
          anesthesia_doctor: params.anesthesiologist,
          operating_room: params.room,
          filter_type: "doctor_schedule",
          start_date: params.start_date || state.operatingDSBStartDate || "",
          end_date: params.end_date || state.operatingDSBEndDate || "",
          limit: 999, // hardcode config
        },
      }),
    ];

    if (params.operatingId) {
      promiseArr.push(
        OperatingOrderDetail.retrieve({
          apiToken: controller.apiToken,
          pk: params.operatingId,
        })
      );
    }

    const [dsbBlock, opDetail] = await Promise.all(promiseArr);

    if (dsbBlock[1]) {
      return controller.setState(
        {
          errorMessage: {
            ...state.errorMessage,
            [params.card]: dsbBlock[1].length > 1000 ? "500 Internal Server erorr" : dsbBlock[1],
          },
        },
        () => {
          params?.onSuccess?.();
        }
      );
    }

    let items: Record<string, any>[] = dsbBlock[0]?.items || [];

    if (params.view === "day") {
      const promiseDSB = (dsbBlock[0]?.items || []).map((item: any) =>
        item.is_doctor_schedule
          ? GetPatientAppointment(controller, item).then((res: any) => ({
              ...item,
              patient_count: res[0]?.items?.length || 0,
            }))
          : OperatingDetailDetail.retrieve({
              apiToken: controller.apiToken,
              pk: item.operating_detail,
            }).then((res: any) => ({
              ...item,
              surgery_teams: res[0]?.surgery_teams,
            }))
      );

      items = await Promise.all(promiseDSB);
    }

    const mappingBlock = items.map((item) => {
      const data = {
        ...item,
        end: new Date(item.end_datetime),
        start: new Date(item.start_datetime),
      };

      const teams: Record<string, any>[] = opDetail?.[0]?.teams || [];

      const treatments = teams.flatMap((team) => {
        const operatingTreatments: Record<string, any>[] =
          team.pre_operating_order_item?.operating_treatments || [];

        return operatingTreatments.map((item) => item.name_code as string);
      });

      return item.operating_detail === opDetail?.[0]?.operating_detail
        ? { ...data, treatments }
        : data;
    });

    if (params.operatingId) {
      if (opDetail[1]) {
        return controller.setState(
          {
            errorMessage: { ...state.errorMessage, [params.card]: opDetail[1] },
          },
          () => {
            params?.onSuccess?.();
          }
        );
      }

      controller.setState({
        selectedOperatingDetail: opDetail[0]?.operating_detail || null,
      });
    }

    return controller.setState(
      {
        operatingBlock: mappingBlock,
        selectedCalendarDoctor: params.doctor,
        selectedCalendarAnesthesiologist: params.anesthesiologist,
        selectedCalendarOperatingRoom: params.room,
        ...(params?.start_date ? { operatingDSBStartDate: params.start_date } : {}),
        ...(params?.end_date ? { operatingDSBEndDate: params.end_date } : {}),
      },
      () => {
        params?.onSuccess?.();
      }
    );
  } else if (params.action === "getDSBRoom") {
    const [dsbOperatingRoom] = await DivisionServiceBlockOperatingAndRoom.list({
      apiToken: controller.apiToken,
      params: {
        filter_type: "room",
        start_date: params.startDate,
        end_date: params.endDate,
        limit: 999, // hardcode config
      },
    });

    params.callback?.();

    const state = controller.getState();

    controller.setState({
      OperatingDateTimeSequence: {
        ...state.OperatingDateTimeSequence,
        dsbOperatingRoom: dsbOperatingRoom?.items || [],
      },
    });
  } else if (params.action === "selectDateTime" && params.data?.operatingId) {
    const state = controller.getState();

    let startDate = moment();

    if (params.data?.start) {
      startDate = moment(params.data.start);
    }

    const [opOrderDetail, opDetail] = await Promise.all([
      OperatingOrderDetail.retrieve({
        apiToken: controller.apiToken,
        pk: params.data?.operatingId,
      }),
      OperatingDetailDetail.retrieve({
        apiToken: controller.apiToken,
        pk: state.selectedOperatingDetail,
      }),
    ]);

    if (opOrderDetail[1]) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: opOrderDetail[1] },
      });
    }

    const showText = EstimateDateTime(state.anesthesiaMethodList, opOrderDetail[0]);

    const procedureType =
      state.masterOptions.orProcedureType?.find(
        (option: any) => option.value === opOrderDetail[0].procedure_type
      )?.text || "";

    const estimateEndTime = startDate
      .clone()
      .add(opOrderDetail?.[0]?.total_estimate_operation_time || 0, "minutes");

    // #const team = opOrderDetail?.[0]?.teams?.find((team: any) => team.is_main);

    // #const treatmentList = team?.pre_operating_order_item?.operating_treatments?.map(
    //   (treatment: any, index: number) => `${index + 1}. ${treatment.name_code}`
    // );

    return controller.setState({
      selectedOperatingDetail: opOrderDetail[0]?.operating_detail || null,
      estimateTimeText: showText.text,
      OperatingDateTimeSequence: {
        ...state.OperatingDateTimeSequence,
        patientHN: opOrderDetail[0].hn,
        patientName: opOrderDetail[0].patient_name,
        selectedDate: adToBeWithSetFormat(
          moment(startDate).format("YYYY-MM-DD"),
          "YYYY-MM-DD",
          "DD MMMM YYYY",
          "th"
        ),
        selectedDateBackendFormat: startDate.format("YYYY-MM-DD"),
        startTime: startDate.locale("th").format("HH:mm"),
        endTime: estimateEndTime.locale("th").format("HH:mm"),
        // treatmentList: treatmentList || [],
        operatingRoom: null,
        anesthesia: state.selectedCalendarAnesthesiologist || null,
        doctor: state.selectedCalendarDoctor || null,
        total_estimate_operation_time: opOrderDetail[0].total_estimate_operation_time || 0,
        admitOrder: opOrderDetail[0]?.admit_order || null,
        admitCase: opOrderDetail[0]?.case?.ipd_case || "",
        admitDate: opOrderDetail[0]?.case?.app_admit_date || "",
        admitTime: "06.00",
        admitRequestICU: opOrderDetail[0]?.case?.is_request_icu || false,
        admitStayDate: opOrderDetail[0]?.case?.ipd_day || 0,
        admitICUStay: opOrderDetail[0]?.case?.icu_day || 0,
        editable: true,
        procedureType,
        anesthesiaMethod: opOrderDetail[0]?.anesthesia_method_name,
        estimateEndDate: estimateEndTime.format("YYYY-MM-DD"),
        allMinutes: showText.allMinutes,
        surgery_teams: opDetail[0]?.surgery_teams,
      },
    });
  } else if (params.action === "selectEvent" && params.data?.id) {
    const { data } = params;
    const showText = EstimateDateTime(state.anesthesiaMethodList, data);

    const [result] = await OperatingDetailDetail.retrieve({
      apiToken: controller.apiToken,
      pk: data.operating_detail,
    });

    return controller.setState({
      estimateTimeText: showText.text,
      OperatingDateTimeSequence: {
        ...state.OperatingDateTimeSequence,
        id: data.id,
        patientHN: state.selectedPatient.hn,
        patientName: data.patient_name,
        selectedDate: adToBeWithSetFormat(
          moment(data.start_datetime).format("YYYY-MM-DD"),
          "YYYY-MM-DD",
          "DD MMMM YYYY",
          "th"
        ),
        selectedDateBackendFormat: moment(data.start_datetime).format("YYYY-MM-DD"),
        startTime: moment(data.start_datetime).locale("th").format("HH:mm"),
        endTime: moment(data.end_datetime).locale("th").format("HH:mm"),
        // treatmentList: data?.treatments || [],
        operatingRoom: data.room || null,
        anesthesia: data.anesthesiologist || null,
        doctor: data.doctor || null,
        admitOrder: data?.admit_order || null,
        admitCase: data?.admit_case || "",
        admitDate: adToBe(data?.admit_date, "YYYY-MM-DD") || "",
        admitTime: data?.admit_time || "",
        admitRequestICU: data?.admit_request_icu || false,
        admitStayDate: data?.admit_stay_date || 0,
        admitICUStay: data?.admit_icu_stay || 0,
        editable: data.order_status === 1,
        procedureType: data.procedure_type,
        anesthesiaMethod: data.anesthesia_method,
        order_status: data.order_status,
        order_status_label: data.order_status_label,
        is_doctor_schedule: data.is_doctor_schedule,
        estimateEndDate: moment(data.end_datetime).format("YYYY-MM-DD"),
        allMinutes: showText.allMinutes,
        surgery_teams: result?.surgery_teams,
      },
    });
  } else if (params.action === "selectDoctorSchedule" && params.data?.id) {
    const data = params.data;

    const [result] = await GetPatientAppointment(controller, data);

    return controller.setState({
      OperatingDateTimeSequence: {
        ...state.OperatingDateTimeSequence,
        selectedDoctorSchedule: {
          ...data,
          patient_count: result?.items?.length || 0,
        },
      },
    });
  } else if (params.action === "changeAppointment") {
    return controller.setState(
      {
        selectOperatingDSBChange: true,
      },
      () => {
        if (params.callback) {
          params.callback();
        }
      }
    );
  } else if (
    params.action === "setValue" &&
    ((params.name && params.value) || IGNORE_VALUE.includes(params.name))
  ) {
    if (params.name === "startTime") {
      const startDateTime = moment(
        `${state.OperatingDateTimeSequence?.selectedDateBackendFormat} ${params.value}`,
        "YYYY-MM-DD HH:mm"
      );
      const estimateEndTime = startDateTime.add(
        state.OperatingDateTimeSequence?.total_estimate_operation_time || 0,
        "minutes"
      );

      return controller.setState({
        OperatingDateTimeSequence: {
          ...state.OperatingDateTimeSequence,
          [params.name]: params.value,
          endTime: estimateEndTime.format("HH:mm"),
          estimateEndDate: estimateEndTime.format("YYYY-MM-DD"),
        },
      });
    } else {
      return controller.setState({
        OperatingDateTimeSequence: {
          ...state.OperatingDateTimeSequence,
          [params.name]: params.value,
        },
      });
    }
  } else if (params.action === "createDSB") {
    let startdDateTime = moment(
      `${state.OperatingDateTimeSequence?.selectedDateBackendFormat}T${state.OperatingDateTimeSequence?.startTime}`,
      "YYYY-MM-DDTHH:mm"
    ).toISOString();

    let admitDateTime = "";
    const operatingRoom = state.OperatingDateTimeSequence?.operatingRoom;

    if (state.OperatingDateTimeSequence?.admitDate && state.OperatingDateTimeSequence?.admitTime) {
      let admitDate = beToAd(state.OperatingDateTimeSequence?.admitDate as any)?.format(
        "YYYY-MM-DD"
      );
      admitDateTime = moment(
        `${admitDate}T${state.OperatingDateTimeSequence?.admitTime}`,
        "YYYY-MM-DDTHH:mm"
      ).toISOString();
    }

    const operating = await CreateUpdateDSBOperatingDetail.post({
      apiToken: controller.apiToken,
      data: {
        start_datetime: startdDateTime,
        operating_detail: state.selectedOperatingDetail,
        doctor: state.OperatingDateTimeSequence?.doctor,
        anesthesiologist: state.OperatingDateTimeSequence?.anesthesia || null,
        operating_room: Array.isArray(operatingRoom) || !operatingRoom ? null : operatingRoom,
        patient_appointment: state.selectedAppointment?.id || null,
        admit_order: state.OperatingDateTimeSequence?.admitOrder || null,
        admit_case: state.OperatingDateTimeSequence?.admitCase || "",
        admit_datetime: admitDateTime,
        // admit_date: beToAd(state.OperatingDateTimeSequence?.admitDate as any)?.format("YYYY-MM-DD") || "",
        // admit_time: state.OperatingDateTimeSequence?.admitTime || "",
        admit_request_icu: state.OperatingDateTimeSequence?.admitRequestICU || false,
        admit_stay_date: state.OperatingDateTimeSequence?.admitStayDate || 0,
        admit_icu_stay: state.OperatingDateTimeSequence?.admitICUStay || 0,
      },
      extra: {
        division: controller.data?.division || null,
        device: (controller.data as any).device || null,
      },
    });

    if (operating[1]) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: operating[1] },
      });
    }

    const reconfirm = state.selectedOrOrder?.status === 7 ? "RECONFIRM" : "";

        return controller.setState({
            successMessage: { ...state.successMessage, [params.card]: "จองห้องผ่าตัดสำเร็จ" },
            OperatingDateTimeSequence: {
                sequenceIndex: "Action",
                constance: state.OperatingDateTimeSequence?.constance || {}
            },
            ...(reconfirm ? {
                selectedAppointment: null,
                selectedBlock: null,
            }: {
                selectedOperatingRoom: operatingRoom
            })
        }, async () => {
            if(!reconfirm){
                await Action(controller, { action: "updateOrOrder" })
            }

        params.callback?.({ message: reconfirm });

        if (!reconfirm) {
          await RefreshSelectAppointment(controller, params);
          await Action(controller, {
            action: "getDSBBlock",
            doctor: state.OperatingDateTimeSequence?.doctor,
            anesthesiologist: state.OperatingDateTimeSequence?.anesthesia,
            room: state.OperatingDateTimeSequence?.operatingRoom,
          });
        }
      }
    );
  } else if (params.action === "updateDSB") {
    let startdDateTime = moment(
      `${state.OperatingDateTimeSequence?.selectedDateBackendFormat}T${state.OperatingDateTimeSequence?.startTime}`,
      "YYYY-MM-DDTHH:mm"
    ).toISOString();
    let admitDateTime = "";
    const operatingRoom = state.OperatingDateTimeSequence?.operatingRoom;

    if (state.OperatingDateTimeSequence?.admitDate && state.OperatingDateTimeSequence?.admitTime) {
      let admitDate = beToAd(state.OperatingDateTimeSequence?.admitDate as any)?.format(
        "YYYY-MM-DD"
      );
      admitDateTime = moment(
        `${admitDate}T${state.OperatingDateTimeSequence?.admitTime}`,
        "YYYY-MM-DDTHH:mm"
      ).toISOString();
    }

    const operating = await CreateUpdateDSBOperatingDetail.put({
      apiToken: controller.apiToken,
      data: {
        start_datetime: startdDateTime,
        operating_detail: state.selectedOperatingDetail,
        doctor: state.OperatingDateTimeSequence?.doctor,
        anesthesiologist: state.OperatingDateTimeSequence?.anesthesia || null,
        operating_room: Array.isArray(operatingRoom) || !operatingRoom ? null : operatingRoom,
        division_service_block: state.OperatingDateTimeSequence?.id || null,
        patient_appointment: state.selectedAppointment?.id || null,
        admit_order: state.OperatingDateTimeSequence?.admitOrder || null,
        admit_case: state.OperatingDateTimeSequence?.admitCase || "",
        admit_datetime: admitDateTime,
        // admit_date: beToAd(state.OperatingDateTimeSequence?.admitDate as any)?.format("YYYY-MM-DD") || "",
        // admit_time: state.OperatingDateTimeSequence?.admitTime || "",
        admit_request_icu: state.OperatingDateTimeSequence?.admitRequestICU || false,
        admit_stay_date: state.OperatingDateTimeSequence?.admitStayDate || 0,
        admit_icu_stay: state.OperatingDateTimeSequence?.admitICUStay || 0,
      },
      extra: {
        division: controller.data?.division || null,
        device: (controller.data as any).device || null,
      },
    });

    if (operating[1]) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: operating[1] },
      });
    }

    return controller.setState(
      {
        successMessage: { ...state.successMessage, [params.card]: "เปลี่ยนแปลงนัดหมายสำเร็จ" },
        OperatingDateTimeSequence: {
          sequenceIndex: "Action",
          constance: state.OperatingDateTimeSequence?.constance || {},
        },
        selectOperatingDSBChange: false,
        selectedDoctor: state.OperatingDateTimeSequence?.doctor,
        selectedAnesthesiologist: state.OperatingDateTimeSequence?.anesthesia,
        selectedOperatingRoom: state.OperatingDateTimeSequence?.operatingRoom,
        // selectedOperatingDetail: null
      },
      async () => {
        await Action(controller, { action: "updateOrOrder" });

        if (params.callback) {
          params.callback();
        }
        await RefreshSelectAppointment(controller, params);
        await Action(controller, {
          action: "getDSBBlock",
          doctor: state.OperatingDateTimeSequence?.doctor,
          anesthesiologist: state.OperatingDateTimeSequence?.anesthesia,
          room: state.OperatingDateTimeSequence?.operatingRoom,
        });
      }
    );
  } else if (params.action === "operatingPostpone") {
    // console.log("Operating postpone: ", state?.userTokenize)
    const operating = await CreateUpdateDSBOperatingDetail.put({
      apiToken: controller.apiToken,
      data: {
        operating_detail: state.selectedOperatingDetail,
        patient_appointment: state.selectedAppointment?.id || null,
        status_note: JSON.stringify(params?.reason),
        user: state?.userTokenize?.employeeName,
      },
      extra: {
        division: controller.data?.division || null,
        device: (controller.data as any).device || null,
      },
    });

    if (operating[1]) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: operating[1] },
      });
    }

    return controller.setState(
      {
        successMessage: {
          ...state.successMessage,
          [params.card]: "เลื่อนนัดหมายไม่ระบุวันที่สำเร็จ",
        },
        OperatingDateTimeSequence: {
          sequenceIndex: "Action",
          constance: state.OperatingDateTimeSequence?.constance || {},
        },
        selectOperatingDSBChange: false,
        userTokenize: null,
        selectedAppointment: null,
        selectedBlock: null,
      },
      async () => {
        if (params.callback) {
          params.callback();
        }
        await RefreshSelectAppointment(controller, params);
        await Action(controller, {
          action: "getDSBBlock",
          doctor: state.OperatingDateTimeSequence?.doctor,
          anesthesiologist: state.OperatingDateTimeSequence?.anesthesia,
          room: state.OperatingDateTimeSequence?.operatingRoom,
        });
      }
    );
  } else if (params.action === "updateOrOrder") {
    // Update state selectedOrOrder
    if (state.selectedOrOrder?.id) {
      const [order] = await OperatingOrderDetail.retrieve({
        apiToken: controller.apiToken,
        pk: state.selectedOrOrder.id,
      });

      return controller.setState({
        selectedOrOrder: order,
      });
    }
  } else if (params.action === "close") {
    return controller.setState(
      {
        OperatingDateTimeSequence: {
          sequenceIndex: "Action",
          constance: state.OperatingDateTimeSequence?.constance || {},
        },
        // selectedOperatingDetail: null
      },
      () => {
        if (params.callback) {
          params.callback();
        }
      }
    );
  } else if (params.action === "unsetAndClose") {
    return controller.setState(
      {
        OperatingDateTimeSequence: {
          sequenceIndex: "Action",
          constance: state.OperatingDateTimeSequence?.constance || {},
        },
        selectOperatingDSBChange: false,
      },
      () => {
        if (params.callback) {
          params.callback();
        }
      }
    );
  }
};

// Utilities
const EstimateDateTime = (anesthesiaOptions: any, data: any) => {
  let anesthesiaMatch = anesthesiaOptions.find((item: any) => item.id === data?.anesthesia_method);
  if (
    anesthesiaMatch &&
    (anesthesiaMatch?.estimate_time_before_operation ||
      anesthesiaMatch?.estimate_time_after_operation)
  ) {
    if (data.estimate_operation_time) {
      let allTime =
        (anesthesiaMatch?.estimate_time_before_operation || 0) +
        (anesthesiaMatch?.estimate_time_after_operation || 0) +
        (data?.estimate_operation_time || 0);
      let hours = Math.floor(allTime / 60);
      let minutes = allTime % 60;
      return {
        text: `เวลาเตรียมผู้ป่วย ก่อนผ่าตัด ${
          anesthesiaMatch?.estimate_time_before_operation || 0
        } นาที
                หลังผ่าตัด ${anesthesiaMatch?.estimate_time_after_operation || 0} นาที
                เวลารวมทั้งหมด ${hours} ชม. ${minutes} นาที`,
        allMinutes: `${hours * 60 + minutes}`,
      };
    }
  }
  return { text: "", allMinutes: 0 };
};

const RefreshSelectAppointment: Handler = async (controller, params) => {
  const { selectedAppointment, errorMessage, selectedPatient } = controller.getState();

  const appointment = await PatientAppointmentView.list({
    params: {
      ...(selectedPatient && { patient_id: selectedPatient?.id }),
      exclude_cancel: true,
    },
    apiToken: controller.apiToken,
  });
  console.log(appointment[1] ? appointment[1] : appointment[0]);

  if (appointment[1] && params?.card) {
    return controller.setState({
      errorMessage: { ...errorMessage, ...(params?.card ? { [params.card]: appointment[1] } : {}) },
    });
  }

  const items = appointment[0].items.map((item: any) => ({
    ...item,
  }));

  if (selectedAppointment?.id) {
    const patientApp = await PatientAppointmentUpdate.retrieve({
      pk: selectedAppointment?.id,
      apiToken: controller.apiToken,
    });

    if (patientApp[1]) {
      return controller.setState({
        errorMessage: {
          ...errorMessage,
          ...(params?.card ? { [params.card]: patientApp[1] } : {}),
        },
      });
    }

    let date = patientApp?.[0]?.display_info?.start_datetime?.split("T")?.[0] || "";
    let start =
      patientApp?.[0]?.display_info?.start_datetime?.split("T")?.[1]?.substring(0, 5) || "";
    let end = patientApp?.[0]?.display_info?.end_datetime?.split("T")?.[1]?.substring(0, 5) || "";
    let datetime = `${date} ${start}-${end}`;

    controller.setState({
      selectedAppointment: {
        ...selectedAppointment,
        ...patientApp[0],
        datetime,
      },
      isSelectTabCalendar: true,
    });
  }

  controller.setState({
    appointmentList: [...items],
  });
};

const GetPatientAppointment: Handler = async (controller, params) => {
  return PatientAppointmentView.list({
    apiToken: controller.apiToken,
    params: {
      division_id: params.division,
      limit: 99999,
      start_date: formatDate(moment(params.start_datetime)),
      end_date: formatDate(moment(params.end_datetime)),
      start_time: moment(params.start_datetime).format("HH:mm"),
      end_time: moment(params.end_datetime).format("HH:mm"),
      provider_id: params.provider,
    },
  });
};

const GetConstance: Handler = async (controller, params) => {
  return await ConstanceView.get({
    params: {
      list: ["core_OPD_ENCOUNTER_EXPIRE"].join(","),
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });
};
