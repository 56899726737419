import React, { useEffect, useMemo, useState } from "react";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// UX
import CardAnesthesiaStatReportUX from "./CardAnesthesiaStatReportUX";

// Interface
import {
  ACTIONS,
  PRINT_OPTIONS,
  FILTERS,
  PickedProps,
  ReportName,
  ReportType,
  RunSequence,
  SetProp,
  State,
} from "./sequence/AnesthesiaStatReport";

// Type
type CardAnesthesiaStatReportProps = {
  // Function
  onEvent: (e: any) => any;
  setProp: SetProp;

  // Sequence
  runSequence: RunSequence;
  AnesthesiaStatReportSequence?: State["AnesthesiaStatReportSequence"];
} & PickedProps;

// Const
const CARD_KEY = `CardAnesthesiaStatReport`;

const REPORT_LIST: Record<ReportType, { name: ReportName; value: ReportType }> = {
  ON_METHOD_GENDER: {
    name: "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามวิธีระงับความรู้สึก เพศ และอายุ",
    value: "ON_METHOD_GENDER",
  },
  ON_METHOD_CASE: {
    name: "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามวิธีระงับความรู้สึก และประเภทเคส",
    value: "ON_METHOD_CASE",
  },
  ON_ASA: { name: "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตาม ASA", value: "ON_ASA" },
  ON_SATISFACTION: {
    name: "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามระดับความพอใจ",
    value: "ON_SATISFACTION",
  },
  ON_DURATION: {
    name: "จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามช่วงเวลาดมยา",
    value: "ON_DURATION",
  },
};

const REPORT_OPTIONS = [
  {
    key: REPORT_LIST.ON_METHOD_GENDER.value,
    value: REPORT_LIST.ON_METHOD_GENDER.value,
    text: REPORT_LIST.ON_METHOD_GENDER.name,
  },
  {
    key: REPORT_LIST.ON_METHOD_CASE.value,
    value: REPORT_LIST.ON_METHOD_CASE.value,
    text: REPORT_LIST.ON_METHOD_CASE.name,
  },
  {
    key: REPORT_LIST.ON_ASA.value,
    value: REPORT_LIST.ON_ASA.value,
    text: REPORT_LIST.ON_ASA.name,
  },
  {
    key: REPORT_LIST.ON_SATISFACTION.value,
    value: REPORT_LIST.ON_SATISFACTION.value,
    text: REPORT_LIST.ON_SATISFACTION.name,
  },
  {
    key: REPORT_LIST.ON_DURATION.value,
    value: REPORT_LIST.ON_DURATION.value,
    text: REPORT_LIST.ON_DURATION.name,
  },
];

const ANES_MEHTOD = {
  IsTIVA: "GA - TIVA",
  IsMAC: "MAC",
  IsLMA: "LMA",
};

const getAnesMethod = (method: any, type: any) => {
  for (const key in type) {
    if (method[key]) {
      return type[key];
    }
  }

  return null;
};

const calDuration = (startTime: string | null, endTime: string | null) => {
  if (!startTime || !endTime) {
    return null;
  }

  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);

  if (isNaN(startHour) || isNaN(startMinute) || isNaN(endHour) || isNaN(endMinute)) {
    return null;
  }

  const start = new Date(0, 0, 0, startHour, startMinute);
  const end = new Date(0, 0, 0, endHour, endMinute);

  if (end < start) {
    return null;
  }

  const durationMs = end.getTime() - start.getTime();
  const hours = Math.floor(durationMs / (1000 * 60 * 60));
  const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

  return { hours, minutes };
};

const CardAnesthesiaStatReport = (props: CardAnesthesiaStatReportProps) => {
  const [anesDataCount, setAnesDataCount] = useState(0);

  useEffect(() => {
    props.runSequence({
      sequence: "AnesthesiaStatReport",
      restart: true,
    });
  }, []);

  useMemo(() => {
    setAnesDataCount(props.AnesthesiaStatReportSequence?.anesDataCount);
  }, [props.AnesthesiaStatReportSequence?.anesDataCount]);

  const handleChangeDate = (name: string, date: string) => {
    if (name === FILTERS.START_DATE) {
      props.setProp(`AnesthesiaStatReportSequence.filterReport.filterStartDate`, date);
    } else {
      props.setProp(`AnesthesiaStatReportSequence.filterReport.filterEndDate`, date);
    }
  };

  const handleChangeReport = (e: any, { value }: { value: ReportType }) => {
    if (value != null) {
      props.setProp(`AnesthesiaStatReportSequence.filterReport.selectedReport`, value);
    } else {
      props.setProp(`AnesthesiaStatReportSequence.filterReport.selectedReport`, null);
    }
  };

  const handleSearchData = () => {
    // Action SEARCH_DATA
    const data = {
      startDate: props.AnesthesiaStatReportSequence?.filterReport?.filterStartDate,
      endDate: props.AnesthesiaStatReportSequence?.filterReport?.filterEndDate,
      selectedReport: props.AnesthesiaStatReportSequence?.filterReport?.selectedReport,
    };

    props.runSequence({
      sequence: "AnesthesiaStatReport",
      action: "SEARCH_DATA",
      btnAction: `${CARD_KEY}_${ACTIONS.SEARCH_DATA}`,
      startDate: data.startDate,
      endDate: data.endDate,
    });

    // console.log(`Yeti Search Data: `, data);
  };

  const handlePrintReport = () => {
    // Action PRINT_REPORT
    const data = {
      startDate: props.AnesthesiaStatReportSequence?.filterReport?.filterStartDate,
      endDate: props.AnesthesiaStatReportSequence?.filterReport?.filterEndDate,
      selectedReport: props.AnesthesiaStatReportSequence?.filterReport?.selectedReport,
      isExportExcel: false,
    };

    props.runSequence({
      sequence: "AnesthesiaStatReport",
      action: "PRINT_REPORT",
      btnAction: `${CARD_KEY}_${ACTIONS.PRINT_REPORT}_${PRINT_OPTIONS.STAT}`,
      type: props.AnesthesiaStatReportSequence?.filterReport?.selectedReport,
      data: data,
    });

    // console.log("Yeti Print Stat Report: ", data);
  };

  const handleExportExcel = () => {
    // Action PRINT_REPORT with isExportExcel: true
    const data = {
      startDate: props.AnesthesiaStatReportSequence?.filterReport?.filterStartDate,
      endDate: props.AnesthesiaStatReportSequence?.filterReport?.filterEndDate,
      selectedReport: props.AnesthesiaStatReportSequence?.filterReport?.selectedReport,
      isExportExcel: true,
    };

    // props.runSequence({
    //   sequence: "AnesthesiaStatReport",
    //   action: "PRINT_REPORT",
    //   btnAction: `${CARD_KEY}_${ACTIONS.PRINT_REPORT}_${PRINT_OPTIONS.EXCEL}`,
    //   type: props.AnesthesiaStatReportSequence?.filterReport?.selectedReport,
    //   data: data,
    // });

    // console.log("Yeti Export Excel Status: ", data);
  };

  return (
    <>
      <CardAnesthesiaStatReportUX
        // Report Filter
        reportOptions={REPORT_OPTIONS}
        onReportValue={props.AnesthesiaStatReportSequence?.filterReport?.selectedReport || ""}
        onHandleSelectReport={handleChangeReport}
        // Date Filter
        startDate={props.AnesthesiaStatReportSequence?.filterReport?.filterStartDate || ""}
        endDate={props.AnesthesiaStatReportSequence?.filterReport?.filterEndDate || ""}
        onHandleChangeDate={handleChangeDate}
        // Search Count
        searchCount={anesDataCount || 0}
        // Table
        tableData={
          props.AnesthesiaStatReportSequence?.preAnesData?.map((item: any) => {
            const getDuration = calDuration(item.data?.StartAnesTime, item.data?.EndAnesTime);
            return {
              ...item,
              anesDate: item.data?.DateRamrk, // วันที่เริ่มดมยา
              startTime: item.data?.StartAnesTime, // เวลาเริ่มดมยา
              endDate: item.data?.DateRamrk, // วันที่สิ้นสุด
              endTime: item.data?.EndAnesTime, // เวลาที่สิ้นสุด
              timeDuration: getDuration
                ? getDuration.hours > 0
                  ? `${getDuration.hours} ชม. ${getDuration.minutes} นาที`
                  : `${getDuration.minutes}`
                : null, // ช่วงเวลาที่ดมยา
              asa: item.data?.ASAPS, // ASA
              anesMethod: getAnesMethod(item.data, ANES_MEHTOD), // วิธีระงับความรู้สึก
            };
          }) || []
        }
        // Search ButtonLoadCheck
        searchButtonLoadCheck={
          <ButtonLoadCheck
            size="medium"
            setProp={props.setProp}
            onClick={handleSearchData}
            paramKey={`${CARD_KEY}_${ACTIONS.SEARCH_DATA}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_${ACTIONS.SEARCH_DATA}`]}
            color="blue"
            title="ค้นหา"
          />
        }
        // Export Excel ButtonLoadCheck
        exportButtonLoadCheck={
          <ButtonLoadCheck
            size="medium"
            setProp={props.setProp}
            onClick={handleExportExcel}
            paramKey={`${CARD_KEY}_${ACTIONS.PRINT_REPORT}_${PRINT_OPTIONS.EXCEL}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_KEY}_${ACTIONS.PRINT_REPORT}_${PRINT_OPTIONS.EXCEL}`]
            }
            color="green"
            title="ดาวน์โหลด xlsx"
          />
        }
        // Print Report ButtonLoadCheck
        printButtonLoadCheck={
          <ButtonLoadCheck
            size="medium"
            setProp={props.setProp}
            onClick={handlePrintReport}
            paramKey={`${CARD_KEY}_${ACTIONS.PRINT_REPORT}_${PRINT_OPTIONS.STAT}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_KEY}_${ACTIONS.PRINT_REPORT}_${PRINT_OPTIONS.STAT}`]
            }
            color="orange"
            title="พิมพ์สถิติ"
          />
        }
      />
    </>
  );
};

export default CardAnesthesiaStatReport;
