import React, { useRef, useEffect, useState, MutableRefObject, SyntheticEvent } from "react";
import { Dropdown, Form } from "semantic-ui-react";

import { ErrorMessage, ModConfirm } from "react-lib/apps/common";

type CardDuplicateDrugClassificationOrdersProps = {
  duplicateReasonsOptions: {
    key: string;
    text: string;
    value: string | number;
  }[];
  duplicateList:
    {
      duplicate_list: {
        duplicated_detail: string;
        note_group_duplicated: string;
        product: number;
      }[]
    };
  onClose: Function;
  onApprove: Function;
};

const CardDuplicateDrugClassificationOrders: React.FunctionComponent<
  CardDuplicateDrugClassificationOrdersProps
> = (props) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [drugGroupDuplicateNote, setDuplicateNote] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);

  const dropdownRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    setOptions(props.duplicateReasonsOptions || []);
  }, [props.duplicateReasonsOptions]);

  const handleConfirm = () => {
    const note = options.find((item) => item.value === drugGroupDuplicateNote)?.text || "";

    // const firstNote = props.duplicateList[0].duplicated_note;

    // if (!note && !firstNote) {
    //   return setErrorMessage("กรุณาระบุ เหตุผลที่สั่งยาซ้ำ");
    // }
    if (note) {
      props.onApprove?.({
        // duplicated_note: note || firstNote,
        duplicated_note: note,
      });
    }
  };

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    setOptions([...options, { key: v.value, text: v.value, value: v.value }]);
  };

  console.log("CardDuplicateDrugClassificationOrder props", props)
  return (
    <div>
      <ModConfirm
        openModal={true}
        titleName={"พบรายการสั่งยาซ้ำตาม Drug Classification"}
        titleColor={"orange"}
        size="small"
        approveButtonText={"ยืนยัน"}
        denyButtonText={"ยกเลิก"}
        onApprove={handleConfirm}
        disabledApproveButton={!drugGroupDuplicateNote}
        onDeny={props.onClose}
        onCloseWithDimmerClick={props.onClose}
        content={
          <>
            <ErrorMessage error={errorMessage} />
            {(props.duplicateList?.duplicate_list || []).map((item: any) => (
              <div className="ui" style={{ whiteSpace: "pre-wrap", lineHeight: 1.75 }}>
                <div dangerouslySetInnerHTML={{ __html: item?.duplicated_detail }} />
              </div>
            ))}
            <br />
            หากต้องการยืนยันการสั่ง กรุณาใส่เหตุผล
            <Dropdown
              ref={dropdownRef}
              placeholder="กรุณาระบุเหตุผลการสั่งซ้ำ"
              value={drugGroupDuplicateNote}
              selection={true}
              fluid
              search
              options={options}
              allowAdditions
              onAddItem={handleAddOptions}
              onChange={(e, v) => setDuplicateNote(v.value)}
            />
          </>
        }
      />
    </div>
  );
};

export default React.memo(CardDuplicateDrugClassificationOrders);
