import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button,
  Radio,
  Dropdown,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardConfirmCoverageUX = (props: any) => {
    return(
      <div
        style={{ width: "100%", height: "100%" , padding: "15px"}}>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                รายชื่อสิทธิ
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{flex: 1}}>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="blue"
                id="btn-addCoverage"
                onClick={props.onNewCoverage}
                size="small">
                เพิ่มสิทธิการรักษา
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <Form>
          <Table
            data={props.coverageList}
            getTrProps={props.rowProps}
            headers="Coverage Name,Payer Name,Service Type,Priority,Status"
            id="tb-coverage"
            keys="coverage_name,payer_name,service_type_name,priority,status"
            minRows={3}
            showPagination={false}
            style={{height: "150px"}}>
          </Table>
        </Form>
        <Form>
          <FormField>
            <label
              style={{paddingTop: "1rem"}}>
              {props.new ? "ข้อมูลสิทธิ (สร้างใหม่)": "ข้อมูลสิทธิเดิม"}
            </label>
          </FormField>
        </Form>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              style={{marginRight: "0.5rem"}}>
              <label
                style={{color: "red", fontWeight: "normal", minWidth: "max-content"}}>
                ประเภทบริการ
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.service_type === "O"}
                id="rdo-opd"
                label="OPD"
                onChange={props.changeCoverage("service_type", "O")}>
              </Radio>
            </FormField>
            <FormField
              inline={true}
              style={{marginRight: "0.5rem"}}>
              <Radio
                checked={props.service_type === "I"}
                id="rdo-ipd"
                label="IPD"
                onChange={props.changeCoverage("service_type", "I")}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "normal"}}>
                ลำดับการเลือกใช้สิทธิ
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{marginRight: "0.5rem"}}
              width={3}>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-priority"
                onChange={props.changeCoverage("priority")}
                options={props.priorityOptions}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.priority}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.active}
                id="rdo-claim"
                label="รับรองสิทธิ"
                onChange={props.changeCoverage("active", true)}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={!props.active}
                id="rdo-suspendClaim"
                label="ระงับสิทธิ"
                onChange={props.changeCoverage("active", false)}>
              </Radio>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              className="required"
              width={8}>
              <label
                style={{color: "red", fontWeight: "normal"}}>
                สิทธิ
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-coverage"
                onChange={props.changeCoverage("coverage")}
                options={props.coverageOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.coverage}>
              </Dropdown>
            </FormField>
            <FormField
              className="required"
              width={8}>
              <label
                style={{color: "red", fontWeight: "normal"}}>
                ประเภทสิทธิ์
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-assureType"
                onChange={props.changeCoverage("assure_type")}
                options={props.assureTypeOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.assure_type}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <div
              className="eight wide field">
              {props.searchBoxPayer}
            </div>
            <FormField
              width={8}>
              <label
                style={{fontWeight: "normal"}}>
                รหัสสถานพยาบาลหลัก
              </label>
              <Input
                fluid={true}
                id="txt-hosCode"
                onChange={props.changeCoverage("main_hospital_code")}
                value={props.main_hospital_code}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={4}>
              <label
                style={{color: "red", fontWeight: "normal"}}>
                วันที่เริ่ม*
              </label>
              <DateTextBox
                id="dt-startDateCLM"
                onChange={props.changeDate("start_date")}
                value={props.start_date}>
              </DateTextBox>
            </FormField>
            <FormField
              width={4}>
              <label
                style={{color: "red", fontWeight: "normal"}}>
                วันหมดอายุ*
              </label>
              <DateTextBox
                id="dt-endDateCLM"
                onChange={props.changeDate("stop_date")}
                value={props.stop_date}>
              </DateTextBox>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={16}>
              <hr>
              </hr>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField>
              <label>
                Refer-in
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{fontWeight: "normal", minWidth: "55px", maxWidth: "55px"}}>
                Referer:
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeCoverage("referer")}
                options={props.refererOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.referer}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{fontWeight: "normal", width: "100%", maxWidth: "max-content"}}>
                Refer date:
              </label>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.changeDate("refer_date")}
                  value={props.refer_date}>
                </DateTextBox>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={16}>
              <label
                style={{fontWeight: "normal", minWidth: "55px", maxWidth: "55px"}}>
                Refer No.
              </label>
              <Input
                fluid={true}
                onChange={props.changeCoverage("refer_no")}
                value={props.refer_no}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={10}>
              <label
                style={{fontWeight: "normal", minWidth: "55px", maxWidth: "55px"}}>
                จุดบริการ
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                onChange={props.changeCoverage("service_point")}
                options={props.servicePointOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.servicePoint}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="green"
                id="btn-saveCoverage"
                loading={props.loadingSave}
                onClick={props.onSave}
                size="small">
                {props.button_save}
              </Button>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardConfirmCoverageUX

export const screenPropsDefault = {"searchBoxPayer":"[SearchBox Payer]"}

/* Date Time : Mon Mar 18 2024 16:33:28 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" , padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormGroup",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 67,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 72,
      "name": "Table",
      "parent": 71,
      "props": {
        "data": {
          "type": "code",
          "value": "props.coverageList"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "Coverage Name,Payer Name,Service Type,Priority,Status"
        },
        "id": {
          "type": "value",
          "value": "tb-coverage"
        },
        "keys": {
          "type": "value",
          "value": "coverage_name,payer_name,service_type_name,priority,status"
        },
        "minRows": {
          "type": "code",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormGroup",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "รายชื่อสิทธิ"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Button",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มสิทธิการรักษา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-addCoverage"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewCoverage"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 73,
      "props": {
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "code",
          "value": "props.new ? \"ข้อมูลสิทธิ (สร้างใหม่)\": \"ข้อมูลสิทธิเดิม\""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"1rem\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 76,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "label",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทบริการ"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", fontWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Radio",
      "parent": 82,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.service_type === \"O\""
        },
        "id": {
          "type": "value",
          "value": "rdo-opd"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"service_type\", \"O\")"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Radio",
      "parent": 83,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.service_type === \"I\""
        },
        "id": {
          "type": "value",
          "value": "rdo-ipd"
        },
        "label": {
          "type": "value",
          "value": "IPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"service_type\", \"I\")"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "ลำดับการเลือกใช้สิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Dropdown",
      "parent": 85,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-priority"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"priority\")"
        },
        "options": {
          "type": "code",
          "value": "props.priorityOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.priority"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Radio",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.active"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "rdo-claim"
        },
        "label": {
          "type": "value",
          "value": "รับรองสิทธิ"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"active\", true)"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Radio",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "!props.active"
        },
        "id": {
          "type": "value",
          "value": "rdo-suspendClaim"
        },
        "label": {
          "type": "value",
          "value": "ระงับสิทธิ"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"active\", false)"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormGroup",
      "parent": 75,
      "props": {
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormGroup",
      "parent": 75,
      "props": {
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 95,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 95,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 96,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", fontWeight: \"normal\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทสิทธิ์"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", fontWeight: \"normal\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสสถานพยาบาลหลัก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Dropdown",
      "parent": 97,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-coverage"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"coverage\")"
        },
        "options": {
          "type": "code",
          "value": "props.coverageOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.coverage"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Dropdown",
      "parent": 98,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-assureType"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"assure_type\")"
        },
        "options": {
          "type": "code",
          "value": "props.assureTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.assure_type"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBoxPayer"
        },
        "className": {
          "type": "value",
          "value": "eight wide field"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Input",
      "parent": 100,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-hosCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"main_hospital_code\")"
        },
        "value": {
          "type": "code",
          "value": "props.main_hospital_code"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormGroup",
      "parent": 75,
      "props": {
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 111,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 111,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เริ่ม*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", fontWeight: \"normal\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "วันหมดอายุ*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", fontWeight: \"normal\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 116,
      "name": "DateTextBox",
      "parent": 112,
      "props": {
        "id": {
          "type": "value",
          "value": "dt-startDateCLM"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"start_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.start_date"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 117,
      "name": "DateTextBox",
      "parent": 113,
      "props": {
        "id": {
          "type": "value",
          "value": "dt-endDateCLM"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"stop_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.stop_date"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormGroup",
      "parent": 75,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 118,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "hr",
      "parent": 120,
      "props": {
      },
      "seq": 121,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormGroup",
      "parent": 75,
      "props": {
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 122,
      "props": {
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "Refer-in"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormGroup",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "FormField",
      "parent": 125,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "FormField",
      "parent": 125,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "Referer:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"55px\", maxWidth: \"55px\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "Refer date:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Dropdown",
      "parent": 126,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"referer\")"
        },
        "options": {
          "type": "code",
          "value": "props.refererOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.referer"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 132,
      "name": "DateTextBox",
      "parent": 131,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"refer_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.refer_date"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "Refer No."
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"55px\", maxWidth: \"55px\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Input",
      "parent": 134,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"refer_no\")"
        },
        "value": {
          "type": "code",
          "value": "props.refer_no"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormGroup",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "จุดบริการ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"55px\", maxWidth: \"55px\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Dropdown",
      "parent": 138,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"service_point\")"
        },
        "options": {
          "type": "code",
          "value": "props.servicePointOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.servicePoint"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormGroup",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Button",
      "parent": 143,
      "props": {
        "children": {
          "type": "code",
          "value": "props.button_save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "btn-saveCoverage"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingSave"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 144,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardConfirmCoverageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "searchBoxPayer": "[SearchBox Payer]"
  },
  "width": 65
}

*********************************************************************************** */
