import React, { useEffect } from "react";
// ui ORM
import CardTourniquetUX from "./CardTourniquetUX";
import { Button, Dropdown, Form, Input } from "semantic-ui-react";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import PreOPButtonAction from "./PreOPButtonAction";

const CARD_KEY: string = "tourniquetData";
const FORM_CODE: string = "CardTourniquet";
const FORM_NAME: string = "Tourniquet";
const FORM_VERSION: string = "1.0";

type CardTourniquetProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardTourniquetInitial: CardTourniquetProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PerioperativeNursingSequence: {},
};

const Tourniquet = {
  round: 0,
  site: "",
  pressure: "",
  time_on: "",
  time_off: "",
  operating_order_id: "",
};

const CardTourniquet: React.FC<CardTourniquetProps> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
    });
  }, []);

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleChangeTourniquet = (value: any, index: any, key: any) => {
    props.setProp(
      `PerioperativeNursingSequence.tourniquetData.data.${index}.${key}`,
      value
    );
  };

  const handlerAddRound = () => {
    const items: any[] =
      props.PerioperativeNursingSequence?.tourniquetData?.data || [];
    items.push({
      ...Tourniquet,
      round: items?.length + 1,
      operating_order_id: props.selectedOrOrder?.id,
    });
    props.setProp("PerioperativeNursingSequence.tourniquetData.data", items);
  };

  const handlerDeleteRound = (index: any) => {
    const items: any[] =
      props.PerioperativeNursingSequence?.tourniquetData?.data || [];

    if (items.length === 1) {
      props.setProp("PerioperativeNursingSequence.tourniquetData.data.0", {
        ...props.PerioperativeNursingSequence.tourniquetData.data?.[0],
        round: "",
        site: "",
        pressure: "",
        time_on: "",
        time_off: "",
      });
    } else if (items.length > 1) {
      items.splice(index, 1);
      props.setProp("PerioperativeNursingSequence.tourniquetData.data", items);
    }
  };

  return (
    <>
      <CardTourniquetUX
        status={props.PerioperativeNursingSequence?.tourniquetData?.status}
        round={
          <Form>
            {props.PerioperativeNursingSequence?.tourniquetData?.data?.map(
              (item: any, index: any) => {
                return (
                  <RoundData
                    item={item}
                    index={index}
                    onChangeTourniquet={handleChangeTourniquet}
                    handlerDeleteRound={handlerDeleteRound}
                    handlerAddRound={handlerAddRound}
                    orTourniquetSide={props.masterOptions?.orTourniquetSide}
                  />
                );
              }
            )}
          </Form>
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
      />
    </>
  );
};

const RoundData = (props: any) => {
  const handleChange = (key: string) => (e: any, data?: any) => {
    const value = typeof e === "string" ? e : data.value;
    props.onChangeTourniquet(value, props.index, key);
  };

  return (
    <Form.Group inline>
      <Form.Field inline width={2}>
        <div>Round</div>
        <Input
          fluid={true}
          value={props.item.round}
          type="number"
          onChange={handleChange("round")}
        ></Input>
      </Form.Field>
      <Form.Field inline width={3}>
        <Dropdown
          selection={true}
          fluid={true}
          options={props.orTourniquetSide}
          value={props.item.site}
          style={{ width: "100%" }}
          onChange={handleChange("site")}
        ></Dropdown>
      </Form.Field>
      <Form.Field inline width={4}>
        <div style={{ minWidth: "max-content" }}>Pressure (mmHg)</div>
        <Input
          value={props.item.pressure}
          onChange={handleChange("pressure")}
        ></Input>
      </Form.Field>
      <Form.Field inline width={3}>
        <div style={{ minWidth: "max-content" }}>Time on</div>
        <TimeComboBox
          fluid={true}
          style={{ width: "100%" }}
          defaultValue={props.item?.time_on || ""}
          onTextChange={handleChange("time_on")}
        ></TimeComboBox>
      </Form.Field>
      <Form.Field inline width={3}>
        <div style={{ minWidth: "max-content" }}>Time off</div>
        <TimeComboBox
          fluid={true}
          style={{ width: "100%" }}
          defaultValue={props.item?.time_off || ""}
          onTextChange={handleChange("time_off")}
        ></TimeComboBox>
      </Form.Field>

      <Form.Field inline style={{ display: "flex", width: "6rem" }}>
        <Button
          color="red"
          icon="minus"
          size="mini"
          style={{ margin: "0px 1rem 0 0.5rem" }}
          onClick={() => props.handlerDeleteRound(props.index)}
        ></Button>
        <Button
          color="blue"
          icon="plus"
          size="mini"
          style={{ display: props.index === 0 ? "block" : "none" }}
          onClick={() => props.handlerAddRound()}
        ></Button>
      </Form.Field>
    </Form.Group>
  );
};

CardTourniquet.defaultProps = CardTourniquetInitial;

export default React.memo(CardTourniquet);
