import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
// APIs
import AnesthesiaDataList from "issara-sdk/apis/AnesthesiaDataList_apps_ANS";
import ANSFormPrint from "issara-sdk/apis/ANSFormPrint_apps_ANS";
import FormDataActionLogList from "issara-sdk/apis/FormDataActionLogList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import UserTokenizeLicenseView from "issara-sdk/apis/UserTokenizeLicenseView_users";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import { formatDate } from "react-lib/utils/dateUtils";
import TeamDetail from "issara-sdk/apis/TeamDetail_apps_ORM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  AnesRecordSequence?: {
    sequenceIndex?: string | null;

    id?: number | null;
    data?: {
      // CardAnesRecord01UX
      DateRamrk?: string;
      ORRmark?: string;
      PatientLocation?: any;
      IsWardRemark?: string;
      StartAnesTime?: string | null;
      StartOpTime?: string | null;
      EndAnesTime?: string | null;
      PACUDurartion?: string | null;
      AnesthesiaTeam: "";
      PreoperativeDiagnosis: "";
      PostoperativeDiagnosis: "";
      SurgicalTeam: "";
      ProposedOperation: "";
      PerformedOperation: "";
      // CardAnesRecord02UX
      IsIdentified: boolean;
      IsConsentPage: boolean;
      ConsentPageRemark?: string | null;
      NPO?: string | null;
      ASAPS?: string | null;
      IsEmergency: boolean;
      Premedication?: any;
      Status?: string | null;
      Problems?: string | null;
      Position?: string | null;
      Monitors?: string | null;
      MonitorsRemark?: string | null;
      Emergency?: string | null;
      IsVerbalCommand: boolean;
      IsEffecttiveCough: boolean;
      IsSwallowingGag: boolean;
      AdequateSpontaneousVentilation: boolean;
      IsHandGripHeadLift: boolean;
      IsStableHemodynamic: boolean;
      IsNoneAfterExtubation: boolean;
      UpperAirwayObstruction: boolean;
      Cyanosis: boolean;
      Wheezing: boolean;
      Dyspnea: boolean;
      IsGeneralAnesthesia: boolean;
      IsUnderMask: boolean;
      IsLMA: boolean;
      IsEndotrachealTube: boolean;
      IsTracheostomyTube: boolean;
      IsTIVA: boolean;
      IsRegionalAnesthesia: boolean;
      IsSpinalBlock: boolean;
      IsEpiduralBlock: boolean;
      IsCombinedSpinalAndEpiduralBlock: boolean;
      IsCaudalblock: boolean;
      IsBrachialPlexusBlock: boolean;
      IsOthers: boolean;
      IsOthersRemark?: string | null;
      IsMAC: boolean;
      ApproachAndSite?: string | null;
      AgentAndConc?: string | null;
      Bevel?: string | null;
      NeedleNo?: string | null;
      Attempts?: string | null;
      Analgesiclevel?: string | null;
      SkinToEpiduralSubarahnoidDepth?: string | null;
      CatheterNo?: string | null;
      CatheterLengthInEpiduralSpace?: string | null;
      IsCombinedGeneralAndRegionalAnesthesia: boolean;
      DutTo?: string | null;
      AirwayManagementIsNone: boolean;
      AirwayManagementAreaIs2: boolean;
      AirwayManagementAreaDropdown2?: string | null;
      AirwayManagementAreaNo2?: string | null;
      AirwayManagementAreaIs3: boolean;
      AirwayManagementAreaDropdown3?: string | null;
      AirwayManagementAreaNo3?: string | null;
      AirwayManagementAreaIs4: boolean;
      AirwayManagementAreaDropdown4?: string | null;
      AirwayManagementAreaIs5: boolean;
      AirwayManagementAreaDropdown5?: string | null;
      AirwayManagementAreaIs6: boolean;
      AirwayManagementAreaDropdown6?: string | null;
      AirwayManagementAreaIs1?: string | null;
      AirwayManagementAreaNo1?: string | null;
      IntubationTime?: string | null;
      AirwayManagementAttempts?: string | null;
      LaryngoscopeView?: string | null;
      Adjunct?: string | null;
      AirwayManagementInduction?: string | null;
      AirwayManagementVentilation?: string | null;
      TransferTo?: string | null;
    };
    modLogInfo?: { open?: boolean; logList: any[] };
  } | null;
};

export const StateInitial: State = {
  AnesRecordSequence: {
    sequenceIndex: null,

    id: null,
    data: {
      // CardAnesRecord01UX
      DateRamrk: "",
      ORRmark: "",
      PatientLocation: "",
      IsWardRemark: "",
      StartAnesTime: "",
      StartOpTime: "",
      EndAnesTime: "",
      PACUDurartion: "",
      AnesthesiaTeam: "",
      PreoperativeDiagnosis: "",
      PostoperativeDiagnosis: "",
      SurgicalTeam: "",
      ProposedOperation: "",
      PerformedOperation: "",
      // CardAnesRecord02UX
      IsIdentified: false,
      IsConsentPage: false,
      ConsentPageRemark: "",
      NPO: "",
      ASAPS: "",
      IsEmergency: false,
      Premedication: [{ premedication: "" }],
      Status: "",
      Problems: "",
      Position: "",
      Monitors: "",
      MonitorsRemark: "",
      Emergency: "",
      IsVerbalCommand: false,
      IsEffecttiveCough: false,
      IsSwallowingGag: false,
      AdequateSpontaneousVentilation: false,
      IsHandGripHeadLift: false,
      IsStableHemodynamic: false,
      IsNoneAfterExtubation: false,
      UpperAirwayObstruction: false,
      Cyanosis: false,
      Wheezing: false,
      Dyspnea: false,
      IsGeneralAnesthesia: false,
      IsUnderMask: false,
      IsLMA: false,
      IsEndotrachealTube: false,
      IsTracheostomyTube: false,
      IsTIVA: false,
      IsRegionalAnesthesia: false,
      IsSpinalBlock: false,
      IsEpiduralBlock: false,
      IsCombinedSpinalAndEpiduralBlock: false,
      IsCaudalblock: false,
      IsBrachialPlexusBlock: false,
      IsOthers: false,
      IsOthersRemark: "",
      IsMAC: false,
      ApproachAndSite: "",
      AgentAndConc: "",
      Bevel: "",
      NeedleNo: "",
      Attempts: "",
      Analgesiclevel: "",
      SkinToEpiduralSubarahnoidDepth: "",
      CatheterNo: "",
      CatheterLengthInEpiduralSpace: "",
      IsCombinedGeneralAndRegionalAnesthesia: false,
      DutTo: "",
      AirwayManagementIsNone: false,
      AirwayManagementAreaIs2: false,
      AirwayManagementAreaDropdown2: "",
      AirwayManagementAreaNo2: "",
      AirwayManagementAreaIs3: false,
      AirwayManagementAreaDropdown3: "",
      AirwayManagementAreaNo3: "",
      AirwayManagementAreaIs4: false,
      AirwayManagementAreaDropdown4: "",
      AirwayManagementAreaIs5: false,
      AirwayManagementAreaDropdown5: "",
      AirwayManagementAreaIs6: false,
      AirwayManagementAreaDropdown6: "",
      AirwayManagementAreaIs1: "",
      AirwayManagementAreaNo1: "",
      IntubationTime: "",
      AirwayManagementAttempts: "",
      LaryngoscopeView: "",
      Adjunct: "",
      AirwayManagementInduction: "",
      TransferTo: "",
    },
    modLogInfo: { open: false, logList: [] },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetMaster: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["drugName", {}],
        ["divisionWard", {}],
        ["anes_asa_ps", {}],
        ["ansrec_preind_status", {}],
        ["ansrec_position", {}],
        ["ansrec_monitors", {}],
        ["ansrec_emergence", {}],
        ["ansrec_bevel", {}],
        ["ansrec_cmbi_gl_rl", {}],
        ["ansrec_airman_chkb1", {}],
        ["ansrec_airman_chkb2", {}],
        ["ansrec_airman_chkb3", {}],
        ["ansrec_airman_chkb4", {}],
        ["ansrec_airman_chkb5", {}],
        ["ansrec_airman_tecint", {}],
        ["ansrec_airman_induct", {}],
        ["ansrec_airman_ventil", {}],
      ],
    },
  });

  controller.setState(
    {
      AnesRecordSequence: { sequenceIndex: "Action" },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH_ANESRECORD") {
    const [formPeriResp, formPeriErr, formPeriNet] = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedOrOrder?.encounter,
        form_code: params.formCode,
        form_version: params.formVersion,
      },
    });

    // set anesthesiaTeam, surgicalTeam
    const [operatingResp, operatingErr, operatingNet] = await OperatingOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder.id,
    });

    const [anesthesiaResp, anesthesiaErr, anesthesiaNet] = await DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: operatingResp?.anesthesia_teams[0]?.anesthesiologist,
    });

    const [surgicalTeamResp, surgicalTeamErr, surgicalTeamNet] = await DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: operatingResp?.teams[0]?.chief_surgeon,
    });

    // set postoperativeDiagnosis, surgicalTeam
    const [teamDetailResp, teamDetailErr, teamDetailNet] = await TeamDetail.retrieve({
      apiToken: controller.apiToken,
      pk: operatingResp?.teams[0]?.id,
    });

    const endAnesDate = `${moment(operatingResp?.end_date_time).format("DD/MM/")}${
      Number(moment(operatingResp?.end_date_time).format("YYYY")) + 543
    }`;
    const endAnesTime = moment(operatingResp?.end_date_time).format("HH:mm");

    const mainIndex = operatingResp?.teams?.findIndex((team: any) => team.is_main);

    let dataAnesRecord = {
      AnesthesiaTeam: anesthesiaResp?.name_code,
      SurgicalTeam: surgicalTeamResp?.name_code,
      ORRmark: operatingResp?.operating_room_no,
      PreoperativeDiagnosis: `[${operatingResp?.teams[0]?.pre_principal_diagnosis[0]?.icd_code}] ${operatingResp?.teams[0]?.pre_principal_diagnosis[0]?.icd_term}`,
      PostoperativeDiagnosis:
        teamDetailResp?.post_principal_diagnosis.length !== 0
          ? `[${teamDetailResp?.post_principal_diagnosis[0]?.icd_code}] ${teamDetailResp?.post_principal_diagnosis[0]?.icd_term}`
          : "",
      ProposedOperation:
        operatingResp?.teams?.[0]?.pre_operating_order_item.operating_treatments?.[0]?.name_code,
      PerformedOperation:
        teamDetailResp?.post_operating_order_item !== null
          ? teamDetailResp?.post_operating_order_item?.treatment?.[0]?.name_code
          : "",
      StartAnesDate: operatingResp?.start_date,
      StartAnesTime: operatingResp?.start_time,
      EndAnesDate: endAnesDate || "",
      EndAnesTime: endAnesTime || "",
      TotalAnseTimeHour: operatingResp?.estimate_operation_hour,
      TotalAnseTimeMinute: operatingResp?.estimate_operation_minute,
      Anesthesiologist: operatingResp?.anesthesia_teams?.[mainIndex]?.anesthesiologist || null,
      AnesthesiaNurse: operatingResp?.anesthesia_teams?.[mainIndex]?.anesthetists
    };
    if (formPeriErr) {
      let seq: any = {
        ...StateInitial.AnesRecordSequence,
        sequenceIndex: state.AnesRecordSequence?.sequenceIndex,
      };

      controller.setState({
        AnesRecordSequence: {
          ...seq,
          data: {
            ...seq.data,
            ...dataAnesRecord,
            DateRamrk: formatDate(moment()),
          },
        },
      });
    } else {
      if (formPeriResp.status === "CONFIRM") {
        controller.setState({
          AnesRecordSequence: {
            ...state.AnesRecordSequence,
            ...formPeriResp,
            PostoperativeDiagnosis:
              teamDetailResp?.post_principal_diagnosis.length !== 0
                ? `[${teamDetailResp?.post_principal_diagnosis[0]?.icd_code}] ${teamDetailResp?.post_principal_diagnosis[0]?.icd_term}`
                : "",
            PerformedOperation:
              teamDetailResp !== null
                ? `[${teamDetailResp?.post_operating_order_item?.procedures[0]?.icd9cm_code}] ${teamDetailResp?.post_operating_order_item?.procedures[0]?.icd9cm_term}`
                : "",
          },
        });
      } else {
        controller.setState({
          AnesRecordSequence: {
            ...state.AnesRecordSequence,
            ...formPeriResp,
            data: {
              ...dataAnesRecord,
              ...formPeriResp.data,
            },
          },
        });
      }
    }
  } else if (params?.action === "SAVE" || params?.action === "CONFIRM") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let saveResp = null;
    let saveErr = null;
    let saveNet = null;

    const data = {
      action: params.action, // SAVE
      data: state.AnesRecordSequence?.data,
      encounter: state.selectedOrOrder?.encounter,
      form_code: params.formCode,
      form_name: params.formName,
      form_version: params.formVersion,
    };

    console.log("test_data",data)
    if (state.AnesRecordSequence?.id) {
      [saveResp, saveErr, saveNet] = await FormDataDetail.update({
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
        pk: state.AnesRecordSequence?.id,
        data: data,
      });
    } else {
      [saveResp, saveErr, saveNet] = await FormDataList.create({
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
        data: data,
      });
    }
    if (saveErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
      });
      Action(controller, { ...params, action: "FETCH_ANESRECORD" });
    }
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.AnesRecordSequence?.id) {
      // Todo
      // const pngData = await ansTableRef.current.getImageData();
      const [printResp, printErr, printNet] = await ANSFormPrint.post({
        apiToken: controller.apiToken,
        data: {
          form_id: state.AnesRecordSequence?.id,
          pdf: true,
          // image: pngData,  // Todo
        },
      });
      if (printErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: printErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "บันทึก Form ก่อนทำรายการ",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "LOG") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.AnesRecordSequence?.id) {
      const [logResp, logErr, logNet] = await FormDataActionLogList.list({
        apiToken: controller.apiToken,
        pk: state.AnesRecordSequence?.id,
      });
      if (logErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: logErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
          AnesRecordSequence: {
            ...state.AnesRecordSequence,
            modLogInfo: { open: true, logList: logResp?.items || [] },
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "ไม่มี Form id",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "GET_USER_TOKENIZE_LICENSE") {
    const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
      apiToken: controller.apiToken,
      data: {
        code: state.AnesRecordSequence?.data?.[`${params.paramKey}Code`],
      },
    });

    let seq: any = { ...state.AnesRecordSequence };

    if (userErr) {
      seq.data[params.paramKey] = "";
      seq.data[`${params.paramKey}LicenseNumber`] = "";
      seq.data[`${params.paramKey}Code`] = "";

      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
      });
    } else {
      const encodedName = userResp.token.split(".")[1];
      seq.data[params.paramKey] = decodeURIComponent(atob(encodedName));
      seq.data[`${params.paramKey}LicenseNumber`] = userResp.license;
    }
    controller.setState({ AnesRecordSequence: seq });
  }
};
