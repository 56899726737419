import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { ModConfirm } from "react-lib/apps/common";
import { Radio, Dimmer, Loader } from "semantic-ui-react";

// Utils
import { formatDate } from "../../utils/dateUtils";

const ModSelectEncounter = (props: any) => {
  const [checkedEncounter, setCheckedEncounter] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    props.onEvent({
      message: "GetEncounterWithPatient",
      params: { patientId: props.patientId },
    });
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [props.encounterList]);

  const encounterList = useMemo(() => {
    if(props.encounterFilterType){
      return [
        ...(props.encounterList || []).filter((item: any) => item.type === props.encounterFilterType),
        ...(props.isNewEncounter ? [{ id: "NO_ID" }] : []),
      ];
    }
    return [
      ...(props.encounterList || []),
      ...(props.isNewEncounter ? [{ id: "NO_ID" }] : []),
    ];
  }, [props.encounterList]);

  const handleApprove = () => {
    const id = checkedEncounter === "NO_ID" ? undefined : checkedEncounter;
    props.onApprove(id);
  };

  return (
    <ModConfirm
      openModal={true}
      titleName={
        props.appointmentType === "CONSULT"? `เลือก Encounter สำหรับสร้างนัดหมาย Consult ${props.encounterFilterType}`
        : props.isNewEncounter
          ? "เลือก Encounter สำหรับสร้างนัดหมายผ่าตัด (EN ใหม่)"
          : "เลือก Encounter สำหรับสร้างนัดหมายผ่าตัดจาก EN เดิม"
      }
      denyButtonColor="grey"
      denyButtonText="ยกเลิก"
      approveButtonColor="green"
      approveButtonText="ยืนยัน"
      disabledApproveButton={!checkedEncounter}
      basic={false}
      modalStyle={{ width: "30%" }}
      content={
        <>
          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>

          {!isLoading && !!!encounterList?.length && (
            <div
              style={{
                fontSize: "1.25rem",
                margin: "1rem 0 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              ไม่พบ Encounter
            </div>
          )}

          {encounterList.map((item: any) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            >
              <Radio
                checked={item.id === checkedEncounter}
                onChange={() => setCheckedEncounter(item.id)}
                style={{ marginRight: "1rem", fontWeight: "bold" }}
                label={
                  item.id !== "NO_ID"
                    ? `Encounter ${item.number} (${item.type}) ${formatDate(
                        moment(item.created_utc)
                      )} ${moment(item.created_utc).format("HH:mm")} ${
                        item.division_name
                      }, ${item.doctor_name}`
                    : `ไม่ระบุ Encounter`
                }
              />
              {/* <label style={{ fontWeight: "bold" }}>
              
            </label> */}
            </div>
          ))}
        </>
      }
      onApprove={handleApprove}
      onDeny={props.onClose}
      onCloseWithDimmerClick={props.onClose}
    />
  );
};

export default ModSelectEncounter;
