import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Label, Input, Segment, Icon, Button } from "semantic-ui-react";
import PureReactTable from "../../component-js/common/PureReactTable";
import ReactTable from "react-table-6";

// id: 347638
// type: "CentralLabOrder"
// date: "01/02/2562 [14:25]"
// encounter_type: "IPD"
// doctor: "วันนิษา วงษ์พิพัฒน์พงษ์"
// division: "หอผู้ป่วย 5C"
// primary_diagnosis: "AIDS C Cryptococcal meningitis (AIDS C Cryptococcal meningitis : B205, B205 HIV disease resulting in other mycoses)↵↵"
// secondary_diagnosis: "Anemia of chronic disease (anemia/ anaemia/ aneamia) (Anemia of chronic disease (anemia/ anaemia/ aneamia) : D539, D539 Nutritional anaemia)↵↵"
// status: "ร้องขอทำ Lab"
// items:
//     lab: "CSF Cell count and Diff"
//     lab_code: "150012"
//     value: ""
//     reference_value: ""
//     unit: "Y06 : test"
//     remark: ""
//     status: "ร้องขอทำ Lab"

const defaultData = {};

const SubIVSelected = React.forwardRef((props, ref) => {
  const [itemData, setItemData] = useState(defaultData);
  const [isShow, setIsShow] = useState(true);

  const { isReadOnly, item } = props;
  const focusRef = useRef(null);
  const contentRef = useRef(null);

  const style = {
    paddingTop: { paddingTop: "20px" },
    segmentGrayStyle: { marginTop: "0px", backgroundColor: "#e8e8e8" },
    segmentBlueStyle: {
      marginTop: "20px",
      marginBottom: "0px",
      backgroundColor: "rgb(3, 29, 62)",
      display: "flex",
      justifyContent: "space-between",
      color: "rgb(243, 243, 243)",
      fontWeight: "bold",
      cursor: "pointer"
    }
  };

  const gridColumns = [
    {
      Header: "Lab",
      accessor: "lab"
    },
    {
      Header: "Value",
      accessor: "value",
      width: 200
    },
    {
      Header: "Ref-value",
      accessor: "reference_value",
      width: 150
    },
    {
      Header: "Unit",
      accessor: "unit",
      width: 150
    },
    {
      Header: "หมายเหตุ",
      accessor: "remark",
      width: 200
    },
    {
      Header: "สถาณะ",
      accessor: "status",
      width: 150
    }
  ];

  useEffect(() => {
    for (var i = 0; i < item.items.length; i++) {
      var subLab = item.items[i];
      subLab.id = i;
    }
    setItemData(item);
  }, [item]);

  React.useImperativeHandle(ref, () => ({
    getitemData: () => {
      return itemData;
    }
  }));

  return (
    <div ref={focusRef}>
      <Segment
        style={style.segmentBlueStyle}
        onClick={e => {
          setIsShow(!isShow);
        }}
      >
        <span className={"ui"}>{itemData.date}</span>
        <span className={"ui"}>{itemData.encounter_type}</span>
        <Button
          icon
          color="red"
          type="button"
          onClick={() => {
            props.onDelete(item);
          }}
        >
          <Icon name="trash" />
        </Button>
      </Segment>
      {isShow && (
        <Segment style={style.segmentGrayStyle}>
          <Form key={itemData.item_id}>
            <Form.Group inline>
              <Form.Field width={4}>
                <Input label="แพทย์" readOnly={true} value={itemData.doctor} />
              </Form.Field>
              <Form.Field width={4}>
                <Input label="แผนก" readOnly={true} value={itemData.division} />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <ReactTable
                  style={{ height: "200px" }}
                  showPagination={false}
                  data={itemData.items}
                  columns={gridColumns}
                  manual={true}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      )}
    </div>
  );
});

SubIVSelected.defaultProps = {
  item: {},
  isReadOnly: false
};

SubIVSelected.propTypes = {
  item: PropTypes.object,
  isReadOnly: PropTypes.bool,
  onDelete: PropTypes.func
};

export default React.memo(SubIVSelected);
