import React from "react";
import CardEMROPDSummary from "./CardEMROPDSummary";
import CardDischargeSummaryView from "./CardDischargeSummaryView";
import CardLayout from "../common/CardLayout";
import { Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";

const CardEMRSummary = ({
  emrSummaryData,
  getEMRSummaryData,
  onPrintEMRSummary,
  isLoading
}) => {
  if (
    emrSummaryData &&
    emrSummaryData.status &&
    emrSummaryData.status === "NODISCHARGESUMMARY"
  ) {
    return (
      <CardLayout
        titleText="Discharge Summary "
        closeable={false}
        toggleable={false}
        hideHeaderIcon={true}
      >
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        Encounter นี้ไม่สามารถดูข้อมูล Discharge Summary ได้
      </CardLayout>
    );
  }

  return (
    <>
      {emrSummaryData && emrSummaryData.hasOwnProperty("dischargeSummary") ? (
        <CardDischargeSummaryView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}

          encounterType={"IPD"}
          dischargeSummaryData={emrSummaryData}
          dischargeSummaryLoading={isLoading}
        />
      ) : (
        <CardEMROPDSummary
          emrSummaryData={emrSummaryData}
          getEMRSummaryData={getEMRSummaryData}
          onPrintEMRSummary={onPrintEMRSummary}
          isLoading={isLoading}
          closeable={false}
          toggleable={false}
        />
      )}
    </>
  );
};

CardEMRSummary.defaultProps = {
  // OPD
  emrSummaryData: {},
  getEMRSummaryData: () => {},
  onPrintEMRSummary: () => {},

  // Common
  closeable: false,
  toggleable: false,
  hideHeaderIcon: true,
  isLoading: false
};

CardEMRSummary.propTypes = {
  // OPD
  emrSummaryData: PropTypes.object,
  getEMRSummaryData: PropTypes.func,
  onPrintEMRSummary: PropTypes.func,

  // Common
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default CardEMRSummary;
