import WasmController from "../../../../frameworks/WasmController";
// APIs
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import { HandleGetEmployeeTokenization } from "../../TPD/TPDInterface";
import OperatingDetailDetail from "issara-sdk/apis/OperatingDetailDetail_apps_ORM";
import OperatingDetailBasicInfo from "issara-sdk/apis/OperatingDetailBasicInfo_apps_ORM";
import NurseNoteTemplate from "issara-sdk/apis/NurseNoteTemplate_apps_ORM";
import UserList from "issara-sdk/apis/UserList_users";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import NurseImplantCreate from "issara-sdk/apis/NurseImplantCreate_apps_ORM";
import OperativeImageList from "issara-sdk/apis/OperativeImageList_apps_ORM";
import NurseImplantImageList from "issara-sdk/apis/NurseImplantImageList_apps_ORM";
import NurseImplantImageDetail from "issara-sdk/apis/NurseImplantImageDetail_apps_ORM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  masterOptions?: any;
  searchedItemListWithKey?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  PerioperativeNursingSequence?: Partial<{
    sequenceIndex?: string | null;
    nurseNoteTemplate: any;
    postOperativeData?: {
      data: {
        is_health_education?: boolean;
        no_health_education?: string | null;
        note?: string | null;
        operating_order_id?: number | null;
        other_transport_from_pacu_to?: string | null;
        signature?: string | null;
        signature_time?: string | null;
        transport_by?: string | null;
        transport_from_pacu_to?: string | null;
        employeeName?: string | null;
        loading?: boolean | null;
        error?: string | null;
      };
    };
    operatingDetail?: Partial<{
      special_equipments: [];
      detail: {};
    }>;
    basicInfoData?: {
      activeSurgeonTeam?: number;
      activeAnesthesiaTeam?: number;
      surgery_teams?: Partial<{
        assistant_surgeons?: any[];
        assistant_surgeon_items?: any[];
        chief_surgeon?: any;
        chief_surgeon_item?: any;
        circulating_nurses?: any[];
        circulating_nurses_items?: any[];
        scrub_nurses?: any[];
        scrub_nurses_items?: any[];
        type?: string;
      }>[];
      anesthesia_teams?: Partial<{
        anesthesiologist?: any;
        anesthesiologist_item?: any;
        anesthetists?: any[];
        anesthetist_items?: any[];
        id?: any;
        type: string;
      }>[];
      pre_diagnosis?: string;
      post_diagnosis?: string;
      pre_operation?: string;
    };
    surgicalPositionData?: {
      data: {
        goal?: string | null;
        nursing_diagnosis?: string | null;
        circulator_support?: string | null;
        device?: string | null;
        evaluation?: string | null;
        evaluation_check?: boolean;
        evaluation_detail?: string | null;
        plan_and_implementation?: string | null;
        position?: string | null;
        warmer?: string | null;
      };
    };
    laserData?: {
      data: {
        is_laser?: boolean | null;
        laser?: string | null;
      };
    };
    electricalData?: {
      data: {
        frequency?: string | null;
        ground_pad_site?: string | null;
        is_surgical_cautery?: boolean | null;
        is_us?: boolean | null;
        is_xray?: boolean | null;
        surgical_cautery?: string | null;
        xray?: string | null;
      };
    };
    tourniquetData?: any;
    defibrillatorData?: any;
    byEquipment?: {
      data?: {
        urinary?: any;
        retained_in_ward?: any;
        is_intermittent_cath?: any;
        change_in_or?: any;
        intermittent_cath?: any;
        insert_in_or?: any;
        foley_cath_size?: any;
        catheterize_solution?: any;
        operating_order_id?: any;
      };
    };

    implantData?: {
      detail?: any;
      image?: any;
      is_active?: any;
      id?: any;
      type?: any;
    };

    drainData?: {
      data?: {
        drain_items?: any;
        is_drain?: any;
        operating_order_id?: any;
      };
    };

    dressingData?: {
      data?: {
        dressing_items?: any;
        is_dressing?: any;
        operating_order_id?: any;
      };
    };

    surgicalSafetyChecklistSignin?: {
      data?: {
        airway_risk?: any;
        anesthesia_check?: any;
        blood?: any;
        know_allergy?: any;
        operating_order_id?: any;
        patient_identify?: any;
        pulse_oximeter?: any;
        risk?: any;
        site_marked?: any;
        special_instrument?: any;
      };
    };

    surgicalSafetyChecklistTimeOut?: {
      data?: {
        team_introduce?: any;
        patient_identify?: any;
        antibiotic?: any;
        essential_imaging?: any;
        critical_events?: any;
        has_sterility?: any;
        equipment_issue?: any;
        is_critical_step?: any;
        critical_step?: any;
        is_long?: any;
        long?: any;
        is_blood_loss?: any;
        blood_loss?: any;
        is_specific_concern?: any;
        specific_concern?: any;
        incision_date?: any;
        incision_time?: any;
        operating_order_id?: any;
      };
    };

    surgicalSafetyChecklistSigninOut?: {
      data?: {
        cardiovaacular?: any;
        completion?: any;
        drain?: any;
        equipment_problems?: any;
        estimate_blood_loss?: any;
        incision_closure?: any;
        is_blood_administered?: any;
        is_drain?: any;
        is_incision_closure?: any;
        is_skin_condition?: any;
        key_concerns?: any;
        metabolism?: any;
        name_procedure?: any;
        neuro_sign?: any;
        no_specimen?: any;
        operating_order_id?: any;
        other?: any;
        respiration?: any;
        skin_condition?: any;
        specimen_items?: any[];
        specimen_labelling?: any;
      };
    };
  }>;
};

export const StateInitial: State = {
  PerioperativeNursingSequence: {
    sequenceIndex: null,

    byEquipment: {
      data: {
        urinary: false,
        retained_in_ward: false,
        is_intermittent_cath: false,
        change_in_or: false,
        intermittent_cath: "",
        insert_in_or: "",
        foley_cath_size: "",
        catheterize_solution: "",
        operating_order_id: null,
      },
    },

    implantData: {
      detail: "",
      image: "",
      is_active: false,
      id: null,
      type: "",
    },

    drainData: {
      data: {
        drain_items: [],
        is_drain: false,
        operating_order_id: null,
      },
    },

    dressingData: {
      data: {
        dressing_items: [],
        is_dressing: false,
        operating_order_id: null,
      },
    },

    postOperativeData: {
      data: {
        is_health_education: true,
        no_health_education: "",
        note: null,
        operating_order_id: null,
        other_transport_from_pacu_to: "",
        signature: "",
        signature_time: "",
        transport_by: "",
        transport_from_pacu_to: "",
        employeeName: "",
        loading: false,
        error: null,
      },
    },
    laserData: {
      data: {
        is_laser: false,
        laser: "",
      },
    },
    electricalData: {
      data: {
        frequency: "",
        ground_pad_site: null,
        is_surgical_cautery: false,
        is_us: false,
        is_xray: false,
        surgical_cautery: null,
        xray: null,
      },
    },
    defibrillatorData: {
      data: {
        defibrillator: [
          {
            defibrillator: "",
            power: "",
            time: "",
          },
        ],
        defibrillator_pad_site: "",
        is_defibrillator: false,
      },
    },
    surgicalSafetyChecklistSignin: {
      data: {
        airway_risk: false,
        anesthesia_check: false,
        blood: false,
        know_allergy: false,
        operating_order_id: null,
        patient_identify: false,
        pulse_oximeter: false,
        risk: false,
        site_marked: false,
        special_instrument: false,
      },
    },

    surgicalSafetyChecklistTimeOut: {
      data: {
        team_introduce: false,
        patient_identify: false,
        antibiotic: false,
        essential_imaging: false,
        critical_events: false,
        has_sterility: false,
        equipment_issue: false,
        is_critical_step: false,
        critical_step: "",
        is_long: false,
        long: "",
        is_blood_loss: false,
        blood_loss: "",
        is_specific_concern: false,
        specific_concern: "",
        incision_date: "",
        incision_time: "",
        operating_order_id: null,
      },
    },

    surgicalSafetyChecklistSigninOut: {
      data: {
        cardiovaacular: false,
        completion: false,
        drain: "",
        equipment_problems: false,
        estimate_blood_loss: "",
        incision_closure: "",
        is_blood_administered: false,
        is_drain: false,
        is_incision_closure: false,
        is_skin_condition: false,
        key_concerns: false,
        metabolism: false,
        name_procedure: false,
        neuro_sign: false,
        no_specimen: false,
        operating_order_id: null,
        other: false,
        respiration: false,
        skin_condition: "",
        specimen_items: [],
        specimen_labelling: false,
      },
    },
  },
};

export const TeamInitial = {
  assistant_surgeons: [],
  chief_surgeon: null,
  circulating_nurses: [],
  id: null,
  is_main: false,
  scrub_nurses: [],
  type: "SUR",
};
export const AnesthesiaTeamInitial = {
  anesthesiologist: null,
  anesthesiologist_item: null,
  anesthetists: [],
  anesthetist_items: [null],
  id: null,
  type: "ANS",
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "HandleGetORPostOperativeTokenization"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["arriveStatus", {}],
        ["orPacuTo", {}],
        ["anesthesiaMethod", {}],
        ["orPlanAndImple", {}],
        ["orSurgicalCirculatory", {}],
        ["orSurgicalDevice", {}],
        ["orSurgicalPosition", {}],
        ["orSurgicalWarmer", {}],
        ["orTourniquetSide", {}],
        ["orGroundPadSite", {}],
        ["orSurgicalCautery", {}],
        ["specialEquipment", {}],
        ["orDefibrillatorSite", {}],
        ["orSurgicalLaser", {}],
        ["orVagina", {}],
        ["orMedication", {}],
        ["route", {}],
        ["orClinicalTerm", {}],
        ["orFoleyCathSize", {}],
        ["orDrain", {}],
        ["orDressing", {}],
        ["orIncisionClosure", {}],
        ["orDrainDetail", {}],
      ],
    },
  } as any);

  controller.setState(
    {
      PerioperativeNursingSequence: {
        sequenceIndex: "Action",
      },
    },
    () => {
      controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: params.action },
      });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  let state = controller.getState();

  if (params?.action === "FETCH_DATA_BASIC_INFO") {
    const [formResp, formErr, formNet] =
      await OperatingDetailBasicInfo.retrieve({
        apiToken: controller.apiToken,
        pk: state.selectedOrOrder?.operating_detail,
      });

    if (formErr) return;
    if (formResp) {
      const chiefSurgeons: number[] = formResp.surgery_teams.map(
        (item: any) => item.chief_surgeon
      );
      const assistSurgeons: number[] = formResp.surgery_teams?.flatMap(
        (item: any) => item.assistant_surgeons
      );

      const scrubNurses: number[] = formResp.surgery_teams?.flatMap(
        (item: any) => item.scrub_nurses
      );
      const circulatingNurses: number[] = formResp.surgery_teams?.flatMap(
        (item: any) => item.circulating_nurses
      );
      const anesthesiologists: number[] = formResp.anesthesia_teams?.flatMap(
        (item: any) => item.anesthesiologist
      );
      const anesthetists: number[] = formResp.anesthesia_teams?.flatMap(
        (item: any) => item.anesthetists
      );

      const uniqueSurgeons = Array.from(
        new Set([...chiefSurgeons, ...assistSurgeons, ...anesthesiologists])
      );
      const uniqueAnesthetists = Array.from(
        new Set([...scrubNurses, ...circulatingNurses, ...anesthetists])
      );

      const listWithKey = state.searchedItemListWithKey || {};

      removeListWithKey(listWithKey);

      if (!!chiefSurgeons.length) {
        const doctorRes = await Promise.all(
          uniqueSurgeons.map((id: number) =>
            DoctorDetail.retrieve({
              apiToken: controller.apiToken,
              pk: id,
            })
          )
        );

        const userRes = await Promise.all(
          uniqueAnesthetists.map((id: number) =>
            UserList.list({ apiToken: controller.apiToken, params: { pk: id } })
          )
        );

        const findDoctor = (id: number) => {
          return doctorRes.find((acc: any) => acc[0]?.id === id)?.[0];
        };

        const findUser = (id: number) => {
          return userRes.find((acc: any) => acc[0]?.items?.[0]?.id === id)?.[0]
            ?.items?.[0];
        };

        formResp.surgery_teams = formResp.surgery_teams.map(
          (item: any, index: number) => {
            const data = findDoctor(item.chief_surgeon);

            const assistSurgeons = item.assistant_surgeons?.length
              ? item.assistant_surgeons.map((id: number) => findDoctor(id))
              : [null];

            const scrubNurses = item.scrub_nurses?.length
              ? item.scrub_nurses.map((id: number) => findUser(id))
              : [null];

            const circulatingNurses = item.circulating_nurses?.length
              ? item.circulating_nurses.map((id: number) => findUser(id))
              : [null];

            for (const [i, assist] of assistSurgeons.entries()) {
              listWithKey[`Doctor_Assist_Surgeon_${index + 1}_${i + 1}`] =
                assist ? [assist] : null;
            }

            for (const [i, assist] of scrubNurses.entries()) {
              listWithKey[`Nurse_Scrub_Surgeon_${index + 1}_${i + 1}`] = assist
                ? [assist]
                : null;
            }

            for (const [i, assist] of circulatingNurses.entries()) {
              listWithKey[`Nurse_Circulating_Surgeon_${index + 1}_${i + 1}`] =
                assist ? [assist] : null;
            }

            listWithKey[`Doctor_Surgeon_${index + 1}`] = data ? [data] : null;

            return {
              ...item,
              chief_surgeon_item: data
                ? { ...data, name_code: `${data.full_name} (${data.code})` }
                : null,
              assistant_surgeon_items: assistSurgeons,
              scrub_nurses_items: scrubNurses,
              circulating_nurses_items: circulatingNurses,
            };
          }
        );

        formResp.anesthesia_teams = formResp.anesthesia_teams?.map(
          (item: any, index: number) => {
            const data = findDoctor(item.anesthesiologist);

            const anesthetists = item.anesthetists.length
              ? item.anesthetists.map((id: number) => findUser(id))
              : [null];

            for (const [i, anesthetist] of anesthetists.entries()) {
              listWithKey[`User_Anesthetist_${index + 1}_${i + 1}`] = [
                anesthetist,
              ];
            }

            listWithKey[`Doctor_Anesthesiologist_${index + 1}`] = data
              ? [data]
              : null;

            return {
              ...item,
              anesthesiologist_item: data
                ? { ...data, name_code: `${data.full_name} (${data.code})` }
                : null,
              anesthetist_items: anesthetists,
            };
          }
        );
      }

      state = controller.getState();

      controller.setState({
        searchedItemListWithKey: listWithKey,
        PerioperativeNursingSequence: {
          ...state.PerioperativeNursingSequence,
          basicInfoData: {
            ...formResp,
            activeSurgeonTeam:
              state.PerioperativeNursingSequence?.basicInfoData
                ?.activeSurgeonTeam || 0,
            activeAnesthesiaTeam:
              state.PerioperativeNursingSequence?.basicInfoData
                ?.activeAnesthesiaTeam || 0,
          },
        },
      });
    }
  } else if (params?.action === "FETCH_DATA_OTHER_FORM") {
    const [
      operating,
      nurseNote,
      essentialImaging,
      skinPreparation,
      irrigation,
      orMedication,
    ] = await Promise.all([
      GetOperatingDetail(controller, params),
      GetNurseNoteTemplate(controller, params),
      GetFormDataLatest(controller, {
        ...params,
        formCode: "CardEssentialImaging",
      }),
      GetFormDataLatest(controller, {
        ...params,
        formCode: "CardSkinPreparation",
      }),
      GetFormDataLatest(controller, { ...params, formCode: "CardIrrigation" }),
      GetFormDataLatest(controller, {
        ...params,
        formCode: "CardORMedication",
      }),
    ]);

    skinPreparation.data = {
      nursing_diagnosis: nurseNote.nursing_diagnosis,
      goal: nurseNote.nursing_diagnosis,
      evaluation: nurseNote.nursing_diagnosis,
      plan_and_implementation: "",
      evaluation_check: false,
      evaluation_detail: "",
      ...skinPreparation.data,
    };

    orMedication.data = {
      med_items: [{ code: "", medicine_name: "", rount: "", time: "" }],
      other_med_items: [{ code: "", medicine_name: "", rount: "", time: "" }],
      ...orMedication.data,
    };

    controller.setState({
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        operatingDetail: operating,
        nurseNoteTemplate: nurseNote,
        [params.essentialImagingKey]: essentialImaging,
        [params.skinPreparationKey]: skinPreparation,
        [params.irrigationKey]: irrigation,
        [params.orMedicationKey]: orMedication,
      },
    });
  } else if (params.action === "add_surgery_team") {
    const teams =
      state.PerioperativeNursingSequence?.basicInfoData?.surgery_teams || [];

    teams.push(TeamInitial);

    controller.setState({
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        basicInfoData: {
          ...state.PerioperativeNursingSequence?.basicInfoData,
          surgery_teams: teams,
        },
      },
    });
  } else if (params.action === "add_anesthesia_team") {
    const teams =
      state.PerioperativeNursingSequence?.basicInfoData?.anesthesia_teams || [];
    teams.push(AnesthesiaTeamInitial);

    controller.setState({
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        basicInfoData: {
          ...state.PerioperativeNursingSequence?.basicInfoData,
          anesthesia_teams: teams,
        },
      },
    });
  } else if (params.action === "selected_surgeon_team") {
    const state = controller.getState();

    const [anesthesiaResp, anesthesiaErr, anesthesiaNet] = await DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state.PerioperativeNursingSequence?.basicInfoData?.surgery_teams?.[params.index]
        ?.chief_surgeon,
    });

    if (anesthesiaResp) {
      let newSurgeryTeams: any[] = [];

      (state.PerioperativeNursingSequence?.basicInfoData?.surgery_teams || [])?.map(
        (item: any, index: number) => {
          if (index === params.index) {
            newSurgeryTeams.push({
              ...item,
              chief_surgeon_item: anesthesiaResp,
            });
          } else {
            newSurgeryTeams.push(item);
          }
        }
      );

      controller.setState({
        PerioperativeNursingSequence: {
          ...state.PerioperativeNursingSequence,
          basicInfoData: {
            ...state.PerioperativeNursingSequence?.basicInfoData,
            surgery_teams: newSurgeryTeams,
          },
        },
      });
    }
  } else if (params?.action === "SAVE_BASIC_INFO") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const detail = { ...state.PerioperativeNursingSequence?.basicInfoData };

    const surgeryTeams: any[] = (detail?.surgery_teams || [])?.map(
      (item: any) => {
        const assistSurgeonIds = item.assistant_surgeon_items
          ?.map((item: any) => item?.id)
          .filter(Boolean);
        const scrubNursesIds = item.scrub_nurses_items
          ?.map((item: any) => item?.id)
          .filter(Boolean);
        const circulatingNursesIds = item.circulating_nurses_items
          ?.map((item: any) => item?.id)
          .filter(Boolean);

        return {
          ...item,
          assistant_surgeons: assistSurgeonIds,
          scrub_nurses: scrubNursesIds,
          circulating_nurses: circulatingNursesIds,
        };
      }
    );

    const anesthetistTeams: any[] = (detail?.anesthesia_teams || [])?.map(
      (item: any) => {
        const anesthetistIds = item.anesthetist_items
          ?.map((item: any) => item?.id)
          .filter(Boolean);

        return {
          anesthesiologist: item.anesthesiologist,
          id: item.id,
          type: "ANS",
          anesthetists: anesthetistIds,
        };
      }
    );

    const [formResp, formErr, formNet] = await OperatingDetailBasicInfo.update({
      pk: state.selectedOrOrder?.operating_detail,
      data: {
        ...state.PerioperativeNursingSequence?.basicInfoData,
        anesthesia_teams: anesthetistTeams,
        surgery_teams: surgeryTeams,
      },
      apiToken: controller.apiToken,
    });

    if (formErr) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: formErr },
        },
      });
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: formResp },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: null },
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
      });
    }

    Action(controller, { action: "FETCH_DATA_BASIC_INFO" });
  } else if (params?.action === "FETCH_FORM_DATA_LATEST") {
    const data = await GetFormDataLatest(controller, params);

    let result: any = {
      ...state.PerioperativeNursingSequence,
      [params.byKey || params.cardKey]: data,
    };

    controller.setState({ PerioperativeNursingSequence: result });
  } else if (params?.action === "SET_DATA") {
    const detail =
      (state.PerioperativeNursingSequence as any)[params.cardKey] || {};

    let data = {
      ...(detail?.data || {}),
      [params.key]: params.value,
    };

    controller.setState({
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        [params.cardKey]: { ...detail, data: data },
      },
    });
  } else if (params?.action === "SAVE") {
    const detail =
      (state.PerioperativeNursingSequence as any)?.[
      params.byKey ? params.byKey : params.cardKey
      ] || {};

    const btnLoadKey = `${params.cardKey}_${params.action}`;

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "LOADING" },
    });

    if (params.cardKey === "postOperativeData") {
      if (
        detail?.data?.hasOwnProperty("employeeName") &&
        detail?.data?.employeeName !== ""
      ) {
      } else {
        return controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "ERROR" },
          errorMessage: { ...state.errorMessage, [params.cardKey]: "กรุณาระบุ employeeName" }
        });
      }
    } else if (["surgicalSafetyChecklistTimeOut", "surgicalSafetyChecklistSigninOut"].includes(params.cardKey)) {
      const data = ({
        surgicalSafetyChecklistTimeOut: {
          incision_date: detail.data?.incision_date,
          incision_time: detail.data?.incision_time,
          ...((detail.data?.incision_date && detail.data?.incision_time) && {
            incision_datetime: `${detail.data?.incision_date}-${detail.data?.incision_time}`,
          })
        },
        surgicalSafetyChecklistSigninOut: {
          closure_date: params?.closure_date,
          closure_time: params?.closure_time,
          remark: params?.remark,
          ...((params?.closure_date && params?.closure_time) && {
            closure_datetime: `${params?.closure_date}-${params?.closure_time}`,
          })
        }
      } as any)[params.cardKey];

      const [formResp, formErr] = await OperatingDetailDetail.patch({
        apiToken: controller.apiToken,
        pk: state.selectedOrOrder?.operating_detail,
        data,
      });

      if (formErr) {
        return controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "ERROR" },
          errorMessage: { ...state.errorMessage, [params.cardKey]: formErr }
        });
      }
    }

    let saveResp = null;
    let saveErr = null;

    if (detail?.id) {
      let data: any = {
        ...detail,
        action: params.action,
        doctor_order: state.selectedOrOrder?.id,
        closure_date: params?.closureDate,
        closure_time: params?.closureTime,
        remark: params?.remark
      };

      if (!data?.emr) {
        delete data.emr;
      }
      if (!data?.doctor) {
        delete data.doctor;
      }

      [saveResp, saveErr] = await FormDataDetail.update({
        apiToken: controller.apiToken,
        pk: detail?.id,
        data: data,
        extra: {
          division: controller.data.division,
        },
      });
    } else {
      let data: any = {
        ...detail.data,
        operating_order_id: state.selectedOrOrder?.id,
      };

      if (params.cardKey === "tourniquetData") {
        data = state.PerioperativeNursingSequence?.tourniquetData?.data;
      }

      [saveResp, saveErr] = await FormDataList.create({
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
        data: {
          action: params.action,
          encounter: state.selectedOrOrder?.encounter,
          doctor_order: state.selectedOrOrder?.id,
          form_code: params.formCode,
          form_name: params.formName,
          form_version: params.formVersion,
          data: data,
        },
      });
    }

    if (saveResp) {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "SUCCESS" },
      });

      if (params.cardKey === "surgicalPositionData") {
        Action(controller, { ...params, action: "FETCH_FORM_DATA_STEP_AH" });
      } else {
        Action(controller, { ...params, action: "FETCH_FORM_DATA_LATEST" });
      }
    } else {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "ERROR" },
        errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr }
      });
    }
  } else if (["CONFIRM", "UNCONFIRM"].includes(params?.action)) {
    const detail =
      (state.PerioperativeNursingSequence as any)?.[
      params.byKey ? params.byKey : params.cardKey
      ] || {};
    const btnLoadKey = `${params.cardKey}_${params.action}`;

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "LOADING" },
    });

    if (params.cardKey === "postOperativeData") {
      if (
        detail?.data?.hasOwnProperty("employeeName") &&
        detail?.data?.employeeName !== ""
      ) {
      } else {
        return controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "ERROR" },
          errorMessage: { ...state.errorMessage, [params.cardKey]: "กรถณาระบุ employeeName" }
        });
      }
    } else if (["surgicalSafetyChecklistTimeOut", "surgicalSafetyChecklistSigninOut"].includes(params.cardKey)) {
      const data = ({
        surgicalSafetyChecklistTimeOut: {
          incision_date: detail.data?.incision_date,
          incision_time: detail.data?.incision_time,
          ...((detail.data?.incision_date && detail.data?.incision_time) && {
            incision_datetime: `${detail.data?.incision_date}-${detail.data?.incision_time}`,
          })
        },
        surgicalSafetyChecklistSigninOut: {
          closure_date: params?.closure_date,
          closure_time: params?.closure_time,
          remark: params?.remark,
          ...((params?.closure_date && params?.closure_time) && {
            closure_datetime: `${params?.closure_date}-${params?.closure_time}`,
          })
        }
      } as any)[params.cardKey];

      const [formResp, formErr] = await OperatingDetailDetail.patch({
        apiToken: controller.apiToken,
        pk: state.selectedOrOrder?.operating_detail,
        data,
      });

      if (formErr) {
        return controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "ERROR" },
          errorMessage: { ...state.errorMessage, [params.cardKey]: formErr }
        });
      }
    }

    if (detail.id) {
      let data: any = { ...detail, action: params.action };

      if (!data?.emr) {
        delete data.emr;
      }

      if (!data?.doctor) {
        delete data.doctor;
      }

      const [confirmResp, confirmErr] = await FormDataDetail.update(
        {
          apiToken: controller.apiToken,
          pk: detail.id,
          data: {
            ...data,
            doctor_order: state.selectedOrOrder?.id,
          },
          extra: {
            division: controller.data.division,
          },
        }
      );

      if (confirmErr) {
        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "ERROR" },
          errorMessage: { ...state.errorMessage, [params.cardKey]: confirmErr }
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [btnLoadKey]: "SUCCESS",
          },
        });

        if (params.cardKey === "surgicalPositionData") {
          Action(controller, { ...params, action: "FETCH_FORM_DATA_STEP_AH" });
        } else {
          Action(controller, { ...params, action: "FETCH_FORM_DATA_LATEST" });
        }
      }
    } else {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "ERROR" },
      });
    }
  } else if (params.action === "SAVE_SPECIAL_EQUIPMENT") {
    HandleSaveSpecialEquipment(controller, params);
  } else if (params.action === "TOKENIZE") {
    const detail = state.PerioperativeNursingSequence || {};
    const data = (detail as any)?.[params.cardKey]?.data || {};

    data[params.type][params.index].loading = true;

    controller.setState({
      PerioperativeNursingSequence: {
        ...detail,
        [params.cardKey]: { ...(detail as any)?.[params.cardKey], data },
      },
    });

    const tokenize = await HandleGetEmployeeTokenization(controller as any, {
      code: params.value,
    });

    data[params.type][params.index].loading = false;

    if (tokenize[1]) {
      data[params.type][params.index].error = true;
      data[params.type][params.index].code = "";
    } else {
      data[params.type][params.index].error = false;
      data[params.type][params.index].code = tokenize[0]?.token;
    }

    return controller.setState({
      PerioperativeNursingSequence: {
        ...detail,
        [params.cardKey]: { ...(detail as any)?.[params.cardKey], data },
      },
    });
  } else if (params?.action === "FETCH_FORM_DATA_STEP_AH") {
    const formCode = [
      "CardSurgicalPosition",
      "CardTourniquet",
      "CardElectricalXray",
      "CardDefibrillator",
      "CardLaser",
    ];
    const formVersion = "1.0";
    const promiseArr = formCode?.map((item: any) => {
      return FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: item,
          form_version: formVersion,
          operating_order_id: state.selectedOrOrder?.id,
        },
      });
    });

    const formData = await Promise.all(promiseArr);

    if (formData?.[0]?.[0] === null) {
      var [response, error] = await NurseNoteTemplate.get({
        apiToken: controller.apiToken,
        params: {
          form_code: "CardSurgicalPosition",
          nurse_note_temp_code: "nurse_note_sp_pd",
          operating_order_id: state.selectedOrOrder?.id,
        },
      });
    }

    controller.setState({
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        surgicalPositionData:
          formData?.[0]?.[0] === null
            ? {
              data: {
                ...response,
                circulator_support: "",
                evaluation_detail: "",
                device: "",
                evaluation_check: true,
                plan_and_implementation: "",
                position: "",
                warmer: "",
              },
            }
            : formData?.[0]?.[0],
        laserData:
          formData?.[4]?.[0] ||
          StateInitial.PerioperativeNursingSequence?.laserData,
        electricalData:
          formData?.[2]?.[0] ||
          StateInitial.PerioperativeNursingSequence?.electricalData,
        tourniquetData: formData?.[1]?.[0] || {
          data: [
            {
              operating_order_id: state.selectedOrOrder?.id,
              pressure: "",
              round: 1,
              site: "",
              time_off: "",
              time_on: "",
            },
          ],
        },
        defibrillatorData:
          formData?.[3]?.[0] ||
          StateInitial.PerioperativeNursingSequence?.defibrillatorData,
      },
    });
  } else if (params.action === "put_nurse_implant") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`implantData_save`]: "LOADING",
      },
    });

    const [response, error] = await NurseImplantCreate.put({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder?.operating_detail,
      data: {
        ...state.PerioperativeNursingSequence?.implantData,
        nurse_implant: state.PerioperativeNursingSequence?.implantData?.id,
      },
    });

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`implantData_save`]: "SUCCESS",
      },
    });
  } else if (params?.action === "get_equipment") {
    const formCode = ["CardTube", "CardORDrain", "CardORDressing"];

    const promiseForm = formCode.map((code) =>
      FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: code,
          form_version: "0.1",
          operating_order_id: state.selectedOrOrder?.id,
        },
      })
    );

    const [
      formTubeResp,
      formDrainResp,
      formDressingResp,
      response,
      responseImage,
    ] = await Promise.all(
      promiseForm.concat([
        NurseImplantCreate.get({
          apiToken: controller.apiToken,
          pk: state.selectedOrOrder?.operating_detail,
        }),
        NurseImplantImageList.list({
          apiToken: controller.apiToken,
          pk: state.selectedOrOrder?.operating_detail,
        }),
      ])
    );

    controller.setState({
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        byEquipment: formTubeResp[0] || {},
        drainData: formDrainResp[0] || {},
        dressingData: formDressingResp[0] || {},
        implantData: {
          id: response[0]?.id,
          type: response[0]?.type,
          is_active: response[0]?.type !== null ? true : false,
          image: responseImage[0]?.items || [],
        },
      },
    });
  } else if (params.action === "get_uploadImg") {
    const [responseImage, errorImage] = await NurseImplantImageList.list({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder?.operating_detail,
    });

    controller.setState({
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        implantData: {
          ...state.PerioperativeNursingSequence?.implantData,
          image: responseImage.items,
        },
      },
    });
  } else if (params.action === "save_uploadImg") {
    const imgCreate = await NurseImplantImageList.create({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder?.operating_detail,
      data: {
        nurse_implant: state.PerioperativeNursingSequence?.implantData?.id,
        image: params.image,
        is_active: true,
        detail: params.detail,
      },
    });

    Action(controller, { ...params, action: "get_uploadImg" });
  } else if (params.action === "update_uploadImg") {
    const imgUpdate = await NurseImplantImageDetail.update({
      pk: params.id,
      apiToken: controller.apiToken,
      data: {
        nurse_implant: state.PerioperativeNursingSequence?.implantData?.id,
        image: params.image,
        is_active: params.is_active,
        detail: params.detail,
      },
    });

    Action(controller, { ...params, action: "get_uploadImg" });
  } else if (params?.action === "FETCH_FORM_DATA_SURGICAL_SAFETY") {
    const formCode = [
      "CardSurgicalSafetyChecklistSignIn",
      "CardSurgicalSafetyChecklistTimeOut",
      "CardAfterClosureAndWoundCare",
    ];

    const promiseForm = formCode.map((code) =>
      FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: code,
          form_version: "0.1",
          operating_order_id: state.selectedOrOrder?.id,
        },
      })
    );

    const [formSignInResp, formTimeOutResp, formSignOutResp, formResp] =
      await Promise.all(
        promiseForm.concat([
          OperatingDetailDetail.retrieve({
            apiToken: controller.apiToken,
            pk: state.selectedOrOrder?.operating_detail,
          }),
        ])
      );

    state = controller.getState();

    controller.setState({
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        surgicalSafetyChecklistSignin: formSignInResp[0] || {},
        surgicalSafetyChecklistTimeOut: formTimeOutResp[0] || {},
        surgicalSafetyChecklistSigninOut: formSignOutResp[0] || {
          data: {
            specimen_items: [
              {
                tissue: "",
                other_specimen: "",
                culture: "",
                is_tissue: false,
                is_other_specimen: false,
                is_frozen: false,
                is_estrogen_rec: false,
                is_cyto_section: false,
                is_culture: false,
              },
            ],
          },
        },
        operatingDetail: formResp[0],
      },
    });
  }
};

/* ------------------------------------------------------ */

/*                         GET API                        */

/* ------------------------------------------------------ */
const GetFormDataLatest: Handler = async (controller, params) => {
  const state = controller.getState();
  const key = params.byKey || params.cardKey;

  const [formResp, formErr, formNet] = await FormDataLatest.retrieve({
    apiToken: controller.apiToken,
    params: {
      encounter: state.selectedOrOrder?.encounter,
      form_code: params.formCode,
      form_version: params.formVersion,
      operating_order_id: state.selectedOrOrder?.id,
    },
  });

  let result: any = {
    [key]: { data: {} },
  };

  if (!formErr) {
    result[key] = formResp;
  }

  return result[key];
};

const GetOperatingDetail: Handler = async (controller, params) => {
  const state = controller.getState();

  const [formResp, formErr, formNet] = await OperatingDetailDetail.retrieve({
    apiToken: controller.apiToken,
    pk: state.selectedOrOrder?.operating_detail,
  });

  if (formErr) return {};

  return formResp;
};

const GetNurseNoteTemplate: Handler = async (controller, params) => {
  const state = controller.getState();

  const [response, error] = await NurseNoteTemplate.get({
    apiToken: controller.apiToken,
    params: {
      form_code: params.form_code,
      nurse_note_temp_code: params.nurse_note_temp_code,
      operating_order_id: state.selectedOrOrder?.id,
    },
  });

  return response;
};

/* ------------------------------------------------------ */

/*                      HandleAction                      */

/* ------------------------------------------------------ */
const HandleSaveSpecialEquipment: Handler = async (controller, params) => {
  const state = controller.getState();
  const btnLoadKey = `${params.cardKey}`;
  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "LOADING" },
  });

  const [saveResp, saveErr] = await OperatingDetailDetail.patch({
    apiToken: controller.apiToken,
    pk: state.selectedOrOrder?.operating_detail,
    data: {
      special_equipments:
        state.PerioperativeNursingSequence?.operatingDetail
          ?.special_equipments || [],
      detail: state.PerioperativeNursingSequence?.operatingDetail
          ?.detail
    } as any,
  });

  if (saveErr) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "ERROR" },
    });
  } else {
    const operating = await GetOperatingDetail(controller, params);

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnLoadKey]: "SUCCESS" },
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        operatingDetail: operating,
      },
    });
  }
};

export const HandleGetORPostOperativeTokenization: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  var detail =
    (state.PerioperativeNursingSequence as any)[params.cardKey] || {};
  controller.setState({
    PerioperativeNursingSequence: {
      ...state.PerioperativeNursingSequence,
      [params.cardKey]: {
        ...detail,
        data: {
          ...detail?.data,
          loading: true,
        },
      },
    },
  });

  const [response, error, network] = await HandleGetEmployeeTokenization(
    controller as any,
    {
      code: params.code,
    }
  );

  if (error) {
    controller.setState({
      PerioperativeNursingSequence: {
        ...state.PerioperativeNursingSequence,
        [params.cardKey]: {
          ...detail,
          data: {
            ...detail?.data,
            loading: false,
            error: error,
          },
        },
      },
    });
    return;
  }
  controller.setState({
    PerioperativeNursingSequence: {
      ...state.PerioperativeNursingSequence,
      [params.cardKey]: {
        ...detail,
        data: {
          ...detail?.data,
          signature: response?.token || "",
          employeeName:
            decodeURIComponent(atob(response?.token.split(".")[1])) || "",
          loading: false,
        },
      },
    },
  });
};

const removeListWithKey = (listWithKey: any) => {
  const removeArrayCIndex = (key: string) => {
    var index = 1;
    var cIndex = 1;

    while (listWithKey[`${key}_${index}_${cIndex}`]) {
      delete listWithKey[`${key}_${index}_${cIndex}`];
      cIndex += 1;
      while (listWithKey[`${key}_${index}_${cIndex}`]) {
        delete listWithKey[`${key}_${index}_${cIndex}`];
        cIndex += 1;
      }
      cIndex = 1;
      index += 1;
    }
  };

  const removeArrayIndex = (key: string) => {
    var index = 1;

    while (listWithKey[`${key}_${index}`]) {
      delete listWithKey[`${key}_${index}`];
      index += 1;
    }
  };

  removeArrayCIndex("Doctor_Assist_Surgeon");
  removeArrayCIndex("Nurse_Scrub_Surgeon");
  removeArrayCIndex("Nurse_Circulating_Surgeon_");
  removeArrayIndex("Doctor_Surgeon");
  removeArrayCIndex("User_Anesthetist");
  removeArrayIndex("Doctor_Anesthesiologist");
};
