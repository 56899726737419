import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Form, Grid, Icon, Input, Radio } from "semantic-ui-react";

const styles = {
  noPadding: {
    padding: "0px",
  },
  noBottomPadding: {
    paddingBottom: "5px",
  },
};

interface SubPhysicalExamRangeOfMotionProps {
  index: number;
  data: { limit: string };
  disable: boolean;
  onAddComponent: (any) => any;
  onRemoveComponent: (any) => any;
  readonly: boolean;
}

const SubPhysicalExamRangeOfMotion: FC<SubPhysicalExamRangeOfMotionProps> = forwardRef(
  (props, ref) => {
    const [item, setItem] = useState<any>(defaultItemData);

    useEffect(() => {
      setItem(props.data);
    }, [props.data]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        return item;
      },
    }));

    const handleValue = (event, data) => {
      if (!props.readonly) {
        setItem({ ...item, [data.name]: data.value });
      }
    };

    const handleRemove = () => {
      props.onRemoveComponent(props.index);
    };

    return (
      <Form>
        <Form.Group inline>
          <Form.Field width={14}>
            <Input
              disabled={props.disable}
              readOnly={props.readonly}
              value={item.limit}
              name="limit"
              onChange={handleValue}
            />
          </Form.Field>
          <Form.Field width={1}>
            <Button
              icon
              color="green"
              type="button"
              size="small"
              disabled={props.disable || props.readonly}
              onClick={props.onAddComponent}
            >
              <Icon name="plus" />
            </Button>
          </Form.Field>
          <Form.Field width={1}>
            <Button
              icon
              color="red"
              type="button"
              size="small"
              readonly={props.readonly}
              disabled={props.disable || props.readonly}
              onClick={handleRemove}
            >
              <Icon name="delete" />
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
);

/* ----- CardRangeOfMotion ----- */
export interface CardRangeOfMotionViewDataType {
  data: {
    isFull: boolean;
    items: [{ limit: string }];
  };
  readonly: boolean
}

const CardRangeOfMotion: FC<CardRangeOfMotionViewDataType> = forwardRef(
  (props, ref) => {
    const subRangeOfMotionRef = useRef([]);
    const [rangeOfMotion, setRangeOfMotion] = useState<any>(defaultData);

    useEffect(() => {
      let rItems: any = { ...props.data };
      if (rItems.items.length === 0) {
        rItems.items.push(defaultItemData);
      }
      setRangeOfMotion(rItems);
    }, [props.data]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        let rItems: any = [];
        subRangeOfMotionRef.current.forEach((item) => {
          try {
            rItems.push(item.getData());
          } catch (err) {}
        });
        const rangeOfMotionResult = { ...rangeOfMotion, items: rItems };

        return rangeOfMotionResult;
      },
    }));

    const handleAddRangeOfMotionComponent = () => {
      if (!props.readonly) {
        let newItems: any = [...rangeOfMotion.items];
        newItems.push(defaultItemData);
        setRangeOfMotion({ ...rangeOfMotion, items: newItems });
      }
    };

    const handleRemoveRangeOfMotionComponent = (index) => {
      if (!props.readonly) {
        let newItems: any = [];
        subRangeOfMotionRef.current.forEach((item, i) => {
          if (i !== index) {
            try {
              newItems.push(item.getData());
            } catch (err) {}
          }
        });
        if (newItems.length === 0) {
          newItems.push(defaultItemData);
        }
        setRangeOfMotion({ ...rangeOfMotion, items: newItems });
      }
    };

    const handleChangeRangeOfMotion = (event, data) => {
      if (!props.readonly) {
        setRangeOfMotion({
          ...rangeOfMotion,
          [data.name]: data.value === "isFull",
        });
      }
    };

    return (
      <Grid>
        <Grid.Row columns={2} style={styles.noBottomPadding}>
          <Grid.Column width={3}>
            <Form>
              <Form.Group inline>
                <Form.Field width={9}>
                  <Radio
                    label="Full"
                    name="isFull"
                    value="isFull"
                    readOnly={props.readonly}
                    checked={rangeOfMotion.isFull}
                    onChange={handleChangeRangeOfMotion}
                  />
                </Form.Field>
                <Form.Field width={7}>
                  <Radio
                    label="Limit"
                    name="isFull"
                    value="isLimit"
                    readOnly={props.readonly}
                    checked={!rangeOfMotion.isFull}
                    onChange={handleChangeRangeOfMotion}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={13}>
            <SubPhysicalExamRangeOfMotion
              ref={(el) => (subRangeOfMotionRef.current[0] = el)}
              key="examRangeOfMotion0"
              index={0}
              data={rangeOfMotion.items ? rangeOfMotion.items[0] : {}}
              disable={rangeOfMotion.isFull}
              onAddComponent={handleAddRangeOfMotionComponent}
              onRemoveComponent={handleRemoveRangeOfMotionComponent}
              readonly={props.readonly}
            />
          </Grid.Column>
        </Grid.Row>
        {rangeOfMotion.items && (
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={3}></Grid.Column>
            <Grid.Column width={13}>
              {rangeOfMotion.items.map((item, index) => {
                if (index !== 0) {
                  return (
                    <SubPhysicalExamRangeOfMotion
                      ref={(el) => (subRangeOfMotionRef.current[index] = el)}
                      key={`examRangeOfMotion${index}`}
                      index={index}
                      data={item}
                      disable={rangeOfMotion.isFull}
                      onAddComponent={handleAddRangeOfMotionComponent}
                      onRemoveComponent={handleRemoveRangeOfMotionComponent}
                      readonly={props.readonly}
                    />
                  );
                }
              })}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
);

const defaultItemData = {
  limit: "",
};

const defaultData = {
  isFull: true,
  items: [defaultItemData],
};

export const CardRangeOfMotionInitialViewData: CardRangeOfMotionViewDataType = {
  data: defaultData,
  readonly: false,
};

CardRangeOfMotion.defaultProps = CardRangeOfMotionInitialViewData;

export default CardRangeOfMotion;
