import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import CONFIG from "config/config";

const FORM_NAME = "FormWaitingList";

const STATUS: Record<string, string> = {
  PENDING: "รอนัดหมาย",
  STALL: "พักคิว",
  APPOINTMENT: "นัดหมายแล้ว",
  CANCEL: "ถูกยกเลิกจากผู้ป่วย",
  ALL: "ทุกสถานะนัดหมาย",
};

const STATUS_CODE: Record<string, string> = {
  PENDING: "รอนัดหมาย",
  STALL: "พักคิว",
  APPOINTMENT: "นัดหมายแล้ว",
  CANCEL: "ถูกยกเลิกจากผู้ป่วย",
  "": "ทุกสถานะนัดหมาย",
};

const FormWaitingList = async (props: any) => {
  console.log("FormWaitingList: ", props.data);

  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    height: 40.4,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  let statusText =
    props.data?.status_code?.length === 0
      ? STATUS_CODE[""]
      : props.data?.status_code?.map((i: any) => STATUS_CODE[i]).join(", ");

  return {
    pageSize: "A4",
    pageOrientation: 'landscape',
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 14,
    },
    pageMargins: [20, 115, 20, 20],
    styles: {
      facultyHeader: {
        fontSize: 19,
        bold: true,
      },
      facultyName: {
        fontSize: 7,
        bold: true,
      },
      facultyDetail: {
        fontSize: 11,
        bold: true,
      },
      facultyValue: {
        fontSize: 11,
      },
      divisionHeaderBold: {
        fontSize: 16,
        bold: true,
      },
      divisionHeader: {
        fontSize: 16,
      },
      headerTitle: {
        fontSize: 16,
      },
      divisionSubHeader: {
        fontSize: 14,
        bold: true,
      },
      divisionSubValue: {
        fontSize: 14,
      },
      tableHeader: {
        fontSize: 10,
        alignment: "center",
        bold: true,
      },
      fieldValue: {
        fontSize: 9,
        alignment: "center",
      },
    },

    header: (currentPage: any, pageCount: any, pageSize: any) => {
      return {
        margin: [20, 10, 20, 10],
        stack: [
          {
            columns: [
              !CONFIG.HIDE_COMPANY_LOGO_PRINT
                ? {
                    width: "30%",
                    stack: [
                      { margin: [0, 0, 0, 0], width: 120, image: "logo", alignment: "left" },
                      {
                        text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                        style: "facultyName",
                      },
                      {
                        text: "Chulalongkorn University Faculty of Dentistry Hospital",
                        style: "facultyName",
                      },
                    ],
                  }
                : null,

              {
                width: "40%",
                stack: [
                  {
                    text: props.data?.division_name || "",
                    style: "divisionHeaderBold",
                    alignment: "center",
                  },
                  {
                    text: [
                      { text: "วันที่เข้าคิว ", style: "divisionHeader" },
                      {
                        text: props.data?.starDate ? props.data?.starDate : "ไม่ระบุ",
                        style: "divisionHeader",
                      },
                      { text: " ถึง ", style: "divisionHeader" },
                      {
                        text: props.data?.endDate ? props.data?.endDate : "ไม่ระบุ",
                        style: "divisionHeader",
                      },
                    ],
                    alignment: "center",
                  },
                  {
                    text: [
                      { text: "รายชื่อผู้ป่วยกลุ่มงาน: ", style: "divisionHeader" },
                      {
                        text: `${props.data?.waiting_list_name || ""}`,
                        style: "divisionHeaderBold",
                      },
                    ],
                    alignment: "center",
                  },
                  { text: `(${statusText})`, style: "divisionHeaderBold", alignment: "center" },
                ],
              },

              // { stack: [{ text: props.data?.provider_name || "", style: "divisionHeader" }] },
              // {
              //   margin: [-15, 10, 0, 0],
              //   width: "*",
              //   stack: [
              //     !CONFIG.HIDE_COMPANY_LOGO_PRINT ? {
              //       text: "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY",
              //       style: "facultyHeader",
              //       alignment: "center",
              //     } : null ,
              //     {
              //       text: [
              //         { text: props.data?.division_name || "", style: "divisionHeader", alignment: "center" },
              //         { text: ` กลุ่มงานคิว : `, style: "divisionHeader", alignment: "center" },
              //         { text: `${props.data?.waiting_list_name || ""} (${STATUS[props.data?.status_name]})`, style: "divisionHeader", alignment: "center" },
              //       ],
              //     },
              //     // {
              //     //   alignment: "center",
              //     //   text: [
              //     //     { text: "ทันตแพทย์/นิสิต : ", style: "divisionHeader" },
              //     //     { text: props.data?.provider_name || "", style: "divisionHeader" },
              //     //   ],
              //     // },
              //     { alignment: "center", text: `รายชื่อผู้ป่วยกลุ่มงานคิว${props.data?.waiting_list_name || ""}`, style: "divisionHeader" },
              //   ],
              // },

              {
                width: "30%",
                margin: [20,0,0,0],
                stack: [
                  { text: " "} ,
                  {
                    text: [
                      { text: `ผู้พิมพ์: `, style: "facultyDetail" },
                      { text: props.data.userPrint, style: "facultyValue" },
                    ],
                    alignment: "left",
                  },
                  {
                    text: [
                      { text: `วันที่พิมพ์: `, style: "facultyDetail" },
                      { text: props.data.datePrint, style: "facultyValue" },
                    ],
                    alignment: "left",
                  },

                  {
                    text: `หน้าที่ ${currentPage.toString()} / ${pageCount}`,
                    style: "facultyValue",
                    alignment: "right",
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: ["4%", "5%", "8%", "6%", "15%", "9%", "15%", "15%", "12%", "11%"],
          body: [
            [
              { text: "ลำดับ", style: "tableHeader", alignment: "center" },
              { text: "เลขที่คิว", style: "tableHeader", alignment: "center" },
              { text: "วันที่เข้าคิว", style: "tableHeader", alignment: "center" },
              { text: "HN", style: "tableHeader" },
              { text: "ชื่อ-สกุล", style: "tableHeader" },
              { text: "เบอร์โทรติดต่อ", style: "tableHeader" },
              { text: "แพทย์/นิสิต", style: "tableHeader" },
              { text: "ประเภทการรักษา", style: "tableHeader" },
              { text: "สถานะ", style: "tableHeader" },
              { text: "รายละเอียด", style: "tableHeader" },
            ],
          ].concat(
            (props.data?.orders || [])?.map((row: any, index: number) => { 
              
              let isStall = row.status === "STALL"
              let isCancel = row.status === "CANCEL"
              let showReason = isStall || isCancel
              let statusName = row.status_name
              if (showReason) {
                statusName = `${row.status_name} (${row.reason})`
              }
            
              return [
                { text: (index + 1).toString(), style: "fieldValue", alignment: "center" },
                { text: row.code ? row.code : "", style: "fieldValue" },
                { text: row.createdFormat ? row.createdFormat : "", style: "fieldValue" },
                { text: row.patient_hn, style: "fieldValue" },
                {
                  text: row.patient_name ? row.patient_name.replace(/ \(.*\)$/g, "") : "",
                  style: "fieldValue",
                },
                {
                  text: row.patient_tel ? row.patient_tel.replace(/(^\d{3})/g, "$1-") : "",
                  style: "fieldValue",
                },
                {
                  text: row.provider_name ? `${row.provider_name} (${row.provider_code})` : "ไม่ระบุ",
                  style: "fieldValue",
                },
                { text: row.type_name || "", style: "fieldValue" },
                {  text: statusName || "", style: "fieldValue" },
                { text: row.note, style: "fieldValue" },
              ]})
          ),
        },
      },
    ],
    // footer: (currentPage: number, pageCount: number) => ({
    //   margin: [20, 0, 20, 0],
    //   columns: [
    //     { width: "*", text: `วันเวลาที่พิมพ์ : ${formatDate(moment())} [${moment().format("HH:mm")}]` },
    //     { text: `หน้าที่ ${currentPage.toString()} / ${pageCount}`, alignment: "right" },
    //   ],
    // }),
    images: {
      ...images,
    },
  };
};

export default FormWaitingList;
