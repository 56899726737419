import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  TextArea,
  Dropdown,
  Button,
  Input
} from 'semantic-ui-react'

const ModProgressNoteFormUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%", padding: "5px" , backgroundColor: "#CCE6F8"}}>
        <div
          style={{display: "flex", justifyContent: "flex-end"}}>
          {props.closeIcon}
        </div>
        <div
          style={{  padding: "15px",  display: "block", marginBottom: "5px" }}>
          <div
            style={{ display: "flex", marginBottom: "15px" }}>
            {}
            <div
              style={{ flex: 1.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              
              <label
                style={{ textAlign: "left", marginRight: "15px",}}>
                Focus
              </label>
            </div>
            <div
              style={{ flex: 6 }}>
              
              <TextArea
                onChange={(e, v) =>  props.setProp("selectedNurseNote.focus", v.value)}
                rows="5"
                style={{ width:"100%", display:"none" }}
                value={props.selectedNurseNote?.focus}>
              </TextArea>
              <Dropdown
                allowAdditions={true}
                onAddItem={props.handleAddNurseNoteFocusOptions}
                onChange={props.onChangeDropdown}
                options={props.nurseNoteFocusOptions}
                search={true}
                selection={true}
                style={{width:"100%"}}
                value={props.selectedNurseNote?.focus}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", marginBottom: "15px" }}>
            
            <div
              style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              
              <label
                style={{ textAlign: "left", marginRight: "15px",}}>
                Progress Note
              </label>
            </div>
            <div
              style={{ flex: 0.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              
              <label
                style={{ textAlign: "right", marginRight: "15px"}}>
                A:
              </label>
            </div>
            <div
              className="ui form"
              style={{ flex: 6 }}>
              
              <TextArea
                onChange={(e, v) =>  props.setProp("selectedNurseNote.diagnosis", v.value)}
                rows="8"
                style={{ width:"100%" }}
                value={props.selectedNurseNote?.diagnosis}>
              </TextArea>
            </div>
          </div>
          <div
            style={{ display: "flex", marginBottom: "15px" }}>
            
            <div
              style={{ flex: 1.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              
              <label
                style={{ textAlign: "right", marginRight: "15px",}}>
                I:
              </label>
            </div>
            <div
              className="ui form"
              style={{ flex: 6 }}>
              
              <TextArea
                onChange={(e, v) =>  props.setProp("selectedNurseNote.plan", v.value)}
                rows="10"
                style={{ width:"100%" }}
                value={props.selectedNurseNote?.plan}>
              </TextArea>
            </div>
          </div>
          <div
            style={{ display: "flex", marginBottom: "15px" }}>
            
            <div
              style={{ flex: 1.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              
              <label
                style={{ textAlign: "right", marginRight: "15px",}}>
                E:
              </label>
            </div>
            <div
              className="ui form"
              style={{ flex: 6 }}>
              
              <TextArea
                onChange={(e, v) =>  props.setProp("selectedNurseNote.goal", v.value)}
                rows="8"
                style={{ width:"100%" }}
                value={props.selectedNurseNote?.goal}>
              </TextArea>
            </div>
          </div>
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
            
            <div
              style={{padding: "0px 15px"}}>
              Username
            </div>
            <div
              style={{padding: "0px 15px"}}>
              
              <Input
                onChange={props.onChangeUsername}
                placeholder="Username"
                value={props.username}>
              </Input>
            </div>
            <div
              style={{padding: "0px 15px"}}>
              Password
            </div>
            <div
              style={{padding: "0px 15px"}}>
              
              <Input
                onChange={props.onChangePassword}
                placeholder="Password"
                type="password"
                value={props.password}>
              </Input>
            </div>
            <div
              style={{padding: "0px 15px"}}>
              
              <Button
                color="green"
                fluid={true}
                onClick={props.handleSaveNurseNote}>
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}


export default ModProgressNoteFormUX

export const screenPropsDefault = {}

/* Date Time : Mon May 15 2023 03:22:58 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", padding: \"5px\" , backgroundColor: \"#CCE6F8\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{  padding: \"15px\",  display: \"block\", marginBottom: \"5px\" }"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"15px\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Progress Note"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"left\", marginRight: \"15px\",}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "TextArea",
      "parent": 99,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.diagnosis\", v.value)"
        },
        "rows": {
          "type": "value",
          "value": "8"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.diagnosis"
        }
      },
      "seq": 101,
      "void": true
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"15px\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"15px\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "I:"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", marginRight: \"15px\",}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "label",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": "E:"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", marginRight: \"15px\",}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "TextArea",
      "parent": 106,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.plan\", v.value)"
        },
        "rows": {
          "type": "value",
          "value": "10"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.plan"
        }
      },
      "seq": 117,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "TextArea",
      "parent": 108,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.goal\", v.value)"
        },
        "rows": {
          "type": "value",
          "value": "8"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.goal"
        }
      },
      "seq": 118,
      "void": true
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"15px\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "Focus"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"left\", marginRight: \"15px\",}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "TextArea",
      "parent": 125,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.focus\", v.value)"
        },
        "rows": {
          "type": "value",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\", display:\"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.focus"
        }
      },
      "seq": 127,
      "void": true
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 0.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": "A:"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", marginRight: \"15px\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "Dropdown",
      "parent": 125,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddNurseNoteFocusOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDropdown"
        },
        "options": {
          "type": "code",
          "value": "props.nurseNoteFocusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.focus"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.closeIcon"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Button",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleSaveNurseNote"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "Username"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "Password"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Input",
      "parent": 140,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeUsername"
        },
        "placeholder": {
          "type": "value",
          "value": "Username"
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Input",
      "parent": 142,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangePassword"
        },
        "placeholder": {
          "type": "value",
          "value": "Password"
        },
        "type": {
          "type": "value",
          "value": "password"
        },
        "value": {
          "type": "code",
          "value": "props.password"
        }
      },
      "seq": 144,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 55,
  "isMounted": true,
  "memo": false,
  "name": "ModProgressNoteFormUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 55
}

*********************************************************************************** */
