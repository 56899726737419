import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";

import {
  Button,
  Form,
  Input,
  Label,
  Segment,
  TextArea,
  Dropdown,
  Icon,
} from "semantic-ui-react";

const style = {
  paddingTop: { paddingTop: "20px" },
};

const TextAreaComponent = React.memo(
  forwardRef((props, ref) => {
    const [value, setValue] = useState("");

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          keyref: props.keyref,
          value: value,
        };
      },
    }));

    useEffect(() => {
      if (props.value) {
        setValue(props.value);
      }
    }, [props.value]);

    const handleValue = (e, data) => {
      setValue(data.value);
    };

    return (
      <Form.Group inline>
        <Form.Field width={16}>
          <TextArea
            readOnly={props.isReadOnly}
            value={value}
            onChange={handleValue}
          />
        </Form.Field>
      </Form.Group>
    );
  })
);

const TextInputComponent = React.memo(
  forwardRef((props, ref) => {
    const [value, setValue] = useState("");

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          keyref: props.keyref,
          value: value,
        };
      },
    }));

    useEffect(() => {
      if (props.value) {
        setValue(props.value);
      }
    }, [props.value]);

    const handleValue = (e, data) => {
      setValue(data.value);
    };

    return (
      <Form.Field width={4}>
        <Input
          readOnly={props.isReadOnly}
          value={value}
          onChange={handleValue}
        ></Input>
      </Form.Field>
    );
  })
);

const DropdownComponent = forwardRef((props, ref) => {
  const [value, setValue] = useState("");
  useImperativeHandle(ref, () => ({
    getData: () => {
      return {
        keyref: props.keyref,
        value: value,
      };
    },
  }));

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  const handleValue = (e, data) => {
    setValue(data.value);
  };

  return (
    <Dropdown
      // className="fluidDropdown"
      disabled={props.isReadOnly}
      selection
      value={value}
      options={props.optionList}
      onChange={handleValue}
    />
  );
});

const SubAdmission = forwardRef((props, ref) => {
  const admissionRef = React.useRef({});

  const [admissionData, setAdmissionData] = useState(null);

  const { titleCard, isReadOnly } = props;

  useImperativeHandle(ref, () => ({
    getSaveData: () => {
      let result = {};
      for (let k in admissionRef.current) {
        const data = admissionRef.current[k].getData();
        result = { ...result, [data.keyref]: data.value };
      }
      return result;
    },
    setData: (data) => {
      setAdmissionData(data);
    },
  }));

  return (
    <Segment raised secondary>
      <Label color="grey" ribbon size="big">
        {titleCard}
      </Label>

      <Form style={style.paddingTop}>
        <Form.Group inline>
          <Form.Field width={2}>
            <Label>แพทย์ผู้บันทึก</Label>
          </Form.Field>
          <Form.Field width={4}>
            <Label>{admissionData ? admissionData.created_by : ""}</Label>
          </Form.Field>

          <Form.Field width={2}>
            <Label>วันที่-เวลา</Label>
          </Form.Field>
          <Form.Field width={2}>
            <Label>{admissionData ? admissionData.created : ""}</Label>
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={16}>
            <label>1. Information:</label>
          </Form.Field>
        </Form.Group>
        <TextAreaComponent
          ref={(el) => (admissionRef.current["information"] = el)}
          keyref="information"
          value={admissionData ? admissionData.information : ""}
          isReadOnly={isReadOnly}
        />

        <Form.Group inline>
          <Form.Field width={2}>
            <label>2. ความน่าเชื่อถือได้:</label>
          </Form.Field>
          {/* <TextInputComponent
            ref={el => (admissionRef.current["info_reliability"] = el)}
            keyref="info_reliability"
            value={admissionData ? admissionData.info_reliability : ""}
            isReadOnly={isReadOnly}
          /> */}
          <DropdownComponent
            ref={(el) => (admissionRef.current["info_reliability"] = el)}
            keyref="info_reliability"
            value={admissionData ? admissionData.info_reliability : ""}
            isReadOnly={isReadOnly}
            optionList={[
              { text: "มาก", id: "HIGH", value: "HIGH" },
              { text: "ปานกลาง", id: "MEDIUM", value: "MEDIUM" },
              { text: "น้อย", id: "LOW", value: "LOW" },
              { text: "ประเมินไม่ได้", id: "NA", value: "NA" },
            ]}
          />
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={16}>
            <label>3. Chief Complaint</label>
          </Form.Field>
        </Form.Group>
        <TextAreaComponent
          ref={(el) => (admissionRef.current["chief_complaint"] = el)}
          keyref="chief_complaint"
          value={admissionData ? admissionData.chief_complaint : ""}
          isReadOnly={isReadOnly}
        />

        <Form.Group inline>
          <Form.Field width={16}>
            <label>4. Present Illness:</label>
          </Form.Field>
        </Form.Group>
        <TextAreaComponent
          ref={(el) => (admissionRef.current["present_illness"] = el)}
          keyref="present_illness"
          value={admissionData ? admissionData.present_illness : ""}
          isReadOnly={isReadOnly}
        />

        <Form.Group inline>
          <Form.Field width={16}>
            <label>
              5. Past Illness / Surgery / Major Injuries / Hospitalization :
            </label>
          </Form.Field>
        </Form.Group>
        <TextAreaComponent
          ref={(el) => (admissionRef.current["pass_illness"] = el)}
          keyref="pass_illness"
          value={admissionData ? admissionData.pass_illness : ""}
          isReadOnly={isReadOnly}
        />

        <Form.Group inline>
          <Form.Field width={16}>
            <label>6. Personal History:</label>
          </Form.Field>
        </Form.Group>
        <TextAreaComponent
          ref={(el) => (admissionRef.current["personal_history"] = el)}
          keyref="personal_history"
          value={admissionData ? admissionData.personal_history : ""}
          isReadOnly={isReadOnly}
        />

        <Form.Group inline>
          <Form.Field width={16}>
            <label>7. Immunization:</label>
          </Form.Field>
        </Form.Group>
        <TextAreaComponent
          ref={(el) => (admissionRef.current["immunization"] = el)}
          keyref="immunization"
          value={admissionData ? admissionData.immunization : ""}
          isReadOnly={isReadOnly}
        />

        <Form.Group inline>
          <Form.Field width={16}>
            <label>8. Family History:</label>
          </Form.Field>
        </Form.Group>
        <TextAreaComponent
          ref={(el) => (admissionRef.current["family_history"] = el)}
          keyref="family_history"
          value={admissionData ? admissionData.family_history : ""}
          isReadOnly={isReadOnly}
        />

        <Form.Group inline>
          <Form.Field width={16}>
            <label>9. Social Condition</label>
          </Form.Field>
        </Form.Group>
        <TextAreaComponent
          ref={(el) => (admissionRef.current["social_condition"] = el)}
          keyref="social_condition"
          value={admissionData ? admissionData.social_condition : ""}
          isReadOnly={isReadOnly}
        />

        <Form.Group inline>
          <Form.Field width={16}>
            <label>10. Sensitive Note</label>
          </Form.Field>
        </Form.Group>
        <TextAreaComponent
          ref={(el) => (admissionRef.current["sensitive_note"] = el)}
          keyref="sensitive_note"
          value={admissionData ? admissionData.sensitive_note : ""}
          isReadOnly={isReadOnly}
        />

        <Form.Group inline>
          <Form.Field width={16}>
            <Button
              color="green"
              type="button"
              onClick={props.onSaveAllCallback}
              loading={props.loading}
            >
              {props.saveText ? <Icon className="check"></Icon> : "บันทึก"}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
});

SubAdmission.defaultProps = {
  titleCard: "Admission Form",

  onSaveAllCallback: () => {},

  isReadOnly: false,
};

SubAdmission.propTypes = {
  onSaveAllCallback: PropTypes.func,

  isReadOnly: PropTypes.bool,
};

export default React.memo(SubAdmission);
