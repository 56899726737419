import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ErrorMessage from "../common/ErrorMessage";
import CardLayout from "../common/CardLayout";
import ReactTable from "react-table-6";
import Dropzone from "react-dropzone";
import { Form, Button, Grid, Message } from "semantic-ui-react";
import { toast } from "react-toastify";
import _ from "react-lib/compat/lodashplus";

const columns = [
	{ Header: "Invoice No", accessor: "invoice_no", width: 200 },
	{
		Header: "สถานะ",
		width: 200,
		Cell: props => {
			let data = props.original;
			let value = data.success ? "สำเร็จ" : "ไม่สำเร็จ";
			return <label>{value}</label>;
		},
	},
	{
		Header: "เหตุผลในการติด C",
		Cell: props => {
			let data = props.original;
			let reason = _.map(data.failed_reasons, item => `[${item.code}] ${item.message}`).join("\n");
			return <label>{reason}</label>;
		},
	},
];

var fileSelected = null;
const CardUploadSentClaimResult = props => {
	const isMounted = useRef(true);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	// State of Input
	const [coverageName, setCoverageName] = useState("");
	const [hospitalCode, setHospitalCode] = useState("");
	const [responseNo, setResponseNo] = useState("");
	const [responseAt, setResponseAt] = useState("");
	const [sentNo, setSentNo] = useState("");
	const [invoiceItems, setInvoiceItems] = useState([]);

	// Initial table
	const initialGetTrProps = (state, rowInfo, column, instance) => {
		var options = {};
		if (rowInfo && !_.get(rowInfo, "original.success")) {
			options.style = {
				background: "rgb(255, 197, 197)",
				color: "black",
			};
		}
		return options;
	};

	const initialGetTdProps = (state, rowInfo, column, instance) => {
		return {
			style: {
				whiteSpace: "pre-line",
			},
		};
	};

	// Upload file
	const handleUploadFile = async (accepted, rejected) => {
		const acceptedFile = _.get(accepted, "[0]");
		const rejectedFile = _.get(rejected, "[0]");
		if (rejectedFile instanceof File) {
			setError(`ประเภทไฟล์ไม่ถูกต้อง: ${rejectedFile.name}`);
			return;
		}
		if (!(acceptedFile instanceof File)) {
			setError(`เกิดข้อผิดพลาดในการเปิดไฟล์: ${rejectedFile.name}`);
			return;
		}
		// There are accepted file.
		if (error) {
			setError(null);
		}
		setLoading(true);
		const [uploadData, uploadError] = await props.controller.uploadResponseFile(acceptedFile);
		setLoading(false);
		if (!isMounted.current) {
			return;
		}
		if (uploadError) {
			setError(uploadError);
			return;
		}

		setCoverageName(uploadData.coverage_payer_sent_claim_group_name);
		setHospitalCode(uploadData.hospital_code);
		setResponseNo(uploadData.response_no);
		setResponseAt(uploadData.response_at);
		setSentNo(uploadData.sent_no);
		setInvoiceItems(uploadData.items);
		fileSelected = acceptedFile;
	};

	// "Confirm" button
	const handleConfirm = async () => {
		if (!fileSelected) {
			return;
		}
		setLoading(true);
		const [uploadData, uploadError] = await props.controller.confirmResponseFile(fileSelected);
		if (!isMounted.current) {
			return;
		}
		setLoading(false);
		if (uploadError) {
			setError(uploadError);
			return;
		}
		toast.success("บันทึกผลการส่งเบิกสำเร็จ");
		// Clear data
		setCoverageName("");
		setHospitalCode("");
		setResponseNo("");
		setResponseAt("");
		setSentNo("");
		setInvoiceItems([]);
		fileSelected = null;
	};

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	return (
		<CardLayout
			titleText="บันทึกผลการส่งเบิก"
			headerColor="green"
			loading={loading}
			toggleable={true}
			closeable={false}
		>
			<Form>
				<ErrorMessage error={error} />
				<Form.Group inline>
					<Form.Field width={2}>
						<label>สิทธิ</label>
					</Form.Field>
					<Form.Input width={3} readOnly={true} value={coverageName} />
				</Form.Group>
				<Form.Group inline>
					<Form.Field width={2}>
						<label>รหัสโรงพยาบาล</label>
					</Form.Field>
					<Form.Input width={3} readOnly={true} value={hospitalCode} />
				</Form.Group>
				<Form.Group inline>
					<Form.Field width={2}>
						<label>เลขที่ตอบรับ</label>
					</Form.Field>
					<Form.Input width={3} readOnly={true} value={responseNo} />
				</Form.Group>
				<Form.Group inline>
					<Form.Field width={2}>
						<label>วันที่ออกเลขตอบรับ</label>
					</Form.Field>
					<Form.Input width={3} readOnly={true} value={responseAt} />
				</Form.Group>
				<Form.Group inline>
					<Form.Field width={2}>
						<label>งวดส่ง</label>
					</Form.Field>
					<Form.Input width={14} readOnly={true} value={sentNo} />
				</Form.Group>
			</Form>
			<br />
			<Grid>
				<Grid.Column width={3}>
					<Dropzone multiple={false} style={STYLE.UPLOAD} accept=".zip" onDrop={handleUploadFile}>
						<h3 style={STYLE.UPLOAD_HEADER}> อัพโหลดไฟล์ </h3>
						<p> คลิกเพื่อเลือกไฟล์ หรือลากไฟล์มาวาง </p>
						<p style={STYLE.UPLOAD_WARNING}> รองรับเฉพาะนามสกุล .zip </p>
					</Dropzone>
				</Grid.Column>
				<Grid.Column width={13}>
					<ReactTable
						className="-striped -highlight"
						defaultPageSize={10}
						loading={loading}
						data={invoiceItems}
						columns={columns}
						getTrProps={initialGetTrProps}
						getTdProps={initialGetTdProps}
					/>
					<div style={STYLE.ALIGN_RIGHT_BUTTON}>
						<Button color="green" onClick={handleConfirm}>
							ยืนยัน
						</Button>
					</div>
				</Grid.Column>
			</Grid>
		</CardLayout>
	);
};

const STYLE = {
	UPLOAD: {
		width: "100%",
		height: "100%",
		border: "3px dashed #b2b2c8",
		backgroundColor: "#fff",
		borderRadius: "5px",
		cursor: "pointer",
		textAlign: "center",
	},
	UPLOAD_HEADER: {
		color: "#474646",
		paddingTop: "5%",
	},
	UPLOAD_WARNING: {
		color: "darkred",
	},
	ALIGN_RIGHT_BUTTON: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "1em",
	},
};

CardUploadSentClaimResult.defaultProps = {
	controller: null,
};

CardUploadSentClaimResult.propTypes = {
	controller: PropTypes.object,
};

export default React.memo(CardUploadSentClaimResult);
