import WasmController from "react-lib/frameworks/WasmController";
import * as MainHISI from "./MainHISInterface";
import * as TPDInterface from "react-lib/apps/HISV3/TPD/TPDInterface";
import * as CardPatientQueueI from "react-lib/apps/HISV3/common/CardPatientListInterface";
import * as VitalSignInterface from "react-lib/apps/HISV3/PTM/VitalSignInterface";
import * as CommonInterface from "react-lib/apps/HISV3/common/CommonInterface";
import * as DPIInterface from "react-lib/apps/HISV3/DPI/DPIInterface";
import * as DPOInterface from "react-lib/apps/HISV3/DPO/DPOInterface";
import * as LabInterface from "react-lib/apps/HISV3/LAB/LabInterface";
import * as BILInterface from "react-lib/apps/HISV3/BIL/BILInterface";
import * as ADMInterface from "react-lib/apps/HISV3/ADM/ADMInterface";
import * as IMEInterface from "react-lib/apps/HISV3/IME/IMEInterface";
import * as PTMInterface from "react-lib/apps/HISV3/PTM/PTMInterface";
import * as ORMInterface from "react-lib/apps/HISV3/ORM/ORMInterface";
import * as CLMInterface from "react-lib/apps/HISV3/CLM/CLMInterface";
import * as PHRInterface from "react-lib/apps/HISV3/PHR/PHRInterface";
import * as HCUInterface from "react-lib/apps/HISV3/HCU/HCUInterface";
import * as DentalRecordI from "react-lib/apps/common/CUDENT/DentalRecordInterface";
import * as PHVInterface from "HIS/PHV/PHVInterface";

import * as StaffChatI from "react-lib/apps/MSG/StaffChatInterface";
import * as QueueForPatientInterface from "react-lib/apps/IsHealth/Dashboard/QueueInterface";

import * as MedAdminI from "react-lib/apps/HISV3/PTM/MedAdminInterface";
import * as IsHealthInterface from "./IsHealth/IsHealthInterface";
import * as VaccineUploadI from "./VaccineUpload/VaccineUploadInterface";
import * as SchedulingI from "react-lib/apps/Scheduling/SchedulingInterface";
import * as SearchBoxI from "react-lib/appcon/common/SearchBoxI";
import * as MSDInterface from "react-lib/apps/HISV3/MSD/MSDInterface";
import * as ADMI from "./Interface/ADMI";
import * as ADRI from "./Interface/ADRI";
import * as ANSI from "./Interface/ANSI";
import * as APPI from "./Interface/APPI";
import * as BILI from "./Interface/BILI";
import * as BLBI from "./Interface/BLBI";
import * as CoreI from "./Interface/CoreI";
import * as DFCI from "./Interface/DFCI";
import * as DPII from "./Interface/DPII";
import * as DPOI from "./Interface/DPOI";
import * as HRMI from "./Interface/HRMI";
import * as ICUI from "./Interface/ICUI";
import * as IMEI from "./Interface/IMEI";
import * as INFI from "./Interface/INFI";
import * as LABI from "./Interface/LABI";
import * as LRMI from "./Interface/LRMI";
import * as MixInI from "./Interface/MixInI";
import * as MSGI from "./Interface/MSGI";
import * as ORMI from "./Interface/ORMI";
import * as PRXI from "./Interface/PRXI";
import * as PTMI from "./Interface/PTMI";
import * as QUEI from "./Interface/QUEI";
import * as REGI from "./Interface/REGI";
import * as TECI from "./Interface/TECI";
import * as TPDI from "./Interface/TPDI";
import * as TRTI from "./Interface/TRTI";
import * as UserI from "./Interface/UserI";
import * as ModPlanningI from "react-lib/apps/HISV3/common/ModPlanningInterface";
import * as ModSolventSelectionI from "react-lib/apps/HISV3/TPD/ModSolventSelectionInterface";
import * as TriageI from "react-lib/apps/HISV3/PTM/sequence/Triage";
import * as SetPatientI from "react-lib/apps/HISV3/REG/sequence/SetPatient";
import * as ReceiveOrderI from "react-lib/apps/HISV3/PTM/sequence/ReceiveOrder";
import * as TreatmentResultI from "react-lib/apps/HISV3/DPO/sequence/TreatmentResult";
import * as DispensingOrderSupplyI from "react-lib/apps/HISV3/MSD/sequence/DispensingOrderSupply";
import * as PerioperativeNursingI from "react-lib/apps/HISV3/ORM/sequence/PerioperativeNursing";
import * as StaffSettingI from "react-lib/apps/USER/StaffSettingInterface";
import * as HealthMemberI from "react-lib/apps/HISV3/PHR/sequence/HealthMember";
// import * as TimeTrackingI from "react-lib/apps/HISV3/DPO/sequence/TimeTracking";

// Ishealth-v3 port into CUDent (vise versa)
import * as PatientSearchCUInterface from "react-lib/apps/common/CU/PatientSearchCUInterface";

export default class MainHISController extends WasmController<
  MainHISI.State,
  MainHISI.Event,
  MainHISI.Data
> {
  constructor(
    getState: () => MainHISI.State,
    setState: (state: MainHISI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MainHISI.DataInitial);
  }

  handleEvent: (e: MainHISI.Event) => any = async (e: MainHISI.Event) => {
    console.groupCollapsed("handleEvent e.message: ", e.message, " e.params: ", (e as any).params);
    console.trace();
    console.groupEnd()
    switch (e.message) {
      case "RunSequence":
        return MainHISI.RunSequence(this, e.params);

      case "Logout":
        return CommonInterface.Logout(this, e.params);

      case "DidMount":
        return MainHISI.DidMount(this, e.params);

      case "DidUpdate":
        return MainHISI.DidUpdate(this, e.params);

      case "GetMasterData":
        return CommonInterface.GetMasterData(this, e.params);

      // TODO: Duplicate ChangeDivision !!
      case "ChangeDivision":
        return MainHISI.ChangeDivision(this, e.params);

      case "ChangeDevice":
        return MainHISI.ChangeDevice(this, e.params);

      case "RefreshDivision":
        return MainHISI.RefreshDivision(this, e.params);

      case "GetEncounterInfo":
        return MainHISI.GetEncounterInfo(this, e.params);

      case "HandleUpdateNotiMessage":
        return MainHISI.HandleUpdateNotiMessage(this, e.params);

      case "HandleDidMountEncounter":
        return MainHISI.HandleDidMountEncounter(this, e.params);

      case "HandleUnmountEncounter":
        return MainHISI.HandleUnmountEncounter(this, e.params);

      case "HandleSelectTabIndex":
        return MainHISI.HandleSelectTabIndex(this, e.params);
      // Scheduling
      case "SearchPatient":
        return SchedulingI.SearchPatient(this, e.params);

      case "ClearAppointment":
        return SchedulingI.ClearAppointment(this, e.params);

      case "SelectPatient":
        return SchedulingI.SelectPatient(this, e.params);

      case "CreatePatientAppointment":
        return SchedulingI.CreatePatientAppointment(this, e.params);

      case "RefreshAppointment":
        return SchedulingI.RefreshAppointment(this, e.params);

      case "SelectAppointment":
        return SchedulingI.SelectAppointment(this, e.params);

      case "HandleDownloadAppointmentSummary":
        return SchedulingI.HandleDownloadAppointmentSummary(this, e.params);

      case "HandlePrintAppointmentSummaryList":
        return SchedulingI.HandlePrintAppointmentSummaryList(this, e.params);

      case "AssignAppointmentToBlock":
        return SchedulingI.AssignAppointmentToBlock(this, e.params);

      case "FilterSchedule":
        return SchedulingI.FilterSchedule(this, e.params);

      case "FilterAppointmentSchedule":
        return SchedulingI.FilterAppointmentSchedule(this, e.params);

      case "AddBlock":
        return SchedulingI.AddBlock(this, e.params);

      case "GetChairList":
        return SchedulingI.GetChairList(this, e.params);

      case "GetChairWithDivisionServiceBlock":
        return SchedulingI.GetChairWithDivisionServiceBlock(this, e.params);

      case "BookChair":
        return SchedulingI.BookChair(this, e.params);

      case "SetScheduling":
        return SchedulingI.SetScheduling(this, e.params);

      case "GetSummaryWaitingQueue":
        return SchedulingI.GetSummaryWaitingQueue(this, e.params);

      case "GetSummaryDoctor":
        return SchedulingI.GetSummaryDoctor(this, e.params);

      case "GetSummaryStatistics":
        return SchedulingI.GetSummaryStatistics(this, e.params);

      case "UserTokenize":
        return SchedulingI.UserTokenize(this, e.params);

      case "GetListSchedule":
        return await SchedulingI.GetListSchedule(this, e.params);

      case "CreateUpdateSchedule":
        return await SchedulingI.CreateUpdateSchedule(this, e.params);

      case "Holiday":
        return await SchedulingI.Holiday(this, e.params);

      case "PrintScheduling":
        return await SchedulingI.PrintScheduling(this, e.params);

      case "getDoctorNoteList":
        return await SchedulingI.getDoctorNoteList(this, e.params);

      case "getDoctorNoteGroupList":
        return await SchedulingI.getDoctorNoteGroupList(this, e.params);

      case "SearchBlockList":
        return await SchedulingI.SearchBlockList(this, e.params);

      case "ChangeDivision":
        return SchedulingI.ChangeDivision(this, e.params);

      case "HandleActionPreOrderList":
        return SchedulingI.HandleActionPreOrderList(this, e.params);

      case "ImagingRequestOrder":
        return SchedulingI.ImagingRequestOrder(this, e.params);

      case "GetEncounterWithPatient":
        SchedulingI.GetEncounterWithPatient(this, e.params);
        return;

      case "HandleSelectAppointmentByOROrder":
        return SchedulingI.HandleSelectAppointmentByOROrder(this, e.params);

      case "HandlePrintORAppointmentForm":
        return SchedulingI.HandlePrintORAppointmentForm(this, e.params);

      case "CreatePatientAppointmentForBloodBank":
        return SchedulingI.CreatePatientAppointmentForBloodBank(this, e.params);

      case "HandleInitialConsultData":
        return SchedulingI.HandleInitialConsultData(this, e.params);

      case "HandleSetConsultDetail":
        return SchedulingI.HandleSetConsultDetail(this, e.params);

      case "HandleMakeConsultAppointment":
        return SchedulingI.HandleMakeConsultAppointment(this, e.params);

      case "HandleConfirmConsultAppointment":
        return SchedulingI.HandleConfirmConsultAppointment(this, e.params);

      case "HandleFinishConsultAppointment":
        return SchedulingI.HandleFinishConsultAppointment(this, e.params);

      case "HandleSaveConsultResponse":
        return SchedulingI.HandleSaveConsultResponse(this, e.params);

      case "HandleEditConsultResponse":
        return SchedulingI.HandleEditConsultResponse(this, e.params);

      case "HandleCancelConsultAppointment":
        return SchedulingI.HandleCancelConsultAppointment(this, e.params);

      case "HandleGetUsername":
        return SchedulingI.HandleGetUsername(this, e.params);

      case "HandlePrintChairBooking":
        return SchedulingI.HandlePrintChairBooking(this, e.params);
      // Scheduling V3
      // case "SearchPatient":
      //   return SchedulingIV3.SearchPatient(this, e.params);

      // case "ClearAppointment":
      //   return SchedulingIV3.ClearAppointment(this, e.params);

      // case "SelectPatient":
      //   return SchedulingIV3.SelectPatient(this, e.params);

      // case "CreatePatientAppointment":
      //   return SchedulingIV3.CreatePatientAppointment(this, e.params);

      // case "SelectAppointment":
      //   return SchedulingIV3.SelectAppointment(this, e.params);

      // case "HandleActionPreOrderList":
      //   return SchedulingIV3.HandleActionPreOrderList(this, e.params);

      // case "AssignAppointmentToBlock":
      //   return SchedulingIV3.AssignAppointmentToBlock(this, e.params);

      // case "FilterSchedule":
      //   return SchedulingIV3.FilterSchedule(this, e.params);

      // case "AddBlock":
      //   return SchedulingIV3.AddBlock(this, e.params);

      // case "GetChairList":
      //   return SchedulingIV3.GetChairList(this, e.params);

      // case "BookChair":
      //   return SchedulingIV3.BookChair(this, e.params);

      // case "SetScheduling":
      //   return SchedulingIV3.SetScheduling(this, e.params);

      // case "GetListSchedule":
      //   return await SchedulingIV3.GetListSchedule(this, e.params);

      // case "CreateUpdateSchedule":
      //   return await SchedulingIV3.CreateUpdateSchedule(this, e.params);

      // case "Holiday":
      //   return await SchedulingIV3.Holiday(this, e.params);

      // case "ImagingRequestOrder":
      //   SchedulingIV3.ImagingRequestOrder(this, e.params);
      //   return;

      // Replaced with SchedulingI
      // case "SearchPatient":
      //   return IsHealthInterface.SearchPatient(this, e.params);

      case "SelectPatientForAppointment":
        return IsHealthInterface.SelectPatientForAppointment(this, e.params);

      // Replaced with SchedulingI
      // case "CreatePatientAppointment":
      //   return IsHealthInterface.CreatePatientAppointment(this, e.params);

      // Replaced with SchedulingI
      // case "SelectAppointment":
      //   return IsHealthInterface.SelectAppointment(this, e.params);

      // Replaced with SchedulingI
      // case "AssignAppointmentToBlock":
      //   return IsHealthInterface.AssignAppointmentToBlock(this, e.params);

      case "ItemSearch":
        return SearchBoxI.ItemSearch(this, e.params);

      case "SetDrugOrder":
        return TPDInterface.SetDrugOrder(this, e.params);

      case "DrugSearch":
        return TPDInterface.DrugSearch(this, e.params);

      case "AddToOrder":
        return TPDInterface.AddToOrder(this, e.params);

      case "HandleChangeDrugOrderDetail":
        return TPDInterface.HandleChangeDrugOrderDetail(this, e.params);

      // Medication Error List
      case "GetSearchUserList":
        return TPDInterface.GetSearchUserList(this, e.params);

      case "GetSearchDivisionList":
        return TPDInterface.GetSearchDivisionList(this, e.params);

      case "GetSearchUserDivisionList":
        return TPDInterface.GetSearchUserDivisionList(this, e.params);

      case "GetMedicationErrorDetail":
        return TPDInterface.GetMedicationErrorDetail(this, e.params);

      case "GetDebugInfo":
        return MainHISI.GetDebugInfo(this, e.params);

      case "GetDrugTransferRequestList":
        return TPDInterface.GetDrugTransferRequestList(this, e.params);

      case "GetDrugItemDetail":
        return TPDInterface.GetDrugItemDetail(this, e.params);

      case "HandleOnRemoveDrug":
        return TPDInterface.HandleOnRemoveDrug(this, e.params);
      case "HanleOnLogDrugOrder":
        return TPDInterface.HanleOnLogDrugOrder(this, e.params);
      case "HandleGetDrugDetail":
        return TPDInterface.HandleGetDrugDetail(this, e.params);

      case "HandleDrugRecommendation":
        return TPDInterface.HandleDrugRecommendation(this, e.params);

      case "GetPediatricDose":
        return TPDInterface.GetPediatricDose(this, e.params);

      // ModPlanning
      case "handleSetDefaultPlanningData":
        return ModPlanningI.handleSetDefaultPlanningData(this);
      case "handleSetPlanningData":
        return ModPlanningI.handleSetPlanningData(this, e.params);
      case "handleSetModPlanningConfig":
        return ModPlanningI.handleSetModPlanningConfig(this, e.params);
      case "handleEditPlanItem":
        return ModPlanningI.handleEditPlanItem(this, e.params);
      case "handleActionPlanItem":
        return ModPlanningI.handleActionPlanItem(this, e.params);

      // ModSolventSelection
      case "initModSolventSelection":
        return ModSolventSelectionI.initModSolventSelection(this, e.params);
      case "SetModSolventSelectionData":
        return ModSolventSelectionI.SetModSolventSelectionData(this, e.params);
      case "SetModSolventSelectionConfig":
        ModSolventSelectionI.SetModSolventSelectionConfig(this, e.params);
        return;
      case "GetDrugSolventList":
        return ModSolventSelectionI.GetDrugSolventList(this, e.params);
      case "GetDrugSolventDetail":
        return ModSolventSelectionI.GetDrugSolventDetail(this, e.params);
      case "SaveSolventOldData":
        return ModSolventSelectionI.SaveSolventOldData(this);
      case "SaveSolventData":
        return ModSolventSelectionI.SaveSolventData(this, e.params);
      case "RemoveSolventData":
        return ModSolventSelectionI.RemoveSolventData(this);

      // TPDInterface
      case "PreviewDrugContinuePlan":
        return TPDInterface.PreviewDrugContinuePlan(this, e.params);

      case "ClaimDrugContinuePlan":
        return TPDInterface.ClaimDrugContinuePlan(this, e.params);

      case "CheckDrugGroupDuplicate":
        return TPDInterface.CheckDrugGroupDuplicate(this, e.params);

      case "ConfirmDrugContinueOrder":
        return TPDInterface.ConfirmDrugContinueOrder(this, e.params);

      case "EditDrugContinuePlan":
        return TPDInterface.EditDrugContinuePlan(this, e.params);

      case "NoteAndVerifyAndCancelDrugContinuePlan":
        return TPDInterface.NoteAndVerifyAndCancelDrugContinuePlan(this, e.params);

      case "ListDrugContinueActionLog":
        return TPDInterface.ListDrugContinueActionLog(this, e.params);

      case "SetDrugContinueOptions":
        return TPDInterface.SetDrugContinueOptions(this, e.params);

      case "SetModNoteData":
        return TPDInterface.SetModNoteData(this, e.params);

      case "SetModSpecialDirectionConfig":
        return TPDInterface.SetModSpecialDirectionConfig(this, e.params);

      case "CheckShowButtonSolvent":
        return TPDInterface.CheckShowButtonSolvent(this);

      case "ConfirmDrugOrder":
        return TPDInterface.ConfirmDrugOrder(this, e.params);

      case "DrugOrderHistory":
        return TPDInterface.DrugOrderHistory(this, e.params);

      case "SetDrugOrderItemsData":
        return TPDInterface.SetDrugOrderItemsData(this, e.params);

      case "OpenMedReconcileFromWarning":
        return TPDInterface.OpenMedReconcileFromWarning(this, e.params);

      case "ClearMedReconcile":
        return TPDInterface.ClearMedReconcile(this, e.params);

      case "GetMedReconcile":
        return TPDInterface.GetMedReconcile(this, e.params);

      case "GetMedReconcileInternal":
        TPDInterface.GetMedReconcileInternal(this, e.params);
        return;

      case "GetMedReconcileLog":
        return TPDInterface.GetMedReconcileLog(this, e.params);

      case "GetMedReconcileCheck":
        return TPDInterface.GetMedReconcileCheck(this, e.params);

      case "ApproveDrugOrder":
        return TPDInterface.ApproveDrugOrder(this, e.params);

      case "SetMedReconcile":
        return TPDInterface.SetMedReconcile(this, e.params);

      case "HandleGetDetailForExternalDrug":
        return TPDInterface.HandleGetDetailForExternalDrug(this, e.params);

      case "MedReconcileDrugOrder":
        TPDInterface.MedReconcileDrugOrder(this, e.params);
        return;

      case "GetClassify":
        return CardPatientQueueI.GetClassify(this, e.params);

      case "GetPatientQueue":
        return CardPatientQueueI.GetPatientQueue(this, e.params);

      case "GetPatientDetailView":
        return CardPatientQueueI.GetPatientDetailView(this, e.params);

      case "SelectEncounter":
        return CardPatientQueueI.SelectEncounter(this, e.params);

      case "HandleSaveMedicalRecordDischarge":
        return CardPatientQueueI.HandleSaveMedicalRecordDischarge(this, e.params);
      case "DrugOrderUpdateCallTelephar":
        return CardPatientQueueI.DrugOrderUpdateCallTelephar(this, e.params);

      case "UpdateEncounterStatus":
        return CardPatientQueueI.UpdateEncounterStatus(this, e.params);

      case "HandleCheckApprove":
        return CardPatientQueueI.HandleCheckApprove(this, e.params);

      case "HandleRefreshEncounter":
        return CardPatientQueueI.HandleRefreshEncounter(this, e.params);

      case "HandleDischargeConsult":
        return CardPatientQueueI.HandleDischargeConsult(this, e.params);

      case "HandleAddMessageProblemDPO":
        return CardPatientQueueI.HandleAddMessageProblemDPO(this, e.params);

      case "GetUsernameForDischarge":
        return CardPatientQueueI.GetUsernameForDischarge(this, e.params);

      case "GetDoctorSearch":
        return CardPatientQueueI.GetDoctorSearch(this, e.params);

      case "GetDivisionByOrganization":
        return CardPatientQueueI.GetDivisionByOrganization(this, e.params);

      case "CheckUserLogin":
        return CardPatientQueueI.CheckUserLogin(this, e.params);

      case "HandleGetDoctorOrder":
        return CardPatientQueueI.HandleGetDoctorOrder(this, e.params);

      case "HandleGoToCreateAppointment":
        return CardPatientQueueI.HandleGoToCreateAppointment(this, e.params);

      case "HandleGoBackEncounter":
        return CardPatientQueueI.HandleGoBackEncounter(this, e.params);

      case "HandleGoBackAppointment":
        return CardPatientQueueI.HandleGoBackAppointment(this, e.params);

      case "GetDrugOrderList":
        return TPDInterface.GetDrugOrderList(this, e.params);

      case "ClearDrugOperatingOrderList":
        return TPDInterface.ClearDrugOperatingOrderList(this, e.params);

      case "GetDrugOrderQueue":
        return TPDInterface.GetDrugOrderQueue(this, e.params);

      case "SaveDrugPendingRemark":
        return TPDInterface.SaveDrugPendingRemark(this, e.params);

      case "GetDrugContinuePlan":
        return TPDInterface.GetDrugContinuePlan(this);

      case "SetDrugContinuePlan":
        return TPDInterface.SetDrugContinuePlan(this, e.params);

      case "SetDrugContinueFilter":
        return TPDInterface.SetDrugContinueFilter(this, e.params);

      case "SelectDrugOrderWorking":
        return TPDInterface.SelectDrugOrderWorking(this, e.params);

      case "SelectDashboardTelephar":
        return TPDInterface.SelectDashboardTelephar(this, e.params);

      case "SelectTelepharQueue":
        return TPDInterface.SelectTelepharQueue(this, e.params);

      case "SelectVerbalDrugOrderWorking":
        return TPDInterface.SelectVerbalDrugOrderWorking(this, e.params);

      case "HandleSavePostponeAnswer":
        TPDInterface.HandleSavePostponeAnswer(this, e.params);
        return;

      case "PutDrugOrderItemPrintLabel":
        TPDInterface.PutDrugOrderItemPrintLabel(this, e.params);
        return;

      case "HandleCloseDrugOrderEstimate":
        TPDInterface.HandleCloseDrugOrderEstimate(this, e.params);
        return;

      case "CheckHaveQuestion":
        TPDInterface.CheckHaveQuestion(this, e.params);
        return;

      case "HandleSetAnswerDrugOrderItem":
        TPDInterface.HandleSetAnswerDrugOrderItem(this, e.params);
        return;

      case "DeleteDrugOrderItem":
        TPDInterface.DeleteDrugOrderItem(this, e.params);
        return;

      case "HandleCheckDoctorCertificate":
        return TPDInterface.HandleCheckDoctorCertificate(this, e.params);

      case "HandleGetDeliverDrugTokenization":
        TPDInterface.HandleGetDeliverDrugTokenization(this, e.params);
        return;

      case "HandleReMedication":
        TPDInterface.HandleReMedication(this, e.params);
        return;

      case "HandleModRecontinueMedication":
        TPDInterface.HandleModRecontinueMedication(this, e.params);
        return;

      case "CreateDrugOrderItemClaim":
        TPDInterface.CreateDrugOrderItemClaim(this, e.params);
        return;

      case "UpdateDrugOrderWorking":
        TPDInterface.UpdateDrugOrderWorking(this, e.params);
        return;

      case "PrintDrugOrderFromDoctor":
        TPDInterface.PrintDrugOrderFromDoctor(this, e.params);
        return;

      case "HandlePrintNarcoticForm":
        TPDInterface.HandlePrintNarcoticForm(this, e.params);
        return;

      case "HandlePrintPsychotropicForm":
        TPDInterface.HandlePrintPsychotropicForm(this, e.params);
        return;

      case "HandleNurseOrderPerformed":
        TPDInterface.HandleNurseOrderPerformed(this, e.params);
        return;

      case "HandleModDrugLabInteraction":
        TPDInterface.HandleModDrugLabInteraction(this, e.params);
        return;

      case "HandleModDrugDisease":
        TPDInterface.HandleModDrugDisease(this, e.params);
        return;

      case "HandleModDrugInteraction":
        TPDInterface.HandleModDrugInteraction(this, e.params);
        return;

      case "AllVerifyDrug":
        return TPDInterface.AllVerifyDrug(this, e.params);

      case "DrugPackageAddToOrder":
        return TPDInterface.DrugPackageAddToOrder(this, e.params);

      case "GetLotNoExp":
        TPDInterface.GetLotNoExp(this, e.params);
        return;

      case "UpdateLotNoExp":
        TPDInterface.UpdateLotNoExp(this, e.params);
        return;

      case "GetVitalSignType":
        return VitalSignInterface.GetVitalSignType(this, e.params);

      case "GetVitalSign":
        return VitalSignInterface.GetVitalSign(this, e.params);

      case "GetLastHeight":
        return VitalSignInterface.GetLastHeight(this, e.params);

      case "GetIPDOrderSummary":
        return DPIInterface.GetIPDOrderSummary(this, e.params);

      case "SetIPDOrderSummaryFirebase":
        return DPIInterface.SetIPDOrderSummaryFirebase(this, e.params);

      case "GetIPDContinueHistory":
        return DPIInterface.GetIPDContinueHistory(this, e.params);

      case "HandleSetContinueHistoryList":
        return DPIInterface.HandleSetContinueHistoryList(this, e.params);

      case "GetUserPermissionOrderSummary":
        return DPIInterface.GetUserPermissionOrderSummary(this, e.params);

      case "HandleUpdatePlanItem":
        return DPIInterface.HandleUpdatePlanItem(this, e.params);

      case "HandleSetOpenModSuccessOrderSummary":
        return DPIInterface.HandleSetOpenModSuccessOrderSummary(this, e.params);

      case "HandleSetFilterOrderSummary":
        return DPIInterface.HandleSetFilterOrderSummary(this, e.params);

      case "UpdateDoctorOrderCancel":
        return DPIInterface.UpdateDoctorOrderCancel(this, e.params);

      case "GetListProgressionNote":
        DPIInterface.GetListProgressionNote(this, e.params);
        return;

      case "SaveNewMeasurement":
        return VitalSignInterface.SaveNewMeasurement(this, e.params);

      case "AddVitalSignValue":
        return VitalSignInterface.AddVitalSignValue(this, e.params);

      case "CancelMeasurement":
        return VitalSignInterface.CancelMeasurement(this, e.params);

      case "getAdverseReactionList":
        return DPOInterface.getAdverseReactionList(this, e.params);

      case "postAdverseReaction":
        return DPOInterface.postAdverseReaction(this, e.params);

      case "postPatientScreenNew":
        return DPOInterface.postPatientScreenNew(this, e.params);

      case "getMedicalRecordEmrDetail":
        return DPOInterface.getMedicalRecordEmrDetail(this, e.params);

      case "getEncounterDetail":
        return DPOInterface.getEncounterDetail(this, e.params);

      case "putPatientScreenUpdate":
        return DPOInterface.putPatientScreenUpdate(this, e.params);

      case "putAdverseReaction":
        return DPOInterface.putAdverseReaction(this, e.params);

      case "getPhysicalExamOrgan":
        return DPOInterface.getPhysicalExamOrgan(this, e.params);

      case "HandleGetTreatmentTokenization":
        return TreatmentResultI.HandleGetTreatmentTokenization(this, e.params);

      case "GetProblemList":
        return CardPatientQueueI.GetProblemList(this, e.params);

      case "GetLabCode":
        return LabInterface.GetLabCode(this, e.params);

      case "GetLabDetail":
        return LabInterface.GetLabDetail(this, e.params);

      case "GetLabInterfaceSummary":
        return LabInterface.GetLabInterfaceSummary(this, e.params);

      case "GetDoctorLabOrderList":
        return LabInterface.GetDoctorLabOrderList(this, e.params);

      case "GetCentralLabOrder":
        return LabInterface.GetCentralLabOrder(this, e.params);

      case "CheckPermissionLabConfidential":
        return LabInterface.CheckPermissionLabConfidential(this, e.params);

      case "SetLabOrderQueueData":
        return LabInterface.SetLabOrderQueueData(this, e.params);

      // ------------------------------ CardSpecimenCollector
      case "SpecimenCollectorMasterData":
        return LabInterface.SpecimenCollectorMasterData(this, e.params);
      case "SpecimenLogList":
        return LabInterface.SpecimenLogList(this, e.params);
      case "PrintLabSticker":
        return LabInterface.PrintLabSticker(this, e.params);
      case "ReceiveSpecimen":
        return LabInterface.ReceiveSpecimen(this, e.params);
      case "RejectSpecimen":
        return LabInterface.RejectSpecimen(this, e.params);
      case "EndCollectSpecimen":
        return LabInterface.EndCollectSpecimen(this, e.params);
      case "CancelCollectSpecimen":
        return LabInterface.CancelCollectSpecimen(this, e.params);
      case "ScanBarcodeStickerSpecimen":
        return LabInterface.ScanBarcodeStickerSpecimen(this, e.params);
      case "HandlePrintStickerSpecimen":
        return LabInterface.HandlePrintStickerSpecimen(this, e.params);
      //
      case "CentralLabResultList":
        return LabInterface.CentralLabResultList(this, e.params);
      case "ListCentralLabResultConfidential":
        return LabInterface.ListCentralLabResultConfidential(this, e.params);

      case "GetLabOrderQueue":
        return LabInterface.GetLabOrderQueue(this, e.params);

      case "SelectLabOrderWorking":
        return LabInterface.SelectLabOrderWorking(this, e.params);

      case "SaveLabResult":
        return LabInterface.SaveLabResult(this, e.params);

      case "AuthorizeLabResult":
        return LabInterface.AuthorizeLabResult(this, e.params);

      case "LabOrderArrive":
        return LabInterface.LabOrderArrive(this, e.params);

      case "HandlePrintLabSummary":
        return LabInterface.HandlePrintLabSummary(this, e.params);

      case "ClearDrugOrderForm":
        return TPDInterface.ClearDrugOrderForm(this, e.params);

      case "GetInvoiceItemByMode":
        return BILInterface.GetInvoiceItemByMode(this, e.params);

      case "GetInvoiceItemByOrder":
        return BILInterface.GetInvoiceItemByOrder(this, e.params);

      case "GetInvoiceItemByItem":
        return BILInterface.GetInvoiceItemByItem(this, e.params);

      case "HandleSearchInvoiceItems":
        return BILInterface.HandleSearchInvoiceItems(this, e.params);

      case "GetMasterDataPayMethod":
        return BILInterface.GetMasterDataPayMethod(this, e.params);

      case "HandleSetPaymentMethodList":
        return BILInterface.HandleSetPaymentMethodList(this, e.params);

      case "HandleChangePaymentMethodList":
        return BILInterface.HandleChangePaymentMethodList(this, e.params);

      case "HandleKeyDownCouponNumber":
        return BILInterface.HandleKeyDownCouponNumber(this, e.params);

      case "GetReportStationSummary":
        BILInterface.GetReportStationSummary(this, e.params);
        return;

      case "UpdateReportStationSummary":
        BILInterface.UpdateReportStationSummary(this, e.params);
        return;

      case "BillingQueueAction":
        return BILInterface.BillingQueueAction(this, e.params);

      case "GetPatientDoctorOrder":
        return BILInterface.GetPatientDoctorOrder(this, e.params);

      case "BillingQueuePagination":
        return BILInterface.BillingQueuePagination(this, e.params);

      case "GetCreditNoteItem":
        return BILInterface.GetCreditNoteItem(this, e.params);

      case "HandleTransfer":
        return BILInterface.HandleTransfer(this, e.params);

      case "HandleGetEncounterSuspension":
        return BILInterface.HandleGetEncounterSuspension(this, e.params);

      case "HandleUpdateEncounterSuspension":
        return BILInterface.HandleUpdateEncounterSuspension(this, e.params);

      case "HandleRefreshTaskTracking":
        return BILInterface.HandleRefreshTaskTracking(this, e.params);

      case "HandlePostReceiptCode":
        return BILInterface.HandlePostReceiptCode(this, e.params);

      case "HandleGetReceiptCode":
        return BILInterface.HandleGetReceiptCode(this, e.params);

      case "GetBillPendingDetail":
        return BILInterface.GetBillPendingDetail(this, e.params);

      case "GetCommonBillTransaction":
        return BILInterface.GetCommonBillTransaction(this, e.params);

      case "HandlePrintMedicalFeeDetail":
        return BILInterface.HandlePrintMedicalFeeDetail(this, e.params);

      case "HandleReportPrintTrackingResult":
        return BILInterface.HandleReportPrintTrackingResult(this, e.params);

      case "GetFexClaimDetail":
        return BILInterface.GetFexClaimDetail(this, e.params);

      case "HandleUpdateFexClaim":
        return BILInterface.HandleUpdateFexClaim(this, e.params);

      case "GetInvoiceItemPackageApply":
        return BILInterface.GetInvoiceItemPackageApply(this, e.params);

      case "CreateInvoiceItemPackageApply":
        return BILInterface.CreateInvoiceItemPackageApply(this, e.params);

      case "GetPatientLanguage":
        return BILInterface.GetPatientLanguage(this, e.params);

      case "GetPatientCoverageList":
        return BILInterface.GetPatientCoverageList(this, e.params);

      // ----- CLM Interface
      case "GetARTransactions":
        return CLMInterface.GetARTransactions(this, e.params);
      case "GetEncounterList":
        return CLMInterface.GetEncounterList(this, e.params);
      case "HandleUploadPatientCoverage":
        return CLMInterface.HandleUploadPatientCoverage(this, e.params);
      // ----- HCU Interface
      case "HandleGetPurchaseOrderList":
        return HCUInterface.HandleGetPurchaseOrderList(this, e.params);
      // ----- PHR Interface
      // Med Admin
      case "MedAdminMainComponentDidMount":
        return MedAdminI.MedAdminMainComponentDidMount(this, e.params);
      case "MedAdminGetMedicationRecordSummary":
        return MedAdminI.MedAdminGetMedicationRecordSummary(this, e.params);
      case "MedAdminGetListMedAdminRecord":
        return MedAdminI.MedAdminGetListMedAdminRecord(this, e.params);
      case "MedAdminGetPatientByHN":
        return MedAdminI.MedAdminGetPatientByHN(this, e.params);
      case "MedAdminGetClinicalTermSet":
        return MedAdminI.MedAdminGetClinicalTermSet(this, e.params);
      case "MedAdminGetUserPermission":
        return MedAdminI.MedAdminGetUserPermission(this, e.params);
      case "MedAdminGetUserTokenize":
        return MedAdminI.MedAdminGetUserTokenize(this, e.params);
      case "MedAdminPostCreateMedAdminRecord":
        return MedAdminI.MedAdminPostCreateMedAdminRecord(this, e.params);
      case "MedAdminPutUpdateMedAdminRecord":
        return MedAdminI.MedAdminPutUpdateMedAdminRecord(this, e.params);
      case "MedAdminPutMedAdminRecord":
        return MedAdminI.MedAdminPutMedAdminRecord(this, e.params);
      case "MedAdminPutDrugOrderItemIdAddStdTimeManual":
        return MedAdminI.MedAdminPutDrugOrderItemIdAddStdTimeManual(this, e.params);
      case "MedAdminPutDrugOrderItemIdEditStdTimeManual":
        return MedAdminI.MedAdminPutDrugOrderItemIdEditStdTimeManual(this, e.params);
      case "MedAdminPutDrugOrderItemIdAdminDivision":
        return MedAdminI.MedAdminPutDrugOrderItemIdAdminDivision(this, e.params);
      case "MedAdminPostMedAdminRecord":
        return MedAdminI.MedAdminPostMedAdminRecord(this, e.params);
      case "MedAdminGetPrintMedRecord":
        return MedAdminI.MedAdminGetPrintMedRecord(this, e.params);
      case "MedAdminGetPatientInfo":
        return MedAdminI.MedAdminGetPatientInfo(this, e.params);
      // ADM
      case "GetListOrderDivision":
        return ADMInterface.GetListOrderDivision(this, e.params);

      case "GetListRoom":
        return ADMInterface.GetListRoom(this, e.params);

      case "PrintReportAdmitOrder":
        return ADMInterface.PrintReportAdmitOrder(this, e.params);

      case "GetListAdmitOrder":
        return ADMInterface.GetListAdmitOrder(this, e.params);

      case "PrintReportPostponeAdmitOrder":
        return ADMInterface.PrintReportPostponeAdmitOrder(this, e.params);

      case "HandleConsultListToWard":
        return ADMInterface.HandleConsultListToWard(this, e.params);

      // IMEInterface
      case "ListImagingResult":
        return IMEInterface.ListImagingResult(this, e.params);
      case "OpenPACS":
        return IMEInterface.OpenPACS(this, e.params);

      // VaccineUploadI
      case "GetVaccineInfo":
        return VaccineUploadI.GetVaccineInfo(this, e.params);

      case "ChooseVaccineFile":
        return VaccineUploadI.ChooseVaccineFile(this, e.params);

      case "UploadVaccineData":
        return VaccineUploadI.UploadData(this, e.params);

      case "GetVaccineDataList":
        return VaccineUploadI.GetDataList(this, e.params);

      case "GenerateVaccineUser":
        return VaccineUploadI.GenerateUser(this, e.params);

      case "CloseGenerateVaccineUserModal":
        return VaccineUploadI.CloseGenerateUserModal(this, e.params);

      case "HandleSelectAdmitEncounter":
        ADMInterface.HandleSelectAdmitEncounter(this, e.params);
        return;

      case "HandleNurseNote":
        PTMInterface.HandleNurseNote(this, e.params);
        return;

      case "handleNurseNoteReport":
        PTMInterface.handleNurseNoteReport(this, e.params);
        return;

      case "HandleGetVerbalHistory":
        PTMInterface.HandleGetVerbalHistory(this, e.params);
        return;

      case "HandleVerbalOrderAction":
        PTMInterface.HandleVerbalOrderAction(this, e.params);
        return;

      case "SaveVerbalOrder":
        PTMInterface.SaveVerbalOrder(this, e.params);
        return;

      case "HandleOPDOrderSummary":
        PTMInterface.HandleOPDOrderSummary(this, e.params);
        return;

      case "HandleEditOPDOrderSummary":
        PTMInterface.HandleEditOPDOrderSummary(this, e.params);
        return;

      case "HandleDeleteOPDOrderSummary":
        PTMInterface.HandleDeleteOPDOrderSummary(this, e.params);
        return;

      case "handleGetEncounterVitalSignList":
        PTMInterface.handleGetEncounterVitalSignList(this, e.params);
        return;
      case "HandleGetDeliverSupplyTokenization":
        DispensingOrderSupplyI.HandleGetDeliverSupplyTokenization(this, e.params);
        return;

      case "HandleGetORPostOperativeTokenization":
        PerioperativeNursingI.HandleGetORPostOperativeTokenization(this, e.params);
        return;

      case "GetChatChannelList":
        StaffChatI.GetChatChannelList(this, e.params);
        return;
      case "GetUnreadMessageCountStaff":
        StaffChatI.GetUnreadMessageCountStaff(this, e.params);
        return;
      case "GetUserChatSubscriptionList":
        StaffChatI.GetUserChatSubscriptionList(this, e.params);
        return;
      case "GetSearchMessageList":
        StaffChatI.GetSearchMessageList(this, e.params);
        return;

      case "CreateUpdateChatRoom":
        return StaffChatI.CreateUpdateChatRoom(this, e.params);
      // Auto gen from this point onward =====================================
      case "getWardType":
        return ADMI.getWardType(this, e.params);

      case "getRoomType":
        return ADMI.getRoomType(this, e.params);

      case "getAdmitStatusChoice":
        return ADMI.getAdmitStatusChoice(this, e.params);

      case "getAdmitOrder":
        return ADMI.getAdmitOrder(this, e.params);

      case "getAdmitOrderDetail":
        return ADMI.getAdmitOrderDetail(this, e.params);

      case "estimateAdmit":
        return ADMI.estimateAdmit(this, e.params);

      case "createAdmitOrder":
        return ADMI.createAdmitOrder(this, e.params);

      case "updateAdmitOrder":
        return ADMI.updateAdmitOrder(this, e.params);

      case "printReservation":
        return ADMI.printReservation(this, e.params);

      case "getAdmitDay":
        return ADMI.getAdmitDay(this, e.params);

      case "getAdmitOrderRoomItemStatus":
        return ADMI.getAdmitOrderRoomItemStatus(this, e.params);

      case "putAdmitOrderRoomItem":
        return ADMI.putAdmitOrderRoomItem(this, e.params);

      case "admService":
        return ADMI.admService(this, e.params);

      case "getAdverseReaction":
        return ADRI.getAdverseReaction(this, e.params);

      // case "getAdverseReactionList":
      //   return ADRI.getAdverseReactionList(this, e.params);

      // case "postAdverseReaction":
      //   return ADRI.postAdverseReaction(this, e.params);

      // case "putAdverseReaction":
      //   return ADRI.putAdverseReaction(this, e.params);

      case "getAnesthesiaQueue":
        return ANSI.getAnesthesiaQueue(this, e.params);

      case "getAnesthesiaQueueZoneChoice":
        return ANSI.getAnesthesiaQueueZoneChoice(this, e.params);

      case "getAnesthesiaOrderWithId":
        return ANSI.getAnesthesiaOrderWithId(this, e.params);

      case "getAnesthesiaConsultWithId":
        return ANSI.getAnesthesiaConsultWithId(this, e.params);

      case "putAnesthesiaOrderAction":
        return ANSI.putAnesthesiaOrderAction(this, e.params);

      case "putAnesthesiaConsultAction":
        return ANSI.putAnesthesiaConsultAction(this, e.params);

      case "getInvestigationResult":
        return ANSI.getInvestigationResult(this, e.params);

      case "getAnesthesiaData":
        return ANSI.getAnesthesiaData(this, e.params);

      case "getPreAnestheticAnesthesiaRecord":
        return ANSI.getPreAnestheticAnesthesiaRecord(this, e.params);

      case "getPreAnestheticAnesthesiaLog":
        return ANSI.getPreAnestheticAnesthesiaLog(this, e.params);

      case "printFormAns":
        return ANSI.printFormAns(this, e.params);

      case "postPreAnestheticAnesthesiaRecord":
        return ANSI.postPreAnestheticAnesthesiaRecord(this, e.params);

      case "putPreAnestheticAnesthesiaRecord":
        return ANSI.putPreAnestheticAnesthesiaRecord(this, e.params);

      case "getAnesthesiaGraph":
        return ANSI.getAnesthesiaGraph(this, e.params);

      case "getAgentTemplate":
        return ANSI.getAgentTemplate(this, e.params);

      case "getAgentByRecord":
        return ANSI.getAgentByRecord(this, e.params);

      case "getAgentItem":
        return ANSI.getAgentItem(this, e.params);

      case "putAgentItem":
        return ANSI.putAgentItem(this, e.params);

      case "postAnesthesiaAgent":
        return ANSI.postAnesthesiaAgent(this, e.params);

      case "getAnesthesiaEventMedication":
        return ANSI.getAnesthesiaEventMedication(this, e.params);

      case "getEventMedicationByRecord":
        return ANSI.getEventMedicationByRecord(this, e.params);

      case "postAnesthesiaEventMedication":
        return ANSI.postAnesthesiaEventMedication(this, e.params);

      case "putAnesthesiaEventMedication":
        return ANSI.putAnesthesiaEventMedication(this, e.params);

      case "getMonitorTemplate":
        return ANSI.getMonitorTemplate(this, e.params);

      case "getMonitorByRecord":
        return ANSI.getMonitorByRecord(this, e.params);

      case "getMonitorItem":
        return ANSI.getMonitorItem(this, e.params);

      case "putMonitorItem":
        return ANSI.putMonitorItem(this, e.params);

      case "postAnesthesiaMonitor":
        return ANSI.postAnesthesiaMonitor(this, e.params);

      case "getIntakeOutputTemplate":
        return ANSI.getIntakeOutputTemplate(this, e.params);

      case "getIntakeByRecord":
        return ANSI.getIntakeByRecord(this, e.params);

      case "postAnesthesiaIntake":
        return ANSI.postAnesthesiaIntake(this, e.params);

      case "getIntakeItem":
        return ANSI.getIntakeItem(this, e.params);

      case "putIntakeItem":
        return ANSI.putIntakeItem(this, e.params);

      case "getOutputByRecord":
        return ANSI.getOutputByRecord(this, e.params);

      case "postAnesthesiaOutput":
        return ANSI.postAnesthesiaOutput(this, e.params);

      case "getOutputItem":
        return ANSI.getOutputItem(this, e.params);

      case "putOutputItem":
        return ANSI.putOutputItem(this, e.params);

      case "getAnesthesiaBillWithId":
        return ANSI.getAnesthesiaBillWithId(this, e.params);

      case "getAnesthesiaTemplateList":
        return ANSI.getAnesthesiaTemplateList(this, e.params);

      case "getAnesthesiaTemplateWithId":
        return ANSI.getAnesthesiaTemplateWithId(this, e.params);

      case "getAnesthesiaBillLog":
        return ANSI.getAnesthesiaBillLog(this, e.params);

      case "printAnesthesiaBill":
        return ANSI.printAnesthesiaBill(this, e.params);

      case "postAnesthesiaBill":
        return ANSI.postAnesthesiaBill(this, e.params);

      case "putAnesthesiaBill":
        return ANSI.putAnesthesiaBill(this, e.params);

      case "getAppointmentWithId":
        return APPI.getAppointmentWithId(this, e.params);

      case "deleteAppointmentWithId":
        return APPI.deleteAppointmentWithId(this, e.params);

      case "cancelAppointment":
        return APPI.cancelAppointment(this, e.params);

      case "updateAppointmentWithId":
        return APPI.updateAppointmentWithId(this, e.params);

      case "postponeAppointmentWithId":
        return APPI.postponeAppointmentWithId(this, e.params);

      case "getDoctorWorkAtDivision":
        return APPI.getDoctorWorkAtDivision(this, e.params);

      case "convertDoctorScheduleToChoice":
        return APPI.convertDoctorScheduleToChoice(this, e.params);

      case "getAppointmentOrder":
        return APPI.getAppointmentOrder(this, e.params);

      case "getAppointmentOrderRaw":
        return APPI.getAppointmentOrderRaw(this, e.params);

      case "getDoctorNote":
        return APPI.getDoctorNote(this, e.params);

      case "getDoctorNoteGroup":
        return APPI.getDoctorNoteGroup(this, e.params);

      case "getDoctorScheduleAppointment":
        return APPI.getDoctorScheduleAppointment(this, e.params);

      case "postAppointmentRequest":
        return APPI.postAppointmentRequest(this, e.params);

      case "printAppointment":
        return APPI.printAppointment(this, e.params);

      case "_convertToAppointmentModel":
        return APPI._convertToAppointmentModel(this, e.params);

      case "getQueueList":
        return BILI.getQueueList(this, e.params);

      case "getInvoice":
        return BILI.getInvoice(this, e.params);

      case "getInvoiceDetail":
        return BILI.getInvoiceDetail(this, e.params);

      case "postInvoice":
        return BILI.postInvoice(this, e.params);

      case "getInvoiceItemByItem":
        return BILI.getInvoiceItemByItem(this, e.params);

      case "postPaymentParameters":
        return BILI.postPaymentParameters(this, e.params);

      case "getReceipt":
        return BILI.getReceipt(this, e.params);

      case "getBloodGroupWithPatient":
        return BLBI.getBloodGroupWithPatient(this, e.params);

      case "getBloodTransfusion":
        return BLBI.getBloodTransfusion(this, e.params);

      case "getEncounterStatus":
        return CoreI.getEncounterStatus(this, e.params);

      // Multiple possible modules Core, PRX. Pick the first.
      case "getEncounter":
        return CoreI.getEncounter(this, e.params);

      case "putEncounter":
        return CoreI.putEncounter(this, e.params);

      case "getNationalities":
        return CoreI.getNationalities(this, e.params);

      case "getDistrict":
        return CoreI.getDistrict(this, e.params);

      case "getCity":
        return CoreI.getCity(this, e.params);

      case "getListPreName":
        return CoreI.getListPreName(this, e.params);

      case "getProvince":
        return CoreI.getProvince(this, e.params);

      case "getCountry":
        return CoreI.getCountry(this, e.params);

      case "getReligion":
        return CoreI.getReligion(this, e.params);

      case "getConstance":
        return CoreI.getConstance(this, e.params);

      case "getChoices":
        return CoreI.getChoices(this, e.params);

      case "getChoicesEpisodeType":
        return CoreI.getChoicesEpisodeType(this, e.params);

      case "createEpisode":
        return CoreI.createEpisode(this, e.params);

      case "getEpisode":
        return CoreI.getEpisode(this, e.params);

      case "getEpisodeFromEN":
        return CoreI.getEpisodeFromEN(this, e.params);

      case "getEpisodeList":
        return CoreI.getEpisodeList(this, e.params);

      case "changeENToEpisode":
        return CoreI.changeENToEpisode(this, e.params);

      case "cancelENFromEpisode":
        return CoreI.cancelENFromEpisode(this, e.params);

      case "getENListFromEpisode":
        return CoreI.getENListFromEpisode(this, e.params);

      case "getDoctorList":
        return CoreI.getDoctorList(this, e.params);

      case "getDoctorNameCodeList":
        return CoreI.getDoctorNameCodeList(this, e.params);

      case "getSpeciality":
        return CoreI.getSpeciality(this, e.params);

      case "getProgressionCycleByEmr":
        return CoreI.getProgressionCycleByEmr(this, e.params);

      case "getProgressionCycle":
        return CoreI.getProgressionCycle(this, e.params);

      case "getClinicalTermSet":
        return CoreI.getClinicalTermSet(this, e.params);

      case "getClinicalTerm":
        return CoreI.getClinicalTerm(this, e.params);

      case "getBarcodePrefix":
        return CoreI.getBarcodePrefix(this, e.params);

      case "getDefaultBarcode":
        return CoreI.getDefaultBarcode(this, e.params);

      case "getRejectedOrder":
        return CoreI.getRejectedOrder(this, e.params);

      case "getCleaningChoices":
        return CoreI.getCleaningChoices(this, e.params);

      case "getCareer":
        return CoreI.getCareer(this, e.params);

      case "getICD10ListWithCode":
        return CoreI.getICD10ListWithCode(this, e.params);

      case "getICD9CMListWithCode":
        return CoreI.getICD9CMListWithCode(this, e.params);

      case "getICD10ListWithTerm":
        return CoreI.getICD10ListWithTerm(this, e.params);

      case "getICD9CMListWithTerm":
        return CoreI.getICD9CMListWithTerm(this, e.params);

      case "getICD10ListWithMedTerm":
        return CoreI.getICD10ListWithMedTerm(this, e.params);

      case "getICD9CMListWithMedTerm":
        return CoreI.getICD9CMListWithMedTerm(this, e.params);

      case "getMiscellaneous":
        return CoreI.getMiscellaneous(this, e.params);

      case "getICD10SublevelListWithCode":
        return CoreI.getICD10SublevelListWithCode(this, e.params);

      case "getICD9CMSublevelListWithCode":
        return CoreI.getICD9CMSublevelListWithCode(this, e.params);

      case "postMiscellaneousOrderPreview":
        return CoreI.postMiscellaneousOrderPreview(this, e.params);

      case "getMiscellaneousOrder":
        return CoreI.getMiscellaneousOrder(this, e.params);

      case "deleteMiscellaneousOrder":
        return CoreI.deleteMiscellaneousOrder(this, e.params);

      case "patchMiscellaneousOrder":
        return CoreI.patchMiscellaneousOrder(this, e.params);

      case "postMiscellaneousOrderCreate":
        return CoreI.postMiscellaneousOrderCreate(this, e.params);

      case "getDivision":
        return CoreI.getDivision(this, e.params);

      case "getDivisionDetail":
        return CoreI.getDivisionDetail(this, e.params);

      case "getDivisionForOPD":
        return CoreI.getDivisionForOPD(this, e.params);

      case "getDivisionChoice":
        return CoreI.getDivisionChoice(this, e.params);

      case "getDivisionForOPDChoice":
        return CoreI.getDivisionForOPDChoice(this, e.params);

      case "getDoctorChoice":
        return CoreI.getDoctorChoice(this, e.params);

      case "getCurrentDoctor":
        return CoreI.getCurrentDoctor(this, e.params);

      case "deleteProgressionCycle":
        return CoreI.deleteProgressionCycle(this, e.params);

      case "postProgressionCycle":
        return CoreI.postProgressionCycle(this, e.params);

      case "putProgressionCycle":
        return CoreI.putProgressionCycle(this, e.params);

      case "getEncounterSearch":
        return CoreI.getEncounterSearch(this, e.params);

      case "getDocumentType":
        return CoreI.getDocumentType(this, e.params);

      case "getDocumentCategory":
        return CoreI.getDocumentCategory(this, e.params);

      case "getDoctor":
        return CoreI.getDoctor(this, e.params);

      case "getScannedDocument":
        return CoreI.getScannedDocument(this, e.params);

      case "putScannedDocumentUpdateSecret":
        return CoreI.putScannedDocumentUpdateSecret(this, e.params);

      case "patchScannedDocument":
        return CoreI.patchScannedDocument(this, e.params);

      case "postScannedDocument":
        return CoreI.postScannedDocument(this, e.params);

      case "getChoicesCheckoutCause":
        return CoreI.getChoicesCheckoutCause(this, e.params);

      case "getZoneOfDivision":
        return CoreI.getZoneOfDivision(this, e.params);

      case "changeZone":
        return CoreI.changeZone(this, e.params);

      case "getEncounterSearchDetail":
        return CoreI.getEncounterSearchDetail(this, e.params);

      case "getTaskTrackingResult":
        return CoreI.getTaskTrackingResult(this, e.params);

      case "getChoicesBillTransactionTypeIPD":
        return CoreI.getChoicesBillTransactionTypeIPD(this, e.params);

      case "getChoicesAdmitOrderType":
        return CoreI.getChoicesAdmitOrderType(this, e.params);

      case "getEncounterPatient":
        return CoreI.getEncounterPatient(this, e.params);

      case "getProduct":
        return CoreI.getProduct(this, e.params);

      case "postMiscellaneousOrderListCreate":
        return CoreI.postMiscellaneousOrderListCreate(this, e.params);

      case "postPatientAddress":
        return CoreI.postPatientAddress(this, e.params);

      case "getPatientAddressList":
        return CoreI.getPatientAddressList(this, e.params);

      case "getPatientAddressDetail":
        return CoreI.getPatientAddressDetail(this, e.params);

      case "putPatientAddressDetail":
        return CoreI.putPatientAddressDetail(this, e.params);

      case "deletePatientAddressDetail":
        return CoreI.deletePatientAddressDetail(this, e.params);

      case "getChoicesANSOrderStatus":
        return CoreI.getChoicesANSOrderStatus(this, e.params);

      case "getDoctorOrderDetail":
        return CoreI.getDoctorOrderDetail(this, e.params);

      case "getEncounterPatientOptimized":
        return CoreI.getEncounterPatientOptimized(this, e.params);

      case "getPatientDiagnosisList":
        return CoreI.getPatientDiagnosisList(this, e.params);

      case "getChoicesSupplyOrderMode":
        return CoreI.getChoicesSupplyOrderMode(this, e.params);

      case "getChoicesSupplyOrderEligibility":
        return CoreI.getChoicesSupplyOrderEligibility(this, e.params);

      case "getOperatingOrderDetailByEmr":
        return CoreI.getOperatingOrderDetailByEmr(this, e.params);

      case "getDoctorDetail":
        return CoreI.getDoctorDetail(this, e.params);

      case "getPatientDetailView":
        return CoreI.getPatientDetailView(this, e.params);

      case "getDiagnosisMedicalRecordDetail":
        return CoreI.getDiagnosisMedicalRecordDetail(this, e.params);

      case "getTreatmentDetail":
        return CoreI.getTreatmentDetail(this, e.params);

      case "getHospitalNameForPrintList":
        return CoreI.getHospitalNameForPrintList(this, e.params);

      case "getPatientAllergyForPrintList":
        return CoreI.getPatientAllergyForPrintList(this, e.params);

      case "checkDoctorFeeOrderWithEMR":
        return DFCI.checkDoctorFeeOrderWithEMR(this, e.params);

      case "getAdmissionForm":
        return DPII.getAdmissionForm(this, e.params);

      case "getPreNameForPrintList":
        return CoreI.getPreNameForPrintList(this, e.params);

      case "getAdmitOrderForPrintList":
        return CoreI.getAdmitOrderForPrintList(this, e.params);

      case "putAdmissionForm":
        return DPII.putAdmissionForm(this, e.params);

      case "createProgressionNote":
        return DPII.createProgressionNote(this, e.params);

      case "putProgressNote":
        return DPII.putProgressNote(this, e.params);

      case "putProgressNoteById":
        return DPII.putProgressNoteById(this, e.params);

      case "dpoService":
        return DPOI.dpoService(this, e.params);

      case "getMedicalRecord":
        return DPOI.getMedicalRecord(this, e.params);

      case "discharged":
        return DPOI.discharged(this, e.params);

      case "cancelCheckIn":
        return DPOI.cancelCheckIn(this, e.params);

      case "getDiagnosis":
        return DPOI.getDiagnosis(this, e.params);

      case "updateDiagnosis":
        return DPOI.updateDiagnosis(this, e.params);

      case "getPatientDiagnosis":
        return DPOI.getPatientDiagnosis(this, e.params);

      case "getClinialType":
        return DPOI.getClinialType(this, e.params);

      case "getDiagnosisTemplate":
        return DPOI.getDiagnosisTemplate(this, e.params);

      case "getDoctorCertificateType":
        return DPOI.getDoctorCertificateType(this, e.params);

      case "postDoctorCertificate":
        return DPOI.postDoctorCertificate(this, e.params);

      case "getDiagnosisSummary":
        return DPOI.getDiagnosisSummary(this, e.params);

      case "getDoctorCertificatesPrintlog":
        return DPOI.getDoctorCertificatesPrintlog(this, e.params);

      case "getDoctorOrder":
        return DPOI.getDoctorOrder(this, e.params);

      case "putDoctorOrder":
        return DPOI.putDoctorOrder(this, e.params);

      case "postDoctorConsultOrder":
        return DPOI.postDoctorConsultOrder(this, e.params);

      case "getDoctorConsultOrderWithId":
        return DPOI.getDoctorConsultOrderWithId(this, e.params);

      case "updateDoctorConsultOrderWithId":
        return DPOI.updateDoctorConsultOrderWithId(this, e.params);

      case "getMedicalRecordGallery":
        return DPOI.getMedicalRecordGallery(this, e.params);

      case "postMedicalRecordGallery":
        return DPOI.postMedicalRecordGallery(this, e.params);

      case "putMedicalRecordPicture":
        return DPOI.putMedicalRecordPicture(this, e.params);

      case "getTemplateGalleryImage":
        return DPOI.getTemplateGalleryImage(this, e.params);

      case "_convertToConsultOrderModel":
        return DPOI._convertToConsultOrderModel(this, e.params);

      case "estimateDoctorOrder":
        return DPOI.estimateDoctorOrder(this, e.params);

      // case "getMedicalRecordEmrDetail":
      //   return DPOI.getMedicalRecordEmrDetail(this, e.params);

      case "getPatientHistoryEMR":
        return DPOI.getPatientHistoryEMR(this, e.params);

      case "getSensitiveNoteEMR":
        return DPOI.getSensitiveNoteEMR(this, e.params);

      case "postSensitiveNoteEMR":
        return DPOI.postSensitiveNoteEMR(this, e.params);

      case "putSensitiveNoteEMR":
        return DPOI.putSensitiveNoteEMR(this, e.params);

      case "putPatientHistoryEMR":
        return DPOI.putPatientHistoryEMR(this, e.params);

      // case "getPhysicalExamOrgan":
      //   return DPOI.getPhysicalExamOrgan(this, e.params);

      // case "postPatientScreenNew":
      //   return DPOI.postPatientScreenNew(this, e.params);

      // case "putPatientScreenUpdate":
      //   return DPOI.putPatientScreenUpdate(this, e.params);

      case "patchPatientDiagnosis":
        return DPOI.patchPatientDiagnosis(this, e.params);

      case "getProviderInfo":
        return HRMI.getProviderInfo(this, e.params);

      case "getRoomList":
        return ICUI.getRoomList(this, e.params);

      case "getEventTypeChoices":
        return ICUI.getEventTypeChoices(this, e.params);

      case "getEncounterDoctorOrder":
        return ICUI.getEncounterDoctorOrder(this, e.params);

      case "putMonitorEvent":
        return ICUI.putMonitorEvent(this, e.params);

      case "getMonitorEvent":
        return ICUI.getMonitorEvent(this, e.params);

      case "deleteMonitorEvent":
        return ICUI.deleteMonitorEvent(this, e.params);

      case "getMedicationsRecordChoices":
        return ICUI.getMedicationsRecordChoices(this, e.params);

      case "createMedicationsRecordChoices":
        return ICUI.createMedicationsRecordChoices(this, e.params);

      case "getFlowSheetVitalSigns":
        return ICUI.getFlowSheetVitalSigns(this, e.params);

      case "getFlowSheetMedication":
        return ICUI.getFlowSheetMedication(this, e.params);

      case "getFlowSheetVentilater":
        return ICUI.getFlowSheetVentilater(this, e.params);

      case "getFlowSheetArterial":
        return ICUI.getFlowSheetArterial(this, e.params);

      case "getFlowSheetOverALLBalance":
        return ICUI.getFlowSheetOverALLBalance(this, e.params);

      case "getGraphicSheetOverAllBalance":
        return ICUI.getGraphicSheetOverAllBalance(this, e.params);

      case "getIcuIntakeOutputDetail":
        return ICUI.getIcuIntakeOutputDetail(this, e.params);

      case "getIntakeOutputChoise":
        return ICUI.getIntakeOutputChoise(this, e.params);

      // Multiple possible modules ICU, PTM. Pick the first.
      case "getIntakeOutput":
        return ICUI.getIntakeOutput(this, e.params);

      case "getFlowsheetDiet":
        return ICUI.getFlowsheetDiet(this, e.params);

      case "putMedicationRecordItem":
        return ICUI.putMedicationRecordItem(this, e.params);

      case "postMedicationRecordItem":
        return ICUI.postMedicationRecordItem(this, e.params);

      case "postGraphicSheetPrint":
        return ICUI.postGraphicSheetPrint(this, e.params);

      case "postFlowSheetPrint":
        return ICUI.postFlowSheetPrint(this, e.params);

      case "postIntakeOutputRecordItemCreateUpdate":
        return ICUI.postIntakeOutputRecordItemCreateUpdate(this, e.params);

      case "getPeriodWorkerTimeSlot":
        return ICUI.getPeriodWorkerTimeSlot(this, e.params);

      case "icuService":
        return ICUI.icuService(this, e.params);

      case "getImagingExamResult":
        return IMEI.getImagingExamResult(this, e.params);

      case "getSentClaimChoices":
        return INFI.getSentClaimChoices(this, e.params);

      case "getChoicesAdmitSource":
        return INFI.getChoicesAdmitSource(this, e.params);

      case "getChoicesPreDischargeType":
        return INFI.getChoicesPreDischargeType(this, e.params);

      case "getChoicesDischargeType":
        return INFI.getChoicesDischargeType(this, e.params);

      case "getChoicesDivision":
        return INFI.getChoicesDivision(this, e.params);

      case "getChoicesSentClaimStatus":
        return INFI.getChoicesSentClaimStatus(this, e.params);

      case "createARTransaction":
        return INFI.createARTransaction(this, e.params);

      case "getARTransaction":
        return INFI.getARTransaction(this, e.params);

      case "getCoveragePayerSentClaimGroup":
        return INFI.getCoveragePayerSentClaimGroup(this, e.params);

      case "getBillTransactionIPD":
        return INFI.getBillTransactionIPD(this, e.params);

      case "getBillTransactionIPDWithId":
        return INFI.getBillTransactionIPDWithId(this, e.params);

      case "updateBillTransactionIPDWithId":
        return INFI.updateBillTransactionIPDWithId(this, e.params);

      case "updateIPDDiagnosisSentClaimWithId":
        return INFI.updateIPDDiagnosisSentClaimWithId(this, e.params);

      case "updateIPDProcedureSentClaimWithId":
        return INFI.updateIPDProcedureSentClaimWithId(this, e.params);

      case "putBillTransactionUpdateStatus":
        return INFI.putBillTransactionUpdateStatus(this, e.params);

      case "putBillTransaction":
        return INFI.putBillTransaction(this, e.params);

      case "getBillTransactionIPDSummary":
        return INFI.getBillTransactionIPDSummary(this, e.params);

      case "getIPDDiagnosisSentClaim":
        return INFI.getIPDDiagnosisSentClaim(this, e.params);

      case "getIPDProcedureSentClaim":
        return INFI.getIPDProcedureSentClaim(this, e.params);

      case "getBillTransactionIPDItem":
        return INFI.getBillTransactionIPDItem(this, e.params);

      case "doGenerateSentClaimDataIPD":
        return INFI.doGenerateSentClaimDataIPD(this, e.params);

      case "arTaskTrackingIPDGenerateSentClaim":
        return INFI.arTaskTrackingIPDGenerateSentClaim(this, e.params);

      case "arTaskTrackingIPDUpdateSentClaim":
        return INFI.arTaskTrackingIPDUpdateSentClaim(this, e.params);

      case "updateSentClaimDataIPDFromARId":
        return INFI.updateSentClaimDataIPDFromARId(this, e.params);

      case "generateSentClaimIPDFileFromARId":
        return INFI.generateSentClaimIPDFileFromARId(this, e.params);

      case "importResponseFileIPD":
        return INFI.importResponseFileIPD(this, e.params);

      case "getSentClaimTransactionIPD":
        return INFI.getSentClaimTransactionIPD(this, e.params);

      case "getEncounterFromARId":
        return INFI.getEncounterFromARId(this, e.params);

      case "getCenterLabDivision":
        return LABI.getCenterLabDivision(this, e.params);

      case "getCentalLabTestResultFitlerList":
        return LABI.getCentalLabTestResultFitlerList(this, e.params);

      case "getCentalLabTestResultComparable":
        return LABI.getCentalLabTestResultComparable(this, e.params);

      case "getInterfaceSummaryReportDetail":
        return LABI.getInterfaceSummaryReportDetail(this, e.params);

      case "getCentralLabResultList":
        return LABI.getCentralLabResultList(this, e.params);

      case "getSearchQueue":
        return LRMI.getSearchQueue(this, e.params);

      case "getANCRecordList":
        return LRMI.getANCRecordList(this, e.params);

      case "getPreviousPregnancy":
        return LRMI.getPreviousPregnancy(this, e.params);

      case "getANCSummary":
        return LRMI.getANCSummary(this, e.params);

      case "getANCItem":
        return LRMI.getANCItem(this, e.params);

      case "getANCLab":
        return LRMI.getANCLab(this, e.params);

      case "getFetalLie":
        return LRMI.getFetalLie(this, e.params);

      case "postLabInvestigation":
        return LRMI.postLabInvestigation(this, e.params);

      case "getLRLab":
        return LRMI.getLRLab(this, e.params);

      case "postSaveANCRecord":
        return LRMI.postSaveANCRecord(this, e.params);

      case "putLabInvestigation":
        return LRMI.putLabInvestigation(this, e.params);

      case "getBirthCertificateApplication":
        return LRMI.getBirthCertificateApplication(this, e.params);

      case "postBirthCertificateApplication":
        return LRMI.postBirthCertificateApplication(this, e.params);

      case "putBirthCertificateApplication":
        return LRMI.putBirthCertificateApplication(this, e.params);

      case "putPrintBirthCertificateApplication":
        return LRMI.putPrintBirthCertificateApplication(this, e.params);

      case "getObstetricBasicInfo":
        return LRMI.getObstetricBasicInfo(this, e.params);

      case "postObstetricBasicInfo":
        return LRMI.postObstetricBasicInfo(this, e.params);

      case "putObstetricBasicInfo":
        return LRMI.putObstetricBasicInfo(this, e.params);

      case "putPrintObstetric":
        return LRMI.putPrintObstetric(this, e.params);

      case "getObstetricLabour":
        return LRMI.getObstetricLabour(this, e.params);

      case "postObstetricLabour":
        return LRMI.postObstetricLabour(this, e.params);

      case "putObstetricLabour":
        return LRMI.putObstetricLabour(this, e.params);

      case "getObstetricInfant":
        return LRMI.getObstetricInfant(this, e.params);

      case "postObstetricInfant":
        return LRMI.postObstetricInfant(this, e.params);

      case "putObstetricInfant":
        return LRMI.putObstetricInfant(this, e.params);

      case "getObstetricPostLabor":
        return LRMI.getObstetricPostLabor(this, e.params);

      case "postObstetricPostLabour":
        return LRMI.postObstetricPostLabour(this, e.params);

      case "putObstetricPostLabour":
        return LRMI.putObstetricPostLabour(this, e.params);

      case "getEDC":
        return LRMI.getEDC(this, e.params);

      case "postNewborn":
        return LRMI.postNewborn(this, e.params);

      case "getCheckOutChoice":
        return LRMI.getCheckOutChoice(this, e.params);

      case "putLRCheckOut":
        return LRMI.putLRCheckOut(this, e.params);

      case "printANCRecordByEncounter":
        return LRMI.printANCRecordByEncounter(this, e.params);

      case "getPrintAPI":
        return MixInI.getPrintAPI(this, e.params);

      case "getRegisterByTokenFacebook":
        return MixInI.getRegisterByTokenFacebook(this, e.params);

      case "checkManufacturer":
        return MSDInterface.checkManufacturer(this, e.params);

      case "checkUserTokenize":
        return MSDInterface.checkUserTokenize(this, e.params);

      case "getSupplyDeliveryAction":
        return MSDInterface.getSupplyDeliveryAction(this, e.params);

      // Multiple possible modules MSG, PRX. Pick the first.
      case "getLatestChatChannel":
        return MSGI.getLatestChatChannel(this, e.params);

      // Multiple possible modules MSG, PRX. Pick the first.
      case "getChatChannelMessageList":
        return MSGI.getChatChannelMessageList(this, e.params);

      case "getChatChannelMessageListMSG":
        return MSGI.getChatChannelMessageListMSG(this, e.params);

      // Multiple possible modules MSG, PRX. Pick the first.
      case "getMessageFromURL":
        return MSGI.getMessageFromURL(this, e.params);

      // Multiple possible modules MSG, PRX. Pick the first.
      case "postChatChannelMessage":
        return MSGI.postChatChannelMessage(this, e.params);

      case "postChatChannelMessageMSG":
        return MSGI.postChatChannelMessageMSG(this, e.params);

      case "postReadMessage":
        return MSGI.postReadMessage(this, e.params);

      case "postUpdateSubscription":
        return MSGI.postUpdateSubscription(this, e.params);

      // ORMInterface
      case "GetCheckoutMaster":
        return ORMInterface.GetCheckoutMaster(this);
      case "GetDischargeMaster":
        return ORMInterface.GetDischargeMaster(this);
      case "ORCheckout":
        return ORMInterface.ORCheckout(this, e.params);
      case "ORDischarge":
        return ORMInterface.ORDischarge(this, e.params);
      case "ORConfirmCompensation":
        return ORMInterface.ORConfirmCompensation(this, e.params);
      case "ORConfirmAdmit":
        return ORMInterface.ORConfirmAdmit(this, e.params);
      case "ORCancelDischarge":
        return ORMInterface.ORCancelDischarge(this, e.params);
      case "GetOROrderByID":
        return ORMInterface.GetOROrderByID(this, e.params);
      case "SearchORItemList":
        return ORMInterface.SearchORItemList(this, e.params);

      // ORMI
      case "getOperatingOrder":
        return ORMI.getOperatingOrder(this, e.params);

      case "postDeliverOperatingAppointment":
        return ORMI.postDeliverOperatingAppointment(this, e.params);

      case "getOrLocation":
        return ORMI.getOrLocation(this, e.params);

      case "getOperativeImage":
        return ORMI.getOperativeImage(this, e.params);

      case "getOperatingProcedureSummary":
        return ORMI.getOperatingProcedureSummary(this, e.params);

      case "printOperativeNote":
        return ORMI.printOperativeNote(this, e.params);

      case "newPRXService":
        return PRXI.newPRXService(this, e.params);

      case "getDiagRule":
        return PRXI.getDiagRule(this, e.params);

      case "getDiagFormClassify":
        return PRXI.getDiagFormClassify(this, e.params);

      case "getDiagForm":
        return PRXI.getDiagForm(this, e.params);

      case "getDiagFormDetail":
        return PRXI.getDiagFormDetail(this, e.params);

      case "getDivisionHasUser":
        return PRXI.getDivisionHasUser(this, e.params);

      case "getDivisionProfile":
        return PRXI.getDivisionProfile(this, e.params);

      case "createDivisionHasUser":
        return PRXI.createDivisionHasUser(this, e.params);

      case "getDiagRuleDetail":
        return PRXI.getDiagRuleDetail(this, e.params);

      case "postDiagRule":
        return PRXI.postDiagRule(this, e.params);

      case "postTuhSetPatientHn":
        return PRXI.postTuhSetPatientHn(this, e.params);

      case "postListPatientFromDiagForm":
        return PRXI.postListPatientFromDiagForm(this, e.params);

      case "postListPatientFromPatient":
        return PRXI.postListPatientFromPatient(this, e.params);

      case "getProxyPatientHasDivisionNotFollow":
        return PRXI.getProxyPatientHasDivisionNotFollow(this, e.params);

      case "getProxyPatientHasDivisionPatientChat":
        return PRXI.getProxyPatientHasDivisionPatientChat(this, e.params);

      case "patchDiagRuleDetail":
        return PRXI.patchDiagRuleDetail(this, e.params);

      case "patchDiagRuleName":
        return PRXI.patchDiagRuleName(this, e.params);

      case "patchDiagRulePublish":
        return PRXI.patchDiagRulePublish(this, e.params);

      case "patchDiagForm":
        return PRXI.patchDiagForm(this, e.params);

      case "getV2EncounterTriage":
        return PRXI.getV2EncounterTriage(this, e.params);

      case "postDiagFormMonitor":
        return PRXI.postDiagFormMonitor(this, e.params);

      case "patchDiagRuleActive":
        return PRXI.patchDiagRuleActive(this, e.params);

      case "getPublishedDiagRule":
        return PRXI.getPublishedDiagRule(this, e.params);

      case "postPublishedDiagRule":
        return PRXI.postPublishedDiagRule(this, e.params);

      case "patchPublishedDiagRule":
        return PRXI.patchPublishedDiagRule(this, e.params);

      case "getTriageLevelClassify":
        return PRXI.getTriageLevelClassify(this, e.params);

      case "getClassifyUser":
        return PRXI.getClassifyUser(this, e.params);

      case "getMasterTriageLevelClassify":
        return PRXI.getMasterTriageLevelClassify(this, e.params);

      case "getEncounterTriage":
        return PRXI.getEncounterTriage(this, e.params);

      case "geNurseNoteList":
        return PRXI.geNurseNoteList(this, e.params);

      case "geProgressNoteList":
        return PRXI.geProgressNoteList(this, e.params);

      case "getChannelDetail":
        return PRXI.getChannelDetail(this, e.params);

      case "geNurseNoteEncounter":
        return PRXI.geNurseNoteEncounter(this, e.params);

      case "getProxyPatient":
        return PRXI.getProxyPatient(this, e.params);

      case "getProxyPatientAllergy":
        return PRXI.getProxyPatientAllergy(this, e.params);

      case "getProxyPatientList":
        return PRXI.getProxyPatientList(this, e.params);

      case "patchProxyPatientHasDivision":
        return PRXI.patchProxyPatientHasDivision(this, e.params);

      case "getProxyPatientHasDivisionAdmin":
        return PRXI.getProxyPatientHasDivisionAdmin(this, e.params);

      case "patchProxyPatientHasDivisionAdmin":
        return PRXI.patchProxyPatientHasDivisionAdmin(this, e.params);

      case "patchEncounterReclassify":
        return PRXI.patchEncounterReclassify(this, e.params);

      case "getChatChannel":
        return PRXI.getChatChannel(this, e.params);

      case "getProxyPatientHasDivisionIdAdmin":
        return PRXI.getProxyPatientHasDivisionIdAdmin(this, e.params);

      case "getTuhUsersList":
        return PRXI.getTuhUsersList(this, e.params);

      case "getProxyPatientHasDivision":
        return PRXI.getProxyPatientHasDivision(this, e.params);

      case "getPatient":
        return PRXI.getPatient(this, e.params);

      case "patchPatient":
        return PRXI.patchPatient(this, e.params);

      case "postEncounterPatientAppointment":
        return PRXI.postEncounterPatientAppointment(this, e.params);

      case "getDoctorWorkSchedule":
        return PRXI.getDoctorWorkSchedule(this, e.params);

      case "getAvailableDivisionServiceBlock":
        return PRXI.getAvailableDivisionServiceBlock(this, e.params);

      case "postSelectDivisionServiceBlock":
        return PRXI.postSelectDivisionServiceBlock(this, e.params);

      case "postRequestChangeDivisionServiceBlock":
        return PRXI.postRequestChangeDivisionServiceBlock(this, e.params);

      case "getEncounterTriageDetail":
        return PRXI.getEncounterTriageDetail(this, e.params);

      case "getPatientQueue":
        return PRXI.getPatientQueue(this, e.params);

      case "getAppointedPatientQueue":
        return PRXI.getAppointedPatientQueue(this, e.params);

      case "getShippingAddress":
        return PRXI.getShippingAddress(this, e.params);

      case "postShippingAddress":
        return PRXI.postShippingAddress(this, e.params);

      case "getInitPatientAddress":
        return PRXI.getInitPatientAddress(this, e.params);

      case "getDashboard":
        return PRXI.getDashboard(this, e.params);

      case "getOfficialAccountDashboard":
        return PRXI.getOfficialAccountDashboard(this, e.params);

      // Remove Duplicate
      // case "getDashboardAppointment":
      //   return PRXI.getDashboardAppointment(this, e.params);

      case "getResolveChatChannel":
        return PRXI.getResolveChatChannel(this, e.params);

      case "postRequestOA":
        return PRXI.postRequestOA(this, e.params);

      case "getListOA":
        return PRXI.getListOA(this, e.params);

      case "postFollowOA":
        return PRXI.postFollowOA(this, e.params);

      case "getOAInviteLink":
        return PRXI.getOAInviteLink(this, e.params);

      case "postCreatejoinOARequest":
        return PRXI.postCreatejoinOARequest(this, e.params);

      case "getListJoinOARequest":
        return PRXI.getListJoinOARequest(this, e.params);

      case "postApproveJoinOARequest":
        return PRXI.postApproveJoinOARequest(this, e.params);

      case "postCreateUpdateSchedule":
        return PRXI.postCreateUpdateSchedule(this, e.params);

      case "postPatientFromDiagRule":
        return PRXI.postPatientFromDiagRule(this, e.params);

      case "deleteJoinOARequest":
        return PRXI.deleteJoinOARequest(this, e.params);

      case "deleteDivisionHasUserOA":
        return PRXI.deleteDivisionHasUserOA(this, e.params);

      case "getListDivisionHasUserOA":
        return PRXI.getListDivisionHasUserOA(this, e.params);

      case "getListAvailabelTraigeLevel":
        return PRXI.getListAvailabelTraigeLevel(this, e.params);

      case "getListUserRoleOA":
        return PRXI.getListUserRoleOA(this, e.params);

      case "getOADivisionProfile":
        return PRXI.getOADivisionProfile(this, e.params);

      case "getListOARequestCheck":
        return PRXI.getListOARequestCheck(this, e.params);

      case "patchDivisionHasUserOA":
        return PRXI.patchDivisionHasUserOA(this, e.params);

      case "patchOADivisionProfile":
        return PRXI.patchOADivisionProfile(this, e.params);

      case "getOAUserProfile":
        return PRXI.getOAUserProfile(this, e.params);

      case "getListSchedule":
        return PRXI.getListSchedule(this, e.params);

      case "getDivisionClassify":
        return PRXI.getDivisionClassify(this, e.params);

      case "getChatChannelTriageLevel":
        return PRXI.getChatChannelTriageLevel(this, e.params);

      case "getListChatChannelTriageLevelDiagRule":
        return PRXI.getListChatChannelTriageLevelDiagRule(this, e.params);

      case "getListAvailabelTriageLevelDiagRule":
        return PRXI.getListAvailabelTriageLevelDiagRule(this, e.params);

      case "getPatientFromDiagRule":
        return PRXI.getPatientFromDiagRule(this, e.params);

      case "putPatientFromDiagRule":
        return PRXI.putPatientFromDiagRule(this, e.params);

      case "patchOAUserProfile":
        return PRXI.patchOAUserProfile(this, e.params);

      case "getVaccineHospital":
        return PRXI.getVaccineHospital(this, e.params);

      case "getAvailableVaccineDivision":
        return PRXI.getAvailableVaccineDivision(this, e.params);

      case "postVaccineServiceBlock":
        return PRXI.postVaccineServiceBlock(this, e.params);

      case "postVaccineNote":
        return PRXI.postVaccineNote(this, e.params);

      case "putVaccineServiceBlock":
        return PRXI.putVaccineServiceBlock(this, e.params);

      case "deleteVaccineServiceBlock":
        return PRXI.deleteVaccineServiceBlock(this, e.params);

      case "getListVaccine":
        return PRXI.getListVaccine(this, e.params);

      case "getListVaccineLot":
        return PRXI.getListVaccineLot(this, e.params);

      case "getListVaccineNote":
        return PRXI.getListVaccineNote(this, e.params);

      case "getVaccineNoteDetail":
        return PRXI.getVaccineNoteDetail(this, e.params);

      case "patchVaccineNote":
        return PRXI.patchVaccineNote(this, e.params);

      case "postRequestBotMessage":
        return PRXI.postRequestBotMessage(this, e.params);

      case "getPatientData":
        return PTMI.getPatientData(this, e.params);

      case "getPatientWardData":
        return PTMI.getPatientWardData(this, e.params);

      case "periodWorkerOptions":
        return PTMI.periodWorkerOptions(this, e.params);

      case "getFlowSheet":
        return PTMI.getFlowSheet(this, e.params);

      case "getPatientAssessment":
        return PTMI.getPatientAssessment(this, e.params);

      case "getFormDataLatest":
        return PTMI.getFormDataLatest(this, e.params);

      case "getFormDataActionLog":
        return PTMI.getFormDataActionLog(this, e.params);

      case "getPatientWeightAndHeight":
        return PTMI.getPatientWeightAndHeight(this, e.params);

      case "getVitalSignValue":
        return PTMI.getVitalSignValue(this, e.params);

      case "getVitalSignValueRaw":
        return PTMI.getVitalSignValueRaw(this, e.params);

      case "getMeasurement":
        return PTMI.getMeasurement(this, e.params);

      case "getVitalSignType":
        return PTMI.getVitalSignType(this, e.params);

      case "getVitalSign":
        return PTMI.getVitalSign(this, e.params);

      case "getLastHeight":
        return PTMI.getLastHeight(this, e.params);

      case "postMeasurement":
        return PTMI.postMeasurement(this, e.params);

      case "patchMeasurement":
        return PTMI.patchMeasurement(this, e.params);

      case "postVitalSignUpdate":
        return PTMI.postVitalSignUpdate(this, e.params);

      case "deleteVitalSign":
        return PTMI.deleteVitalSign(this, e.params);

      case "getMultidisciplinary":
        return PTMI.getMultidisciplinary(this, e.params);

      case "saveMultidisciplinary":
        return PTMI.saveMultidisciplinary(this, e.params);

      case "updateMultidisciplinary":
        return PTMI.updateMultidisciplinary(this, e.params);

      case "printMultidisciplinary":
        return PTMI.printMultidisciplinary(this, e.params);

      case "getFormType":
        return PTMI.getFormType(this, e.params);

      case "getFormDataNurseNote":
        return PTMI.getFormDataNurseNote(this, e.params);

      case "deleteMultidisciplinary":
        return PTMI.deleteMultidisciplinary(this, e.params);

      case "getNurseDiagnosisResultGroup":
        return PTMI.getNurseDiagnosisResultGroup(this, e.params);

      case "putNurseDiagPrintPhv":
        return PTMI.putNurseDiagPrintPhv(this, e.params);

      case "getNurseNoteLatest":
        return PTMI.getNurseNoteLatest(this, e.params);

      case "getNurseNoteItem":
        return PTMI.getNurseNoteItem(this, e.params);

      case "postNurseNoteItem":
        return PTMI.postNurseNoteItem(this, e.params);

      case "putNurseNoteItem":
        return PTMI.putNurseNoteItem(this, e.params);

      case "deleteNurseNoteItem":
        return PTMI.deleteNurseNoteItem(this, e.params);

      case "putNurseNote":
        return PTMI.putNurseNote(this, e.params);

      case "getDrOrder":
        return PTMI.getDrOrder(this, e.params);

      case "getVentilatorMode":
        return PTMI.getVentilatorMode(this, e.params);

      case "putEncounterVitalSignType":
        return PTMI.putEncounterVitalSignType(this, e.params);

      case "putRemoveEncounterVitalSignType":
        return PTMI.putRemoveEncounterVitalSignType(this, e.params);

      case "postFormData":
        return PTMI.postFormData(this, e.params);

      case "putFormData":
        return PTMI.putFormData(this, e.params);

      case "putMeasurement":
        return PTMI.putMeasurement(this, e.params);

      case "ptmService":
        return PTMI.ptmService(this, e.params);

      case "GetTriageLevel":
        return TriageI.GetTriageLevel(this, e.params);
      case "GetChoicesBarcode":
        return ReceiveOrderI.GetChoicesBarcode(this, e.params);

      case "createServiceSlot":
        return QUEI.createServiceSlot(this, e.params);

      case "updateServiceSlot":
        return QUEI.updateServiceSlot(this, e.params);

      case "loadServiceSlot":
        return QUEI.loadServiceSlot(this, e.params);

      case "loadDivisionServiceBlock":
        return QUEI.loadDivisionServiceBlock(this, e.params);

      case "getDivisionServiceBlockDetail":
        return QUEI.getDivisionServiceBlockDetail(this, e.params);

      case "listPatientAppointment":
        return QUEI.listPatientAppointment(this, e.params);

      case "getPatientAppointment":
        return QUEI.getPatientAppointment(this, e.params);

      case "updatePatientAppointment":
        return QUEI.updatePatientAppointment(this, e.params);

      case "getPatientAppointmentUpdate":
        return QUEI.getPatientAppointmentUpdate(this, e.params);

      case "getPatientInfo":
        return REGI.getPatientInfo(this, e.params);

      case "getSearchAllPatient":
        return REGI.getSearchAllPatient(this, e.params);

      case "QueueForPatient":
        return QueueForPatientInterface.QueueForPatient(this, e.params);

      // Ishealth-v3 port into CUDent (vise versa)
      // case "getPatientOldName":
      //   return REGI.getPatientOldName(this, e.params);

      case "getPatientNameWithID":
        return REGI.getPatientNameWithID(this, e.params);

      // Ishealth-v3 port into CUDent (vise versa)
      // case "getPatientByHN":
      //   return REGI.getPatientByHN(this, e.params);

      case "getBloodType":
        return REGI.getBloodType(this, e.params);

      case "regService":
        return REGI.regService(this, e.params);

      case "putArriveTRTOrder":
        return TECI.putArriveTRTOrder(this, e.params);

      case "getTECSession":
        return TECI.getTECSession(this, e.params);

      case "getListMedAdminRecord":
        return TPDI.getListMedAdminRecord(this, e.params);

      case "postCreateMedAdminRecord":
        return TPDI.postCreateMedAdminRecord(this, e.params);

      case "putUpdateMedAdminRecord":
        return TPDI.putUpdateMedAdminRecord(this, e.params);

      case "putMedAdminRecord":
        return TPDI.putMedAdminRecord(this, e.params);

      case "putDrugOrderItemIdAdminDivision":
        return TPDI.putDrugOrderItemIdAdminDivision(this, e.params);

      case "postMedAdminRecord":
        return TPDI.postMedAdminRecord(this, e.params);

      case "getPrintMedRecord":
        return TPDI.getPrintMedRecord(this, e.params);

      case "getDrugOrderItemForMedReconcile":
        return TPDI.getDrugOrderItemForMedReconcile(this, e.params);

      case "tpdService":
        return TPDI.tpdService(this, e.params);

      case "getTreatmentOrder":
        return TRTI.getTreatmentOrder(this, e.params);

      case "getTreatmentOrderPerformer":
        return TRTI.getTreatmentOrderPerformer(this, e.params);

      case "getTreatment":
        return TRTI.getTreatment(this, e.params);

      case "postTreatmentOrderEstimate":
        return TRTI.postTreatmentOrderEstimate(this, e.params);

      case "postTreatmentOrder":
        return TRTI.postTreatmentOrder(this, e.params);

      case "putTreatmentOrderEncounterArrive":
        return TRTI.putTreatmentOrderEncounterArrive(this, e.params);

      case "getUserPermission":
        return UserI.getUserPermission(this, e.params);

      case "getUserTokenize":
        return UserI.getUserTokenize(this, e.params);

      case "getUserTokenizeLicense":
        return UserI.getUserTokenizeLicense(this, e.params);

      case "getUserDetail":
        return UserI.getUserDetail(this, e.params);

      case "postUserLogin":
        return UserI.postUserLogin(this, e.params);

      case "getUserEmployee":
        return UserI.getUserEmployee(this, e.params);

      case "getUsers":
        return UserI.getUsers(this, e.params);

      case "getUserProfile":
        return UserI.getUserProfile(this, e.params);

      case "updateUserProfile":
        return UserI.updateUserProfile(this, e.params);

      case "getUserPosition":
        return UserI.getUserPosition(this, e.params);

      case "getUserWithPatient":
        return UserI.getUserWithPatient(this, e.params);

      case "postUserSetPassword":
        return UserI.postUserSetPassword(this, e.params);

      case "getLocation":
        return CoreI.getLocation(this, e.params); // proxy to getDivision

      case "getDashboardAppointment":
        return PRXI.getDashboardAppointment(this, e.params);

      // Ishealth-v3 port into CUDent (vise versa)
      case "getPatientList":
        return await PatientSearchCUInterface.getPatientList(this, e.params);

      case "getNatinalities":
        return await PatientSearchCUInterface.getNatinalities(this, e.params);

      case "choiceNationalities":
        return await PatientSearchCUInterface.choiceNationalities(this, e.params);

      case "getPatientOldName":
        return await PatientSearchCUInterface.getPatientOldName(this, e.params);

      case "getPatientByHN":
        return await PatientSearchCUInterface.getPatientByHN(this, e.params);

      case "searchPatients":
        return await PatientSearchCUInterface.searchPatients(this, e.params);

      case "loadPatientOldName":
        return await PatientSearchCUInterface.loadPatientOldName(this, e.params);

      case "SearchStaff":
        return await StaffSettingI.SearchStaff(this, e.params);

      case "SettingStaff":
        return await StaffSettingI.SettingStaff(this, e.params);

      case "SettingDivision":
        return await StaffSettingI.SettingDivision(this, e.params);

      // ----- Dental Diagram
      case "AddLocation":
        DentalRecordI.AddLocation(this as any, e.params);
        return;
      case "ToggleLocation":
        DentalRecordI.ToggleLocation(this as any, e.params);
        return;

      case "apiToken":
        return;

      case "HandleSelect":
        return SetPatientI.HandleSelect(this, e.params);

      case "HandleGetPatientOldName":
        return SetPatientI.HandleGetPatientOldName(this, e.params);

      case "GetHealthMemberSegment":
        return HealthMemberI.GetHealthMemberSegment(this, e.params);

      case "SelectSegmentGroup":
        return HealthMemberI.SelectSegmentGroup(this, e.params);

      case "SelectPatientSegment":
        return HealthMemberI.SelectPatientSegment(this, e.params);

      // ----- PHVInterface
      case "PrintEMRSummaryForm":
        return PHVInterface.PrintEMRSummaryForm(this, e.params);

      default:
        let testExhausitve: never = e;
    }
    console.error("-- Unhandled case");
  };
}
