import React, { FC, useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Label,
  Modal,
} from "semantic-ui-react";
import {
  CardLayout,
  DateTextBox,
  ErrorMessage,
  PureReactTable,
  RightLabel,
} from "../common";
import { formatDateToStringBe } from "react-lib/utils/dateUtils";

const styles = {
  modalHeader: {
    backgroundColor: "mediumseagreen",
    color: "white",
  },
};
const columns = [
  { Header: "หมายเลขถุงเลือด", width: 120, accessor: "bag_number" },
  { Header: "ประเภทเลือด", width: 120, accessor: "component_name" },
  { Header: "หมู่เลือดในถุง", width: 100, accessor: "bag_blood_group" },
  { Header: "เวลาเริ่มต้น", width: 100, accessor: "start_time" },
  { Header: "ผู้ให้เลือด", width: 150, accessor: "start_by_fullname" },
  { Header: "เวลาสิ้นสุด", width: 100, accessor: "end_time" },
  { Header: "ผู้สิ้นสุดการให้", width: 150, accessor: "end_by_fullname" },
  {
    Header: "Reaction",
    width: 80,
    accessor: "transfuse_reaction",
    Cell: (row) => {
      if (row.original.transfuse_reaction) {
        return (
          <div style={{ textAlign: "center" }}>
            <Label color="green">Yes</Label>
          </div>
        );
      } else {
        return (
          <div style={{ textAlign: "center" }}>
            <Label color="brown">No</Label>
          </div>
        );
      }
    },
  },
  { Header: "Comment", width: 230, accessor: "comment" },
];

const THAI_DATE_FORMAT = "DD/MM/YYYY";

export interface BloodTransfusionHistoryProps {
  closeable: boolean;
  hideCallback: () => any;
  django: {
    user: {
      id: number;
    };
  };
  controller: any;
  patientId: number;
  encounterId: number;
  encounterDoctorId: number;
}

const CardBloodTransfusionHistory: FC<BloodTransfusionHistoryProps> = (
  props
) => {

  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [bloodTransfusionLIS, setBloodTransfusionLIS] = useState<string>("");
  const [showButtonLabResult, setShowButtonLabResult] = useState<boolean>(false);
  // search
  const [startTime, setStartTime] = useState<any>(
    formatDateToStringBe(moment())
  );
  const [endTime, setEndTime] = useState<any>(
    formatDateToStringBe(moment())
  );
  // table
  const [bloodTransfusionList, setBloodTransfusionList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(1);
  // modal
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [patientBloodGroup, setPatientBloodGroup] = useState<any>({
    blood_group: "",
    rh_group: "",
    sub_group: "",
    approve_by: "",
    approve_datetime: "",
  });

  useEffect(() => {
    const getUserPermission = async () => {
      setIsLoading(true);
      const [result, error] = await props.controller.getUserPermission();
      if (isMounted.current) {
        if (error) {
          setErrors(error);
        } else {
          setBloodTransfusionLIS(result.config_BLB_BLOOD_TRANSFUSION_LIS);
        }
      }
      setIsLoading(false);
    };

    getUserPermission();
  }, []);

  useEffect(() => {
    if (props.patientId) {
      refresh();
    }
  }, [props.patientId]);

  useEffect(() => {
    if (
      !!props.encounterId &&
      !!props.encounterDoctorId &&
      !!props.django &&
      !!props.django.user &&
      !!props.django.user.id
    ) {
      setShowButtonLabResult(true);
    } else {
      setShowButtonLabResult(false);
    }
  }, [props.encounterId, props.encounterDoctorId, props.django]);

  useEffect(() => {
    getBloodTransfusion();
  }, [currentPage, limit]);

  const refresh = () => {
    getBloodGroup();
    getBloodTransfusion();
  };

  const getBloodGroup = async () => {
    setIsLoading(true);
    const [
      result,
      error,
      network,
    ] = await props.controller.getBloodGroupWithPatient(props.patientId);
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        setErrors("");
        setPatientBloodGroup(result);
      }
    }
    setIsLoading(false);
  };

  const getBloodTransfusion = async () => {
    setIsLoading(true);
    const [result, error, network] = await props.controller.getBloodTransfusion(
      {
        patient: props.patientId,
        start_time: startTime,
        end_time: endTime,
        limit: limit,
        offset: currentPage * limit,
      }
    );
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        setErrors("");
        setBloodTransfusionList(result?.items || []);
        setTotalPage(Math.ceil(result?.total / limit));
      }
    }
    setIsLoading(false);
  };

  const handleStartTime = (date) => {
    setStartTime(date);
  };

  const handleEndTime = (date) => {
    setEndTime(date);
  };

  const handleLabResult = () => {
    const url =
      bloodTransfusionLIS +
      `?encounter_id=${props.encounterId}?user_id=${props.encounterDoctorId}?clinician_id=${props.django.user.id}`;
    window.open(url, "_blank");
  };

  // table
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (limit) => {
    setLimit(limit);
  };

  // modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <CardLayout
      headerColor="pink"
      loading={isLoading}
      hideTitleText={true}
      hideDivider={true}
      hideHeaderIcon={true}
      toggleable={false}
      closeable={props.closeable}
      onClose={props.hideCallback}
    >
      <Form error={errors ? true : false}>
        <ErrorMessage error={errors} />

        <Form.Group inline>
          <Form.Field width={16}>
            <Header size="medium">Blood Transfusion History</Header>
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={1}>
            <RightLabel>From</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <DateTextBox value={startTime} onChange={handleStartTime} />
          </Form.Field>
          <Form.Field>
            <RightLabel>To</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <DateTextBox value={endTime} onChange={handleEndTime} />
          </Form.Field>
          <Form.Field >
            <Button color="green" type="button" onClick={refresh}>
              Search
            </Button>
          </Form.Field>
          <Form.Field width={2}>
            <RightLabel>หมู่เลือดของผู้ป่วย :</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <Label className="fluid">
              {patientBloodGroup.blood_group || "-"}{" "}
              {patientBloodGroup.rh_group}
            </Label>
          </Form.Field>
          <Form.Field width={3}>
            <Button
              icon
              fluid
              labelPosition="right"
              color="green"
              type="button"
              onClick={handleOpenModal}
            >
              รายละเอียดหมู่เลือด
              <Icon name="right arrow" />
            </Button>
          </Form.Field>
          <Form.Field width={2}>
            <Button
              fluid
              color="green"
              type="button"
              onClick={handleLabResult}
              disabled={!showButtonLabResult}
            >
              Lab result
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>

      <Divider />

      <PureReactTable
        data={bloodTransfusionList}
        columns={columns}
        minRows={10}
        showPagination={true}
        showPageSizeOptions={true}
        pageSize={limit}
        page={currentPage}
        pages={totalPage}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        manual
      />

      <Modal open={openModal} onClose={handleCloseModal} size="tiny">
        <Modal.Header style={styles.modalHeader}>
          รายละเอียดหมู่เลือด
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Field width={5}>
                <RightLabel>blood group:</RightLabel>
              </Form.Field>
              <Form.Field width={11}>
                {patientBloodGroup.blood_group || "-"}
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field width={5}>
                <RightLabel>rh group:</RightLabel>
              </Form.Field>
              <Form.Field width={11}>
                {patientBloodGroup.rh_group || "-"}
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field width={5}>
                <RightLabel>sub group:</RightLabel>
              </Form.Field>
              <Form.Field width={11}>
                {patientBloodGroup.sub_group || "-"}
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field width={5}>
                <RightLabel>approve by:</RightLabel>
              </Form.Field>
              <Form.Field width={11}>
                {patientBloodGroup.approve_by_fullname || "-"}
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field width={5}>
                <RightLabel>approve datetime:</RightLabel>
              </Form.Field>
              <Form.Field width={11}>
                {patientBloodGroup.approve_datetime || "-"}
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleCloseModal}>
            <Icon name="remove" /> close
          </Button>
        </Modal.Actions>
      </Modal>
    </CardLayout>
  );
};

export const BloodTransfusionHistoryDefaultProps: BloodTransfusionHistoryProps = {
  closeable: false,
  hideCallback: () => {},
  django: {
    user: {
      id: null,
    },
  },
  controller: null,
  patientId: null,
  encounterId: null,
  encounterDoctorId: null,
};
CardBloodTransfusionHistory.defaultProps = BloodTransfusionHistoryDefaultProps;

export default CardBloodTransfusionHistory;
