import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const CardSettingDivisionUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>

        <div
          style={{display:"flex",marginBottom:"10px",justifyContent:"flex-end"}}>
          {}
          <div>

            <Button
              color="blue"
              onClick={props.onOpenDivision}>
              {props.titleAddDivision}
            </Button>
          </div>
        </div>
        <div
          style={{display:props.showTable ? "":"none"}}>

          <Table
            data={props.dataDivision}
            getTdProps={props.getTdProps}
            headers={props.headers ? props.headers : "รหัส,ชื่อคลินิก,สิทธิการใช้,Active"}
            keys={props.keys ? props.keys : "code,name,extra,active"}
            minRows="8"
            showPagination={false}
            style={{height: "400px"}}>
          </Table>
        </div>
        <div
          style={{display:props.showTable ? "":"none"}}>

          <div
            style={{display:"flex",marginTop:"10px",justifyContent:"flex-end"}}>

            <div
              style={{marginRight:"10px"}}>
              {props.buttonSave}
            </div>
            <Button
              color="red"
              onClick={props.onCancel}
              size="small"
              style={{display: "none"}}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardSettingDivisionUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Mon Jun 17 2024 09:53:34 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginBottom:\"10px\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.dataDivision"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "code",
          "value": "props.headers ? props.headers : \"รหัส,ชื่อคลินิก,สิทธิการใช้,Active\""
        },
        "keys": {
          "type": "code",
          "value": "props.keys ? props.keys : \"code,name,extra,active\""
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.showTable ? \"\":\"none\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleAddDivision"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenDivision"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.showTable ? \"\":\"none\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginTop:\"10px\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Button",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 24,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingDivisionUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
