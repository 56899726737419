import React, { useEffect, useState, useMemo } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// ui ORM
import CardSurgicalPositionUX from "./CardSurgicalPositionUX";
import CardSpecialEquipmentUX from "./CardSpecialEquipmentUX";
import CardEssentialImaging, {
  ESSENTIAL_IMAGING,
} from "./CardEssentialImaging";
import CardSkinPreparation, { SKIN_PREPARATION } from "./CardSkinPreparation";
import CardIrrigation, { IRRIGATION } from "./CardIrrigation";
import CardORMedication, { OR_MEDICATION } from "./CardORMedication";
import { Button, Checkbox, Dropdown, Input } from "semantic-ui-react";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import PreOPButtonAction from "./PreOPButtonAction";

// const CARD_KEY: string = "postOperativeData";
// const FORM_CODE: string = "CardPostOperativeNursingRecord";
// const FORM_NAME: string = "Post-Operative-Nursing";
// const FORM_VERSION: string = "0.1";
export const ACTION = {
  SAVE: "SAVE",
  CONFIRM: "CONFIRM",
  UNCONFIRM: "UNCONFIRM",
};

type CardOtherFormProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardOtherFormInitial: CardOtherFormProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PerioperativeNursingSequence: {},
};

const CARD_KEY = "postOperativeData";
const FORM_VERSION = "1.0";

const CardOtherForm: React.FC<CardOtherFormProps> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "FETCH_DATA_OTHER_FORM",
      formVersion: FORM_VERSION,
      // cardKey: CARD_KEY,
      nurse_note_temp_code: "nurse_note_sp",
      essentialImagingKey: ESSENTIAL_IMAGING,
      skinPreparationKey: SKIN_PREPARATION,
      irrigationKey: IRRIGATION,
      orMedicationKey: OR_MEDICATION,
    });
  }, []);

  const nurseNoteTemplate = useMemo(() => {
    return props.PerioperativeNursingSequence?.nurseNoteTemplate || {};
  }, [props.PerioperativeNursingSequence?.nurseNoteTemplate]);

  const handleChangeData = (_event: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;
    props.setProp(
      `PerioperativeNursingSequence.${data.name || data.className}`,
      value,
      data.callback
    );
  };

  const handleSaveSpecialEquipment = (e: any, v: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE_SPECIAL_EQUIPMENT",
      cardKey: v.name,
    });
  };

  const handleChangedEssentialImaging = (key: string) => (e: any, v: any) => {
    handleChangeData(e, {
      ...v,
      name: `${key}.data${v.name ? `.${v.name}` : ""}`,
    });
  };

  const handleChangeSpecialEquipment = (e: any, v: any) => {
    handleChangeData(e, { ...v, name: `operatingDetail.${v.name}` });
  };

  const handleSaveEssentialImaging = (action: string) => (e: any, v: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action,
      formCode: v.formCode,
      formVersion: FORM_VERSION,
      formName: v.formName,
      cardKey: v.card,
      byKey: v.key,
    });
  };

  const handleEnterToken = (value: any, index: any, key: any, type: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "TOKENIZE",
      value,
      index,
      key,
      type,
      cardKey: OR_MEDICATION,
    });
  };

  console.log("CardOtherForm", props);

  return (
    <>
      <CardSpecialEquipmentUX
        // data
        setProp={props.setProp}
        specialEquipment={
          props.PerioperativeNursingSequence?.operatingDetail
            ?.special_equipments
        }
        detail={props.PerioperativeNursingSequence?.operatingDetail
            ?.detail}
        // options
        specialEquipmentOptions={props.masterOptions?.specialEquipment}
        // callback
        onChangeData={handleChangeSpecialEquipment}
        // Element
        ButtonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{
              CardSpecialEquipment: { allowed_actions: "SAVE", id: "FAKE_ID" },
            }}
            type="save"
            cardKey={"CardSpecialEquipment"}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSaveSpecialEquipment}
          />
        }
      />
      <CardEssentialImaging
        // data
        setProp={props.setProp}
        data={props.PerioperativeNursingSequence?.[ESSENTIAL_IMAGING]}
        buttonLoadCheck={props.buttonLoadCheck}
        // options
        masterOptions={props.masterOptions}
        // callback
        onChangeData={handleChangedEssentialImaging(ESSENTIAL_IMAGING)}
        onSave={handleSaveEssentialImaging}
      />
      <CardSkinPreparation
        // data
        setProp={props.setProp}
        data={props.PerioperativeNursingSequence?.[SKIN_PREPARATION]}
        buttonLoadCheck={props.buttonLoadCheck}
        // options
        masterOptions={props.masterOptions}
        // callback
        onChangeData={handleChangedEssentialImaging(SKIN_PREPARATION)}
        onSave={handleSaveEssentialImaging}
      />
      <CardIrrigation
        // data
        setProp={props.setProp}
        data={props.PerioperativeNursingSequence?.[IRRIGATION]}
        buttonLoadCheck={props.buttonLoadCheck}
        // callback
        onChangeData={handleChangedEssentialImaging(IRRIGATION)}
        onSave={handleSaveEssentialImaging}
        setProp={props.setProp}
      />
      <CardORMedication
        // data
        setProp={props.setProp}
        data={props.PerioperativeNursingSequence?.[OR_MEDICATION]}
        buttonLoadCheck={props.buttonLoadCheck}
        // options
        masterOptions={props.masterOptions}
        // callback
        onChangeData={handleChangedEssentialImaging(OR_MEDICATION)}
        onEnterToken={handleEnterToken}
        onSave={handleSaveEssentialImaging}
      />
    </>
  );
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
export const checkHideButton = (data: any, type: string) => {
  switch (type) {
    case ACTION.SAVE:
      if (!data?.allow_actions) {
        return false;
      } else if (data?.allow_actions?.indexOf(ACTION.SAVE) !== -1) {
        return false;
      }
      return true;
    case ACTION.CONFIRM:
      return (data?.allowed_actions || [])?.indexOf(ACTION.CONFIRM) === -1;

    case ACTION.UNCONFIRM:
      return (data?.allowed_actions || [])?.indexOf(ACTION.UNCONFIRM) === -1;
    case "STATUS":
      return !data?.status;
    default:
      break;
  }
};

CardOtherForm.defaultProps = CardOtherFormInitial;

export default React.memo(CardOtherForm);
