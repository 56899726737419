import React, { CSSProperties, useMemo } from "react";

// UX
import SubUserActionLogUX from "../common/SubUserActionLogUX";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";

// Types
type CardUserActionLogProps = {
  data:
    | {
        [key in ValueOf<typeof ACTION_LOGS>]: Record<string, any> | null;
      }
    | null;
  actions?: (keyof typeof ACTION_LOGS)[];
  timeLabel?: string;
  timeWidth?: CSSProperties["width"];
  divisionLabel?: string;
  divisionWidth?: CSSProperties["width"];
};

type ValueOf<T> = T[keyof T];

const ACTION_LOGS = {
  ผู้ขอคืน: "requested",
  ผู้อนุมัติ: "approved",
  ผู้ส่ง: "delivered",
  ผู้รับ: "received",
  ผู้ปฏิเสธ: "rejected",
  ผู้ยกเลิก: "canceled",
  ผู้สั่ง: "requested",
  ผู้ตรวจ: "printed",
  ผู้จัด: "checked",
  ผู้จ่าย: "delivered",
  ผู้ขนส่ง: "transported",
} as const;

const DEFAULT_ACTIONS = [
  "ผู้ขอคืน",
  "ผู้อนุมัติ",
  "ผู้ส่ง",
  "ผู้รับ",
  "ผู้ปฏิเสธ",
  "ผู้ยกเลิก",
] as const;

const CardUserActionLog = (props: CardUserActionLogProps) => {
  const userActionLogReturnList = useMemo(() => {
    const actions = (props.actions || DEFAULT_ACTIONS).map((title) => ({
      key: ACTION_LOGS[title],
      title,
    }));
    const list = actions.flatMap((action) =>
      props.data?.[action.key] ? [{ ...props.data[action.key], title: action.title }] : []
    );

    return list.map((item: any, idx: any) => (
      <SubUserActionLogUX
        key={item.id}
        // data
        timeLabel={props.timeLabel}
        divisionLabel={props.divisionLabel}
        name={item.name}
        division={item.division}
        typeName={item.title}
        datetime={formatDatetime(item.datetime, true)}
        // style
        timeWidth={props.timeWidth}
        divisionWidth={props.divisionWidth}
        // config
        fontSize=""
        disabled={true}
      />
    ));
  }, [
    props.data,
    props.timeLabel,
    props.timeWidth,
    props.divisionLabel,
    props.divisionWidth,
    props.actions,
  ]);

  return <div className="--readOnly --grey">{userActionLogReturnList}</div>;
};

CardUserActionLog.displayName = "CardUserActionLog";

export default React.memo(CardUserActionLog);
