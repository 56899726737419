import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input,
  Button,
  Dropdown,
  TextArea,
  Checkbox,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardTreatmentOrderUX = (props: any) => {
    return(
      <div>
        <div
          id="CardTreatmentOrder-Div-TreatmentOrderScreen"
          style={{display:"flex"}}>
          
          <div
            style={{fontWeight: "bold", fontSize: "1.25rem", padding: "20px 10px 10px" }}>
            {props.titleName || "Treatment"}
          </div>
          <div
            style={{flex:"1"}}>
            
          </div>
          <div
            style={{display: props.checkOutPerformDiv ? "grid" : "flex", margin: "1rem 1rem 0 0"}}>
            
            <div
              style={{display: "flex", alignItems: "center", ...(props.hidePreOrder && {display: "none"})}}>
              
              <div
                style={{marginRight: "10px"}}>
                
                <Checkbox
                  checked={props?.preOrderCheck || false}
                  id="CardTreatmentOrder-Checkbox-PreOrderCheck"
                  label={props.checkOutPerformDiv ? "คำสั่งนอกแผนก" : "สั่ง Treatment ล่วงหน้า"}
                  onChange={props.handleCheckPreOrder}
                  toggle={true}>
                </Checkbox>
              </div>
              <div>
                
                <DateTextBox
                  disabled={!props?.preOrderCheck }
                  id="CardTreatmentOrder-DateTextBox-PreOrderDate"
                  minDate={props?.minDate}
                  onChange={props?.onChangePreOrderDate("preOrderDate")}
                  style={{marginRight:"1rem", ...(props.checkOutPerformDiv && {display: "none"})}}
                  value={props?.preOrderDate}>
                </DateTextBox>
                <Dropdown
                  disabled={!props?.preOrderCheck }
                  onChange={props?.onChangePreOrderDate("preOrderDate")}
                  options={props.divisionOpdOptions || []}
                  selection={true}
                  style={{marginRight:"5px",marginTop:"5px", ...(!props.checkOutPerformDiv && {display: "none"})}}
                  value={props?.preOrderDate || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "flex-end", ...(props.checkOutPerformDiv && {marginTop: "1rem"}), ...(props.showPreOrder && {display: "none"})}}>
              
              <label
                style={{marginRight: "10px", ...(!props.checkOutPerformDiv && {display: "none"})}}>
                Template
              </label>
              <Input
                id="CardTreatmentOrder-Input-TemplateOrder"
                placeholder={props.checkOutPerformDiv  ? "" : "template"}
                style={{marginRight:"5px"}}>
              </Input>
              <Button
                color="green"
                icon="search"
                onClick={props?.onSearch}>
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{padding: "10px"}}>
          {props?.ErrorMessage}
        </div>
        <div
          style={{padding: "10px"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              style={{marginLeft: "10px", marginRight: "5px", minWidth: "max-content"}}>
              รายการหัตถการ
            </div>
            <div
              style={{position: "relative", width: "100%"}}>
              
              <div
                id="txt-searchTreatment"
                style={{width: "100%"}}>
                {props.searchTreatmentListBox}
              </div>
            </div>
            <div
              style={{marginLeft: "10px", marginRight: "5px"}}>
              จำนวน
            </div>
            <Input
              fluid={true}
              id="txt-qty"
              onChange={props.onChangeTreatmentQuantity}
              style={{width: "100%"}}
              value={props.treatmentQuantity}>
            </Input>
            <div
              style={{marginLeft: "10px", marginRight: "5px"}}>
              ครั้ง
            </div>
            <Dropdown
              fluid={true}
              id="cb-eligibilityType"
              onChange={props.onChangeEligibilityType}
              options={props.claimOptions}
              selection={true}
              style={{width: "100%"}}
              value={props.eligibilityType}>
            </Dropdown>
            <Button
              color="green"
              disabled={props.disabledAddTreatment}
              id="btn-addTreatment"
              onClick={props.addTreatment}
              style={{marginLeft: "10px", minWidth: "max-content"}}>
              เพิ่มรายการ
            </Button>
          </div>
          <div>
            
            <Table
              data={props.treatmentList}
              headers={!props.checkOutPerformDiv ? "รายการหัตถการ,จำนวน,รายละเอียดเบิก,สถานะ" : "รายการหัตถการ,จำนวน,รายละเอียดเบิก,Del"}
              id="tb-treatmentList"
              keys={!props.checkOutPerformDiv ? "product,quantity,claim,status" : "product,quantity,claim,del"}
              minRows="3"
              showPagination={false}
              style={{height: "200px", marginTop: "0.5rem"}}>
            </Table>
          </div>
        </div>
        <div
          style={{padding: "10px", position: "relative", display: props.hidePrincipalDiagnosis ? "none" : "" }}>
          
          <div
            style={{fontWeight: "bold"}}>
            Principal Procedure
          </div>
          <div
            style={{display: "flex", alignItems: "center",position:"relative"}}>
            
            <div
              style={{marginLeft: "10px", marginRight: "5px", minWidth: "max-content"}}>
              Medical Term
            </div>
            <Input
              fluid={true}
              icon="search"
              id="txt-medicalterm"
              loading={props?.loadingmedTermPrincipal}
              name="medterm"
              onChange={props.onSearchTextChange("medTermPrincipal")}
              style={{width: "100%"}}
              value={props.medTermPrincipal}>
            </Input>
            <div
              style={{marginLeft: "10px", marginRight: "5px", minWidth: "max-content"}}>
              ICD9CM Term
            </div>
            <Input
              fluid={true}
              icon="search"
              id="txt-icd9term"
              loading={props?.loadingICD9CMTermPrincipal}
              name="icdterm"
              onChange={props.onSearchTextChange("ICD9CMTermPrincipal")}
              style={{width: "100%"}}
              value={props.ICD9CMTermPrincipal}>
            </Input>
            <div
              style={{marginLeft: "10px", marginRight: "5px", minWidth: "max-content"}}>
              ICD9CM Code
            </div>
            <Input
              fluid={true}
              icon="search"
              id="txt-icd9code"
              loading={props?.loadingICD9CMCodeprincipal}
              name="icdcode"
              onChange={props.onSearchTextChange("ICD9CMCodeprincipal")}
              style={{width: "100%"}}
              value={props.ICD9CMCodeprincipal}>
            </Input>
            <Button
              color="green"
              id="btn-addDiagnosis"
              onClick={props.onAddPrincipal}
              style={{marginLeft: "10px", minWidth: "max-content"}}>
              เพิ่มรายการ
            </Button>
          </div>
          <div
            style={{display: props.showSearchResult ? "block" : "none", position: "absolute", zIndex: 100, width: "calc(100% - 20px)", height: "400px" }}>
            
            <Table
              data={props?.icd9Search}
              getTrProps={props?.icd9RowProps}
              headers="Medical Term,ICD9CM,ICD9CM"
              keys="medterm,icdterm,icdcode"
              minRows="4"
              showPagination={false}
              style={{height:"150px"}}>
            </Table>
          </div>
          <div>
            
            <Table
              data={props.diagnosisListPrincipal}
              headers="Medical Term, ICD9CM, ICD9CM"
              id="tb-diagListPrinciple"
              keys="icd9cm_med_term,icd9cm_term,icd9cm_code"
              minRows="3"
              showPagination={false}
              style={{height: "200px", marginTop: "0.5rem"}}>
            </Table>
          </div>
        </div>
        <div
          style={{padding: "10px",  position: "relative", display : props.hideSecondaryDiagnosis ? "none" : ""}}>
          
          <div
            style={{fontWeight: "bold"}}>
            Secondary Procedure
          </div>
          <div
            style={{display: "flex", alignItems: "center",position:"relative"}}>
            
            <div
              style={{marginLeft: "10px", marginRight: "5px",minWidth: "max-content"}}>
              Medical Term
            </div>
            <Input
              fluid={true}
              icon="search"
              id="CardTreatmentOrder-Input-SecondMedicalTerm"
              loading={props?.loadingMedTermSecondary}
              name="medterm"
              onChange={props.onSearchSecondaryChange("medTermSecondary")}
              style={{width: "100%"}}
              value={props?.medTermSecondary}>
            </Input>
            <div
              style={{marginLeft: "10px", marginRight: "5px",minWidth: "max-content"}}>
              ICD9CM Term
            </div>
            <Input
              fluid={true}
              icon="search"
              id="CardTreatmentOrder-Input-SecondIcd9Term"
              loading={props?.loadingICD9MTermSecondary}
              name="icdterm"
              onChange={props.onSearchSecondaryChange("ICD9MTermSecondary")}
              style={{width: "100%"}}
              value={props?.ICD9MTermSecondary}>
            </Input>
            <div
              style={{marginLeft: "10px", marginRight: "5px",minWidth: "max-content"}}>
              ICD9CM Code
            </div>
            <Input
              fluid={true}
              icon="search"
              id="CardTreatmentOrder-Input-SecondIcd9Code"
              loading={props?.loadingICD9MCodeSecondary}
              name="icdcode"
              onChange={props.onSearchSecondaryChange("ICD9MCodeSecondary")}
              style={{width: "100%"}}
              value={props.ICD9MCodeSecondary}>
            </Input>
            <Button
              color="green"
              id="CardTreatmentOrder-Button-AddSecondProcedure"
              onClick={props.onAddSecondary}
              style={{marginLeft: "10px",minWidth: "max-content"}}>
              เพิ่มรายการ
            </Button>
          </div>
          <div
            style={{display: props.showSearchResultSecondary ? "block" : "none", position: "absolute", zIndex: 100, width: "calc(100% - 20px)", height: "400px" }}>
            
            <Table
              data={props?.secondarySearch}
              getTrProps={props?.secondaryRowProps}
              headers="Medical Term,ICD9CM,ICD9CM"
              keys="medterm,icdterm,icdcode"
              minRows="4"
              showPagination={false}
              style={{height:"150px"}}>
            </Table>
          </div>
          <div>
            
            <Table
              data={props?.diagnosisListSecondary}
              getTrProps={props?.secondaryRowProps}
              headers="Medical Term,ICD9CM Term,ICD9CM Code"
              keys="icd9cm_med_term,icd9cm_term,icd9cm_code"
              minRows="3"
              showPagination={false}
              style={{height: "200px", marginTop: "0.5rem"}}>
            </Table>
          </div>
        </div>
        <div
          style={{height: "350px", padding: "10px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(13, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/3", gridColumn: "1/7",  padding: "5px", display: "flex"}}>
              ผู้ทำหัตถการ
              <div
                style={{color:"red"}}>
                *
              </div>
            </div>
            <div
              className="ui form"
              id="txt-responsible"
              style={{padding: "5px", gridRow: "1/3", gridColumn: "8/41",  }}>
              <Dropdown
                compact={true}
                error={props?.toBePerformMissing}
                fluid={ true}
                id="cb-responsible"
                onChange={props?.handleChangeValue("toBePerformer")}
                options={props?.options}
                search={true}
                selection={true}
                value={props?.toBePerformer}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "3/7", gridColumn: "1/7",  padding: "5px"}}>
              รายละเอียดเพิ่มเติม
            </div>
            <div
              className="ui form"
              style={{ padding: "5px",gridRow: "3/7", gridColumn: "8/41", }}>
              <TextArea
                id="CardTreatmentOrder-TextArea-OrderDetail"
                onChange={props?.handleChangeValue("orderDetail")}
                style={{width: "100%", height: "100%", border: "solid #cccccc 1px"}}
                value={props?.orderDetail}>
              </TextArea>
            </div>
            <div
              style={{ gridRow: "7/11", gridColumn: "1/7",  padding: "5px"}}>
              อุปกรณ์
            </div>
            <div
              className="ui form"
              style={{  padding: "5px", gridRow: "7/11", gridColumn: "8/41",  }}>
              {}
              <TextArea
                id="CardTreatmentOrder-TextArea-EquipmentDetail"
                onChange={props?.handleChangeValue("equipmentDetail")}
                style={{width: "100%", height: "100%", border: "solid #cccccc 1px"}}
                value={props?.equipmentDetail}>
              </TextArea>
            </div>
            <div
              className="ui form --readOnly --grey"
              style={{ gridRow: "12/13", gridColumn: "1/41"  }}>
              
              <FormGroup
                inline={true}
                style={{margin: 0}}>
                <FormField
                  inline={true}
                  style={{flex: 1}}>
                </FormField>
                <FormField
                  inline={true}>
                  <div>
                    ราคา
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <Input
                    disabled={true}
                    fluid={true}
                    style={{width: "100%"}}
                    value={Number(props?.treatmentOrder?.price_total||"0").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{minWidth:"max-content"}}>
                    เบิกได้
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <Input
                    disabled={true}
                    fluid={true}
                    style={{width: "100%"}}
                    value={Number(props?.treatmentOrder?.price_claimable||"0").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{minWidth:"max-content"}}>
                    เบิกไม่ได้
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <Input
                    disabled={true}
                    fluid={true}
                    style={{width: "100%"}}
                    value={Number(props?.treatmentOrder?.price_non_claimable ||"").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </FormField>
              </FormGroup>
            </div>
            <div
              style={{ gridRow: "14/16", gridColumn: "1/41", display: "flow-root", justifyContent: 'flex-end', }}>
              <div
                style={{ float: "left" }}>
                {props.orderByBox}
              </div>
              <div
                style={{ float: "right" }}>
                
                <div
                  style={{ ...(props.hideSaveButton && { display: "none" }) }}>
                  {props.buttonSaveOrder}
                </div>
                <div
                  style={{ ...(props.hideSaveButton && { display: "none" }) }}>
                  {props.buttonConfirmOrder}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{display:props.checkOutPerformDiv ?  "none": "",padding: "0px 10px 20px 10px"}}>
          
          <Table
            data={props.OrderTreatments}
            getTrProps={props.orderRowProps}
            headers="Treatment Detail,Care provider,Del,Edit,Status"
            id="tb-tratementOrderStatus"
            keys="detail,careprovider,delete,edit,status"
            minRows="5"
            showPagination={false}
            style={{height:"250px"}}
            widths="auto,230px,80px,80px,130px">
          </Table>
        </div>
      </div>
    )
}


export default CardTreatmentOrderUX

export const screenPropsDefault = {"showTreatmentList":true}

/* Date Time : Tue Oct 08 2024 12:37:38 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 7,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.treatmentList"
        },
        "headers": {
          "type": "code",
          "value": "!props.checkOutPerformDiv ? \"รายการหัตถการ,จำนวน,รายละเอียดเบิก,สถานะ\" : \"รายการหัตถการ,จำนวน,รายละเอียดเบิก,Del\""
        },
        "id": {
          "type": "value",
          "value": "tb-treatmentList"
        },
        "keys": {
          "type": "code",
          "value": "!props.checkOutPerformDiv ? \"product,quantity,claim,status\" : \"product,quantity,claim,del\""
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\", marginTop: \"0.5rem\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการหัตถการ"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวน"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 2,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-qty"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentQuantity"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.treatmentQuantity"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ครั้ง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการ"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAddTreatment"
        },
        "id": {
          "type": "value",
          "value": "btn-addTreatment"
        },
        "onClick": {
          "type": "code",
          "value": "props.addTreatment"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Dropdown",
      "parent": 2,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-eligibilityType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEligibilityType"
        },
        "options": {
          "type": "code",
          "value": "props.claimOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.eligibilityType"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", position: \"relative\", display: props.hidePrincipalDiagnosis ? \"none\" : \"\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",position:\"relative\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Medical Term"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 17,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "txt-medicalterm"
        },
        "loading": {
          "type": "code",
          "value": "props?.loadingmedTermPrincipal"
        },
        "name": {
          "type": "value",
          "value": "medterm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSearchTextChange(\"medTermPrincipal\")"
        },
        "placeholder": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.medTermPrincipal"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ICD9CM Term"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 17,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "txt-icd9term"
        },
        "loading": {
          "type": "code",
          "value": "props?.loadingICD9CMTermPrincipal"
        },
        "name": {
          "type": "value",
          "value": "icdterm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSearchTextChange(\"ICD9CMTermPrincipal\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ICD9CMTermPrincipal"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ICD9CM Code"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการ"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "btn-addDiagnosis"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddPrincipal"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 26,
      "name": "Table",
      "parent": 25,
      "props": {
        "data": {
          "type": "code",
          "value": "props.diagnosisListPrincipal"
        },
        "headers": {
          "type": "value",
          "value": "Medical Term, ICD9CM, ICD9CM"
        },
        "id": {
          "type": "value",
          "value": "tb-diagListPrinciple"
        },
        "keys": {
          "type": "value",
          "value": "icd9cm_med_term,icd9cm_term,icd9cm_code"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\", marginTop: \"0.5rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\",  position: \"relative\", display : props.hideSecondaryDiagnosis ? \"none\" : \"\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",position:\"relative\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "Medical Term"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\",minWidth: \"max-content\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Input",
      "parent": 28,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-Input-SecondMedicalTerm"
        },
        "loading": {
          "type": "code",
          "value": "props?.loadingMedTermSecondary"
        },
        "name": {
          "type": "value",
          "value": "medterm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSearchSecondaryChange(\"medTermSecondary\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.medTermSecondary"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "ICD9CM Term"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\",minWidth: \"max-content\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 28,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-Input-SecondIcd9Term"
        },
        "loading": {
          "type": "code",
          "value": "props?.loadingICD9MTermSecondary"
        },
        "name": {
          "type": "value",
          "value": "icdterm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSearchSecondaryChange(\"ICD9MTermSecondary\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.ICD9MTermSecondary"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "ICD9CM Code"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\",minWidth: \"max-content\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Button",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการ"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-Button-AddSecondProcedure"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddSecondary"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\",minWidth: \"max-content\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 37,
      "name": "Table",
      "parent": 36,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.diagnosisListSecondary"
        },
        "getTrProps": {
          "type": "code",
          "value": "props?.secondaryRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Medical Term,ICD9CM Term,ICD9CM Code"
        },
        "keys": {
          "type": "value",
          "value": "icd9cm_med_term,icd9cm_term,icd9cm_code"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\", marginTop: \"0.5rem\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "label": "divgrid",
      "name": "div",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"350px\", padding: \"10px\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ทำหัตถการ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/3\", gridColumn: \"1/7\",  padding: \"5px\", display: \"flex\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 38,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "txt-responsible"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", gridRow: \"1/3\", gridColumn: \"8/41\",  }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดเพิ่มเติม"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/7\", gridColumn: \"1/7\",  padding: \"5px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 38,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\",gridRow: \"3/7\", gridColumn: \"8/41\", }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "อุปกรณ์"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/11\", gridColumn: \"1/7\",  padding: \"5px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{  padding: \"5px\", gridRow: \"7/11\", gridColumn: \"8/41\",  }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form --readOnly --grey"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/41\"  }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 38,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"1/41\", display: \"flow-root\", justifyContent: 'flex-end', }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Dropdown",
      "parent": 41,
      "props": {
        "compact": {
          "type": "code",
          "value": "true"
        },
        "error": {
          "type": "code",
          "value": "props?.toBePerformMissing"
        },
        "fluid": {
          "type": "code",
          "value": " true"
        },
        "id": {
          "type": "value",
          "value": "cb-responsible"
        },
        "onChange": {
          "type": "code",
          "value": "props?.handleChangeValue(\"toBePerformer\")"
        },
        "options": {
          "type": "code",
          "value": "props?.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props?.toBePerformer"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "TextArea",
      "parent": 43,
      "props": {
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-TextArea-OrderDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props?.handleChangeValue(\"orderDetail\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", border: \"solid #cccccc 1px\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.orderDetail"
        }
      },
      "seq": 54,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "TextArea",
      "parent": 45,
      "props": {
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-TextArea-EquipmentDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props?.handleChangeValue(\"equipmentDetail\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", border: \"solid #cccccc 1px\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.equipmentDetail"
        }
      },
      "seq": 55,
      "void": true
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "Principal Procedure"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "Secondary Procedure"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Input",
      "parent": 17,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "txt-icd9code"
        },
        "loading": {
          "type": "code",
          "value": "props?.loadingICD9CMCodeprincipal"
        },
        "name": {
          "type": "value",
          "value": "icdcode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSearchTextChange(\"ICD9CMCodeprincipal\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.ICD9CMCodeprincipal"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Input",
      "parent": 28,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-Input-SecondIcd9Code"
        },
        "loading": {
          "type": "code",
          "value": "props?.loadingICD9MCodeSecondary"
        },
        "name": {
          "type": "value",
          "value": "icdcode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSearchSecondaryChange(\"ICD9MCodeSecondary\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ICD9MCodeSecondary"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\", width: \"100%\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchTreatmentListBox"
        },
        "id": {
          "type": "value",
          "value": "txt-searchTreatment"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showSearchResult ? \"block\" : \"none\", position: \"absolute\", zIndex: 100, width: \"calc(100% - 20px)\", height: \"400px\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 66,
      "name": "Table",
      "parent": 65,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.icd9Search"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props?.icd9RowProps"
        },
        "headers": {
          "type": "value",
          "value": "Medical Term,ICD9CM,ICD9CM"
        },
        "keys": {
          "type": "value",
          "value": "medterm,icdterm,icdcode"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"150px\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showSearchResultSecondary ? \"block\" : \"none\", position: \"absolute\", zIndex: 100, width: \"calc(100% - 20px)\", height: \"400px\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 68,
      "name": "Table",
      "parent": 67,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.secondarySearch"
        },
        "getTrProps": {
          "type": "code",
          "value": "props?.secondaryRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Medical Term,ICD9CM,ICD9CM"
        },
        "keys": {
          "type": "value",
          "value": "medterm,icdterm,icdcode"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"150px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-Div-TreatmentOrderScreen"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleName || \"Treatment\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.25rem\", padding: \"20px 10px 10px\" }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.checkOutPerformDiv ? \"grid\" : \"flex\", margin: \"1rem 1rem 0 0\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", ...(props.hidePreOrder && {display: \"none\"})}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\", ...(props.checkOutPerformDiv && {marginTop: \"1rem\"}), ...(props.showPreOrder && {display: \"none\"})}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Checkbox",
      "parent": 91,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.preOrderCheck || false"
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-Checkbox-PreOrderCheck"
        },
        "label": {
          "type": "code",
          "value": "props.checkOutPerformDiv ? \"คำสั่งนอกแผนก\" : \"สั่ง Treatment ล่วงหน้า\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckPreOrder"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 98,
      "name": "DateTextBox",
      "parent": 97,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props?.preOrderCheck "
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-DateTextBox-PreOrderDate"
        },
        "minDate": {
          "type": "code",
          "value": "props?.minDate"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangePreOrderDate(\"preOrderDate\")"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"1rem\", ...(props.checkOutPerformDiv && {display: \"none\"})}"
        },
        "value": {
          "type": "code",
          "value": "props?.preOrderDate"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Dropdown",
      "parent": 97,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props?.preOrderCheck "
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangePreOrderDate(\"preOrderDate\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOpdOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\",marginTop:\"5px\", ...(!props.checkOutPerformDiv && {display: \"none\"})}"
        },
        "value": {
          "type": "code",
          "value": "props?.preOrderDate || \"\""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Input",
      "parent": 90,
      "props": {
        "id": {
          "type": "value",
          "value": "CardTreatmentOrder-Input-TemplateOrder"
        },
        "placeholder": {
          "type": "code",
          "value": "props.checkOutPerformDiv  ? \"\" : \"template\""
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Button",
      "parent": 90,
      "props": {
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "onClick": {
          "type": "code",
          "value": "props?.onSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "Template"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", ...(!props.checkOutPerformDiv && {display: \"none\"})}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.checkOutPerformDiv ?  \"none\": \"\",padding: \"0px 10px 20px 10px\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 104,
      "name": "Table",
      "parent": 103,
      "props": {
        "data": {
          "type": "code",
          "value": "props.OrderTreatments"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.orderRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Treatment Detail,Care provider,Del,Edit,Status"
        },
        "id": {
          "type": "value",
          "value": "tb-tratementOrderStatus"
        },
        "keys": {
          "type": "value",
          "value": "detail,careprovider,delete,edit,status"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"250px\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,230px,80px,80px,130px"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormGroup",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคา"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Input",
      "parent": 110,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props?.treatmentOrder?.price_total||\"0\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Input",
      "parent": 112,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props?.treatmentOrder?.price_claimable||\"0\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Input",
      "parent": 114,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props?.treatmentOrder?.price_non_claimable ||\"\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 129,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveOrder"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.hideSaveButton && { display: \"none\" }) }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 129,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirmOrder"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.hideSaveButton && { display: \"none\" }) }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderByBox"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"left\" }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 129,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardTreatmentOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "showTreatmentList": true
  },
  "width": 80
}

*********************************************************************************** */
