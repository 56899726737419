import React, { useEffect, useMemo, CSSProperties, useCallback } from "react";
import { Button } from "semantic-ui-react";
// UX
import CardDoctorPendingTaskUX from "./CardDoctorPendingTaskUX";
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import ModInfo from "react-lib/apps/common/ModInfo";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

const TASK_STATUS = {
  ACKNOWLEDGED: "ACKNOWLEDGED",
  PENDING: "PENDING",
};

const CARD_DOCTOR_PENDING_TASK = "CardDoctorPendingTask";

const CardDoctorPendingTask = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorPendingTask",
      restart: true,
      card: CARD_DOCTOR_PENDING_TASK,
    });
    return () => {
      props.runSequence({ sequence: "DoctorPendingTask", clear: true });
    };
  }, []);

  const doctorPendingTaskOptions = useMemo(() => {
    return [
      {
        key: "all",
        text: "แสดงทุกสถานะ",
        value: "all",
      },
      ...(props.masterOptions.doctorPendingTask || []),
    ];
  }, [props.masterOptions.doctorPendingTask]);

  const handleEditRow = useCallback((params: any) => {
    props.runSequence({
      sequence: "DoctorPendingTask",
      action: "edit",
      ...params,
      card: CARD_DOCTOR_PENDING_TASK,
    });
  }, []);

  const handleGetTdProps = useCallback(
    (state: any, rowInfo: any = {}, column: any, instance: any) => {
      if (!rowInfo?.original) {
        return {};
      }

      const style = {} as CSSProperties;
      const { status, approve_late, ack_result, ack_comments } =
        rowInfo.original;

      if (status === TASK_STATUS.ACKNOWLEDGED) {
        style.background = "white";
        style.color = "#2185D0";
      } else if (status === TASK_STATUS.PENDING) {
        style.background = "#FBBD08";
      }

      if (approve_late) {
        style.background = "#EB5757";
        style.color = "";
      }

      if (status === TASK_STATUS.PENDING) {
        if (ack_result === "APPROVE" && ack_comments === "") {
          style.background = "#F0FFD7";
          style.color = "";
        } else if (ack_result === "EDIT" || ack_comments !== "") {
          style.background = "#FFECD7";
          style.color = "";
        }
      }

      return {
        style,
      };
    },
    [props.DoctorPendingTaskSequence?.items]
  );

  const doctorPendingTaskItems = useMemo(() => {
    return (props.DoctorPendingTaskSequence?.items || []).map(
      (item: any, index: number) => ({
        ...item,
        patient_name: (
          <div style={{ lineHeight: 1.75, display: "grid" }}>
            <label>{item.patient_name}</label>
            <label>{item.editor}</label>
            <label>{item.edited}</label>
          </div>
        ),
        detail: <div dangerouslySetInnerHTML={{ __html: item.detail }} />,
        menu: (
          <ButtonApprove
            item={item}
            onClick={() =>
              handleEditRow({ data: item, field: "approve", index })
            }
          />
        ),
        comment:
          item.status === TASK_STATUS.PENDING ? (
            <EditorColumn
              value={item.ack_comments}
              type="textarea"
              align="left"
              valueOnFocus={true}
              // style
              backgroundColor={"transparent"}
              onDataChanged={(value: string) =>
                handleEditRow({
                  data: item,
                  field: "ack_comments",
                  index,
                  value,
                })
              }
            />
          ) : (
            item.status === TASK_STATUS.ACKNOWLEDGED && (
              <div>{item.ack_comments}</div>
            )
          ),
      })
    );
  }, [props.DoctorPendingTaskSequence?.items]);

  const handleChangePatient = (id: any, hn: any) => {
    handleChangeValue(null, { name: "hn", hn });
  };

  const handleChangeValue = (e: any, v: any) => {
    props.setProp(`DoctorPendingTaskSequence.${v.name}`, v.value);
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "DoctorPendingTask",
      action: "search",
      card: CARD_DOCTOR_PENDING_TASK,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "DoctorPendingTask",
      action: "save",
      card: CARD_DOCTOR_PENDING_TASK,
    });
  };

  const handleCloseModError = () => {
    props.setProp(`errorMessage.DoctorPendingTask`, null);
  };

  return (
    <div style={{ margin: "1rem" }}>
      <ModInfo
        open={props.errorMessage?.["DoctorPendingTask"]}
        titleName="ไม่สามารถบันทึกได้"
        titleColor={"red"}
        onApprove={handleCloseModError}
        onClose={handleCloseModError}
      >
        <div style={{ padding: "0.5rem 0", lineHeight: 1.5 }}>
          {typeof props.errorMessage?.["DoctorPendingTask"] === "string" ? (
            <div
              dangerouslySetInnerHTML={{
                __html: props.errorMessage?.["DoctorPendingTask"],
              }}
            />
          ) : (
            <ErrorMessage error={props.errorMessage?.["DoctorPendingTask"]} />
          )}
        </div>
      </ModInfo>

      <CardDoctorPendingTaskUX
        // data
        doctorPendingTaskOptions={doctorPendingTaskOptions}
        status={props.DoctorPendingTaskSequence?.status || ""}
        onChangeValue={handleChangeValue}
        loading={props.loadingStatus?.[CARD_DOCTOR_PENDING_TASK]}
        items={doctorPendingTaskItems}
        // getTrProps={handleGetTrProps}
        getTdProps={handleGetTdProps}
        // callback
        onSearch={handleSearch}
        onSave={handleSave}
        // Element
        SearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            onEnterPatientSearch={handleChangePatient}
          />
        }
        StartDate={
          <DateTextBox
            value={props.DoctorPendingTaskSequence?.startDate}
            onChange={(date: string) =>
              handleChangeValue(null, { name: "startDate", value: date })
            }
          />
        }
        EndDate={
          <DateTextBox
            value={props.DoctorPendingTaskSequence?.endDate}
            onChange={(date: string) =>
              handleChangeValue(null, { name: "endDate", value: date })
            }
          />
        }
      />
    </div>
  );
};

export const ButtonApprove = (props: any) => {
  const buttonColor: any = useMemo(() => {
    let color = "";
    const { status, ack_result, ack_comments } = props.item || {};

    if (status === TASK_STATUS.ACKNOWLEDGED) {
      if (ack_result === "APPROVE") {
        color = "green";
      } else if (ack_result === "EDIT") {
        color = "orange";
      }
    } else if (status === TASK_STATUS.PENDING) {
      if (ack_result === "APPROVE" && ack_comments === "") {
        color = "green";
      } else if (ack_result === "EDIT" || ack_comments !== "") {
        color = "orange";
      }
    }

    return color;
  }, [props.item]);

  const handleClick = () => {
    if (props.item?.status === TASK_STATUS.PENDING) {
      props.onClick?.();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        icon="checkmark"
        color={buttonColor}
        onClick={handleClick}
      ></Button>
    </div>
  );
};

export default React.memo(CardDoctorPendingTask);
