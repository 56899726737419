import {
  ColumnProperties,
  Content,
  ContentColumns,
  ContentStack,
  TDocumentDefinitions,
} from "pdfmake/interfaces";

import { getLogoReportNResize, getWidth } from "./CommonInterface";

import CONFIG from "config/config";

// Types
type ComponyLogoFormProps = {
  font: FontType;
  form: string;
  height: number;
  hideLogo?: CompanyType[];
  isHorizontal?: boolean;
  maxWidth?: number;
  showAddress?: CompanyType[];
  type?: number;
};

export type CompanyType = "CU" | "SAPIENS";

export type FontType = "KanitLM" | "PoppinsLM" | "THSarabunNew";

type CompanyLogoLayoutConfig = {
  addressWidth: number;
  fontSizes: Record<number, number>;
  lineHeights: Record<number, number>;
  logoHeight: number;
  logoWidth: number;
} & ColumnProperties &
  ContentColumns &
  Pick<TDocumentDefinitions, "images">;

interface CustomContentStack extends ContentStack {
  stack: ({ scale?: number } & Content)[];
}

const COMPANY_LOGO: Partial<
  Record<
    string,
    {
      address: { horizontal?: CustomContentStack; vertical: CustomContentStack };
      marginTop?: number;
    }
  >
> = {
  CU: {
    address: {
      horizontal: {
        stack: [
          { bold: true, lineHeight: 0.8, text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย" },
          { bold: true, text: "Chulalongkorn University Faculty of Dentistry Hospital" },
        ],
      },
      vertical: {
        stack: [
          { bold: true, lineHeight: 0.8, text: "โรงพยาบาลคณะทันตแพทยศาสตร์" },
          { bold: true, text: "จุฬาลงกรณ์มหาวิทยาลัย" },
          { lineHeight: 0.8, text: "ถนนอังรีดูนังต์ ปทุมวัน กรุงเทพฯ 10330" },
          { text: "โทร 02-218-8705" },
        ],
      },
    },
  },
  SAPIENS: {
    address: {
      vertical: {
        marginLeft: 5,
        stack: [
          { bold: true, lineHeight: 1, marginTop: -2.5, scale: 1.9, text: "โรงพยาบาล เซเปี้ยนซ์" },
          { lineHeight: 0.8, scale: 1.2, text: "498/7 ซอยรามคำแหง 39 (เทพลีลา1)" },
          { scale: 1.2, text: "แขวงวังทองหลาง เขตวังทองหลาง กรุงเทพฯ 10310 โทร 02-111-3703" },
        ],
      },
    },
  },
};

const generateAdjustedValues = (
  start: number,
  end: number,
  step: number,
  fontOffset: number
): Record<number, number> => {
  const length = Math.floor((end - start) / step) + 1;
  const entries = Array.from({ length }, (unused, index) => {
    const value = Number.parseFloat((start + index * step).toFixed(2));
    const adjustedValue = Number.parseFloat((value * fontOffset).toFixed(2));

    return [value, adjustedValue] as [number, number];
  });

  return Object.fromEntries(entries);
};

const generateFontSize = (fontType: FontType): Record<number, number> => {
  const fontOffset = ["KanitLM", "PoppinsLM"].includes(fontType) ? 0.75 : 1;

  return generateAdjustedValues(0, 30, 0.05, fontOffset);
};

const generateLineHeight = (fontType: FontType): Record<number, number> => {
  const fontOffset = ["KanitLM", "PoppinsLM"].includes(fontType) ? 1.125 : 1;

  return generateAdjustedValues(0, 3, 0.05, fontOffset);
};

const ComponyLogoForm = async (props: ComponyLogoFormProps): Promise<CompanyLogoLayoutConfig> => {
  const type = props.type || 1;

  const companyName: CompanyType = CONFIG.COMPANY || "";
  const companyLogo = COMPANY_LOGO[companyName];
  const { font, form, height, isHorizontal, maxWidth } = props;

  let logoHeight = height;
  let marginTop = -1.5;

  const logo = await getLogoReportNResize(logoHeight, type, form);

  if (maxWidth && logo.width > maxWidth) {
    logoHeight *= maxWidth / logo.width;
    logo.width = maxWidth;

    marginTop += (height - logoHeight) / 2;
  }

  // 140: Address width
  const fontSizes = generateFontSize(font);
  const lineHeights = generateLineHeight(font);
  const align = isHorizontal ? "horizontal" : "vertical";
  const showAddress = !!companyLogo?.address && !!props.showAddress?.includes(companyName);

  const stack =
    showAddress && companyLogo.address[align]?.stack
      ? companyLogo.address[align].stack
      : [{ text: "" }];

  const fontSize = Object.keys(fontSizes).find((size) => {
    const height = stack.length * (Number(size) + 2);
    const difference = Math.abs(logoHeight - height);

    return difference >= 0 && difference <= 1;
  });
  const textWidths = stack.map((data: any) => getWidth(data.text, Number(fontSize)));
  const addressWidth = Math.max(...textWidths) + 5;

  const width = addressWidth + logo.width;

  const address = companyLogo?.address[align];

  const hideLogo = !!CONFIG.HIDE_COMPANY_LOGO_PRINT || !!props.hideLogo?.includes(companyName);

  if (address?.stack) {
    address.stack = address.stack.map((content) => {
      if (typeof content === "object") {
        return {
          ...content,
          ...(content.scale && { fontSize: Number(fontSize) * content.scale }),
        } as Content;
      }

      return content;
    });
  }

  return {
    addressWidth,
    columns: hideLogo
      ? []
      : [
          {
            height: logoHeight,
            image: "logo",
            marginTop,
            width: logo.width,
          },
          showAddress ? { ...(address as ContentStack), fontSize: Number(fontSize) } : { text: "" },
        ],
    font,
    fontSizes,
    images: {
      logo: logo.src ? `${origin}${logo.src}` : "",
    },
    lineHeights,
    logoHeight,
    logoWidth: logo.width,
    width,
  };
};

export default ComponyLogoForm;
