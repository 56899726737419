import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  MutableRefObject,
} from "react";
import {
  Form,
  Button,
  Modal,
  Icon,
  Menu,
  Tab,
  TabProp,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";

import axios from "axios";
import { fabric } from "fabric";
import { GithubPicker, ColorResult } from "react-color";
import { ModConfirm as ModConfirmSave } from "react-lib/apps/common";
import { useIntl } from "react-intl";
import "../../css/ModalEditImage.css";

const COLOR_PALETTE = [
  "#000000",
  "#7f7f7f",
  "#880015",
  "#ed1c24",
  "#ff7f27",
  "#fff200",
  "#22b14c",
  "#00a2e8",
  "#3f48cc",
  "#a349a4",
  "#ffffff",
  "#c3c3c3",
  "#b97a57",
  "#ffaec9",
  "#ffc90e",
  "#efe4b0",
  "#b5e61d",
  "#99d9ea",
  "#7092be",
  "#c8bfe7",
];

type ModalEditImageType = {
  hiddenTab?: boolean;
  openEditModal: boolean;
  onCloseModal: (close: boolean) => any;
  cancelImage: () => any;
  editedImage: (dataUrl: string) => any;
  editedImageOverlay?: (image: any) => any;
  maxWidth?: number;
  image: {
    image: string;
  };
  activeTabs?: string[];
  imageOverlay?: any[];
  hideBabyTooth?: boolean | null;
  hidePeriodontium?: boolean;
};

const ModalEditImageInitialState: ModalEditImageType = {
  hiddenTab: true,
  openEditModal: false,
  onCloseModal: () => null,
  cancelImage: () => null,
  editedImage: () => null,
  editedImageOverlay: () => null,
  maxWidth: 1400,
  image: {
    image: "",
  },
  activeTabs: [],
  imageOverlay: [],
  hideBabyTooth: null,
  hidePeriodontium: false,
};

const XGA_WIDTH = 1048;
const WXGA_WIDTH = 1390;

// ========= Static variables =========
const TOOL_OFFSET = 50; // Offset div for tool
const BG_COLOR = "#D5D8DC";
const SELECT_CORNOR_SIZE = 6;
const FREEHAND_COLOR = "#ff3300";
const FREEHAND_STROKE_WIDTH = 3;
const ANNOTATION_MARKER_SIZE = 10;
const ANNOTATION_MARKER_OPACITY = 0.7;
const ANNOTATION_MARKER_COLOR = "#EFA8A8";
const ANNOTATION_TEXT_SIZE = 20;
const ANNOTATION_TEXT_COLOR = "#ff3300";
const DEFAULT_TAB_INDEX = 0;

async function fetchImageObjectUrl(url: string) {
  try {
    const response = await axios.get(url, { responseType: "arraybuffer" });
    const blob = new Blob([response.data], { type: response.headers["content-type"] });

    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error fetching or converting image:", error);

    return url;
  }
}

const ModalEditImage: React.FC<ModalEditImageType> = (props) => {
  const intl = useIntl();
  const fabricCanvasRef = useRef(null) as any;
  const [selectTool, setSelectTool] = useState(0);
  const [colorPicker, setColorPicker] = useState<ColorResult>(COLOR_PALETTE[0]);
  const [activeIndex, setActiveIndex] = useState(DEFAULT_TAB_INDEX);
  const [activePanes, setActivePanes] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // const [stackFabric, setStackFabric] = useState<any[]>([]);
  const [hideBabyTooth, setHideBabyTooth] = useState<boolean | null>();
  const [openModConfirmSave, setOpenModConfirmSave] = useState(false);

  const isMounted = useRef<any>(false);
  const isPressControl = useRef<boolean>(false);
  const isPermanentTeeth = useRef(false) as MutableRefObject<boolean>;
  const stackFabricRef = useRef<any[]>([]);

  var canvas: any;

  useEffect(() => {
    if (
      props.openEditModal &&
      fabricCanvasRef.current === null &&
      props.hiddenTab
    ) {
      createDrawingFabric();
    }
  }, [props.openEditModal]);

  useEffect(() => {
    if (props.hiddenTab) {
      return;
    }

    const panes = PANES.filter((pane) => props.activeTabs?.includes(pane.key));
    if (!panes[0]) {
      panes.push(PANES[0]);
    }

    if (
      panes.find((o) => o.key === "tooth") &&
      !panes.find((o: any) => o.key === "odontogram")
    ) {
      panes.splice(1, 0, PANES[1]);
    }

    console.log("PANES SET: ", panes);
    if (!panes.find((o) => o.key === "tooth")) {
      panes.splice(0, 0, PANES[0]);
    }

    setActivePanes(panes);
    setActiveIndex(-1);
  }, [props.activeTabs]);

  useEffect(() => {
    if (activeIndex === -1) {
      setActiveIndex(
        activePanes[DEFAULT_TAB_INDEX]?.key === "tooth" ? 1 : DEFAULT_TAB_INDEX
      );
      isMounted.current = true;
    }
  }, [activeIndex]);

  useEffect(() => {
    if (
      props.openEditModal &&
      fabricCanvasRef.current === null &&
      !props.hiddenTab &&
      activeIndex !== -1
    ) {
      createDrawingFabric();
    }
  }, [activePanes, activeIndex]);

  useEffect(() => {
    if (fabricCanvasRef.current && !props.hiddenTab) {
      fabricCanvasRef.current = null;
      createDrawingFabric();
    }
  }, [hideBabyTooth]);

  useEffect(() => {
    setHideBabyTooth(props.hideBabyTooth);
  }, [props.hideBabyTooth]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardShortcut);
    document.addEventListener("keyup", handleKeyup, false);

    return () => {
      document.removeEventListener("keydown", handleKeyboardShortcut);
      document.removeEventListener("keyup", handleKeyup, false);
    };
  }, []);

  useEffect(() => {
    document.onkeydown = (event: any) => {
      const permanent_teeth =
        activePanes?.[activeIndex]?.key === "tooth" && hideBabyTooth;

      if (
        event.key === "Control" &&
        !isPressControl.current &&
        permanent_teeth
      ) {
        fabricDragMode(true);
        isPressControl.current = true;
      }
    };
    return () => {
      document.onkeydown = null;
    };
  }, [activePanes, activeIndex, hideBabyTooth]);

  const createDrawingFabric = async () => {
    const image = props.hiddenTab ? props.image : activePanes[activeIndex];
    //เพื่อทำให้เปิด tooth ได้ถูกภาพ โดย check จาก boolean
    if (
      !image ||
      (!props.hiddenTab && typeof props.hideBabyTooth !== "boolean")
    ) {
      return;
    }
    let imageFabric = image.image;
    let imageKey = image.key;
    if (!props.hiddenTab) {
      imageFabric =
        image.key === "tooth"
          ? !hideBabyTooth
            ? image.children.primary_teeth.image
            : image.children.permanent_teeth.image
          : image.image;
      imageKey =
        image.key === "tooth"
          ? !hideBabyTooth
            ? Object.keys(image.children)[0]
            : Object.keys(image.children)[1]
          : image.key;
    }

    const objectUrl = await fetchImageObjectUrl(imageFabric);

    fabric.Image.fromURL(
      objectUrl,
      function (myImg: any) {
        console.log("function Image set: ", props.hiddenTab);
        if (myImg && myImg._element && myImg.width > 0 && myImg.height > 0) {
          // TODO: scale logic have paronama bug
          if (myImg.width > (props.maxWidth || 0)) {
            myImg.scaleToWidth(props.maxWidth, false);
          }
          const width = myImg.width * myImg.scaleX;
          const height = myImg.height * myImg.scaleY;

          if (!props.hiddenTab) {
            const cloneElm = document.querySelector("#body-image-canvas");
            if (cloneElm) {
              cloneElm.innerHTML = '<canvas id="ImageCanvas" />';
            }
          }
          canvas = new fabric.Canvas("ImageCanvas", {
            preserveObjectStacking: true,
            backgroundColor: props.hiddenTab ? "red" : "white",
            width,
            height,
            padding: 0,
            margin: "auto",
            hoverCursor: "pointer",
          });

          fabricCanvasRef.current = canvas;

          // issue 53771
          if (window.innerWidth <= WXGA_WIDTH && props.hiddenTab) {
            const modal = document.querySelector(".modal-edit-image") as HTMLDivElement;

            if (modal?.offsetWidth < width) {
              const diff = width - modal.offsetWidth + 10;
              const zoom = (100 - (diff / width) * 100) / 100;
              handleCropCanvas(width, height, zoom);
            }
          }

          if (!props.hiddenTab) {
            const stack = stackFabricRef.current.find((fabric) => fabric.key === imageKey);
            if (stack) {
              console.log("stack", stack, stack.fabric.getObjects());
              for (const itemFabric of stack.fabric.getObjects()) {
                fabricCanvasRef.current?.add?.(itemFabric);
              }
            } else {
              const [removeOnSave, notRemoveOnSave] = props.imageOverlay?.reduce(
                (result, item) => {
                  result[item.remove_on_save ? 0 : 1].push(item);
                  return result;
                },
                [[], []]
              );

              const concatArray = [...removeOnSave, notRemoveOnSave[0]];

              console.log("concatArray", concatArray);

              for (const overlay of concatArray) {
                if (overlay?.image) {
                  const pugImg = new Image();
                  pugImg.crossOrigin = "anonymous";
                  pugImg.onload = function (img) {
                    const targetImage = img.target as HTMLImageElement;
                    const pug = new fabric.Image(pugImg, {
                      width: targetImage.width,
                      height: targetImage.height,
                      remove_on_save: overlay.remove_on_save,
                      is_image_overlay: true,
                      // selectable: false,
                      selectable: overlay.remove_on_save ? false : true,
                    });
                    canvas.add(pug);
                  };
                  pugImg.src = overlay.image[imageKey];
                }
              }
            }

            isPermanentTeeth.current = false;

            if (imageKey === "permanent_teeth") {
              isPermanentTeeth.current = true;
              canvas.on("mouse:wheel", function (opt: any) {
                const delta = opt.e.deltaY;
                let zoom = canvas.getZoom();
                zoom *= 0.999 ** delta;
                if (zoom > 20) zoom = 20;
                if (zoom < 0.01) zoom = 0.01;

                canvas.setZoom(zoom);
                opt.e.preventDefault();
                opt.e.stopPropagation();
              });
            }

            // issue 53771
            if (window.innerWidth <= XGA_WIDTH) {
              if (
                ["primary_teeth", "permanent_teeth", "muscle", "soft", "jaw"].includes(imageKey)
              ) {
                canvas.setZoom(0.75);
                handleCropCanvas(width, height, 0.75);
              }
              if (["periodontium"].includes(imageKey)) {
                canvas.setZoom(0.85);
                handleCropCanvas(width, height, 0.85);
              }
            }
          }

          console.log("createStackFabric: ", imageKey);
          createStackFabric(imageKey);

          canvas.setBackgroundImage(myImg, function () {
            canvas.renderAll();
          });
        }
      },
      { crossOrigin: "anonymous" }
    );
  };

  // issue 53771
  const handleCropCanvas = (width: number, height: number, zoom: number) => {
    canvas.setZoom(zoom);

    const container = document.querySelector(
      ".canvas-container"
    ) as HTMLDivElement;

    if (container) {
      container.style.height = `${height * zoom}px`;
      container.style.width = `${width * zoom}px`;
      container.style.overflow = "hidden";
    }
  };

  const fabricDragMode = (mode: boolean) => {
    if (!fabricCanvasRef.current) {
      return;
    }

    const STATE_IDLE = "idle";
    const STATE_PANNING = "panning";
    const canvas = fabricCanvasRef.current;

    fabric.Canvas.prototype.toggleDragMode = function (dragMode: boolean) {
      // Remember the previous X and Y coordinates for delta calculations
      let lastClientX: any;
      let lastClientY: any;
      // Keep track of the state
      let state = STATE_IDLE;
      // We're entering dragmode
      if (dragMode) {
        // Discard any active object
        this.discardActiveObject();
        // Set the cursor to 'move'
        this.defaultCursor = "move";
        // Loop over all objects and disable events / selectable. We remember its value in a temp variable stored on each object
        this.forEachObject(function (object: any) {
          object.prevEvented = object.evented;
          object.prevSelectable = object.selectable;
          object.evented = false;
          object.selectable = false;
        });
        // Remove selection ability on the canvas
        this.selection = false;
        // When MouseUp fires, we set the state to idle
        this.on("mouse:up", function (e: any) {
          state = STATE_IDLE;
        });
        // When MouseDown fires, we set the state to panning
        this.on("mouse:down", (e: any) => {
          state = STATE_PANNING;
          lastClientX = e.e.clientX;
          lastClientY = e.e.clientY;
        });
        // When the mouse moves, and we're panning (mouse down), we continue
        this.on("mouse:move", (e: any) => {
          if (state === STATE_PANNING && e && e.e) {
            // let delta = new fabric.Point(e.e.movementX, e.e.movementY); // No Safari support for movementX and movementY
            // For cross-browser compatibility, I had to manually keep track of the delta

            // Calculate deltas
            let deltaX = 0;
            let deltaY = 0;
            if (lastClientX) {
              deltaX = e.e.clientX - lastClientX;
            }
            if (lastClientY) {
              deltaY = e.e.clientY - lastClientY;
            }
            // Update the last X and Y values
            lastClientX = e.e.clientX;
            lastClientY = e.e.clientY;

            let delta = new fabric.Point(deltaX, deltaY);
            this.relativePan(delta);
            // this.trigger("moved");
          }
        });
      } else {
        // When we exit dragmode, we restore the previous values on all objects
        this.forEachObject(function (object: any) {
          object.evented =
            object.prevEvented !== undefined
              ? object.prevEvented
              : object.evented;
          object.selectable =
            object.prevSelectable !== undefined
              ? object.prevSelectable
              : object.selectable;
        });
        // Reset the cursor
        this.defaultCursor = "default";
        // Remove the event listeners
        this.off("mouse:up");
        this.off("mouse:down");
        this.off("mouse:move");
        // Restore selection ability on the canvas
        this.selection = true;
      }
    };
    canvas.toggleDragMode(mode);
  };

  const tabListOptions = useMemo(() => {
    const panes = PANES.filter(
      (pane) => !activePanes.find((item) => item.key === pane.key)
    );
    return [
      { key: 0, text: "ไม่เลือก", value: null },
      ...(panes
        .filter((item) =>
          props?.hidePeriodontium ? item.key !== "periodontium" : item
        )
        .map((item, index) => ({
          key: index + 1,
          text: item.name,
          value: item.key,
        })) || []),
    ];
  }, [activePanes]);

  const handleKeyboardShortcut = useCallback((event: any) => {
    // Key code
    const KEY_DEL = 8;
    const KEY_DELETE = 46;
    const KEY_ESC = 27;
    const KEY_1 = 49;
    const KEY_2 = 50;
    const KEY_3 = 51;

    if (event.keyCode == KEY_DEL || event.keyCode == KEY_DELETE) {
      // Remove selected object
      if (fabricCanvasRef && fabricCanvasRef.current) {
        if (
          fabricCanvasRef.current.getActiveObject()?.hoverCursor === "text" &&
          event.keyCode == KEY_DEL
        ) {
          return;
        }

        if (fabricCanvasRef.current.getActiveObject() != null) {
          // Clear for single selection
          console.log(
            "fabricCanvasRef.current",
            fabricCanvasRef.current.getActiveObjects()
          );

          fabricCanvasRef.current.getActiveObjects().forEach((obj: any) => {
            fabricCanvasRef.current.remove(obj);
          });
          fabricCanvasRef.current.discardActiveObject().renderAll();

          refreshEditing();
          // fabricCanvasRef.current.getActiveObject().remove();
        }
      }
    }

    if (isPermanentTeeth.current) {
      if (event.key === "Shift") {
        const canvas = fabricCanvasRef.current;
        canvas?.setViewportTransform([1, 0, 0, 1, 0, 0]);
        if (window.innerWidth <= XGA_WIDTH) {
          canvas?.setZoom(0.75);
        } else {
          canvas?.setZoom(1);
        }
      }
    }
  }, []);

  const handleKeyup = useCallback(() => {
    fabricDragMode(false);
    isPressControl.current = false;
  }, []);

  const refreshEditing = () => {
    let edting = false;

    for (const item of stackFabricRef.current) {
      console.log("Fabric Item:", item, item.fabric.getObjects());
      const { fabric } = item;
      for (const itemFabric of fabric.getObjects()) {
        console.log("Fabric Item:", itemFabric);
        if (!itemFabric.is_image_overlay) {
          edting = true;
        }
      }
    }

    setIsEditing(edting);
  };

  const createStackFabric = (imageKey: string) => {
    let stack = [...stackFabricRef.current];
    if (!stack.find((stack) => stack.key === imageKey)) {
      stack.push({
        key: imageKey,
        fabric: fabricCanvasRef.current,
      });
    } else {
      stack = stack.map((item) => {
        return item.key === imageKey
          ? {
              ...item,
              fabric: fabricCanvasRef.current,
            }
          : item;
      });
    }

    stackFabricRef.current = stack;
  };

  const onCloseModalCleanUp = () => {
    fabricCanvasRef.current = null;
    stackFabricRef.current = [];

    props.onCloseModal(false);
    setActiveIndex(0);
    setActivePanes([]);
    setOpenModConfirmSave(false);
    setSelectTool(0);
    setColorPicker(COLOR_PALETTE[0]);
    setIsEditing(false);
  };

  const handleSelectTool = (select: number) => {
    const tool = {
      0: handleOnSelectionTool,
      1: handleFreeHandTool,
      2: handleAnnotationTool,
      3: handleCircleTool,
      4: handleArrowTool,
      5: handleStarTool,
      6: handleLineTool,
    }[select];

    if (![0, 1, 2].includes(select)) {
      setIsEditing(true);
    }
    setSelectTool(select);
    tool?.(fabricCanvasRef.current);
  };

  const handleOnSelectionTool = (canvasSelection: any) => {
    setSelectTool(0);
    canvasSelection.isDrawingMode = false;
    canvasSelection.off("mouse:up"); // Unregister listener
  };

  const handleFreeHandTool = (canvasFreeHand: any) => {
    canvasFreeHand.freeDrawingBrush = new fabric["PencilBrush"](canvasFreeHand);
    canvasFreeHand.freeDrawingBrush.color = colorPicker;
    canvasFreeHand.freeDrawingBrush.width = FREEHAND_STROKE_WIDTH;
    canvasFreeHand.isDrawingMode = true;

    const mouseup = function (options: any) {
      if (options.target == null) {
        refreshEditing();
      }
    };

    canvasFreeHand.off("mouse:up", mouseup); // Unregister listener
    canvasFreeHand.on("mouse:up", mouseup);
  };

  const handleAnnotationTool = (canvasAnnotation: any) => {
    canvasAnnotation.isDrawingMode = false;
    canvasAnnotation.on("mouse:up", function (options: any) {
      if (options.target == null) {
        // Create empty text object and add to canvas with editing mode
        const text = new fabric.IText("", {
          left:
            (options.e.offsetX || options.e.layerX) + ANNOTATION_MARKER_SIZE,
          top: (options.e.offsetY || options.e.layerY) + ANNOTATION_MARKER_SIZE,
          fontSize: ANNOTATION_TEXT_SIZE,
          stroke: colorPicker,
          fill: colorPicker,
          cornerSize: SELECT_CORNOR_SIZE,
        });

        // Handle leaving editing mode with empty text
        text.on("editing:exited", function () {
          if (text.text.trim() === "") {
            // Empty text found, remove the text element
            canvasAnnotation.remove(text);
          }
          // Move to selection tool
          handleOnSelectionTool(canvasAnnotation);
          refreshEditing();
        });

        canvasAnnotation.add(text).setActiveObject(text);
        text.enterEditing();
      }
    });
  };

  const handleCircleTool = (canvas: any) => {
    canvas.isDrawingMode = false;
    const circle = new fabric.Circle({
      radius: 65,
      fill: "transparent",
      left: 0,
      stroke: colorPicker,
      strokeWidth: 3,
    });
    canvas.add(circle);
  };

  const handleStarTool = (canvas: any) => {
    canvas.isDrawingMode = false;
    const polyg = new fabric.Polygon(
      [
        { x: 350, y: 75 },
        { x: 380, y: 160 },
        { x: 470, y: 160 },
        { x: 400, y: 215 },
        { x: 423, y: 301 },
        { x: 350, y: 250 },
        { x: 277, y: 301 },
        { x: 303, y: 215 },
        { x: 231, y: 161 },
        { x: 321, y: 161 },
      ],
      {
        left: 45,
        top: 30,
        fill: "transparent",
        stroke: colorPicker,
        strokeWidth: 15,
        scaleX: 0.18,
        scaleY: 0.18,
      }
    );
    canvas.add(polyg);
  };

  const handleArrowTool = (canvas: any) => {
    canvas.isDrawingMode = false;
    const polyg = new fabric.Polygon(
      [
        { x: 0, y: 12.5 },
        { x: 55, y: 12.5 },
        { x: 37.5, y: -2.5 },
        { x: 45, y: -5 },
        { x: 70, y: 15 },
        { x: 45, y: 35 },
        { x: 37.5, y: 32.5 },
        { x: 55, y: 17.5 },
        { x: 0, y: 17.5 },
      ],
      {
        left: 45,
        top: 45,
        fill: colorPicker,
        scaleX: 0.7,
        scaleY: 0.7,
        angle: -45,
      }
    );
    canvas.add(polyg);
  };

  const handleLineTool = (canvas: any) => {
    canvas.isDrawingMode = false;
    const line = new fabric.Line([0, 100, 100, 100], {
      left: 45,
      top: 90,
      stroke: colorPicker,
      strokeWidth: 3,
      // scaleX: 0.7,
      // scaleY: 0.7,
      angle: -45,
      strokeLineCap: "round",
    });
    canvas.add(line);
  };

  const handleChangeColor = (color: any) => {
    const targetObjectCanvas = fabricCanvasRef.current.getActiveObject();

    // console.log(fabricCanvasRef.current)
    if (targetObjectCanvas) {
      const settle: any = {};
      if (
        targetObjectCanvas.fill &&
        targetObjectCanvas.fill !== "transparent"
      ) {
        settle.fill = color;
      }
      if (targetObjectCanvas.stroke) {
        settle.stroke = color;
      }

      targetObjectCanvas.set(settle);
      fabricCanvasRef.current.renderAll();
    }

    if (fabricCanvasRef.current.isDrawingMode) {
      fabricCanvasRef.current.freeDrawingBrush.color = color;
      fabricCanvasRef.current.renderAll();
    }

    setColorPicker(color);
  };

  const handleTabChange = (data: TabProp) => {
    const cloneElm = fabricCanvasRef.current?.wrapperEl?.parentElement;
    cloneElm.innerHTML = '<canvas id="ImageCanvas" />';
    fabricCanvasRef.current?.wrapperEl?.remove();
    fabricCanvasRef.current = null;
    setSelectTool(0);
    setColorPicker(COLOR_PALETTE[0]);
    setActiveIndex(data.activeIndex);
  };

  const handleSelectTabList = (e: any, v: DropdownProps) => {
    if (v.value) {
      const findPane = PANES.find((pane) => pane.key === v.value);
      setActivePanes([...activePanes, findPane]);
    }
  };

  const handleCheckEditing = () => {
    if (isEditing) {
      setOpenModConfirmSave(true);
    } else {
      handleConfirmSave();
    }
  };

  const handleConfirmSave = () => {
    handleSaveDrawingOverlay();
    props.cancelImage();
    onCloseModalCleanUp();
  };
  const dataURLtoFile = (dataurl: any, filename: string) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  //Usage example:

  const handleSaveDrawingOverlay = () => {
    let dataURI = "";
    const image: any = {};

    const permanent_teeth =
      activePanes?.[activeIndex]?.key === "tooth" && hideBabyTooth;
    if (permanent_teeth) {
      const canvas = fabricCanvasRef.current;
      canvas?.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas?.setZoom(1);
    }

    for (const item of stackFabricRef.current) {
      let isEditing = false;
      const { fabric } = item;

      if (!fabric) {
        continue;
      }

      fabric?.setViewportTransform([1, 0, 0, 1, 0, 0]);
      fabric?.setZoom(1);
      fabric.backgroundImage = null;
      fabric.setBackgroundColor("transparent");
      const getObjects = fabric.getObjects?.() || [];

      for (const itemFabric of getObjects) {
        console.log("FabricItem:", itemFabric);
        if (itemFabric.remove_on_save) {
          fabric.remove(itemFabric);
        }
        if (!itemFabric.is_image_overlay) {
          isEditing = true;
        }
      }

      try {
        dataURI = fabric.toDataURL();
        const findImageOverlay = props.imageOverlay?.find(
          (image) => image?.image?.[item.key] && !image.remove_on_save
        );
        if (isEditing) {
          image[item.key] = {};
          image[item.key].image_file = dataURLtoFile(dataURI, "image.png");
          image[item.key].fully_transparent = false;
        }
        if (findImageOverlay && !getObjects[0]) {
          image[item.key] = {};
          image[item.key].image_file = dataURLtoFile(dataURI, "image.png");
          image[item.key].fully_transparent = true;
        }
      } catch (error) {}
    }

    props.editedImageOverlay?.(image);
    onCloseModalCleanUp();
  };

  const handleConfirmClose = () => {
    onCloseModalCleanUp();
  };

  const ColorPicker = ({
    colors,
    width,
    color,
    onChangeComplete,
    style,
  }: any = {}) => {
    return (
      <div
        className="edit-image-color-picker"
        style={{ width: width, ...style }}
      >
        {colors?.map((colorList: any, idx: number) => (
          <span key={idx}>
            <div className="group-color" key={idx}>
              <span>
                <div
                  className={`box-color${colorList === color ? " active" : ""}`}
                  style={{ backgroundColor: colorList }}
                  onClick={() => onChangeComplete(colorList)}
                ></div>
              </span>
            </div>
          </span>
        ))}
      </div>
    );
  };

  const FormPanelTool = useMemo(
    () => (
      <Form
        className="form-custom-fabric"
        style={{
          padding: "10px 20px 0px 20px",
          ...(props.hiddenTab && { width: "95%" }),
        }}
      >
        <Form.Field
          style={{
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <label
            className="label-edit"
            style={{ paddingRight: "10px", width: "95px" }}
          >
            {" "}
            Edit Image{" "}
          </label>
          <Button
            id="ModalEditImage-Button-Move"
            icon
            color={selectTool == 0 ? "purple" : "grey"}
            onClick={() => {
              handleSelectTool(0);
            }}
          >
            <Icon name="move" />
          </Button>
          <Button
            id="ModalEditImage-Button-Pencil"
            icon
            color={selectTool == 1 ? "purple" : "grey"}
            onClick={() => {
              handleSelectTool(1);
            }}
          >
            <Icon name="pencil" />
          </Button>
          <Button
            id="ModalEditImage-Button-TextCursor"
            icon
            color={selectTool == 2 ? "purple" : "grey"}
            onClick={() => {
              handleSelectTool(2);
            }}
          >
            <Icon name="text cursor" />
          </Button>
          <Button
            id="ModalEditImage-Button-CircleOutline"
            icon
            color={selectTool == 3 ? "purple" : "grey"}
            onClick={() => {
              handleSelectTool(3);
            }}
          >
            <Icon name="circle outline" />
          </Button>
          <Button
            id="ModalEditImage-Button-Arrow"
            icon
            color={selectTool == 4 ? "purple" : "grey"}
            onClick={() => {
              handleSelectTool(4);
            }}
          >
            <Icon
              name="arrow up"
              style={{
                transform: "rotate(45deg)",
              }}
            />
          </Button>
          <Button
            id="ModalEditImage-Button-StarOutline"
            icon
            color={selectTool == 5 ? "purple" : "grey"}
            onClick={() => {
              handleSelectTool(5);
            }}
          >
            <Icon name="star outline" />
          </Button>
          <Button
            id="ModalEditImage-Button-Line"
            icon
            color={selectTool == 6 ? "purple" : "grey"}
            onClick={() => {
              handleSelectTool(6);
            }}
            style={{ padding: ".465em .804em" }}
          >
            <div style={styles.line}></div>
          </Button>
          <ColorPicker
            id="ModalEditImage-ColorPicker-Color"
            colors={COLOR_PALETTE}
            width="100%"
            color={colorPicker}
            onChangeComplete={handleChangeColor}
            style={{ marginLeft: "35px", maxWidth: "615px" }}
          />
          {activePanes?.[activeIndex]?.key === "tooth" && !props.hiddenTab && (
            <div
              className="button-toggle-baby-teeth"
              style={{ margin: "8px 0 0 15px" }}
            >
              <Button
                circular
                icon
                style={{
                  color: "white",
                  backgroundColor: "#2D9CDB",
                  marginBottom: "10px",
                }}
                onClick={() => setHideBabyTooth(!hideBabyTooth)}
              >
                <Icon name={hideBabyTooth ? "unhide" : "hide"} />
              </Button>
              <span style={{ color: "#4f4f4f", paddingLeft: "3px" }}>
                {hideBabyTooth ? "Show Baby Teeth" : "Hide Baby Teeth"}
              </span>
            </div>
          )}
        </Form.Field>
        <div style={{ borderBottom: "1px solid #d4d4d5", marginTop: "-5px" }} />
      </Form>
    ),
    [selectTool, colorPicker, activeIndex, activePanes, hideBabyTooth]
  );

  console.log(activePanes, activeIndex);
  return (
    <Modal
      className="modal-edit-image"
      open={props.openEditModal}
      size="fullscreen"
      onClose={() => {
        if (props.hiddenTab) {
          props.cancelImage();
          onCloseModalCleanUp();
        } else {
          handleCheckEditing();
        }
      }}
      closeOnDimmerClick={false}
      closeIcon={true}
    >
      {props.hiddenTab ? (
        <>
          {FormPanelTool}
          <div style={{ textAlign: "center", minHeight: "295px" }}>
            <canvas id="ImageCanvas" />
          </div>
        </>
      ) : (
        <>
          <Tab
            activeIndex={activeIndex}
            onTabChange={(
              e: React.MouseEvent<HTMLDivElement, MouseEvent>,
              data: TabProp
            ) => {
              if (
                data.activeIndex !== activeIndex &&
                data.activeIndex !== activePanes.length
              ) {
                handleTabChange(data);
              }
            }}
            style={{ margin: "8px" }}
            panes={[
              ...activePanes.map((item, idx) => {
                return {
                  menuItem: (
                    <Menu.Item
                      key={"face" + idx}
                      style={{
                        width: "150px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {item.name}
                    </Menu.Item>
                  ),
                  render: () => (
                    <Tab.Pane>
                      {FormPanelTool}
                      <div style={{ textAlign: "center", minHeight: "200px" }}>
                        <div
                          id="body-image-canvas"
                          style={{ overflow: "hidden" }}
                        >
                          <canvas id="ImageCanvas" />
                        </div>
                      </div>
                    </Tab.Pane>
                  ),
                };
              }),
              {
                menuItem: (
                  <Menu.Item
                    key="face"
                    active={false}
                    style={{
                      padding: 0,
                      marginLeft: "3px",
                      marginBottom: "3px",
                    }}
                  >
                    <Dropdown
                      onChange={handleSelectTabList}
                      placeholder="เลือกแท็บ"
                      button
                      className="icon"
                      labeled
                      icon="plus"
                      options={tabListOptions as any}
                      text="เลือกแท็บ"
                      style={{ background: "white" }}
                    ></Dropdown>
                  </Menu.Item>
                ),
              },
            ]}
          />
        </>
      )}

      {/* <Modal.Header>

      </Modal.Header>
      <Modal.Description style={{ textAlign: "center" }}>
        <div style={{ textAlign: "center" }}>
          <canvas id="ImageCanvas" />
        </div>
      </Modal.Description> */}

      <Modal.Actions>
        <Button
          color="green"
          disabled={!isEditing && !props.hiddenTab}
          onClick={() => {
            if (!props.hiddenTab) {
              handleSaveDrawingOverlay();
              return;
            }

            fabricCanvasRef.current?.setZoom(1);
            let dataURI = fabricCanvasRef?.current?.toDataURL();

            if (dataURI) {
              props.editedImage(dataURI);
              onCloseModalCleanUp();
            }
          }}
          inverted
        >
          <Icon name="check" /> {intl.formatMessage({ id: "cukey321" })}
        </Button>
        <Button
          color="red"
          onClick={() => {
            props.cancelImage();
            onCloseModalCleanUp();
          }}
          inverted
        >
          <Icon name="cancel" /> {intl.formatMessage({ id: "cukey356" })}
        </Button>
      </Modal.Actions>
      <ModConfirmSave
        titleColor="blue"
        openModal={openModConfirmSave}
        content={
          <div
            style={{
              textAlign: "center",
              marginTop: "-10px",
              fontSize: "16px",
            }}
          >
            Save to changes this picture?
          </div>
        }
        onDeny={handleConfirmClose}
        onApprove={handleConfirmSave}
        onCloseWithDimmeClick={() => setOpenModConfirmSave(false)}
      />
    </Modal>
  );
};

ModalEditImage.defaultProps = ModalEditImageInitialState;
export default ModalEditImage;

const styles = {
  line: {
    width: "2px",
    height: "19px",
    backgroundColor: "white",
    transform: "rotate(45deg)",
    margin: "1px 7px 3px 7px",
    borderRadius: "8px",
  },
};

const PANES = [
  {
    key: "tooth",
    name: "Dental Diagram",
    children: {
      primary_teeth: {
        image:
          process.env.PUBLIC_URL + "/static/images/primaryTeethDiagram.png",
      },
      permanent_teeth: {
        image:
          process.env.PUBLIC_URL + "/static/images/permanentTeethDiagram.png",
      },
    },
  },
  {
    key: "odontogram",
    name: "Dental Anatomy",
    image: process.env.PUBLIC_URL + "/static/images/odontogram.png",
  },
  {
    key: "face",
    name: "Head & Neck",
    image: process.env.PUBLIC_URL + "/static/images/faceDiagram.png",
  },
  {
    key: "muscle",
    name: "Muscle & TMJ",
    image: process.env.PUBLIC_URL + "/static/images/muscleDiagram.png",
  },
  {
    key: "periodontium",
    name: "Periodontium",
    image: process.env.PUBLIC_URL + "/static/images/periodontiumDiagram.png",
  },
  {
    key: "soft",
    name: "Oral cavity",
    image: process.env.PUBLIC_URL + "/static/images/softDiagram.png",
  },
  {
    key: "jaw",
    name: "Jaw-bones",
    image: process.env.PUBLIC_URL + "/static/images/jawBonesDiagram.png",
  },
];
