import React, { useMemo, useState } from "react";

// Utils
import { Form, FormGroup, Dropdown } from "semantic-ui-react";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Constant
const PATIENT_TYPE: any[] = [
  { key: "OPD และ IPD", value: "OPD และ IPD", text: "OPD และ IPD" },
  { key: "OPD", value: "OPD", text: "OPD" },
  { key: "IPD", value: "IPD", text: "IPD" },
];

const CardPatientSaveRiskReportFilter = (props: any) => {
  // useState
  const [requiredStartDate, setRequiredStartDate] = useState<boolean>(false);

  // UseMemo
  const coverageOptions = useMemo(() => {
    return [
      { key: "ทุกสิทธิ", value: "ทุกสิทธิ", text: "ทุกสิทธิ" },
      ...(props.masterOptions?.coverage || []),
    ];
  }, [props.masterOptions?.coverage]);

  const doctorOptions = useMemo(() => {
    return [
      { key: "ทุกแพทย์", value: "ทุกแพทย์", text: "ทุกแพทย์" },
      ...(props.masterOptions?.doctor || []),
    ];
  }, [props.masterOptions?.doctor]);

  const handlePreviewButton = () => {
    if (requiredStartDate) {
      alert("กรุณาระบุวันที่รับบริการ");
    } else {
      props.runSequence({
        sequence: "ClinicAnnualReport",
        action: "PREVIEW_REPORT",
        menu: "รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ",
      });
    }
  };

  const handlePrintButton = () => {
    if (requiredStartDate) {
      alert("กรุณาระบุวันที่รับบริการ");
    } else {
      props.runSequence({
        sequence: "ClinicAnnualReport",
        action: "PRINT_REPORT",
        menu: "รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ",
      });
    }
  };

  return (
    <Form>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label style={{ display: "flex", alignItems: "center", padding: "0rem 0rem 0rem 0rem" }}>
          วันที่รับบริการ<span style={{ color: "red" }}>*</span>
        </label>
        <DateTextBox
          onChange={(date: string) => {
            props.setProp(`ClinicAnnualReportSequence.patientSaveRiskReport.filterStartDate`, date)
            if (props.ClinicAnnualReportSequence?.patientSaveRiskReport?.filterStartDate.length < 1) {
              setRequiredStartDate(true);
            } else {
              setRequiredStartDate(false);
            }
          }}
          style={{ width: "10rem" }}
          value={props.ClinicAnnualReportSequence?.patientSaveRiskReport?.filterStartDate || ""}
        ></DateTextBox>
        <label style={{ display: "flex", alignItems: "center" }}>ถึง</label>
        <DateTextBox
          onChange={(date: string) =>
            props.setProp(`ClinicAnnualReportSequence.patientSaveRiskReport.filterEndDate`, date)
          }
          style={{ width: "10rem" }}
          value={props.ClinicAnnualReportSequence?.patientSaveRiskReport?.filterEndDate || ""}
        ></DateTextBox>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 3.5rem 0rem 0rem",
          }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handlePreviewButton}
          paramKey={`ClinicAnnualReport_PREVIEW_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`ClinicAnnualReport_PREVIEW_REPORT`]}
          color="yellow"
          style={{ width: "11rem" }}
          title="Preview"
        />
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 2.7rem 0rem 0rem",
          }}
        >
          ประเภท
        </label>
        <Dropdown
          selection={true}
          value={
            props.ClinicAnnualReportSequence?.patientSaveRiskReport?.encounterType || "OPD และ IPD"
          }
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.patientSaveRiskReport.encounterType`,
              data.value
            )
          }
          options={PATIENT_TYPE}
          style={{ minWidth: "9rem" }}
        ></Dropdown>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 0rem 0rem 0rem",
          }}
        >
          สิทธิรักษา
        </label>
        <Dropdown
          value={props.ClinicAnnualReportSequence?.patientSaveRiskReport?.coverageSelected || ""}
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.patientSaveRiskReport.coverageSelected`,
              data.value
            )
          }
          placeholder="เลือกสิทธิ"
          options={coverageOptions}
          search={true}
          selection={true}
          style={{ minWidth: "12.4rem" }}
        ></Dropdown>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handlePrintButton}
          paramKey={`ClinicAnnualReport_PRINT_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`ClinicAnnualReport_PRINT_REPORT`]}
          color="blue"
          style={{ width: "11rem" }}
          title="พิมพ์"
        />
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 0.2rem 0rem 0rem",
          }}
        >
          ชื่อทันตแพทย์
        </label>
        <Dropdown
          value={props.ClinicAnnualReportSequence?.patientSaveRiskReport?.doctorSelected || ""}
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.patientSaveRiskReport.doctorSelected`,
              data.value
            )
          }
          placeholder="เลือกแพทย์"
          options={doctorOptions}
          search={true}
          selection={true}
          style={{ minWidth: "15.5rem" }}
        ></Dropdown>
      </FormGroup>
    </Form>
  );
};

export default CardPatientSaveRiskReportFilter;
