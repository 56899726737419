import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Input, Form, Button, Divider, Checkbox } from "semantic-ui-react";
import * as Common from "../common";
import PureReactTable, { formatPrice } from "../common/PureReactTable";

import CONFIG from "config/config";

const CardProductSearch = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [numberOfPage, setNumberOfPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectItem, setSelectItem] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState({});

  React.useEffect(() => {
    handleSearchItem();
  }, []);

  React.useEffect(() => {
    handleSearchItem();
  }, [currentPage]);

  const orderSearchItems = useMemo(() => {
    let orderData = [
      {
        Header: "",
        accessor: "checked",
        width: 30,
        Cell: (row) => {
          return (
            <Checkbox
              checked={Object.keys(selectItem).includes(row.original.id.toString())}
              onChange={(e, { checked }) => handleCheckBoxChange(e, checked, row)}
            />
          );
        },
      },
      {
        Header: "Service Code",
        accessor: "service_code",
        width: 100,
      },
      {
        Header: "Product Name",
        accessor: "name",
        width: 350,
      },
    ];

    if (CONFIG.ENABLE_PRICE_LEVEL) {
      orderData.push(
        {
          Header: "ราคา Normal (ระดับที่1)",
          accessor: "price_normal",
          resizable: false,
          Cell: (row) => {
            return <div style={{ textAlign: "right" }}>{formatPrice(row.value)}</div>;
          },
        },
        {
          Header: "ราคา Special (ระดับที่2)",
          accessor: "price_special",
          resizable: false,
          Cell: (row) => {
            return <div style={{ textAlign: "right" }}>{formatPrice(row.value)}</div>;
          },
        },
        {
          Header: "ราคา Premium (ระดับที่3)",
          accessor: "price_premium",
          resizable: false,
          Cell: (row) => {
            return <div style={{ textAlign: "right" }}>{formatPrice(row.value)}</div>;
          },
        },
        {
          Header: "ราคาชาวต่างชาติ",
          accessor: "price_foreign",
          resizable: false,
          Cell: (row) => {
            return <div style={{ textAlign: "right" }}>{formatPrice(row.value)}</div>;
          },
        }
      );
    } else {
      orderData.push({
        Header: "ราคา",
        accessor: "price_unit",
        resizable: false,
        Cell: (row) => {
          return <div style={{ paddingRight: "10px" }}>{formatPrice(row.value)}</div>;
        },
      });
    }

    return orderData;
  }, [data, selectItem]);

  const handleSearchItem = async () => {
    let limit = 40;
    let offset = limit * currentPage;
    setIsLoading(true);
    const [itemData, error] = await props.onGetItem({
      searchText: searchValue,
      limit,
      offset,
    });
    setIsLoading(false);
    if (itemData) {
      setData(itemData.items);
      setNumberOfPage(parseInt(itemData.total / limit) + 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCheckBoxChange = (e, checked, row) => {
    let stateItem = { ...selectItem };
    if (!checked) {
      delete stateItem[row.original.id];
    } else {
      stateItem[row.original.id] = row.original;
    }
    setSelectItem(stateItem);
  };

  return (
    <Common.CardLayout
      headerColor="orange"
      titleText="ค้นหารายการเบ็ดเตล็ด"
      testid="crdProductSearch"
      loading={isLoading}
      closeable={props.closeable}
      toggleable={props.toggleable}
      hideHeaderIcon={props.hideHeaderIcon}
    >
      <Form>
        <Form.Group inline>
          <Form.Field width={2}>
            <label>ชื่อหรือรหัส</label>
          </Form.Field>
          <Form.Field width={12}>
            <Input onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
          </Form.Field>
          <Form.Field width={2}>
            <Button color="blue" content="ค้นหา" fluid onClick={handleSearchItem} />
          </Form.Field>
        </Form.Group>
      </Form>
      <Divider />
      <PureReactTable
        style={styles.reactTable}
        selectedClassName="blueSelectedRow"
        className=""
        data={data}
        pageSize={data ? (data.length < 15 ? 15 : data.length) : 15}
        pages={numberOfPage}
        showPageSizeOptions={false}
        onPageChange={handlePageChange}
        onSelect={(originalRow) => setSelectedRow(originalRow)}
        selectedRow={selectedRow}
        columns={orderSearchItems}
      />
      <Divider />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={2}>
            <Button
              type="button"
              fluid
              color="green"
              content="เลือก"
              onClick={() => {
                props.onSelectItems({ item: Object.values(selectItem) });
                props.hideCallback();
              }}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Button type="button" fluid color="red" content="ยกเลิก" onClick={props.hideCallback} />
          </Form.Field>
        </Form.Group>
      </Form>
    </Common.CardLayout>
  );
};

const styles = {
  reactTable: {
    height: "440px",
  },
};

CardProductSearch.defaultProps = {
  closeable: true,
  toggleable: true,
  hideHeaderIcon: false,
  hideCallback: () => {},
  onGetItem: () => [null, null],
  onSelectItems: () => {},
};

CardProductSearch.propTypes = {
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  hideCallback: PropTypes.func,
  onGetItem: PropTypes.func,
  onSelectItems: PropTypes.func,
};

export default React.memo(CardProductSearch);
