import React, { useState, useEffect, useMemo } from "react";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import CardQueuePrepareDrugUX from "./CardQueuePrepareDrugUX";
import moment from "moment";
import "react-lib/apps/IsHealth/css/QueueForPatient.scss";

const hospital = "โรงพยาบาล มอร์เฮลท์ เเคร์";
const IMAGE = {
  logo: "images/IHimage.png",
};

const dateDefault = dateToStringWithoutTimeBE(new Date());

const CardQueuePrepareDrug = (props: any) => {
  const [time, setTime] = useState<any>();

  useEffect(() => {
    props.onEvent({
      message: "QueueForPatient",
      params: { action: "queuePrepareDrug" },
    });
  }, []);

  useEffect(() => {
    let list = setInterval(
      () =>
        props.onEvent({
          message: "QueueForPatient",
          params: { action: "queuePrepareDrug" },
        }),
      1000 * 30
    );
    return () => {
      clearInterval(list);
    };
  }, []);
  useEffect(() => {
    let timeId = setInterval(
      () => setTime(moment(new Date())?.format("HH:mm:ss")),
      1000
    );
    return () => {
      clearInterval(timeId);
    };
  }, []);

  const itemLess = useMemo(() => {
    const listLess: any = [];
    for (let index = 0; index < 15; index++) {
      listLess.push(
        props?.queuePrepareDrug?.prepareDrugList?.item_less?.[index]
      );
    }

    return listLess;
  }, [props?.queuePrepareDrug?.prepareDrugList?.item_less]);

  const itemMore = useMemo(() => {
    const listMore: any = [];

    for (let index = 0; index < 10; index++) {
      listMore.push(
        props?.queuePrepareDrug?.prepareDrugList?.item_more?.[index]
      );
    }

    return listMore;
  }, [props?.queuePrepareDrug?.prepareDrugList?.item_more]);

  return (
    <>
      <CardQueuePrepareDrugUX
        hospital={hospital}
        division={
          props.masterOptions?.division?.find(
            (options: any) => options.value === props.queuePrepareDrug?.division
          )?.text
        }
        date={dateDefault}
        time={time}
        logo={IMAGE.logo}
        countItemLess={
          props.queuePrepareDrug?.prepareDrugList?.count_item_less || 0
        }
        countItemMore={
          props.queuePrepareDrug?.prepareDrugList?.count_item_more || 0
        }
        openFullScreen={props.openFullScreen}
        onClose={() => {
          props.setOpenFullScreen(false);
        }}
        itemLess={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(itemLess || [])?.map((item: any, index: any) => {
              return (
                <ItemLess
                  item={item}
                  index={index}
                  width="30%"
                  margin="15px 10px 10px 12px"
                />
              );
            })}
          </div>
        }
        itemMore={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(itemMore || [])?.map((item: any, index: any) => {
              return (
                <ItemLess
                  item={item}
                  index={index}
                  width="40%"
                  margin="15px 10px 15px 30px"
                />
              );
            })}
          </div>
        }
        itemReject={
          <div
            className="QueueForPatient"
            style={{
              padding:
                props.queuePrepareDrug?.prepareDrugList?.item_reject?.length > 5
                  ? "0 5rem"
                  : "0px",
              width: "100%",
            }}
          >
            <div
              className={
                props.queuePrepareDrug?.prepareDrugList?.item_reject?.length > 5
                  ? "marquee"
                  : ""
              }
              style={{
                display:
                  props.queuePrepareDrug?.cancelQueueList?.item_reject?.length >
                  5
                    ? ""
                    : "flex",
              }}
            >
              {(
                props.queuePrepareDrug?.prepareDrugList?.item_reject || []
              )?.map((item: any, index: number) => {
                return (
                  <ItemReject
                    item={
                      props.queuePrepareDrug?.prepareDrugList?.item_reject
                        ?.length > 5
                        ? `${props?.queuePrepareDrug?.prepareDrugList?.item_reject}`
                        : item
                    }
                    index={index}
                    count={
                      props.queuePrepareDrug?.prepareDrugList?.item_reject
                        ?.length
                    }
                  />
                );
              })}
            </div>
          </div>
        }
      />
    </>
  );
};

const ItemReject = (props: any) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
          {props?.item},
        </div>
      </div>
    </>
  );
};

const ItemLess = (props: any) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        margin: props.margin,
        padding: "10px",
        width: props.width,
        height: "50px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: "22px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {props?.item}
      </div>
    </div>
  );
};

export default CardQueuePrepareDrug;
