import React from "react";
import PropTypes from "prop-types";
import * as Common from "../common";
import {
  Button,
  Icon,
  Form,
  Label,
  Input,
  Divider,
  Header,
  TextArea,
  Checkbox,
  Modal
} from "semantic-ui-react";
import { SearchBox, ComboBox } from "../common";
import * as DPO from "../DPO";
import * as REG from "../REG";
import * as Util from "../../utils";
import * as CONSTANT from "../../utils/constant";

const SubTreatment = React.memo(
  React.forwardRef((props, ref) => {
    const [openModTreatment, setOpenModTreatment] = React.useState(false);
    const [treatment, setTreatment] = React.useState({});
    const [quantity, setQuantity] = React.useState("");
    const [eligibility, setEligibility] = React.useState("");
    const [typingTimeout, setTypingTimeout] = React.useState(null);
    const [treatmentOrderItemId, setTreatmentOrderItemId] = React.useState(null)

    const treatmentRef = React.useRef();

    React.useImperativeHandle(ref, () => ({
      getTreatment: () => {
        return treatment;
      },
      getQuantity: () => {
        return quantity;
      },
      getEligibility: () => {
        return eligibility;
      },
      getData: () => {
        let id = treatmentOrderItemId
        let product = treatment.product
        if(treatmentOrderItemId && !parseInt(treatmentOrderItemId) && treatmentOrderItemId.includes("fake")){
          id = null
        }
        if(!product){
          product = treatment.id
        }
        return new Object({
          id: id,
          product: product ? product : null,
          quantity: quantity ? quantity : 0,
          eligibility_type: eligibility
        });
      },
      checkValidData: () => {
        return treatment.hasOwnProperty("id") && quantity && eligibility;
      },
      setTreatmentOrderItemId: (id) => setTreatmentOrderItemId(id)
    }));

    React.useEffect(() => {
      if (props.eligibility.length > 0) {
        setEligibility(props.eligibility[0].value);
      }
    }, [props.eligibility]);

    React.useEffect(() => {
      if (treatment.hasOwnProperty("id") && !isNaN(parseInt(quantity))) {
        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }
        setTypingTimeout(setTimeout(() => props.onRecalculatePrice(), 400));
      }
    }, [treatment, eligibility, quantity]);

    React.useEffect(() => {
      setQuantity(props.treatment.quantity ? props.treatment.quantity : "")
      setEligibility(props.treatment.eligibility_type ? props.treatment.eligibility_type : "")
      if(props.treatment){
        setTreatment(props.treatment)
        treatmentRef.current.setId(props.treatment.product)
        let optionsObj = {...props.treatment}
        optionsObj.id = props.treatment.product
        treatmentRef.current.setOptions([optionsObj])
        treatmentRef.current.setText(props.treatment.name_code)
      }
    }, [props.treatment])

    const handleSelectTreatment = item => {
      setTreatment(item);
      setOpenModTreatment(false);
      treatmentRef.current.setOptions([item]);
      treatmentRef.current.setId(item.id);
    };

    React.useEffect(() => {
      if(props.defaultId){
        setTreatmentOrderItemId(props.defaultId)
      }
    }, [props.defaultId])

    return (
      <Form.Group inline>
        <Modal
          open={openModTreatment}
          closeOnDimmerClick={true}
          onClose={() => setOpenModTreatment(false)}
          size="small"
          content={
            <REG.CardSearchDialog
              onClose={() => setOpenModTreatment(false)}
              onGetData={props.onGetSearchOptions}
              onSelectItem={handleSelectTreatment}
            />
          }
        />
        <Form.Field width={5}>
          <label>รายการหัตถการ</label>
          <SearchBox
            ref={treatmentRef}
            onGetSearchOptions={props.onGetSearchOptions}
            textField="name_code"
            linkIcon
            onIconClick={() => setOpenModTreatment(true)}
            onSelectOption={({ item }) => {
              setTreatment(item)
              setTreatmentOrderItemId(null)
            }}
          />
        </Form.Field>
        <Form.Field width={5}>
          <label>จำนวน</label>
          <Input
            readOnly={props.readOnly}
            value={quantity}
            onChange={e => setQuantity(e.target.value)}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Label
            className="fluid large"
            content={
              treatment.unit_name ? treatment.unit_name : <div>&nbsp;</div>
            }
          />
        </Form.Field>
        <Form.Field width={3}>
          <ComboBox
            search
            fluid
            style={{ width: "100%" }}
            options={props.eligibility}
            value={eligibility}
            onValueChange={value => setEligibility(value)}
          />
        </Form.Field>
        <Form.Field width={1}>
          <Button
            icon="minus"
            color="red"
            onClick={props.onRemove}
            type="button"
          />
        </Form.Field>
      </Form.Group>
    );
  })
);

SubTreatment.defaultProps = {
  eligibility: [],
  onRemove: () => {},
  onRecalculatePrice: () => {},
  onGetSearchOptions: () => [],
  readOnly: false,
}

export default SubTreatment