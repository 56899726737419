import { TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

const FormClinicIncomeReportMonthly = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props form ClinicIncomeReport Monthly:", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.reportClinicIncomeData?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานสรุปภาพรวมรายรับ (รายเดือน)",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `ประเภท : ${props.reportClinicIncomeData?.params?.encounter_type_label}`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `คลินิก: ${props.reportClinicIncomeData?.params?.division} วันที่ ${props.reportClinicIncomeData?.params?.start_date} ถึง ${props.reportClinicIncomeData?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const extractedItemsData = (data: any) => {
    let extractedData: any = [];

    if (data?.length > 0) {
      data.forEach((row: any) => {
        row.items?.forEach((item: any) => {
          extractedData.push({
            year: row.year,
            month: row.month,
            coverage_code: item.coverage_code,
            coverage_name: item.coverage_name,
            coverage_count: item.coverage_count,
            total_send_claim: item.total_send_claim,
            total_paid: item.total_paid,
            total_overdue: item.total_overdue,
            total_price: item.total_price,
            summary_coverage_count: row.coverage_count,
            summary_send_claim: row.total_send_claim,
            summary_paid: row.total_paid,
            summary_overdue: row.total_overdue,
            summary_price: row.total_price,
          });
        });
      });
    }

    return extractedData;
  };

  const generateTableBody = (data: any, columns: any) => {
    let body: any = [];
    let yearList: any = [];
    let monthCount: any = {};
    let yearCount: any = {};
    let eachMonthCount: any = {};
    // Total Value
    let totalCoverage: any = {};
    let totalSendClaim: any = {};
    let totalPaid: any = {};
    let totalOverdue: any = {};
    let totalPrice: any = {};

    // Header of the Table
    body.push([
      { text: "ปี", bold: true, alignment: "center" },
      { text: "เดือน", bold: true, alignment: "center" },
      { text: "รหัสสิทธิ", bold: true, alignment: "center" },
      { text: "สิทธิการรักษา", bold: true, alignment: "center" },
      { text: "จำนวน Encounter (ราย)", bold: true, alignment: "center" },
      { text: "ยอดส่งเบิก", bold: true, alignment: "center" },
      { text: "ยอดรับชำระ", bold: true, alignment: "center" },
      { text: "ค้างจ่าย", bold: true, alignment: "center" },
      { text: "ยอดรวม", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      let extractedData = extractedItemsData(data);

      // Count Year Column
      extractedData.forEach((row: any) => {
        if (!yearCount[row.year]) {
          yearCount[row.year] = 1;
        } else {
          yearCount[row.year]++;
        }
      });

      // Count Month Column and Add Total Value
      extractedData.forEach((row: any) => {
        let key = row.year + "-" + row.month;

        if (!monthCount[key]) {
          monthCount[key] = 1;
          // Add Total Value
          totalCoverage[key] = row.summary_coverage_count;
          totalSendClaim[key] = row.summary_send_claim;
          totalPaid[key] = row.summary_paid;
          totalOverdue[key] = row.summary_overdue;
          totalPrice[key] = row.summary_price;
        } else {
          monthCount[key]++;
        }
      });

      // Count Row Total each Month
      for (const month in monthCount) {
        let year = month.split("-")[0];
        if (eachMonthCount[year]) {
          eachMonthCount[year] += 1;
        } else {
          eachMonthCount[year] = 1;
        }
      }

      extractedData.forEach((row: any, index: number) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            switch (column) {
              case "year":
                if (!yearList.includes(row[column])) {
                  yearList.push(row[column]);
                  dataRow.push({
                    text: row[column],
                    rowSpan: yearCount[row[column]] + eachMonthCount[row[column]],
                  });
                } else {
                  dataRow.push({});
                }
                break;
              case "month":
                let monthKey = row.year + "-" + row.month;
                if (monthCount[monthKey] > 1) {
                  dataRow.push({ text: row[column], rowSpan: monthCount[monthKey] });
                } else {
                  dataRow.push({ text: row[column] });
                }
                break;
              case "coverage_count":
              case "total_send_claim":
              case "total_paid":
              case "total_overdue":
              case "total_price":
                dataRow.push({ text: row[column], alignment: "right" });
                break;
              default:
                dataRow.push({ text: row[column].toString() });
                break;
            }
          }
        });

        body.push(dataRow);

        let nextRow = extractedData[index + 1];
        if (!nextRow || nextRow.month !== row.month) {
          let key = row.year + "-" + row.month;
          body.push([
            {},
            { text: "รวม", colSpan: 3, bold: true, alignment: "center" },
            {},
            {},
            { text: totalCoverage[key], bold: true, alignment: "right" },
            { text: totalSendClaim[key], bold: true, alignment: "right" },
            { text: totalPaid[key], bold: true, alignment: "right" },
            { text: totalOverdue[key], bold: true, alignment: "right" },
            { text: totalPrice[key], bold: true, alignment: "right" },
          ]);
        }
      });
    }

    // Overall Total Row
    body.push([
      { text: "รวมทั้งสิ้น", colSpan: 4, bold: true, alignment: "center" },
      {},
      {},
      {},
      {
        text: props.reportClinicIncomeData?.params?.coverage_count,
        bold: true,
        alignment: "right",
      },
      {
        text: props.reportClinicIncomeData?.params?.total_send_claim,
        bold: true,
        alignment: "right",
      },
      { text: props.reportClinicIncomeData?.params?.total_paid, bold: true, alignment: "right" },
      { text: props.reportClinicIncomeData?.params?.total_overdue, bold: true, alignment: "right" },
      { text: props.reportClinicIncomeData?.params?.total_price, bold: true, alignment: "right" },
    ]);

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["5%", "10%", "11.11%", "*", "11.11%", "11.11%", "11.11%", "11.11%", "11.11%"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.reportClinicIncomeData?.fields || [], [
        "year",
        "month",
        "coverage_code",
        "coverage_name",
        "coverage_count",
        "total_send_claim",
        "total_paid",
        "total_overdue",
        "total_price",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: "A4",
  };
};

export default FormClinicIncomeReportMonthly;
