import React, { useState, useEffect, useMemo } from "react";

// UX
import CardClinicAnnualReportUX from "./CardClinicAnnualReportUX";
import CardPatientPaidServicesReportFilter from "./CardPatientPaidServicesReportFilter";
import CardClinicIncomeReportFilter from "./CardClinicIncomeReportFilter";
import CardPatientSaveRiskReportFilter from "./CardPatientSaveRiskReportFilter";
import CardPatientPaidRiskReportFilter from "./CardPatientPaidRiskReportFilter";
import CardPriceChangeRiskReportFilter from "./CardPriceChangeRiskReportFilter";
import CardDoctorIncomeMonthlySummaryFilter from "./CardDoctorIncomeMonthlySummaryFilter";
import CardPriceChangeOverviewSummaryFilter from "./CardPriceChangeOverviewSummaryFilter";
import CardPaidCompensationWorkingTypeFilter from "./CardPaidCompensationWorkingTypeFilter";
import CardDoctorIncomeSummaryFilter from "./CardDoctorIncomeSummaryFilter";

// Interface
import { State } from "./sequence/ClinicAnnualReport";

// Utils
import { Modal, ModalContent, Header } from "semantic-ui-react";

// Props
type CardClinicAnnualReportProps = {
  onEvent: (e: any) => any;
  setProp: any;
  runSequence: any;
  buttonLoadCheck: Record<string, any>;
  // Sequence
  ClinicAnnualReportSequence?: State["ClinicAnnualReportSequence"];
  masterOptions?: Record<string, any>;
};

const CardClinicAnnualReport = (props: CardClinicAnnualReportProps) => {
  // State
  const [openModal, setOpenModal] = useState(false);

  // Call Sequence
  useEffect(() => {
    props.runSequence({
      sequence: "ClinicAnnualReport",
      restart: true,
    });
  }, []);

  console.log("ClinicAnnual Report Props: ", props);
  console.log("ClinicAnnual Report MasterData: ", props.masterOptions);

  // Call Sequence (GET_REPORT)
  const callGetReportSequence = (menu: string) => {
    props.runSequence({
      sequence: "ClinicAnnualReport",
      action: "GET_REPORT",
      menu: menu,
    });
  };

  // Select Menu
  const handleSelectMenu = (menu: string) => {
    console.log("ClinicAnnual Report Menu: ", menu);
    setOpenModal(true);
    callGetReportSequence(menu);
  };

  // Close Modal
  const handleCloseModal = (option: boolean) => {
    props.runSequence({
      sequence: "ClinicAnnualReport",
      restart: true,
    });
    setOpenModal(option);
  }

  // Get Filter Content
  const getFilterModalContent = useMemo(() => {
    switch (props.ClinicAnnualReportSequence?.selectedMenu) {
      case "รายงานสรุปรายได้ และค่าตอบแทนแพทย์":
        return (
          <CardDoctorIncomeSummaryFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            ClinicAnnualReportSequence={props.ClinicAnnualReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์":
        return (
          <CardDoctorIncomeMonthlySummaryFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            ClinicAnnualReportSequence={props.ClinicAnnualReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน":
        return (
          <CardPaidCompensationWorkingTypeFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            ClinicAnnualReportSequence={props.ClinicAnnualReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา":
        return (
          <CardPriceChangeRiskReportFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            ClinicAnnualReportSequence={props.ClinicAnnualReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์":
        return (
          <CardPriceChangeOverviewSummaryFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            ClinicAnnualReportSequence={props.ClinicAnnualReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานสรุปรายได้คลินิก":
        return (
          <CardClinicIncomeReportFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            ClinicAnnualReportSequence={props.ClinicAnnualReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานผู้ป่วยค้างชำระ":
        return (
          <CardPatientPaidRiskReportFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            ClinicAnnualReportSequence={props.ClinicAnnualReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ":
        return (
          <CardPatientSaveRiskReportFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            ClinicAnnualReportSequence={props.ClinicAnnualReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานผู้ป่วยที่ชำระค่าบริการ":
        return (
          <CardPatientPaidServicesReportFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            ClinicAnnualReportSequence={props.ClinicAnnualReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      default:
        return null;
    }
  }, [props.ClinicAnnualReportSequence, props.masterOptions, props.buttonLoadCheck]);

  const handleModalSize = () => {
    switch (props.ClinicAnnualReportSequence?.selectedMenu) {
      case "รายงานสรุปรายได้ และค่าตอบแทนแพทย์":
      case "รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์":
      case "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน":
      case "รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา":
      case "รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์":
      case "รายงานสรุปรายได้คลินิก":
      case "รายงานผู้ป่วยค้างชำระ":
        return "small";
      case "รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ":
      // return "large";
      case "รายงานผู้ป่วยที่ชำระค่าบริการ":
        return "small";
      default:
        return undefined;
    }
  };

  return (
    <>
      <CardClinicAnnualReportUX onHandleSelectedMenu={handleSelectMenu} />

      <Modal onClose={() => handleCloseModal(false)} open={openModal} size={handleModalSize()}>
        <Header>{props.ClinicAnnualReportSequence?.selectedMenu}</Header>
        <ModalContent style={{ padding: "1rem" }}>{getFilterModalContent}</ModalContent>
      </Modal>
    </>
  );
};

export default CardClinicAnnualReport;
