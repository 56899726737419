import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const ANES_PACU_Tab = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        className="pacu-record-tabs"
        style={{ height: "80px" }}>
        <div
          style={{ display: "flex" ,padding : "10px", }}>
          
          <Button
            name="Anesthesia"
            onClick={props.onChangeContainerName}
            style={{ color: props.tab === "Anesthesia" ? "blue" : "grey" }}>
            Anesthesia
          </Button>
          <Button
            name="Intraoperative"
            onClick={props.onChangeContainerName}
            style={{ color: props.tab === "Intraoperative" ? "blue" : "grey" }}>
            Intraoperative intake/output
          </Button>
          <Button
            name="PostAnesthetic"
            onClick={props.onChangeContainerName}
            style={{ color: props.tab === "PostAnesthetic" ? "blue" : "grey" }}>
            Post anesthetic recovery score
          </Button>
          <Button
            name="Monitor"
            onClick={props.onChangeContainerName}
            style={{ color: props.tab === "Monitor" ? "blue" : "grey" }}>
            Monitor & Nursing care
          </Button>
          <Button
            name="Medication"
            onClick={props.onChangeContainerName}
            style={{ color: props.tab === "Medication" ? "blue" : "grey", display: "none" }}>
            Medication
          </Button>
          <Button
            name="Problems"
            onClick={props.onChangeContainerName}
            style={{ color: props.tab === "Problems" ? "blue" : "grey" }}>
            Problems/Remarks
          </Button>
          <Button
            name="Referral"
            onClick={props.onChangeContainerName}
            style={{ color: props.tab === "Referral" ? "blue" : "grey" }}>
            Referral data for transfer
          </Button>
        </div>
        <div
          style={{width:"100%" , marginLeft:"10px"}}>
          
          <hr>
          </hr>
        </div>
      </div>
    )
}


export default ANES_PACU_Tab

export const screenPropsDefault = {}

/* Date Time : Tue Dec 26 2023 10:04:56 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": "pacu-record-tabs"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"80px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,padding : \"10px\", }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia"
        },
        "name": {
          "type": "value",
          "value": "Anesthesia"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"Anesthesia\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Intraoperative intake/output"
        },
        "name": {
          "type": "value",
          "value": "Intraoperative"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"Intraoperative\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Post anesthetic recovery score"
        },
        "name": {
          "type": "value",
          "value": "PostAnesthetic"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"PostAnesthetic\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Monitor & Nursing care"
        },
        "name": {
          "type": "value",
          "value": "Monitor"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"Monitor\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        },
        "name": {
          "type": "value",
          "value": "Medication"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"Medication\" ? \"blue\" : \"grey\", display: \"none\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Problems/Remarks"
        },
        "name": {
          "type": "value",
          "value": "Problems"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"Problems\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\" , marginLeft:\"10px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "hr",
      "parent": 13,
      "props": {
      },
      "seq": 14,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Referral data for transfer"
        },
        "name": {
          "type": "value",
          "value": "Referral"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"Referral\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 15,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PACU_Tab",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
