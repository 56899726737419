import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Button
} from 'semantic-ui-react'

const CardSummaryDoctorUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        
        <div
          style={{display:"flex",marginBottom:"10px"}}>
          {}
          <div
            style={{marginLeft:"10px",display:"flex",alignItems:"center"}}>
            แพทย์/ทันตเเพทย์
          </div>
          <div
            style={{marginLeft:"10px",width:"200px"}}>
            {props?.searchDoctorBox}
          </div>
          <div
            style={{marginLeft:"10px",display:"flex",alignItems:"center"}}>
            วันที่รักษา 
          </div>
          <div
            style={{marginLeft:"10px"}}>
            
            <DateTextBox
              onChange={props.changeDate("startDate")}
              value={props.startDate}>
            </DateTextBox>
          </div>
          <div
            style={{marginLeft:"10px",display:"flex",alignItems:"center"}}>
            ถึง
          </div>
          <div
            style={{marginLeft:"10px"}}>
            
            <DateTextBox
              onChange={props.changeDate("endDate")}
              value={props.endDate}>
            </DateTextBox>
          </div>
          <div
            style={{flex:"1"}}>
            
          </div>
          <div
            style={{display:"flex", flexDirection: "row"}}>
            
            <Button
              color="blue"
              onClick={props.OnSearch}>
              ค้นหา
            </Button>
            <div>
              {props.buttonExportCSV}
            </div>
          </div>
        </div>
        <div>
          
          <Table
            data={props?.dataSummaryDoctor}
            headers="เเพทย์/ทันตแพทย์,จำนวน Encounter ที่แพทย์รักษา"
            keys="name,encounter"
            minRows="15"
            showPagination={false}
            style={{height: "600px"}}>
          </Table>
        </div>
      </div>
    )
}

export default CardSummaryDoctorUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Thu Aug 25 2022 10:26:13 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginBottom:\"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.dataSummaryDoctor"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "เเพทย์/ทันตแพทย์,จำนวน Encounter ที่แพทย์รักษา"
        },
        "keys": {
          "type": "value",
          "value": "name,encounter"
        },
        "minRows": {
          "type": "value",
          "value": "15"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"600px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์/ทันตเเพทย์"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.searchDoctorBox"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",width:\"200px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่รักษา "
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 8,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"startDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 11,
      "name": "DateTextBox",
      "parent": 10,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"endDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", flexDirection: \"row\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.OnSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonExportCSV"
        }
      },
      "seq": 18,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSummaryDoctorUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
