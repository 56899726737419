import React, {
  CSSProperties,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Button, Dropdown, DropdownProps, Form, Input, Popup, PopupProps } from "semantic-ui-react";

import { ColumnStyle } from "react-lib/appcon/common/DropDownColumn";

import { CustomRadioButton, prepareModSolventSelection } from "./CardEditMedOrder";

// Types
type PopupEditRouteDescProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  backgroundColor?: any;
  children: ReactElement;
  data: Record<string, any>;
  disabled?: boolean;
  drugPermission?: Record<string, any>;
  modSolventSelectionConfig?: Record<string, any> | null;
  orderType?: string;
  masterData?: Record<string, any>;
  options: Record<string, any>[];
  // callback
  onEditSolvent: (data: Record<string, any>, index: number) => void;
  onSave: (data: Record<string, any>) => void;
};

type Styles = Record<"popup", CSSProperties>;

const styles: Styles = {
  popup: { minWidth: "28rem" },
};

const POPUP_ON: PopupProps["on"] = ["click"];

const PopupEditRouteDesc = (props: PopupEditRouteDescProps) => {
  const [route, setRoute] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  // Memo Effect
  const isRequireRate = useMemo(() => {
    const routeData: any[] = props.masterData?.route || [];

    const foundRoute = routeData.find((item) => item.id === route);

    return !!foundRoute?.require_rate;
  }, [isOpen, props.masterData?.route, route]);

  useEffect(() => {
    if (isRequireRate && isOpen) {
      const { params } = prepareModSolventSelection({
        drug: props.data,
        drugPermission: props.drugPermission,
        frequency: props.masterData?.frequency,
        noDefaultSolvent: true,
        orderType: props.orderType,
        solvent: props.modSolventSelectionConfig,
      });

      props.onEvent({
        message: "initModSolventSelection",
        params,
      });
    }
  }, [isRequireRate]);

  // Callback
  const handleOpen = useCallback(() => {
    props.onEvent({ message: "ClearDrugOrderForm", params: {} });

    props.onEditSolvent(
      {
        admixtureMode: "",
        drug_drip_in: props.data.drug_drip_in,
        drug_drip_in_unit: props.data.drug_drip_in_unit,
        mixture_iv_rate: props.data.mixture_iv_rate,
        mixture_iv_rate_unit: props.data.mixture_iv_rate_unit,
      },
      -1
    );

    setIsOpen(true);

    setRoute(props.data.route);
  }, [props.data]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSave = useCallback(() => {
    const data = props.modSolventSelectionConfig || {};

    props.onEvent({
      message: "SaveSolventData",
      params: {
        drugOrderType: props.orderType,
        isRequireRate,
        onSuccess: () => {
          props.onSave({
            drug_drip_in: data.drug_drip_in || 0,
            drug_drip_in_unit: data.drug_drip_in_unit,
            mixture_iv_rate: data.mixture_iv_rate || 0,
            mixture_iv_rate_unit: data.mixture_iv_rate_unit,
            route,
          });

          handleClose();
        },
      },
    });
  }, [props.modSolventSelectionConfig, route]);

  // #const handleChangeDescription = useCallback((e: SyntheticEvent, data: InputProps) => {
  //   setDescription(data.value);
  // }, []);

  const handleChangeRoute = useCallback(
    (e: SyntheticEvent, data: DropdownProps) => {
      const route: any[] = props.masterData?.route || [];

      const foundRoute = route.find((item) => item.id === data.value);

      if (!foundRoute?.require_rate) {
        props.setProp("modSolventSelectionConfig", {
          ...props.modSolventSelectionConfig,
          dripRate: null,
          drug_drip_in: null,
          drug_drip_in_unit: null,
          mixture_iv_rate: null,
          mixture_iv_rate_unit: null,
        });
      }

      setRoute(data.value as number);
    },
    [props.masterData?.route]
  );

  // change
  const handleChangeModData = useCallback((event: any, data: any) => {
    if (data.type === "number" && Number(data.value) < 0) {
      data.value = "0";
    }

    props.onEvent({
      message: "SetModSolventSelectionData",
      params: { name: data.name, value: data.value },
    });
  }, []);

  // Memo
  const trigger = useMemo(
    () => (
      <div
        style={{
          ...ColumnStyle,
          backgroundColor: props.backgroundColor ?? ColumnStyle.background,
          textAlign: "center",
        }}
      >
        {props.children}
      </div>
    ),
    [props.backgroundColor, props.children]
  );

  const content = useMemo(
    () => (
      <Form>
        <Form.Group style={{ marginBottom: "0.5rem" }}>
          <Form.Dropdown
            label="Route"
            style={{ width: "100%" }}
            value={route || ""}
            width={16}
            fluid
            search
            selection
            options={props.options}
            onChange={handleChangeRoute}
          />
        </Form.Group>
        {isRequireRate && (
          <Form.Group className="--readOnly --grey">
            <Form.Field width={4}>
              <CustomRadioButton
                checked={props.modSolventSelectionConfig?.dripRate === "rate"}
                label="Drip Rate"
                labelStyle={{ marginTop: "" }}
                name="dripRate"
                value="rate"
                showHeader
                onChange={handleChangeModData}
              />
              <Input
                disabled={props.modSolventSelectionConfig?.dripRate !== "rate"}
                name={"mixture_iv_rate"}
                type="number"
                value={props.modSolventSelectionConfig?.mixture_iv_rate}
                fluid
                onChange={handleChangeModData}
                onKeyDown={(e: KeyboardEvent) => {
                  if (["-", "e"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>{"\u00A0"}</label>
              <Dropdown
                className="inline-label"
                disabled={props.modSolventSelectionConfig?.dripRate !== "rate"}
                name="mixture_iv_rate_unit"
                style={{ width: "100%" }}
                value={props.modSolventSelectionConfig?.mixture_iv_rate_unit}
                clearable
                fluid
                search
                selection
                options={props.modSolventSelectionConfig?.ivRateOptions || []}
                onChange={handleChangeModData}
              />
            </Form.Field>
            <Form.Field width={4}>
              <CustomRadioButton
                checked={props.modSolventSelectionConfig?.dripRate === "drip"}
                label="Drip Duration"
                labelStyle={{ marginTop: "" }}
                name="dripRate"
                value="drip"
                showHeader
                onChange={handleChangeModData}
              />
              <Input
                disabled={props.modSolventSelectionConfig?.dripRate === "rate"}
                name="drug_drip_in"
                type="number"
                value={props.modSolventSelectionConfig?.drug_drip_in}
                fluid
                onChange={handleChangeModData}
                onKeyDown={(e: KeyboardEvent) => {
                  if (["-", "e"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>{"\u00A0"}</label>
              <Dropdown
                className="inline-label"
                disabled={props.modSolventSelectionConfig?.dripRate === "rate"}
                name="drug_drip_in_unit"
                style={{ width: "100%" }}
                value={props.modSolventSelectionConfig?.drug_drip_in_unit}
                clearable
                fluid
                search
                selection
                options={props.modSolventSelectionConfig?.dripInOptions || []}
                onChange={handleChangeModData}
              />
            </Form.Field>
          </Form.Group>
        )}

        <Form.Group style={{ marginBottom: 0 }}>
          <Form.Field style={{ flex: 1 }} />
          <Form.Field style={{ padding: 0 }}>
            <Button color="green" size="mini" onClick={handleSave}>
              SAVE
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    ),
    [handleSave, isRequireRate, props.modSolventSelectionConfig, props.options, route]
  );

  return (
    <Popup
      content={content}
      disabled={props.disabled}
      open={isOpen}
      position="bottom center"
      style={styles.popup}
      trigger={trigger}
      on={POPUP_ON}
      onClose={handleClose}
      onOpen={handleOpen}
    />
  );
};

PopupEditRouteDesc.displayName = "PopupEditRouteDesc";

export default React.memo(PopupEditRouteDesc);
