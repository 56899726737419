import { ContentTable, TDocumentDefinitions } from "pdfmake/interfaces";

import { splitStringNewLine } from "../../common/CommonInterface";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

// Types
type FormDoctorIncomeSummaryProps = {
  doctor_name: string;
  division_name: string;
  period: string;
  start_date: string;
  end_date: string;
  print_date_time: string;
  print_user: string;
  total_revenue_price: number;
  total_revenue_outpatient: number;
  total_revenue_credit: number;
  final_revenue_credit: number;
  total_compensation_price: number;
  total_revenue_point: number;
  final_revenue_point: number;
  total_paid_price: number;
  total_waiting_claim_price: number;
  total_lab_df_deduct: number;
  total_lab_price: number;
  total_waiting_claim_price_cumulative: number;
  total_point: number;
  isByYear: boolean;
  reportName: string;
  items: ReportDoctorIncomeSummary[];
};

type ReportDoctorIncomeSummary = DetailFields &
  Partial<SummaryFields> & {
    [K in keyof SummaryFields as `${K}_list`]?: SummaryFields[K][];
  };

type DetailFields = {
  examination_type: string;
  year: number | string;
  month: string;
  doctor_position: string;
};

type SummaryFields = {
  item_code: string;
  item_name: string;
  quantity: number;
  revenue: number;
  management_fee: number;
  credit_card_fee: number;
  leftover: number;
  compensation_percent: number;
  final_revenue_price: number;
  net_price: number;
  paid_price: number;
  waiting_claim_price: number;
  payment_method: string;
};

type KeyType = typeof KEYS;

interface ContentTableWithId extends Omit<ContentTable, "table"> {
  id?: string;
  table: ContentTable["table"];
}

interface ITDocumentDefinitions extends TDocumentDefinitions {
  content: (TDocumentDefinitions["content"] | ContentTableWithId)[];
}

const TB_MARGIN = 4;

const FONT_SIZE = 12;

const BOTTOM = 535;

const KEYS = [
  "item_name",
  "item_code",
  "quantity",
  "revenue",
  "management_fee",
  "credit_card_fee",
  "leftover",
  "compensation_percent",
  "final_revenue_price",
  "net_price",
  "paid_price",
  "waiting_claim_price",
  "payment_method",
] as const;

const getHeight = (tables: any[], i: number) => {
  const type: any[] = tables[i]?.[0]?.stack || [];
  const name: any[] = tables[i]?.[5]?.stack || [];

  const maxLine = Math.max(type.length, name.length);

  const height = maxLine * (FONT_SIZE + TB_MARGIN) + TB_MARGIN;

  return height;
};

const formatPrice = (value: number | string) => {
  return value !== ""
    ? Number(value).toLocaleString("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    : "";
};

const FormDoctorIncomeSummary = async (props: FormDoctorIncomeSummaryProps): Promise<ITDocumentDefinitions> => {
  const headerForm = await HeaderSummaryReportForm({
    title: props.reportName || "รายงานสรุปรายได้",
    isLightLines: true,
    data: {
      start_date: props.start_date,
      end_date: props.end_date,
      print_date_time: props.print_date_time,
      print_user: props.print_user,
      period: props.period || "ทุกคาบ",
      division_name: props.division_name,
      doctor_name: props.doctor_name,
    },
    pageMargins: [10, 107.5, 10, 15],
    extra: {
      margin: [10, 10, 10, 0],
      table: {
        widths: ["100%"],
        heights: 465,
        body: [[{ text: "", border: [true, false, true, true] }]],
      },
      layout: {
        hLineWidth: () => 0.25,
        vLineWidth: () => 0.25,
      },
    },
  });

  const formattedItems = props.items.flatMap((item) => {
    return item.item_name_list
      ? item.item_name_list.map((_, index) => {
          return {
            ...(index === 0 ? item : {}),
            ...Object.fromEntries(KEYS.flatMap((key) => [[key, item[`${key}_list`]?.[index]]])),
          };
        })
      : [{ ...item }];
  });

  // #const groupIndexes = props.items.map((item, index) => item.item_name_list?.length || index + 1);

  const itemCount = formattedItems.length;

  const tables = formattedItems.map((item, pIndex) => {
    const nameLines = splitStringNewLine(item.item_name || " ", {
      width: 160,
      fontSize: FONT_SIZE,
    }).flatMap((text) => (text.includes("\n") ? [text.replace("\n", ""), ""] : [text]));
    const typeLines = splitStringNewLine(item.examination_type || " ", {
      width: 61,
      fontSize: FONT_SIZE,
    });

    const maxLength = Math.max(nameLines.length, typeLines.length);

    const addBlankLine = (value: string | number) => {
      const blank = Array.from({ length: maxLength - 1 }).fill("");

      return [value, ...blank];
    };

    const formattedLine = Object.fromEntries(
      KEYS.map((key) => [key, addBlankLine(item[key] ?? "")])
    ) as Record<KeyType[number], (string | number)[]>;

    const lines = {
      ...formattedLine,
      item_name: nameLines,
    };

    const formatValue = (key: KeyType[number]) => (value: number | string) => {
      const content = !["item_name", "item_code", "quantity", "payment_method"].includes(key)
        ? {
            text: formatPrice(value),
            alignment: "right",
            noWrap: true,
            color: key === "waiting_claim_price" && value ? "red" : "",
          }
        : { text: value, noWrap: true };

      return content;
    };

    const columns = Object.fromEntries(
      KEYS.map((key) => {
        return [
          key,
          {
            stack: lines[key].map(formatValue(key)),
          },
        ];
      })
    ) as Record<KeyType[number], any>;

    return [
      {
        stack: typeLines.map((text) => ({ text, noWrap: true })),
        alignment: "center",
      },
      {
        text: item.year,
        alignment: "center",
      },
      { text: item.month },
      { text: item.doctor_position },
      { ...columns.item_code },
      { ...columns.item_name },
      { ...columns.quantity, alignment: "center" },
      { ...columns.revenue },
      { ...columns.management_fee },
      { ...columns.credit_card_fee },
      { ...columns.leftover },
      { ...columns.compensation_percent },
      { ...columns.final_revenue_price },
      { ...columns.net_price },
      { ...columns.paid_price },
      { ...columns.waiting_claim_price },
      { ...columns.payment_method, alignment: "center" },
    ];
  });

  console.log("FormDoctorIncomeSummary", props);

  return {
    ...headerForm,
    pageOrientation: `landscape`,
    defaultStyle: {
      font: `THSarabunNew`,
      fontSize: FONT_SIZE,
    },
    pageSize: `A4`,
    content: [
      {
        table: {
          headerRows: 1,
          dontBreakRows: true,
          widths: [
            "4.25%",
            "3%",
            "6%",
            "5%",
            "5%",
            "15.5%",
            "4%",
            "5.75%",
            "6.5%",
            "5.75%",
            "5.75%",
            "6.5%",
            "5.75%",
            "5.75%",
            "5.75%",
            "5.75%",
            "4%",
          ],
          body: [
            [
              { text: "ประเภท ลงตรวจ", style: "tableHeader" },
              { text: "ปี", style: "tableHeader" },
              { text: props.isByYear ? "" : "เดือน", style: "tableHeader" },
              { text: "ตำแหน่ง แพทย์", style: "tableHeader" },
              { text: "รหัส", style: "tableHeader" },
              { text: "รายการ", style: "tableHeader" },
              { text: "จำนวน", style: "tableHeader" },
              { text: "รายรับรวม", style: "tableHeader" },
              { text: "หักค่าบริหาร", style: "tableHeader" },
              { text: "หักค่า ธรรมเนียม บัตรเครดิต", style: "tableHeader" },
              { text: "คงเหลือ", style: "tableHeader" },
              { text: "ส่วนแบ่ง(%)", style: "tableHeader" },
              { text: "รายรับ หลังหัก ส่วนแบ่ง", style: "tableHeader" },
              { text: "รวมสุทธิ", style: "tableHeader" },
              { text: "ชำระแล้ว", style: "tableHeader" },
              { text: "รอเบิก", style: "tableHeader" },
              { text: "ประเภท รายรับ", style: "tableHeader" },
            ],
            ...tables,
          ],
        },
        layout: {
          vLineWidth: (i) => {
            // return i === 0 || i === 17 ? 0.25 : 0;
            return props.isByYear && i === 3 ? 0 : 0.25;
          },
          hLineWidth: (i) => {
            const cNps: any[] = tables[i - 3]?.[0]?.positions || [];
            const nNps: any[] = tables[i - 2]?.[0]?.positions || [];

            const currentTop = cNps.slice(-1)[0]?.top || 0;
            const nextTop = nNps.slice(-1)[0]?.top || 0;

            const isLast = i >= itemCount + 1 && i !== 1;

            const prevHeight = getHeight(tables, i - 3);
            const currentHeight = getHeight(tables, i - 2);
            const nextHeight = getHeight(tables, i - 1);

            const isLastRowOfPage =
              nextTop + currentHeight > BOTTOM - FONT_SIZE &&
              nextTop + currentHeight + nextHeight > BOTTOM + FONT_SIZE &&
              currentTop + prevHeight + currentHeight < BOTTOM + FONT_SIZE + TB_MARGIN + 2;

            return isLast || isLastRowOfPage ? 0 : 0.25;
          },
          paddingBottom: (i, node: any) => {
            const nps: any[] = node.positions || [];

            const top = nps.slice(-1)[0]?.top || 0;
            const currentHeight = getHeight(tables, i - 2);

            const currentTop = top + currentHeight;

            return i === itemCount && currentTop < 382.5 ? 382.5 - currentTop : TB_MARGIN;
          },
          paddingTop: () => TB_MARGIN,
        },
      },
      {
        id: "summary",
        table: {
          widths: ["100%"],
          body: [
            [
              {
                border: [false, true, false, false],
                fontSize: 14,
                margin: [5, 5, 0, 0],
                columns: [
                  {
                    width: "17.5%",
                    stack: [
                      {
                        text: "หมายเหตุ ส่วนแบ่ง (%) ยังไม่ได้ถูกหักค่า Lab (ถ้ามี)",
                        noWrap: true,
                      },
                      {
                        text: "C- ได้รับเป็นเงิน  P- ได้รับเป็นแต้มสะสม",
                        noWrap: true,
                        marginTop: 5,
                      },
                    ],
                  },
                  {
                    width: "*",
                    text: "",
                  },
                  {
                    width: "80%",
                    stack: [
                      {
                        columns: [
                          { text: "", width: "55%" },
                          {
                            columns: [
                              { text: "รายรับรวมทั้งหมด", width: "auto" },
                              { text: props.total_revenue_price, width: "auto", marginLeft: 25 },
                            ],
                            noWrap: true,
                          },
                          { text: "", width: "*" },
                        ],
                      },
                      ...[
                        {
                          width: "90%",
                          columns: [
                            [
                              {
                                width: "29%",
                                text: "ค่าบริการผู้ป่วยนอก",
                                value: formatPrice(props.total_revenue_outpatient),
                              },
                              {
                                width: "37%",
                                text: "รายรับจากการรักษา (รับเป็นเงิน)",
                                value: formatPrice(props.total_revenue_credit),
                              },
                              {
                                width: "34%",
                                text: "รายรับที่แบ่ง (รับเป็นเงิน)",
                                value: formatPrice(props.final_revenue_credit),
                              },
                            ],
                            [
                              {
                                width: "29%",
                                text: "รายรับที่ไม่แบ่ง",
                                value: formatPrice(props.total_compensation_price),
                              },
                              {
                                width: "37%",
                                text: "รายรับจากโครงการ (แต้มสะสม)",
                                value: formatPrice(props.total_revenue_point),
                              },
                              {
                                width: "34%",
                                text: "รายรับที่แบ่ง (แต้มสะสม)",
                                value: formatPrice(props.final_revenue_point),
                              },
                            ],
                          ],
                        },
                        {
                          width: "95%",
                          columns: [
                            [
                              {
                                width: "25%",
                                text: "ยอดรวมผู้ป่วยชำระแล้ว",
                                value: formatPrice(props.total_paid_price),
                              },
                              {
                                width: "25%",
                                text: "ยอดรวมรอเบิก",
                                value: formatPrice(props.total_waiting_claim_price),
                              },
                              {
                                width: "25%",
                                text: "ยอดค่าLab (หักแพทย์)",
                                value: formatPrice(props.total_lab_df_deduct),
                              },
                              {
                                width: "25%",
                                text: "ยอดค่าLab (ไม่หักแพทย์)",
                                value: formatPrice(props.total_lab_price),
                              },
                            ],
                            [
                              {
                                width: "25%",
                                text: "ยอดรวมรอเบิก(สะสม)",
                                value: formatPrice(props.total_waiting_claim_price_cumulative),
                              },
                              { width: "25%", text: "ยอดรวมแต้ม(สะสม)", value: props.total_point },
                            ],
                          ],
                        },
                      ].map((item) => ({
                        marginBottom: 2.5,
                        columns: [
                          { width: "*", text: "" },
                          {
                            width: item.width,
                            stack: item.columns.map((columns) => ({
                              marginTop: 10,
                              columns: columns.map(({ text, value, width }) => ({
                                width,
                                columns: [
                                  { width: "*", text },
                                  { width: "auto", text: value },
                                ],
                                marginRight: 30,
                              })),
                            })),
                          },
                        ],
                      })),
                    ],
                  },
                ],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
        },
      },
    ],
    pageBreakBefore: (currentNode) => {
      const pageNumbers = currentNode.pageNumbers;

      if (currentNode.id === "summary") {
        return pageNumbers.length !== 1 || pageNumbers[0] !== currentNode.pages;
      }

      return false;
    },
    styles: {
      ...headerForm.styles,
      tableHeader: {
        bold: true,
        alignment: "center",
        fontSize: 12,
      },
    },
    images: {
      ...headerForm.images,
    },
  };
};

export default FormDoctorIncomeSummary;
