import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import ReactTable from "react-table-6";
import CardCSDivisionSearch from "./CardCSDivisionSearch";
import CardCSDoctorSearch from "./CardCSDoctorSearch";
import { Pagination, PaginationProps, Icon, Modal, Button } from "semantic-ui-react";
import { formatDatetime } from "react-lib/utils/dateUtils";
import ModInfo from "react-lib/apps/common/ModInfo";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

interface CardCSDiagnosisProps {
  allDivisionList: any;
  allDoctorList: any;
  patientDataAPI: any;
  handleGetCSDiagnosisList: any;
  handlePatchDiagnosis: any;
  csDiagnosisList: any;
  isNurse: boolean;
}

const CardCSDiagnosis = forwardRef((props: CardCSDiagnosisProps, ref) => {

  const [doctorSearch, setDoctorSearch] = useState("")
  const [divisionSearch, setDivisionSearch] = useState("")

  const [activePage, setActivePage] = useState(1)
  const isMounted = useRef(true);
  const [isCSDiagnosisLoading, setIsCSDiagnosisLoading] = useState(false);
  const [isCSDivisionSearchLoading, setIsCSDivisionSearchLoading] = useState(false);
  const [isCSDoctorSearchLoading, setIsCSDoctorSearchLoading] = useState(false);

  const [openConfirmedDeactive, setOpenConfirmedDeactive] = useState(false)
  const [confirmDeactiveLoading, setConfirmDeactiveLoading] = useState(false)
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null)


  const [openModInfoSuccess, setOpenModInfoSuccess] = useState(false)
  const [openModInfoError, setOpenModInfoError] = useState(false)
  const [errorInfo, setErrorInfo] = useState(" ระบบผิดพลาด")
  const [deactivateLoading, setDeactivateLoading] = useState(false)


  const limit = 6

  useImperativeHandle(ref, () => ({
    getData: () => {
      // console.log(" useImperativeHandle CardCSDiagnosis")
      return {
        division: divisionSearch,
        doctor: doctorSearch,
        limit: limit,
        offset: activePage - 1,
      }
    },
  })
  )

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loadDiagnosis = async () => {
    // console.log(" Loading Diagnosis")
    setIsCSDiagnosisLoading(true);
    await props.handleGetCSDiagnosisList();
    setIsCSDiagnosisLoading(false);
  };


  useEffect(() => {
    loadDiagnosis();
  }, [props.patientDataAPI, doctorSearch, divisionSearch, activePage]);

  const columns = [
    {
      Header: "Date",
      // accessor: "edited", // String-based value accessors!
      resizable: false,
      width: 90,
      Cell: props => (<>{formatDatetime(props.original.edited)}</>),
    },
    {
      Header: "Diagnosis",
      accessor: "icd_term",
      resizable: false,
      Cell: props => (<>({props.original.icd_code}) {props.original.icd_term} </>),
    },
    {
      Header: "Type",
      accessor: "type_name",
      width: 90,
      resizable: false,
    },
    {
      Header: "Division/Doctor",
      Cell: props => (<><div className="division-label">{props.original.division_code}</div>{props.original.doctor_name}</>),
      resizable: false,
      width: 140,
    },
    {
      Header: "",
      resizable: false,
      Cell: d => {
        console.log(" CeLL props: ", props)
        return (<span style={{ cursor: props.isNurse ? "default" : "pointer" }}><Icon onClick={() => {
          // console.log(" Deatcive ", props.original.id)
          if (!props.isNurse) {
            handleClickDeactiveDisease(d.original.id)
          }
        }} color="red" disabled={props.isNurse} size="large" name="trash alternate" /></span>)
      },
      width: 50,
    },
  ];

  // console.log("props.csDiagnosisList",props.csDiagnosisList)

  // const diagnosisData = props.csDiagnosisList
  // .filter(item => {
  //   if (item.diagnosis?.principal_diagnosis?.length > 0 || item.diagnosis?.secondary_diagnosis?.length > 0 ) {
  //     return true 
  //   }
  //   else {
  //     return false
  //   }
  // }).map(item => {
  //   if ( item.diagnosis?.principal_diagnosis?.length > 0 ){ 
  //     return { 
  //       date: item.emr?.date,
  //       diagnosis: item.diagnosis?.principal_diagnosis,
  //       type: "PRINCIPLE",
  //       doctor: item.emr?.doctor_name,
  //     } 
  //   }  else if (item.diagnosis?.secondary_diagnosis?.length > 0 ) {
  //     return {
  //       date: item.emr?.date,
  //       diagnosis: item.diagnosis?.secondary_diagnosis,
  //       type: "SECONDARY",
  //       doctor: item.emr?.doctor_name
  //     }
  //   } 
  // })

  const handleClickDeactiveDisease = (id) => {
    // console.log(" handleClickDeactiveDisease", id)
    setSelectedDiagnosis(id)
    setOpenConfirmedDeactive(true)
  }

  const handleDeactivate = async () => {
    // setIsCSDiagnosisLoading(true)
    const [data, error, network] = await props.handlePatchDiagnosis(selectedDiagnosis, { active_disease: false })
    setDeactivateLoading(false)

    if (data) {
      setOpenModInfoSuccess(true)
    } else {
      setOpenModInfoError(true)
      setErrorInfo(error)
    }
    setOpenConfirmedDeactive(false)
    setSelectedDiagnosis(null)
    setActivePage(1)
    loadDiagnosis()
  }

  // console.log("prepare diagnosisData: ", props.csDiagnosisList)
  const totalPages = Math.ceil(props.csDiagnosisList?.total / limit);
  console.log(" CardCSDiagnosis props: ", props)
  return (
    <>
      {openModInfoSuccess ? (
        <ModInfo
          type="success"
          closeOnDimmerClick
          open={openModInfoSuccess}
          onApprove={() => { setOpenModInfoSuccess(false) }}
          onClose={() => { setOpenModInfoSuccess(false) }}
        >
          <div style={{ color: "green", textAlign: "left" }}> ลบเรียบร้อย </div>
        </ModInfo>)
        : null}
      {openModInfoError ? (
        <ModInfo
          type="error"
          closeOnDimmerClick
          open={openModInfoError}
          onApprove={() => { setOpenModInfoError(false) }}
          onClose={() => { setOpenModInfoError(false) }}
        >
          <ErrorMessage error={errorInfo} />
        </ModInfo>
      )
        : null}

      {openConfirmedDeactive ? (
        <Modal
          onClose={() => setOpenConfirmedDeactive(false)}
          trigger={<Button>Show Modal</Button>}
          open={openConfirmedDeactive}
        >
          <Modal.Header>คุณจะทำการลบ Diagnosis นี้ ใช่หรือไม่ ? </Modal.Header>
          <Modal.Content>
            <span style={{ fontWeight: "bold" }}>  Diagnosis: {props.csDiagnosisList?.items?.filter(item => item.id === selectedDiagnosis)[0]?.icd_term}  </span>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' onClick={() => {
              setOpenConfirmedDeactive(false)
            }}>
              ไม่
            </Button>
            <Button loading={deactivateLoading} color='red' onClick={() => {
              handleDeactivate()
              setDeactivateLoading(true)
            }}>
              ใช่
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}


      <div className="card-cs card-diagnosis">
        <div className="card-cs-header">
          {/* <div style={{ width: "200px" }}></div> */}
          {/* <div className="card-cs-header-title">Division</div> */}
          <CardCSDivisionSearch
            disabled={isCSDivisionSearchLoading}
            allDivisionList={props.allDivisionList}
            setDivisionSearch={(search) => {
              setDivisionSearch(search)
              setActivePage(1)
            }}
            isLoading={isCSDivisionSearchLoading}
          />
          {/* <div className="card-cs-header-title">Doctor</div> */}
          <CardCSDoctorSearch
            disabled={isCSDivisionSearchLoading}
            allDoctorList={props.allDoctorList}
            setDoctorSearch={(search) => {
              setDoctorSearch(search)
              setActivePage(1)
            }}
            isLoading={isCSDoctorSearchLoading}
          />
        </div>
        <div className="card-cs-header-title">Diagnosis History</div>
        <div className="card-wrap-cs-table">
          <ReactTable
            className="card-cs-table-none-border"
            columns={columns}
            loading={isCSDiagnosisLoading}
            data={props.csDiagnosisList?.items?.length > 0 ? props.csDiagnosisList?.items : []}
            showPagination={false}
            style={{ height: "100%" }}
            minRows={5}
            getTheadThProps={(state, rowInfo, column, instance) => {
              // console.log(" getTdProps", state, rowInfo, column, instance)
              return {
                style: {
                  fontSize: "12px",
                  border: "none",
                  boxShadow: "none",
                  textAlign: "left",
                },
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              // console.log(" getTdProps", state, rowInfo, column, instance)

              let style = {
                fontSize: "12px",
                border: "none",
                boxShadow: "none",
                fontWeight: "bolder",
                color: "#4F4F4F",
              };
              if (column.Header === "Order Type" || column.Header === "Division") {
                style = { ...style, ...{ fontWeight: "normal" } };
              }
              return {
                style: style,
              };
            }}
          />
        </div>
        {totalPages > 1 ? (
          <div style={{ textAlign: "center" }}>
            <Pagination
              disabled={!(props.csDiagnosisList?.items?.length > 0)}
              onPageChange={(
                e: React.MouseEvent<HTMLAnchorElement>,
                data: PaginationProps
              ) => {
                setActivePage(data.activePage as number);
              }}
              activePage={activePage}
              firstItem={null}
              lastItem={null}
              ellipsisItem={null}
              pointing
              secondary
              siblingRange={1}
              totalPages={totalPages}
            />
          </div>
        ) : null}
      </div>
    </>
  );
});

export default React.memo(CardCSDiagnosis);
