import React  from "react"
// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import {SubAssistantsInputProps} from "react-lib/apps/HISV3/ORM/SubAssistantsInput"

/* ------------------------------------------------------ */

/*                    SearchBoxWithKey;                   */

/* ------------------------------------------------------ */
type SearchBoxWithKeyProps = {
  id: string;
  disabled?: boolean;
  selectedItem?: any;
  // callback
  setSelected?: (value: any, key: any) => any;
} & Pick<
  SubAssistantsInputProps,
  "onEvent" | "type" | "role" | "searchedItemListWithKey" | "isDetail"
>;

const SearchBoxWithKey = (props: SearchBoxWithKeyProps) => {
  const handleSelect = (value: any, key: any) => {
    if (props.isDetail) {
      const key = `${props.type}_${props.id}`;
      const detail = props.searchedItemListWithKey?.[key]?.find(
        (item: any) => item.id === value
      );

      value = detail
        ? {
            id: detail.id,
            name_code: detail.name_code,
          }
        : null;
    }

    return props.setSelected?.(value, key);
  };

  return (
    <SearchBoxDropdown
      type={props.type}
      id={props.id?.toString()}
      style={{ width: "100%" }}
      boxStyle={{ width: "100%" }}
      fluid={true}
      label=""
      role={props.role}
      disabled={props.disabled}
      // disabled={selectedManagePatient !== null}
      onEvent={props.onEvent}
      // searchedItemList={props.searchedItemList}
      searchedItemListWithKey={props.searchedItemListWithKey}
      selectedItem={props.selectedItem}
      setSelectedItem={handleSelect}
      useWithKey={true}
    />
  );
};

SearchBoxWithKey.displayName = "SearchBoxWithKey";

export default React.memo(SearchBoxWithKey);
