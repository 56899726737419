import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Checkbox,
  TextArea,
  Segment,
  Label,
  Dropdown,
  Form,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const ModWaitingListManageUX = (props: any) => {
    return(
      <div>
        
        <Segment>
          <Label
            color="blue"
            ribbon={true}
            style={{fontSize: "1.2rem", fontWeight: "bold"}}>
            จัดการรายชื่อผู้ป่วย Waiting List
          </Label>
          <Label
            size="big">
            {props.waitingListName}
          </Label>
          <div
            style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px 0px 10px 0px"}}>
            
            <div
              style={{display: props.isHiddenGroup ? "none": "flex", flexDirection: "column", width: "40%", padding: "0px 5px"}}>
              ชื่อ-สกุล / HN
              <div
                style={{width: "100%", margin: "10px 0px"}}>
                {props.patientSelector}
              </div>
            </div>
            <div
              style={{display: props.isHiddenGroup ? "none": "flex", flexDirection: "column", width: "30%",padding: "0px 5px"}}>
              ทันตแพทย์ / นิสิต
              <div
                style={{width: "100%", margin: "10px 0px"}}>
                {props.doctorSelector}
              </div>
            </div>
            <div
              style={{flex:1}}>
              
            </div>
            <div
              style={{display: "flex", flexDirection: "column", alignSelf: "flex-end",paddingBottom: "12px"}}>
              {}
              <Button
                color="green"
                disabled={props.disableAddPatient}
                onClick={props.addPatientToWaitingList}
                size="small"
                style={{...(props.isHiddenGroup && {display: "none"})}}>
                เพิ่มผู้ป่วยเข้า List
              </Button>
            </div>
            <div
              style={{display: "flex", flexDirection: "column",alignSelf: "flex-end",paddingBottom: "12px"}}>
              
              <Button
                color="blue"
                disabled={props.disabledPrint}
                onClick={props.printWaitingList}
                size="small">
                พิมพ์รายการทั้งหมด
              </Button>
            </div>
          </div>
          <div>
            {props.divider}
          </div>
          <div
            style={{display: "none", justifyContent: "flex-start", alignItems: "center", padding: "10px 10px"}}>
            
            <Checkbox
              checked={props.isAppointed}
              label="แสดงรายชื่อผู้ป่วยที่มีนัดหมายแล้ว"
              onClick={props.toggleIsAppointed}
              style={{marginRight: "30px"}}>
            </Checkbox>
            <Checkbox
              checked={props.isOnHoldQ}
              label="แสดงรายชื่อผู้ป่วยที่พักคิว"
              onClick={props.toggleIsOnHoldQ}
              style={{marginRight: "30px",}}>
            </Checkbox>
          </div>
          <div
            style={{display:"flex",justifyContent:"space-between",alignItems:"flex-start",marginTop: "2px"}}>
            
            <Form>
              <FormGroup
                inline={true}>
                <FormField>
                  <label>
                    วันที่เข้าคิว
                  </label>
                  <DateTextBox
                    onChange={props.onChangeStartDate}
                    value={props.startDate}>
                  </DateTextBox>
                </FormField>
                <FormField>
                  <label>
                    ถึง
                  </label>
                  <DateTextBox
                    onChange={props.onChangeEndDate}
                    value={props.endDate}>
                  </DateTextBox>
                </FormField>
                <FormField
                  style={{display:"flex",alignItems:"center"}}>
                  <label>
                    สถานะ
                  </label>
                  <Dropdown
                    clearable={true}
                    multiple={true}
                    onChange={props.onChangeFilterStatus}
                    options={props.statusOptions}
                    selection={true}
                    value={props.filterStatus}>
                  </Dropdown>
                </FormField>
                <FormField>
                  <div
                    style={{display: "flex",alignItems:"center"}}>
                    
                    <Checkbox
                      checked={props.isFilterDoctor}
                      disabled={props.disabledFilterDoctor}
                      label="แสดงตามชื่อแพทย์/นิสิต"
                      onChange={props.toggleIsFilterDoctor}
                      style={{marginRight: "30px"}}>
                    </Checkbox>
                    <Dropdown
                      disabled={props.disabledFilterDoctorDropdown}
                      onChange={props.onChangeSelectedFilterDoctor}
                      options={props.doctorLists}
                      selection={true}
                      value={props.selectedFilterDoctor}>
                    </Dropdown>
                  </div>
                </FormField>
              </FormGroup>
            </Form>
            <Button
              color="blue"
              loading={props.waitingListLoading}
              onClick={props.onClickSearch}>
              ค้นหา
            </Button>
          </div>
          <Table
            data={props.waitingListItems}
            getTrProps={props.getTrPropsWaitingList}
            headers=",เลขที่คิว,ใบคิว,วันที่เข้าคิว,HN,ชื่อ-สกุล,เบอร์โทรติดต่อ,แพทย์/นิสิต,สถานะ,ประเภทการรักษา,Note"
            keys="checkbox,code,iconPrint,createdFormat,patient_hn,patient_name,patient_tel,provider_name,statusNameWithReason,type_name,note"
            minRows="4"
            showPagination={false}
            style={{maxHeight: "50vh"}}
            widths="30,80,80,150,80,auto,120,150,160,160,160">
          </Table>
          <div>
            {props.pagination}
          </div>
          <div
            style={{ marginTop:"15px", display: props.disableAssign ? "none" : "block" }}>
            
            <div
              style={{display: "flex", flexDirection:"row"}}>
              
              <div
                style={{width: "50%"}}>
                {props.providerSelector}
              </div>
              <div
                style={{display:"flex", justifyContent: "center", alignItems:"center"}}>
                ประเภทการนัดหมาย
                <Dropdown
                  onChange={props.onChangeTreatmentType}
                  options={props.treatmentTypeOptions}
                  selection={true}
                  style={{marginLeft:"10px"}}
                  value={props.treatmentType}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{margin:"20px 0px 10px 0px",fontWeight: "bold"}}>
              รายละเอียด, ตำแหน่ง, หมายเหตุ
            </div>
            <Form>
              <TextArea
                onChange={props.changeNote}
                style={{width: "100%", margin :"0px 0px 20px 0px"}}
                value={props.note}>
              </TextArea>
            </Form>
            <div
              style={{display:"flex", flexDirection: "row"}}>
              
              <Button
                color="green"
                disabled={props.disableAssign}
                onClick={props.assignWaitingListItems}
                size="tiny"
                style={{marginRight: "10px"}}>
                Save
              </Button>
              <Button
                color="orange"
                disabled={props.disabledPauseQ}
                onClick={props.onClickPauseQ}
                size="tiny"
                style={{marginRight: "10px"}}>
                พักคิว
              </Button>
              <Button
                color="blue"
                disabled={props.disabledResumeQ}
                onClick={props.onClickResumeQ}
                size="tiny"
                style={{marginRight: "10px"}}>
                คืนคิว
              </Button>
              <Button
                color="red"
                onClick={props.onCancelTreatmentPlan}
                size="tiny"
                style={{marginRight: "10px"}}>
                ยกเลิกวางแผนการรักษา
              </Button>
              <Button
                onClick={props.onSendToClinic}
                size="tiny"
                style={{backgroundColor: "#5bbdd2", color: "white"}}>
                ส่งต่อคลินิก
              </Button>
            </div>
          </div>
          <div
            style={{padding: "10px",display:props.openModClinic ? "":"none"}}>
            {props.OpenClinic}
          </div>
        </Segment>
      </div>
    )
}


export default ModWaitingListManageUX

export const screenPropsDefault = {"patientSelector":"[Patient Selector]","providerSelector":"[Provider Selector]"}

/* Date Time : Fri Jun 28 2024 16:59:26 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 20,
      "props": {
        "data": {
          "type": "code",
          "value": "props.waitingListItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsWaitingList"
        },
        "headers": {
          "type": "value",
          "value": ",เลขที่คิว,ใบคิว,วันที่เข้าคิว,HN,ชื่อ-สกุล,เบอร์โทรติดต่อ,แพทย์/นิสิต,สถานะ,ประเภทการรักษา,Note"
        },
        "keys": {
          "type": "value",
          "value": "checkbox,code,iconPrint,createdFormat,patient_hn,patient_name,patient_tel,provider_name,statusNameWithReason,type_name,note"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"50vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "30,80,80,150,80,auto,120,150,160,160,160"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\", padding: \"20px 0px 10px 0px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มผู้ป่วยเข้า List"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableAddPatient"
        },
        "onClick": {
          "type": "code",
          "value": "props.addPatientToWaitingList"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(props.isHiddenGroup && {display: \"none\"})}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSelector"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"10px 0px\"}",
          "valueEN": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", justifyContent: \"flex-start\", alignItems: \"center\", padding: \"10px 10px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isAppointed"
        },
        "label": {
          "type": "value",
          "value": "แสดงรายชื่อผู้ป่วยที่มีนัดหมายแล้ว"
        },
        "onClick": {
          "type": "code",
          "value": "props.toggleIsAppointed"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.providerSelector"
        },
        "style": {
          "type": "code",
          "value": "{width: \"50%\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginTop:\"15px\", display: props.disableAssign ? \"none\" : \"block\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableAssign"
        },
        "onClick": {
          "type": "code",
          "value": "props.assignWaitingListItems"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายการทั้งหมด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrint"
        },
        "onClick": {
          "type": "code",
          "value": "props.printWaitingList"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ทันตแพทย์ / นิสิต"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isHiddenGroup ? \"none\": \"flex\", flexDirection: \"column\", width: \"30%\",padding: \"0px 5px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "TextArea",
      "parent": 36,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeNote"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin :\"0px 0px 20px 0px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 30,
      "void": true
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", flexDirection: \"row\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียด, ตำแหน่ง, หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"20px 0px 10px 0px\",fontWeight: \"bold\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.OpenClinic"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\",display:props.openModClinic ? \"\":\"none\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "จัดการรายชื่อผู้ป่วย Waiting List"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "ribbon": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล / HN"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isHiddenGroup ? \"none\": \"flex\", flexDirection: \"column\", width: \"40%\", padding: \"0px 5px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSelector"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\", alignSelf: \"flex-end\",paddingBottom: \"12px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\",alignSelf: \"flex-end\",paddingBottom: \"12px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOnHoldQ"
        },
        "label": {
          "type": "value",
          "value": "แสดงรายชื่อผู้ป่วยที่พักคิว"
        },
        "onClick": {
          "type": "code",
          "value": "props.toggleIsOnHoldQ"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\",}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 31,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentType"
        },
        "options": {
          "type": "code",
          "value": "props.treatmentTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.treatmentType"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection:\"row\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"center\", alignItems:\"center\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "พักคิว"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPauseQ"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickPauseQ"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนคิว"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledResumeQ"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickResumeQ"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกวางแผนการรักษา"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelTreatmentPlan"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Label",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.waitingListName"
        },
        "size": {
          "type": "value",
          "value": "big"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Form",
      "parent": 12,
      "props": {
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งต่อคลินิก"
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onSendToClinic"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#5bbdd2\", color: \"white\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Checkbox",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isFilterDoctor"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledFilterDoctor"
        },
        "label": {
          "type": "value",
          "value": "แสดงตามชื่อแพทย์/นิสิต"
        },
        "onChange": {
          "type": "code",
          "value": "props.toggleIsFilterDoctor"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Dropdown",
      "parent": 39,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledFilterDoctorDropdown"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSelectedFilterDoctor"
        },
        "options": {
          "type": "code",
          "value": "props.doctorLists"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedFilterDoctor"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"space-between\",alignItems:\"flex-start\",marginTop: \"2px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormGroup",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 47,
      "name": "DateTextBox",
      "parent": 45,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Form",
      "parent": 43,
      "props": {
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 52,
      "name": "DateTextBox",
      "parent": 50,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เข้าคิว"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 44,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterStatus"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filterStatus"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 44,
      "props": {
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divider"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Button",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.waitingListLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickSearch"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pagination"
        }
      },
      "seq": 43,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "ModWaitingListManageUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "patientSelector": "[Patient Selector]",
    "providerSelector": "[Provider Selector]"
  },
  "width": 40
}

*********************************************************************************** */
