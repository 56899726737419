import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from "react";

import { Button, Dropdown, Input } from "semantic-ui-react";

import { Column } from "react-table-6";

// Framework
import { Table } from "react-lib/frameworks/Table";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

import { CARD_EXPORT_E_CLAIM_FILE } from "./CardExportEClaimFile";
import CardClaimInformationDetailUX from "./CardClaimInformationDetailUX";

import {
  AE_TYPE_OPTIONS,
  ClaimInfoDetailType,
  DISEASE_TYPE_OPTIONS,
  DRUG_REMARK_OPTIONS,
  EM_TYPE_OPTIONS,
  GENDER_OPTIONS,
  Key16Files,
  MARRIAGE_OPTIONS,
  MasterOptionsType,
  PROCEDURE_OPTIONS,
  REFER_TYPE_OPTIONS,
  RunSequence,
  TABLE_16_FILES,
  UCAE_TYPE_OPTIONS,
  USE_STATUS_OPTIONS,
  UUC_TYPE_OPTIONS,
  sanitizeNumber,
} from "./sequence/ExportEClaimFile";

// Utils
import { beToAd } from "react-lib/utils/dateUtils";

type CardClaimInformationDetailProps = {
  onEvent: (e: any) => any;
  setProp: (key: any, value: any, callback?: () => any) => any;
  // seq
  runSequence: RunSequence;
  // options
  masterOptions?: MasterOptionsType;
  // data
  masterData?: Record<string, any>;
  userTokenize?: any;
  claimInfoDetail?: Partial<ClaimInfoDetailType> | null;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose: () => void;
};

const BUTTON_ACTIONS = {
  EDIT: "EDIT_REF_NO",
  SAVE: "SAVE_ECLAIM",
};

const EDITOR_DROPDOWNS: Record<string, { placeholder: string; options: string }> = {
  changwat: { placeholder: "จังหวัด", options: "provider" },
  marriage: { placeholder: "สภาพสมรส", options: "marriage" },
  amphur: { placeholder: "อำเภอ", options: "amphur" },
  sex: { placeholder: "เพศ", options: "gender" },
  occupa: { placeholder: "อาชีพ", options: "career" },
  nation: { placeholder: "สัญชาติ", options: "nationality" },
  dxtype: { placeholder: "ชนิดของโรค", options: "disease_type" },
  oper: { placeholder: "รหัสหัตถการตาม ICD9 CM", options: "icd9cm" },
  refertype: { placeholder: "ประเภทการส่งต่อ", options: "refer_type" },
  diag: { placeholder: "วินิจฉัยโรค ตามรหัส ICD 10", options: "icd10" },
  optype: { placeholder: "ชนิดของหัตถการ", options: "procedure" },
  aetype: { placeholder: "สิทธิการรักษาอื่น กรณีอุบัติเหตุ", options: "ae_type" },
  ucae: { placeholder: "รหัสบ่งบอกการรักษากรณีอุบัติเหตุ/ ฉุกเฉิน", options: "ucae_type" },
  emtype: { placeholder: "รหัสข้อบ่งชี้ของกรณีฉุกเฉินตามเงื่อนไข", options: "em_type" },
  uuc: { placeholder: "การใช้สิทธิ", options: "uuc_type" },
  use_status: { placeholder: "หมวดรายการยา", options: "use_status" },
  drugremark: {
    placeholder: "รหัสเหตุผลการใช้ยา",
    options: "drug_remark",
  },
};

const EDITOR_INPUTS: Record<
  string,
  {
    placeholder: string;
    max?: number;
    type?: "number" | "string";
    formatted?: (value: string) => string;
  }
> = {
  drdx: { placeholder: "เลขที่ใบประกอบวิชาชีพ", max: 6 },
  dropid: { placeholder: "แพทย์ผู้รักษา ตามเลขที่ใบประกอบวิชาชีพเวชกรรม", max: 6 },
  adm_w: {
    placeholder: "น้ำหนักแรกรับ",
    max: 7,
    type: "number",
    formatted: sanitizeNumber,
  },
  hospmain: { placeholder: "รหัสสถานพยาบาลหลัก", max: 5 },
  hospsub: { placeholder: "รหัสสถานพยาบาลรอง", max: 5 },
  govcode: { placeholder: "รหัสหน่วยงานต้นสังกัดของผู้มีสิทธิ", max: 6 },
  govname: { placeholder: "ชื่อหน่วยงานต้นสังกัดของผู้มีสิทธิ", max: 255 },
  permitno: { placeholder: "รหัส Claim Code /เลขอนุมัติ", max: 13 },
  docno: { placeholder: "เลขที่หนังสือ", max: 30 },
  refer: { placeholder: "สถานพยาบาลหรือคลินิกที่เกี่ยวข้องกับการส่งต่อ", max: 5 },
  authae: { placeholder: " Claim code", max: 12 },
  refer_no: { placeholder: "เลขที่ใบส่งต่อ", max: 20 },
  refmaini: { placeholder: "รหัสโรงพยาบาลที่ส่งผู่ป่วยมารับการรักษา", max: 5 },
  ireftype: { placeholder: "รหัสวัตถุประสงค์ของการรับ Refer", max: 4 },
  refmaino: { placeholder: "รหัสโรงพยาบาลที่ส่งต่อไปรักษา (Refer Out)", max: 5 },
  oreftype: { placeholder: "รหัสวัตถุประสงค์ของการ Refer ออก", max: 4 },
  clinic: { placeholder: "รหัสคลินิกที่รับบริการ", max: 4 },
  did: { placeholder: "รหัสยาเก่าตามระบบเดิมที่เก็บไว้", max: 30 },
  didname: { placeholder: "ชื่อยาที่ใช้อยู่ปัจจุบันสัมพันธ์กับ DID", max: 255 },
  amount: { placeholder: "จำนวนยาที่จ่าย", max: 12, type: "number" },
  drugpric: { placeholder: "ราคาขายต่อหน่วย", max: 14, type: "number" },
  drugcost: { placeholder: "ราคาทุน", max: 14, type: "number" },
  didstd: { placeholder: "รหัสยาที่กำหนดเป็น 24 หลัก", max: 24 },
  unit: { placeholder: "หน่วยนับของยาที่ใช้ในการจ่ายยา ", max: 20 },
  unit_pack: { placeholder: "ขนาดบรรจุต่อหน่วยนับ", max: 20 },
  pa_no: {
    placeholder: "เลขที่ได้รับการอนุมัติ",
    max: 9,
  },
  totcopay: {
    placeholder: "จำนวนเงินรวม เป็นบาท ในส่วนที่เบิกไม่ได้",
    max: 12,
    type: "number",
    formatted: (value) => sanitizeNumber(value, 2),
  },
};

const EDITOR_DATE_TEXT_BOXES: Record<string, { placeholder: string }> = {
  datein: { placeholder: "วันเดือนปีที่เริ่มทำหัตถการ " },
  dob: { placeholder: "วันเกิด" },
  datedsc: { placeholder: "วันจำหน่าย" },
  dateout: { placeholder: "วันเดือนปีที่เริ่มทำหัตถการสิ้นสุด" },
  datedx: { placeholder: "วันเดือนปีที่วินิจฉัยโรค" },
  dateopd: { placeholder: "วันที่รับบริการ" },
  date_serv: { placeholder: "วันที่ที่รับบริการ" },
};

const EDITOR_TIME_COMBOBOXES: Record<string, { placeholder: string }> = {
  timedsc: { placeholder: "เวลาจำหน่าย" },
  timein: { placeholder: "เวลาเริ่ม บันทึกเป็นชั่วโมง นาที" },
  timeout: { placeholder: "เวลาสิ้นสุด บันทึกเป็นชั่วโมง นาที" },
  aetime: { placeholder: "เวลาที่เกิดอุบัติเหตุ" },
  timeopd: { placeholder: "เวลาที่บันทึกเป็นชั่วโมง นาที" },
};

const CARD_CLAIM_INFORMATION_DETAIL = "CardClaimInformationDetail";

const CardClaimInformationDetail = (props: CardClaimInformationDetailProps) => {
  const [selectedType, setSelectedType] = useState<Key16Files>("ins");
  const [refNo, setRefNo] = useState<string>("");
  const [editModeIndex, setEditModeIndex] = useState<number | null>(null);
  const [openModConfirm, setOpenModConfirm] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    setRefNo(props.claimInfoDetail?.ref_no || "");
    setEditModeIndex(null);
  }, [props.claimInfoDetail?.ref_no]);

  useEffect(() => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: "SELECT_TYPE",
      type: selectedType,
    });
  }, [selectedType]);

  // Callback memo
  const mapOption = useCallback((items?: any[], id = "") => {
    return (items || []).map((item) => ({
      key: item.id,
      text: item.name,
      value: item[id],
    }));
  }, []);

  // Memo callback
  const providerOptions = useMemo(() => {
    return mapOption(props.masterData?.province, "code").filter((option) => option.value);
  }, [props.masterData?.province]);

  const districtOptions = useMemo(() => {
    return mapOption(props.masterData?.district, "code");
  }, [props.masterData?.district]);

  const careerOptions = useMemo(() => {
    return mapOption(props.masterData?.career, "code");
  }, [props.masterData?.career]);

  const nationalityOptions = useMemo(() => {
    return mapOption(props.masterData?.nationality, "id").map((data) => ({
      ...data,
      value: (data.value as number).toString().padStart(3, "0").slice(0, 3),
    }));
  }, [props.masterData?.nationality]);

  const allOptions = useMemo(() => {
    return {
      marriage: MARRIAGE_OPTIONS,
      gender: GENDER_OPTIONS,
      disease_type: DISEASE_TYPE_OPTIONS,
      refer_type: REFER_TYPE_OPTIONS,
      procedure: PROCEDURE_OPTIONS,
      ae_type: AE_TYPE_OPTIONS,
      ucae_type: UCAE_TYPE_OPTIONS,
      em_type: EM_TYPE_OPTIONS,
      uuc_type: UUC_TYPE_OPTIONS,
      use_status: USE_STATUS_OPTIONS,
      drug_remark: DRUG_REMARK_OPTIONS,
      provider: providerOptions,
      district: districtOptions,
      career: careerOptions,
      nationality: nationalityOptions,
      icd9cm: props.masterOptions?.icd9cm,
      icd10: props.masterOptions?.icd10,
    } as Record<string, any[]>;
  }, [
    providerOptions,
    districtOptions,
    careerOptions,
    props.masterOptions?.icd9cm,
    props.masterOptions?.icd10,
  ]);

  const editors = useMemo(() => {
    const requiredKeys = TABLE_16_FILES[selectedType].required as unknown as string[];

    const filterKeys = <T extends Record<string, any>>(data: T) => {
      const entries = requiredKeys
        .map((key: keyof T) => {
          const keyLowerCase = typeof key === "string" ? key.toLowerCase() : "";
          const value = data[keyLowerCase];

          return value ? [keyLowerCase, value] : null;
        })
        .filter(Boolean) as any[];

      return Object.fromEntries(entries) as T;
    };

    return {
      dropdown: filterKeys(EDITOR_DROPDOWNS),
      input: filterKeys(EDITOR_INPUTS),
      date: filterKeys(EDITOR_DATE_TEXT_BOXES),
      time: filterKeys(EDITOR_TIME_COMBOBOXES),
    };
  }, [selectedType]);

  //  Callback memo
  const handleChangeValue = useCallback(
    (data: { id: string; index: number }) => async (e: any, v: any) => {
      props.runSequence({
        sequence: "ExportEClaimFile",
        action: "CHANGE_VALUE",
        ...data,
        type: selectedType,
        value: v.value,
      });
    },
    [selectedType]
  );

  const districtFilterOptions = useCallback(
    (value: string) => {
      const provinceData: any[] = props.masterData?.province || [];
      const districtData: any[] = props.masterData?.district || [];

      const provinceId = provinceData.find((item) => item.code === value)?.id;

      const districts = value ? districtData.filter((item: any) => item.area === provinceId) : [];

      return mapOption(districts, "code");
    },
    [props.masterData?.district, props.masterData?.province]
  );

  const formatDropdown = useCallback(
    (data: { data: Record<string, any>; index: number; editing: boolean }) => {
      return Object.fromEntries(
        Object.entries(editors.dropdown).map(([key, value]) => {
          const amphurOptions = districtFilterOptions(data.data.changwat);

          const options = { ...allOptions, amphur: amphurOptions }[value.options];

          const text = options?.find((option: any) => option.value === data.data[key])?.text;

          const requiredValue = `กรุณาระบุ${value.placeholder}`;

          return [
            key,
            data.editing ? (
              <Dropdown
                className="inline-label"
                key={`dropdown-${key}`}
                value={data.data[key] || ""}
                placeholder={`เลือก${value.placeholder}`}
                selection
                basic
                clearable
                search
                fluid
                options={{ ...allOptions, amphur: amphurOptions }[value.options]}
                style={{ height: "fit-content" }}
                // callback
                onChange={handleChangeValue({
                  id: key,
                  index: data.index,
                })}
              />
            ) : (
              text || requiredValue
            ),
          ];
        })
      );
    },
    [allOptions, districtFilterOptions, editors, handleChangeValue]
  );

  const formatInput = useCallback(
    (data: { data: Record<string, any>; index: number; editing: boolean }) => {
      return Object.fromEntries(
        Object.entries(editors.input).map(([key, value]) => {
          const requiredValue = `กรุณาระบุ${value.placeholder}`;

          return [
            key,
            data.editing ? (
              <Input
                value={data.data[key] || ""}
                placeholder={`ระบุ${value.placeholder}`}
                type={value.type}
                fluid
                maxLength={value.max}
                onKeyDown={(e: KeyboardEvent) => {
                  if (value.type === "number" && ["-", "e"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                style={{ width: "100%", height: "fit-content" }}
                onChange={(e, v) => {
                  if (value.max && v.value.length > value.max) {
                    return;
                  }

                  handleChangeValue({
                    id: key,
                    index: data.index,
                  })(null, {
                    // E10.123456 > 10.123
                    value: value.formatted ? value.formatted(v.value) : v.value,
                  });
                }}
              />
            ) : (
              data.data[key] || requiredValue
            ),
          ];
        })
      );
    },
    [editors, handleChangeValue]
  );

  const formatDateTextBox = useCallback(
    (data: { data: Record<string, any>; index: number; editing: boolean }) => {
      return Object.fromEntries(
        Object.entries(editors.date).map(([key, value]) => {
          const text: string = data.data[key] || "";
          // 20230621
          const dateText = text.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3");

          const requiredValue = `กรุณาระบุ${value.placeholder}`;

          return [
            key,
            data.editing ? (
              <DateTextBox
                value={dateText}
                inputFluid
                useAdDate
                setLanguage="en"
                style={{ width: "100%" }}
                inputStyle={{ width: "100%" }}
                onChange={(date: string) => {
                  if (date && date.length !== 10) {
                    return;
                  }

                  handleChangeValue({
                    id: key,
                    index: data.index,
                  })(null, { value: beToAd(date)?.format("YYYYMMDD") });
                }}
              />
            ) : (
              dateText || requiredValue
            ),
          ];
        })
      );
    },
    [editors, handleChangeValue]
  );

  const formatTimeCombobox = useCallback(
    (data: { data: Record<string, any>; index: number; editing: boolean }) => {
      return Object.fromEntries(
        Object.entries(editors.time).map(([key, value]) => {
          const text: string = data.data[key] || "";
          // 1747
          const timeText = text.replace(/^(\d{2})(\d{2})$/, "$1:$2");

          const requiredValue = `กรุณาระบุ${value.placeholder}`;

          return [
            key,
            data.editing ? (
              <TimeComboBox
                placeholder={`ระบุ${value.placeholder}`}
                defaultValue={timeText}
                fluid
                style={{ width: "100%" }}
                onTextChange={(time: string) => {
                  handleChangeValue({
                    id: key,
                    index: data.index,
                  })(null, { value: time.replace(":", "") });
                }}
              />
            ) : (
              timeText || requiredValue
            ),
          ];
        })
      );
    },
    [editors, handleChangeValue]
  );

  // Memo
  const headers = useMemo(() => {
    return [...TABLE_16_FILES[selectedType].headers, ""];
  }, [selectedType]);

  const keys = useMemo(() => {
    return [
      ...TABLE_16_FILES[selectedType].headers.map((header) => header.toLowerCase()),
      "edit",
    ].join(",");
  }, [selectedType]);

  const items = useMemo(() => {
    const files = props.claimInfoDetail?.files;

    return (files?.[selectedType] || []).map((item, index) => {
      const editing = editModeIndex === index;

      const formattedDropdown = formatDropdown({ data: item, index, editing });

      const formattedInput = formatInput({ data: item, index, editing });

      const formattedDateTextBox = formatDateTextBox({ data: item, index, editing });

      const formattedTimeCombobox = formatTimeCombobox({ data: item, index, editing });

      return {
        ...item,
        ...formattedDropdown,
        ...formattedInput,
        ...formattedDateTextBox,
        ...formattedTimeCombobox,
        edit: (
          <div style={{ display: "grid", placeContent: "center" }}>
            <Button
              size="mini"
              color={editing ? "green" : "yellow"}
              onClick={() => {
                if (editing) {
                  setOpenModConfirm(item);
                } else {
                  setEditModeIndex(index);
                }
              }}
            >
              {editing ? "บันทึก" : "แก้ไข"}
            </Button>
          </div>
        ),
      };
    });
  }, [selectedType, editModeIndex, formatDropdown, props.claimInfoDetail]);

  // Handler
  const handleGetTheadThProps = () => {
    return {
      style: {
        paddingLeft: "0",
        paddingRight: "0",
        fontSize: "0.85rem",
      },
    };
  };

  const handleGetTdProps = (state: any, rowInfo: any, column: Column) => {
    const original = rowInfo?.original || {};
    const header = column.Header as string;
    const requiredHeaders = TABLE_16_FILES[selectedType].required as unknown as string[];

    const value = original[header.toLowerCase()];

    const isEmpty =
      requiredHeaders.includes(header) &&
      (!value || (typeof value === "string" && value.includes("กรุณาระบุ")));

    return {
      className: "ui form small",
      style: {
        display: "flex",
        justifyContent: "center",
        overflow: "unset",
        color: isEmpty ? "#fa4040" : "",
        // wordBreak: "break-all",
      },
    };
  };

  const handleGetTrProps = (state: any, rowInfo: any) => {
    const original = rowInfo?.original || {};
    const isEmpty = Object.keys(original).some((key) => {
      const value = original[key];

      const requiredKeys = TABLE_16_FILES[selectedType].required as unknown as string[];

      return (
        requiredKeys.includes(key.toUpperCase()) &&
        (!value || (typeof value === "string" && value.includes("กรุณาระบุ")))
      );
    });

    return {
      style: {
        backgroundColor: isEmpty ? "#edbdbd" : "",
      },
    };
  };

  const handleGetTbodyProps = () => {
    return {
      style: { overflow: "unset" },
    };
  };

  const handleSelect = (type: Key16Files) => () => {
    setSelectedType(type);
    setEditModeIndex(null);
  };

  const handleChange = (e: any, v: any) => {
    setRefNo(v.value);
  };

  const handleClose = () => {
    setOpenModConfirm(null);

    handleClearToken();
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: "SAVE_ECLAIM",
      type: selectedType,
      card: CARD_CLAIM_INFORMATION_DETAIL,
      errorKey: CARD_EXPORT_E_CLAIM_FILE,
      data: props.claimInfoDetail?.files?.[selectedType]?.find(
        (item, index) => index === editModeIndex
      ),
      onSuccess: () => {
        handleClose();
        setEditModeIndex(null);
      },
    });
  };

  const handleEnterToken = (code: string) => {
    props.onEvent({
      message: "UserTokenize",
      params: {
        action: "CheckUserToken",
        code,
      },
    });
  };

  const handleClearToken = () => {
    props.setProp("userTokenize", {
      ...props.userTokenize,
      token: "",
      employeeName: "",
      loading: false,
      error: null,
    });
  };

  const handleEditRefNo = () => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: "EDIT_REF_NO",
      refNo,
      card: CARD_CLAIM_INFORMATION_DETAIL,
      errorKey: CARD_EXPORT_E_CLAIM_FILE,
    });
  };

  // #console.log("CardClaimInformationDetailUX", props);

  return (
    <div>
      {/* <TimeComboBox /> */}
      <CardClaimInformationDetailUX
        // data
        title={`${TABLE_16_FILES[selectedType].title} (${selectedType.toUpperCase()})`}
        type={`(${selectedType})`}
        refNo={refNo}
        detail={props.claimInfoDetail}
        // config
        isEditPatient
        // callback
        onChange={handleChange}
        onClose={props.onClose}
        // Element
        typeList={(Object.keys(TABLE_16_FILES) as Key16Files[]).map((type) => (
          <Button
            key={`btn-${type}`}
            style={{
              marginBottom: "8px",
              width: "100%",
              paddingRight: 0,
              paddingLeft: 0,
            }}
            color={selectedType === type ? "blue" : undefined}
            fluid
            onClick={handleSelect(type)}
          >
            {type.toUpperCase()}
          </Button>
        ))}
        ButtonEdit={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEditRefNo}
            // data
            paramKey={`${CARD_CLAIM_INFORMATION_DETAIL}_${BUTTON_ACTIONS.EDIT}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_CLAIM_INFORMATION_DETAIL}_${BUTTON_ACTIONS.EDIT}`]
            }
            // config
            color={"yellow"}
            name={BUTTON_ACTIONS.EDIT}
            size="medium"
            title="แก้ไข"
          />
        }
        TableElement={
          <Table
            key={`table-${selectedType}`}
            data={items}
            getTheadThProps={handleGetTheadThProps}
            getTdProps={handleGetTdProps}
            getTrProps={handleGetTrProps}
            getTbodyProps={handleGetTbodyProps}
            headers={headers}
            keys={keys}
            minRows={9}
            showPagination={false}
            style={{ maxHeight: "75vh" }}
            widths={TABLE_16_FILES[selectedType].widths}
          ></Table>
        }
      />

      <ModConfirm
        openModal={!!openModConfirm}
        basic={false}
        modalStyle={{ marginTop: "calc(50vh - 150px)", width: "28%" }}
        titleName="ยืนยันการแก้ไขข้อมูลส่งเบิก"
        size="small"
        // callback
        onCloseWithDimmerClick={handleClose}
        onClose={handleClose}
        // Element
        approveButton={<></>}
        // !!Note ใช่ onDeny เนื่องจากต้องการให้ปุ่มบันทึกอยู่ทางขวา
        denyButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_CLAIM_INFORMATION_DETAIL}_${BUTTON_ACTIONS.SAVE}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_CLAIM_INFORMATION_DETAIL}_${BUTTON_ACTIONS.SAVE}`]
            }
            // config
            disabled={!props.userTokenize?.employeeName}
            fluid
            color={"green"}
            name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title="บันทึก"
          />
        }
        content={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 0 -1rem",
            }}
          >
            <span style={{ marginRight: "2.5rem", minWidth: "max-content" }}>
              <strong>กรุณาระบุรหัสพนักงาน</strong>
              <span style={{ color: "red" }}>*</span>
            </span>
            <EmployeeToken
              placeholder={"รหัสผู้บันทึก"}
              onEnterToken={handleEnterToken}
              onClearToken={handleClearToken}
              error={!!props.userTokenize?.error}
              loading={props.userTokenize?.loading}
              employeeName={props.userTokenize?.employeeName}
            />
          </div>
        }
      />
    </div>
  );
};

CardClaimInformationDetail.displayName = "CardClaimInformationDetail";

export default React.memo(CardClaimInformationDetail);
