import React, { useState, useMemo } from "react";

// Utils
import { Button, Form, FormGroup, Dropdown } from "semantic-ui-react";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const CardSapFIExportFilter = (props: any) => {
  console.log("Yeti Card SAP FI Export: ", props);

  const [selectedStation, setSelectedStation] = useState(null);

  const stationOptions = useMemo(() => {
    return [
      // { key: 0, value: "ทุก Station", text: "ทุก Station" },
      ...(props.masterOptions?.bilStation || []),
    ];
  }, [props.masterOptions?.bilStation]);

  const handleExportButton = () => {
    if (!selectedStation) {
      alert("โปรดเลือกจุดเก็บเงิน");
    } else {
      props.runSequence({
        sequence: "SapAccountingReport",
        action: "EXPORT_REPORT",
        menu: "SAP FI Export",
      });
    }
  };

  return (
    <Form>
      <FormGroup className="ui form" style={{ gap: "1em", padding: "0em 0em 0em 1em" }}>
        <label style={{ display: "flex", alignItems: "center" }}>
          วันที่ชุดนำส่งเงิน<span style={{ color: "red" }}>*</span>
        </label>
        <DateTextBox
          placeholder="01/01/2567"
          onChange={(date: string) => {
            props.setProp(`SapAccountingReportSequence.sapFIExport.sendMoneyDate`, date);
          }}
          value={props.SapAccountingReportSequence?.sapFIExport?.sendMoneyDate || ""}
          style={{ width: "11em" }}
        ></DateTextBox>
        <label style={{ display: "flex", alignItems: "center" }}>
          จุดเก็บเงิน<span style={{ color: "red" }}>*</span>
        </label>
        <Dropdown
          id="station-dropdown"
          selection={true}
          placeholder="เลือกจุดเก็บเงิน"
          options={stationOptions}
          style={{ minWidth: "15em" }}
          onChange={(e: any, data: any) => {
            props.setProp(`SapAccountingReportSequence.sapFIExport.selectedStation`, data.value);
            setSelectedStation(data.value);
          }}
          value={props.SapAccountingReportSequence?.sapFIExport?.selectedStation || ""}
        ></Dropdown>
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1em", padding: "0em 0em 0em 1em" }}>
        <label style={{ display: "flex", alignItems: "center", padding: "0em 0.2em 0em 0em" }}>
          วันที่สรุปรายการ<span style={{ color: "red" }}>*</span>
        </label>
        <DateTextBox
          onChange={(date: string) => {
            props.setProp(`SapAccountingReportSequence.sapFIExport.summaryDate`, date);
          }}
          value={props.SapAccountingReportSequence?.sapFIExport?.summaryDate || ""}
          style={{ width: "11em" }}
        ></DateTextBox>
        <label
          style={{ display: "flex", alignItems: "center", padding: "0em 43em 0em 0em" }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handleExportButton}
          paramKey={`SapAccountingReport_EXPORT_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`SapAccountingReport_EXPORT_REPORT`]}
          color="blue"
          style={{ width: "9em" }}
          title="Export"
        />
      </FormGroup>
    </Form>
  );
};

export default CardSapFIExportFilter;
