import React, { useEffect } from 'react';
import CardLayout from '../common/CardLayout';
import { Header, Table } from 'semantic-ui-react'

const styles = {
  gridHeader: {
    fontWeight: 'bold',
    backgroundColor: '#F2F2F2'
  },
  gridColumn: {
    overflowWrap: 'break-word'
  },
  title: {
    fontWeight: 'bold'
  }
}

const TRIAGE_LEVEL = {
  '1': 'Life Treatening',
  '2': 'Emergency',
  '3': 'Urgency',
  '4': 'Semi-Urgency',
  '5': 'ทั่วไป',
}

const ARRIVE_STATUS = {
  'WLK': 'เดิน',
  'WCH': 'รถนั่ง',
  'CRT': 'รถนอน',
  'ATH': 'อื่นๆ',
}

const TRANSLATOR = {
  true: 'ใช่',
  false: 'ไม่ใช่'
}

const PSYCHOLOGICAL = {
  true: 'แสดงออกทางอารมณ์หรือพฤติกรรมผิดปกติระบุ',
  false: 'แสดงออกทางอารมณ์และพฤติกรรมปกติ'
}

const PHYCO_NURSE_NOTE = {
  NON: 'No intervention Require',
  REP: 'Action Plan Require',
  ND: 'Notify Doctor',
}

const SPIRITUAL = {
  false: 'NO',
  true: 'YES'
}

const SUSPECTED = {
  NON: 'ไม่พบร่องรอยผิดปกติจากการประเมินโดยการสังเกต',
  SUS1: 'พบร่องรอยบาดแผล รอยฟกช้ำ ไม่สัมพันธ์กับการเจ็บป่วย รายงานแพทย์',
  SUS2: 'พบร่องรอยบาดแผล รอยฟกช้ำ มีอาการหวาดกลัว ซึมเศร้า ถามไม่ตอบหรือเหม่อลอย รายงานแพทย์'
}

const SUS_NURSE_NOTE = {
  NON: 'ไม่จำเป็น',
  REP: 'รายงานแพทย์',
  OTN: 'อื่นๆ'
}

const TOBACCO = {
  NA: 'NA',
  NO: 'ไม่สูบ',
  YES: 'สูบ',
  Past: 'มีประวัติการสูบ'
}

const ALCOHOL = {
  NA: 'NA',
  NO: 'ไม่ดื่ม',
  YES: 'ดื่ม',
  Past: 'มีประวัติการดื่ม'
}

const NARCOTIC = {
  NA: 'NA',
  NO: 'ไม่เคยใช้',
  YES: 'มีประวัติการใช้'
}

const MENSTRUATION = {
  NA: 'NA',
  LMP: 'LMP',
  MN: 'Menopause'
}

const PAIN_NURSING = {
  NA: 'NA',
  NON: 'ไม่จำเป็น',
  REL: 'จัดท่าเพื่อลดความเจ็บปวด',
  HCP: 'วาง Hot/Cold pack',
  REP: 'รายงานแพทย์ pain score > 3',
  YRX: 'มี Rx',
  NRX: 'ไม่มี Rx',
  Other: 'อื่นๆ'
}

const NUTRITION_RESULT = {
  Normal: 'ปกติ',
  Abnormal1: 'ผิดปกติ รายงานแพทย์',
  OTH: 'อื่นๆ ระบุ',
  Abnormal: 'Notify นักกำหนดอาหาร/นักโภชนบำบัดเพื่อประเมินภาวะโภชนาการ'
}

const CardPatientAssessmentView = (props) => {

  const patient = props.patientAssessment.patient ? props.patientAssessment.patient.data : {}
  const fallRisk = props.patientAssessment.fallRisk ? props.patientAssessment.fallRisk.data : []
  const pain = props.patientAssessment.pain ? props.patientAssessment.pain.data : []
  const nutrition = props.patientAssessment.nutrition ? props.patientAssessment.nutrition.data : []

  useEffect(() => {
    if(props.encounterType === 'IPD'){
      // IPD ไม่มีแบบประเมินแรกรับ ไม่ต้อง fetch
      return
    }
    props.onGetPatientAssessment({ encounterId: props.encounterId });
  }, [props.encounterId, props.encounterType])

  const getFunctionalAssessment = (data, field, type) => {
    let clinicalTerm = ''
    if(props.clinicalTermData[type]){
      clinicalTerm = props.clinicalTermData[type].find((items) => items.id == data[field])
    }
    return clinicalTerm ? clinicalTerm.name : clinicalTerm
  }

  return (
    <CardLayout
      titleText='แบบประเมินแรกรับ'
      headerColor='olive'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.patientAssessmentLoading}
      enableMargin={props.enableMargin}
    >
    { props.encounterType === 'IPD' ? 'No Data.' :
      <div style={{ height: 480, overflowY: 'auto', overflowX: 'hidden' }}>
        <Table celled>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              บันทึกแก้ไข(ล่าสุด)
            </Table.Cell>
            <Table.Cell >
              {patient.date} {patient.user_fullname}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              Chief Complaint
            </Table.Cell>
            <Table.Cell >
              {patient.chief_complaint}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              ระดับการคัดกรอง
            </Table.Cell>
            <Table.Cell>
              {TRIAGE_LEVEL[patient.triage_level]}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              การมาถึง
            </Table.Cell>
            <Table.Cell>
              {ARRIVE_STATUS[patient.arrive_status]}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              ล่าม
            </Table.Cell>
            <Table.Cell>
              {TRANSLATOR[patient.translator]}{' '}{patient.translator_specific}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              Functional Assessment
            </Table.Cell>
            <Table.Cell>
            {patient.functional && isNaN(patient.functional) ? patient.functional : getFunctionalAssessment(patient, 'functional', 'FA') }
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              Psychological Assessment
            </Table.Cell>
            <Table.Cell>
              {PSYCHOLOGICAL[patient.psychological]}&emsp;{patient.psychological_specific}
              &emsp;&emsp;<text style={styles.title}>การให้การพยาบาล</text>
              &emsp;{PHYCO_NURSE_NOTE[patient.phyco_nurse_note]}
              &emsp;{patient.phyco_nurse_note_specific}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              Spiritual/Cultural Assessment
            </Table.Cell>
            <Table.Cell>
              {SPIRITUAL[patient.spiritual]}&emsp;{patient.spiritual_detail}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              Suspected abuse or neglect
            </Table.Cell>
            <Table.Cell>
              {SUSPECTED[patient.suspected]}&emsp;{patient.suspected_specific}
              &emsp;&emsp;<text style={styles.title}>การให้การพยาบาล</text>
              &emsp;{SUS_NURSE_NOTE[patient.sus_nurse_note]}
              &emsp;{patient.sus_nurse_note_specific}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              History of Febrile convulsion
            </Table.Cell>
            <Table.Cell>
              {patient.convulsion}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              เสี่ยงต่อการติดเชื้อ
            </Table.Cell>
            <Table.Cell>
              {patient.mdr_triage}
              &emsp;&emsp;<text style={{...styles.title, display: patient.mdr ? null : 'none'}}>MDR</text>
              &emsp;{patient.mdr}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              ประวัติการสูบบุหรี่/ดื่มสุรา
            </Table.Cell>
            <Table.Cell>
              <text style={styles.title}>สูบบุหรี่</text>
              &emsp;{TOBACCO[patient.tobacco]}&emsp;{patient.tobacco_specific}
              &emsp;&emsp;<text style={styles.title}>ดื่มสุรา</text>
              &emsp;{ALCOHOL[patient.alcohol]}&emsp;{patient.alcohol_specific}
              &emsp;&emsp;<text style={styles.title}>การใช้สารเสพติด</text>
              &emsp;{NARCOTIC[patient.narcotic]}&emsp;{patient.narcotic_specific}
            </Table.Cell>
          </Table.Row>
          <Table.Row inline>
            <Table.Cell style={styles.gridHeader} collapsing>
              Menstruation
            </Table.Cell>
            <Table.Cell>
              {MENSTRUATION[patient.menstruation]}&emsp;{patient.lmp}
            </Table.Cell>
          </Table.Row>
        </Table>

        <Header>Fall Risk Assessment</Header>
        <div style={{ overflowX: 'auto' }}>
          <Table celled >
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                วันที่ - เวลา
            </Table.Cell>
              {fallRisk.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.date}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                ผู้บันทึก/แก้ไข
            </Table.Cell>
              {fallRisk.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.user_fullname}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                Fall Risk Assessment
            </Table.Cell>
              {fallRisk.map((items, index) => {
                let risk = null;
                if (items.fall_risk === "LOW") {
                  risk = "Low Risk";
                } else {
                  risk = "High Risk";
                }
                return (
                  <Table.Cell key={index}>
                    {risk ? risk : items.fall_risk}
                  </Table.Cell>
                );
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                การให้การพยาบาลผู้ป่วย
              </Table.Cell>
              {fallRisk.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.nursing}
                  </Table.Cell>
                )
              })}
            </Table.Row>
          </Table>
        </div>

        <Header>Pain Assessment</Header>
        <div style={{ overflowX: 'auto' }}>
          <Table celled>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                วันที่ - เวลา
            </Table.Cell>
              {pain.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.date}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                ผู้บันทึก/แก้ไข
            </Table.Cell>
              {pain.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.user_fullname}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                Pain score
            </Table.Cell>
              {pain.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.pain_score}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                pain Assessment Tool
            </Table.Cell>
              {pain.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                  {items.pain_assessment_tool && isNaN(items.pain_assessment_tool) ? items.pain_assessment_tool : getFunctionalAssessment(items, 'pain_assessment_tool', 'PAS') }
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                Location
            </Table.Cell>
              {pain.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {/* {getFunctionalAssessment(items, 'location', 'PLO')} */}
                    {items.location && isNaN(items.location) ? items.location : getFunctionalAssessment(items, 'location', 'PLO') }
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                Characteristic
            </Table.Cell>
              {pain.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.characteristic}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                Frequency
            </Table.Cell>
              {pain.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                  {items.frequency && isNaN(items.frequency) ? items.frequency : getFunctionalAssessment(items, 'frequency', 'PFC') }
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                ครั้งละประมาณ
            </Table.Cell>
              {pain.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.duration_min ? items.duration_min + 'นาที' : ''}{' '}{items.duration_sec ? items.duration_sec + 'วินาที' : ''}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                การให้การพยาบาลผู้ป่วย
            </Table.Cell>
              {pain.map((items, index) => {
                console.log('items การให้การพยาบาลผู้ป่วย', items)
                return (
                  <Table.Cell key={index}>
                    {PAIN_NURSING[items.nursing]}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                ระบุ
            </Table.Cell>
              {pain.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.nursing_remark}
                  </Table.Cell>
                )
              })}
            </Table.Row>
          </Table>
        </div>

        <Header>Nutrition Assessment</Header>
        <div style={{ overflowX: 'auto' }}>
          <Table celled >
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                วันที่ - เวลา
            </Table.Cell>
              {nutrition.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.date}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                ผู้บันทึก/แก้ไข
            </Table.Cell>
              {nutrition.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {items.user_fullname}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                ผู้ป่วยมีน้ำหนักตัวลดลงโดยไม่ได้ตั้งใจในช่วง 6 เดือนที่ผ่านมาหรือไม่
            </Table.Cell>
              {nutrition.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {TRANSLATOR[items.lose_weight]}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                ผู้ป่วยได้รับอาหารน้อยกว่าที่เคยได้(เกินกว่า 7 วัน)
            </Table.Cell>
              {nutrition.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {TRANSLATOR[items.malnutrition]}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                {'BMI < 18.5 หรือ > 25.0 kg/m'}
            </Table.Cell>
              {nutrition.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {TRANSLATOR[items.bmi_outrange]}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                ผู้ป่วยมีภาวะโรควิกฤตหรือกึ่งวิกฤตร่วมด้วยหรือไม่
            </Table.Cell>
              {nutrition.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {TRANSLATOR[items.critical]}
                  </Table.Cell>
                )
              })}
            </Table.Row>
            <Table.Row inline>
              <Table.Cell style={styles.gridHeader} collapsing>
                ผลการคัดกรอง
            </Table.Cell>
              {nutrition.map((items, index) => {
                return (
                  <Table.Cell key={index}>
                    {NUTRITION_RESULT[items.result]}
                  </Table.Cell>
                )
              })}
            </Table.Row>
          </Table>
        </div>
      </div>
    }
    </CardLayout >
  )
}

CardPatientAssessmentView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  patientAssessment: {},
  onGetPatientAssessment: () => { },
  clinicalTermData: {},
  enableMargin: true,
};

export default CardPatientAssessmentView;
