import React from "react";
import CardReAssessment from "react-lib/apps/HISV3/ADM/CardReAssessment";
import CardAssessmentBundle from "react-lib/apps/HISV3/PTM/CardAssessmentBundle";
import CardTriage from "react-lib/apps/HISV3/PTM/CardTriage";
import CardPreAssessment from "react-lib/apps/HISV3/ADM/CardPreAssessment";
import CardByEquipment from "react-lib/apps/HISV3/ORM/CardByEquipment";

type CardFormScreenProps = {
  onEvent: any;
  setProp: any;
  formScreen: any;
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  runSequence: any;
  ReAssessmentSequence?: any;
  masterOptions?: any;
  reAssessmentOptions?: any;
  DJANGO?: any;
  selectedEncounter?: any;
  masterData?: any;
  AssessmentSequence?: any;
  choiceTriage?: any;
  selectedPatient?: any;
  TriageSequence?: any;
  crdAdmissionFormController: any;
  PreAssessmentSequence: any;
  preAssessmentOptions: any;
  preAssessmentActionLog: any;
  subICDController: any;
  emrId: any;
  selectedOrOrder: any;
  PerioperativeNursingSequence: any;
};

const CardFormScreenInitial: CardFormScreenProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // seq
  runSequence: null,

  // options
  masterOptions: {},
  reAssessmentOptions: {},
};

const CardFormScreen = (props: CardFormScreenProps) => {
  return (
    <>
      <div>
        <div
          style={{
            padding: "10px",
            fontSize: "1.4rem",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          {props.formScreen?.name}
        </div>

        <div
          style={{
            border: "1.5px solid black",
            margin: "15px",
          }}
        >
          {props.formScreen?.name ===
            "ประเมินความเจ็บปวดผู้ป่วยหลังออกจากห้องตรวจ (IPD)" ||
          props.formScreen?.name ===
            "ประเมินความเสี่ยงต่อการพลัดตกหกล้มผู้ป่วยหลังออกจากห้องตรวจ (IPD)" ||
          props.formScreen?.name ===
            "ประเมินการพักผ่อนนอนหลับผู้ป่วยหลังออกจากห้องตรวจ (IPD)" ? (
            <CardReAssessment
              onEvent={props.onEvent}
              setProp={props.setProp}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              runSequence={props.runSequence}
              ReAssessmentSequence={props.ReAssessmentSequence}
              masterOptions={props.masterOptions}
              reAssessmentOptions={props.reAssessmentOptions}
              showForm={
                props.formScreen?.name ===
                "ประเมินความเจ็บปวดผู้ป่วยหลังออกจากห้องตรวจ (IPD)"
                  ? "pain"
                  : props.formScreen?.name ===
                    "ประเมินความเสี่ยงต่อการพลัดตกหกล้มผู้ป่วยหลังออกจากห้องตรวจ (IPD)"
                  ? "riskFall"
                  : props.formScreen?.name ===
                      "ประเมินการพักผ่อนนอนหลับผู้ป่วยหลังออกจากห้องตรวจ (IPD)" &&
                    "sleep"
              }
            />
          ) : props.formScreen?.name === "Fall Risk Assessment" ||
            props.formScreen?.name === "Pain Assessment" ||
            props.formScreen?.name === "Nutrition Assessment" ||
            props.formScreen?.name === "CardPregnancyAssessment" ? (
            <CardAssessmentBundle
              setProp={props.setProp}
              runSequence={props.runSequence}
              DJANGO={props.DJANGO}
              selectedEncounter={props.selectedEncounter}
              masterOptions={props.masterOptions}
              masterData={props.masterData}
              AssessmentSequence={props.AssessmentSequence}
              choiceTriage={props.choiceTriage}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              selectedPatient={props.selectedPatient}
              buttonLoadCheck={props.buttonLoadCheck}
            />
          ) : props.formScreen?.name === "ประเมินอาการผู้ป่วยแรกรับ (IPD)" ? (
            <CardPreAssessment
              onEvent={props.onEvent}
              setProp={props.setProp}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              selectedPatient={props.selectedPatient}
              emrId={props.emrId}
              runSequence={props.runSequence}
              PreAssessmentSequence={props.PreAssessmentSequence}
              masterOptions={props.masterOptions}
              preAssessmentOptions={props.preAssessmentOptions}
              preAssessmentActionLog={props.preAssessmentActionLog}
              subICDController={props.subICDController}
            />
          ) : props.formScreen?.name === "แบบประเมินแรกรับ" ? (
            <CardTriage
              setProp={props.setProp}
              runSequence={props.runSequence}
              selectedEncounter={props.selectedEncounter}
              TriageSequence={props.TriageSequence}
              masterOptions={props.masterOptions}
              choiceTriage={props.choiceTriage}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
            />
          ) : props.formScreen?.name === "Tube" ||
            props.formScreen?.name === "ORDrain" ||
            props.formScreen?.name === "ORDressing" ? (
            <CardByEquipment
              onEvent={props.onEvent}
              setProp={props.setProp}
              buttonLoadCheck={props.buttonLoadCheck}
              selectedOrOrder={props.selectedOrOrder}
              masterOptions={props.masterOptions}
              runSequence={props.runSequence}
              PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

CardFormScreen.defaultProps = CardFormScreenInitial;

export default React.memo(CardFormScreen);
