import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Icon,
  Input,
  Label,
  Checkbox
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDrugOrderWorkingUX = (props: any) => {
    return(
      <div>
        <div
          style={{  position: "relative" }}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(60, 1fr)"}}>
            <div
              className="ui form small"
              style={{"gridRow":`1/1`,"gridColumn":"2/60"}}>
              
              <FormGroup
                style={{marginTop: "1rem", alignItems: "center"}}>
                <FormField>
                  <div
                    style={{fontSize: "1.2rem", fontWeight: "bold"}}>
                    {props.nameTypeOrder}
                  </div>
                </FormField>
                <FormField
                  style={{flex:1}}>
                </FormField>
                <FormField
                  inline={true}
                  style={{display: "flex", alignItems: "center"}}>
                  <div
                    style={{display:  props.showTelepharType?"flex":"none", justifyContent: "flex-end"}}>
                    
                    <Checkbox
                      checked={props.telepharType === "DELIVERY"}
                      className="blue"
                      label="Home delivery"
                      style={{fontWeight: "normal", textAlign: "left", maxWidth: "max-content", width: "calc(100% - 13rem)"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.telepharType === "INTRA"}
                      className="blue"
                      label="Intrahospital Telepharmacy"
                      style={{fontWeight: "normal", margin: "0 0rem 0 1rem", textAlign: "left", maxWidth: "max-content", width: "calc(100% - 9rem)"}}>
                    </Checkbox>
                  </div>
                  <div>
                    {props.subAdrPopup}
                  </div>
                  <div>
                    {props.orderStatus}
                  </div>
                  <div>
                    {props.orderPaymentStatus}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField>
                  <label
                    style={{minWidth: "80px", maxWidth: "80px"}}>
                    การวินิจฉัย
                  </label>
                </FormField>
                <FormField
                  inline={true}
                  width={16}>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?.diagnosis || "\u00a0"}
                  </Label>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField>
                  <label
                    style={{minWidth: "80px", maxWidth: "80px"}}>
                    Doctor Note
                  </label>
                </FormField>
                <FormField
                  inline={true}
                  width={10}>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?.doctor_note || "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  width="6">
                  <label>
                    Prescription date : 
                  </label>
                  <label>
                    {props.drugOrder?.order_for_date || ""}
                  </label>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px"}}>
                    ผู้สั่ง
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?.requested?.name || "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    เวลา
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.requestTime|| "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    แผนก
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?.requested?.division || "\u00a0"}
                  </Label>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{display : props.drugOrder?. printed ? "": "none"}}>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px"}}>
                    ผู้ Verify
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?. printed?.name || "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    เวลา
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.printedTime|| "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    เเผนก
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?. printed?.division || "\u00a0"}
                  </Label>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{display : props.drugOrder?. checked ? "": "none"}}>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px"}}>
                    ผู้ Check
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?. checked?.name || "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    เวลา
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.checkedTime|| "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    แผนก
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?. checked?.division || "\u00a0"}
                  </Label>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{display : props.drugOrder?. delivered ? "": "none" }}>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px"}}>
                    ผู้จ่าย
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?. delivered?.name || "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    เวลา
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.deliveredTime|| "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    แผนก
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?. delivered?.division || "\u00a0"}
                  </Label>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{display : props.drugOrder?. transported ? "": "none"}}>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px"}}>
                    ผู้ขนส่ง
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?. transported?.name || "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    เวลา
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.transportedTime|| "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    แผนก
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?.transported?.division || "\u00a0"}
                  </Label>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{display : props.drugOrder?.received ? "": "none"}}>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px"}}>
                    ผู้รับ
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?.received?.name || "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    เวลา
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.receivedTime|| "\u00a0"}
                  </Label>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "50px", maxWidth: "50px", textAlign: "right"}}>
                    แผนก
                  </label>
                  <Label
                    style={{padding: "0.78rem", width: "100%"}}>
                    {props.drugOrder?.received?.division || "\u00a0"}
                  </Label>
                </FormField>
              </FormGroup>
              <FormGroup>
                <FormField
                  style={{display: props.showToolBox ? "flex" : ""}}
                  width={16}>
                  <Table
                    className="--rt-tbody-overflow-y-hidden"
                    data={props.drugOrderItems}
                    getTrProps={props.rowProps}
                    headers={props.showPrintLabel? ",ชื่อยา,Dose,Unit,Route,Site,Frequency,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price, Label": ",ชื่อยา,Dose,Unit,Route,Site,Frequency,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price"}
                    id="tb-medOrderReqList"
                    keys={props.showPrintLabel? "check, name_status, dose, code_unit, code_route,code_site, code_frequency, prn, quantity, onhand, code_stock, price_unit, price_total,print_label":"check, name_status, dose, code_unit, code_route,code_site, code_frequency, prn, quantity, onhand, code_stock, price_unit, price_total"}
                    minRows="4"
                    showPagination={false}
                    style={{height: "255px", width: props.showToolBox ? "77%" : "100%", display: "none" }}
                    widths={props.showPrintLabel? "30,^150,^60,^60,^100,^100,^125,^80,^70,^60,^80,^80,^70,^55":"30,^150,^60,^60,^100,^100,^125,^80,^50,^70,^80,^70,^60"}>
                  </Table>
                  <div
                    style={{ width: props.showToolBox ? "77%" : "100%"}}>
                    {props.tableElement}
                  </div>
                  <div
                    style={{width: "23%"}}>
                    {props.SubDrugOrderToolbox}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField>
                  <label
                    style={{fontWeight: "normal", minWidth: "max-content"}}>
                    แผนกที่สั่ง
                  </label>
                </FormField>
                <FormField
                  inline={true}
                  width={4}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    value={props.drugOrder?.order_div || ""}>
                  </Input>
                </FormField>
                <FormField>
                  <label
                    style={{fontWeight: "normal", minWidth: "max-content"}}>
                    ห้องยา
                  </label>
                </FormField>
                <FormField
                  inline={true}
                  width={4}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    value={props.drugOrder?.order_perform_div || ""}>
                  </Input>
                </FormField>
                <FormField>
                  <label
                    style={{fontWeight: "normal", minWidth: "max-content"}}>
                    ราคารวม
                  </label>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    value={Number(props.sumPriceTotal||"0").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </FormField>
                <FormField>
                  <label
                    style={{fontWeight: "normal", minWidth: "max-content"}}>
                    เบิกได้
                  </label>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    value={ Number(props.sumPriceClaimable||"0").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </FormField>
                <FormField>
                  <label
                    style={{fontWeight: "normal", minWidth: "max-content"}}>
                    เบิกไม่ได้
                  </label>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    value={Number(props.sumPriceNonClaimable||"0").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{minWidth: "max-content"}}>
                <FormField
                  inline={true}>
                  <Button
                    onClick={props.handleOnLog}
                    size="small"
                    style={{marginRight: "0.5rem"}}>
                    LOG
                  </Button>
                  <Button
                    loading={props.loadingNarcotic}
                    onClick={props.narcoticPrint}
                    size="small"
                    style={{display: props.buttonActions?.narcotic?.show ? "":"none",marginRight: "0.5rem"}}>
                    แบบ ย.ส. 5
                  </Button>
                  <Button
                    onClick={props?.OnReprint}
                    size="small"
                    style={{display: props.buttonActions?.reprint?.show ? "" : "none",marginRight: "0.5rem"}}>
                    Reprint
                  </Button>
                  <Button
                    onClick={props.onClickDiscount}
                    size="small"
                    style={{display: props.buttonActions?.discount?.show ? "" : "none",marginRight: "0.5rem"}}>
                    Discount
                  </Button>
                  <Button
                    disabled={props.disabledPrintDrugAdviceBtn}
                    icon={true}
                    labelPosition="left"
                    onClick={props.printDrugAdvice}
                    size="small"
                    style={{...(props.buttonActions?.drugRecommendation?.show ? {}: {display: "none"})}}>
                    คำแนะนำการใช้ยา
                    <Icon
                      name="print">
                    </Icon>
                  </Button>
                </FormField>
                <FormField
                  style={{flex:1, padding:0}}>
                </FormField>
                <FormField
                  style={{display:props.buttonActions?.additional?.show?"":"none"}}>
                  <div>
                    {props.additionalProps}
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  style={{display: "flex"}}>
                  <div>
                    {props.passwordInput}
                  </div>
                  <div
                    style={{marginRight: "0.5rem",display:props.buttonActions?.language?.show ? "" : "none"}}>
                    {props.buttonLanguage}
                  </div>
                  <div
                    style={{marginRight: "0.5rem",display:props.buttonActions?.refill?.show ? "" : "none",}}>
                    {props.buttonRefill}
                  </div>
                  <div
                    style={{marginRight: "0.5rem",display:props.buttonActions?.telephar?.show ? "" : "none"}}>
                    {props.buttonTelePharmacy}
                  </div>
                  <div
                    style={{marginRight: "0.5rem",display: props.buttonActions?.return?.show ?"":"none"}}>
                    {props.buttonReturn}
                  </div>
                  <div
                    style={{marginRight: "0.5rem",display:props.buttonActions?.forward?.show? "" : "none"}}>
                    {props.buttonForward}
                  </div>
                  <div
                    style={{marginRight: "0.5rem",display:props.buttonActions?.backward?.show ? "" : "none"}}>
                    {props.buttonBackward}
                  </div>
                  <div
                    style={{marginRight: "0.5rem",display:props.buttonActions?.endConsult?.show ? "" : "none"}}>
                    {props.buttonEndConsult}
                  </div>
                  <div
                    style={{display:props.buttonActions?.callAgain?.show ? "" : "none"}}>
                    {props.buttonVideoCallAgain}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup>
                <FormField
                  style={{flex:1}}>
                </FormField>
                <FormField
                  style={{marginRight: "20%"}}>
                  <div>
                    {props?.drugDelivery}
                  </div>
                </FormField>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardDrugOrderWorkingUX

export const screenPropsDefault = {"backwardActionButton":"REJECT","forwardActionButton":"VERIFY AND PRINT"}

/* Date Time : Wed Jul 17 2024 09:46:50 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 194,
      "label": "divgrid",
      "name": "div",
      "parent": 195,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(60, 1fr)\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  position: \"relative\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":`1/1`,\"gridColumn\":\"2/60\"}"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 328,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 432,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 329,
      "name": "FormField",
      "parent": 328,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 330,
      "name": "Button",
      "parent": 329,
      "props": {
        "children": {
          "type": "value",
          "value": "LOG"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleOnLog"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 331,
      "name": "Button",
      "parent": 329,
      "props": {
        "children": {
          "type": "value",
          "value": "แบบ ย.ส. 5"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingNarcotic"
        },
        "onClick": {
          "type": "code",
          "value": "props.narcoticPrint"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.buttonActions?.narcotic?.show ? \"\":\"none\",marginRight: \"0.5rem\"}"
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 332,
      "name": "Button",
      "parent": 329,
      "props": {
        "children": {
          "type": "value",
          "value": "Reprint"
        },
        "onClick": {
          "type": "code",
          "value": "props?.OnReprint"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.buttonActions?.reprint?.show ? \"\" : \"none\",marginRight: \"0.5rem\"}"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 333,
      "name": "Button",
      "parent": 329,
      "props": {
        "children": {
          "type": "value",
          "value": "คำแนะนำการใช้ยา"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrintDrugAdviceBtn"
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "labelPosition": {
          "type": "value",
          "value": "left"
        },
        "onClick": {
          "type": "code",
          "value": "props.printDrugAdvice"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(props.buttonActions?.drugRecommendation?.show ? {}: {display: \"none\"})}"
        }
      },
      "seq": 455,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 334,
      "name": "Icon",
      "parent": 333,
      "props": {
        "name": {
          "type": "value",
          "value": "print"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 335,
      "name": "FormField",
      "parent": 328,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1, padding:0}"
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "FormField",
      "parent": 328,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": null,
      "id": 337,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRefill"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\",display:props.buttonActions?.refill?.show ? \"\" : \"none\",}"
        }
      },
      "seq": 339,
      "void": false
    },
    {
      "from": null,
      "id": 338,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonForward"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\",display:props.buttonActions?.forward?.show? \"\" : \"none\"}"
        }
      },
      "seq": 443,
      "void": false
    },
    {
      "from": null,
      "id": 339,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonBackward"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\",display:props.buttonActions?.backward?.show ? \"\" : \"none\"}"
        }
      },
      "seq": 444,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 340,
      "name": "FormField",
      "parent": 328,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:props.buttonActions?.additional?.show?\"\":\"none\"}"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": null,
      "id": 341,
      "name": "div",
      "parent": 340,
      "props": {
        "children": {
          "type": "code",
          "value": "props.additionalProps"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": null,
      "id": 342,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReturn"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\",display: props.buttonActions?.return?.show ?\"\":\"none\"}"
        }
      },
      "seq": 442,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 343,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 445,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 344,
      "name": "FormField",
      "parent": 343,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 344,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 345,
      "name": "FormField",
      "parent": 343,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginRight: \"20%\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 345,
      "void": false
    },
    {
      "from": null,
      "id": 346,
      "name": "div",
      "parent": 345,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.drugDelivery"
        }
      },
      "seq": 346,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 347,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 404,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 348,
      "name": "FormField",
      "parent": 347,
      "props": {
      },
      "seq": 348,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 349,
      "name": "FormField",
      "parent": 347,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 349,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 350,
      "name": "FormField",
      "parent": 347,
      "props": {
      },
      "seq": 350,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 351,
      "name": "FormField",
      "parent": 347,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 351,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 352,
      "name": "FormField",
      "parent": 347,
      "props": {
      },
      "seq": 352,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 353,
      "name": "FormField",
      "parent": 347,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 353,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 354,
      "name": "FormField",
      "parent": 347,
      "props": {
      },
      "seq": 354,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 355,
      "name": "FormField",
      "parent": 347,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 355,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 356,
      "name": "FormField",
      "parent": 347,
      "props": {
      },
      "seq": 356,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 357,
      "name": "FormField",
      "parent": 347,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 357,
      "void": false
    },
    {
      "from": null,
      "id": 358,
      "name": "label",
      "parent": 348,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 358,
      "void": false
    },
    {
      "from": null,
      "id": 359,
      "name": "label",
      "parent": 350,
      "props": {
        "children": {
          "type": "value",
          "value": "ห้องยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 359,
      "void": false
    },
    {
      "from": null,
      "id": 360,
      "name": "label",
      "parent": 352,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 360,
      "void": false
    },
    {
      "from": null,
      "id": 361,
      "name": "label",
      "parent": 354,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 361,
      "void": false
    },
    {
      "from": null,
      "id": 362,
      "name": "label",
      "parent": 356,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 362,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 363,
      "name": "Input",
      "parent": 357,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "Number(props.sumPriceNonClaimable||\"0\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 363,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 364,
      "name": "Input",
      "parent": 355,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": " Number(props.sumPriceClaimable||\"0\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 364,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 365,
      "name": "Input",
      "parent": 353,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "Number(props.sumPriceTotal||\"0\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 365,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 366,
      "name": "Input",
      "parent": 351,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.drugOrder?.order_perform_div || \"\""
        }
      },
      "seq": 366,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 367,
      "name": "Input",
      "parent": 349,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.drugOrder?.order_div || \"\""
        }
      },
      "seq": 367,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 368,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 369,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 347,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 370,
      "name": "FormField",
      "parent": 368,
      "props": {
      },
      "seq": 370,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 371,
      "name": "FormField",
      "parent": 368,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 371,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 372,
      "name": "FormField",
      "parent": 369,
      "props": {
      },
      "seq": 372,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 373,
      "name": "FormField",
      "parent": 369,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 373,
      "void": false
    },
    {
      "from": null,
      "id": 374,
      "name": "label",
      "parent": 370,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 374,
      "void": false
    },
    {
      "from": null,
      "id": 375,
      "name": "label",
      "parent": 372,
      "props": {
        "children": {
          "type": "value",
          "value": "Doctor Note"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 375,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 376,
      "name": "Label",
      "parent": 373,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.doctor_note || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 376,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 377,
      "name": "Label",
      "parent": 371,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.diagnosis || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 377,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 378,
      "name": "FormGroup",
      "parent": 327,
      "props": {
      },
      "seq": 403,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 379,
      "name": "FormField",
      "parent": 378,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.showToolBox ? \"flex\" : \"\"}"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 379,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 380,
      "name": "Table",
      "parent": 379,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.drugOrderItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.showPrintLabel? \",ชื่อยา,Dose,Unit,Route,Site,Frequency,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price, Label\": \",ชื่อยา,Dose,Unit,Route,Site,Frequency,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price\""
        },
        "id": {
          "type": "value",
          "value": "tb-medOrderReqList"
        },
        "keys": {
          "type": "code",
          "value": "props.showPrintLabel? \"check, name_status, dose, code_unit, code_route,code_site, code_frequency, prn, quantity, onhand, code_stock, price_unit, price_total,print_label\":\"check, name_status, dose, code_unit, code_route,code_site, code_frequency, prn, quantity, onhand, code_stock, price_unit, price_total\""
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"255px\", width: props.showToolBox ? \"77%\" : \"100%\", display: \"none\" }"
        },
        "widths": {
          "type": "code",
          "value": "props.showPrintLabel? \"30,^150,^60,^60,^100,^100,^125,^80,^70,^60,^80,^80,^70,^55\":\"30,^150,^60,^60,^100,^100,^125,^80,^50,^70,^80,^70,^60\""
        }
      },
      "seq": 380,
      "void": false
    },
    {
      "from": null,
      "id": 381,
      "name": "div",
      "parent": 379,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubDrugOrderToolbox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"23%\"}"
        }
      },
      "seq": 462,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 382,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 368,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 383,
      "name": "FormField",
      "parent": 382,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 383,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 384,
      "name": "FormField",
      "parent": 382,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 384,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 385,
      "name": "FormField",
      "parent": 382,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 385,
      "void": false
    },
    {
      "from": null,
      "id": 386,
      "name": "label",
      "parent": 383,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 386,
      "void": false
    },
    {
      "from": null,
      "id": 387,
      "name": "label",
      "parent": 384,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 387,
      "void": false
    },
    {
      "from": null,
      "id": 388,
      "name": "label",
      "parent": 385,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 388,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 389,
      "name": "Label",
      "parent": 383,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.requested?.name || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 389,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 390,
      "name": "Label",
      "parent": 384,
      "props": {
        "children": {
          "type": "code",
          "value": "props.requestTime|| \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 390,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 391,
      "name": "Label",
      "parent": 385,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.requested?.division || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 391,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 392,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. printed ? \"\": \"none\"}"
        }
      },
      "seq": 369,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 393,
      "name": "FormField",
      "parent": 392,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 393,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 394,
      "name": "FormField",
      "parent": 392,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 394,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 395,
      "name": "FormField",
      "parent": 392,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 395,
      "void": false
    },
    {
      "from": null,
      "id": 396,
      "name": "label",
      "parent": 393,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ Verify"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 396,
      "void": false
    },
    {
      "from": null,
      "id": 397,
      "name": "label",
      "parent": 394,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 397,
      "void": false
    },
    {
      "from": null,
      "id": 398,
      "name": "label",
      "parent": 395,
      "props": {
        "children": {
          "type": "value",
          "value": "เเผนก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 398,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 399,
      "name": "Label",
      "parent": 393,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. printed?.name || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 399,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 400,
      "name": "Label",
      "parent": 394,
      "props": {
        "children": {
          "type": "code",
          "value": "props.printedTime|| \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 400,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 401,
      "name": "Label",
      "parent": 395,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. printed?.division || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 401,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 402,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. checked ? \"\": \"none\"}"
        }
      },
      "seq": 378,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 403,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. delivered ? \"\": \"none\" }"
        }
      },
      "seq": 382,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 404,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. transported ? \"\": \"none\"}"
        }
      },
      "seq": 392,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 405,
      "name": "FormField",
      "parent": 402,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 405,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 406,
      "name": "FormField",
      "parent": 403,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 406,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 407,
      "name": "FormField",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 407,
      "void": false
    },
    {
      "from": null,
      "id": 408,
      "name": "label",
      "parent": 405,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ Check"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 408,
      "void": false
    },
    {
      "from": null,
      "id": 409,
      "name": "label",
      "parent": 406,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้จ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 409,
      "void": false
    },
    {
      "from": null,
      "id": 410,
      "name": "label",
      "parent": 407,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ขนส่ง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 410,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 411,
      "name": "Label",
      "parent": 405,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. checked?.name || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 411,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 412,
      "name": "Label",
      "parent": 406,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. delivered?.name || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 412,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 413,
      "name": "Label",
      "parent": 407,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. transported?.name || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 413,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 414,
      "name": "FormField",
      "parent": 402,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 414,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 415,
      "name": "FormField",
      "parent": 402,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 415,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 416,
      "name": "FormField",
      "parent": 403,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 416,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 417,
      "name": "FormField",
      "parent": 403,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 417,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 418,
      "name": "FormField",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 418,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 419,
      "name": "FormField",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 419,
      "void": false
    },
    {
      "from": null,
      "id": 420,
      "name": "label",
      "parent": 416,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 420,
      "void": false
    },
    {
      "from": null,
      "id": 421,
      "name": "label",
      "parent": 417,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 421,
      "void": false
    },
    {
      "from": null,
      "id": 422,
      "name": "label",
      "parent": 414,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 422,
      "void": false
    },
    {
      "from": null,
      "id": 423,
      "name": "label",
      "parent": 415,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 423,
      "void": false
    },
    {
      "from": null,
      "id": 424,
      "name": "label",
      "parent": 418,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 424,
      "void": false
    },
    {
      "from": null,
      "id": 425,
      "name": "label",
      "parent": 419,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 425,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 426,
      "name": "Label",
      "parent": 419,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.transported?.division || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 426,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 427,
      "name": "Label",
      "parent": 418,
      "props": {
        "children": {
          "type": "code",
          "value": "props.transportedTime|| \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 427,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 428,
      "name": "Label",
      "parent": 417,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. delivered?.division || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 428,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 429,
      "name": "Label",
      "parent": 416,
      "props": {
        "children": {
          "type": "code",
          "value": "props.deliveredTime|| \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 429,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 430,
      "name": "Label",
      "parent": 415,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. checked?.division || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 430,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 431,
      "name": "Label",
      "parent": 414,
      "props": {
        "children": {
          "type": "code",
          "value": "props.checkedTime|| \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 431,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 432,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\", alignItems: \"center\"}"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 433,
      "name": "FormField",
      "parent": 432,
      "props": {
      },
      "seq": 433,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 434,
      "name": "FormField",
      "parent": 432,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 434,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 435,
      "name": "FormField",
      "parent": 432,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 435,
      "void": false
    },
    {
      "from": null,
      "id": 436,
      "name": "div",
      "parent": 433,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nameTypeOrder"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 436,
      "void": false
    },
    {
      "from": null,
      "id": 437,
      "name": "div",
      "parent": 435,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:  props.showTelepharType?\"flex\":\"none\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 437,
      "void": false
    },
    {
      "from": null,
      "id": 438,
      "name": "div",
      "parent": 435,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderStatus"
        }
      },
      "seq": 439,
      "void": false
    },
    {
      "from": null,
      "id": 439,
      "name": "div",
      "parent": 435,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderPaymentStatus"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 457,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 440,
      "name": "Checkbox",
      "parent": 437,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.telepharType === \"DELIVERY\""
        },
        "className": {
          "type": "value",
          "value": "blue"
        },
        "label": {
          "type": "value",
          "value": "Home delivery"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", textAlign: \"left\", maxWidth: \"max-content\", width: \"calc(100% - 13rem)\"}"
        }
      },
      "seq": 440,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 441,
      "name": "Checkbox",
      "parent": 437,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.telepharType === \"INTRA\""
        },
        "className": {
          "type": "value",
          "value": "blue"
        },
        "label": {
          "type": "value",
          "value": "Intrahospital Telepharmacy"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", margin: \"0 0rem 0 1rem\", textAlign: \"left\", maxWidth: \"max-content\", width: \"calc(100% - 9rem)\"}"
        }
      },
      "seq": 441,
      "void": false
    },
    {
      "from": null,
      "id": 442,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEndConsult"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\",display:props.buttonActions?.endConsult?.show ? \"\" : \"none\"}"
        }
      },
      "seq": 456,
      "void": false
    },
    {
      "from": null,
      "id": 443,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonVideoCallAgain"
        },
        "style": {
          "type": "code",
          "value": "{display:props.buttonActions?.callAgain?.show ? \"\" : \"none\"}"
        }
      },
      "seq": 458,
      "void": false
    },
    {
      "from": null,
      "id": 444,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonTelePharmacy"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\",display:props.buttonActions?.telephar?.show ? \"\" : \"none\"}"
        }
      },
      "seq": 342,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 445,
      "name": "FormGroup",
      "parent": 327,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?.received ? \"\": \"none\"}"
        }
      },
      "seq": 402,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 446,
      "name": "FormField",
      "parent": 445,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 446,
      "void": false
    },
    {
      "from": null,
      "id": 447,
      "name": "label",
      "parent": 446,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้รับ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 447,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 448,
      "name": "Label",
      "parent": 446,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.received?.name || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 448,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 449,
      "name": "FormField",
      "parent": 445,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 449,
      "void": false
    },
    {
      "from": null,
      "id": 450,
      "name": "label",
      "parent": 449,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 450,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 451,
      "name": "Label",
      "parent": 449,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receivedTime|| \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 451,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 452,
      "name": "FormField",
      "parent": 445,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 452,
      "void": false
    },
    {
      "from": null,
      "id": 453,
      "name": "label",
      "parent": 452,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\", textAlign: \"right\"}"
        }
      },
      "seq": 453,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 454,
      "name": "Label",
      "parent": 452,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.received?.division || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.78rem\", width: \"100%\"}"
        }
      },
      "seq": 454,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 455,
      "name": "Button",
      "parent": 329,
      "props": {
        "children": {
          "type": "value",
          "value": "Discount"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDiscount"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.buttonActions?.discount?.show ? \"\" : \"none\",marginRight: \"0.5rem\"}"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": null,
      "id": 456,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLanguage"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\",display:props.buttonActions?.language?.show ? \"\" : \"none\"}"
        }
      },
      "seq": 338,
      "void": false
    },
    {
      "from": null,
      "id": 457,
      "name": "div",
      "parent": 435,
      "props": {
        "children": {
          "type": "code",
          "value": "props.subAdrPopup"
        }
      },
      "seq": 438,
      "void": false
    },
    {
      "from": null,
      "id": 458,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "code",
          "value": "props.passwordInput"
        }
      },
      "seq": 337,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 459,
      "name": "FormField",
      "parent": 369,
      "props": {
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 459,
      "void": false
    },
    {
      "from": null,
      "id": 460,
      "name": "label",
      "parent": 459,
      "props": {
        "children": {
          "type": "value",
          "value": "Prescription date : "
        }
      },
      "seq": 460,
      "void": false
    },
    {
      "from": null,
      "id": 461,
      "name": "label",
      "parent": 459,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.order_for_date || \"\""
        }
      },
      "seq": 461,
      "void": false
    },
    {
      "from": null,
      "id": 462,
      "name": "div",
      "parent": 379,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tableElement"
        },
        "style": {
          "type": "code",
          "value": "{ width: props.showToolBox ? \"77%\" : \"100%\"}"
        }
      },
      "seq": 381,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugOrderWorkingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "backwardActionButton": "REJECT",
    "forwardActionButton": "VERIFY AND PRINT"
  },
  "width": 90
}

*********************************************************************************** */
