import React, {
  CSSProperties,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Icon } from "semantic-ui-react";

import moment from "moment";
import { Calendar, Formats, View, ViewsProps, momentLocalizer } from "react-big-calendar";

import { splitStringNewLine } from "../HISV3/common/CommonInterface";

import { PREFIX_CODE_REGEX } from "../HISV3/ORM/sequence/OperatingDateTime";

// Types
type CardOperatingCalendarProps = {
  anesthesiologist: number | null;
  calendarView: "day" | "month" | "week";
  // Filter
  doctor: number | null;
  filterAnesthetic: boolean;
  filterDoctor: boolean;
  opdEncounterExpire: number;
  operatingBlock?: Record<string, any>[];
  operatingLocationRoom?: Record<string, any>[];
  rangeDate: RangeDateType;
  room: any[];
  selectOperatingDSBChange: boolean;
  // data
  selectedOperatingDetail: Record<string, any> | null;
  selectedOrOrder: Record<string, any> | null;
  onChangeCalendarView: (view: CalendarViewType) => any;
  onChangeDate: (date: RangeDateType, view: string) => any;
  onChangeRangeDate: (rangeDate: RangeDateType) => any;
  // callback
  onSelectEvent: (event: any) => any;
  onSelectSlot: (event: any) => any;
};

type CalendarViewType = "day" | "month" | "week";

type RangeDateType = {
  end: Date;
  start: Date;
};

type BarColorKeys = keyof typeof BAR_COLORS;

type Styles = Record<
  | "bar"
  | "calendar"
  | "flexCenter"
  | "paddingTextLeftSlot"
  | "paddingTextRightSlot"
  | "point"
  | "pointLarge"
  | "title",
  CSSProperties
>;

type SlotWithColor = {
  color: string;
  slot: HTMLDivElement;
};

type CalculateSlotParams = {
  currentDate: moment.Moment;
  encounterType: string;
  endOfDay: moment.Moment;
  isCompare: boolean;
  rbcTimeColumn: NodeListOf<Element>;
  rbcToday: Element | null;
  timeSlot: NodeListOf<Element> | undefined;
  timeSlotRange: { end: moment.Moment; start: moment.Moment };
};

// Styles
const styles: Styles = {
  bar: {
    borderRadius: "5px",
    cursor: "pointer",
    height: "100%",
    width: "30px",
  },
  calendar: { height: "calc(100dvh - 17rem)" },
  flexCenter: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
    padding: "5px 2px 0px",
  },
  paddingTextLeftSlot: {
    padding: "5px 0 0",
    textAlign: "left",
    width: "100%",
  },
  paddingTextRightSlot: {
    padding: "5px 0px",
    textAlign: "right",
    width: "100%",
  },
  point: {
    borderRadius: "50%",
    height: "8px",
    marginRight: "0.5rem",
    marginTop: "5px",
    minWidth: "9px",
    width: "9px",
  },
  pointLarge: {
    borderRadius: "50%",
    height: "12px",
    marginRight: "0.5rem",
    marginTop: 0,
    minWidth: "12px",
    width: "12px",
  },
  title: {
    margin: "0px 1.25rem 0px 0.9rem",
  },
};

const BAR_COLORS = {
  anesthetic: {
    bd: "#b667d8",
    bg: "#E3BFF3",
  },
  doctor: {
    bd: "#ffa809",
    bg: "#FFE1A9",
  },
  major: {
    bd: "#e95959",
    bg: "#E95959",
  },
  minor: {
    bd: "#f08f8f",
    bg: "#FFC5C5",
  },
} as const;

console.log("initial moment locale en week dow 1");
moment.locale("en", { week: { dow: 1 } });

const localizer = momentLocalizer(moment);

const formats: Formats = {
  eventTimeRangeFormat: () => "",
  timeGutterFormat: (date, culture, localizer) => {
    if (localizer) {
      return localizer.format(date, "H:mm", culture);
    }

    return "";
  },
};

const DATE_FORMAT = "YYYY-MM-DD";

const VIEWS: ViewsProps = ["day", "week", "month"];

const RBC_TIME_SLOT = ".rbc-time-slot";

export const roundTime = (date: moment.Moment) => {
  const mm = Number(date.format("mm"));
  const roundedTime = date.startOf("hour").add(mm > 30 ? 60 : 30, "minute");

  return roundedTime.format("HH:mm") === "00:00" ? date.endOf("day") : roundedTime;
};

export const getTimeSlotRange = (date: string, expire: number) => {
  const start = roundTime(moment(date));

  const end = roundTime(moment(date)).add(expire, "hours");

  return { end, start };
};

const CURRENT_DATE = moment().format(DATE_FORMAT);

const CardOperatingCalendar = (props: CardOperatingCalendarProps) => {
  const intervalRef = useRef<any>(0);
  const animationRef = useRef<number>(0);
  const adjustedIndexRef = useRef<number[]>([]);

  const getTimeSlots = useCallback(
    (element: Element | null): HTMLDivElement[] =>
      element ? [...element.querySelectorAll<HTMLDivElement>(RBC_TIME_SLOT)] : [],
    []
  );

  const applyBackgroundColor = useCallback((slotsWithColors: SlotWithColor[]) => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    animationRef.current = requestAnimationFrame(() => {
      for (const { color, slot } of slotsWithColors) {
        slot.style.backgroundColor = color;
      }
    });
  }, []);

  const calculateSlotsToColor = useCallback(
    ({
      currentDate,
      encounterType,
      endOfDay,
      isCompare,
      rbcTimeColumn,
      rbcToday,
      timeSlot,
      timeSlotRange,
    }: CalculateSlotParams): HTMLDivElement[] => {
      const calculateTodaySlots = (): HTMLDivElement[] => {
        if (timeSlot?.length && encounterType === "OPD" && isCompare) {
          const todayDiff = Math.ceil(endOfDay.diff(moment().startOf("hour"), "minutes") / 30);
          const slots = [...timeSlot].reverse().slice(0, todayDiff) as HTMLDivElement[];
          const index = rbcToday ? [...rbcTimeColumn].indexOf(rbcToday) : -1;

          adjustedIndexRef.current.push(index);

          return slots;
        }

        return [];
      };

      const determineNextTime = (): Element | null => {
        const rangeStartDate = moment(props.rangeDate.start).utcOffset("+07:00").toISOString(true);
        const isNextDay =
          moment(rangeStartDate).diff(moment(timeSlotRange.start).startOf("days"), "days") === 1;

        if (isNextDay && props.calendarView === "day") {
          adjustedIndexRef.current.push(1);

          return rbcTimeColumn[1];
        }

        if (isCompare && rbcToday) {
          adjustedIndexRef.current.push([...rbcTimeColumn].indexOf(rbcToday) + 1);

          return rbcToday.nextElementSibling;
        }

        return null;
      };

      const calculateNextDaySlots = (nextTime: Element): HTMLDivElement[] => {
        let startOfDay = timeSlotRange.end.clone().startOf("day");

        startOfDay = currentDate.diff(startOfDay, "seconds") > 0 ? currentDate : startOfDay;

        const [hour, minute] = timeSlotRange.end.format("HH:mm").split(":");
        const maxSlot = Number(hour) * 2 + (minute === "30" ? 1 : 0);

        const tomorrowDiff = Math.ceil(timeSlotRange.end.diff(startOfDay, "minutes") / 30);

        const nextTimeSlot = nextTime.querySelectorAll<HTMLDivElement>(RBC_TIME_SLOT);
        const nextTimeSlots = [...nextTimeSlot].slice(0, maxSlot).reverse();

        return nextTimeSlots.slice(0, tomorrowDiff);
      };

      const todaySlots = calculateTodaySlots();
      const nextTime = determineNextTime();

      if (nextTime && encounterType === "OPD") {
        const nextDaySlots = calculateNextDaySlots(nextTime);

        return [...todaySlots, ...nextDaySlots];
      }

      return todaySlots;
    },
    [props.calendarView, props.rangeDate.start]
  );

  const handleSetBackgroundColor = useCallback(() => {
    const currentDate = moment();
    const endOfDay = currentDate.clone().endOf("day");
    const isValidView = ["day", "week"].includes(props.calendarView);

    if (!(isValidView && props.selectedOrOrder)) {
      return;
    }

    const { encounterDatetime, encounterType } = props.selectedOrOrder;
    const timeSlotRange = getTimeSlotRange(encounterDatetime, props.opdEncounterExpire);

    const isCompare =
      currentDate.format(DATE_FORMAT) === moment(encounterDatetime).format(DATE_FORMAT);

    const rbcTimeColumn = document.querySelectorAll<HTMLDivElement>(".rbc-time-column");
    const rbcToday = document.querySelector<HTMLDivElement>(
      ".rbc-day-slot.rbc-time-column.rbc-now.rbc-today"
    );
    const timeSlot = rbcToday?.querySelectorAll<HTMLDivElement>(RBC_TIME_SLOT);

    const filteredColumns = [...rbcTimeColumn].filter((unused, index) =>
      [...new Set([0, ...adjustedIndexRef.current])].includes(index)
    );

    const slotsToReset = filteredColumns.flatMap((element) => getTimeSlots(element));

    adjustedIndexRef.current = [];

    const slotsToColor = calculateSlotsToColor({
      currentDate,
      encounterType,
      endOfDay,
      isCompare,
      rbcTimeColumn,
      rbcToday,
      timeSlot,
      timeSlotRange,
    });

    const slotsWithColors: SlotWithColor[] = [
      ...[...slotsToReset, ...(timeSlot || [])].map((slot) => ({ color: "", slot })),
      ...slotsToColor.map((slot) => ({ color: "rgb(255, 252, 239)", slot })),
    ];

    applyBackgroundColor(slotsWithColors);
  }, [
    applyBackgroundColor,
    calculateSlotsToColor,
    getTimeSlots,
    props.calendarView,
    props.opdEncounterExpire,
    props.selectedOrOrder,
  ]);

  // Effect
  useEffect(() => {
    if (props.opdEncounterExpire) {
      handleSetBackgroundColor();

      clearInterval(intervalRef.current);

      intervalRef.current = setInterval(() => {
        handleSetBackgroundColor();
      }, 6000);
    }

    return () => {
      clearInterval(intervalRef.current);

      cancelAnimationFrame(animationRef.current);
    };
  }, [handleSetBackgroundColor, props.opdEncounterExpire]);

  // Callback
  const getDetailBackgroundColor = useCallback(
    (item: any) => {
      const showDetail =
        item.operating_detail === props.selectedOperatingDetail ||
        item.operating_detail === props.selectedOrOrder?.operating_detail;

      // เลื่อนนัดหมาย
      if (item.order_status === 1 && showDetail && props.selectOperatingDSBChange) {
        return "#4CDCFC4D";
      } else if (item.order_status === 1 && showDetail) {
        return "#4CDCFC";
      } else if (item.doctor === props.doctor && !item.is_doctor_schedule && showDetail) {
        return "#64E282"; // green
      }

      return "";
    },
    [
      props.doctor,
      props.selectedOperatingDetail,
      props.selectedOrOrder,
      props.selectOperatingDSBChange,
    ]
  );

  const getEventStyle = useCallback(
    (event: any): CSSProperties => {
      const bg = getDetailBackgroundColor(event);

      if (event.room && !event.doctor) {
        return {
          backgroundColor: "#E9E9E9",
          borderColor: "#E9E9E9",
          borderRadius: "none",
          color: "#121010",
        };
      }

      if (bg) {
        return { backgroundColor: bg, color: "#000000" };
      }

      const monthStyle: CSSProperties = {};

      switch (props.calendarView) {
        case "week": {
          return {
            backgroundColor: "transparent",
            border: "none",
            padding: 0,
            pointerEvents: "none",
          };
        }
        case "month": {
          if (event.type) {
            monthStyle.backgroundColor = (BAR_COLORS as any)[event.type]?.bg;

            if (!event.count) {
              monthStyle.opacity = 0;
              monthStyle.pointerEvents = "none";
            } else if (event.type === "procedure") {
              monthStyle.backgroundColor = "transparent";
              monthStyle.padding = 0;
            }
          }

          return monthStyle;
        }
        case "day": {
          if (event.children?.length) {
            return {
              backgroundColor: "transparent",
              border: "none",
              borderRadius: 0,
              padding: 0,
              pointerEvents: "none",
            };
          }

          return {
            backgroundColor: BAR_COLORS.doctor.bg,
            border: `2px solid ${BAR_COLORS.doctor.bd}`,
          };
        }

        default: {
          return {};
        }
      }
    },
    [
      props.calendarView,
      props.doctor,
      props.selectedOperatingDetail,
      props.selectedOrOrder,
      props.selectOperatingDSBChange,
    ]
  );

  const handleEventPropGetter = useCallback(
    (event: any) => {
      const style = getEventStyle(event);

      return {
        style,
      };

      //     : event.room && !event.doctor
      //     ? {
      //         backgroundColor: "#D9D9D9",
      //         color: "#D9D9D9",
      //         borderColor: "#D9D9D9",
      //         maxWidth: "30px",
      //       }
      //     : event.room &&
      //       event.procedure_type.includes("Major Surgical")
    },
    [
      props.calendarView,
      props.doctor,
      props.selectedOperatingDetail,
      props.selectedOrOrder,
      props.selectOperatingDSBChange,
    ]
  );

  const handleRangeChange = useCallback(
    (range: any, view?: View) => {
      if (props.calendarView === view) {
        return;
      }

      // set view mode
      if (view) {
        props.onChangeCalendarView(view as any);
      }

      handleNavigate(
        view === "month" ? props.rangeDate.start : range?.[0],
        view || props.calendarView
      );
    },
    [props.calendarView, props.rangeDate, props.rangeDate.start]
  );

  const handleSlotPropGetter = useCallback(
    (date: Date) => ({
      style: {
        backgroundColor:
          moment(date).format(DATE_FORMAT) === CURRENT_DATE &&
          moment(date).diff(moment(), "minutes") >= 0
            ? "#FFFCEF"
            : "",
      },
    }),
    []
  );

  const handleDayPropGetter = useCallback(
    () =>
      // #console.log("dayPropGetter", date, resourceId);
      ({
        style: {
          backgroundColor: "transparent",
        },
      }),
    []
  );

  const getBarList = useCallback(
    (event: Record<string, any>) => {
      const isDoctor = props.doctor && event.doctor === props.doctor;
      const isAnesthetic =
        props.anesthesiologist && event.anesthesiologist === props.anesthesiologist;
      const isRoom = props.room.length > 0 && !!roomDict[event.room];
      const procedureType: string = event.procedure_type || "";

      let roomKey = "room";

      if (procedureType.includes("Major")) {
        roomKey = "major";
      } else if (procedureType.includes("Minor")) {
        roomKey = "minor";
      }

      return [
        // เมื่อแพทย์ตรงกัน และ filter doctor
        isDoctor && { hide: !props.filterDoctor, key: "doctor" },
        // เมื่อวิสัญญีแพทย์ตรงกัน
        isAnesthetic && { hide: !props.filterAnesthetic, key: "anesthetic" },
        // เมื่อห้องผ่าตัดตรงกัน และ filter doctor anesthetic
        isRoom && { key: roomKey },
      ].filter((o: any) => o?.key) as { hide?: boolean; key: BarColorKeys }[];
    },
    [props.anesthesiologist, props.doctor, props.filterAnesthetic, props.filterDoctor, props.room]
  );

  // Memo
  const roomDict = useMemo(() => {
    if (!Array.isArray(props.room)) {
      return {};
    }

    const options = (props.operatingLocationRoom || []).flatMap(
      (option) => option.subOptions as Record<string, any>[]
    );

    return Object.fromEntries(
      props.room.map(
        (id) =>
          [id, options.find((option: any) => option.id === id)?.text || ""] as [number, string]
      )
    );
  }, [props.operatingLocationRoom, props.room]);

  const operatingBlock = useMemo((): Record<string, any>[] => {
    const renderBarList = (barList: any[]) => (
      <>
        {barList.map((bar) => (
          <div
            key={`bar-${bar.key}`}
            style={{
              background: (BAR_COLORS as any)[bar.key].bg,
              border: `2px solid ${(BAR_COLORS as any)[bar.key].bd}`,
              ...(bar.hide ? { opacity: 0, pointerEvents: "none" } : { pointerEvents: "auto" }),
              ...styles.bar,
            }}
          />
        ))}
      </>
    );

    /*
    render will be order like this:
    1. doctor item match selected doctor and is_doctor_schedule = true  => renderDoctorSchedule
    2. doctor item match selected doctor => renderRoomSchedule
    3. anesthesiologist item match selected anesthesiologist => renderAnesthesia
    4. other doctor but use the same operating room => renderRoomSchedule
    */

    /// status
    /// 1 : active
    /// 2 : inactive
    /// 3 : revise
    const operatingBlock = (props.operatingBlock || [])?.filter((i: any) => i.status !== 2);

    return operatingBlock.map((item, index: number) => {
      const bg = getDetailBackgroundColor(item);

      // เมื่อเป็น mode day
      const barList = getBarList(item);
      const isSubSlot = barList.length > 1 && !bg;

      // เมื่อเป็น mode week
      const showBar = barList.length > 0 && !bg && props.calendarView === "week";

      let title: ReactElement | string = "-"; // Default title if none of the conditions are match

      if (!!bg && props.calendarView === "week") {
        title = (
          <RenderRoomSchedule
            data={item}
            index={index}
            selectedOperatingDetail={
              props.selectedOperatingDetail || props.selectedOrOrder?.operating_detail
            }
          />
        );
      } else if (props.calendarView === "day") {
        if (item.is_doctor_schedule) {
          title = <RenderDoctorSchedule data={item} index={index} />;
        } else if (isSubSlot) {
          title = <RenderScheduleDetailList bars={barList} data={item} index={index} />;
        } else {
          title = <RenderScheduleDetail data={item} index={index} />;
        }
      } else if (showBar) {
        title = <div style={{ display: "flex", height: "100%" }}>{renderBarList(barList)}</div>;
      }

      return {
        ...item,
        children: isSubSlot ? barList : null,
        show_detail: !!bg,
        title,
      };
    });
  }, [
    props.calendarView,
    props.filterAnesthetic,
    props.filterDoctor,
    props.operatingBlock,
    props.selectedOperatingDetail,
    props.selectedOrOrder,
  ]);

  const groupOperatingBlock = useMemo(() => {
    if (props.calendarView !== "month") {
      return operatingBlock;
    }

    const groupDate: Record<string, Record<string, any> | undefined> = {};

    for (const item of operatingBlock) {
      const date = moment(item.start_datetime).format(DATE_FORMAT);

      if (!groupDate[date]) {
        const items = operatingBlock.filter(
          (item) => date === moment(item.start_datetime).format(DATE_FORMAT)
        );

        const barList = items.flatMap((item) => getBarList(item));

        groupDate[date] = ["doctor", "anesthetic", "procedure"].map((type, index) => {
          const getBarCount = (type: string) => {
            const filteredBars = barList.filter((bar) => bar.key === type && !bar.hide);

            return filteredBars.length;
          };

          const count = getBarCount(type);

          const start = new Date(`${date} ${index + 1}:00`);
          const end = new Date(`${date} ${index + 1}:00`);

          const bars = ["major", "minor"].map((type) => {
            const count = getBarCount(type);

            return { count, end, start, type };
          });

          return {
            count: type === "procedure" ? 1 : count,
            end,
            start,
            title:
              type === "procedure" ? (
                <div style={{ display: "flex" }}>
                  <RenderTitleList bars={bars} onSelectEvent={props.onSelectEvent} />
                </div>
              ) : (
                <RenderTitle count={count} />
              ),
            type,
          };
        });
      }
    }

    return Object.values(groupDate).flat() as Record<string, any>[];
  }, [operatingBlock, props.calendarView]);

  const formattedOperatingBlock = useMemo(
    () =>
      groupOperatingBlock.flatMap((item) => {
        const { end, start } = item;
        const startDate = moment(start).format(DATE_FORMAT);
        const endDate = moment(end).format(DATE_FORMAT);

        return startDate === endDate
          ? [item]
          : [
              { ...item, end: moment(start).endOf("dates").toDate(), start },
              { ...item, end, start: moment(end).startOf("dates").toDate() },
            ];
      }),
    [groupOperatingBlock]
  );

  // today | back | next
  const handleNavigate = useCallback(
    (dateStart: any, view: any) => {
      let updatedDate = {
        end: props.rangeDate.end,
        start: props.rangeDate.start,
      };

      const monthStart = moment(dateStart).startOf("month").format(DATE_FORMAT);
      const splitMonthStart = monthStart.split("-");

      const monthEnd = moment(dateStart).endOf("month").format(DATE_FORMAT);
      const splitMonthEnd = monthEnd.split("-");

      const dayStart = moment(dateStart).format(DATE_FORMAT);
      const splitDayStart = dayStart.split("-");

      if (view === "month") {
        const start = new Date(
          Number.parseInt(splitMonthStart[0]),
          Number.parseInt(splitMonthStart[1]) - 1,
          Number.parseInt(splitMonthStart[2])
        );
        const end = new Date(
          Number.parseInt(splitMonthEnd[0]),
          Number.parseInt(splitMonthEnd[1]) - 1,
          Number.parseInt(splitMonthEnd[2])
        );

        updatedDate = {
          end,
          start,
        };
      } else if (view === "week") {
        const start = new Date(
          Number.parseInt(splitDayStart[0]),
          Number.parseInt(splitDayStart[1]) - 1,
          Number.parseInt(splitDayStart[2])
        );
        const end = new Date(
          Number.parseInt(splitDayStart[0]),
          Number.parseInt(splitDayStart[1]) - 1,
          Number.parseInt(splitDayStart[2]) + 6
        );

        updatedDate = {
          end,
          start,
        };
      } else if (view === "day") {
        const start = new Date(
          Number.parseInt(splitDayStart[0]),
          Number.parseInt(splitDayStart[1]) - 1,
          Number.parseInt(splitDayStart[2])
        );

        updatedDate = {
          end: start,
          start,
        };
      }

      props.onChangeRangeDate(updatedDate);

      props.onChangeDate(updatedDate, view);
    },
    [props.onChangeDate, props.onChangeRangeDate, props.rangeDate]
  );

  // #const handleOnChangeWeekRange = (start: Date, end: Date, view: string) => {
  //   props.runSequence({
  //     sequence: "OperatingDateTime",
  //     action: "getDSBBlock",
  //     card: CARD_SELECT_DATE_TIME_OPERATION,
  //     doctor: doctor,
  //     anesthesiologist: anesthesiologist,
  //     room: room,
  //     start_date: moment(start).format("YYYY-MM-DD"),
  //     end_date: moment(end).format("YYYY-MM-DD"),
  //     operatingId,
  //     view,
  //     onSuccess: () => setLoadingTimeSlot(false),
  //   });
  // };

  // #console.log("CardOperatingCalendar props", formattedOperatingBlock);

  return (
    <Calendar
      date={props.rangeDate.start}
      dayPropGetter={handleDayPropGetter}
      defaultView="week"
      endAccessor="end"
      eventPropGetter={handleEventPropGetter}
      events={formattedOperatingBlock}
      formats={formats}
      localizer={localizer}
      slotPropGetter={handleSlotPropGetter}
      startAccessor="start"
      style={styles.calendar}
      tooltipAccessor={""}
      view={props.calendarView}
      views={VIEWS}
      // dayLayoutAlgorithm={"no-overlap"}
      selectable
      showAllEvents
      // #onNavigate={handleNavigate}
      // day | week | month
      onRangeChange={handleRangeChange}
      onSelectEvent={props.onSelectEvent}
      onSelectSlot={props.onSelectSlot}
    />
  );
};

/* ------------------------------------------------------ */

/*                   RenderRoomSchedule                   */

/* ------------------------------------------------------ */
type RenderRoomScheduleProps = {
  data?: Record<string, any>;
  index: number;
  selectedOperatingDetail: Record<string, any> | null;
};

const RenderRoomSchedule = (props: RenderRoomScheduleProps) => {
  const [displayedTreatments, setDisplayedTreatments] = useState<string[]>([]);

  // Ref
  const treatmentRef = useRef<HTMLDivElement | null>(null);

  // Memo Effect
  const treatments = useMemo(() => {
    const treatments: string[] = props.data?.treatments || [];

    return treatments;
  }, [props.data?.treatments]);

  // Effect
  useEffect(() => {
    if (!treatmentRef.current) {
      return;
    }

    const eventContainer = treatmentRef.current.closest(".rbc-event");

    const truncatedTreatments = splitStringNewLine(treatments.join(", "), {
      ellipse: "...",
      fontSize: 16,
      max: 3,
      width: eventContainer?.clientWidth,
    });

    setDisplayedTreatments(truncatedTreatments);
  }, [treatmentRef.current]);

  // Memo
  const isShowDetail = useMemo(
    () => props.data?.operating_detail === props.selectedOperatingDetail,
    [props.data, props.selectedOperatingDetail]
  );

  const roomKey = useMemo(() => {
    const procedureType: string = props.data?.procedure_type || "";

    if (procedureType.includes("Major")) {
      return "major";
    } else if (procedureType.includes("Minor")) {
      return "minor";
    }

    return "";
  }, [props.data]);

  // #let start_time = moment(item.start_datetime).format("HH:mm");
  // #let end_time = moment(item.end_datetime).format("HH:mm");

  return isShowDetail ? (
    <div
      key={`DSB${props.index + 1}`}
      style={{
        ...styles.flexCenter,
        lineHeight: 1.25,
        overflowX: "hidden",
        paddingTop: "2px",
      }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            ...styles.point,
            backgroundColor: (BAR_COLORS as any)[roomKey]?.bg,
          }}
        />
        <strong>{props.data?.anesthesia_method}</strong>
      </div>

      {/* #<div
        style={styles.paddingTimeSlot}
      >{`${start_time} น. - ${end_time} น.`}</div>
      <div style={styles.paddingTextLeftSlot}>{item.patient_name}</div>
      <div style={styles.paddingTextLeftSlot}>{item.doctor_name}</div> */}

      <div ref={treatmentRef} style={{ lineHeight: 1 }}>
        {displayedTreatments.map((text, index) => (
          <div key={`DSB${text}T${index}`} style={styles.paddingTextLeftSlot}>
            {text}
          </div>
        ))}
      </div>
      <strong style={{ ...styles.paddingTextRightSlot }}>{props.data?.order_status_label}</strong>
    </div>
  ) : null;
};

/* ------------------------------------------------------ */

/*                  RenderDoctorSchedule;                 */

/* ------------------------------------------------------ */
type RenderDoctorScheduleProps = {
  data: Record<string, any>;
  index: number;
};

const RenderDoctorSchedule = (props: RenderDoctorScheduleProps) => (
  <div
    key={`DSB${props.index + 1}`}
    style={{
      ...styles.flexCenter,
      color: "rgba(0,0,0,0.87)",
      fontWeight: "bold",
      padding: "0.5rem 10px 0px 5px",
    }}
  >
    <div
      style={{
        lineHeight: 2,
        paddingLeft: "1rem",
        textAlign: "left",
        width: "100%",
      }}
    >
      <label>{props.data.doctor_name}</label>

      <div style={{ alignItems: "center", display: "flex" }}>
        <div>{props.data.division_name}</div>
      </div>
      <div style={{ alignItems: "center", display: "flex" }}>
        <div>(ผู้ป่วย {props.data.patient_count} คน)</div>
      </div>
    </div>
  </div>
);

/* ------------------------------------------------------ */

/*                RenderScheduleDetailList;               */

/* ------------------------------------------------------ */
type RenderScheduleDetailListProps = {
  bars: Record<string, any>[];
  data: Record<string, any>;
  index: number;
};

const RenderScheduleDetailList = (props: RenderScheduleDetailListProps) => (
  <div style={{ display: "flex", height: "inherit" }}>
    {props.bars.map((bar) => (
      <div
        key={`schedule-bar-${bar.key}`}
        style={{
          backgroundColor: (BAR_COLORS as any)[bar.key]?.bg,
          border: `1px solid ${(BAR_COLORS as any)[bar.key]?.bd}`,
          borderRadius: "5px",
          padding: "2px 5px",
          width: "100%",
          ...(bar.hide ? { opacity: 0, pointerEvents: "none" } : { pointerEvents: "auto" }),
        }}
      >
        {<RenderScheduleDetail data={props.data} index={props.index} />}
      </div>
    ))}
  </div>
);

/* ------------------------------------------------------ */

/*                  RenderScheduleDetail;                 */

/* ------------------------------------------------------ */
type RenderScheduleDetailProps = {
  data: Record<string, any>;
  index: number;
};

const RenderScheduleDetail = (props: RenderScheduleDetailProps) => {
  const roomKey = useMemo(() => {
    const procedureType: string = props.data.procedure_type || "";

    return procedureType.includes("Minor") ? "minor" : "major";
  }, [props.data]);

  // #const treatments = useMemo(() => {
  //   const treatments: string[] = props.data.treatments || [];

  //   return treatments;
  // }, [props.data.treatments]);

  const surgeryTeams = useMemo(() => {
    const teams = props.data.surgery_teams || [];

    return teams as { [key: string]: any; chief_surgeon: string | null }[];
  }, [props.data.surgery_teams]);

  return (
    <div
      key={`DSB${props.index + 1}`}
      style={{
        ...styles.flexCenter,
        color: "rgba(0,0,0,0.87)",
        padding: "5px 10px 0px 5px",
        ...(props.data.room && !props.data.doctor && { display: "none" }),
      }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            ...styles.pointLarge,
            backgroundColor: (BAR_COLORS as any)[roomKey]?.bg,
            marginTop: "1px",
          }}
        />
        <strong>{props.data.anesthesia_method}</strong>
        <div style={{ flex: 1 }} />
        <strong>{props.data.room_name}</strong>
      </div>
      <div
        style={{
          lineHeight: 1.5,
          paddingLeft: "2rem",
          textAlign: "left",
          width: "100%",
        }}
      >
        <label>{props.data.patient_name}</label>

        <div style={{ alignItems: "baseline", display: "flex" }}>
          <div
            style={{
              ...styles.pointLarge,
              backgroundColor: BAR_COLORS.doctor.bg,
              border: "1px solid black",
              marginBottom: "5px",
            }}
          />
          <div>
            {surgeryTeams.map((team, teamIndex) => {
              const postOperatingTreatments: Record<string, any>[] =
                team.post_operating_order_item?.treatment || [];

              const chiefSurgeonName = team.chief_surgeon?.replace(PREFIX_CODE_REGEX, "").trim();
              const treatmentCodes = postOperatingTreatments
                .map((treatment) => treatment.name_code as string)
                .join(", ");
              const otherTreatment: string = team.post_operating_order_item?.other_treatment || "";
              const formattedOtherTreatment = otherTreatment.split("\n").join(" ");
              const teamSeparator =
                surgeryTeams.length > 1 && teamIndex !== surgeryTeams.length - 1 ? "/ " : "";

              return (
                <>
                  <strong>
                    <span>ทีม {teamIndex + 1}</span>
                  </strong>{" "}
                  {chiefSurgeonName}{" "}
                  <strong>
                    <span>{treatmentCodes}</span>
                  </strong>{" "}
                  {formattedOtherTreatment} {teamSeparator}
                </>
              );
            })}
          </div>
        </div>
        {props.data.anesthesiologist_name && (
          <div style={{ alignItems: "center", display: "flex" }}>
            <div
              style={{
                ...styles.pointLarge,
                backgroundColor: BAR_COLORS.anesthetic.bg,
                border: "1px solid black",
              }}
            />
            <div>{props.data.anesthesiologist_name}</div>
          </div>
        )}
      </div>
      {/* #{treatments.map((treatment: any, ti: number) => (
        <div key={`DSB${props.index + 1}T${ti}`} style={styles.paddingTextLeftSlot}>
          {treatment}
        </div>
      ))} */}
      <strong style={styles.paddingTextRightSlot}>{props.data.order_status_label}</strong>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                     RenderTitleList                    */

/* ------------------------------------------------------ */
type RenderTitleListProps = {
  bars: Record<string, any>[];
  // callback
  onSelectEvent: (event: any) => any;
};

const RenderTitleList = (props: RenderTitleListProps) => {
  const handleClick = useCallback(
    (bar: any) => (e: SyntheticEvent) => {
      e.stopPropagation();

      props.onSelectEvent(bar);
    },
    []
  );

  return (
    <>
      {props.bars.map((bar, index: number) => (
        <div
          key={`title-bar-${bar.type}`}
          aria-hidden="true"
          onClick={handleClick(bar)}
          style={{
            backgroundColor: (BAR_COLORS as any)[bar.type].bg,
            borderRadius: "5px",
            margin: index === 0 ? "0 0.25rem 0 0" : "0 0 0 0.25rem",
            padding: "2px 0",
            width: "50%",
            ...(bar.count ? { pointerEvents: "auto" } : { opacity: 0, pointerEvents: "none" }),
          }}
        >
          <RenderTitle count={bar.count} style={styles.title} />
        </div>
      ))}
    </>
  );
};

/* ------------------------------------------------------ */

/*                       RenderTitle                      */

/* ------------------------------------------------------ */
type RenderTitleProps = {
  count: number;
  style?: CSSProperties;
};

const RenderTitle = (props: RenderTitleProps) => {
  const style = useMemo(
    () => ({
      fontSize: "0.65rem",
      margin: "0 3rem 0 0.5rem",
      ...props.style,
    }),
    [props.style]
  );

  return (
    <div style={{ display: "flex" }}>
      <Icon color="grey" name="user" style={style} />
      <div
        style={{
          color: "#575656",
          fontSize: "0.85rem",
        }}
      >
        <label>{props.count}</label>
      </div>
    </div>
  );
};

CardOperatingCalendar.displayName = "CardOperatingCalendar";

export default React.memo(CardOperatingCalendar);
