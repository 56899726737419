import React, { useState, useEffect } from 'react';
import CardLayout from '../common/CardLayout';
import { Checkbox, Button } from 'semantic-ui-react';
import ReactTable from 'react-table-6';
import { formatToBEDatetime } from '../../utils'

const CardSecretEncounter = (props) => {

  const [secretEncounter, setSecretEncounter] = useState([])

  useEffect(() => {
    props.onGetSecretEncounter()
  }, [])

  useEffect(() => {
    setSecretEncounter(props.secretEncounterData)
  }, [props.secretEncounterData])

  const handleCheck = (e, index, checked) => {
    secretEncounter[index].active = checked
    setSecretEncounter([...secretEncounter])
  }

  return (
    <CardLayout
      closeable={props.closeable}
      toggleable={props.toggleable}
      hideHeaderIcon={props.hideHeaderIcon}
      defaultBackground={props.defaultBackground}
      loading={props.secretEncounterLoading}
    >
      <ReactTable
        data={secretEncounter}
        showPagination={false}
        noDataText='ไม่มีข้อมูล'
        style={{ height: 480, backgroundColor: '#FFFFFF' }}
        pageSize={secretEncounter ? (secretEncounter.length < 10 ? 10 : secretEncounter.length) : 10}
        columns={[
          {
            Header: 'ปกปิด',
            width: 50,
            style: { 'whiteSpace': 'unset', textAlign: 'center' },
            Cell: (data) => {
              if (!data.original) {
                return ''
              }
              return (
                <Checkbox
                  index={data.index}
                  checked={data.original.active}
                  onChange={(e, { index, checked }) => handleCheck(e, index, checked)}
                />
              )
            }
          },
          {
            Header: 'Type',
            accessor: 'type',
            style: { 'whiteSpace': 'unset', textAlign: 'center' },
            width: 120,
            Cell: row => {
              let colorType;
              if (row.value === "IPD") {
                colorType = { color: '#2F80ED' };
              } else {
                colorType = { color: 'green' };
              }
              return (
                <div>
                  <center>
                    <span style={colorType}> {row.value}  </span> <span style={{ color: 'gray' }}>  {row.original.number} </span>
                    {/* <font color={colorType}>{row.value}  </font> */}
                    {/* <font color="gray">{row.original.number} </font> */}
                  </center>
                </div>
              );
            },
          },
          {
            Header: 'Date',
            accessor: 'created',
            style: { 'whiteSpace': 'unset', textAlign: 'center' },
            minWidth: 180,
            Cell: (data) => {
              if (!data.original) {
                return ''
              }
              return formatToBEDatetime({ date: data.original.created })
            }
          },
          {
            Header: 'Doctor/Division',
            accessor: 'doctor_display_name',
            style: { 'whiteSpace': 'unset', textAlign: 'left' },
            resizable: false,
            Cell: (data) => {
              if (!data.original) {
                return ''
              }
              return data.original.doctor_display_name + ' / ' + data.original.division_display_name
            },
            minWidth: 250,
          },
        ]}
      />
      <br />
      <Button.Group style={{ display: 'block', textAlign: 'right' }} >
        <Button color='green' onClick={() => props.onPostSecretEncounter(secretEncounter)}>SAVE</Button>
      </Button.Group>
    </CardLayout>
  )
}

CardSecretEncounter.defaultProps = {
  closeable: true,
  toggleable: true,
  hideHeaderIcon: false,
  defaultBackground: true,
  onGetSecretEncounter: () => { },
  secretEncounterLoading: false,
  secretEncounterData: [],
  onPostSecretEncounter: () => { },
};

export default CardSecretEncounter;