import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ButtonProps, DropdownProps } from "semantic-ui-react";

import { RowInfo } from "react-table-6";
import Dropzone, { DropFilesEventHandler } from "react-dropzone";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { formatPrice } from "react-lib/apps/common/PureReactTable";
import ButtonLoadCheck, { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";

import LoadingIcon from "../HCU/LoadingIcon";

import CardSendClaimResponseFileUX from "./CardSendClaimResponseFileUX";

// Interface
import {
  ACTIONS,
  BTN_ACTS,
  CARD_SEND_CLAIM_RESPONSE_FILE,
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/SendClaimResponseFile";

// Types
type CardSendClaimResponseFileProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  SendClaimResponseFileSequence: State["SendClaimResponseFileSequence"];
} & PickedProps;

type TabMenuKeys = keyof typeof SEND_CLAIM;

type Styles = {
  [key in "errorMessage" | "upload" | "uploadHeader" | "uploadWarning"]: CSSProperties;
};

// Const
const styles: Styles = {
  errorMessage: {
    bottom: "-1.5rem",
    color: "darkred",
    position: "absolute",
  },
  upload: {
    backgroundColor: "#fff",
    border: "2px dashed #b2b2c8",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    marginRight: "1rem",
    padding: "1rem",
    textAlign: "center",
  },
  uploadHeader: {
    color: "#474646",
    marginBottom: "0.5rem",
    paddingTop: "5%",
  },
  uploadWarning: {
    alignItems: "flex-end",
    color: "darkred",
    display: "flex",
    justifyContent: "center",
    margin: "1rem 0",
    position: "relative",
  },
};

const SEND_CLAIM = {
  AIPN: {
    accept: ".zip",
    data: "aipnDetail",
    headers: "Invoice No.,สถานะ,เหตุผลในการติด C",
    keys: "invoice_no,formatted_success,formatted_reason",
    warning: "รองรับเฉพาะนามสกุล ไฟล์ .zip",
    widths: "150,150,^200",
  },
  EClaim: {
    accept: ".csv,.xls",
    data: "eclaimDetail",
    headers: "REP,HN,วันที่เข้ารักษา,เรียกเก็บ,พึงรับทั้งหมด",
    keys: "rep_no,hn,datetime_start,sent_claim_price,receiving_total",
    warning: "รองรับเฉพาะนามสกุล ไฟล์ .csv .xls",
  },
} as const;

const CardSendClaimResponseFile = (props: CardSendClaimResponseFileProps) => {
  const [activeTab, setActiveTab] = useState<TabMenuKeys>("EClaim");

  // Effect
  useEffect(() => {
    setActiveTab("EClaim");

    props.runSequence({
      sequence: "SendClaimResponseFile",
      nextIndex: "EClaim",
      restart: true,
    });

    return () => {
      props.runSequence({ sequence: "SendClaimResponseFile", clear: true });
    };
  }, []);

  // Callback
  const handleGetTrProps = useCallback((state: any, rowInfo?: RowInfo) => {
    const original = rowInfo?.original || {};

    return {
      style:
        "success" in original && !original?.success
          ? { background: "rgb(255, 197, 197)", color: "black" }
          : {},
    };
  }, []);

  const handleChangeTab = useCallback(
    (e: SyntheticEvent, data: { name: TabMenuKeys } & ButtonProps) => {
      setActiveTab(data.name);

      props.runSequence({
        sequence: "SendClaimResponseFile",
        nextIndex: data.name,
      });
    },
    []
  );

  const handleUploadFile: DropFilesEventHandler = useCallback((accepted, rejected) => {
    props.runSequence({
      sequence: "SendClaimResponseFile",
      action: ACTIONS.UPLOAD_FILE,
      accepted,
      card: CARD_SEND_CLAIM_RESPONSE_FILE,
      rejected,
    });
  }, []);

  const handleChangeCoveragePayer = useCallback(
    (e: SyntheticEvent, data: { value: number } & DropdownProps) => {
      const eclaimDetail = {
        ...props.SendClaimResponseFileSequence?.eclaimDetail,
        coverage_payer_sent_claim_group: data.value,
      };

      props.setProp("SendClaimResponseFileSequence.eclaimDetail", eclaimDetail);
    },
    [props.SendClaimResponseFileSequence?.eclaimDetail]
  );

  const handleSave: BLClickHandler<typeof ACTIONS.SAVE> = useCallback((e, data) => {
    props.runSequence({
      sequence: "SendClaimResponseFile",
      action: data.name,
      card: CARD_SEND_CLAIM_RESPONSE_FILE,
    });
  }, []);

  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${CARD_SEND_CLAIM_RESPONSE_FILE}`, null);
  }, []);

  // Memo
  const eclaimDetail = useMemo(
    () => props.SendClaimResponseFileSequence?.eclaimDetail,
    [props.SendClaimResponseFileSequence?.eclaimDetail]
  );

  const disabledDropzone = useMemo(() => {
    const isLoading = !!props.buttonLoadCheck?.[BTN_ACTS.UPLOAD_FILE];

    return activeTab === "EClaim"
      ? !eclaimDetail?.coverage_payer_sent_claim_group || isLoading
      : false;
  }, [activeTab, eclaimDetail, props.buttonLoadCheck]);

  const disabledSave = useMemo(() => {
    const data = props.SendClaimResponseFileSequence?.[SEND_CLAIM[activeTab].data];

    return !data?.items?.length;
  }, [activeTab, eclaimDetail, props.SendClaimResponseFileSequence?.aipnDetail]);

  const tableDetail = useMemo(() => {
    const data = props.SendClaimResponseFileSequence?.[SEND_CLAIM[activeTab].data];

    const items = data?.items || [];

    return {
      data: SEND_CLAIM[activeTab],
      items: items.map((item) => {
        if ("receiving_total" in item) {
          return {
            ...item,
            receiving_total: formatPrice(item.receiving_total),
            sent_claim_price: formatPrice(item.sent_claim_price),
          };
        }

        const formattedSuccess = item.success ? "สำเร็จ" : "ติด C";
        const failedReasons: any[] = item.failed_reasons || [];

        const formattedReason = failedReasons
          .map((accumulator) => `[${accumulator.code}] ${accumulator.message}`)
          .join("\n");

        return { ...item, formatted_reason: formattedReason, formatted_success: formattedSuccess };
      }),
    };
  }, [activeTab, eclaimDetail, props.SendClaimResponseFileSequence?.aipnDetail]);

  const buttonSave = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"green"}
        disabled={disabledSave}
        name={ACTIONS.SAVE}
        paramKey={BTN_ACTS.SAVE}
        size="small"
        // style={{ minWidth: "8rem" }}
        title="ยืนยัน"
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SAVE]}
        // callback
        onClick={handleSave}
      />
    ),
    [disabledSave, handleSave, props.buttonLoadCheck]
  );

  const dropzone = useMemo(
    () => (
      <Dropzone
        accept={tableDetail.data.accept}
        disabled={disabledDropzone}
        // disabledStyle={{ opacity: 0.75, pointerEvents: "none" }}
        multiple={false}
        style={styles.upload}
        onDrop={handleUploadFile}
      >
        <h3 style={styles.uploadHeader}>อัพโหลดไฟล์</h3>
        <p>คลิกเพื่อเลือกไฟล์ หรือลากไฟล์มาวาง</p>
        <div style={styles.uploadWarning}>
          {tableDetail.data.warning}
          {!eclaimDetail?.coverage_payer_sent_claim_group && activeTab === "EClaim" && (
            <span style={styles.errorMessage}>จำเป็นต้องระบุสิทธิ*</span>
          )}
        </div>
        <LoadingIcon loading={props.buttonLoadCheck?.[BTN_ACTS.UPLOAD_FILE]} />
      </Dropzone>
    ),
    [
      activeTab,
      disabledDropzone,
      eclaimDetail,
      handleUploadFile,
      props.buttonLoadCheck,
      tableDetail.data,
    ]
  );

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_SEND_CLAIM_RESPONSE_FILE]}
        success={null}
        onClose={handleCloseErrMsg}
      />

      <CardSendClaimResponseFileUX
        // data
        activeTab={activeTab}
        aipnDetail={props.SendClaimResponseFileSequence?.aipnDetail}
        buttonSave={buttonSave}
        data={tableDetail.items}
        dropzone={dropzone}
        eclaimDetail={eclaimDetail}
        table={tableDetail.data}
        // options
        eclaimCoverageOptions={props.SendClaimResponseFileSequence?.eclaimCoverageOptions}
        // callback
        onChangeCoveragePayer={handleChangeCoveragePayer}
        onChangeTab={handleChangeTab}
        onGetTrProps={handleGetTrProps}
      />
    </div>
  );
};

CardSendClaimResponseFile.displayName = "CardSendClaimResponseFile";

export default React.memo(CardSendClaimResponseFile);
