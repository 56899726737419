import React, { useEffect } from 'react';
import CardLayout from '../common/CardLayout';
import { Segment, Label } from 'semantic-ui-react'
import { formatToBEDatetime } from '../../utils';

const CardSensitiveNoteView = (props) => {

  useEffect(() => {
    props.onGetSensitiveNote({})
  }, [])

  let titleContent = null;
  if (props.sensitiveNote) {
    titleContent = (
      <div>
        {props.sensitiveNote.edit_user} &ensp;
         <Label style={{ color: 'black' }}
          content={formatToBEDatetime({ date: props.sensitiveNote.edited })}
        />
      </div>
    )
  }

  const formatSensitiveNote = (text) => {
    console.log(props.sensitiveNote);
    let note = props.sensitiveNote.sensitive_note.replace(/\u21B5/g, '<br/>')
    return (
      <div style={{ whiteSpace: 'pre-line' }}>
        {note}
      </div>
    )
  }

  return (
    <CardLayout
      titleText='Sensitive Note'
      headerColor='brown'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      titleContent={titleContent}
      loading={props.sensitiveNoteLoading}
      enableMargin={props.enableMargin}
    >
      <div style={{ maxHeight: 480, overflowY: 'auto' }}>
        <Segment>
        {props.sensitiveNote?.sensitive_note ?
          formatSensitiveNote(props.sensitiveNote.sensitive_note)
          : 'No Data.'}
        </Segment>
      </div>
    </CardLayout>
  )
}

CardSensitiveNoteView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  sensitiveNote: {sensitive_note:""},
  sensitiveNoteLoading: false,
  onGetSensitiveNote: () => { },
  enableMargin: true,
};

export default CardSensitiveNoteView;
