import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const CardPatientListUX = (props: any) => {
    return(
      <div
        style={{ height: "100%", position: "relative"}}>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          data={props.encounterList}
          getTrProps={props.encounterListRowProps}
          headers={`HN (Encounter),ชื่อผู้ป่วย,Triage level,ประเภทผู้ป่วย,เวลามา,ระยะเวลารอคอย,เวลานัด${props.showDocument ? ",document":""},Dr. Order${props.showDivision ? ",แผนก":""},แพทย์,Status,Classify,เข้า Zone,ออก Zone,Zone,Location,Admit Code`}
          id="tb-encounterPatientList"
          keys={`hn_encounter,patient_full_name,triage_level,patient_case_name,created,waiting_time,appointment_time${props.showDocument ? ",document":""},doctor_order${props.showDivision ? ",division_name":""},edit_encounter,status_name,classify_name,zone_in_time,zone_out_time,zone_name,location,admit_code`}
          minRows="10"
          showPagination={true}
          style={{ height: "calc(100vh - 16.5rem)"}}
          widths={`120,160,*,*,*,120,*,${props.showDocument ? "*":"0"},160 ${props.showDivision ? ",*":""},170,^140,*, *,*,*,*,*`}>
        </Table>
      </div>
    )
}


export default CardPatientListUX

export const screenPropsDefault = {}

/* Date Time : Tue Jun 18 2024 15:28:18 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", position: \"relative\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.encounterList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.encounterListRowProps"
        },
        "headers": {
          "type": "code",
          "value": "`HN (Encounter),ชื่อผู้ป่วย,Triage level,ประเภทผู้ป่วย,เวลามา,ระยะเวลารอคอย,เวลานัด${props.showDocument ? \",document\":\"\"},Dr. Order${props.showDivision ? \",แผนก\":\"\"},แพทย์,Status,Classify,เข้า Zone,ออก Zone,Zone,Location,Admit Code`"
        },
        "id": {
          "type": "value",
          "value": "tb-encounterPatientList"
        },
        "keys": {
          "type": "code",
          "value": "`hn_encounter,patient_full_name,triage_level,patient_case_name,created,waiting_time,appointment_time${props.showDocument ? \",document\":\"\"},doctor_order${props.showDivision ? \",division_name\":\"\"},edit_encounter,status_name,classify_name,zone_in_time,zone_out_time,zone_name,location,admit_code`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"calc(100vh - 16.5rem)\"}"
        },
        "widths": {
          "type": "code",
          "value": "`120,160,*,*,*,120,*,${props.showDocument ? \"*\":\"0\"},160 ${props.showDivision ? \",*\":\"\"},170,^140,*, *,*,*,*,*`"
        }
      },
      "seq": 1,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardPatientListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 90
}

*********************************************************************************** */
