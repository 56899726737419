import React, { useEffect, useState, CSSProperties, useRef, useMemo } from "react";
import { Modal } from "semantic-ui-react";
import moment from "moment";
import { DateLocalizer, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import SearchBox from "react-lib/appcon/common/SearchBox";
import CardDoctorScheduleUX from "./CardDoctorScheduleUX";
import ModSchedulingUX from "./ModSchedulingUX";
import { DsbStatus } from "./Model";
import { join } from "path";

console.log("initial moment locale en week dow 1");
moment.locale("en", { week: { dow: 1 } });
const localizer = momentLocalizer(moment);

type CardDoctorScheduleType = {
  onEvent: any;
  setProp: any;
  runSequence: any;
  selectedDivision: any;
  SetScheduleSequence: any;
  searchedItemList: any;
  selectedProvider: any;
  holiday: any;
  providerBlockList: any;
};

const CardDoctorSchedule = (props: CardDoctorScheduleType) => {
  const [showModal, setShowModal] = useState(false);
  const [event, setEvent] = useState<any>(null);
  const [inactive, setInactive] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<any>(null);

  useEffect(() => {
    props.runSequence({ sequence: "SetSchedule", restart: true });

    props.onEvent({
      message: "Holiday",
      params: {
        action: "refresh",
      },
    });
  }, [props.selectedDivision]);

  useEffect(() => {
    if (props.selectedProvider) {
      setSelectedProvider(props.selectedProvider);
      props.onEvent({
        message: "FilterSchedule",
        params: {
          providerId: props.selectedProvider?.provider_id,
        },
      });
    }
  }, [props.selectedProvider]);

  // Prepare blockList for rendering

  const blockList = useMemo(() => {
    // props.SetScheduleSequence?.blockList  ดึงแผนกปัจจุบัน
    // props.providerBlockList ดึงตารางออกตรวจของตัวเอง

    // ซึ่งมันจะซ้ำกันใน แผนกเดียวกัน ต้องกรองออก 69290

    // console.log('blockList props.providerBlockList : ', props.providerBlockList );
    // console.log('blockList props.SetScheduleSequence?.blockList: ', props.SetScheduleSequence?.blockList);
    let currentDSBIdList = (props.SetScheduleSequence?.blockList || [])
      ?.flatMap((i: any) => i.providers)
      ?.map((i: any) => i?.dsb?.dsb_id);

    // console.log('blockList currentDSBIdList: ', currentDSBIdList);
    let providerBL = (props.providerBlockList || [])?.filter(
      (i: any) => !currentDSBIdList.includes(i.dsb_id)
    );
    // console.log('blockList providerBL: ', providerBL);

    let block = (props.SetScheduleSequence?.blockList || []).concat(providerBL || []);
    let joinblock = block
      .map((item: any) => ({
        ...item,
        providers: item.providers
          .filter((item: any) => item.doctor_provider_id == selectedProvider?.provider_id)
          .map((item: any) => item.dsb),
        title:
          item.division_id && item.division_id !== props.selectedDivision?.id
            ? `${item.title} (${item.division_name})`
            : item?.title,
      }))
      ?.filter((i: any) => (i.providers || [])?.length > 0);

    return selectedProvider === null ? block : joinblock;
  }, [selectedProvider, props.SetScheduleSequence?.blockList, props.providerBlockList]);
  // const blockList =

  const blockListAndHoliday = useMemo(() => {
    const holiday = (props.holiday?.items || []).flatMap((item: any) => {
      const [day, month, year] = item.date[0].split("/");
      const date = `${year - 543}-${month}-${day}`;
      return {
        start: new Date(`${date} 00:00`),
        end: new Date(`${date} 23:59`),
        title: item.detail,
        holiday: true,
      };
      // return calendarYear.map((year)=>{
      // const date = `${year}-${month}-${day}`;
      //   return { start: new Date(`${date} 00:00`), end: new Date(`${date} 23:59`), title: item.detail, holiday: true };
      // })
    });
    console.log("blockListAndHoliday holiday: ", holiday);
    console.log("blockListAndHoliday blocklist: ", blockList);
    return [...holiday, ...blockList];
  }, [blockList, props.holiday?.items]);

  console.log("blockList: ", blockList);

  const formats = {
    monthHeaderFormat: (date: Date, culture?: string, localize?: DateLocalizer) => {
      let defaultMonth = localize?.format(date, "MMMM YYYY", culture);
      let BEYear = Number(localize?.format(date, "YYYY")) + 543;
      let thaiMonth = localize?.format(date, "MMMM", "th-TH");
      return `${defaultMonth}  (${thaiMonth} ${BEYear})`;
    },
  };

  // Handler for calendar select event, double click
  const handleSelectTimeout = useRef(0);
  const handleSelectEvent = (
    event: any,
    e: React.SyntheticEvent<HTMLElement, globalThis.Event>
  ) => {
    // Save current target in case we need to use it
    const currentTarget = e.currentTarget;
    if (!handleSelectTimeout.current) {
      handleSelectTimeout.current = setTimeout(() => {
        handleSelectTimeout.current = 0;
        console.log(event);
        setShowModal(true);
        setEvent(event);
        setInactive(event?.providers?.[0]?.status === DsbStatus.INACTIVE);
      }, 200) as any;
    } else {
      clearTimeout(handleSelectTimeout.current);
      handleSelectTimeout.current = 0;
      console.log(event);
      console.log(event?.providers?.[0]?.status);
      props.runSequence({
        sequence: "SetSchedule",
        action: "AddBlock",
        event: event,
        selectedProvider: selectedProvider,
        inactive:
          typeof event?.providers?.[0]?.status !== "undefined" &&
          event?.providers?.[0]?.status !== DsbStatus.INACTIVE,
      });
    }
  };

  return (
    <div style={{ height: "90vh", padding: "5px" }}>
      <CardDoctorScheduleUX
        // Select provider
        selectedProvider={selectedProvider}
        providerSelector={
          <SearchBox
            type="Provider"
            vertical={true}
            style={{ width: "100%" }}
            fluid={true}
            label="แพทย์"
            placeholder="ค้นหาชื่อแพทย์"
            toDisplay={(item: any) => `${item.first_name || ""} ${item.last_name || ""}`}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={selectedProvider}
            setSelectedItem={props.selectedProvider ? () => {} : setSelectedProvider}
          />
        }
        // Block
        localizer={localizer}
        formats={formats}
        blockList={selectedProvider ? blockListAndHoliday : []}
        eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {
          return {
            style: event?.holiday
              ? { backgroundColor: "#FFE696", color: "black", textAlign: "center" }
              : event?.division_id !== props.selectedDivision?.id
              ? { backgroundColor: "orange", color: "black" }
              : event?.providers?.[0]?.doctor_dsb_status === 2
              ? { backgroundColor: "#cccccc", color: "black" }
              : event?.providers?.length === 0
              ? { backgroundColor: "pink", color: "black" }
              : event?.providers?.[0]?.status === 2
              ? { backgroundColor: "#cccccc", color: "black" }
              : ({} as CSSProperties),
          };
        }}
        onSelectEvent={handleSelectEvent}
      />
      <Modal open={showModal} closeOnDimmerClick={true} onClose={() => setShowModal(false)}>
        <ModSchedulingUX
          data={event ? [event] : []}
          addBlock={(e: any) => {
            props.runSequence({
              sequence: "SetSchedule",
              action: "AddBlock",
              event: event,
              selectedProvider: selectedProvider,
              inactive: inactive,
            });
            setShowModal(false);
          }}
          // Inactive
          inactive={inactive}
          changeInactive={() => setInactive(!inactive)}
        />
      </Modal>
    </div>
  );
};

CardDoctorSchedule.displayName = "CardDoctorSchedule";
export default CardDoctorSchedule;
