import React, { useEffect, useState } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// ui ORM
import CardSurgicalPosition from "./CardSurgicalPosition";
import CardTourniquet from "./CardTourniquet";
import CardElectricalXrayUltrasound from "./CardElectricalXrayUltrasound";
import CardDefibrillator from "./CardDefibrillator";
import CardLaser from "./CardLaser";
import { Button, Checkbox, Dropdown, Input } from "semantic-ui-react";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

type CardStepAHProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardStepAHInitial: CardStepAHProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PerioperativeNursingSequence: {},
};

const CardStepAH: React.FC<CardStepAHProps> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "FETCH_FORM_DATA_STEP_AH",
      
    });
  }, []);

  console.log(
    "props.PerioperativeNursingSequence",
    props.PerioperativeNursingSequence
  );
  return (
    <>
      <CardSurgicalPosition
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        selectedOrOrder={props.selectedOrOrder}
        masterOptions={props.masterOptions}
        runSequence={props.runSequence}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
      />
      <CardTourniquet
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        selectedOrOrder={props.selectedOrOrder}
        masterOptions={props.masterOptions}
        runSequence={props.runSequence}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
      />
      <CardElectricalXrayUltrasound
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        selectedOrOrder={props.selectedOrOrder}
        masterOptions={props.masterOptions}
        runSequence={props.runSequence}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
      />
      <CardDefibrillator
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        selectedOrOrder={props.selectedOrOrder}
        masterOptions={props.masterOptions}
        runSequence={props.runSequence}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
      />
      <CardLaser
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        selectedOrOrder={props.selectedOrOrder}
        masterOptions={props.masterOptions}
        runSequence={props.runSequence}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
      />
    </>
  );
};

CardStepAH.defaultProps = CardStepAHInitial;

export default React.memo(CardStepAH);
