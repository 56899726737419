import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import {
  Form,
  Dimmer,
  Loader,
  Grid,
  TextArea,
  Button,
  Dropdown,
} from "semantic-ui-react";

import ReactTable from "react-table-6";
import PropTypes from "prop-types";
import CardLayout from "../common/CardLayout";
import ErrorMessage from "../common/ErrorMessage";
import DoctorCompleteBox from "../common/DoctorCompleteBox";
import DateFromTo from "../common/DateFromTo";
import _ from "../../compat/lodashplus";
import ModInfoError from "../common/ModInfo";
import ModInfoSuccess from "../common/ModInfo";

// Style
const grayBackground = { backgroundColor: "lightgray" };

const Diagnosis = React.forwardRef((props:any, ref:any) => {
  return (
    <>
      <Form>
        <Form.Field>
          <label>Diagnosis</label>
        </Form.Field>
      </Form>
      <Form loading={props.loading}>
        <Form.Field>
          <TextArea
            placeholder={props.readOnly ? "" : "โปรดระบุ Diganosis"}
            rows={10}
            ref={ref}
            style={props.readOnly ? grayBackground : {}}
            disabled={props.readOnly}
          />
        </Form.Field>
      </Form>
    </>
  );
});

const DoctorOpinion = React.forwardRef((props:any, ref:any) => {
  return (
    <Form.Field>
      <label>ความเห็นแพทย์</label>
      <TextArea
        placeholder={props.readOnly ? "" : "โปรดระบุ ความเห็นแพทย์"}
        rows={10}
        ref={ref}
        style={props.readOnly ? grayBackground : {}}
        disabled={props.readOnly}
      />
    </Form.Field>
  );
});

const DoctorCreated = React.forwardRef((props:any, ref:any) => {
  console.log("test",props.defaultDoctor,props.controller)
  return (
    <Form.Group inline>
      <Form.Field>
        <label>แพทย์ที่แสดงในใบรับรอง</label>
      </Form.Field>
      <Form.Field width={16}>
        <DoctorCompleteBox
          defaultdoctor={props.defaultDoctor}
          controller={props.controller}
          ref={ref}
          isDoctorOptions={true}
        />
      </Form.Field>
    </Form.Group>
  );
});

const DocumentType = (props:any) => {
  return (
    <>
      <label>ประเภทเอกสาร</label>
      <Form.Field width={16}>
        <Dropdown
          placeholder="เลือกประเภทเอกสารใบรับรองแพทย์"
          fluid
          selection
          search
          style={{ width: 0, minWidth: "100%" }}
          options={props.typeList instanceof Array ? props.typeList : []}
          onChange={props.selectedDocumentType}
        />
      </Form.Field>
    </>
  );
};

const LanguageSelect = React.forwardRef((props:any, ref:any) => {
  const [value, setValue] = useState("");
  useImperativeHandle(ref, () => ({
    getLang() {
      return value;
    },
  }));

  return (
    <>
      <label>ภาษา</label>
      <Form.Field width={16} style={{ minWidth: "8rem" }}>
        <Dropdown
          placeholder="โปรดเลือกภาษาเอกสาร"
          fluid
          selection
          value={value}
          style={{ width:0, minWidth: "100%" }}
          onChange={(e, data) => {
            setValue(data.value);
          }}
          options={props.options}
        />
      </Form.Field>
    </>
  );
});

const DoctorSearch = React.forwardRef((props:any, ref:any) => {
  return (
    <Form.Group inline className="rightAlign">
      <Form.Field>
        <label>แพทย์</label>
      </Form.Field>
      <Form.Field width={14}>
        <DoctorCompleteBox 
          controller={props.controller} 
          ref={ref}
          isDoctorOptions={true} 
        />
      </Form.Field>
      <Form.Field>
        <Button
          color="blue"
          onClick={props.loading ? null : props.onSearch}
          loading={props.loading}
        >
          ค้นหา
        </Button>
      </Form.Field>
    </Form.Group>
  );
});

const MedCertTable = React.forwardRef((props, ref) => {
  const [selectedRow, setSelectedRow] = useState(null);

  useImperativeHandle(ref, () => ({
    unSelecteTable() {
      setSelectedRow(null);
      return;
    },
  }));

  const getColumnWidth = (rows:any, accessor:any, headerText:any) => {
    const maxWidth = 400;
    const magicSpacing = 8;
    const cellLength = Math.max(
      ...rows.map((row: any) => (`${row[accessor]}` || "").length),
      headerText.length
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  };

  const columns = [
    {
      Header: "วันที่-เวลาที่พิมพ์",
      accessor: "date_time",
      headerStyle: { whiteSpace: "unset" },
      style: { whiteSpace: "unset" },
      minWidth: 150,
    },
    {
      Header: "รหัส",
      accessor: "code",
      headerStyle: { whiteSpace: "unset" },
      style: { whiteSpace: "unset" },
      minWidth: 100,
    },
    {
      Header: "ประเภทเอกสาร",
      accessor: "type_name",
      headerStyle: { whiteSpace: "unset" },
      style: { whiteSpace: "unset" },
      minWidth: 200,
    },
    {
      Header: "ภาษา",
      accessor: "language",
      headerStyle: { whiteSpace: "unset" },
      style: { whiteSpace: "unset" },
      width: 50,
    },
    {
      Header: "ผู้พิมพ์",
      accessor: "print_user",
      headerStyle: { whiteSpace: "unset" },
      style: { whiteSpace: "unset" },
      width: getColumnWidth(props.data || [], "print_user", "ผู้พิมพ์"),
    },
    {
      Header: "สำเนา",
      accessor: "duplicate",
      headerStyle: { whiteSpace: "unset" },
      style: { whiteSpace: "unset" },
      width: 70,
    },
  ];

  return (
    <>
      <Form>
        <Dimmer active={props.loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <ReactTable
          columns={columns}
          showPagination={false}
          data={props.data || []}
          minRows={10}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  setSelectedRow(rowInfo.index);
                  props.selectedOldMedCert(rowInfo);
                },
                className:
                  rowInfo.index === selectedRow ? "blueSelectedRow" : "",
              };
            } else {
              return {};
            }
          }}
          getNoDataProps={() => {
            return { style: { display: "none" } };
          }}
          style={
            props.data && props.data.length && props.data.length > 14
              ? {
                height: "500px",
              }
              : null
          }
        />
      </Form>
    </>
  );
});

const CardMedCert = (props:any) => {
  const isMounted = useRef(true);
  const diagnosisRef = useRef();
  const doctorOpinionRef = useRef();
  const dateFormToRef = useRef();
  const doctorCreateRef = useRef();
  const doctorSearchRef = useRef();
  const languageSelectedRef = useRef();
  const medCertTableRef = useRef();

  //Loading
  const [isDiagnosLoading, setIsDiagnosLoading] = useState(false);
  const [isMedCertTableLoading, setIsMedCertTableLoading] = useState(false);
  const [isDoctorSearchLoading, setIsDoctorSearchLoading] = useState(false);
  const [printLoading, setPringLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);

  const [doctorCertPrintLog, setDoctorCertPrintLog] = useState([]);
  const [currentDoctor, setCurrentDoctor] = useState({});
  const [doctorCertTypeList, setDoctorCertTypeList] = useState({});
  const [doctorCertList, setDoctorCertList] = useState({});
  const [langunageOptions, setLanguageOptions] = useState([]);

  const [errors, setError] = React.useState(null);

  const [alertText, setAlertText] = React.useState("");
  const [openModInfoError, setOpenModInfoError] = React.useState(false);
  const [openModInfoSuccess, setOpenModInfoSuccess] = React.useState(false);

  const [selectedDocType, setSelectedDocType] = useState({});
  const [cardMedCertReadOnly, setCardMedCertReadOnly] = useState(false);
  const [selectedCertMed, setSelectedCertMed] = useState({});


  useEffect(() => {
    setIsDiagnosLoading(true);
    setIsMedCertTableLoading(true);

    const getCurrentDoctor = async () => {
      let [
        currentDoctor,
        errorCurrentDoctor,
      ] = await props.controller.getCurrentDoctor();
      // console.log("GetCurrentDoctor Reply",);
      if (isMounted.current) {
        setCurrentDoctor(currentDoctor);
      }
    };

    const getDoctorCertTypeList = async () => {
      let [
        doctorCertificateType,
        errordoctorCertificateType,
      ] = await props.controller.getDoctorCertificateType();
      // console.log("getDoctorCertificateType Reply");
      if (isMounted.current) {
        setDoctorCertTypeList(doctorCertificateType?.map((item: any) => ({ key: item.id, text: item.name, value: item.name})));
        setDoctorCertList(doctorCertificateType)
      }
    };

    const getDoctorCerPrintLog = async () => {
      let [
        doctorCertPrintlog,
        errorDoctorCertPrintLog,
      ] = await props.controller.getDoctorCertificatesPrintlog({
        startDate: dateFormToRef.current.getStartDate(),
        endDate: dateFormToRef.current.getEndDate(),
        patient: props.patientData?.patient_id,
      });
      // console.log("getDoctorCertificatesPrintlog Reply");
      if (isMounted.current) {
        setDoctorCertPrintLog(doctorCertPrintlog);
        setIsMedCertTableLoading(false);
      }
    };

    const getDiagnosisSummary = async () => {
      let [
        diagnosisSummary,
        errorDiagnosisSummary,
      ] = await props.controller.getDiagnosisSummary({
        emrId: props.patientData?.EMR?.emr_id,
      });
      // console.log("getDiagnosisSummary Reply");
      if (diagnosisSummary && diagnosisSummary.hasOwnProperty("diagnosis")) {
        if (isMounted.current) {
          diagnosisRef.current.ref.current.value = diagnosisSummary.diagnosis;
          setIsDiagnosLoading(false);
        }
      }
    };

    let a = getCurrentDoctor();
    let b = getDoctorCertTypeList();
    let c = getDoctorCerPrintLog();
    let d = getDiagnosisSummary();

    Promise.all([a, b, c, d]).then(() => {
      // console.log("Promise All Done 4");
      if (isMounted.current) {
        props.finishLoading();
      }
    });

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSelectDocumentType = async (e:any, selected:any) => {
    let b = [];
    if (
      selected &&
      selected.value &&
      doctorCertTypeList &&
      doctorCertTypeList.length > 0
    ) {
      let list = doctorCertList;
      b = _.find(list, (e:any) => {
        return e.name === selected.value;
      });
      let c = _.map(b.language_list, (e) => {
        let d = _.set(e, "value", e.name);
        let f = _.mapKeys(d, function (value, key) {
          if (key === "id") {
            return "key";
          } else if (key === "name") {
            return "text";
          } else {
            return key;
          }
        });
        return f;
      });
      setSelectedDocType(b);
      setLanguageOptions(c);
    }
  };

  const handleSaveAndPrint = (pdf:any) => async () => {
    if (pdf) {
      setPreviewLoading(true);
    } else {
      setPringLoading(true);
    }

    let params = {};
    let selectedDoctor = doctorCreateRef.current.getSelectedDoctor()

    if (selectedDoctor) {
      // selectedDoctor.id form apis/core/get-current-doctor
      // selectedDoctor.key form searchDoctorName
      var doctorId = selectedDoctor.id || selectedDoctor.key
    } else {
      // doctorId = -1
      setAlertText("กรุณาเลือก แพทย์ที่แสดงในใบรับรอง อีกครั้ง")
      setOpenModInfoError(true);
      setPreviewLoading(false);
      return
    }
    if (!cardMedCertReadOnly) {
      params = {
        id: "",
        emr: props.patientData.EMR.emr_id,
        pdf: pdf,
        pdf_b64data: "",
        original: true,
        detail: doctorOpinionRef.current.ref.current.value, // semantic ref
        type: selectedDocType.id,
        language: languageSelectedRef.current.getLang(),
        diagnosis: diagnosisRef.current.ref.current.value, // semantic ref
        doctor: doctorId
      };
    } else {
      params = {
        id: selectedCertMed.object_id,
        emr: selectedCertMed.emr,
        pdf: pdf,
        pdf_b64data: "",
        original: false,
        detail: selectedCertMed.detail,
        type: selectedCertMed.type,
        language: selectedCertMed.language,
        diagnosis: selectedCertMed.diagnosis,
        doctor: doctorId
      };
    }

    const [data, error, network] = await props.controller.saveAndPrintMedCert(
      params
    );

    if (pdf) {
      setPreviewLoading(false);
    } else {
      setPringLoading(false);
    }

    if (error) {
      console.log("error", error);
      console.log("network.response.status", network);
      // TODO: ModInfoError need display only necessary detail
      setError(error);
      // setAlertText(error);
      setAlertText("")
      setOpenModInfoError(true);
      return;
    }

    if (data && data.pdf_b64data && pdf) {
      var pdfWindow = window.open("_blank");
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        data.pdf_b64data +
        "'></iframe>"
      );
      return;
    }

    const getDoctorCerPrintLog = async () => {
      const [data, error, network] = await props.controller.saveAndPrintMedCert(
        {...params, pdf: true}
      );

      if (data && data.pdf_b64data) {
        var pdfWindow = window.open("_blank");
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          data.pdf_b64data +
          "'></iframe>"
        );
      }

      let [
        doctorCertPrintlog,
        errorDoctorCertPrintLog,
      ] = await props.controller.getDoctorCertificatesPrintlog({
        startDate: dateFormToRef.current.getStartDate(),
        endDate: dateFormToRef.current.getEndDate(),
        patient: props.patientData.patient_id,
      });
      if (doctorCertPrintlog) {
        setDoctorCertPrintLog(doctorCertPrintlog);
        setIsMedCertTableLoading(false);
      } else {
        setIsMedCertTableLoading(false);
      }
    };

    if (!pdf) {
      setIsMedCertTableLoading(true);
      getDoctorCerPrintLog();
      setOpenModInfoSuccess(true);
    }
  };

  const handleSearchMedCerts = async () => {
    setIsDoctorSearchLoading(true);

    let params = {
      patient: props.patientData.patient_id,
      startDate: "",
      endDate: "",
    };

    let startDate = dateFormToRef.current.getStartDate();
    if (startDate) {
      params.startDate = startDate;
    }

    let endDate = dateFormToRef.current.getEndDate();
    if (endDate) {
      params.endDate = endDate;
    }

    let doctor = doctorSearchRef.current.getSelectedDoctor();
    if (doctor && doctor.id) {
      params.doctorId = doctor.id;
    }

    let [
      doctorCertPrintlog,
      errorDoctorCertPrintLog,
    ] = await props.controller.getDoctorCertificatesPrintlog(params);

    if (isMounted.current) {
      if (errorDoctorCertPrintLog) {
        setAlertText(errorDoctorCertPrintLog);
        setOpenModInfoError(true);
        return;
      }
      setDoctorCertPrintLog(doctorCertPrintlog);
      setIsDoctorSearchLoading(false);
    }
  };

  const handleSelectedOldMedCert = (info:any) => {
    let original = info.original;
    diagnosisRef.current.ref.current.value = original.diagnosis;
    doctorOpinionRef.current.ref.current.value = original.detail;
    setCardMedCertReadOnly(true);
    setSelectedCertMed(info.original);
  };

  const handleCreateNewMedCert = async () => {
    //TODO: Check with Front-End need ICD10 or clear
    //      diagnosis when CreateNewMedCert from Read History MedCert State

    // let [
    //   diagnosisSummary,
    //   errorDiagnosisSummary
    // ] = await props.controller.getDiagnosisSummary({
    //   emrId: props.patientData.EMR.emr_id
    // });
    // if (diagnosisSummary && diagnosisSummary.hasOwnProperty('diagnosis')) {
    //   diagnosisRef.current.ref.current.value = diagnosisSummary.diagnosis;
    // }

    doctorOpinionRef.current.ref.current.value = "";
    diagnosisRef.current.ref.current.value = "";
    medCertTableRef.current.unSelecteTable();
    setCardMedCertReadOnly(false);
  };

  return (
    // TODO: If not QML , in cnmicro: need remove .hidden: display:none !important out in common.css ,
    // and replace <br /> to <Divider hidden/>

    <CardLayout
      titleText="ใบรับรองแพทย์"
      closeable={props.closeable}
      toggleable={props.toggleable}
      hideHeaderIcon={props.hideHeaderIcon}
      defaultBackground={props.defaultBackground}
      hideTopRightText={false}
      headerColor="brown"
      onClose={props.onClose}
    >
      <ErrorMessage error={errors} />

      <ModInfoError
        titleColor="red"
        titleName="เกิดข้อผิดพลาด !!"
        btnText="ตกลง"
        size="small"
        open={openModInfoError}
        alertText={alertText}
        onApprove={() => setOpenModInfoError(false)}
      />
      <ModInfoSuccess
        titleColor="green"
        titleName={cardMedCertReadOnly ? "พิมพ์รายการสำเร็จ" : "บันทึกสำเร็จ"}
        btnText="ตกลง"
        open={openModInfoSuccess}
        onApprove={() => setOpenModInfoSuccess(false)}
      />

      <Grid>
        <Grid.Row>
          <Grid.Column width={7}>
            <Form>
              <ErrorMessage />
              <br />
            </Form>

            <Diagnosis
              loading={isDiagnosLoading}
              ref={diagnosisRef}
              readOnly={cardMedCertReadOnly || props.disableEditDiagnosis}
            />
            <br />

            <Form>
              <DoctorOpinion
                ref={doctorOpinionRef}
                readOnly={cardMedCertReadOnly || props.disableEditDiagnosis}
              />
              <br />

              <DoctorCreated
                ref={doctorCreateRef}
                controller={props.controller}
                defaultDoctor={currentDoctor}
                readOnly={cardMedCertReadOnly}
              />
              <br />
            </Form>
          </Grid.Column>
          <Grid.Column textAlign={"left"} width={9}>
            <Form>
              <DateFromTo className="rightAlign" ref={dateFormToRef} />
              <DoctorSearch
                loading={isDoctorSearchLoading}
                ref={doctorSearchRef}
                controller={props.controller}
                onSearch={handleSearchMedCerts}
              />
              <br />
            </Form>
            <MedCertTable
              loading={isMedCertTableLoading || isDoctorSearchLoading}
              ref={medCertTableRef}
              data={doctorCertPrintLog}
              selectedOldMedCert={handleSelectedOldMedCert}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Form>
              <Form.Group inline>
                <Form.Field width={7}>
                  {!cardMedCertReadOnly && (
                    <DocumentType
                      typeList={doctorCertTypeList}
                      selectedDocumentType={handleSelectDocumentType}

                    />
                  )}
                </Form.Field>
                <Form.Field width={4} style={{ padding: 0 }}>
                  {!cardMedCertReadOnly && (
                    <LanguageSelect
                      options={langunageOptions}
                      ref={languageSelectedRef}
                    />
                  )}
                  {cardMedCertReadOnly && (
                    <Button color="blue" fluid onClick={handleCreateNewMedCert}>
                      สร้างใบรับรองใหม่
                    </Button>
                  )}
                </Form.Field>

                <Form.Field style={{ flex: 1 }}/>

                <Form.Field style={{ display: "flex", padding: 0 }}>
                  <Button
                    color="green"
                    fluid
                    loading={printLoading}
                    style={{ minWidth: "max-content" }}
                    onClick={printLoading ? null : handleSaveAndPrint(false)}
                  >
                    {!cardMedCertReadOnly ? "บันทึกและพิมพ์" : "พิมพ์สำเนา"}
                  </Button>

                  <Button
                    color="yellow"
                    fluid
                    loading={previewLoading}
                    onClick={previewLoading ? null : handleSaveAndPrint(true)}
                  >
                    Preview
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </CardLayout>
  );
};

CardMedCert.defaultProps = {
  closeable: true,
  toggleable: false,
  hideHeaderIcon: false,
  defaultBackground: true,

  // finishLoading: null,
  controller: null,

  patientData: null,
  disableEditDiagnosis: false,
  onClose: () => { },
  finishLoading: () => { },
};

CardMedCert.propTypes = {
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  defaultBackground: PropTypes.bool,

  finishLoading: PropTypes.func,
  controller: PropTypes.object,

  patientData: PropTypes.object,
  disableEditDiagnosis: PropTypes.bool,
  onClose: PropTypes.func,
};

export default React.memo(CardMedCert);
