import React from "react";
import { Form } from "semantic-ui-react";

const CHECK_PARSE: Record<string, boolean> = {
  True: true,
  False: false,
};

const SubQAType: React.FC<any> = (props) => {
  const mapOptions = (list: any[]) => {
    return list.map((item: any) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
  };

  return (
    <>
      {props.type === "CHOICE" ? (
        <Form.Dropdown
          value={
            typeof props.choices?.[0]?.id === "number"
              ? Number(props.answer)
              : props.answer
          }
          label={props.label}
          placeholder="กรุณาระบุ"
          options={mapOptions(props.choices)}
          selection
          style={{ width: "auto" }}
          name={props.dataValidateKey}
          onChange={props.onChange}
          disabled={props.statusRequest ? true : false}
        />
      ) : props.type === "BOOLEAN" ? (
        <Form.Checkbox
          checked={CHECK_PARSE[props.answer]}
          label={props.label}
          name={props.dataValidateKey}
          onChange={props.onChange}
          readOnly={props.statusRequest ? true : false}
        />
      ) : props.type === "DECIMAL" ? (
        <Form.Input
          value={props.answer || ""}
          type="number"
          name={props.dataValidateKey}
          onChange={props.onChange}
          readOnly={props.statusRequest ? true : false}
        />
      ) : props.type === "TEXT" ? (
        <Form.Input
          value={props.answer || ""}
          name={props.dataValidateKey}
          onChange={props.onChange}
          readOnly={props.statusRequest ? true : false}
        />
      ) : null}
    </>
  );
};

export default SubQAType;
