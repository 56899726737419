import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button,
  Input,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardBillingHistoryArUX = (props: any) => {
    return(
      <div
        style={{ width: "100%", padding: "10px" }}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem", display: "flex", justifyContent: "space-between", }}>
          ประวัติการรับชำระเงินจากจากหน่วยงานต้นสังกัด
        </div>
        <div>
          
          <hr>
          </hr>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{ display: "flex", alignItems: "center" }}>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ width: "100%", }}>
                  
                  <Checkbox
                    checked={props.searchHistory?.checkedPayer}
                    label="หน่วยงานต้นสังกัด"
                    name="checkedPayer"
                    onChange={props.onChangeSearch}
                    style={{ fontWeight: "bold" }}>
                  </Checkbox>
                </div>
                <div
                  style={{ width: "100%", }}>
                  
                  <div>
                    {props.searchBoxPayer}
                  </div>
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ width: "100%", }}>
                  
                  <Checkbox
                    checked={props.searchHistory?.checkedStatus}
                    label="สถานะใบเสร็จ"
                    name="checkedStatus"
                    onChange={props.onChangeSearch}
                    style={{ fontWeight: "bold" }}>
                  </Checkbox>
                </div>
                <div
                  style={{ width: "100%", }}>
                  
                  <Dropdown
                    fluid={true}
                    name="status"
                    onChange={props.onChangeSearch}
                    options={props.bilStatusOptions}
                    selection={true}
                    value={props.searchHistory?.status}>
                  </Dropdown>
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ width: "90%", }}>
                  
                  <Checkbox
                    checked={props.searchHistory?.checkedDate}
                    label="วันที่ออกเอกสาร"
                    name="checkedDate"
                    onChange={props.onChangeSearch}
                    style={{ fontWeight: "bold" }}>
                  </Checkbox>
                </div>
                <div
                  style={{ width: "100%", }}>
                  
                  <DateTextBox
                    onChange={props.onChangeStartDate}
                    style={{ width: "100%" }}
                    value={props.searchHistory?.startDate}>
                  </DateTextBox>
                </div>
                <div
                  style={{ width: "15%", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 20px"  }}>
                  ถึง
                </div>
                <div
                  style={{ width: "100%", }}>
                  
                  <DateTextBox
                    onChange={props.onChangeEndDate}
                    style={{ width: "100%" }}
                    value={props.searchHistory?.endDate}>
                  </DateTextBox>
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div>
                  {props.buttonSearch}
                </div>
              </div>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{ padding: "0px 10px 10px" }}>
          
          <Table
            data={props.arReceiptList}
            getTrProps={props.selectARReceipt}
            headers="รหัสเอกสาร,หน่วยงานต้นสังกัด,เลขที่ใบแจ้งหนี้,วันที่-เวลา,ยอดเงิน,สถานะใบเสร็จ,ผู้ออกใบเสร็จ"
            keys="code_formatted,payer_name,ar_transaction_lot_no,created_at_dt,price,status_label,created_by"
            minRows="8"
            showPagination={false}
            style={{ height: "450px" }}>
          </Table>
        </div>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem", display: "flex", justifyContent: "space-between", }}>
          รายละเอียดรับชำระ
        </div>
        <div>
          
          <hr>
          </hr>
        </div>
        <div
          style={{ padding: "10px 0px", ...(props.orderBy !== "Mode" && { display: "none" }) }}>
          
          <Table
            data={props.arModeData}
            headers="รหัส,หมวดค่าใช้จ่าย,ส่งเบิก,รับชำระ"
            keys="bill_mode_code,bill_mode_name,total_send_claim_price,total_paid_price"
            minRows={10}
            showPagination={false}
            style={{ height: "350px" }}
            widths="^30,^150,^60,^60">
          </Table>
        </div>
        <div
          style={{ padding: "10px 0px", ...(props.orderBy !== "Item" && { display: "none" }) }}>
          
          <Table
            data={props.arItemData}
            headers="CODE,PRODUCT,MODE,จำนวน,ส่งเบิก,รับชำระ"
            keys="product_code,product_name,bill_mode_name,quantity,total_send_claim_price,total_paid_price"
            minRows="8"
            showPagination={false}
            style={{ height: "350px" }}>
          </Table>
        </div>
        <div
          style={{ display: "flex", margin: "10px 0px" }}>
          
          <div
            style={{ display: "flex" }}>
            
            <Button
              className={props.orderBy === "Mode" && "active"}
              color="teal"
              id="btn-billMode"
              name="Mode"
              onClick={props.onChangeOrderBy}
              size="mini">
              BY MODE
            </Button>
            <Button
              className={props.orderBy === "Item" && "active"}
              color="teal"
              id="btn-billItem"
              name="Item"
              onClick={props.onChangeOrderBy}
              size="mini">
              BY ITEM
            </Button>
          </div>
          <div
            style={{ flex: 1 }}>
            
          </div>
          <div
            style={{ display: "flex" }}>
            
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}>
              
              <div
                style={{ margin: "0px 10px", width: "55%" }}>
                ส่งเบิก
              </div>
              <Input
                readOnly="true"
                size="mini"
                style={{ width: "62%" }}
                value={props.invoiceTotalSendClaimPrice}>
              </Input>
            </div>
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}>
              
              <div
                style={{ margin: "0px 10px", width: "55%" }}>
                รับชำระ
              </div>
              <Input
                readOnly="true"
                size="mini"
                style={{ width: "62%" }}
                value={props.invoiceTotalRemainPrice}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", margin: "15px 0px" }}>
          
          <div
            style={{ display: "flex", margin: "0px 15px", width: "450px" }}>
            
            <div
              style={{ display: "flex", alignItems: "center", fontWeight: "bold", width: "70%"  }}>
              ชื่อหน่วยงานบนใบเสร็จ
            </div>
            <div
              style={{ width: "100%" }}>
              {props.searchBoxPayerReceipt}
            </div>
          </div>
          <div>
            {props.printARReceiptCopy}
          </div>
          <div>
            {props.printPaymentARReport}
          </div>
          <Button
            color="red"
            disabled={props.disabledButton}
            onClick={props.cancelReceipt}
            size="small"
            style={props.buttonCancelStyle}>
            ยกเลิกใบเสร็จ
          </Button>
        </div>
      </div>
    )
}


export default CardBillingHistoryArUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Fri May 17 2024 01:28:47 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการรับชำระเงินจากจากหน่วยงานต้นสังกัด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\", display: \"flex\", justifyContent: \"space-between\", }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "hr",
      "parent": 128,
      "props": {
      },
      "seq": 129,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormGroup",
      "parent": 226,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px 0px\", ...(props.orderBy !== \"Mode\" && { display: \"none\" }) }"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"10px 0px\" }"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 276,
      "name": "Table",
      "parent": 274,
      "props": {
        "data": {
          "type": "code",
          "value": "props.arModeData"
        },
        "headers": {
          "type": "value",
          "value": "รหัส,หมวดค่าใช้จ่าย,ส่งเบิก,รับชำระ"
        },
        "keys": {
          "type": "value",
          "value": "bill_mode_code,bill_mode_name,total_send_claim_price,total_paid_price"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"350px\" }"
        },
        "widths": {
          "type": "value",
          "value": "^30,^150,^60,^60"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Button",
      "parent": 277,
      "props": {
        "children": {
          "type": "value",
          "value": "BY MODE"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Mode\" && \"active\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billMode"
        },
        "name": {
          "type": "value",
          "value": "Mode"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "Button",
      "parent": 277,
      "props": {
        "children": {
          "type": "value",
          "value": "BY ITEM"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Item\" && \"active\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billItem"
        },
        "name": {
          "type": "value",
          "value": "Item"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": null,
      "id": 285,
      "name": "div",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "div",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "div",
      "parent": 285,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\", width: \"55%\" }"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 289,
      "name": "div",
      "parent": 286,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\", width: \"55%\" }"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "Input",
      "parent": 285,
      "props": {
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"62%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceTotalSendClaimPrice"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "Input",
      "parent": 286,
      "props": {
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"62%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceTotalRemainPrice"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": null,
      "id": 294,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1 }"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px 0px\", ...(props.orderBy !== \"Item\" && { display: \"none\" }) }"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 300,
      "name": "Table",
      "parent": 298,
      "props": {
        "data": {
          "type": "code",
          "value": "props.arItemData"
        },
        "headers": {
          "type": "value",
          "value": "CODE,PRODUCT,MODE,จำนวน,ส่งเบิก,รับชำระ"
        },
        "keys": {
          "type": "value",
          "value": "product_code,product_name,bill_mode_name,quantity,total_send_claim_price,total_paid_price"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"350px\" }"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 301,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดรับชำระ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\", display: \"flex\", justifyContent: \"space-between\", }"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 302,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 303,
      "name": "hr",
      "parent": 302,
      "props": {
      },
      "seq": 303,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 338,
      "void": false
    },
    {
      "from": null,
      "id": 306,
      "name": "div",
      "parent": 305,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": null,
      "id": 307,
      "name": "div",
      "parent": 304,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": null,
      "id": 308,
      "name": "div",
      "parent": 241,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", }"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 309,
      "name": "Checkbox",
      "parent": 308,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.searchHistory?.checkedPayer"
        },
        "label": {
          "type": "value",
          "value": "หน่วยงานต้นสังกัด"
        },
        "name": {
          "type": "value",
          "value": "checkedPayer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearch"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": null,
      "id": 310,
      "name": "div",
      "parent": 241,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", }"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 312,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": null,
      "id": 313,
      "name": "div",
      "parent": 312,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": null,
      "id": 315,
      "name": "div",
      "parent": 313,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", }"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": null,
      "id": 316,
      "name": "div",
      "parent": 313,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", }"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 317,
      "name": "Checkbox",
      "parent": 315,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.searchHistory?.checkedStatus"
        },
        "label": {
          "type": "value",
          "value": "สถานะใบเสร็จ"
        },
        "name": {
          "type": "value",
          "value": "checkedStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearch"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "Dropdown",
      "parent": 316,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearch"
        },
        "options": {
          "type": "code",
          "value": "props.bilStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.searchHistory?.status"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": null,
      "id": 319,
      "name": "div",
      "parent": 307,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", }"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": null,
      "id": 320,
      "name": "div",
      "parent": 307,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", }"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": null,
      "id": 321,
      "name": "div",
      "parent": 307,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"15%\", fontWeight: \"bold\", display: \"flex\", justifyContent: \"center\", margin: \"0px 20px\"  }"
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": null,
      "id": 322,
      "name": "div",
      "parent": 307,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", }"
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 323,
      "name": "Checkbox",
      "parent": 319,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.searchHistory?.checkedDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่ออกเอกสาร"
        },
        "name": {
          "type": "value",
          "value": "checkedDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearch"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 324,
      "name": "DateTextBox",
      "parent": 320,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.searchHistory?.startDate"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 325,
      "name": "DateTextBox",
      "parent": 322,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.searchHistory?.endDate"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", margin: \"15px 0px\" }"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": null,
      "id": 328,
      "name": "div",
      "parent": 327,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"0px 15px\", width: \"450px\" }"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 331,
      "name": "Button",
      "parent": 327,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกใบเสร็จ"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledButton"
        },
        "onClick": {
          "type": "code",
          "value": "props.cancelReceipt"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "props.buttonCancelStyle"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": null,
      "id": 332,
      "name": "div",
      "parent": 328,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อหน่วยงานบนใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", fontWeight: \"bold\", width: \"70%\"  }"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": null,
      "id": 334,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px 10px\" }"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 335,
      "name": "Table",
      "parent": 334,
      "props": {
        "data": {
          "type": "code",
          "value": "props.arReceiptList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.selectARReceipt"
        },
        "headers": {
          "type": "value",
          "value": "รหัสเอกสาร,หน่วยงานต้นสังกัด,เลขที่ใบแจ้งหนี้,วันที่-เวลา,ยอดเงิน,สถานะใบเสร็จ,ผู้ออกใบเสร็จ"
        },
        "keys": {
          "type": "value",
          "value": "code_formatted,payer_name,ar_transaction_lot_no,created_at_dt,price,status_label,created_by"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"450px\" }"
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 337,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 338,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 337,
      "void": false
    },
    {
      "from": null,
      "id": 339,
      "name": "div",
      "parent": 310,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBoxPayer"
        }
      },
      "seq": 339,
      "void": false
    },
    {
      "from": null,
      "id": 340,
      "name": "div",
      "parent": 306,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": null,
      "id": 341,
      "name": "div",
      "parent": 328,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBoxPayerReceipt"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": null,
      "id": 342,
      "name": "div",
      "parent": 327,
      "props": {
        "children": {
          "type": "code",
          "value": "props.printARReceiptCopy"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": null,
      "id": 343,
      "name": "div",
      "parent": 327,
      "props": {
        "children": {
          "type": "code",
          "value": "props.printPaymentARReport"
        }
      },
      "seq": 342,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingHistoryArUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 55
}

*********************************************************************************** */
