import { TDocumentDefinitions } from "pdfmake/interfaces";

const COLORS = {
    greyHeader: "#D9D9D9",
    greySummary: "#E8E8E8",
    greyBorder: "#9F9F9F",
    black: "#000000",
  };

  const SvgCheckbox = (check?: boolean) => {
    return `<svg width="12" height="12">
    <rect x="1" y="5" width="12" height="12" style="fill:transparent;stroke:${
      COLORS.black
    };stroke-width:1.5;fill-opacity:1;stroke-opacity:0.75" />
    ${
      check &&
      `<polygon style="fill:${COLORS.black};stroke-width:1.5;stroke:${COLORS.black};transform:scale(0.075) translate(10 20)" points="191.268,26.967 59.541,158.683 5.615,104.76 0,110.386 59.541,169.92 196.887,32.585"/>`
    }
    </svg>`;
  };

  type FormARremittanceReportProps = {
    params?: any;
    fields?: any;
    date?: any;
    report_name?: string;
  };

  const FormARremittanceReport = (props: FormARremittanceReportProps): Promise<TDocumentDefinitions> => {
    let fields = props.fields?.[0];
    let fieldsReceipt =
      fields?.receipt_paid_list?.length > fields?.receipt_canceled_list?.length
        ? fields?.receipt_paid_list
        : fields?.receipt_canceled_list;

    const fileData = (fieldsReceipt || []).map((item: any, index: number) => {
      let rangesPaid = "";
      let rangesPaidData = (fields?.receipt_paid_list?.[index]?.ranges || [])?.map(
        (data: any, number: number) => {
          return `${rangesPaid} ${data}`;
        }
      );

      let rangesCancel = "";
      let rangesCancelData = (fields?.receipt_canceled_list?.[index]?.ranges || [])?.map(
        (data: any, number: number) => {
          return `${rangesCancel} ${data}`;
        }
      );

      return [
        {
          text: [
            {
              text: `${fields?.receipt_paid_list?.[index]?.prefix || ""}`,
              decoration: "underline",
            },
            {
              text: fields?.receipt_paid_list?.[index]?.prefix ? ` / ${rangesPaidData || ""}` : "",
            },
          ],
          border: [true, false, true, false],
        },
        {
          text: `${fields?.receipt_paid_list?.[index]?.quantity || ""}`,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: `${fields?.receipt_paid_list?.[index]?.total_price_round_down || ""}`,
          alignment: "right",
          border: [true, false, true, false],
        },
        {
          text: `${fields?.receipt_paid_list?.[index]?.total_price_satang || ""}`,
          alignment: "right",
          border: [true, false, true, false],
        },
        {
          text: [
            {
              text: `${fields?.receipt_canceled_list?.[index]?.prefix || ""}`,
              decoration: "underline",
            },
            {
              text: fields?.receipt_canceled_list?.[index]?.prefix
                ? ` / ${rangesCancelData || ""}`
                : "",
            },
          ],
          border: [true, false, true, false],
        },
        {
          text: `${fields?.receipt_canceled_list?.[index]?.quantity || ""}`,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: `${fields?.receipt_canceled_list?.[index]?.total_price_round_down || ""}`,
          alignment: "right",
          border: [true, false, true, false],
        },
        {
          text: `${fields?.receipt_canceled_list?.[index]?.total_price_satang || ""}`,
          alignment: "right",
          border: [true, false, true, false],
        },
      ];
    });

    let fieldsLength =
      fieldsReceipt?.length > 5 ? fieldsReceipt?.length / 5 : fieldsReceipt?.length;
    let numberRows = 5 - fieldsLength;

    const rowEmpty = Array.from({ length: numberRows }).map((item: any, index: number) => [
      {
        text: " ",
        border: [true, false, true, false],
      },
      {
        text: " ",
        border: [true, false, true, false],
      },
      {
        text: " ",
        border: [true, false, true, false],
      },
      {
        text: " ",
        border: [true, false, true, false],
      },
      {
        text: " ",
        border: [true, false, true, false],
      },
      {
        text: " ",
        border: [true, false, true, false],
      },
      {
        text: " ",
        border: [true, false, true, false],
      },
      {
        text: " ",
        border: [true, false, true, false],
      },
    ]);

    return {
      defaultStyle: {
        font: "THSarabunNew",
        fontSize: 14,
      },
      pageSize: "A4",
      pageMargins: [10, 390, 10, 212],
      header: function () {
        return {
          margin: [5, 13, 5, 0],
          stack: [
            // header
            {
              margin: [19, 0, 19, 0],
              table: {
                widths: [90, "*", 90],
                body: [
                  [
                    { text: "", border: [false, false, false, false] },
                    {
                      stack: [
                        {
                          stack: [
                            {
                              text: `\n`,
                            },
                            {
                              text: `${props?.report_name} \n`,
                              alignment: "center",
                              style: {
                                fontSize: 20,
                              },
                            },
                          ],
                        },
                        {
                          margin: [0, 10, 0, 0],
                          text: [
                            {
                              text: "ส่วนราชการ ",
                            },
                            {
                              text: "โรงพยาบาลลคณะทันตแพทยศาสตร์  จุฬาลงกรณ์มหาวิทยาลัย",
                              style: {
                                bold: true,
                              },
                            },
                          ],
                        },
                        {
                          text: `ที่ทำการ ${props.params?.station_name || ""}`,
                        },
                        {
                          text: `วันที่ ${props.params?.date_with_year_prefix || ""}`,
                        },
                      ],
                      border: [false, false, false, false],
                    },
                    {
                      stack: [
                        {
                          text: `เลขที่ผู้นำส่ง ${props.params?.document_no || ""}`,
                        },
                        {
                          text: "เลขที่ผู้รับ  ......................",
                        },
                      ],
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
            },
            { text: "" },
            // receipt detail
            {
              margin: [19, 0, 19, 0],
              table: {
                widths: [200, 130, "*"],
                body: [
                  [
                    {
                      text: `ข้าพเจ้า ${props.params?.closed_user_name || ""}`,
                      border: [false, false, false, false],
                    },
                    {
                      text: `ตำแหน่ง ${props.params?.closed_user_position || ""}`,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    { text: "", border: [false, false, false, false] },
                  ],
                  [
                    {
                      columns: [
                        {
                          text: "ขอนำส่ง ",
                          width: 120,
                        },
                        {
                          stack: [
                            {
                              columns: [
                                {
                                  svg: SvgCheckbox(
                                    Number(props.params?.sum_payment_cash_price) !== 0.0
                                  ),
                                  width: 19,
                                },
                                { text: "เงินสด" },
                              ],
                            },
                            {
                              columns: [
                                {
                                  svg: SvgCheckbox(
                                    Number(props.params?.sum_payment_cheque_price) !== 0.0
                                  ),
                                  width: 19,
                                },
                                { text: "เช็ค" },
                              ],
                            },
                            {
                              columns: [
                                {
                                  svg: SvgCheckbox(
                                    Number(props.params?.sum_payment_credit_card_price) !== 0.0
                                  ),
                                  width: 19,
                                },
                                { text: "บัตรเครดิต" },
                              ],
                            },
                            {
                              columns: [
                                {
                                  svg: SvgCheckbox(
                                    Number(props.params?.sum_payment_transfer_price) !== 0.0
                                  ),
                                  width: 19,
                                },
                                { text: "เงินโอน" },
                              ],
                            },
                            {
                              columns: [
                                {
                                  svg: SvgCheckbox(
                                    Number(props.params?.sum_payment_qr_price) !== 0.0
                                  ),
                                  width: 19,
                                },
                                { text: "QR Code." },
                              ],
                            },
                          ],
                        },
                      ],
                      border: [false, false, false, false],
                    },
                    {
                      stack: [
                        {
                          text: "จำนวนเงิน",
                          alignment: "right",
                        },
                        {
                          text: "จำนวนเงิน",
                          alignment: "right",
                        },
                        {
                          text: "จำนวนเงิน",
                          alignment: "right",
                        },
                        {
                          text: "จำนวนเงิน",
                          alignment: "right",
                        },
                        {
                          text: "จำนวนเงิน",
                          alignment: "right",
                        },
                      ],
                      border: [false, false, false, false],
                    },
                    {
                      stack: [
                        {
                          columns: [
                            { text: "", alignment: "right", width: 25 },
                            { text: "", alignment: "right", width: 40 },
                            {
                              text: `${
                                Number(props.params?.sum_payment_cash_price) !== 0
                                  ? props.params?.sum_payment_cash_price
                                  : ""
                              }`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                        {
                          columns: [
                            { text: "", alignment: "right", width: 25 },
                            { text: "", alignment: "right", width: 40 },
                            {
                              text: `${
                                Number(props.params?.sum_payment_cheque_price) !== 0
                                  ? props.params?.sum_payment_cheque_price
                                  : ""
                              }`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                        {
                          columns: [
                            { text: "", alignment: "right", width: 25 },
                            { text: "", alignment: "right", width: 40 },
                            {
                              text: `${
                                Number(props.params?.sum_payment_credit_card_price) !== 0
                                  ? props.params?.sum_payment_credit_card_price
                                  : ""
                              }`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                        {
                          columns: [
                            { text: "", alignment: "right", width: 25 },
                            { text: "", alignment: "right", width: 40 },
                            {
                              text: `${
                                Number(props.params?.sum_payment_transfer_price) !== 0
                                  ? props.params?.sum_payment_transfer_price
                                  : ""
                              }`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                        {
                          columns: [
                            { text: "", alignment: "right", width: 25 },
                            { text: "", alignment: "right", width: 40 },
                            {
                              text: `${
                                Number(props.params?.sum_payment_qr_price) !== 0
                                  ? props.params?.sum_payment_qr_price
                                  : ""
                              }`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                      ],
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "ต่อ (กรมหรือหน่วย)  หน่วยการเงิน ",
                      border: [false, false, false, false],
                    },
                    {
                      stack: [
                        {
                          text: "เบิกจ่ายตรง",
                          alignment: "right",
                        },
                        {
                          text: "อปท.",
                          alignment: "right",
                        },
                        {
                          text: "ธนาคารออมสิน",
                          alignment: "right",
                        },
                        {
                          text: "สนง.ทรัพย์สิน ฯ",
                          alignment: "right",
                        },
                        {
                          text: "ธนาคารแห่งประเทศไทย",
                          alignment: "right",
                        },
                        {
                          text: "ครอบครัวธนาคารแห่งประเทศไทย",
                          alignment: "right",
                        },
                      ],
                      border: [false, false, false, false],
                    },
                    {
                      stack: [
                        {
                          columns: [
                            {
                              text: `${props.params?.count_ggo_nhsi}`,
                              alignment: "right",
                              width: 25,
                            },
                            { text: "ราย", alignment: "right", width: 40 },
                            {
                              text: `${props.params?.sum_ggo_nhsi_price}`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                        {
                          columns: [
                            { text: `${props.params?.count_lgo}`, alignment: "right", width: 25 },
                            { text: "ราย", alignment: "right", width: 40 },
                            {
                              text: `${props.params?.sum_lgo_price}`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                        {
                          columns: [
                            { text: `${props.params?.count_gsb}`, alignment: "right", width: 25 },
                            { text: "ราย", alignment: "right", width: 40 },
                            {
                              text: `${props.params?.sum_gsb_price}`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                        {
                          columns: [
                            {
                              text: `${props.params?.count_crown_property}`,
                              alignment: "right",
                              width: 25,
                            },
                            { text: "ราย", alignment: "right", width: 40 },
                            {
                              text: `${props.params?.sum_crown_property_price}`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                        {
                          columns: [
                            { text: `${props.params?.count_bot}`, alignment: "right", width: 25 },
                            { text: "ราย", alignment: "right", width: 40 },
                            {
                              text: `${props.params?.sum_bot_price}`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                        {
                          columns: [
                            {
                              text: `${props.params?.count_bot_family}`,
                              alignment: "right",
                              width: 25,
                            },
                            { text: "ราย", alignment: "right", width: 40 },
                            {
                              text: `${props.params?.sum_bot_family_price}`,
                              alignment: "right",
                              width: 80,
                            },
                            { text: "บาท", alignment: "right", width: 40 },
                          ],
                          alignment: "right",
                        },
                      ],
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "ตามรายการ",
                      border: [false, false, false, false],
                    },
                    {
                      text: "",
                      border: [false, false, false, false],
                    },
                    { text: "", border: [false, false, false, false] },
                  ],
                ],
              },
            },
          ],
        };
      },
      styles: {},
      content: [
        {
          table: {
            widths: ["*", 28, 80, 15, "*", 28, 80, 15],
            headerRows: 2,
            body: [
              [
                {
                  text: "รายการ",
                  alignment: "center",
                  rowSpan: 2,
                  margin: [0, 10, 0, 0],
                },
                {
                  text: "จำนวน",
                  alignment: "center",
                  rowSpan: 2,
                  margin: [0, 10, 0, 0],
                },
                {
                  text: "จำนวนเงิน",
                  alignment: "center",
                  colSpan: 2,
                },
                {},
                {
                  text: "รายการ",
                  alignment: "center",
                  rowSpan: 2,
                  margin: [0, 10, 0, 0],
                },
                {
                  text: "จำนวน",
                  alignment: "center",
                  rowSpan: 2,
                  margin: [0, 10, 0, 0],
                },
                {
                  text: "จำนวนเงิน",
                  alignment: "center",
                  colSpan: 2,
                },
                {},
              ],
              [
                {},
                {},
                {
                  text: "บาท",
                  alignment: "center",
                },
                {
                  text: "สต",
                  alignment: "center",
                },
                {},
                {},
                {
                  text: "บาท",
                  alignment: "center",
                },
                {
                  text: "สต",
                  alignment: "center",
                },
              ],
              [
                {
                  text: "ใบเสร็จรับเงินที่ใช้",
                  decoration: "underline",
                  style: {
                    bold: true,
                  },
                  border: [true, false, true, false],
                },
                { text: "", border: [true, false, true, false] },
                { text: "", border: [true, false, true, false] },
                { text: "", border: [true, false, true, false] },
                {
                  text: "ใบเสร็จรับเงินที่ยกเลิก",
                  decoration: "underline",
                  style: {
                    bold: true,
                  },
                  border: [true, false, true, false],
                },
                { text: "", border: [true, false, true, false] },
                { text: "", border: [true, false, true, false] },
                { text: "", border: [true, false, true, false] },
              ],
              // data
              ...fileData,
              ...rowEmpty,
              [
                {
                  text: "รวมเงิน",
                  alignment: "right",
                  border: [false, true, true, false],
                },
                {
                  text: `${props?.params?.count_receipt_paid || ""}`,
                  alignment: "center",
                  border: [true, true, true, true],
                },
                {
                  text: `${props?.params?.sum_receipt_paid_price || ""}`,
                  alignment: "right",
                  border: [true, true, true, true],
                },
                {
                  text: `.-`,
                  alignment: "right",
                  border: [true, true, true, true],
                },
                {
                  text: "รวมเงิน",
                  alignment: "right",
                  border: [false, true, true, false],
                },
                {
                  text: `${props?.params?.count_receipt_canceled || ""}`,
                  alignment: "center",
                  border: [true, true, true, true],
                },
                {
                  text: `${props?.params?.sum_receipt_canceled_price || ""}`,
                  alignment: "right",
                  border: [true, true, true, true],
                },
                {
                  text: `.-`,
                  alignment: "right",
                  border: [true, true, true, true],
                },
              ],
            ],
          },
        },
      ],
      footer: function (currentPage: number, pageCount: number) {
        return {
          margin: [25, 0, 29, 0],
          stack: [
            { text: `หมายเหตุ`, margin: [4, 0, 0, 0] },
            { text: `\n` },
            {
              text: `ข้าพเจ้าขอมอบให้ .................................................................................................... เป็นผู้นำส่งเงินแทนข้าพเจ้า`,
              margin: [4, 0, 0, 0],
            },
            {
              table: {
                widths: [320, "*"],
                body: [
                  [
                    {
                      stack: [
                        {
                          text: `\n`,
                        },
                        {
                          text: "ลายมือชื่อผู้นำส่งเงิน ..........................................................",
                          alignment: "left",
                        },
                      ],
                      margin: [0, 10, 0, 0],
                      border: [false, false, false, false],
                    },
                    {
                      stack: [
                        {
                          text: "ตรวจสอบความถูกต้องแล้ว",
                          alignment: "right",
                          margin: [0, 0, 20, 0],
                        },
                        {
                          text: "ลายมือชื่อผู้รับเงิน..........................................................",
                        },
                        {
                          text: "( .................................................)",
                          alignment: "right",
                          margin: [0, 0, 20, 0],
                        },
                        {
                          text: "ตำแหน่ง        ..........................................................",
                        },
                      ],
                      margin: [0, 10, 0, 0],
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
            },
          ],
        };
      },
    };
  };

  export default FormARremittanceReport;
