import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";

import { ButtonProps } from "semantic-ui-react";

// UX
import CardFinancialReportUX from "./CardFinancialReportUX";
import ModFinancialReportTemplate, {
  ACTION_PREVIEW,
  ACTION_PRINT,
  GetStationLogHandler,
  TemplateDetail,
  Templates,
} from "./ModFinancialReportTemplate";

// Interface
import {
  ACTIONS,
  MasterOptionsType,
  OUTSTANDING_DEBTOR_OPTIONS,
  PickedProps,
  REPAY_DEBT_OPTIONS,
  ReportTypes,
  RunSequence,
  SetProp,
  State,
} from "./sequence/FinancialReport";

// Types
type CardFinancialReportProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  runSequence: RunSequence;
  // seq
  FinancialReportSequence?: State["FinancialReportSequence"];
  // options
  masterOptions?: MasterOptionsType;
} & PickedProps;

// Const
const REPORT_TEMPLATES: Record<
  ReportTypes,
  {
    title: string;
    components: Templates;
  }
> = {
  REMITTANCE: {
    title: "รายงานใบนำส่งเงิน",
    components: [
      [
        { type: "start_date", width: 7, required: true },
        { type: "end_date", width: 6, labelWidth: 50 },
      ],
      [
        { type: "coverage", width: 7, required: true, labelWidth: 50 },
        { type: "payer", width: 9 },
      ],
      [
        { type: "station", width: 6, labelWidth: 50 },
        { type: "station_log", width: 10 },
      ],
    ],
  },
  SEPARATE_COVERAGE: {
    title: "รายงานค่ารักษาพยาบาลแยกตามสิทธิ",
    components: [
      [
        { label: "วันที่เริ่มต้น", type: "start_date", width: 7, required: true, labelWidth: 80 },
        { type: "end_date", width: 6, labelWidth: 50 },
      ],
      [
        { type: "coverage", width: 7, required: true },
        { type: "payer", width: 9 },
      ],
      [
        { type: "encounter_type", width: 7 },
        { type: "station", width: 6, labelWidth: 50 },
      ],
      [{ type: "station_log", width: 13 }],
    ],
  },
  OVERDUE: {
    title: "รายงานค่ารักษาพยาบาลค้างชำระ",
    components: [
      [
        { label: "วันที่เข้ารับบริการ", type: "start_date", width: 7, required: true },
        { type: "end_date", width: 6, labelWidth: 50 },
      ],
      [
        { type: "coverage", width: 7, required: true, labelWidth: 50 },
        { type: "payer", width: 9 },
      ],
      [{ type: "encounter_type", width: 7, labelWidth: 105 }],
    ],
  },
  EXPENSE_EXCEPTION: {
    title: "รายงานค่าใช้จ่ายที่ยกเว้นค่ารักษาพยาบาล",
    components: [
      [
        { label: "วันที่เข้ารับบริการ", type: "start_date", width: 7, required: true },
        { type: "end_date", width: 6 },
      ],
      [
        { type: "encounter_type", width: 7, labelWidth: 105 },
        { type: "division", width: 9 },
      ],
    ],
  },
  OUTSTANDING_DEBTOR: {
    title: "รายงานลูกหนี้คงค้าง",
    components: [
      [
        { label: "วันที่ออกใบแจ้งหนี้", type: "start_date", width: 7, required: true },
        { type: "end_date", width: 4 },
        { type: "status", width: 5, options: OUTSTANDING_DEBTOR_OPTIONS, default: "NONE" },
      ],
      [
        { type: "coverage", width: 7, required: true },
        { type: "payer", width: 9 },
      ],
      [{ type: "encounter_type", width: 7, labelWidth: 115 }],
    ],
  },
  REPAY_DEBT: {
    title: "รายงานการชำระหนี้",
    components: [
      [
        { label: "วันที่รับชำระ", type: "start_date", width: 7, required: true },
        { type: "end_date", width: 4 },
        { type: "status", width: 5, options: REPAY_DEBT_OPTIONS, default: "SENT_SUCCESS" },
      ],
      [
        { type: "coverage", width: 7, required: true },
        { type: "payer", width: 9 },
      ],
      [{ type: "encounter_type", width: 7 }],
    ],
  },
  COVERAGE: {
    title: "รายงานการใช้สิทธิ",
    components: [
      [
        { label: "วันที่รักษา เริ่มต้น", type: "start_date", width: 7, required: true },
        { label: "สิ้นสุด", type: "end_date", width: 5 },
        { label: "ประเภท", type: "encounter_type", width: 5 },
      ],
      [
        { type: "coverage", width: 7, required: true, labelWidth: 42.5 },
        { type: "payer", width: 10 },
      ],
      [
        { type: "station", width: 7 },
        { type: "station_log", width: 10, labelWidth: 50 },
      ],
    ],
  },
};

const CardFinancialReport = (props: CardFinancialReportProps) => {
  // State
  const [modReport, setModReport] = useState<ReportTypes | "">("");

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "FinancialReport",
      restart: true,
    });
  }, []);

  // Callback
  const handleSelectMenu = useCallback(
    (e: SyntheticEvent, data: ButtonProps & { name: ReportTypes }) => {
      setModReport(data.name);
    },
    []
  );

  const handleCloseModReport = useCallback(() => {
    const { [ACTION_PREVIEW]: eApv, [ACTION_PRINT]: eApt, ...error } = props.errorMessage || {};
    const { [ACTION_PREVIEW]: bApv, [ACTION_PRINT]: bApt, ...blc } = props.buttonLoadCheck || {};

    props.setProp("FinancialReportSequence.bilStationLogOptions", []);
    props.setProp(`errorMessage`, error);
    props.setProp(`buttonLoadCheck`, blc);

    setModReport("");
  }, []);

  const handleGetStationLog = useCallback<GetStationLogHandler>((data) => {
    props.runSequence({
      sequence: "FinancialReport",
      action: ACTIONS.GET_BIL_STATION_LOG,
      ...data,
    });
  }, []);

  const handlePrintReport = useCallback(
    (data: TemplateDetail, btnAction: string, isPreview = false) => {
      if (modReport) {
        props.runSequence({
          sequence: "FinancialReport",
          action: ACTIONS.PRINT_REPORT,
          type: modReport,
          btnAction,
          data,
          isPreview,
        });
      }
    },
    [modReport]
  );

  const handlePreview = useCallback(
    (data: TemplateDetail, btnAction: string) => {
      handlePrintReport(data, btnAction, true);
    },
    [handlePrintReport]
  );

  // #console.log("CardFinancialReportUX", props);

  return (
    <>
      <CardFinancialReportUX onSelectMenu={handleSelectMenu} />

      {modReport && (
        <ModFinancialReportTemplate
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          open={true}
          title={REPORT_TEMPLATES[modReport].title}
          templates={REPORT_TEMPLATES[modReport].components}
          isLoadingStationLog={!!props.buttonLoadCheck?.[ACTIONS.GET_BIL_STATION_LOG]}
          // options
          masterOptions={props.masterOptions}
          stationLogOptions={props.FinancialReportSequence?.bilStationLogOptions}
          // CommonInterface
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // callback
          onGetStationLog={handleGetStationLog}
          onPreview={handlePreview}
          onPrint={handlePrintReport}
          onClose={handleCloseModReport}
        />
      )}
    </>
  );
};

CardFinancialReport.displayName = "CardFinancialReport";

export default React.memo(CardFinancialReport);
