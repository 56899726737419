import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  FormGroup,
  FormField,
  Dropdown
} from 'semantic-ui-react'

const CardVerbalOrderUX = (props: any) => {
    return(
      <div>
        <div
          style={{padding: "10px 0", fontSize: "1.3rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          รับคำสั่งแพทย์
        </div>
        <div
          className="tabs-verbal-order"
          style={{padding: "10px 0", fontSize: "1.2rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          
          <Button
            color={props.containerName === "history" ? "blue" : undefined}
            onClick={()=> props.onChangeTab("history")}>
            ประวัติ รคส.
          </Button>
          <Button
            color={props.containerName === "admit" ? "blue" : undefined}
            disabled={props.hideAdmitOrder}
            onClick={()=> props.onChangeTab("admit")}
            style={{marginLeft: "15px"}}>
            Admit Order
          </Button>
          <Button
            color={props.containerName === "drug" ? "blue" : undefined}
            onClick={()=> props.onChangeTab("drug")}
            style={{marginLeft: "15px"}}>
            ยา
          </Button>
          <Button
            color={props.containerName === "lab" ? "blue" : undefined}
            onClick={()=> props.onChangeTab("lab")}
            style={{marginLeft: "15px"}}>
            Central Lab
          </Button>
          <Button
            color={props.containerName === "treatment" ? "blue" : undefined}
            onClick={()=> props.onChangeTab("treatment")}
            style={{marginLeft: "15px"}}>
            Treatment Order
          </Button>
          <Button
            color={props.containerName === "imaging" ? "blue" : undefined}
            onClick={()=> props.onChangeTab("imaging")}
            style={{marginLeft: "15px"}}>
            Imaging
          </Button>
          <Button
            color={props.containerName === "drnote" ? "blue" : undefined}
            onClick={()=> props.onChangeTab("drnote")}
            style={{marginLeft: "15px"}}>
            คำสั่งอื่นๆ
          </Button>
        </div>
        <div
          style={{padding: "10px", display: "flex", alignItems: "center", backgroundColor: "#F3F3F3" }}>
          {props.container}
        </div>
        <div
          className="save-verbal-order"
          style={{padding: "10px", fontSize: "1.2rem", display: props.hiddenPerformed? "none":"flex", alignItems: "center" }}>
          
          <div
            className="ui form"
            style={{width: "100%"}}>
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{ minWidth: "max-content" }}>
                  แพทย์ผู้สั่ง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{width: "100%"}}>
                  {props.doctorSearchBox}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{ minWidth: "max-content" }}>
                  รับคำสั่ง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Dropdown
                  fluid={true}
                  id="cardverbalorder-dropdown-1"
                  onChange={props.methodOnChange}
                  options={props.methodOptions}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.method}>
                </Dropdown>
              </FormField>
              <FormField
                className="required"
                inline={true}>
                <label
                  style={{ minWidth: "max-content" }}>
                  ยืนยันรหัสผู้รับคำสั่ง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{width: "100%"}}>
                  {props.rePassword}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{padding: 0}}>
                <Button
                  color="green"
                  disabled={props.disabledSave}
                  fluid={false}
                  loading={props.saveloading}
                  onClick={props.saveVerbalOrder}>
                  {props.save}
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default CardVerbalOrderUX

export const screenPropsDefault = {}

/* Date Time : Mon Aug 05 2024 11:28:41 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รับคำสั่งแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 0\", fontSize: \"1.3rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "tabs-verbal-order"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 0\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติ รคส."
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"history\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"history\")"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Admit Order"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"admit\" ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "props.hideAdmitOrder"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"admit\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ยา"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"drug\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"drug\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Central Lab"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"lab\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"lab\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment Order"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"treatment\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"treatment\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.container"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", alignItems: \"center\", backgroundColor: \"#F3F3F3\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "save-verbal-order"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontSize: \"1.2rem\", display: props.hiddenPerformed? \"none\":\"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"imaging\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"imaging\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "คำสั่งอื่นๆ"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"drnote\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"drnote\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormGroup",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 41,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\" }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "รับคำสั่ง"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันรหัสผู้รับคำสั่ง"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Dropdown",
      "parent": 45,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cardverbalorder-dropdown-1"
        },
        "onChange": {
          "type": "code",
          "value": "props.methodOnChange"
        },
        "options": {
          "type": "code",
          "value": "props.methodOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.method"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "code",
          "value": "props.rePassword"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Button",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledSave"
        },
        "fluid": {
          "type": "code",
          "value": "false"
        },
        "loading": {
          "type": "code",
          "value": "props.saveloading"
        },
        "onClick": {
          "type": "code",
          "value": "props.saveVerbalOrder"
        }
      },
      "seq": 55,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardVerbalOrderUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
