import React, { CSSProperties, useMemo, useState } from "react";
import { Card } from "semantic-ui-react";

// UX
import CardDrugDiseaseISegmentResultUX from "./CardDrugDiseaseISegmentResultUX";
import SubDrugDiseaseISegmentResultDetail from "./SubDrugDiseaseISegmentResultDetail";

// Common
import { ModConfirm } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  DrugRelatedSubSegmentType,
  RunSequence,
  SubSegmentType,
} from "./sequence/DrugDiseaseInteraction";
import { CARD_DRUG_DISEASE_INTERACTION } from "./CardDrugDiseaseInteraction";

type CardDrugDiseaseISegmentResultProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // data
  segment?: DrugRelatedSubSegmentType | null;
  subSegmentList?: SubSegmentType[];
  segmentGroupUDList?: Record<string, any>[];
  subGroupDict?: Record<string, any[]>;
  // seq
  runSequence: RunSequence;
};

// Constant
const ICONS = {
  segment: "/static/images/segment-icon.png",
};

const styles = {
  no_chat: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: "rgb(158 157 157)",
  } as CSSProperties,
};

const CARD_DRUG_DISEASE_I_SEGMENT_RESULT = "CardDrugDiseaseISegmentResult";

const CardDrugDiseaseISegmentResult = (
  props: CardDrugDiseaseISegmentResultProps
) => {
  const [openModConfirmRemove, setOpenModConfirmRemove] =
    useState<boolean>(false);

  const handleAddSegmentResult = () => {
    props.runSequence({
      sequence: "DrugDiseaseInteraction",
      action: "CHANGE_SUB_SEGMENT",
      method: "ADD",
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "DrugDiseaseInteraction",
      action: "CREATE_SEGMENT_RESULT",
      card: CARD_DRUG_DISEASE_I_SEGMENT_RESULT,
      errorKey: CARD_DRUG_DISEASE_INTERACTION,
    });
  };

  // Mod
  const handleConfirmRemove = () => {
    props.runSequence({
      sequence: "DrugDiseaseInteraction",
      action: "DELETE_SEGMENT_GROUP",
      card: CARD_DRUG_DISEASE_I_SEGMENT_RESULT,
      errorKey: CARD_DRUG_DISEASE_INTERACTION,
      onSuccess: () => setOpenModConfirmRemove(false),
    });
  };

  const handleOpenModConfirmRemove = () => {
    setOpenModConfirmRemove(true);
  };

  const handleCloseModConfirmRemove = () => {
    setOpenModConfirmRemove(false);
  };

  return (
    <div style={{ height: "100%" }}>
      {props.segment ? (
        <>
          <CardDrugDiseaseISegmentResultUX
            // data
            drugType={props.segment?.drug_type}
            drugName={props.segment?.drug_name?.split(" ")?.[0] || ""}
            // callback
            onAddSegmentResult={handleAddSegmentResult}
            onDelete={handleOpenModConfirmRemove}
            // Element
            ButtonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleSave}
                // data
                paramKey={`${CARD_DRUG_DISEASE_I_SEGMENT_RESULT}_SAVE`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[
                    `${CARD_DRUG_DISEASE_I_SEGMENT_RESULT}_SAVE`
                  ]
                }
                // config
                color={"green"}
                name="SAVE"
                size="medium"
                title="SAVE"
              />
            }
            SubSegmentList={
              <div
                style={{
                  height: "calc(100vh - 213px)",
                  width: "100%",
                  overflow: "auto",
                  padding: "2px 3px 2px 2px",
                }}
              >
                {(props.subSegmentList || []).map(
                  (item, index) =>
                    item.active && (
                      <Card
                        key={
                          `card-${item.segment_group_id}-${item.sub_segment_group_id}` +
                          index
                        }
                        style={{ width: "100%" }}
                      >
                        <SubDrugDiseaseISegmentResultDetail
                          onEvent={props.onEvent}
                          // seq
                          runSequence={props.runSequence}
                          // data
                          segmentGroupUDList={props.segmentGroupUDList}
                          data={item}
                          index={index}
                          subGroupDict={props.subGroupDict}
                        />
                      </Card>
                    )
                )}
              </div>
            }
          />
        </>
      ) : (
        <div style={styles.no_chat}>
          <img
            src={ICONS.segment}
            alt={`segment.icon`}
            style={{ width: "10rem", marginRight: "-3rem" }}
          />
          <p style={{ fontSize: "1.25rem", marginTop: "-1rem" }}>
            เลือก Segment Group
          </p>
        </div>
      )}

      <ModConfirm
        openModal={openModConfirmRemove}
        titleName="ลบกลุ่ม"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmRemove}
            // data
            paramKey={`${CARD_DRUG_DISEASE_I_SEGMENT_RESULT}_DELETE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_DRUG_DISEASE_I_SEGMENT_RESULT}_DELETE`
              ]
            }
            // config
            color={"green"}
            name="DELETE"
            size="medium"
            title="ตกลง"
            basic={true}
          />
        }
        content={
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "0rem 0 -1rem",
            }}
          >
            ต้องการลบกลุ่มที่เลือก
          </div>
        }
        // onApprove={handleConfirmRemove}
        onDeny={handleCloseModConfirmRemove}
        onCloseWithDimmerClick={handleCloseModConfirmRemove}
      />
    </div>
  );
};

export default React.memo(CardDrugDiseaseISegmentResult);
