import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Modal, Icon, Image } from "semantic-ui-react";
import ModInfo from "./ModInfo";
import config from "config/config";

type ModalUploadFileProps = {
  openUploadFileModal?: boolean;
  onCloseModal?: (value: boolean) => void;
  onUploadFile?: (value: string) => void;
  isCheckSize?: boolean;
};

const ModalUploadFile = (props: ModalUploadFileProps) => {
  const fileUploaderRef = useRef();
  const [uploadImage, setUploadImage] = useState<string>("");
  const [opneModInfo, setOpneModInfo] = useState<boolean>(false);
  // const defaultImage = "../../static/images/picture.gif";

  const readChooseFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setUploadImage(e.target.result);
        //console.log('base64 of a choosed file:', e.target.result)
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleCheckSize = () => {
    const img = document.createElement("img");
    img.onload = function handleLoad() {
      // console.log(`Width: ${img.width}, Height: ${img.height}`);
      if (img.width > config?.CU_UPLOAD_GALLERY?.width || img.height > config?.CU_UPLOAD_GALLERY?.height) {
        setOpneModInfo(true);
      } else {
        props.onUploadFile?.(uploadImage);
        props.onCloseModal?.(false);
        setUploadImage("");
      }
    };
    img.src = uploadImage;
  };

  const handleCloseModInfo = () => {
    setOpneModInfo(false);
    setUploadImage("");
  };

  return (
    <>
      <Modal
        open={props.openUploadFileModal}
        size="small"
        onClose={() => {
          setUploadImage("");
          props.onCloseModal?.(false);
        }}
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <Modal.Header>
          <Form>
            <Form.Field inline>
              <label> Upload Image </label>
              <Button
                color="teal"
                onClick={() => {
                  fileUploaderRef.current.click();
                }}
              >
                <Icon name="folder" color="blue"/> Choose file
              </Button>
              <input type="file" id="file" ref={fileUploaderRef} style={{ display: "none" }} onChange={readChooseFile} accept="image/*" />
            </Form.Field>
          </Form>
        </Modal.Header>
        {uploadImage && (
          <Modal.Description style={{ textAlign: "center" }}>
            <img src={uploadImage} style={{ maxWidth: 640, maxHeight: 480 }} />
          </Modal.Description>
        )}

        <Modal.Actions>
          <Button
            color="green"
            disabled={!uploadImage}
            onClick={() => {
              props.isCheckSize ? handleCheckSize() : 
              props.onUploadFile?.(uploadImage);
              props.onCloseModal?.(false);
              setUploadImage("");
            }}
          >
            <Icon name="check" /> Upload File
          </Button>
        </Modal.Actions>
      </Modal>

      <ModInfo
        open={opneModInfo}
        size="tiny"
        titleColor={"red"}
        alertText={"ไม่สามารถอัพโหลดภาพได้ เนื่องจากไฟล์เกินขนาดจำกัดของระบบ"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
        closeOnDimmerClick
        style={{ margin: "auto" }}
        btnText={"ปิด"}
        buttonStyle={{ marginTop: "20px" }}
        buttonColor={"blue"}
      />
    </>
  );
};

ModalUploadFile.defaultProps = {
  openUploadFileModal: false,
  onCloseModal: () => {},
  onUploadFile: () => {},
  isCheckSize: false,
};

export default ModalUploadFile;
