import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import {
  Form,
  Grid,
  Button,
  Pagination,
  Dimmer,
  Loader,
  Header,
  Modal,
  Icon,
} from "semantic-ui-react";

import { toast } from "react-toastify";

import CardLayout from "react-lib/apps/common/CardLayout";
import DoctorSearchBox from "react-lib/apps/common/DoctorSearchBox";
import FilterDateRange from "react-lib/apps/common/FilterDateRange";
import PatientData from "react-lib/apps/common/PatientData";
import PatientSearchBox from "react-lib/apps/common/PatientSearchBox";

import CardEMRSummary from "./CardEMRSummary";
import CardMedicalNote from "./CardMedicalNote";
import CardMedicationReconcile from "./CardMedicationReconcile";
import CardNurseRecord from "./CardNurseRecord";
import CardVitalSign from "./CardVitalSign";
import CardScannedDocument from "./CardScannedDocument";
import CardSecretEncounter from "./CardSecretEncounter";
import PatientPanel from "./PatientPanel";
import TabBar from "./TabBar";

import { formatDate } from "react-lib/utils/dateUtils"
import CardORHistory from "react-lib/apps/HISV3/ORM/CardORHistory";
import CardBloodTransfusionHistory from "../BLB/CardBloodTransfusionHistory";
import CardMedDetail from "../DPO/CardMedDetail";
import CardLabResult from "../LAB/CardLabResult";
import _ from "../../compat/lodashplus";
import CardImagingResult from "../HISV3/IME/CardImagingResult";

const styles = {
  buttonGroup: {
    display: "block",
    textAlign: "right",
  },
};

const MEDICAL_NOTE_ACTIVE_INDEX = {
  PROGRESS_NOTE: 0,
  OPERATIVE_NOTE: 1,
  ADMISSION_NOTE: 2,
  DISCHARGE_SUMMARY: 3,
  ORDER_SUMMARY: 4,
  CONSULT_NOTE: 5,
  TREATMENT_RESULT: 6,
  SENSITIVE_NOTE: 7,
  ARI_EXAMINATION: 8,
  TECHNICAL_RECORD: 9,
  IMAGE_GALLERY_VIEW: 10,
};

const NURSE_RECORD_ACTIVE_INDEX = {
  NURSE_NOTE: 0,
  PATIENT_ASSESSMENT: 1,
  NURSING_DISCHARGE_SUMMARY: 2,
  DISCHARGE_TEACHING: 3,
  MULTIDISCIPLINARY: 4,
  ANC_RECORD: 5,
  NURSING_DIAGNOSIS: 6,
};

const MAIN_ACTIVE_INDEX = {
  EMR_SUMMARY: 0,
  MED_DETAIL: 1,
  MEDICATIONS: 2,
  LAB: 3,
  BLOOD: 4,
  IMAGING_EXAM: 5,
  MEDICAL_NOTE: 6,
  NURSE_RECORD: 7,
  SCANNED_DOC: 8,
  VITAL_SIGN: 9,
  SECRET_ENCOUNTER: 10,
  OR_HISTORY: 11,
};

const MEDICAL_NOTE_HIDE_PAGINATION = [
  MEDICAL_NOTE_ACTIVE_INDEX.ADMISSION_NOTE,
  MEDICAL_NOTE_ACTIVE_INDEX.DISCHARGE_SUMMARY,
  MEDICAL_NOTE_ACTIVE_INDEX.CONSULT_NOTE,
  MEDICAL_NOTE_ACTIVE_INDEX.SENSITIVE_NOTE,
  MEDICAL_NOTE_ACTIVE_INDEX.ARI_EXAMINATION,
];

const NURSE_RECORD_HIDE_PAGINATION = [
  NURSE_RECORD_ACTIVE_INDEX.PATIENT_ASSESSMENT,
  NURSE_RECORD_ACTIVE_INDEX.NURSING_DISCHARGE_SUMMARY,
  NURSE_RECORD_ACTIVE_INDEX.DISCHARGE_TEACHING,
  NURSE_RECORD_ACTIVE_INDEX.ANC_RECORD,
  NURSE_RECORD_ACTIVE_INDEX.NURSING_DIAGNOSIS,
];

const MAIN_HIDE_PAGINATION = [
  MAIN_ACTIVE_INDEX.EMR_SUMMARY,
  MAIN_ACTIVE_INDEX.MEDICATIONS,
  MAIN_ACTIVE_INDEX.LAB,
  MAIN_ACTIVE_INDEX.IMAGING_EXAM,
  MAIN_ACTIVE_INDEX.SCANNED_DOC,
  MAIN_ACTIVE_INDEX.VITAL_SIGN,
  MAIN_ACTIVE_INDEX.SECRET_ENCOUNTER,
  MAIN_ACTIVE_INDEX.MED_DETAIL,
  MAIN_ACTIVE_INDEX.OR_HISTORY
];

const CardPHV = (props) => {
  const limit = 5;

  const defaultEncounterId = null;
  const defaultMedicalRecordId = null;
  const defaultCheckInTime = "";
  const defaultDischargeTime = "";

  const [isSecret, setIsSecret] = useState(false);

  const [activeMainTabIndex, setActiveMainTabIndex] = useState(0);
  const [activeNurseRecordIndex, setActiveNurseRecordIndex] = useState(0);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [patientId, setPatientId] = useState(null);
  const [doctorId, setDoctorId] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [encounterType, setEncounterType] = useState(null);
  const [encounterStartDate, setEncounterStartDate] = useState(null);
  const [encounterEndDate, setEncounterEndDate] = useState(null);
  const [encounterFilter, setEncounterFilter] = useState({
    encounterType: "",
    noteType: "",
  });
  const [dischargeStatus, setDischargeStatus] = useState("");

  const [patientPanelisLoading, setPatientPanelisLoading] = useState(false);

  const [encounterId, setEncounterId] = useState(defaultEncounterId);
  const [encounterDoctorId, setEncounterDoctorId] = useState(null);
  const [medicalRecordId, setMedicalRecordId] = useState(
    defaultMedicalRecordId
  );
  const [hnPatient, setHNPatient] = useState(null);
  const [checkInTime, setCheckInTime] = useState(defaultCheckInTime);
  const [dischargeTime, setDischargeTime] = useState(defaultDischargeTime);

  const [emrSummaryData, setEmrSummaryData] = useState({});
  const [emrSummaryLoading, setEmrSummaryLoading] = useState(false);

  const [triggerMedicationRecord, setTriggerMedicationRecord] = useState({});
  const [triggerLabResult, setTriggerLabResult] = useState(0);

  const [medReconcile, setMedReconcile] = useState([]);
  const [medReconcileDetail, setMedReconcileDetail] = useState({});
  const [medRecordLog, setMedRecordLog] = useState([]);
  const [medReconcileLoading, setMedReconcileLoading] = useState(false);
  const [medReconcileLoadingModal, setMedReconcileLoadingModal] =
    useState(false);

  // Oldie
  const [medicationData, setMedicationData] = useState({});
  const [medicationLoading, setMedicationLoading] = useState(false);

  // Newbie
  const [homeMedDetail, setHomeMedDetail] = useState({});
  const [homeMedDetailLoading, setHomeMedDetailLoading] = useState(false);

  const [labCode, setLabCode] = useState({});
  const [labDetail, setLabDetail] = useState({});
  const [labError, setLabError] = useState({});

  const [reportDetail, setReportDetail] = useState(null);
  const [labLoading, setLabLoading] = useState(false);

  const [imagingExamResult, setImagingExamResult] = useState([]);
  const [imagingExamResultLoading, setImagingExamResultLoading] =
    useState(false);

  const [totalPage, setTotalPage] = useState(0);

  const [dispatchAction, setDispatchAction] = useState({});

  const [nurseRecordStartDate, setNurseRecordStartDate] = useState(null);
  const [nurseRecordEndDate, setNurseRecordEndDate] = useState(null);

  const [callGetVitalSign, setCallGetVitalSign] = useState(null);
  const [callGetScannedDoc, setCallGetScannedDoc] = useState(null);

  const [dischargeSummaryData, setDischargeSummaryData] = useState(null);

  const doctorSearchRef = useRef(null);
  const hnSearchRef = useRef(null) as MutableRefObject<any>;
  const cardMedicalNoteRef = useRef(null);

  const [resultPrinter, setResultPrinter] = useState(null);
  const [orderSummaryPrintLoading, setOrderSummaryPrintLoading] =
    useState(false);

  const [patientDataTEC, setPatientDataTEC] = useState({});

  const nurseRecordRef = useRef();
  const isSelectedPatientRef = useRef<boolean>(false);

  useEffect(() => {
    if (props.patientDataAPI?.hn && hnSearchRef.current && props.hnOnDidMount) {
      hnSearchRef.current?.setValue?.(props.patientDataAPI?.hn);
    }
  }, []);

  useEffect(() => {
    if (isSelectedPatientRef.current && props.filterOnSelectPatient) {
      handleOnClickFilterDate();
      isSelectedPatientRef.current = false;
    }
  }, [hnPatient]);

  // PatientSearchBox
  const handleOnSelectPatient = (id, hn) => {
    if (id && hn) {
      setPatientId(id);
      setHNPatient(hn);
      isSelectedPatientRef.current = true;
    }
  };

  // DoctorSearchBox
  const handleOnSelectDoctor = (id, name) => {
    setDoctorName(name);
    setDoctorId(id);
  };

  // FilterDateRange
  const handleOnSelectStartDate = (date) => {
    setEncounterStartDate(date);
  };

  // PatientPanel
  const handleOnSelectEndDate = (date) => {
    setEncounterEndDate(date);
  };

  const prepareEncounterFilter = (ipdType, opdType, noteType) => {
    var selectEncounterType = "";
    if (ipdType && opdType) {
      selectEncounterType = ["IPD", "OPD", "ER", "SS"];
    } else if (ipdType) {
      selectEncounterType = "IPD";
    } else if (opdType) {
      selectEncounterType = ["OPD", "ER", "SS"];
    }
    let f = { encounterType: selectEncounterType, noteType: noteType };
    setEncounterFilter(f);
    return f;
  };

  const handleOnChangeEncounterFilter = async ({
    ipdType,
    opdType,
    noteType,
    page,
    forcedSelectEncounterId,
  }) => {
    console.log(" handleOnChangeEncounterFilter 1");
    let filter = prepareEncounterFilter(ipdType, opdType, noteType);
    setPatientPanelisLoading(true);
    console.log(" handleOnChangeEncounterFilter 2");
    await props.getEncounterHistory({
      filter,
      page,
      limit,
      forcedSelectEncounterId,
    });
    setPatientPanelisLoading(false);
  };

  const handleOnChangeEncounterId = ({
    isSecret,
    encounterId,
    emrId,
    hnPatient,
    doctorId,
    encounterType,
    checkInTime,
    dischargeTime,
    status,
  }) => {
    setCheckInTime(checkInTime);
    setDischargeTime(dischargeTime);
    setEncounterType(encounterType);
    setHNPatient(hnPatient);
    setMedicalRecordId(emrId);
    setEncounterId(encounterId);
    setEncounterDoctorId(doctorId);
    setIsSecret(isSecret);
    setDischargeStatus(status);
  };

  // const handleHNTypeChange = (hn) => {
  //   setTypeHN(hn)
  // }

  const reloadEncounter = async () => {
    if (patientId) {
      props.getEncounterList({
        patientId: patientId,
        doctorId: doctorId,
        startDate: encounterStartDate,
        endDate: encounterEndDate,
        filter: encounterFilter,
        limit: limit,
      });
    }
  };

  // const handleOnSubmit = () => {
  //   if ( document.activeElement.id === 'InputPatientSearchBox' ) {
  //     hnSearchRef.current.openPatientSearch();
  //   } else if ( document.activeElement.id === 'InputDoctorSearchBox' ) {
  //     doctorSearchRef.current.openDoctorSearch();
  //   }
  // }

  const handleOnClickFilterDate = async () => {
    let patientData;
    let patientError;
    let doctorData;
    let doctorError;

    let preparePatientId = null;
    let prepareDoctorId = null;

    if (
      document.activeElement.id === "StartDateTextBox" ||
      document.activeElement.id === "EndDateTextBox"
    ) {
      return;
    }

    let typingHN =
      hnSearchRef.current.value() && hnSearchRef.current.value().toUpperCase();

    // User typing new HN
    if (
      typingHN &&
      typingHN.length > 0 &&
      (typingHN !== hnPatient || !patientId)
    ) {
      setHNPatient("");
      setPatientId("");
      const [data, error] = await props.getPatientByHN({ hn: typingHN });
      patientData = data;
      patientError = error;

      if (patientData && patientData.id) {
        setHNPatient(patientData.hn);
        setPatientId(patientData.id);
        preparePatientId = patientData.id;
      } else if (patientError) {
        // hnSearchRef.current.clear();
        hnSearchRef.current.openPatientSearch();
        return;
      }
    } else if (typingHN === hnPatient && patientId && patientId.length > 0) {
      preparePatientId = patientId;
    }

    let typingDoctor = doctorSearchRef.current.value();

    // User typing new Doctor Name
    if (
      typingDoctor &&
      typingDoctor.length > 0 &&
      typingDoctor !== doctorName
    ) {
      setDoctorName("");
      setDoctorId("");
      const [data, error] = await props.getSearchDoctor({
        doctorName: typingDoctor,
      });
      doctorData = data;
      doctorError = error;

      if (doctorData && doctorData.length === 1) {
        setDoctorId(doctorData[0].id);
        setDoctorName(doctorData[0].full_name);
        doctorSearchRef.current.setName(doctorData[0].full_name);
        prepareDoctorId = doctorData[0].id;
      } else {
        // doctorSearchRef.current.clear();
        // prepareDoctorId = null;
        doctorSearchRef.current.openDoctorSearch();
        return;
      }
    }

    if (preparePatientId) {
      props.onFilterDate({
        patientId: preparePatientId,
        doctorId: prepareDoctorId,
        startDate: encounterStartDate,
        endDate: encounterEndDate,
        filter: encounterFilter,
        limit: limit,
      });
      return;
    }

    if (patientId) {
      props.onFilterDate({
        patientId: patientId,
        doctorId: prepareDoctorId,
        startDate: encounterStartDate,
        endDate: encounterEndDate,
        filter: encounterFilter,
        limit: limit,
      });
      return;
    }
    hnSearchRef.current.openPatientSearch();
  };

  // Search / Clear Button

  const handleClearAllSearch = () => {
    doctorSearchRef.current.clear();
    hnSearchRef.current.clear();

    setDispatchAction({ type: "CLEAR" });
    setPatientId(null);
    setHNPatient(null);
    setDoctorId("");
    setEncounterStartDate(null);
    setEncounterEndDate(null);

    setEmrSummaryData({});
    setHomeMedDetail({});
    setLabCode({});
    setLabDetail({});
    setLabError({});
    setImagingExamResult([]);

    setCallGetVitalSign(null);
    setCallGetScannedDoc(null);
    setEncounterId(defaultEncounterId);
    setEncounterDoctorId(null);
    setMedicalRecordId(defaultMedicalRecordId);

    props.clearPatientData();
  };

  const handleActiveIndexChange = (activeIndex) => {
    setActiveMainTabIndex(activeIndex);
  };

  // EMRSummary

  const handleGetEmrSummary = async () => {
    // patientId, encounterId
    if (encounterType === "OPD" && patientId && encounterId) {
      setEmrSummaryLoading(true);
      // OPD
      const [[emrData], [vitalSign], [vitalSignType], [lastHeight]] = await Promise.all([
        props.getEMRSummaryData({
          encounterId,
          patientId,
        }),
        props.onGetVitalSign({
          encounterId,
        }),
        props.onGetVitalSignType({ encounterId }),
        props.onGetLastHeight(),
      ]);

      setEmrSummaryData({
        ...emrData,
        vitalSign,
        vitalSignType,
        lastHeight,
        encounterId,
        patientId,
        encounterType,
      });
      setEmrSummaryLoading(false);
    } else if (encounterType === "IPD" && patientId && encounterId) {
      // if (
      //   dischargeStatus === "ADMITTED" &&
      //   typeof dischargeStatus === "string"
      // ) {
      //   setEmrSummaryData({ status: "ADMITTED" });
      //   return;
      // }

      setEmrSummaryLoading(true);
      // IPD
      const [data, error] = await props.getDischargeSummaryOnlyData({
        medicalRecordId,
      });

      if (error?.dischargeSummary === null) {
        setEmrSummaryData(data);
      } else {
        setEmrSummaryData({ status: "NODISCHARGESUMMARY" });
      }

      setEmrSummaryLoading(false);
    } else {
      setEmrSummaryData({});
    }
  };

  const handleGetEmrSummaryPrint = () => {
    // patientId, encounterId
    if (patientId && encounterId) {
      props.getEMRSummaryPrint({
        patientId: patientId,
        encounterId: encounterId,
      });
    }
  };

  // Medication

  const handleGetMedicationRecord = async (params) => {
    if (medicalRecordId) {
      if (params && params.hasOwnProperty("date")) {
        setMedicationLoading(true);
        const [medData, medError] = await props.getMedicationRecord({
          emrId: medicalRecordId,
          date: params.date,
          type: params.type,
        });
        // TODO: Handle medError
        setMedicationData(medData);
        setMedicationLoading(false);
        return;
      }
    } else {
      setMedicationData({});
    }
  };

  const handleGetMedReconciliation = async (params) => {
    if (!encounterId) {
      setMedReconcile([]);
      return toast.error("กรุณาระบุ encounter");
    }
    // if (params && params.hasOwnProperty("date")) {
    setMedReconcileLoadingModal(true);
    const [data, err] = await props.getMedReconciliation({
      ...params,
      encounter: encounterId,
    });
    // TODO: Handle medError
    setMedReconcile(data);
    setMedReconcileLoadingModal(false);
    return;
    // }
  };
  const handleGetMedReconciliationDetail = async (medReconcileId) => {
    // if (params && params.hasOwnProperty("date")) {
    setMedReconcileLoading(true);
    const [data, err] = await props.getMedReconciliationDetail(medReconcileId);
    // TODO: Handle medError
    setMedReconcileDetail(data);
    setMedReconcileLoading(false);
    return;
  };
  const handleGetMedReconciliationLog = async (params) => {
    // if (params && params.hasOwnProperty("date")) {
    setMedReconcileLoadingModal(true);
    const [data, err] = await props.getMedReconciliationLog(params);
    // TODO: Handle medError
    setMedRecordLog(data);
    setMedReconcileLoadingModal(false);
    return;
  };

  // New Medications

  const handleGetHomeMedDetail = async () => {
    if (encounterId && medicalRecordId) {
      setHomeMedDetailLoading(true);
      const [homeMedData, homeMedError] = await props.getHomeMedDetail({
        emrId: medicalRecordId,
        encounterId: encounterId,
      });
      // TODO: Handle medError
      setHomeMedDetail(homeMedData);
      setHomeMedDetailLoading(false);
    }
  };

  // Lab
  // const handleLabResultPrint = async ({ documentId }) => {
  //   props.printLab({ documentId })
  // }

  const handleGetLabCode = async ({ divisionList }) => {
    if (encounterId) {
      setLabDetail({});
      setLabLoading(true);
      // TODO: handle case datenull

      const [labCode, labCodeError] = await props.getLabCode({
        patientId: patientId,
        divisionList: divisionList,
      });

      // TODO: Handle medError
      setLabCode(labCode);
      setLabLoading(false);
    }
  };

  const handleGetLabDetail = async ({
    LabCode,
    startDate,
    stopDate,
    username,
    password,
  }) => {
    if (encounterId) {
      setLabDetail({});
      setLabLoading(true);
      // TODO: handle case datenull

      const [labDetail, labDetailError] = await props.getLabDetail({
        patientId: patientId,
        LabCode: LabCode,
        startDate: startDate,
        stopDate: stopDate,
        username: username,
        password: password,
      });

      if (
        labDetail &&
        labDetail.labCompare &&
        !_.isEmpty(labDetail.labCompare)
      ) {
        setLabDetail(labDetail);
      } else {
        setLabError(labDetailError);
      }

      // TODO: Handle medError
      setLabLoading(false);
    }
  };

  const handleGetInterfaceSummaryReportDetail = async (params) => {
    const { cloi } = params; // Central Lab Order Id
    if (cloi) {
      setLabLoading(true);
      const [data, error] = await props.getInterfaceSummaryReportDetail({
        cloi,
      });

      setReportDetail(data);
      setLabLoading(false);
    }
  };

  // ImagingExamResult
  const handleGetImagingExamResult = async (params) => {
    if (encounterId) {
      setImagingExamResultLoading(true);
      const [imagingResult, imagingError] = await props.getImagingExamResult({
        encounterId: encounterId,
      });
      // TODO: Handle medError
      setImagingExamResult(imagingResult.items);
      setImagingExamResultLoading(false);
    }
  };

  const handleGetDisChargeSummary = () => {
    props.onGetDischargeSummary({ medicalRecordId, encounterType });
  };

  const handleGetAdmissionNote = (param) => {
    props.getAdmissionNote({ ...param, encounterType });
  };

  useEffect(() => {
    //Change Encounter
    let params = {
      medicalStartDate: cardMedicalNoteRef?.current?.getStartDate(),
      medicalEndDate: cardMedicalNoteRef?.current?.getEndDate(),
      nurseRecordStartDate: nurseRecordStartDate,
      nurseRecordEndDate: nurseRecordEndDate,
      medicalRecordId,
      encounterId,
      medicalNoteDoctor: cardMedicalNoteRef?.current?.getSpeciality(),
      medicalNotePerformUser: cardMedicalNoteRef?.current?.getPerformUser(),
      currentPage: currentPagination,
      patientId,
      encounterType,
    };

    // TODO : change Interger to String (key)
    handleGetEmrSummary();

    setTriggerMedicationRecord({
      encounterId,
      checkInTime,
      dischargeTime,
      encounterType,
    });

    setLabDetail({});

    setTriggerLabResult(patientId);

    setHomeMedDetail({});
    handleGetHomeMedDetail();

    // handleGetLabData();
    handleGetImagingExamResult();
    props.onGetSecretEncounter(params);

    // ========================================== MEDICAL NOTE ===============================================
    if (activeMainTabIndex === MAIN_ACTIVE_INDEX.MEDICAL_NOTE) {
      const medNoteActiveTab =
        cardMedicalNoteRef?.current?.getMedicalNoteActiveTab();
      if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.PROGRESS_NOTE) {
        props.getProgressionNote(params);
      }
      // else if (
      //   medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.OPERATIVE_NOTE
      // ) {
      //   props.onGetOperativeNote(params);
      // }
      else if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.ADMISSION_NOTE) {
        handleGetAdmissionNote(params);
      } else if (
        medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.DISCHARGE_SUMMARY
      ) {
        handleGetDisChargeSummary();
        // props.onGetDischargeSummary(params);
      } else if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.ORDER_SUMMARY) {
        props.onGetOrderSummary(params);
      } else if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.CONSULT_NOTE) {
        props.onGetConsultNote(params);
      } else if (
        medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.TREATMENT_RESULT
      ) {
        props.onGetTreatmentResult(params);
      } else if (
        medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.SENSITIVE_NOTE
      ) {
        props.onGetSensitiveNote({ medicalRecordId });
      } else if (
        medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.IMAGE_GALLERY_VIEW
      ) {
        props.onGetImageGallery({ medicalRecordId });
      }
    }

    // ========================================== NURSE RECORD ===============================================
    else if (activeMainTabIndex === MAIN_ACTIVE_INDEX.NURSE_RECORD) {
      if (activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.NURSE_NOTE) {
        props.onGetNurseNote(params);
      } else if (
        activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.PATIENT_ASSESSMENT
      ) {
        if (encounterType === "IPD") {
          return;
        }
        props.onGetPatientAssessment(params);
      } else if (
        activeNurseRecordIndex ===
        NURSE_RECORD_ACTIVE_INDEX.NURSING_DISCHARGE_SUMMARY
      ) {
        props.onGetDischargePlanning(params);
      } else if (
        activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.DISCHARGE_TEACHING
      ) {
        if (encounterType === "IPD") {
          return;
        }
        props.onGetDischargeTeaching(params);
      } else if (
        activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.MULTIDISCIPLINARY
      ) {
        props.onGetMultidisciplinary(params);
      }
    } else if (activeMainTabIndex === MAIN_ACTIVE_INDEX.SCANNED_DOC) {
      setCallGetScannedDoc(patientId);
    } else if (activeMainTabIndex === MAIN_ACTIVE_INDEX.VITAL_SIGN) {
      console.log(" Call setCallGetVitalSign from encounterId");
      setCallGetVitalSign(encounterId);
    }
  }, [encounterId]);

  useEffect(() => {
    //Selected MainTab on secret Lab
    setLabDetail({});

    if (activeMainTabIndex === MAIN_ACTIVE_INDEX.MEDICAL_NOTE) {
      // MedicalNote issue #39863 หน้าจอ PHV2 tab Progress Note ไม่ refresh
      handleMedicalNoteSearch();
    } else if (activeMainTabIndex === MAIN_ACTIVE_INDEX.NURSE_RECORD) {
      // MedicalNote issue #39863 หน้าจอ PHV2 tab Progress Note ไม่ refresh
      handleNurseRecordSearch();
    }
  }, [activeMainTabIndex]);

  useEffect(() => {
    setDischargeSummaryData(props.dischargeSummaryData);
  }, [props.dischargeSummaryData]);

  useEffect(() => {
    props.onGetClinicalTerm("PAS");
    props.onGetClinicalTerm("FA");
    props.onGetClinicalTerm("PLO");
    props.onGetClinicalTerm("PFC");
  }, []);

  const handleScannedDocument = ({
    docStartDate,
    docEndDate,
    scanStartDate,
    scanEndDate,
    expiredStartDate,
    expiredEndDate,
    documentCategory,
    scanDivision,
    ownerDivision,
    docNo,
    scanUser,
    encounterDoctor,
    currentScannedDocPage,
    documentType,
  }) => {
    if (!hnPatient) {
      return toast.error("กรุณาเลือก hn");
    }
    props.onGetScannedDocument({
      docStartDate,
      docEndDate,
      scanStartDate,
      scanEndDate,
      expiredStartDate,
      expiredEndDate,
      documentCategory,
      scanDivision,
      ownerDivision,
      docNo,
      scanUser,
      encounterDoctor,
      hnPatient,
      currentScannedDocPage,
      documentType,
    });
  };

  const handleMedicalNoteSearch = () => {
    let params = {
      medicalStartDate: cardMedicalNoteRef?.current?.getStartDate(),
      medicalEndDate: cardMedicalNoteRef?.current?.getEndDate(),
      medicalRecordId,
      currentPage: currentPagination,
      encounterId,
      medicalNoteDoctor: cardMedicalNoteRef?.current?.getSpeciality(),
      medicalNotePerformUser: cardMedicalNoteRef?.current?.getPerformUser(),
      encounterType,
      startDate: cardMedicalNoteRef?.current?.getStartDate(),
      endDate: cardMedicalNoteRef?.current?.getEndDate(),
    };
    if (!encounterId) {
      return toast.error("กรุณาระบุ encounter");
    }
    const medNoteActiveTab =
    cardMedicalNoteRef?.current?.getMedicalNoteActiveTab();

    if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.TREATMENT_RESULT) {
      return props.onGetTreatmentResult(params);
    }
    // else if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.OPERATIVE_NOTE) {
    //   return props.onGetOperativeNote(params);
    // }
    if (!medicalRecordId && encounterType === "IPD") {
      return toast.error("ไม่มี medical record");
    }
    if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.ORDER_SUMMARY) {
      return props.onGetOrderSummary({
        ...params,
        medicalStartDate: params.medicalStartDate !== ""
          ? params.medicalStartDate
          : formatDate(new Date()),
      });
    }
    if (!medicalRecordId) {
      return toast.error("ไม่มี medical record");
    }
    if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.PROGRESS_NOTE) {
      return props.getProgressionNote(params);
    }
  };

  const handleNurseRecordSearch = () => {
    let params = {
      nurseRecordStartDate: nurseRecordStartDate,
      nurseRecordEndDate: nurseRecordEndDate,
      encounterId,
      currentPage: currentPagination,
    };
    if (!encounterId) {
      return toast.error("กรุณาระบุ encounter");
    }
    if (activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.NURSE_NOTE) {
      return props.onGetNurseNote(params);
    } else if (
      activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.PATIENT_ASSESSMENT
    ) {
      if (encounterType === "IPD") {
        return;
      }
      return props.onGetPatientAssessment(params);
    } else if (
      activeNurseRecordIndex ===
      NURSE_RECORD_ACTIVE_INDEX.NURSING_DISCHARGE_SUMMARY
    ) {
      return props.onGetDischargePlanning(params);
    } else if (
      activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.DISCHARGE_TEACHING
    ) {
      if (encounterType === "IPD") {
        return;
      }
      return props.onGetDischargeTeaching(params);
    } else if (
      activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.MULTIDISCIPLINARY
    ) {
      return props.onGetMultidisciplinary(params);
    } else if (
      activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.NURSING_DIAGNOSIS
    ) {
      nurseRecordRef.current.nursingDiagnosisRef.current.getNurseDiagnosisResultGroup();
      // return props.getNurseDiagnosisResultGroup(params);
    }
  };

  useEffect(() => {
    // When change Tab --> Change pagination
    setTotalPage(0);
    let page = null;
    const medNoteActiveTab =
      cardMedicalNoteRef?.current?.getMedicalNoteActiveTab();
    if (activeMainTabIndex === MAIN_ACTIVE_INDEX.MEDICAL_NOTE) {
      if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.PROGRESS_NOTE) {
        page = "progressionNote";
      } else if (
        medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.OPERATIVE_NOTE
      ) {
        page = "operativeNote";
      } else if (
        medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.ADMISSION_NOTE
      ) {
        handleGetAdmissionNote({ medicalRecordId });
      } else if (
        medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.TREATMENT_RESULT
      ) {
        page = "treatmentNote";
      } else if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.ORDER_SUMMARY) {
        page = "orderSummary";
      } else if (
        medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.TECHNICAL_RECORD
      ) {
        props.handleTECEncounterPD(encounterId, patientDataTEC);
        // This encounter may not have TEC Data
        // props.showTECSessionCard(true)
      }
    } else if (activeMainTabIndex === MAIN_ACTIVE_INDEX.NURSE_RECORD) {
      if (medNoteActiveTab === NURSE_RECORD_ACTIVE_INDEX.NURSE_NOTE) {
        page = "nurseNote";
      } else if (
        medNoteActiveTab === NURSE_RECORD_ACTIVE_INDEX.MULTIDISCIPLINARY
      ) {
        page = "multidisciplinary";
      }
    } else if (activeMainTabIndex === MAIN_ACTIVE_INDEX.SCANNED_DOC) {
      setCallGetScannedDoc(patientId);
    } else if (activeMainTabIndex === MAIN_ACTIVE_INDEX.VITAL_SIGN) {
      setCallGetVitalSign(encounterId);
    }
    if (props.numberOfPage && page && props.numberOfPage[page]) {
      setTotalPage(props.numberOfPage[page]);
    }
    if (
      activeMainTabIndex !== MAIN_ACTIVE_INDEX.MEDICAL_NOTE ||
      (activeMainTabIndex === MAIN_ACTIVE_INDEX.MEDICAL_NOTE &&
        medNoteActiveTab !== MEDICAL_NOTE_ACTIVE_INDEX.TECHNICAL_RECORD)
    ) {
      props.showTECSessionCard(false);
    }
  }, [
    activeMainTabIndex,
    // activeMedicalNoteIndex,  -> cardMedicalNoteRef?.current?.getMedicalNoteActiveTab()
    activeNurseRecordIndex,
    props.numberOfPage,
  ]);

  useEffect(() => {
    // call to fetch new page
    if (activeMainTabIndex === MAIN_ACTIVE_INDEX.MEDICAL_NOTE) {
      handleMedicalNoteSearch();
    } else if (activeMainTabIndex === MAIN_ACTIVE_INDEX.NURSE_RECORD) {
      handleNurseRecordSearch();
    }
  }, [currentPagination]);

  useEffect(() => {
    if (props.forcedSelectHN) {
      handleFilterDate(props.forcedSelectPatientId, props.forcedSelectHN);
    }
  }, [props.forcedSelectHN, props.forcedSelectPatientId]);

  useEffect(() => {
    if (
      !props.forcedSelectHN &&
      !props.forcedSelectPatientId &&
      props.patientDataAPI?.id &&
      props.patientDataAPI?.hn &&
      props.patientDataAPI?.id !== patientId &&
      props.patientDataAPI?.hn !== hnPatient
    ) {
      handleFilterDate(props.patientDataAPI?.id, props.patientDataAPI?.hn);
      // hnSearchRef.current.set(props.patientDataAPI.hn);
    }
  }, [props.patientDataAPI, props.forcedSelectHN, props.forcedSelectPatientId]);

  const handleFilterDate = (id: number, hn: string) => {
    hnSearchRef.current?.setValue(hn);
    setPatientId(id);
    setHNPatient(hn);

    if (!props.showContentOnly) {
      props.onFilterDate({
        patientId: id,
        doctorId: doctorId,
        startDate: encounterStartDate,
        endDate: encounterEndDate,
        filter: encounterFilter,
        limit: limit,
      });
    }
  };

  const handlePrintClick = async () => {
    let params = {
      nurseRecordStartDate: nurseRecordStartDate,
      nurseRecordEndDate: nurseRecordEndDate,
      encounterId,
      currentPage: currentPagination,
      encounterType,
      medicalNoteDoctor: cardMedicalNoteRef?.current?.getSpeciality(),
      medicalNotePerformUser: cardMedicalNoteRef?.current?.getPerformUser(),
      medicalStartDate: cardMedicalNoteRef?.current?.getStartDate(),
      medicalEndDate: cardMedicalNoteRef?.current?.getEndDate(),

      medicalRecordId,
    };
    if (!encounterId) {
      toast.error("กรุณาเลือก encounter");
    }
    if (activeMainTabIndex === MAIN_ACTIVE_INDEX.NURSE_RECORD) {
      if (activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.NURSE_NOTE) {
        props.onPrintNurseNote(params);
      } else if (
        activeNurseRecordIndex === NURSE_RECORD_ACTIVE_INDEX.MULTIDISCIPLINARY
      ) {
        props.onPrintMultidisciplinary(params);
      }
    } else if (activeMainTabIndex === MAIN_ACTIVE_INDEX.MEDICAL_NOTE) {
      const medNoteActiveTab =
        cardMedicalNoteRef?.current?.getMedicalNoteActiveTab();
      if (medNoteActiveTab === MEDICAL_NOTE_ACTIVE_INDEX.ORDER_SUMMARY) {
        setOrderSummaryPrintLoading(true);
        let printOrderSummaryResult = await props.onPrintOrderSummary(params);
        setOrderSummaryPrintLoading(false);

        // if ( printOrderSummaryResult && printOrderSummaryResult.status && printOrderSummaryResult.status === 200) {
        //   setResultPrinter(printOrderSummaryResult)
        // } else {
        //   setResultPrinter(printOrderSummaryResult)
        // }
      }
    }
  };

  const ResultPrinterModal = (props) => (
    <Modal open={props.resultPrinter} size="mini">
      <Header icon="archive" content="แจ้งเตือน" />
      <Modal.Content>
        {props.resultPrinter && props.resultPrinter.status === 200 ? (
          <p> พิมพ์สำเร็จ </p>
        ) : (
          <p> พิมพ์ไม่สำเร็จ โปรดลองอีกครั้ง </p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color={
            props.resultPrinter && props.resultPrinter.status === 200
              ? "green"
              : "red"
          }
          onClick={() => {
            setResultPrinter(false);
          }}
        >
          <Icon name="checkmark" /> OK
        </Button>
      </Modal.Actions>
    </Modal>
  );

  useEffect(() => {
    //console.log('TECSession Need update')
    if (patientId) {
      if (encounterId) {
        let prepare = new PatientData();
        prepare.setEmrId(medicalRecordId);
        prepare.setPatientId(patientId);
        prepare.setEncounterId(encounterId);
        //console.log('setPatientData')
        console.log(
          "called handleTECEncounterPD with encounterId: ",
          encounterId
        );
        setPatientDataTEC(prepare);
        props.handleTECEncounterPD(encounterId, prepare);
      }
    }

    // TODO: When Call by first time med, en = undefined, patientId = null should not set PatientDataTEC
  }, [encounterId, medicalRecordId, patientId]);

  // console.log("CardPHV Render ")

  return (
    <CardLayout
      titleText="Patient History Viewer"
      headerColor={props.showContentOnly ? "" : "pink"}
      toggleable={props.toggleable}
      closeable={props.closeable}
      hideHeaderIcon={props.showContentOnly}
      accordionTitleStyle={props.showContentOnly ? { display: "none" } : {}}
      style={props.showContentOnly ? { border: "none", boxShadow: "none" } : {}}
    >
      <Dimmer active={props.isLoading} inverted>
        <Loader />
      </Dimmer>
      <ResultPrinterModal resultPrinter={resultPrinter} />
      {!props.showContentOnly && (
        <Form>
          <Form.Group inline>
            <Form.Field>HN</Form.Field>
            <Form.Field>
              <PatientSearchBox
                controller={props.patientSearchController}
                forcedSelectHN={props.forcedSelectHN}
                //Action (Clear)
                ref={hnSearchRef}
                // dispatchAction={dispatchAction}
                //Data
                patientListLoading={props.patientListLoading}
                nationalitiesList={props.nationalitiesList}
                patientList={props.crdSearchPatientData}
                numberOfPage={props.patientListNumberOfPage}
                currentPage={props.patientCurrentPage}
                // callback
                getNationality={props.getNationality}
                getPatientList={props.getPatientList}
                onSelectPatient={handleOnSelectPatient}
                onGetPatientOldName={props.onGetPatientOldName}
                patientOldNameDataList={props.patientOldNameData}
                patientOldNameLoading={props.patientOldNameLoading}
                modalStyle={props.patientSearchBoxStyle}
                // onTypeHN={handleHNTypeChange}
                onPatientListPaginationChange={props.onPatientListPaginationChange}
              />
            </Form.Field>
            <Form.Field>แพทย์</Form.Field>
            <Form.Field>
              <DoctorSearchBox
                disabled={!!props.forcedSelectEncounterId}
                //Action (Clear)
                ref={doctorSearchRef}
                fluid={false}
                // dispatchAction={dispatchAction}
                //Data
                specialitiesList={props.specialitiesList}
                doctorList={props.doctorList}
                doctorListLoading={props.doctorListLoading}
                doctorListNumberOfPage={props.doctorListNumberOfPage}
                currentPage={props.doctorCurrentPage}
                // callback
                onPageChange={props.onDoctorListPageChange}
                getSearchDoctorList={props.getSearchDoctorList}
                getSpecialitiesList={props.getSpecialitiesList}
                onSelectDoctor={handleOnSelectDoctor}
              />
            </Form.Field>
            <Form.Field>
              <FilterDateRange
                disabled={!!props.forcedSelectEncounterId}
                //Action (Clear)
                dispatchAction={dispatchAction}
                dateTextBoxSize="large"
                //callback
                onStartDateChange={handleOnSelectStartDate}
                onEndDateChange={handleOnSelectEndDate}
                onFilterDate={handleOnClickFilterDate}
              />
            </Form.Field>
            <Form.Field>
              <Button
                type="reset"
                color="teal"
                onClick={handleClearAllSearch}
                disabled={!!props.forcedSelectEncounterId}
              >
                Clear
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      )}

      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <PatientPanel
              patientPanelData={props.patientPanelData}
              forcedSelectEncounterId={props.forcedSelectEncounterId}
              selectedRowEncounter={props.selectedRowEncounter}
              showContentOnly={props.showContentOnly}
              isLoading={patientPanelisLoading}
              handleOnChangeEncounterFilter={handleOnChangeEncounterFilter}
              handleOnChangeEncounterId={handleOnChangeEncounterId}
            />
          </Grid.Column>
          <Grid.Column width={props.showContentOnly ? 16 : 13}>
            <TabBar
              onActiveIndexChange={handleActiveIndexChange}
              isSecret={isSecret}
              showContentOnly={props.showContentOnly}
            >
              <CardEMRSummary
                key="emrSummary"
                isLoading={emrSummaryLoading}
                emrSummaryData={emrSummaryData}
                getEMRSummaryData={handleGetEmrSummary}
                onPrintEMRSummary={(data) =>
                  props.onPrintEMRSummary?.({ ...data, medicalRecordId })
                }
                closeable={false}
                toggleable={false}
              />
              {/* <CardMedicationRecord
                key="medications"
                closeable={false}
                toggleable={false}
                hideHeaderIcon={true}
                medRecordList={medicationData}
                getMedicationRecord={handleGetMedicationRecord}
                triggerMedicationRecord={triggerMedicationRecord}
                isLoading={medicationLoading}
                onMedicaltionRecordPrint={() => props.onMedicaltionRecordPrint({ encounterId })}
              /> */}
              <CardMedicationReconcile
                key="medications"
                closeable={false}
                toggleable={false}
                hideHeaderIcon={true}
                isLoading={medReconcileLoading}
                isLoadingModal={medReconcileLoadingModal}
                getMedReconciliation={handleGetMedReconciliation}
                getMedReconciliationDetail={handleGetMedReconciliationDetail}
                getMedReconciliationLog={handleGetMedReconciliationLog}
                medReconcileList={medReconcile}
                medReconcileDetail={medReconcileDetail}
                medRecordLog={medRecordLog}
              />
              <CardLabResult
                key="lab"
                closeable={false}
                toggleable={false}
                hideHeaderIcon={true}
                isLoading={labLoading}
                labCode={labCode}
                labDetail={labDetail}
                reportDetail={reportDetail}
                labError={labError}
                // postPrint={handleLabResultPrint}
                getLabCode={handleGetLabCode}
                getLabDetail={handleGetLabDetail}
                getInterfaceSummaryReportDetail={handleGetInterfaceSummaryReportDetail}
                patientId={triggerLabResult}
                patientInfo={
                  props.patientPanelData && props.patientPanelData.patientData
                    ? props.patientPanelData.patientData
                    : null
                }
              />
              <CardBloodTransfusionHistory
                django={props.django}
                controller={props.cardBloodTransfusionHistoryController}
                key="blood"
                closeable={false}
                patientId={props.patientPanelData?.patientData?.id}
                encounterId={encounterId}
                encounterDoctorId={encounterDoctorId}
              />
              {/* <CardImagingExamResult
                key="imagingExam"
                closeable={false}
                toggleable={false}
                hideHeaderIcon={true}
                getImagingExamResult={handleGetImagingExamResult}
                imagingExameResult={imagingExamResult}
                isLoading={imagingExamResultLoading}
                onOpenPACS={() => props.onOpenPACS({ hnPatient })}
              /> */}
              <CardImagingResult
                key="imagingExam"
                onEvent={props.onEvent}
                setProp={props.setProp}
                // seq
                runSequence={props.runSequence}
                ImagingResultSequence={props.ImagingResultSequence}
                // data
                encounterId={encounterId}
                orderStatus="APPROVED"
                selectedPatient={props.patientPanelData?.patientData}
                // CommonInterface
                buttonLoadCheck={props.buttonLoadCheck}
                errorMessage={props.errorMessage}
                searchedItemListWithKey={props.searchedItemListWithKey}
                modXrayDetail={props.modXrayDetail}
                // options
                masterOptions={props.masterOptions}
                // style
                cardStyle={{
                  boxShadow: "0 1px 2px 0 rgb(34 36 38 / 15%), 0 0 0 1px rgb(34 36 38 / 15%)",
                  borderTop: "2px solid #2185d0",
                }}
                tableHeight="calc(100vh - 34rem)"
                // config
                readOnly={true}
                hideColumnResult={true}
                // ----- Dental Diagram
                clinicalFindingId={props.clinicalFindingId}
                clinicalFindingIndex={props.clinicalFindingIndex}
                clinicalFindings={props.clinicalFindingList || []}
                organ={props.organ}
                clinicaltags={props.clinicaltags}
                filterClinicalFindingIds={props.filterClinicalFindingIds || []}
                // -----#
              />
              <CardMedicalNote
                ref={cardMedicalNoteRef}
                key="medicalNote"
                controller={props.cardMedicalNoteController}
                doctorSearchBoxController={props.doctorSearchBoxController}
                cardOperativeNoteViewController={props.cardOperativeNoteViewController}
                closeable={false}
                toggleable={false}
                hideHeaderIcon={true}
                encounterId={encounterId}
                patientId={patientId}
                encounterType={encounterType}
                getSpeciality={props.getSpeciality}
                onPrintClick={handlePrintClick}
                handleMedicalNoteSearch={handleMedicalNoteSearch}
                speicialityList={props.speicialityList}
                doctorList={props.doctorList}
                doctorSearchLoading={props.doctorSearchLoading}
                medicalNoteUserListLoading={props.medicalNoteUserListLoading}
                medicalNoteUserList={props.medicalNoteUserList}
                // Technician Record

                showTECSessionCard={(isSelected) => {
                  props.handleTECEncounterPD(encounterId, patientDataTEC);
                  props.showTECSessionCard(isSelected);
                }}
                // progression note
                progressionNote={props.progressionNote}
                getProgressionNote={props.getProgressionNote}
                progressNoteLoading={props.progressNoteLoading}
                // admission note
                admissionNote={props.admissionNoteData}
                admissionNoteLoading={props.admissionNoteLoading}
                getAdmissionNote={() => {
                  handleGetAdmissionNote({ medicalRecordId });
                }}
                // discharge summary
                dischargeSummaryData={dischargeSummaryData}
                onGetDischargeSummary={handleGetDisChargeSummary}
                // => props.onGetDischargeSummary({ medicalRecordId, encounterType })}
                dischargeSummaryLoading={props.dischargeSummaryLoading}
                // consult order
                consultNoteData={props.consultNoteData}
                onGetConsultNote={() => props.onGetConsultNote({ encounterId })}
                consultNoteLoading={props.consultNoteLoading}
                // treatment order
                treatmentNoteData={props.treatmentNoteData}
                treatmentNoteLoading={props.treatmentNoteLoading}
                // sensitive note
                sensitiveNote={props.sensitiveNoteData}
                onGetSensitiveNote={() => props.onGetSensitiveNote({ medicalRecordId })}
                sensitiveNoteLoading={props.sensitiveNoteLoading}
                // Image Gallery Viewer
                imageGallery={props.imageGalleryData}
                onGetImageGallery={() => props.onGetImageGallery({ medicalRecordId })}
                imageGalleryLoading={props.imageGalleryLoading}
                // order summary
                onGetOrderSummary={() =>
                  props.onGetOrderSummary({
                    startDate: cardMedicalNoteRef?.current?.getStartDate(),
                    endDate: cardMedicalNoteRef?.current?.getEndDate(),
                    medicalRecordId,
                    currentPage: currentPagination,
                    encounterType,
                    encounterId,
                  })
                }
                orderSummaryLoading={props.orderSummaryLoading}
                orderSummaryData={props.orderSummaryData}
                isPrintLoading={orderSummaryPrintLoading}
              />
              <CardNurseRecord
                key="nurseRecord"
                ref={nurseRecordRef}
                closeable={false}
                toggleable={false}
                hideHeaderIcon={true}
                encounterType={encounterType}
                activeIndex={activeNurseRecordIndex}
                setActiveIndex={setActiveNurseRecordIndex}
                onPrintClick={handlePrintClick}
                onPrintMultidisciplinary={props.onPrintMultidisciplinary}
                onStartDateChange={(startDate) => setNurseRecordStartDate(startDate)}
                onEndDateChange={(endDate) => setNurseRecordEndDate(endDate)}
                onNurseRecordSearch={handleNurseRecordSearch}
                // Nurse note
                onGetNurseNote={props.onGetNurseNote}
                nurseNote={props.nurseNote}
                nurseNoteLoading={props.nurseNoteLoading}
                // Patient Assessment
                onGetPatientAssessment={props.onGetPatientAssessment}
                patientAssessmentData={props.patientAssessmentData}
                patientAssessmentLoading={props.patientAssessmentLoading}
                clinicalTermData={props.clinicalTermData}
                // Discharge Planning
                onGetDischargePlanning={handleNurseRecordSearch}
                dischargePlanning={props.dischargePlanning}
                dischargePlanningLoading={props.dischargePlanningLoading}
                // Discharge Teaching
                onGetDischargeTeaching={handleNurseRecordSearch}
                reassessmentLoading={props.reassessmentLoading}
                reassessmentData={props.reassessmentData}
                // Multidisciplinary
                onGetMultidisciplinary={props.onGetMultidisciplinary}
                multidisciplinaryLoading={props.multidisciplinaryLoading}
                multidisciplinaryData={props.multidisciplinaryData}
                crdMultiDisciplinaryController={props.crdMultiDisciplinaryController}
                // ANC Record
                encounterId={encounterId}
                getANCRecordPDF={() => props.getANCRecordPDF({ encounterId })}
                // Nursgin Diagnosis
                getNurseDiagnosisResultGroup={() =>
                  props.getNurseDiagnosisResultGroup({
                    startDate: nurseRecordStartDate,
                    endDate: nurseRecordEndDate,
                    encounterId,
                  })
                }
                getNurseDiagnosisPDF={props.getNurseDiagnosisPDF}
              />
              <CardScannedDocument
                key="scannedDocument"
                closeable={false}
                toggleable={false}
                hideHeaderIcon={true}
                callGetScannedDoc={callGetScannedDoc}
                onGetScannedDocument={handleScannedDocument}
                scannedDocLoading={props.scannedDocLoading}
                scannedDocData={props.scannedDocData}
                onGetScannedDocumentOptions={props.onGetScannedDocumentOptions}
                scannedDocOptions={props.scannedDocOptions}
                onGetSearchUserList={props.onGetSearchUserList}
                userList={props.userList}
                userListLoading={props.userListLoading}
                onGetSearchDoctorList={props.onGetSearchDoctorList}
                doctorList={props.scanDocDoctorList}
                doctorListLoading={props.doctorListLoading}
                numberOfPage={props.scannedDocNumberOfPage}
                currentPage={props.currentScannedDocPage}
                onPaginationChange={handleScannedDocument}
                onGetDocumentType={props.onGetDocumentType}
              />

              <CardVitalSign
                key="vitalSign"
                closeable={false}
                toggleable={false}
                callOnDidMount={false}
                onGetVitalSign={({ startDate, endDate, startTime, endTime, limit, offset }) =>
                  props.onGetVitalSign({
                    startDate,
                    endDate,
                    startTime,
                    endTime,
                    encounterId,
                    limit,
                    offset,
                  })
                }
                callGetVitalSign={callGetVitalSign}
                vitalSignLoading={props.vitalSignLoading}
                vitalSignData={props.vitalSign}
                onGetVitalSignType={() => props.onGetVitalSignType({ encounterId })}
                onGetLastHeight={props.onGetLastHeight}
                vitalSignType={props.vitalSignType}
                readOnly={true}
                encounterId={encounterId}
                useUTCTime={true}
              />
              <CardSecretEncounter
                key="secretEncounter"
                closeable={false}
                toggleable={false}
                hideHeaderIcon={true}
                defaultBackground={false}
                secretEncounterLoading={props.secretEncounterLoading}
                secretEncounterData={props.secretEncounterData}
                onSaveSecretEncounter={(secretEncounter) =>
                  props.onSaveSecretEncounter({ secretEncounter, encounterId })
                }
                onGetSecretEncounter={() => {
                  props.onGetSecretEncounter({ patientId });
                }}
                onPostSecretEncounter={async (secretEncounter) => {
                  await props.onSaveSecretEncounter({
                    patientId,
                    secretEncounter,
                  });
                  // Delay this
                  setTimeout(() => {
                    reloadEncounter();
                  }, 10);
                }}
              />
              <div key="orHistory">
                <CardORHistory
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // controller
                  proxyController={props.proxyController}
                  subICD10DetailController={props.subICD10DetailController}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  django={props.django}
                  division={props.division}
                  // options
                  masterOptions={props.masterOptions}
                  masterData={props.masterData}
                  ChoiceTriage={props.ChoiceTriage}
                  // seq
                  runSequence={props.runSequence}
                  AssessmentSequence={props.AssessmentSequence}
                  HistoryCentralLabSequence={props.HistoryCentralLabSequence}
                  ORHistorySequence={props.ORHistorySequence}
                  ORPostOperationSequence={props.ORPostOperationSequence}
                  ORRequestSequence={props.ORRequestSequence}
                  PerioperativeNursingSequence={props.PerioperativeNursingSequence}
                  PreOperationSequence={props.PreOperationSequence}
                  // SearchBox
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  // data
                  encounterId={encounterId}
                  selectedPatient={props.selectedPatient}
                  loadingStatus={props.loadingStatus}
                  userTokenize={props.userTokenize}
                />
              </div>
              <CardMedDetail
                key="medDetail"
                closeable={false}
                toggleable={false}
                hideHeaderIcon={true}
                defaultBackground={false}
                isLoading={homeMedDetailLoading}
                homeMedDetail={homeMedDetail}
                getHomeMedDetail={handleGetHomeMedDetail}
              />
            </TabBar>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Button.Group
        style={{
          ...styles.buttonGroup,
          display:
            (activeMainTabIndex === MAIN_ACTIVE_INDEX.MEDICAL_NOTE &&
              MEDICAL_NOTE_HIDE_PAGINATION.includes(
                cardMedicalNoteRef?.current?.getMedicalNoteActiveTab()
              )) ||
            (activeMainTabIndex === MAIN_ACTIVE_INDEX.NURSE_RECORD &&
              NURSE_RECORD_HIDE_PAGINATION.includes(activeNurseRecordIndex)) ||
            MAIN_HIDE_PAGINATION.includes(activeMainTabIndex)
              ? "none"
              : "block",
        }}
      >
        <Pagination
          totalPages={Math.ceil(totalPage)}
          activePage={currentPagination}
          onPageChange={(e, data) => {
            setCurrentPagination(data.activePage);
          }}
        />
      </Button.Group>
    </CardLayout>
  );
};

CardPHV.defaultProps = {
  django: null,

  controller: null,
  cardBloodTransfusionHistoryController: null,
  doctorSearchBoxController: null,
  cardOperativeNoteViewController: null,
  patientSearchController: null,
  patientSearchBoxStyle: {},
  hnOnDidMount: false,
  filterOnSelectPatient: false,
  showContentOnly: false,

  onGetSecretEncounter: () => {},
  onGetClinicalTerm: () => {},
  getEncounterHistory: () => {},
  getANCRecordPDF: () => [null, null],
  getNurseDiagnosisResultGroup: () => [null, null],
  getNurseDiagnosisPDF: () => [null, null],
};

export default CardPHV;
