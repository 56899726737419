import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
// INF
import DFPayrollItemList from "issara-sdk/apis/DFPayrollItemList_apps_INF";
import DFPayrollItemOverview from "issara-sdk/apis/DFPayrollItemOverview_apps_INF";
import DFPayrollItemOverviewReport from "issara-sdk/apis/DFPayrollItemOverviewReport_apps_INF";
import DFPayrollItemOverviewSummary from "issara-sdk/apis/DFPayrollItemOverviewSummary_apps_INF";
import DFPayrollItemReport from "issara-sdk/apis/DFPayrollItemReport_apps_INF";
import DFPayrollItemSummary from "issara-sdk/apis/DFPayrollItemSummary_apps_INF";

import DFPayrollItemOverviewSerializer from "issara-sdk/types/DFPayrollItemOverviewSerializer_apps_INF";
import DFPayrollItemSerializerI from "issara-sdk/types/DFPayrollItemSerializer_apps_INF";

// Common
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { SetErrorMessage, SetProperty } from "react-lib/apps/HISV3/common/CommonInterface";

// PDF
import FormDFPayrollItemReport from "../../CLM/pdf/FormDFPayrollItemReport";
import FormDoctorIncomeSummary from "../../BIL/pdfFormBil/FormDoctorIncomeSummary";

import { State as MainState } from "HIS/MainHISInterface";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

export type State = Partial<{
  // sequence
  DoctorFeeReportSequence: Partial<
    {
      sequenceIndex: "START" | NextIndexType | null;
      dfPayrollDesc: DFPayrollDescription;
      dfPayrollOverview: DFPayrollOverview;
      searchListAbort: AbortController | null;
    } & AllFilterType
  > | null;
}>;

type PickedState = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage" | "successMessage">>;

export type PickedProps = Partial<Omit<PickedState, "">>;

export type AllFilterType = {
  filterDesc: Partial<FilterDFReport>;
  filterOverview: Partial<FilterDFReport>;
};

export type FilterAllValues = FilterDFReport[keyof FilterDFReport];

export type FilterDFReport = {
  division: number | string;
  endDate: string;
  period: string;
  startDate: string;
  status: string;
  workingType: string;
};

export type DFPayrollDescription = {
  items: DFPayrollItemSerializer[];
  summary: Partial<{
    final_revenue_credit: number;
    final_revenue_point: number;
    total_compensation_price: number;
    total_payable: number;
    total_revenue_credit: number;
    total_revenue_outpatient: number;
    total_revenue_point: number;
    total_revenue_price: number;
    total_send_claim: number;
  }>;
};

export type DFPayrollItemSerializer = {
  patient_pay_status_label?: string;
} & DFPayrollItemSerializerI;

export type DFPayrollOverview = {
  items: DFPayrollItemOverviewSerializer[];
  summary: Partial<{
    final_revenue_credit: number;
    final_revenue_point: number;
    total_compensation_price: number;
    total_lab_deduct_doctor: number;
    total_lab_price: number;
    total_paid_price: number;
    total_point: number;
    total_quantity: number;
    total_revenue_credit: number;
    total_revenue_outpatient: number;
    total_revenue_point: number;
    total_revenue_price: number;
    total_waiting_claim_price: number;
    total_waiting_claim_price_cumulative: number;
  }>;
};

export type MasterOptionsType = Partial<Record<(typeof Masters)[number][0], OptionType[]>>;

export type OptionType = {
  key: number | string;
  text: string;
  value: number | string;
};

// Sequence
type NextIndexType = "Description" | "Overview";

type SeqState = {
  sequence: "DoctorFeeReport";
  clear?: boolean;
  nextIndex?: NextIndexType;
  noInit?: boolean;
  restart?: boolean;
};

// Handle Action
type ActionType =
  // Search
  | {
      action: "SEARCH";
    }
  // Action
  | { action: "DF_PAYROLL_REPORT"; card: string }
  | { action: "INIT" };

type SeqAct = ActionType & SeqState;

type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  DoctorFeeReportSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  device?: number;
  division?: number;
  masterData?: Record<string, any>;
};

export const DataInitial = {};

const Masters = [
  ["division", {}],
  ["period", {}],
  ["workingType", {}],
] as const;

export const CARD_DF_REPORT = "CardDoctorFeeReport";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_DF_REPORT}_${T[K] & string}`;
};

export const ACTIONS = {
  INIT: "INIT",
  DF_PAYROLL_REPORT: "DF_PAYROLL_REPORT",
  SEARCH: "SEARCH",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${CARD_DF_REPORT}_${key}`])
) as Optional<typeof ACTIONS>;

export const PAYMENT_STATUS_OPTIONS = [
  { key: 2, text: "ชำระเงินแล้ว", value: "PAID" },
  { key: 3, text: "ค้างชำระ", value: "BILLED" },
];

const momentDate = moment();

const DEFAULT_FILTER = {
  division: "ALL",
  endDate: formatDate(momentDate),
  period: "ALL",
  startDate: formatDate(momentDate.clone().add(-1, "days")),
  status: "ALL",
  workingType: "ALL",
};

type Handler<P = unknown, R = void> = (
  controller: WasmController<PickedState & State, Event, Data>,
  ...params: unknown extends P ? [params?: P] : [params: P]
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const Start: Handler<SeqState> = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  Next(controller, params);
};

/* ------------------------------------------------------ */

/*                          Next                          */

/* ------------------------------------------------------ */
const Next: Handler<SeqState> = (controller, params) => {
  const state = controller.getState();

  const { nextIndex, noInit } = params;

  if (!nextIndex) {
    return;
  }

  state.DoctorFeeReportSequence?.searchListAbort?.abort();

  controller.setState(
    {
      DoctorFeeReportSequence: { ...state.DoctorFeeReportSequence, sequenceIndex: nextIndex },
    },
    () => {
      const init = {
        Description,
        Overview,
      }[nextIndex];

      if (!noInit) {
        init(controller, { ...params, action: ACTIONS.INIT, nextIndex: undefined });
      }
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Description: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    Next(controller, params);

    return;
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.INIT]: HandleInitDescription,
    [ACTIONS.DF_PAYROLL_REPORT]: HandleDFPayrollItemReport,
    [ACTIONS.SEARCH]: HandleSearchPayrollItem,
  };

  const { action } = params;

  actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

export const Overview: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    Next(controller, params);

    return;
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.INIT]: HandleInitOverview,
    [ACTIONS.DF_PAYROLL_REPORT]: HandleDFPayrollOverviewReport,
    [ACTIONS.SEARCH]: HandleSearchPayrollOverview,
  };

  const { action } = params;

  actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

/* ------------------------------------------------------ */

/*                   Handle Description                   */

/* ------------------------------------------------------ */
const HandleInitDescription: Handler<Params<"INIT">> = (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeReportSequence;
  const filter = { ...DEFAULT_FILTER, ...seq?.filterDesc };

  controller.setState(
    {
      DoctorFeeReportSequence: { ...seq, filterDesc: filter },
    },
    () => {
      HandleSearchPayrollItem(controller);
    }
  );
};

const HandleInitOverview: Handler<Params<"INIT">> = (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeReportSequence;
  const filter = { ...DEFAULT_FILTER, ...seq?.filterOverview };

  controller.setState(
    {
      DoctorFeeReportSequence: { ...seq, filterOverview: filter },
    },
    () => {
      HandleSearchPayrollOverview(controller);
    }
  );
};

const HandleSearchPayrollItem: Handler = async (controller) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const result = await GetDFPayrollItemList(controller);

  if (result.message) {
    return;
  }

  state = controller.getState();

  controller.setState({
    DoctorFeeReportSequence: {
      ...state.DoctorFeeReportSequence,
      dfPayrollDesc: result.data,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

const HandleDFPayrollItemReport: Handler<Params<"DF_PAYROLL_REPORT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DF_PAYROLL_REPORT]: "LOADING" },
  });

  const filter = state.DoctorFeeReportSequence?.filterDesc;

  const [result, error] = await GetDFPayrollReport(controller, {
    filter,
    reportApi: DFPayrollItemReport,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });

    return;
  }

  // CreatePDF
  console.log(result);

  const doctorFeePayrollItemData = {
    filter,
    result,
  };

  const docDFPayrollItemDef: any = await FormDFPayrollItemReport(doctorFeePayrollItemData);

  const pdfMakeInstance = await getPdfMake(true);

  const pdf = pdfMakeInstance.createPdf(docDFPayrollItemDef);

  pdf.print();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DF_PAYROLL_REPORT]: "SUCCESS" },
  });
};

const HandleDFPayrollOverviewReport: Handler<Params<"DF_PAYROLL_REPORT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DF_PAYROLL_REPORT]: "LOADING" },
  });

  const filter = state.DoctorFeeReportSequence?.filterOverview;

  const [result, error] = await GetDFPayrollReport(controller, {
    filter,
    reportApi: DFPayrollItemOverviewReport,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });

    return;
  }

  // CreatePDF
  const docDef: any = await FormDoctorIncomeSummary({
    ...result.params,
    items: result.fields.map((item) => ({
      ...item,
      leftover: item.leftover_revenue_price,
      net_price: item.total_net,
      revenue: item.revenue_price,
    })),
  });

  const pdfMake = await getPdfMake(true);
  const pdf = pdfMake.createPdf(docDef);

  pdf.open();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DF_PAYROLL_REPORT]: "SUCCESS" },
  });
};

const HandleSearchPayrollOverview: Handler = async (controller) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const result = await GetDFPayrollOverviewList(controller);

  if (result.message) {
    return;
  }

  state = controller.getState();

  controller.setState({
    DoctorFeeReportSequence: {
      ...state.DoctorFeeReportSequence,
      dfPayrollOverview: result.data,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */
const GetDFPayrollItemList: Handler<
  unknown,
  Promise<{ data: DFPayrollDescription; message: string }>
> = async (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeReportSequence;
  const filter = seq?.filterDesc;

  if (!(filter?.startDate && filter.endDate)) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: null },
    });

    return { data: { items: [], summary: {} }, message: "required" };
  }

  return GetDFPayrollList(controller, {
    filter,
    listApi: DFPayrollItemList.list,
    summaryApi: DFPayrollItemSummary,
  });
};

const GetDFPayrollOverviewList: Handler<
  unknown,
  Promise<{ data: DFPayrollOverview; message: string }>
> = async (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeReportSequence;
  const filter = seq?.filterOverview;

  if (!(filter?.startDate && filter.endDate)) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: null },
    });

    return { data: { items: [], summary: {} }, message: "required" };
  }

  return GetDFPayrollList(controller, {
    filter,
    listApi: DFPayrollItemOverview.get,
    summaryApi: DFPayrollItemOverviewSummary,
  });
};

const GetDFPayrollList: Handler<
  {
    filter?: Partial<FilterDFReport>;
    listApi: typeof DFPayrollItemList.list | typeof DFPayrollItemOverview.get;
    summaryApi: typeof DFPayrollItemOverviewSummary | typeof DFPayrollItemSummary;
  },
  Promise<{ data: { items: Record<string, any>[]; summary: Record<string, any> }; message: string }>
> = async (controller, params) => {
  const state = controller.getState();

  const seq = state.DoctorFeeReportSequence;
  const abortCtrl = new AbortController();

  controller.setState({
    DoctorFeeReportSequence: { ...seq, searchListAbort: abortCtrl },
  });

  const formatParams = (key: keyof FilterDFReport) => {
    const value = params.filter?.[key];

    return value && value !== "ALL" ? value : undefined;
  };

  const urlParams = {
    division: formatParams("division"),
    end_date: formatParams("endDate"),
    patient_pay_status_name: formatParams("status"),
    period: formatParams("period"),
    start_date: formatParams("startDate"),
    working_type: formatParams("workingType"),
  };

  const [list, sum] = await Promise.all([
    params.listApi({
      apiToken: controller.apiToken,
      params: urlParams,
      extra: {
        signal: abortCtrl.signal,
      },
    }),
    params.summaryApi.get({
      apiToken: controller.apiToken,
      params: urlParams,
    }),
  ]);

  if (list[2].message === "canceled") {
    return { data: { items: [], summary: {} }, message: list[2].message };
  }

  return {
    data: { items: list[0]?.items || [], summary: sum[0] || {} },
    message: "",
  };
};

const GetDFPayrollReport: Handler<
  {
    filter?: Partial<FilterDFReport>;
    reportApi: typeof DFPayrollItemOverviewReport | typeof DFPayrollItemReport;
  },
  Promise<[{ fields: Record<string, any>[]; params: any }, unknown]>
> = async (controller, params) => {
  const formatParams = (key: keyof FilterDFReport) => {
    const filterValue = params.filter?.[key];

    return filterValue && filterValue !== "ALL" ? filterValue : undefined;
  };

  return params.reportApi.get({
    apiToken: controller.apiToken,
    params: {
      division: formatParams("division"), // params division (สำหรับ debug)
      end_date: formatParams("endDate"),
      period: formatParams("period"),
      start_date: formatParams("startDate"),
      status: formatParams("status"),
      working_type: formatParams("workingType"),
    },
  }) as Promise<[{ fields: Record<string, any>[]; params: any }, unknown]>;
};
