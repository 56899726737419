import { TDocumentDefinitions } from "pdfmake/interfaces";

// Types
type FormMedicalExpenseInvoiceProps = {
  print_date_time: string;
  print_user: string;
  start_date: string;
  end_date: string;
  coverage_name: string;
  payer_name: string;
  user_count: number;
  total_treatment_price: number;
  total_xray_price: number;
  total_drug_price: number;
  total_combine_price: number;
  items: {
    seq: number;
    patient_name: string;
    relationship: string;
    treatment_price: number;
    xray_price: number;
    drug_price: number;
    combine_price: number;
    performed_date: string;
    employee_no: string;
  }[];
};

const FONT_SIZE = 14;

const TB_MARGIN = 3;

const formatPrice = (value: number | string) => {
  return value !== ""
    ? Number(value).toLocaleString("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    : "";
};

const FormMedicalExpenseInvoice = (props: FormMedicalExpenseInvoiceProps): TDocumentDefinitions => {
  return {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: FONT_SIZE,
    },
    pageMargins: [10, 115, 10, 105],
    styles: {
      fieldHeader: {
        fontSize: 15,
        alignment: "center",
      },
      tableHeader: {
        alignment: "center",
      },
      miniField: {
        fontSize: 10,
      },
    },
    header: () => {
      return {
        margin: [10, 20, 10, 0],
        stack: [
          {
            table: {
              headerRows: 1,
              widths: ["100%"],
              body: [
                [
                  {
                    stack: [
                      {
                        text: "คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                        style: "fieldHeader",
                      },
                      {
                        text: `ใบแจ้งหนี้ค่ารักษาพยาบาลทางทันตกรรม ${props.coverage_name}`,
                        style: "fieldHeader",
                        marginTop: 2.5,
                      },
                      { text: props.payer_name || "-", style: "fieldHeader" },
                      {
                        text: `วันที่ออกใบแจ้งหนี้ ${props.start_date} - ${props.end_date}`,
                        style: "fieldHeader",
                        marginTop: 2.5,
                      },
                    ],
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: () => 0,
              vLineWidth: () => 0,
            },
          },
        ],
      };
    },
    content: [
      {
        table: {
          headerRows: 1,
          dontBreakRows: true,
          widths: ["5%", "21%", "10.5%", "10%", "10%", "10%", "10%", "12.5%", "11%"],
          body: [
            [
              { text: "ลำดับ ที่", style: "tableHeader" },
              { text: "ชื่อ-สกุล", style: "tableHeader", marginTop: (FONT_SIZE + TB_MARGIN) / 2 },
              {
                text: "ความสัมพันธ์",
                style: "tableHeader",
                marginTop: (FONT_SIZE + TB_MARGIN) / 2,
              },
              { text: "ค่ารักษา พยาบาล", style: "tableHeader" },
              {
                text: "ค่าเอ็กซเรย์",
                style: "tableHeader",
                marginTop: (FONT_SIZE + TB_MARGIN) / 2,
              },
              { text: "ค่ายา", style: "tableHeader", marginTop: (FONT_SIZE + TB_MARGIN) / 2 },
              { text: "ยอดรวม", style: "tableHeader", marginTop: (FONT_SIZE + TB_MARGIN) / 2 },
              {
                text: "วันที่รับการรักษา",
                style: "tableHeader",
                marginTop: (FONT_SIZE + TB_MARGIN) / 2,
              },
              { text: "บัตรพนักงาน บัตรครอบครัว", style: "tableHeader" },
            ],
            ...props.items.map((item, index) => {
              return [
                { text: `${item.seq}.`, alignment: "center" },
                { text: item.patient_name },
                { text: item.relationship },
                { text: formatPrice(item.treatment_price), alignment: "right" },
                { text: formatPrice(item.xray_price), alignment: "right" },
                { text: formatPrice(item.drug_price), alignment: "right" },
                { text: formatPrice(item.combine_price), alignment: "right" },
                { text: item.performed_date, alignment: "center" },
                { text: item.employee_no, alignment: "center" },
              ];
            }),
            [
              {
                text: "จำนวนเงินรวมทั้งสิ้น",
                alignment: "center",
                colSpan: 3,
              },
              "",
              "",
              { text: formatPrice(props.total_treatment_price), alignment: "right" },
              { text: formatPrice(props.total_xray_price), alignment: "right" },
              { text: formatPrice(props.total_drug_price), alignment: "right" },
              { text: formatPrice(props.total_combine_price), alignment: "right" },
              {
                colSpan: 2,
                text: `ผู้ใช้สิทธิทั้งหมด ${props.user_count} ราย`,
                alignment: "center",
              },
              "",
            ],
          ],
        },
        layout: {
          hLineWidth: (i) => {
            return i <= 1 || i >= props.items.length + 1 ? 0.25 : 0;
          },
          vLineWidth: () => {
            return 0.25;
          },
          paddingTop: (i) => (i === props.items.length + 2 ? 8 : TB_MARGIN),
          paddingBottom: (i) => (i === props.items.length + 2 ? 8 : TB_MARGIN),
        },
      },
    ],
    footer: (currentPage, pageCount) => {
      return {
        margin: [10, 0, 10, 0],
        stack: [
          {
            marginTop: 17.5,
            text: Array.from({ length: 80 }).fill("").join("."),
            alignment: "center",
          },
          { text: "ศาสตราจารย์ ทันตแพทย์ ดร.พรชัย จันศิษย์ยานนท์", alignment: "center" },
          { text: "คณบดี คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย", alignment: "center" },
          {
            marginTop: 10,
            text: [`วันที่พิมพ์ : ${props.print_date_time}`, ` หน้า ${currentPage}/${pageCount}`],
            alignment: "right",
          },
        ],
      };
    },
  };
};

export default FormMedicalExpenseInvoice;
