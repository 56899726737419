import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import ModEventDetailUX from "./ModEventDetailUX";

// Utils
import { formatADtoBEString } from "react-lib/utils/dateUtils";

// Types
type Provider = {
  dsb: Record<string, any>;
};

type ModEventDetailProps = {
  onEvent: (params: { message: string; params: any }) => void;
  chairList: any[];
  divisionDict: Record<string, { name: string }>;
  isShowChair?: boolean;
  providers: Provider[];
  selectedAppointment?: any;
  selectedBlock: any;
  title: string;
  setEstimatedAt?: (at: string) => void;
  setEstimatedDuration?: Dispatch<SetStateAction<string>>;
  setShowMod?: (show: boolean) => void;
  setTab?: (tab: string) => void;
};

const ModEventDetail = (props: ModEventDetailProps) => {
  const handleRowClick = useCallback(
    (rowInfo: any) => {
      if (!props.selectedAppointment || rowInfo?.original?.doctor_dsb_full) {
        return;
      }

      console.log("select provider:", rowInfo?.original);
      props.onEvent({
        message: "AssignAppointmentToBlock",
        params: rowInfo?.original,
      });

      console.log(
        "rowInfo?.original?.doctor_dsb_slot_length:",
        rowInfo?.original?.doctor_dsb_slot_length
      );

      if (props.setShowMod) {
        props.setShowMod(false);
      }

      if (props.setTab) {
        props.setTab("detail");
      }

      if (props.setEstimatedAt) {
        props.setEstimatedAt("");
      }

      if (props.setEstimatedDuration) {
        props.setEstimatedDuration(rowInfo?.original?.doctor_dsb_slot_length);
      }
    },
    [
      props.selectedAppointment,
      props.setEstimatedAt,
      props.setEstimatedDuration,
      props.setShowMod,
      props.setTab,
    ]
  );

  const handleRowProps = useCallback(
    (state: any, rowInfo: any) => ({
      style: {
        backgroundColor:
          props.selectedBlock && rowInfo?.original?.dsb_id === props.selectedBlock?.dsb_id
            ? "#cccccc"
            : "white",
        cursor: "pointer",
      },
      onClick: () => {
        handleRowClick(rowInfo);
      },
    }),
    [handleRowClick, props.selectedBlock]
  );

  const formattedProviders = useMemo(
    () =>
      props.providers
        .filter((item) => item.dsb.doctor_dsb_status !== 2)
        .filter((item) => item.dsb.doctor_provider_type === "Doctor")
        .map((item) => {
          const totalMinutes =
            (Number.parseFloat(item.dsb.doctor_end_time) -
              Number.parseFloat(item.dsb.doctor_start_time)) *
            60;
          const slotCount = totalMinutes / item.dsb.doctor_dsb_slot_length;

          const baseProvider = {
            ...item.dsb,
            chair: item.dsb.chair_provider_id
              ? props.chairList.find((acc) => acc.provider === item.dsb.chair_provider_id)?.name
              : "ว่าง",
            date: formatADtoBEString(item.dsb.date),
            doctor_provider_type: item.dsb.doctor_provider_type,
            dsb: item.dsb,
            number_appointments: `${item.dsb.appointments?.length} (${Math.floor(slotCount)})`,
            zone: item.dsb.chair_zone_id ? props.divisionDict[item.dsb.chair_zone_id].name : "ว่าง",
          };

          if (item.dsb.doctor_dsb_full) {
            return {
              ...baseProvider,
              chair: <div style={{ color: "#4F4F4F" }}>{baseProvider.chair}</div>,
              date: <div style={{ color: "#4F4F4F" }}>{formatADtoBEString(item.dsb.date)}</div>,
              doctor_end_time: <div style={{ color: "#4F4F4F" }}>{item.dsb.doctor_end_time}</div>,
              doctor_provider_name: (
                <div style={{ color: "#4F4F4F" }}>{item.dsb.doctor_provider_name}</div>
              ),
              doctor_start_time: (
                <div style={{ color: "#4F4F4F" }}>{item.dsb.doctor_start_time}</div>
              ),
              number_appointments: <div style={{ color: "#4F4F4F" }}>FULL</div>,
              zone: <div style={{ color: "#4F4F4F" }}>{baseProvider.zone}</div>,
            };
          }

          return baseProvider;
        }),
    [props.chairList, props.divisionDict, props.providers]
  );

  return (
    <ModEventDetailUX
      isShowChair={props.isShowChair}
      providers={formattedProviders}
      rowProps={handleRowProps}
      title={props.title}
    />
  );
};

ModEventDetail.displayName = "ModEventDetail";

export default React.memo(ModEventDetail);
