import WasmController from "react-lib/frameworks/WasmController";

// APIS
import ChatChannelNoSubscriptionList from "issara-sdk/apis/ChatChannelNoSubscriptionList_apps_MSG";
import UserChatSubscriptionList from "issara-sdk/apis/UserChatSubscriptionList_apps_MSG";
import ChatChannelList from "issara-sdk/apis/ChatChannelList_apps_MSG";
import UserChatSubscriptionDetail from "issara-sdk/apis/UserChatSubscriptionDetail_apps_MSG";
import KickUserChatChannelDetail from "issara-sdk/apis/KickUserChatChannelDetail_apps_MSG";
import ChatChannelDetail from "issara-sdk/apis/ChatChannelDetail_apps_MSG";
import MessageSearchView from "issara-sdk/apis/MessageSearchView_apps_MSG";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";

export type State = {
  // common state
  django?: any;
  currentDoctor?: any;

  chatRoomList?: any[];
  allChatRoomList?: number[];
  userChatSubscriptionList?: any[];
  showIconNoti?: boolean;
  goToStaffChat?: boolean;
  searchMessageList?: Record<string, any>;
  modOpenLinkEncounter?: string;
};

export const StateInitial: State = {
  chatRoomList: [],
  allChatRoomList: [],
  searchMessageList: {},
  userChatSubscriptionList: [],
  goToStaffChat: false,
};

export type Event =
  | {
      message: "GetChatChannelList";
      params: { isPatient: boolean; user: string; all?: boolean };
    }
  | {
      message: "GetUnreadMessageCountStaff";
      params: { isPatient: boolean; user: string; all?: boolean };
    }
  | {
      message: "CreateUpdateChatRoom";
      params: { userIds: number[]; groupName: string };
    }
  | { message: "GetUserChatSubscriptionList"; params: { id: number } }
  | { message: "GetSearchMessageList"; params: { search: string } }
  | {
      message: "HandleOpenLinkEncounter";
      params: {
        type?: string;
        event?: MouseEvent;
        encounterId: string;
        href?: string;
        django?: any;
        replace?: string;
        doctorId?: number;
      };
    };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

const formatMembers: Handler = async (
  controller,
  params: { items: any[]; channelId: string | number; chatRoomList: any[] }
) => {
  let { items } = params;

  if (params.channelId === "all") {
    let promiseArr: any[] = [];

    promiseArr = items
      .map((item: any) => item.chat_channel_id)
      .map((id) =>
        UserChatSubscriptionList.list({
          apiToken: controller.apiToken,
          params: { chat_channel_id: id },
        })
      );

    const response = await Promise.all(promiseArr);

    items = items.map((item, index) => ({
      ...item,
      chat_channel_name_autogen: item.member_count === 1 ? "ห้องว่าง": item.chat_channel_name_autogen,
      name: item.member_count === 1 ? "" : item.name,
      members: response[index][0]?.items || [],
    }));
  } else if (params.channelId) {
    const [member] = await UserChatSubscriptionList.list({
      apiToken: controller.apiToken,
      params: { chat_channel_id: params.channelId },
    });

    items = items.map((item, index) => ({
      ...item,
      chat_channel_name_autogen: item.member_count === 1 ? "ห้องว่าง" : item.chat_channel_name_autogen,
      name: item.member_count === 1 ? "" : item.name,
      members:
        params.channelId === item.chat_channel_id
          ? member?.items || []
          : (params.chatRoomList || []).find(
              (acc) => item.chat_channel_id === acc.chat_channel_id
            )?.members || [],
    }));
  }

  return items;
};

export const GetChatChannelList: Handler = async (controller, params) => {
  const state = controller.getState();

  const chat = await ChatChannelNoSubscriptionList.list({
    apiToken: controller.apiToken,
    params: {
      employee_only: true,
      search: params.search,
      // user_type: params.isPatient ? "PATIENT" : "STAFF",
      // user: params.user
    },
  });

  let items: any[] = chat[0]?.items || [];
  let allItems = state.allChatRoomList || [];

  if (!params.all) {
    items = await formatMembers(controller, {
      items,
      channelId: params.channelId,
      chatRoomList: state.chatRoomList || [],
    });
  } else {
    allItems = items.map((item: any) => item.chat_channel_id);
  }

  const isNewChat = allItems.find(
    (id) => params.channelId !== id && typeof params.channelId === "number"
  );

  if (isNewChat) {
    allItems = [Number(params.channelId), ...allItems];
  }

  const isUnread = GetUnreadMessageCount(controller, {
    chatList: items,
    chatRoomId: params.chatRoomId,
  });

  controller.setState({
    [params.all ? "allChatRoomList" : "chatRoomList"]: [...items],
    allChatRoomList: allItems,
    showIconNoti: isUnread,
  });

  return items;
};

export const GetUnreadMessageCountStaff: Handler = async (
  controller,
  params
) => {
  await GetChatChannelList(controller, params);
};

export const GetUserChatSubscriptionList: Handler = async (
  controller,
  params
) => {
  if (!params.chat_channel_id) {
    return controller.setState({ userChatSubscriptionList: [] });
  }

  const chat = await UserChatSubscriptionList.list({
    apiToken: controller.apiToken,
    params,
  });

  controller.setState({
    userChatSubscriptionList: chat[0].items || [],
  });
};

export const CreateUpdateChatRoom: Handler = async (controller, params) => {
  if (params.type === "create") {
    let chat: any = [null,null,null];

    if (!params.notChangeName){
      chat = await (params.chatChannelId
        ? ChatChannelDetail.update
        : ChatChannelList.create)({
          apiToken: controller.apiToken,
          pk: params.chatChannelId,
          data: {
            name: params.groupName,
            basic: "true",
          } as any,
          extra: {
            division: controller.data.division,
          },
        });
    }

    return (await params.userIds?.length)
      ? UserChatSubscriptionList.create({
          apiToken: controller.apiToken,
          data: {
            list_user_id: params.userIds,
            chat_channel_id: params.chatChannelId || chat[0].chat_channel_id,
            action: params.action,
            force: "true",
          } as any,
          extra: {
            division: controller.data.division,
          },
        })
      : [null, null, null];
  } else if (params.type === "delete") {
    // if (params.isChangeName) {
    //   await CreateUpdateChatRoom(controller, {
    //     ...params,
    //     type: "change_name",
    //   });
    // }

    return await KickUserChatChannelDetail.update({
      apiToken: controller.apiToken,
      pk: params.chatChannelId,
      data: {
        list_user_id: params.userIds,
        action: "KICK",
      } as any,
    });
  } else if (params.type === "leave") {
    // if (params.isChangeName) {
    //   await CreateUpdateChatRoom(controller, {
    //     ...params,
    //     type: "change_name",
    //   });
    // }

    return await UserChatSubscriptionDetail.update({
      apiToken: controller.apiToken,
      pk: params.userChatSubscriptionId,
      data: {
        action: "LEAVE",
      },
    });
  } else if (params.type === "change_name") {
    return await ChatChannelDetail.update({
      apiToken: controller.apiToken,
      pk: params.chatChannelId,
      data: {
        name: params.groupName,
        basic: "true",
      } as any,
      extra: {
        division: controller.data.division,
      },
    });
  }
};

const GetMessageSearch: Handler = (controller, params) => {
  return MessageSearchView.list({
    apiToken: controller.apiToken,
    chat_channel_id: params.chatChannelId,
    params: {
      search: params.search,
      offset: params.offset,
      last: params.last,
    },
  });
};

export const GetSearchMessageList: Handler = async (controller, params) => {
  if (params.clear) {
    return controller.setState({ searchMessageList: {} });
  }

  const state = controller.getState();

  let result: any = {};

  if (params.previous) {
    [result] = await controller.handleEvent({
      message: "getMessageFromURL",
      params: { apiToken: controller.apiToken, url: params.previous },
    } as any);
  } else {
    [result] = await GetMessageSearch(controller, params);
  }

  const item = Array.isArray(result?.items)
    ? { items: result.items, count: result.total }
    : {};

  if (item.items) {
    item.items = item.items.reverse();
  }

  if (params.concat) {
    item.items = [
      ...(state.searchMessageList?.items || []),
      ...(item.items || []),
    ];

    const key = "id";
    const arrayUniqueByKey: any[] = Array.from(
      new Map(item.items.map((item: any) => [item[key], item])).values()
    );

    item.items = arrayUniqueByKey;
  }

  controller.setState({ searchMessageList: { ...item, page: params.page } });
};

const GetUnreadMessageCount: Handler = (controller, params) => {
  const sum = params.chatList
    .filter((item: any) => item.id !== params.chatRoomId)
    .reduce(
      (result: number, item: any) => (result += item.unread_message_count || 0),
      0
    );

  if (sum >= 1) {
    return true;
  } else {
    return false;
  }
};

export const HandleOpenLinkEncounter: Handler = async (
  controller,
  params: Extract<Event, { message: "HandleOpenLinkEncounter" }>["params"]
) => {
  const state =controller.getState();

  const e = params.event;
  const doctorId = params.doctorId || state.currentDoctor?.id;
  const clickLeft = e?.button === 0;
  const element = e?.target as HTMLAnchorElement | null;
  let loader = element?.querySelector(".ui.mini.active.loader") as HTMLAnchorElement | null;
  const isContext = params.type === "contextmenu"

  if (e && params.encounterId && !isContext) {
    e.preventDefault();
    e.stopPropagation();
  }

  if(isContext){
    return loader?.remove()
  }

  if (loader) {
    return
  }

  const django = params.django || controller.getState().django;

  if (params.encounterId) {
    element?.insertAdjacentHTML("afterbegin",`<div class="ui mini active loader" style="left: -7px;"/>`)

    const result = await EncounterDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.encounterId,
    });

    const encounter = result[0] || {};

    if (encounter.id) {
      const isDoctor = django?.user?.role_types?.includes("DOCTOR");
      const isActive =
        (doctorId === encounter.approve_by || doctorId === encounter.doctor) &&
        isDoctor 
        // &&
        // encounter.is_expired === false;

      const isNotPermission =
        isDoctor && doctorId !== encounter.approve_by && doctorId !== encounter.doctor;

      // console.log(isActive, params, encounter)

      if (isActive) {
        // if (encounter.status === "IN_EXAM") {
        //   if (params.href) {
        //     if (clickLeft){
        //       window.open(params.href, "_blank", "noopener noreferrer")?.focus();
        //     }
        //   }
        // } else 
        if (encounter.status === "CHECKED_OUT") {
          if (clickLeft || params.replace){
            controller.setState({
              modOpenLinkEncounter:
                "ไม่สามารถดำเนินการได้ เนื่องจากผู้ป่วยออกจากห้องตรวจแล้ว",
            });
          }
        }else{
          if (params.href) {
            if (clickLeft){
              window.open(params.href, "_blank", "noopener noreferrer")?.focus();
            }
          }
        }
      } else {
        if (clickLeft || params.replace){
          controller.setState({
            modOpenLinkEncounter: "ไม่มีสิทธิในการดำเนินการ",
          });
        }
        // if (isNotPermission || !isDoctor)
      }

      loader = element?.querySelector(".ui.mini.active.loader") as HTMLAnchorElement | null;
      loader?.remove()
    }
  }

  console.log(params, django)
};
