import React from "react";
import { Button } from "semantic-ui-react";

// Common
import { ModConfirm } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Types
type ModConfirmReprintProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  open: boolean;
  data?: Record<string, any> | null;
  receiptId?: string;
  // config
  errorKey?: string;
  // commonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose: () => any;
};

const BUTTON_ACTIONS = {
  REPRINT: "confirm_reprint",
};

const MOD_CONFIRM_REPRINT = "ModConfirmReprint";

const ModConfirmReprint = (props: ModConfirmReprintProps) => {
  const handleConfirmVoid = (useEDC: boolean) => (e: any, data: any) => {
    props.runSequence({
      sequence: "BillingHistory",
      action: "confirm_reprint",
      data: props.data,
      receipt: props.receiptId,
      card: MOD_CONFIRM_REPRINT,
      errorKey: props.errorKey,
      onSuccess: props.onClose,
    });
  };

  return (
    <ModConfirm
      closeIcon={true}
      titleColor={"yellow"}
      openModal={props.open}
      titleName={"-"}
      content={
        <div style={{ margin: "-0.5rem 0 -1.5rem" }}>
          <div>ยืนยันการ REPRINT รายการนี้ใช่หรือไม่</div>
        </div>
      }
      onDeny={props.onClose}
      onApprove={handleConfirmVoid(false)}
      onClose={props.onClose}
      approveButton={
        <ButtonLoadCheck
          // function
          setProp={props.setProp}
          onClick={handleConfirmVoid(false)}
          // data
          paramKey={`${MOD_CONFIRM_REPRINT}_${BUTTON_ACTIONS.REPRINT}`}
          buttonLoadCheck={
            props.buttonLoadCheck?.[
              `${MOD_CONFIRM_REPRINT}_${BUTTON_ACTIONS.REPRINT}`
            ]
          }
          // config
          color={"green"}
          name={BUTTON_ACTIONS.REPRINT}
          size="medium"
          title="ใช่"
          basic={true}
          style={{ width: "100%" }}
          iconStyle={{ margin: 0 }}
        />
      }
      denyButtonText={"ไม่"}
      denyButtonColor={"red"}
    />
  );
};

export default React.memo(ModConfirmReprint);
