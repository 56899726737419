import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  MutableRefObject,
  useCallback,
} from "react";
import { Checkbox, Dropdown } from "semantic-ui-react";

import moment from "moment";

// UX
import CardUDSegmentMemberUX from "./CardUDSegmentMemberUX";
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";

// Common
import { ModConfirm, ModInfo } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Utils
import { formatDate } from "../../../utils/dateUtils";

// Interface
import {
  FilterPatientSegmentType,
  RunSequence,
  SubSegmentType,
} from "./sequence/UnderlyingDisease";
import { CARD_UNDERLYING_DISEASE } from "./CardUnderlyingDisease";

// Types
type CardUDSegmentMemberProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // controller
  drugOrderQueueController: any;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // data
  subSegmentList?: SubSegmentType[];
  segmentGroupCode: string;
  patientSegmentList?: Record<string, any>[];
};

type PatientType = Partial<{
  id: number;
  hn: string;
  full_name: string;
  birthdate: string;
}>;

export const CARD_UD_SEGMENT_MEMBER = "CardUDSegmentMember";

const CardUDSegmentMember = (props: CardUDSegmentMemberProps) => {
  // add patient
  const [selectedPatient, setSelectedPatient] = useState<PatientType>({});
  const [selectedSubSegment, setSelectedSubSegment] = useState<string>("");
  // file
  const [patientFile, setPatientFile] = useState<File | null>(null);
  // search
  const [filter, setFilter] = useState<FilterPatientSegmentType>({});
  // checked
  const [checked, setChecked] = useState<Record<string, Record<string, any>>>(
    {}
  );
  // mod
  const [openModConfirmRemove, setOpenModConfirmRemove] =
    useState<boolean>(false);
  const [openModInfo, setOpenModInfo] = useState<boolean>(false);
  const [modEdit, setModEdit] = useState<string>("");
  const [selectedSubSegmentCode, setSelectedSubSegmentCode] =
    useState<string>("");

  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;
  const patientRef = useRef<any>();

  // Effect
  useEffect(() => {
    setChecked({});

    props.runSequence({
      sequence: "UnderlyingDisease",
      action: "FETCH_PATIENT_SEGMENT",
      card: CARD_UD_SEGMENT_MEMBER,
    });
  }, []);

  // Callback Memo
  const handleChecked = useCallback(
    (item: any) => (e: any, data: any) => {
      const callback = (checked: any) => {
        if (data.checked) {
          checked[item.id] = item;
        } else {
          delete checked[item.id];
        }

        return { ...checked };
      };

      setChecked(callback);
    },
    []
  );

  // Use Memo
  const subSegmentOptions = useMemo(() => {
    return (props.subSegmentList || []).map((item) => ({
      key: item.id,
      value: item.code,
      text: item.name,
    }));
  }, [props.subSegmentList]);

  const subSegmentIdOptions = useMemo(() => {
    return (props.subSegmentList || []).map((item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }, [props.subSegmentList]);

  const patientSegmentItems = useMemo(() => {
    return (props.patientSegmentList || []).map((item) => ({
      ...item,
      chk: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            checked={!!checked[item.id]}
            onChange={handleChecked(item)}
          />
        </div>
      ),
      full_name: item.fullname,
      // full_age: 79 ปี 0 เดือน split(" ")[0] เพื่อถึงปีออกมา
      birthdate: `${formatDate(moment(item.birthdate))} [${
        item.full_age.split(" ")?.[0] || ""
      } ปี]`,
      result:
        subSegmentOptions.find((acc) => acc.value === item.result)?.text || "",
    }));
  }, [props.patientSegmentList, checked, subSegmentOptions]);

  const isCheckedAll = useMemo(() => {
    const length = (props.patientSegmentList || []).length;
    return Object.keys(checked).length === length && !!length;
  }, [checked, props.patientSegmentList]);

  // Handler
  const handleChangePatient = (
    id: any,
    hn: string,
    full_name: string,
    birthdate: string
  ) => {
    setSelectedPatient(id ? { id, hn, full_name, birthdate } : {});
  };

  const handleChangeSubSegment = (e: any, data: any) => {
    setSelectedSubSegment(data.value);
  };

  const handleClearAddPatient = () => {
    setSelectedPatient({});
    setSelectedSubSegment("");
  };

  const handleCreatePatientSegment = (
    data: {
      patients?: { hn: string; result: string }[];
      method?: "DELETE" | "EDIT";
      oldResult?: string;
      onSuccess?: Function;
      buttonKey?: string;
    } = {}
  ) => {
    props.runSequence({
      sequence: "UnderlyingDisease",
      action: "CREATE_PATIENT_SEGMENT",
      errorKey: CARD_UNDERLYING_DISEASE,
      card: CARD_UD_SEGMENT_MEMBER,
      buttonKey: data.buttonKey || "",
      patients: data.patients
        ? data.patients
        : [{ hn: selectedPatient?.hn || "", result: selectedSubSegment }],
      group: props.segmentGroupCode,
      method: data.method,
      oldResult: data.oldResult,
      // callback
      onSuccess: data.onSuccess,
    });
  };

  const handleAddPatient = (
    data: {
      patients?: { hn: string; result: string }[];
      method?: "DELETE" | "EDIT";
      oldResult?: string;
    } = {}
  ) => {
    handleCreatePatientSegment({
      buttonKey: "ADD",
      // callback
      onSuccess: handleClearAddPatient,
    });
  };

  const handleClickBrowse = () => {
    fileRef.current?.click?.();
  };

  const handleChangeFile = (event: any) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setPatientFile(file);
    } else {
      fileRef.current.value = "";
      setPatientFile(null);
    }
  };

  const handleImportFile = () => {
    if (props.segmentGroupCode.trim() === "") {
      return console.log("errorMessage", "Segment code not found.");
    }

    if (!patientFile) {
      return console.log("errorMessage", "Please select a file.");
    }

    let fileReader = new FileReader();
    fileReader.readAsBinaryString(patientFile);
    fileReader.onload = async (event) => {
      if (!event.target) {
        return;
      }

      const XLSX = await import("xlsx");

      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const rowObject: any[] = (XLSX.utils as any).sheet_to_row_object_array(
        workbook.Sheets[workbook.SheetNames[0]]
      );
      const results = subSegmentOptions.map((item) => item.value);

      const patients = rowObject.flatMap((item: any) =>
        results.includes(item["Subgroup code"])
          ? [
              {
                hn: item["HN"],
                result: item["Subgroup code"],
              },
            ]
          : []
      );

      const hnList = patients.map((item) => item.hn).filter(Boolean);

      if (!patients.length) {
        return props.setProp(
          `errorMessage.${CARD_UNDERLYING_DISEASE}`,
          "Subgroup code not found."
        );
      } else if (!hnList.length) {
        return props.setProp(
          `errorMessage.${CARD_UNDERLYING_DISEASE}`,
          "Patient not found."
        );
      }

      handleCreatePatientSegment({
        patients,
        buttonKey: "IMPORT",
      });
    };

    fileRef.current.value = "";
    setPatientFile(null);
  };

  const handleChangeValue = (e: any, data: any) => {
    setFilter({
      ...filter,
      [data.name]: data.value,
    });
  };

  const handleSearch = () => {
    setChecked({});

    props.runSequence({
      sequence: "UnderlyingDisease",
      action: "FETCH_PATIENT_SEGMENT",
      card: CARD_UD_SEGMENT_MEMBER,
      ...filter,
    });
  };

  const handleCheckedAll = (e: any, data: any) => {
    const list = props.patientSegmentList || [];

    setChecked(
      Object.assign(
        {},
        ...(data.checked ? list.map((item) => ({ [item.id]: item })) : [])
      )
    );
  };

  const handleChangeEditSubSegment = (e: any, data: any) => {
    setSelectedSubSegmentCode(data.value);
  };

  const handleDownloadFile = () => {
    import("xlsx").then((XLSX) => {
      let workbook = XLSX.utils.book_new();
      workbook.SheetNames.push("Segment");

      const sheet_data = [
        ["HN", "Subgroup code"],
        ...(props.patientSegmentList || []).map((item) => [
          item.hn,
          item.result,
        ]),
      ];
      const sheet = XLSX.utils.aoa_to_sheet(sheet_data);

      workbook.Sheets["Segment"] = sheet;

      const output = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

      const url = URL.createObjectURL(
        new Blob([output], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.download = "segment.xlsx";
      link.click();
      link.remove();
    });
  };

  // Mod
  const handleOpenModConfirmRemove = () => {
    setOpenModConfirmRemove(true);
  };

  const handleCloseModConfirmRemove = () => {
    setOpenModConfirmRemove(false);
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(false);
  };

  const handleCloseModEdit = () => {
    setModEdit("");
    setSelectedSubSegmentCode("");
  };

  const handleConfirmRemove = () => {
    const patients = Object.entries(checked).map(([key, item]) => ({
      hn: item.hn,
      result: item.result,
    }));

    handleCreatePatientSegment({
      patients,
      method: "DELETE",
      buttonKey: "REMOVE",
      // callback
      onSuccess: () => {
        setOpenModConfirmRemove(false);
        setChecked({});
      },
    });
  };

  const handleOpenModEdit = () => {
    const results = Object.entries(checked).map(([key, item]) => item.result);
    const unique = Array.from(new Set(results));

    if (unique.length === 1) {
      setModEdit(unique[0]);
    } else {
      setOpenModInfo(true);
    }
  };

  const handleConfirmEdit = () => {
    const patients = Object.entries(checked).map(([key, item]) => ({
      hn: item.hn,
      result: selectedSubSegmentCode,
    }));

    handleCreatePatientSegment({
      patients,
      method: "EDIT",
      oldResult: modEdit,
      buttonKey: "EDIT",
      // callback
      onSuccess: () => {
        setModEdit("");
        setChecked({});
        setSelectedSubSegmentCode("");
      },
    });
  };

  // console.log(selectedPatient, selectedSubSegment, props);

  return (
    <div>
      <CardUDSegmentMemberUX
        // options
        subSegmentOptions={subSegmentOptions}
        subSegmentIdOptions={subSegmentIdOptions}
        // headers
        headers={[
          <Checkbox checked={isCheckedAll} onChange={handleCheckedAll} />,
          "HN",
          "ชื่อ-นามสกุล",
          "วัน เดือน ปีเกิด [อายุ]",
          "กลุ่มย่อย",
        ]}
        // data
        patientSegmentList={patientSegmentItems}
        subSegment={selectedSubSegment}
        fileName={patientFile?.name}
        hn={filter.hn}
        fullName={filter.fullName}
        subSegmentId={filter.subSegmentId}
        // config
        disabledDownloadTemplate={!props.patientSegmentList?.length}
        // callback
        onDownloadTemplate={handleDownloadFile}
        onChangeSubSegment={handleChangeSubSegment}
        onBrowse={handleClickBrowse}
        onChangeValue={handleChangeValue}
        onRemove={handleOpenModConfirmRemove}
        onEdit={handleOpenModEdit}
        // Element
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_UD_SEGMENT_MEMBER}_SEARCH`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_UD_SEGMENT_MEMBER}_SEARCH`]
            }
            // config
            color={"blue"}
            name="SEARCH"
            size="medium"
            title="ค้นหา"
          />
        }
        ButtonAdd={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleAddPatient}
            // data
            paramKey={`${CARD_UD_SEGMENT_MEMBER}_ADD`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_UD_SEGMENT_MEMBER}_ADD`]
            }
            // config
            color={"blue"}
            name="ADD"
            size="medium"
            title="ADD"
            disabled={!selectedPatient?.hn || !selectedSubSegment}
          />
        }
        ButtonImport={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleImportFile}
            // data
            paramKey={`${CARD_UD_SEGMENT_MEMBER}_IMPORT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_UD_SEGMENT_MEMBER}_IMPORT`]
            }
            // config
            color={"blue"}
            name="IMPORT"
            size="medium"
            title="Import"
            disabled={!patientFile}
          />
        }
        PatientSearchBox={
          <CardPatientSearchBox
            // ref
            ref={patientRef}
            // controller
            controller={props.drugOrderQueueController}
            // defaultValue={selectedPatient?.hn || ""}
            clearHNInput={selectedPatient?.hn === undefined ? true : false}
            // callback
            onEnterPatientSearch={handleChangePatient}
          />
        }
      />

      <input
        ref={fileRef}
        type="file"
        accept=".xlsx"
        onChange={handleChangeFile}
        hidden
      />

      <ModInfo
        open={openModInfo}
        titleColor={"yellow"}
        titleName={"ข้อความแจ้งเตือน"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <div style={{ padding: "0.5rem 0" }}>
          ไม่สามารถแก้ไขสมาชิกที่มีกลุ่มย่อยต่างกันได้
        </div>
      </ModInfo>

      <ModConfirm
        openModal={openModConfirmRemove}
        titleName="ลบสมาชิกกลุ่ม"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        // approveButtonColor="green"
        // approveButtonText="ตกลง"
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmRemove}
            // data
            paramKey={`${CARD_UD_SEGMENT_MEMBER}_REMOVE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_UD_SEGMENT_MEMBER}_REMOVE`]
            }
            // config
            color={"green"}
            name="REMOVE"
            size="medium"
            title="ตกลง"
            basic={true}
          />
        }
        content={
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "0rem 0 -1rem",
            }}
          >
            ต้องการลบสมาชิกที่เลือกออกจากกลุ่ม
          </div>
        }
        // onApprove={handleConfirmRemove}
        onDeny={handleCloseModConfirmRemove}
        onCloseWithDimmerClick={handleCloseModConfirmRemove}
      />

      <ModConfirm
        openModal={!!modEdit}
        titleName="แก้ไข"
        titleColor="yellow"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        // approveButtonColor="green"
        // approveButtonText="บันทึก"
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmEdit}
            // data
            paramKey={`${CARD_UD_SEGMENT_MEMBER}_EDIT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_UD_SEGMENT_MEMBER}_EDIT`]
            }
            // config
            color={"green"}
            name="EDIT"
            size="medium"
            title="บันทึก"
            basic={true}
          />
        }
        disabledApproveButton={!selectedSubSegmentCode}
        content={
          <div
            style={{
              margin: "-1rem 0 -1.25rem",
              lineHeight: 2.35,
            }}
          >
            <div>
              <strong>เปลี่ยนกลุ่มย่อยของสมาชิกที่เลือก</strong>
            </div>
            <div>
              จาก:{" "}
              {subSegmentOptions.find((acc) => acc.value === modEdit)?.text ||
                ""}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ width: "120px" }}>เปลี่ยนไปเป็น:</label>
              <Dropdown
                value={selectedSubSegmentCode}
                options={subSegmentOptions}
                selection={true}
                fluid={true}
                style={{ marginLeft: "10px", width: "100%" }}
                onChange={handleChangeEditSubSegment}
              />
            </div>
          </div>
        }
        // onApprove={handleConfirmEdit}
        onDeny={handleCloseModEdit}
        onCloseWithDimmerClick={handleCloseModEdit}
      />
    </div>
  );
};

export default React.memo(CardUDSegmentMember);
