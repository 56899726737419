import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";

// Common
import { ModConfirm, ModInfo } from "react-lib/apps/common";

// Types
type ModDescriptionUDProps = {
  // data
  open: boolean;
  description?: string;
  // config
  editable?: boolean;
  // callback
  onClose?: () => any;
  onApprove?: (description: string) => any;
};

const ModDescriptionUD = (props: ModDescriptionUDProps) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(props.description || "");
  }, [props.description]);

  const handleApprove = () => {
    props.onApprove?.(inputValue);
  };

  return (
    <div>
      {props.editable ? (
        <ModConfirm
          openModal={props.open}
          titleName="รายละเอียดการใช้ยาเพิ่มเติม"
          backgroundColor="var(--primary-theme-color)"
          size="mini"
          denyButtonColor="red"
          denyButtonText="ยกเลิก"
          approveButtonColor="green"
          approveButtonText="ยืนยัน"
          modalStyle={{ width: "25%" }}
          content={
            <div
              style={{
                margin: "0rem 0.5rem -1rem",
              }}
            >
              <Input
                value={inputValue}
                fluid={true}
                onChange={(e, v) => setInputValue(v.value)}
              />
            </div>
          }
          onApprove={handleApprove}
          onDeny={props.onClose}
          onCloseWithDimmerClick={props.onClose}
        />
      ) : (
        <ModInfo
          open={props.open}
          centered={true}
          titleName="รายละเอียดการใช้ยาเพิ่มเติม"
          backgroundColor="var(--primary-theme-color)"
          size="mini"
          buttonColor="red"
          btnText="ปิดหน้าต่าง"
          style={{ width: "25%" }}
          closeOnDimmerClick={true}
          onApprove={props.onClose}
          onClose={props.onClose}
        >
          <div
            style={{
              margin: "1.75rem 0rem 0.75rem",
            }}
          >
            <Input value={props.description} fluid={true} readOnly={true} />
          </div>
        </ModInfo>
      )}
    </div>
  );
};

export default React.memo(ModDescriptionUD);
