import WasmController from "react-lib/frameworks/WasmController";

// APIs
import DrugRelatedSubSegmentGroupList from "issara-sdk/apis/DrugRelatedSubSegmentGroupList_apps_PHR";
import SegmentGroupList from "issara-sdk/apis/SegmentGroupList_apps_PHR";
import SegmentGroupDetail from "issara-sdk/apis/SegmentGroupDetail_apps_PHR";

// Serializer
import SegmentGroupSerializer from "issara-sdk/types/SegmentGroupSerializer_apps_PHR";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

export type State = Partial<{
  // sequence
  DrugDiseaseInteractionSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    items: Required<DrugRelatedSubSegmentType>[];
    segmentGroupDetail: DrugRelatedSubSegmentType | null;
    segmentGroupUDList: SegmentGroupSerializer[];
    subSegmentList: SubSegmentType[];
    drugTypeDic: Record<DrugType, string>;
    subGroupDict: Record<string, any[]>;
  }> | null;
}>;

type Picked = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage">>;

export type DrugRelatedSubSegmentType = Partial<{
  id: string | number;
  drug_id: number | string;
  drug_name: string;
  drug_type: DrugType;
  sub_segment_group_binders: SubSegmentType[];
}>;

export type SubSegmentType = Partial<{
  id: number;
  sub_segment_group_id: number;
  sub_segment_group_code: string;
  sub_segment_group_name: string;
  segment_group_id: number;
  segment_group_code: string;
  segment_group_name: string;
  message: string;
  edit_user: number;
  active: boolean;
}>;

export type DrugType = "PRODUCT" | "INGREDIENT" | "MIMS" | "ATC";

export type MasterOptionsType = Record<typeof MASTERS[number][0], OptionType[]>;

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "DrugDiseaseInteraction";
  restart?: boolean;
  clear?: boolean;
};
// Handle Action
type ActionType =
  | {
      action: "FETCH_SEGMENT_GROUP";
      name?: string;
    }
  | {
      action: "CREATE_SEGMENT_GROUP";
      data: DrugRelatedSubSegmentType;
      card: string;
      errorKey?: string;
      // callback
      onSuccess?: Function;
    }
  | {
      action: "SELECT_SEGMENT_GROUP";
      data: DrugRelatedSubSegmentType;
      isUpdate?: boolean;
    }
  | {
      action: "ADD_SEGMENT_GROUP";
      data: DrugRelatedSubSegmentType;
    }
  | {
      action: "CHANGE_SUB_SEGMENT";
      method: "ADD" | "UPDATE" | "DELETE" | "CHECK";
      index?: number;
      name?: keyof SubSegmentType;
      value?: string | boolean;
      confirm?: boolean;
      card?: string;
      errorKey?: string;
      // callback
      callback?: (type: string, data?: Record<string, any>) => any;
    }
  | { action: "CREATE_SEGMENT_RESULT"; card: string; errorKey: string }
  | {
      action: "DELETE_SEGMENT_GROUP";
      card: string;
      errorKey?: string;
      // callback
      onSuccess?: Function;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type Params<A extends ActionType["action"]> = Extract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  DrugDiseaseInteractionSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

const MASTERS = [
  ["drugName", []],
  ["drugGroup", []],
] as const

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler = async (controller, params: SeqState) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: MASTERS,
    },
  } as any);

  const segment = await SegmentGroupList.list({
    apiToken: controller.apiToken,
  });

  const state = controller.getState();

  controller.setState(
    {
      DrugDiseaseInteractionSequence: {
        ...state.DrugDiseaseInteractionSequence,
        sequenceIndex: "Action",
        segmentGroupUDList: segment[0]?.items || [],
        drugTypeDic: {
          INGREDIENT: "Ingredient",
          MIMS: "MIMS group",
          PRODUCT: "Product",
          ATC: "ATC group",
        },
      },
    },
    () =>
      controller.handleEvent({ message: "RunSequence", params: { ...params } })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler = async (controller, params: ActionType) => {
  if (params.action === "FETCH_SEGMENT_GROUP") {
    HandleFetchSegmentGroup(controller, params);
  } else if (params.action === "CREATE_SEGMENT_GROUP") {
    HandleCreateSegmentGroup(controller, params);
  } else if (params.action === "SELECT_SEGMENT_GROUP") {
    HandleSelectSegmentGroup(controller, params);
  } else if (params.action === "ADD_SEGMENT_GROUP") {
    HandleAddSegmentGroup(controller, params);
  } else if (params.action === "CHANGE_SUB_SEGMENT") {
    HandleChangeSubSegment(controller, params);
  } else if (params.action === "CREATE_SEGMENT_RESULT") {
    HandleCreateSegmentResult(controller, params);
  } else if (params.action === "DELETE_SEGMENT_GROUP") {
    HandleDeleteSegmentGroup(controller, params);
  }
};

const HandleFetchSegmentGroup: Handler = async (
  controller,
  params: Params<"FETCH_SEGMENT_GROUP">
) => {
  const drug = await DrugRelatedSubSegmentGroupList.get({
    apiToken: controller.apiToken,
    params: { drug_name: params.name },
  });

  const state = controller.getState();

  controller.setState({
    DrugDiseaseInteractionSequence: {
      ...state.DrugDiseaseInteractionSequence,
      items: drug[0] || [],
    },
  });
};

const HandleCreateSegmentGroup: Handler = async (
  controller,
  params: Params<"CREATE_SEGMENT_GROUP">
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_SAVE`]: "LOADING",
    },
  });

  const segment = await DrugRelatedSubSegmentGroupList.post({
    apiToken: controller.apiToken,
    data: {
      ...params.data,
      drug_name: "",
      sub_segment_group_binders: [],
    } as any,
  });

  if (segment[0]) {
    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SAVE`]: "SUCCESS",
      },
    });
    HandleFetchSegmentGroup(controller, params);
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SAVE`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey || ""]: segment[1],
      },
    });
  }
};

const HandleSelectSegmentGroup: Handler = async (
  controller,
  params: Params<"SELECT_SEGMENT_GROUP">
) => {
  let detail = params.data;

  // ต้องการ fetch ข้อมูลใหม่
  if (params.isUpdate) {
    [detail] = await DrugRelatedSubSegmentGroupList.get({
      apiToken: controller.apiToken,
      params: {
        drug_id: params.data?.drug_id,
        drug_type: params.data?.drug_type,
      },
    });

    detail = (detail as any)?.[0] || {};
  }

  const state = controller.getState();

  const list = [...(detail?.sub_segment_group_binders || [])].map((item) => ({
    ...item,
    active: true,
  }));

  HandleGetSubGroupDict(controller, {
    groups: list.map((item) => item.segment_group_id),
  });

  controller.setState({
    DrugDiseaseInteractionSequence: {
      ...state.DrugDiseaseInteractionSequence,
      segmentGroupDetail: params.data,
      subSegmentList: list,
    },
  });
};

const HandleAddSegmentGroup: Handler = (
  controller,
  params: Params<"ADD_SEGMENT_GROUP">
) => {
  const state = controller.getState();

  const items = state.DrugDiseaseInteractionSequence?.items || [];

  controller.setState({
    DrugDiseaseInteractionSequence: {
      ...state.DrugDiseaseInteractionSequence,
      items: [...items, params.data as any],
      segmentGroupDetail: params.data,
    },
  });
};

const HandleChangeSubSegment: Handler = async (
  controller,
  params: Params<"CHANGE_SUB_SEGMENT">
) => {
  const state = controller.getState();
  const data = state.DrugDiseaseInteractionSequence || {};

  const list = [
    ...(state.DrugDiseaseInteractionSequence?.subSegmentList || []),
  ];

  const { index = 0, name = "", value = "" } = params;

  // ADD
  if (params.method === "ADD") {
    list.push({ message: "", active: true });
  }
  // UPDATE
  else if (params.method === "UPDATE") {
    (list as any)[index][name] = value;

    if (params.name === "segment_group_id") {
      (list as any)[index]["sub_segment_group_id"] = "";

      HandleGetSubGroupDict(controller, { groups: [value] });
    }
  }
  // DELETE
  else if (params.method === "DELETE") {
    const item = (list as any)[index];

    // ยังไม่ได้บันทึก
    if (!item.id) {
      (list as any).splice(index, 1);
    }
    // หากบันทึกแล้วต้องการลบ
    else {
      if (params.confirm) {
        (list as any)[index]["active"] = false;

        const isRemove = list.every((item) => !item.active);

        await UpdateSegmentGroup(controller, {
          detail: data.segmentGroupDetail,
          list: list,
        });

        if (isRemove) {
          HandleFetchSegmentGroup(controller, params);
        } else {
          HandleSelectSegmentGroup(controller, {
            data: data.segmentGroupDetail,
            isUpdate: true,
          });
        }

        params.callback?.("");
      } else {
        params.callback?.("CONFIRM", { index });
      }

      return;
    }
  }

  controller.setState({
    DrugDiseaseInteractionSequence: {
      ...state.DrugDiseaseInteractionSequence,
      subSegmentList: list.map((item) => ({ ...item })),
    },
  });
};

const HandleCreateSegmentResult: Handler = async (
  controller,
  params: Params<"CREATE_SEGMENT_RESULT">
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_SAVE`]: "LOADING",
    },
  });

  const data = state.DrugDiseaseInteractionSequence || {};
  const detail = data.segmentGroupDetail || {};
  const isNewSegment = typeof detail.id === "string";
  const updateData = (data.subSegmentList || []).filter(
    (item) => item.sub_segment_group_id
  );

  if (isNewSegment) {
    delete detail.id;
  }

  const [updateRes] = await UpdateSegmentGroup(controller, {
    detail,
    list: updateData,
  });

  if (updateRes) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SAVE`]: "SUCCESS",
      },
    });

    if (isNewSegment) {
      HandleFetchSegmentGroup(controller, params);
    }

    HandleSelectSegmentGroup(controller, { data: detail, isUpdate: true });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SAVE`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: updateRes?.[1],
      },
    });
  }
};

const HandleDeleteSegmentGroup: Handler = async (
  controller,
  params: Params<"DELETE_SEGMENT_GROUP">
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_DELETE`]: "LOADING",
    },
  });

  const data = state.DrugDiseaseInteractionSequence || {}
  const list = data.subSegmentList || [];

  const detail = [null, null, null];

  await UpdateSegmentGroup(controller, {
    detail: data.segmentGroupDetail,
    list: list.map((item) => ({ ...item, active: false })),
  });

  if (detail[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_DELETE`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey || ""]: detail[1],
      },
    });
  } else {
    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_DELETE`]: "SUCCESS",
      },
      DrugDiseaseInteractionSequence: {
        ...state.DrugDiseaseInteractionSequence,
        segmentGroupDetail: null,
        subSegmentList: [],
      },
    });

    HandleFetchSegmentGroup(controller, params);
  }
};

const HandleGetSubGroupDict: Handler = async (controller, params) => {
  let state = controller.getState();
  const subGroupDict = state.DrugDiseaseInteractionSequence?.subGroupDict || {};

  const groups = params.groups.filter((id: number) => !subGroupDict[id]);

  const promiseArr = groups.map((id: number) =>
    SegmentGroupDetail.retrieve({
      pk: id,
      apiToken: controller.apiToken,
    }).then((res: any) => ({ [id]: res[0]?.sub_segments || [] }))
  );

  const response = await Promise.all(promiseArr);

  state = controller.getState();

  controller.setState({
    DrugDiseaseInteractionSequence: {
      ...state.DrugDiseaseInteractionSequence,
      subGroupDict: { ...Object.assign(subGroupDict || {}, ...response) },
    },
  });
};

// API
const UpdateSegmentGroup: Handler = (controller, params) => {
  return DrugRelatedSubSegmentGroupList.post({
    apiToken: controller.apiToken,
    data: {
      ...params.detail,
      drug_name: "",
      sub_segment_group_binders: params.list,
    },
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
