import React, { useState, useEffect, useMemo } from "react";

// UX
import CardSapAccountingReportUX from "./CardSapAccountingReportUX";
import CardSapFIExportFilter from "./CardSapFIExportFilter";
import CardSapMedExpenseCostCenterFilter from "./CardSapMedExpenseCostCenterFilter";
import CardSapPaymentStatReportFilter from "./CardSapPaymentStatReportFilter";
// Interface
import { State } from "./sequence/SapAccountingReport";
// Util
import { Modal, ModalContent, Header } from "semantic-ui-react";
// Common

// Props
type CardSapAccountingReportProps = {
  onEvent: (e: any) => any;
  setProp: any;
  runSequence: any;
  // Sequence
  SapAccountingReportSequence?: State["SapAccountingReportSequence"];
  masterOptions?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
};

const CardSapAccountingReport = (props: CardSapAccountingReportProps) => {
  // State
  const [openModal, setOpenModal] = useState(false);

  // Call Sequence
  useEffect(() => {
    props.runSequence({
      sequence: "SapAccountingReport",
      restart: true,
    });
  }, []);

  // Call Sequence (GET_MENU)
  const callGetMenuSequence = (menu: string) => {
    props.runSequence({
      sequence: "SapAccountingReport",
      action: "GET_MENU",
      menu: menu,
    });
  };

  const handleSelectedMenu = (menu: string) => {
    console.log("SapAcc Menu: ", menu);
    setOpenModal(true);
    callGetMenuSequence(menu);
  };

  const handleCloseModal = (option: boolean) => {
    props.runSequence({
      sequence: "SapAccountingReport",
      restart: true,
    });
    setOpenModal(option);
  }

  const getFilterModalContent = useMemo(() => {
    switch (props.SapAccountingReportSequence?.selectedMenu) {
      case "SAP FI Export":
        return (
          <CardSapFIExportFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            SapAccountingReportSequence={props.SapAccountingReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานสรุปรายได้ตามศูนย์ต้นทุน":
        return (
          <CardSapMedExpenseCostCenterFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            SapAccountingReportSequence={props.SapAccountingReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      case "รายงานสรุปสถิติการรับชำระเงิน":
        return (
          <CardSapPaymentStatReportFilter
            runSequence={props.runSequence}
            setProp={props.setProp}
            SapAccountingReportSequence={props.SapAccountingReportSequence}
            masterOptions={props.masterOptions}
            buttonLoadCheck={props.buttonLoadCheck}
          />
        );
      default:
        return null;
    }
  }, [props.SapAccountingReportSequence, props.masterOptions, props.buttonLoadCheck]);

  return (
    <>
      <CardSapAccountingReportUX onHandleSelectedMenu={handleSelectedMenu} />

      <Modal onClose={() => handleCloseModal(false)} open={openModal} size="large">
        <Header>{props.SapAccountingReportSequence?.selectedMenu}</Header>
        <ModalContent style={{ padding: "1rem" }}>{getFilterModalContent}</ModalContent>
      </Modal>
    </>
  );
};

export default CardSapAccountingReport;
