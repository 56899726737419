import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
// ORM
import OperatingDetailDetail from "issara-sdk/apis/OperatingDetailDetail_apps_ORM";
import OperatingOrderBundleCreate from "issara-sdk/apis/OperatingOrderBundleCreate_apps_ORM";
import OperatingRoomList from "issara-sdk/apis/OperatingRoomList_apps_ORM";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import OperatingOrderDetailByEmr from "issara-sdk/apis/OperatingOrderDetailByEmr_apps_ORM";
import OperatingLocationList from "issara-sdk/apis/OperatingLocationList_apps_ORM";
import OperatingOrderPrintDetail from "issara-sdk/apis/OperatingOrderPrintDetail_apps_ORM";
// Core
import ClinicalTermSetView from "issara-sdk/apis/ClinicalTermSetView_core";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import EncounterMedicalRecordList from "issara-sdk/apis/EncounterMedicalRecordList_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
// ADM
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import AdmitOrderRoomItemList from "issara-sdk/apis/AdmitOrderRoomItemList_apps_ADM";
// QUE
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
// USERs
import UserList from "issara-sdk/apis/UserList_users";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
// REG
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
// PTM
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
// Interface
import {
  HandleSearch,
  HandleClear,
  HandleSelect,
  formatData as formatProcedureData,
  formatItems as formatProcedureItems,
} from "../../ADM/sequence/DischargeSummary";
import { State as MainState } from "../../../../../HIS/MainHISInterface";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { ProcessedLeaves, SetProperty } from "../../common/CommonInterface";

export type State = {
  // CommonInterface
  selectedOperatingOption?: any;

  // sequence
  ORRequestSequence?: Partial<
    {
      sequenceIndex?: string | null;
      operatingRoomOptions?: any[];
      orLocationOptions?: any[];
      clinicalTerm: Record<string, any>;
      admitOrderOptions?: any[];
      orImplantOptions?: any[];
      activeSurgeonTeam?: number;
      activeAnesthesiaTeam?: number;
      permission?: any;
      modConfirm: any;
      encounterType: string;
      encounterDatetime: string;
    } & Partial<ORDetailType>
  > | null;
};

type PickedState = Partial<
  Pick<
    MainState,
    | "errorMessage"
    | "buttonLoadCheck"
    | "loadingStatus"
    | "selectedAppointment"
    | "selectedDoctor"
    | "selectedAnesthesiologist"
    | "selectedOperatingRoom"
    | "searchedItemListWithKey"
    | "userTokenize"
    | "selectedOrOrder"
    | "selectedPatient"
    | "appointmentList"
    | "selectedEncounter"
    | "preOrderList"
    | "masterOptions"
    | "selectedEmr"
    | "selectedMainOrOrder"
    | "selectedBlock"
    | "goBackAppointmentState"
    | "ackMedReconcileId"
    | "initialLayout"
  >
>;

export type PickedProps = Partial<
  Pick<
    MainState,
    | "masterOptions"
    | "searchedItemListWithKey"
    | "buttonLoadCheck"
    | "userTokenize"
    | "errorMessage"
    | "loadingStatus"
  >
>;

export type ChangeNameType =
  | keyof ORDetailType
  | ProcessedLeaves<Pick<ORDetailType, "blood_request">>;

export type ORDetailType = {
  id: number;
  type: string;
  clinic_type: string;
  case: CaseType;
  blood_request: Partial<BloodRequestType>;
  teams: TeamType[];
  anesthesia_teams: AnesthesiaTeamType[];
  is_appointment: boolean;
  is_request_icu: boolean;
  is_med_block: boolean;
  med_block_date: string;
  is_anesthesia: boolean;
  anesthesia_date: string;
  emr: number;
  is_other: boolean;
  other: string;
  other_date: string;
  start_date: string;
  anesthesia_method: number;
  main_team: string;
  start_time: string;
  is_ipd_patient: boolean;
  anticipated_blood_loss: AnticipatedBloodLossType;
  anti_platelet_coagulant: AntiPlateletCoagulantType;
  estimate_operation_hour: string;
  estimate_operation_minute: string;
  estimate_operation_time: number;
  admit_order: number;
  operating_room: number | string;
  frozen_section: boolean;
  contrast_media: number;
  asa_class: number;
  special_equipments: number[];
  special_equipments_text: string;
  location: number | string;
  remark: number;
  status: number;
  extra: {
    nullMaxDate: boolean;
    minDate: moment.Moment;
    maxDate: moment.Moment;
  };
  allowed_action: string[];
  checkin_code: string;
  pre_op_medical_assessment: PreOpMedicalAssessmentType;
};

type CaseType = Partial<{
  app_admit_date: string;
  icu_day: string;
  ipd_case: string;
  ipd_day: string;
  is_app_admit: boolean;
  is_ipd_case: boolean;
  is_one_day_case: boolean;
  is_opd_case: boolean;
  is_request_icu: boolean;
}>;

export type BloodRequestType = {
  ffp: string;
  is_ffp: boolean;
  is_na: boolean;
  is_other: boolean;
  is_plt: boolean;
  is_prc: boolean;
  is_yes: boolean;
  is_yes_ts: boolean;
  other: string;
  plt: string;
  prc: string;
};

export type TeamType = Partial<{
  assistant_surgeons: (number | null)[];
  chief_surgeon: number | null;
  id: number;
  implant: string[];
  is_main: boolean;
  pre_operating_order_item: PreOperatingOrderItem;
  pre_principal_diagnosis: any[];
  pre_secondary_diagnosis: any[];
  diagnosis: any[];
  type: number;
}>;

type PreOperatingOrderItem = Partial<{
  other_treatment: string;
  position: number;
  other_position: string;
  operating_treatments: any[];
  procedures: any[];
  site_right: boolean;
  site_left: boolean;
}>;

export type AnesthesiaTeamType = Partial<{
  anesthesiologist: number | null;
  anesthetists: (number | null)[];
  id: any;
  type: string;
}>;

type AnticipatedBloodLossType = Partial<{
  is_less_500: boolean;
  is_more_500: boolean;
  define: number;
}>;

type AntiPlateletCoagulantType = Partial<{
  value: string;
  remark: string;
  date: string;
  other_drug: string;
  other_drug_remark: string;
  other_drug_date: string;
  npo: string;
  npo_date: string;
  npo_time: string;
}>;

type PreOpMedicalAssessmentType = Partial<{
  data: {
    cardiovascular: string;
    bleeding: string;
    dvt: string;
    co_mobidities: string;
    proceed_with_surgery: string;
    risk_bleeding: {
      active_bleeding: boolean;
      active_gastroduodenal: boolean;
      intracranial_bleeding: boolean;
      platelet_count: boolean;
      intraocular: boolean;
      platelet_dysfunction: boolean;
      untreated: boolean;
      therapeutic: boolean;
      hypertensive: boolean;
      advanced: boolean;
      postoperative: boolean;
      heparin: boolean;
      epidural: boolean;
      none_risk: boolean;
      summary: string;
      disabled_none_risk: boolean;
    };
    risk_dvt: {
      elderly_age: boolean;
      trauma: boolean;
      obesity_bmi: boolean;
      cancer: boolean;
      heart: boolean;
      mobillity: boolean;
      mi: boolean;
      thrombophilia: boolean;
      infection: boolean;
      vte: boolean;
      hormonal: boolean;
      none_risk: boolean;
      score: string;
      disabled_none_risk: boolean;
    };
  };
}>;

export const PRE_ASSESSMENT_OPTIONS = [
  { key: "Low", value: "Low", text: "Low" },
  { key: "Moderate", value: "Moderate", text: "Moderate" },
  { key: "High", value: "High", text: "High" },
];

export type SetProp = SetProperty<State & PickedState>;

export const StateInitial: State = {
  // sequence
  ORRequestSequence: {
    sequenceIndex: null,
    operating_room: "",
    location: "",
  },
};

const ORInitial = {
  is_appointment: true,
  start_date: "",
  start_time: "",
  case: {
    app_admit_date: "",
  },
  allowed_action: ["EDIT_REQUEST"],
  blood_request: {
    is_na: true,
  },
  teams: [
    {
      is_main: true,
      implant: [],
      assistant_surgeons: [null],
      pre_operating_order_item: {
        operating_treatments: [null],
        procedures: [{}],
      },
      chief_surgeon: null,
      pre_principal_diagnosis: [{}, {}],
      diagnosis: [{}, {}],
    },
  ],
  anesthesia_teams: [],
  extra: {
    nullMaxDate: false,
    minDate: moment(),
    maxDate: moment().add(1, "days"),
  },
};

export const TeamInitial = {
  implant: [],
  is_main: false,
  assistant_surgeons: [null],
  pre_operating_order_item: {
    operating_treatments: [null],
    procedures: [{}],
  },
  pre_principal_diagnosis: [{}, {}],
  diagnosis: [{}, {}],
};

export const AnesthesiaTeamInitial = {
  anesthesiologist: null,
  anesthetists: [null],
  id: null,
  type: "ANS",
};

export const ADM_FIBRINOLYTIC_USED = {
  ANTI_PALETELET: "A", // งด
  CUT_DRUG: "C", // ไม่ต้องงด
  NO_DRUG: "N", // ไม่ได้ใช้ยา
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "RefreshAppointment"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

export const SEARCH_KEYS = {
  DOCTOR_ASSIST_SURGEON: "Doctor_Assist_Surgeon",
  OPERATING_TREATMENT: "OperatingTreatment",
  DOCTOR_SURGEON: "Doctor_Surgeon",
  USER_ANESTHETIST: "User_Anesthetist",
  DOCTOR_ANESTHESIOLOGIST: "Doctor_Anesthesiologist",
};

export const SEARCH_INDEXES = {
  [SEARCH_KEYS.DOCTOR_ASSIST_SURGEON]: "CI",
  [SEARCH_KEYS.OPERATING_TREATMENT]: "CI",
  [SEARCH_KEYS.DOCTOR_SURGEON]: "I",
  [SEARCH_KEYS.USER_ANESTHETIST]: "CI",
  [SEARCH_KEYS.DOCTOR_ANESTHESIOLOGIST]: "I",
} as const;

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler = async (controller, params) => {
  let state = controller.getState();

  const { order, clinical, admit, permission, encounter } = await GetMasterOrRequest(
    controller,
    params
  );

  const admitOrderOptions = admit.map((item: any) => ({
    key: item.id,
    value: item.id,
    text: `[${item.code}] ${item.admit_type}: ${item.admit_date} (${item.status_label})`,
  }));

  const orImplantOptions = mapOptions(clinical?.or_implant?.items || [], "name");

  if (order?.id) {
    let listWithKey = state.searchedItemListWithKey || {};

    removeListWithKey(listWithKey, SEARCH_INDEXES);

    if (order.teams?.length) {
      const key = await getListWithKey(controller, {
        order,
        listWithKey,
        keys: ["chief_surgeon", "assistant_surgeons", "anesthesiologist", "anesthetists"],
      });

      listWithKey = { ...listWithKey, ...key };

      order.teams = order.teams.map((item: any, index: number) => {
        const implant: any[] = item.implant ? item.implant.split(",") : [];

        for (const value of implant) {
          const findOption = orImplantOptions.find((item: any) => item.text === value);
          if (!findOption) {
            orImplantOptions.push({ key: value, value, text: value });
          }
        }

        return {
          ...item,
          diagnosis: [
            ...formatProcedureItems(item.pre_principal_diagnosis[0], [], "icd10"),
            ...formatProcedureItems(
              item.pre_secondary_diagnosis[0],
              item.pre_secondary_diagnosis.slice(1),
              "icd10"
            ),
          ],
          pre_operating_order_item: {
            ...item.pre_operating_order_item,
            procedures: formatProcedureItems(
              item.pre_operating_order_item?.procedures?.[0],
              item.pre_operating_order_item?.procedures?.slice(1),
              "icd9cm"
            ),
          },
          implant,
        };
      });
    }

    const teamIndex = state.ORRequestSequence?.activeSurgeonTeam || 0;

    state = controller.getState();

    controller.setState(
      {
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
        searchedItemListWithKey: listWithKey,
        selectedDoctor: order.teams?.[0]?.chief_surgeon || null,
        selectedAnesthesiologist: order.anesthesia_teams?.[0]?.anesthesiologist || null,
        selectedOperatingRoom: order.operating_room || null,
        selectedOperatingOption: order.type || "",
        selectedOrOrder: order,
        selectedMainOrOrder: params.emrId === undefined ? order : state.selectedMainOrOrder,
        ORRequestSequence: {
          // ...state.ORRequestSequence,W
          ...order,
          permission: permission,
          clinicalTerm: clinical,
          admitOrderOptions,
          orImplantOptions,
          activeSurgeonTeam: teamIndex,
          activeAnesthesiaTeam: state.ORRequestSequence?.activeAnesthesiaTeam || 0,
          extra: {
            nullMaxDate: false,
            minDate: moment(),
            maxDate: moment().add(1, "days"),
          },
          sequenceIndex: "SaveAndEdit",
        },
      },
      () =>
        controller.handleEvent({
          message: "RunSequence",
          params: { ...params, action: "update_location_room" },
        })
    );
  } else {
    HandleInitial(controller, {
      ...params,
      options: state.masterOptions,
      permission: permission,
      emrId: state.selectedAppointment?.order_emr,
      clinicalTerm: clinical,
      admitOrderOptions,
      orImplantOptions,
      encounter,
    });
  }

  console.log("OR Request,", (state as any).masterOptions);
};

const HandleInitial: Handler = (controller, params) => {
  const state = controller.getState();
  const options = params.options;

  const listWithKey = state.searchedItemListWithKey || {};

  removeListWithKey(listWithKey, SEARCH_INDEXES);

  controller.setState(
    {
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
      searchedItemListWithKey: listWithKey,
      ORRequestSequence: {
        ...JSON.parse(JSON.stringify(ORInitial)),
        ...(options && {
          type: params.isNewEncounter ? "EL" : "EL_NOT_APP",
          clinic_type: options.orClinicType?.[0]?.value,
          anesthesia_method: options.anesthesiaMethod?.[0]?.value,
          selectedOrOrder: null,
        }),
        activeSurgeonTeam: 0,
        activeAnesthesiaTeam: 0,
        sequenceIndex: "SaveAndEdit",
        id: params.orderId || null,
        emr: params.emrId || null,
        permission: params.permission,
        clinicalTerm: params.clinicalTerm,
        admitOrderOptions: params.admitOrderOptions,
        orImplantOptions: params.orImplantOptions,
        extra: {
          nullMaxDate: false,
          minDate: moment(),
          maxDate: moment().add(1, "days"),
        },
        operating_room: "",
        location: "",
        encounterType: params.encounter?.type,
        encounterDatetime: params.encounter?.created_utc,
      },
    },
    () => !params.orderId && controller.handleEvent({ message: "RunSequence", params: params })
  );
};

/* ------------------------------------------------------ */

/*                         ACTION                         */

/* ------------------------------------------------------ */
export const SaveAndEdit: Handler = async (controller, params) => {
  const state = controller.getState();

  const orRequest = state.ORRequestSequence || {};

  if (params.action === "edit") {
    HandleEdit(controller, params);
  } else if (["search", "clear", "select"].includes(params.action)) {
    let data = {};

    if (params.key === "procedures") {
      data = (orRequest as any).teams[params.teamIndex].pre_operating_order_item[params.key][
        params.index
      ];
    } else {
      data = (orRequest as any).teams[params.teamIndex][params.key][params.index];
    }

    const change = (data: any) => {
      if (params.key === "procedures") {
        (orRequest as any).teams[params.teamIndex].pre_operating_order_item[params.key][
          params.index
        ] = data;
      } else {
        (orRequest as any).teams[params.teamIndex][params.key][params.index] = data;
      }

      controller.setState({
        ORRequestSequence: { ...orRequest },
      });
    };

    if (params.action === "search") {
      HandleSearch(controller as any, {
        ...params,
        data,
        onChange: change,
        onClear: (params: any) => controller.handleEvent({ message: "RunSequence", params }),
      });
    } else if (params.action === "clear") {
      HandleClear(controller as any, {
        ...params,
        data,
        onChange: change,
      });
    } else if (params.action === "select") {
      HandleSelect(controller as any, {
        ...params,
        data,
        onSearch: (params: any) =>
          controller.handleEvent({
            message: "RunSequence",
            params,
          }),
        onChange: change,
      });
    }
  } else if (params.action === "add_team") {
    const teams = state.ORRequestSequence?.teams || [];

    teams.push(JSON.parse(JSON.stringify(TeamInitial)));

    controller.setState({
      ORRequestSequence: { ...state.ORRequestSequence, teams: [...teams] },
    });
  } else if (params.action === "anesthesia_team") {
    const teams = state.ORRequestSequence?.anesthesia_teams || [];

    teams.push(JSON.parse(JSON.stringify(AnesthesiaTeamInitial)));

    controller.setState({
      ORRequestSequence: {
        ...state.ORRequestSequence,
        anesthesia_teams: [...teams],
      },
    });
  } else if (params.action === "clear_screen") {
    const detail = state.ORRequestSequence || {};

    HandleInitial(controller, {
      card: params.card,
      orderId: detail?.id,
      emrId: detail?.emr,
      permission: detail?.permission?.[0] || {},
      clinicalTerm: detail?.clinicalTerm,
      admitOrderOptions: detail?.admitOrderOptions,
      orImplantOptions: detail?.orImplantOptions,
      encounter: {
        type: detail?.encounterType,
        created_utc: detail?.encounterDatetime,
      },
    });
  } else if (params.action === "update_location_room") {
    const detail: any = state.ORRequestSequence || {};
    const team = detail?.teams?.find((item: any) => item.is_main === true) || {};

    const [result]: any = await OperatingLocationList.list({
      apiToken: controller.apiToken,
      params: {},
    });

    if (detail.location) {
      detail.location = Number(detail.location);
    }

    const locations = mapOptions(result?.items || []);
    let rooms: any[] = [];
    const location = locations.find((item: any) => item.value === detail.location);

    if (!location) {
      detail.location = "";
    }

    if (detail.location) {
      rooms = await GetOperatingRoomOptions(controller, {
        surgeon: team.chief_surgeon,
        location: detail.location,
      });

      const room = rooms.find((item: any) => item.value === Number(detail.operating_room));

      if (!room) {
        detail.operating_room = "";
      }
    } else {
      detail.operating_room = "";
    }

    controller.setState({
      ORRequestSequence: {
        ...detail,
        orLocationOptions: locations,
        operatingRoomOptions: rooms,
      },
    });
  } else if (
    ["CONFIRM", "CANCEL_CONFIRM", "EDIT_REQUEST", "EDIT", "CANCEL", "REQUEST"].includes(params.action)
  ) {
    if (
      !state.ORRequestSequence?.modConfirm &&
      ["CANCEL_CONFIRM", "CANCEL"].includes(params.action)
    ) {
      return controller.setState({
        ORRequestSequence: {
          ...state.ORRequestSequence,
          modConfirm: params,
        },
      });
    }

    HandleSave(controller, params);
  } else if (params.action === "PRINT") {
    HandlePrint(controller, params);
  } else if (params.action === "SAVE_FORM_BLEEDING") {
    HandleSaveFormBleeding(controller, params);
  } else if (params.action === "SAVE_FORM_DVT") {
    HandleSaveFormDVT(controller, params);
  } else if(params.action === "GO_TO_MED_REC"){
    HandleGoToMedRec(controller, params)
  }
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetOperatingRoomOptions: Handler = async (controller, params) => {
  const result = await OperatingRoomList.list({
    apiToken: controller.apiToken,
    params: {
      location: params.location,
      // surgeon: params.surgeon,
    },
    extra: {
      division: controller.data.division,
    },
  });

  return mapOptions(result[0]?.items || [], "id", "room_no");
};

const UpdatePatientAppointmentUpdate: Handler = async (controller, params) => {
  return await PatientAppointmentUpdate.patch({
    pk: params.pk,
    apiToken: controller.apiToken,
    data: params.data as any,
  });
};

/* ------------------------------------------------------ */

/*                      HandleAction                     */

/* ------------------------------------------------------ */
const HandleEdit: Handler = async (controller, params) => {
  const state = controller.getState();

  const detail: any = state.ORRequestSequence || {};

  const split = params.name?.split(".") || [];
  const lastKey = split.slice(-1)?.[0] || "";

  if (["is_one_day_case", "is_opd_case", "is_ipd_case"].includes(lastKey)) {
    const caseValue = detail?.case || {};

    caseValue.is_ipd_case = false;
    caseValue.is_one_day_case = false;
    caseValue.is_opd_case = false;

    if (lastKey !== "is_ipd_case") {
      caseValue.ipd_day = "";
      caseValue.ipd_case = "";
    }

    detail.case = caseValue;
  } else if (lastKey === "is_app_admit") {
    const extra: any = detail?.extra || {};
    const is_ipd_patient = detail?.is_ipd_patient || false;
    let date = "";

    extra.nullMaxDate = params.value || is_ipd_patient;

    if (params.value) {
      date = formatDate(moment().add(1, "days"));
      extra.minDate = moment().add(1, "days");
      extra.maxDate = moment().add(1, "years");
    } else {
      date = formatDate(moment());
      extra.minDate = moment();
      extra.maxDate =
        extra.nullMaxDate || is_ipd_patient ? moment().add(1, "years") : moment().add(1, "days");
    }

    detail.case.app_admit_date = date;
    detail.extra = extra;
  } else if (lastKey === "is_request_icu") {
    const caseValue = detail?.case || {};

    if (!params.value) {
      caseValue.icu_day = "";
    }

    detail.case = caseValue;
  } else if (params.name === "is_med_block") {
    if (!params.value) {
      detail.med_block_date = "";
    }
  } else if (params.name === "is_anesthesia") {
    if (!params.value) {
      detail.anesthesia_date = "";
    }
  } else if (params.name === "is_other") {
    if (!params.value) {
      detail.other = "";
      detail.other_date = "";
    }
  } else if (params.name === "location") {
    const team = detail?.teams?.find((item: any) => item.is_main === true) || {};

    detail.operating_room = "";

    detail.operatingRoomOptions = await GetOperatingRoomOptions(controller, {
      surgeon: team.chief_surgeon,
      location: params.value,
    });
  }
  // blood request
  else if (["is_prc", "is_ffp", "is_plt", "is_other"].includes(lastKey)) {
    let bloodRequest = detail?.blood_request || {};

    if (!params.value) {
      bloodRequest[lastKey.replace(/is_/g, "")] = "";
    }

    detail.blood_request = bloodRequest;
  } else if (params.name.includes("blood_request")) {
    let bloodRequest = detail?.blood_request || {};

    bloodRequest.is_na = false;
    bloodRequest.is_yes = false;
    bloodRequest.is_yes_ts = false;

    // #if (params.value === "is_na") {
    //   bloodRequest = {
    //     ffp: "",
    //     is_ffp: false,
    //     is_other: false,
    //     is_plt: false,
    //     is_prc: false,
    //     other: "",
    //     plt: "",
    //     prc: "",
    //   };
    //   detail.blood_request = bloodRequest;
    // }
    detail.blood_request = bloodRequest;
  } else if (["is_less_500", "is_more_500"].includes(lastKey)) {
    const antiBloodLoss = detail.anticipated_blood_loss || {};

    antiBloodLoss.is_less_500 = false;
    antiBloodLoss.is_more_500 = false;

    detail.anticipated_blood_loss = antiBloodLoss;
  } else if (params.name.includes("anti_platelet_coagulant")) {
    if (
      lastKey === "value" &&
      [ADM_FIBRINOLYTIC_USED.CUT_DRUG, ADM_FIBRINOLYTIC_USED.NO_DRUG].includes(params.value)
    ) {
      const antiPlateletCoagulant = detail.anti_platelet_coagulant || {};

      antiPlateletCoagulant.remark = "";
      antiPlateletCoagulant.date = "";

      detail.anti_platelet_coagulant = antiPlateletCoagulant;
    }
  } else if (lastKey === "is_main") {
    if (params.value) {
      detail.teams = detail.teams.map((item: any) => ({
        ...item,
        is_main: false,
      }));
    } else {
      return;
    }
  } else if (params.name.includes("pre_op_medical_assessment")) {
    let preOpMedAssessment = {
      data: { risk_dvt: {}, risk_bleeding: {} },
      ...detail.pre_op_medical_assessment,
    };
    if (params.name.includes("risk_bleeding")) {
      preOpMedAssessment.data.risk_bleeding = {
        ...preOpMedAssessment.data.risk_bleeding,
        [lastKey]: params.value,
      };
      if (
        preOpMedAssessment?.data?.risk_bleeding?.active_bleeding ||
        preOpMedAssessment?.data?.risk_bleeding?.active_gastroduodenal ||
        preOpMedAssessment?.data?.risk_bleeding?.intracranial_bleeding ||
        preOpMedAssessment?.data?.risk_bleeding?.platelet_count ||
        preOpMedAssessment?.data?.risk_bleeding?.intraocular ||
        preOpMedAssessment?.data?.risk_bleeding?.platelet_dysfunction ||
        preOpMedAssessment?.data?.risk_bleeding?.untreated ||
        preOpMedAssessment?.data?.risk_bleeding?.therapeutic ||
        preOpMedAssessment?.data?.risk_bleeding?.hypertensive ||
        preOpMedAssessment?.data?.risk_bleeding?.advanced ||
        preOpMedAssessment?.data?.risk_bleeding?.postoperative ||
        preOpMedAssessment?.data?.risk_bleeding?.heparin ||
        preOpMedAssessment?.data?.risk_bleeding?.epidural
      ) {
        preOpMedAssessment.data.risk_bleeding = {
          ...preOpMedAssessment.data.risk_bleeding,
          disabled_none_risk: true,
          none_risk: false,
        };
      } else {
        preOpMedAssessment.data.risk_bleeding = {
          ...preOpMedAssessment.data.risk_bleeding,
          disabled_none_risk: false,
        };
      }
    } else if (params.name.includes("risk_dvt")) {
      preOpMedAssessment.data.risk_dvt = {
        ...preOpMedAssessment.data.risk_dvt,
        [lastKey]: params.value,
      };
      let score: number = 0;
      if (preOpMedAssessment?.data?.risk_dvt?.elderly_age) {
        score += 1;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.obesity_bmi) {
        score += 1;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.heart) {
        score += 1;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.mi) {
        score += 1;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.infection) {
        score += 1;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.hormonal) {
        score += 1;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.trauma) {
        score += 2;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.cancer) {
        score += 3;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.mobillity) {
        score += 3;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.thrombophilia) {
        score += 3;
      }
      if (preOpMedAssessment?.data?.risk_dvt?.vte) {
        score += 3;
      }
      let summary = PRE_ASSESSMENT_OPTIONS[0].value; // Low
      if (score >= 4) {
        summary = PRE_ASSESSMENT_OPTIONS[2].value; // High
      }
      if (score === 0) {
        preOpMedAssessment.data.risk_dvt = {
          ...preOpMedAssessment.data.risk_dvt,
          disabled_none_risk: false,
          score: score.toString(),
          summary: summary,
        };
      } else {
        preOpMedAssessment.data.risk_dvt = {
          ...preOpMedAssessment.data.risk_dvt,
          disabled_none_risk: true,
          none_risk: false,
          score: score.toString(),
          summary: summary,
        };
      }
    }
    detail.pre_op_medical_assessment = preOpMedAssessment;
  }

  controller.setState(
    {
      ORRequestSequence: { ...detail },
    },
    () => params.callback()
  );
};

const HandleSave: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.card_action]: "LOADING",
    },
    loadingStatus: {
      ...state.loadingStatus,
      [params.card_action]: true,
    },
  });

  const detail = {
    ...state.ORRequestSequence,
    pre_op_medical_assessment: {
      ...state.ORRequestSequence?.pre_op_medical_assessment,
      action: "SAVE",
      form_version: 1.0,
      form_code: "pre_operative_medical_assessment",
      form_name: "Pre-Operative Medical Assessment",
    },
  };

  const teamIndex = detail?.teams?.findIndex((item: any) => item.is_main);
  const teamName = `team${teamIndex ?? ""}`;

  const teams: any[] = (detail?.teams || [])?.map((item: any) => {
    const assistSurgeonIds = item.assistant_surgeons.filter(Boolean);

    const formatItems = (items: any[], icdType: string, type: any) => {
      return (items || [])
        .map((item: any) => {
          const cloneItem = { ...item };
          return {
            ...cloneItem,
            type: type,
            ...formatProcedureData(cloneItem, icdType),
          };
        })
        .filter((item: any) => item[icdType] || `${item[icdType]}_id`);
    };

    return {
      ...item,
      assistant_surgeons: assistSurgeonIds,
      pre_operating_order_item: {
        ...item.pre_operating_order_item,
        procedures: item.pre_operating_order_item.procedures.map((item: any) => {
          const cloneItem = { ...item };
          return {
            ...cloneItem,
            type: cloneItem.subType,
            ...formatProcedureData(cloneItem, "icd9cm"),
            active_disease: cloneItem.active_disease || false,
          };
        }),
        operating_treatments: item.pre_operating_order_item.operating_treatments?.filter(Boolean),
      },
      pre_principal_diagnosis: formatItems([item.diagnosis[0]], "icd10", 1),
      pre_secondary_diagnosis: formatItems(item.diagnosis.slice(1), "icd10", 2),
      implant: item.implant.join(","),
    };
  });

  const anesthetistTeams: any[] = (detail?.anesthesia_teams || [])?.map((item: any) => {
    const anesthetistIds = item.anesthetists.filter(Boolean);

    return {
      ...item,
      anesthetists: anesthetistIds,
    };
  });

  // formatData(primaryProcedure, "icd9cm")

  const data = {
    drug_operating_order_id: null,
    operating_order_id: detail?.id || null,
    operating_order: {
      action: params.action,
      ...(detail || {}),
      main_team: teamName,
      cancel_reason: params.cancel_reason,
      estimate_operation_time:
        parseInt(detail?.estimate_operation_hour || "0") * 60 +
        parseInt(detail?.estimate_operation_minute || "0"),
      teams,
      is_appointment: params.is_appointment,
      anesthesia_teams: anesthetistTeams,
      patient_appointment: params.patientAppointmentId,
      user: state?.userTokenize?.employeeName,
      start_time: detail?.start_time || null,
    },
  };

  let msgError: any = null;

  const errors = {
    estimate: {
      error: !Number(detail?.estimate_operation_hour) && !Number(detail?.estimate_operation_minute),
      label: "Estimate time",
      msg: "กรุณาระบุ estimate time",
    },
    principal: {
      error:
        typeof teamIndex === "number"
          ? !teams[teamIndex]?.pre_principal_diagnosis?.[0]?.icd10
          : false,
      label: "Preoperative diagnosis",
      msg: "กรุณาระบุ preoperative diagnosis",
    },
    ipd_case: {
      error: detail.case?.is_ipd_case ? !detail.case.ipd_case : false,
      label: "IPD Case",
      msg: "กรุณาระบุ ipd case",
    },
    ipd_day: {
      error: detail.case?.is_ipd_case ? !Number(detail?.case.ipd_day) : false,
      label: "นอน รพ.",
      msg: "กรุณาระบุวันนอน รพ.",
    },
    admit_date: {
      error: detail.case?.is_ipd_case ? !detail.case.app_admit_date : false,
      label: "นัดหมาย",
      msg: "กรุณาระบุวันที่นัดหมาย",
    },
    anti_platelet: {
      error: !detail.anti_platelet_coagulant?.value,
      label: "ยาที่ต้องหยุดก่อนผ่าตัด",
      msg: "กรุณาระบุ ยาที่ต้องหยุดก่อนผ่าตัด",
    },
    anesthesia: {
      error: detail.anesthesia_teams?.length && !detail.is_anesthesia,
      label: "เนื่องจากนัดหมายมีทีมวิสัญญีแพทย์",
      msg: "กรุณาทำการ Checkbox ช่อง “วิสัญญี”",
    },
  };

  const isError = Object.values(errors).some((value) => value.error);

  if (isError) {
    msgError = Object.values(errors).reduce(
      (result: any, value) => ({
        ...result,
        ...(value.error ? { [value.label]: value.msg } : {}),
      }),
      {}
    );
  }

  let result: any[] = [null, null, null];

  if (!msgError) {
    if (state.ORRequestSequence?.id && ["CANCEL_CONFIRM", "CANCEL"].includes(params.action)) {
      result = await OperatingOrderDetail.update({
        pk: params.operatingOrderId,
        apiToken: controller.apiToken,
        data: data.operating_order,
        extra: {
          division: controller.data.division,
        },
      });
    } else {
      result = await OperatingOrderBundleCreate.post({
        apiToken: controller.apiToken,
        data,
        extra: {
          division: controller.data.division,
        },
      });
    }
  }

  if (result[1] || msgError) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: result[1] || msgError,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card_action]: "ERROR",
      },
      loadingStatus: {
        ...state.loadingStatus,
        [params.card_action]: false,
      },
    });
    params.onError?.();
  } else {
    if (!state.ORRequestSequence?.id) {
      const ansOrder = result[0].operating_order?.other_orders.find(
        (item: any) => item.model === "anesthesiaorder"
      );
      await UpdatePatientAppointmentUpdate(controller, {
        pk: params.patientAppointmentId,
        data: {
          order_dict: {
            [result[0].operating_order.id]: "operatingorder",
            ...(ansOrder ? { [ansOrder.id]: "anesthesiaorder" } : {}),
            ...(result[0].operating_order?.admit_order
              ? { [result[0].operating_order.admit_order]: "admitorder" }
              : {}),
          },
        },
      });
      await SelectAppointment(controller, { ...state.selectedAppointment });
      // controller.handleEvent({
      //   message: "SelectAppointment" as any,
      //   params: state.selectedAppointment,
      // });
    }

    await RefreshAppointment(controller, { ...state.selectedPatient });

    controller.setState(
      {
        errorMessage: { ...state.errorMessage, [params.card]: null },
        selectedOperatingOption: state.ORRequestSequence?.type || "",
        ORRequestSequence: {
          ...state.ORRequestSequence,
          sequenceIndex: "START",
          modConfirm: null,
          // ...(
          //   params?.action === "CONFIRM"?
          //   (result[0]?.operating_order?.status? {status: result[0]?.operating_order.status} : {}) :
          //   params?.action === "CANCEL_CONFIRM"?
          //   (result[0]?.status? {status: result[0]?.status} : {}) :
          //   {}
          // ),
          // ...(params?.action === "CONFIRM"? ({allowed_action: result[0]?.operating_order?.allowed_action}) : {})
        },
        // selectedOrOrder: {
        //   ...state.selectedOrOrder,
        //   ...(
        //     params?.action === "CONFIRM"?
        //     (result[0]?.operating_order?.status? {status: result[0]?.status} : {}) :
        //     params?.action === "CANCEL_CONFIRM"?
        //     (result[0]?.status? {status: result[0]?.status} : {}) :
        //     {}
        //   )
        // },
        userTokenize: null,
      },
      async () => {
        if (params.action === "CANCEL") {
          return controller.setState({
            selectedAppointment: null,
            selectedBlock: null,
            buttonLoadCheck: { ...state.buttonLoadCheck, [params.card_action]: "SUCCESS" },
            loadingStatus: { ...state.loadingStatus, [params.card_action]: false },
          });
        }

        if (
          (state as any).ORQueueSequence?.orOrders &&
          ["CANCEL_CONFIRM", "CONFIRM"].includes(params.action)
        ) {
          controller.handleEvent({
            message: "RunSequence",
            params: { sequence: "ORQueue", action: "FETCH" },
          });

          params.onSaveSuccess?.();
        }

        await GetMaster(controller, {
          encounterId: params.encounterId,
          operatingOrderId: params.operatingOrderId,
          isNewEncounter: params.isNewEncounter,
          patientId: params?.patientId,
        });

        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [params.card_action]: "SUCCESS" },
          loadingStatus: { ...state.loadingStatus, [params.card_action]: false },
        });

        params.onCreated?.();
      }
    );
  }
};

const HandleSaveFormBleeding: Handler = async (controller, params) => {
  let state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.card_action]: "LOADING",
    },
  });

  let detail = { ...state.ORRequestSequence?.pre_op_medical_assessment, action: "SAVE" };
  const mm = moment();
  detail.data.risk_bleeding.edited = `${mm.format("DD/MM/YYYY")} [${mm.format("HH:mm")}]`;
  detail.data.risk_bleeding.edit_by = state.django?.user?.full_name;

  if (state.ORRequestSequence?.operating_detail) {
    const [resp, err, net] = await FormDataDetail.patch({
      apiToken: controller.apiToken,
      pk: detail?.id,
      data: detail,
    });
    if (err) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card_action]: "ERROR",
        },
      });
    } else {
      state = controller.getState();
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card_action]: "SUCCESS",
        },
        ORRequestSequence: { ...state.ORRequestSequence, pre_op_medical_assessment: resp },
      });
    }
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card_action]: "SUCCESS",
      },
      ORRequestSequence: { ...state.ORRequestSequence, pre_op_medical_assessment: detail },
    });
  }
};

const HandleSaveFormDVT: Handler = async (controller, params) => {
  let state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.card_action]: "LOADING",
    },
  });

  let detail = { ...state.ORRequestSequence?.pre_op_medical_assessment, action: "SAVE" };
  const mm = moment();
  detail.data.risk_dvt.edited = `${mm.format("DD/MM/YYYY")} [${mm.format("HH:mm")}]`;
  detail.data.risk_dvt.edit_by = state.django?.user?.full_name;

  if (state.ORRequestSequence?.operating_detail) {
    const [resp, err, net] = await FormDataDetail.patch({
      apiToken: controller.apiToken,
      pk: detail?.id,
      data: detail,
    });
    if (err) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card_action]: "ERROR",
        },
      });
    } else {
      state = controller.getState();
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card_action]: "SUCCESS",
        },
        ORRequestSequence: { ...state.ORRequestSequence, pre_op_medical_assessment: resp },
      });
    }
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card_action]: "SUCCESS",
      },
      ORRequestSequence: { ...state.ORRequestSequence, pre_op_medical_assessment: detail },
    });
  }
};

const HandlePrint: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.card_action]: "LOADING",
    },
  });

  const result = await OperatingOrderPrintDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.operatingOrderId,
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (result[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: result[1],
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card_action]: "ERROR",
      },
    });
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: null },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card_action]: "SUCCESS",
      },
    });
  }
};

const HandleGoToMedRec: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({ goBackAppointmentState: { ...state } });

  const divisionId = state.selectedEncounter?.division;
  const encounterId = state.selectedEncounter?.id;

  const [admitOrderItem] = await AdmitOrderRoomItemList.list({
    apiToken: controller.apiToken,
    params: { barcode_or_an_or_hn: state.selectedEncounter?.number },
    extra: { division: divisionId },
  });

  const admitOrderItems: any[] = admitOrderItem?.items || [];

  const matchingAdmitOrderItem = admitOrderItems.find((item) => item.encounter.id === encounterId);

  if (!matchingAdmitOrderItem?.id) {
    return;
  }

  controller.handleEvent({ message: "HandleSelectTabIndex" as any, params: { tab: "Encounter" } });

  controller.handleEvent({
    message: "ChangeDivision" as any,
    params: { divisionId, isRefresh: true, noDefaultTabIndex: true, resetLayout: false },
  });

  controller.setState({
    ackMedReconcileId: params.medReconcileCheck?.med_reconciliation,
    initialLayout: 1,
  });

  controller.handleEvent({
    message: "HandleSelectAdmitEncounter" as any,
    params: {
      admitRoom: { room_item_id: matchingAdmitOrderItem.id },
      encounterId,
      goToMenu: "Med Reconcile",
    },
  });
};

export const mapOptions = (list: any[], valueKey = "id", textKey = "name") => {
  return list?.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item[textKey],
  }));
};

// ========================= Appointment Event for Async ================================
const RefreshAppointment: Handler = async (controller, params) => {
  const state = controller.getState();
  // #console.log('RefreshAppointment state.selectedPatient: ', state.selectedPatient);
  const appointment = await PatientAppointmentView.list({
    params: {
      ...(state.selectedPatient && { patient_id: state.selectedPatient?.id }),
      ...(params?.id && { patient_id: params?.id }),
      exclude_cancel: true,
    },
    apiToken: controller.apiToken,
  });
  console.log(appointment[1] ? appointment[1] : appointment[0]);

  if (appointment[1] && params?.card) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: appointment[1] },
    });
  }

  const items = appointment[0]?.items?.map((item: any) => ({
    ...item,
  }));
  controller.setState({
    appointmentList: items,
  });
};

const SelectAppointment: Handler = async (controller, params) => {
  const state = controller.getState();

  const [appointment, medRecord] = await Promise.all([
    PatientAppointmentUpdate.retrieve({
      pk: params.id,
      apiToken: controller.apiToken,
    }),

    EncounterMedicalRecordList.list({
      pk: params.order_encounter,
      extra: {
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
  ]);

  if (appointment[1]) {
    if (params.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: appointment[1] },
      });
    }
  }

  console.log("medRecord", medRecord);
  console.log("appointment", appointment);

  let date = params.display_info?.start_datetime?.split("T")?.[0] || "";
  let start = params.display_info?.start_datetime?.split("T")?.[1]?.substring(0, 5) || "";
  let end = params.display_info?.end_datetime?.split("T")?.[1]?.substring(0, 5) || "";
  let datetime = `${date} ${start}-${end}`;

  // console.log("params", params)

  const [response] = await PatientDetailView.retrieve({
    pk: appointment[0]?.patient,
    apiToken: controller.apiToken,
  });

  controller.setState({
    selectedAppointment: {
      ...(params || {}),
      ...appointment[0],
      date: params.estimated_at?.split(" ")?.[0] || "",
      patient_name: `${params.patient_first_name} ${params.patient_last_name}`,
      detail: `${params.division_name || ""} (${params.display_info?.provider_name || ""} ${
        datetime || ""
      })`,
      datetime,
    },
    ...(!params?.isSave && { selectedBlock: null }),
    // selectedBlock: params?.isSave ? : null,
    selectedEncounter: {
      id: params.order_encounter,
      patient_gender_name: response?.gender_name,
    },
    preOrderList: appointment[0]?.orders || [],
    selectedEmr: medRecord[0]?.items?.[0],
  });

  // const [r, e, n] = await DivisionServiceBlockView.list({
  //   params: {
  //     divisions: [controller.data.division]
  //   },
  //   apiToken: controller.apiToken
  // });
  // if (e) return console.log("Error",
};

/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */
const GetMasterOrRequest: Handler<
  any,
  Promise<{
    order: Record<string, any>;
    clinical: Record<string, { items: any[] }>;
    admit: Record<string, any>[];
    permission: any;
    encounter: Record<string, any>;
  }>
> = async (controller, params) => {
  let state = controller.getState();

  // Master data
  if (params.action !== "refresh") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["orType", {}],
          // ["orLocation", {}],
          ["orProcedureType", {}],
          ["specialEquipment", {}],
          // ["orImplant", {}],
          ["otherTrtPosition", {}],
          ["procedureType", {}],
          ["orCancelNote", {}],
        ],
      },
    } as any);

    await controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["orClinicType", {}],
          ["anesthesiaMethod", {}],
        ],
      },
    } as any);
  }

  const api =
    params.emrId && !params.operatingOrderId
      ? OperatingOrderDetailByEmr.retrieve
      : OperatingOrderDetail.retrieve;
  const pk = params.emrId && !params.operatingOrderId ? params.emrId : params.operatingOrderId;

  const getOperatingOrder = api({
    apiToken: controller.apiToken,
    pk: pk,
  });

  const getClinicalTermSet = ClinicalTermSetView.post({
    apiToken: controller.apiToken,
    data: { question: ["asa_class", "CM", "or_implant"] },
  });

  const getAdmitOrder = AdmitOrderList.list({
    apiToken: controller.apiToken,
    params: {
      patient_id: params.patientId,
      not_finished: true,
      active: true,
      is_short: true,
    },
  });

  const getUserPermission = UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      action_ORM_OperatingOrder_CONFIRM: "",
      action_ORM_OperatingOrder_CANCEL_CONFIRM: "",
      action_ORM_OperatingOrder_CANCEL: "",
    },
  });

  const getEncounter = EncounterDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.encounterId || state.selectedEncounter?.id,
  });

  const promiseArr = [
    getOperatingOrder,
    getClinicalTermSet,
    getAdmitOrder,
    getUserPermission,
    getEncounter,
  ];

  // #if (!state.selectedEncounter?.type) {
  //   promiseArr.push(
  //     EncounterDetail.retrieve({
  //       apiToken: controller.apiToken,
  //       pk: params.emrId || state.selectedEmr?.encounter,
  //     })
  //   );
  // }

  const [order, clinical, admit, permission, encounter] = await Promise.all(promiseArr);

  const encounterDetail = encounter?.[0];

  return {
    order: order[0]
      ? {
          ...order[0],
          encounterType: encounterDetail?.type,
          encounterDatetime: encounterDetail?.created_utc,
        }
      : {},
    clinical: clinical[0],
    admit: admit[0]?.items || [],
    permission: permission[0],
    encounter: encounterDetail,
  };
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
type FormatWithKeyParams = {
  data: Record<string, any>[] | Record<string, any> | null;
  key: string;
  index: number;
};

const formatListWithKey = (
  listWithKey: any,
  params: FormatWithKeyParams | FormatWithKeyParams[]
) => {
  const setListWithKey = (item: FormatWithKeyParams) => {
    if (item.data && Array.isArray(item.data)) {
      for (const [i, detail] of item.data.entries()) {
        listWithKey[`${item.key}_${item.index + 1}_${i + 1}`] = [detail];
      }
    } else {
      listWithKey[`${item.key}_${item.index + 1}`] = item.data ? [item.data] : null;
    }
  };

  if (Array.isArray(params)) {
    for (const item of params) {
      setListWithKey(item);
    }
  } else {
    setListWithKey(params);
  }
};

export const removeListWithKey = (listWithKey: any, removeKeys: typeof SEARCH_INDEXES) => {
  for (const key in removeKeys) {
    if (removeKeys[key] === "CI") {
      removeArrayCIndex(listWithKey, key);
    } else {
      removeArrayIndex(listWithKey, key);
    }
  }
};

export const shiftArrayWithKey = (
  listWithKey: any,
  removeKeys: typeof SEARCH_INDEXES,
  index: number
) => {
  const clone = JSON.parse(JSON.stringify(listWithKey)); // Deep copy the original data

  for (const key in removeKeys) {
    if (removeKeys[key] === "CI") {
      shiftArrayCIndex({ listWithKey, clone }, key, index);
    } else {
      shiftArrayIndex({ listWithKey, clone }, key, index);
    }
  }
};

const shiftArrayCIndex = (data: any, key: string, shiftIndex: number) => {
  const maxIndex = findMaxIndex(data.listWithKey, key);

  let cIndex = 1;

  for (let index = shiftIndex; index <= maxIndex; index++) {
    while (data.clone[`${key}_${index + 1}_${cIndex}`]) {
      data.listWithKey[`${key}_${index}_${cIndex}`] =
        data.clone[`${key}_${index + 1}_${cIndex}`] || [];

      cIndex++;
    }

    cIndex = 1;
  }
};

const shiftArrayIndex = (data: any, key: string, shiftIndex: number) => {
  const maxIndex = findMaxIndex(data.listWithKey, key);

  for (let index = shiftIndex; index <= maxIndex; index++) {
    data.listWithKey[`${key}_${index}`] = data.clone[`${key}_${index + 1}`] || [];
  }
};

const removeArrayCIndex = (listWithKey: any, key: string) => {
  let index = 1;
  let cIndex = 1;

  while (listWithKey[`${key}_${index}_${cIndex}`]) {
    delete listWithKey[`${key}_${index}_${cIndex}`];

    cIndex++;
    while (listWithKey[`${key}_${index}_${cIndex}`]) {
      delete listWithKey[`${key}_${index}_${cIndex}`];
      cIndex++;
    }

    index++;
    cIndex = 1;
  }
};

const findMaxIndex = (obj: Record<string, any>, prefix: string): number => {
  const regex = new RegExp(`^${prefix}_([0-9]+)`);

  const maxNumber = Math.max(
    ...Object.keys(obj)
      .map((key) => {
        const match = key.match(regex);
        return match ? Number.parseInt(match[1]) : Number.NEGATIVE_INFINITY;
      })
      .filter((num) => !Number.isNaN(num))
  );

  return maxNumber === Number.NEGATIVE_INFINITY ? -1 : maxNumber;
};

export const removeSpecificArrayCIndex = (listWithKey: any, key: string, index: number) => {
  let cIndex = 1;

  while (listWithKey[`${key}_${index}_${cIndex}`]) {
    delete listWithKey[`${key}_${index}_${cIndex}`];

    cIndex++;
  }
};

const removeArrayIndex = (listWithKey: any, key: string) => {
  let index = 1;

  while (listWithKey[`${key}_${index}`]) {
    delete listWithKey[`${key}_${index}`];

    index++;
  }
};

export const getListWithKey: Handler = async (
  controller,
  params: {
    order: any;
    listWithKey: any;
    keys: ["chief_surgeon", "assistant_surgeons", "anesthesiologist", "anesthetists"];
  }
) => {
  const order = params.order;
  const listWithKey = params.listWithKey;

  const mapItems = (items?: any[], key: any = "") => {
    return (items || [])
      .flatMap((item: any) => (params.keys.includes(key) ? [item[key]] : []))
      .flat();
  };

  const chiefSurgeons: number[] = mapItems(order.teams, "chief_surgeon");

  const assistSurgeons: number[] = mapItems(order.teams, "assistant_surgeons");

  const anesthesiologists: number[] = mapItems(order.anesthesia_teams, "anesthesiologist");

  const anesthetists: number[] = mapItems(order.anesthesia_teams, "anesthetists");

  const uniqueSurgeons = Array.from(
    new Set([...chiefSurgeons, ...assistSurgeons, ...anesthesiologists])
  );

  const uniqueAnesthetists = Array.from(new Set([...anesthetists]));

  const doctorRes = await Promise.all(
    uniqueSurgeons.map((id: number) =>
      DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: id,
      })
    )
  );

  const userRes = await Promise.all(
    uniqueAnesthetists.map((id: number) =>
      UserList.list({ apiToken: controller.apiToken, params: { pk: id } })
    )
  );

  const findDoctor = (id: number) => {
    return doctorRes.find((acc: any) => acc[0]?.id === id)?.[0];
  };

  const findUser = (id: number) => {
    return userRes.find((acc: any) => acc[0]?.items?.[0]?.id === id)?.[0]?.items?.[0];
  };

  for (const [index, item] of order.teams.entries()) {
    const data = findDoctor(item.chief_surgeon);

    const assistSurgeons = item.assistant_surgeons?.length
      ? item.assistant_surgeons.map((id: number) => findDoctor(id))
      : [];

    formatListWithKey(listWithKey, [
      {
        data: item.pre_operating_order_item?.operating_treatments || [],
        key: SEARCH_KEYS.OPERATING_TREATMENT,
        index,
      },
      {
        data: assistSurgeons,
        key: SEARCH_KEYS.DOCTOR_ASSIST_SURGEON,
        index,
      },
      {
        data,
        key: SEARCH_KEYS.DOCTOR_SURGEON,
        index,
      },
    ]);
  }

  for (const [index, item] of order.anesthesia_teams.entries()) {
    const data = findDoctor(item.anesthesiologist);

    const anesthetists = item.anesthetists?.length
      ? item.anesthetists.map((id: number) => findUser(id))
      : [];

    formatListWithKey(listWithKey, [
      {
        data: anesthetists,
        key: SEARCH_KEYS.USER_ANESTHETIST,
        index,
      },
      {
        data,
        key: SEARCH_KEYS.DOCTOR_ANESTHESIOLOGIST,
        index,
      },
    ]);
  }

  return listWithKey;
};
