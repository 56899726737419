import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";

import CardDrugTransferRequestQueueUX from "./CardDrugTransferRequestQueueUX";


const ColumnStyle = {
    marginTop: -7,
    marginLeft: -4,
    width: "calc(100% + 10px)",
    height: "calc(100% + 14px)",
    padding: "7px 7px",
  };
  const SEMANTIC_COLOR={
    "blue": "rgba(33, 133, 208, 1)",
    "red":"rgba(219, 40, 40, 0.2)",
    "yellow":"rgba(251, 189, 8, 0.4)",
    "purple":"rgba(163, 51, 200, 1)",
    'gray':"rgb(160, 160, 160)"
   }
  const STATUS_COLOR :Record<string, string> = {
    '':            SEMANTIC_COLOR.gray,
    'REQUESTED':  SEMANTIC_COLOR.red,
    'APPROVED':    SEMANTIC_COLOR.yellow,
    'DELIVERED':   SEMANTIC_COLOR.blue,
    'RECEIVED': SEMANTIC_COLOR.purple
}


const get_status_color = (status :string) => {
    return STATUS_COLOR[status] || STATUS_COLOR['']
}


const CardDrugTransferRequestQueue = (props: any) => {

  useEffect(() => {
    props.onEvent({ message: "GetDrugTransferRequestList"});
  }, []);

  const drugTransferRequest = useMemo(() => {
    return (props.drugRequestList?.items || []).map((item: any) => ({
      ...item,
      type : (
        <div style={{...ColumnStyle, color: "white", backgroundColor: item?.is_requester ?  SEMANTIC_COLOR.blue : item?.is_provider ? SEMANTIC_COLOR.red : SEMANTIC_COLOR.gray}}>
            {item?.is_requester 
            ? "REQUEST"
            : item?.is_provider
            ? "PROVIDE"
            : null
            }  
        </div>
      ),
      requested:(
        <div style={{...ColumnStyle, backgroundColor:item?.status_name === "REQUESTED" ? get_status_color(item?.status_name) : ""  }}>
          {moment(item?.requested?.datetime).format("HH:mm")} 
        </div>
      ),
      approved:(
        <div style={{...ColumnStyle, backgroundColor: item?.status_name === "APPROVED" ? get_status_color(item?.status_name) :""  }}>
          {item?.approved?.datetime ? moment(item?.approved?.datetime).format("HH:mm") : null} 
        </div>

      ),
      delivered:(
        <div style={{...ColumnStyle, backgroundColor: item?.status_name === "DELIVERED" ? get_status_color(item?.status_name) :"" }}>
          {item?.delivered?.datetime ? moment(item?.delivered?.datetime).format("HH:mm") : null} 
        </div>
      ),
      received:(
        <div style={{...ColumnStyle, backgroundColor:item?.status_name === "RECEIVED" ? get_status_color(item?.status_name) :"" }}>
          {item?.received?.datetime ? moment(item?.received?.datetime).format("HH:mm") : null} 
        </div>
      ),
      is_requester: (
        <div style={{...ColumnStyle}}>
          {item?.is_requester
            ? item?.provider_name
            : item?.is_provider
            ? item?.requester_name
            : null
          }
        </div>
      ),
      status_name:(
        <div style={{...ColumnStyle, backgroundColor: get_status_color(item?.status_name)  }}>
        {item?.status_name } 
    </div> 
      )  
    }));
  }, [props.drugRequestList]);


  return (
    <CardDrugTransferRequestQueueUX
      drugTransferRequestList={drugTransferRequest}
    />
  );
};

export default CardDrugTransferRequestQueue;
