import React, { CSSProperties } from "react";

// HIS new architecture
import LandingUX from "react-lib/apps/HISV3/common/LandingUX";
import Debug from "react-lib/apps/HISV3/common/Debug";
import setUpController from "./setUpController";
import TopBar from "./TopBar";
import * as ViewContent from "./ViewContent";
import * as ViewAppointment from "./ViewAppointment";
import * as ViewRegistration from "./ViewRegistration";
import * as ViewEncounter from "./ViewEncounter";
import * as ViewPathway from "./ViewPathway";
import * as ViewPathwayOld from "./ViewPathwayOld";
import * as ViewAccounting from "./ViewAccounting";
import * as MainVideoCall from "./MainVideoCall";
import * as MemberHealthKit from "./MemberHealthKit";

// IsHealth
import DoctorSchedule from "./IsHealth/DoctorSchedule";
import CardDoctorSchedule from "react-lib/apps/HISV3/APP/CardDoctorSchedule";
import CardDoctorFeeReportOld from "react-lib/apps/HISV3/APP/CardDoctorFeeReport";
import CardTelemedRecord from "react-lib/apps/IsHealth/Dashboard/CardTelemedRecord";
import Studio from "./IsHealth/Studio";
import CreateOA from "./IsHealth/CreateOA";
import { SettingOAAccount } from "Main/Settings";
import { ManagePermissions } from "Main/Settings";
import VaccineUpload from "./VaccineUpload/VaccineUpload";
import ErrorBoundary from "react-lib/apps/common/ErrorBoundary";
import StaffChat from "react-lib/apps/MSG/StaffChat";
import CardSettingTRTOptions from "react-lib/apps/HISV3/DEN/CardSettingTRTOptions";
import CardSettingMediaDocument from "react-lib/apps/HISV3/DEN/CardSettingMediaDocument";

import "react-table-6/react-table.css";
import "semantic-ui-css/semantic.min.css";
import "react-lib/apps/QUE/Time.css";
import "../css/main.scss";

// From CUDENT2
import CardDentistSchedule from "react-lib/apps/Scheduling/CardDentistSchedule";
import CardDivisionSchedule from "react-lib/apps/Scheduling/CardDivisionSchedule";
import CardHoliday from "react-lib/apps/Scheduling/CardHoliday";
import CardChair from "react-lib/apps/Scheduling/CardChair";
import CardStatistics from "react-lib/apps/Scheduling/CardStatistics";

// ANS
import CardSetupAnesthesiaTemplate from "react-lib/apps/HISV3/ANE/CardSetupAnesthesiaTemplate";
// ORM
import CardManageOperatingRoom from "react-lib/apps/HISV3/ORM/CardManageOperatingRoom";

// Port bplus 55888
import QAChatTable from "react-lib/apps/IsHealth/Platform/QAChatTable";
import ModSelectAppointment from "react-lib/apps/IsHealth/Common/ModSelectAppointment";
// Port bplus 55887
import Dashboard from "react-lib/apps/IsHealth/Dashboard/Dashboard";
import CardQueueForPatient from "react-lib/apps/IsHealth/Dashboard/CardQueueForPatient";

// CLM
import CardCreateInvoiceGroup from "react-lib/apps/HISV3/CLM/CardCreateInvoiceGroup";
import CardExportEClaimFile from "react-lib/apps/HISV3/CLM/CardExportEClaimFile";
import CardOPDDownloadSentClaimFile from "react-lib/apps/HISV3/CLM/CardOPDDownloadSentClaimFile";
import CardUploadPatientCoverage from "react-lib/apps/HISV3/CLM/CardUploadPatientCoverage";
import CardImportLabValuesCalcDoctorFee from "react-lib/apps/HISV3/CLM/CardImportLabValuesCalcDoctorFee";
import CardDoctorFeeSummary from "react-lib/apps/HISV3/CLM/CardDoctorFeeSummary";
import CardDoctorFeeReport from "react-lib/apps/HISV3/CLM/CardDoctorFeeReport";
import CardClaimTaxDocument from "react-lib/apps/HISV3/CLM/CardClaimTaxDocument";
import CardAIPNSendClaim from "react-lib/apps/HISV3/CLM/CardAIPNSendClaim";
import CardSendClaimResponseFile from "react-lib/apps/HISV3/CLM/CardSendClaimResponseFile";
import CardCostCenterReceipt from "react-lib/apps/HISV3/CLM/CardCostCenterReceipt";
import CardSapAccountingReport from "react-lib/apps/HISV3/CLM/CardSapAccountingReport";

// PHR
import CardUnderlyingDisease from "react-lib/apps/HISV3/PHR/CardUnderlyingDisease";
import CardDrugDiseaseInteraction from "react-lib/apps/HISV3/PHR/CardDrugDiseaseInteraction";
import CardHealthMember from "react-lib/apps/HISV3/PHR/CardHealthMember";

// TPD
import CardNotiMessage from "react-lib/apps/HISV3/TPD/CardNotiMessage";

// HCU
import CardSettingPackage from "react-lib/apps/HISV3/HCU/CardSettingPackage";
import CardSettingQueue from "react-lib/apps/HISV3/HCU/CardSettingQueue";
import CardSettingAppointmentReminder from "react-lib/apps/HISV3/HCU/CardSettingAppointmentReminder";
import CardPersonnelInformation from "react-lib/apps/HISV3/HCU/CardPersonnelInformation";
import CardStaffSetting from "react-lib/apps/USER/CardStaffSetting";

// BIL
import CardFinancialReport from "react-lib/apps/HISV3/BIL/CardFinancialReport";
import CardClinicAnnualReport from "react-lib/apps/HISV3/BIL/CardClinicAnnualReport";
import CardReceiptAr from "react-lib/apps/HISV3/BIL/CardReceiptAr";
import CardWithDrawalRules from "react-lib/apps/HISV3/BIL/CardWithDrawalRules";

// From cnmicro
import {
  DOCTOR_ORDER_ACRONYM,
  DOCTOR_ORDER_BG_COLOR,
} from "../_qmljs/Common/Util";
import * as CONSTANT from "react-lib/utils/constant";
import ControllerAdapter from "./ControllerAdapter";
import CardOPDClaimResolve from "react-lib/apps/ARM/CardOPDClaimResolve";
import CardCreateARTransactionContainer from "react-lib/apps/ARM/CardCreateARTransactionContainer";
import CardUploadSentClaimResponseFile from "react-lib/apps/ARM/CardUploadSentClaimResponseFileContainer";
import CardCreateARTransaction from "react-lib/apps/ARM/CardCreateARTransaction";
import CardSentClaimInformation from "react-lib/apps/ARM/CardSentClaimInformation";
import CardUploadSentClaimResult from "react-lib/apps/ARM/CardUploadSentClaimResult";
// import ARReview from "./AR/ARReview";
// import DataScience from "./AR/DataScience";
import { vcMessenger, WSMessenger } from "react-lib/compat/vc-websocket";

// Misc
import { toast } from "react-toastify";
import { formatDropdown } from "react-lib/utils";
import { deliveryStatusOptions } from "react-lib/apps/HISV3/TPD/telepharOptions";
import MainHISController from "./MainHISController";
import * as MainHISI from "./MainHISInterface";
import { SetPropSeparate } from "react-lib/frameworks/SetProp";
import Cookies from "js-cookie";
import { IntlProvider } from "react-intl";
import thMessage from "./lang/th.json";
import enMessage from "./lang/en.json";
import ChatSupport from "react-lib/apps/IsHealth/Common/ChatSupport";

import TabPenta from "react-lib/frameworks/TabPenta";
import PasswordLoginManager from "react-lib/apps/IsHealth/Common/PasswordLogin/PasswordLoginManager";

import CONFIG from "config/config";
import {
  Icon,
  Image,
  Label,
  Popup,
  Header,
  Progress,
  Grid,
} from "semantic-ui-react";

const HOST = `${CONFIG.API_HOST}`;
const WS_HOST = `${CONFIG.WS_HOST}`;

const IMAGES = {
  IHlogo: "images/IHlogo.png",
  logo: "images/IHimage.png",
  appointment: "/static/images/menus/appointment.png",
  queue: "/static/images/menus/queue.png",
  search: "/static/images/menus/search.png",
  telemed: "/static/images/menus/telemed.png",
  report: "/static/images/menus/report.png",
  chatSupport: "/static/images/menus/chat-support.png",
  staffChat: "/static/images/menus/staff-chat.png",
  memberHealthKit: "/static/images/menus/ep_monitor.png",
};

const styles = {
  tab_icon: {
    padding: "0.5rem",
    height: "65px",
    display: "grid",
    placeContent: "center",
    width: "54px",
  } as CSSProperties,
};

class MainHIS extends React.Component<any, MainHISI.State> {
  controller: MainHISController;

  // Handle legacy controller
  proxyController: any;
  crdFormViewerController: any;
  nurseNoteController: any;
  subICD10DetailController: any;
  crdAppointmentRequestNewController: any;
  modSearchDoctorNoteController: any;
  subDoctorScheduleController: any;
  consultController: any;
  crdAdmitOrderController: any;
  crdAdmissionFormController: any;
  cardDischargeController: any;
  cardImageGalleryController: any;
  cardMedCertController: any;
  crdMultiDisciplinaryController: any;
  modOrderDetailController: any;
  scannedDocumentController: any;
  printListController: any;
  queueController: any;
  dashboardController: any;
  ruleListController: any;
  chatController: any;
  diagFormController: any;
  patientPanelController: any;
  progressNoteController: any;
  classifyController: any;
  crdVitalSignController: any;
  diagComposerController: any;
  drugOrderHistoryController: any;
  drugOrderQueueController: any;
  appointmentDashboardController: any;
  cardANSBillController: any;
  cardSentClaimInformationController: any;
  hasProgressionCycle: any;

  chatBoxRef: any;
  staffChatRef: any;
  // qaChatTable: any;
  qaChatTableRef: any;
  dashboardRef: any;
  tabPatientRef: any;
  tabSubAppointmentRef?: any;
  lastReadMessage: any;
  openVideoCallModal: boolean;
  closeOverriding: boolean;
  videoCallRoom: string;
  currChatChannelId: string;
  currPatientId: string;
  vcMessengerQueue: any;
  vcMessengerBil: any;
  vcMessengerShp: any;
  serviceQueueRef: any;
  transferOrderCount: number;

  constructor(props: any) {
    super(props);
    console.log(" ")
    this.state = { ...MainHISI.StateInitial };
    this.controller = new MainHISController(
      () => {
        return this.state;
      },
      (state: MainHISI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );
    this.setState = this.setState.bind(this);
    this.controller.setProp = SetPropSeparate(this, "");
    this.controller.cookies = Cookies;

    this.controller.apiToken = Cookies.get("apiToken") || "";
    this.controller.data.user = parseInt(Cookies.get("user_id") || "0");
    this.controller.data.division = parseInt(Cookies.get("division_id") || "0");
    this.controller.data.device = parseInt(Cookies.get("device_id") || "0");

    document.documentElement.setAttribute("data-theme", "penta");
    // To convert legacy controller to handleEvent call
    this.proxyController = Object.fromEntries(
      ControllerAdapter.map((item: any[]) => [
        item[0],
        (...args: any) =>
          this.controller.handleEvent({
            message: item[0],
            params:
              item[1].length === 0
                ? args.length === 0
                  ? {}
                  : args[0]
                : item[1].reduce(
                  (obj: object, paramName: string, index: number) => {
                    return {
                      ...obj,
                      [paramName]: index < args.length ? args[index] : null,
                    };
                  },
                  {}
                ),
          }),
      ])
    );
    setUpController(this);

    this.chatBoxRef = React.createRef();
    this.staffChatRef = React.createRef();
    // this.qaChatTable = React.createRef();
    this.qaChatTableRef = React.createRef();
    this.dashboardRef = React.createRef();
    this.tabPatientRef = React.createRef();
    this.tabSubAppointmentRef = React.createRef();
    this.serviceQueueRef = React.createRef();

    this.lastReadMessage = null;
    this.openVideoCallModal = false;
    this.closeOverriding = false;
    this.videoCallRoom = "";
    this.currChatChannelId = "";
    this.currPatientId = "";
    this.vcMessengerQueue = new WSMessenger();
    this.vcMessengerBil = new WSMessenger();
    this.vcMessengerShp = new WSMessenger();
    this.transferOrderCount = 0;

    vcMessenger.onMessage((message: any) => {
      console.log("saika ~ message vcMessenger:", message)
      this.handleOnMessageMSG(message);
    });

    this.vcMessengerQueue.onMessage((message: any) => {
      console.log("saika ~ message vcMessengerQueue:", message)
      this.handleOnMessageQueue(message);
    });

    this.vcMessengerBil.onMessage((message: any) => {
      this.handleOnMessageBil(message);
    });

    this.vcMessengerShp.onMessage((message: any) => {
      this.handleOnMessageShp(message);
    });

    // Initial DuckDB If config
    this.controller.handleEvent({
      message: "DidMount",
      params: {},
    });


  }

  componentDidMount = async () => {
    // Intercept Issue 63382 login by cert
    const params = new URLSearchParams(window.location.search);

    const auth_ver: any = params.get("auth_ver") || "1";
    const app = CONFIG.APP_NAME; // "isHealth"
    const email = params.get("email") || "";
    const firstName = params.get("first_name") || "";
    const lastName = params.get("last_name") || "";
    const time = params.get("time") || "";
    const sign = params.get("sign") || "";
    const role = params.get("role") || "";
    const division = params.get("division") || "";
    const title = params.get("title") || "";
    const security_group = params.get("security_group") || "";
    const care_provider_code = params.get("care_provider_code") || "";

    if (sign && role && care_provider_code) {
      // Login by cert
      const plm = new PasswordLoginManager();
      let data = null
      try {
       data = await plm.requestTokenByCertLogin({
        app,
        auth_ver,
        care_provider_code,
        division,
        email,
        firstName,
        lastName,
        role,
        security_group,
        sign,
        time,
        title,
      });

      console.log("requestTokenByCertLogin data:", data);
    } catch (error) {
      // ไปหน้า login
      console.error('error: ', error);
      const url = new URL(window.location.origin);
      url.searchParams.set("app", "HIS");
      window.history.replaceState({}, "", url);
      return this.controller.handleEvent({
        message: "Logout",
        params: {},
      });
    }

      if (data?.token && data?.profile?.userId) {
        Cookies.set("apiToken", data?.token, { path: "/" });
        Cookies.set("user_id", data?.profile?.userId, { path: "/" });
        this.controller.apiToken = data?.token;
        const url = new URL(window.location.origin);
        url.searchParams.set("app", "HIS");
        window.history.replaceState({}, "", url);
      }
    }

    const drug_id = params.get("drug_id");
    console.log('componentDidMount drug_id: ', drug_id);
    const encounter_id = params.get("encounter_id");
    console.log('componentDidMount encounter_id: ', encounter_id);
    const division_id = params.get("division_id");
    console.log('componentDidMount division_id: ', division_id);
    const staff_chat = params.get("staff_chat");
    console.log('componentDidMount staff_chat: ', staff_chat);
    const patientId = params.get("patient_id");
    console.log('componentDidMount patientId: ', patientId);
    const hn = params.get("hn");
    console.log('componentDidMount hn: ', hn);
    const open = params.get("open") as "Scheduling" | "SearchPatient" | "ServiceQueue" | null;
    console.log('componentDidMount open: ', open);
    const tab = params.get("tab");
    console.log('componentDidMount tab: ', tab);

    let defaultSideMenu = "";

    console.log("continue SetState apiToken:  ", Cookies.get("apiToken"));
    console.log("continue SetState user_id", Cookies.get("user_id"));
    console.log("open:", open);

    if (open === "Scheduling") {
      defaultSideMenu = "Appointment";

      if (patientId && hn) {
        this.setState({
          goToCreateAppointment: {
            selectedPatient: { id: Number(patientId), hn },
          },
        });
      }

      const url = new URL(window.location.href);

      url.searchParams.delete("patient_id");
      url.searchParams.delete("hn");
      url.searchParams.delete("open");
      window.history.replaceState({}, "", url.toString());
    } else if (open === "SearchPatient") {
      defaultSideMenu = "Registration";

      if (patientId) {
        this.setState({ initialLayout: 1 });

        this.runSequence({
          sequence: "SetPatient",
          action: "select",
          patient: { id: Number(patientId) },
          restart: true,
        });
      }
    } else if (open === "ServiceQueue") {
      defaultSideMenu = "Encounter";

      if (division_id && tab) {
        this.controller.handleEvent({
          message: "ChangeDivision",
          params: {
            divisionId: division_id,
            isRefresh: true,
            noDefaultTabIndex: true,
            tabPatientList: tab,
          },
        });
      }
    } else if (encounter_id && drug_id) {
      this.setState({ initialLayout: 1 });
      // ดูก่อน ว่ามี SelectedDrugOrderWorking Component
      this.controller.handleEvent({
        message: "SelectDrugOrderWorking",
        params: {
          drugOrder: { id: drug_id, encounter: encounter_id },
          initial: true,
        },
      });
    } else if (encounter_id && division_id) {
      if (this.controller.apiToken) {
        this.handleGetEncounter();
      }
    } else if (staff_chat && !CONFIG.RAKSTHAI_DISABLED_USER_MODE) {
      this.setState({ goToStaffChat: true, mode: "user" });
    }

    await this.controller.handleEvent({
      message: "RunSequence",
      params: { sequence: "AppInitialize", defaultSideMenu },
    });

    this.controller.handleEvent({
      message: "GetUnreadMessageCountStaff",
      params: {
        all: true,
        isPatient: false,
        user: Cookies.get("user_id") || "0",
      },
    });
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (this.state.selectedDivision?.id !== prevState.selectedDivision?.id && this.state.selectedDivision?.id) {
      // console.log('vc- componentDidUpdate prevState.selectDivision?.id: ', prevState.selectDivision?.id);
      // console.log('vc- componentDidUpdate this.state.selectedDivision?.id: ', this.state.selectedDivision?.id);
      this.connectWebSocket(this.state.selectedDivision);
    } else {
      // console.log('vc- componentDidUpdate not called ',this.state.selectedDivision?.id);
    }

    if (this.state.userId && this.state.userId !== prevState.userId) {
      CONFIG.updateConfig();
      CONFIG.updateVersion();
    }

    if (
      this.state.goToMenu &&
      (this.state.selectedEncounter ||
        this.state.SetPatientSequence?.patient?.id)
    ) {
      const tabs = Array.from(document.querySelectorAll(".tab-menu.--framework > [id*=tab-]"));
      console.log('MainHIS tabs: ', tabs);

      const index = tabs.findIndex((elm) =>
        elm.textContent?.trim()?.includes(this.state.goToMenu)
    );

      // console.log("MainHIS Change selectedRecordViewIndex: ", index);
      // console.log("MainHIS Change this.state.goToMenu: ", this.state.goToMenu);
      // console.log("MainHIS Change this.state.SetPatientSequence?.patient?.id: ", this.state.SetPatientSequence?.patient?.id);

      if ( index === -1 ) {
        this.setState({
          selectedRecordViewIndex: 0,
          goToMenu: "",
        });
      } else if (tabs.length) {
        this.setState({
          selectedRecordViewIndex: index,
          goToMenu: "",
        });
      }
    }

    if (this.state.errorMessage !== null && !this.controller.apiToken) {
      const message = this.state.errorMessage;
      console.log("message", this.state.errorMessage, message);
      if (message?.password && message?.username) {
        toast.error("username และ password ไม่ถูกต้อง");
      } else {
        if (message?.password) {
          toast.error("password ไม่ถูกต้อง");
        } else if (message?.username) {
          toast.error("username ไม่ถูกต้อง");
        } else if (message?.non_field_errors) {
          toast.error("ไม่สามารถเข้าใช้งานด้วยใบรับรองนี้");
        } else if (message?.request_token_errors) {
          toast.error("กรุณาล็อคอินใหม่อีกครั้ง");
        } else if (message?.server) {
          toast.error(message.server);
        }
      }
      this.controller.setProp("errorMessage", null);
    }


  };

  connectWebSocket = (target_division: any) => {
    console.log('vc-websocket connectWebSocket: target_division ', target_division);
    // Need to send DJANGO via REST instead of template rendering
    if ((vcMessenger as any).isSupported) {
      // Connect to websocket
      if (!vcMessenger.isConnecting()) {
        if (!vcMessenger.isConnected()) {
          // Prevent duplicate connection
          console.log("vc-websocket connectWebSocket... ", this.state.selectedDivision);
          vcMessenger.connect(
            "MSG",
            {
              screens: target_division?.id ? target_division.id : "",
              token: this.controller.apiToken,
            },
            WS_HOST
          );
        }

      }

      this.handleConnectVCMessenger(this.vcMessengerQueue, "ServiceQueue");

      this.handleConnectVCMessenger(this.vcMessengerBil, "MainBIL");

      this.handleConnectVCMessenger(this.vcMessengerShp, "SHP");
    } else {
      // Websocket not supported
      console.error("Websocket not supported");
    }
  };

  handleConnectVCMessenger = (vcMessenger: any, screens: any) => {
    if (!vcMessenger.isConnecting()) {
      if (!vcMessenger.isConnected()) {
        // Prevent duplicate connection
        console.log("vc-websocket connectWebSocket... ", this.state.selectedDivision);
        vcMessenger.connect(
          this.state.selectedDivision?.code,
          {
            screens: screens,
            token: this.controller.apiToken,
          },
          WS_HOST
        );
      }
    }
  };

  handleOnMessageMSG = (message: any) => {
    console.log(" vcMessenger.onMessage", { message });

    if (message["data_message_type"] === "MESSAGE") {
      const isChatBoxRef =
        !("current" in (this.chatBoxRef || {})) && this.chatBoxRef;

      if (!isChatBoxRef && this.state.chatDetail?.chat_channel) {
        this.updateUnreadMessageCount("detail", message);
      }

      if (!isChatBoxRef && !!this.state.encounterList?.length) {
        this.updateUnreadMessageCount("encounter", message);
      }

      if (this.state.allChatRoomList?.length) {
        this.updateUnreadMessageCount("staff", message);
      }

      if (this.chatBoxRef?.receivedMessage) {
        this.chatBoxRef.receivedMessage({
          chatChannelId: parseInt(message["channel_id"]),
        });
      }

      const isQaChatTableRef =
        !("current" in (this.qaChatTableRef || {})) && this.qaChatTableRef;

      if (isQaChatTableRef && this.qaChatTableRef) {
        if (this.qaChatTableRef.chatBoxRef) {
          this.qaChatTableRef.chatBoxRef.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }
        this.qaChatTableRef.receivedMessage?.({
          chatChannelId: parseInt(message["channel_id"]),
        });
      }

      // #if (config.CLASSIFY_CHAT || config.AIRFLOW_CHAT) {
      //   if (qaChatTable) {
      //     if (qaChatTable.chatBoxRef) {
      //       qaChatTable.chatBoxRef.getLastMessage({
      //         chatChannelId: parseInt(message["channel_id"]),
      //       });
      //     }
      //     if (qaChatTable.chatBoxRef) {
      //       qaChatTable.chatBoxRef.receivedMessage({
      //         chatChannelId: parseInt(message["channel_id"]),
      //       });
      //     }
      //     qaChatTable.receivedMessage({
      //       chatChannelId: parseInt(message["channel_id"]),
      //     });
      //   }
      // }

      if (message["content_type"] === "activity") {
        if (
          message.user_name !== this.controller.data?.userProfile?.username &&
          message.channel_id !== this.chatBoxRef?.chatChannelId
        ) {
          this.setState({ showIconNoti: true });
        }
        //
        console.log(" need refresh chat !! this.chatBoxRef: ", this.chatBoxRef);
        console.log(
          " need refresh chat !! this.chatBoxRef?.current: ",
          this.chatBoxRef?.current
        );
        console.log(
          " need refresh chat !! this.staffChatRef: ",
          this.staffChatRef
        );
        console.log(
          " need refresh chat !! this.staffChatRef?.current: ",
          this.staffChatRef?.current
        );

        this.staffChatRef?.getChatChannelList?.({
          channelId: message.channel_id,
        });
      }
    } else if (message["data_message_type"] === "MESSAGE_READ") {
      if (this.lastReadMessage === message.message_id) {
        return;
      }

      this.lastReadMessage = message.message_id;

      if (this.chatBoxRef?.receivedMessageRead) {
        this.chatBoxRef.receivedMessageRead({
          chatChannelId: parseInt(message["channel_id"]),
          messageId: parseInt(message["message_id"]),
        });
      }

      if (this.chatBoxRef?.receivedMessage) {
        this.chatBoxRef.receivedMessage({
          chatChannelId: parseInt(message["channel_id"]),
        });
      }

      const isQaChatTableRef =
        !("current" in (this.qaChatTableRef || {})) && this.qaChatTableRef;

      if (isQaChatTableRef && this.qaChatTableRef) {
        this.qaChatTableRef.receivedMessage?.({
          chatChannelId: parseInt(message["channel_id"]),
        });
      }
    } else if (message["event"] === "RECLASSIFY") {
      if (this.chatBoxRef?.loadPatientEncounter) {
        this.chatBoxRef.loadPatientEncounter();
      }

      const isQaChatTableRef =
        !("current" in (this.qaChatTableRef || {})) && this.qaChatTableRef;

      if (isQaChatTableRef && this.qaChatTableRef) {
        this.qaChatTableRef?.getListTriageLevel?.();
      }
    } else if (message["event"] === "DIAG_FORM_UPDATED") {
      const isQaChatTableRef =
        !("current" in (this.qaChatTableRef || {})) && this.qaChatTableRef;

      if (isQaChatTableRef && this.qaChatTableRef) {
        this.qaChatTableRef?.updateDiagForm?.({
          patientId: parseInt(message["patient_id"]),
        });
      }
    } else if (
      message["event"] === "PATIENT_APPOINTMENT_UPDATE" &&
      message["status"] === 3
    ) {
      this.controller.handleEvent({
        message: "HandleUpdateNotiMessage",
        params: {
          data: {
            id: `PATIENT_APPOINTMENT_UPDATE-${message.patient_appointment_id}`,
            title: `นัดหมายของ HN : ${message.hn}`,
            description: "ถูกเลื่อนเวลานัดหมาย",
            type: "APPOINTMENT"
          },
          update: true,
        },
      });
    }
  };

  handleOnMessageQueue = (message: any) => {
    console.log(" vcMessengerQueue.onMessage", { message });

    if (message["event"] === "NEW_LAB_ORDER") {
      this.serviceQueueRef?.refreshLabOrderQueue?.();
    } else if (message["event"] === "REJECTED_SPECIMEN") {
      this.serviceQueueRef?.refreshLabOrderQueue?.();
    } else if (message["event"] === "update_drug_transfer_request") {
      this.setState({ transferOrderCount: this.transferOrderCount + 1 });
    } else if (message["event"] === "update_supply_transfer_request") {
      this.setState({ transferOrderCount: this.transferOrderCount + 1 });
    } else if (message["event"] === "update_imaging_queue") {
      setTimeout(() => {
        this.serviceQueueRef?.refreshImagingWorkList?.();
      }, 1250);
    }
  };

  handleOnMessageBil = (message: any) => {
    console.log(" vcMessengerBil.onMessage", { message });

    if (
      message["source"] === "BIL" &&
      ["BIL_RECALCULATE", "BIL_RECALCULATE_ENCOUNTER"].includes(message["event"])
    ) {
      // investigate only current on screen patient
      if (message.patient === this.state.selectedEncounter?.patient_id) {
        if (message.status == "IN_PROGRESS" || message.status == "WAITING") {
          this.controller.handleEvent({
            message: "HandleRefreshTaskTracking",
            params: {},
          });
        } else if (message.status === "COMPLETED" || message.status === "FAILED") {
          this.setState({ taskTrackingList: [] });
          this.controller.handleEvent({
            message: "HandleRefreshEncounter" as any,
            params: {},
          });
          this.controller.handleEvent({
            message: "HandleGetEncounterSuspension" as any,
            params: {},
          });
        }
      }
    } else if (
      message["source"] === "BIL" &&
      message["event"] === "BIL_UPDATE_RECEIPT" &&
      message["description"] === "Complete"
    ) {
      this.controller.handleEvent({
        message: "GetCreditNoteItem" as any,
        params: {
          action: "printReceipt",
          data: message,
        },
      });
    } else if (message["event"] === "BIL_REPORT_PRINT") {
      if (message.task_id === this.state.billReportPrint?.task_id) {
        this.setState({
          billReportPrint: {
            ...this.state.billReportPrint,
            status: message.status,
            progress: message.progress,
          },
        });

        if (message.status === "COMPLETED") {
          this.controller.handleEvent({
            message: "HandleReportPrintTrackingResult",
            params: { taskId: message.task_id },
          });
        } else if (message.status === "FAILED") {
          this.setState({
            billReportPrint: { error: "ผิดพลาด" },
          });
        }
      }
    } else if (message["event"] === "drug_order_payment_status_updated") {
      const drugOrder = this.state.selectedDrugOrderWorking || {};

      if (drugOrder.pk === message.order_id) {
        this.controller.handleEvent({
          message: "SelectDrugOrderWorking",
          params: {
            drugOrder: { ...drugOrder, id: drugOrder.pk },
            isNotGetEncounter: true,
          },
        });
      }
    }
  };

  handleOnMessageShp = (message: any) => {
    console.log(" vcMessengerShp.onMessage", { message });

    if (message["source"] === "SHP" && message["event"] === "SHIPPING_UPDATE") {
      const status = deliveryStatusOptions.find(
        (option) => option.value === message.delivery_status
      );

      this.controller.handleEvent({
        message: "HandleUpdateNotiMessage",
        params: {
          data: {
            id: `SHIPPING_UPDATE-${message.shipping_order_id}`,
            description: status?.text,
            image: status?.img,
            divisionId: message.division_id,
            organizationId: message.organization_id,
            type: "SHP"
          },
        },
      });
    }
  };

  handleLoginSuccess = () => {
    this.handleGetEncounter();
  };

  handleGetEncounter = () => {
    const params = new URLSearchParams(window.location.search);
    const encounter_id = params.get("encounter_id");
    const division_id = params.get("division_id");
    const menu = params.get("menu");

    if (!(encounter_id && division_id)) {
      return;
    }

    this.setState({ initialLayout: 1 });

    this.controller.handleEvent({
      message: "GetEncounterInfo",
      params: { encounterId: encounter_id, goToMenu: menu },
    });
    this.controller.handleEvent({
      message: "ChangeDivision",
      params: { divisionId: division_id, isRefresh: true },
    });
  };

  handleEpisodeSelected = async (episode: any) => {
    this.setState({ eocIsLoading: true });
    const [data, error] = await this.proxyController.changeENToEpisode({
      encounterId: this.state.selectedEncounter.id,
      episodeId: episode.id,
    });
    if (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error(JSON.stringify(error));
      }
      this.setState({ eocIsLoading: false });
      return;
    }
    const enEpData = await this.getEncounterOfEpisode(episode.id);
    this.setState({ eocIsLoading: false });
    this.setState({
      eocEncounterList: enEpData?.enList || [],
      episodeInfo: episode,
      eocEpisodeSelected: { id: episode.id, date_time: episode.created_at },
      eocEnEpData: enEpData,
    });

    if (episode.episode_type === "ANC") {
      setTimeout(() => {
        // waiting for state value
        this.openANCRecord();
      }, 0);
    }
  };

  openANCRecord = () => {
    this.setState({ openNote: CONSTANT.LOADING_ANIMATION.OPENING }, () => {
      setTimeout(() => {
        this.setState({ openNote: CONSTANT.LOADING_ANIMATION.OPENED });
        // if (this.state.openConsult) {
        //   this.CardAppointmentRequestConsult.refresh();
        // }
      }, 0);
    });
  };

  getEncounterOfEpisode = async (episodeID: number) => {
    const [enListData, enError] =
      await this.proxyController.getENListFromEpisode(episodeID);
    if (enError) {
      console.log("Error: Get encounter of episode!, ", enError);
      return null;
    }
    // Prepare data
    var enList = [];
    var epType = "";
    var epName = "";
    for (var item of enListData.items) {
      var diagnosis = "";
      for (var d of item.diagnoses) {
        if (d.type_name === "PRIMARY") {
          diagnosis += diagnosis === "" ? d.icd_term : ", " + d.icd_term;
        }
      }
      var orderList = [];
      for (var o of item.doctor_orders) {
        var a: any = {
          id: o.id,
          text: (DOCTOR_ORDER_ACRONYM as { [key: string]: any })[
            o.specific_type
          ],
          color: (DOCTOR_ORDER_BG_COLOR as { [key: string]: any })[
            o.order_status
          ],
        };
        orderList.push(a);
      }
      // Data
      var en = {
        id: item.id,
        date_time: item.created,
        encounter: item.number,
        doctor: item.doctor_name,
        diagnosis: diagnosis,
        order: orderList,
      };
      enList.push(en);
      epType = item.episode_type_label;
      epName = item.episode_name;
    }

    const enEpData = { enList: enList, epType: epType, epName: epName };
    return enEpData;
  };

  updateUnreadMessageCount = async (type: string, message: any) => {
    if (type === "detail") {
      this.controller.setProp(
        "chatDetail.unread_message_count",
        (this.state.chatDetail.unread_message_count || 0) + 1
      );
    } else if (type === "encounter") {
      const update = (this.state.encounterList || []).map((item: any) => ({
        ...item,
        unread_message_count:
          item.division === message.division_id &&
            item.chat_channel === message.channel_id
            ? message.user_name === this.controller.data?.userProfile?.username
              ? 0
              : (item.unread_message_count || 0) + 1
            : item.unread_message_count,
      }));

      this.setState({ encounterList: [...update] });
    } else if (type === "staff") {
      if (this.chatBoxRef) {
        if (
          this.chatBoxRef?.chatChannelId &&
          this.chatBoxRef?.chatChannelId === message.channel_id
        ) {
          return;
        }
      }

      if (message.user_name !== this.controller.data?.userProfile?.username) {
        const isStaff = this.state.allChatRoomList?.find(
          (id: number) => id === message.channel_id
        );

        if (isStaff) {
          this.setState({ showIconNoti: true });

          this.staffChatRef?.getChatChannelList?.({
            channelId: message.channel_id,
          });
        }
      }
    }
  };

  handleCancelENFromEpisode = async (row: any) => {
    // Change episode
    this.setState({
      eocIsLoading: true,
      episodeInfo: {},
      openNote: CONSTANT.LOADING_ANIMATION.CLOSED,
    });
    const [data, error] = await this.proxyController.cancelENFromEpisode(
      this.state.selectedEncounter.id
    );
    if (error) {
      toast.error(error);
      this.setState({ eocIsLoading: false });
      return;
    }
    this.setState({
      eocEncounterList: [],
      episodeInfo: {},
      eocEpisodeSelected: { id: -1, date_time: "" },
      eocIsLoading: false,
    });
  };

  handleGetDoctorSpecialty = async () => {
    const [data, error] = await this.proxyController.getSpeciality();
    if (error) {
      toast.error(error);
    }
    let specialtiesList = formatDropdown(data, "name", "id");
    let list = specialtiesList.map((item: any) => {
      delete item.is_active;
      delete item.display_seq;
      return item;
    });
    this.setState({ searchDoctorSpecialties: list });
  };

  handleSearchDoctor = async (name: any, div: any, doctorCurrentPage: any) => {
    if (!doctorCurrentPage) {
      doctorCurrentPage = this.state.searchDoctorCurrentPage;
    }
    let limit = 40;
    let params = {
      nameCode: name,
      specialty: div,
      offset: (doctorCurrentPage - 1) * limit,
      limit: limit,
    };
    const [doctorListData, doctorListError, numberOfPage] =
      await this.proxyController.getDoctorList(params);
    if (doctorListError) {
      toast.error(doctorListError);
    }
    if (numberOfPage) {
      this.setState({ searchDoctorNumberOfPage: numberOfPage });
    }
    // TODO: handle error doctorListError
    this.setState({ searchDoctorList: doctorListData });
  };

  removeCardScannedDocument = () => {
    const items = [...(this.state.items || [])];
    if (items.length > 0) {
      var pos = 0;
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.key === "Common/CardScannedDocument") {
          pos = i;
          break;
        }
      }
      items.splice(pos, 1);
      this.setState({
        items: items,
        openCardScannedDocument: CONSTANT.LOADING_ANIMATION.CLOSED,
      });
    }
  };

  togglePrintList = () => {
    if (this.state.openPrintList === CONSTANT.LOADING_ANIMATION.OPENED) {
      this.closeModalPrintList();
    } else {
      this.openModalPrintList();
    }
  };

  openModalPrintList = () => {
    this.setState({ openPrintList: CONSTANT.LOADING_ANIMATION.OPENED }, () => {
      this.morePopupClose();
    });
  };

  morePopupClose = () => this.setState({ openMorePopup: false });

  closeModalPrintList = () => {
    this.setState({ openPrintList: CONSTANT.LOADING_ANIMATION.CLOSED });
  };

  handleClickBell = () => {
    if (this.state.showIconNoti) {
      this.setState({ mode: "user", goToStaffChat: true });
    }
  };

  handleReadAllMessages = () => {
    if (this.state.showIconNoti && !CONFIG.RAKSTHAI_DISABLED_USER_MODE) {
      this.setState({ showIconNoti: false });
    }
  };

  runSequence = (params: any) =>
    this.controller.handleEvent({ message: "RunSequence", params: params });

  // Port bplus 55888
  handleCreateAppointment = ({ contentPayload, appointmentId }: any = {}) => {
    let id = contentPayload
      ? contentPayload.patient_appointment
      : appointmentId;

    this.setState({ patientAppointmentId: id, openModChooseAppointment: true });
    // setPatientAppointmentId(id);
    // setOpenModChooseAppointment(true);
  };

  handleNavigationMessage = ({ content = "", contentPayload = null } = {}) => {
    if (content.includes("{ตารางออกตรวจผู้ให้คำปรึกษา}")) {
      let contentArr = content.split("{ตารางออกตรวจผู้ให้คำปรึกษา}");
      return (
        <div>
          {contentArr.map((item, index) => {
            if (index !== contentArr.length - 1) {
              return (
                <>
                  {item}
                  <span
                    className="textButton"
                    onClick={() =>
                      this.handleCreateAppointment({ contentPayload })
                    }
                  >
                    ตารางออกตรวจผู้ให้คำปรึกษา
                  </span>
                </>
              );
            }
          })}
        </div>
      );
    } else {
      return content;
    }
  };

  handleStaffChatDidMount = () => {
    this.setState({ goToStaffChat: false });
  };

  render() {
    // console.log("this.state", this.state);
    // console.log(" this.state.selectedDivision?.code" , this.state.selectedDivision?.code)
    // console.log("!!! CONFIG.SCREEN_ITEM !!! ", CONFIG.SCREEN_ITEM);

    return (
      <IntlProvider
        locale={window?.navigator?.language?.includes("th") ? "th" : "en"}
        messages={
          window?.navigator?.language?.includes("th") ? thMessage : enMessage
        }
      >
        <div
          style={{ overflowY: "hidden", height: "100%" }}
          className="main-nurse"
        >
          {/* <button onClick={()=> { this.methodDoesNotExist.sds()}}>Test Sentry</button> */}

          {this.controller.apiToken === "" ? (
            <div style={{ height: "100%", background: "#1ABDD4" }}>
              <LandingUX
                changeUsername={(e: any) =>
                  this.controller.setProp(
                    "AppInitializeSequence.username",
                    e.target.value
                  )
                }
                changePassword={(e: any) =>
                  this.controller.setProp(
                    "AppInitializeSequence.password",
                    e.target.value
                  )
                }
                username={this.state.AppInitializeSequence?.username || ""}
                password={this.state.AppInitializeSequence?.password || ""}
                loginSubmit={() =>
                  this.runSequence({
                    sequence: "AppInitialize",
                    action: "LoginSubmit",
                    onSuccess: this.handleLoginSuccess,
                  })
                }
                logo={IMAGES.logo}
                eye={
                  this.state.openPassword ? (
                    <Icon
                      name="eye"
                      link
                      onClick={() => {
                        this.controller.setProp(
                          "openPassword",
                          !this.state.openPassword
                        );
                      }}
                    />
                  ) : (
                    <Icon
                      name="eye slash"
                      link
                      onClick={() => {
                        this.controller.setProp(
                          "openPassword",
                          !this.state.openPassword
                        );
                      }}
                    />
                  )
                }
                typePassword={this.state.openPassword ? "" : "password"}
                loading={this.state.loadingLogin}
              />
            </div>
          ) : !this.state.loggedin ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "3rem",
              }}
            >
              <Grid reversed="tablet vertically">
                <Grid.Row>
                  <Grid.Column>
                    {" "}
                    <Image src={IMAGES.IHlogo} centered />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header textAlign="center">
                      <Header.Content>
                        ...Please wait while loading info...
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Column></Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <Header textAlign="center">
                      <Header.Content>
                        <Progress
                          // textAlign="center"
                          style={{ width: "750px" }}
                          percent={100}
                          color="teal"
                          size="small"
                          active
                        />
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          ) : (
            <div className="MainScreen" style={{ height: "100vh" }}>
              <TopBar // 40px
                django={this.state.django}
                onEvent={this.controller.handleEvent}
                onClickBell={this.handleClickBell}
                setProp={this.controller.setProp}
                divisionOptions={this.state.divisionOptions}
                selectedDivision={this.state.selectedDivision}
                deviceOptions={this.state.deviceOptions}
                selectedDevice={this.state.selectedDevice}
                showIconNoti={this.state.showIconNoti}
                showTabName={this.state.showTabName}
                mode={this.state.mode}
              />
              <div style={{ height: "calc( 100% - 40px)" }}>
                <MainVideoCall.Main
                  onEvent={this.controller.handleEvent}
                  setProp={this.controller.setProp}
                  openVideoCallModal={this.state.openVideoCallModal}
                  videoCallRoom={this.state.videoCallRoom}
                  closeOverriding={this.state.closeOverriding}
                  isPatient={false}
                  username={
                    this.state.providerEmployeeInfo?.employee_info?.full_name
                  }
                  userProfile={this.controller.data?.userProfile}
                  chatController={this.chatController}
                  currChatChannelId={this.state.currChatChannelId}
                  division_id={
                    this.controller.data.division
                      ? this.controller.data.division
                      : this.state.selectedDivision?.id
                  }
                  patient_id={
                    this.state.selectedEncounter
                      ? this.state.selectedEncounter?.patient_id
                      : this.state.currPatientId
                  }
                  encounter_id={this.state.selectedEncounter?.id}
                  apiToken={this.controller.apiToken}
                  onClose={() => {
                    this.setState({ openVideoCallModal: false });
                  }}
                />

                {this.state.mode === "admin" ? (
                  <TabPenta
                    selectedTabIndex={0}
                    key={"admin"}
                    tabWidth="150px"
                    style={{ overflow: "auto" }}
                    data={[
                      {
                        key: "ตั้งค่าแพ็กเกจ",
                        name: "ตั้งค่าแพ็กเกจ",
                        render: () => (
                          <CardSettingPackage
                            setProp={this.controller.setProp}
                            onEvent={this.controller.handleEvent}
                            // seq
                            runSequence={this.runSequence}
                            SettingPackageSequence={
                              this.state.SettingPackageSequence
                            }
                            // CommonInterface
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            errorMessage={this.state.errorMessage}
                            searchedItemListWithKey={
                              this.state.searchedItemListWithKey
                            }
                            // options
                            masterOptions={this.state.masterOptions}
                          />
                        ),
                      },
                      {
                        key: "สร้างแผนกเพิ่ม",
                        name: "สร้างแผนกเพิ่ม",
                        render: () => (
                          <CreateOA
                            controller={this.classifyController}
                            onEvent={this.controller.handleEvent}
                            apiToken={this.controller.apiToken}
                            onGetDivision={(divisionId: any) => { }} // Already handled in AppInitialize:GetUserInfo
                            myDivisions={this.state.divisionOptions}
                            onSetShowMenuDivision={(show: any) => { }} // This props is not used in code any more
                            onSetShowMenuNoti={(show: any) =>
                              this.setState({ showMenuNoti: show })
                            }
                            userFullName={
                              this.state.providerEmployeeInfo?.employee_info
                                ?.full_name
                            }
                            userRoleList={this.state.userRoleList}
                            onCreated={(divisionId: number) =>
                              this.controller.handleEvent({
                                message: "RefreshDivision",
                                params: { divisionId: divisionId },
                              })
                            }
                          />
                        ),
                      },
                      {
                        key: "ตั้งค่าเอกสารยินยอม",
                        name: "ตั้งค่าเอกสารยินยอม",
                        render: () => {
                          return (
                            <CardSettingMediaDocument
                              onEvent={this.controller.handleEvent}
                              setProp={this.controller.setProp}
                              // seq
                              runSequence={this.runSequence}
                              SettingMediaDocumentSequence={this.state.SettingMediaDocumentSequence}
                              // CommonInterface
                              buttonLoadCheck={this.state.buttonLoadCheck}
                              errorMessage={this.state.errorMessage}
                              // options
                              masterOptions={this.state.masterOptions}
                            />
                          );
                        },
                      },
                      {
                        key: "ตั้งค่าชุดทางเลือกการรักษา",
                        name: "ตั้งค่าชุดทางเลือกการรักษา",
                        render: () => {
                          return (
                            <CardSettingTRTOptions
                              onEvent={this.controller.handleEvent}
                              setProp={this.controller.setProp}
                              // seq
                              runSequence={this.runSequence}
                              SettingTRTOptionsSequence={
                                this.state.SettingTRTOptionsSequence
                              }
                              // CommonInterface
                              buttonLoadCheck={this.state.buttonLoadCheck}
                              errorMessage={this.state.errorMessage}
                              // options
                              masterOptions={this.state.masterOptions}
                            />
                          );
                        },
                      },
                      {
                        key: "ตั้งค่าแผนก",
                        name: "ตั้งค่าแผนก",
                        render: () => {
                          return (
                            <SettingOAAccount
                              divisionProfile={{}}
                              onEditProfile={() => { }}
                              division={this.controller.data.division || 0}
                            // divisionProfile={divisionProfile}
                            // onEditProfile={handleEditProfile}
                            // division={props.division}
                            />
                          );
                        },
                      },
                      {
                        key: "จัดการสิทธิ",
                        name: "จัดการสิทธิ",
                        render: () => (
                          <ManagePermissions
                            pendingList={[]}
                            onApproveJoinOARequest={() => { }}
                            getInvitationUrl={() => { }}
                            memberList={[]}
                            roleOptions={[]}
                            onChangeRole={() => { }}
                            selectedRow={null}
                            onDeleteDivisionHasUserOA={() => { }}
                            onSaveRoleChange={() => { }}
                            onDeleteJoinOARequest={() => { }}
                            readOnly={false}
                            division={this.controller.data.division || 0}

                          // pendingList={pendingList}
                          // onApproveJoinOARequest={handleApproveJoinOARequest}
                          // getInvitationUrl={getInvitationUrl}
                          // memberList={memberList}
                          // roleOptions={roleOptions}
                          // onChangeRole={handleChangeRole}
                          // selectedRow={selectedRow}
                          // onDeleteDivisionHasUserOA={handleDeleteDivisionHasUserOA}
                          // onSaveRoleChange={handleSaveRoleChange}
                          // onDeleteJoinOARequest={handleDeleteJoinOARequest}
                          // readOnly={false}
                          // division={props.division}
                          />
                        ),
                      },
                      {
                        key: "ตั้งค่าแจ้งเตือนเรียกคิว",
                        name: "ตั้งค่าแจ้งเตือนเรียกคิว",
                        render: () => (
                          <CardSettingQueue
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            SettingQueueSequence={
                              this.state.SettingQueueSequence
                            }
                            masterOptions={this.state.masterOptions}
                          />
                        ),
                      },
                      {
                        key: "ตั้งค่าแจ้งเตือนนัดหมาย",
                        name: "ตั้งค่าแจ้งเตือนนัดหมาย",
                        render: () => (
                          <CardSettingAppointmentReminder
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            SettingQueueSequence={
                              this.state.SettingQueueSequence
                            }
                            masterOptions={this.state.masterOptions}
                          />
                        ),
                      },
                      {
                        key: "ลงตารางแพทย์",
                        name: "ลงตารางแพทย์",
                        render: () => (
                          <CardDentistSchedule
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            chairList={this.state.chairList}
                            chairExistDSBList={this.state.chairExistDSBList}
                            selectedDivision={this.state.selectedDivision}
                            blockList={this.state.blockList}
                            holiday={this.state.holiday}
                            providerBlockList={this.state.providerBlockList}
                            searchedItemList={this.state.searchedItemList}
                            scheduleTemplates={this.state.scheduleTemplates}
                            openModConfirmAddBlock={
                              this.state.openModConfirmAddBlock
                            }
                            masterOptions={this.state.masterOptions}
                            userTokenize={this.state.userTokenize}
                          />
                        ),
                      },

                      {
                        key: "บริหารเก้าอี้",
                        name: "บริหารเก้าอี้",
                        render: () => (
                          <CardChair
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            chairList={this.state.chairList}
                            blockList={this.state.blockList}
                            providerBlockList={this.state.providerBlockList}
                            scheduleTemplates={this.state.scheduleTemplates}
                            selectedDivision={this.state.selectedDivision}
                            divisionDict={this.controller.data.divisionDict} // TODO: Check port
                            holiday={this.state.holiday}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                          />
                        ),
                      },

                      {
                        key: "ลงตารางเภสัช",
                        name: "ลงตารางเภสัช",
                        render: () => (
                          <CardDentistSchedule
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            chairList={this.state.chairList}
                            chairExistDSBList={this.state.chairExistDSBList}
                            selectedDivision={this.state.selectedDivision}
                            holiday={this.state.holiday}
                            blockList={this.state.blockList}
                            providerBlockList={this.state.providerBlockList}
                            searchedItemList={this.state.searchedItemList}
                            openModConfirmAddBlock={
                              this.state.openModConfirmAddBlock
                            }
                            scheduleTemplates={this.state.scheduleTemplates}
                            telephar={true}
                            masterOptions={this.state.masterOptions}
                            userTokenize={this.state.userTokenize}
                          />
                        ),
                      },
                      {
                        key: "สร้างตารางคลินิก",
                        name: "สร้างตารางคลินิก",
                        render: () => (
                          <CardDivisionSchedule
                            onEvent={this.controller.handleEvent}
                            apiToken={this.controller.apiToken}
                            selectedDivision={this.state.selectedDivision}
                            scheduleTemplates={this.state.scheduleTemplates}
                          />
                        ),
                      },
                      {
                        key: "ตั้งค่าวันหยุด",
                        name: "ตั้งค่าวันหยุด",
                        render: () => (
                          <CardHoliday
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            divisionList={this.state.divisionList}
                            selectedDivision={this.state.selectedDivision}
                            holiday={this.state.holiday}
                          />
                        ),
                      },
                      {
                        key: "ข้อมูลสถิติ",
                        name: "ข้อมูลสถิติ",
                        render: () => (
                          <CardStatistics
                            controller={this.controller}
                            proxyController={this.proxyController}
                            setProp={this.controller.setProp}
                            onEvent={this.controller.handleEvent}
                            divisionList={this.state.divisionList}
                            selectedDivision={this.state.selectedDivision}
                            searchedItemListWithKey={this.state.searchedItemListWithKey}
                            searchedItemList={this.state.searchedItemList}
                            summaryDoctorList={this.state.summaryDoctorList} // TODO: Check
                            summaryStatisticsList={this.state.summaryStatisticsList} // TODO: Check
                            summaryWaitingQueueList={this.state.summaryWaitingQueueList} // TODO: Check
                          />
                        ),
                      },
                      {
                        key: "ข้อมูลบุคลากร",
                        name: "ข้อมูลบุคลากร",
                        render: () => (
                          <CardStaffSetting
                            setProp={this.controller.setProp}
                            onEvent={this.controller.handleEvent}
                            runSequence={this.runSequence}
                            // controller
                            controller={this.controller}
                            proxyController={this.proxyController}
                            // data
                            staffList={this.state.staffList}
                            profileUser={this.state.profileUser}
                            changePassword={this.state.changePassword}
                            user={this.state.user}
                            profileEmployee={this.state.profileEmployee}
                            profileDivision={this.state.profileDivision}
                            masterData={this.controller.data.masterData}
                            // options
                            masterOptions={this.state.masterOptions}
                            roleOptions={this.state.roleOptions}
                            divisionOptions={this.state.divisionOptions}
                            // CommonInterface
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            errorMessage={this.state.errorMessage}
                            successMessage={this.state.successMessage}
                            // style
                            userEmployeeStyle={{
                              height: "calc(100dvh - 7.5rem)",
                              overflow: "auto",
                            }}
                            // config
                            showPassword2={true}
                          />
                        ),
                      },
                      {
                        key: "Studio",
                        name: "Studio",
                        render: () => (
                          <Studio
                            apiToken={this.controller.apiToken}
                            ruleListController={this.ruleListController}
                            diagComposerController={this.diagComposerController}
                            onSetState={(state: any) =>
                              this.setState({ ...state })
                            }
                            // Didn't really use onGetDivision in the original code
                            // onGetDivision={props.getDivision}
                            division={this.state.selectedDivision?.id || 0}
                            // isVerified={props.isVerified}
                            isVerified={true} // for demo
                          />
                        ),
                      },
                      {
                        key: "VaccineUpload",
                        name: "VaccineUpload",
                        render: () => (
                          <VaccineUpload
                            onEvent={this.controller.handleEvent}
                            division={this.controller.data.division}
                            vaccineInfo={this.state.VaccineUpload?.vaccineInfo}
                            appointmentList={
                              this.state.VaccineUpload?.appointmentList
                            }
                            generateUserModal={
                              this.state.VaccineUpload?.generateUserModal
                            }
                            generateUserResult={
                              this.state.VaccineUpload?.generateUserResult
                            }
                          />
                        ),
                      },
                      {
                        key: "Upload ข้อมูลผู้ใช้สิทธิ",
                        name: "Upload ข้อมูลผู้ใช้สิทธิ",
                        render: () => (
                          <CardUploadPatientCoverage
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            // CommonInterface
                            errorMessage={this.state.errorMessage}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            // data
                            UploadPatientCoverageSequence={
                              this.state.UploadPatientCoverageSequence
                            }
                          />
                        ),
                      },
                      {
                        key: "ตั้งค่ากฏการเบิก",
                        name: "ตั้งค่ากฏการเบิก",
                        render: () => (
                          <CardWithDrawalRules
                          />
                        ),
                      },
                      // {
                      //   key: "Claim ยา",
                      //   name: "Claim ยา",
                      //   render: () => (
                      //     <div
                      //       style={{
                      //         padding: "10px",
                      //         display: "flex",
                      //         flexDirection: "column",
                      //       }}
                      //     >
                      //       <a
                      //         href={`${HOST}/manage/CLM/drugclaimpattern/`}
                      //         target="_blank"
                      //       >
                      //         D.1 กำหนดรูปแบบการเคลมยา
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/coveragedrugclaimpattern/`}
                      //         target="_blank"
                      //       >
                      //         D.2 ผูกสิทธิกับรูปแบบการเคลมยา
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/question/`}
                      //         target="_blank"
                      //       >
                      //         D.3 กำหนดคำถาม
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/core/doctorgroup/`}
                      //         target="_blank"
                      //       >
                      //         D.4 กำหนดกลุ่มแพทย์
                      //       </a>
                      //       <a
                      //         href={`${HOST}/CLM/ModConditionList.qml?for_claim_type=2`}
                      //         target="_blank"
                      //       >
                      //         D.5 กำหนดเงื่อนไขการเบิกยา
                      //       </a>
                      //       <a
                      //         href={`${HOST}/CLM/CardDrugClaimPatternItemList.qml`}
                      //         target="_blank"
                      //       >
                      //         D.6 กำหนดรายละเอียดการเบิกยา
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/coveragedrugclaimpatternforclaimdrug/`}
                      //         target="_blank"
                      //       >
                      //         D.7 ผูกสิทธิกับรูปแบบการเคลมยา สำหรับเพดานเบิกยา
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/claimdrug/`}
                      //         target="_blank"
                      //       >
                      //         D.8 กำหนดเพดานเบิกยา
                      //       </a>
                      //     </div>
                      //   ),
                      // },
                      // {
                      //   key: "Claim เวชภัณฑ์",
                      //   name: "Claim เวชภัณฑ์",
                      //   render: () => (
                      //     <div
                      //       style={{
                      //         padding: "10px",
                      //         display: "flex",
                      //         flexDirection: "column",
                      //       }}
                      //     >
                      //       <a
                      //         href={`${HOST}/manage/CLM/supplyclaimpattern/`}
                      //         target="_blank"
                      //       >
                      //         S.1 กำหนดรูปแบบการเคลมเวชภัณฑ์
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/coveragesupplyclaimpattern/`}
                      //         target="_blank"
                      //       >
                      //         S.2 ผูกสิทธิกับรูปแบบการเคลมเวชภัณฑ์
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/question/`}
                      //         target="_blank"
                      //       >
                      //         S.3 กำหนดคำถาม
                      //       </a>
                      //       <a
                      //         href={`${HOST}/CLM/ModConditionList.qml?for_claim_type=1`}
                      //         target="_blank"
                      //       >
                      //         S.4 กำหนดเงื่อนไขรหัสเบิกเวชภัณฑ์
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/claimsupply/`}
                      //         target="_blank"
                      //       >
                      //         S.5 กำหนดรหัสเบิกเวชภัณฑ์
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/MSD/supplyforclaim/`}
                      //         target="_blank"
                      //       >
                      //         S.6 กำหนดหมวดเวชภัณฑ์
                      //       </a>
                      //       <a
                      //         href={`${HOST}/CLM/CardSupplyClaimSupply.qml`}
                      //         target="_blank"
                      //       >
                      //         S.7 ผูกรหัสเบิกกับเวชภัณฑ์
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/maxreimbclaimsupplygroup/`}
                      //         target="_blank"
                      //       >
                      //         S.8
                      //         กำหนดเพดานเบิกแบบหลายรหัสเบิกไม่เกินเพดานที่กำหนด
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/icd9cmbeauty/`}
                      //         target="_blank"
                      //       >
                      //         S.11 กำหนดรายการ Icd9cm เพื่อความสวยงาม
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/overridesupplymode/`}
                      //         target="_blank"
                      //       >
                      //         S.13 เปลี่ยนหมวดเวชภัณฑ์เมื่อสั่งด้วยสิทธิที่ระบุ
                      //       </a>
                      //     </div>
                      //   ),
                      // },
                      // {
                      //   key: "Claim บริการ",
                      //   name: "Claim บริการ",
                      //   render: () => (
                      //     <div
                      //       style={{
                      //         padding: "10px",
                      //         display: "flex",
                      //         flexDirection: "column",
                      //       }}
                      //     >
                      //       <a
                      //         href={`${HOST}/manage/CLM/otherclaimpattern/`}
                      //         target="_blank"
                      //       >
                      //         O.1 กำหนดรูปแบบการเคลมบริการ
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/coverageotherclaimpattern/`}
                      //         target="_blank"
                      //       >
                      //         O.2 ผูกสิทธิกับรูปแบบการเคลมบริการ
                      //       </a>
                      //       <a
                      //         href={`${HOST}/CLM/ModConditionList.qml?for_claim_type=3`}
                      //         target="_blank"
                      //       >
                      //         O.3 กำหนดเงื่อนไขรหัสเบิกบริการ
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/claimother/`}
                      //         target="_blank"
                      //       >
                      //         O.4 กำหนดรหัสเบิกบริการ
                      //       </a>
                      //       <a
                      //         href={`${HOST}/CLM/CardOtherClaimOther.qml`}
                      //         target="_blank"
                      //       >
                      //         O.5 ผูกรหัสเบิกกับบริการ
                      //       </a>
                      //     </div>
                      //   ),
                      // },
                      // {
                      //   key: "Claim common",
                      //   name: "Claim common",
                      //   render: () => (
                      //     <div
                      //       style={{
                      //         padding: "10px",
                      //         display: "flex",
                      //         flexDirection: "column",
                      //       }}
                      //     >
                      //       <a
                      //         href={`${HOST}/manage/CLM/selfreimbproduct/`}
                      //         target="_blank"
                      //       >
                      //         C.1 กำหนดรายการสินค้าที่นำไปตั้งเบิกเอง
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/notreimbproduct/`}
                      //         target="_blank"
                      //       >
                      //         C.2 กำหนดรายการสินค้าห้ามเบิก
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/notreimbicd10/`}
                      //         target="_blank"
                      //       >
                      //         C.3 กำหนดรายการ ICD10 ห้ามเบิก
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/coveragesetting/`}
                      //         target="_blank"
                      //       >
                      //         C.4 ตั้งค่าสิทธิ
                      //       </a>
                      //       <a
                      //         href={`${HOST}/manage/CLM/coverageabsorb/`}
                      //         target="_blank"
                      //       >
                      //         C.5 ตั้งค่า Absorb (โรงพยาบาลรองรับค่าใช้จ่าย)
                      //       </a>
                      //     </div>
                      //   ),
                      // },
                      {
                        key: "รับชำระเงินต้นสังกัด",
                        name: "รับชำระเงินต้นสังกัด",
                        render: () => (
                          <CardReceiptAr
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            BillPaymentArSequence={
                              this.state.BillPaymentArSequence
                            }
                            masterOptions={this.state.masterOptions}
                            selectedDevice={this.state.selectedDevice}
                            errorMessage={this.state.errorMessage}
                            successMessage={this.state.successMessage}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            searchedItemList={this.state.searchedItemList}
                            // BIL
                            bilReceiptCodeDetail={this.state.bilReceiptCodeDetail}
                            // Medical Fee
                            BillPaymentSequence={this.state.BillPaymentSequence}
                            billingHistorySequence={this.state.BillingHistorySequence}
                          />
                        ),
                      },
                      {
                        key: "รายงานทางการเงิน",
                        name: "รายงานทางการเงิน",
                        render: () => (
                          <CardFinancialReport
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            FinancialReportSequence={
                              this.state.FinancialReportSequence
                            }
                            // options
                            masterOptions={this.state.masterOptions}
                            // CommonInterface
                            errorMessage={this.state.errorMessage}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                          />
                        ),
                      },
                      {
                        key: "รายงานประจำคลินิก",
                        name: "รายงานประจำคลินิก",
                        render: () => (
                          <CardClinicAnnualReport
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            masterOptions={this.state.masterOptions}
                            ClinicAnnualReportSequence={this.state.ClinicAnnualReportSequence}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                          />
                        )
                      },
                      {
                        key: "ออกใบแจ้งหนี้ / ตั้งงวด",
                        name: "ออกใบแจ้งหนี้ / ตั้งงวด",
                        render: () => (
                          <CardCreateInvoiceGroup
                            // function
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            drugOrderQueueController={this.drugOrderQueueController}
                            // seq
                            runSequence={this.runSequence}
                            ARInvoiceGroupSequence={
                              this.state.ARInvoiceGroupSequence
                            }
                            // data
                            billtransactionItem={this.state.billtransactionItem}
                            commonBillTransaction={
                              this.state.commonBillTransaction
                            }
                            // options
                            coveragePayerOptions={
                              this.state.coveragePayerOptions
                            }
                            masterOptions={this.state.masterOptions}
                            // CommonInterface
                            errorMessage={this.state.errorMessage}
                            successMessage={this.state.successMessage}
                            searchedItemListWithKey={
                              this.state.searchedItemListWithKey
                            }
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            // Medical Fee
                            BillPaymentSequence={this.state.BillPaymentSequence}
                            invoiceItemByModes={this.state.invoiceItemByModes}
                            invoiceItemByOrders={this.state.invoiceItemByOrders}
                            invoiceItemByItems={this.state.invoiceItemByItems}
                            // Bill Report Print
                            billReportPrint={this.state.billReportPrint}
                          />
                        ),
                      },
                      {
                        key: "เตรียมข้อมูล E-Claim",
                        name: "เตรียมข้อมูล E-Claim",
                        render: () => (
                          <CardExportEClaimFile
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            ExportEClaimFileSequence={
                              this.state.ExportEClaimFileSequence
                            }
                            SendClaimDownloadZipFileSequence={this.state.SendClaimDownloadZipFileSequence}
                            // controller
                            drugOrderQueueController={
                              this.drugOrderQueueController
                            }
                            // options
                            masterOptions={this.state.masterOptions}
                            // data
                            masterData={this.controller.data.masterData}
                            userTokenize={this.state.userTokenize}
                            // CommonInterface
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            errorMessage={this.state.errorMessage}
                          />
                        ),
                      },
                      {
                        key: "AIPN Send Claim",
                        name: "AIPN Send Claim",
                        render: () => (
                          <CardAIPNSendClaim
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // controller
                            drugOrderQueueController={this.drugOrderQueueController}
                            cardSentClaimInformationController={
                              this.cardSentClaimInformationController
                            }
                            // seq
                            runSequence={this.runSequence}
                            AIPNSendClaimSequence={this.state.AIPNSendClaimSequence}
                            SendClaimDownloadZipFileSequence={this.state.SendClaimDownloadZipFileSequence}
                            // CommonInterface
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            errorMessage={this.state.errorMessage}
                            searchedItemListWithKey={this.state.searchedItemListWithKey}
                            // options
                            masterOptions={this.state.masterOptions}
                          />
                        ),
                      },
                      {
                        key: "บันทึกไฟล์ตอบกลับ",
                        name: "บันทึกไฟล์ตอบกลับ",
                        render: () => (
                          <CardSendClaimResponseFile
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            SendClaimResponseFileSequence={this.state.SendClaimResponseFileSequence}
                            // options
                            coveragePayerOptions={this.state.coveragePayerOptions}
                            // CommonInterface
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            errorMessage={this.state.errorMessage}
                          />
                        ),
                      },
                      {
                        key: "import ค่า Lab สำหรับคำนวณรายได้แพทย์",
                        name: "import ค่า Lab สำหรับคำนวณรายได้แพทย์",
                        render: () => (
                          <CardImportLabValuesCalcDoctorFee
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            ImportLabValuesCalcDFSequence={this.state.ImportLabValuesCalcDFSequence}
                            // CommonInterface
                            searchedItemListWithKey={this.state.searchedItemListWithKey}
                            errorMessage={this.state.errorMessage}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                          />
                        ),
                      },
                      {
                        key: "พิมพ์เอกสารภาษี",
                        name: "พิมพ์เอกสารภาษี",
                        render: () => (
                          <CardClaimTaxDocument
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            errorMessage={this.state.errorMessage}
                            // seq
                            runSequence={this.runSequence}
                            ClaimTaxDocumentSequence={this.state.ClaimTaxDocumentSequence}
                            // common
                            // options
                            masterOptions={this.state.masterOptions}
                            masterData={this.controller.data.masterData}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                          />
                        )
                      },
                      {
                        key: "สรุปค่าตอบแทนแพทย์",
                        name: "สรุปค่าตอบแทนแพทย์",
                        hide: CONFIG.HIDE_DOCTOR_FEE_ALL_MENU, // 67071 hide
                        render: () => (
                          <CardDoctorFeeSummary
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            DoctorFeeSummarySequence={this.state.DoctorFeeSummarySequence}
                            // CommonInterface
                            searchedItemListWithKey={this.state.searchedItemListWithKey}
                            errorMessage={this.state.errorMessage}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            successMessage={this.state.successMessage}
                            // options
                            masterOptions={this.state.masterOptions}
                            masterData={this.controller.data.masterData}
                          />
                        ),
                      },
                      {
                        key: "ศูนย์ต้นทุนรายใบเสร็จ",
                        name: "ศูนย์ต้นทุนรายใบเสร็จ",
                        render: () => (
                          <CardCostCenterReceipt
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            CostCenterReceiptSequence={this.state.CostCenterReceiptSequence}
                            // common
                            // options
                            masterOptions={this.state.masterOptions}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                          />
                        ),
                      },
                      {
                        key: "รายงานบัญชี",
                        name: "รายงานบัญชี",
                        render: () => (
                          <CardSapAccountingReport
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            SapAccountingReportSequence={this.state.SapAccountingReportSequence}
                            // common
                            // options
                            masterOptions={this.state.masterOptions}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                          />
                        ),
                      },
                      {
                        key: "OPD Claim Resolve",
                        name: "OPD Claim Resolve",
                        render: () => (
                          <div>
                            <CardOPDClaimResolve />
                          </div>
                        ),
                      },
                      {
                        key: "OPD AR Transaction",
                        name: "OPD AR Transaction",
                        render: () => (
                          <div>
                            <CardCreateARTransactionContainer
                              apiToken={this.controller.apiToken}
                              tableStyle={{
                                width: "calc(100vw - 180px)",
                                height: "calc(100vh - 29rem)",
                              }}
                            />
                          </div>
                        ),
                      },
                      {
                        key: "OPD รายการที่สร้างเลขงวดแล้ว",
                        name: "OPD รายการที่สร้างเลขงวดแล้ว",
                        render: () => (
                          <div>
                            <CardOPDDownloadSentClaimFile
                              onEvent={this.controller.handleEvent}
                              setProp={this.controller.setProp}
                              // controller
                              cardSentClaimInformationController={
                                this.cardSentClaimInformationController
                              }
                              // seq
                              runSequence={this.runSequence}
                              DownloadSentClaimFileSequence={
                                this.state.DownloadSentClaimFileSequence
                              }
                              // data
                              apiToken={this.controller.apiToken}
                              // CommonInterface
                              buttonLoadCheck={this.state.buttonLoadCheck}
                              errorMessage={this.state.errorMessage}
                              // options
                              masterOptions={this.state.masterOptions}
                            />
                          </div>
                        ),
                      },
                      {
                        key: "OPD Send Claim",
                        name: "OPD Send Claim",
                        render: () => (
                          <div>
                            <CardUploadSentClaimResponseFile />
                          </div>
                        ),
                      },
                      {
                        key: "IPD AR Transaction",
                        name: "IPD AR Transaction",
                        render: () => (
                          <div>
                            <CardCreateARTransaction
                              cardTitle={"สร้างเลขตั้งเบิกให้กับผู้ป่วยใน"}
                            // controller={this.createARTransactionController}
                            />
                          </div>
                        ),
                      },
                      {
                        key: "IPD Send Claim Info",
                        name: "IPD Send Claim Info",
                        render: () => (
                          <div>
                            <CardSentClaimInformation
                              // controller={this.sentClaimInfoController}
                              onEvent={this.controller.handleEvent}
                            />
                          </div>
                        ),
                      },
                      {
                        key: "IPD Send Claim Result",
                        name: "IPD Send Claim Result",
                        render: () => (
                          <div>
                            <CardUploadSentClaimResult
                            // controller={this.uploadSentClaimResultController}
                            />
                          </div>
                        ),
                      },
                      {
                        key: "จัดการตารางห้องผ่าตัด",
                        name: "จัดการตารางห้องผ่าตัด",
                        render: () => (
                          <div>
                            <CardManageOperatingRoom
                              // callback
                              onEvent={this.controller.handleEvent}
                              setProp={this.controller.setProp}
                              runSequence={this.runSequence}
                              // data
                              ManageORSequence={this.state.ManageORSequence}
                              errorMessage={this.state.errorMessage}
                            />
                          </div>
                        ),
                      },
                      {
                        key: "Anesthesia Template",
                        name: "Anesthesia Template",
                        render: () => (
                          <div>
                            <CardSetupAnesthesiaTemplate
                              // function
                              onEvent={this.controller.handleEvent}
                              setProp={this.controller.setProp}
                              // CommonInterface
                              errorMessage={this.state.errorMessage}
                              successMessage={this.state.successMessage}
                              buttonLoadCheck={this.state.buttonLoadCheck}
                              masterOptions={this.state.masterOptions}
                              // seq
                              runSequence={this.runSequence}
                              AnesTemplateSequence={
                                this.state.AnesTemplateSequence
                              }
                            />
                          </div>
                        ),
                      },
                      {
                        key: "Segment tool",
                        name: "Segment tool",
                        render: () => {
                          return (
                            <TabPenta
                              selectedTabIndex={0}
                              key={"segment"}
                              tabWidth="125px"
                              data={[
                                {
                                  key: "Underlying disease",
                                  name: "Underlying disease",
                                  render: () => (
                                    <CardUnderlyingDisease
                                      onEvent={this.controller.handleEvent}
                                      setProp={this.controller.setProp}
                                      // controller
                                      drugOrderQueueController={
                                        this.drugOrderQueueController
                                      }
                                      // seq
                                      runSequence={this.runSequence}
                                      UnderlyingDiseaseSequence={
                                        this.state.UnderlyingDiseaseSequence
                                      }
                                      // CommonInterface
                                      errorMessage={this.state.errorMessage}
                                      buttonLoadCheck={
                                        this.state.buttonLoadCheck
                                      }
                                    />
                                  ),
                                },
                                {
                                  key: "Risk Alert",
                                  name: "Risk Alert",
                                  render: () => <></>,
                                },
                                {
                                  key: "Drug-Disease interaction",
                                  name: "Drug-Disease interaction",
                                  render: () => (
                                    <>
                                      <CardDrugDiseaseInteraction
                                        onEvent={this.controller.handleEvent}
                                        setProp={this.controller.setProp}
                                        // seq
                                        runSequence={this.runSequence}
                                        DrugDiseaseInteractionSequence={
                                          this.state
                                            .DrugDiseaseInteractionSequence
                                        }
                                        // CommonInterface
                                        errorMessage={this.state.errorMessage}
                                        buttonLoadCheck={
                                          this.state.buttonLoadCheck
                                        }
                                        searchedItemListWithKey={
                                          this.state.searchedItemListWithKey
                                        }
                                        // options
                                        masterOptions={this.state.masterOptions}
                                      />
                                    </>
                                  ),
                                },
                                {
                                  key: "Health Member",
                                  name: "Health Member",
                                  render: () =>
                                    <CardHealthMember
                                      onEvent={this.controller.handleEvent}
                                      setProp={this.controller.setProp}
                                      // seq
                                      runSequence={this.runSequence}
                                      HealthMemberSequence={this.state.HealthMemberSequence}
                                      // controller
                                      drugOrderQueueController={
                                        this.drugOrderQueueController
                                      }
                                      // CommonInterface
                                      errorMessage={this.state.errorMessage}
                                      buttonLoadCheck={this.state.buttonLoadCheck}
                                      searchedItemListWithKey={this.state.searchedItemListWithKey}
                                    />,
                                },
                              ]}
                              setProp={this.controller.setProp}
                            />
                          );
                        },
                      },
                      // {
                      //   key: "รีวิว",
                      //   name: "รีวิว",
                      //   render: () =>
                      //     <div>
                      //       <ARReview
                      //         onEvent={context.controller.handleEvent}
                      //         setProp={context.controller.setProp}
                      //         setState={context.controller.setState}
                      //         encounterInfo={this.state.encounterInfo}
                      //         claimStatusOpd={this.state.claimStatusOpd}
                      //         claimStatusIpd={this.state.claimStatusIpd}
                      //         claimTaskOPDList={this.state.claimTaskOPDList}
                      //         claimTaskOPDAmountIndex={this.state.claimTaskOPDAmountIndex}
                      //         claimTaskOPDInvoiceIndex={this.state.claimTaskOPDInvoiceIndex}
                      //         claimTaskIPDList={this.state.claimTaskIPDList}
                      //         claimTaskIPDDateIndex={this.state.claimTaskIPDDateIndex}
                      //         claimTaskIPDInvoiceIndex={this.state.claimTaskIPDInvoiceIndex}
                      //     />
                      //     </div>
                      // },
                      // {
                      //   key: "Data Science",
                      //   name: "Data Science",
                      //   render: () =>
                      //     <div>
                      //       <DataScience
                      //       onEvent={context.controller.handleEvent}
                      //       encounterStat={this.state.encounterStat}
                      //       diagnosisStat={this.state.diagnosisStat}
                      //       orderStat={this.state.orderStat}
                      //       labStat={this.state.labStat}
                      //     />
                      //     </div>
                      // },
                      {
                        key: "Debug",
                        name: "Debug",
                        render: () => (
                          <Debug
                            onEvent={this.controller.handleEvent}
                            debugInfo={this.state.debugInfo}
                          />
                        ),
                      },
                    ]
                      .filter((item: any): boolean => {
                        const isVisible = !(
                          CONFIG.V3_HIDDEN_TAB_LIST.includes(item.key) || item.hide
                        );

                        let tabList =
                          this.state.divisionType === "backoffice"
                            ? MainHISI.BACK_OFFICE_ADMIN_TAB_LIST
                            : undefined;

                        return isVisible && (!tabList || tabList.includes(item.key));
                      })}
                    setProp={this.controller.setProp}
                    onChangeSelectedTabName={(name: string) => {
                      this.setState({ showTabName: name });
                    }}
                  />
                ) : this.state.mode === "patient" ? (
                  <TabPenta
                    ref={(ref: any) => (this.tabPatientRef = ref)}
                    key={"patient"}
                    padding="2px"
                    tabWidth="60px"
                    itemStyle={{
                      borderBottom: "1px solid rgba(217,217,217,0.4)",
                    }}
                    style={{
                      backgroundColor: "rgba(217, 217, 217, 0.41)",
                    }}
                    {
                      ...(!CONFIG.RAKSTHAI_TAB_DEFAULT_MENU && {
                        selectedTabIndex: this.state.selectedTabIndex,
                      }) /// Feature 54669
                    }
                    // callback
                    onChangeSelectedTabName={(name: string, idx: number) => {
                      this.setState({ selectedTabPatientList: ""})
                      this.setState({ selectedTabIndex: idx})
                    }}
                    data={[
                      {
                        key: "Dashboard",
                        name: (
                          <div
                            style={{
                              ...styles.tab_icon,
                              padding: "0.85rem 0.375rem 0.85rem 0.275rem",
                            }}
                          >
                            <Popup
                              trigger={<Image src={IMAGES.report} />}
                              content="Report & Dashboard"
                              position="right center"
                            />
                          </div>
                        ),
                        render: () => (
                          <ErrorBoundary>
                            <CardQueueForPatient
                              // Dashboard
                              match={{ params: {} }}
                              ref={this.dashboardRef}
                              apiToken={this.controller.apiToken}
                              division={this.controller.data.division || 0}
                              controller={this.dashboardController}
                              onEvent={this.controller.handleEvent}
                              queueBill={this.state.queueBill}
                              masterOptions={this.state.masterOptions}
                              queueDrug={this.state.queueDrug}
                              queuePrepareDrug={this.state.queuePrepareDrug}
                              queueDoctor={this.state.queueDoctor}
                              selectedDivision={this.state.selectedDivision}
                              // ODPDashboard
                              runSequence={this.runSequence}
                              setProp={this.controller.setProp}
                              TimeTrackingSequence={this.state.TimeTrackingSequence}
                              duckDBLoaded={this.state.duckDBLoaded}
                              appController={this.controller}
                              searchedItemListWithKey={this.state.searchedItemListWithKey}

                            />
                          </ErrorBoundary>
                        ),
                      },
                      {
                        key: "Registration",
                        name: (
                          <div style={styles.tab_icon}>
                            <Popup
                              trigger={<Image src={IMAGES.search} />}
                              content="Search Patient"
                              position="right center"
                            />
                          </div>
                        ),
                        render: () => (
                          <ErrorBoundary>
                            <ViewRegistration.Main
                              onEvent={this.controller.handleEvent}
                              setProp={this.controller.setProp}
                              setState={this.controller.setState}
                              runSequence={this.runSequence}
                              listView={ViewRegistration.listView(this)}
                              panelView={ViewRegistration.panelView(this)}
                              recordView={ViewRegistration.recordView(this)}
                              resetLayout={this.state.resetLayout}
                              initialLayout={this.state.initialLayout}
                              // get เลขที่ใบเสร็จ
                              bilReceiptCodeDetail={this.state.bilReceiptCodeDetail}
                              selectedDevice={this.state.selectedDevice}
                              showReceiptCode={this.state.divisionType === "การเงิน"}
                              onOpenVideoCall={(
                                url: string,
                                chat_channel_id: string
                              ) =>
                                this.setState({
                                  openVideoCallModal: true,
                                  videoCallRoom: url,
                                  currChatChannelId: chat_channel_id,
                                })
                              }
                            />
                          </ErrorBoundary>
                        ),
                      },
                      {
                        key: "Encounter",
                        name: (
                          <div style={styles.tab_icon}>
                            <Popup
                              trigger={<Image src={IMAGES.queue} />}
                              content="Service Queue"
                              position="right center"
                            />
                          </div>
                        ),
                        render: () => {
                          // console.log("MainHIS render this.state.patientQueueEncounter", this.state.patientQueueEncounter)
                          return (
                          <ErrorBoundary>
                            <ViewEncounter.Main
                              onEvent={this.controller.handleEvent}
                              setProp={this.controller.setProp}
                              listView={ViewEncounter.listView(this)}
                              panelView={ViewEncounter.panelView(this)}
                              recordView={ViewEncounter.recordView(this)}
                              resetLayout={this.state.resetLayout}
                              initialLayout={this.state.initialLayout}
                              //PHV port
                              apiToken={this.controller.apiToken}
                            />
                          </ErrorBoundary>
                        ) } ,
                      },
                      {
                        key: "Appointment",
                        name: (
                          <div
                            style={{
                              ...styles.tab_icon,
                              padding: "0.8rem 0.85rem",
                            }}
                          >
                            <Popup
                              trigger={<Image src={IMAGES.appointment} />}
                              content="Appointment"
                              position="right center"
                            />
                          </div>
                        ),
                        render: () => (
                          <ViewAppointment.Main
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            setState={this.controller.setState}
                            runSequence={this.runSequence}
                            listView={ViewAppointment.listView(this)}
                            panelView={ViewAppointment.panelView(this)}
                            recordView={ViewAppointment.recordView(this)}
                            resetLayout={this.state.resetLayout}
                            onOpenVideoCall={(
                              url: string,
                              chat_channel_id: string
                            ) =>
                              this.setState({
                                openVideoCallModal: true,
                                videoCallRoom: url,
                                currChatChannelId: chat_channel_id,
                              })
                            }
                          />
                        ),
                      },
                      {
                        key: "ChatSupport",
                        name: (
                          <div
                            style={{
                              ...styles.tab_icon,
                              padding: "0.8rem 0.85rem",
                            }}
                          >
                            <Popup
                              trigger={<Image src={IMAGES.chatSupport} />}
                              content="Chat Support"
                              position="right center"
                            />
                          </div>
                        ),
                        render: () => (
                          <ChatSupport
                            // ref={ref => (this.chatTable = ref)}
                            setProp={this.controller.setProp}
                            allowCreateAppoint={true}
                            controller={this.chatController}
                            diagFormController={this.diagFormController}
                            classifyController={this.classifyController}
                            apiToken={this.controller.apiToken}
                            division={this.controller.data.division || 0}
                            userId={this.controller.data.user?.toString() || ""}
                            fullname={this.state.providerEmployeeInfo?.employee_info
                              ?.full_name}
                            username={this.state.django?.user?.full_name || ""}
                            finishedTriageLevel={this.finishedTriageLevel}
                            onNavigationMessage={this.handleNavigationMessage}
                            onEditAppointment={this.handleCreateAppointment}
                            isPatient={false}
                            onOpenVideoCall={(
                              url: string,
                              chat_channel_id: string
                            ) =>
                              this.setState({
                                openVideoCallModal: true,
                                videoCallRoom: url,
                                currChatChannelId: chat_channel_id,
                              })
                            }
                          />
                        ),
                      },
                      {
                        key: "StaffChat",
                        name: (
                          <div
                            style={{
                              ...styles.tab_icon,
                              padding: "0.9rem 0.6rem 0.9rem 0.5rem",
                            }}
                          >
                            <Popup
                              trigger={<Image src={IMAGES.staffChat} />}
                              content="Staff Chat"
                              position="right center"
                            />
                          </div>
                        ),
                        render: () => (
                          <StaffChat
                            ref={(ref: any) => {
                              this.staffChatRef = ref;
                              return (this.chatBoxRef = ref?.chatBoxRef || null);
                            }}
                            onEvent={this.controller.handleEvent}
                            chatController={{
                              ...this.chatController,
                              ...this.chatController.msgManager,
                            }}
                            userId={this.controller.data.user?.toString() || ""}
                            userName={this.state.django?.user?.full_name || ""}
                            userLastName={this.controller.data.userProfile?.last_name || ""}
                            userFirstName={this.controller.data.userProfile?.first_name || ""}
                            // userId={"5727"}
                            // userName={"พยาบาล ดุจดาว"}
                            apiToken={this.controller.apiToken}
                            divisionId={this.state.selectedDivision?.id}
                            chatRoomList={this.state.chatRoomList}
                            searchUserList={this.state.searchUserList}
                            userChatSubscriptionList={this.state.userChatSubscriptionList}
                            searchMessageList={this.state.searchMessageList}
                            onReadAllMessages={this.handleReadAllMessages}
                            onOpenVideoCall={(url: string, chat_channel_id: string) =>
                              this.setState({
                                openVideoCallModal: true,
                                videoCallRoom: url,
                                currChatChannelId: chat_channel_id,
                              })
                            }
                            onDidMount={this.handleStaffChatDidMount}
                          />
                        ),
                      },
                      {
                        key: "MemberHealthKit",
                        name: (
                          <div
                            style={{
                              ...styles.tab_icon,
                              padding: "0.8rem 0.85rem",
                            }}
                          >
                            <Popup
                              trigger={<Image src={IMAGES.memberHealthKit} />}
                              content="Member HealthKit"
                              position="right center"
                            />
                          </div>
                        ),
                        render: () => (
                          <MemberHealthKit.Main
                            runSequence={this.runSequence}
                            setProp={this.controller.setProp}
                            onEvent={this.controller.handleEvent}
                            panelView={MemberHealthKit.panelView(this)}
                            recordView={MemberHealthKit.recordView(this)}
                            HealthMemberSequence={this.state.HealthMemberSequence}
                          />
                        ),
                      },
                      // {
                      //   key: "PathwayOld",
                      //   name: (
                      //     <div
                      //       style={{
                      //         ...styles.tab_icon,
                      //         padding: "0.8rem 0.8rem 0.5rem 0.2rem",
                      //       }}
                      //     >
                      //       <Popup
                      //         trigger={<Image src={IMAGES.telemed} />}
                      //         content="TeleHealth"
                      //         position="right center"
                      //       />
                      //     </div>
                      //   ),
                      //   render: () => (
                      //     // <ViewPathwayOld.Main
                      //     //   ref={this.qaChatTable}
                      //     //   onEvent={this.controller.handleEvent}
                      //     //   listView={ViewPathwayOld.listView(this)}
                      //     //   panelView={ViewPathwayOld.panelView(this)}
                      //     //   recordView={ViewPathwayOld.recordView(this)}
                      //     //   resetLayout={this.state.resetLayout}
                      //     //   context={this}
                      //     // />

                      //     <>
                      //       <ModSelectAppointment
                      //         controller={this.queueController}
                      //         open={this.state.openModChooseAppointment}
                      //         onClose={() => {
                      //           this.setState({
                      //             patientAppointmentId: null,
                      //             openModChooseAppointment: false,
                      //           });
                      //           // setPatientAppointmentId(null);
                      //           // setOpenModChooseAppointment(false);
                      //         }}
                      //         patientAppointmentId={
                      //           this.state.patientAppointmentId
                      //         }
                      //         division={this.controller.data.division || 0}
                      //       />

                      //       <QAChatTable
                      //         setState={this.controller.setState}
                      //         getState={this.controller.getState}
                      //         // for backward complatible
                      //         // diagFormId={this.state?.QAChatTable?.diagFormId}
                      //         // triageLevel={this.state?.QAChatTable?.triageLevel}

                      //         // apiToken={props.apiToken}
                      //         apiToken={this.controller.apiToken}
                      //         // division={props.division}
                      //         division={this.controller.data.division || 0}
                      //         // finishedTriageLevel={props.finishedTriageLevel}
                      //         finishedTriageLevel={
                      //           this.state.finishedTriageLevel
                      //         }
                      //         // {...props}
                      //         // มาจาก MainScreen props ทั้งหมด และ render props ของ react-router บน Platform
                      //         // แต่ render props ถูก override ด้วย render props ของ Main screen

                      //         // ref={(ref) => (this.mainScreen = ref)} // Override

                      //         // Not used
                      //         // getLeftMenuItem={this.getLeftMenuItem}
                      //         // getDivisionMenuItem={this.getDivisionMenuItem}
                      //         // getRightMenuItem={this.getRightMenuItem}
                      //         // chatController={this.ChatController}
                      //         // appointmentChatController={this.AppointmentChatController}
                      //         // ruleListController={this.ruleListController}
                      //         // diagComposerController={this.diagComposerController}

                      //         // Override
                      //         // diagFormController={this.diagFormController}
                      //         // patientPanelController={this.patientPanelController}
                      //         // nurseNoteController={this.nurseNoteController}
                      //         // progressNoteController={this.progressNoteController}

                      //         // Not used
                      //         // bindHNPageController={this.bindHNPageController}

                      //         // Override
                      //         // crdVitalSignController={this.crdVitalSignController}

                      //         // Not used
                      //         // appointmentDashboardController={
                      //         //   this.appointmentDashboardController
                      //         // }

                      //         // Override
                      //         // classifyController={this.classifyController}
                      //         // apiToken={this.state.apiToken}
                      //         // onGetDivision={this.loadMydivision}
                      //         // division={this.state.division}
                      //         // userId={this.state.userId}
                      //         // fullname={this.state.fullname}
                      //         // username={this.state.username}
                      //         // finishedTriageLevel={this.state.finishedTriageLevel}

                      //         // Override
                      //         // queueController={this.queueController}
                      //         // dashboardController={this.dashboardController}

                      //         // Not used
                      //         // regChatController={this.regChatController}
                      //         // bilPatientPanelController={this.bilPatientPanelController}
                      //         // onReconnectWs={this.reconnectWs}
                      //         // onEvent={this.controller.handleEvent}
                      //         // patientSegmentList={this.state.patientSegmentList}
                      //         // diagFormDetail={this.state.diagFormDetail}
                      //         // divisionProfileList={this.state.divisionProfileList}
                      //         // isLoadingFormAnalytics={this.state.isLoadingFormAnalytics}
                      //         // myDivisions={this.state.myDivisions}
                      //         // storage={this.controller.storage}
                      //         // getDivision={this.getDivision}
                      //         // onSetState={(state: any) => this.setState({ ...state })}
                      //         // isVerified={this.state.isVerified}
                      //         // goToRegister={this.handleGoToRegister}
                      //         // vaccineInfo={this.state.vaccineInfo}
                      //         // appointmentList={this.state.appointmentList}
                      //         // generateUserModal={this.state.generateUserModal}
                      //         // generateUserResult={this.state.generateUserResult}

                      //         //Feature #54627 Override
                      //         // patientADR={this.state.patientADR}
                      //         /// Enb {...props}

                      //         // {...chatProps}
                      //         // react-router
                      //         // props.match.params ถ้ามี params คือ ใช้บน MainScreen

                      //         // pass new props
                      //         diag_rule={this.state?.QAChatTable?.diag_rule}
                      //         match={{
                      //           params: {
                      //             triageLevel:
                      //               this.state?.QAChatTable?.triageLevel,
                      //             diagFormId:
                      //               this.state?.QAChatTable?.diagFormId,
                      //           },
                      //         }} // จำลอง react-router คืน
                      //         noReactRouter={true}
                      //         // ref={qaChatTable}
                      //         ref={(ref: any) => (this.qaChatTableRef = ref)}
                      //         allowCreateAppoint={
                      //           CONFIG.BOOKING_FUNCTION ? true : false
                      //         }
                      //         // Edit
                      //         // Controller
                      //         controller={{
                      //           ...this.chatController,
                      //           regManager: this.proxyController,
                      //           prxManager: this.proxyController,
                      //         }}
                      //         diagFormController={this.diagFormController}
                      //         patientPanelController={
                      //           this.patientPanelController
                      //         }
                      //         nurseNoteController={{
                      //           ...this.proxyController,
                      //           ...this.nurseNoteController,
                      //         }}
                      //         progressNoteController={
                      //           this.progressNoteController
                      //         }
                      //         classifyController={this.classifyController}
                      //         crdVitalSignController={
                      //           this.crdVitalSignController
                      //         }
                      //         onGetDivision={() => {}} // เรียกตอน useEffect[] ดังนั้น initial ตั้งแต่แรกไปเลย
                      //         dashboardController={this.dashboardController}
                      //         queueController={this.queueController}
                      //         // Data
                      //         userId={this.controller.data.user || 0}
                      //         // fullname={this.state.fullname}
                      //         // username={this.state.username}
                      //         storage={this.controller.storage}
                      //         isPatient={false}
                      //         autoHeight={true}
                      //         classifyFromDiagRule={true}
                      //         // Callback
                      //         onNavigationMessage={this.handleNavigationMessage}
                      //         onEditAppointment={this.handleCreateAppointment}
                      //         // config
                      //         AIRFLOW_CHAT={true}
                      //         //Feature #54627
                      //         patientADR={this.state.patientADR}
                      //       />
                      //     </>
                      //   ),
                      // },
                      // {
                      //   key: "Content",
                      //   name: <Icon name="images" />,
                      //   render: () =>
                      //     <ViewContent.Main
                      //       onEvent={this.controller.handleEvent}
                      //       setProp={this.controller.setProp}
                      //       setState={this.controller.setState}
                      //       runSequence={this.runSequence}
                      //       listView={ViewContent.listView(this)}
                      //       panelView={ViewContent.panelView(this)}
                      //       recordView={ViewContent.recordView(this)}
                      //       resetLayout={this.state.resetLayout}
                      //     />
                      // },

                      // {
                      //   key: "Pathway",
                      //   name: <Icon name="home" />,
                      //   render: () =>
                      //     <ViewPathway.Main
                      //       onEvent={this.controller.handleEvent}
                      //       listView={ViewPathway.listView(this)}
                      //       panelView={ViewPathway.panelView(this)}
                      //       recordView={ViewPathway.recordView(this)}
                      //       resetLayout={this.state.resetLayout}
                      //     />
                      // },
                      // {
                      //   key: "Accounting",
                      //   name: <Icon name="save" />,
                      //   render: () =>
                      //     <ViewAccounting.Main
                      //       onEvent={this.controller.handleEvent}
                      //       setProp={this.controller.setProp}
                      //       setState={this.controller.setState}
                      //       runSequence={this.runSequence}
                      //       listView={ViewAccounting.listView(this)}
                      //       panelView={ViewAccounting.panelView(this)}
                      //       recordView={ViewAccounting.recordView(this)}
                      //       resetLayout={this.state.resetLayout}
                      //     />
                      // },
                    ]
                      .filter((item: any): boolean => {
                        const screenItems = CONFIG.SCREEN_ITEM?.map((screenItem): string =>
                          screenItem.extra ? (JSON.parse(screenItem.extra) as string).key as string : ""
                        ).filter(Boolean);

                        const isVisible = CONFIG.SIDEMENU_SCREEN_CONFIG
                          ? !!screenItems?.includes(item.key) && !item.hide
                          : true;

                        let tabList =
                          this.state.divisionType === "backoffice" ? ["Registration"] : undefined;

                        return isVisible && (!tabList || tabList.includes(item.key));
                      })}
                    setProp={this.controller.setProp}

                  />
                ) : this.state.mode === "user" ? (
                  <TabPenta
                    selectedTabIndex={this.state.goToStaffChat ? 1 : 0}
                    key={"user"}
                    tabWidth="150px"
                    data={[
                      {
                        key: "Doctor Schedule",
                        name: "Doctor Schedule",
                        render: () => (
                          <CardDoctorSchedule
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            selectedDivision={this.state.selectedDivision}
                            SetScheduleSequence={this.state.SetScheduleSequence}
                            searchedItemList={this.state.searchedItemList}
                            selectedProvider={this.state.providerInfo}
                            holiday={this.state.holiday}
                            providerBlockList={this.state.providerBlockList}
                          />
                        ),
                      },
                      {
                        key: "Staff Chat",
                        hide: CONFIG.HIDE_STAFF_CHAT_ADMIN_MODE,
                        name: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <label>Staff Chat</label>
                            {this.state.showIconNoti && (
                              <Label
                                data-testid="msg-noti"
                                circular
                                color={"red"}
                                style={{ fontSize: "0.3rem" }}
                              ></Label>
                            )}
                          </div>
                        ),
                        render: () => (
                          <StaffChat
                            ref={(ref: any) => {
                              this.staffChatRef = ref;
                              return (this.chatBoxRef =
                                ref?.chatBoxRef || null);
                            }}
                            onEvent={this.controller.handleEvent}
                            chatController={{
                              ...this.chatController,
                              ...this.chatController.msgManager,
                            }}
                            userId={this.controller.data.user?.toString() || ""}
                            userName={this.state.django?.user?.full_name || ""}
                            userLastName={
                              this.controller.data.userProfile?.last_name || ""
                            }
                            userFirstName={
                              this.controller.data.userProfile?.first_name || ""
                            }
                            // userId={"5727"}
                            // userName={"พยาบาล ดุจดาว"}
                            apiToken={this.controller.apiToken}
                            divisionId={this.state.selectedDivision?.id}
                            chatRoomList={this.state.chatRoomList}
                            searchUserList={this.state.searchUserList}
                            userChatSubscriptionList={
                              this.state.userChatSubscriptionList
                            }
                            searchMessageList={this.state.searchMessageList}
                            onReadAllMessages={this.handleReadAllMessages}
                            onOpenVideoCall={(
                              url: string,
                              chat_channel_id: string
                            ) =>
                              this.setState({
                                openVideoCallModal: true,
                                videoCallRoom: url,
                                currChatChannelId: chat_channel_id,
                              })
                            }
                            onDidMount={this.handleStaffChatDidMount}
                          />
                        ),
                      },
                      {
                        key: "Doctor Schedule Old",
                        name: "Doctor Schedule Old",
                        render: () => (
                          <DoctorSchedule
                            // {...this.props}
                            // {...routeProps}
                            show="DoctorSchedule"
                            match={this.state.matchCompat}
                            apiToken={this.controller.apiToken}
                            division={this.controller.data.division || 0}
                            controller={this.dashboardController}
                            queueController={this.queueController}
                            providerId={this.controller.data.provider || 0}
                            // id={}
                            // weekStartSerial={}
                            onClickDaySlot={({
                              index,
                              weekStartSerial,
                            }: any) => {
                              console.log(index, weekStartSerial);
                              this.setState({
                                matchCompat: {
                                  params: {
                                    id: index,
                                    weekStartSerial: weekStartSerial,
                                  },
                                },
                              });
                            }}
                          />
                        ),
                      },
                      {
                        key: "DoctorFee_Report_Old",
                        name: "DoctorFee Report",
                        render: () => (
                          <CardDoctorFeeReportOld
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            SetDoctorFeeReportSequence={
                              this.state.SetDoctorFeeReportSequence
                            }
                            searchedItemList={this.state.searchedItemList}
                            selectedProvider={this.state.providerInfo}
                            errorMessage={this.state.errorMessage}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                          />
                        ),
                      },
                      {
                        key: "DoctorFee_Report",
                        name: "DoctorFee Report",
                        // hide: CONFIG.HIDE_DOCTOR_FEE_ALL_MENU, // 67071 hide
                        render: () => (
                          <CardDoctorFeeReport
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            DoctorFeeReportSequence={this.state.DoctorFeeReportSequence}
                            // options
                            masterOptions={this.state.masterOptions}
                            // CommonInterface
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            errorMessage={this.state.errorMessage}
                          />
                        ),
                      },
                      {
                        key: "Telemed record",
                        name: "Telemed record",
                        render: () => (
                          <CardTelemedRecord
                            controller={this.controller}
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            runSequence={this.runSequence}
                            selectedDivision={this.state.selectedDivision}
                            SetDoctorFeeReportSequence={
                              this.state.SetDoctorFeeReportSequence
                            }
                            searchedItemList={this.state.searchedItemList}
                            selectedProvider={this.state.providerInfo}
                          />
                        ),
                      },
                      {
                        key: "Profile",
                        name: "Profile",
                        render: () => (
                          <CardStaffSetting
                            setProp={this.controller.setProp}
                            onEvent={this.controller.handleEvent}
                            // Controller
                            controller={this.controller}
                            proxyController={this.proxyController}
                            // staffList={this.state.staffList}
                            // data
                            profileUser={this.state.profileUser}
                            changePassword={this.state.changePassword}
                            profileDivision={this.state.profileDivision}
                            profileEmployee={this.state.profileEmployee}
                            user={this.state.user}
                            // options
                            masterOptions={this.state.masterOptions}
                            roleOptions={this.state.roleOptions}
                            divisionOptions={this.state.divisionOptions}
                            masterData={this.controller.data.masterData}
                            // CommonInterface
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            errorMessage={this.state.errorMessage}
                            successMessage={this.state.successMessage}
                            // config
                            editUserProfile={this.controller.data.userProfile}
                            mode="edit"
                            tab="user-employee"
                            hideTabDivision={true}
                            hideEmployeeForm={true}
                            hideButtonBack={true}
                            fetchOptionsOnly={true}
                            showPassword2={true}
                            pw2CheckTeacherFlag={true}
                          />
                        ),
                      },
                    ].filter((item: any): boolean => {
                      return !(
                        CONFIG.V3_HIDDEN_TAB_LIST.includes(item.key) || item.hide
                      );
                    })}
                    setProp={this.controller.setProp}

                    onChangeSelectedTabName={(name: string, idx: number) => {
                      this.setState({ showTabName: name})
                    }}
                  />
                ) : this.state.mode === "segment" ? (
                  <TabPenta
                    selectedTabIndex={0}
                    key={"segment"}
                    tabWidth="125px"
                    data={[
                      {
                        key: "Underlying disease",
                        name: "Underlying disease",
                        render: () => (
                          <CardUnderlyingDisease
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // controller
                            drugOrderQueueController={
                              this.drugOrderQueueController
                            }
                            // seq
                            runSequence={this.runSequence}
                            UnderlyingDiseaseSequence={
                              this.state.UnderlyingDiseaseSequence
                            }
                            // CommonInterface
                            errorMessage={this.state.errorMessage}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                          />
                        ),
                      },
                      {
                        key: "Risk Alert",
                        name: "Risk Alert",
                        render: () => <></>,
                      },
                      {
                        key: "Drug-Disease interaction",
                        name: "Drug-Disease interaction",
                        render: () => (
                          <>
                            <CardDrugDiseaseInteraction
                              onEvent={this.controller.handleEvent}
                              setProp={this.controller.setProp}
                              // seq
                              runSequence={this.runSequence}
                              DrugDiseaseInteractionSequence={
                                this.state.DrugDiseaseInteractionSequence
                              }
                              // CommonInterface
                              errorMessage={this.state.errorMessage}
                              buttonLoadCheck={this.state.buttonLoadCheck}
                              searchedItemListWithKey={
                                this.state.searchedItemListWithKey
                              }
                              // options
                              masterOptions={this.state.masterOptions}
                            />
                          </>
                        ),
                      },
                      {
                        key: "Health Member",
                        name: "Health Member",
                        render: () =>
                          <CardHealthMember
                            onEvent={this.controller.handleEvent}
                            setProp={this.controller.setProp}
                            // seq
                            runSequence={this.runSequence}
                            HealthMemberSequence={this.state.HealthMemberSequence}
                            // controller
                            drugOrderQueueController={
                              this.drugOrderQueueController
                            }
                            // CommonInterface
                            errorMessage={this.state.errorMessage}
                            buttonLoadCheck={this.state.buttonLoadCheck}
                            searchedItemListWithKey={this.state.searchedItemListWithKey}
                          />,
                      },
                    ]}
                    setProp={this.controller.setProp}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}

          <CardNotiMessage
            setProp={this.controller.setProp}
            // data
            notificationMessageList={this.state.notificationMessageList}
          />
        </div>
      </IntlProvider>
    );
  }
}

export default MainHIS;
