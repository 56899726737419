import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Checkbox,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardAIPNSendClaimUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div
          className="ui form">

          <div
            style={{fontSize: "1.15rem", fontWeight: "bold", padding: "2.5px 0"}}>
            AIPN Send Claim
          </div>
        </div>
        <div
          className="ui divider">

        </div>
        <Form
          className="small">
          <FormGroup
            className="--readOnly --grey"
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <label>
                เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.searchInvoiceGroup}
              </div>
            </FormField>
            <FormField
              className="required"
              inline={true}
              width={3}>
              <label
                style={{minWidth: "max-content"}}>
                สิทธิ
              </label>
              <Input
                disabled={true}
                fluid={true}
                value={props.filter?.arTransaction?.coverage_code_name||""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth: "max-content"}}>
                หน่วยงานต้นสังกัดที่เรียกเก็บ
              </label>
              <Input
                disabled={true}
                fluid={true}
                value={props.filter?.arTransaction?.payer_code_name||""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={1}>
              <Checkbox
                checked={props.filter?.isDate|| false}
                label="ระบุวันที่"
                name="isDate"
                onChange={props.onChangeFilter}
                style={{minWidth: "max-content", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  disabled={!props.filter?.isDate}
                  onChange={(value: string)=>props.onChangeFilter?.(null,{name:"startDate", value})}
                  style={{width: "100%"}}
                  value={props.filter?.startDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{textAlign: "center", width: "100%"}}>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  disabled={!props.filter?.isDate}
                  onChange={(value: string)=>props.onChangeFilter?.(null,{name:"endDate", value})}
                  style={{width: "100%"}}
                  value={props.filter?.endDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.isStatus || false}
                label="สถานะส่งเบิก"
                name="isStatus"
                onChange={props.onChangeFilter}
                style={{minWidth: "max-content", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                className="inline-label"
                disabled={!props.filter?.isStatus}
                fluid={true}
                name="status"
                onChange={props.onChangeFilter}
                options={props.sentClaimChoiceOptions || []}
                search={true}
                selection={true}
                style={{width: "100%", fontSize: "1em"}}
                value={props.filter?.status || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.isPatient|| false}
                label="HN"
                name="isPatient"
                onChange={props.onChangeFilter}
                style={{minWidth: "max-content", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.patientSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.isEncounter|| false}
                label="AN"
                name="isEncounter"
                onChange={props.onChangeFilter}
                style={{minWidth: "max-content", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.searchEncounter}
              </div>
            </FormField>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginTop: "2rem"}}>
            <FormField
              inline={true}
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}
              width={1}>
              <div
                style={{width: "46px", height: "16px", backgroundColor: "rgba(255, 204, 102, 1)", marginLeft: "1rem"}}>

              </div>
              <label
                style={{fontWeight: "normal"}}>
                Validate ไม่ผ่าน
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}
              width={1}>
              <div
                style={{width: "46px", height: "16px", backgroundColor: "rgba(255, 197, 197, 1)", marginLeft: "1rem"}}>

              </div>
              <label
                style={{fontWeight: "normal"}}>
                ติด C
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}
              width={1}>
              <div
                style={{width: "46px", height: "16px", backgroundColor: "rgba(153, 204, 255, 1)", marginLeft: "1rem"}}>

              </div>
              <label
                style={{fontWeight: "normal"}}>
                รอผลตอบกลับ
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}
              width={1}>
              <div
                style={{width: "46px", height: "16px", backgroundColor: "rgba(153, 255, 153, 1)", marginLeft: "1rem"}}>

              </div>
              <label
                style={{fontWeight: "normal"}}>
                เบิกสำเร็จ
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}
              width={1}>
              <div
                style={{width: "46px", height: "16px", backgroundColor: "rgba(201, 255, 229, 1)", marginLeft: "1rem"}}>

              </div>
              <label
                style={{fontWeight: "normal"}}>
                ไม่ส่งเบิก
              </label>
            </FormField>
          </FormGroup>
        </Form>
        <div>

          <Table
            data={props.billTransactionList}
            getTrProps={props.onGetTrProps}
            headers=",สถานะส่งเบิก,Date Transaction,Hospital Code,Encounter ID.,HN,AN,Amount,Claim Amount,Paid,Personal ID,Name"
            keys="description,status_display,dt_tran,hospital_code,encounter,hn,encounter,total_amount_price,total_send_claim_price,total_paid_price,personal_id,name, ref_no"
            minRows={9}
            showPagination={false}
            style={{height: "calc(-28.5rem + 100vh)"}}
            widths="110,^80,140,^85,^80,^70,^70,^70,^85,^70,^90,^120,^70">
          </Table>
        </div>
        <Form
          className="--readOnly --grey small">
          <FormGroup
            inline={true}
            style={{marginTop: "1rem"}}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                จำนวนข้อมูลที่ส่งเบิกได้
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                disabled={true}
                value={props.billTransactionSummary?.total_rows||""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                ยอดเบิก
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                disabled={true}
                value={props.billTransactionSummary?.total_sent_claim_price||""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                ยอดผู้ป่วยจ่าย
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                disabled={true}
                value={props.billTransactionSummary?.total_paid_price || props.billTransactionSummary?.total_paid||""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                ยอดรวมจ่าย
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                disabled={true}
                value={props.billTransactionSummary?.total_other_pay_price || props.billTransactionSummary?.total_other_pay||""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginTop: "2rem"}}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="purple"
                disabled={!props.filter?.arTransaction?.id}
                onClick={props.onClickDownloadPreviousSendClaim}
                size="small">
                ดาวน์โหลด zip ไฟล์ย้อนหลัง
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonDownloadZipFile}
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardAIPNSendClaimUX

export const screenPropsDefault = {}

/* Date Time : Thu May 23 2024 10:57:35 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "AIPN Send Claim"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "className": {
          "type": "value",
          "value": "--readOnly --grey"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 6,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานต้นสังกัดที่เรียกเก็บ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchInvoiceGroup"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Input",
      "parent": 10,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.arTransaction?.coverage_code_name||\"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 11,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.arTransaction?.payer_code_name||\"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isDate|| false"
        },
        "label": {
          "type": "value",
          "value": "ระบุวันที่"
        },
        "name": {
          "type": "value",
          "value": "isDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontSize: \"1em\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 24,
      "name": "DateTextBox",
      "parent": 23,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.filter?.isDate"
        },
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.(null,{name:\"startDate\", value})"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.startDate || \"\""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 30,
      "name": "DateTextBox",
      "parent": 29,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.filter?.isDate"
        },
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.(null,{name:\"endDate\", value})"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.endDate || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isPatient|| false"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "name": {
          "type": "value",
          "value": "isPatient"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontSize: \"1em\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 32,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isEncounter|| false"
        },
        "label": {
          "type": "value",
          "value": "AN"
        },
        "name": {
          "type": "value",
          "value": "isEncounter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontSize: \"1em\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchEncounter"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 7,
      "props": {
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 41,
      "name": "Table",
      "parent": 40,
      "props": {
        "data": {
          "type": "code",
          "value": "props.billTransactionList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": ",สถานะส่งเบิก,Date Transaction,Hospital Code,Encounter ID.,HN,AN,Amount,Claim Amount,Paid,Personal ID,Name"
        },
        "keys": {
          "type": "value",
          "value": "description,status_display,dt_tran,hospital_code,encounter,hn,encounter,total_amount_price,total_send_claim_price,total_paid_price,personal_id,name, ref_no"
        },
        "minRows": {
          "type": "code",
          "value": "9"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(-28.5rem + 100vh)\"}"
        },
        "widths": {
          "type": "value",
          "value": "110,^80,140,^85,^80,^70,^70,^70,^85,^70,^90,^120,^70"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Checkbox",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isStatus || false"
        },
        "label": {
          "type": "value",
          "value": "สถานะส่งเบิก"
        },
        "name": {
          "type": "value",
          "value": "isStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontSize: \"1em\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Dropdown",
      "parent": 43,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "disabled": {
          "type": "code",
          "value": "!props.filter?.isStatus"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.sentClaimChoiceOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.status || \"\""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "Validate ไม่ผ่าน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "ติด C"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "รอผลตอบกลับ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกสำเร็จ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่ส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"46px\", height: \"16px\", backgroundColor: \"rgba(255, 204, 102, 1)\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"46px\", height: \"16px\", backgroundColor: \"rgba(255, 197, 197, 1)\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"46px\", height: \"16px\", backgroundColor: \"rgba(153, 204, 255, 1)\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"46px\", height: \"16px\", backgroundColor: \"rgba(153, 255, 153, 1)\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"46px\", height: \"16px\", backgroundColor: \"rgba(201, 255, 229, 1)\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--readOnly --grey small"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormGroup",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนข้อมูลที่ส่งเบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดเบิก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดผู้ป่วยจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวมจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Input",
      "parent": 73,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billTransactionSummary?.total_other_pay_price || props.billTransactionSummary?.total_other_pay||\"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Input",
      "parent": 71,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billTransactionSummary?.total_paid_price || props.billTransactionSummary?.total_paid||\"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Input",
      "parent": 69,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billTransactionSummary?.total_sent_claim_price||\"\""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Input",
      "parent": 67,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billTransactionSummary?.total_rows||\"\""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 65,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormGroup",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 83,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 83,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 83,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Button",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ดาวน์โหลด zip ไฟล์ย้อนหลัง"
        },
        "color": {
          "type": "value",
          "value": "purple"
        },
        "disabled": {
          "type": "code",
          "value": "!props.filter?.arTransaction?.id"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDownloadPreviousSendClaim"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 86,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonDownloadZipFile"
        }
      },
      "seq": 89,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": true,
  "memo": false,
  "name": "CardAIPNSendClaimUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 100
}

*********************************************************************************** */
