import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input,
  Icon
} from 'semantic-ui-react'

const CardLabReportUX = (props: any) => {
    return(
      <div
        style={{padding:"10px", minWidth: "max-content"}}>
        <div
          style={{ paddingBottom: "10px" }}>

          <div
            style={{ paddingBottom: "10px" }}>

            <div
              style={{ fontSize: "1.2rem", fontWeight: "bold", padding:"0.35rem 0 0.35rem", display: "flex", justifyContent: "space-between"}}>

              <div>
                {props.titleName || "Lab Results"}
              </div>
              <div
                style={{ display: props.showPatientInfo?"":"none"}}>

                <Icon
                  className="red"
                  link={true}
                  name="close"
                  onClick={props.onClose}>
                </Icon>
              </div>
            </div>
            <hr>
            </hr>
          </div>
          <div
            style={{ paddingBottom: "1rem" , display: props.showPatientInfo?"flex":"none"}}>

            <div
              style={{fontWeight: "bold"}}>
              HN
            </div>
            <div
              style={{marginRight:"2px", paddingLeft: "5px"}}>
              {props.patientInfo?.hn}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              ชื่อ
            </div>
            <div
              style={{marginRight:"2px", paddingLeft: "5px"}}>
              {props.patientInfo?.full_name}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              เพศ
            </div>
            <div
              style={{marginRight:"5px", paddingLeft: "5px"}}>
              {props.patientInfo?.gender==="M"?"ชาย":props.patientInfo?.gender==="F"?"หญิง":"ไม่ระบุ"}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              วันเกิด
            </div>
            <div
              style={{marginRight:"5px", paddingLeft: "5px"}}>
              {props.patientInfo?.birthdate}
            </div>
          </div>
          <div
            style={{display:"flex",  paddingBottom: "1rem",textWrap: "nowrap",marginRight: "115px"}}>

            <div
              style={{fontWeight: "bold"}}>
              Lab ID:
            </div>
            <div
              style={{marginRight:"10px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.full_access_number}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              วันเวลาที่สั่ง :
            </div>
            <div
              style={{marginRight:"5px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.date}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              สถานะ lab order:
            </div>
            <div
              style={{marginRight:"5px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.status_name}
            </div>
            <div
              style={{ fontWeight: "bold", marginLeft: "3%"}}>
               วันเวลาที่รับออเดอร์ :
            </div>
            <div
              style={ {marginRight:"5px", paddingLeft: "5px"}}>
              {props.arriveTime}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              การชำระเงิน :
            </div>
            <div
              style={ {width:"10%",marginRight:"5px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.payment_status_label}
            </div>
          </div>
          <div
            style={{display:"flex",  paddingBottom: "1rem" }}>

            <div
              style={{fontWeight: "bold"}}>
              แพทย์ผู้สั่ง :
            </div>
            <div
              style={{marginRight:"2px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.doctor_name}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              แผนก :
            </div>
            <div
              style={{marginRight:"2px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.division_name}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              Remark:
            </div>
            <div
              style={{marginRight:"5px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.note}
            </div>
          </div>
        </div>
        <div
          style={{display: "flex" }}>

          <Button
            color="blue"
            disabled={props.disabledAttachFile || false}
            loading={props.loadingPrint}
            onClick={props.onAttachFile}>
            Attach file
          </Button>
          <Button
            color="violet"
            disabled={props.disabledLabReport || false}
            onClick={props.onLabReport}
            style={{display: props.hideButtonLabReport ? "none":""}}>
            Lab Report
          </Button>
          <div>
            {props.buttonPrintLabResult}
          </div>
          <div
            style={{flex: 1}}>

          </div>
          <Input
            onChange={props.changeUsername}
            placeholder="username"
            size="mini"
            style={{marginRight:"5px", display: "none"}}
            value={props.username}>
          </Input>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "0.5rem"}}>
            Password
          </div>
          <Input
            onChange={props.changePassword}
            placeholder="ระบุรหัสผ่าน"
            size="mini"
            style={{marginRight:"5px"}}
            type="password"
            value={props.password}>
          </Input>
          <div
            style={{marginRight:"5px"}}>
            {props.buttonSave}
          </div>
          <div>
            {props.buttonAuthorize}
          </div>
        </div>
        <div
          style={{marginTop:"10px"}}>
          {props.tableReport}
        </div>
      </div>
    )
}


export default CardLabReportUX

export const screenPropsDefault = {}

/* Date Time : Tue Jul 02 2024 11:47:22 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Attach file"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAttachFile || false"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrint"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAttachFile"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUsername"
        },
        "placeholder": {
          "type": "value",
          "value": "username"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\", display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 13,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changePassword"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุรหัสผ่าน"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\"}"
        },
        "type": {
          "type": "value",
          "value": "password"
        },
        "value": {
          "type": "code",
          "value": "props.password"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"10px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"10px\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\", padding:\"0.35rem 0 0.35rem\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "hr",
      "parent": 17,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 20,
      "void": true
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tableReport"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",  paddingBottom: \"1rem\",textWrap: \"nowrap\",marginRight: \"115px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab ID:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.full_access_number"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาที่สั่ง :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.date"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ lab order:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.status_name"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": " วันเวลาที่รับออเดอร์ :"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.arriveTime"
        },
        "style": {
          "type": "code",
          "value": " {marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "การชำระเงิน :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.payment_status_label"
        },
        "style": {
          "type": "code",
          "value": " {width:\"10%\",marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",  paddingBottom: \"1rem\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้สั่ง :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.doctor_name"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"2px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.division_name"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"2px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.note"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAuthorize"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Report"
        },
        "color": {
          "type": "value",
          "value": "violet"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledLabReport || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onLabReport"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideButtonLabReport ? \"none\":\"\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleName || \"Lab Results\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showPatientInfo?\"\":\"none\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Icon",
      "parent": 83,
      "props": {
        "className": {
          "type": "value",
          "value": "red"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"1rem\" , display: props.showPatientInfo?\"flex\":\"none\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInfo?.hn"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"2px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInfo?.full_name"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"2px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "เพศ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInfo?.gender===\"M\"?\"ชาย\":props.patientInfo?.gender===\"F\"?\"หญิง\":\"ไม่ระบุ\""
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเกิด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInfo?.birthdate"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintLabResult"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Password"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"0.5rem\"}"
        }
      },
      "seq": 80,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 50,
  "isMounted": false,
  "memo": false,
  "name": "CardLabReportUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 85
}

*********************************************************************************** */
