import React, { useState, useEffect, CSSProperties, useMemo } from "react";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import CardQueueBillUX from "./CardQueueBillUX";
import moment from "moment";
import { Button, Icon, Modal } from "semantic-ui-react";

const styles = {
  arrow: {
    display: "block",
    width: "15px",
    height: "15px",
    borderTop: "4px solid #949494",
    borderLeft: "4px solid #949494",
    transform: "rotate(135deg)",
  } as CSSProperties,
  verticalLine: {
    borderLeft: "4px solid #949494",
    height: "50px",
  } as CSSProperties,
};
const hospital = "โรงพยาบาล มอร์เฮลท์ เเคร์";
const IMAGE = {
  logo: "images/IHimage.png",
};

const dateDefault = dateToStringWithoutTimeBE(new Date());

const CardQueueBill = (props: any) => {
  const [time, setTime] = useState<any>();

  useEffect(() => {
    props.onEvent({
      message: "QueueForPatient",
      params: { action: "queueBill" },
    });
  }, []);

  useEffect(() => {
    let list = setInterval(
      () =>
        props.onEvent({
          message: "QueueForPatient",
          params: { action: "queueBill" },
        }),
      1000 * 30
    );
    return () => {
      clearInterval(list);
    };
  }, []);
  useEffect(() => {
    let timeId = setInterval(
      () => setTime(moment(new Date())?.format("HH:mm:ss")),
      1000
    );
    return () => {
      clearInterval(timeId);
    };
  }, []);

  const list = useMemo(() => {
    const items: any = [];
    for (let index = 0; index < 25; index++) {
      items.push(props?.queueBill?.billList?.[index]);
    }

    return items;
  }, [props?.queueBill?.billList]);

  return (
    <>
      <CardQueueBillUX
        hospital={hospital}
        division={
          props.masterOptions?.division?.find(
            (options: any) => options.value === props.queueBill?.division
          )?.text
        }
        date={dateDefault}
        time={time}
        logo={IMAGE.logo}
        number={props?.queueBill?.billList?.length || "0"}
        openFullScreen={props.openFullScreen}
        onClose={() => {
          props.setOpenFullScreen(false);
        }}
        list={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(list || [])?.map((item: any, index: any) => {
              return <ListData item={item} index={index} />;
            })}
          </div>
        }
        arrow={<Arrow />}
      />
    </>
  );
};

const ListData = (props: any) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        margin: "15px 10px 10px 12px",
        padding: "10px",
        width: "18%",
        height: "50px",
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: "22px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {props?.item?.number}
      </div>
    </div>
  );
};

const Arrow = (props: any) => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            border: "2px solid #949494",
            width: "5%",
            position: "absolute",
            left: "260px",
            top: "70px",
          }}
        />
        <div
          style={{
            ...styles.arrow,
            position: "absolute",
            left: "326px",
            top: "65px",
          }}
        />
        <div
          style={{
            border: "2px solid #949494",
            width: "5%",
            position: "absolute",
            left: "565px",
            top: "70px",
          }}
        />
        <div
          style={{
            ...styles.arrow,
            position: "absolute",
            left: "630px",
            top: "65px",
          }}
        />
        <div
          style={{
            border: "2px solid #949494",
            width: "5%",
            position: "absolute",
            left: "860px",
            top: "70px",
          }}
        />
        <div
          style={{
            ...styles.arrow,
            position: "absolute",
            left: "925px",
            top: "65px",
          }}
        />
        <div
          style={{
            border: "2px solid #949494",
            width: "5%",
            position: "absolute",
            right: "326px",
            top: "70px",
          }}
        />
        <div
          style={{
            ...styles.arrow,
            position: "absolute",
            right: "323px",
            top: "65px",
          }}
        />
        <div
          style={{
            border: "2px solid #949494",
            width: "5%",
            position: "absolute",
            right: "49px",
            top: "70px",
          }}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div
          style={{
            position: "absolute",
            border: "2px solid #949494",
            width: "4%",
            top: "150px",
            left: "0px",
          }}
        />
        <div
          style={{
            display: "flex",
            position: "absolute",
            width: "90%",
            right: "40px",
            top: "150px",
          }}
        >
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "55px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "55px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "55px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "55px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "25px",
            }}
          />
          <div
            style={{
              border: "2px solid #949494",
              width: "15%",
              marginRight: "55px",
            }}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            borderLeft: "4px solid #949494",
            position: "absolute",
            height: "44px",
            top: "150px",
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            border: "2px solid #949494",
            width: "1%",
            position: "absolute",
            left: "2px",
            top: "190px",
          }}
        />
        <div
          style={{
            ...styles.arrow,
            position: "absolute",
            left: "2px",
            top: "185px",
          }}
        />
      </div>

      <div>
        <div
          style={{
            borderLeft: "4px solid #949494",
            position: "absolute",
            height: "84px",
            top: "70px",
            right: "45px",
          }}
        />

        <div
          style={{
            position: "absolute",
            border: "2px solid #949494",
            width: "2%",
            top: "150px",
            right: "45px",
          }}
        />
      </div>
    </div>
  );
};

export default CardQueueBill;
