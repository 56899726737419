import WasmController from "react-lib/frameworks/WasmController";

// APIS
import PatientCoverageList from "issara-sdk/apis/PatientCoverageList_core";
import PatientCoverageDetail from "issara-sdk/apis/PatientCoverageDetail_core";

import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";

export type State = {
  selectedPatient?: any;
  SetCoverageSequence?: {
    sequenceIndex: string | null;
    coverageList?: any[];
    selectedCoverage?: any;
  } | null;
  errorMessage?: any;
  successMessage?: any;
  errorCoverage?: any;
};

export const StateInitial: State = {
  SetCoverageSequence: null,
  errorMessage: null,
  successMessage: null,
  errorCoverage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetCoverageSequence) return;
  if (!Number.isInteger(state.selectedPatient?.id)) return;

  // Get master
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["assureType", {}],
        ["coverage", {}],
        ["referer", {}],
        ["divisionOpd", {}],
      ],
    },
  });

  // Get patient coverage
  const patientCoverage = await PatientCoverageList.list({
    params: {
      patient: state.selectedPatient?.id,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    SetCoverageSequence: {
      sequenceIndex: "Edit",
      coverageList: (patientCoverage[0].items || 0).sort((a: any, b: any) =>
        a?.priority < b?.priority ? -1 : 1
      ),
      selectedCoverage: {
        active: true,
        service_type: "",
        priority: 99,
        coverage: "",
        assure_type: "",
        main_hospital_code: "",
        start_date: formatDate(moment()),
        stop_date: formatDate(moment()),
        refer_date: "",
      },
    },
  });
};

export const Edit: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.SetCoverageSequence) return;

  if (params.action === "select" && params.item) {
    controller.setState({
      SetCoverageSequence: {
        ...state.SetCoverageSequence,
        selectedCoverage: params.item,
      },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  } else if (params.action === "new") {
    controller.setState({
      SetCoverageSequence: {
        ...state.SetCoverageSequence,
        selectedCoverage: {
          active: true,
          service_type: "",
          priority: 99,
          coverage: "",
          assure_type: "",
          main_hospital_code: "",
          start_date: formatDate(moment()),
          stop_date: formatDate(moment()),
          refer_date: "",
        },
      },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  } else if (params.action === "save") {
    if (state.SetCoverageSequence.selectedCoverage?.id) {
      const { id, ...data } = state.SetCoverageSequence.selectedCoverage;
      const patientCoverage = await PatientCoverageDetail.update({
        pk: id,
        data: data,
        apiToken: controller.apiToken,
      });

      if (patientCoverage[0]) {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params?.sequence]: patientCoverage[0],
          },
        });

        Start(controller, {});
      }

      if (patientCoverage[1]) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params?.sequence]: patientCoverage[1],
          },
        });
      }
    } else {
      const [response, error] = await PatientCoverageList.create({
        data: {
          ...state.SetCoverageSequence.selectedCoverage,
          patient: state.selectedPatient?.id,
        },
        apiToken: controller.apiToken,
      });

      if (response) {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params?.sequence]: response,
          },
        });

        Start(controller, {});
      }

      if (error) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params?.sequence]: error },
        });
      }
    }
  } else if (params?.action === "clear") {
    controller.setState({
      SetCoverageSequence: {
        ...state.SetCoverageSequence,
        selectedCoverage: {
          active: true,
          service_type: "",
          priority: 99,
          coverage: "",
          assure_type: "",
          main_hospital_code: "",
          start_date: formatDate(moment()),
          stop_date: formatDate(moment()),
          refer_date: "",
        },
      },
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  }

  params.callback?.();
};

const NewCoverage = {
  active: true,
};
