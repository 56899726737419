import React, {
  useState,
  useEffect,
  useMemo,
  CSSProperties,
  useCallback,
} from "react";
import { Button, Dropdown, Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import CardStockManagementTabIssueUX from "./CardStockManagementTabIssueUX";
import ModStockSelectLotNo from "./ModStockSelectLotNo";

// Common
import ModInfo from "react-lib/apps/common/ModInfo";

// Interface
import {
  AggStockSerializer,
  PermissionsType,
  IssueStockDetailType,
  ProductStockSerializer,
  BUTTON_ACTIONS,
  RunSequence,
  DISTRIBUTION_REASON,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Types
type CardStockManagementTabIssueProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // data
  data: Partial<AggStockSerializer>;
  divisionId?: number;
  permissions?: PermissionsType;
  productStockList?: ProductStockSerializer[];
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // options
  divisionTypeDrugOptions?: Record<string, any>[];
  divisionOptions?: Record<string, any>[];
};

// Const
const PATIENT_SEARCH_SMI = "Patient_SMI";

const CARD_STOCK_MANAGEMENT_TAB_ISSUE = "CardStockManagementTabIssue";

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

const CardStockManagementTabIssue = (
  props: CardStockManagementTabIssueProps
) => {
  // data
  const [issueStockDetail, setIssueStockDetail] = useState<
    Partial<IssueStockDetailType>
  >({});
  const [currentDetail, setCurrentDetail] = useState<
    Partial<IssueStockDetailType>
  >({});
  const [issueStockList, setIssueStockList] = useState<
    Partial<IssueStockDetailType>[]
  >([]);
  // mod
  const [openModInfo, setOpenModInfo] = useState<boolean>(false);
  const [openModSelectLot, setOpenModSelectLot] = useState<boolean>(false);

  // Effect
  useEffect(() => {
    const checkPType =
      !!issueStockList.length &&
      !issueStockList.find(
        (item) => item.product?.p_type_name === props.data.product?.p_type_name
      );
    const detail = {
      requester: props.divisionId, //props.data.storage?.id
      product: props.data.product,
      storage: props.data.storage,
    };

    if (!props.data.storage?.id) {
      setCurrentDetail({});
      return setIssueStockDetail({});
    } else if (checkPType) {
      setCurrentDetail(detail);
      setIssueStockDetail({});

      return setOpenModInfo(true);
    } else {
      setCurrentDetail(detail);
    }

    setIssueStockDetail(detail);

    return () => {
      handleClearSearchBox();
    };
  }, [props.data]);

  // Use Callback
  const handleRemove = useCallback(
    (index: number) => () => {
      const list = [...issueStockList];

      const filter = list.filter((_: any, idx: number) => idx !== index);

      if (!filter.length) {
        setIssueStockDetail(currentDetail);
      }

      setIssueStockList(filter);
    },
    [issueStockList, currentDetail]
  );

  const handleEditQty = useCallback(
    (index: number) => (value: string | number) => {
      // type number value ไม่น้อยกว่า 0
      value = Number(value) < 0 ? 0 : value;

      issueStockList[index]["quantity"] = value.toString();

      setIssueStockList([...issueStockList]);
    },
    [issueStockList]
  );

  const handleSelectedItem = useCallback(
    async (value: any, key: any) => {
      handleChangeValue(null, {
        value: value || null,
        name: "provider",
      });
    },
    [props.searchedItemListWithKey, issueStockDetail]
  );

  const mapPatientOptions = useCallback((items: any) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name,
    }));
  }, []);

  // Use Memo
  const distributionReasonOptions = useMemo(() => {
    return Object.values(DISTRIBUTION_REASON).map((value) => ({
      key: value,
      text: value,
      value,
    }));
  }, []);

  const titleName = useMemo(() => {
    const product = issueStockDetail.product;

    return product?.id ? `[${product?.code || ""}]-${product?.name || ""}` : "";
  }, [issueStockDetail]);

  // หากมีรายการมากว่า 1 ให้ default รายการแรก - เหตุผลในการตัดจ่ายสินค้า และ ตัดจ่ายสินค้าไปยัง และ หมายเหตุเพิ่มเติม
  const defaultDetail = useMemo(() => {
    return issueStockList[0] || issueStockDetail;
  }, [issueStockDetail, issueStockList]);

  const allowAdd = useMemo(() => {
    // requester ต้องเป็น requester เดียวกัน
    const isRequester =
      !!issueStockList.length &&
      !!issueStockList.find(
        (item) => item.requester === defaultDetail.requester
      );

    const isProvider = [
      DISTRIBUTION_REASON.OTHER_DIVISION,
      DISTRIBUTION_REASON.PATIENT,
    ].includes((defaultDetail.reason || "") as any)
      ? !!defaultDetail.provider
      : true;

    const isAllow =
      defaultDetail.product?.id &&
      !!issueStockDetail.lot?.id &&
      !!issueStockDetail.quantity &&
      !!defaultDetail.reason &&
      isProvider &&
      (isRequester || !issueStockList.length);

    return isAllow && props.permissions?.TAB_ISSUE_STOCK_ISSUE;
  }, [defaultDetail, issueStockList, props.permissions, issueStockDetail]);

  // const providerDivisionOptions = useMemo(() => {
  //   return (props.divisionTypeDrugOptions || []).filter(
  //     (item: any) =>
  //       item.value !== issueStockDetail.requester &&
  //       item.key === issueStockDetail.storage?.id
  //   );
  // }, [props.divisionTypeDrugOptions, issueStockDetail, props.divisionOptions, props.divisionId]);

  const requestDivisionOptions = useMemo(() => {
    const divisions = (props.divisionOptions || []).filter(
      (option) => option.value === props.divisionId
    );
    return Array.from(
      new Map(
        [...(props.divisionTypeDrugOptions || []), ...divisions].map((item) => [
          item["value"],
          item,
        ])
      ).values()
    );
  }, [props.divisionTypeDrugOptions, props.divisionOptions, props.divisionId]);

  const providerLabel = useMemo(() => {
    const label = {
      [DISTRIBUTION_REASON.OTHER_DIVISION]: "เลือกหน่วยงาน",
      // [DISTRIBUTION_REASON.VENDOR]: "เลือก Vendor",
      [DISTRIBUTION_REASON.PATIENT]: "ค้นหาผู้ป่วย",
    };

    return (label as any)[issueStockDetail.reason || ""] || "";
  }, [issueStockDetail.reason]);

  const providerOptions = useMemo(() => {
    const label = {
      [DISTRIBUTION_REASON.OTHER_DIVISION]: props.divisionOptions || [],
    };

    return (label as any)[defaultDetail.reason || ""] || "";
  }, [defaultDetail.reason, props.divisionOptions]);

  const issueStockItems = useMemo(() => {
    return issueStockList.map((item, index) => ({
      ...item,
      code: <div style={GridCenter}>{item.product?.code}</div>,
      name: item.product?.name,
      lot_no: <div style={GridCenter}>{item.lot?.mfc_no}</div>,
      expiry_date: (
        <div style={GridCenter}>
          {item.lot?.exp_datetime
            ? formatDate(moment(item.lot?.exp_datetime))
            : ""}
        </div>
      ),
      quantity: (
        <EditorColumn
          value={item.quantity}
          valueOnFocus={true}
          selectOnFocus={true}
          inputType="number"
          // style
          backgroundColor={"rgb(255, 255, 204)"}
          padding="0 1rem 0 0"
          // callback
          onDataChanged={handleEditQty(index)}
        />
      ),
      action: (
        <div style={GridCenter}>
          <Button
            size="tiny"
            icon="trash alternate"
            color="red"
            style={{
              fontSize: "0.8rem",
              padding: "0.5rem",
            }}
            onClick={handleRemove(index)}
          />
        </div>
      ),
    }));
  }, [issueStockList, currentDetail]);

  const disabledProvider = useMemo(() => {
    return [
      DISTRIBUTION_REASON.INTERNAL_DIVISION,
      DISTRIBUTION_REASON.TREATMENT,
      DISTRIBUTION_REASON.DISPOSAL,
    ].includes((defaultDetail.reason || "") as any);
  }, [defaultDetail.reason]);

  // Handler
  const handleChangeValue = (_e: any, data: any) => {
    if (!props.permissions?.TAB_ISSUE_STOCK_ISSUE) {
      return;
    }

    const name = data.name as keyof IssueStockDetailType;
    let value = data.value;
    const detail = { ...issueStockDetail };

    // จำนวนตัดจ่ายต้องมากกว่า 0 และน้อยกว่า จำนวนคงเหลือ
    if (name === "quantity") {
      value = Number(value) < 0 ? 0 : value;

      const order = issueStockList.find(
        (item) =>
          item.product?.id === detail.product?.id &&
          item.product?.id === detail.product?.id
      );

      const maxQty = Number(detail?.max_quantity) - Number(order?.quantity);

      value =
        !!order && Number(value) > Number(maxQty)
          ? maxQty.toString()
          : Number(value) > Number(detail?.max_quantity)
          ? detail?.max_quantity?.toString()
          : value;
    } else if (name === "reason") {
      detail.provider = "";

      handleClearSearchBox();
    }

    detail[name] = value;

    setIssueStockDetail(detail);
  };

  const handleClearSearchBox = () => {
    props.setProp(`searchedItemListWithKey.${PATIENT_SEARCH_SMI}`, []);
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(false);
  };

  const handleCloseModSelectLot = () => {
    setOpenModSelectLot(false);
  };

  const handleOpenSelectLot = () => {
    setOpenModSelectLot(true);
  };

  const handleSelectLot = (productStock: ProductStockSerializer | null) => {
    const quantity = issueStockDetail.quantity || "";

    setIssueStockDetail({
      ...issueStockDetail,
      lot: productStock?.lot,
      stock_id: productStock?.id,
      max_quantity: productStock?.quantity,
      quantity:
        Number(quantity) > Number(productStock?.quantity)
          ? productStock?.quantity.toString()
          : quantity,
    });
  };

  const handleAdd = () => {
    const list = [...issueStockList];
    const detail = issueStockDetail;

    const index = list.findIndex(
      (item) =>
        item.product?.id === detail.product?.id &&
        item.lot?.id === detail.lot?.id
    );

    if (index >= 0) {
      list[index].quantity = (
        Number(list[index].quantity) + Number(detail.quantity)
      ).toString();
    } else {
      list.push({
        ...issueStockDetail,
      });
    }

    setIssueStockList(list);
    setIssueStockDetail({
      requester: props.divisionId, //props.data.storage?.id,
      product: props.data.product,
      storage: props.data.storage,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "SAVE_ISSUE_STOCK",
      issueStockList,
      card: CARD_STOCK_MANAGEMENT_TAB_ISSUE,
      errorKey: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.SAVE,
      onSuccess: handleCancel,
    });
  };

  const handleCancel = () => {
    setIssueStockDetail(currentDetail);
    setIssueStockList([]);
  };

  console.log("CardStockManagementTabIssueUX", props);

  return (
    <div>
      <CardStockManagementTabIssueUX
        // data
        issueStockList={issueStockItems}
        issueStockDetail={issueStockDetail}
        name={titleName}
        reason={defaultDetail?.reason}
        remark={defaultDetail?.remark}
        expiryDate={
          !!issueStockDetail.lot?.exp_datetime
            ? formatDate(moment(issueStockDetail.lot.exp_datetime))
            : ""
        }
        providerLabel={providerLabel}
        // options
        requestDivisionOptions={requestDivisionOptions}
        distributionReasonOptions={distributionReasonOptions}
        // providerDivisionOptions={providerDivisionOptions}
        // config
        disabledAdd={!allowAdd}
        disabledProvider={disabledProvider}
        readOnly={
          !issueStockDetail.product?.id ||
          !props.permissions?.TAB_ISSUE_STOCK_ISSUE
        }
        // callback
        onChangeValue={handleChangeValue}
        onOpenSelectLot={handleOpenSelectLot}
        onAdd={handleAdd}
        onCancel={handleCancel}
        // Element
        ButtonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirm}
            // data
            paramKey={`${CARD_STOCK_MANAGEMENT_TAB_ISSUE}_${BUTTON_ACTIONS.SAVE}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_STOCK_MANAGEMENT_TAB_ISSUE}_${BUTTON_ACTIONS.SAVE}`
              ]
            }
            // config
            disabled={!issueStockList.length}
            color={"green"}
            name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title="ยืนยันการตัดจ่าย"
          />
        }
        ProviderElement={
          [
            DISTRIBUTION_REASON.INTERNAL_DIVISION,
            DISTRIBUTION_REASON.TREATMENT,
            DISTRIBUTION_REASON.OTHER_DIVISION,
            DISTRIBUTION_REASON.DISPOSAL,

            "",
          ].includes((defaultDetail.reason || "") as any) ? (
            <Dropdown
              value={defaultDetail.provider || ""}
              name="provider"
              options={providerOptions}
              selection={true}
              clearable={true}
              search={true}
              fluid={true}
              disabled={
                !!issueStockList.length ||
                disabledProvider ||
                !issueStockDetail.reason
              }
              // style
              style={{
                width: "100%",
                opacity: 1,
                ...(disabledProvider && {
                  background: "#EAEAEA",
                  opacity: 0.75,
                  border: "none",
                }),
              }}
              onChange={handleChangeValue}
            />
          ) : (
            <SearchBoxDropdown
              onEvent={props.onEvent}
              // config
              type="Patient"
              id="SMI"
              fluid={true}
              useWithKey={true}
              icon="search"
              limit={20}
              disabled={!!issueStockList.length}
              // // Select
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={defaultDetail.provider || null}
              setSelectedItem={handleSelectedItem}
              // // options
              mapOptions={mapPatientOptions}
              // stylw
              style={{ width: "100%" }}
              dropdownStyle={{ opacity: 1 }}
            />
          )
        }
      />

      <ModInfo
        open={openModInfo}
        titleColor={"red"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <div
          style={{ padding: "0.5rem 0", fontWeight: "bold", lineHeight: 1.5 }}
        >
          <div>กรุณาเลือก Product Type ประเภทเดียวกัน</div>
        </div>
      </ModInfo>

      <Modal
        open={openModSelectLot}
        closeOnDimmerClick={true}
        style={{ width: "40%" }}
        // callback
        onClose={handleCloseModSelectLot}
      >
        <ModStockSelectLotNo
          // setProp={props.setProp}
          // seq
          // runSequence={props.runSequence}
          // data
          data={props.data}
          productStockList={props.productStockList}
          permissions={props.permissions}
          // callback
          onClose={handleCloseModSelectLot}
          onSelect={handleSelectLot}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardStockManagementTabIssue);
