import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Form, Checkbox } from "semantic-ui-react";
import { toast } from "react-toastify";
import _ from "react-lib/compat/lodashplus";
import moment from "moment";

import CardLayout from "react-lib/apps/common/CardLayout";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import PureReactTable from "react-lib/apps/common/PureReactTable";

const CardSearchARTransaction = props => {
	const isMounted = useRef(true);
	// State of checkbox
	const [targetDate, setTargetDate] = useState(
		moment()
			.add(543, "year")
			.format("DD/MM/YYYY")
	);
	const [filterDate, setFilterDate] = useState(true);
	const [filterLotNumber, setFilterLotNumber] = useState(false);
	const [filterFiscalYear, setFilterFiscalYear] = useState(false);
	const [filterOnlyC, setFilterOnlyC] = useState(false);
	// State of table
	const [data, setData] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [pageTotal, setPageTotal] = useState(1);
	const [pageSize, setPageSize] = useState(5);

	const lotNumberRef = useRef();
	const fiscalYearRef = useRef();

	const handleCheckboxChange = (event, data) => {
		switch (data.name) {
			case FILTER.DATE:
				setFilterDate(data.checked);
				break;

			case FILTER.LOT_NUMBER:
				setFilterLotNumber(data.checked);
				break;

			case FILTER.FISCAL_YEAR:
				setFilterFiscalYear(data.checked);
				break;

			case FILTER.ONLY_C:
				setFilterOnlyC(data.checked);
				break;

			default:
				break;
		}
	};

	const handleDateChange = date => {
		setTargetDate(date);
	};

	/** Handle table */
  /** OnEnter */
  const selectedWithDoubleClick = (item) => {
		props.onSelected(item);
		props.onClose();
  }

  /** OnSelect */
  const selectedWithSingleClick = (item) => {
		setSelectedRow(item);
  }

	const handlePageChange = pageNo => {
		searchARTransaction({ targetPage: pageNo + 1 });
	};

	const handlePageSizeChange = pageSize => {
		setPageSize(pageSize);
		searchARTransaction({ targetPageSize: pageSize });
	};
	/******************* */
	/** Handle "Selected" button */
	const handleSelected = (event, data) => {
		if (selectedRow) {
			props.onSelected(selectedRow);
			props.onClose();
		}
	}

	/** Handle "Search" button */
	const handleSearch = async () => {
		searchARTransaction();
	};

	const searchARTransaction = async ({ targetPage, targetPageSize } = {}) => {
		if (!props.controller) {
			return;
		}
		var params = {
			page: targetPage ? targetPage : page,
			pageSize: targetPageSize ? targetPageSize : pageSize,
		};
		if (filterDate) {
			params.invoiceDate = targetDate;
		}
		if (filterLotNumber) {
			params.lotNo = lotNumberRef.current.inputRef.current.value;
		}
		if (filterFiscalYear) {
			params.fiscalYear = fiscalYearRef.current.inputRef.current.value;
		}
		if (filterOnlyC) {
			params.onlyContainFailed = true;
		}
		setLoading(true);
		const [response, error] = await props.controller.searchARTransaction(params);
		if (isMounted.current) {
			setLoading(false);
			if (error) {
				return;
			}
			setPage(response.page);
			setPageTotal(response.total);
			setData(response.items);
		}
  };

	const columns = [
		{
			Header: "ปีงบประมาณ",
			accessor: "fiscal_year",
		},
		{
			Header: "เลขงวด",
			accessor: "lot_no",
		},
		{
			Header: "สิทธิ",
			accessor: "coverage_code_name",
		},
		{
			Header: "Payer",
			accessor: "payer_code_name",
		},
		{
			Header: "ประเภทผู้ป่วย",
			accessor: "patient_type",
			width: 120,
		},
		{
			Header: "สถานะส่งเบิก",
			accessor: "sent_claim_status_display",
			width: 220,
		},
		{
			Header: "สถานะตั้งหนี้",
			accessor: "status_display",
		},
		{
			Header: "วันตั้งหนี้",
			accessor: "created_at",
		},
		{
			Header: "วันตั้งหนี้ที่ SAP",
			accessor: "interfaced_at",
		},
		{
			Header: "วันตัดหนี้ที่ SAP",
			accessor: "completed_at",
		},
	];

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	return (
		<CardLayout
			titleText={"ค้นหารายการที่สร้างเลขงวดแล้ว"}
			headerColor={"blue"}
			toggleable={false}
			closeable={true}
			onClose={props.onClose}
		>
			<Form>
				<Form.Group inline>
					<Form.Field
						style={{ width: "120px" }}
						name={FILTER.DATE}
						control={Checkbox}
						label={"วันที่ในเอกสาร"}
						defaultChecked={true}
						onChange={handleCheckboxChange}
					/>
					<Form.Field
						width={3}
						control={DateTextBox}
						value={targetDate}
						disabled={!filterDate}
						onChange={handleDateChange}
					/>
					<Form.Field width={1} />
					<Form.Field name={FILTER.LOT_NUMBER} control={Checkbox} label={"เลขงวด"} onChange={handleCheckboxChange} />
					<Form.Field width={3}>
						<Input ref={lotNumberRef} disabled={!filterLotNumber} />
					</Form.Field>
					<Form.Field width={2}>
						<Button fluid color={"blue"} onClick={handleSearch}>
							ค้นหา
						</Button>
					</Form.Field>
				</Form.Group>

				<Form.Group inline>
					<Form.Field
						style={{ width: "120px" }}
						name={FILTER.FISCAL_YEAR}
						control={Checkbox}
						label={"ปีงบประมาณ"}
						onChange={handleCheckboxChange}
					/>
					<Form.Field width={3}>
						<Input ref={fiscalYearRef} disabled={!filterFiscalYear} />
					</Form.Field>
				</Form.Group>

				<Form.Field
					name={FILTER.ONLY_C}
					control={Checkbox}
					label={"แสดงเฉพาะ AR ที่มีรายการติด C"}
					onChange={handleCheckboxChange}
				/>
			</Form>

			<PureReactTable
				style={{ marginTop: "16px", marginBottom: "16px", ...props.tableStyle }}
				loading={loading}
				pageSize={pageSize}
				pages={pageTotal}
				page={page - 1}
				onPageSizeChange={handlePageSizeChange}
				onPageChange={handlePageChange}
				data={data}
				columns={columns}
				selectedRow={selectedRow}
        onSelect={selectedWithSingleClick}
        onEnter={selectedWithDoubleClick}
				getTrProps={(state, rowInfo) => {
					const options = {};
					if (rowInfo && _.get(rowInfo, "original.have_failed")) {
						options.style = {
							background: "rgb(255, 197, 197)",
							color: "black",
							whiteSpace: "pre-line",
						};
					}
					return options;
				}}
			/>

			<Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
				<Form.Button color={"green"} type={"button"} onClick={handleSelected}>
					เลือก
				</Form.Button>
			</Form.Group>
		</CardLayout>
	);
};

const FILTER = {
	DATE: "date",
	LOT_NUMBER: "lot_number",
	FISCAL_YEAR: "fiscal_year",
	ONLY_C: "only_c",
};

CardSearchARTransaction.defaultProps = {
	controller: null,
  onClose: () => {},
	tableStyle: {},
  onSelected: (item) => {},
};

CardSearchARTransaction.propTypes = {
	controller: PropTypes.object,
	tableStyle: PropTypes.object,
  onClose: PropTypes.func,
  onSelected: PropTypes.func,
};

export default React.memo(CardSearchARTransaction);
