import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Radio,
  Input
} from "semantic-ui-react";

const defaultData = {
  systemic_organ: 0, choice_id: 0, choice_name: "", has_description: false, status: ""
};

const SubSystemicReviewItem = React.forwardRef((props, ref) => {
  const [choiceData, setChoiceData] = useState(defaultData);

  const { isReadOnly, choice } = props;

  useEffect(() => {
    setChoiceData(choice)
  }, [choice]);

  React.useImperativeHandle(ref, () => ({
    getChoiceData: () => {
      return choiceData;
    },
  }));

  return (
    <Form key={choiceData.choice_id}>
      <Form.Group inline>
        <Form.Field width={1}>
          &nbsp;&nbsp;&nbsp;
          <Radio
            value="X"
            name="radioGroup"
            checked={choiceData.status === "X"}
            onChange={e => {
              setChoiceData({...choiceData, status: "X"})
            }}
            disabled={isReadOnly}
          />
        </Form.Field>
        <Form.Field width={1}>
          &nbsp;&nbsp;
          <Radio
            value="Y"
            name="radioGroup"
            checked={choiceData.status === "Y"}
            onChange={e => {
              setChoiceData({...choiceData, status: "Y"})
            }}
            disabled={isReadOnly}
          />
        </Form.Field>
        <Form.Field width={1}>
          &nbsp;&nbsp;&nbsp;
          <Radio
            value="N"
            name="radioGroup"
            checked={choiceData.status === "N"}
            onChange={e => {
                setChoiceData({...choiceData, status: "N"})
            }}
            disabled={isReadOnly}
          />
        </Form.Field>
        <Form.Field width={10}>
          <label>{choiceData.choice_name}</label>
          {choiceData.has_description && (
            <Input
              value={choiceData.description}
              onChange={e => {
                setChoiceData({...choiceData, description: e.target.value})
              }}
              disabled={isReadOnly}
            ></Input>
          )}
        </Form.Field>
      </Form.Group>
    </Form>
  );
});

SubSystemicReviewItem.defaultProps = {
  choice: {},
  isReadOnly: false
};

SubSystemicReviewItem.propTypes = {
  choice: PropTypes.object,
  isReadOnly: PropTypes.bool
};

export default React.memo(SubSystemicReviewItem);
