import React, { CSSProperties, useState, useEffect } from "react";

// UX
import ModUDSegmentGroupUX from "./ModUDSegmentGroupUX";

// Common
import { ErrorMessage } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { RunSequence } from "./sequence/UnderlyingDisease";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// types
type ModUDSegmentGroupProps = {
  setProp?: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence?: RunSequence;
  // data
  detail?: DetailType;
  errorKey?: string;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // Callback
  onSuccess?: Function;
  onCancel?: Function;
  onChange?: (data: { value: string; name: string }) => any;
  // config
  contentStyle?: CSSProperties;
  hideHeader?: boolean;
  hideAction?: boolean;
  readOnly?: boolean;
};

export type DetailType = {
  code?: string;
  name?: string;
  name_en?: string;
};

export const MOD_UD_SEGMENT_GROUP = "ModUDSegmentGroup";

const ModUDSegmentGroup = (props: ModUDSegmentGroupProps) => {
  const [detail, setDetail] = useState<DetailType>({});
  const [errorMessage, setErrorMessage] = useState<any>(null);

  useEffect(() => {
    setDetail(props.detail || {});
  }, [props.detail]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    if (props.onChange) {
      props.onChange(data);
    }

    setDetail({
      ...detail,
      [data.name]: data.value,
    });
  };

  const handleCreate = () => {
    let error: Record<string, any> = {};
    const messages = [
      ["code", "Group Code"],
      ["name", "ชื่อ Segment group [ภาษาไทย]"],
    ] as const;

    for (const msg of messages) {
      if (!detail[msg[0]]) {
        error[msg[1]] = ["This field is required."];
      }
    }

    if (!!Object.keys(error).length) {
      return setErrorMessage(error);
    }

    setErrorMessage(null);

    props.runSequence?.({
      sequence: "UnderlyingDisease",
      data: detail,
      action: "CREATE_SEGMENT_GROUP",
      card: MOD_UD_SEGMENT_GROUP,
      errorKey: props.errorKey,
      onSuccess: () => {
        props.onSuccess?.();

        setDetail({});
      },
    });
  };

  return (
    <>
      <ModUDSegmentGroupUX
        // data
        data={detail}
        // config
        contentStyle={props.contentStyle}
        hideHeader={props.hideHeader}
        hideAction={props.hideAction}
        readOnly={props.readOnly}
        // callback
        onCreate={handleCreate}
        onCancel={props.onCancel}
        onChangeValue={handleChangeValue}
        // Element
        ErrorMessage={
          <ErrorMessage
            error={errorMessage}
            style={{ margin: "-1rem 1rem 1rem 0" }}
          />
        }
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleCreate}
            // data
            paramKey={`${MOD_UD_SEGMENT_GROUP}_SAVE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${MOD_UD_SEGMENT_GROUP}_SAVE`]
            }
            // config
            color={"green"}
            name="SAVE"
            size="medium"
            style={{ width: "200px", marginRight: "3rem" }}
            title="สร้างกลุ่ม"
          />
        }
      />
    </>
  );
};

export default React.memo(ModUDSegmentGroup);
