import React, {
  ChangeEvent,
  MutableRefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Icon, Pagination } from "semantic-ui-react";

import moment from "moment";
import { RowInfo } from "react-table-6";

// UX
import CardSettingMediaDocumentUX from "./CardSettingMediaDocumentUX";

// Interface
import {
  BTN_ACTS,
  CARD_SETTING_MEDIA_DOCUMENT,
  RunSequence,
  SetProp,
  State,
  PickedProps,
  LIST_LIMIT,
  FilterType,
  DentalMediaDocumentSerializer,
  ACTIONS,
  MasterOptionsType,
  REQUIRED_FIELDS,
} from "./sequence/SettingMediaDocument";
import { getErrorMsgLabel } from "../common/CommonInterface";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import DateTextBox from "react-lib/apps/common/DateTextBox";

// Utils
import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";

// Types
type CardSettingMediaDocumentProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  SettingMediaDocumentSequence: State["SettingMediaDocumentSequence"];
  // options
  masterOptions?: MasterOptionsType;
} & PickedProps;

const CardSettingMediaDocument = (props: CardSettingMediaDocumentProps) => {
  const [modDelete, setModDelete] = useState<DentalMediaDocumentSerializer | null>(null);
  const [workGroupOptions, setWorkGroupOptions] = useState<any[]>([]);

  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;
  const boxRef = useRef() as MutableRefObject<HTMLDivElement>;

  // Memo Effect
  const mediaDocDetail = useMemo(() => {
    return props.SettingMediaDocumentSequence?.mediaDocDetail;
  }, [props.SettingMediaDocumentSequence?.mediaDocDetail]);

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "SettingMediaDocument",
      restart: true,
    });

    return () => {
      props.setProp(`errorMessage.${CARD_SETTING_MEDIA_DOCUMENT}`, null);
    };
  }, []);

  useEffect(() => {
    if (mediaDocDetail?.id) {
      const list = props.SettingMediaDocumentSequence?.mediaDocList;

      const findItem = list?.items.find((item) => item.id === mediaDocDetail.id);

      if (!findItem) {
        props.setProp("SettingMediaDocumentSequence.mediaDocDetail", null);
      }
    }
  }, [mediaDocDetail, props.SettingMediaDocumentSequence?.mediaDocList]);

  useEffect(() => {
    const wgOptions = props.SettingMediaDocumentSequence?.workGroupOptions || [];
    const wgMasterOptions = props.masterOptions?.workgroup || [];

    setWorkGroupOptions([...wgOptions, ...wgMasterOptions]);
  }, [props.SettingMediaDocumentSequence?.workGroupOptions, props.masterOptions?.workgroup]);

  // Callback
  const handleGetTrProps = useCallback(
    (_state: any, rowInfo: RowInfo) => {
      return {
        className:
          mediaDocDetail?.id && mediaDocDetail?.id === rowInfo?.original.id
            ? "blueSelectedRow"
            : "",
      };
    },
    [mediaDocDetail]
  );

  const handleEdit = useCallback((data: DentalMediaDocumentSerializer) => {
    props.runSequence({ sequence: "SettingMediaDocument", action: ACTIONS.SELECT_ITEM, data });
  }, []);

  const handleDelete = useCallback((data: DentalMediaDocumentSerializer) => {
    setModDelete(data);
  }, []);

  // Memo
  const errorMsgLabel = useMemo(() => {
    const error = props.errorMessage?.[CARD_SETTING_MEDIA_DOCUMENT];

    return getErrorMsgLabel(error, REQUIRED_FIELDS);
  }, [props.errorMessage]);

  const qrCodeUrl = useMemo(() => {
    let qrCode = props.SettingMediaDocumentSequence?.mediaDocDetail?.qr_image || "";

    if (qrCode) {
      qrCode = typeof qrCode === "string" ? qrCode : URL.createObjectURL(qrCode);
    }

    return qrCode;
  }, [props.SettingMediaDocumentSequence?.mediaDocDetail?.qr_image]);

  const filterMediaDoc = useMemo(() => {
    return props.SettingMediaDocumentSequence?.filter || {};
  }, [props.SettingMediaDocumentSequence?.filter]);

  const mediaDocItems = useMemo(() => {
    const items = props.SettingMediaDocumentSequence?.mediaDocList?.items || [];

    return items.map((item, index) => {
      return {
        ...item,
        edited: formatDatetime(item.edited, true),
        qr_expired_at: item.qr_expired_at ? formatDate(moment(item.qr_expired_at)) : "",
        _qrcode: (
          <div style={{ display: "grid", placeContent: "center" }}>
            {item.qr_image && (
              <Icon name="check circle" color="green" style={{ fontSize: "1.3rem" }} />
            )}
          </div>
        ),
        _action: (
          <RenderButtonAction
            data={item}
            index={index}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            // callback
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        ),
      };
    });
  }, [
    props.SettingMediaDocumentSequence?.mediaDocList,
    props.SettingMediaDocumentSequence?.workGroupOptions,
    handleEdit,
    handleDelete,
  ]);

  // Handler
  const handlePageChange = (_e: any, data: any) => {
    handleSearch(data.activePage);
  };

  const handleSearch = (page: number) => {
    props.runSequence({
      sequence: "SettingMediaDocument",
      action: ACTIONS.SEARCH,
      activePage: typeof page === "number" ? page : 1,
    });
  };

  const handleChangeFilter = (e: any, data: any) => {
    const name = data.name as keyof FilterType;

    const filter = { ...filterMediaDoc };

    filter[name] = data.value;

    props.setProp(`SettingMediaDocumentSequence.filter`, { ...filter });
  };

  const handleChangeValue = (
    e: any,
    data: { value: any; name: keyof DentalMediaDocumentSerializer }
  ) => {
    const name = data.name;

    const detail = { ...mediaDocDetail };

    detail[name] = data.value;

    props.setProp(`SettingMediaDocumentSequence.mediaDocDetail`, {
      ...detail,
    });
  };

  const handleClose = () => {
    props.setProp("SettingMediaDocumentSequence.mediaDocDetail", null);
  };

  const handleAddNew = () => {
    props.setProp("SettingMediaDocumentSequence", {
      ...props.SettingMediaDocumentSequence,
      mediaDocDetail: {},
      showRequiredField: null,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "SettingMediaDocument",
      action: ACTIONS.SAVE,
      card: CARD_SETTING_MEDIA_DOCUMENT,
    });
  };

  const handleCloseModDelete = () => {
    setModDelete(null);
  };

  const handleConfirmDelete = () => {
    if (modDelete?.id) {
      props.runSequence({
        sequence: "SettingMediaDocument",
        action: ACTIONS.DELETE,
        card: CARD_SETTING_MEDIA_DOCUMENT,
        mediaDocId: modDelete?.id,
        onSuccess: handleCloseModDelete,
      });
    }
  };

  const handleAddWorkGroupOptions = (e: SyntheticEvent, v: any) => {
    setWorkGroupOptions([...workGroupOptions, { key: v.value, text: v.value, value: v.value }]);
  };

  const handleClickBrowse = () => {
    fileRef.current?.click?.();
  };

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      handleChangeValue(null, { name: "qr_image", value: file });

      fileRef.current.value = "";
    }
  };

  const handleClickDate = (e: any) => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  };

  const handleChangeDate = (name: keyof DentalMediaDocumentSerializer) => async (date: string) => {
    handleChangeValue(null, { name, value: date });
  };

  console.log("CardSettingMediaDocument", props);

  return (
    <div ref={boxRef} style={{ height: "calc(-3rem + 100vh)", overflow: "auto" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_SETTING_MEDIA_DOCUMENT}`, null);
        }}
        error={errorMsgLabel}
        success={null}
      />

      <CardSettingMediaDocumentUX
        // data
        qrCodeUrl={qrCodeUrl}
        mediaDocList={mediaDocItems}
        filter={filterMediaDoc}
        showDetail={!!mediaDocDetail}
        mediaDocDetail={mediaDocDetail}
        showRequiredField={props.SettingMediaDocumentSequence?.showRequiredField}
        // options
        workGroupsOptions={workGroupOptions}
        // table
        onGetTrProps={handleGetTrProps}
        // callback
        onChangeFilter={handleChangeFilter}
        onChangeValue={handleChangeValue}
        onClickCancel={handleClose}
        onClickClose={handleClose}
        onAddNew={handleAddNew}
        onAddItemWorkGroup={handleAddWorkGroupOptions}
        onClickBrowse={handleClickBrowse}
        onClickDate={handleClickDate}
        // Element
        WorkGroupDropdownOptions={
          <DropdownOptions
            value={filterMediaDoc.workGroups || ""}
            name="workGroups"
            // multiple={true}
            search={true}
            // checked={true}
            inline={true}
            clearable={true}
            options={props.SettingMediaDocumentSequence?.workGroupOptions}
            onChange={handleChangeFilter}
            fluid={true}
            style={{ width: "100%" }}
          />
        }
        ExpireDateTextBox={
          <DateTextBox
            value={mediaDocDetail?.qr_expired_at}
            style={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            onChange={handleChangeDate("qr_expired_at")}
            onClick={handleClickDate}
          />
        }
        Pagination={
          <Pagination
            activePage={props.SettingMediaDocumentSequence?.mediaDocList?.activePage || 0}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={Math.ceil(
              (props.SettingMediaDocumentSequence?.mediaDocList?.total || 0) / LIST_LIMIT
            )}
            size="mini"
            // callback
            onPageChange={handlePageChange}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // // data
            paramKey={BTN_ACTS.SEARCH}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEARCH]}
            // config
            color={"blue"}
            name={ACTIONS.SEARCH}
            size="small"
            title="ค้นหา"
          />
        }
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // // data
            paramKey={BTN_ACTS.SAVE}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SAVE]}
            // config
            color={mediaDocDetail?.id ? "yellow" : "green"}
            name={ACTIONS.SAVE}
            size="small"
            title={mediaDocDetail?.id ? "แก้ไข" : "บันทึก"}
          />
        }
      />

      <input
        type="file"
        id="file"
        ref={fileRef}
        style={{ display: "none" }}
        onChange={handleChangeFile}
        accept="image/jpeg"
      />

      <ModConfirm
        openModal={!!modDelete}
        titleName="แจ้งเตือน"
        titleColor="red"
        size="mini"
        denyButtonText="ยกเลิก"
        // callback
        onDeny={handleCloseModDelete}
        onCloseWithDimmerClick={handleCloseModDelete}
        // ELement
        content={
          <div
            style={{
              margin: "0rem 1.5rem -1rem 0rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            ต้องการลบรายการใช่หรือไม่
          </div>
        }
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmDelete}
            // data
            paramKey={BTN_ACTS.DELETE}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DELETE]}
            // config
            color={"green"}
            name={ACTIONS.DELETE}
            size="medium"
            title={"ตกลง"}
            basic={true}
          />
        }
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                   RenderButtonAction;                  */

/* ------------------------------------------------------ */
const RenderButtonAction = (props: any) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button
        size="mini"
        icon="edit"
        color="yellow"
        // loading={!!props.buttonLoadCheck?.[`${ACTION_SELECT}_${props.index}`]}
        style={{ padding: "0.5rem", marginRight: "2rem" }}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();

          props.onEdit?.(props.data, props.index);
        }}
      />
      <Button
        size="mini"
        icon="trash alternate"
        color="red"
        style={{ padding: "0.5rem" }}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();

          props.onDelete?.(props.data, props.index);
        }}
      />
    </div>
  );
};

CardSettingMediaDocument.displayName = "CardSettingMediaDocument";

export default React.memo(CardSettingMediaDocument);
