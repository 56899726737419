import React, { useState, useEffect } from "react";
import { CardLayout, ErrorMessage, PureReactTable } from "../common";
import { Button } from "semantic-ui-react";
import "./CardSelectVitalSign.scss";

export interface CardSelectVitalSignState {
  loading?: boolean;
  encounterVitalSignList?: any[];
  getEncounterVitalSignListErr?: any;
}

export const CardSelectVitalSignInitial: CardSelectVitalSignState = {
  loading: false,
  encounterVitalSignList: [],
  getEncounterVitalSignListErr: null,
};

export type CardSelectVitalSignEvent = 
  | { message: "handleGetEncounterVitalSignList", params: any }

const CardSelectVitalSign = (props) => {

  const columns = [
    {
      Header: "วัน-เวลา",
      accessor: "date",
      width: 180,
      Cell: (row) => {
        return (
          <div>{`${row.value} ${row.original.time}`}</div>
        )
      }
    },
    {
      Header: "ชื่อผู้บันทึก",
      accessor: "edit_user_name",
      width: 200,
    },
    {
      Header: "Vital Signs",
      minWidth: 100,
      Cell: (row) => {
        return (
          row.original.vital_signs.map((item, index) => {
            return <span key={index}>{`${item.name} ${item.formatted_string} ${item.unit}${
              parseInt(index) !== (row.original.vital_signs.length - 1) ? ", " : ""
            }`}</span>
          })
        )
      }
    },
    {
      Header: "",
      width: 100,
      style: { textAlign: "center" },
      Cell: (row) => {
        return (
          <Button content="เลือก" color="blue" compact onClick={() => props.onSelect({ row })}/>
        )
      }
    },
  ]

  useEffect(() => {
    setCommonState({ getEncounterVitalSignListErr: null })
    props.onEvent({ message: "handleGetEncounterVitalSignList", params: {
      use_encounter: true
    } })
  }, [])

  const setCommonState = state => {
    props.onEvent({ message: "setState", params: state });
  };

  return (
    <CardLayout
      cardLayoutClassName="card-select-vital-sign"
      closeable={false}
      toggleable={false}
      titleText="รายการวัด Vital Signs"
      headerColor="orange"
      hideHeaderIcon
      loading={props.loading}
    >
      <ErrorMessage error={props.getEncounterVitalSignListErr}/>
      <PureReactTable
        data={props.encounterVitalSignList}
        columns={columns}
        showPagination={false}
        pageSize={props.encounterVitalSignList.length > 5 ? props.encounterVitalSignList.length : 5}
      />
    </CardLayout>
  )
}

CardSelectVitalSign.defaultProps = {
  onEvent: () => {},
  loading: false,
  encounterVitalSignList: [],
  getEncounterVitalSignListErr: null,
  onSelect: () => {},
}

export default React.memo(CardSelectVitalSign)