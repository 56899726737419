import React, { FC, forwardRef, useImperativeHandle, useRef } from "react";
import { Form, Grid, Header } from "semantic-ui-react";
import CardRangeOfMotion from "./CardRangeOfMotion";
import CardSensory from "./CardSensory";

const styles = {
  gridBG: {
    background: "#F3F3F3",
    marginLeft: "1px",
    marginRight: "1px",
    paddingTop: "15px",
    paddingLeft: "0px",
  },
  noPadding: {
    padding: "0px",
  },
  leftPadding: {
    paddingLeft: "10px",
  },
};

export interface CardOTPhysicalExaminationCerebralPalsyMIDViewDataType {
  // dropDownOptions: {
  //   sensoryOptions: any[];
  // };
  viewData: {
    rangeOfMotion: {
      isFull: boolean;
      items: [{ limit: string }];
    };
    // sensory: {
    //   choice: number;
    //   remark: string;
    // };
  };
  readonly: boolean;
}

const CardOTPhysicalExaminationCerebralPalsyMID: FC<CardOTPhysicalExaminationCerebralPalsyMIDViewDataType> = forwardRef(
  (props, ref) => {
    // Range Of Motion
    const subRangeOfMotionRef = useRef();
    // const subSensoryRef = useRef();
    
    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          rangeOfMotion: subRangeOfMotionRef.current.getData(),
          // sensory: subSensoryRef.current.getData(),
        };
      },
    }));

    return (
      <>
        {/* Range of motion */}
        <Grid style={styles.gridBG}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={16}>
                    <Header.Content>
                      <label>Range of motion</label>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.leftPadding}>
              <CardRangeOfMotion
                ref={subRangeOfMotionRef}
                data={props.viewData.rangeOfMotion}
                readonly={props.readonly}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Sensory */}
        {/* <Grid style={styles.gridBG}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={1}></Form.Field>
                  <Form.Field width={15}>
                    <Header.Content>
                      <strong>Sensory</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.noPadding}>
            <CardSensory
                ref={subSensoryRef}
                data={props.viewData.sensory}
                dropDownOptions={props.dropDownOptions}
                readonly={props.readonly}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid> */}
      </>
    );
  }
);

export const CardOTPhysicalExaminationCerebralPalsyMIDInitialViewData: CardOTPhysicalExaminationCerebralPalsyMIDViewDataType = {
  // dropDownOptions: { sensoryOptions: [] },
  viewData: {
    rangeOfMotion: {
      isFull: true,
      items: [{ limit: "" }],
    },
    // sensory: {
    //   choice: null,
    //   remark: "",
    // }
  },
  readonly: false,
  
};

CardOTPhysicalExaminationCerebralPalsyMID.defaultProps = CardOTPhysicalExaminationCerebralPalsyMIDInitialViewData;

export default CardOTPhysicalExaminationCerebralPalsyMID;
