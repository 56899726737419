import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react'

const CardReceiveOrderUX  = (props: any) => {
    return(
      <div
        style={{ width: "100%",padding: "10px" }}>
        <Form>
          <FormGroup
            inline={true}
            style={{display: props.hidePanel ? "none" : ""}}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                {props.nameAuto ? "สเเกนรหัส" : "กรอกรหัส" }
              </label>
              <Input
                fluid={true}
                onChange={props.handleChangeValue("selectedCode")}
                type="text"
                value={props.selectedCode}>
              </Input>
              <Button
                color="green"
                icon="search"
                onClick={props.onhandleSearch}>
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                clearable={true}
                defaultValue="auto"
                onChange={props.handleChangeValue("selectedType")}
                options={props.options}
                search={true}
                selection={true}
                style={{marginLeft: "1rem"}}
                value={props.selectedType}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{display: props.encounter ? "" : "none", paddingTop: "20px"}}>
          
          <Form>
            <FormGroup
              inline={true}
              style={{marginBottom:0}}>
              <FormField
                inline={true}
                width={3}>
                <label
                  style={{minWidth: "max-content", fontWeight: "normal"}}>
                  HN
                </label>
                <Input
                  fluid={true}
                  readOnly={true}
                  value={props.hn}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <label
                  style={{minWidth: "max-content", fontWeight: "normal"}}>
                  ชื่อผู้ป่วย
                </label>
                <Input
                  fluid={true}
                  readOnly={true}
                  value={props.name}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <label
                  style={{minWidth: "max-content", fontWeight: "normal"}}>
                  เพศ
                </label>
                <Input
                  fluid={true}
                  readOnly={true}
                  value={props.gender}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <label
                  style={{minWidth: "max-content", fontWeight: "normal"}}>
                  วันเกิด
                </label>
                <Input
                  fluid={true}
                  readOnly={true}
                  value={props.birthdate}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <label
                  style={{minWidth: "max-content", fontWeight: "normal"}}>
                  อายุ
                </label>
                <Input
                  fluid={true}
                  readOnly={true}
                  value={props.age}>
                </Input>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardReceiveOrderUX 

export const screenPropsDefault = {"height":"40vh","patientSearch":"[Patient Search Box]"}

/* Date Time : Mon Dec 04 2023 15:59:36 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\",padding: \"10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.encounter ? \"\" : \"none\", paddingTop: \"20px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormGroup",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 54,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 54,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nameAuto ? \"สเเกนรหัส\" : \"กรอกรหัส\" "
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Input",
      "parent": 55,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedCode\")"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.selectedCode"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Button",
      "parent": 55,
      "props": {
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "onClick": {
          "type": "code",
          "value": "props.onhandleSearch"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Dropdown",
      "parent": 56,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "defaultValue": {
          "type": "value",
          "value": "auto"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedType\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedType"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Form",
      "parent": 38,
      "props": {
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:0}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "เพศ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเกิด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Input",
      "parent": 63,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.hn"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 64,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.name"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Input",
      "parent": 65,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.gender"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Input",
      "parent": 66,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.birthdate"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 67,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.age"
        }
      },
      "seq": 77,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 20,
  "isMounted": false,
  "memo": false,
  "name": "CardReceiveOrderUX ",
  "project": "IsHealth",
  "screenPropsDefault": {
    "height": "40vh",
    "patientSearch": "[Patient Search Box]"
  },
  "width": 90
}

*********************************************************************************** */
