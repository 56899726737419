/* eslint-disable react/display-name */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Button,
  Dimmer,
  Loader,
  Label,
  List,
  Grid,
  Icon,
  Card,
} from "semantic-ui-react";
import { ErrorMessage } from "../../common";
import Cookies from "js-cookie";
import { injectIntl } from "react-intl";
import moment from "moment";

interface HospitalDetail {
  id: number;
  name: string;
  short_location: string;
  fastest_available_vaccine_date: string;
  fastest_available_vaccine_slots: [];
}

const VCAppointment = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [hospitalList, setHospitalList] = useState<HospitalDetail[]>([]);
  const isMounted = useRef(true);
  const inputRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    const mockHosList = [
      {
        id: 11092,
        short_location: "เขตคลองสาน, กรุงเทพมหานคร",
        fastest_available_vaccine_date: "2023-01-16",
        fastest_available_vaccine_slots: [
          {
            id: 29841,
            label: "05:00-10:00 น.",
          },
          {
            id: 29961,
            label: "05:00-08:00 น.",
          },
          {
            id: 29931,
            label: "05:00-06:00 น.",
          },
          {
            id: 29842,
            label: "05:00-10:00 น.",
          },
          {
            id: 29941,
            label: "05:00-06:00 น.",
          },
          {
            id: 29843,
            label: "05:00-10:00 น.",
          },
          {
            id: 29946,
            label: "05:00-06:00 น.",
          },
          {
            id: 29844,
            label: "05:00-10:00 น.",
          },
          {
            id: 29951,
            label: "05:00-08:00 น.",
          },
          {
            id: 29845,
            label: "05:00-10:00 น.",
          },
          {
            id: 29956,
            label: "05:00-08:00 น.",
          },
          {
            id: 29921,
            label: "05:00-08:00 น.",
          },
          {
            id: 29936,
            label: "07:00-08:00 น.",
          },
        ],
        name: "IsHealth Hospital",
        display_seq: 1,
        is_active: true,
        code: "ISHEALTH",
        name_en: "",
        short_name: "",
        type: 1,
        location:
          "77/104 อาคาร สิน สาธร ชั้น 25 กรุงธนบุรี แขวง คลองต้นไทร เขตคลองสาน กรุงเทพมหานคร 10600",
        is_telemed: true,
        background_color: null,
        parent: null,
        billing_div: null,
        drug_div: null,
        supply_div: null,
        cost_center: null,
        storage: null,
        area: null,
        departments: [],
      },
      {
        id: 11093,
        short_location: "เขตคลองสาน, กรุงเทพมหานคร",
        fastest_available_vaccine_date: "2023-01-16",
        fastest_available_vaccine_slots: [
          {
            id: 29841,
            label: "05:00-10:00 น.",
          },
          {
            id: 29961,
            label: "05:00-08:00 น.",
          },
          {
            id: 29931,
            label: "05:00-06:00 น.",
          },
          {
            id: 29842,
            label: "05:00-10:00 น.",
          },
          {
            id: 29941,
            label: "05:00-06:00 น.",
          },
          {
            id: 29843,
            label: "05:00-10:00 น.",
          },
          {
            id: 29946,
            label: "05:00-06:00 น.",
          },
          {
            id: 29844,
            label: "05:00-10:00 น.",
          },
          {
            id: 29951,
            label: "05:00-08:00 น.",
          },
          {
            id: 29845,
            label: "05:00-10:00 น.",
          },
          {
            id: 29956,
            label: "05:00-08:00 น.",
          },
          {
            id: 29921,
            label: "05:00-08:00 น.",
          },
          {
            id: 29936,
            label: "07:00-08:00 น.",
          },
        ],
        name: "IsHealth Hospital 2",
        display_seq: 1,
        is_active: true,
        code: "ISHEALTH",
        name_en: "",
        short_name: "",
        type: 1,
        location:
          "77/104 อาคาร สิน สาธร ชั้น 25 กรุงธนบุรี แขวง คลองต้นไทร เขตคลองสาน กรุงเทพมหานคร 10600",
        is_telemed: true,
        background_color: null,
        parent: null,
        billing_div: null,
        drug_div: null,
        supply_div: null,
        cost_center: null,
        storage: null,
        area: null,
        departments: [],
      },
    ];
    setHospitalList(mockHosList);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getVaccineHospital();
  }, [Cookies.get("apiToken"), props.apiToken]);

  const getVaccineHospital = async () => {
    let apiToken = props.apiToken ? props.apiToken : Cookies.get("apiToken");
    let search = inputRef.current.inputRef.current.value;
    setIsLoading(true);
    const [response] = await props.controller.getVaccineHospital({
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      keyword: search,
    });
    if (!isMounted.current) {
      return;
    }
    if (response && response.items) {
      // let options = [];
      // for (let item of response.items) {
      //   options.push({
      //     key: item.id,
      //     text: item.name,
      //     value: item.id
      //   });
      // }
      setHospitalList(response.items);
    } else {
      setHospitalList([]);
    }
    setIsLoading(false);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      getVaccineHospital();
    }
  };

  const createHospitalList = () => {
    return hospitalList.map((item, index) => (
      <List.Item
        key={index}
        className="diag-rule-list-item"
        // onClick={handleDiagRuleSelected(item)}
      >
        <Grid>
          <Grid.Column width={11}>
            <p>{item.name}</p>
          </Grid.Column>
          <Grid.Column width={5}>
            <Label size={"tiny"}>
              <Icon name={"map marker alternate"} color={"red"} />
              {item.division ? item.division.name : "No Location"}
            </Label>
          </Grid.Column>
        </Grid>
      </List.Item>
    ));
  };

  const handleSelectedHospital = (hospital) => {
    const date = moment(hospital.fastest_available_vaccine_date);
    props.goToVaccineAppointmentSlot({
      divisionId: hospital.id,
      divisionName: hospital.name,
      year: date.format("YYYY"),
      month: date.format("MM")
    });
  };

  return (
    <Dimmer.Dimmable>
      {props.header}
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
      <div className="VCAppointment">
        <ErrorMessage error={error} />
        <Input
          ref={inputRef}
          fluid
          icon={"search"}
          iconPosition={"left"}
          placeholder={props.intl.formatMessage({
            id: "appoint.search_placeholder",
          })}
          onKeyDown={handleInputKeyPress}
          loading={isLoading}
        />
        <Card.Group
          style={{
            marginTop: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
          centered
          itemsPerRow={1}
        >
          {hospitalList.length <= 0 ?
          <div
            style={{ marginTop: "35px", display: "flex", justifyContent: "center" }}>
            <div style={{ background: "#f6f7f7", padding: "13px" }}>
              ไม่พบข้อมูล
            </div>
          </div> : null}
          {hospitalList.map((hos) => {
            return (
              <Card
                onClick={(event) => {
                  handleSelectedHospital(hos);
                }}
                style={{
                  backgroundColor: "#E6F7F7",
                }}
              >
                <Card.Content>
                  <Icon
                    style={{ padding: "5px", float: "left" }}
                    name="hospital"
                    color="blue"
                    size="big"
                  />
                  <Card.Header style={{ color: "#004884" }}>
                    {hos.name}
                  </Card.Header>
                  <Card.Meta>
                    <Icon
                      style={{ paddingRight: "5px" }}
                      name="map marker alternate"
                      color="grey"
                      size="small"
                    />
                    {hos.short_location}
                  </Card.Meta>
                  <Card.Description>
                    <strong>
                      {`คิวว่างเร็วที่สุด ${hos.fastest_available_vaccine_date ? hos.fastest_available_vaccine_date:"-"}`}
                    </strong>
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  {hos.fastest_available_vaccine_slots.map((slot) => {
                    return (
                      <Button basic color="blue" size="mini">
                        {slot.label}
                      </Button>
                    );
                  })}
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      </div>
    </Dimmer.Dimmable>
  );
});

VCAppointment.propTypes = {
  controller: PropTypes.object,
  goToVaccineAppointmentSlot: PropTypes.func,
  apiToken: PropTypes.string,
  division: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

VCAppointment.defaultProps = {
  controller: null,
  goToVaccineAppointmentSlot: () => {},
  apiToken: null,
  division: null,
};

export default React.memo(injectIntl(VCAppointment));
