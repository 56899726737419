import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button, CheckboxProps, Popup } from "semantic-ui-react";
import CardHolidayUX from "./CardHolidayUX";
import DateTextBox8 from "react-lib/apps/common/DateTextBox8";
import { beStringToAdString, beStringToDateObject } from "react-lib/utils/dateUtils";
import { getCurrentMonth } from 'HRM/src/common/utils.js';

const customHolidayStyle = { color: "red" };

const initialHolidayItem = {
  isNew: true,
  date: [""],
  // day: "", // obsolete
  // month: "", // obsolete
  detail: "",
  remark: "",
};

const CardHoliday = (props: any) => {
  const [selected, setSelected] = useState<"normal" | "manual">("normal");

  const [holidayItem, setHolidayItem] = useState(Object.assign({}, initialHolidayItem));
  const [error, setError] = useState<string[]>([]);

  useEffect(() => {
    props.onEvent({
      message: "Holiday",
      params: {
        action: "refresh",
      },
    });
  }, [props.selectedDivision]);

  const reset = useCallback(
    () => {
      setHolidayItem(Object.assign({}, initialHolidayItem));
      setError([]);
    },
    [],
  )

  const currentHolidayList = useMemo(() => props.holiday?.items?.map((item: any) => beStringToDateObject(item.date?.[0])), [props.holiday?.items]);

  const holidayList = useMemo(() => {
    const sortItems = (props.holiday?.items || []).sort((a:any, b: any)=>{
      const getMonth = (v: any) => v.date?.[0].split("/")?.[1] || "";
      return getMonth(a) - getMonth(b);
    })

    return sortItems.map((item: any) => ({
      ...item,
      date: item.date?.[0],
      day: parseInt(item.date?.[0]?.split("/")?.[0]),
      month: parseInt(item.date?.[0]?.split("/")?.[1]),
      year: parseInt(item.date?.[0]?.split("/")?.[2]),
      deleteButton: (
        <Button
          size="small"
          color="red"
          onClick={(e: any) => {
            e.stopPropagation();
            props.onEvent({
              message: "Holiday",
              params: {
                action: "delete",
                item: item,
              },
            });
            reset();
          }}
        >
          Delete
        </Button>
      ),
    }));
  }, [props.holiday?.items]);

  const setHolidayField = (field: string) => (e: any) => {
    // Only allow update day and month if this is a new item

    // for "remark" and "detail"
    if (holidayItem.isNew || !["day", "month"].includes(field)) {
      setHolidayItem({
        ...holidayItem,
        [field]: e.target.value,
      });
    }
  };

  const updateHoliday = () => {
    // console.log(holidayItem);
    let newError: string[] = [];

    // ใช้ Calendar แล้ว
    // if (!Number.isInteger(Number(holidayItem.day)) || holidayItem.day.trim() === "") newError.push("day: กรุณากรอกค่าเป็นเลขจำนวนเต็ม");
    // else if ([1, 3, 5, 7, 8, 10, 12].includes(Number(holidayItem.month)) && (Number(holidayItem.day) < 1 || Number(holidayItem.day) > 31)) {
    //   newError.push("day: กรุณากรอกค่าเป็นเลขจำนวนเต็มระหว่าง 1 ถึง 31");
    // } else if ([4, 6, 9, 11].includes(Number(holidayItem.month)) && (Number(holidayItem.day) < 1 || Number(holidayItem.day) > 30)) {
    //   newError.push("day: กรุณากรอกค่าเป็นเลขจำนวนเต็มระหว่าง 1 ถึง 30");
    // } else if ([2].includes(Number(holidayItem.month)) && (Number(holidayItem.day) < 1 || Number(holidayItem.day) > 19)) {
    //   newError.push("day: กรุณากรอกค่าเป็นเลขจำนวนเต็มระหว่าง 1 ถึง 29");
    // }

    // if (!Number.isInteger(Number(holidayItem.month)) || holidayItem.month.trim() === "") newError.push("month: กรุณากรอกค่าเป็นเลขจำนวนเต็ม");
    // else if (Number(holidayItem.month) < 1 || Number(holidayItem.month) > 12) {
    //   newError.push("month: กรุณากรอกค่าเป็นเลขจำนวนเต็มระหว่าง 1 ถึง 12");
    // }
    // setError(newError);

    if (newError.length === 0) {
      props.onEvent({
        message: "Holiday",
        params: {
          action: "update",
          item: holidayItem,
        },
      });
      reset();
    }
  };

  return (
    <>
      <CardHolidayUX
        dateTextBox={
          <DateTextBox8
            onChange={({ string }: any) => {
              let date: string[] = string?.split(",");
              setHolidayItem({
                ...holidayItem,
                date: date,
              });
            }}
            mode="multiple"
            valueList={holidayItem?.date?.map((item: any) => item.replace(/\//g, "-"))}
            // modifiers={{ customHoliday:  }}
            modifiers={{ customHoliday: currentHolidayList }}
            modifiersStyles={{ customHoliday: customHolidayStyle }}
          />
        }
        selected={selected} // normal , manual
        onChangeRadio={(e: React.FormEvent<HTMLInputElement>, { value }: CheckboxProps) => {
          setSelected(value as any);
        }}
        divisionOptions={props.divisionList.map((item: any) => ({
            key: item.id,
            value: item.id,
            text: item.name_code,
          }))}
        selectedDivisionId={props.selectedDivision?.id || -1}
        changeDivision={(e: any, v: any) => {
          const division = props.divisionList.find((item: any) => item.id === v.value);
          props.setProp("selectedDivision", division);
          reset();
        }}
        holidayItems={holidayList}
        rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor: holidayItem.date?.includes(rowInfo?.original?.date) ? "#cccccc" : "#d6ecf3",
          },
          onClick: () => {
            // console.log("rowInfo?.original", rowInfo?.original);
            const { day, month, deleteButton, ...row } = rowInfo?.original;
            // console.log(" holidayItem", { ...row, date: [row.date] });
            setHolidayItem({ ...row, date: [row.date] });
          },
        })}
        // day={holidayItem.day}
        // month={holidayItem.month}
        detail={holidayItem.detail}
        remark={holidayItem.remark}
        setHolidayField={setHolidayField}
        updateHoliday={updateHoliday}
        clearHoliday={() => {
          reset();
        }}
        error={
          error.length > 0 ? (
            <div>
              {error.map((item: string, i: number) => (
                <div key={i}>{item}</div>
              ))}
            </div>
          ) : (
            false
          )
        }
        // config
        disableUpdate={!holidayItem?.date?.[0]}
        hideCopyPaste={true}
        hideRadioGroup={true}
      />
    </>
  );
};

export default CardHoliday;
