import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Modal } from "semantic-ui-react";

// UX
import ModDrugDispensingOrderUX from "./ModDrugDispensingOrderUX";

// Types
type ModDrugDispensingOrderProps = {
  open: boolean;
  totalDispenseUnit: number;
  fullName: string;
  // options
  unitOptions: number;
  routeOptions: number;
  frequencyOptions: number;
  // callback
  onClose: () => any;
  onSave: (data: Partial<DrugDetailType> & { quantity: string }) => any;
} & DrugDetailType;

type DrugDetailType = {
  dose?: string;
  unit: number;
  route: number;
  frequency: number;
};

const ModDrugDispensingOrder = (props: ModDrugDispensingOrderProps) => {
  const [drugDetail, setDrugDetail] = useState<Partial<DrugDetailType>>({});

  // Effect
  useEffect(() => {
    if (props.open) {
      setDrugDetail({
        dose: props.dose,
        unit: props.unit,
        route: props.route,
        frequency: props.frequency,
      });
    }
  }, [props.open]);

  // Callback
  const handleChangeValue = useCallback((e: any, data: any) => {
    setDrugDetail((detail) => {
      let value = data.value;
      // type number value ไม่น้อยกว่า 0
      if (["dose"].includes(data.name)) {
        value = Number(value) < 0 ? 0 : value;
      }

      return { ...detail, [data.name]: value };
    });
  }, []);

  const handleClose = useCallback(() => {
    setDrugDetail({});

    props.onClose();
  }, []);

  const handleSave = useCallback(() => {
    props.onSave({
      ...drugDetail,
      quantity: (parseFloat(drugDetail.dose || "") / props.totalDispenseUnit).toString(),
    });
  }, [drugDetail]);

  // Memo
  const allowSave = useMemo(() => {
    return Object.values(drugDetail).every((value) => value);
  }, [drugDetail]);

  return (
    <Modal open={props.open} size="small">
      <ModDrugDispensingOrderUX
        fullName={props.fullName}
        drugDetail={drugDetail}
        // config
        allowSave={allowSave}
        // options
        unitOptions={props.unitOptions}
        routeOptions={props.routeOptions}
        frequencyOptions={props.frequencyOptions}
        // callback
        onChangeValue={handleChangeValue}
        onClickCancel={handleClose}
        onClickSave={handleSave}
      />
    </Modal>
  );
};

ModDrugDispensingOrder.displayName = "ModDrugDispensingOrder";

export default React.memo(ModDrugDispensingOrder);
