import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button, Input, Modal } from "semantic-ui-react";

import { RowInfo } from "react-table-6";

// UX
import CardFinancialAmountSetUX from "./CardFinancialAmountSetUX";

// Common
import ButtonLoadCheck, { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";
import { ModConfirm } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModCoverageSelect from "./ModCoverageSelect";
import { alignCenter, formatPrice, alignRight } from "react-lib/appcon/common/PureReactTable";

// Interface
import { RunSequence, SetProp, State, PickedProps, ACTIONS } from "./sequence/FinancialAmountSet";

const CARD_FA_SET = "CardFinancialAmountSet";

// Types
type CardFinancialAmountSetProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  FinancialAmountSetSequence: State["FinancialAmountSetSequence"];
  // config
  readOnly?: boolean;
} & PickedProps;

const CardFinancialAmountSet = (props: CardFinancialAmountSetProps) => {
  const [modalConfirm, setModalConfirm] = useState({
    open: false,
    item: {} as Record<string, any>,
  });

  useEffect(() => {
    props.runSequence({
      sequence: "FinancialAmountSet",
      restart: true,
      card: CARD_FA_SET,
      nextIndex: "Action",
    });

    return () => {
      props.runSequence({ sequence: "FinancialAmountSet", clear: true });

      props.setProp(`errorMessage.${CARD_FA_SET}`, null);
    };
  }, []);

  // Memo Callback
  const selectedFinancialAmount = useMemo(() => {
    return props.FinancialAmountSetSequence?.selectedFinancialAmount;
  }, [props.FinancialAmountSetSequence?.selectedFinancialAmount]);

  // Callback
  const handleSelectedReim = useCallback(
    (state: any, rowInfo: RowInfo) => {
      return {
        className:
          selectedFinancialAmount?.id && rowInfo?.original?.id === selectedFinancialAmount?.id
            ? "blueSelectedRow"
            : "",
        onClick: () => {
          if (rowInfo?.original?.id && !props.readOnly) {
            props.runSequence({
              sequence: "FinancialAmountSet",
              action: ACTIONS.SELECT_PATIENT_REIMB,
              data: rowInfo.original,
            });
          }
        },
      };
    },
    [selectedFinancialAmount, props.readOnly]
  );

  const handleOpenModConfirm = useCallback(
    (item: any) => () => {
      setModalConfirm({ open: true, item: { ...item } });
    },
    []
  );

  const handleOnChange = (
    name: keyof Required<State>["FinancialAmountSetSequence"],
    value: any
  ) => {
    if (name === "amount" && (value === "" || value > -1)) {
      props.setProp(`FinancialAmountSetSequence.amount`, value);
    }
    if (name !== "amount") {
      props.setProp(`FinancialAmountSetSequence.${name}`, value);
    }
  };

  const financialAmountList = React.useMemo(() => {
    return (props.financialAmountList || []).map((item) => ({
      ...item,
      coverage_name_text: item.coverage_name,
      payer_name_text: item.payer_name,
      service_type_text: alignCenter(item.service_type),
      start_date_text: alignCenter(item.start_date),
      stop_date_text: alignCenter(item.stop_date),
      max_reimb_text: alignRight(item.max_reimb),
      remaining_balance_text: <div style={{ textAlign: 'right' }}>{item.remaining_balance}</div>,
      _delete: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button icon="minus" color="red" size="mini" onClick={handleOpenModConfirm(item)} />
        </div>
      ),
    }));
  }, [props.financialAmountList]);

  const handleConfirm = () => {
    props.runSequence({
      sequence: "FinancialAmountSet",
      action: ACTIONS.DELETE,
      card: CARD_FA_SET,
      data: { ...modalConfirm.item },
    });

    setModalConfirm({ open: false, item: {} });
  };

  const handleCancel = () => {
    setModalConfirm({ open: false, item: {} });
  };

  const handleOpenModal = () => {
    props.runSequence({
      sequence: "FinancialAmountSet",
      nextIndex: "ModalAction",
      card: CARD_FA_SET,
    });
  };

  const handleSave: BLClickHandler<typeof ACTIONS.SAVE> = (e, data) => {
    props.runSequence({ sequence: "FinancialAmountSet", action: data.name, card: CARD_FA_SET });
  };

  const handleClear = () => {
    props.runSequence({
      sequence: "FinancialAmountSet",
      action: ACTIONS.CLEAR_DATA,
      card: CARD_FA_SET,
    });
  };

  const handleSelectPatientCoverage = () => {
    props.runSequence({
      sequence: "FinancialAmountSet",
      action: ACTIONS.SELECT_PATIENT_COVERAGE,
      card: CARD_FA_SET,
    });
  };

  const handleCloseModCoverage = () => {
    props.runSequence({ sequence: "FinancialAmountSet", action: ACTIONS.CLOSE, card: CARD_FA_SET });
  };

  // #console.log("CardFinancialAmountSet props: ", props);
  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_FA_SET}`, null);
        }}
        error={props.errorMessage?.[CARD_FA_SET]}
        success={null}
      />

      <CardFinancialAmountSetUX
        // data
        // amount={props.FinancialAmountSetSequence?.amount}
        financialAmountData={financialAmountList}
        // config
        readOnly={props.readOnly}
        // function
        onSelectedReim={handleSelectedReim}
        // component
        amount={
          <Input
            onChange={(e, data) => {
              handleOnChange("amount", data.value);
            }}
            value={props.FinancialAmountSetSequence?.amount}
            step={0.01}
            type="number"
          />
        }
        patientCoverageSearch={
          <Input
            readOnly={true}
            style={{ width: "100%" }}
            label={
              <Button
                disabled={selectedFinancialAmount?.id}
                icon="search"
                onClick={handleOpenModal}
              />
            }
            labelPosition="right"
            value={props.FinancialAmountSetSequence?.selectedPatientCoverageDetail}
          />
        }
        buttonSave={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              paramKey={`${CARD_FA_SET}_SAVE`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_FA_SET}_SAVE`]}
              // config
              color={selectedFinancialAmount?.id ? "yellow" : "green"}
              name={ACTIONS.SAVE}
              size="small"
              title={selectedFinancialAmount?.id ? "แก้ไข" : "เพิ่มรายการ"}
            />

            {props.FinancialAmountSetSequence?.selectedFinancialAmount?.id && (
              <Button content="เคลียร์" size="small" onClick={handleClear} />
            )}
          </div>
        }
      />
      <ModConfirm
        openModal={modalConfirm.open}
        titleName="ต้องการยกเลิกใช่หรือไม่ ?"
        onDeny={handleCancel}
        onCloseWithDimmerClick={handleCancel}
        onApprove={handleConfirm}
      />

      <Modal open={props.modCoverageApprove?.open} size="large" closeOnDimmerClick={false}>
        <ModCoverageSelect
          // data
          patientCoverageList={props.modCoverageApprove?.patientCoverageList}
          // function
          selectedPatientCoverage={props.modCoverageApprove?.selectedPatientCoverage}
          setSelectedPatientCoverage={(coverage: any) =>
            props.setProp("modCoverageApprove.selectedPatientCoverage", coverage)
          }
          onClickSelectedPatientCoverage={handleSelectPatientCoverage}
          onClose={handleCloseModCoverage}
        />
      </Modal>
    </>
  );
};

export default CardFinancialAmountSet;
