import React, { useState, useImperativeHandle, ReactElement } from "react";
import { Popup, SemanticCOLORS } from "semantic-ui-react";

type PopupConfig = {
  color?: SemanticCOLORS;
  offset?: number;
  inverted?: boolean;
  position?: any;
};

type DrugIconPopupProps = {
  textContent: string | ReactElement;
  config?: PopupConfig;
  children?: ReactElement;
  open?: boolean;
  onOpen?: Function;
};

type DrugIconPopupRef = {
  setOpen: (open: boolean) => void;
};

const DrugIconPopup = React.forwardRef<any, DrugIconPopupProps>((props, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useImperativeHandle<DrugIconPopupRef, DrugIconPopupRef>(ref, () => ({
    setOpen: setIsOpen,
  }));

  const handleOpen = () => {
    props.onOpen?.();
    setIsOpen(true);
  };

  return (
    <Popup
      open={typeof props.open === "boolean" ? props.open : isOpen}
      style={{ border: "1px solid #97c5e4" }}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent",
        },
      }}
      onOpen={handleOpen}
      onClose={(e, data) => setIsOpen(false)}
      closeOnDocumentClick
      offset={props.config?.offset}
      trigger={props.children}
      wide
      inverted={props.config?.inverted}
      position={props.config?.position || "bottom left"}
      content={
        typeof props.textContent === "string" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: props.textContent,
            }}
          />
        ) : (
          props.textContent
        )
      }
    />
  );
});

DrugIconPopup.displayName = "DrugIconPopup"
export default React.memo(DrugIconPopup);