import { useIntl } from "react-intl";
import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  SyntheticEvent,
  MutableRefObject,
} from "react";
import {
  Input,
  Modal,
  Button,
  Header,
  Segment,
  Icon,
  Dimmer,
  Loader,
  SemanticCOLORS,
  Form,
  Pagination,
} from "semantic-ui-react";
import moment from "moment";
import { Table } from "react-lib/frameworks/Table";
import { ModInfo, ErrorMessage } from "react-lib/apps/common";
import { momentToStringBE } from "react-lib/utils/dateUtils";

type EncounterType = "IPD" | "OPD";

type ModErrorType = {
  open: boolean;
  color?: SemanticCOLORS;
  error: any;
};

type ModalSearchEncounterRef = {
  getText: () => string;
  getId: () => number | undefined;
  getItem: () => any;
  setId: (id: number) => void;
  setText: (text: string) => void;
  setItem: (item: any) => void;
  clear: () => void;
};

type ModalSearchEncounterProps = {
  defaultValue?: string;
  disabled?: boolean;
  fluid?: boolean;
  patientId?: number | null;
  modalSize?: "mini" | "tiny" | "small" | "large" | "fullscreen";
  readOnly?: boolean;
  disabledSearch?: boolean;
  textField?: string;

  // callback
  onGetEncounter: (params: {
    type?: EncounterType;
    patient: number | null;
    number?: number;
    order_by_started?: boolean;
  }) => any;
  onClose?: () => any;
  onSelect?: (row: any) => any;
  onClearInput?: () => any;
};

const ModalSearchEncounterInitial: ModalSearchEncounterProps = {
  defaultValue: "",
  disabled: false,
  fluid: false,
  patientId: null,
  modalSize: "large",
  readOnly: false,
  disabledSearch: false,
  textField: "number",

  // callback
  onGetEncounter: ({}) => [[], null, null],
  onClose: () => null,
  onSelect: () => null,
  onClearInput: () => null,
};

const modErrorInitial: ModErrorType = {
  open: false,
  color: undefined,
  error: null,
};

const ModalSearchEncounter = React.forwardRef<ModalSearchEncounterRef, ModalSearchEncounterProps>(
  (props, ref) => {
    const intl = useIntl();
    const [encounterInput, setEncounterInput] = useState<string>("");

    // loading
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

    // open
    const [openModalSearch, setOpenModalSearch] = useState<boolean>(false);
    const [openModError, setOpenModError] = useState<ModErrorType>(modErrorInitial);
    const [inputId, setInputId] = useState<any>();

    // List
    const [encounterIPDList, setEncounterIPDList] = useState<any[]>([]);
    const [encounterOPDList, setEncounterOPDList] = useState<any[]>([]);
    const [encounterListAll, setEncounterListAll] = useState<any[]>([]);
    const [listAll, setListAll] = useState<any[]>([]);

    // Selected
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [toggleButton, setToggleButton] = useState<EncounterType[]>([]);
    const [activePage, setActivePage] = useState<number>(1);

    const inputRef = useRef() as MutableRefObject<any>;
    const setInputRef = useRef<{
      inputId?: number;
      inputText?: string;
    }>({});
    const limit = 20;

    useImperativeHandle<ModalSearchEncounterRef, ModalSearchEncounterRef>(ref, () => ({
      getText: () => {
        return encounterInput;
      },
      getId: () => {
        return inputId;
      },
      getItem: () => {
        return selectedItem;
      },
      setId: (id) => {
        setInputId(id);
        setInputRef.current = {
          ...setInputRef.current,
          inputId: id,
        };
      },
      setText: (text) => {
        setEncounterInput(text);
        setInputRef.current = {
          ...setInputRef.current,
          inputText: text,
        };
      },
      setItem: (item) => {
        setSelectedItem(item);
      },
      setTextByElement: (text: string) => {
        const elm = document.querySelector(".input-encounter-search")
          ?.childNodes?.[0] as HTMLInputElement;
        if (elm) {
          elm.value = text;
          setInputRef.current = {
            ...setInputRef.current,
            inputText: text,
          };
        }
        setEncounterInput(text);
      },
      clear: () => {
        clearInput();
      },
    }));

    const GetEncounter = async () => {
      const response = await Promise.all([
        props.onGetEncounter({
          type: "IPD",
          patient: props.patientId,
          order_by_started: true,
        }),
        props.onGetEncounter({
          type: "OPD",
          patient: props.patientId,
          order_by_started: true,
        }),
      ]);
      const itemIpd = sortEncounterByStarted(response[0][0] || []);
      const itemOpd = sortEncounterByStarted(response[1][0] || []);
      const all = sortEncounterByStarted([...itemOpd, ...itemIpd]);
      setIsLoading(false);
      setEncounterListAll(all);
      setEncounterIPDList(itemIpd);
      setEncounterOPDList(itemOpd);
      setListAll(all);
    };

    const sortEncounterByStarted = (data: any) => {
      return data.sort((a: any, b: any) => ("" + b.started).localeCompare(a.started));
    };

    const handleOnChangeEncounter = (e: any, { value }: any) => {
      setEncounterInput(value);
    };

    const handleOpenModalSearch = () => {
      if (isLoadingSearch) {
        return;
      }
      setIsLoading(true);
      GetEncounter();
      setOpenModalSearch(true);
      setInputId(setInputRef.current.inputId);
      setEncounterInput(setInputRef.current.inputText || "");
    };

    const handleScannedRowProps = (state: any, rowInfo: any) => {
      return {
        onClick: () => {
          setSelectedRow(rowInfo.original);
        },
        onDoubleClick: () => {
          handleSelect(false, rowInfo.original);
        },
        className: rowInfo?.original && rowInfo?.original?.id === selectedRow?.id ? "selected" : "",
        style: {
          cursor: "pointer",
        },
      };
    };

    const handleToggleButton = (type: EncounterType) => {
      setSelectedRow(null);

      if (toggleButton.includes(type)) {
        setToggleButton([]);
        setEncounterListAll(listAll);
        return;
      }

      switch (type) {
        case "IPD":
          setEncounterListAll(encounterIPDList);
          setToggleButton(["IPD"]);
          break;
        case "OPD":
          setEncounterListAll(encounterOPDList);
          setToggleButton(["OPD"]);
          break;
        default:
          break;
      }
    };

    const handleSelect = (isClose = false, setRow = null) => {
      const row = setRow ? setRow : selectedRow;
      if (!isClose) {
        setEncounterInput(row?.[props.textField || ""]);
        setInputId(row?.id);
        setSelectedItem(row);
        props.onSelect?.(row);
        setInputRef.current = {
          inputId: row?.id,
          inputText: row?.[props.textField || ""],
        };
      } else {
        setEncounterInput(encounterInput.trim());
        setInputId(inputId);
      }
      setSelectedRow(null);
      setOpenModalSearch(false);
      setToggleButton([]);
    };

    const clearInput = () => {
      setEncounterInput("");
      setInputId(null);
      setEncounterListAll([]);
      setSelectedItem(null);
      setToggleButton([]);
      setInputRef.current = {};
    };

    const handleClearInput = () => {
      clearInput();
      props.onClearInput?.();
    };

    const handleOnKeyPressEncounter = async (ev: any) => {
      let encounter = ev.target.value;
      if (ev.key === "Enter") {
        ev.preventDefault();
        setIsLoadingSearch(true);
        const [res, error] = await props.onGetEncounter({
          number: encounter,
          patient: props.patientId,
          order_by_started: true,
        });
        setIsLoadingSearch(false);
        if (error) {
          setOpenModError({
            open: true,
            error,
          });
          return;
        }
        if (res.length > 1) {
          setOpenModError({
            open: true,
            error: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div>{intl.formatMessage({ id: "cukey343" })} </div>
                <div style={{ marginTop: "5px" }}>
                  {intl.formatMessage({ id: "cukey248" })} <Icon name="search" />{" "}
                  {intl.formatMessage({ id: "cukey449" })}
                </div>
              </div>
            ),
            color: "blue",
          });
          setInputId(setInputRef.current.inputId);
          setEncounterInput(setInputRef.current.inputText || "");
          return;
        } else if (res.length === 1) {
          const [data] = res;
          setEncounterInput(data?.[props.textField || ""]);
          setInputId(data?.id);
          setSelectedItem(data);
          props.onSelect?.(data);
          setInputRef.current = {
            inputId: data?.id,
            inputText: data?.[props.textField || ""],
          };
        } else {
          handleOpenModalSearch();
        }
      }
    };

    const handleCloseModInfo = () => {
      setOpenModError(modErrorInitial);
    };

    const handlePageChange = (event: SyntheticEvent, data: any) => {
      setActivePage(data.activePage);
    };

    console.log(inputId, encounterInput);
    return (
      <React.Fragment>
        <Input
          className="input-encounter-search"
          ref={inputRef}
          value={encounterInput === "" ? props.defaultValue : encounterInput}
          disabled={props.disabled}
          onChange={handleOnChangeEncounter}
          action
          fluid={props.fluid}
          onKeyPress={handleOnKeyPressEncounter}
          readOnly={props.readOnly}
        >
          <input />
          {inputId ? (
            <Icon
              style={{
                position: "absolute",
                right: "42px",
                top: "9px",
                zIndex: 1,
                cursor: "pointer",
              }}
              name="close"
              onClick={handleClearInput}
            />
          ) : null}
          <Button
            icon="search"
            onClick={handleOpenModalSearch}
            loading={isLoadingSearch}
            disabled={props.disabledSearch}
          />
        </Input>
        <Modal
          className="modal-search-encounter"
          open={openModalSearch}
          size={props.modalSize}
          onClose={() => handleSelect(true)}
        >
          <Segment>
            <Dimmer active={isLoading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "-4px 0 8px",
              }}
            >
              <Header as="h3" style={{ fontWeight: "bold", margin: "0 40px 0 0" }}>
                {intl.formatMessage({ id: "cukey221" })}
              </Header>
              <Button
                color={toggleButton.includes("OPD") ? "teal" : "blue"}
                size="small"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  handleToggleButton("OPD");
                }}
              >
                {intl.formatMessage({ id: "cukey137" })}
              </Button>
              <Button
                color={toggleButton.includes("IPD") ? "teal" : "blue"}
                size="small"
                onClick={() => {
                  handleToggleButton("IPD");
                }}
              >
                {intl.formatMessage({ id: "cukey101" })}
              </Button>
              <div style={{ flex: 1 }} />
              <Icon
                name="close"
                size="large"
                color="red"
                link={true}
                style={{ margin: "auto" }}
                onClick={() => handleSelect(true)}
              />
            </div>
            <Table
              columns={columnsEncounter}
              data={encounterListAll}
              defaultPageSize="5"
              getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
                return handleScannedRowProps(state, rowInfo);
              }}
              showPagination={false}
              style={{ height: "300px" }}
            ></Table>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "baseline",
              }}
            >
              <Pagination
                activePage={activePage}
                boundaryRange={0}
                onPageChange={handlePageChange}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={0}
                size="small"
              />
              <Button
                color="green"
                style={{ margin: "1.25rem 0 0 1rem" }}
                onClick={() => handleSelect(false)}
              >
                {intl.formatMessage({ id: "cukey457" })}
              </Button>
            </div>
          </Segment>
        </Modal>

        <ModInfo
          open={openModError.open}
          titleColor={openModError.color || "red"}
          key="modError"
          onApprove={handleCloseModInfo}
          onClose={handleCloseModInfo}
        >
          {Object.prototype.toString.call(openModError.error) === "[object Object]" ? (
            <ErrorMessage error={openModError.error} />
          ) : typeof openModError.error === "string" ? (
            <div>{openModError.error}</div>
          ) : (
            openModError.error?.()
          )}
        </ModInfo>
      </React.Fragment>
    );
  }
);

ModalSearchEncounter.defaultProps = ModalSearchEncounterInitial;

export default React.memo(ModalSearchEncounter);

const columnsEncounter = [
  {
    Header: "ชื่อผู้ป่วย",
    accessor: " ",
    minwidth: 80,
    Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.original?.patient_name}</div>,
  },
  {
    Header: "ประเภทผู้ป่วย",
    accessor: " ",
    minWidth: 80,
    Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.original?.type}</div>,
  },
  {
    Header: "VN/AN",
    accessor: " ",
    width: 100,
    Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.original?.number}</div>,
  },
  {
    Header: "คลินิก",
    accessor: " ",
    minWidth: 80,
    Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.original?.division_name}</div>,
  },
  {
    Header: "ทันตแพทย์",
    accessor: " ",
    minWidth: 80,
    Cell: (row: any) => <div style={{ textAlign: "center" }}>{row.original?.doctor_name}</div>,
  },
  {
    Header: "Date-Time",
    accessor: " ",
    minWidth: 80,
    Cell: (row: any) => (
      <div style={{ textAlign: "center" }}>
        {momentToStringBE(moment(row.original?.started))}
      </div>
    ),
  },
];
