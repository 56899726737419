import React, { useMemo } from "react";
import CardSettingTimeOperatingRoomUX from "./CardSettingTimeOperatingRoomUX";

import TimeComboBox from "../../common/TimeComboBox";
import { DateTextBox } from "../../common";

const DAY_ITEMS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const CardSettingTimeOperatingRoom = (props: any) => {
  const handleChangeDate = (name: string) => (date: string) => {
    props.onChangeValue(null, { name, value: date });
  };

  const configEveryWeek = () => {
    const config: Record<string, boolean> = {};
    for (const day of DAY_ITEMS) {
      config[`disabled${day}`] = !(
        props.data?.everyday === false && props.data?.active_day?.includes(day)
      );
    }

    return config;
  };

  console.log(configEveryWeek());

  return (
    <>
      <CardSettingTimeOperatingRoomUX
        // data
        allHour={props.data?.all_hour}
        selectDay={props.data?.select_day}
        everyday={props.data?.everyday}
        repeat={props.data?.repeat}
        // callback
        onChangeValue={props.onChangeValue}
        onCancel={props.onCancel}
        onSave={props.onSave}
        // config
        config={{
          enabledRepeat: props.data?.repeat,
          disabledSave: !(
            props.data?.start_time &&
            props.data?.end_time &&
            props.data?.start_date &&
            props.data?.end_date
          ),
          ...configEveryWeek(),
        }}
        // Element
        DateTextBoxStart={
          <DateTextBox
            value={props.data?.start_date || ""}
            formatDate={(date: string) => {
              console.log(date);
              return "";
            }}
            onChange={handleChangeDate("start_date")}
          />
        }
        DateTextBoxEnd={
          <DateTextBox
            value={props.data?.end_date || ""}
            onChange={handleChangeDate("end_date")}
          />
        }
        TimeTextBoxStart={
          <TimeComboBox
            defaultValue={props.data?.start_time || ""}
            onTextChange={handleChangeDate("start_time")}
            disabled={props.data?.all_hour}
            style={{ width: "100%" }}
          />
        }
        TimeTextBoxEnd={
          <TimeComboBox
            defaultValue={props.data?.end_time || ""}
            onTextChange={handleChangeDate("end_time")}
            disabled={props.data?.all_hour}
            style={{ width: "100%" }}
          />
        }
      />
    </>
  );
};

export default CardSettingTimeOperatingRoom;
