import React, { useRef, useEffect, useState, MutableRefObject, useMemo } from "react";
import { CardLayout, DateTextBox } from "react-lib/apps/common";
import {
  Button,
  Checkbox,
  Dimmer,
  Dropdown,
  Form,
  Icon,
  Input,
  Item,
  Label,
  Loader,
  Modal,
} from "semantic-ui-react";
import CardNursingDiagnosisUX from "./CardNursingDiagnosisUX";
import CardNursingDiagnosisEditUX from "./CardNursingDiagnosisEditUX";
import CardNursingDiagnosisSearchUX from "./CardNursingDiagnosisSearchUX";
import CardNursingDiagnosisTemplateUX from "./CardNursingDiagnosisTemplateUX";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import moment from "moment";

import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import { formatDate } from "react-lib/utils";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const CardNursingDiagnosis = (props: any) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const [checkBox, setCheckBox] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (
      [
        props.buttonLoadCheck?.["CardNursingDiagnosisEdit_save"],
        props.buttonLoadCheck?.["CardNursingDiagnosisEdit_update"],
      ].includes("SUCCESS")
    ) {
      setOpenCreate(false);
      setOpenEdit(false);
    }
  }, [props.buttonLoadCheck]);

  useEffect(() => {
    if (
      checkBox.length === props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.results.length
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [checkBox]);

  useEffect(() => {
    props.runSequence({ sequence: "NursingDiagnosis", action: "getData", card: "CardNursingDiagnosis_search" });
    setOpenEdit(false);
    setOpenCreate(false);
  }, []);

  useEffect(() => {
    props.runSequence({
      sequence: "NursingDiagnosis",
      action: "templateForm",
    });
  }, [props.NursingDiagnosisSequence?.nursingDiagnosis?.templateID]);

  useEffect(() => {
    // if (
    //   props.errorMessage?.NursingDiagnosis !== null &&
    //   props.errorMessage?.NursingDiagnosis !== undefined
    // ) {
    //   setTimeout(() => {
    //     props.runSequence({
    //       sequence: "NursingDiagnosis",
    //       action: "clear",
    //     });
    //   }, 2000);
    // }
    if (
      props.successMessage?.NursingDiagnosis !== null &&
      props.successMessage?.NursingDiagnosis !== undefined &&
      props.NursingDiagnosisSequence?.selectedEncouter === null
    ) {
      props.runSequence({
        sequence: "NursingDiagnosis",
        action: "clear",
      });
    }
  }, [props.successMessage?.NursingDiagnosis, props.errorMessage?.NursingDiagnosis]);

  const splitCreate = (created: any) => {
    var day = created.split("<");
    var time = created.split(">");
    return `${day[0]}  ${time[1]}`;
  };

  const nursingDiagnosisSelected = useMemo(
    () =>
      props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.results?.map(
        (item: any, idx: any) => {
          return {
            ...item,
            createdDate: (
              <div style={{ display: "inline", justifyContent: "center" }}>
                {splitCreate(item?.created) || ""}
              </div>
            ),
            menu: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="yellow"
                  content="Edit"
                  size="mini"
                  onClick={async () => {
                    await props.runSequence({
                      sequence: "NursingDiagnosis",
                      action: "selectEdit",
                      idx: idx,
                    });
                    setOpenEdit(true);
                    setOpenCreate(false);
                  }}
                />
                <Button
                  color="red"
                  content="Delete"
                  size="mini"
                  onClick={async () => {
                    await props.runSequence({
                      sequence: "NursingDiagnosis",
                      action: "delete",
                      idx: idx,
                    });
                  }}
                />
              </div>
            ),
          };
        }
      ),
    [props.NursingDiagnosisSequence, props.NursingDiagnosisSequence?.nursingDiagnosisSelected]
  );

  const DataTemplate = (itemData: any, idx: number, index: number) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "0px 0px 10px 10px",
        }}
      >
        {itemData?.type === "CHECKBOX" ? (
          <Checkbox
            checked={itemData?.value}
            onChange={handleChangeCheck(idx, index)}
            name="items"
          />
        ) : itemData?.type === "DATE_TEXTBOX" ? (
          <DateTextBox
            value={itemData?.value}
            onChange={handleChangeDate(idx, index)}
            name="items"
          />
        ) : itemData?.type === "LABEL" ? (
          <label style={{ paddingLeft: "10px" }}>{itemData?.value}</label>
        ) : (
          ""
        )}
        {itemData?.type !== "TEXTBOX" ? (
          <label style={{ paddingLeft: "10px" }}>{itemData?.name}</label>
        ) : (
          <div>
            <label style={{ paddingRight: "10px" }}>{itemData?.name}</label>
            <Input
              checked={itemData?.value}
              onChange={handleChangeCheck(idx, index)}
              name="items"
            />
          </div>
        )}
        <br />
      </div>
    );
  };

  const handleChangeCheck = (pIndex: number, cIndex: number) => (e: any, v: any) => {
    props.setProp(
      `NursingDiagnosisSequence.nursingDiagnosistemplateQuestionsList.${pIndex}.${v.name}.${cIndex}.value`,
      v.type === "checkbox" ? v.checked : v.value
    );
  };

  const handleChangeDate = (pIndex: number, cIndex: number) => (date: any, v: any) => {
    props.setProp(
      `NursingDiagnosisSequence.nursingDiagnosistemplateQuestionsList.${pIndex}.${v.name}.${cIndex}.value`,
      date
    );
  };

  const handleChangeEndDate = (pIndex: number) => (date: any) => {
    console.log("date", date);
    props.runSequence({
      sequence: "NursingDiagnosis",
      action: "handleChange",
      pIndex,
      date,
    });
    // props.setProp(
    //   `NursingDiagnosisSequence.nursingDiagnosistemplateQuestionsList.${pIndex}.end_date`,
    //   date
    // );
  };

  const nursingDiagnosistemplateQuestionsList = useMemo(
    () =>
      props.NursingDiagnosisSequence?.nursingDiagnosistemplateQuestionsList?.map(
        (item: any, idx: number) => {
          return {
            diagnosis: (
              <div style={{ display: "inline", justifyContent: "center" }}>
                <div style={{ padding: "10px 0px 20px 10px", fontWeight: "bold" }}>
                  {item?.name}
                </div>

                {item?.items.map((itemData: any, index: number) => {
                  if (itemData?.column === 1) {
                    return DataTemplate(itemData, idx, index);
                  }
                })}
              </div>
            ),
            plan: (
              <div style={{ display: "inline", justifyContent: "center" }}>
                <div style={{ paddingTop: "40px" }}>
                  {item?.items.map((itemData: any, index: number) => {
                    if (itemData?.column === 2) {
                      return DataTemplate(itemData, idx, index);
                    }
                  })}
                </div>
              </div>
            ),
            goal: (
              <div style={{ display: "inline", justifyContent: "center" }}>
                <div style={{ paddingTop: "40px" }}>
                  {item?.items.map((itemData: any, index: number) => {
                    if (itemData?.column === 3) {
                      return DataTemplate(itemData, idx, index);
                    }
                  })}
                </div>
              </div>
            ),
            end_date: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                  // position: "absolute",
                  minHeight: "300px",
                }}
              >
                <DateTextBox
                  value={item?.end_date}
                  onChange={handleChangeEndDate(idx)}
                ></DateTextBox>
              </div>
            ),
          };
        }
      ),
    [props.NursingDiagnosisSequence?.nursingDiagnosistemplateQuestionsList]
  );
  console.log(
    "nursingDiagnosistemplateQuestionsList",
    props.NursingDiagnosisSequence?.nursingDiagnosistemplateQuestionsList
  );

  const nursingDiagnosisList = useMemo(
    () =>
      props.NursingDiagnosisSequence?.nursingDiagnosisList
        ?.filter((acc: any) => acc?.results?.length !== 0)
        ?.map((item: any, idx: number) => {
          return {
            ...item,
            edit_user_name: (
              <div style={{ display: "inline", justifyContent: "center" }}>
                {item?.results?.[0]?.edit_user_name || "-"}
              </div>
            ),
            createdDate: (
              <div style={{ display: "inline", justifyContent: "center" }}>
                {splitCreate(item?.created)}
              </div>
            ),
          };
        }),
    [props.NursingDiagnosisSequence?.nursingDiagnosisList]
  );

  const changeNursingDiagnosis = (key: string, value: any) => (e: any, v: any) =>
    props.setProp(`NursingDiagnosisSequence.nursingDiagnosis.${key}`, e.target.value);

  const changeNursingDiagnosisSearch = (key: string) => (e: any, v: any) =>
    props.setProp(`NursingDiagnosisSequence.nursingDiagnosisSearch.${key}`, v.value);

  const changeDate = (key: string) => (date: any) =>
    props.setProp(`NursingDiagnosisSequence.nursingDiagnosis.${key}`, date);

  const changeDateSearch = (key: string) => (date: any) =>
    props.setProp(`NursingDiagnosisSequence.nursingDiagnosisSearch.${key}`, date);

  const nursing = props.NursingDiagnosisSequence?.nursingDiagnosis;
  const nursingSearch = props.NursingDiagnosisSequence?.nursingDiagnosisSearch;

  const onCheckedAll = () => {
    setCheckAll(!checkAll);
    if (!checkAll) {
      let stateItem: any = [];
      props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.results?.map(
        (item: any, idx: any) => {
          stateItem.push(item.id);
        }
      );
      setCheckBox(stateItem);
    } else {
      setCheckBox([]);
    }
  };

  const handleCheck = (e: any, checked: any, row: any) => {
    var checkBoxArray = [...checkBox];
    if (checked) {
      checkBoxArray = [...checkBox, row.original.id];
    } else {
      checkBoxArray.splice(checkBox.indexOf(row.original.id), 1);
    }
    setCheckBox(checkBoxArray);
  };

  const columns_nursingDiagnosisList: any[] = [
    {
      Header: "",
      accessor: "checked",
      width: 60,
      Cell: (row: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={checkBox.includes(row.original.id)}
              onChange={(e, { checked }) => handleCheck(e, checked, row)}
            />
          </div>
        );
      },
    },
    {
      Header: "วันที่",
      accessor: "createdDate",
      width: 135,
    },
    {
      Header: "ข้อวินิจฉัยทางการพยาบาล",
      accessor: "diagnosis",
    },
    {
      Header: "แผนการพยาบาล",
      accessor: "plan",
    },
    {
      Header: "ผลลัพธ์ที่คาดหวัง",
      accessor: "goal",
    },
    {
      Header: "วันที่สิ้นสุดปัญหา",
      accessor: "end_date",
      width: 135,
    },
    {
      Header: "ชื่อผู้บันทึก",
      accessor: "edit_user_name",
    },
    {
      Header: "",
      accessor: "menu",
    },
  ];

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.runSequence({
            sequence: "NursingDiagnosis",
            action: "clear",
          });
        }}
        error={props.errorMessage?.NursingDiagnosis}
        success={props.successMessage?.NursingDiagnosis}
      />

      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
      />

      <Modal onClose={() => setOpenModal(false)} open={openModal} size={"large"}>
        <Dimmer.Dimmable>
          <Dimmer active={props.NursingDiagnosisSequence?.nursingDiagnosisLoading} inverted>
            <Loader />
          </Dimmer>
          <CardNursingDiagnosisSearchUX
            nursingDiagnosisSearch={nursingDiagnosisList || []}
            divisionOptions={props.masterOptions?.division || []}
            division={nursingSearch?.division || ""}
            start_date={nursingSearch?.start_date || formatDate(moment())}
            end_date={nursingSearch?.end_date || formatDate(moment())}
            changeNursingDiagnosis={changeNursingDiagnosisSearch}
            changeDate={changeDateSearch}
            onSearch={(e: any) => {
              props.runSequence({
                sequence: "NursingDiagnosis",
                action: "search",
              });
            }}
            rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
              style: {
                backgroundColor:
                  props.NursingDiagnosisSequence?.nursingDiagnosisSelected &&
                  rowInfo?.original?.id &&
                  rowInfo?.original?.id ===
                    props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.id
                    ? "#d6ecf3"
                    : "white",
              },
              onClick: async () => {
                await props.runSequence({
                  sequence: "NursingDiagnosis",
                  action: "select",
                  item: rowInfo?.original,
                });
                setOpenModal(false);
              },
            })}
          />
        </Dimmer.Dimmable>
      </Modal>
      <Modal
        onClose={() => setOpenModalTemplate(false)}
        open={openModalTemplate}
        size={"fullscreen"}
        style={{ marginLeft: "2%" }}
      >
        <CardNursingDiagnosisTemplateUX
          nursingDiagnosisTemplate={nursingDiagnosistemplateQuestionsList || []}
          changeNursingDiagnosis={(key: string) => (e: any, v: any) => {
            props.setProp(`NursingDiagnosisSequence.nursingDiagnosis.${key}`, v.value);
          }}
          templateID={nursing?.templateID || ""}
          templateOptions={props.NursingDiagnosisSequence?.nursingDiagnosisTemplateOptions || []}
          onSave={(e: any) => {
            props.runSequence({
              sequence: "NursingDiagnosis",
              action: "saveTemplate",
            });
            setOpenModalTemplate(false);
          }}
        />
      </Modal>

      <CardLayout titleText={"Nursing Diagnosis"} toggleable={true} closeable={true}>
        <CardNursingDiagnosisUX
          columns_nursingDiagnosisList={columns_nursingDiagnosisList}
          checked={checkAll}
          onChangeChecked={onCheckedAll}
          nursingDiagnosisList={nursingDiagnosisSelected || []}
          // nursingDiagnosisID={
          //   <Input
          //     value={
          //       props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.id ||
          //       "NEW"
          //     }
          //     disabled={true}
          //     style={{ border: "solid 1px rgb(200, 200, 200, 0.5)" }}
          //   />
          // }
          onClickPrint={() => {
            console.log("print clicked");
            props.runSequence({
              sequence: "NursingDiagnosis",
              action: "print",
              diagResults: checkBox,
            });
          }}
          onTemplate={() => {
            props.runSequence({
              sequence: "NursingDiagnosis",
              action: "templateList",
            });
            setOpenModalTemplate(true);
          }}
          addButton={
            <Button
              icon
              circular
              color="yellow"
              onClick={() => {
                props.runSequence({
                  sequence: "NursingDiagnosis",
                  action: "add",
                });

                setOpenCreate(true);
                setOpenEdit(false);
              }}
            >
              <Icon name="add" />
            </Button>
          }
          searchButton={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "NursingDiagnosis",
                  action: "search",
                });
                setOpenModal(true);
              }}
              // data
              paramKey={`CardNursingDiagnosis_search}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`CardNursingDiagnosis_search`]}
              // config
              color="blue"
              size="small"
              title={"ค้นหา"}
            />
          }
        />
        {(openCreate || openEdit) && (
          <CardLayout
            titleText={"เพิ่ม/แก้ไขข้อมูล Nursing Diagnosis"}
            toggleable={false}
            closeable={true}
            onClose={() => {
              setOpenCreate(false);
              setOpenEdit(false);
            }}
            style={{ marginTop: "10px" }}
          >
            <CardNursingDiagnosisEditUX
              diagnosis={nursing?.diagnosis || ""}
              plan={nursing?.plan || ""}
              goal={nursing?.goal || ""}
              end_date={nursing?.end_date || ""}
              changeNursingDiagnosis={changeNursingDiagnosis}
              changeDate={changeDate}
              buttonSave={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={() => {
                    props.runSequence({
                      sequence: "NursingDiagnosis",
                      action: !!openEdit ? "update" : "save",
                      card: "CardNursingDiagnosisEdit",
                    });
                  }}
                  // data
                  paramKey={`CardNursingDiagnosisEdit_${!!openEdit ? "update" : "save"}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[
                      `CardNursingDiagnosisEdit_${!!openEdit ? "update" : "save"}`
                    ]
                  }
                  // config
                  color={!!openEdit ? "yellow" : "green"}
                  name={"SAVE"}
                  size="small"
                  title={!!openEdit ? "EDIT" : "SAVE"}
                />
              }
            />
          </CardLayout>
        )}
      </CardLayout>
    </div>
  );
};

export default CardNursingDiagnosis;
