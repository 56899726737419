import React, { useCallback, useMemo } from 'react'
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { FORM_DATA_ACTION } from "./sequence/PreOperation";
/* ------------------------------------------------------ */

/*                    PreOPButtonAction                   */

/* ------------------------------------------------------ */

type PreOPButtonActionProps = {
  setProp: any;
  // data
  data?: Record<string, any>;
  type: "confirm" | "save" | "unconfirm";
  cardKey: string;
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClick: Function;
};

export const PreOPButtonAction = (props: PreOPButtonActionProps) => {
  const status = useMemo(() => {
    return props.data?.[props.cardKey]?.status as "" | "DRAFT" | "CONFIRMED";
  }, [props.data]);

  const show = useCallback(() => {
    return {
      confirm:
        props.data?.[props.cardKey]?.allowed_actions?.includes(
          FORM_DATA_ACTION.CONFIRM
        ) && status !== "CONFIRMED",
      save:
        (props.data?.[props.cardKey]?.id &&
          props.data?.[props.cardKey]?.allowed_actions?.includes(
            FORM_DATA_ACTION.SAVE
          ) &&
          status !== "CONFIRMED") ||
        !props.data?.[props.cardKey]?.id,
      unconfirm: props.data?.[props.cardKey]?.allowed_actions?.includes(
        FORM_DATA_ACTION.UNCONFIRM
      ),
    }[props.type];
  }, [props.type, props.data, props.cardKey, status]);

  const config = useMemo(() => {
    console.log(status, props.type);
    return {
      confirm: {
        title: "Confirm",
        color: "green",
        action: "CONFIRM",
      },
      save: {
        title: status === "DRAFT" ? "Edit" : "Save",
        color: status === "DRAFT" ? "yellow" : "green",
        action: "SAVE",
        show: true,
      },
      unconfirm: {
        title: "Unconfirm",
        color: "red",
        action: "UNCONFIRM",
        show: true,
      },
    }[props.type];
  }, [props.type, status]);

  const handleClick = (e: any) => {
    props.onClick?.(e, {
      name: `${props.cardKey}_${FORM_DATA_ACTION[config.action]}`,
      card: props.cardKey,
    });
  };

  return (
    <ButtonLoadCheck
      // function
      setProp={props.setProp}
      onClick={handleClick}
      // data
      paramKey={`${props.cardKey}_${FORM_DATA_ACTION[config.action]}`}
      buttonLoadCheck={
        props.buttonLoadCheck?.[
          `${props.cardKey}_${FORM_DATA_ACTION[config.action]}`
        ]
      }
      // config
      color={config.color}
      size="small"
      style={{
        display: show() ? "" : "none",
      }}
      title={config.title}
    />
  );
};

export default PreOPButtonAction