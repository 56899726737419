import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  TimeFreeTextBox24,
  DateTextBox
} from 'react-lib/apps/common'

const CardReAssessmentUX = (props: any) => {
    return(
      <div
        style={{paddingBottom: "2rem"}}>
        <div
          style={{ display: "flex", justifyContent: "center",  fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "15px", margin: "0px 0px 20px" }}>
          การประเมินความเจ็บปวด
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{  fontWeight: "bold" ,marginRight:"20px"  }}>
              
              <div>
                ความเจ็บปวด :
              </div>
            </div>
            <div
              style={{display: "flex"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.painStatus === "ปวด"}
                label="ปวด"
                name="painStatus"
                onChange={props.onChangeData}
                style={{ display: "flex", alignItems: "center" ,marginRight:"20px"   }}
                value="ปวด">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.painStatus === "ไม่ปวด"}
                label="ไม่ปวด"
                name="painStatus"
                onChange={props.onChangeData}
                style={{ display: "flex", alignItems: "center" ,marginRight:"20px"   }}
                value="ไม่ปวด">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ marginRight:"20px"  }}>
              
              <div>
                ประเมินระดับความเจ็บปวด :
              </div>
            </div>
            <div
              style={{  display: "flex"}}>
              
              <Dropdown
                clearable={true}
                name="assessPainLevel"
                onChange={props.onChangeData}
                options={props.masterOptions?.pain}
                selection={true}
                style={{ width: "30%" }}
                value={props.ReAssessmentSequence?.assessPainLevel}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "100%", display: "flex"}}>
              
              <div
                style={{ display: "flex", alignItems: "center" , marginRight: "10px" }}>
                ระดับความปวด :
              </div>
              <Dropdown
                clearable={true}
                name="painLevel"
                onChange={props.onChangeData}
                options={props.reAssessmentOptions?.painLevel}
                selection={true}
                style={{ width: "10%", marginRight: "20px" }}
                value={props.ReAssessmentSequence?.painLevel}>
              </Dropdown>
              <div
                style={{ marginRight: "10px" , display: "flex", alignItems: "center"  }}>
                บริเวณที่ปวด :
              </div>
              <Dropdown
                clearable={true}
                name="painArea"
                onChange={props.onChangeData}
                options={props.masterOptions?.location}
                selection={true}
                style={{ width: "10%", marginRight: "20px" }}
                value={props.ReAssessmentSequence?.painArea}>
              </Dropdown>
              <div
                style={{ marginRight: "10px" , display: "flex", alignItems: "center"  }}>
                ลักษณะการปวด :
              </div>
              <Dropdown
                clearable={true}
                name="painNature"
                onChange={props.onChangeData}
                options={props.masterOptions?.characteristic}
                selection={true}
                style={{ width: "10%" }}
                value={props.ReAssessmentSequence?.painNature}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ marginRight:"20px" }}>
              
              <div>
                การให้การพยาบาลผู้ป่วย :
              </div>
            </div>
            <div
              style={{display: "flex"}}>
              
              <Dropdown
                clearable={true}
                name="sensesNursingCare"
                onChange={props.onChangeData}
                options={props.reAssessmentOptions?.sensesNursingCareOptions}
                selection={true}
                style={{ width: "30%" }}
                value={props.ReAssessmentSequence?.sensesNursingCare}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "right", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ display: "flex", alignItems: "center", margin: "0px 20px"  }}>
              ประเมินวันที่
            </div>
            <DateTextBox
              onChange={props.onChangDatePain}
              value={props.ReAssessmentSequence?.painData?.saveData}>
            </DateTextBox>
            <div
              style={{ display: "flex", alignItems: "center", margin: "0px 20px"  }}>
              เวลา
            </div>
            <TimeFreeTextBox24
              autoFocus={false}
              onChange={props.onChangTimePain}
              value={props.ReAssessmentSequence?.painData?.saveTime}>
            </TimeFreeTextBox24>
            <div
              style={{ margin: "0px 20px" }}>
              {props?.buttonSavePain}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "15px", margin: "30px 0px 20px" }}>
          การประเมินความเสี่ยงต่อการพลัดตกหกล้ม
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "100%", display: "flex"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.riskFall === "ไม่มีความเสี่ยง"}
                label="ไม่มีความเสี่ยง"
                name="riskFall"
                onChange={props.onChangeData}
                style={{display: "flex", alignItems: "center" ,marginRight:"20px" }}
                value="ไม่มีความเสี่ยง">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.riskFall === "มีความเสี่ยง"}
                label="มีความเสี่ยง โปรดระบุ"
                name="riskFall"
                onChange={props.onChangeData}
                style={{ marginRight:"20px" ,display: "flex", alignItems: "center"  }}
                value="มีความเสี่ยง">
              </Radio>
              <div
                style={{ display: props.ReAssessmentSequence?.riskFall !== "มีความเสี่ยง" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Dropdown
                clearable={true}
                disabled={props.ReAssessmentSequence?.riskFall !== "มีความเสี่ยง"}
                name="riskFallRemark"
                onChange={props.onChangeData}
                options={props.reAssessmentOptions?.painRiskFallOptions}
                selection={true}
                style={{ width: "20%" }}
                value={props.ReAssessmentSequence?.riskFallRemark}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight:"2%"  }}>
              
              <div>
                การให้การพยาบาลผู้ป่วย :
              </div>
            </div>
            <div
              style={{  display: "flex"}}>
              
              <Dropdown
                clearable={true}
                name="nursingCare"
                onChange={props.onChangeData}
                options={props.reAssessmentOptions?.painNursingCareOptions}
                selection={true}
                style={{ width: "20%" }}
                value={props.ReAssessmentSequence?.nursingCare}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "right", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ display: "flex", alignItems: "center", margin: "0px 20px"  }}>
              ประเมินวันที่
            </div>
            <DateTextBox
              onChange={props.onChangDateRiskFall}
              value={props.ReAssessmentSequence?.riskFallData?.saveData}>
            </DateTextBox>
            <div
              style={{ display: "flex", alignItems: "center", margin: "0px 20px"  }}>
              เวลา
            </div>
            <TimeFreeTextBox24
              autoFocus={false}
              onChange={props.onChangTimeRiskFall}
              value={props.ReAssessmentSequence?.riskFallData?.saveTime}>
            </TimeFreeTextBox24>
            <div
              style={{ margin: "0px 20px" }}>
              {props?.buttonSaveRiskFall}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "15px", margin: "30px 0px 20px" }}>
          การพักผ่อนนอนหลับ
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "4%", fontWeight: "bold" }}>
              
              <div>
                นอน :
              </div>
            </div>
            <div
              style={{ width: "80%", display: "flex"}}>
              
              <Input
                name="sleepingPerDay"
                onChange={props.onChangeData}
                onKeyDown={props.onKeyDown}
                style={{ width: "5%" , marginRight: "20px"}}
                value={props.ReAssessmentSequence?.sleepingPerDay}>
              </Input>
              <div
                style={{ fontWeight: "bold", marginRight: "20px", alignItems: "center", display: "flex" }}>
                ชม./วัน
              </div>
              <Radio
                checked={props.ReAssessmentSequence?.sleepingEnough === "เพียงพอ"}
                label="เพียงพอ"
                name="sleepingEnough"
                onChange={props.onChangeData}
                style={{ display: "flex", alignItems: "center",marginRight:"20px"  }}
                value="เพียงพอ">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.sleepingEnough === "ไม่เพียงพอ"}
                label="ไม่เพียงพอ"
                name="sleepingEnough"
                onChange={props.onChangeData}
                style={{ display: "flex", alignItems: "center"  }}
                value="ไม่เพียงพอ">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold",marginRight:"30px"}}>
              
              <div>
                ปัญหาการนอน :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.sleepingProblem === "ไม่มี"}
                label="ไม่มี"
                name="sleepingProblem"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center" ,marginRight:"20px" }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.sleepingProblem === "มี"}
                label="มี โปรดระบุ"
                name="sleepingProblem"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center" ,marginRight:"20px" }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.ReAssessmentSequence?.sleepingProblem !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.ReAssessmentSequence?.sleepingProblem !== "มี"}
                name="sleepingProblemRemark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.ReAssessmentSequence?.sleepingProblem === "มี"  ?  props.ReAssessmentSequence?.sleepingProblemRemark || ""   :  ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold" ,marginRight:"30px"}}>
              
              <div>
                การใช้ยานอนหลับ :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.sleepPill === "ไม่ใช้"}
                label="ไม่ใช้"
                name="sleepPill"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center",marginRight:"20px"  }}
                value="ไม่ใช้">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.sleepPill === "ไม่ทราบ"}
                label="ไม่ทราบ"
                name="sleepPill"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center" ,marginRight:"20px" }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.sleepPill === "ใช้"}
                label="ใช้ โปรดระบุ"
                name="sleepPill"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center",marginRight:"20px" }}
                value="ใช้">
              </Radio>
              <div
                style={{ display: props.ReAssessmentSequence?.sleepPill !== "ใช้" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.ReAssessmentSequence?.sleepPill !== "ใช้" }
                name="sleepPillRemark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.ReAssessmentSequence?.sleepPill === "ใช้"  ? props.ReAssessmentSequence?.sleepPillRemark  || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "right", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ display: "flex", alignItems: "center", margin: "0px 20px"  }}>
              ประเมินวันที่
            </div>
            <DateTextBox
              onChange={props.onChangDateSleep}
              value={props.ReAssessmentSequence?.sleepData?.saveData}>
            </DateTextBox>
            <div
              style={{ display: "flex", alignItems: "center", margin: "0px 20px"  }}>
              เวลา
            </div>
            <TimeFreeTextBox24
              autoFocus={false}
              onChange={props.onChangTimeSleep}
              value={props.ReAssessmentSequence?.sleepData?.saveTime}>
            </TimeFreeTextBox24>
            <div
              style={{ margin: "0px 20px" }}>
              {props?.buttonSaveSleep}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardReAssessmentUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 20 2023 05:48:06 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingBottom: \"2rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1034,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 1259,
      "void": false
    },
    {
      "from": null,
      "id": 1035,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "การพักผ่อนนอนหลับ"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 1258,
      "void": false
    },
    {
      "from": null,
      "id": 1036,
      "name": "div",
      "parent": 1034,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1036,
      "void": false
    },
    {
      "from": null,
      "id": 1037,
      "name": "div",
      "parent": 1034,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1037,
      "void": false
    },
    {
      "from": null,
      "id": 1038,
      "name": "div",
      "parent": 1034,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1038,
      "void": false
    },
    {
      "from": null,
      "id": 1039,
      "name": "div",
      "parent": 1036,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"4%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1039,
      "void": false
    },
    {
      "from": null,
      "id": 1040,
      "name": "div",
      "parent": 1036,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\"}"
        }
      },
      "seq": 1040,
      "void": false
    },
    {
      "from": null,
      "id": 1041,
      "name": "div",
      "parent": 1037,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\",marginRight:\"30px\"}"
        }
      },
      "seq": 1041,
      "void": false
    },
    {
      "from": null,
      "id": 1042,
      "name": "div",
      "parent": 1037,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1042,
      "void": false
    },
    {
      "from": null,
      "id": 1043,
      "name": "div",
      "parent": 1038,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\" ,marginRight:\"30px\"}"
        }
      },
      "seq": 1043,
      "void": false
    },
    {
      "from": null,
      "id": 1044,
      "name": "div",
      "parent": 1038,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1044,
      "void": false
    },
    {
      "from": null,
      "id": 1045,
      "name": "div",
      "parent": 1039,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน :"
        }
      },
      "seq": 1045,
      "void": false
    },
    {
      "from": null,
      "id": 1046,
      "name": "div",
      "parent": 1041,
      "props": {
        "children": {
          "type": "value",
          "value": "ปัญหาการนอน :"
        }
      },
      "seq": 1046,
      "void": false
    },
    {
      "from": null,
      "id": 1047,
      "name": "div",
      "parent": 1043,
      "props": {
        "children": {
          "type": "value",
          "value": "การใช้ยานอนหลับ :"
        }
      },
      "seq": 1047,
      "void": false
    },
    {
      "from": null,
      "id": 1049,
      "name": "div",
      "parent": 1040,
      "props": {
        "children": {
          "type": "value",
          "value": "ชม./วัน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"20px\", alignItems: \"center\", display: \"flex\" }"
        }
      },
      "seq": 1051,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1050,
      "name": "Radio",
      "parent": 1040,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingEnough === \"เพียงพอ\""
        },
        "label": {
          "type": "value",
          "value": "เพียงพอ"
        },
        "name": {
          "type": "value",
          "value": "sleepingEnough"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\",marginRight:\"20px\"  }"
        },
        "value": {
          "type": "value",
          "value": "เพียงพอ"
        }
      },
      "seq": 1319,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1051,
      "name": "Radio",
      "parent": 1040,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingEnough === \"ไม่เพียงพอ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่เพียงพอ"
        },
        "name": {
          "type": "value",
          "value": "sleepingEnough"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่เพียงพอ"
        }
      },
      "seq": 1320,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1052,
      "name": "Radio",
      "parent": 1042,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingProblem === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "sleepingProblem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\" ,marginRight:\"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 1052,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1053,
      "name": "Radio",
      "parent": 1042,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingProblem === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "sleepingProblem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\" ,marginRight:\"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 1053,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1054,
      "name": "Radio",
      "parent": 1044,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill === \"ไม่ใช้\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช้"
        },
        "name": {
          "type": "value",
          "value": "sleepPill"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\",marginRight:\"20px\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ใช้"
        }
      },
      "seq": 1054,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1055,
      "name": "Radio",
      "parent": 1044,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill === \"ไม่ทราบ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "sleepPill"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\" ,marginRight:\"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 1055,
      "void": false
    },
    {
      "from": null,
      "id": 1056,
      "name": "div",
      "parent": 1042,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.ReAssessmentSequence?.sleepingProblem !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1056,
      "void": false
    },
    {
      "from": null,
      "id": 1057,
      "name": "div",
      "parent": 1044,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.ReAssessmentSequence?.sleepPill !== \"ใช้\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1059,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1058,
      "name": "Input",
      "parent": 1042,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingProblem !== \"มี\""
        },
        "name": {
          "type": "value",
          "value": "sleepingProblemRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingProblem === \"มี\"  ?  props.ReAssessmentSequence?.sleepingProblemRemark || \"\"   :  \"\""
        }
      },
      "seq": 1058,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1059,
      "name": "Input",
      "parent": 1044,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill !== \"ใช้\" "
        },
        "name": {
          "type": "value",
          "value": "sleepPillRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill === \"ใช้\"  ? props.ReAssessmentSequence?.sleepPillRemark  || \"\"   : \"\""
        }
      },
      "seq": 1061,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1061,
      "name": "Radio",
      "parent": 1044,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill === \"ใช้\""
        },
        "label": {
          "type": "value",
          "value": "ใช้ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "sleepPill"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\",marginRight:\"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "ใช้"
        }
      },
      "seq": 1057,
      "void": false
    },
    {
      "from": null,
      "id": 1062,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "การประเมินความเจ็บปวด"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\",  fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"15px\", margin: \"0px 0px 20px\" }"
        }
      },
      "seq": 571,
      "void": false
    },
    {
      "from": null,
      "id": 1063,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 574,
      "void": false
    },
    {
      "from": null,
      "id": 1070,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1070,
      "void": false
    },
    {
      "from": null,
      "id": 1123,
      "name": "div",
      "parent": 1070,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\" ,marginRight:\"20px\"  }"
        }
      },
      "seq": 1123,
      "void": false
    },
    {
      "from": null,
      "id": 1124,
      "name": "div",
      "parent": 1070,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 1124,
      "void": false
    },
    {
      "from": null,
      "id": 1125,
      "name": "div",
      "parent": 1123,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเจ็บปวด :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1126,
      "name": "Radio",
      "parent": 1124,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painStatus === \"ปวด\""
        },
        "label": {
          "type": "value",
          "value": "ปวด"
        },
        "name": {
          "type": "value",
          "value": "painStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,marginRight:\"20px\"   }"
        },
        "value": {
          "type": "value",
          "value": "ปวด"
        }
      },
      "seq": 1126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1127,
      "name": "Radio",
      "parent": 1124,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painStatus === \"ไม่ปวด\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปวด"
        },
        "name": {
          "type": "value",
          "value": "painStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" ,marginRight:\"20px\"   }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปวด"
        }
      },
      "seq": 1127,
      "void": false
    },
    {
      "from": null,
      "id": 1128,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1128,
      "void": false
    },
    {
      "from": null,
      "id": 1129,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1129,
      "void": false
    },
    {
      "from": null,
      "id": 1130,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1130,
      "void": false
    },
    {
      "from": null,
      "id": 1131,
      "name": "div",
      "parent": 1128,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginRight:\"20px\"  }"
        }
      },
      "seq": 1131,
      "void": false
    },
    {
      "from": null,
      "id": 1132,
      "name": "div",
      "parent": 1128,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  display: \"flex\"}"
        }
      },
      "seq": 1132,
      "void": false
    },
    {
      "from": null,
      "id": 1134,
      "name": "div",
      "parent": 1129,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\"}"
        }
      },
      "seq": 1134,
      "void": false
    },
    {
      "from": null,
      "id": 1135,
      "name": "div",
      "parent": 1130,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginRight:\"20px\" }"
        }
      },
      "seq": 1135,
      "void": false
    },
    {
      "from": null,
      "id": 1136,
      "name": "div",
      "parent": 1130,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 1136,
      "void": false
    },
    {
      "from": null,
      "id": 1137,
      "name": "div",
      "parent": 1131,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินระดับความเจ็บปวด :"
        }
      },
      "seq": 1137,
      "void": false
    },
    {
      "from": null,
      "id": 1139,
      "name": "div",
      "parent": 1135,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาลผู้ป่วย :"
        }
      },
      "seq": 1139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1140,
      "name": "Dropdown",
      "parent": 1132,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "assessPainLevel"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pain"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.assessPainLevel"
        }
      },
      "seq": 1140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1141,
      "name": "Dropdown",
      "parent": 1136,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "sensesNursingCare"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.sensesNursingCareOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sensesNursingCare"
        }
      },
      "seq": 1141,
      "void": false
    },
    {
      "from": null,
      "id": 1142,
      "name": "div",
      "parent": 1134,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับความปวด :"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" , marginRight: \"10px\" }"
        }
      },
      "seq": 1142,
      "void": false
    },
    {
      "from": null,
      "id": 1143,
      "name": "div",
      "parent": 1134,
      "props": {
        "children": {
          "type": "value",
          "value": "บริเวณที่ปวด :"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" , display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1144,
      "void": false
    },
    {
      "from": null,
      "id": 1144,
      "name": "div",
      "parent": 1134,
      "props": {
        "children": {
          "type": "value",
          "value": "ลักษณะการปวด :"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" , display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1145,
      "name": "Dropdown",
      "parent": 1134,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painLevel"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painLevel"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painLevel"
        }
      },
      "seq": 1143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1146,
      "name": "Dropdown",
      "parent": 1134,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painArea"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.location"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painArea"
        }
      },
      "seq": 1145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1147,
      "name": "Dropdown",
      "parent": 1134,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painNature"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.characteristic"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painNature"
        }
      },
      "seq": 1147,
      "void": false
    },
    {
      "from": null,
      "id": 1258,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "การประเมินความเสี่ยงต่อการพลัดตกหกล้ม"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 611,
      "void": false
    },
    {
      "from": null,
      "id": 1259,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 614,
      "void": false
    },
    {
      "from": null,
      "id": 1287,
      "name": "div",
      "parent": 1259,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1288,
      "void": false
    },
    {
      "from": null,
      "id": 1288,
      "name": "div",
      "parent": 1259,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1287,
      "void": false
    },
    {
      "from": null,
      "id": 1289,
      "name": "div",
      "parent": 1287,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight:\"2%\"  }"
        }
      },
      "seq": 1289,
      "void": false
    },
    {
      "from": null,
      "id": 1290,
      "name": "div",
      "parent": 1289,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาลผู้ป่วย :"
        }
      },
      "seq": 1290,
      "void": false
    },
    {
      "from": null,
      "id": 1291,
      "name": "div",
      "parent": 1287,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  display: \"flex\"}"
        }
      },
      "seq": 1291,
      "void": false
    },
    {
      "from": null,
      "id": 1292,
      "name": "div",
      "parent": 1288,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\"}"
        }
      },
      "seq": 1292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1293,
      "name": "Radio",
      "parent": 1292,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFall === \"ไม่มีความเสี่ยง\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มีความเสี่ยง"
        },
        "name": {
          "type": "value",
          "value": "riskFall"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\" ,marginRight:\"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มีความเสี่ยง"
        }
      },
      "seq": 1293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1294,
      "name": "Radio",
      "parent": 1292,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFall === \"มีความเสี่ยง\""
        },
        "label": {
          "type": "value",
          "value": "มีความเสี่ยง โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "riskFall"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight:\"20px\" ,display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มีความเสี่ยง"
        }
      },
      "seq": 1294,
      "void": false
    },
    {
      "from": null,
      "id": 1295,
      "name": "div",
      "parent": 1292,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.ReAssessmentSequence?.riskFall !== \"มีความเสี่ยง\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1296,
      "name": "Dropdown",
      "parent": 1292,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFall !== \"มีความเสี่ยง\""
        },
        "name": {
          "type": "value",
          "value": "riskFallRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painRiskFallOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallRemark"
        }
      },
      "seq": 1296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1297,
      "name": "Dropdown",
      "parent": 1291,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "nursingCare"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painNursingCareOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.nursingCare"
        }
      },
      "seq": 1297,
      "void": false
    },
    {
      "from": null,
      "id": 1298,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"right\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1298,
      "void": false
    },
    {
      "from": null,
      "id": 1299,
      "name": "div",
      "parent": 1298,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 20px\"  }"
        }
      },
      "seq": 1299,
      "void": false
    },
    {
      "from": null,
      "id": 1300,
      "name": "div",
      "parent": 1298,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 20px\"  }"
        }
      },
      "seq": 1301,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 1301,
      "name": "TimeFreeTextBox24",
      "parent": 1298,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangTimePain"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painData?.saveTime"
        }
      },
      "seq": 1302,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 1302,
      "name": "DateTextBox",
      "parent": 1298,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangDatePain"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painData?.saveData"
        }
      },
      "seq": 1300,
      "void": false
    },
    {
      "from": null,
      "id": 1304,
      "name": "div",
      "parent": 1259,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"right\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1304,
      "void": false
    },
    {
      "from": null,
      "id": 1305,
      "name": "div",
      "parent": 1034,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"right\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1305,
      "void": false
    },
    {
      "from": null,
      "id": 1306,
      "name": "div",
      "parent": 1304,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 20px\"  }"
        }
      },
      "seq": 1306,
      "void": false
    },
    {
      "from": null,
      "id": 1307,
      "name": "div",
      "parent": 1305,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 20px\"  }"
        }
      },
      "seq": 1307,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 1308,
      "name": "DateTextBox",
      "parent": 1304,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangDateRiskFall"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallData?.saveData"
        }
      },
      "seq": 1308,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 1309,
      "name": "DateTextBox",
      "parent": 1305,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangDateSleep"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepData?.saveData"
        }
      },
      "seq": 1309,
      "void": false
    },
    {
      "from": null,
      "id": 1310,
      "name": "div",
      "parent": 1304,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 20px\"  }"
        }
      },
      "seq": 1310,
      "void": false
    },
    {
      "from": null,
      "id": 1311,
      "name": "div",
      "parent": 1305,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 20px\"  }"
        }
      },
      "seq": 1311,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 1312,
      "name": "TimeFreeTextBox24",
      "parent": 1304,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangTimeRiskFall"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallData?.saveTime"
        }
      },
      "seq": 1312,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 1313,
      "name": "TimeFreeTextBox24",
      "parent": 1305,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangTimeSleep"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepData?.saveTime"
        }
      },
      "seq": 1313,
      "void": false
    },
    {
      "from": null,
      "id": 1316,
      "name": "div",
      "parent": 1298,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSavePain"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px\" }"
        }
      },
      "seq": 1316,
      "void": false
    },
    {
      "from": null,
      "id": 1317,
      "name": "div",
      "parent": 1304,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSaveRiskFall"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px\" }"
        }
      },
      "seq": 1317,
      "void": false
    },
    {
      "from": null,
      "id": 1318,
      "name": "div",
      "parent": 1305,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSaveSleep"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px\" }"
        }
      },
      "seq": 1318,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1320,
      "name": "Input",
      "parent": 1040,
      "props": {
        "name": {
          "type": "value",
          "value": "sleepingPerDay"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDown"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"5%\" , marginRight: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingPerDay"
        }
      },
      "seq": 1050,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardReAssessmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
