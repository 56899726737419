import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  Segment,
  TextArea,
  Button
} from 'semantic-ui-react'

const SubDrugOrderToolbox = (props: any) => {
    return(
      <div
        style={{  height: "100%", minHeight: "16.5rem"}}>
        <div
          className="ui segment"
          style={{ backgroundColor: "#F3F3F3", margin: "0 01rem", width: "100%", height: "100%" }}>
          
          <div
            style={{ display: "flex", justifyContent: "space-between" }}>
            
            <label
              style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              กล่องเครื่องมือ
            </label>
            <div
              style={{ cursor: "pointer" }}>
              
              <Icon
                className="red"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
          <div
            className="ui divider"
            style={{ margin: "0.3rem 0 -0.75rem" }}>
            
          </div>
          <Segment
            className="inverted">
            <label
              style={{ fontWeight: "bold" }}>
              {props.name}
            </label>
            <TextArea
              disabled={true}
              rows={3}
              style={{ width: "100%", background: "white", height: "calc(100% - 6.5rem)" }}
              value={props.label}>
            </TextArea>
            <div
              style={{ display: "grid", gridTemplateColumns: "50% 47%", rowGap: "10px", columnGap: "10px", }}>
              
              <Button
                onClick={props.onClickDrugRequest}
                size="mini">
                ขอยา
              </Button>
              <Button
                disabled={props.disabledMedError}
                onClick={props.onClickMedError}
                size="mini">
                Med Error
              </Button>
              <Button
                onClick={props.onClickReprint}
                size="mini"
                style={{display: props.orderStatus === "REQUESTED"? "none": ""}}>
                Reprint
              </Button>
              <Button
                disabled={!props.showButtonLotNo}
                onClick={props.onClickLotNo}
                size="mini"
                style={{display: props.showButtonLotNo ? "" : "none", paddingRight: 0, paddingLeft: 0}}>
                Lot No./Exp.
              </Button>
            </div>
          </Segment>
        </div>
      </div>
    )
}


export default SubDrugOrderToolbox

export const screenPropsDefault = {}

/* Date Time : Wed Dec 13 2023 12:50:57 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{  height: \"100%\", minHeight: \"16.5rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui segment"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#F3F3F3\", margin: \"0 01rem\", width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "กล่องเครื่องมือ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ cursor: \"pointer\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Icon",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "red"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0.3rem 0 -0.75rem\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Segment",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "inverted"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.name"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "TextArea",
      "parent": 7,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"white\", height: \"calc(100% - 6.5rem)\" }"
        },
        "value": {
          "type": "code",
          "value": "props.label"
        }
      },
      "seq": 9,
      "void": true
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"grid\", gridTemplateColumns: \"50% 47%\", rowGap: \"10px\", columnGap: \"10px\", }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ขอยา"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDrugRequest"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Med Error"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledMedError"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickMedError"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Reprint"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickReprint"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{display: props.orderStatus === \"REQUESTED\"? \"none\": \"\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Lot No./Exp."
        },
        "disabled": {
          "type": "code",
          "value": "!props.showButtonLotNo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickLotNo"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showButtonLotNo ? \"\" : \"none\", paddingRight: 0, paddingLeft: 0}"
        }
      },
      "seq": 14,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "SubDrugOrderToolbox",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
