import React, { useState, useEffect, CSSProperties } from "react";
import { Modal, Button, Segment, Dropdown, Loader, Form, TextArea } from "semantic-ui-react";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import ErrorMessage from "./ErrorMessage";

const REASON_MSG = "เหตุผล";
const CODE_MSG = "ชื่อผู้บันทึกข้อมูล";

type ModConfirmAuthenProps = {
  type?: string;
  onButtonLeftClick?: any;
  onButtonRightClick?: any;
  onClose?: any;
  open?: boolean;
  size?: any;
  titleColor?: string;
  title?: string;
  subTitle?: string;
  loading?: boolean;
  buttonLeftLoading?: boolean;
  buttonRightLoading?: boolean;
  buttonLeftColor?: any;
  buttonRightColor?: any;
  buttonLeftLabel?: string;
  buttonRightLabel?: string;
  disableButtonLeft?: boolean;
  isButtonBasic?: boolean;
  questionDetail?: any;
  userEditQuestion?: any;
  onEvent?: any;
  userTokenize?: any;
  multiple?: boolean;
  errorMessage?: any;
  setProp?: any;
  isRequiredQuestionDetail?: boolean;
  isUserEditQuesion?: boolean;
  options?: any[];
  handleChange?: any;
  handleAddition?: any;
  currentValue?: any[];
  inputType?: "textarea" | "";
  isErrMsg?: boolean;
  style?: CSSProperties;
};

const ModConfirmAuthen = (props: ModConfirmAuthenProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  const styles = {
    header: {
      lineHeight: "1.3em",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
    },
    button: {
      marginTop: "10px",
      marginRight: "20px",
      minWidth: "100px",
    },
    divButton: {
      display: "flex",
      justifyContent: "center",
    },
    textArea: {
      minHeight: 100,
    },
  };

  useEffect(() => {
    setErrorMessage(props.errorMessage || "")
  }, [props.errorMessage])

  const handleButtonLeftClick = () => {
    if (props.isErrMsg) {

      let message = "";
      const isEmpty = Array.isArray(props.currentValue) ? !props.currentValue.length : !props.currentValue;

      if (isEmpty && !props.userTokenize?.employeeName && props.isUserEditQuesion && props.isRequiredQuestionDetail) {
        message = `${REASON_MSG}และ${CODE_MSG}`;
      } else if (isEmpty && props.isRequiredQuestionDetail) {
        message = REASON_MSG;
      } else if (!props.userTokenize?.employeeName && props.isUserEditQuesion) {
        message = CODE_MSG;
      }

      if (message) {
        setErrorMessage(`กรุณาระบุ${message}`);
        return;
      }
    }

    clearMessage();
    props.onButtonLeftClick();
  };

  const handleButtonRightClick = () => {
    clearMessage();
    props.onButtonRightClick();
  };

  const handleClose = () => {
    clearMessage();
    props.onClose?.();
  };

  const clearMessage = () => {
    setErrorMessage("");
  };

  return (
    <Modal open={props.open} size={props.size} style={props.style} onClose={handleClose}>
      <Segment inverted className={"modHeader " + props.titleColor} style={styles.header}>
        {props.title}
      </Segment>
      <Segment align="center" className="modContent">
        <ErrorMessage error={errorMessage} style={{ marginBottom: "-1rem" }} />

        {props.subTitle && <div style={{ textAlign: "left", margin: "20px 0px" }}> {props.subTitle} </div>}

        <div style={{ textAlign: "left" }}>
          <div style={{ margin: "20px 0px 10px 0px" }}>
            {props.questionDetail} {props.isRequiredQuestionDetail && <span style={{ color: "red" }}>*</span>}
          </div>

          {props.inputType === "textarea" ? (
            <Form>
              <TextArea value={props.currentValue?.toString()} rows={4} onChange={props.handleChange} fluid error={errorMessage?.toString()?.includes(REASON_MSG)} />
            </Form>
          ) : (
            <Dropdown
              options={props.options}
              search
              selection
              fluid
              multiple={props.multiple ?? true}
              allowAdditions
              value={props.currentValue}
              onAddItem={props.handleAddition}
              onChange={props.handleChange}
              error={errorMessage?.toString()?.includes(REASON_MSG)}
            />
          )}
        </div>

        {props.userEditQuestion && (
          <div style={{ textAlign: "left", margin: "20px 0px" }}>
            <span style={{ marginRight: "10px" }}>
              {" "}
              {props.userEditQuestion} {props.isUserEditQuesion && <span style={{ color: "red" }}>*</span>}
            </span>

            <EmployeeToken
              placeholder={"รหัสผู้บันทึก"}
              onEnterToken={(code) => {
                props.onEvent({
                  message: "UserTokenize",
                  params: {
                    action: "CheckUserToken",
                    code: code,
                  },
                });

                // props.onEvent({
                //   message: "HandleGetDeliverDrugTokenization",
                //   params: { code },
                // });
              }}
              onClearToken={() =>
                props.setProp("userTokenize", {
                  ...props.userTokenize,
                  token: "",
                  employeeName: "",
                  loading: false,
                  error: null,
                })
              }
              error={!!props.userTokenize?.error || (errorMessage?.toString()?.includes(CODE_MSG) && !props.userTokenize?.employeeName)}
              loading={props.userTokenize?.loading}
              employeeName={props.userTokenize?.employeeName}
            />
          </div>
        )}

        <div style={styles.divButton}>
          <Button
            style={styles.button}
            {...(props.isButtonBasic && { basic: true })}
            loading={props.buttonLeftLoading}
            disabled={props.buttonRightLoading || props.disableButtonLeft}
            color={props.buttonLeftColor}
            onClick={handleButtonLeftClick}
          >
            {props.buttonLeftLabel}
          </Button>
          <Button
            style={styles.button}
            {...(props.isButtonBasic && { basic: true })}
            loading={props.buttonRightLoading}
            disabled={props.buttonLeftLoading}
            color={props.buttonRightColor}
            onClick={handleButtonRightClick}
          >
            {props.buttonRightLabel}
          </Button>
        </div>
      </Segment>
    </Modal>
  );
};

export default ModConfirmAuthen;
