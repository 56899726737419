import React, { useMemo, useState, useEffect } from "react";
import { Button, Icon, Input, Label, Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import CardOPDDownloadSentClaimFileUX from "./CardOPDDownloadSentClaimFileUX";

// ARM
import CardSearchARTransaction from "react-lib/apps/ARM/CardSearchARTransaction";
import ModDownloadPreviousSendClaimFileContainer from "../../ARM/ModDownloadPreviousSendClaimFileContainer";
import ModBillTransactionContainer from "../../ARM/ModBillTransactionContainer";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  RunSequence,
  State,
  TransactionSummaryType,
} from "./sequence/DownloadSentClaimFile";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

import config from "../../../../config/config";

const HOST = `${config.API_HOST}`;

// Types
type CardOPDDownloadSentClaimFileProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // controller
  cardSentClaimInformationController: any;
  // seq
  runSequence: RunSequence;
  DownloadSentClaimFileSequence: State["DownloadSentClaimFileSequence"];
  // data
  apiToken?: string;
  // CommonInterface
  errorMessage?: any;
  buttonLoadCheck?: any;
  // options
  masterOptions?: Record<string, any>;
};

// Const
const SENT_SUCCESS = 3;
const RESENT_SUCCESS = 7;
const SENT_WAITING_RESULT = 2;
const RESENT_WAITING_RESULT = 6;
const NOT_SENT = 8;

const VALID_FALSE_BACKGROUND_COLOR = "rgb(255, 204, 102)";
const C_BACKGROUND_COLOR = "rgb(255, 197, 197)";
const WAITING_RESULT_BACKGROUND_COLOR = "rgb(153, 204, 255)";
const SENT_SUCCESS_BACKGROUND_COLOR = "rgb(153, 255, 153)";
const NOT_SENT_BACKGROUND_COLOR = "rgb(201, 255, 229)";

const CARD_OPD_DOWNLOAD_SENT_CLAIM_FILE = "CardOPDDownloadSentClaimFile";

const CardOPDDownloadSentClaimFile = (
  props: CardOPDDownloadSentClaimFileProps
) => {
  const [openModSearchAR, setOpenModSearchAR] = useState<boolean>(false);
  const [modDownloadPrevSendClaimFile, setModDownloadPrevSendClaimFile] =
    useState<{ arTransaction?: number; header?: string } | null>(null);
  const [modShowDetail, setModShowDetail] = useState<number | null>(null);

  // Use Effect
  useEffect(() => {
    props.runSequence({ sequence: "DownloadSentClaimFile", restart: true });
  }, []);

  // Use Memo

  const filter = useMemo(() => {
    return props.DownloadSentClaimFileSequence?.filter || {};
  }, [props.DownloadSentClaimFileSequence?.filter]);

  const billTransactionItems = useMemo(() => {
    return (props.DownloadSentClaimFileSequence?.billTransactionList || []).map(
      (item: any) => {
        const priceFormat = Object.assign(
          {},
          ...["amount", "paid", "claim_amount", "other_pay"].map((key) => ({
            [key]: new Intl.NumberFormat().format(item[key]),
          }))
        );

        return {
          ...item,
          description: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                color="orange"
                size="mini"
                style={{ paddingRight: "15px", paddingLeft: "15px" }}
                onClick={() => setModShowDetail(item.id)}
              >
                รายละเอียด
              </Button>
            </div>
          ),
          ...priceFormat,
        };
      }
    );
  }, [props.DownloadSentClaimFileSequence?.billTransactionList]);

  const billSummary = useMemo(() => {
    const summary =
      props.DownloadSentClaimFileSequence?.billTransactionSummary || {};
    const keys =
      "total_rows,total_price,total_sent_claim_price,total_paid,total_other_pay,total_price_bill_item_mode_2,total_sent_claim_price_bill_item_mode_2,total_price_bill_item_mode_3_5,total_sent_claim_price_bill_item_mode_3_5,total_price_bill_item_mode_remain,total_sent_claim_price_bill_item_mode_remain,total_price_dispensing,total_sent_claim_price_dispensing,total_price_operation_service";

    const split = keys.split(",") as (keyof TransactionSummaryType)[];

    return Object.assign(
      {},
      ...split.map((key) => ({
        [key]: summary.isCalulating ? "Calculating..." : summary[key] || "",
      }))
    );
  }, [
    props.DownloadSentClaimFileSequence?.billTransactionSummary?.isCalulating,
  ]);

  // Handler
  // Table
  const handleGetTrProps = (
    _state: any,
    rowInfo: any,
    _column: any,
    _instance: any
  ) => {
    const original = rowInfo?.original || {};

    const background =
      original.status === NOT_SENT
        ? NOT_SENT_BACKGROUND_COLOR
        : original.status === SENT_WAITING_RESULT ||
          original.status === RESENT_WAITING_RESULT
        ? WAITING_RESULT_BACKGROUND_COLOR
        : original.status === SENT_SUCCESS || original.status === RESENT_SUCCESS
        ? SENT_SUCCESS_BACKGROUND_COLOR
        : original.valid === false
        ? VALID_FALSE_BACKGROUND_COLOR
        : original.have_failed
        ? C_BACKGROUND_COLOR
        : "";

    return { style: { background } };
  };

  const handleOpenModSearchAR = () => {
    setOpenModSearchAR(true);
  };

  const handleCloseModSearchAR = () => {
    setOpenModSearchAR(false);
  };

  const handleSelected = async (data: any) => {
    console.log("... handleSelected: ", data);
    if (data) {
      props.runSequence({
        sequence: "DownloadSentClaimFile",
        action: "CHANGE",
        data: {
          arTransaction: data.id,
          coveragePayer: data.coverage_payer_sent_claim_group,
          fiscalYear: data.fiscal_year,
          lotNo: data.lot_no,
          coverageCodeName: data.coverage_code_name,
        },
      });
    }
  };

  const handleChangeValue = async (e: any, v: any) => {
    const value = typeof v.checked === "boolean" ? v.checked : v.value;

    props.runSequence({
      sequence: "DownloadSentClaimFile",
      action: "CHANGE",
      data: {
        [v.name]: value,
      },
    });
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleUpdateSentClaimData = () => {
    props.runSequence({
      sequence: "DownloadSentClaimFile",
      action: "UPDATE_SENT_CLAIM",
      card: CARD_OPD_DOWNLOAD_SENT_CLAIM_FILE,
    });
  };

  const handleDownloadZipFile = () => {
    const lotNo = filter.lotNo || "";
    const fiscal_year = filter.fiscalYear || "";
    const header = `Download Zip File ย้อนหลังของงวด ${lotNo}  ปีงบประมาณ ${fiscal_year}`;

    setModDownloadPrevSendClaimFile({
      arTransaction: filter.arTransaction,
      header,
    });
  };

  const handleCloseModDownloadPrevSendClaimFile = () => {
    setModDownloadPrevSendClaimFile(null);
  };

  const handleDownloadSentClaimFile = () => {
    props.runSequence({
      sequence: "DownloadSentClaimFile",
      action: "DOWNLOAD_SENT_CLAIM",
      card: CARD_OPD_DOWNLOAD_SENT_CLAIM_FILE,
    });
  };

  const handleCloseModShowDetail = () => {
    setModShowDetail(null);
  };

  const handleShowAdmin = (pk: number, model: string) => {
    const url = HOST + `/manage/INF/${model}/${pk}/change/`;
    window.open(url, "_blank");
  };

  const handleUrlRedirect = () => {};

  console.log("CardOPDDownloadSentClaimFile", props, billTransactionItems);

  return (
    <div style={{ height: "195vh", overflow: "auto", width: "calc(100vw - 150px)" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(
            `errorMessage.${CARD_OPD_DOWNLOAD_SENT_CLAIM_FILE}`,
            null
          );
        }}
        error={props.errorMessage?.[CARD_OPD_DOWNLOAD_SENT_CLAIM_FILE]}
        success={null}
      />

      <CardOPDDownloadSentClaimFileUX
        // data
        billTransactionList={billTransactionItems}
        fiscalYear={filter.fiscalYear}
        isDate={filter.isDate}
        startDate={filter.startDate}
        endDate={filter.endDate}
        coveragePayer={filter.coverageCodeName}
        isOnlyC={filter.isOnlyC}
        billSummary={billSummary}
        // config
        disabledUpdateSentClaim={!filter.arTransaction}
        disabledDownloadZipFile={!filter.arTransaction}
        disabledDownloadSentClaimFile={!filter.arTransaction}
        // callback
        onChangeValue={handleChangeValue}
        onChangeDate={handleChangeDate}
        // onUpdateSentClaimData={handleUpdateSentClaimData}
        onDownloadZipFile={handleDownloadZipFile}
        // onDownloadSentClaimFile={handleDownloadSentClaimFile}
        getTrProps={handleGetTrProps}
        // Element
        SearchARTransaction={
          <Input
            action={{ icon: "search", onClick: handleOpenModSearchAR }}
            value={filter.lotNo || ""}
            readOnly={true}
            onClick={handleOpenModSearchAR}
          />
        }
        ButtonUpdate={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleUpdateSentClaimData}
            // data
            paramKey={`${CARD_OPD_DOWNLOAD_SENT_CLAIM_FILE}_UPDATE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_OPD_DOWNLOAD_SENT_CLAIM_FILE}_UPDATE`
              ]
            }
            // config
            color={"blue"}
            name="UPDATE"
            size="medium"
            title="แก้ไขรายการที่ติด C และ Validate ไม่ผ่าน"
          />
        }
        ButtonDownload={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleDownloadSentClaimFile}
            // data
            paramKey={`${CARD_OPD_DOWNLOAD_SENT_CLAIM_FILE}_DOWNLOAD`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_OPD_DOWNLOAD_SENT_CLAIM_FILE}_DOWNLOAD`
              ]
            }
            // config
            color={"green"}
            name="DOWNLOAD"
            size="medium"
            title="Download"
          />
        }
      />

      <Modal
        open={openModSearchAR}
        size="large"
        onClose={handleCloseModSearchAR}
      >
        <CardSearchARTransaction
          // controller
          controller={props.cardSentClaimInformationController}
          tableStyle={{ maxHeight: "470px" }}
          // callback
          onSelected={handleSelected}
          onClose={handleCloseModSearchAR}
        />
      </Modal>

      <ModBillTransactionContainer
        open={!!modShowDetail}
        billTransactionId={modShowDetail}
        apiToken={props.apiToken}
        modalStyle={{ margin: "auto 2.5rem" }}
        // callback
        onShowAdmin={handleShowAdmin}
        onUrlRedirect={handleUrlRedirect}
        onClose={handleCloseModShowDetail}
      />

      <ModDownloadPreviousSendClaimFileContainer
        open={!!modDownloadPrevSendClaimFile}
        arTransactionId={modDownloadPrevSendClaimFile?.arTransaction}
        header={modDownloadPrevSendClaimFile?.header}
        apiToken={props.apiToken}
        modalStyle={{ margin: "auto 2.5rem" }}
        // callback
        onClose={handleCloseModDownloadPrevSendClaimFile}
      />
    </div>
  );
};

export default React.memo(CardOPDDownloadSentClaimFile);
