import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown,
  Form,
  FormField,
  TextArea
} from 'semantic-ui-react'

const CardDischargeSummaryUX = (props: any) => {
    return(
      <div
        style={{}}>
        <div
          className="accordion ui fluid blue raised segment"
          style={{boxShadow: "none"}}>
          
          <label
            style={{fontSize: "1.25rem", fontWeight: "bold"}}>
            Discharge Summary
          </label>
          <div
            className="ui divider">
            
          </div>
          <div
            style={{display: "flex", justifyContent: "flex-end", marginTop: "15px"}}>
            
            <Button
              color={props.id ? "yellow" : "green"}
              onClick={props.onSave}>
              {props.id ? "แก้ไข" : "บันทึก"}
            </Button>
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}>
            
            <label
              style={{marginRight: "15px"}}>
              Plan Discharge
            </label>
            <div
              style={{marginRight: "15px"}}>
              {props.DateElement}
            </div>
            <Dropdown
              name="planDischargeType"
              onChange={props.onChangeValue}
              options={props.planDischargeTypeOptions}
              selection={true}
              value={props.planDischargeType}>
            </Dropdown>
          </div>
          <div>
            {props.SubProvisionDiag}
          </div>
          <div>
            {props.SubDRG}
          </div>
          <div
            className="ui divider">
            
          </div>
          <div>
            
            <Form>
              <FormField>
                <label>
                  Brief History and Physical Findings
                </label>
                <TextArea
                  name="brief"
                  onChange={props.onChangeValue}
                  value={props.brief}>
                </TextArea>
              </FormField>
              <FormField>
                <label>
                  Hospital Course
                </label>
                <TextArea
                  name="course"
                  onChange={props.onChangeValue}
                  value={props.course}>
                </TextArea>
              </FormField>
              <FormField>
                <label>
                  Disposition / Condition at discharge
                </label>
                <TextArea
                  name="condition"
                  onChange={props.onChangeValue}
                  value={props.condition}>
                </TextArea>
              </FormField>
              <FormField>
                <label>
                  Follow up plan
                </label>
                <TextArea
                  name="followup"
                  onChange={props.onChangeValue}
                  value={props.followup}>
                </TextArea>
              </FormField>
              <FormField>
                <label>
                  Discharge status
                </label>
                <Dropdown
                  clearable={true}
                  name="dischargeStatus"
                  onChange={props.onChangeValue}
                  options={props.dischargeStatusOptions}
                  selection={true}
                  value={props.dischargeStatus}>
                </Dropdown>
              </FormField>
              <FormField>
                <label>
                  Readmission Type
                </label>
                <Dropdown
                  clearable={true}
                  name="readmissionType"
                  onChange={props.onChangeValue}
                  options={props.readmissionTypeOptions}
                  selection={true}
                  value={props.readmissionType}>
                </Dropdown>
              </FormField>
            </Form>
          </div>
          <div>
            {props.LoadingElement}
          </div>
        </div>
      </div>
    )
}

export default CardDischargeSummaryUX

export const screenPropsDefault = {"by":"order","title":"MEDICAL FEE","titleDescription":"ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"}

/* Date Time : Mon Jun 20 2022 02:24:09 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "accordion ui fluid blue raised segment"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Discharge Summary"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.25rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"15px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.id ? \"แก้ไข\" : \"บันทึก\""
        },
        "color": {
          "type": "code",
          "value": "props.id ? \"yellow\" : \"green\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan Discharge"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"15px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateElement"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"15px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Dropdown",
      "parent": 7,
      "props": {
        "name": {
          "type": "value",
          "value": "planDischargeType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.planDischargeTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.planDischargeType"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubProvisionDiag"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubDRG"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Form",
      "parent": 16,
      "props": {
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Brief History and Physical Findings"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "TextArea",
      "parent": 18,
      "props": {
        "name": {
          "type": "value",
          "value": "brief"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.brief"
        }
      },
      "seq": 20,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "Hospital Course"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "Disposition / Condition at discharge"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Follow up plan"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "Discharge status"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Readmission Type"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 25,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "readmissionType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.readmissionTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.readmissionType"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Dropdown",
      "parent": 24,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "dischargeStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.dischargeStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.dischargeStatus"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "TextArea",
      "parent": 21,
      "props": {
        "name": {
          "type": "value",
          "value": "course"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.course"
        }
      },
      "seq": 33,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "TextArea",
      "parent": 22,
      "props": {
        "name": {
          "type": "value",
          "value": "condition"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.condition"
        }
      },
      "seq": 34,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "TextArea",
      "parent": 23,
      "props": {
        "name": {
          "type": "value",
          "value": "followup"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.followup"
        }
      },
      "seq": 35,
      "void": true
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.LoadingElement"
        }
      },
      "seq": 36,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDischargeSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "by": "order",
    "title": "MEDICAL FEE",
    "titleDescription": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
  },
  "width": 80
}

*********************************************************************************** */
