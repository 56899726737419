import WasmController from "react-lib/frameworks/WasmController";

// APIs
// INF
import DFPayrollGroupList from "issara-sdk/apis/DFPayrollGroupList_apps_INF";
import ImportLabExternalViewM from "issara-sdk/apis/ImportLabExternalView_apps_INFM";
import DownloadLabExternalTemplateView from "issara-sdk/apis/DownloadLabExternalTemplateView_apps_INF";
// Core
import DoctorList from "issara-sdk/apis/DoctorList_core";

// Serializer
import DFPayrollGroupSerializer from "issara-sdk/types/DFPayrollGroupSerializer_apps_INF";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import {
  SetErrorMessage,
  SetProperty,
  downloadXLSX,
  mapOptions,
} from "../../common/CommonInterface";

export type State = Partial<{
  // sequence
  ImportLabValuesCalcDFSequence: Partial<
    {
      sequenceIndex: "Start" | "Action" | null;
      excelFile: File | null;
      items: ItemType[];
      items_success: ItemType[];
      items_failed: ItemType[];
      dfPayrollGroupOptions: (OptionType & { original: DFPayrollGroupSerializer })[];
      activeTab: TabMenuKeys;
    } & DetailType
  > | null;
}>;

const DETAIL_INIT = {
  items: [],
  dfPayrollGroupId: "",
  doctorId: null,
  excelFile: null,
};

type PickedState = Partial<
  Pick<MainState, "buttonLoadCheck" | "errorMessage" | "searchedItemListWithKey">
>;

export type PickedProps = Partial<Omit<PickedState, "">>;

export type DetailType = {
  dfPayrollGroupId: number | string;
  doctorId: number | null | string;
};

export type ItemType = Record<TableHeaderKeys, string | number>;

export type TableHeaderKeys = (typeof TABLE_HEADERS)[number];

export type TabMenuKeys = keyof typeof TAB_KEYS;

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "ImportLabValuesCalcDF";
  restart?: boolean;
  clear?: boolean;
};

// Handle Action
type ActionType =
  // Action
  | { action: "INIT" }
  | { action: "CLEAR_FILE" }
  | { action: "CHANGE_FILE"; file: File }
  | { action: "UPLOAD"; card: string }
  | { action: "DOWNLOAD_TEMPLATE"; card: string };
// Method

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<State & PickedState>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  ImportLabValuesCalcDFSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

const Masters = [] as const;

export const CARD_IMPORT_LAB_VALUES_CALC_DF = "CardImportLabValuesCalcDoctorFee";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_IMPORT_LAB_VALUES_CALC_DF}_${T[K] & string}`;
};

export const ACTIONS = {
  CHANGE_FILE: "CHANGE_FILE",
  UPLOAD: "UPLOAD",
  INIT: "INIT",
  CLEAR_FILE: "CLEAR_FILE",
  DOWNLOAD_TEMPLATE: "DOWNLOAD_TEMPLATE",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${CARD_IMPORT_LAB_VALUES_CALC_DF}_${key}`])
) as Optional<typeof ACTIONS>;

export const TABLE_HEADERS = [
  "HN",
  "ชื่อ-สกุลผู้ป่วย",
  "รหัสแพทย์ผู้สั่ง",
  "แพย์ผู้สั่ง",
  "รหัส",
  "รายการ",
  "ยอดเงิน",
  "ประเภทการคำนวณ DF",
  "% ค่าบริหาร",
  "% หักแพทย์",
  "เลขที่ใบส่งงาน",
] as const;

// Const
export const TAB_KEYS = {
  UPLOAD: "UPLOAD",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
};

export const TAB_MENUS = {
  [TAB_KEYS.UPLOAD]: {
    title: "Upload",
    bg: "",
    key: "items",
  },
  [TAB_KEYS.SUCCESS]: {
    title: "Success",
    bg: "#27AE60",
    key: "items_success",
  },
  [TAB_KEYS.FAIL]: {
    title: "Fail",
    bg: "#FF0000",
    key: "items_failed",
  },
} as const;

const DOCTOR_SB_ID = "Doctor_ILVCDF";

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  controller.setState(
    {
      ImportLabValuesCalcDFSequence: {
        ...state.ImportLabValuesCalcDFSequence,
        sequenceIndex: "Action",
        activeTab: "UPLOAD",
      },
    },
    () => Action(controller, { ...params, action: "INIT" })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers = {
    [ACTIONS.CHANGE_FILE]: HandleChangeFile,
    [ACTIONS.UPLOAD]: HandleUpload,
    [ACTIONS.INIT]: HandleInit,
    [ACTIONS.CLEAR_FILE]: HandleClearFile,
    [ACTIONS.DOWNLOAD_TEMPLATE]: HandleDownloadTemplate,
  };

  const action = params.action;

  if (action && actionHandlers[action]) {
    return actionHandlers[action](controller, params as any);
  }
};

const HandleInit: Handler<Params<"INIT">> = async (controller, params) => {
  const [result] = await DFPayrollGroupList.list({
    apiToken: controller.apiToken,
    params: { limit: 99999 },
  });

  const state = controller.getState();

  controller.setState({
    ImportLabValuesCalcDFSequence: {
      ...state.ImportLabValuesCalcDFSequence,
      dfPayrollGroupOptions: mapOptions(result?.items, "id", "lot_no"),
      ...DETAIL_INIT,
    },
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [DOCTOR_SB_ID]: [],
    },
  });
};

const HandleChangeFile: Handler<Params<"CHANGE_FILE">> = (controller, params) => {
  const state = controller.getState();

  let fileReader = new FileReader();
  fileReader.readAsBinaryString(params.file);

  fileReader.onload = async (event) => {
    if (!event.target) {
      return;
    }

    const XLSX = await import("xlsx");

    const data = event.target.result;
    const workbook = XLSX.read(data, { type: "binary" });
    const rowObject: any[] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    if (!rowObject.length) {
      return console.warn("Please check the correctness file.");
    }

    const items = rowObject
      .map((item) =>
        Object.assign(
          {},
          ...TABLE_HEADERS.map((header) => ({
            [header]: item[header] || "",
          }))
        )
      )
      .filter((item) => Object.values(item).some((value) => value)) as ItemType[];

    const doctorCode = items[0]["รหัสแพทย์ผู้สั่ง"];

    const [doctor] = await DoctorList.list({
      apiToken: controller.apiToken,
      params: { search: doctorCode },
    });

    const doctorDetail = doctor?.items?.find((item: any) => item.code === doctorCode) || null;

    controller.setState({
      ImportLabValuesCalcDFSequence: {
        ...state.ImportLabValuesCalcDFSequence,
        items: items,
        excelFile: params.file,
        doctorId: doctorDetail?.id || null,
      },
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [DOCTOR_SB_ID]: doctorDetail ? [doctorDetail] : [],
      },
    });
  };
};

const HandleUpload: Handler<Params<"UPLOAD">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPLOAD]: "LOADING" },
  });

  const seq = state.ImportLabValuesCalcDFSequence;

  const [result, error] = await ImportLabExternalViewM.post({
    apiToken: controller.apiToken,
    data: {
      df_payroll_group: seq?.dfPayrollGroupId,
      file: seq?.excelFile || "",
      save: true,
      doctor: seq?.doctorId || "",
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });
  } else {
    let activeTab = TAB_KEYS.UPLOAD;

    if (result.items_failed?.length) {
      activeTab = TAB_KEYS.FAIL;
    } else if (result.items_success?.length) {
      activeTab = TAB_KEYS.SUCCESS;
    }

    controller.setState(
      {
        ImportLabValuesCalcDFSequence: {
          ...state.ImportLabValuesCalcDFSequence,
          ...result,
          activeTab,
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPLOAD]: null },
      },
      () => Action(controller, { ...params, action: "INIT" })
    );
  }
};

const HandleClearFile: Handler<Params<"CLEAR_FILE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    ImportLabValuesCalcDFSequence: {
      ...state.ImportLabValuesCalcDFSequence,
      excelFile: null,
      doctorId: null,
      items: [],
    },
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [DOCTOR_SB_ID]: [],
    },
  });
};

export const HandleDownloadTemplate: Handler<Params<"DOWNLOAD_TEMPLATE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_TEMPLATE]: "LOADING" },
  });

  const [result, error] = await DownloadLabExternalTemplateView.get({
    apiToken: controller.apiToken,
    extra: { responseType: "arraybuffer" },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });
  } else {
    downloadXLSX(result, "รายการหักค่าLabแพทย์_template");

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_TEMPLATE]: "SUCCESS" },
    });
  }
};
