import React, { useState, CSSProperties, useEffect } from "react";

// Framework
import TabPenta from "react-lib/frameworks/TabPenta";

// UX
import CardDiseaseSegmentGroupUX from "./CardDiseaseSegmentGroupUX";
import CardHealthMemberDetail from "./CardHealthMemberDetail";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Interface
import { RunSequence, State } from "./sequence/HealthMember";

// Types
type CardHealthMemberProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  HealthMemberSequence?: State["HealthMemberSequence"];
  // controller
  drugOrderQueueController: any;
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
};

// Constant
const ICONS = {
  segment: "/static/images/segment-icon.png",
};

const COLORS = {
  grey: "rgb(204, 204, 204)",
  divider: "rgba(228, 218, 218, 0.5)",
  label: "#979797",
  blue: "#2185d0",
  card_active: "#d6ecf3",
};

const styles = {
  card: {
    padding: "15px 10px",
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: "pointer",
  } as CSSProperties,
  icon_button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
};

export const CARD_HEALTH_MEMBER = "CardHealthMember";

const CardHealthMember = (props: CardHealthMemberProps) => {
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    props.runSequence({
      sequence: "HealthMember",
      restart: true,
      action: "FETCH_SEGMENT_GROUP",
    });
  }, []);

  // Use Memo

  // Handler
  const handleSelected = (data: any) => (e: any) => {
    props.runSequence({
      sequence: "HealthMember",
      data,
      action: "SELECT_SEGMENT_GROUP",
    });
  };

  const handleNewSegment = () => {
    props.setProp("HealthMemberSequence.segmentGroupDetail", null);
  };

  const handleChangeInput = (e: any, data: any) => {
    setSearchText(data.value);
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "HealthMember",
      action: "FETCH_SEGMENT_GROUP",
      name: searchText,
    });
  };

  return (
    <div className="HealthMember" style={{ height: "95vh" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_HEALTH_MEMBER}`, null);
        }}
        error={props.errorMessage?.[CARD_HEALTH_MEMBER]}
        success={null}
      />

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "22.5% 1fr",
          height: "100%",
        }}
      >
        {/* Segment Group List */}
        <div style={{ borderRight: `1px solid ${COLORS.grey}` }}>
          <CardDiseaseSegmentGroupUX
            // data
            searchText={searchText}
            // callback
            onNewSegment={handleNewSegment}
            onChangeInput={handleChangeInput}
            onSearch={handleSearch}
            // Element
            SegmentList={
              <div
                style={{
                  width: "100%",
                  overflow: "auto",
                  height: "calc(100vh - 185px)",
                }}
              >
                {(props.HealthMemberSequence?.segmentGroupList || []).map(
                  (item: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        ...styles.card,
                        backgroundColor:
                          props.HealthMemberSequence?.segmentGroupDetail?.id === item.id
                            ? COLORS.card_active
                            : "",
                      }}
                      onClick={handleSelected(item)}
                    >
                      {item.name}
                    </div>
                  )
                )}
              </div>
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <TabPenta
            // selectedTabIndex={1}
            key={"segment-tab"}
            tabWidth="75px"
            disabled={!props.HealthMemberSequence?.segmentGroupDetail}
            activeStyle={
              !!props.HealthMemberSequence?.segmentGroupDetail
                ? { backgroundColor: COLORS.blue }
                : { backgroundColor: "" }
            }
            activeItemStyle={
              !!props.HealthMemberSequence?.segmentGroupDetail && {
                filter: "brightness(0) invert(1)",
              }
            }
            data={[
              {
                key: "Segment",
                name: (
                  <div style={styles.icon_button}>
                    <img
                      src={ICONS.segment}
                      alt={`segment.icon`}
                      style={{ width: "100%", marginRight: "-0.5rem" }}
                    />
                    <label style={{ color: COLORS.label, marginTop: "-0.25rem" }}>Segment</label>
                  </div>
                ),
                render: () => (
                  <>
                    <CardHealthMemberDetail
                      onEvent={props.onEvent}
                      setProp={props.setProp}
                      // seq
                      runSequence={props.runSequence}
                      HealthMemberSequence={props.HealthMemberSequence}
                      // controller
                      drugOrderQueueController={props.drugOrderQueueController}
                      // CommonInterface
                      errorMessage={props.errorMessage}
                      buttonLoadCheck={props.buttonLoadCheck}
                      // data
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CardHealthMember);
