import React, { useState } from "react";
import { QueueController } from "./Time";
import "./Time.css";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { Button, Icon, Modal, Form } from "semantic-ui-react";
import ErrorMessages from "../common/ErrorMessage";
import { FormattedMessage } from "react-intl";
interface ModPatientEditAppointmentProps {
  apiToken?: string;
  controller: QueueController;
  open: boolean;
  onClose: () => void;
  appointmentId: number | null;
  division: number | null;
}

const ModPatientEditAppointment = (props: ModPatientEditAppointmentProps) => {
  const history = useHistory();
  const isMounted = React.useRef(true);
  const [success, setSuccess] = useState<string|null>(null);
  const [error, setError] = useState<string|any[]|null|{[key: string] : any}>(null);
  const [isLoading, setIsLoading] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState("")

  const handleTextAreaChange = (e: any) => {
    const { value } = e.target;
    setTextAreaValue(value)
  }

  const clearSuccessError = () => {
    setSuccess(null)
    setError(null)
  }

  const handleConfirmAppointment = async () => {
    setIsLoading(true)
    clearSuccessError()
    const [res, err, network] = await props.controller.postRequestChangeDivisionServiceBlock({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      remark: textAreaValue,
      division: props.division,
      patientAppointment: props.appointmentId,
    })
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setSuccess("สร้างนัดหมายสำเร็จ")
        setTimeout(() => {
          if(isMounted.current){
            props.onClose()
          }
        }, 1000)
      } else if (err) {
        if(network.response.status === 500){
          setError(" Error 500: เกิดข้อผิดพลาด")
        } else {
          setError(err);
        }
      }
    }
  }

  return (
    <Modal
      closeOnDimmerClick
      onClose={props.onClose}
      open={props.open}
      size="tiny"
      className="mod-edit-select-appointment"
    >
      <Modal.Header>
        <h4><FormattedMessage id="appoint.ask_cancel_postpone" /></h4>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <ErrorMessages error={error} />
          <ErrorMessages success={success} />
          <h5 ><FormattedMessage id="appoint.specify_reason" /> <span style={{ color: "red" }}>*</span></h5>
          <Form.TextArea onChange={handleTextAreaChange} value={textAreaValue}/>
          <Form.Group inline className="button-group">
            <Form.Field>
              <Button
                content={<FormattedMessage id="common.cancel" />}
                color="grey"
                onClick={props.onClose}
                loading={isLoading}
                disabled={isLoading}
              />
            </Form.Field>
            <Form.Field >
              <Button
                content={<FormattedMessage id="common.confirm" />}
                primary
                loading={isLoading}
                disabled={isLoading || !textAreaValue}
                onClick={handleConfirmAppointment}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

ModPatientEditAppointment.defaultProps = {
  patientData: {},
  controller: {}
};

export default React.memo(ModPatientEditAppointment);
