import React, { useState } from "react";
import MainRuleList from "./MainRuleList";
import DiagComposer from "react-lib/apps/IsHealth/Studio/DiagComposer";

const Studio = (props: any) => {

  const [showProp, setShowProp] = useState({ screen: "MainRuleList", id: null});

  return(
    showProp.screen === "MainRuleList" ?
    <MainRuleList
      apiToken={props.apiToken}
      controller={props.ruleListController}
      onSetState={props.onSetState}
      // Didn't really use onGetDivision in the original code
      // onGetDivision={props.getDivision}
      division={props.division}
      // isVerified={props.isVerified}
      isVerified={props.isVerified} // for demo
      setShowProp={setShowProp}
    />:
    <DiagComposer
      // {...appProps}
      apiToken={props.apiToken}
      division={props.division}
      isVerified={props.isVerified} // for demo
      match={{params: { id: showProp?.id }}}
      controller={props.diagComposerController}
      storage={props.storage}
      onGetDivision={props.getDivision}
      goToRegister={props.goToRegister}
    />    
  )
}

export default Studio