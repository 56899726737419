import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { AsyncDuckDB, AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { tableFromJSON, Table } from "apache-arrow";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import formatComma from "react-lib/utils/formatComma";

import {
  Button,
  Dropdown,
  Form,
  FormField,
  FormGroup,
  FormInput,
  Icon,
  Input,
  Label,
  LabelDetail,
  LabelGroup,
  List,
  ListItem,
  Popup,
} from "semantic-ui-react";
import { DateTextBox } from "react-lib/apps/common";
import ReactTable, { CellInfo, Column, RowInfo } from "react-table-6";
import moment from "moment";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";

type OPDDashboardProps = {
  appController: any;
  runSequence: any;
  setProp: any;
  TimeTrackingSequence: any;
  duckDBLoaded?: boolean;
  searchedItemListWithKey?: any;
  onEvent?: any;
  masterOptions?: any[];
};

const styles = {
  // collapse: {
  //   top: getArrowStyle("top", 2, -27, 133),
  //   bottom: getArrowStyle("bottom", 2, -11, -45),
  // },
  // expend: {
  //   top: getArrowStyle("top", -1, -20, -45),
  //   bottom: getArrowStyle("bottom", -2, -19, 133),
  // },
  not_found: {
    display: "grid",
    textAlign: "center",
    whiteSpace: "pre-line",
    lineHeight: "1.35",
    margin: "0 -0.75rem",
  } as CSSProperties,
};

const BADGE_STATUS_NAME = {
  ทั้งหมด: 0,
  รอคัดกรอง: 1,
  รอซักประวัติ: 2,
  รอพบแพทย์: 3,
  กำลังพบแพทย์: 4,
  รอจำหน่าย: 5,
  รอชำระเงิน: 6,
  รอรับยา: 7,
  มาตามนัด: 8,
  ไม่มาตามนัด: 9,
} as const;

// Feature 67343
const THERSHOLD = {
  [BADGE_STATUS_NAME.รอคัดกรอง]: 30,
  [BADGE_STATUS_NAME.รอซักประวัติ]: 30,
  [BADGE_STATUS_NAME.รอพบแพทย์]: 20,
  [BADGE_STATUS_NAME.รอจำหน่าย]: 20,
  [BADGE_STATUS_NAME.รอชำระเงิน]: 20,
  [BADGE_STATUS_NAME.รอรับยา]: 15,
};

const MULTIPLIER_FACTOR = {
  [BADGE_STATUS_NAME.รอคัดกรอง]: 3,
  [BADGE_STATUS_NAME.รอซักประวัติ]: 2,
  [BADGE_STATUS_NAME.รอพบแพทย์]: 5,
  [BADGE_STATUS_NAME.รอจำหน่าย]: 1,
  [BADGE_STATUS_NAME.รอชำระเงิน]: 4,
  [BADGE_STATUS_NAME.รอรับยา]: 6,
};

const VIEWS_OPTIONS = [
  { key: 0, value: "ALL", text: "ALL" },
  { key: 1, value: "Walk-in", text: "Walk-in" },
  { key: 2, value: "Appointment", text: "Appointment" },
];

type BADGE_TYPE = keyof typeof BADGE_STATUS_NAME;

const OPDDashboard = (props: OPDDashboardProps) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedViews, setSelectedViews] = useState<string>("ALL");
  const [encounterStatus, setEncounterStatus] = useState<BADGE_TYPE>("ทั้งหมด");
  const [waitingCount, setWaitingCount] = useState<any[]>([0, 0, 0, 0, 0, 0, 0, 0, 0]);

  console.log("OPDDashboard props: ", props);
  useEffect(() => {
    props.runSequence({
      sequence: "TimeTracking",
      restart: true,
    });
  }, []);

  // DUCK DB

  useEffect(() => {
    console.log("props.controller", props.appController);
    const warpperInsert = async () => {
      let duckDBConn = props.appController?.data.duckDBConn;

      if (!duckDBConn) {
        return;
      }
      const jsonRowContent = props.TimeTrackingSequence?.patientServiceTimeTracking?.items?.map(
        (i: any) => {
          let { encounter_related_list, ...rest } = i;
          return rest;
        }
      );
      console.log("jsonRowContent: ", jsonRowContent);

      try {
        // Check If table exist
        let duckDBConn = props.appController?.data.duckDBConn;
        let show: Table<any> = await duckDBConn.query(`show all tables;`);
        let showTables = (show?.toArray() || []).map((item: any) => item.toJSON());
        console.table(showTables);

        if (showTables?.length > 0 && showTables.some((i: any) => i.name === "patient")) {
          // Drop Table
          await duckDBConn.query(`drop table patient`);
        }

        /// Use registerFileText (1)
        // await duckDB.registerFileText("patient.json", JSON.stringify(jsonRowContent));
        // await duckConn.insertJSONFromPath("patient.json", { name: "patient" });

        /// Use insertArrowTable (2)
        await duckDBConn.insertArrowTable(tableFromJSON(jsonRowContent), {
          name: "patient",
          schema: "main",
          create: true,
        });

        // let result:Table<any> = await duckConn.query(`describe patient`);
        // let resultTable = (result?.toArray() || []).map((item: any) => item.toJSON());
        // console.table( result?.toArray());
        // console.table( resultTable);

        let read: Table<any> = await duckDBConn.query(`select * from patient`);
        let readTable = (read?.toArray() || []).map((item: any) => item.toJSON());
        console.table(readTable);

        // let count = await props.duckDBConn.query(`select count(*) from patient;`);
        // let countTable = (count?.toArray() || []).map((item: any) => item.toJSON());
        // console.table(countTable);
      } catch (e: any) {
        console.log(e);
      }
    };

    if (
      props.TimeTrackingSequence?.patientServiceTimeTracking?.items?.length > 0 &&
      props.duckDBLoaded
    ) {
      warpperInsert();
    }
  }, [props.TimeTrackingSequence?.patientServiceTimeTracking, props.duckDBLoaded]);

  const setStartTimeAndState = (dataRow?: any) => {
    console.log("dataRow: ", dataRow);

    if (
      dataRow.encounter_created &&
      !dataRow.OPD_SCREEN &&
      !dataRow.OPD_ARRIVE &&
      !dataRow.OPD_CHECK_IN &&
      !dataRow.OPD_CHECK_OUT &&
      !dataRow.OPD_DISCHARGE &&
      !dataRow.OPD_PAID
    ) {
      // รอคัดกรอง
      return {
        stateBatch: BADGE_STATUS_NAME.รอคัดกรอง,
        startTimeBatch: dataRow.encounter_created,
      };
    } else if (
      dataRow.encounter_created &&
      dataRow.OPD_SCREEN &&
      !dataRow.OPD_ARRIVE &&
      !dataRow.OPD_CHECK_IN &&
      !dataRow.OPD_CHECK_OUT &&
      !dataRow.OPD_DISCHARGE &&
      !dataRow.OPD_PAID
    ) {
      // รอซักประวัติ
      return {
        stateBatch: BADGE_STATUS_NAME.รอซักประวัติ,
        startTimeBatch: dataRow.OPD_SCREEN,
      };
    } else if (
      dataRow.encounter_created &&
      dataRow.OPD_ARRIVE &&
      !dataRow.OPD_CHECK_IN &&
      !dataRow.OPD_CHECK_OUT &&
      !dataRow.OPD_DISCHARGE &&
      !dataRow.OPD_PAID
    ) {
      // รอพบแพทย์
      return {
        stateBatch: BADGE_STATUS_NAME.รอพบแพทย์,
        startTimeBatch: dataRow.OPD_ARRIVE,
      };
    } else if (
      dataRow.encounter_created &&
      dataRow.OPD_CHECK_IN &&
      !dataRow.OPD_CHECK_OUT &&
      !dataRow.OPD_DISCHARGE &&
      !dataRow.OPD_PAID
    ) {
      // กำลังพบแพทย์
      return {
        stateBatch: BADGE_STATUS_NAME.กำลังพบแพทย์,
        startTimeBatch: dataRow.OPD_CHECK_IN,
      };
    } else if (
      dataRow.encounter_created &&
      dataRow.OPD_CHECK_OUT &&
      !dataRow.OPD_DISCHARGE &&
      !dataRow.OPD_PAID
    ) {
      // รอจำหน่าย
      return {
        stateBatch: BADGE_STATUS_NAME.รอจำหน่าย,
        startTimeBatch: dataRow.OPD_CHECK_OUT,
      };
    } else if (dataRow.encounter_created && dataRow.OPD_DISCHARGE && !dataRow.OPD_PAID) {
      // รอชำระเงิน
      return {
        stateBatch: BADGE_STATUS_NAME.รอชำระเงิน,
        startTimeBatch: dataRow.OPD_DISCHARGE,
      };
    } else if (
      dataRow.OPD_PAID &&
      dataRow.orders !== undefined && // มียา
      dataRow.datetime !== undefined
    ) {
      // ยังไม่ได้รับยา
      // รอรับยา // กรณีมียา เท่านั้น
      return {
        stateBatch: BADGE_STATUS_NAME.รอรับยา,
        startTimeBatch: dataRow.OPD_PAID,
      };
    } else {
      return { stateBatch: null, startTimeBatch: null };
    }
  };

  const isExpiredEncounter = (encounterCreated: Date) => {
    if (
      typeof props.TimeTrackingSequence?.opdEncounterExpire === "number" &&
      encounterCreated instanceof Date
    ) {
      let currentTime = new Date();
      let expiredDate: Date = new Date(encounterCreated.valueOf());
      expiredDate.setTime(
        encounterCreated?.getTime() +
          props.TimeTrackingSequence?.opdEncounterExpire * 60 * 60 * 1000
      );

      // console.log("waitingTime currentTime: ", currentTime.toISOString());
      // console.log("waitingTime expiredDate: ", expiredDate.toISOString());
      if (currentTime > expiredDate) {
        return true;
      }
    }

    return false;
  };

  const joinData = useMemo(() => {
    let table: any[] = [];

    console.log(
      "OPDDashboard joinData props.TimeTrackingSequence?.drugServiceTimeTracking: ",
      props.TimeTrackingSequence?.drugServiceTimeTracking
    );

    if (props.TimeTrackingSequence?.patientServiceTimeTracking?.items?.length > 0) {
      table = props.TimeTrackingSequence?.patientServiceTimeTracking?.items;

      let keys = Object.keys(props.TimeTrackingSequence?.drugServiceTimeTracking).map((i: any) =>
        Number(i)
      );

      table = table.map((patient: any) => ({
        ...patient,
        ...(keys.includes(patient.encounter) &&
          props.TimeTrackingSequence?.drugServiceTimeTracking[patient.encounter]),
      }));

      // console.log("joinData table: ", table);
      /// Add UIstatus
      table = table.map((i) => {
        return {
          ...i,
          badgeState: setStartTimeAndState(i),
        };
      });

      // Cal Waiting time
      table = table.map((i) => {
        if (i.badgeState?.startTimeBatch && i.badgeState?.stateBatch) {
          let startTime = new Date(i.badgeState?.startTimeBatch);
          let currentTime = new Date();
          let waitingTime = currentTime - startTime;
          let isExpired = isExpiredEncounter(startTime);
          let minutes = 0;

          if (isExpired) {
            minutes = 1440;
          } else {
            minutes = Math.ceil(waitingTime / 1000 / 60);
          }
          return {
            ...i,
            badgeState: {
              ...i.badgeState,
              minutes,
              isExpired,
            },
          };
        }
        return { ...i };
      });

      // Sort
      let arrivalList = table?.filter((i) => i.encounter_created);
      let notArrivalList = table?.filter(
        (i) => !i.encounter_created && i.appointment && i.appointment_datetime
      )

      let priorityList = arrivalList?.filter(i => (i?.badgeState && i?.badgeState?.minutes >= THERSHOLD[i?.badgeState?.stateBatch]) )
      arrivalList = arrivalList?.filter(i => !(priorityList?.map(p => p.encounter).includes(i.encounter)))

      arrivalList = arrivalList.sort((a, b) => {
        return new Date(a.encounter_created) - new Date(b.encounter_created);
      });

      notArrivalList = notArrivalList.sort((a, b) => {
        return new Date(a.appointment_datetime) - new Date(b.appointment_datetime);
      });

      // pick max threadhold
      priorityList = priorityList?.sort((a, b) => b.badgeState?.minutes * MULTIPLIER_FACTOR[a.badgeState?.stateBatch] - a.badgeState?.minutes * MULTIPLIER_FACTOR[a.badgeState?.stateBatch])
      table = [...priorityList, ...arrivalList, ...notArrivalList];

    }
    // console.log("OPDDashboard table: ", table);
    return table;
  }, [
    props.TimeTrackingSequence?.patientServiceTimeTracking,
    props.TimeTrackingSequence?.drugServiceTimeTracking,
  ]);

  useEffect(() => {
    let patients: any[] = joinData || [];
    // console.log("joinData: ", joinData);

    if (joinData?.length === 0) {
      setWaitingCount([0, 0, 0, 0, 0, 0, 0, 0, 0]);
      return;
    }

    switch (selectedViews) {
      case "ALL":
        patients = props.TimeTrackingSequence?.patientServiceTimeTracking?.items;
        break;
      case "Walk-in":
        patients = props.TimeTrackingSequence?.patientServiceTimeTracking?.items?.filter(
          (i) => !i.appointment
        );
        break;
      case "Appointment":
        patients = props.TimeTrackingSequence?.patientServiceTimeTracking?.items?.filter(
          (i) => i.appointment
        );
        break;
      default:
        console.warn("switch case not handle");
        break;
    }

    let counts = [
      patients?.length,
      filterWaitingScreen(patients)?.length,
      filterWaitingArrive(patients)?.length,
      filterWaitingDoctor(patients)?.length,
      filterInExam(patients)?.length,
      filterWaitingCheckout(patients)?.length,
      filterWaitingPaid(patients)?.length,
      filterWaitingDrug(patients)?.length,
      filterArriveByAppointment(patients)?.length,
      filterNotArriveByAppointment(patients)?.length,
    ];

    setWaitingCount(counts);

    if (encounterStatus && selectedViews) {
      let idx = BADGE_STATUS_NAME[encounterStatus];
      if (counts[idx] === 0) {
        setEncounterStatus("ทั้งหมด");
      }
    }
  }, [joinData, selectedViews]);

  const filterWaitingScreen = (a: any[]) => {
    return (a || []).filter(
      (i: any) =>
        i.encounter_created &&
        !i.OPD_SCREEN &&
        !i.OPD_ARRIVE &&
        !i.OPD_CHECK_IN &&
        !i.OPD_CHECK_OUT &&
        !i.OPD_DISCHARGE &&
        !i.OPD_PAID
    );
  };

  const filterWaitingArrive = (a: any[]) => {
    return (a || []).filter(
      (i: any) =>
        i.encounter_created &&
        i.OPD_SCREEN &&
        !i.OPD_ARRIVE &&
        !i.OPD_CHECK_IN &&
        !i.OPD_CHECK_OUT &&
        !i.OPD_DISCHARGE &&
        !i.OPD_PAID
    );
  };

  const filterWaitingDoctor = (a: any[]) => {
    return (a || []).filter(
      (i: any) =>
        i.encounter_created &&
        i.OPD_ARRIVE &&
        !i.OPD_CHECK_IN &&
        !i.OPD_CHECK_OUT &&
        !i.OPD_DISCHARGE &&
        !i.OPD_PAID
    );
  };

  const filterInExam = (a: any[]) => {
    return (a || []).filter(
      (i: any) =>
        i.encounter_created && i.OPD_CHECK_IN && !i.OPD_CHECK_OUT && !i.OPD_DISCHARGE && !i.OPD_PAID
    );
  };

  const filterWaitingCheckout = (a: any[]) => {
    return (a || []).filter(
      (i: any) => i.encounter_created && i.OPD_CHECK_OUT && !i.OPD_DISCHARGE && !i.OPD_PAID
    );
  };

  const filterWaitingPaid = (a: any[]) => {
    return (a || []).filter((i: any) => i.encounter_created && i.OPD_DISCHARGE && !i.OPD_PAID);
  };

  const filterArriveByAppointment = (a: any[]) => {
    return (a || []).filter((i: any) => i.appointment_datetime && i.encounter);
  };

  const filterNotArriveByAppointment = (a: any[]) => {
    return (a || []).filter((i: any) => i.appointment_datetime && !i.encounter);
  };

  const filterWaitingDrug = (a: any[]) => {
    return a?.filter((i) => i.OPD_PAID && i.orders !== undefined && i.datetime !== undefined);
  };

  const appointmentCount = useMemo(() => {
    let count = (props.TimeTrackingSequence?.patientServiceTimeTracking?.items || []).filter(
      (i: any) => i.appointment !== null
    )?.length;
    return count;
  }, [props.TimeTrackingSequence?.patientServiceTimeTracking]);

  const walkInCount = useMemo(() => {
    let count = (props.TimeTrackingSequence?.patientServiceTimeTracking?.items || []).filter(
      (i: any) => i.appointment === null
    )?.length;
    return count;
  }, [props.TimeTrackingSequence?.patientServiceTimeTracking]);

  const showOnlyWaiting = useMemo(() => {
    let statusNameList = Object.keys(BADGE_STATUS_NAME);
    statusNameList = statusNameList?.filter((i) => i !== "กำลังพบแพทย์");
    return statusNameList;
  }, []);

  useEffect(() => {
    let prepareTable: any[] = [];
    switch (encounterStatus) {
      case "ทั้งหมด":
        prepareTable = joinData;
        break;
      case "รอคัดกรอง":
        prepareTable = filterWaitingScreen(joinData);
        break;
      case "รอซักประวัติ":
        prepareTable = filterWaitingArrive(joinData);
        break;
      case "รอพบแพทย์":
        prepareTable = filterWaitingDoctor(joinData);
        break;
      case "รอจำหน่าย":
        prepareTable = filterWaitingCheckout(joinData);
        break;
      case "รอชำระเงิน":
        prepareTable = filterWaitingPaid(joinData);
        break;
      case "รอรับยา":
        prepareTable = filterWaitingDrug(joinData);
        break;
      case "มาตามนัด":
        prepareTable = filterArriveByAppointment(joinData);
        break;
      case "ไม่มาตามนัด":
        prepareTable = filterNotArriveByAppointment(joinData);
        break;
      default:
        console.warn("switch case not handle");
        break;
    }

    switch (selectedViews) {
      case "ALL":
        setTableData(prepareTable);
        break;
      case "Walk-in":
        setTableData(prepareTable?.filter((i) => !i.appointment));
        break;
      case "Appointment":
        setTableData(prepareTable?.filter((i) => i.appointment));
        break;
      default:
        console.warn("switch case not handle");
        break;
    }
  }, [encounterStatus, joinData, selectedViews]);

  // หน่วยตรวจ
  const divisionTypeSeven = useMemo(() => {
    // console.log(
    //   "props.appController?.masterData?.division: ",
    //   props.appController?.data?.masterData?.division
    // );
    if (props.appController?.data?.masterData?.division?.length > 0) {
      let divisionList: any[] = props.appController?.data?.masterData?.division
        ?.filter((div: any) => {
          return div.type_label === "หน่วยตรวจ";
        })
        .map((div: any) => div.id);

      let divisions = props.masterOptions?.division?.filter((div: any) => {
        return (divisionList || []).includes(div.key);
      });

      return [{ key: 0, value: 0, text: "แสดงทั้งหมด" }, ...divisions];
    }
    return [];
  }, [props.masterOptions?.division]);

  const doctorOptions = useMemo(() => {
    let masterOptions = [];
    if (props.masterOptions?.doctor?.length > 0) {
      masterOptions = [{ key: 0, value: 0, text: "แสดงทั้งหมด" }, ...props.masterOptions?.doctor];
    }
    return masterOptions;
  }, [props.masterOptions?.doctor]);

  // Callback
  const handleSelectedItem = useCallback(
    (data: any) => async (value: any) => {
      props.setProp(`TimeTrackingSequence.filters.${data.name}`, value || null);
    },
    [props.searchedItemListWithKey]
  );

  const extractHoursMins = (value: string, badgeState?: any, waiting?: number) => {
    // console.log("waiting: ", waiting);

    if (typeof value === "string" && value?.length > 10) {
      return <div> {moment(value)?.format("HH:mm")} </div>;
    } else if (
      typeof waiting === "number" &&
      badgeState?.stateBatch === waiting &&
      badgeState?.minutes
    ) {
      // let startTime = new Date(badgeState.startTimeBatch);

      // let currentTime = new Date();
      // let waitingTime = currentTime - startTime;
      // let isExpired = isExpiredEncounter(startTime);
      // let minutes = 0;

      // if (isExpired) {
      //   minutes = 1440;
      // } else {
      //   minutes = Math.ceil(waitingTime / 1000 / 60);
      // }
      let redText = false

      if (badgeState?.minutes >= THERSHOLD[badgeState.stateBatch] && !badgeState.isExpired) {
        redText = true
      }

      return (
        <div
          style={{
            color: "#0B5BAD",
            flexDirection: "column",
            display: "flex",
            fontSize: "xx-small",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ width: "fit-content" }}>
              <Icon 
                name="time" 
                size="large" 
                {...(redText && {color: "red"})}
              />
            </div>
            <div style={{ width: "fit-content", fontWeight: "bold", marginTop: "2px",  ...(redText && {color: "red"})}}>
              {badgeState?.minutes >= 0
                ? `${formatComma(badgeState?.minutes, "en-US", false)} นาที`
                : null}
            </div>
          </div>

          <div style={{ fontWeight: "bold", ...(redText && {color: "red"}) }}>
            {Object.keys(BADGE_STATUS_NAME).find(
              (key) => BADGE_STATUS_NAME[key] === badgeState.stateBatch
            )}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const columns: any[] = [
    {
      Header: "EN No.",
      accessor: "encounter_number",
      width: 80,
    },
    {
      Header: "HN",
      id: "hn",
      width: 100,
    },
    {
      Header: "ชื่อ นามสกุล",
      accessor: "patient_name",
      minWidth: 150,
    },
    {
      Header: "จำนวนคลินิก",
      accessor: "encounter_related_list",
      width: 100,
      Cell: (cellInfo: CellInfo) => {
        // console.log("encounter_related_list props: ", cellInfo);
        let relatedTable: any[] = [];
        let currentDivision = cellInfo.original.division;
        if (currentDivision) {
          let sameDivision = (cellInfo.value || []).filter(
            (i) => currentDivision && i.division === currentDivision
          );
          if (sameDivision?.length > 0) {
            sameDivision.sort(
              (a, b) => new Date(a.encounter_created) - new Date(b.encounter_created)
            );
            // console.log("encounter_related_list sameDivision: ", sameDivision);
            relatedTable = [...sameDivision];
          }
        }
        let restDivision = (cellInfo.value || []).filter(
          (i) => !(currentDivision && i.division === currentDivision)
        );
        restDivision.sort((a, b) => new Date(a.encounter_created) - new Date(b.encounter_created));
        // console.log("encounter_related_list restDivision: ", restDivision);
        relatedTable = relatedTable.concat(restDivision);
        // console.log("encounter_related_list relatedTable: ", relatedTable);

        return cellInfo.value?.length > 0 ? (
          <Popup
            flowing
            content={
              <List bulleted size="tiny">
                {(relatedTable || [])
                  ?.sort((a, b) => {})
                  ?.map((related) => {
                    return (
                      <ListItem style={{ fontSize: "small" }}>
                        <div>
                          <span style={{ color: "#295DB3", fontWeight: "bold" }}>
                            {related.division_name ? related.division_name : "ไม่ระบุ"}
                          </span>{" "}
                          {related.doctor_name ? `[${related.doctor_name}] ` : ""}{" "}
                        </div>
                        <div>
                          {" "}
                          {related.encounter_status_label} ({related.encounter})
                        </div>
                      </ListItem>
                    );
                  })}
              </List>
            }
            trigger={
              <Label circular color="teal">
                {cellInfo.value?.length}
              </Label>
            }
          />
        ) : null;
      },
    },
    {
      Header: "แผนก",
      accessor: "division_name",
      width: 200,
    },
    {
      Header: "แพทย์เจ้าของไข้",
      accessor: "doctor_name",
      minWidth: 150,
    },
    {
      Header: "นัดหมาย",
      accessor: "appointment_datetime",
      width: 70,
      Cell: (cellInfo: CellInfo) => extractHoursMins(cellInfo.value),
    },
    {
      Header: "เวลามาถึง",
      accessor: "encounter_created",
      width: 70,
      Cell: (cellInfo: CellInfo) => extractHoursMins(cellInfo.value),
    },
    {
      Header: "คัดกรอง",
      accessor: "OPD_SCREEN",
      width: 90, //70,
      Cell: (cellInfo: CellInfo) =>
        extractHoursMins(cellInfo.value, cellInfo.original.badgeState, BADGE_STATUS_NAME.รอคัดกรอง),
    },
    {
      Header: "ซักประวัติ",
      accessor: "OPD_ARRIVE",
      width: 90, //70,
      Cell: (cellInfo: CellInfo) =>
        extractHoursMins(
          cellInfo.value,
          cellInfo.original.badgeState,
          BADGE_STATUS_NAME.รอซักประวัติ
        ),
    },
    {
      Header: "เข้าพบแพทย์",
      accessor: "OPD_CHECK_IN",
      width: 120, //100,
      Cell: (cellInfo: CellInfo) =>
        extractHoursMins(cellInfo.value, cellInfo.original.badgeState, BADGE_STATUS_NAME.รอพบแพทย์),
    },
    {
      Header: "ออกจากห้องตรวจ",
      accessor: "OPD_CHECK_OUT",
      width: 120,
      Cell: (cellInfo: CellInfo) =>
        extractHoursMins(
          cellInfo.value,
          cellInfo.original.badgeState,
          BADGE_STATUS_NAME.กำลังพบแพทย์
        ),
    },
    {
      Header: "ออกจากแผนก",
      accessor: "OPD_DISCHARGE",
      width: 100,
      Cell: (cellInfo: CellInfo) =>
        extractHoursMins(cellInfo.value, cellInfo.original.badgeState, BADGE_STATUS_NAME.รอจำหน่าย),
    },
    {
      Header: "ชำระเงิน",
      accessor: "OPD_PAID",
      width: 90, //70,
      Cell: (cellInfo: CellInfo) =>
        extractHoursMins(
          cellInfo.value,
          cellInfo.original.badgeState,
          BADGE_STATUS_NAME.รอชำระเงิน
        ),
    },
    {
      Header: "รับยา",
      accessor: "datetime",
      width: 90, //70,
      Cell: (cellInfo: CellInfo) => {
        // console.log("รับยา cellInfo: ", cellInfo);
        // มียา คือ มี key encounter
        if (
          cellInfo.original.OPD_PAID &&
          cellInfo.original.datetime === undefined &&
          cellInfo.original.orders === undefined
        ) {
          return (
            <div
              style={{
                fontSize: "x-small",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              -
            </div>
          );
        } else if (
          cellInfo.original.OPD_PAID &&
          cellInfo.original.datetime !== undefined &&
          cellInfo.original.orders !== undefined
        ) {
          return extractHoursMins(
            cellInfo.value,
            cellInfo.original.badgeState,
            BADGE_STATUS_NAME.รอรับยา
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: "ระยะเวลา",
      width: 70,
      accessor: "waitingTime",
      Cell: (cellInfo: CellInfo) => {
        if (cellInfo.original.encounter_created) {
          // console.log('waitingTime cellInfo.original.encounter_created: ', cellInfo);
          // นับจาก encounter_created -> encounter หมดอายุ หรือ ไม่มียา นับจาก PAID หรือ มียา นับจาก datetime field(รับยาไปแล้ว)
          let startTime = new Date(cellInfo.original.encounter_created);
          // console.log('waitingTime startTime: ', startTime);
          let isExpired = isExpiredEncounter(startTime);
          // console.log('waitingTime isExpired: ', isExpired);
          let minutes: number | undefined = 0;
          let diffTime: number = 0;

          if (cellInfo.original.encounter && isExpired) {
            // หมดอายุ โดยไม่เข้า flow ไหนเลย
            // console.log('หมดอายุ โดยไม่เข้า flow ไหนเลย: ');
            minutes = 1440;
          } else if (
            cellInfo.original.orders === undefined &&
            cellInfo.original.datetime === undefined &&
            cellInfo.original.OPD_PAID
          ) {
            // ไม่มียา นับถึงจ่ายเงิน
            // console.log('waitingTime ไม่มียา นับถึงจ่ายเงิน: cellInfo.original.OPD_PAID:', cellInfo.original.OPD_PAID);
            // console.log('waitingTime ไม่มียา นับถึงจ่ายเงิน: startTime:', startTime);
            diffTime = new Date(cellInfo.original.OPD_PAID) - startTime; // นับถึงจ่ายเงิน
            minutes = Math.ceil(diffTime / 1000 / 60); // นับถึงเวลาปัจจุบัน
          } else if (
            cellInfo.original.orders !== undefined &&
            cellInfo.original.datetime !== undefined &&
            cellInfo.original.OPD_PAID
          ) {
            // มียา รับยาแล้ว นับถึงรับยา
            // console.log('waitingTime มียา รับยาแล้ว นับถึงรับยา cellInfo.original.datetime: ', cellInfo.original.datetime);
            diffTime = new Date(cellInfo.original.datetime) - startTime; // นับถึงรับยา
            minutes = Math.ceil(diffTime / 1000 / 60); // นับถึงเวลาปัจจุบัน
          } else {
            // เคสอื่นๆ
            //  console.log('waitingTime');
            diffTime = new Date() - startTime;
            minutes = Math.ceil(diffTime / 1000 / 60); // นับถึงเวลาปัจจุบัน
          }
          // console.log('waitingTime minutes: ', minutes);

          // console.log(
          //   "waitingTime props.TimeTrackingSequence?.opdEncounterExpire : ",
          //   props.TimeTrackingSequence?.opdEncounterExpire
          // );

          // ถ้าไม่มียา นับจาก PAID , ถ้ามียา นับจาก date

          return (
            <div
              style={{
                fontSize: "x-small",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {`${formatComma(minutes, "en-US", false)} นาที`}
            </div>
          );
        } else {
          return <></>;
        }

        // }
      },
    },
  ];

  return (
    <div>
      <div style={{ margin: "0px 5px" }}>
        <Form>
          <FormGroup>
            <FormField>
              <label>
                วันที่<span style={{ color: "red" }}>*</span>
              </label>
              <DateTextBox
                value={props.TimeTrackingSequence?.filters?.date}
                onChange={(dateText: string) => {
                  props.setProp("TimeTrackingSequence", {
                    ...props.TimeTrackingSequence,
                    filters: {
                      ...props.TimeTrackingSequence?.filters,
                      date: dateText,
                    },
                  });
                }}
              />
            </FormField>

            <FormField width={4}>
              <label>แผนก</label>
              <Dropdown
                selection
                options={divisionTypeSeven}
                value={props.TimeTrackingSequence?.filters?.divisionId}
                onChange={(e, data) => {
                  props.setProp("TimeTrackingSequence", {
                    ...props.TimeTrackingSequence,
                    filters: {
                      ...props.TimeTrackingSequence?.filters,
                      divisionId: data.value,
                    },
                  });
                }}
              />
            </FormField>

            <FormField width={4}>
              <label>แพทย์เจ้าของไข้</label>
              <Dropdown
                selection
                options={doctorOptions}
                value={props.TimeTrackingSequence?.filters?.doctorId}
                onChange={(e, data) => {
                  props.setProp("TimeTrackingSequence", {
                    ...props.TimeTrackingSequence,
                    filters: {
                      ...props.TimeTrackingSequence?.filters,
                      doctorId: data.value,
                    },
                  });
                }}
              />
            </FormField>

            <FormField width={4}>
              <label> ค้นหาจากผู้ป่วย</label>
              <SearchBoxDropdown
                onEvent={props.onEvent}
                // config
                type="HNFirstLast"
                id="OPDDash"
                style={{ width: "100%" }}
                fluid={true}
                useWithKey={true}
                icon="search"
                limit={20}
                inline={true}
                placeholder={"HN, First name, Last name"}
                noResultsMessage={
                  <div style={styles.not_found}>
                    <span>ไม่พบข้อมูล</span>
                    <span>กรุณาทำการค้นหาใหม่อีกครั้ง</span>
                  </div>
                }
                // Select
                searchedItemListWithKey={props.searchedItemListWithKey}
                selectedItem={props.TimeTrackingSequence?.filters?.patientSearch || null}
                iconStyle={{ marginTop: "-0.85rem" }}
                setSelectedItem={handleSelectedItem({
                  name: "patientSearch",
                  type: "Patient",
                })}
              />

              {/* <Input
                onChange={(e, { value }) => {
                  props.setProp("TimeTrackingSequence", {
                    ...props.TimeTrackingSequence,
                    filters: {
                      ...props.TimeTrackingSequence?.filters,
                      patientSearch: value,
                    },
                  });
                }}
                value={props.TimeTrackingSequence?.filters?.patientSearch}
                placeholder="XX-XX-XXXXXX"
              /> */}
            </FormField>

            <FormField
              style={{
                display: "flex",
                alignItems: "flex-end",
                padding: "6px 0px",
                margin: "0px 6px",
              }}
            >
              <Button
                loading={props.TimeTrackingSequence?.searchLoading}
                color="blue"
                disabled={!props.TimeTrackingSequence?.filters?.date}
                onClick={() => {
                  props.runSequence({ sequence: "TimeTracking", action: "SEARCH" });
                }}
              >
                ค้นหา
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <div style={{ margin: "6px 0px", display: "flex", alignItems: "center", gap: "10px" }}>
          <div>
            <Label color="teal">
              <Icon name="calendar" /> {appointmentCount}
              <LabelDetail>Appointment</LabelDetail>
            </Label>
            <Label color="teal">
              <Icon name="male" /> {walkInCount}
              <LabelDetail> Walk-in</LabelDetail>
            </Label>
            <Label color="teal">
              <Icon name="group" /> {appointmentCount + walkInCount}
              <LabelDetail>Total</LabelDetail>
            </Label>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            View:
            <Dropdown
              selection
              options={VIEWS_OPTIONS}
              value={selectedViews}
              onChange={(e, { value }) => {
                setSelectedViews(value);
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            {showOnlyWaiting.map((name) => {
              // console.log("waitingCount: ", waitingCount);
              // console.log("name: ", name);
              return (
                <Label
                  style={{
                    ...(waitingCount?.[BADGE_STATUS_NAME[name]] > 0 && {
                      cursor: "pointer",
                      display: "flex",
                      gap: "4px",
                    }),
                    ...(waitingCount?.[BADGE_STATUS_NAME[name]] === 0 && { color: "#999999" }),
                  }}
                  {...(encounterStatus === name && { color: "yellow" })}
                  onClick={() => {
                    if (waitingCount?.[BADGE_STATUS_NAME[name]] <= 0) {
                      return;
                    }
                    setEncounterStatus(name);
                  }}
                >
                  {selectedViews === "Walk-in" && name.includes("นัด") ? null : (
                    <Icon
                      name="group"
                      color={waitingCount?.[BADGE_STATUS_NAME[name]] === 0 ? "lightgrey" : "red"}
                    />
                  )}

                  {selectedViews === "Walk-in" && name.includes("นัด")
                    ? "-"
                    : waitingCount?.[BADGE_STATUS_NAME[name]]}

                  <LabelDetail
                    style={{
                      ...(waitingCount?.[BADGE_STATUS_NAME[name]] === 0 && { color: "#999999" }),
                    }}
                  >
                    {name}
                  </LabelDetail>
                </Label>
              );
            })}
          </div>
        </div>

        <ReactTable
          data={tableData}
          columns={columns}
          minRows={10}
          defaultPageSize={props.TimeTrackingSequence?.tableData?.length}
          className="-striped -highlight"
          showPagination={false}
          getTdProps={(state: any, rowInfo?: RowInfo, col?: Column, instance?: any) => {
            // console.log("getTdProps instance: ", instance);
            // console.log("getTdProps col: ", col);
            // console.log("getTdProps rowInfo: ", rowInfo);
            // console.log("getTdProps state: ", state);
            let TIME_COL_ID: string[] = [
              "encounter_related_list",
              "appointment_datetime",
              "encounter_created",
              "OPD_SCREEN",
              "OPD_ARRIVE",
              "OPD_CHECK_IN",
              "OPD_CHECK_OUT",
              "OPD_DISCHARGE",
              "OPD_PAID",
              "receivedDrugTime",
            ];

            return {
              onClick: (e) => {
                // if (col?.Header === undefined) {
                //   const { expanded } = state;
                //   const path = rowInfo?.nestingPath[0];
                //   const diff = { [path]: expanded[path] ? false : true };
                //   instance.setState({
                //     expanded: {
                //       ...expanded,
                //       ...diff,
                //     },
                //   });
                // } else {
                //   alert(`Hello ${rowInfo?.original.firstName} ${rowInfo?.original.lastName}`);
                // }
              },
              style: {
                ...(TIME_COL_ID.includes(col?.id as string) && {
                  textAlign: "center",
                  padding: 0,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }),
              },
            };
          }}
          NoDataComponent={() => {
            return <div> </div>;
          }}

          // SubComponent={(row) => {
          //   return (
          //     <div style={{ padding: "20px" }}>
          //       <em>You can put any component you want here.</em>
          //       <br />
          //       <br />
          //       <div style={{ color: "blue" }}>
          //         Name: {row.original.firstName} {row.original.lastName}
          //       </div>
          //     </div>
          //   );
          // }}
        />
      </div>
    </div>
  );
};

// const [date, setDate] = useState("02/03/2566") // dd/mm/yyyy BE.
// const [divisionId, setDivisionId] = useState(null)
// const [doctorId, setDoctorId] = useState(null)
// const [patientSearch, setPatientSearch] = useState("")
// const [patientId, setPatientId] = useState(null)

// console.log('OPDDashboard props: ', props);

// let duckDB = props.appController.duckDB
// let duckConn = props.appController.duckConn

// const query = async (sql: string, con: AsyncDuckDBConnection | null) => {
//   if (!con) return [];
//   let res = await con.query(sql);
//   let output = (res?.toArray() || []).map((item: any) => item.toJSON());
//   return output
// }

// useEffect(() => {

//   const warpperInsert = async () => {
//     const jsonRowContent = [
//       { "col1": 1, "col2": "foo" },
//       { "col1": 2, "col2": "bar" },
//   ];
//     await duckDB.registerFileText(
//         'rows.json',
//         JSON.stringify(jsonRowContent),
//     );
//     await duckConn.insertJSONFromPath('rows.json', { name: 'rows' });

//     // let a = await duckConn.query(`DESCRIBE SELECT * FROM rows *`)
//     // console.log("a", a)

//     let output = query(`select * from rows`, duckConn)
//     console.log('output: ', output);

//   }

//   // const warpperQueuy = async () => {
//   //   // Either materialize the query result
//   //   await duckConn.query<{ v: arrow.Int }>(`
//   //     SELECT * FROM generate_series(1, 100) t(v)
//   //   `);
//   //   // ..., or fetch the result chunks lazily
//   //   for await (const batch of await duckConn.send<{ v: arrow.Int }>(`
//   //     SELECT * FROM generate_series(1, 100) t(v)
//   //   `)) {
//   //     // ...
//   //   }
//   // }

//   warpperInsert()
//   // warpperQueuy()

// }, [])

//   const UpdatePriorityDict: Handler = async (controller, {priorityDict}) => {
//     let { duckCon } = controller.data;
//     // Prepare priority_list table
//     const priorityItems: any[] = Object.values(priorityDict)
//     .map((item: any) => ({
//         ...item,
//         issues: (item?.issues || []).join(","),
//         releases: (item?.releases || []).join(","),
//     }));
//     try {
//         await duckCon.query(`drop table if exists priority;`)
//     } catch {}
//     try {
//         await duckCon.insertArrowTable(tableFromJSON(priorityItems),
//             {name: "priority", schema: "main", create: true});
//     } catch (e: any) { console.log(e); };
//     try { await duckCon.query(`
//         create or replace table priority_list as
//         from priority
//         select
//         * replace (
//             list_filter(issues.split(','), x -> x !='') as issues,
//             list_filter(releases.split(','), x-> x !='') as releases
//         );`)
//     } catch (e: any) { console.log(e) };
//     controller.setState({priorityDict: Object.assign({}, priorityDict)});
// }

export default OPDDashboard;
