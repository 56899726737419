import React, {useEffect ,useState, useRef, useCallback} from 'react'
import Webcam from "react-webcam";
import {
  Button,
  Modal,
  Icon,
} from "semantic-ui-react";

const videoConstraints = {
  width: 360,
  height: 480,
  facingMode: "user"
};



const WebcamComponent = ({ open, onClose , setNewImage}) => {


  const [isCapture, setIsCapture] = useState(false);
  const [imageSrc, setImageSrc] = useState(null)

  const webcamRef = useRef(null);
  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImageSrc(imageSrc)
      setIsCapture(true)
    },
    [webcamRef]
  );

    
  useEffect(() => {
    setIsCapture(false)
  }, [open]);

  
  const confirmImage = () => {
    setNewImage(imageSrc)
    onClose()
  }
  
  return (
    <Modal open={open} basic size="tiny"  onClose={onClose} >
      {!isCapture ? (
        <>
          <Modal.Header style={{textAlign:"center"}} >Take a photo</Modal.Header>
          <Modal.Description style={{ margin:"auto",textAlign: "center", backgroundColor: "grey", width:"360px", height:"480px" }}>
            <Webcam
              audio={false}
              width={360}
              height={480}

              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          </Modal.Description>
          <Modal.Actions style={{ textAlign: "center", marginTop: "-15px" }}>
            {/* <Button color="green" onClick={capture} inverted>
              <Icon name="camera" /> Capture photo
            </Button> */}
            <div className="ui centered padded grid" style={ {width: "360px", backgroundColor: "black", opacity: "0.85", display: "inline-block"} }>
            <div className="ui centered row" style={{display: "flex"}}>
                <button onClick={capture}  className="ui circular icon button" style={{background: "transparent"}}>
                    <i className="large photo icon" style={{color:"white"}}></i>
                </button>
            </div>
            </div>
            {/* <Button color="red" onClick={onClose} inverted>
              <Icon name="cancel" /> Cancel
            </Button> */}
          </Modal.Actions>
        </>
      ) : (
        <>
          <Modal.Header style={{textAlign:"center"}} >Please confirmed this Image</Modal.Header>
          <Modal.Description style={{ textAlign: "center" }}>
            <img src={imageSrc} />
          </Modal.Description>

          <Modal.Actions style={{ textAlign: "center" , marginTop: "-15px"}}>
          <div className="ui centered padded grid" style={ {width: "360px", backgroundColor: "black", opacity: "0.85", display: "inline-block"} }>
            <div className="centered row" style={{ display: "flex"}}>
                <button onClick={()=>{ setIsCapture(false)}}  className={"ui icon blue button"}  style={{background:"transparent"}}>
                    <i className="large repeat blue icon"></i>
                </button>
                <div className="column"></div>
                <button  onClick={confirmImage} className="ui icon button" data-type="use" style={{ background:"transparent"}}>
                    <i className="large checkmark green icon"></i>
                </button>
            </div>
            </div>
            {/* <Button color="green" onClick={confirmImage} inverted>
              <Icon name="check" /> OK
            </Button>
            <Button color="red" onClick={()=>{ setIsCapture(false)}} inverted>
              <Icon name="cancel" /> Cancel
            </Button> */}
          </Modal.Actions>

        </>
      )}
    </Modal>
  );
};

export default WebcamComponent ;
