import React, {
  FC,
  ForwardRefRenderFunction,
  forwardRef,
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
  useImperativeHandle,
  InputHTMLAttributes,
} from "react";
import ReactTable from "react-table-6";
import {
  Input,
  Button,
  Dropdown,
  DropdownProps,
  DropdownItemProps,
  Dimmer,
  Loader,
  Modal,
  Header,
  Icon,
  Form,
} from "semantic-ui-react";
import axios from "axios";
import * as CONSTANT from "../../../utils/constant";

import { DateTextBox } from "../../common";
import Cookies from "js-cookie";
import * as Util from "../../../utils";
import moment from "moment";
interface AppointmentDetail {
  hn: string;
  patient_name: string;
  patient_dob: string;
  appointment_datetime: string;
  division_name: string;
  doctor_name: string;
}

interface AppointmentDashBoardSearchBarProps {
  changeDOB: (string) => void;
  changeStartDate: (string) => void;
  changeToDate: (string) => void;
  onSearch: () => void;
  selectLocation: (e: SyntheticEvent, data: DropdownProps) => void;
  startDate: string;
  toDate: string;
  dob: string;
  locationOptions: DropdownItemProps[];
  handleSearchKeyPress: () => void;
}

type AppointmentDashboardHandle = {
  getSearchHN: () => string;
  getSearchDoctorName: () => string;
  getSearchPatientName: () => string;
};

interface SemanticUIReactInput extends Input {
  inputRef: React.MutableRefObject<HTMLInputElement>;
}

const AppointmentDashBoardSearchBarFunction: ForwardRefRenderFunction<
  AppointmentDashboardHandle,
  AppointmentDashBoardSearchBarProps
> = (props, ref) => {
  const [options, setOptions] = useState([]);
  const searchHNRef = useRef<SemanticUIReactInput>(null);
  const searchDoctorNameRef = useRef<SemanticUIReactInput>(null);
  const SearchPatientNameRef = useRef<SemanticUIReactInput>(null);

  useImperativeHandle(ref, () => ({
    getSearchHN: () => {
      return searchHNRef.current.inputRef.current.value;
    },
    getSearchDoctorName: () => {
      return searchDoctorNameRef.current.inputRef.current.value;
    },
    getSearchPatientName: () => {
      return SearchPatientNameRef.current.inputRef.current.value;
    },
  }));

  useEffect(() => {
    let dropdownOptions = props.locationOptions.map((item) => {
      let prepare = {
        key: item.id,
        value: item.id,
        text: item.name,
      };
      return prepare;
    });
    setOptions(dropdownOptions);
  }, [props.locationOptions]);

  return (
    <div className="appointment-db-searchbar">
      <div className="filter-row">
        <div>
          <b> HN </b>
          <Input
            className="width100"
            ref={searchHNRef}
            placeholder="XX-XX-XXXXXX"
            onKeyPress={props.handleSearchKeyPress}
          />
        </div>
        <div>
          <b> ชื่อ-สกุล </b>
          <Input
            className="width100"
            ref={SearchPatientNameRef}
            placeholder="Search..."
            onKeyPress={props.handleSearchKeyPress}
          />
        </div>
        <div className="birthdate">
          <b> วันเกิด </b>
          <DateTextBox value={props.dob} onChange={props.changeDOB} />
        </div>
      </div>
      <div className="filter-row">
        <div>
          <b> Location </b>
          <Dropdown
            className="width100"
            selection
            placeholder="Search..."
            options={options}
            onChange={props.selectLocation}
            clearable
          />
        </div>
        <div>
          <b>แพทย์</b>
          <Input
            className="width100"
            ref={searchDoctorNameRef}
            placeholder="Search..."
          />
        </div>
        <div className="date-range daypicker hilight">
          <b>วันที่ขอนัดหมาย</b>&ensp;
          <b> เริ่ม </b>
          <DateTextBox
            value={props.startDate}
            onChange={(text:any) => {
              if (Util.formatDateToYYYYMMDD(text) >= Util.formatDateToYYYYMMDD(props.toDate)) {
                props.changeToDate("")
              }
              props.changeStartDate(text)
            }}
            maxDate={Util.formatDate(moment().add(1, "months"))}
          />
          <b> ถึง </b>
          <DateTextBox
            minDate={moment(props.startDate,'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY')}
            value={props.toDate}
            onChange={(text:any )=>{
              if (moment(Util.formatDateToYYYYMMDD(props.startDate)).isAfter(Util.formatDateToYYYYMMDD(text))) {
                props.changeToDate("")
              } else {
                props.changeToDate(text)
              }
            }}
            maxDate={Util.formatDate(moment().add(1, "months"))}
          />
        </div>
      </div>
      <div className="search-button-area">
        <Button className="search-button" onClick={props.onSearch}>
          ค้นหา
        </Button>
      </div>
    </div>
    // <div className="appointment-db-searchbar">
    //   <div className="search-component">
    //     <div className="key">
    //       <label> HN </label>
    //     </div>
    //     <div className="value">
    //       <Input ref={searchHNRef} placeholder="XX-XX-XXXXXX" />
    //     </div>
    //   </div>

    //   <div className="search-component">
    //     <div className="key">
    //       <label>ชื่อ-สกุล</label>
    //     </div>
    //     <div className="value">
    //       <Input ref={SearchPatientNameRef} placeholder="Search..." />
    //     </div>
    //   </div>

    //   <div className="search-component-last">
    //     <div className="key">
    //       <label>วันเกิด</label>
    //     </div>
    //     <div className="value">
    //       <DateTextBox value={props.dob} onChange={props.changeDOB} />
    //     </div>
    //   </div>
    //   <div className="break"> </div>

    //   <div className="search-component">
    //     <div className="key">
    //       <label> Location </label>
    //     </div>
    //     <div className="value">
    //       <Dropdown
    //         selection
    //         fluid
    //         placeholder="Search..."
    //         options={options}
    //         onChange={props.selectLocation}
    //         clearable
    //       />
    //     </div>
    //   </div>

    //   <div className="search-component">
    //     <div className="key">
    //       <label>แพทย์</label>
    //     </div>
    //     <div className="value">
    //       <Input ref={searchDoctorNameRef} placeholder="Search..." />
    //     </div>
    //   </div>

    //   <div className="search-component-last">
    //     <div className="key">
    //       <label>วันที่ขอนัดหมาย</label>
    //     </div>
    //     <div className="daypicker hilight">
    //       <label> เริ่ม </label>
    //       <DateTextBox
    //         value={props.startDate}
    //         onChange={props.changeStartDate}
    //         maxDate={Util.formatDate(moment().add(1, "months"))}
    //       />
    //     </div>
    //     <div className="daypicker hilight">
    //       <label> ถึง </label>
    //       <DateTextBox
    //         value={props.toDate}
    //         onChange={props.changeToDate}
    //         maxDate={Util.formatDate(moment().add(1, "months"))}
    //       />
    //     </div>
    //   </div>
    //   <div className="break"> </div>
    //   <div className="search-button-area">
    //     <Button className="search-button" onClick={props.onSearch}>
    //       ค้นหา
    //     </Button>
    //   </div>
    // </div>
  );
};

const AppointmentDashBoardSearchBar = forwardRef(
  AppointmentDashBoardSearchBarFunction
);

interface AppointmentListTableProps {
  lists: AppointmentDetail[];
}

const AppointmentListTable: FC<AppointmentListTableProps> = (props) => {
  const headerStyle = {
    padding: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columns = [
    {
      Header: "HN",
      accessor: "hn", // String-based value accessors!
      headerStyle: headerStyle,
      Cell: (props) => <span className="hn">{props.value}</span>,
    },
    {
      Header: "ชื่อ-นามสกุล",
      accessor: "patient_name",
      headerStyle: headerStyle,
      Cell: (props) => <span className="full-name">{props.value}</span>,
    },
    {
      Header: "วันเกิด",
      accessor: "patient_dob",
      headerStyle: headerStyle,
      Cell: (props) => (
        <span className="dob">
          {props.value?.indexOf("/") >= 0 
            ? Util.formatDate(moment(props.value, "DD/MM/YYYY"))
            : Util.formatDate(moment(props.value))}
        </span>
      ),
    },
    {
      Header: "วันที่ขอนัดหมาย",
      accessor: "appointment_datetime",
      headerStyle: headerStyle,
      Cell: (props) => {
        let time = props.original.start_time.split(":");
        time.pop();
        return (
          <span className="request-adt">{`${Util.formatDate(
            moment(props.original.date)
          )}, ${time.join(":")} น.`}</span>
        );
      },
    },
    {
      Header: "Location",
      accessor: "division_name",
      headerStyle: headerStyle,
      Cell: (props) => <span className="location">{props.value}</span>,
    },
    {
      Header: "แพทย์",
      accessor: "doctor_name",
      headerStyle: headerStyle,
      Cell: (props) => <span className="doctor-name">{props.value}</span>,
    },
  ];

  return (
    <div className="main-table">
      <ReactTable
        className="noHover"
        style={{ height: "450px" }}
        data={props.lists}
        columns={columns}
        pageSize={props.lists.length > 5 ? props.lists.length : 5}
        showPagination={false}
        noDataText="ไม่พบข้อมูลที่ค้นหา"
      />
    </div>
  );
};

interface AppointmentDashboardProps {
  controller: any;
  denyQParamsIsTelemed?: boolean;
}

const IsCancelCalledAPI = (props) => {
  return (
    <Modal closeIcon open={props.open} onClose={() => props.setOpen(false)}>
      <Header icon="cancel" content="Cancel this search ?" />
      <Modal.Content>
        <p>ต้องการยกเลิกการค้นหานี้ ใช่หรือไม่</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <Icon name="remove" /> ไม่
        </Button>
        <Button
          color="green"
          onClick={() => {
            props.answer(true);
            props.setOpen(false);
          }}
        >
          <Icon name="checkmark" /> ใช่
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const AppointmentDashboard: FC<AppointmentDashboardProps> = (props) => {
  const [appointmentList, setAppointmentList] = useState<AppointmentDetail[]>(
    []
  );
  const [locationOptions, setLocationOptions] = useState([]);

  const isMounted = useRef(true);
  const searchBarRef = useRef(null);

  const [dob, setDOB] = useState<string>("");
  const [searchStartDate, setSearchStartDate] = useState<string>(
    Util.formatDate(moment())
  );
  const [searchToDate, setSearchToDate] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openIsCancel, setOpenIsCancel] = useState<boolean>(false);

  const [source, setSource] = useState(null);

  const handleOnSearch = () => {
    getDashboardAppointment();
  };

  const handleSelectLocation = (e: SyntheticEvent, data: DropdownProps) => {
    setSelectedLocation(data.value);
  };

  const getDivisionList = async () => {
    setIsLoading(true);
    let apiToken = Cookies.get("apiToken")
      ? Cookies.get("apiToken")
      : null;
    // console.log('apiToken', apiToken)

    const params: any = {};
    if (!props.denyQParamsIsTelemed) {
      params.isTelemed = true;
    }
    const [data, error] = await props.controller.getLocation({
      apiToken: apiToken,
      ...params,
    });
    console.log("getLocation error", error);
    console.log("getLocation data", data);
    if (error) {
      setIsLoading(false);
      return;
    }
    if (data && data.items.length > 0) {
      setLocationOptions(data.items);
      setIsLoading(false);
    }
  };

  const handleCancelAPI = () => {
    source.cancel("Users cancel");
    setIsLoading(false);
    document.body.removeEventListener("click", cancelAPI);
  };

  const cancelAPI = () => {
    // console.log("click");
    setOpenIsCancel(true);
    // Cancle API
  };

  const getDashboardAppointment = async () => {
    setIsLoading(true);
    let apiToken = Cookies.get("apiToken")
      ? Cookies.get("apiToken")
      : null;

    let hn = searchBarRef.current.getSearchHN();
    let patient_name = searchBarRef.current.getSearchPatientName();
    let doctor_name = searchBarRef.current.getSearchDoctorName();

    let axiosSource = axios.CancelToken.source();
    setSource(axiosSource);
    document.body.addEventListener("click", cancelAPI);

    const [data, error] = await props.controller.getDashboardAppointment({
      apiToken: apiToken,
      ...(hn && { hn }),
      ...(patient_name && { patient_name }),
      ...(doctor_name && { doctor_name }),
      ...(selectedLocation && { division: selectedLocation }),
      ...(searchStartDate && { start_date: searchStartDate }),
      ...(searchToDate && { end_date: searchToDate}),
      ...(dob && { patient_dob: dob }),
      cancelToken: axiosSource.token,
    });

    document.body.removeEventListener("click", cancelAPI);

    console.log("getDashboardAppointment error", error);
    console.log("getDashboardAppointment data", data);
    if (error) {
      setOpenIsCancel(false);
      setIsLoading(false);
      return;
    }
    setOpenIsCancel(false);
    setIsLoading(false);
    if (data && data.items.length > 0) {
      setAppointmentList(data.items);
    } else {
      setAppointmentList([]);
    }
  };

  useEffect(() => {
    getDivisionList();
    getDashboardAppointment();
  }, [Cookies]);

  console.log(props);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSearchKeyPress = (e) => {
    var keyCode = e.keyCode || e.charCode;
    if (keyCode === CONSTANT.KEY_CODE.ENTER) {
      return getDashboardAppointment();
    }
  };

  return (
    <>
      <Dimmer inverted active={isLoading}>
        <Loader active={isLoading}>Loading</Loader>
      </Dimmer>
      <IsCancelCalledAPI
        open={openIsCancel}
        setOpen={setOpenIsCancel}
        answer={handleCancelAPI}
      />
      <div className="appointment-dashboard">
        <div className="title">รายการ Appointment</div>
        <AppointmentDashBoardSearchBar
          ref={searchBarRef}
          changeDOB={setDOB}
          changeStartDate={setSearchStartDate}
          changeToDate={setSearchToDate}
          onSearch={handleOnSearch}
          selectLocation={handleSelectLocation}
          startDate={searchStartDate}
          toDate={searchToDate}
          dob={dob}
          locationOptions={locationOptions}
          handleSearchKeyPress={handleSearchKeyPress}
        />
        <AppointmentListTable lists={appointmentList} />
        <div className="footer"></div>
      </div>
    </>
  );
};

export default AppointmentDashboard;
