import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Input,
  Checkbox,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const ANES_PreANES_02_history = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPreAnestheticHistory"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",padding:"20px 10px 10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props.buttonSave}
            </div>
          </div>
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  Previous anesthesia
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="PreviousAnesthesia"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_previous || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.PreviousAnesthesia || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  
                </label>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <TextArea
                  disabled={props.PreAnestheticSequence?.data?.PreviousAnesthesia !== "Yes"}
                  name="PreviousAnesthesiaRemark"
                  onChange={props.onChangeData}
                  rows={5}
                  value={props.PreAnestheticSequence?.data?.PreviousAnesthesiaRemark || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  Complication
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  fluid={true}
                  name="Complication"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_complication || []}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.Complication || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.Complication !== "Yes"}
                  fluid={true}
                  name="ComplicationRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.ComplicationRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  Known disease
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="KnownDisease"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_known_disease || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.KnownDisease || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  
                </label>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <TextArea
                  disabled={props.PreAnestheticSequence?.data?.KnownDisease !== "Yes"}
                  name="KnownDiseaseRemark"
                  onChange={props.onChangeData}
                  rows={5}
                  value={props.PreAnestheticSequence?.data?.KnownDiseaseRemark || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  Family history
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  fluid={true}
                  name="FamilyHistory"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_family_history || []}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.FamilyHistory || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.FamilyHistory !== "Yes"}
                  fluid={true}
                  name="FamilyHistoryRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.FamilyHistoryRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  Current medications
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  fluid={true}
                  name="CurrentMedications"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_current_med || []}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.CurrentMedications || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  
                </label>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <TextArea
                  disabled={props.PreAnestheticSequence?.data?.CurrentMedications !== "Yes"}
                  name="CurrentMedicationsRemark"
                  onChange={props.onChangeData}
                  rows={5}
                  value={props.PreAnestheticSequence?.data?.CurrentMedicationsRemark || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{alignItems: "baseline"}}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  Habit
                </label>
              </FormField>
              <FormField
                style={{display: "grid"}}
                width={16}>
                <FormGroup
                  inline={true}
                  style={{marginBottom: "1rem"}}
                  widths={16}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.PreAnestheticSequence?.data?.HabitAlcohol || false}
                      label="Alcohol"
                      name="HabitAlcohol"
                      onChange={props.onChangeData}
                      style={{minWidth: "140px", maxWidth: "140px"}}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{ display: props.PreAnestheticSequence?.data?.HabitAlcohol  ? "flex" : "none", paddingRight: "70px" }}
                    width={9}>
                    <Input
                      disabled={!props.PreAnestheticSequence?.data?.HabitAlcohol}
                      fluid={true}
                      name="HabitAlcoholRemark"
                      onChange={props.onChangeData}
                      style={{width: "100%"}}
                      value={props.PreAnestheticSequence?.data?.HabitAlcoholRemark || ""}>
                    </Input>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}
                  widths={16}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.PreAnestheticSequence?.data?.HabitSmoking || false}
                      label="Smoking"
                      name="HabitSmoking"
                      onChange={props.onChangeData}
                      style={{minWidth: "140px", maxWidth: "140px"}}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{ display: props.PreAnestheticSequence?.data?.HabitSmoking  ? "flex" : "none", paddingRight: "70px"}}
                    width={9}>
                    <Input
                      disabled={!props.PreAnestheticSequence?.data?.HabitSmoking}
                      name="HabitSmokingRemark"
                      onChange={props.onChangeData}
                      value={props.PreAnestheticSequence?.data?.HabitSmokingRemark || ""}>
                    </Input>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}
                  widths={16}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.PreAnestheticSequence?.data?.HabitDrugAbuse || false}
                      label="Drug abuse"
                      name="HabitDrugAbuse"
                      onChange={props.onChangeData}
                      style={{minWidth: "140px", maxWidth: "140px"}}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{ display: props.PreAnestheticSequence?.data?.HabitDrugAbuse  ? "flex" : "none" ,paddingRight: "70px" }}
                    width={9}>
                    <Input
                      disabled={!props.PreAnestheticSequence?.data?.HabitDrugAbuse}
                      name="HabitDrugAbuseRemark"
                      onChange={props.onChangeData}
                      value={props.PreAnestheticSequence?.data?.HabitDrugAbuseRemark || ""}>
                    </Input>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}
                  widths={16}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.PreAnestheticSequence?.data?.HabitHerbSupplement || false}
                      label="Herb/Supplement"
                      name="HabitHerbSupplement"
                      onChange={props.onChangeData}
                      style={{minWidth: "140px", maxWidth: "140px"}}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{ display:  props.PreAnestheticSequence?.data?.HabitHerbSupplement ? "flex" : "none",paddingRight: "70px" }}
                    width={9}>
                    <Input
                      disabled={!props.PreAnestheticSequence?.data?.HabitHerbSupplement}
                      name="HabitHerbSupplementRemark"
                      onChange={props.onChangeData}
                      value={props.PreAnestheticSequence?.data?.HabitHerbSupplementRemark || ""}>
                    </Input>
                  </FormField>
                </FormGroup>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  Functional class
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="FunctionalClass"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_functional_class || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.FunctionalClass || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  LMP
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{width: "100%"}}>
                  
                  <DateTextBox
                    onChange={props.onChangeDate}
                    value={props.PreAnestheticSequence?.data?.FunctionalLMP || ""}>
                  </DateTextBox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "130px", maxWidth: "130px"}}>
                  Contact lens
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="FunctionalIsContactLens"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_contact_lens || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.FunctionalIsContactLens || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default ANES_PreANES_02_history

export const screenPropsDefault = {}

/* Date Time : Thu Sep 05 2024 16:58:09 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticHistory"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",padding:\"20px 10px 10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 226,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "FormGroup",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "FormGroup",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "FormGroup",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormGroup",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "FormGroup",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormField",
      "parent": 230,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormField",
      "parent": 231,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormField",
      "parent": 232,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormField",
      "parent": 233,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "FormField",
      "parent": 234,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "FormField",
      "parent": 234,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "FormField",
      "parent": 234,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "FormField",
      "parent": 233,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "FormField",
      "parent": 233,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "FormField",
      "parent": 232,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "FormField",
      "parent": 232,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "FormField",
      "parent": 231,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "FormField",
      "parent": 231,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "FormField",
      "parent": 230,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "FormField",
      "parent": 230,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "label",
      "parent": 235,
      "props": {
        "children": {
          "type": "value",
          "value": "Previous anesthesia"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "label",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "Complication"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "label",
      "parent": 237,
      "props": {
        "children": {
          "type": "value",
          "value": "Known disease"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "label",
      "parent": 238,
      "props": {
        "children": {
          "type": "value",
          "value": "Family history"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "label",
      "parent": 239,
      "props": {
        "children": {
          "type": "value",
          "value": "Current medications"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "Dropdown",
      "parent": 248,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PreviousAnesthesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_previous || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreviousAnesthesia || \"\""
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "Dropdown",
      "parent": 246,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Complication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_complication || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Complication || \"\""
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "Dropdown",
      "parent": 244,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "KnownDisease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_known_disease || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.KnownDisease || \"\""
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "Dropdown",
      "parent": 242,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "FamilyHistory"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_family_history || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FamilyHistory || \"\""
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "Dropdown",
      "parent": 240,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "CurrentMedications"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_current_med || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CurrentMedications || \"\""
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "Input",
      "parent": 243,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FamilyHistory !== \"Yes\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "FamilyHistoryRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FamilyHistoryRemark || \"\""
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "Input",
      "parent": 247,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Complication !== \"Yes\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ComplicationRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ComplicationRemark || \"\""
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 266,
      "name": "FormGroup",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 267,
      "name": "FormField",
      "parent": 266,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "FormField",
      "parent": 266,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"grid\"}"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "FormGroup",
      "parent": 268,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "FormGroup",
      "parent": 268,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "FormGroup",
      "parent": 268,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "FormGroup",
      "parent": 268,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 273,
      "name": "FormField",
      "parent": 269,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 274,
      "name": "FormField",
      "parent": 270,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 275,
      "name": "FormField",
      "parent": 271,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 276,
      "name": "FormField",
      "parent": 272,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 277,
      "name": "Checkbox",
      "parent": 273,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitAlcohol || false"
        },
        "label": {
          "type": "value",
          "value": "Alcohol"
        },
        "name": {
          "type": "value",
          "value": "HabitAlcohol"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 278,
      "name": "Checkbox",
      "parent": 274,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitSmoking || false"
        },
        "label": {
          "type": "value",
          "value": "Smoking"
        },
        "name": {
          "type": "value",
          "value": "HabitSmoking"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Checkbox",
      "parent": 275,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitDrugAbuse || false"
        },
        "label": {
          "type": "value",
          "value": "Drug abuse"
        },
        "name": {
          "type": "value",
          "value": "HabitDrugAbuse"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "Checkbox",
      "parent": 276,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitHerbSupplement || false"
        },
        "label": {
          "type": "value",
          "value": "Herb/Supplement"
        },
        "name": {
          "type": "value",
          "value": "HabitHerbSupplement"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "label",
      "parent": 267,
      "props": {
        "children": {
          "type": "value",
          "value": "Habit"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "FormGroup",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "FormField",
      "parent": 282,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "FormField",
      "parent": 282,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "FormGroup",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 286,
      "name": "FormGroup",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 287,
      "name": "FormField",
      "parent": 285,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "FormField",
      "parent": 285,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "FormField",
      "parent": 286,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "FormField",
      "parent": 286,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "label",
      "parent": 283,
      "props": {
        "children": {
          "type": "value",
          "value": "Functional class"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "label",
      "parent": 287,
      "props": {
        "children": {
          "type": "value",
          "value": "LMP"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": null,
      "id": 293,
      "name": "label",
      "parent": 289,
      "props": {
        "children": {
          "type": "value",
          "value": "Contact lens"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Dropdown",
      "parent": 284,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "FunctionalClass"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_functional_class || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FunctionalClass || \"\""
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "Dropdown",
      "parent": 290,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "FunctionalIsContactLens"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_contact_lens || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FunctionalIsContactLens || \"\""
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": null,
      "id": 297,
      "name": "div",
      "parent": 288,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 298,
      "name": "DateTextBox",
      "parent": 297,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FunctionalLMP || \"\""
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 299,
      "name": "FormField",
      "parent": 269,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.data?.HabitAlcohol  ? \"flex\" : \"none\", paddingRight: \"70px\" }"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "FormField",
      "parent": 270,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.data?.HabitSmoking  ? \"flex\" : \"none\", paddingRight: \"70px\"}"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 301,
      "name": "FormField",
      "parent": 271,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.data?.HabitDrugAbuse  ? \"flex\" : \"none\" ,paddingRight: \"70px\" }"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "FormField",
      "parent": 272,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display:  props.PreAnestheticSequence?.data?.HabitHerbSupplement ? \"flex\" : \"none\",paddingRight: \"70px\" }"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 303,
      "name": "Input",
      "parent": 302,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.HabitHerbSupplement"
        },
        "name": {
          "type": "value",
          "value": "HabitHerbSupplementRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitHerbSupplementRemark || \"\""
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "Input",
      "parent": 301,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.HabitDrugAbuse"
        },
        "name": {
          "type": "value",
          "value": "HabitDrugAbuseRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitDrugAbuseRemark || \"\""
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "Input",
      "parent": 300,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.HabitSmoking"
        },
        "name": {
          "type": "value",
          "value": "HabitSmokingRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitSmokingRemark || \"\""
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 306,
      "name": "Input",
      "parent": 299,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.HabitAlcohol"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "HabitAlcoholRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitAlcoholRemark || \"\""
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 307,
      "name": "FormGroup",
      "parent": 229,
      "props": {
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 308,
      "name": "FormGroup",
      "parent": 229,
      "props": {
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 309,
      "name": "FormGroup",
      "parent": 229,
      "props": {
      },
      "seq": 285,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "FormField",
      "parent": 309,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 311,
      "name": "FormField",
      "parent": 309,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 312,
      "name": "FormField",
      "parent": 308,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "FormField",
      "parent": 308,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 314,
      "name": "FormField",
      "parent": 307,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 315,
      "name": "FormField",
      "parent": 307,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 316,
      "name": "TextArea",
      "parent": 311,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CurrentMedications !== \"Yes\""
        },
        "name": {
          "type": "value",
          "value": "CurrentMedicationsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CurrentMedicationsRemark || \"\""
        }
      },
      "seq": 316,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 317,
      "name": "TextArea",
      "parent": 313,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.KnownDisease !== \"Yes\""
        },
        "name": {
          "type": "value",
          "value": "KnownDiseaseRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.KnownDiseaseRemark || \"\""
        }
      },
      "seq": 317,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "TextArea",
      "parent": 315,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreviousAnesthesia !== \"Yes\""
        },
        "name": {
          "type": "value",
          "value": "PreviousAnesthesiaRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreviousAnesthesiaRemark || \"\""
        }
      },
      "seq": 318,
      "void": true
    },
    {
      "from": null,
      "id": 319,
      "name": "label",
      "parent": 314,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": null,
      "id": 320,
      "name": "label",
      "parent": 312,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": null,
      "id": 321,
      "name": "label",
      "parent": 310,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 321,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_02_history",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
