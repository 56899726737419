import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Checkbox
} from 'semantic-ui-react'

const CardNursingDiagnosisUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%"}}>
        <div
          style={{display: "flex",alignItems: "center",justifyContent: "flex-end"}}>
          
          <div>
            {props.nursingDiagnosisID}
          </div>
          <div>
            {props.searchButton}
          </div>
          <Button
            color="blue"
            onClick={props.onTemplate}
            size="small"
            style={{marginLeft: "5px"}}>
            เลือก Template
          </Button>
        </div>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          columns={props.columns_nursingDiagnosisList}
          data={props.nursingDiagnosisList}
          minRows={10}
          showPagination={false}
          style={{height: "400px", marginBottom: "10px"}}>
        </Table>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          
          <div>
            {props.addButton}
          </div>
          <div>
            
            <Checkbox
              checked={props.checked}
              label="เลือกทั้งหมด"
              onChange={props.onChangeChecked}
              style={{padding: "10px"}}>
            </Checkbox>
            <Button
              color="blue"
              onClick={props.onClickPrint}
              style={{padding: "10px"}}>
              พิมพ์
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardNursingDiagnosisUX

export const screenPropsDefault = {"roomDetail":"7C06 Ward  อายุรกรรม ห้อง Standard","searchLabel":"ค้นหา"}

/* Date Time : Thu Jun 13 2024 11:48:24 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems: \"center\",justifyContent: \"flex-end\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 23,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "columns": {
          "type": "code",
          "value": "props.columns_nursingDiagnosisList"
        },
        "data": {
          "type": "code",
          "value": "props.nursingDiagnosisList"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\", marginBottom: \"10px\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก Template"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onTemplate"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addButton"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Checkbox",
      "parent": 46,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checked"
        },
        "label": {
          "type": "value",
          "value": "เลือกทั้งหมด"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Button",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickPrint"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nursingDiagnosisID"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchButton"
        }
      },
      "seq": 49,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "CardNursingDiagnosisUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "roomDetail": "7C06 Ward  อายุรกรรม ห้อง Standard",
    "searchLabel": "ค้นหา"
  },
  "width": 75
}

*********************************************************************************** */
