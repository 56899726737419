import React, { useMemo, useState, useCallback } from "react";

// UX
import ModDrugDiseaseISegmentGroupUX from "./ModDrugDiseaseISegmentGroupUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { ErrorMessage } from "react-lib/apps/common";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

// Interface
import {
  DrugType,
  MasterOptionsType,
  RunSequence,
} from "./sequence/DrugDiseaseInteraction";

const MOD_DRUG_DISEASE_I_SEGMENT_GROUP = "ModDrugDiseaseISegmentGroup";

type ModDrugDiseaseISegmentGroupProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  // data
  errorKey: string;
  drugTypeDict?: Record<DrugType, string>;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
  // Callback
  onSuccess?: Function;
  onCancel?: Function;
};

type DetailType = Partial<{
  drug_id: number | string;
  drug_type: DrugType;
}>;

const ModDrugDiseaseISegmentGroup = (
  props: ModDrugDiseaseISegmentGroupProps
) => {
  const [detail, setDetail] = useState<DetailType>({});
  const [errorMessage, setErrorMessage] = useState<any>(null);

  // Callback
  const mapADRingredientOptions = useCallback((items: any) => {
    // console.log("mapDivisionOptions items: ", items);
    return items.map((item: any) => ({
      key: item["id"],
      value: item["id"],
      text: item["name"],
    }));
  }, []);

  const handleSelectedItem = useCallback(
    async (value: any, key: any, obj: any) => {
      let product = props.searchedItemListWithKey?.ADRingredient_1?.find(
        (item: any) => item["id"] === key
      );
      await handleChangeValue(null, {
        name: "drug_id",
        value: product?.["id"] || "",
      });
    },
    [props.searchedItemListWithKey, detail]
  );

  // Use Memo
  const drugTypeOptions = useMemo(() => {
    return DRUG_TYPE.map((value) => ({
      key: value,
      text: props.drugTypeDict?.[value],
      value,
    }));
  }, [DRUG_TYPE, props.drugTypeDict]);

  const drugByTypeOptions = useMemo(() => {
    return {
      INGREDIENT: [],
      PRODUCT: props.masterOptions?.drugName || [],
      MIMS: props.masterOptions?.drugGroup || [],
      ATC: props.masterOptions?.drugGroup || [],
      "": [],
    }[detail.drug_type || ""];
  }, [props.masterOptions, detail.drug_type]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    if (data.name === "drug_type") {
      return setDetail({
        [data.name]: data.value,
        drug_id: "",
      });
    }

    setDetail({
      ...detail,
      [data.name]: data.value,
    });
  };

  const handleCreate = () => {
    let error: Record<string, any> = {};
    const messages = [
      ["drug_id", "ประเภทกลุ่มยา"],
      ["drug_type", "ชื่อยา/กลุ่มยา"],
    ] as const;

    for (const msg of messages) {
      if (!detail[msg[0]]) {
        error[msg[1]] = ["This field is required."];
      }
    }

    if (!!Object.keys(error).length) {
      return setErrorMessage(error);
    }

    setErrorMessage(null);

    const name =
      drugByTypeOptions.find((option) => option.value === detail.drug_id)
        ?.text || "";

    props.runSequence({
      sequence: "DrugDiseaseInteraction",
      action: "ADD_SEGMENT_GROUP",
      data: {
        ...detail,
        id: new Date().toISOString(),
        drug_name: name,
      },
    });

    props.onSuccess?.();
  };

  return (
    <ModDrugDiseaseISegmentGroupUX
      // options
      drugTypeOptions={drugTypeOptions}
      drugByTypeOptions={drugByTypeOptions}
      // data
      drugId={detail.drug_id}
      drugType={detail.drug_type}
      // config
      disabledDrug={!detail.drug_type}
      // callback
      onCancel={props.onCancel}
      onChangeValue={handleChangeValue}
      // Element
      ErrorMessage={
        <ErrorMessage
          error={errorMessage}
          style={{ margin: "-1rem 1rem 1rem 0" }}
        />
      }
      ButtonSave={
        <ButtonLoadCheck
          // function
          setProp={props.setProp}
          onClick={handleCreate}
          // data
          paramKey={`${MOD_DRUG_DISEASE_I_SEGMENT_GROUP}_SAVE`}
          buttonLoadCheck={
            props.buttonLoadCheck?.[`${MOD_DRUG_DISEASE_I_SEGMENT_GROUP}_SAVE`]
          }
          // config
          color={"green"}
          name="SAVE"
          size="small"
          style={{ width: "142px", marginRight: "3rem" }}
          title="สร้างกลุ่ม"
        />
      }
      SearchBox={
        <SearchBoxDropdown
          onEvent={props.onEvent}
          // config
          type="ADRingredient"
          id="1"
          style={{ width: "100%" }}
          fluid={true}
          useWithKey={true}
          icon="search"
          limit={20}
          // Select
          searchedItemListWithKey={props.searchedItemListWithKey}
          selectedItem={detail.drug_id || null}
          setSelectedItem={handleSelectedItem}
          // options
          mapOptions={mapADRingredientOptions}
        />
      }
    />
  );
};

const DRUG_TYPE = ["PRODUCT", "INGREDIENT", "MIMS", "ATC"] as const;

export default React.memo(ModDrugDiseaseISegmentGroup);
