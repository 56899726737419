import React, { useState } from 'react';
import CardLayout from '../common/CardLayout';
import { Grid, Image, Segment } from 'semantic-ui-react'
import Viewer from 'react-viewer';

const CardANCRecordView = (props) => {

  const [image, setImage] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const FrameChrome = ({ file }) => {
    if (file) {
      let frame =
        '<iframe src="data:application/pdf;base64,' + file + '" width="100%" height="100%" ></iframe>';
      return (
        <div
          style={{ width: "100%", height: "100%" }}
          dangerouslySetInnerHTML={{ __html: frame }}
        />
      );
    } else {
      return <></>;
    }
  };

  React.useEffect(() => {
    getANCRecordPDF()
  }, [props.encounterId])

  const getANCRecordPDF = async () => {
    setIsLoading(true)
    const [data, error] = await props.getANCRecordPDF()
    if(data && data.success && data.pdf_b64data.length > 0){
      setImage(data.pdf_b64data)
    } else {
      setImage('')
    }
    setIsLoading(false)
  }

  return (
    <CardLayout
      titleText='ANC Record'
      headerColor='red'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={isLoading}
      cardLayoutClassName={props.cardLayoutClassName}
    >
      {!image && <>ไม่มีข้อมูล</>}
      {image && <div style={{ height: 480, overflowY: 'auto', overflowX: 'hidden' }}>
      <Segment
        id="container"
        style={{ height: "480px" }}
        className="grey inverted"
      >
        <FrameChrome
          file={image}
        />
      </Segment>
      </div>}
    </CardLayout>
  )
}

CardANCRecordView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  imageList: [],
  getANCRecordPDF: () => [null, null],
  encounterId: null,
  cardLayoutClassName: '',
};

export default CardANCRecordView;