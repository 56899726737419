import React, { useState, useEffect } from "react";
import { Button, Icon, Popup } from "semantic-ui-react";

// UX
import ModChangePasswordUX from "./ModChangePasswordUX";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

type ModChangePasswordProps = {
  onEvent: any;
  setProp: any;
  // data
  user?: Record<string, any>;
  changePassword?: Record<string, any>;
  // CommonInterface
  successMessage?: Record<string, any>;
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // config
  showPassword2?: boolean;
  pw2CheckTeacherFlag?: boolean;
  // callback
  onClose: () => any;
};

// Const
const BUTTON_ACTIONS = {
  save: "SAVE",
};

const CARD_CHANGE_PASSWORD = "ModChangePasswordUX";

const ACTION_SAVE = `${CARD_CHANGE_PASSWORD}_${BUTTON_ACTIONS.save}`;

const ModChangePassword = (props: ModChangePasswordProps) => {
  const [changePass1, setChangePass1] = useState<boolean>(false);
  const [changeConPass1, setChangeConPass1] = useState<boolean>(false);
  const [changePass2, setChangePass2] = useState<boolean>(false);
  const [changeConPass2, setChangeConPass2] = useState<boolean>(false);

  const [showChangePass1, setShowChangePass1] = useState<boolean>(false);
  const [showChangePass2, setShowChangePass2] = useState<boolean>(false);
  const [approvalPassword, setApprovalPassword] = useState<boolean | undefined>(
    false
  );

  useEffect(() => {
    if (props.successMessage?.[CARD_CHANGE_PASSWORD]) {
      handleOnCancel();

      setShowChangePass1(false);
      setShowChangePass2(false);
    }
  }, [props.successMessage?.[CARD_CHANGE_PASSWORD]]);

  useEffect(() => {
    setApprovalPassword(props.user?.use_sec_pass_for_approve);
  }, [props.user]);

  const handleClear = () => {
    props.setProp("changePassword", null);
    props.setProp(`errorMessage.${CARD_CHANGE_PASSWORD}`, null);
    props.setProp(`successMessage.${CARD_CHANGE_PASSWORD}`, null);
  };

  const handleOnCancel = () => {
    handleClear();

    props.onClose();
  };

  const handleChangeApprovalPassword = (checked: boolean) => {
    setApprovalPassword(checked);
  };

  const handleChangePassword = () => {
    props.onEvent({
      message: "SettingStaff",
      params: {
        action: "CHANGE_PASSWORD",
        card: CARD_CHANGE_PASSWORD,
        buttonLoadKey: ACTION_SAVE,
        id: props.user?.id,
        changePass1: showChangePass1,
        changePass2: showChangePass2,
        ...(approvalPassword !== props.user?.use_sec_pass_for_approve && {
          approvalPassword,
        }),
      },
    });
  };

  const changeValue = (key: string) => (_e: any, v: any) => {
    const value: string = v.value;

    if (key.includes("password2")) {
      // 123456
      if (!value || value.search(/^\d+$/) >= 0) {
        props.setProp(`changePassword.${key}`, value.slice(0, 6));
      }
    } else {
      props.setProp(`changePassword.${key}`, value);
    }
  };

  const handleClickChangePass2 = () => {
    if (!props.showPassword2) {
      return;
    }

    handleClear();

    setShowChangePass2(!showChangePass2);
  };

  return (
    <ModChangePasswordUX
      // data
      username={props.user?.username}
      password1={props.changePassword?.password1}
      confirm_password1={props.changePassword?.confirm_password1}
      password2={props.changePassword?.password2}
      confirm_password2={props.changePassword?.confirm_password2}
      showChangePass1={showChangePass1}
      showChangePass2={showChangePass2}
      approvalPassword={approvalPassword}
      // config
      showPassword2={props.showPassword2}
      pw2CheckTeacherFlag={props.pw2CheckTeacherFlag}
      // callback
      changeValue={changeValue}
      OnCancel={handleOnCancel}
      onChangeApprovalPassword={handleChangeApprovalPassword}
      // Element
      ErrorMessages={
        <ErrorMessage
          error={props?.errorMessage?.[CARD_CHANGE_PASSWORD]?.error}
        />
      }
      changePass1={
        <span
          aria-hidden="true"
          style={{
            color: showChangePass1 ? "red" : "#2185D0",
            cursor: "pointer",
          }}
          onClick={() => {
            handleClear();

            setShowChangePass1(!showChangePass1);
          }}
        >
          {showChangePass1 ? "ยกเลิก" : "เปลี่ยนรหัสผ่าน"}
        </span>
      }
      changePass2={
        <span
          aria-hidden="true"
          style={{
            color: showChangePass2 ? "red" : "#2185D0",
            cursor: "pointer",
          }}
          onClick={handleClickChangePass2}
        >
          {showChangePass2 ? "ยกเลิก" : "เปลี่ยนรหัสผ่าน"}
        </span>
      }
      eyePass1={
        <Icon
          name={changePass1 ? "eye" : "eye slash"}
          link
          onClick={() => {
            setChangePass1(!changePass1);
          }}
        />
      }
      typePassword1={changePass1 ? "" : "password"}
      eyePass2={
        <Icon
          name={changePass2 ? "eye" : "eye slash"}
          link
          onClick={() => {
            setChangePass2(!changePass2);
          }}
        />
      }
      typePassword2={changePass2 ? "" : "password"}
      eyeConfirm1={
        <Icon
          name={changeConPass1 ? "eye" : "eye slash"}
          link
          onClick={() => {
            setChangeConPass1(!changeConPass1);
          }}
        />
      }
      typeConfirm1={changeConPass1 ? "" : "password"}
      eyeConfirm2={
        <Icon
          name={changeConPass2 ? "eye" : "eye slash"}
          link
          onClick={() => {
            setChangeConPass2(!changeConPass2);
          }}
        />
      }
      typeConfirm2={changeConPass2 ? "" : "password"}
      buttonSave={
        <ButtonLoadCheck
          // function
          setProp={props.setProp}
          onClick={handleChangePassword}
          // data
          paramKey={ACTION_SAVE}
          buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SAVE]}
          // config
          color="green"
          name="SAVE"
          style={{ width: "100%" }}
          title="บันทึก"
        />
      }
      InfoPopup={
        <Popup
          position="right center"
          content="กำหนดผ่านรหัสผ่านชุดที่ 2 ด้วยตัวเลข 0-9 จำนวน 6 ตัว"
          trigger={
            <Button
              circular={true}
              color="blue"
              icon="info"
              size="mini"
              style={{ padding: "0.15rem 0.2rem", marginLeft: "0.55rem" }}
            />
          }
        />
      }
    />
  );
};

ModChangePassword.displayName = "ModChangePassword";

export default React.memo(ModChangePassword);
