import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Dropdown, Icon, Modal } from "semantic-ui-react";
import ModConsultDateTimeUX from "react-lib/apps/Scheduling/ModConsultDateTimeUX";
import ModConsultAppointmentListUX from "react-lib/apps/Scheduling/ModConsultAppointmentListUX";
import { adToBe } from "react-lib/utils";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModDuplicateAppointment from "./ModDuplicateAppointment";

const localizer = momentLocalizer(moment);
type ConsultDateTimeProps = Partial<{
  selectedPatient: Record<string, any>;
  selectedAppointment: Record<string, any>;
  consultDetail: Record<string, any>;
  consultData: Record<string, any>;
  divisionList: Record<string, any>[];
  onRefreshAppointment: any;
  onEvent: any;
  setProp: any;
  mapProviderOptions: any;
  buttonLoadCheck: Record<string, any>;
  backToDetailTab: any;
  filter: Record<string, any> | undefined;
  duplicateAppointment: any;
  reoccureDuplicateAppointment: any;
  userTokenize: any;
  card: string;
}>;

const CARD_CONSULT_DATE_TIME = "CardConsultDateTime";

const CardConsultDateTime = (props: ConsultDateTimeProps) => {
  const [calendarYear, setCalendarYear] = useState<string[]>([
    moment().format("YYYY"),
  ]);
  const [modConsultApp, setModConsultApp] = useState<any>({ open: false });
  const [openModAppList, setOpenModAppList] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [selectTime, setSelectTime] = useState<string>("");
  const [isToday, setIsToday] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());

  // Duplicated make appointment
  const [duplicateReason, setDuplicateReason] = useState("");

  useEffect(() => {
    setDate(props.consultDetail?.date? new Date(props.consultDetail?.date) : new Date())
  }, [props.consultDetail?.date])

  useEffect(() => {
    setSelectTime(props.consultDetail?.time || "");
  }, [props.consultDetail?.time]);

  useEffect(() => {
    if (props.duplicateAppointment) {
      setDuplicateReason("");
    }
  }, [props.duplicateAppointment]);

  const selectedDivision = useMemo(() => {
    return (
      (props.divisionList || []).find(
        (item: any) => item.id === props.consultDetail?.destinationDivision
      )?.name_code || props.consultDetail?.destinationDivisionNameCode || ""
    );
  }, [props.consultDetail?.destinationDivision]);

  const selectedDoctor = useMemo(() => {
    return (
      (props.consultData?.providerDoctorOptions || []).find(
        (item: any) =>
          item.value === props.consultDetail?.destinationProviderDoctor
      )?.text || "ไม่ระบุแพทย์"
    );
  }, [
    props.consultDetail?.destinationProviderDoctor,
    props.consultData?.providerDoctorOptions,
  ]);

  const blockList = useMemo(() => {
    const dsb = props.consultData?.divisionServiceBlockOptions || [];
    if (props.consultDetail?.destinationProviderDoctor) {
      return dsb
        .filter((d: any) =>
          d.providers?.some(
            (i: any) =>
              i.doctor_provider_id ===
              props.consultDetail?.destinationProviderDoctor
          )
        )
        .map((d: any) => {
          // target doctor schedule
          const doctorFilter = d.providers.filter(
            (i: any) =>
              i.doctor_provider_id ===
              props.consultDetail?.destinationProviderDoctor
          );

          return doctorFilter.map((doct: any) => {
            const endTime: any = new Date(
              `${doct.dsb.date} ${doct.dsb.doctor_end_time}`
            );
            const startTime: any = new Date(
              `${doct.dsb.date} ${doct.dsb.doctor_start_time}`
            );
            const slot =
              (endTime - startTime) / 60000 / doct.dsb.doctor_dsb_slot_length;
            return {
              ...doct.dsb,
              title: (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{`${doct.dsb.doctor_start_time}-${doct.dsb.doctor_end_time}`}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "5px",
                    }}
                  >
                    <Icon name="user" size="small" />
                    {doct.dsb.doctor_dsb_full
                      ? "Full"
                      : `${doct.dsb.appointments?.length}/${slot}`}
                  </div>
                </div>
              ),
              start: startTime,
              end: endTime,
            };
          });
        })
        .flat();
    } else {
      return dsb.map((d: any) => {
        return {
          ...d,
          title: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{`${d.start_time}-${d.end_time}`}</div>
            </div>
          ),
          start: new Date(`${d.date} ${d.start_time}`),
          end: new Date(`${d.date} ${d.end_time}`),
        };
      });
    }
  }, [props.consultDetail, props.consultData?.divisionServiceBlockOptions]);

  const selectedDate = useMemo(() => {
    if (selectedEvent) {
      const convertDate = moment(selectedEvent.date, "YYYY-MM-DD").locale("th");
      const formatDate = convertDate.format("วันdddd ที่ D MMMM");
      const formatYear = parseInt(convertDate.format("YYYY")) + 543;
      return (
        <div>
          {`${formatDate} ${formatYear}`}{" "}
          {isToday ? (
            <span style={{ color: "#0D71BB", fontWeight: "bold" }}>
              (วันนี้)
            </span>
          ) : (
            ""
          )}
        </div>
      );
    }
    return "";
  }, [selectedEvent]);

  const selectedPatient = useMemo(() => {
    if (props.selectedPatient) {
      return (
        <div>
          <div
            style={{
              display: "flex",
              fontSize: "18px",
              fontWeight: "bold",
              paddingBottom: "10px",
            }}
          >
            {`${props.selectedPatient.full_name_th} [${props.selectedPatient.hn}]`}
          </div>
          <div
            style={{ display: "flex", fontSize: "16px", paddingBottom: "20px" }}
          >
            <Icon
              name={
                props.selectedPatient?.gender === "M"
                  ? "man"
                  : props.selectedPatient?.gender === "F"
                  ? "woman"
                  : "question"
              }
              color={
                props.selectedPatient?.gender === "M"
                  ? "blue"
                  : props.selectedPatient?.gender === "F"
                  ? "pink"
                  : "grey"
              }
            />
            <span style={{ paddingRight: "5px" }}>
              {props.selectedPatient?.birthdate}
            </span>
            <Icon name="call" color="blue" />
            <span>{props.selectedPatient?.tel_num || "-"}</span>
          </div>
        </div>
      );
    }
    return <></>;
  }, [props.selectedPatient]);

  const viewListIcon = useMemo(() => {
    if (props.consultDetail?.destinationProviderDoctor) {
      return (
        <div
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            setOpenModAppList(true);
          }}
        >
          <Icon.Group size="large" style={{ width: "auto" }}>
            <Icon name="user" color="blue" />
            <Icon corner="top right" name="circle" color="blue" size="tiny" />
            <Icon corner="bottom right" name="list" color="blue" size="small" />
          </Icon.Group>
          <div
            style={{ fontSize: "10px", color: "#2185d0", textWrap: "nowrap" }}
          >
            View list
          </div>
        </div>
      );
    }
    return <></>;
  }, [props.consultDetail?.destinationProviderDoctor]);

  const timeOptions = useMemo(() => {
    if (selectedEvent && props.consultDetail?.destinationProviderDoctor) {
      // already appointment
      const formatDateTime = "YYYY-MM-DDTHH:mm";
      const appointmentTime =
        selectedEvent?.appointments?.map((item: any) =>
          moment(item.estimated_at).format(formatDateTime)
        ) || [];

      let minutesPerSession = selectedEvent.doctor_dsb_slot_length;
      let options = [];
      let timeNow = moment()
      let startTime = moment(selectedEvent.start);
      let endTime = moment(selectedEvent.end);
      // console.log("minutesPerSession: ", minutesPerSession, appointmentTime, startTime)
      while (startTime < endTime) {
        if(!(isToday && startTime < timeNow)){
          const time = startTime.format("HH:mm");
          const timeText = appointmentTime.some(
            (item: any) => item === startTime.format(formatDateTime)
          )
            ? `${time} (มีนัดหมายแล้ว)`
            : time;
          options.push({ key: time, text: timeText, value: time });
        }
        startTime = startTime.add(minutesPerSession, "minutes");
      }
      return options;
    }
    return [];
  }, [selectedEvent, props.consultDetail?.destinationProviderDoctor, isToday]);

  const appointmentList = useMemo(() => {
    const appointments = selectedEvent?.appointments || [];
    console.log("appointments: ", appointments, selectedEvent);
    return appointments.map((item: any) => ({
      patient_full_name: `${item.first_name} ${item.last_name}`,
      start_time: moment(item.estimated_at).format("HH:mm"),
      duration: `${item.estimated_duration} นาที`,
      appointment_user: item.edit_user_name,
      booking_date_time: `${adToBe(selectedEvent.date, "YYYY-MM-DD")} [${
        selectedEvent.start_time
      } - ${selectedEvent.end_time}]`,
    }));
  }, [selectedEvent]);

  const duplicateDate = useMemo(() => {
    const payload = props.duplicateAppointment?.payload;
    if ( payload?.selectedEvent && payload?.time) {
      return `${payload?.selectedEvent?.dateText || payload?.selectedEvent?.date} [${payload?.time}]`;
    } else { 
      return undefined
    }

  }, [props.duplicateAppointment]);

  const handleOnChangeValue = (name: string, value: any) => {
    props.onEvent({
      message: "HandleSetConsultDetail",
      params: { name, value: name === "date"? adToBe(value, "YYYY-MM-DD"): value},
    });
  };

  const handleConfirmDuplicate = () => {
    props.onEvent({
      message: props.duplicateAppointment.message || "SetScheduling",
      params: {
        ...props.duplicateAppointment.payload,
        repetition_note: duplicateReason,
      },
    });
  };

  const handleCancelDuplicate = () => {
    console.log('handleCancelDuplicate: ');
    props.setProp("duplicateAppointment", null);
    props.setProp(`buttonLoadCheck.${props.card}_CONFIRM`, null);
  };

  // console.log("CardConsultDateTime props: ", props);
  return (
    <div style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px",
          fontSize: "16px",
        }}
      >
        <div>{`แผนก: ${selectedDivision}`}</div>
        {selectedDoctor ? (
          <div>{`แพทย์: ${selectedDoctor}`}</div>
        ) : (
          <div>{`แพทย์: ไม่ระบุแพทย์`}</div>
        )}
      </div>
      <Calendar
        endAccessor="end"
        eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {
          // console.log("event", event)
          let length = event?.providers?.filter(
            (item: any) =>
              item?.dsb?.doctor_provider_type === "Doctor" && item?.dsb?.doctor_dsb_status !== 2
          )?.length;

          return {
            style: event?.holiday
              ? {
                  backgroundColor: "#FFE696",
                  color: "black",
                  textAlign: "center",
                }
              : props.selectedAppointment?.status === 2 &&
                (props.selectedAppointment?.division_service_block === event.dsb_id ||
                  props.selectedAppointment?.division_service_block === (event.doctor_dsb_id || ""))
              ? { backgroundColor: "#21ba45", color: "white" }
              : props.selectedAppointment?.division_service_block === event.dsb_id ||
                props.selectedAppointment?.division_service_block === (event.doctor_dsb_id || "")
              ? { backgroundColor: "#f7941d", color: "white" }
              : length === 0
              ? { backgroundColor: "pink", color: "black" }
              : moment() > moment(event.end) || event.doctor_dsb_full
              ? { backgroundColor: "#3174ad94", color: "white" }
              : ({} as CSSProperties),
          };
        }}
        events={blockList || []}
        localizer={localizer}
        min={new Date(1972, 0, 1, 8, 0, 0)}
        max={new Date(1972, 0, 1, 20, 0, 0)}
        defaultDate={date}
        onRangeChange={(range: any, view: any) => {
          console.log(range);
          let year = range.start?.getFullYear();
          let month = range.start?.getMonth() + 1;
          if (!Number.isInteger(year) || !Number.isInteger(month)) return;
          if (range.start?.getDate() !== 1) {
            if (month !== 12) {
              month = month + 1;
            } else {
              month = 1;
              year += 1;
            }
          }

          const uYear = Array.isArray(range)
            ? [range[0], range.slice(-1)[0]]
            : [range.start, range.end];
          const uniqueYear = Array.from(
            new Set([moment(uYear[0]).format("YYYY"), moment(uYear[1]).format("YYYY")])
          );
          if (uniqueYear.toString() !== calendarYear.toString()) {
            setCalendarYear(uniqueYear);
          }
          // props.onEvent({
          //   message: "FilterSchedule",
          //   params: { range: { year, month } },
          // });
        }}
        onSelectEvent={(e: any) => {
          if (props.selectedAppointment && moment() < moment(e.end) && !e.doctor_dsb_full) {
            // console.log("onSelectEvent:", e);
            setSelectedEvent(e);
            setIsToday(new Date().toLocaleDateString() === new Date(e.date).toLocaleDateString());
            handleOnChangeValue("date", e.date);
            setModConsultApp({ open: true });
          }
        }}
        startAccessor="start"
        style={{ width: "100%", height: "100%" }}
        tooltipAccessor="tooltip"
        views={["month", "week"]}
      />
      <Modal open={modConsultApp?.open} closeOnDimmerClick={false} size="small">
        <ModConsultDateTimeUX
          patientDetail={selectedPatient}
          selectDate={selectedDate}
          divisionDetail={selectedDivision}
          doctorDetail={selectedDoctor}
          viewListIcon={viewListIcon}
          isEdit={!!props?.consultDetail?.id}
          // "แก้ไขนัดหมาย" : "ทำนัดหมาย"
          buttonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() =>
                props.onEvent({
                  message: "HandleMakeConsultAppointment",
                  params: {
                    selectedEvent,
                    action: "MAKE_APPOINTMENT",
                    time: selectTime,
                    onSuccess: () => {
                      setModConsultApp({ open: false });
                      props?.backToDetailTab?.();
                      props?.onRefreshAppointment?.();
                    },
                    ButtonLoadCheck: "CardConsultDateTime_CONFIRM_BUTTON",
                    filter: props.filter || {},
                    isToday,
                    card: CARD_CONSULT_DATE_TIME,
                  },
                })
              }
              // data
              paramKey="CardConsultDateTime_CONFIRM_BUTTON"
              buttonLoadCheck={props.buttonLoadCheck?.["CardConsultDateTime_CONFIRM_BUTTON"]}
              // config
              color={isToday ? "green" : !!props?.consultDetail?.id ? "orange" : "green"}
              fluid={true}
              size="large"
              icon={isToday ? "" : "calendar check outline"}
              title={
                isToday && props.selectedAppointment?.type_display !== "นัดหมาย Consult IPD"
                  ? "เปิด Encounter"
                  : !!props?.consultDetail?.id
                  ? "แก้ไขนัดหมาย"
                  : "ทำนัดหมาย"
              }
            />
          }
          selectTime={
            props.consultDetail?.destinationProviderDoctor ? (
              <Dropdown
                selection={true}
                options={timeOptions}
                fluid={true}
                value={selectTime}
                onChange={(e: any, v: any) => {
                  setSelectTime(v.value);
                }}
              />
            ) : (
              <div>{`${selectedEvent?.start_time} - ${selectedEvent?.end_time}`}</div>
            )
          }
          onCancel={() => {
            setModConsultApp({ open: false });
          }}
        />
      </Modal>

      <Modal open={openModAppList} closeOnDimmerClick={false} size="large">
        <ModConsultAppointmentListUX
          showActionButton={false}
          // onConfirmAppointment
          // onEditAppointmentDateTime
          // onCancelAppointment
          onClose={() => {
            setOpenModAppList(false);
          }}
          data={appointmentList}
        />
      </Modal>

      <ModDuplicateAppointment
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        buttonLeftColor={"red"}
        buttonLeftLabel={"ใช่"}
        buttonRightColor={"green"}
        buttonRightLabel={"ไม่"}
        duplicateAppointment={props.duplicateAppointment}
        duplicateClinicName={props.selectedAppointment?.division_name}
        duplicateDate={duplicateDate}
        duplicateDoctorName={props.selectedAppointment?.display_info?.provider_name}
        open={!!props.duplicateAppointment}
        reason={duplicateReason}
        reoccureDuplicateAppointment={props.reoccureDuplicateAppointment}
        size={"large"}
        // options={options}
        title={"กรุณาตรวจสอบนัดหมาย"}
        isButtonBasic
        userTokenize={props.userTokenize}
        // callback
        onButtonLeftClick={handleConfirmDuplicate}
        onButtonRightClick={handleCancelDuplicate}
        onChangeReason={(event, data) => {
          setDuplicateReason(data?.value);
        }}
        onClose={handleCancelDuplicate}
      />
    </div>
  );
};

export default CardConsultDateTime;
