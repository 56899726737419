import React from "react";
import PropTypes from "prop-types";
import * as Common from "../common";
import * as Util from "../../utils"
import { DateTextBox } from "../common";
import ReactTable from "react-table-6";
import { Button, Form, Icon } from "semantic-ui-react";
import moment from 'moment';

const CardSelectDoctorOrder = React.forwardRef((props, ref) => {

  const [doctorOrderData, setDoctorOrderData] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [fromDate, setFromDate] = React.useState(Util.formatDate(moment()))
  const [toDate, setToDate] = React.useState(Util.formatDate(moment()))
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [expandRow, setExpandRow] = React.useState([])

  React.useEffect(() => {
    getDoctorOrder()
  }, [props.encounter])

  const getDoctorOrder = async () => {
    setIsLoading(true)
    const [data, error] = await props.controller.getDoctorOrder({
      fromDate,
      toDate,
      encounterId: props.encounter
    })
    setIsLoading(false)
    if (data && data.items) {
      for (let item in data.items) {
        for (let i in data.items[item].children) {
          data.items[item].children[i].selectId = `${data.items[item].head}${data.items[item].children[i].id}`
        }
      }
      setDoctorOrderData(data.items)
    }
  }

  const defaultExpandedRows = doctorOrderData.map((element, index) => {
    if (Array.isArray(expandRow) && expandRow.includes(index)) {
      return true
    }
    return false
  })

  return (
    <Common.CardLayout
      closeable={props.closeable}
      toggleable={props.toggleable}
      loading={isLoading}
      titleText="เลือกคำสั่งแพทย์"
      onClose={props.onClose}
    >
      <Form>
        <Form.Group inline>
          <Form.Field label='วันที่' />
          <Form.Field width={3}>
            <DateTextBox
              testid='dtbFromDate'
              value={fromDate}
              onChange={(date) => setFromDate(date)}
            />
          </Form.Field>
          <Form.Field label='-' />
          <Form.Field width={3}>
            <DateTextBox
              testid='dtbToDate'
              value={toDate}
              onChange={(date) => setToDate(date)}
            />
          </Form.Field>
          <Form.Field><Button testid='btnSearch' color='blue' content='ค้นหา' onClick={getDoctorOrder} /></Form.Field>
        </Form.Group>
      </Form>
      <ReactTable
        style={{ height: 470, backgroundColor: '#FFFFFF' }}
        noDataText='ไม่มีข้อมูล'
        data={doctorOrderData}
        className='blueSelectedRow'
        showPagination={false}
        expanded={defaultExpandedRows}
        expanderDefaults={{
          sortable: false,
          resizable: true,
          filterable: false
        }}
        minRows={11}
        onExpandedChange={(newExpanded, index) => {
          if (Array.isArray(expandRow) && expandRow.includes(index[0])) {
            let newRow = [...expandRow]
            newRow.splice(newRow.indexOf(index), 1);
            setExpandRow([...newRow])
          } else {
            setExpandRow([...expandRow, ...index])
          }
        }}
        subRowsKey="children"
        getTrProps={(state, rowInfo, column, instance) => {
          if (rowInfo && rowInfo.row && !rowInfo.subRows) {
            const id = rowInfo.original.selectId;
            let selectedRowId = null;

            if (selectedRow && typeof selectedRow === 'object') {
              selectedRowId = selectedRow.selectId;
            }
            return {
              onClick: (event, handleOriginal) => {
                if (handleOriginal) {
                  handleOriginal();
                }
                setSelectedRow(rowInfo.original)
              },
              onDoubleClick(event, handleOriginal) {
                if (handleOriginal) {
                  handleOriginal();
                }
                props.onSelect({ orderDetail: rowInfo.original.order_summary_detail })
              },
              className: id === selectedRowId ? 'blueSelectedRow' : '',
            };
          } else if (rowInfo && rowInfo.row && rowInfo.subRows) {
            return {
              onClick: (event, handleOriginal) => {
                if (handleOriginal) {
                  handleOriginal();
                }
                if (Array.isArray(expandRow) && expandRow.includes(rowInfo.index)) {
                  let newRow = [...expandRow]
                  newRow.splice(newRow.indexOf(rowInfo.index), 1);
                  setExpandRow([...newRow])
                } else {
                  setExpandRow([...expandRow, rowInfo.index])
                }
              },
              style: {
                flex: 'unset',
                backgroundColor: '#EEE'
              }
            }
          }
          return {};
        }}
        columns={[
          {
            accessor: 'head',
            expander: true,
            width: 50,
            Expander: ({ isExpanded, ...rest }) => {
              if (!rest.original.hasOwnProperty("children")) {
                return '';
              } else {
                return (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {isExpanded ? (
                      <>
                        <span
                          style={{
                            display: "inline-block",
                            transform: "rotate(90deg)",
                          }}
                        >
                          &#10148;&ensp;
                      </span>
                        <span style={styles.headText}>{rest.original.head}</span>
                      </>
                    ) : rest.original.children &&
                      rest.original.children.length === 0 ? (
                          <span> </span>
                        ) : (
                          <span style={{ ...styles.headText }}>&#10148;&ensp;{rest.original.head}</span>
                        )}
                  </div>
                );
              }
            }
          },
          {
            Header: 'Order Summary Detail',
            accessor: 'order_summary_detail',
            minWidth: 50,
            Cell: (row) => {
              if (!row.original.hasOwnProperty("children")) {
                return <div>&nbsp;&nbsp;&nbsp;{row.original.order_summary_detail}</div>;
              } else if (
                row.original.hasOwnProperty("children") &&
                row.original.children.length === 0
              ) {
                return <div>{row.original.order_summary_detail}</div>;
              } else { return <div>{row.original.order_summary_detail}</div> }
            }
          },
          {
            Header: 'คำสั่งแพทย์ผูกกับ Nurse Note',
            accessor: 'is_bind_nurse_note',
            minWidth: 50,
            Cell: (row) => {
              if (!row.original.hasOwnProperty("children")) {
                if (row.value) {
                  return <Icon name='check' color='green' />;
                } else {
                  return <Icon name='remove' circular style={{ backgroundColor: 'red', color: 'white' }} />;
                }

              } else if (
                row.original.hasOwnProperty("children") &&
                row.original.children.length === 0
              ) {
                return <div>{row.original.is_bind_nurse_note}</div>;
              } else { return <div>{row.original.is_bind_nurse_note}</div> }
            }
          }]}
      />
      <br/>
      <Form>
        <Form.Group>
          <Form.Field width={14}/>
          <Form.Field width={2}>
            <Button fluid color='green' content='เลือก' onClick={() => props.onSelect({ orderDetail: selectedRow.order_summary_detail})}/>
          </Form.Field>
        </Form.Group>
      </Form>
    </Common.CardLayout>
  )
})

const styles = {
  headText: {
    fontSize: 'larger',
    fontWeight: 'bold'
  }
}

CardSelectDoctorOrder.defaultProps = {
  closeable: true,
  toggleable: true,
  onClose: () => { },
  controller: {},
  patientData: {},
  onSelect: () => { },
};

CardSelectDoctorOrder.propTypes = {
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  onClose: PropTypes.func,
  controller: PropTypes.object,
  patientData: PropTypes.object,
  onSelect: PropTypes.func,
};

export default React.memo(CardSelectDoctorOrder);
