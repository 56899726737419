import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  TextArea,
  Dropdown,
  Checkbox,
  Radio,
  Input
} from 'semantic-ui-react'

const ANES_PreANES_05_AnesProb = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPreAnestheticProblemsConsiderations"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",padding:"20px 10px 10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props?.buttonSave}
            </div>
          </div>
          <Form>
            <FormGroup>
              <FormField
                width={16}>
                <label>
                  Anesthetic problems / considerations
                </label>
                <TextArea
                  name="AnestheticProblemsConsiderationsRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.AnestheticProblemsConsiderationsRemark || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Mallampati grade
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="MallampatiGrade"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_mallampati || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.MallampatiGrade || ""}>
                </Dropdown>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.IsEmergency || false}
                  label="Emergency"
                  name="IsEmergency"
                  onChange={props.onChangeData}
                  style={{minWidth: "max-content", marginLeft: "1rem"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <label
                  style={{width: "100%", maxWidth: "max-content"}}>
                  Anesthetic Informed Consent
                </label>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent === true}
                  label="Yes"
                  name="IsAnestheticInformedConsent"
                  onChange={props.onChangeData}
                  style={{minWidth: "max-content", marginRight: "1rem"}}
                  value={!props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent}>
                </Radio>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent === false}
                  label="No"
                  name="IsAnestheticInformedConsent"
                  onChange={props.onChangeData}
                  style={{minWidth: "max-content", marginRight: "1rem"}}
                  value={!props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent}>
                </Radio>
                <label
                  style={{minWidth: "max-content", marginRight: "1rem"}}>
                  NPO
                </label>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.IsNPO === true}
                  label="Yes"
                  name="IsNPO"
                  onChange={props.onChangeData}
                  style={{minWidth: "max-content", marginRight: "1rem"}}
                  value={!props.PreAnestheticSequence?.data?.IsNPO}>
                </Radio>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.IsNPO === false}
                  label="No"
                  name="IsNPO"
                  onChange={props.onChangeData}
                  style={{minWidth: "max-content", marginRight: "1rem"}}
                  value={!props.PreAnestheticSequence?.data?.IsNPO}>
                </Radio>
                <Input
                  disabled={!props.PreAnestheticSequence?.data?.IsNPO}
                  name="NPO"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.NPO || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Anestheic plan
                </label>
                <Input
                  name="AnestheicPlan"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.AnestheicPlan || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <label
                  style={{width: "100%", maxWidth: "max-content"}}>
                  Alternative of anesthesia discussed
                </label>
                <Input
                  name="AlternativeOfAnesthesiaDiscussed"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.AlternativeOfAnesthesiaDiscussed || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Premedication
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{padding: 0, minWidth: "max-content"}}>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.PremedicationNone === false}
                  label="None"
                  name="PremedicationNone"
                  onChange={props.onChangeData}
                  style={{minWidth: "60px",maxWidth: "60px", marginRight: "1rem"}}
                  value={!props.PreAnestheticSequence?.data?.PremedicationNone}>
                </Radio>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.PremedicationNone === true}
                  label="Yes"
                  name="PremedicationNone"
                  onChange={props.onChangeData}
                  style={{minWidth: "50px",maxWidth: "50px", marginRight: "1rem"}}
                  value={!props.PreAnestheticSequence?.data?.PremedicationNone}>
                </Radio>
              </FormField>
              <FormField
                inline={true}
                width={15}>
                <Dropdown
                  clearable={true}
                  disabled={!props.PreAnestheticSequence?.data?.PremedicationNone}
                  fluid={true}
                  multiple={true}
                  name="PremedicationNoneRemark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.drugName || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.PremedicationNoneRemark || []}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Blood prepared
                </label>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.BloodPreparedStatus === true}
                  label="Yes"
                  name="BloodPreparedStatus"
                  onChange={props.onChangeData}
                  style={{minWidth: "60px",maxWidth: "60px", marginRight: "1rem"}}
                  value={!props.PreAnestheticSequence?.data?.BloodPreparedStatus}>
                </Radio>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.BloodPreparedStatus === false}
                  label="No"
                  name="BloodPreparedStatus"
                  onChange={props.onChangeData}
                  style={{minWidth: "50px",maxWidth: "50px", marginRight: "1rem"}}
                  value={!props.PreAnestheticSequence?.data?.BloodPreparedStatus}>
                </Radio>
                <Input
                  disabled={!props.PreAnestheticSequence?.data?.BloodPreparedStatus}
                  name="BloodPrepared"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.BloodPrepared || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <label
                  style={{width: "100%", maxWidth: "max-content"}}>
                  Plan of postoperative care
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="PlanOfPostoperativeCare"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.anes_plan_of_postope || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.PlanOfPostoperativeCare || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <label
                  style={{width: "100%", maxWidth: "max-content"}}>
                  Ambulatory patient with companion
                </label>
                <Input
                  fluid={true}
                  name="AmbulatoryPatientWithCompanion"
                  onChange={props.onChangeData}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.AmbulatoryPatientWithCompanion || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <label
                  style={{width: "100%",maxWidth: "max-content", paddingRight: "7px"}}>
                  Plan of postoperative pain management
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="PlanOfPostoperativePainManagement"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_plan_of_post || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.PlanOfPostoperativePainManagement || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default ANES_PreANES_05_AnesProb

export const screenPropsDefault = {}

/* Date Time : Fri Dec 01 2023 18:05:46 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticProblemsConsiderations"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",padding:\"20px 10px 10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginBottom: \"1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic problems / considerations"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "TextArea",
      "parent": 116,
      "props": {
        "name": {
          "type": "value",
          "value": "AnestheticProblemsConsiderationsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AnestheticProblemsConsiderationsRemark || \"\""
        }
      },
      "seq": 118,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "Mallampati grade"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "Anestheic plan"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Dropdown",
      "parent": 121,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "MallampatiGrade"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_mallampati || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.MallampatiGrade || \"\""
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Checkbox",
      "parent": 121,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsEmergency || false"
        },
        "label": {
          "type": "value",
          "value": "Emergency"
        },
        "name": {
          "type": "value",
          "value": "IsEmergency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic Informed Consent"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Radio",
      "parent": 122,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent === true"
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "IsAnestheticInformedConsent"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"1rem\"}",
          "valueEN": ""
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Radio",
      "parent": 122,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent === false"
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "IsAnestheticInformedConsent"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "NPO"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"1rem\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Radio",
      "parent": 122,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsNPO === true"
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "IsNPO"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsNPO"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "Radio",
      "parent": 122,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsNPO === false"
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "IsNPO"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsNPO"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Input",
      "parent": 122,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsNPO"
        },
        "name": {
          "type": "value",
          "value": "NPO"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.NPO || \"\""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Input",
      "parent": 123,
      "props": {
        "name": {
          "type": "value",
          "value": "AnestheicPlan"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AnestheicPlan || \"\""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "Input",
      "parent": 124,
      "props": {
        "name": {
          "type": "value",
          "value": "AlternativeOfAnesthesiaDiscussed"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AlternativeOfAnesthesiaDiscussed || \"\""
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "Alternative of anesthesia discussed"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0, minWidth: \"max-content\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormField",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Premedication"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood prepared"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "Radio",
      "parent": 145,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BloodPreparedStatus === true"
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "BloodPreparedStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"60px\",maxWidth: \"60px\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.BloodPreparedStatus"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Radio",
      "parent": 145,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BloodPreparedStatus === false"
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "BloodPreparedStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\",maxWidth: \"50px\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.BloodPreparedStatus"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "Radio",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PremedicationNone === false"
        },
        "label": {
          "type": "value",
          "value": "None"
        },
        "name": {
          "type": "value",
          "value": "PremedicationNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"60px\",maxWidth: \"60px\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.PremedicationNone"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Radio",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PremedicationNone === true"
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "PremedicationNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\",maxWidth: \"50px\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.PremedicationNone"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Input",
      "parent": 145,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.BloodPreparedStatus"
        },
        "name": {
          "type": "value",
          "value": "BloodPrepared"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BloodPrepared || \"\""
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan of postoperative care"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "label",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "Ambulatory patient with companion"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Dropdown",
      "parent": 146,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PlanOfPostoperativeCare"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.anes_plan_of_postope || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanOfPostoperativeCare || \"\""
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Input",
      "parent": 148,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AmbulatoryPatientWithCompanion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AmbulatoryPatientWithCompanion || \"\""
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 161,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "label",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan of postoperative pain management"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",maxWidth: \"max-content\", paddingRight: \"7px\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Dropdown",
      "parent": 162,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PlanOfPostoperativePainManagement"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_plan_of_post || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanOfPostoperativePainManagement || \"\""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Dropdown",
      "parent": 143,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.PremedicationNone"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PremedicationNoneRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.drugName || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PremedicationNoneRemark || []"
        }
      },
      "seq": 165,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_05_AnesProb",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
