import React, { useEffect, useRef, useState, useMemo } from "react";
import { Menu, Dropdown, Button, Icon, Radio, Popup } from "semantic-ui-react";
import CONFIG from "config/config";
import Cookies from "js-cookie";
import DotColor from "react-lib/apps/Scheduling/DotColor";

type TopBarProps = {
  django?: any;
  setProp?: any;
  onEvent?: any;
  onClickBell?: any;
  divisionOptions?: any[];
  selectedDivision?: any;
  deviceOptions?: any[];
  selectedDevice?: any;
  showIconNoti?: boolean;
  showTabName: string;
  mode?: string;
};

const TopBar = (props: TopBarProps) => {
  const [isOffline, setIsOffline] = useState<boolean>(false);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      let mode = Cookies.get("offlineMode") || "online";
      setIsOffline(mode === "offline" ? true : false);
    } else {
      navigator.serviceWorker?.ready?.then((reg: ServiceWorkerRegistration) => {
        reg?.active?.postMessage({
          type: "TOGGLE_OFFLINE",
          isOffline: isOffline,
        });
      });
    }

    Cookies.set("offlineMode", isOffline ? "offline" : "online");
  }, [isOffline]);

  const userFullName = useMemo(() => {
    const fullName = props.django?.user?.full_name || "";
    // * ไม่ระบุ first_name last_name
    return fullName.replace(/^ไม่ระบุ /, "");
  }, [props.django?.user]);

  const handleClickUserMode = (e: any) => {
    if (!CONFIG.RAKSTHAI_DISABLED_USER_MODE) {
      props.setProp("mode", "user");
    }
  };

  return (
    <Menu secondary className={`topbar-menu ${isOffline ? "offline" : ""}`}>
      <Menu.Item>
        <Dropdown
          style={{
            color: "white",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          button
          className={"icon"}
          floating
          labeled
          icon={<Icon name="hospital" style={{ fontSize: "1.3em" }} />}
          options={props.divisionOptions}
          search
          value={props.selectedDivision?.id}
          placeholder={"Select Division"}
          onChange={(e: any, v: any) =>
            props.onEvent({
              message: "ChangeDivision",
              params: { divisionId: v.value },
            })
          }
          onOpen={() => {
            // this.setState({
            //   currentDivision: null,
            //   division: null,
            // });
          }}
        />
        <Dropdown
          style={{
            color: "white",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          button
          className={"icon"}
          floating
          labeled
          icon={<Icon name="hospital outline" style={{ fontSize: "1.3em" }} />}
          options={props.deviceOptions}
          search
          value={props.selectedDevice?.id}
          placeholder={"Select Device"}
          onChange={(e: any, v: any) =>
            props.onEvent({
              message: "ChangeDevice",
              params: { deviceId: v.value },
            })
          }
          onOpen={() => {
            // this.setState({
            //   currentDivision: null,
            //   division: null,
            // });
          }}
        />
      </Menu.Item>
      {!CONFIG.V3_HIDDEN_TAB_LIST.some((i: any) => i === "submenu_admin_tab") ? (
        <Menu.Item>
          {!CONFIG.V3_HIDDEN_TAB_LIST.some((i: any) => i === "submenu_only_admin_btn") ? (
            <Button onClick={(e: any) => props.setProp("mode", "admin")}>
              <span
                style={{
                  ...(props.mode === "admin" && {
                    textDecoration: "underline",
                    textUnderlineOffset: "6px",
                    textDecorationStyle: "dashed",
                  }),
                }}
              >
                Admin
              </span>
            </Button>
          ) : null}
          <Button onClick={(e: any) => props.setProp("mode", "patient")}>
            <span
              style={{
                ...(props.mode === "patient" && {
                  textDecoration: "underline",
                  textUnderlineOffset: "6px",
                  textDecorationStyle: "dashed",
                }),
              }}
            >
              Patient
            </span>
          </Button>
          {/* <Button onClick={(e: any) => props.setProp("mode", "segment")}>
        Segment tool
      </Button> */}
        </Menu.Item>
      ) : null}

      <Menu.Item position="right">
        {(
          (["ทำนัดหมาย", "ลงตารางแพทย์", "บริหารเก้าอี้"].includes(props.showTabName) && props.mode === "admin") || 
          (["Doctor Schedule"].includes(props.showTabName) && props.mode === "user")
        ) && (
          <div className="ui right aligned category search item" style={{ color: "white" }}>
            {/* <Icon name="info circle" /> */}
            {/* <Button circular icon='info circle' /> */}

            <Popup
              wide="very"
              content={
                <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
                  {props.showTabName === "ทำนัดหมาย" && (
                    <>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="#3174ad" /> [แพทย์] มีแพทย์ออกตรวจ
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="pink" /> [แพทย์] ไม่มีแพทย์ออกตรวจ
                      </div>
                    </>
                  )}

                  {props.showTabName === "Doctor Schedule" && (
                    <>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="pink" />
                        [คลินิก] ช่วงเวลาที่คลินิกเปิด แต่ไม่มีแพทย์ออกตรวจ / ไม่ได้ลงตารางออกตรวจ
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="#3174ad" /> [แพทย์] ช่วงเวลาที่แพทย์ลงตารางออกตรวจ
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="orange" />
                        [แพทย์] ช่วงเวลาที่ลงเวลาออกตรวจที่แผนกอื่น
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="#cccccc" />
                        [แพทย์] ช่วงเวลาที่งดออกตรวจ
                      </div>
                    </>
                  )}

                  {props.showTabName === "ลงตารางแพทย์" && (
                    <>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="pink" />
                        [คลินิก] ช่วงเวลาที่คลินิกเปิด แต่ไม่มีแพทย์ออกตรวจ / ไม่ได้ลงตารางออกตรวจ
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="#3174ad" /> [แพทย์] ช่วงเวลาที่แพทย์ลงตารางออกตรวจ
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="green" /> [แพทย์]
                        ช่วงเวลาที่ลงตารางออกตรวจและจองเก้าอี้แล้ว
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="orange" />
                        [แพทย์] ช่วงเวลาที่ลงเวลาออกตรวจที่แผนกอื่น
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="#cccccc" />
                        [แพทย์] ช่วงเวลาที่งดออกตรวจ
                      </div>
                    </>
                  )}
                  {props.showTabName === "บริหารเก้าอี้" && (
                    <>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="pink" />
                        [คลินิก] ช่วงเวลา เปิดบริการของคลินิก{" "}
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="orange" />
                        [เก้าอี้] มีการบริหารเก้าอี้ไว้ที่คลินิกอื่น{" "}
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="cyan" /> [เก้าอี้] มีการบริหารเก้าอี้ไว้แล้ว{" "}
                      </div>
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                        {" "}
                        <DotColor color="#cccccc" />
                        [เก้าอี้] เก้าอี้เต็มแล้วทุกตัว{" "}
                      </div>
                    </>
                  )}

                  {/* <div style={{display:"flex", gap:"10px", justifyContent: "flex-start"}}> <Dot color="yellow"/> TEST4 </div> */}
                </div>
              }
              trigger={<Button circular icon="info circle" />}
            />
          </div>
        )}
        <div style={{ position: "relative", marginRight: "22px" }}>
          <Icon
            name="bell"
            style={{
              fontSize: "1.4em",
              color: "white",
              cursor: "pointer",
            }}
            onClick={props.onClickBell}
          />
          {props.showIconNoti && (
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: "red",
                borderRadius: "50%",
                position: "absolute",
                top: "2px",
                right: "6px",
              }}
            ></div>
          )}
        </div>
        <div style={{ cursor: "pointer" }} onClick={handleClickUserMode}>
          <Icon name="user doctor" style={{ color: "white", fontSize: "1.3em" }} />
        </div>
        <Button
          style={{
            paddingLeft: "3px",
            ...(props.mode === "user" && {
              textDecoration: "underline",
              textUnderlineOffset: "6px",
              textDecorationStyle: "dashed",
            }),
          }}
          onClick={handleClickUserMode}
        >
          {userFullName}
        </Button>
        <Button
          onClick={(e: any) => {
            localStorage.clear();

            navigator?.serviceWorker?.ready?.then((reg: ServiceWorkerRegistration) => {
              reg?.active?.postMessage({
                type: "CLEAR_APIS",
              });
            });

            props.onEvent({ message: "Logout", params: {} });
          }}
        >
          Logout
        </Button>
        {isOffline ? <Icon name="ban" color="red" /> : <Icon name="check circle" color="blue" />}
        <Radio
          toggle
          disabled={CONFIG.DISABLE_FEATURE_OFFLINE || CONFIG.DISABLE_CACHE}
          checked={isOffline}
          onChange={() => {
            setIsOffline(!isOffline);
          }}
        />
      </Menu.Item>
      {/* {this.getLeftMenuItem()}
  {this.getRightMenuItem()} */}
    </Menu>
  );
};

export default TopBar;
