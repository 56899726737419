import * as SetPatientI from "./SetPatient";
import * as CreateUpdateEncounterI from "./CreateUpdateEncounter"
import * as SetCoverageI from "./SetCoverage";
import * as EpisodeOfCareI from "./EpisodeOfCare";

const SequencePattern: { [name: string]: { [flavor: string]: { [func: string]: any } } } = {
  SetPatient: {
    default: {
      START: SetPatientI.GetMaster,
      SearchOrNew: SetPatientI.SearchOrNew,
      EditPatientInfo: SetPatientI.EditPatientInfo,
    },
  },
  CreateUpdateEncounter: {
    default: {
      START: CreateUpdateEncounterI.GetActiveEncounterList,
      CreateOrUpdate: CreateUpdateEncounterI.CreateOrUpdate,
    },
  },
  SetCoverage: {
    default: {
      START: SetCoverageI.Start,
      Edit: SetCoverageI.Edit,
    },
  },
  EpisodeOfCare: {
    default: {
      START: EpisodeOfCareI.Start,
      Action: EpisodeOfCareI.Action,
    },
  },
};

export default SequencePattern;
