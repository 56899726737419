import React, { useState, useEffect, useMemo } from "react";
import CardSummaryWaitingQueueUX from "./CardSummaryWaitingQueueUX";
import moment from "moment";
import { parseDate, formatADtoBEString } from "react-lib/utils/dateUtils";
import { DATE_FORMAT, timeSlotList } from "react-lib/apps/Scheduling/common/Utils";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { Icon, Button } from "semantic-ui-react";
import { CSVLink } from "react-csv";

const staterDate = new Date().setMonth(new Date().getMonth() - 1);
const startDateDefault = moment(staterDate)?.format(DATE_FORMAT);
const endDateDefault = moment(new Date())?.format(DATE_FORMAT);

const startExportDateDefault = formatADtoBEString(moment(startDateDefault).format(DATE_FORMAT));
const endExportDateDefault = formatADtoBEString(moment(endDateDefault).format(DATE_FORMAT));

const CardSummaryWaitingQueue = (props: any) => {
  const [selectDivisionPA, setSelectDivisionPA] = useState<any>(null);
  const [startDate, setStartDate] = useState(startDateDefault);
  const [endDate, setEndDate] = useState(endDateDefault);
  const [startExportDate, setStartExportDate] = useState(startExportDateDefault);
  const [endExportDate, setEndExportDate] = useState(endExportDateDefault);

  useEffect(() => {
    props.onEvent({ message: "GetSummaryWaitingQueue", params: {} });
  }, []);

  const dataSummaryQueue = useMemo(() => {
    return (props.summaryWaitingQueueList || []).map((item: any, index: number) => ({
      ...item,
      avg_paid_timg: "-",
      date: `${startExportDate} ถึง ${endExportDate}`,
    }));
  }, [props.summaryWaitingQueueList]);

  const mapDivisionOptions = (items: any) => {
    // console.log("mapDivisionOptions items: ", items);
    return items.map((item: any) => ({
      key: item.code,
      value: item.name,
      text: item.name,
    }));
  };
  const changeDate = (key: string) => (value: any) => {
    console.log(value);
    const newDateString = (parseDate(value, true) as moment.Moment).format(DATE_FORMAT);
    if (key === "startDate") {
      setStartDate(newDateString);
      setStartExportDate(value);
    } else if (key === "endDate") {
      setEndDate(newDateString);
      setEndExportDate(value);
    }
  };

  //Export .CSV
  const headers = [
    { label: "วันที่รักษา", key: "date" },
    { label: "คลินิก", key: "division__name" },
    { label: "ระยะเวลาเฉลี่ยลงทะเบียน ถึง เข้าห้องพบแพทย์ (นาที)", key: "avg_check_in" },
    { label: "ระยะเวลาเฉลี่ยเข้าห้องแพทย์ ถึง ออกจากห้องเเพทย์ (นาที)", key: "avg_check_out" },
    { label: "ระยะเวลาเฉลี่ยออกห้องแพทย์ - ชำระเงินเสร็จสิ้น (นาที)", key: "avg_paid_timg" },
  ];

  return (
    <>
      <CardSummaryWaitingQueueUX
        startDate={formatADtoBEString(moment(startDate).format(DATE_FORMAT))}
        endDate={formatADtoBEString(moment(endDate).format(DATE_FORMAT))}
        dataSummaryQueue={dataSummaryQueue}
        changeDate={changeDate}
        divisionSearch={
          <SearchBoxDropdown
            type="Division"
            id="1"
            style={{ width: "100%" }}
            fluid={true}
            label=""
            // disabled={selectedManagePatient !== null}
            toDisplay={(item: any) => `${item.name}`}
            onEvent={props.onEvent}
            // searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={selectDivisionPA}
            setSelectedItem={(value: any, key: any, obj: any) => {
              if (value === null && key === null) {
                setSelectDivisionPA(null);
                return;
              }
              let division = props.searchedItemListWithKey?.Division_1?.find((item: any) => item.code === key);
              setSelectDivisionPA(division);
            }}
            useWithKey={true}
            mapOptions={mapDivisionOptions}
          />
        }
        OnSearch={() => {
          props.onEvent({
            message: "GetSummaryWaitingQueue",
            params: {
              action: "search",
              data: {
                division: selectDivisionPA,
                startDate: startDate,
                endDate: endDate,
              },
            },
          });
        }}
        buttonExportCSV={
          <CSVLink data={dataSummaryQueue} headers={headers} filename={"รายงานระยะเวลารอคิว.csv"} target="_blank">
            <Button color="orange">
              <div style={{ display: "flex" }}>
                <Icon name="download" />
                <div style={{ marginRight: "3px" }}> Export .CSV </div>
              </div>
            </Button>
          </CSVLink>
        }
      />
    </>
  );
};

export default CardSummaryWaitingQueue;
