import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Button,
  ButtonProps,
  Checkbox,
  CheckboxProps,
  Dimmer,
  Form,
  Input,
  InputProps,
  Loader,
} from "semantic-ui-react";

import { ComponentPropsGetterR } from "react-table-6";
import moment from "moment";

import Android12Switch from "react-lib/appcon/common/Android12Switch";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModAuthen from "react-lib/apps/common/ModAuthen";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import PaginationShorthand from "react-lib/appcon/common/PaginationShorthand";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SearchBoxDropdown, { SelectedItemHandler } from "react-lib/appcon/common/SearchBoxDropdown";

import CardEpisodeOfCareUX from "./CardEpisodeOfCareUX";

import {
  ACTIONS,
  BTN_ACTS,
  ENCOUNTER_LIMIT,
  FilterEncounterChangeHandler,
  FilterEncounterType,
  FilterEpisodeChangeHandler,
  MasterOptions,
  OnEvent,
  PickedProps,
  RunSequence,
  SEQUENCE_NAME,
  SetProp,
  State,
  StateChangeHandler,
} from "./sequence/EpisodeOfCare";

import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";

// Types
type CardEpisodeOfCareProps = PickedProps & {
  onEvent: OnEvent;
  setProp: SetProp;
  runSequence: RunSequence;
  EpisodeOfCareSequence: State["EpisodeOfCareSequence"];
  masterOptions: MasterOptions;
};

type Styles = Record<"center" | "loader" | "searchBox" | "switchStyle", CSSProperties>;

type FormatEncounterListParams = {
  checkedIds: number[];
  encounterList: Record<string, any>[];
  isActiveList: boolean;
  onChange: (event: SyntheticEvent, data: CheckboxProps) => void;
};

// Constants
const styles: Styles = {
  center: { display: "grid", placeContent: "center" },
  loader: {
    alignItems: "flex-start",
    background: "transparent",
    left: 0,
    marginLeft: "1px",
    padding: 0,
  },
  searchBox: { width: "100%" },
  switchStyle: { marginRight: "-3px", transform: "scale(0.7)" },
};

const CardEpisodeOfCare = (props: CardEpisodeOfCareProps) => {
  const [modDeleteEncounter, setModDeleteEncounter] = useState<{ id: number; note: string } | null>(
    null
  );
  const [openModDelete, setOpenModDelete] = useState<boolean>(false);

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "EpisodeOfCare", restart: true });

    return () => {
      props.setProp(`errorMessage.${SEQUENCE_NAME}`, null);
    };
  }, []);

  // Memo callback
  const episodeDetail = useMemo(
    () => props.EpisodeOfCareSequence?.episodeDetail,
    [props.EpisodeOfCareSequence?.episodeDetail]
  );

  const checkedActiveEncounter = useMemo(
    () => props.EpisodeOfCareSequence?.checkedActiveEncounter || [],
    [props.EpisodeOfCareSequence?.checkedActiveEncounter]
  );

  const uncheckedEpisodeEncounter = useMemo(
    () => props.EpisodeOfCareSequence?.uncheckedEpisodeEncounter || [],
    [props.EpisodeOfCareSequence?.uncheckedEpisodeEncounter]
  );

  const filterEncounter = useMemo(
    () => props.EpisodeOfCareSequence?.filterEncounter || {},
    [props.EpisodeOfCareSequence?.filterEncounter]
  );

  // Callback
  const NoDataComponent = useCallback(
    () => (
      <div
        className="rt-noData"
        style={{ backgroundColor: "transparent", top: "calc(50% - -1rem)" }}
      >
        No encounter items
      </div>
    ),
    []
  );

  const handleNewEpisode = useCallback(() => {
    props.runSequence({ sequence: "EpisodeOfCare", action: ACTIONS.NEW_EPISODE });
  }, []);

  const handleChangeValue = useCallback(
    <T extends object>(e: SyntheticEvent | null, data: StateChangeHandler<T>["data"]) => {
      const name = data.name as StateChangeHandler<T>["name"];
      const value = data.type === "checkbox" ? data.checked : data.value;

      if (!name) {
        return;
      }

      props.setProp("EpisodeOfCareSequence.episodeDetail", { ...episodeDetail, [name]: value });
    },
    [episodeDetail]
  );

  const handleChangeFilterEpisode = useCallback(
    async <T extends object>(
      e: SyntheticEvent | null,
      data: FilterEpisodeChangeHandler<T>["data"]
    ) => {
      const name = data.name as FilterEpisodeChangeHandler<T>["name"];

      if (!name) {
        return;
      }

      await props.setProp(`EpisodeOfCareSequence.filterEpisode.${name}`, data.checked);

      props.runSequence({
        sequence: "EpisodeOfCare",
        action: ACTIONS.SEARCH_EPISODE,
      });
    },
    []
  );

  const handleChangeFilterEncounter = useCallback(
    async <T extends object>(
      e: SyntheticEvent | null,
      data: FilterEncounterChangeHandler<T>["data"]
    ) => {
      const name = data.name as FilterEncounterChangeHandler<T>["name"];
      const value = data.type === "checkbox" ? data.checked : data.value;

      if (!name) {
        return;
      }

      props.setProp("EpisodeOfCareSequence.filterEncounter", { ...filterEncounter, [name]: value });
    },
    [filterEncounter]
  );

  const handleSelectedItem: SelectedItemHandler = useCallback(
    (value) => {
      props.setProp("EpisodeOfCareSequence.episodeDetail.doctor", value || null);
    },
    [props.searchedItemListWithKey]
  );

  const handleSelectEpisode = useCallback(
    (original: Record<string, any> | null) => {
      const episodeList = props.EpisodeOfCareSequence?.episodeList || [];

      const data = episodeList.find((item) => item.id === original?.id);

      props.runSequence({
        sequence: "EpisodeOfCare",
        action: ACTIONS.SELECT,
        data: data || null,
        isResetEncounter: true,
      });
    },
    [props.EpisodeOfCareSequence?.episodeList]
  );

  const handleGetTrProps: ComponentPropsGetterR = useCallback(
    (state, rowInfo) => {
      const original = rowInfo?.original;

      return {
        className: original && original.id === episodeDetail?.id ? "blueSelectedRow" : "",
        onClick: () => {
          handleSelectEpisode(original);
        },
      };
    },
    [episodeDetail, handleSelectEpisode]
  );

  const handleSave = useCallback(() => {
    props.runSequence({ sequence: "EpisodeOfCare", action: ACTIONS.SAVE });
  }, []);

  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${SEQUENCE_NAME}`, null);
  }, []);

  const handleCheckAll = useCallback(
    (e: SyntheticEvent, data: CheckboxProps) => {
      const items = props.EpisodeOfCareSequence?.activeEncounterList?.items || [];

      const ids = items.map((item) => item.id as number);

      props.setProp("EpisodeOfCareSequence.checkedActiveEncounter", data.checked ? ids : []);
    },
    [props.EpisodeOfCareSequence?.activeEncounterList]
  );

  const handleCheckActiveEncounter = useCallback(
    (e: SyntheticEvent, data: CheckboxProps) => {
      let checked = [...checkedActiveEncounter];

      if (data.checked) {
        checked.push(Number(data.name));
      } else {
        checked = checked.filter((id) => id !== Number(data.name));
      }

      props.setProp("EpisodeOfCareSequence.checkedActiveEncounter", [...checked]);
    },
    [checkedActiveEncounter]
  );

  const handleCheckEpisodeEncounter = useCallback(
    (e: SyntheticEvent, data: CheckboxProps) => {
      let checked = [...uncheckedEpisodeEncounter];

      if (data.checked) {
        checked = checked.filter((id) => id !== Number(data.name));
      } else {
        checked.push(Number(data.name));
      }

      props.setProp("EpisodeOfCareSequence.uncheckedEpisodeEncounter", [...checked]);
    },
    [uncheckedEpisodeEncounter]
  );

  const handleOpenModDeleteEncounter = useCallback((e: SyntheticEvent, data: ButtonProps) => {
    setModDeleteEncounter({ id: data.name, note: "" });
  }, []);

  const handleCloseModDeleteEncounter = useCallback(() => {
    setModDeleteEncounter(null);
  }, []);

  const handleChangeNote = useCallback(
    (e: SyntheticEvent, data: InputProps) => {
      if (modDeleteEncounter) {
        setModDeleteEncounter({ ...modDeleteEncounter, note: String(data.value) });
      }
    },
    [modDeleteEncounter]
  );

  const handleDeleteEncounter = useCallback(
    (data: { password: string; username: string }) => {
      if (modDeleteEncounter) {
        props.runSequence({
          sequence: "EpisodeOfCare",
          action: ACTIONS.DELETE_ENCOUNTER,
          ...data,
          ...modDeleteEncounter,
          onSuccess: handleCloseModDeleteEncounter,
        });
      }
    },
    [handleCloseModDeleteEncounter, modDeleteEncounter]
  );

  const handleOpenModDeleteEpisode = useCallback(() => {
    setOpenModDelete(true);
  }, []);

  const handleCloseModDeleteEpisode = useCallback(() => {
    setOpenModDelete(false);
  }, []);

  const handleConfirmDeleteEpisode = useCallback(() => {
    props.runSequence({
      sequence: "EpisodeOfCare",
      action: ACTIONS.DELETE_EPISODE,
      onSuccess: handleCloseModDeleteEpisode,
    });
  }, [handleCloseModDeleteEpisode]);

  const handleSearchEncounter = useCallback(
    (
      e: SyntheticEvent | null,
      data: { name?: string; updateFilter?: FilterEncounterType; value?: number }
    ) => {
      props.runSequence({
        sequence: "EpisodeOfCare",
        action: ACTIONS.SEARCH_ENCOUNTER,
        activePage: data.value || 1,
        updateFilter: data.updateFilter,
      });
    },
    [props.EpisodeOfCareSequence?.activeEncounterList]
  );

  const handleChangeIncludeExpired = useCallback(
    async (e: SyntheticEvent, checked: boolean) => {
      handleSearchEncounter(null, {
        updateFilter: {
          include_expired: checked,
          ...(checked ? { ended: formatDate(moment()) } : { ended: "", started: "" }),
        },
      });
    },
    [filterEncounter, handleSearchEncounter]
  );

  const handlePageChange = useCallback(
    (activePage: number) => {
      handleSearchEncounter(null, { value: activePage });
    },
    [handleSearchEncounter]
  );

  const formatEncounterList = useCallback(
    ({ checkedIds, encounterList, isActiveList, onChange }: FormatEncounterListParams) =>
      encounterList.map((item) => ({
        ...item,
        canceledDate: formatDatetime(item.canceled_date, true),
        checkbox: (
          <div style={styles.center}>
            <Checkbox
              checked={isActiveList ? checkedIds.includes(item.id) : !checkedIds.includes(item.id)}
              name={item.id}
              onChange={onChange}
            />
          </div>
        ),
        delete: item.status !== "CANCELED" && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="red"
              content="ยกเลิก"
              name={item.id}
              size="mini"
              onClick={handleOpenModDeleteEncounter}
            />
          </div>
        ),
        startDate: (
          <div style={{ textAlign: "center" }}>
            {formatDatetime(item.started, true)}
            {item.is_expired && isActiveList && <div style={{ color: "red" }}>(Exp.)</div>}
          </div>
        ),
      })),
    [handleOpenModDeleteEncounter]
  );

  // Memo
  const formattedEpisodeList = useMemo(() => {
    const items = props.EpisodeOfCareSequence?.episodeList || [];

    return items.map((item) => {
      const episodeType = props.masterOptions.episodeType?.find(
        (option) => option.value === item.episode_type
      );

      const firstEncounterCreated = formatDatetime(item.first_encounter_created, true);
      const created = formatDatetime(item.created, true);

      return {
        ...item,
        episodeTypeName: episodeType?.text,
        finishedAt: formatDatetime(item.finished_at, true),
        firstEncounterCreated: firstEncounterCreated || created,
      };
    });
  }, [props.EpisodeOfCareSequence?.episodeList]);

  const formattedEpisodeEncounterList = useMemo(() => {
    const episodeList = props.EpisodeOfCareSequence?.episodeEncounterList || [];

    return formatEncounterList({
      checkedIds: uncheckedEpisodeEncounter,
      encounterList: episodeList,
      isActiveList: false,
      onChange: handleCheckEpisodeEncounter,
    });
  }, [
    handleCheckEpisodeEncounter,
    props.EpisodeOfCareSequence?.episodeEncounterList,
    uncheckedEpisodeEncounter,
  ]);

  const formattedActiveEncounterList = useMemo(() => {
    const activeList = props.EpisodeOfCareSequence?.activeEncounterList?.items || [];

    return formatEncounterList({
      checkedIds: checkedActiveEncounter,
      encounterList: activeList,
      isActiveList: true,
      onChange: handleCheckActiveEncounter,
    });
  }, [
    checkedActiveEncounter,
    handleCheckActiveEncounter,
    props.EpisodeOfCareSequence?.activeEncounterList,
  ]);

  const searchBoxDoctor = useMemo(
    () => (
      <SearchBoxDropdown
        onEvent={props.onEvent}
        id="PHVL"
        defaultText={episodeDetail?.doctor_name_code || ""}
        icon="search"
        limit={20}
        selectedItem={episodeDetail?.doctor || null}
        style={styles.searchBox}
        type="Doctor"
        fluid
        inline
        useWithKey
        searchedItemListWithKey={props.searchedItemListWithKey}
        setSelectedItem={handleSelectedItem}
      />
    ),
    [episodeDetail?.doctor, handleSelectedItem, props.searchedItemListWithKey]
  );

  // #const isAllChecked = useMemo(() => {
  //   const activeList = props.EpisodeOfCareSequence?.activeEncounterList?.items || [];
  //   const activeListIds = activeList.map((item) => item.id as number);
  //   return (
  //     checkedActiveEncounter.length > 0 &&
  //     activeListIds.every((id) => checkedActiveEncounter.includes(id))
  //   );
  // }, [checkedActiveEncounter, props.EpisodeOfCareSequence?.activeEncounterList]);

  const tableHeaders = useMemo(
    () => [
      // <Checkbox key="all-checkbox" checked={isAllChecked} onChange={handleCheckAll} />,
      "",
      "Encounter",
      "Division",
      "Doctor",
      "Status",
      "Start",
      "Del",
    ],
    [handleCheckAll]
  );

  const buttonSave = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color="green"
        paramKey={BTN_ACTS.SAVE}
        size="small"
        title="SAVE"
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SAVE]}
        onClick={handleSave}
      />
    ),
    [handleSave, props.buttonLoadCheck]
  );

  const buttonSearchEncounter = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color="blue"
        paramKey={BTN_ACTS.SEARCH_ENCOUNTER}
        size="mini"
        title="ค้นหา"
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEARCH_ENCOUNTER]}
        onClick={handleSearchEncounter}
      />
    ),
    [handleSearchEncounter, props.buttonLoadCheck]
  );

  const loadingCheckbox = useMemo(
    () => (
      <div style={{ position: "relative", width: "fit-content" }}>
        <Checkbox
          checked={props.EpisodeOfCareSequence?.filterEpisode?.include_finished}
          label="Include Finished"
          name="include_finished"
          onChange={handleChangeFilterEpisode}
        />
        <Dimmer
          active={props.buttonLoadCheck?.[BTN_ACTS.SEARCH_EPISODE]}
          size="mini"
          style={styles.loader}
          inverted
        >
          <Loader size="mini" inline />
        </Dimmer>
      </div>
    ),
    [props.buttonLoadCheck, props.EpisodeOfCareSequence?.filterEpisode]
  );

  const approveButton = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"green"}
        name="DELETE"
        paramKey={BTN_ACTS.DELETE_EPISODE}
        size="medium"
        title="ตกลง"
        basic
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DELETE_EPISODE]}
        onClick={handleConfirmDeleteEpisode}
      />
    ),
    [handleConfirmDeleteEpisode, props.buttonLoadCheck]
  );

  const confirmContent = useMemo(
    () => (
      <div
        style={{
          fontWeight: "bold",
          margin: "0rem 1.5rem -1rem 0rem",
          textAlign: "center",
        }}
      >
        ต้องการลบรายการใช่หรือไม่
      </div>
    ),
    []
  );

  const toggleSwitch = useMemo(
    () => (
      <Android12Switch
        label="Include exp."
        switchStyle={styles.switchStyle}
        value={filterEncounter.include_expired}
        onChange={handleChangeIncludeExpired}
      />
    ),
    [filterEncounter.include_expired, handleChangeIncludeExpired]
  );

  const pagination = useMemo(
    () => (
      <PaginationShorthand
        activePage={props.EpisodeOfCareSequence?.activeEncounterList?.activePage || 1}
        limit={ENCOUNTER_LIMIT}
        total={props.EpisodeOfCareSequence?.activeEncounterList?.total}
        onPageChange={handlePageChange}
      />
    ),
    [handlePageChange, props.EpisodeOfCareSequence?.activeEncounterList]
  );

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[SEQUENCE_NAME]}
        success={null}
        onClose={handleCloseErrMsg}
      />

      <CardEpisodeOfCareUX
        activeEncounterList={formattedActiveEncounterList}
        buttonSave={buttonSave}
        buttonSearchEncounter={buttonSearchEncounter}
        episodeDetail={episodeDetail}
        episodeEncounterList={formattedEpisodeEncounterList}
        episodeList={formattedEpisodeList}
        filterEncounter={filterEncounter}
        loadingCheckbox={loadingCheckbox}
        NoDataComponent={NoDataComponent}
        pagination={pagination}
        searchBoxDoctor={searchBoxDoctor}
        showDeleteEpisode={episodeDetail?.id && formattedEpisodeEncounterList.length === 0}
        tableHeaders={tableHeaders}
        toggleSwitch={toggleSwitch}
        // options
        episodeTypeOptions={props.masterOptions.episodeType}
        // callback
        onChangeFilterEncounter={handleChangeFilterEncounter}
        onChangeValue={handleChangeValue}
        onDeleteEpisode={handleOpenModDeleteEpisode}
        onGetTrProps={handleGetTrProps}
        onNewEpisode={handleNewEpisode}
      />

      <ModAuthen
        loading={!!props.buttonLoadCheck?.[BTN_ACTS.DELETE_ENCOUNTER]}
        open={!!modDeleteEncounter}
        titleColor="blue"
        titleName="ระบุเหตุผลยกเลิก Encounter"
        onApprove={handleDeleteEncounter}
        onCloseWithDimmerClick={handleCloseModDeleteEncounter}
        onDeny={handleCloseModDeleteEncounter}
      >
        <Form.Field label="เหตุผลการยกเลิก" style={{ marginBottom: "0.5rem" }} />
        <Form.Field>
          <Input value={modDeleteEncounter?.note ?? ""} fluid onChange={handleChangeNote} />
        </Form.Field>
      </ModAuthen>

      <ModConfirm
        approveButton={approveButton}
        content={confirmContent}
        openModal={openModDelete}
        size="mini"
        titleColor="red"
        titleName="ข้อความแจ้งเตือน"
        onCloseWithDimmerClick={handleCloseModDeleteEpisode}
        onDeny={handleCloseModDeleteEpisode}
      />
    </div>
  );
};

CardEpisodeOfCare.displayName = "CardEpisodeOfCare";

export default React.memo(CardEpisodeOfCare);
