import WasmController from "react-lib/frameworks/WasmController";
import * as SetPatient from "./sequence/SetPatient";
import * as CreateUpdateEncounter from "./sequence/CreateUpdateEncounter";
import * as SetCoverage from "./sequence/SetCoverage";
import * as EpisodeOfCare from "./sequence/EpisodeOfCare";

// Config
import CONFIG from "config/config";

export type State = {} & SetPatient.State &
  CreateUpdateEncounter.State &
  SetCoverage.State &
  EpisodeOfCare.State;

export const StateInitial: State = {
  // ...SetPatient.StateInitial,
  // ...CreateUpdateEncounter.StateInitial,
  // ...SetCoverage.StateInitial,
};

export type Event =
  | SetPatient.Event
  | CreateUpdateEncounter.Event
  | SetCoverage.Event
  | EpisodeOfCare.Event;

export type Data = {
  division?: number;
  device?: number;
} & SetPatient.Data &
  CreateUpdateEncounter.Data &
  SetCoverage.Data &
  EpisodeOfCare.Data;

export const DataInitial = {
  ...SetPatient.DataInitial,
  ...CreateUpdateEncounter.DataInitial,
  ...SetCoverage.DataInitial,
  ...EpisodeOfCare.DataInitial
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

const SUPPLY_ICONS = {
  appointment_or_cancel: "./static/images/order_images/supply.png",
  pending: "./static/images/order_images/supply_pending.png",
  performed: "./static/images/order_images/supply_performed.png",
};

export const DRUG_ICONS = {
  appointment_or_cancel: "./static/images/order_images/medication.png",
  pending: "./static/images/order_images/medication_pending.png",
  performed: "./static/images/order_images/medication_performed.png",
  // intratelephar
  none: {
    idle: "/static/images/intratelephar/home-opd.png",
    call: "/static/images/intratelephar/home-opd-calling.png",
    finish: "/static/images/intratelephar/home-opd-performed.png",
  },
  intra: {
    idle: "/static/images/intratelephar/intra-telephar.png",
    call: "/static/images/intratelephar/intra-telephar-calling.png",
    telelift:
      "/static/images/intratelephar/intra-telephar-transport-telelift.png",
    tele_idle: "/static/images/intratelephar/call-intra-telephar.png",
    tele_call: "/static/images/intratelephar/call-intra-telephar-calling.png",
    finish: "/static/images/intratelephar/intra-telephar-performed.png",
  },
  delivery: {
    idle: "/static/images/intratelephar/home-delivery.png",
    call: "/static/images/intratelephar/home-delivery-calling.png",
    finish: "/static/images/intratelephar/home-delivery-performed.png",
  },
};

export const DRUG_LIST_ISOLATED_ICON = CONFIG.ENABLE_INTRA_TELEPHAR
  ? ["drugopdhomeorder", "drugstatorder", "drugonedoseorder"]
  : [];

export const DOCTOR_ORDER_ACTION_MAP = {
  appointment_or_cancel: ["APPOINTMENT", "CANCEL"],
  pending: ["PENDING"],
  performed: ["PERFORMED"],
  inprogress: ["INPROGRESS"],
};

export const TELEPHAR_ACTION_MAP = {
  idle: ["IDLE"],
  call: ["CALLING", "ON_CALL"],
  tele_idle: ["TELE_IDLE"],
  tele_call: ["TELE_CALLING", "TELE_ON_CALL"],
  finish: ["FINISH", "TELE_FINISH"],
};

export const DOCTOR_ORDER_MAPPING_ICON = {
  drug: DRUG_ICONS,
  // drugstatorder: DRUG_ICONS,
  // drugonedoseorder: DRUG_ICONS,
  // drugopdhomeorder: {},
  treatmentorder: {
    appointment_or_cancel: "./static/images/order_images/treatment.png",
    pending: "./static/images/order_images/treatment_pending.png",
    performed: "./static/images/order_images/treatment_performed.png",
  },
  centrallaborder: {
    appointment_or_cancel: "./static/images/order_images/lab.png",
    pending: "./static/images/order_images/lab_pending.png",
    performed: "./static/images/order_images/lab_performed.png",
  },
  doctorconsultorder: {
    appointment: "./static/images/order_images/consult.png",
    pending: "./static/images/order_images/consult_pending.png",
    performed: "./static/images/order_images/consult_performed.png",
    cancel: "./static/images/order_images/consult_cancel.png",
    loading: "./static/images/order_images/consult_loading.png",
  },
  imagingorder: {
    appointment_or_cancel: "./static/images/order_images/xray.png",
    pending: "./static/images/order_images/xray_pending.png",
    inprogress: "./static/images/order_images/xray_inprogress.png",
    performed: "./static/images/order_images/xray_performed.png",
  },
  operatingorder: {
    appointment_or_cancel: "./static/images/order_images/or.png",
    pending: "./static/images/order_images/or_pending.png",
    performed: "./static/images/order_images/or_performed.png",
  },
  anesthesiaorder: {
    appointment_or_cancel: "./static/images/order_images/ane.png",
    pending: "./static/images/order_images/ane_pending.png",
    performed: "./static/images/order_images/ane_performed.png",
  },
  supply: SUPPLY_ICONS, // * supply ใช้ icon เดียวกัน ไม่ต้องระบุ supplyonedayorder กับ supplyopdhomeorder
  // supplyonedayorder: SUPPLY_ICONS,
  // supplyopdhomeorder: SUPPLY_ICONS,
  admitorder: {
    appointment_or_cancel: "./static/images/order_images/admission.png",
    pending: "./static/images/order_images/admission_pending.png",
    performed: "./static/images/order_images/admission_performed.png",
  },
  doctornoteorder: {
    pending: "./static/images/order_images/drnoteorder_pending.png",
    performed: "./static/images/order_images/drnoteorder_performed.png",
  },
};
