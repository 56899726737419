import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
// INF
import DFPayrollByDoctorView from "issara-sdk/apis/DFPayrollByDoctorView_apps_INF";
import DFPayrollDraft from "issara-sdk/apis/DFPayrollDraft_apps_INF";
import DFPayrollEdit from "issara-sdk/apis/DFPayrollEdit_apps_INF";
import DFPayrollGroupCreate from "issara-sdk/apis/DFPayrollGroupCreate_apps_INF";
import DFPayrollGroupDetail from "issara-sdk/apis/DFPayrollGroupDetail_apps_INF";
import DFPayrollGroupList from "issara-sdk/apis/DFPayrollGroupList_apps_INF";
import DFPayrollIncomeReport from "issara-sdk/apis/DFPayrollIncomeReport_apps_INF";
import DFPayrollList from "issara-sdk/apis/DFPayrollList_apps_INF";
import DFPayrollReport from "issara-sdk/apis/DFPayrollReport_apps_INF";
import DFPayrollSummaryView from "issara-sdk/apis/DFPayrollSummaryView_apps_INF";

// Serializer
import DFPayrollGroupSerializer from "issara-sdk/types/DFPayrollGroupSerializer_apps_INF";
import DFPayrollSerializerI from "issara-sdk/types/DFPayrollSerializer_apps_INF";

// Common
import getPdfMake from "react-lib/appcon/common/pdfMake";
import {
  ProcessedLeaves,
  RetTypeAtPath,
  SetErrorMessage,
  SetProperty,
  combinePdfFiles,
  downloadXLSX,
  getErrorMsgLabel,
} from "react-lib/apps/HISV3/common/CommonInterface";

// Form
import FormDFPayrollReport from "../pdf/FormDFPayrollReport";
import FormDFSummary from "../pdf/FormDFSummary";
import FormSalarySlip from "../pdf/FormSalarySlip";

import { State as MainState } from "HIS/MainHISInterface";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

export type State = Partial<{
  // sequence
  DoctorFeeSummarySequence: Partial<
    {
      sequenceIndex: "START" | NextIndexType | null;
      dfPayrollDetail: DFPayrollSerializer | null;
      dfPayrollGroupList: DFPayrollGroupSerializer[];
      searchListAbort: AbortController | null;
    } & AllFilterType &
      AllSearchType
  > | null;
}>;

type PickedState = Partial<
  Pick<MainState, "buttonLoadCheck" | "errorMessage" | "searchedItemListWithKey" | "successMessage">
>;

export type PickedProps = Partial<Omit<PickedState, "">>;

export type AllFilterType = {
  filterCreate: Partial<FilterCreate>;
  filterFee: Partial<FilterFee>;
  filterSummary: Partial<FilterSummary>;
};

export type AllSearchType = {
  dfPayrollDraft: DFPayrollDraftType;
  dfPayrollFee: DFPayrollListType;
  dfPayrollSummary: DFPayrollListType;
};

export type FilterCreate = {
  endDate: string;
  startDate: string;
};

export type FilterFee = {
  dfPayrollGroup: DFPayrollGroupSerializer | null;
  doctorId: number | string; // "ALL"
  examinationType: string;
};

export type FilterSummary = {
  dfPayrollGroup: DFPayrollGroupSerializer | null;
  doctorId: number | string; // "ALL"
};

export type FilterAllKeys<T = ProcessedLeaves<AllFilterType>> =
  T extends `${string}.dfPayrollGroup.${string}` ? never : T;

export type FilterAllValues = RetTypeAtPath<FilterAllKeys, AllFilterType>;

export type DFPayrollListType = {
  items: DFPayrollSerializer[];
  summary: Partial<{
    total: number;
    total_fee_price: string;
    total_final_revenue_price: string;
    total_lab_price: string;
    total_leftover_revenue_price: string;
    total_management_fee_price: string;
    total_net_income_price: string;
    total_net_price: string;
    total_overdue_price: string;
    total_revenue_after_lab_price: string;
    total_revenue_price: string;
    total_sum_income_price: string;
  }>;
};

export type DFPayrollDraftType = {
  items: {
    adjust_down_price: number;
    adjust_up_price: number;
    bank: string;
    bank_account_no: string;
    bank_branch: string;
    compensation_percent: number;
    doctor_name: string;
    final_revenue_price: number;
    lab_price: number;
    leftover_revenue: number;
    management_fee: number;
    net_price: number;
    revenue_after_lab: number;
    revenue_price: number;
    seq: number;
  }[];
  summary: Partial<{
    doctor_count: number;
    total_final_revenue_price: number;
    total_lab_price: number;
    total_leftover_revenue: number;
    total_management_fee: number;
    total_net_price: number;
    total_revenue_after_lab: number;
    total_revenue_price: number;
  }>;
};

export type DFPayrollSerializer = { seq?: number } & DFPayrollSerializerI;

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  text: string;
  value: number | string;
};

// Sequence
type NextIndexType = "Create" | "Fee" | "Summary";

type SeqState = {
  sequence: "DoctorFeeSummary";
  clear?: boolean;
  nextIndex?: NextIndexType;
  noInit?: boolean;
  restart?: boolean;
};

// Handle Action
type ActionType =
  | {
      action: "SAVE_DF_PAYROLL";
      btnAction: string;
      data: DFPayrollSerializer;
    }
  | {
      action: "SAVE_DF_PAYROLL_GROUP";
      card: string;
    }
  | {
      action: "SEARCH_DF_PAYROLL_GROUP";
      btnAction: string;
      data: Partial<{ created_date: string; lot_no: string; year: string }>;
    }
  | { action: "CREATE"; card: string }
  | { action: "DF_PAYROLL_INCOME_REPORT"; card: string }
  | { action: "DF_PAYROLL_REPORT"; card: string }
  | { action: "DF_PAYROLL_REPORT_PDF"; card: string }
  | { action: "INIT" }
  | { action: "PRINT_PAYMENT_SLIP"; card: string }
  | { action: "SEARCH" };

// Method

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  DoctorFeeSummarySequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  device?: number;
  division?: number;
  masterData?: Record<string, any>;
};

export const DataInitial = {};

const Masters = [
  ["bankBranch", {}],
  ["doctor", {}],
  ["examinationTypeName", {}],
] as const;

export const CARD_DF_SUMMARY = "CardDoctorFeeSummary";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_DF_SUMMARY}_${T[K] & string}`;
};

export const ACTIONS = {
  INIT: "INIT",
  CREATE: "CREATE",
  DF_PAYROLL_INCOME_REPORT: "DF_PAYROLL_INCOME_REPORT",
  DF_PAYROLL_REPORT: "DF_PAYROLL_REPORT",
  DF_PAYROLL_REPORT_PDF: "DF_PAYROLL_REPORT_PDF",
  PRINT_PAYMENT_SLIP: "PRINT_PAYMENT_SLIP",
  SAVE_DF_PAYROLL: "SAVE_DF_PAYROLL",
  SAVE_DF_PAYROLL_GROUP: "SAVE_DF_PAYROLL_GROUP",
  SEARCH: "SEARCH",
  SEARCH_DF_PAYROLL_GROUP: "SEARCH_DF_PAYROLL_GROUP",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${CARD_DF_SUMMARY}_${key}`])
) as Optional<typeof ACTIONS>;

type Handler<P = unknown, R = void> = (
  controller: WasmController<PickedState & State, Event, Data>,
  ...params: unknown extends P ? [params?: P] : [params: P]
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const Start: Handler<SeqState> = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  Next(controller, params);
};

/* ------------------------------------------------------ */

/*                          Next                          */

/* ------------------------------------------------------ */
const Next: Handler<SeqState> = (controller, params) => {
  const state = controller.getState();

  const { nextIndex, noInit } = params;

  if (!nextIndex) {
    return;
  }

  state.DoctorFeeSummarySequence?.searchListAbort?.abort();

  controller.setState(
    {
      DoctorFeeSummarySequence: { ...state.DoctorFeeSummarySequence, sequenceIndex: nextIndex },
    },
    () => {
      const init = {
        Create,
        Fee,
        Summary,
      }[nextIndex];

      if (!noInit) {
        init(controller, { ...params, action: ACTIONS.INIT, nextIndex: undefined });
      }
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Create: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    Next(controller, params);

    return;
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.INIT]: HandleInitCreate,
    [ACTIONS.CREATE]: HandlerCreatePayrollGroup,
    [ACTIONS.SEARCH]: HandleSearchPayrollDraft,
  };

  const { action } = params;

  actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

export const Fee: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    Next(controller, params);

    return;
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.INIT]: HandleInitFee,
    [ACTIONS.DF_PAYROLL_REPORT]: HandleDFPayrollReport,
    [ACTIONS.DF_PAYROLL_REPORT_PDF]: HandleDFPayrollReportPdf,
    [ACTIONS.SAVE_DF_PAYROLL]: HandleSaveDFPayroll,
    [ACTIONS.SEARCH]: HandleSearchDFPayroll,
    [ACTIONS.SEARCH_DF_PAYROLL_GROUP]: HandleSearchDFPayrollGroup,
  };

  const { action } = params;

  actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

export const Summary: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (params.nextIndex) {
    Next(controller, params);

    return;
  }

  state.DoctorFeeSummarySequence?.searchListAbort?.abort();

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.INIT]: HandleInitSummary,
    [ACTIONS.DF_PAYROLL_INCOME_REPORT]: HandleDFPayrollIncomeReport,
    [ACTIONS.PRINT_PAYMENT_SLIP]: HandlePrintPaymentSlip,
    [ACTIONS.SAVE_DF_PAYROLL_GROUP]: HandleSaveDFPayrollGroup,
    [ACTIONS.SEARCH]: HandleSearchDFPayrollByDoctor,
    [ACTIONS.SEARCH_DF_PAYROLL_GROUP]: HandleSearchDFPayrollGroup,
  };

  const { action } = params;

  actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

/* ------------------------------------------------------ */

/*                      Handle Create                     */

/* ------------------------------------------------------ */
const HandleInitCreate: Handler<Params<"INIT">> = (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeSummarySequence;

  const momentDate = moment();

  const filter = {
    endDate: formatDate(momentDate.clone().endOf("months")),
    startDate: formatDate(momentDate.clone().startOf("months")),
    ...seq?.filterCreate,
  };

  controller.setState(
    {
      DoctorFeeSummarySequence: { ...seq, filterCreate: filter },
    },
    () => {
      HandleSearchPayrollDraft(controller);
    }
  );
};

const HandleInitFee: Handler<Params<"INIT">> = (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeSummarySequence;

  const filter = { doctorId: "ALL", ...seq?.filterFee };

  controller.setState(
    {
      DoctorFeeSummarySequence: { ...seq, filterFee: filter },
    },
    () => {
      HandleSearchDFPayroll(controller);
    }
  );
};

const HandleInitSummary: Handler<Params<"INIT">> = (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeSummarySequence;

  const filter = { doctorId: "ALL", ...seq?.filterSummary };

  controller.setState(
    {
      DoctorFeeSummarySequence: { ...seq, filterSummary: filter },
    },
    () => {
      HandleSearchDFPayrollByDoctor(controller);
    }
  );
};

const HandleSearchPayrollDraft: Handler = async (controller) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const result = await GetDFPayrollDraft(controller);

  if (result.message) {
    return;
  }

  state = controller.getState();

  controller.setState({
    DoctorFeeSummarySequence: {
      ...state.DoctorFeeSummarySequence,
      dfPayrollDraft: result.data,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

const HandlerCreatePayrollGroup: Handler<Params<"CREATE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.CREATE]: "LOADING" },
  });

  const filter = state.DoctorFeeSummarySequence?.filterCreate;

  const [result, error] = await DFPayrollGroupCreate.post({
    apiToken: controller.apiToken,
    params: {
      end_date: filter?.endDate,
      start_date: filter?.startDate,
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.CREATE]: "SUCCESS" },
      successMessage: { ...state.successMessage, [BTN_ACTS.CREATE]: result },
    });
  }
};

/* ------------------------------------------------------ */

/*                       Handle Fee                       */

/* ------------------------------------------------------ */
const HandleSearchDFPayrollGroup: Handler<Params<"SEARCH_DF_PAYROLL_GROUP">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "LOADING" },
  });

  const [result] = await DFPayrollGroupList.list({
    apiToken: controller.apiToken,
    params: params.data,
  });

  controller.setState({
    DoctorFeeSummarySequence: {
      ...state.DoctorFeeSummarySequence,
      dfPayrollGroupList: result?.items || [],
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "SUCCESS" },
  });
};

const HandleSearchDFPayroll: Handler = async (controller) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const result = await GetDFPayrollList(controller);

  if (result.message) {
    return;
  }

  state = controller.getState();

  controller.setState({
    DoctorFeeSummarySequence: {
      ...state.DoctorFeeSummarySequence,
      dfPayrollFee: result.data,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

const HandleDFPayrollReport: Handler<Params<"DF_PAYROLL_REPORT">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DF_PAYROLL_REPORT]: "LOADING" },
  });

  const filter = state.DoctorFeeSummarySequence?.filterFee;

  const dfPayrollGroup = filter?.dfPayrollGroup || {};

  const [result, error] = await DFPayrollReport.get({
    apiToken: controller.apiToken,
    params: {
      doctor: filter?.doctorId === "ALL" ? undefined : filter?.doctorId,
      end_date: dfPayrollGroup.ended_date,
      examination_type: filter?.examinationType,
      export_excel: true,
      payroll_group: filter?.dfPayrollGroup?.id,
      start_date: dfPayrollGroup.started_date,
    },
    extra: { responseType: "arraybuffer" },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });
  } else {
    downloadXLSX(result, "รายงานค่าตอบแทนแทพย์");

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DF_PAYROLL_REPORT]: "SUCCESS" },
    });
  }
};

const HandleDFPayrollReportPdf: Handler<Params<"DF_PAYROLL_REPORT_PDF">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DF_PAYROLL_REPORT_PDF]: "LOADING" },
  });

  const filter = state.DoctorFeeSummarySequence?.filterFee;

  const dfPayrollGroup = filter?.dfPayrollGroup || {};

  const [result, error] = await DFPayrollReport.get({
    apiToken: controller.apiToken,
    params: {
      doctor: filter?.doctorId === "ALL" ? undefined : filter?.doctorId,
      end_date: dfPayrollGroup.ended_date,
      examination_type: filter?.examinationType, // #67185
      export_excel: false,
      payroll_group: filter?.dfPayrollGroup?.id,
      start_date: dfPayrollGroup.started_date,
    },
    // extra: { responseType: "arraybuffer" },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });

    return;
  }

  const doctorFeePayrollData = {
    filter,
    result,
  };

  const docDFPayrollDef: any = await FormDFPayrollReport(doctorFeePayrollData);
  const pdfMake = await getPdfMake(true);

  pdfMake.createPdf(docDFPayrollDef).print();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [BTN_ACTS.DF_PAYROLL_REPORT_PDF]: "SUCCESS",
    },
  });
};

const HandleSaveDFPayroll: Handler<Params<"SAVE_DF_PAYROLL">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "LOADING" },
  });

  const { btnAction, data } = params;

  const adjustUpError = !data.remark_adjust_up_price && !!Number(data.adjust_up_price);
  const adjustDownError = !data.remark_adjust_down_price && !!Number(data.adjust_down_price);

  if (adjustUpError || adjustDownError) {
    const errors = [
      adjustUpError && ["remark_adjust_up_price", "จำเป็นต้องระบุเหตุผลปรับเพิ่มค่าตอบแทน"],
      adjustDownError && ["remark_adjust_down_price", "จำเป็นต้องระบุเหตุผลปรับลดค่าตอบแทน"],
    ].filter(Boolean) as any[];

    SetErrorMessage(controller, {
      ...params,
      btnError: "",
      error: {
        error: errors.map((value) => value?.[1]),
        show: true,
        showRequiredField: Object.fromEntries(errors),
      },
      errorKey: btnAction,
    });

    return;
  }

  const [, error] = await DFPayrollEdit.patch({
    apiToken: controller.apiToken,
    data,
    pk: data.id,
  });

  if (error) {
    SetErrorMessage(controller, {
      ...params,
      btnError: "",
      error: { error, show: true },
      errorKey: btnAction,
    });
  } else {
    controller.setState(
      {
        DoctorFeeSummarySequence: { ...state.DoctorFeeSummarySequence, dfPayrollDetail: null },
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnAction]: null },
        errorMessage: { ...state.errorMessage, [btnAction]: null },
      },
      () => {
        HandleSearchDFPayroll(controller);
      }
    );
  }
};

/* ------------------------------------------------------ */

/*                     Handle Summary                     */

/* ------------------------------------------------------ */
const HandleSearchDFPayrollByDoctor: Handler = async (controller) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const result = await GetDFPayrollListByDoctor(controller);

  if (result.message) {
    return;
  }

  state = controller.getState();

  controller.setState({
    DoctorFeeSummarySequence: {
      ...state.DoctorFeeSummarySequence,
      dfPayrollSummary: result.data,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

const HandleDFPayrollIncomeReport: Handler<Params<"DF_PAYROLL_INCOME_REPORT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DF_PAYROLL_INCOME_REPORT]: "LOADING" },
  });

  const [result, error] = await GetDFPayrollIncomeReport(controller, { excel: true });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });
  } else {
    downloadXLSX(result, "รายงานสรุปรายได้ค่าตอบแทนแพทย์");

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DF_PAYROLL_INCOME_REPORT]: "SUCCESS" },
    });
  }
};

const HandleSaveDFPayrollGroup: Handler<Params<"SAVE_DF_PAYROLL_GROUP">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE_DF_PAYROLL_GROUP]: "LOADING" },
  });

  const seq = state.DoctorFeeSummarySequence;
  const dfPayrollGroup = seq?.filterSummary?.dfPayrollGroup || {};
  const summaryItems = seq?.dfPayrollSummary?.items || [];

  const [result, error] = await DFPayrollGroupDetail.patch({
    apiToken: controller.apiToken,
    data: {
      pay_date: dfPayrollGroup.pay_date,
      pay_to_bank_branch: dfPayrollGroup.pay_to_bank_branch,
    },
    pk: dfPayrollGroup.id,
  });

  if (error) {
    SetErrorMessage(controller, {
      ...params,
      error: {
        error: getErrorMsgLabel(error, {
          pay_date: "วันเดือนปีที่จ่าย",
          pay_to_bank_branch: "สถานที่นำเงินเข้า",
        }),
        show: true,
        showRequiredField: error,
      },
    });
  } else {
    controller.setState(
      {
        DoctorFeeSummarySequence: {
          ...seq,
          filterSummary: { ...seq?.filterSummary, dfPayrollGroup: result },
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE_DF_PAYROLL_GROUP]: "SUCCESS" },
        errorMessage: { ...state.errorMessage, [params.card]: null },
      },
      () => {
        if (summaryItems.length > 0) {
          HandleSearchDFPayroll(controller);
        }
      }
    );
  }
};

const HandlePrintPaymentSlip: Handler<Params<"PRINT_PAYMENT_SLIP">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT_PAYMENT_SLIP]: "LOADING" },
  });

  const [result, error] = await GetDFPayrollIncomeReport(controller, { excel: false });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  const formsPromises: any[] = [FormDFSummary({ ...result.params, data: result.fields })];

  // issue 67973 
  const splitItemsResultData = (items: any, size: number) => {
    let result = [];
    for (let i = 0; i < items.length; i += size) {
      result.push(items.slice(i, i + size));
    }
    return result;
  };

  const transformResultData = (params: any, data: any) => {
    let result: any[] = [];
    data.forEach((doctor: any) => {
      let itemsData = splitItemsResultData(doctor.items, 2);
      itemsData.forEach((group: any, index: number) => {
        let newGroup = {
          ...params,
          ...doctor,
          items: group,
          showSummary: index === itemsData.length - 1
        };
        result.push(newGroup);
      });
    });
    return result;
  };

  const transformResult = transformResultData(result.params, result.fields);

  for (const field of transformResult) {
    formsPromises.push(FormSalarySlip({ ...field, ...transformResult }));
  }

  const forms = await Promise.all(formsPromises);

  const bloburl = await combinePdfFiles(forms);

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [BTN_ACTS.PRINT_PAYMENT_SLIP]: "SUCCESS",
    },
  });

  globalThis.open(bloburl);
};

/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */
const GetDFPayrollDraft: Handler<
  unknown,
  Promise<{ data: DFPayrollDraftType; message: string }>
> = async (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeSummarySequence;
  const abortCtrl = new AbortController();

  controller.setState({
    DoctorFeeSummarySequence: { ...seq, searchListAbort: abortCtrl },
  });

  const filter = seq?.filterCreate;

  if (!(filter?.startDate && filter.endDate)) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: null },
    });

    return { data: { items: [], summary: {} }, message: "required" };
  }

  const response = await DFPayrollDraft.get({
    apiToken: controller.apiToken,
    params: {
      end_date: filter.endDate,
      start_date: filter.startDate,
    },
    extra: {
      signal: abortCtrl.signal,
    },
  });

  if (response[2].message === "canceled") {
    return { data: { items: [], summary: {} }, message: response[2].message };
  }

  return {
    data: { items: response[0]?.fields || [], summary: response[0]?.params || {} },
    message: "",
  };
};

const GetDFPayrollList: Handler<
  any,
  Promise<{ data: DFPayrollListType; message: string }>
> = async (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeSummarySequence;
  const defaultData = { items: [], summary: {} };
  const abortCtrl = new AbortController();

  controller.setState({
    DoctorFeeSummarySequence: { ...seq, searchListAbort: abortCtrl },
  });

  const filter = seq?.filterFee || {};

  if (!filter.dfPayrollGroup?.id) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: null },
    });

    return { data: defaultData, message: "required" };
  }

  const { dfPayrollGroup, doctorId, examinationType } = filter;

  // #const examinationType = controller.data.masterData?.examinationType?.find(
  //   (item: any) => "examinationType" in filter && item.id === filter.examinationType
  // )?.name;

  const urlParams = {
    df_payroll_group: dfPayrollGroup.id,
    doctor: doctorId === "ALL" ? undefined : doctorId,
    examination_type: examinationType,
  };

  const [list, sum] = await Promise.all([
    DFPayrollList.list({
      apiToken: controller.apiToken,
      params: urlParams,
      extra: {
        signal: abortCtrl.signal,
      },
    }),
    DFPayrollSummaryView.get({
      apiToken: controller.apiToken,
      params: urlParams,
    }),
  ]);

  if (list[2].message === "canceled") {
    return { data: defaultData, message: list[2].message };
  }

  return {
    data: { items: list[0]?.items || [], summary: sum[0] || {} },
    message: "",
  };
};

const GetDFPayrollListByDoctor: Handler<
  any,
  Promise<{ data: DFPayrollListType; message: string }>
> = async (controller) => {
  const state = controller.getState();

  const seq = state.DoctorFeeSummarySequence;
  const defaultData = { items: [], summary: {} };
  const abortCtrl = new AbortController();

  controller.setState({
    DoctorFeeSummarySequence: { ...seq, searchListAbort: abortCtrl },
  });

  const filter = seq?.filterSummary || {};

  if (!filter.dfPayrollGroup?.id) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: null },
    });

    return { data: defaultData, message: "required" };
  }

  const { dfPayrollGroup, doctorId } = filter;

  const urlParams = {
    df_payroll_group: dfPayrollGroup.id,
    doctor: doctorId === "ALL" ? undefined : doctorId,
  };

  const [list, sum] = await Promise.all([
    DFPayrollByDoctorView.get({
      apiToken: controller.apiToken,
      params: urlParams,
      extra: {
        signal: abortCtrl.signal,
      },
    }),
    DFPayrollSummaryView.get({
      apiToken: controller.apiToken,
      params: urlParams,
    }),
  ]);

  if (list[2].message === "canceled") {
    return { data: defaultData, message: list[2].message };
  }

  return {
    data: { items: list[0]?.items || [], summary: sum[0] || {} },
    message: "",
  };
};

const GetDFPayrollIncomeReport: Handler<{ excel: boolean }, Promise<[any, any]>> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const filter = state.DoctorFeeSummarySequence?.filterSummary;

  const dfPayrollGroup = filter?.dfPayrollGroup || {};

  return DFPayrollIncomeReport.get({
    apiToken: controller.apiToken,
    params: {
      doctor: filter?.doctorId === "ALL" ? undefined : filter?.doctorId,
      end_date: dfPayrollGroup.ended_date,
      export_excel: params.excel,
      pay_date: dfPayrollGroup.pay_date,
      pay_to_bank_branch: dfPayrollGroup.pay_to_bank_branch,
      payroll_group: dfPayrollGroup.id,
      start_date: dfPayrollGroup.started_date,
    },
    extra: params.excel ? { responseType: "arraybuffer" } : {},
  });
};
