import React, { CSSProperties, SyntheticEvent, useCallback, useMemo, useState } from "react";

import { Button, Label } from "semantic-ui-react";

import htmlParse from "html-react-parser";
import moment from "moment";

// Common
import ModInfo from "react-lib/apps/common/ModInfo";

// UX
import CardConsultCancelDetail from "../DPO/CardConsultCancelDetail";
import CardConsultOrderDetailUX from "../DPO/CardConsultOrderDetailUX";

import CardHomeMedDetail from "./CardHomeMedDetail";

import { APPOINTMENT_STATUS, APPOINTMENT_STATUS_NAME } from "../DPO/sequence/ConsultationList";
import { RESULT_SUMMARY, RESULT_SUMMARY_LABEL } from "../IME/sequence/ImagingHandler";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

// Types
type ModDoctorOrderDetailProps = {
  onEvent: any;
  setProp: (key: string, value: any, callback?: () => any) => any;

  encounterType?: string;
  items?: (Record<string, any> | string)[];
  open: boolean;
  orderType?: string;
  // options
  masterOptions?: any;
  // callback
  onClose?: (e: SyntheticEvent) => any;
};

type Styles = Record<"label", CSSProperties>;

// Const
const styles: Styles = {
  label: { paddingRight: "10px" },
};

const ModDoctorOrderDetail = (props: ModDoctorOrderDetailProps) => {
  const [openModConsultCancelDetail, setOpenModConsultCancelDetail] = useState<any>({
    item: null,
    open: false,
  });

  // Callback
  const handleOpenModConsultCancelDetail = useCallback((item: any) => {
    setOpenModConsultCancelDetail({ item, open: true });
  }, []);

  // Memo
  const consultItems = useMemo(() => {
    if (props.orderType !== "doctorconsultorder") {
      return [];
    }

    const items = (props.items || []).filter(
      (item): item is Record<string, any> => typeof item !== "string"
    );

    return items.map((item) => {
      let appStatus = APPOINTMENT_STATUS_NAME.CONFIRMED;

      if (APPOINTMENT_STATUS.WAIT_CONFIRMED === item.app_status) {
        appStatus = APPOINTMENT_STATUS_NAME.WAIT_CONFIRMED;
      } else if (APPOINTMENT_STATUS.CANCELED === item.app_status) {
        appStatus = APPOINTMENT_STATUS_NAME.CANCELED;
      }

      let appColorStatus = "green";

      if (APPOINTMENT_STATUS.WAIT_CONFIRMED === item.app_status) {
        appColorStatus = "yellow";
      } else if (APPOINTMENT_STATUS.CANCELED === item.app_status) {
        appColorStatus = "red";
      }

      const orderDateTime = moment(item.created as string, "YYYY-MM-DD HH:mm:ss");
      const orderDate = `${formatDate(orderDateTime)} [${orderDateTime.format("HH:mm")}]`;

      const bookingTime = item.app_start_time
        ? moment(item.app_start_time as string, "HH:mm:ss").format("HH:mm")
        : "";
      const bookingText = `${item.app_date} [${bookingTime}]`;

      return { ...item, appColorStatus, appStatus, bookingText, orderDate };
    });
  }, [props.items]);

  const showDetail = useMemo(
    () => !props.items?.every((value) => typeof value === "string"),
    [props.items]
  );

  const buttonStyle = useMemo(
    () => ({
      display: showDetail ? "none" : "",
    }),
    [showDetail]
  );

  const style = useMemo(() => {
    if (showDetail && props.orderType !== "doctorconsultorder") {
      return { minWidth: "fit-content", width: 0 };
    }

    return {};
  }, [props.orderType, showDetail]);

  const handleCloseModConsultCancelDetail = useCallback(() => {
    setOpenModConsultCancelDetail({ item: null, open: false });
  }, []);

  return (
    <ModInfo
      backgroundColor="var(--primary-theme-color)"
      btnText="ปิด"
      buttonColor="grey"
      buttonStyle={buttonStyle}
      // size="large"
      open={props.open}
      size="small"
      style={style}
      titleName="รายละเอียด"
      closeOnDimmerClick
      onApprove={props.onClose}
      onClose={props.onClose}
    >
      {/* #<Dimmer active={!!props.loadingStatus?.[MOD_ORDER]} inverted>
        <Loader inverted />
      </Dimmer> */}
      <RenderContent
        onEvent={props.onEvent}
        setProp={props.setProp}
        consultItems={consultItems}
        encounterType={props.encounterType}
        items={props.items}
        // data
        orderType={props.orderType}
        showDetail={showDetail}
        // options
        masterOptions={props.masterOptions}
        // callback
        onClose={props.onClose}
        onOpenModConsultCancelDetail={handleOpenModConsultCancelDetail}
      />

      <CardConsultCancelDetail
        cancel_note={openModConsultCancelDetail?.item?.cancel_note || ""}
        cancel_user={openModConsultCancelDetail?.item?.cancel_user || ""}
        isOpen={openModConsultCancelDetail?.open}
        onCloseMod={handleCloseModConsultCancelDetail}
      />
    </ModInfo>
  );
};

/* ------------------------------------------------------ */

/*                      RenderContent                     */

/* ------------------------------------------------------ */
type OrderContentProps = {
  // data
  consultItems?: Record<string, any>[];
  showDetail: boolean;
  // callback
  onOpenModConsultCancelDetail: (data: any) => void;
} & Pick<
  ModDoctorOrderDetailProps,
  "encounterType" | "items" | "masterOptions" | "onClose" | "onEvent" | "orderType" | "setProp"
>;

const RenderContent = (props: OrderContentProps) => {
  // Callback
  const getStatusColor = useCallback((consultStatusLabel: string) => {
    switch (consultStatusLabel) {
      case "กำลัง Consult": {
        return "yellow";
      }
      case "Consult เสร็จสิ้น": {
        return "green";
      }

      default: {
        return "grey";
      }
    }
  }, []);

  const handleOpenModConsultCancelDetail = useCallback(
    (data: any) => () => {
      props.onOpenModConsultCancelDetail(data);
    },
    [props.onOpenModConsultCancelDetail]
  );

  const getLabelStatus = useCallback(
    (item: Record<string, any>) => (
      <div>
        <Label
          color={item.appColorStatus || ""}
          content={item.appStatus || ""}
          icon="calendar alternate"
          style={styles.label}
        />
        {item.order_status_name === "CANCEL" ? (
          <Button
            color="red"
            icon="sticky note"
            size="mini"
            onClick={handleOpenModConsultCancelDetail(item)}
          />
        ) : (
          <Label
            color={getStatusColor(item.consult_status_label as string)}
            content={item.consult_status_label}
            icon="user md"
            style={styles.label}
          />
        )}
      </div>
    ),
    [getStatusColor]
  );

  if (props.orderType === "doctorconsultorder") {
    return (
      <>
        {props.consultItems?.map((item) => (
          <div key={`consult-${item.id}`}>
            <CardConsultOrderDetailUX
              consultedDateTime={item.bookingText}
              consultedDivision={item.division_name || ""}
              consultedEncounterNo={item.consulted_encounter_no || ""}
              consultedNote={item.note}
              labelStatus={getLabelStatus(item)}
              orderDateTime={item.orderDate || ""}
              orderDivision={item.ordered_div_name || ""}
              orderDoctor={item.ordered_doctor_name || ""}
              orderEncounterNo={item.ordered_encounter_no || ""}
              consultedDoctor={
                item.consulted_doctor_name === "-" ? "ไม่ระบุแพทย์" : item.consulted_doctor_name
              }
            />
          </div>
        ))}
      </>
    );
  } else if (props.showDetail) {
    const items = (props.items || []).filter(
      (item): item is Record<string, any> => typeof item !== "string"
    );

    const filteredItems = items.flatMap((object) =>
      ((object.items || []) as Record<string, any>[]).filter(
        (item) => RESULT_SUMMARY_LABEL[item.result_summary] === RESULT_SUMMARY.ABNORMAL
      )
    );

    return (
      <div>
        {items.map((item, index) => (
          <CardHomeMedDetail
            key={`detail${item.id}`}
            onEvent={props.onEvent}
            setProp={props.setProp}
            // data
            displayAbnormal={filteredItems.length > 0}
            index={index}
            items={items}
            // options
            masterOptions={props.masterOptions}
            // callback
            onClose={props.onClose}
          />
        ))}
      </div>
    );
  }

  return (
    <div
      style={{
        lineHeight: 1.75,
        margin: "-0.5rem 0rem -0.25rem",
        textAlign: "left",
      }}
    >
      {(props.items || []).map(
        (item) =>
          typeof item === "string" && (
            <div key={item} style={{ padding: "10px 0px" }}>
              {htmlParse(item)}
            </div>
          )
      )}
    </div>
  );
};

ModDoctorOrderDetail.displayName = "ModDoctorOrderDetail";

export default React.memo(ModDoctorOrderDetail);
