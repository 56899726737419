import React, { useState, useEffect, useMemo } from "react";
import { Icon, Button, Label, Pagination } from "semantic-ui-react";

// UX
import CardSettingUser from "./CardSettingUser";
import CardSettingEmployee from "./CardSettingEmployee";
import CardSettingDivision from "./CardSettingDivision";
import CardSettingUserEmployee from "./CardSettingUserEmployee";
import CardStaffSearch from "./CardStaffSearch";

// Types
export type FilterType = {
  searchCode: string;
  firstName: string;
  lastName: string;
  position: string;
  organization: string | number;
};

const CardStaffSetting = (props: any) => {
  const [mode, setMode] = useState<"search" | "add" | "edit" | "">("");
  const [tab, setTab] = useState<string>("user-employee");

  const [filter, setFilter] = useState<Partial<FilterType>>({});

  const [selectedRow, setSelectedRow] = useState<any>(null);

  useEffect(() => {
    props.onEvent({
      message: "SearchStaff",
      params: { fetchOptionsOnly: props.fetchOptionsOnly, activePage: 1 },
    });
  }, []);

  useEffect(() => {
    if (props.editUserProfile?.id) {
      const data = props.editUserProfile;

      const profile = {
        id: data.id,
        username: data.username,
        email: data.email,
        citizen_passport: data.citizen_passport,
        profile: {
          ...data,
          cid: data.citizen_passport || "",
        },
        employee: {},
        pre_name: data.pre_name,
        first_name: data.first_name,
        last_name: data.last_name,
        isUser: true,
      };

      handleSelected(profile, false);
    }
  }, []);

  useEffect(() => {
    setMode(props.mode || "search");
    setTab(props.tab || "");
  }, []);

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    setFilter({
      ...filter,
      [key]: v.value,
    });
  };

  const handleAddStaff = () => {
    setMode("add");
    setTab("user-employee");

    props.onEvent({
      message: "SettingDivision",
      params: { action: "FETCH_DATA" },
    });

    props.setProp("profileUser", null);
    props.setProp("profileEmployee", { status: 1 });
  };

  const handleSelected = (data: any, isFetchDivision: boolean) => {
    setMode("edit");
    setTab("user-employee");
    setSelectedRow(data);

    if (isFetchDivision) {
      props.onEvent({
        message: "SettingDivision",
        params: { id: data?.id, action: "FETCH_DATA" },
      });
    }

    props.onEvent({
      message: "SettingStaff",
      params: {
        action: "EDIT",
        id: data?.id,
        profile: data.profile,
        showMemberGroupPermission: props.showMemberGroupPermission,
      },
    });
  };

  const handleGoback = () => {
    setMode("search");
    setSelectedRow(null);

    if (mode === "add" && !!props.user?.id) {
      setFilter({});

      props.onEvent({ message: "SearchStaff", params: { activePage: 1 } });
    } else {
      props.onEvent({
        message: "SearchStaff",
        params: { activePage: props.staffList?.activePage, filter },
      });
    }

    props.setProp("profileUser", null);
    props.setProp("user", null);
    props.setProp("profileEmployee", null);
  };

  // #console.log("CardStaffSearch", props)

  return (
    <>
      {mode === "search" ? (
        <CardStaffSearch
          onEvent={props.onEvent}
          // data
          selectedRow={selectedRow}
          filter={filter}
          staffList={props.staffList}
          // options
          masterOptions={props.masterOptions}
          // config
          organizationFilter={props.organizationFilter}
          // callback
          onChangeValue={handleChangeValue}
          onAddStaff={handleAddStaff}
          onSelected={handleSelected}
        />
      ) : ["add", "edit"].includes(mode) ? (
        <div>
          <div
            style={{
              display: "flex",
              padding: "15px 0px 0 10px",
            }}
          >
            {!props.hideButtonBack && (
              <Label
                as="a"
                basic
                size="large"
                style={{ border: 0 }}
                onClick={handleGoback}
              >
                <Icon name="arrow circle left"></Icon>
                Back
              </Label>
            )}

            {/* {!props.hideTabEmployee && (
              <Button color={tab === "employee" ? "blue" : undefined} onClick={() => setTab("employee")}>
                ข้อมูลพนักงาน
              </Button>
            )} */}

            {/* {!props.hideTabUser && (
              <Button color={tab === "user" ? "blue" : undefined} onClick={() => setTab("user")}>
                ข้อมูลผู้ใช้งานระบบ
              </Button>
            )} */}

            <Button
              color={tab === "user-employee" ? "blue" : undefined}
              onClick={() => setTab("user-employee")}
            >
              ข้อมูลผู้ใช้งาน
            </Button>

            {!props.hideTabDivision && (
              <Button
                color={tab === "division" ? "blue" : undefined}
                onClick={() => setTab("division")}
              >
                หน่วยงาน/สิทธิ์
              </Button>
            )}
          </div>
          <hr></hr>

          {tab === "user-employee" ? (
            <CardSettingUserEmployee
              // ให้เหมือนกันทั้ง CU และ Raksthai
              // isRaksthaiRequirment={!!props.isRaksthaiRequirement}
              setProp={props.setProp}
              onEvent={props.onEvent}
              // data
              profileUser={props.profileUser}
              changePassword={props.changePassword}
              profileEmployee={props.profileEmployee}
              selectedRow={selectedRow}
              user={props.user}
              languageUX={props.languageUX}
              // CommonInterface
              errorMessage={props.errorMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              successMessage={props.successMessage}
              // options
              masterOptions={props.masterOptions}
              roleOptions={props.roleOptions}
              masterData={props.masterData}
              memberGroupOptions={props.memberGroupOptions}
              // style
              style={props.userEmployeeStyle}
              // config
              hideDoctorForm={props.hideDoctorForm}
              showPassword2={props.showPassword2}
              pw2CheckTeacherFlag={props.pw2CheckTeacherFlag}
              hideEmployeeForm={props.hideEmployeeForm}
              showMemberGroupPermission={props.showMemberGroupPermission}
            />
          ) : // tab === "user-employee" ? (
          //   <CardSettingUser
          //     setProp={props.setProp}
          //     onEvent={props.onEvent}
          //     // data
          //     profileUser={props.profileUser}
          //     selectedRow={selectedRow}
          //     changePassword={props.changePassword}
          //     user={props.user}
          //     // CommonInterface
          //     successMessage={props.successMessage}
          //     errorMessage={props.errorMessage}
          //     buttonLoadCheck={props.buttonLoadCheck}
          //     // options
          //     masterOptions={props.masterOptions}
          //   />
          // ) :
          //  tab === "employee" ? (
          //   <CardSettingEmployee
          //     setProp={props.setProp}
          //     onEvent={props.onEvent}
          //     // data
          //     profileUser={props.profileUser}
          //     changePassword={props.changePassword}
          //     profileEmployee={props.profileEmployee}
          //     selectedRow={selectedRow}
          //     user={props.user}
          //     profileDivision={props.profileDivision}
          //     // CommonInterface
          //     errorMessage={props.errorMessage}
          //     buttonLoadCheck={props.buttonLoadCheck}
          //     successMessage={props.successMessage}
          //     // options
          //     masterOptions={props.masterOptions}
          //     divisionOptions={props.divisionOptions}
          //     roleOptions={props.roleOptions}
          //     // config
          //     hideDoctorForm={props.hideDoctorForm}
          //     showUserForm={props.showUserForm}
          //   />
          // ) :
          tab === "division" ? (
            <CardSettingDivision
              setProp={props.setProp}
              onEvent={props.onEvent}
              // data
              user={props.user}
              profileDivision={props.profileDivision}
              languageUX={props.languageUX}
              // CommonInterface
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
              // options
              divisionOptions={props.masterOptions.division}
              // config
              tableHeaders={props.tableHeaders}
              tableKeys={props.tableKeys}
              organizationFilter={props.organizationFilter}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

CardStaffSetting.displayName = "CardStaffSetting";

export default React.memo(CardStaffSetting);
