import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Calendar
} from 'react-big-calendar'
import {
  Button,
  Input,
  Dropdown
} from 'semantic-ui-react'

const CardChairUX = (props: any) => {
    return(
      <div
        style={{display: "flex", height: "100%"}}>
        <div
          style={{flex: 1}}>
          
          <div
            style={{display: props.mode === "chair" ? "block" : "none", padding: "5px"}}>
            
            <div
              style={{fontWeight: "bold",margin: "0px 0px 10px 0px"}}>
              รายการเก้าอี้
            </div>
            <div
              style={{display: "flex", justifyContent:"space-between",margin: "10px 0px"}}>
              
              <div
                style={{width: "33%"}}>
                เลขเก้าอี้
                <Input
                  icon="filter"
                  onChange={props.onChangeFilterChairNo}
                  style={{width: "100%", display: "none"}}
                  value={props.filterChairNo}>
                </Input>
                <Dropdown
                  clearable={true}
                  onChange={props.onChangeFilterChairNo}
                  options={props.chairNoOptions}
                  search={true}
                  selection={true}
                  value={props.filterChairNo}>
                </Dropdown>
              </div>
              <div
                style={{width: "33%"}}>
                สถานะ
                <Input
                  icon="filter"
                  onChange={props.onChangeFilterStatus}
                  style={{width: "100%", display: "none"}}
                  value={props.filterStatus}>
                </Input>
                <Dropdown
                  clearable={true}
                  onChange={props.onChangeFilterStatus}
                  options={props.statusOptions}
                  search={true}
                  selection={true}
                  value={props.filterStatus}>
                </Dropdown>
              </div>
              <div
                style={{width: "33%"}}>
                คลินิก
                <Input
                  icon="filter"
                  onChange={props.onChangeFilterClinic}
                  style={{width: "100%", display: "none"}}
                  value={props.filterClinic}>
                </Input>
                <Dropdown
                  clearable={true}
                  onChange={props.onChangeFilterClinic}
                  options={props.clinicOptions}
                  search={true}
                  selection={true}
                  value={props.filterClinic}>
                </Dropdown>
              </div>
            </div>
            <div>
              
              <Table
                data={props.chairList}
                getTrProps={props.rowProps}
                headers=",เลขเก้าอี้,สถานะ,คลีนิค"
                keys="select,name,status_name,division_name"
                showPagination={false}
                style={{height: "80vh"}}
                widths="30,100,100">
              </Table>
            </div>
            <div>
              
              <Button
                color="green"
                onClick={props.selectChairFinish}
                size="tiny">
                เสร็จสิ้น
              </Button>
              <Button
                color="red"
                onClick={props.clearChair}
                size="tiny">
                เคลียร์
              </Button>
            </div>
          </div>
          <div
            style={{ position: "relative", display: props.mode === "home" ? "block" : "none", padding: "5px"}}>
            
            <div
              style={{marginBottom: "10px"}}>
              
              <div
                style={{display: "flex",}}>
                
                <div
                  style={{display: "flex", fontWeight: "bold"}}>
                  เก้าอี้: 
                </div>
                <div
                  style={{marginLeft: "10px"}}>
                  {props.selectedChairs}
                </div>
              </div>
              <div>
                
                <Button
                  color="green"
                  onClick={props.selectChair}
                  size="tiny">
                  เลือกเก้าอี้
                </Button>
                <Button
                  color="red"
                  onClick={props.clearChair}
                  size="tiny">
                  เคลียร์
                </Button>
              </div>
            </div>
            <div>
              {props.zoneSelector}
            </div>
            <div
              style={{ marginTop: "10px"}}>
              {props.cardSelectTemplate}
            </div>
          </div>
        </div>
        <div
          style={{flex: 3, padding: "5px", height: "90vh"}}>
          
          <div
            style={{fontWeight: "bold"}}>
            วันเวลาที่ให้บริการ
          </div>
          <Calendar
            eventPropGetter={props.eventPropGetter}
            events={props.blockList || []}
            localizer={props.localizer}
            max={props.maxTime}
            min={props.minTime}
            onRangeChange={props.onRangeChange}
            onSelectEvent={props.onSelectEvent}>
          </Calendar>
        </div>
      </div>
    )
}

export default CardChairUX

export const screenPropsDefault = {"cardSelectTemplate":"[CardSelectTemplate]","divisionChoices":{"0":{"key":1,"text":"a","value":1}},"dsbList":{"0":1,"1":2},"mode":"home","selectedChairs":"00023-1, 00024-2","zoneSelector":"[ZoneSelector]"}

/* Date Time : Wed Aug 10 2022 05:53:58 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ position: \"relative\", display: props.mode === \"home\" ? \"block\" : \"none\", padding: \"5px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 2,
      "props": {
        "data": {
          "type": "code",
          "value": "props.chairList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": ",เลขเก้าอี้,สถานะ,คลีนิค"
        },
        "keys": {
          "type": "value",
          "value": "select,name,status_name,division_name"
        },
        "minRows": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"80vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "30,100,100"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาที่ให้บริการ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการเก้าอี้"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",margin: \"0px 0px 10px 0px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, padding: \"5px\", height: \"90vh\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "react-big-calendar",
      "id": 39,
      "name": "Calendar",
      "parent": 38,
      "props": {
        "eventPropGetter": {
          "type": "code",
          "value": "props.eventPropGetter"
        },
        "events": {
          "type": "code",
          "value": "props.blockList || []"
        },
        "localizer": {
          "type": "code",
          "value": "props.localizer"
        },
        "max": {
          "type": "code",
          "value": "props.maxTime"
        },
        "min": {
          "type": "code",
          "value": "props.minTime"
        },
        "onRangeChange": {
          "type": "code",
          "value": "props.onRangeChange"
        },
        "onSelectEvent": {
          "type": "code",
          "value": "props.onSelectEvent"
        }
      },
      "seq": 39,
      "void": true
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cardSelectTemplate"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.mode === \"chair\" ? \"block\" : \"none\", padding: \"5px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.zoneSelector"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"10px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกเก้าอี้"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.selectChair"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Button",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearChair"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedChairs"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "เก้าอี้: "
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Button",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "เสร็จสิ้น"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.selectChairFinish"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearChair"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"space-between\",margin: \"10px 0px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขเก้าอี้"
        },
        "style": {
          "type": "code",
          "value": "{width: \"33%\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"33%\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Input",
      "parent": 55,
      "props": {
        "icon": {
          "type": "value",
          "value": "filter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterChairNo"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterChairNo"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Input",
      "parent": 57,
      "props": {
        "icon": {
          "type": "value",
          "value": "filter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterStatus"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterStatus"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิก"
        },
        "style": {
          "type": "code",
          "value": "{width: \"33%\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Input",
      "parent": 61,
      "props": {
        "icon": {
          "type": "value",
          "value": "filter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterClinic"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterClinic"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterChairNo"
        },
        "options": {
          "type": "code",
          "value": "props.chairNoOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filterChairNo"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Dropdown",
      "parent": 57,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterStatus"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filterStatus"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Dropdown",
      "parent": 61,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterClinic"
        },
        "options": {
          "type": "code",
          "value": "props.clinicOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filterClinic"
        }
      },
      "seq": 65,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardChairUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "cardSelectTemplate": "[CardSelectTemplate]",
    "divisionChoices": {
      "0": {
        "key": 1,
        "text": "a",
        "value": 1
      }
    },
    "dsbList": {
      "0": 1,
      "1": 2
    },
    "mode": "home",
    "selectedChairs": "00023-1, 00024-2",
    "zoneSelector": "[ZoneSelector]"
  },
  "width": 85
}

*********************************************************************************** */
