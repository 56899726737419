import React, { useEffect, useMemo, useCallback } from "react";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import PreOPButtonAction from "react-lib/apps/HISV3/ORM/PreOPButtonAction"
// ui ORM
import CardPreOPVisitUX from "./CardPreOPVisitUX";

const CARD_KEY: string = "preOPVisitData";
const FORM_CODE: string = "CardPreOPVisit";
const FORM_NAME: string = "Pre-OP Visit";
const FORM_VERSION: string = "0.1";

type CardPreOPVisitProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PreOperationSequence?: any;
};

const CardPreOPVisitInitial: CardPreOPVisitProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PreOperationSequence: {},
};

const CardPreOPVisit: React.FC<CardPreOPVisitProps> = (props: any) => {
  // Callback Effect
  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${CARD_KEY}`, null);
    props.setProp(`successMessage.${CARD_KEY}`, null);
  }, []);

  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });

    return () => {
      handleCloseErrMsg();
    };
  }, []);

  const handleChangeDate = (date: string) => {
    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: "date_of_visit",
      value: date,
    });
  };

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }
    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  return (
    <>
      {(props.errorMessage?.[CARD_KEY] || props.successMessage?.[CARD_KEY]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={handleCloseErrMsg}
          error={props.errorMessage?.[CARD_KEY]}
          success={props.successMessage?.[CARD_KEY]}
        />
      )}
      <CardPreOPVisitUX
        // function
        onChangeDate={handleChangeDate}
        onChangeData={handleChangeData}
        // options
        masterOptions={props.masterOptions}
        // seq
        PreOperationSequence={props.PreOperationSequence}
        // component
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
      />
    </>
  );
};



CardPreOPVisit.defaultProps = CardPreOPVisitInitial;

export default React.memo(CardPreOPVisit);
