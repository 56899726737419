import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Dropdown,
  FormField,
  FormGroup,
  Input,
  Modal,
} from "semantic-ui-react";
// ui common
import { EmployeeToken } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
// ui LAB
import CardHistoryCentralLab from "../LAB/CardHistoryCentralLab";
// ui ORM
import CardHoldingRoomUX from "./CardHoldingRoomUX";
import PreOPButtonAction from "./PreOPButtonAction";

const CARD_KEY: string = "holdingRoomData";
const FORM_CODE: string = "CardHoldingRoom";
const FORM_NAME: string = "Holding Room";
const FORM_VERSION: string = "0.1";

type CardHoldingRoomProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PreOperationSequence?: any;
  HistoryCentralLabSequence?: any;
};

const CardHoldingRoomInitial: CardHoldingRoomProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PreOperationSequence: {},
  HistoryCentralLabSequence: {},
};

const CardHoldingRoom: React.FC<CardHoldingRoomProps> = (props: any) => {
  const [openModHistoryCentralLab, setOpenModHistoryCentralLab] =
    useState<boolean>(false);

  // Callback Effect
  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${CARD_KEY}`, null);
    props.setProp(`successMessage.${CARD_KEY}`, null);
  }, []);

  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });

    return ()=> {
      handleCloseErrMsg()
    }
  }, []);

  const handleShowLabData = () => {
    setOpenModHistoryCentralLab(true);
  };

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  const handleAddMedication = (key: string) => {
    let result = [...props.PreOperationSequence?.holdingRoomData?.data?.[key]];
    result.push({
      medicine_name: "",
      route: "",
      time: "",
      code: "",
    });

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: key,
      value: result,
    });
  };

  const handleRemoveMedication = (key: string, index: number) => {
    let result = [...props.PreOperationSequence?.holdingRoomData?.data?.[key]];
    result.splice(index, 1);

    if (result.length === 0) {
      result.push({
        medicine_name: "",
        route: "",
        time: "",
        code: "",
        codeText: "",
      });
    }

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: key,
      value: result,
    });
  };

  const handleChangeMedication = (key: string, index: number, data: any) => {
    let result = [...props.PreOperationSequence?.holdingRoomData?.data?.[key]];
    result[index][data.name] = data.value;

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: key,
      value: result,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  return (
    <>
      {(props.errorMessage?.[CARD_KEY] || props.successMessage?.[CARD_KEY]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={handleCloseErrMsg}
          error={props.errorMessage?.[CARD_KEY]}
          success={props.successMessage?.[CARD_KEY]}
        />
      )}

      <CardHoldingRoomUX
        // function
        onChangeData={handleChangeData}
        onShowLabData={handleShowLabData}
        // options
        masterOptions={props.masterOptions}
        // seq
        PreOperationSequence={props.PreOperationSequence}
        // component
        divMedication={
          <>
            {props.PreOperationSequence?.holdingRoomData?.data?.medication_item?.map(
              (item: any, index: number) => {
                return (
                  <FormGroup key={index} inline={true}>
                    <FormField inline={true} width={3}>
                      <Dropdown
                        value={item?.medicine_name}
                        name="medicine_name"
                        options={props.masterOptions?.orMedication || []}
                        selection={true}
                        fluid={true}
                        style={{ width: "100%" }}
                        onChange={(_event: any, data: any) =>
                          handleChangeMedication("medication_item", index, data)
                        }
                      ></Dropdown>
                    </FormField>
                    <FormField inline={true} width={4}>
                      <label>Route</label>
                      <Dropdown
                        value={item?.route}
                        name="route"
                        options={props.masterOptions?.route || []}
                        selection={true}
                        fluid={true}
                        style={{ width: "100%" }}
                        onChange={(_event: any, data: any) =>
                          handleChangeMedication("medication_item", index, data)
                        }
                      ></Dropdown>
                    </FormField>
                    <FormField inline={true} width={4}>
                      <label>Time</label>
                      <TimeComboBox
                        defaultValue={item?.time || ""}
                        style={{ width: "100%" }}
                        onTextChange={(time: string) =>
                          handleChangeMedication("medication_item", index, {
                            name: "time",
                            value: time,
                          })
                        }
                      />
                    </FormField>
                    <FormField inline={true} width={5}>
                      <label style={{ width: "100%", maxWidth: "max-content" }}>
                        Given by
                      </label>
                      <EmployeeToken
                        placeholder="Code"
                        employeeName={
                          item?.code
                            ? decodeURIComponent(atob(item?.code.split(".")[1]))
                            : ""
                        }
                        //
                        employeeCode={item?.codeText}
                        onChangeCodeText={(codeText: any) => {
                          props.setProp(
                            `PreOperationSequence.holdingRoomData.data.medication_item.${index}.codeText`,
                            codeText.value
                          );
                        }}
                        inputStyle={{ width: "100%" }}
                        onEnterToken={(codeText: any) => {
                          props.runSequence({
                            sequence: "PreOperation",
                            action: "USER_TOKENIZE",
                            cardKey: CARD_KEY,
                            code: codeText,
                            key: "medication_item",
                            index: index,
                          });
                        }}
                        onClearToken={() =>
                          handleChangeMedication("medication_item", index, {
                            name: "code",
                            value: "",
                          })
                        }
                      />
                    </FormField>
                    <FormField
                      inline={true}
                      style={{
                        minWidth: "max-content",
                        padding: 0,
                        marginRight: "-0.5rem",
                      }}
                    >
                      <Button
                        color="red"
                        icon="minus"
                        size="mini"
                        style={{ margin: "0 10px 0 -10px" }}
                        onClick={() =>
                          handleRemoveMedication("medication_item", index)
                        }
                      ></Button>
                      <Button
                        color="green"
                        icon="plus"
                        size="mini"
                        style={{ margin: "0 0 0 10px" }}
                        onClick={() => handleAddMedication("medication_item")}
                      ></Button>
                    </FormField>
                  </FormGroup>
                );
              }
            )}
          </>
        }
        divOtherMedication={
          <>
            {props.PreOperationSequence?.holdingRoomData?.data?.medication_other_item?.map(
              (item: any, index: number) => {
                return (
                  <FormGroup key={index} inline={true}>
                    <FormField inline={true} width={3}>
                      <Input
                        name="medicine_name"
                        value={item?.medicine_name}
                        fluid={true}
                        style={{ width: "100%" }}
                        onChange={(_event: any, data: any) =>
                          handleChangeMedication(
                            "medication_other_item",
                            index,
                            data
                          )
                        }
                      ></Input>
                    </FormField>
                    <FormField inline={true} width={4}>
                      <label>Route</label>
                      <Dropdown
                        name="route"
                        value={item?.route}
                        selection={true}
                        fluid={true}
                        style={{ width: "100%" }}
                        options={props.masterOptions?.route || []}
                        onChange={(_event: any, data: any) =>
                          handleChangeMedication(
                            "medication_other_item",
                            index,
                            data
                          )
                        }
                      ></Dropdown>
                    </FormField>
                    <FormField inline={true} width={4}>
                      <label>Time</label>
                      <TimeComboBox
                        defaultValue={item?.time || ""}
                        style={{ width: "100%" }}
                        onTextChange={(time: string) =>
                          handleChangeMedication(
                            "medication_other_item",
                            index,
                            {
                              name: "time",
                              value: time,
                            }
                          )
                        }
                      />
                    </FormField>
                    <FormField inline={true} width={5}>
                      <label style={{ width: "100%", maxWidth: "max-content" }}>
                        Given by
                      </label>
                      <EmployeeToken
                        placeholder="Code"
                        employeeName={
                          item?.code
                            ? decodeURIComponent(atob(item?.code.split(".")[1]))
                            : ""
                        }
                        employeeCode={item?.codeText}
                        onChangeCodeText={(codeText: any) => {
                          props.setProp(
                            `PreOperationSequence.holdingRoomData.data.medication_other_item.${index}.codeText`,
                            codeText.value
                          );
                        }}
                        inputStyle={{ width: "100%" }}
                        onEnterToken={(codeText: any) => {
                          props.runSequence({
                            sequence: "PreOperation",
                            action: "USER_TOKENIZE",
                            cardKey: CARD_KEY,
                            code: codeText,
                            key: "medication_other_item",
                            index: index,
                          });
                        }}
                        onClearToken={() =>
                          handleChangeMedication(
                            "medication_other_item",
                            index,
                            {
                              name: "code",
                              value: "",
                            }
                          )
                        }
                      />
                    </FormField>
                    <FormField
                      inline={true}
                      style={{
                        minWidth: "max-content",
                        padding: 0,
                        marginRight: "-0.5rem",
                      }}
                    >
                      <Button
                        color="red"
                        icon="minus"
                        size="mini"
                        style={{ margin: "0 10px 0 -10px" }}
                        onClick={() =>
                          handleRemoveMedication("medication_other_item", index)
                        }
                      ></Button>
                      <Button
                        color="green"
                        icon="plus"
                        size="mini"
                        style={{ margin: "0 0 0 10px" }}
                        onClick={() =>
                          handleAddMedication("medication_other_item")
                        }
                      ></Button>
                    </FormField>
                  </FormGroup>
                );
              }
            )}
          </>
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
      />

      {openModHistoryCentralLab && (
        <Modal
          size="large"
          closeIcon
          open={openModHistoryCentralLab}
          onClose={() => setOpenModHistoryCentralLab(false)}
        >
          <CardHistoryCentralLab
            // function
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            HistoryCentralLabSequence={props.HistoryCentralLabSequence}
            // data
            buttonLoadCheck={props.buttonLoadCheck}
            patientId={props.selectedOrOrder?.patient_id}
            hidePrintButton={true}
          />
        </Modal>
      )}
    </>
  );
};

CardHoldingRoom.defaultProps = CardHoldingRoomInitial;

export default React.memo(CardHoldingRoom);
