import React , {useRef, useEffect, useState, useCallback} from "react";
import { Input, InputProps } from "semantic-ui-react";
import PropTypes from "prop-types";
import _ from "../../compat/lodashplus"

const CardCSDoctorSearch = (props) => {

  const isMounted = useRef(true)
  const doctorInputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)

  const [doctorOptions, setDoctorOptions] = useState([])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (props.allDoctorList?.length > 0) {
      const options = props.allDoctorList?.map( item => {
        return {
          key: item.id,
          value: item.full_name, 
          text: item.full_name, 
        }
      })
      setDoctorOptions(options)
    }
  }, [props.allDoctorList])


  const debounceKeying = useCallback(_.debounce(() =>{
    handleSearch()
    }, 5000, {leading: false}),
    []
  )

  const handleSearch = useCallback(
    () => {
      let doctorSearch = doctorInputRef?.current?.inputRef?.current?.value
      if (doctorSearch !== null && doctorSearch !== undefined) {
        setIsLoading(true)
        props.setDoctorSearch(doctorSearch)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000);
      }
    },
    [doctorInputRef],
  )

  return (
    <Input
      disabled={props.disabled}
      className="card-cs-division-search"
      size="mini"
      ref={doctorInputRef}
      icon="search"
      loading={props.isLoading || isLoading}
      placeholder="doctor..."
      onChange={(e: ChangeEvent, data: InputProps)=> {
        console.log("e:", e)
        console.log("data", data)
        debounceKeying()
      }}
      onKeyDown={(e: KeyboardEvent) => {
        console.log(" e.key", e.key)
        if (e.key === "Enter") {
          debounceKeying.cancel()
          handleSearch()
        } 
      }}
      // options={doctorOptions}
    />
  );
};

CardCSDoctorSearch.propTypes = {
  allDoctorList: PropTypes.arrayOf(PropTypes.object),
  setDoctorSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

CardCSDoctorSearch.defaultProps = {
  allDoctorList: [],
  setDoctorSearch: () => {},
  isLoading: false,
  disabled: false,

};
export default CardCSDoctorSearch;
