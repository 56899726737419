import React, { useEffect, useRef, useState } from "react";
// UX
import CardFiveDiseasesCertificateUX from "./CardFiveDiseasesCertificateUX";
// Common

// Interface
import { State } from "./sequence/DoctorCertificate";
// Utils
import moment from "moment";

// Main Props
type CardFiveDiseasesCertificateProps = {
  // Function
  setProp: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

// Current Date
const currentDate = moment().format("YYYY-MM-DD, LT");
const christYear = moment(currentDate).format("YYYY");
const buddhistYear = (parseInt(christYear) + 543).toString();
const formattedDate = moment(currentDate).format(
  "DD/MM/YYYY".replace("YYYY", buddhistYear)
);

// Main
const CardFiveDiseasesCertificate = (
  props: CardFiveDiseasesCertificateProps
) => {
  // log Props
  // console.log("FiveDiseasesCertificate Props: ", props.DoctorCertificateSequence);

  // Call Sequence ( Five Diseases Certificate )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "FETCH_CERTIFICATE",
      fetchType: "CardFiveDiseasesCertificate",
    });
  }, []);

  // Change Date
  const handleChangeDate = (name: any, date: string) => {
    // console.log("Key: ", name + " Date: ", date);
    props.setProp(`DoctorCertificateSequence.${name}`, date);
  };

  // Change Found Diseases
  const handleChangeFoundDisease = (value: string) => {
    props.setProp(`DoctorCertificateSequence.fiveReport.isFoundDisease`, value);
    // console.log("Radio in Sequence: ", props.DoctorCertificateSequence?.fiveReport?.isFoundDisease);
    if (
      props.DoctorCertificateSequence?.fiveReport?.isFoundDisease === "Free"
    ) {
      props.setProp(
        `DoctorCertificateSequence.fiveReport.isFirstDisease`,
        false
      );
      props.setProp(
        `DoctorCertificateSequence.fiveReport.isSecondDisease`,
        false
      );
      props.setProp(
        `DoctorCertificateSequence.fiveReport.isThirdDisease`,
        false
      );
      props.setProp(
        `DoctorCertificateSequence.fiveReport.isFourthDisease`,
        false
      );
      props.setProp(
        `DoctorCertificateSequence.fiveReport.isFifthDisease`,
        false
      );
    }
  };

  // Select Radio ( Found Diseases ?)
  const handleSelectedRadio = (e: any, { value }: { value: string }) => {
    // console.log("Radio Select: ", value); // Radio Select: "Free", "Found"
    handleChangeFoundDisease(value);
  };

  // Checked Radio
  const radioChecked = (value: string) => {
    switch (value) {
      case "Free":
        return (
          props.DoctorCertificateSequence?.fiveReport?.isFoundDisease ===
          `${value}`
        );
      case "Found":
        return (
          props.DoctorCertificateSequence?.fiveReport?.isFoundDisease ===
          `${value}`
        );
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Enable / Disable Checkbox
  const handleAbleCheckbox = () => {
    // console.log("Radio Check: ", props.DoctorCertificateSequence?.fiveReport?.isFoundDisease);
    if (
      props.DoctorCertificateSequence?.fiveReport?.isFoundDisease === "Free"
    ) {
      return true;
    } else if (
      props.DoctorCertificateSequence?.fiveReport?.isFoundDisease === "Found"
    ) {
      return false;
    } else {
      return true;
    }
  };

  // Check / Uncheck Checkbox
  const handleCheckCheckbox = (name: string) => {
    // console.log("Found Disease Status: ", props.DoctorCertificateSequence?.fiveReport?.isFoundDisease); / "Free", "Found"
    // console.log("Name from handleCheckCheckbox: ", name);
    if (
      props.DoctorCertificateSequence?.fiveReport?.isFoundDisease === "Free"
    ) {
      return false;
    }

    switch (name) {
      case "isFirstDisease":
      case "isSecondDisease":
      case "isThirdDisease":
      case "isFourthDisease":
      case "isFifthDisease":
        return props.DoctorCertificateSequence?.fiveReport?.[name];
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Change Value Disease
  const handleSelectedDisease = (e: any, name: string, data: any) => {
    // console.log(" Value Type: ", data.type + " Status: ", data.checked);
    if (data.type === "checkbox") {
      var value = data.checked;
      props.setProp(`DoctorCertificateSequence.fiveReport.${name}`, value);
    }
  };

  // Change Other Note
  const handleChangeNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setProp(
      `DoctorCertificateSequence.fiveReport.otherNote`,
      e.target.value
    );
  };

  // Condition Close Button
  // const clearSelectedData = () => {
  //   // Set selectedReport = null
  //   props.setProp(`DoctorCertificateSequence.selectedReport`, null);

  //   // Re-Fetch
  //   props.runSequence({
  //     sequence: "DoctorCertificate",
  //     action: "CLEAR_REPORT",
  //     callReport: "CardFiveDiseasesCertificate",
  //   });
  // };

  // const closeSelectedCheck = (reportSelected: any) => {
  //   if (reportSelected != null) {
  //     return (
  //       <Button
  //         color="red"
  //         style={{ width: "8em" }}
  //         onClick={clearSelectedData}
  //       >
  //         ปิด
  //       </Button>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Disable when select FiveForm
  const handleDisableForm = (selected: any, reportType?: any) => {
    if (selected == null) {
      return false;
    } else if (selected !== null && reportType === "ใบรับรองแพทย์ 5 โรค") {
      return true;
    } else {
      return false;
    }
  };
  // console.log("Five_Disable Report: ", props.DoctorCertificateSequence?.selectedReport?.data?.reportType)
  // console.log("Five_Disable Status: ", handleDisableForm(props.DoctorCertificateSequence?.selectedReport, props.DoctorCertificateSequence?.selectedReport?.data?.reportType))

  return (
    <>
      <CardFiveDiseasesCertificateUX
        // Examination Date
        examDate={props.DoctorCertificateSequence?.fiveReport?.examDate}
        onHandleExamDate={(date: string) =>
          handleChangeDate("fiveReport.examDate", date)
        }
        // Min Date
        onMinDate={moment().subtract(1, "days")}
        // Identification
        identNo={
          props.DoctorCertificateSequence?.fiveReport?.patientInfo
            ?.citizen_no ||
          props.DoctorCertificateSequence?.fiveReport?.patientInfo
            ?.citizen_passport ||
          ""
        }
        // Select Radio
        onHandleSelectedRadio={handleSelectedRadio}
        onCheckRadio={radioChecked}
        // Checkbox enable/disable
        onCheckboxCheck={handleAbleCheckbox}
        // Send Checkbox Value
        onCheckCheckbox={handleCheckCheckbox}
        onHandleSelectedDisease={handleSelectedDisease}
        // Other Note
        otherNote={props.DoctorCertificateSequence?.fiveReport?.otherNote || ""}
        onHandleChangeNote={handleChangeNote}
        // Close Button When Selected Report
        // onCloseSelected={closeSelectedCheck(
        //   null
        // )}
        // Disable Input
        // onHandleDisableForm={handleDisableForm(
        //   null,
        //   props.DoctorCertificateSequence?.selectedReport?.data?.reportType
        // )}
      />
    </>
  );
};

export default CardFiveDiseasesCertificate;
