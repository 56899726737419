import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input,
  Dropdown,
  Button,
  FormGroup,
  FormField,
  Icon
} from 'semantic-ui-react'

const CardDrugContinuePlanUX = (props: any) => {
    return(
      <div>
        <div
          style={{ height: "50px" , padding: "0 5px"}}>

          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(3, 1fr)","gridTemplateColumns":"repeat(38, 1fr)"}}>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/8" }}>
              <Button
                className={props.drugContinueFilter?.status === "only_open" ? "fluid" : "basic fluid"}
                color="blue"
                name="only_open"
                onClick={props.onSetdrugContinueFilter}
                style={{minWidth: "max-content"}}>
                เฉพาะรายการที่ยังไม่ปิด
              </Button>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "9/15" }}>
              <Button
                className={props.drugContinueFilter?.status === "only_new" ? "fluid" : "basic fluid"}
                color="blue"
                name="only_new"
                onClick={props.onSetdrugContinueFilter}
                style={{minWidth: "max-content"}}>
                เฉพาะรายการใหม่
              </Button>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "16/22" }}>
              <Button
                className={props.drugContinueFilter?.status === "only_active" ? "fluid" : "basic fluid"}
                color="blue"
                name="only_active"
                onClick={props.onSetdrugContinueFilter}
                style={{minWidth: "max-content"}}>
                เฉพาะรายการปัจจุบัน
              </Button>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "23/27" }}>
              <Button
                className={props.drugContinueFilter?.status === "all" ? "fluid" : "basic fluid"}
                color="blue"
                name="all"
                onClick={props.onSetdrugContinueFilter}
                style={{minWidth: "max-content"}}>
                แสดงทั้งหมด
              </Button>
            </div>
          </div>
        </div>
        <Table
          NoDataComponent={props.noDataComponent}
          data={props.drugContinuePlanItems}
          getTdProps={props.getDrugListTdProps}
          getTrProps={props.drugListProps}
          headers="Drug, Dose, Unit, Route, Site, Frequency,PRN, Qty, Status, Action"
          keys="title_status, dose, unitText, routeText, siteText, frequencyText,prn, qty, status,action"
          minRows="10"
          showPagination={false}
          style={{height:"35vh", padding: "0 5px", display: "none"}}
          widths=",80,80,,130,130, 80,80,,80">
        </Table>
        <div>
          {props.tableElement}
        </div>
        <div
          className="ui form"
          style={{padding:"15px", ...(props.hidePanel && {display: "none"})}}>

          <FormGroup
            style={{marginBottom: "0.5rem"}}>
            <FormField
              inline={true}
              width={5}>
              <div>
                <div
                  style={{marginRight:"10px"}}>
                  ยา
                </div>
                <div
                  style={{width: "100%"}}>
                  {props.drugSearchBox}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>

                <div
                  style={{minWidth:"40px"}}>
                  {"\u00a0"}
                </div>
                <Button
                  color={props.hasFactsheetData ? "blue" : undefined}
                  disabled={!props.hasFactsheetData}
                  fluid={true}
                  onClick={props.onClickDrugFact}>
                  DRUG FACT
                </Button>
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <div>
                {props.calculatorIcon}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {"\u00a0"}
              </div>
              <Button
                basic={!props.modSolventSelectionConfig?.activeButtonSolvent}
                color={props.modSolventSelectionConfig?.showButtonSolvent ?"blue": "grey"}
                disabled={!props.modSolventSelectionConfig?.showButtonSolvent }
                onClick={props.onOpenModSolventSelection}
                style={{cursor: props.modSolventSelectionConfig?.activeButtonSolvent || typeof props.modSolventSelectionConfig?.admixtureIndex === "number"?"auto":"pointer"}}>
                SOLVENT
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={3}>
              <div>

                <label
                  style={{minWidth:"40px"}}>
                  Dose
                </label>
                <label
                  style={{ display: props.DrugSelectSequence?.require_unit ? "" : "none", color: "red" }}>
                  *
                </label>
                <Input
                  disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                  fluid={true}
                  loading={props.loadingPreviews}
                  name="dose"
                  onChange={props.onPreviewDrugContinuePlan}
                  value={props.DrugSelectSequence?.dose || ""}>
                </Input>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>

                <label
                  style={{minWidth:"55px"}}>
                  Unit
                </label>
                <label
                  style={{ display: props.DrugSelectSequence?.require_unit ? "" : "none", color: "red" }}>
                   *
                </label>
                <Dropdown
                  className="inline-label"
                  clearable={true}
                  disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                  fluid={true}
                  loading={props.loadingPreviews}
                  name="unit"
                  onChange={props.onPreviewDrugContinuePlan}
                  options={props.drugUnits}
                  search={true}
                  selection={true}
                  value={props.DrugSelectSequence?.unit || ""}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>

                <label
                  style={{minWidth:"40px"}}>
                  Route
                </label>
                <label
                  style={{ display: props.DrugSelectSequence?.require_route ? "" : "none", color: "red" }}>
                   *
                </label>
                <Dropdown
                  className="inline-label"
                  clearable={true}
                  disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                  fluid={true}
                  loading={props.loadingPreviews}
                  name="route"
                  onChange={props.onPreviewDrugContinuePlan}
                  options={props.drugRoutes}
                  search={true}
                  selection={true}
                  value={props.DrugSelectSequence?.route || ""}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>

                <label
                  style={{minWidth:"40px"}}>
                  Site
                </label>
                <label
                  style={{ display: props.DrugSelectSequence?.require_site ? "" : "none", color: "red" }}>
                   *
                </label>
                <Dropdown
                  className="inline-label"
                  clearable={true}
                  disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                  fluid={true}
                  loading={props.loadingPreviews}
                  name="site"
                  onChange={props.onPreviewDrugContinuePlan}
                  options={props.drugSites}
                  search={true}
                  selection={true}
                  value={props.DrugSelectSequence?.site || ""}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ display: "none" }}
              width={3}>
              <div>

                <label
                  style={{minWidth:"55px"}}>
                  Method
                </label>
                <label
                  style={{ display: props.DrugSelectSequence?.require_method ? "" : "none", color: "red" }}>
                   *
                </label>
                <Dropdown
                  className="inline-label"
                  clearable={true}
                  disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                  fluid={true}
                  loading={props.loadingPreviews}
                  name="method"
                  onChange={props.onPreviewDrugContinuePlan}
                  options={props.drugMethods}
                  search={true}
                  selection={true}
                  value={props.DrugSelectSequence?.method || ""}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div>

                <label
                  style={{minWidth:"75px"}}>
                  Frequency
                </label>
                <label
                  style={{ display: props.DrugSelectSequence?.require_frequency ? "" : "none", color: "red" }}>
                   *
                </label>
                <Dropdown
                  className="inline-label"
                  clearable={true}
                  disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                  fluid={true}
                  loading={props.loadingPreviews}
                  name="frequency"
                  onChange={props.onPreviewDrugContinuePlan}
                  options={props.drugFrequencies}
                  search={true}
                  selection={true}
                  value={props.DrugSelectSequence?.frequency || ""}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{alignItems: "center", display: "flex",  justifyContent: "center"}}
              width={1}>
              <div
                style={{ display: "none" }}>

                <div
                  style={{marginTop: "-0.15rem"}}>
                  {"\u00a0"}
                </div>
                <Button
                  className="circular"
                  color="green"
                  icon="clipboard list"
                  onClick={props.onDrugDescription}
                  style={{ padding: "0.5rem 0.59rem 0.6rem", fontSize: "1.45rem"}}>
                </Button>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width="8">
              <div>

                <div>
                  PRN
                </div>
                <Dropdown
                  allowAdditions={!!props.DrugSelectSequence?.code}
                  clearable={true}
                  fluid={true}
                  name="prn"
                  onAddItem={props.onAdditionPrn}
                  onChange={props.onUnPreviewDrugContinuePlan}
                  options={props.prnOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.DrugSelectSequence?.prn || ""}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              width="7">
              <div>

                <div>
                  รายละเอียดการใช้ยาเพิ่มเติม
                </div>
                <Input
                  fluid={true}
                  name="description"
                  onChange={props.onUnPreviewDrugContinuePlan}
                  value={props.DrugSelectSequence?.description || ""}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            className="--readOnly --grey"
            style={{display: props.isRequireRate? "": "none",marginBottom: "0.5rem"}}>
            <FormField
              width={2}>
              <div
                style={{marginBottom: "-1px"}}>
                {props.dripRateLabel}
              </div>
              <Input
                disabled={props.modSolventSelectionConfig?.dripRate !== "rate"}
                fluid={true}
                name="mixture_iv_rate"
                onChange={props.onChangeModData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.modSolventSelectionConfig?.mixture_iv_rate}>
              </Input>
            </FormField>
            <FormField
              width={2}>
              <label>
                {"\u00a0"}
              </label>
              <Dropdown
                className="inline-label"
                clearable={true}
                disabled={props.modSolventSelectionConfig?.dripRate !== "rate"}
                fluid={true}
                name="mixture_iv_rate_unit"
                onChange={props.onChangeModData}
                options={props.modSolventSelectionConfig?.ivRateOptions || []}
                search={true}
                selection={true}
                value={props.modSolventSelectionConfig?.mixture_iv_rate_unit}>
              </Dropdown>
            </FormField>
            <FormField
              width={2}>
              <div
                style={{marginBottom: "-1px"}}>
                {props.dripDurationLabel}
              </div>
              <Input
                disabled={props.modSolventSelectionConfig?.dripRate === "rate"}
                fluid={true}
                name="drug_drip_in"
                onChange={props.onChangeModData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.modSolventSelectionConfig?.drug_drip_in}>
              </Input>
            </FormField>
            <FormField
              width={2}>
              <label>
                {"\u00a0"}
              </label>
              <Dropdown
                className="inline-label"
                clearable={true}
                disabled={props.modSolventSelectionConfig?.dripRate === "rate"}
                fluid={true}
                name="drug_drip_in_unit"
                onChange={props.onChangeModData}
                options={props.modSolventSelectionConfig?.dripInOptions || []}
                search={true}
                selection={true}
                value={props.modSolventSelectionConfig?.drug_drip_in_unit}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              style={{display: "flex", alignItems: "center"}}
              width="3">
              <div>

                <div>
                  est.
                </div>
                <label>
                  {props.DrugSelectSequence?.calculated_daily_quantity}
                </label>
              </div>
            </FormField>
            <FormField
              width="3">
              <div>

                <div>
                  qty.
                </div>
                <Input
                  fluid={true}
                  loading={props.loadingPreviews}
                  name="sending_quantity"
                  onChange={props.onUnPreviewDrugContinuePlan}
                  value={props.DrugSelectSequence?.sending_quantity || ""}>
                </Input>
              </div>
            </FormField>
            <FormField
              width="4">
              <div>

                <div>
                  day
                </div>
                <Input
                  fluid={true}
                  loading={props.loadingPreviews}
                  name="sending_interval"
                  onChange={props.onUnPreviewDrugContinuePlan}
                  value={props.DrugSelectSequence?.sending_interval || ""}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{display: props.hideSolvent? "none": "",marginBottom: "0.5rem"}}>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderRight: "none", paddingTop: "1rem", paddingBottom: "1rem" }}
              width={6}>
              <label>
                Solvent
              </label>
              <div>
                {props.searchBoxSolvent}
              </div>
            </FormField>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderRight: "none", paddingTop: "1rem", paddingBottom: "1rem" , borderLeft: "none"}}
              width={2}>
              <label>
                Volume (For mixing)
              </label>
              <Input
                fluid={true}
                name="volume"
                onChange={props.onChangeModData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.modSolventSelectionConfig?.volume ?? ""}>
              </Input>
            </FormField>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderRight: "none", paddingTop: "1rem", paddingBottom: "1rem" , borderLeft: "none"}}
              width={2}>
              <label>
                Unit (of volume)
              </label>
              <Dropdown
                className="inline-label"
                fluid={true}
                name="unit"
                onChange={props.onChangeModData}
                options={props.unitOptions}
                search={true}
                selection={true}
                value={props.modSolventSelectionConfig?.unit || ""}>
              </Dropdown>
            </FormField>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderRight: "none", paddingTop: "1rem", paddingBottom: "1rem" , borderLeft: "none"}}
              width={2}>
              <label
                style={{minWidth: "max-content"}}>
                Total Qty (per dispense)
              </label>
              <Input
                fluid={true}
                name="quantity"
                onChange={props.onChangeModData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.modSolventSelectionConfig?.quantity ?? ""}>
              </Input>
            </FormField>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderLeft: "none", paddingTop: "1rem", paddingBottom: "1rem", position: "relative", flex:1, marginRight: "0.75rem" }}>
              <div
                style={{position: "absolute", top: "0", right: "0.25rem", display: typeof props.modSolventSelectionConfig?.admixtureIndex === "number"?"none":""}}>

                <Icon
                  className="red small"
                  link={true}
                  name="close"
                  onClick={props.onCloseButtonSolvent}>
                </Icon>
              </div>
            </FormField>
            <FormField
              width="1">
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginTop: "1.5rem"}}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField>
              <div
                style={{display:"flex", alignItems: "center"}}>
                <div
                  style={{marginRight:"10px"}}>
                  ความต่อเนื่อง
                </div>
                <Button
                  color="yellow"
                  icon="edit"
                  onClick={props.onOpenModPlanning}>
                </Button>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.planningData?.date_time_summary}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{marginTop:"2rem"}}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display:"flex"}}>
                <Button
                  color="grey"
                  onClick={props.onClearDrugOrderForm}
                  size="small">
                  CLEAR
                </Button>
                <Button
                  name="NOTE"
                  onClick={props.onNoteAndVerifyAndCancelDrugContinuePlan}
                  size="small"
                  style={{ display: props.DrugSelectSequence?.allowed_actions?.includes("NOTE") ? "" : "none" }}>
                  NOTE
                </Button>
                <div>
                  {props.buttonEdit}
                </div>
                <div>
                  {props.buttonEditQuantity}
                </div>
                <Button
                  color="red"
                  name="CANCEL"
                  onClick={props.onNoteAndVerifyAndCancelDrugContinuePlan}
                  size="small"
                  style={{ display: props.buttonActions?.cancel?.show ? "" : "none" }}>
                  CANCEL
                </Button>
                <div>
                  {props.buttonRequest}
                </div>
                <Button
                  color="red"
                  name="REJECT"
                  onClick={props.onNoteAndVerifyAndCancelDrugContinuePlan}
                  size="small"
                  style={{ display: props.buttonActions?.reject?.show ? "" : "none" }}>
                  REJECT
                </Button>
                <Button
                  color="green"
                  name="VERIFY"
                  onClick={props.onNoteAndVerifyAndCancelDrugContinuePlan}
                  size="small"
                  style={{ display: props.buttonActions?.verify?.show ? "" : "none" }}>
                  VERIFY
                </Button>
                <Button
                  color="orange"
                  onClick={props.onOpenModSpecialDirection}
                  size="small"
                  style={{ display: props.modSpecialDirectionConfig?.showButton ? "" : "none" }}>
                  รายละเอียดวิธีการใช้ยา
                </Button>
                <Button
                  color="yellow"
                  onClick={props.onOpenModSolventSelection}
                  size="small"
                  style={{ display: "none" }}>
                  เลือกตัวทำละลาย
                </Button>
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display: props.openCalCulator ? "inline" : "none", position:"absolute", width:"70%", height:"180px", right:"16px", top:"10px", backgroundColor:"red"}}>
          {props.pediatricUI}
        </div>
      </div>
    )
}


export default CardDrugContinuePlanUX

export const screenPropsDefault = {"showSearchResult":true}

/* Date Time : Wed Aug 28 2024 12:53:52 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "props.noDataComponent"
        },
        "data": {
          "type": "code",
          "value": "props.drugContinuePlanItems"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getDrugListTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugListProps"
        },
        "headers": {
          "type": "value",
          "value": "Drug, Dose, Unit, Route, Site, Frequency,PRN, Qty, Status, Action"
        },
        "keys": {
          "type": "value",
          "value": "title_status, dose, unitText, routeText, siteText, frequencyText,prn, qty, status,action"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35vh\", padding: \"0 5px\", display: \"none\"}"
        },
        "widths": {
          "type": "value",
          "value": ",80,80,,130,130, 80,80,,80"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ยา"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 145,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 151,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Input",
      "parent": 132,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPreviews"
        },
        "name": {
          "type": "value",
          "value": "dose"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.dose || \"\""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPreviews"
        },
        "name": {
          "type": "value",
          "value": "unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugUnits"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.unit || \"\""
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Dropdown",
      "parent": 17,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPreviews"
        },
        "name": {
          "type": "value",
          "value": "frequency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugFrequencies"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.frequency || \"\""
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Dropdown",
      "parent": 19,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPreviews"
        },
        "name": {
          "type": "value",
          "value": "site"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugSites"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.site || \"\""
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPreviews"
        },
        "name": {
          "type": "value",
          "value": "route"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugRoutes"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.route || \"\""
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 23,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPreviews"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugMethods"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.method || \"\""
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "ความต่อเนื่อง"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "code",
          "value": "props.planningData?.date_time_summary"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 164,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Button",
      "parent": 81,
      "props": {
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "icon": {
          "type": "value",
          "value": "edit"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModPlanning"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "CANCEL"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNoteAndVerifyAndCancelDrugContinuePlan"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.buttonActions?.cancel?.show ? \"\" : \"none\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "REJECT"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "REJECT"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNoteAndVerifyAndCancelDrugContinuePlan"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.buttonActions?.reject?.show ? \"\" : \"none\" }"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "VERIFY"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "VERIFY"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNoteAndVerifyAndCancelDrugContinuePlan"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.buttonActions?.verify?.show ? \"\" : \"none\" }"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "CLEAR"
        },
        "color": {
          "type": "value",
          "value": "grey"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearDrugOrderForm"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "NOTE"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "NOTE"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNoteAndVerifyAndCancelDrugContinuePlan"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.allowed_actions?.includes(\"NOTE\") ? \"\" : \"none\" }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "label": "divgrid",
      "name": "div",
      "parent": 98,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(38, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50px\" , padding: \"0 5px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/8\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Button",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "เฉพาะรายการที่ยังไม่ปิด"
        },
        "className": {
          "type": "code",
          "value": "props.drugContinueFilter?.status === \"only_open\" ? \"fluid\" : \"basic fluid\""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "only_open"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetdrugContinueFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 97,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"9/15\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "เฉพาะรายการใหม่"
        },
        "className": {
          "type": "code",
          "value": "props.drugContinueFilter?.status === \"only_new\" ? \"fluid\" : \"basic fluid\""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "only_new"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetdrugContinueFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 97,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"16/22\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 97,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"23/27\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Button",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "เฉพาะรายการปัจจุบัน"
        },
        "className": {
          "type": "code",
          "value": "props.drugContinueFilter?.status === \"only_active\" ? \"fluid\" : \"basic fluid\""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "only_active"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetdrugContinueFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Button",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "แสดงทั้งหมด"
        },
        "className": {
          "type": "code",
          "value": "props.drugContinueFilter?.status === \"all\" ? \"fluid\" : \"basic fluid\""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "all"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetdrugContinueFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดวิธีการใช้ยา"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModSpecialDirection"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.modSpecialDirectionConfig?.showButton ? \"\" : \"none\" }"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกตัวทำละลาย"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModSolventSelection"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"75px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_frequency ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Method"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"55px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_method ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "Route"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"40px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_route ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "Site"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"40px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_site ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"55px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_unit ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEditQuantity"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRequest"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "label",
      "parent": 132,
      "props": {
        "children": {
          "type": "value",
          "value": "Dose"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"40px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "label",
      "parent": 132,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_unit ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Button",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": "DRUG FACT"
        },
        "color": {
          "type": "code",
          "value": "props.hasFactsheetData ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "!props.hasFactsheetData"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDrugFact"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Button",
      "parent": 166,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "clipboard list"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDrugDescription"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0.5rem 0.59rem 0.6rem\", fontSize: \"1.45rem\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"15px\", ...(props.hidePanel && {display: \"none\"})}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormGroup",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormGroup",
      "parent": 140,
      "props": {
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormGroup",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 144,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormGroup",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginTop:\"2rem\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormField",
      "parent": 149,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 149,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 144,
      "props": {
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormField",
      "parent": 144,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", display: \"flex\",  justifyContent: \"center\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 135,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"40px\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricUI"
        },
        "style": {
          "type": "code",
          "value": "{display: props.openCalCulator ? \"inline\" : \"none\", position:\"absolute\", width:\"70%\", height:\"180px\", right:\"16px\", top:\"10px\", backgroundColor:\"red\"}"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-0.15rem\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "FormGroup",
      "parent": 140,
      "props": {
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "FormGroup",
      "parent": 140,
      "props": {
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormField",
      "parent": 175,
      "props": {
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 175,
      "props": {
        "width": {
          "type": "value",
          "value": "7"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 176,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormField",
      "parent": 176,
      "props": {
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormField",
      "parent": 176,
      "props": {
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "value",
          "value": "PRN"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการใช้ยาเพิ่มเติม"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 179,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "est."
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 191,
      "props": {
        "children": {
          "type": "value",
          "value": "qty."
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "day"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Input",
      "parent": 185,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "description"
        },
        "onChange": {
          "type": "code",
          "value": "props.onUnPreviewDrugContinuePlan"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.description || \"\""
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Input",
      "parent": 191,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPreviews"
        },
        "name": {
          "type": "value",
          "value": "sending_quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onUnPreviewDrugContinuePlan"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.sending_quantity || \"\""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Input",
      "parent": 194,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPreviews"
        },
        "name": {
          "type": "value",
          "value": "sending_interval"
        },
        "onChange": {
          "type": "code",
          "value": "props.onUnPreviewDrugContinuePlan"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.sending_interval || \"\""
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "label",
      "parent": 188,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugSelectSequence?.calculated_daily_quantity"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "Button",
      "parent": 155,
      "props": {
        "basic": {
          "type": "code",
          "value": "!props.modSolventSelectionConfig?.activeButtonSolvent"
        },
        "children": {
          "type": "value",
          "value": "SOLVENT"
        },
        "color": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.showButtonSolvent ?\"blue\": \"grey\""
        },
        "disabled": {
          "type": "code",
          "value": "!props.modSolventSelectionConfig?.showButtonSolvent "
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModSolventSelection"
        },
        "style": {
          "type": "code",
          "value": "{cursor: props.modSolventSelectionConfig?.activeButtonSolvent || typeof props.modSolventSelectionConfig?.admixtureIndex === \"number\"?\"auto\":\"pointer\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "FormGroup",
      "parent": 140,
      "props": {
        "className": {
          "type": "value",
          "value": "--readOnly --grey"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isRequireRate? \"\": \"none\",marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormField",
      "parent": 204,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "FormField",
      "parent": 204,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 204,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "FormField",
      "parent": 204,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "div",
      "parent": 205,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dripRateLabel"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-1px\"}"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": null,
      "id": 210,
      "name": "div",
      "parent": 207,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dripDurationLabel"
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-1px\"}"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "Input",
      "parent": 205,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate !== \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_iv_rate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.mixture_iv_rate"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "Input",
      "parent": 207,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate === \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_drip_in"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.drug_drip_in"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "label",
      "parent": 206,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "label",
      "parent": 208,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "Dropdown",
      "parent": 206,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate !== \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_iv_rate_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.ivRateOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.mixture_iv_rate_unit"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Dropdown",
      "parent": 208,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate === \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_drip_in_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripInOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.drug_drip_in_unit"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "FormGroup",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.hideSolvent? \"none\": \"\",marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormField",
      "parent": 217,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderRight: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\" }"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 217,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderRight: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\" , borderLeft: \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 220,
      "name": "FormField",
      "parent": 217,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderRight: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\" , borderLeft: \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "FormField",
      "parent": 217,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderRight: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\" , borderLeft: \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "FormField",
      "parent": 217,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderLeft: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\", position: \"relative\", flex:1, marginRight: \"0.75rem\" }"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "label",
      "parent": 218,
      "props": {
        "children": {
          "type": "value",
          "value": "Solvent"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "div",
      "parent": 218,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBoxSolvent"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "label",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": "Volume (For mixing)"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "Input",
      "parent": 219,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "volume"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.volume ?? \"\""
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "label",
      "parent": 220,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit (of volume)"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "Dropdown",
      "parent": 220,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.unitOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.unit || \"\""
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 229,
      "name": "label",
      "parent": 221,
      "props": {
        "children": {
          "type": "value",
          "value": "Total Qty (per dispense)"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Input",
      "parent": 221,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.quantity ?? \"\""
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": null,
      "id": 231,
      "name": "div",
      "parent": 222,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", top: \"0\", right: \"0.25rem\", display: typeof props.modSolventSelectionConfig?.admixtureIndex === \"number\"?\"none\":\"\"}"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "Icon",
      "parent": 231,
      "props": {
        "className": {
          "type": "value",
          "value": "red small"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCloseButtonSolvent"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormField",
      "parent": 217,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tableElement"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormField",
      "parent": 141,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 236,
      "name": "label",
      "parent": 235,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": null,
      "id": 237,
      "name": "div",
      "parent": 235,
      "props": {
        "children": {
          "type": "code",
          "value": "props.calculatorIcon"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "Dropdown",
      "parent": 182,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "!!props.DrugSelectSequence?.code"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "prn"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAdditionPrn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onUnPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.prnOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.prn || \"\""
        }
      },
      "seq": 238,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugContinuePlanUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "showSearchResult": true
  },
  "width": 80
}

*********************************************************************************** */
