import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button,  Form, Grid, Header, Input } from "semantic-ui-react";
import CardRangeOfMotion from "./CardRangeOfMotion";
import CardSensory from "./CardSensory";

const styles = {
  noRightPadding: {
    paddingRight: "0px",
  },
  noLeftPadding: {
    paddingLeft: "0px",
  },
  gridBG: {
    background: "#F3F3F3",
    marginLeft: "1px",
    marginRight: "1px",
    paddingTop: "15px",
    paddingLeft: "3px",
  },
  noPadding: {
    padding: "0px",
  },
  leftPadding: {
    paddingLeft: "5px",
  },
};

export interface CardPTPhysicalExamCardiopulmonaryMIDViewDataType {
  onEvent: (any) => any;
  saveButtonDisabled: boolean;
  dropDownOptions: {
    sensoryOptions: any[];
  };
  viewData: {
    rangeOfMotion: {
      isFull: boolean;
      isLimit: boolean;
      items: [{ limit: string }];
    };
    sensory: {
      choice: number;
      remark: string;
    };
  };
  readonly: boolean;
}

const CardPTPhysicalExamCardiopulmonaryMID: FC<CardPTPhysicalExamCardiopulmonaryMIDViewDataType> = forwardRef(
  (props, ref) => {
    // Range Of Motion
    const subRangeOfMotionRef = useRef();

    // Sensory
    const subSensoryRef = useRef();

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          rangeOfMotion: subRangeOfMotionRef.current.getData(),
          sensory: subSensoryRef.current.getData(),
        };
      },
    }));

    // Save
    const handleSave = () => {
      if (!props.readonly) {
        const params = {
          name: "SaveRequest",
          view: "CardPTPhysicalExamCardiopulmonaryMID",
        };
        props.onEvent({ message: "clickButton", params: params });
      }
    };

    return (
      <>
        {/* Range of motion */}
        <Grid style={styles.gridBG}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={1}></Form.Field>
                  <Form.Field width={15}>
                    <Header.Content>
                      <strong>Range of motion</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.leftPadding}>
              <CardRangeOfMotion
                ref={subRangeOfMotionRef}
                data={props.viewData.rangeOfMotion}
                readonly={props.readonly}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Sensory */}
        <Grid style={styles.gridBG}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field width={14}>
                    <Header.Content>
                      <strong>Sensory</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.leftPadding}>
              <CardSensory
                ref={subSensoryRef}
                data={props.viewData.sensory}
                dropDownOptions={props.dropDownOptions}
                readonly={props.readonly}
              />
            </Grid.Column>
          </Grid.Row>

          {/* Save */}
          { !props.readonly ? <Grid.Row columns={2}>
            <Grid.Column width={15}></Grid.Column>
            <Grid.Column width={1}>
              <Button
                fluid
                disabled={props.saveButtonDisabled}
                color="green"
                type="button"
                size="small"
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid.Column>
          </Grid.Row> : null}
        </Grid>
      </>
    );
  }
);

export const CardPTPhysicalExamCardiopulmonaryMIDInitialViewData: CardPTPhysicalExamCardiopulmonaryMIDViewDataType = {
  onEvent: ({}) => {},
  saveButtonDisabled: false,
  dropDownOptions: { sensoryOptions: [] },
  viewData: {
    rangeOfMotion: {
      isFull: true,
      isLimit: false,
      items: [
        {
          limit: "",
        },
      ],
    },
    sensory: {
      choice: null,
      remark: "",
    },
  },
};

CardPTPhysicalExamCardiopulmonaryMID.defaultProps = CardPTPhysicalExamCardiopulmonaryMIDInitialViewData;

export default CardPTPhysicalExamCardiopulmonaryMID;
