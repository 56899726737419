import React, { useState, useEffect, useMemo } from "react";
import { dateToStringWithoutTimeBE } from "../../../../react-lib/utils/dateUtils";
import CardQueueDrugUX from "./CardQueueDrugUX";
import moment from "moment";

const hospital = "โรงพยาบาล มอร์เฮลท์ เเคร์";
const IMAGE = {
  logo: "images/IHimage.png",
};

const dateDefault = dateToStringWithoutTimeBE(new Date());

const CardQueueDrug = (props: any) => {
  const [time, setTime] = useState<any>();

  useEffect(() => {
    props.onEvent({
      message: "QueueForPatient",
      params: { action: "queueDrug" },
    });
  }, []);

  useEffect(() => {
    let list = setInterval(
      () =>
        props.onEvent({
          message: "QueueForPatient",
          params: { action: "queueDrug" },
        }),
      1000 * 30
    );
    return () => {
      clearInterval(list);
    };
  }, []);
  useEffect(() => {
    let timeId = setInterval(
      () => setTime(moment(new Date())?.format("HH:mm:ss")),
      1000
    );
    return () => {
      clearInterval(timeId);
    };
  }, []);

  const list = useMemo(() => {
    const items: any = [];
    for (let index = 0; index < 25; index++) {
      items.push(props?.queueDrug?.drugList?.items?.[index]);
    }

    return items;
  }, [props?.queueDrug?.drugList?.items]);

  return (
    <>
      <CardQueueDrugUX
        hospital={hospital}
        division={
          props.masterOptions?.division?.find(
            (options: any) => options.value === props.queueDrug?.division
          )?.text
        }
        date={dateDefault}
        time={time}
        logo={IMAGE.logo}
        number={props?.queueDrug?.drugList?.count_items || "0"}
        openFullScreen={props.openFullScreen}
        onClose={() => {
          props.setOpenFullScreen(false);
        }}
        list={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(list || [])?.map((item: any, index: any) => {
              return <ListData item={item} index={index} />;
            })}
          </div>
        }
      />
    </>
  );
};

const ListData = (props: any) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        margin: "15px 10px 10px 12px",
        padding: "10px",
        width: "18%",
        height: "50px",
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: "22px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {props?.item}
      </div>
    </div>
  );
};

export default CardQueueDrug;
