import React, { useMemo, useState, useEffect } from "react";
import { Icon, Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import ModMakeAppointmentPackageUX from "./ModMakeAppointmentPackageUX";

// Common
import ModSearchDoctorNote from "react-lib/apps/APP/ModSearchDoctorNote";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import { CARD_SELECT_DATE_TIME_PACKAGE } from "./CardSelectDateTimePackage";
import {
  PackageBlockDetailType,
  PackageItemSerializer,
  RunSequence,
  SEQ_ACTIONS,
} from "./sequence/PackageDateTime";

// Types
type ModMakeAppointmentPackageProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // controller
  proxyController?: any;
  // seq
  runSequence: RunSequence;

  // data
  data: Record<string, any>;
  selectedPatient?: Record<string, any> | null;
  selectedPackageItems?: PackageItemSerializer[];
  // CommonInterface
  selectedDivision?: any;
  buttonLoadCheck?: Record<string, any>;
  // callback
  onCancel: () => any;
};

// Const
const MOD_MAKE_APPOINTMENT_PACKAGE = "ModMakeAppointmentPackage";

const ACTION_MAKE_APP = `${MOD_MAKE_APPOINTMENT_PACKAGE}_${SEQ_ACTIONS.MAKE_APPOINTMENT}`;

const ModMakeAppointmentPackage = (props: ModMakeAppointmentPackageProps) => {
  // data
  const [data, setData] = useState<Partial<PackageBlockDetailType>>({});
  // Mod
  const [openModDoctorNote, setOpenModDoctorNote] = useState<boolean>(false);

  // Use Effect
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  // Use Memo
  const slotDate = useMemo(() => {
    const date = props.data?.start || "";
    const momentDate = moment(date).clone();

    return date
      ? momentDate.locale("th").format(`วัน dddd ที่ D MMMM YYYY`)
      : "";
  }, [props.data]);

  // Handler
  const handleChangeValue = (e: any, v: any) => {
    setData({ ...data, [v.name]: v.value });
  };

  const handleMakeAppointment = () => {
    props.runSequence({
      sequence: "PackageDateTime",
      action: SEQ_ACTIONS.MAKE_APPOINTMENT,
      data,
      card: MOD_MAKE_APPOINTMENT_PACKAGE,
      errorKey: CARD_SELECT_DATE_TIME_PACKAGE,
      onSuccess: props.onCancel,
    });
  };

  const handleOnSelectNote = (noteList: string[]) => {
    const orderNote = data.doctor_note || "";

    noteList = noteList.map((note) => `- ${note}`);

    const note: (string | number)[] = orderNote
      ? [orderNote, ...noteList]
      : noteList;

    setData({ ...data, doctor_note: note.join("\n") });
  };

  const handleAddNote = () => {
    setOpenModDoctorNote(true);
  };

  return (
    <div>
      <ModMakeAppointmentPackageUX
        // data
        patientInfo={props.selectedPatient}
        slotDate={slotDate}
        time={data.time}
        divisionName={data.division_name}
        remark={data.doctor_note}
        // callback
        onCancel={props.onCancel}
        onMakeAppointment={handleMakeAppointment}
        onAddNote={handleAddNote}
        onChangeValue={handleChangeValue}
        // Element
        packageNameList={
          <>
            {props.selectedPackageItems?.map((item) => (
              <div key={"item" + item.id}>
                [{item.product_code}] {item.product_name}
              </div>
            ))}
          </>
        }
        ButtonMakeAppointment={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleMakeAppointment}
            // data
            paramKey={ACTION_MAKE_APP}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_MAKE_APP]}
            // config
            color={"green"}
            name={SEQ_ACTIONS.MAKE_APPOINTMENT}
            size="medium"
            title={
              <>
                <span style={{ marginRight: "10px" }}>ทำนัดหมาย</span>
                <Icon name="calendar alternate outline" />
              </>
            }
            style={{
              display: "flex",
              paddingRight: "0.75rem",
              paddingLeft: "1rem",
              minWidth: "126px",
            }}
            iconStyle={{ margin: "auto" }}
          />
        }
      />

      <Modal
        open={openModDoctorNote}
        onClose={() => setOpenModDoctorNote(false)}
      >
        <ModSearchDoctorNote
          controller={props.proxyController}
          division={props.selectedDivision?.id}
          onClose={() => setOpenModDoctorNote(false)}
          onSelectNote={handleOnSelectNote}
        />
      </Modal>
    </div>
  );
};

ModMakeAppointmentPackage.displayName = "ModMakeAppointmentPackage";

export default React.memo(ModMakeAppointmentPackage);
