import React, { MutableRefObject, useRef, useState, useEffect, useImperativeHandle } from "react";
import PatientSearchBox from "react-lib/apps/common/PatientSearchBox";

const CardPatientSearchBox = React.forwardRef<any, any>((props, ref) => {
  const [nationalities, setNationalities] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientList, setPatientList] = useState<any>([]);
  const [currentHNPage, setCurrentHNPage] = useState(1);
  const [numberOfHNPage, setNumberOfHNPage] = useState(1);
  const [patientOldNameLoading, setPatientOldNameLoading] = useState<boolean>(false);
  const [patientOldNameDataList, setPatientOldNameDataList] = useState<any[]>([]);

  const patientRef = useRef() as MutableRefObject<any>;
  const isMounted = useRef<boolean>(false);

  useImperativeHandle(ref, () => ({
    current: patientRef.current,
  }));

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (patientRef.current && props.defaultValue) {
      patientRef.current.onEnter(props.defaultValue);
    }
  }, []);

  const getNationality = async () => {
    const [data, error] = await props.controller.getNationalities();
    if (isMounted.current) {
      if (data) {
        setNationalities(data);
      } else {
        setNationalities([]);
      }
    }
  };

  const getPatientList = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    let limit = 40;
    let page = patientCurrentPage ? patientCurrentPage : currentHNPage;
    setIsLoading(true);
    setCurrentHNPage(page);
    const [data, error] = await props.controller.getSearchAllPatient({
      hn,
      fullName,
      citizenId,
      nationality,
      is_old_name_search: true,
      limit,
      offset: (page - 1) * limit,
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setPatientList(data.items);
        setNumberOfHNPage(parseInt(data.total) / limit + 1);
      } else {
        setPatientList([]);
        setNumberOfHNPage(1);
      }
    }
    return [data, error];
  };

  const handleGetPatientByHN = async (hn: string) => {
    const [data, error, network] = await props.controller.getPatientByHN({
      hn,
    });
    return [data, error, network];
  };

  const handlePaginationChange = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    setCurrentHNPage(patientCurrentPage);
    getPatientList({
      hn,
      fullName,
      citizenId,
      nationality,
      patientCurrentPage,
    });
  };

  const handleGetPatientOldName = async (patientId: number) => {
    setPatientOldNameLoading(true);

    const [data] = await props.controller.getPatientOldName({
      patientId,
    });

    setPatientOldNameDataList(data);
    setPatientOldNameLoading(false);
  };

  const handleOnInputChange = (hnInput: string) => {
    props.onInputChange?.(hnInput);
  };

  return (
    <PatientSearchBox
      // ref
      ref={patientRef}
      // data
      modalStyle={{ width: "auto" }}
      inputStyle={props.inputStyle}
      fluid={props.fluid}
      patientList={patientList}
      defaultValue={props.defaultValue}
      nationalitiesList={nationalities}
      patientListLoading={isLoading}
      patientOldNameLoading={patientOldNameLoading}
      patientOldNameDataList={patientOldNameDataList}
      numberOfPage={numberOfHNPage}
      currentPage={currentHNPage}
      disabled={props.disabled}
      // callback
      getNationality={getNationality}
      getPatientList={getPatientList}
      onEnter={props.onEnterPatientSearch}
      onSelectPatient={props.onEnterPatientSearch}
      onGetPatientByHN={handleGetPatientByHN}
      onGetPatientOldName={handleGetPatientOldName}
      onPatientListPaginationChange={handlePaginationChange}
      onInputChange={handleOnInputChange}
      clearHNInput={props.clearHNInput}
    />
  );
});

export default React.memo(CardPatientSearchBox);
