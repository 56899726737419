import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardORQueueUX = (props: any) => {
    return(
      <div
        id="CardORQueue"
        style={{ padding: "15px" }}>
        <div
          className="ui form"
          style={{display: props.showFilterORQueue  || props.showFilterWardQueue ? "": "none"}}>
          
          <FormGroup>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                HN
              </label>
              <div>
                {props.patientSearchBox}
              </div>
            </FormField>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                ชื่อ - นามสกุล
              </label>
              <Input
                fluid={true}
                readOnly={true}
                value={props.ORQueueSequence?.orFilter?.patientFullName || ""}>
              </Input>
            </FormField>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                แพทย์ผ่าตัด
              </label>
              <div>
                {props.doctorSearchBox}
              </div>
            </FormField>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                Type
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                name="type"
                onChange={props.onChangeTypeStatus}
                options={props.masterOptions?.operatingType || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORQueueSequence?.orFilter?.type || null}>
              </Dropdown>
            </FormField>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                Status
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                name="status"
                onChange={props.onChangeTypeStatus}
                options={props.masterOptions?.operatingStatus || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORQueueSequence?.orFilter?.status || null}>
              </Dropdown>
            </FormField>
            <FormField>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonPrint}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                วันที่ผ่าตัด
              </label>
              <div
                style={{width: "100%"}}>
                {props.startORDate}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <div
                style={{marginTop: "0.95rem", minWidth: "max-content"}}>
                ถึง
              </div>
            </FormField>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                {"\u00a0"}
              </label>
              <div
                style={{width: "100%"}}>
                {props.endORDate}
              </div>
            </FormField>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                วันจองห้องผ่าตัด
              </label>
              <div
                style={{width: "100%"}}>
                {props.creatORDate}
              </div>
            </FormField>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                Location
              </label>
              <Dropdown
                clearable={true}
                disabled={props.ORQueueSequence?.orFilter?.no_date_no_room}
                fluid={true}
                name="location"
                onChange={props.onChangeLocationRoom}
                options={props.ORQueueSequence?.locationOptions || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORQueueSequence?.orFilter?.operating_detail__operating_room__location_id || null}>
              </Dropdown>
            </FormField>
            <FormField
              width={3}>
              <label
                style={{fontWeight: "normal"}}>
                Room
              </label>
              <Dropdown
                clearable={true}
                disabled={props.ORQueueSequence?.orFilter?.no_date_no_room}
                fluid={true}
                name="room"
                onChange={props.onChangeLocationRoom}
                options={props.ORQueueSequence?.roomOptions || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORQueueSequence?.orFilter?.operating_detail__operating_room__id || null}>
              </Dropdown>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <Checkbox
                checked={props.ORQueueSequence?.orFilter?.no_date_no_room}
                label="OR Request ไม่ระบุนัดหมาย"
                name="no_date_no_room"
                onChange={props.onChangeNoDateRoom}
                style={{marginTop: "0.65rem", minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form small"
          style={{display: props.showFilterDrugQueue ? "": "none"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div>
                วันที่ผ่าตัด
              </div>
            </FormField>
            <FormField
              inline={true}
              width="2">
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onDateChange("start_date")}
                  value={props.startDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%", textAlign: "center" }}>
                ถึง
              </div>
            </FormField>
            <FormField
              inline={true}
              width="2">
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onDateChange("end_date")}
                  value={props.endDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="6">
              <div
                style={{ marginLeft: "20px" }}>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          data={props.orOrderItems}
          getTrProps={props.getSelectedRow}
          headers={props.showFilterORQueue ? "วันที่ผ่าตัด, Location/Room, HN, ข้อมูลผู้ป่วย, แพทย์ผ่าตัด,Medical Term / Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia, Status," : "วันที่ผ่าตัด, Location/Room, HN, ข้อมูลผู้ป่วย, แพทย์ผ่าตัด,Medical Term / Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia, Status"}
          keys={props.showFilterORQueue ? "surgeryDatetime, locationRoom, hn, patientNameCheckinCode, primary_doctor_name, diagnosis, typeLabel, pre_operation_product_summary, special_equipments_text_lbl, blood_request_summary, anesthesia_method_name, statusNote, menu" : "surgeryDatetime, locationRoom, hn, patientNameCheckinCode, primary_doctor_name, diagnosis, typeLabel, pre_operation_product_summary, special_equipments_text_lbl, blood_request_summary, anesthesia_method_name, statusNote"}
          minRows="6"
          showPagination={false}
          style={{height: "calc(100dvh - 20.5rem)"}}
          widths={props.showFilterORQueue  ? "^90,^110,^80,^120,^100,^110,^110,^110,^100,^80,^80,^100,^80"  : "^100,^110,^80,^150,^110,^110,^110,^110,^120,^80,^80,^100"}>
        </Table>
      </div>
    )
}


export default CardORQueueUX

export const screenPropsDefault = {"current":null}

/* Date Time : Fri Oct 11 2024 10:00:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORQueue"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"15px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 51,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.orOrderItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getSelectedRow"
        },
        "headers": {
          "type": "code",
          "value": "props.showFilterORQueue ? \"วันที่ผ่าตัด, Location/Room, HN, ข้อมูลผู้ป่วย, แพทย์ผ่าตัด,Medical Term / Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia, Status,\" : \"วันที่ผ่าตัด, Location/Room, HN, ข้อมูลผู้ป่วย, แพทย์ผ่าตัด,Medical Term / Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia, Status\""
        },
        "keys": {
          "type": "code",
          "value": "props.showFilterORQueue ? \"surgeryDatetime, locationRoom, hn, patientNameCheckinCode, primary_doctor_name, diagnosis, typeLabel, pre_operation_product_summary, special_equipments_text_lbl, blood_request_summary, anesthesia_method_name, statusNote, menu\" : \"surgeryDatetime, locationRoom, hn, patientNameCheckinCode, primary_doctor_name, diagnosis, typeLabel, pre_operation_product_summary, special_equipments_text_lbl, blood_request_summary, anesthesia_method_name, statusNote\""
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100dvh - 20.5rem)\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.showFilterORQueue  ? \"^90,^110,^80,^120,^100,^110,^110,^110,^100,^80,^80,^100,^80\"  : \"^100,^110,^80,^150,^110,^110,^110,^110,^120,^80,^80,^100\""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "label": "divgrid",
      "name": "div",
      "parent": 87,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 84,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/5\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showFilterDrugQueue ? \"\": \"none\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"center\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 146,
      "name": "DateTextBox",
      "parent": 144,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"start_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 147,
      "name": "DateTextBox",
      "parent": 145,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"end_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"20px\" }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showFilterORQueue  || props.showFilterWardQueue ? \"\": \"none\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormGroup",
      "parent": 149,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormGroup",
      "parent": 149,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Input",
      "parent": 156,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.patientFullName || \"\""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Dropdown",
      "parent": 160,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTypeStatus"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.operatingType || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.type || null"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Dropdown",
      "parent": 162,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTypeStatus"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.operatingStatus || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.status || null"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 177,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startORDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.95rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 179,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endORDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "code",
          "value": "props.creatORDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "Dropdown",
      "parent": 183,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.no_date_no_room"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "location"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocationRoom"
        },
        "options": {
          "type": "code",
          "value": "props.ORQueueSequence?.locationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.operating_detail__operating_room__location_id || null"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Dropdown",
      "parent": 185,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.no_date_no_room"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "room"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocationRoom"
        },
        "options": {
          "type": "code",
          "value": "props.ORQueueSequence?.roomOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.operating_detail__operating_room__id || null"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Checkbox",
      "parent": 186,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.no_date_no_room"
        },
        "label": {
          "type": "value",
          "value": "OR Request ไม่ระบุนัดหมาย"
        },
        "name": {
          "type": "value",
          "value": "no_date_no_room"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNoDateRoom"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.65rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "label",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "label",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ - นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "label",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "Type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "label",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": "Status"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "label",
      "parent": 179,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "label",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": "วันจองห้องผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "label",
      "parent": 183,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "label",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "Room"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 210,
      "name": "label",
      "parent": 164,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "label",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 189,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardORQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "current": null
  },
  "width": 80
}

*********************************************************************************** */
