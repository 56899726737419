import React, {
  useEffect,
  useState,
  useMemo,
  MutableRefObject,
  useCallback,
} from "react";
import { Header, Icon, Modal } from "semantic-ui-react";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// Common
import { SearchBox, ErrorMessage, ModInfo } from "react-lib/apps/common";
import EditorColumn from "react-lib/appcon/common/EditorColumn";

// UX
import CardDrugTransferRequestUX from "./CardDrugTransferRequestUX";
import { formatDatetime } from "react-lib/utils/dateUtils";

// Types
type CardDrugTransferRequestProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  DrugTransferRequestSequence?: Record<string, any> | null;
  // controller
  controller: any;
  // data
  drugTransferRequestlist?: any[];
  drugTransferLog?: { items?: any[] } | null;
  drugRequestList?: { items?: any[] } | null;
  drugTransferRequestDetail?: Record<string, any>;
  // options
  masterOptions?: Record<string, any[]>;
  // CommonInterface
  selectedDivision?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  // config
  showStatus?: boolean;
  notAllowedAdd?: boolean;
  closeIcon?: boolean;
  // callback
  onClose?: () => any;
};

type ModInfoType = {
  open: boolean;
  error?: any;
  title?: string;
  color?: string;
};

const modInfoInitial: ModInfoType = {
  open: false,
  error: null,
  title: "",
  color: undefined,
};

export const CARD_DRUG_TRANSFERREQUEST: string = "CardDrugTransferRequest";

export const CARD_DRUG_TRANSFER_REQUEST: string = "CardDrugTransferRequest";

const CardDrugTransferRequest = (props: CardDrugTransferRequestProps) => {
  const drugRef = React.useRef() as MutableRefObject<any>;
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial);
  const [selectedRow, setSelectedRow] = useState(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [openModal, setOpenModel] = useState(false);

  // Effect Memo
  const divisionPharmaOptions = useMemo(() => {
    return (props.masterOptions?.divisionPharma || []).filter(
      (option: any) => option.value !== props.selectedDivision?.id
    );
  }, [props.masterOptions?.divisionPharma, props.selectedDivision]);

  useEffect(() => {
    props.runSequence({ sequence: "DrugTransferRequest", restart: true });

    return () => {
      props.runSequence({ sequence: "DrugTransferRequest", clear: true });
    };
  }, []);

  // Set null selectedDivisionPharma หาก value ไม่มีอยู่ใน options
  // useEffect(() => {
  //   const division = props.DrugTransferRequestSequence?.selectedDivisionPharma;

  //   if (division) {
  //     const findDivision = divisionPharmaOptions.find(
  //       (option) => option.value === division
  //     );

  //     if (!findDivision) {
  //       props.setProp(
  //         "DrugTransferRequestSequence.selectedDivisionPharma",
  //         null
  //       );
  //     }
  //   }
  // }, [
  //   divisionPharmaOptions,
  //   props.DrugTransferRequestSequence?.selectedDivisionPharma,
  // ]);

  const handleChangeEdit = useCallback((data: any) => {
    props.runSequence({
      sequence: "DrugTransferRequest",
      action: "edit",
      data,
    });
  }, []);

  const handleRemove = useCallback((row: any) => {
    props.runSequence({
      sequence: "DrugTransferRequest",
      action: "remove",
      selectedRow: row,
    });
  }, []);

  const drugTransferItems = useMemo(() => {
    return (props.drugTransferRequestlist || []).map(
      (item: any, index: number) => ({
        ...item,
        no: <div style={{ textAlign: "center" }}>{index + 1}</div>,
        request_quantity: (
          <EditorColumn
            value={item.request_quantity}
            valueOnFocus={true}
            inputType="number"
            // style
            backgroundColor={"rgb(255, 255, 204)"}
            onDataChanged={(value: string) =>
              handleChangeEdit({ value, index, type: "request_quantity" })
            }
          />
        ),
        remove: (
          <div style={{ display: "grid", placeContent: "center" }}>
            <Icon
              name="trash alternate"
              color="red"
              style={{ cursor: "pointer" }}
              onClick={() => handleRemove({ ...item, index })}
            />
          </div>
        ),
      })
    );
  }, [props.drugTransferRequestlist]);

  const transferLogList = useMemo(() => {
    return (props.drugTransferLog?.items || []).map(
      (item: any, index: number) => ({
        ...item,
        datetime: <div>{moment(item.datetime).format("DD/MM/YY HH:mm")}</div>,
      })
    );
  }, [props.drugTransferLog?.items]);

  const datetime = useMemo(() => {
    const detail = props.drugTransferRequestDetail || {};

    return {
      request: detail.requested
        ? formatDatetime(detail.requested.datetime)
        : "",
      cancel: detail.canceled ? formatDatetime(detail.canceled.datetime) : "",
    };
  }, [props.drugTransferRequestDetail]);

  const handleChangeValue = (key: string) => async (e: any, v: any) => {
    await props.setProp(`DrugTransferRequestSequence.${key}`, v.value);

    if (key === "selectedDivisionPharma") {
      // await props.setProp("providerDivision", v.value);
      props.runSequence({
        sequence: "DrugTransferRequest",
        action: "update_stock",
        drug: props.drugTransferRequestlist?.[0]?.drug,
        division: v.value,
        index: 0,
      });
    }
  };

  const handleOnAdd = () => {
    let textAlert: string[] = [];
    // if (!props.DrugTransferRequestSequence?.selectedDivisionPharma) {
    //   textAlert.push("กรุณาระบุข้อมูลใน provider");
    // }
    if (!props.DrugTransferRequestSequence?.selectedDrugSearch) {
      textAlert.push("กรุณาระบุข้อมูลใน drug");
    }
    if (!props.DrugTransferRequestSequence?.selectedDrugDose) {
      textAlert.push("กรุณาระบุข้อมูลใน quantity เป็นตัวเลข");
    }

    setErrorMessage(textAlert);

    if (textAlert.length === 0) {
      props.runSequence({ sequence: "DrugTransferRequest", action: "add" });
      drugRef.current?.clear();
    }
  };

  //Selected Drug
  const handleGetDrugOptions = async ({ searchText }: any = {}) => {
    const [data, error] = await props.controller.getDrugSearch({
      keyword: searchText,
    });

    return [data, error];
  };

  const handleSelectedDrug = (id: number) => {
    props.setProp("DrugTransferRequestSequence.selectedDrugSearch", id);
  };

  const handleClose = () => setOpenModel(false);

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial);
  };

  const handleCloseMessage = () => {
    props.setProp(`errorMessage.${CARD_DRUG_TRANSFER_REQUEST}`, null);
    props.setProp(`successMessage.${CARD_DRUG_TRANSFER_REQUEST}`, null);
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      onClick: () => {
        setSelectedRow({ ...rowInfo?.original, index: rowInfo?.index });
      },
    };
  };

  const handleRequest = () => {
    props.runSequence({
      sequence: "DrugTransferRequest",
      action: "request",
      card: CARD_DRUG_TRANSFER_REQUEST,
    });
  };

  const handleEdit = () => {
    props.runSequence({
      sequence: "DrugTransferRequest",
      action: "update",
    });

    setOpenModInfo({
      open: true,
      title: "EDIT  สำเร็จ",
      color: "green",
    });
  };

  const handleCancel = () => {
    props.runSequence({
      sequence: "DrugTransferRequest",
      action: "cancel",
    });

    setOpenModInfo({
      open: true,
      title: "CANCEL  สำเร็จ",
      color: "green",
    });
  };

  const handleLog = () => {
    props.runSequence({
      sequence: "DrugTransferRequest",
      action: "log",
    });

    setOpenModel(true);
  };

  const handleNew = () => {
    props.runSequence({
      sequence: "DrugTransferRequest",
      action: "new",
    });
  };

  const handleClear = () => {
    const drugTransfer = props.DrugTransferRequestSequence;

    drugRef.current?.clear();

    props.setProp("DrugTransferRequestSequence", {
      ...drugTransfer,
      selectedDivisionPharma: "",
      selectedDrugSearch: "",
      selectedDrugDose: "",
    });

    props.setProp("providerDivision", null);
  };

  console.log("CardDrugTransferRequest", props);

  return (
    <>
      <div>
        <CardDrugTransferRequestUX
          //data
          drugTransferRequestlist={drugTransferItems}
          selectedDivisionPharma={
            props.DrugTransferRequestSequence?.selectedDivisionPharma || ""
          }
          selectedDrugSearch={
            props.DrugTransferRequestSequence?.selectedDrugSearch || ""
          }
          selectedDrugDose={
            props.DrugTransferRequestSequence?.selectedDrugDose || ""
          }
          // requesterName={props.drugRequestList.items?.[0]?.provider_name}
          requesterName={props.selectedDivision?.name || ""}
          // requestId={drugTransferItems[0]?.request}
          requested={props.drugTransferRequestDetail?.requested}
          canceled={props.drugTransferRequestDetail?.canceled}
          // actionCancel={logCancel[0]?.action}
          // datetimeCancel={logCancel[0]?.datetime.props.children}
          requestedDate={datetime.request}
          canceledDate={datetime.cancel}
          showStatus={props.showStatus}
          code={
            drugTransferItems[0]?.request
              ? props.drugTransferRequestDetail?.code
              : "[NEW]"
          }
          status={
            drugTransferItems[0]?.request
              ? props.drugTransferRequestDetail?.status_name
              : ""
          }
          //options
          divisionPharmaOptions={divisionPharmaOptions}
          // config
          notAllowedAdd={props.notAllowedAdd}
          //funtion
          drugRowProps={handleGetTrProps}
          onChangeValue={handleChangeValue}
          onAddDrug={handleOnAdd}
          onRequest={handleRequest}
          onEdit={handleEdit}
          onCancel={handleCancel}
          onLog={handleLog}
          onNew={handleNew}
          onClear={handleClear}
          closeIcon={props.closeIcon}
          onClose={props.onClose}
          // component
          drugSearch={
            <SearchBox
              ref={drugRef}
              onGetSearchOptions={handleGetDrugOptions}
              textField="full_name"
              onSelectOption={handleSelectedDrug}
            />
          }
          ErrorMessage={
            <ErrorMessage
              error={errorMessage}
              style={{
                marginBottom: "0.5rem",
                marginTop: props.notAllowedAdd ? "" : "0.5rem",
              }}
            />
          }
        />
      </div>

      <Modal
        open={openModal}
        size="small"
        style={{ padding: "0 10px" }}
        onClose={handleClose}
      >
        {/* @ts-ignore */}
        <Header style={{ padding: "0.85rem 0.5rem" }}> ประวัติการทำงาน</Header>
        <div>
          <Table
            data={transferLogList}
            headers="User,Acion,Date-Time"
            keys="name,action,datetime"
            minRows="10"
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
        </div>
      </Modal>

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color}
        titleName={openModInfo.title}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      ></ModInfo>

      <ModInfo
        open={!!props.errorMessage?.[CARD_DRUG_TRANSFER_REQUEST]}
        titleColor={"red"}
        onApprove={handleCloseMessage}
        onClose={handleCloseMessage}
      >
        <ErrorMessage
          error={props.errorMessage?.[CARD_DRUG_TRANSFER_REQUEST]}
        />
      </ModInfo>

      <ModInfo
        open={!!props.successMessage?.[CARD_DRUG_TRANSFER_REQUEST]}
        titleColor={"green"}
        titleName="REQUEST สำเร็จ"
        onApprove={handleCloseMessage}
        onClose={handleCloseMessage}
      ></ModInfo>
    </>
  );
};

export default CardDrugTransferRequest;
