import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Table, Checkbox, Input } from "semantic-ui-react";
import "./../../css/TableMotion.css"
import _ from "../../compat/lodashplus";
const styles = {
  header: {
    color: "#FFFFFF",
    backgroundColor: "#2F3E52",
    fontWeight: "bold",
    borderRight: "0.5px solid #FFFFFF",
  },
  inputTable: {
    width: "100%",
  },
  cellOdd: {
    fontWeight: "bold",
  },
  cellEven: {
    fontWeight: "bold",
    backgroundColor: "rgb(79,79,79,0.1)",
  },
  noBottomPadding: {
    paddingBottom: "5px",
  },
};

export interface TableRangeOfMotionViewDataType {
  tableDisable: boolean;
  viewData: {
    shoulder: shoulderDataType;
    elbow: elbowDataType;
    forearm: forearmDataType;
    wrist: wristDataType;
  };
}

interface cellDataType {
  isOnlyFew: boolean;
  isDecreased: boolean;
  isNormal: boolean;
  noted: string;
}

interface shoulderDataType {
  flexion: cellDataType;
  abduction: cellDataType;
  internalRotation: cellDataType;
  externalRotation: cellDataType;
}

interface elbowDataType {
  flexion: cellDataType;
  extension: cellDataType;
}

interface forearmDataType {
  pronation: cellDataType;
  supination: cellDataType;
}

interface wristDataType {
  flexion: cellDataType;
  extension: cellDataType;
}

const TableInitialData: TableRangeOfMotionViewDataType = {
  tableDisable: false,
  viewData: {
    shoulder: {
      flexion: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
      abduction: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
      internalRotation: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
      externalRotation: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
    },
    elbow: {
      flexion: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
      extension: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
    },
    forearm: {
      pronation: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
      supination: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
    },
    wrist: {
      flexion: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
      extension: {
        isOnlyFew: false,
        isDecreased: false,
        isNormal: false,
        noted: "",
      },
    },
  },
};

interface SubTableRangeOfMotionProps {
  cellStyle: {
    fontWeight: string;
    backgroundColor?: string;
  };
  title: string;
  inputStyle: {
    width: string;
  };
  mainName: string;
  name: string;
}

const TableRangeOfMotion: FC<TableRangeOfMotionViewDataType> = forwardRef(
  (props, ref) => {
    const [data, setData] = useState<any>(TableInitialData.viewData);
    const [tableDisable, setTableDisable] = useState(
      TableInitialData.tableDisable
    );

    useEffect(() => {
      if (props) {
        setData(props.viewData);
        setTableDisable(props.tableDisable);
      }
    }, [props]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        return data;
      },
    }));
    const SubTableRangOfMotion: FC<SubTableRangeOfMotionProps> = (props) => {
      let inputData = data[props.mainName][props.name]
      // <SubTableRangeOfMotion cellStyle={styles.cellOdd} rowSpan="4" textAlign="center" header="Shoulder" title="flexion (0° - 180°)" inputStyle={styles.inputTable} />
      return (
        <>
          <Table.Cell style={props.cellStyle}>{props.title}</Table.Cell>
          {Object.keys(inputData).map(item => {
            if(typeof(inputData[item]) === "boolean"){
              return <Table.Cell key={item} textAlign="center">
                <Checkbox
                    disabled={tableDisable}
                    checked={inputData[item]}
                    onChange={() => {handleOnCheckBoxChange(props.mainName, props.name, item, inputData[item])}}
                />
                </Table.Cell>
            }else{
              return <Table.Cell key={item}>
                <Input
                    style={props.inputStyle}
                    defaultValue={inputData[item]}
                    disabled={tableDisable}
                    onBlur={(event)=> {handleOnInputChange(props.mainName, props.name, item, event)}}
                />
                </Table.Cell>
            }
          })}

        </>
      )
    };

    const handleOnCheckBoxChange = (mainName, name, subName, checkValue) => {
      // console.log('handleOnCheckBoxChange mainName, name, subName, checkValue', mainName, name, subName, checkValue)
      let dictA = Object.assign({}, data);
      dictA[mainName][name][subName] = !checkValue;
      setData(dictA);
    };

    const handleOnInputChange = (mainName, name, subName, event) => {
      let dictA = Object.assign({}, data);
      dictA[mainName][name][subName] = event.target.value;
      setData(dictA);
    };
    
    return (
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={styles.header}
              textAlign="center"
              colSpan="2"
              width="4"
            >
              Motion(PROM)
            </Table.HeaderCell>
            <Table.HeaderCell
              style={styles.header}
              textAlign="center"
              width="5"
            >
              Only few degre (less than 10° in shoulder)
            </Table.HeaderCell>
            <Table.HeaderCell
              style={styles.header}
              textAlign="center"
              width="1"
            >
              decreased
            </Table.HeaderCell>
            <Table.HeaderCell
              style={styles.header}
              textAlign="center"
              width="1"
            >
              normal
            </Table.HeaderCell>
            <Table.HeaderCell
              style={styles.header}
              textAlign="center"
              width="5"
            >
              Note
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className="table-motion" style={{backgroundColor:tableDisable? "rgba(0, 0, 0, 0.09)" : "#FFFFFF"}}>
          <Table.Row>
            <Table.Cell style={styles.cellOdd} rowSpan="4" textAlign="center">
              {" "}
              Shoulder{" "}
            </Table.Cell>
            <SubTableRangOfMotion
              cellStyle={styles.cellOdd}
              title="flexion (0° - 180°)"
              inputStyle={styles.inputTable}
              mainName="shoulder"
              name="flexion"
            />
          </Table.Row>
          <Table.Row>
            <SubTableRangOfMotion
              cellStyle={styles.cellOdd}
              title="Abduction (0° - 90°)"
              inputStyle={styles.inputTable}
              mainName="shoulder"
              name="abduction"
            />
          </Table.Row>
          <Table.Row>
            <SubTableRangOfMotion
              cellStyle={styles.cellOdd}
              title="Internal rotation"
              inputStyle={styles.inputTable}
              mainName="shoulder"
              name="internalRotation"
            />
          </Table.Row>
          <Table.Row>
            <SubTableRangOfMotion
              cellStyle={styles.cellOdd}
              title="external rotation"
              inputStyle={styles.inputTable}
              mainName="shoulder"
              name="externalRotation"
            />
          </Table.Row>
          <Table.Row>
            <Table.Cell style={styles.cellEven} rowSpan="2" textAlign="center">
              Elbow
            </Table.Cell>
            <SubTableRangOfMotion
              cellStyle={styles.cellEven}
              title="flexion"
              inputStyle={styles.inputTable}
              mainName="elbow"
              name="flexion"
            />
          </Table.Row>
          <Table.Row>
            <SubTableRangOfMotion
              cellStyle={styles.cellEven}
              title="extension"
              inputStyle={styles.inputTable}
              mainName="elbow"
              name="extension"
            />
          </Table.Row>
          <Table.Row>
            <Table.Cell style={styles.cellOdd} rowSpan="2" textAlign="center">
              Forearm
            </Table.Cell>
            <SubTableRangOfMotion
              cellStyle={styles.cellOdd}
              title="pronation"
              inputStyle={styles.inputTable}
              mainName="forearm"
              name="pronation"
            />
          </Table.Row>
          <Table.Row>
            <SubTableRangOfMotion
              cellStyle={styles.cellOdd}
              title="supination"
              inputStyle={styles.inputTable}
              mainName="forearm"
              name="supination"
            />
          </Table.Row>
          <Table.Row>
            <Table.Cell style={styles.cellEven} rowSpan="2" textAlign="center">
              Wrist
            </Table.Cell>
            <SubTableRangOfMotion
              cellStyle={styles.cellEven}
              title="flexion"
              inputStyle={styles.inputTable}
              mainName="wrist"
              name="flexion"
            />
          </Table.Row>
          <Table.Row>
            <SubTableRangOfMotion
              cellStyle={styles.cellEven}
              title="extension"
              inputStyle={styles.inputTable}
              mainName="wrist"
              name="extension"
            />
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
);

// const rangeOfMonitorData = {};

export const TableRangeOfMotionInitialViewData: TableRangeOfMotionViewDataType = {
  tableDisable: false,
  viewData: TableInitialData.viewData,
};

TableRangeOfMotion.defaultProps = TableRangeOfMotionInitialViewData;

export default TableRangeOfMotion;
