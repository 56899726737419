import React, { SyntheticEvent, useCallback } from "react";

import { Icon, Pagination, PaginationProps } from "semantic-ui-react";

const PAGINATION_CONFIG = {
  ellipsis: {
    icon: true,
    content: <Icon name="ellipsis horizontal" />,
  },
  first: {
    icon: true,
    content: <Icon name="angle double left" />,
  },
  last: {
    icon: true,
    content: <Icon name="angle double right" />,
  },
  next: { icon: true, content: <Icon name="angle right" /> },
  prev: { icon: true, content: <Icon name="angle left" /> },
};

export type PaginationShorthandProps = {
  activePage: number;
  limit: number;
  size?: PaginationProps["size"];
  total?: number;
  onPageChange: (page: number) => void;
};

// Safe division function
const calculateSafeDivision = (numerator: number, denominator: number): number =>
  denominator === 0 ? 0 : Math.ceil(numerator / denominator);

const PaginationShorthand = (props: PaginationShorthandProps) => {
  const handlePageChange = useCallback(
    (e: SyntheticEvent, data: PaginationProps) => {
      props.onPageChange(data.activePage as number);
    },
    [props.onPageChange]
  );

  const totalPages = React.useMemo(
    () => calculateSafeDivision(props.total || 0, props.limit || 1),
    [props.total, props.limit]
  );

  return (
    <Pagination
      activePage={props.activePage}
      ellipsisItem={PAGINATION_CONFIG.ellipsis}
      firstItem={PAGINATION_CONFIG.first}
      lastItem={PAGINATION_CONFIG.last}
      nextItem={PAGINATION_CONFIG.next}
      prevItem={PAGINATION_CONFIG.prev}
      size={props.size ?? "mini"}
      totalPages={totalPages}
      onPageChange={handlePageChange}
    />
  );
};

export default React.memo(PaginationShorthand);
