import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  FormField,
  FormGroup,
  Input,
  Modal,
  Segment,
} from "semantic-ui-react";
import { CardLayout, DateTextBox, ModInfo } from "react-lib/apps/common";
import { Table } from "react-lib/frameworks/Table";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBox from "react-lib/appcon/common/SearchBox";

// UX
import CardBillingPaymentArUX from "react-lib/apps/HISV3/BIL/CardBillingPaymentArUX";
import CardPayingArUX from "react-lib/apps/HISV3/BIL/CardPayingArUX";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import SubRepeaterPayMethodUX from "./SubRepeaterPayMethodUX";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import formatComma from "react-lib/utils/formatComma";
import ModReceiptCodeAr from "react-lib/apps/HISV3/BIL/ModReceiptCodeAr";

import { styles as MedFeeStyles } from "react-lib/apps/HISV3/BIL/CardMedicalFee";
import { makeStyles } from "@mui/styles";

// Props
type CardBillingPaymentArProps = {
  onEvent: (e: any) => any;
  setProp: any;
  runSequence: any;
  // Sequence
  BillPaymentArSequence?: Record<string, any>;
  BillPaymentSequence?: Record<string, any>;
  bilReceiptCodeDetail?: Record<string, any>;
  searchedItemList?: any;
  masterOptions?: Record<string, any>;
  selectedDevice?: any;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
};

const CARD_KEY = "CardBillingPaymentAr";

const useStyles = makeStyles((theme) => ({
  inputColorRed: {
    "&.ui.input>input": {
      color: "red",
      textAlign: "right",
    },
  },
  inputSummary: {
    "&.ui.input>input": {
      textAlign: "right",
    },
  },
}));

const CardBillingPaymentAr = (props: CardBillingPaymentArProps) => {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState<string>("Item");
  const [openModalTransaction, setOpenModalTransaction] = useState<boolean>(false);
  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const [openModSuccess, setOpenModSuccess] = useState<boolean>(false);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);

  const payment = props.BillPaymentArSequence?.payment;

  useEffect(() => {
    props.runSequence({
      sequence: "BillPaymentAr",
      restart: true,
    });
  }, []);

  useEffect(() => {
    let id: any[] = [];

    (props.BillPaymentArSequence?.paymentARItem || []).map((item: any) => {
      id.push(item.id);
    });

    setCheckedIds(id);

    props.runSequence({
      sequence: "BillPaymentAr",
      action: "getInvoiceAr",
      card: CARD_KEY,
      checkedIds: id,
    });
  }, [props.BillPaymentArSequence?.paymentArId]);

  const handleChangeOrderBy = (_event: any, data: any) => {
    if (data.name === "Mode") {
      onSelectALL();
    }
    setOrderBy(data.name);
  };

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`BillPaymentArSequence.search.${data.name}`, data.checked);
    } else {
      props.setProp(`BillPaymentArSequence.search.${data.name}`, data.value);
    }
  };

  const handleChangeDate = (name: string, date: any) => {
    props.setProp(`BillPaymentArSequence.search.${name}`, date);
  };

  const handleChecked = (_checked: boolean | undefined, item: any) => {
    const ids = item.id_list || [item.id];
    let id: any[] = [];

    if (checkedIds.find((id) => ids.includes(id))) {
      id = checkedIds.filter((id) => !ids.includes(id));
    } else {
      id = [...checkedIds, ...ids];
    }

    setCheckedIds(id);

    props.runSequence({
      sequence: "BillPaymentAr",
      action: "getInvoiceAr",
      card: CARD_KEY,
      checkedIds: id,
    });
  };

  const handleChangeEdit = useCallback(
    (data: { item: any; value: string; index: number; key: string }) => {
      let value = data.value;
      if (Number(value) > Number(data.item?.total_send_claim_price)) {
        value = data.item?.total_send_claim_price;
      }

      if (Number(value) || Number(value) === 0) {
        const list = (props.BillPaymentArSequence?.paymentARItem || []).map(
          (item: any, index: number) =>
            index === data.index
              ? { ...item, [data.key]: Number(value).toFixed(2), is_editing: true }
              : item
        );

        props.runSequence({
          sequence: "BillPaymentAr",
          action: "getInvoiceAr",
          card: CARD_KEY,
          checkedIds: checkedIds,
          paymentARItem: [...list],
        });
      } else {
        alert("กรุณากรอกตัวเลข");
      }
    },
    [props.BillPaymentArSequence?.paymentARItem, checkedIds]
  );

  const onSearchReceipt = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "getArTransaction",
      card: CARD_KEY,
    });
  };

  const payMethodData = useMemo(() => {
    return payment?.payments || [];
  }, [payment?.payments]);

  const masterAccountOptions = useMemo(() => {
    return (props.BillPaymentArSequence?.masterAccountList || []).map((item: any) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
  }, [props.BillPaymentArSequence?.masterAccountList]);

  const arTransactionList = useMemo(() => {
    return (props.BillPaymentArSequence?.arTransactionList || []).map(
      (item: any, index: number) => ({
        ...item,
      })
    );
  }, [props.BillPaymentArSequence?.arTransactionList]);

  const paymentARItem = useMemo(() => {
    return (props.BillPaymentArSequence?.paymentARItem || []).map((item: any, index: number) => {
      let unpaidValue: number | string = 0;
      unpaidValue = Number(item.total_send_claim_price) - Number(item.total_pay_price);

      return {
        ...item,
        chk: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={checkedIds.includes(item.id)}
              onChange={(_e, v) => handleChecked(v.checked, item)}
            />
          </div>
        ),
        quantity: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>{item.quantity}</div>
        ),
        total_send_claim_price: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.total_send_claim_price))}
          </div>
        ),
        total_pay_price: (
          <EditorColumn
            value={formatComma(Number(item.total_pay_price))}
            valueOnFocus={true}
            selectOnFocus={true}
            // style
            backgroundColor={"#FFEEB2"}
            onDataChanged={(value: string) =>
              handleChangeEdit({ item, value, index, key: "total_pay_price" })
            }
          />
        ),
        unpaidValue: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* {Number(unpaidValue).toFixed(2)} */}
            {formatComma(Number(unpaidValue))}
          </div>
        ),
      };
    });
  }, [props.BillPaymentArSequence?.paymentARItem, checkedIds]);

  const paymentARMode = useMemo(() => {
    return (props.BillPaymentArSequence?.paymentARMode || []).map((item: any, index: number) => {
      return {
        ...item,
        total_send_claim_price: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.total_send_claim_price))}
          </div>
        ),
        total_pay_price: (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {formatComma(Number(item.total_pay_price))}
          </div>
        ),
      };
    });
  }, [props.BillPaymentArSequence?.paymentARMode]);

  const selectARTransaction = (_state: any, rowInfo: any, _column: any, _instance: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id === props.BillPaymentArSequence?.selectPaymentAr?.id
            ? "rgba(93, 188, 210, 0.41)"
            : "white",
      },
      onClick: () => {
        props.setProp("BillPaymentArSequence.selectPaymentAr", rowInfo?.original);
      },
    };
  };

  const handleSelectARTransaction = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "getARItem",
      card: CARD_KEY,
      orderBy: orderBy,
      callback: setOpenModalTransaction,
    });
  };

  const handlePrintARReport = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "printARReport",
      card: CARD_KEY,
    });
  };

  const handlePaymentAr = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "paymentAR",
      card: CARD_KEY,
      orderBy: orderBy,
      receipt_code: props.bilReceiptCodeDetail?.receipt_code,
      callback: (payment:boolean, success:boolean) => {
        setOpenPayment(payment)
        setOpenModSuccess(success)
      },
    });
  };

  const onSelectALL = () => {
    let id: any[] = [];

    (props.BillPaymentArSequence?.paymentARItem || []).map((item: any) => {
      id.push(item.id);
    });

    setCheckedIds(id);

    props.runSequence({
      sequence: "BillPaymentAr",
      action: "getInvoiceAr",
      card: CARD_KEY,
      checkedIds: id,
    });
  };

  const onSelectNone = () => {
    setCheckedIds([]);

    props.runSequence({
      sequence: "BillPaymentAr",
      action: "getInvoiceAr",
      card: CARD_KEY,
      checkedIds: [],
    });
  };

  const handleEnterCashAmountText = (e: any) => {
    if (e.key === "Enter")
      props.runSequence({
        sequence: "BillPaymentAr",
        action: "updatePaymentAR",
        card: CARD_KEY,
      });
  };

  const handleBlurCashAmountText = (e: any) => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "updatePaymentAR",
      card: CARD_KEY,
      checkedIds: checkedIds,
    });
  };

  const handleBillPayment = () => {
    props.runSequence({
      sequence: "BillPaymentAr",
      action: "checkPaymentAR",
      card: CARD_KEY,
      checkedIds: checkedIds,
      orderBy: orderBy,
    });
    setOpenPayment(true);
  };

  const handleAddPaymentMethod = (type: number) => {
    props.setProp(`BillPaymentArSequence.payment.payments`, [
      ...(payment?.payments || []),
      { type, payment_no: "", payment_target: 0, value: 0 },
    ]);
  };

  const handleChangePayMethod = (e: any, v: any, index: number) => {
    if (v.name === "value" && (isNaN(v.value) || parseFloat(v.value) < 0)) {
      alert("กรุณากรอกตัวเลข");
    } else {
      props.setProp(`BillPaymentArSequence.payment.payments.${index + 1}.${v.name}`, v.value);
      props.runSequence({
        sequence: "BillPaymentAr",
        action: "updatePaymentAR",
        card: CARD_KEY,
      });
    }
  };

  const handleRemovePaymentMethod = (index: number) => {
    let newPayment = (payment?.payments || []).filter(
      (item: any, number: number) => index + 1 !== number
    );
    props.setProp(`BillPaymentArSequence.payment.payments`, newPayment);

    props.runSequence({
      sequence: "BillPaymentAr",
      action: "updatePaymentAR",
      card: CARD_KEY,
    });
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  return (
    <div style={{ overflow: "auto" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={null}
      />

      {/* <ModReceiptCodeAr
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        bilReceiptCodeDetail={props.bilReceiptCodeDetail}
        // CommonInterface
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
        // config
        isDefault={true}
      /> */}

      <CardBillingPaymentArUX
        selectPaymentAr={props.BillPaymentArSequence?.selectPaymentAr}
        invoiceArItem={props.BillPaymentArSequence?.invoiceArItem}
        invoiceSummary={payment?.invoiceSummary}
        totalSendClaimPrice={
          props.BillPaymentArSequence?.invoiceArItem?.total_send_claim_price === "0.00"
            ? "-"
            : formatComma(
                Number(props.BillPaymentArSequence?.invoiceArItem?.total_send_claim_price)
              ) || "-"
        }
        invoiceTotalSendClaimPrice={formatComma(
          Number(payment?.invoiceSummary?.total_send_claim_price)
        )}
        invoiceTotalRemainPrice={formatComma(Number(payment?.invoiceSummary?.total_remain_price))}
        invoiceTotalPaidPrice={formatComma(Number(payment?.invoiceSummary?.total_paid_price))}
        classes={classes}
        orderBy={orderBy}
        arItemData={paymentARItem}
        arModeData={paymentARMode}
        onChangeOrderBy={handleChangeOrderBy}
        onClickAll={onSelectALL}
        onClickNone={onSelectNone}
        billPayment={handleBillPayment}
        disabledPayment={
          props.BillPaymentArSequence?.selectPaymentAr?.sent_claim_status_display ===
          "รับชำระเงินแล้ว"
        }
        arTransaction={
          <Input
            value={
              props.BillPaymentArSequence?.selectPaymentAr
                ? `${props.BillPaymentArSequence?.selectPaymentAr?.lot_no || ""}-${
                    props.BillPaymentArSequence?.selectPaymentAr?.fiscal_year || ""
                  }`
                : ""
            }
            action={{
              icon: "search",
              onClick: (event: any) => {
                props.setProp("BillPaymentArSequence.selectPaymentAr", {});
                onSearchReceipt();
                setOpenModalTransaction(true);
              },
              type: "button",
            }}
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrintARReport}
            // data
            paramKey={`${CARD_KEY}_printARReport`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_printARReport`]}
            disabled={
              props.BillPaymentArSequence?.selectPaymentAr?.sent_claim_status_display ===
              "รับชำระเงินแล้ว"
            }
            // config
            color="blue"
            size="small"
            title="พิมพ์ใบแสดงรายละเอียด"
          />
        }
        paymentStatue={
          <>
            {Number(props.BillPaymentArSequence?.paymentStatue?.total_billed_price) > 0 && (
              <div style={{ margin: "20px 0px 10px" }}>
                <div style={{ ...MedFeeStyles.message, backgroundColor: "#db2828" }}>
                  มีรายการที่ยังไม่ได้ชำระ{" "}
                  {formatComma(
                    Number(props.BillPaymentArSequence?.paymentStatue?.total_billed_price)
                  )}{" "}
                  บาท
                </div>
              </div>
            )}

            {Number(props.BillPaymentArSequence?.paymentStatue?.total_outstanding_balance_price) ===
              0 &&
              Number(props.BillPaymentArSequence?.paymentStatue?.total_billed_price) === 0 && (
                <div style={{ margin: "20px 0px 10px" }}>
                  <div style={{ ...MedFeeStyles.message, backgroundColor: "green" }}>
                    รับชำระเงินครบทุกรายการ
                  </div>
                </div>
              )}
          </>
        }
      />

      <Modal open={openModalTransaction} size="large">
        <CardLayout
          titleText="ค้นหาเลขที่ใบแจ้งหนี้"
          onClose={() => {
            setOpenModalTransaction(false);
            props.setProp("BillPaymentArSequence.selectPaymentAr", {});
          }}
          toggleable={false}
        >
          <div style={{ padding: "10px 5px" }}>
            <Form>
              <FormGroup inline>
                <FormField inline width={6}>
                  <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <div style={{ width: "100%" }}>
                      <Checkbox
                        checked={props.BillPaymentArSequence?.search?.checkedReceiptDate}
                        label="วันที่สร้างใบแจ้งหนี้"
                        name="checkedReceiptDate"
                        onChange={handleChangeData}
                      ></Checkbox>
                    </div>
                    <div style={{ width: "100%" }}>
                      <DateTextBox
                        value={props.BillPaymentArSequence?.search?.created_date}
                        onChange={(date: any) => handleChangeDate("created_date", date)}
                      />
                    </div>
                  </div>
                </FormField>
                <FormField inline width={5}>
                  <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <div style={{ width: "100%" }}>
                      <Checkbox
                        checked={props.BillPaymentArSequence?.search?.checkedReceiptNumber}
                        label="เลขที่ใบแจ้งหนี้"
                        name="checkedReceiptNumber"
                        onChange={handleChangeData}
                      ></Checkbox>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Input
                        value={props.BillPaymentArSequence?.search?.lot_no}
                        name="lot_no"
                        onChange={handleChangeData}
                      ></Input>
                    </div>
                  </div>
                </FormField>
                <FormField inline width={4}>
                  <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <div style={{ width: "100%" }}>
                      <Checkbox
                        checked={props.BillPaymentArSequence?.search?.checkedReceiptYear}
                        label="ปีงบประมาณ"
                        name="checkedReceiptYear"
                        onChange={handleChangeData}
                      ></Checkbox>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Input
                        value={props.BillPaymentArSequence?.search?.fiscal_year}
                        name="fiscal_year"
                        onChange={handleChangeData}
                      ></Input>
                    </div>
                  </div>
                </FormField>

                <FormField inline width={1}>
                  <ButtonLoadCheck
                    // function
                    setProp={props.setProp}
                    onClick={onSearchReceipt}
                    // data
                    paramKey={`${CARD_KEY}_getArTransaction`}
                    buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_getArTransaction`]}
                    // config
                    color="blue"
                    size="small"
                    title="ค้นหา"
                  />
                </FormField>
              </FormGroup>
            </Form>

            <Table
              data={arTransactionList}
              getTrProps={selectARTransaction}
              headers="ปีงบประมาณ,เลขที่ใบแจ้งหนี้,สิทธิ,หน่วยงานต้นสังกัด,ประเภทผู้ป่วย,สถานะส่งเบิก"
              keys="fiscal_year,lot_no,coverage_code_name,payer_code_name,patient_type,sent_claim_status_display"
              minRows={6}
              style={{ height: "350px" }}
              showPagination={false}
              widths="^50,^60,^140,^140,^60,^80"
            ></Table>

            <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "20px" }}>
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleSelectARTransaction}
                // data
                paramKey={`${CARD_KEY}_getARItem`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_getARItem`]}
                // config
                color="green"
                size="small"
                title="เลือก"
              />
            </div>
          </div>
        </CardLayout>
      </Modal>

      <Modal
        closeOnDimmerClick={true}
        style={{ maxHeight: "80%", width: "80%", overflow: "auto" }}
        open={openPayment}
        onClose={() => setOpenPayment(false)}
      >
        {/* import formatComma from "react-lib/utils/formatComma";
         */}
        <CardPayingArUX
          bilReceiptCode={props.bilReceiptCodeDetail?.receipt_code}
          selectPaymentAr={props.BillPaymentArSequence?.selectPaymentAr}
          payment={props.BillPaymentArSequence?.payment}
          priceText={payment?.priceText}
          totalPrice={payment?.totalPrice}
          //
          sendClaim={formatComma(Number(payment?.totalPrice?.sendClaim))}
          payable={formatComma(Number(payment?.totalPrice?.payable))}
          oweText={formatComma(Number(payment?.priceText?.oweText))}
          transferText={formatComma(Number(payment?.priceText?.transferText))}
          chequeText={formatComma(Number(payment?.priceText?.chequeText))}
          installmentText={formatComma(Number(payment?.priceText?.installmentText))}
          totalText={formatComma(Number(payment?.priceText?.totalText))}
          invoiceChange={formatComma(
            Number(
              Number(payment?.totalPrice?.payable) > Number(payment?.priceText?.totalText)
                ? "0.00"
                : Number(payment?.priceText?.totalText) - Number(payment?.totalPrice?.payable) || 0
            )
          )}
          //
          onAddPaymentMethod={handleAddPaymentMethod}
          onChangePaidDate={(date: any) =>
            props.setProp(`BillPaymentArSequence.payment.paid_date`, date)
          }
          // callback
          onChangeCashAmountText={(e: any) => {
            if (isNaN(e.target.value) || parseFloat(e.target.value) < 0) {
              alert("กรุณากรอกตัวเลข");
            } else {
              props.setProp(
                "BillPaymentArSequence.payment.priceText.cashAmountText",
                e.target.value
              );
            }
          }}
          onEnterCashAmountText={handleEnterCashAmountText}
          onBlurCashAmountText={handleBlurCashAmountText}
          searchBoxPayer={
            <SearchBox
              vertical={true}
              onEvent={props.onEvent}
              setProp={props.setProp}
              searchedItemList={props.searchedItemList}
              selectedItem={payment?.payer_target}
              setSelectedItem={(item: any) => {
                props.setProp(`BillPaymentArSequence.payment.payer_target`, item);
              }}
              type="Payer"
              placeholder="กรอกชื่อหน่วยงานต้นสังกัดผู้โอนที่ต้องการค้นหา"
              toDisplay={(item: any) => `${item.name_with_id}`}
              fluid={true}
              style={{ width: "100%" }}
              useWithKey={false}
            />
          }
          searchBoxPayerReceipt={
            <SearchBox
              vertical={true}
              onEvent={props.onEvent}
              setProp={props.setProp}
              searchedItemList={props.searchedItemList}
              selectedItem={payment?.payer}
              setSelectedItem={(item: any) => {
                props.setProp(`BillPaymentArSequence.payment.payer`, item);
              }}
              type="Payer"
              placeholder="กรอกชื่อหน่วยงานบนใบเสร็จที่ต้องการค้นหา"
              toDisplay={(item: any) => `${item.name_with_id}`}
              fluid={true}
              style={{ width: "100%" }}
              useWithKey={false}
            />
          }
          SubRepeaterPayMethod={
            <>
              {payMethodData
                .filter((item: any) => item.type !== "1")
                .map((item: any, index: number) => (
                  <SubRepeaterPayMethodUX
                    key={"sub-repeater-paymethod" + item.id}
                    type={item.type}
                    paymentNo={item.paymentNo}
                    paymentTarget={item.paymentTarget}
                    value={item.value}
                    couponMessage={item.couponMessage}
                    //  Use Memo
                    masterAccountOptions={masterAccountOptions}
                    onChange={(e: any, v: any) => handleChangePayMethod(e, v, index)}
                    onClose={() => handleRemovePaymentMethod(index)}
                    handleKeyDownNumber={handleKeyDownNumber}
                  />
                ))}
            </>
          }
          buttonPay={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handlePaymentAr}
              // data
              paramKey={`${CARD_KEY}_paymentAR`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_paymentAR`]}
              // config
              color="green"
              size="small"
              title="รับชำระเงิน"
            />
          }
        />
      </Modal>

      <ModInfo
        open={openModSuccess}
        titleColor={"green"}
        titleName={"แจ้งเตือน"}
        onApprove={() => setOpenModSuccess(false)}
        onClose={() => setOpenModSuccess(false)}
        alertText={
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>{"บันทึกรับชำระสำเร็จ"}</div>
        }
      />
    </div>
  );
};

export default CardBillingPaymentAr;
