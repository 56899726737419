import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea
} from 'semantic-ui-react'

import DateTextBox from '../common/DateTextBox';
import TimeFreeTextBox24 from '../common/TimeFreeTextBox24';


import styles from './CardPTRehabilitationtreatment.module.css'

export interface PTRehabilitationtreatmentViewDataType {
    PtRehabilitationTreatment3: string,
    PtRehabilitationTreatment3Disabled: boolean,
    PtRehabilitationTreatment5: string,
    PtRehabilitationTreatment5Disabled: boolean,
    PtRehabilitationTreatment7: string,
    PtRehabilitationTreatment7Disabled: boolean,
    PtRehabilitationTreatment9: string,
    PtRehabilitationTreatment9Disabled: boolean,
    PtRehabilitationTreatment11: string,
    PtRehabilitationTreatment11Disabled: boolean,
    Ct7PainScore: string,
    Ct7PainScoreDisabled: boolean,
    Ct7PainScoreOptions: any,
    PtRehabilitationTreatment17: string,
    PtRehabilitationTreatment17Disabled: boolean,
    PtRehabilitationTreatment19: boolean,
    PtRehabilitationTreatment19Disabled: boolean,
    PtRehabilitationTreatment22: boolean,
    PtRehabilitationTreatment22Disabled: boolean,
    PtRehabilitationTreatment24: string,
    PtRehabilitationTreatment24Disabled: boolean,
    PtRehabilitationTreatment25: boolean,
    PtRehabilitationTreatment25Disabled: boolean,
    PtRehabilitationTreatment27: string,
    PtRehabilitationTreatment27Disabled: boolean,
    PtRehabilitationTreatment29: boolean,
    PtRehabilitationTreatment29Disabled: boolean,
    PtRehabilitationTreatment31: boolean,
    PtRehabilitationTreatment31Disabled: boolean,
    PtRehabilitationTreatment34: string,
    PtRehabilitationTreatment34Disabled: boolean,
    PtRehabilitationTreatment36: string,
    PtRehabilitationTreatment36Disabled: boolean,
    PtRehabilitationTreatment38: string,
    PtRehabilitationTreatment38Disabled: boolean,
    PtRehabilitationTreatment45: string,
    PtRehabilitationTreatment45Disabled: boolean,
    PtRehabilitationTreatment46: string,
    PtRehabilitationTreatment46Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const PTRehabilitationtreatmentInitialViewData: PTRehabilitationtreatmentViewDataType = {
    PtRehabilitationTreatment3: "",
    PtRehabilitationTreatment3Disabled: false,
    PtRehabilitationTreatment5: "",
    PtRehabilitationTreatment5Disabled: false,
    PtRehabilitationTreatment7: "",
    PtRehabilitationTreatment7Disabled: false,
    PtRehabilitationTreatment9: "",
    PtRehabilitationTreatment9Disabled: false,
    PtRehabilitationTreatment11: "",
    PtRehabilitationTreatment11Disabled: false,
    Ct7PainScore: "",
    Ct7PainScoreDisabled: false,
    Ct7PainScoreOptions: [{key: 0, value: "A", text: "A"}],
    PtRehabilitationTreatment17: "",
    PtRehabilitationTreatment17Disabled: false,
    PtRehabilitationTreatment19: false,
    PtRehabilitationTreatment19Disabled: false,
    PtRehabilitationTreatment22: false,
    PtRehabilitationTreatment22Disabled: false,
    PtRehabilitationTreatment24: "",
    PtRehabilitationTreatment24Disabled: false,
    PtRehabilitationTreatment25: false,
    PtRehabilitationTreatment25Disabled: false,
    PtRehabilitationTreatment27: "",
    PtRehabilitationTreatment27Disabled: false,
    PtRehabilitationTreatment29: false,
    PtRehabilitationTreatment29Disabled: false,
    PtRehabilitationTreatment31: false,
    PtRehabilitationTreatment31Disabled: false,
    PtRehabilitationTreatment34: "",
    PtRehabilitationTreatment34Disabled: false,
    PtRehabilitationTreatment36: "",
    PtRehabilitationTreatment36Disabled: false,
    PtRehabilitationTreatment38: "",
    PtRehabilitationTreatment38Disabled: false,
    PtRehabilitationTreatment45: "",
    PtRehabilitationTreatment45Disabled: false,
    PtRehabilitationTreatment46: "",
    PtRehabilitationTreatment46Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtRehabilitationTreatment1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment2}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Problem/ Physical therapy</strong></p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment3}>
                <Input
                    value={props.viewData.PtRehabilitationTreatment3}
                    disabled={ props.viewData.PtRehabilitationTreatment3Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTRehabilitationtreatment',
                            name: 'PtRehabilitationTreatment3',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtRehabilitationTreatment4}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>diagnosis*</strong></p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment5}>
                <Input
                    value={props.viewData.PtRehabilitationTreatment5}
                    disabled={ props.viewData.PtRehabilitationTreatment5Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTRehabilitationtreatment',
                            name: 'PtRehabilitationTreatment5',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtRehabilitationTreatment6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Goal of Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment7}>
                <TextArea
                    value={props.viewData.PtRehabilitationTreatment7}
                    disabled={ props.viewData.PtRehabilitationTreatment7Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTRehabilitationtreatment',
                            name: 'PtRehabilitationTreatment7',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PtRehabilitationTreatment8}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment9}>
                <Input
                    value={props.viewData.PtRehabilitationTreatment9}
                    disabled={ props.viewData.PtRehabilitationTreatment9Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTRehabilitationtreatment',
                            name: 'PtRehabilitationTreatment9',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtRehabilitationTreatment10}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Post of treatment</strong></p>
                </div>
            </div>
            {/* <div className={styles.PtRehabilitationTreatment11}>
                <Input
                    value={props.viewData.PtRehabilitationTreatment11}
                    disabled={ props.viewData.PtRehabilitationTreatment11Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTRehabilitationtreatment',
                            name: 'PtRehabilitationTreatment11',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct7PainScore}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct7PainScore}
                    disabled={ props.viewData.Ct7PainScoreDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTRehabilitationtreatment",
                                name: "Ct7PainScore",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct7PainScoreOptions}
                />
            </div>
            <div className={styles.PtRehabilitationTreatment15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Pain score</strong></p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Other</strong></p>
                </div>
            </div> */}
            <div className={styles.PtRehabilitationTreatment17}>
                <TextArea
                    value={props.viewData.PtRehabilitationTreatment17}
                    disabled={ props.viewData.PtRehabilitationTreatment17Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTRehabilitationtreatment',
                            name: 'PtRehabilitationTreatment17',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PtRehabilitationTreatment18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note</strong></p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment19}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtRehabilitationTreatment19}
                    disabled={ props.viewData.PtRehabilitationTreatment19Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTRehabilitationtreatment",
                                name: "PtRehabilitationTreatment19"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtRehabilitationTreatment20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note to doctor</strong></p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment22}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtRehabilitationTreatment22}
                    disabled={ props.viewData.PtRehabilitationTreatment22Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "PTRehabilitationtreatment",
                                name: "PtRehabilitationTreatment22"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtRehabilitationTreatment23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Yes, able to perform treatment follow doctor's prescription</p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment24}>
                <Input
                    value={props.viewData.PtRehabilitationTreatment24}
                    disabled={ props.viewData.PtRehabilitationTreatment24Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTRehabilitationtreatment',
                            name: 'PtRehabilitationTreatment24',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtRehabilitationTreatment25}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtRehabilitationTreatment25}
                    disabled={ props.viewData.PtRehabilitationTreatment25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "PTRehabilitationtreatment",
                                name: "PtRehabilitationTreatment25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtRehabilitationTreatment26}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>No,</p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment27}>
                <Input
                    value={props.viewData.PtRehabilitationTreatment27}
                    disabled={ props.viewData.PtRehabilitationTreatment27Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTRehabilitationtreatment',
                            name: 'PtRehabilitationTreatment27',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtRehabilitationTreatment28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Instruction*</strong></p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtRehabilitationTreatment29}
                    disabled={ props.viewData.PtRehabilitationTreatment29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTRehabilitationtreatment",
                                name: "PtRehabilitationTreatment29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtRehabilitationTreatment30}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Patient and/or family was/were informed about instruction and plan of treatment</p>
                </div>
            </div>
            <div className={styles.PtRehabilitationTreatment31}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtRehabilitationTreatment31}
                    disabled={ props.viewData.PtRehabilitationTreatment31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTRehabilitationtreatment",
                                name: "PtRehabilitationTreatment31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtRehabilitationTreatment32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Need to be reviewed</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PTRehabilitationtreatment',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
