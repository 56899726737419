import React, { CSSProperties, useEffect, useState, useMemo } from "react";
import moment from "moment";
import { Checkbox, Modal } from "semantic-ui-react";
import { momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CardSelectTemplate from "./CardSelectTemplate";
import CardChairUX from "./CardChairUX";
import { blockListForChair } from "./common/BlockList";
import ZoneSelector from "./ZoneSelector";
import ModChairDetailUX from "./ModChairDetailUX";
import ModalChangeName from "react-lib/apps/Scheduling/ModalChangeName";
import { CHAIR_STATUS } from "./SchedulingInterface";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

console.log("initial moment locale en week dow 1")
moment.locale("en", { week: { dow: 1 } });
const localizer = momentLocalizer(moment);
const CARD_CHAIR = "CardChair";

const CardChair = (props: any) => {
  const [mode, setMode] = useState("home");
  const [chairList, setChairList] = useState<any[]>([]);
  const [zone, setZone] = useState<any>(null);
  const [selectedChair, setSelectedChair] = useState<any>(null);
  const [chairProviderIdList, setChairProviderIdList] = useState<number[]>([]);
  const [selectedDivision, setSelectedDivision] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const [filterChairNo, setFilterChairNo] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [filterClinic, setFilterClinic] = useState<string>("");

  const [calendarYear, setCalendarYear] = useState<string[]>([moment().format("YYYY")]);

  const [timePeriodSelected, setTimePeriodSelected] = useState<string>("");
  const [dateSelected, setDateSelected] = useState<string>("");
  const [chairBookingId, setChairBookingId] = useState<number>();

  useEffect(() => {
    props.onEvent({ message: "GetChairList", params: {} });
    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "GetScheduleTemplate",
      },
    });
  }, []);

  useEffect(() => {
    props.onEvent({
      message: "FilterSchedule",
      params: {
        divisionId: props.selectedDivision?.id,
        initial: selectedDivision === null,
      },
    });
    props.onEvent({ message: "Holiday", params: { action: "refresh" } });

    setSelectedDivision(props.selectedDivision);
    setChairProviderIdList([]);
  }, [props.selectedDivision]);

  useEffect(() => {
    let chairList: any[] = [];
    if (props.chairList?.length > 0) {
      chairList = [...props.chairList];
      if (filterChairNo) {
        chairList = chairList.filter((item: any) => item.name?.includes(filterChairNo));
      }
      if (filterStatus) {
        chairList = chairList.filter((item: any) => item.status_name ===filterStatus);
      }
      if (filterClinic) {
        chairList = chairList.filter((item: any) => item.division_name?.includes(filterClinic));
      }
    }
    console.log(" status_name", chairList, filterStatus);

    updateChairList(chairList);
  }, [props.chairList, filterChairNo, filterClinic, filterStatus]);

  useEffect(() => {
    // if (chairProviderIdList?.length) {
      props.onEvent({
        message: "FilterSchedule",
        params: {
          providerId: chairProviderIdList,
        },
      });
    // }
    updateChairList(chairList);
  }, [chairProviderIdList]);

  const createOptions = (value: string, index: number) => ({ key: index+1, value, text: value });

  const chairNoOptions = useMemo(() => {
    return Array.from(new Set(props.chairList || []))
      .map((item: any) => item.name)
      .map((item: any, index: number) => createOptions(item, index + 1));
  }, [props.chairList]);

  const statusOptions = useMemo(() => {
    return [...Object.values(CHAIR_STATUS), "กำลังใช้งาน", "ปิดซ่อม"].map(createOptions);
  }, [props.chairList]);

  const clinicOptions = useMemo(() => {
    return Array.from(new Set(Object.values(props.divisionDict || {})))
      .map((item: any) => item.name)
      .map(createOptions);
  }, [props.chairList]);

  console.log("props.chairList", chairNoOptions, statusOptions, clinicOptions);

  const updateChairList = (items: any[]) => {
    setChairList(
      items.map((chair: any) => ({
        ...chair,
        select: (
          <Checkbox
            checked={chairProviderIdList.includes(chair?.provider)}
            onClick={(e: any) => {
              e.stopPropagation();
              if (chairProviderIdList.includes(chair?.provider)) setChairProviderIdList(chairProviderIdList.filter((id: number) => id !== chair?.provider));
              else setChairProviderIdList([...chairProviderIdList, chair?.provider]);
            }}
          />
        ),
      }))
    );
  };

  const blockStyle = (event: any) => {
    if (selectedChair) {
      // Issue 55924
      return event?.holiday
        ? { backgroundColor: "#FFE696", color: "black", textAlign: "center" }
        : event?.division_id !== props.selectedDivision?.id
        ? { backgroundColor: "orange", color: "black" }
        : event?.open_chairs.map((chair: any) => chair.provider_id).includes(selectedChair?.provider)
        ? { backgroundColor: "cyan", color: "black" }
        : event?.booked_chairs.map((chair: any) => chair.provider_id).includes(selectedChair?.provider)
        ? { backgroundColor: "#cccccc", color: "black" }
        : { backgroundColor: "pink", color: "black" };
    } else {
      // Issue 55924
      return event?.holiday
        ? { backgroundColor: "#FFE696", color: "black", textAlign: "center" }
        : event?.division_id !== props.selectedDivision?.id
        ? { backgroundColor: "orange", color: "black" }
        : event?.open_chairs.length > 0
        ? { backgroundColor: "cyan", color: "black" }
        : event?.booked_chairs.length > 0
        ? { backgroundColor: "#cccccc", color: "black" }
        : { backgroundColor: "pink", color: "black" };
    }
  };

  const blockList = useMemo(() => {
    let blockListSet = props.blockList
    .concat(props.providerBlockList?.filter((item: any) => item.division_id !== props.selectedDivision?.id
      ) || [])
    let bl = blockListForChair(blockListSet, selectedChair, zone, props.divisionDict, props.chairList, props.selectedDivision);
    // console.log('blockList useMemo bl: ', bl);
    return bl;
  }, [props.blockList, selectedChair, zone, props.divisionDict, props.chairList, props.providerBlockList]);

  const blockListAndHoliday = useMemo(() => {
    const holiday = (props.holiday?.items || []).flatMap((item: any) => {
      const [day, month, year] = item.date[0].split("/");
      const date = `${year - 543}-${month}-${day}`;
      return { start: new Date(`${date} 00:00`), end: new Date(`${date} 23:59`), title: item.detail, holiday: true };
      // return calendarYear.map((year) => {
      //   const date = `${year}-${month}-${day}`;
      //   return { start: new Date(`${date} 00:00`), end: new Date(`${date} 23:59`), title: item.detail, holiday: true };
      // });
    });

    return [...holiday , ...blockList ];
  }, [calendarYear, blockList, props.holiday?.items]);

  const chairDetailData = (selectedEvent?.open_chairs || []).concat(selectedEvent?.booked_chairs || [])?.map((item: any) => ({
    ...item,
    chair_status: item.chair_status === 2 ? "ปิดให้บริการ" : "เปิดให้บริการ",
  }));

  const selectedChairText = useMemo(
    () =>
      chairList
        .filter((chair: any) => chairProviderIdList.includes(chair.provider))
        .map((chair: any) => chair?.name)
        .join(", "),
    [chairProviderIdList, chairList]
  );

  // 2024-05-29 -> พุธที่ 29 พฤษภาคม 2567
  const formatDateLocale = (date: string) => {
    moment.locale("th");
    const formattedDate = moment(date).format("ddddที่ D MMMM YYYY");
    const buddhistYear = moment(date).year()+ 543;
    return formattedDate.replace(moment(date).year().toString(), buddhistYear.toString());
  }

  const onPrintReport = () => {
    props.onEvent({
      message: "HandlePrintChairBooking",
      params: {
        dsb: chairBookingId,
        date: dateSelected,
        period: timePeriodSelected,
        booked: selectedEvent?.booked_chairs,
        providers: selectedEvent?.providers
      }
    });
  }

  console.log("CardChair: ", props.providerBlockList);
  // console.log("Yeti Chair All Props: ", props);
  // console.log("Yeti Format Date: ", formatDateLocale("2024-04-29"))
  return (
    <div>
      <CardChairUX
        filterChairNo={filterChairNo}
        filterStatus={filterStatus}
        filterClinic={filterClinic}
        chairNoOptions={chairNoOptions}
        statusOptions={statusOptions}
        clinicOptions={clinicOptions}
        onChangeFilterChairNo={(e: any, { value }: any) => {
          setFilterChairNo(value);
        }}
        onChangeFilterStatus={(e: any, { value }: any) => {
          setFilterStatus(value);
        }}
        onChangeFilterClinic={(e: any, { value }: any) => {
          setFilterClinic(value);
        }}
        mode={mode}
        // Chair
        selectChair={(e: any) => setMode("chair")}
        clearChair={(e: any) => {
          setChairProviderIdList([]);
          setMode("home");
        }}
        selectedChairs={selectedChairText}
        chairList={chairList}
        rowProps={(state: any, rowInfo: any, column: any, instance: any) => {
          return {
            style: {
              backgroundColor: rowInfo?.original?.id && rowInfo?.original?.id === selectedChair?.id ? "#cccccc" : "white",
            },
            onClick: () => {
              console.log("onClick rowInfo?.original", rowInfo?.original);
              if (selectedChair && rowInfo?.original?.id === selectedChair?.id) setSelectedChair(null);
              else setSelectedChair(rowInfo?.original);
            },
          };
        }}
        selectChairFinish={(e: any) => setMode("home")}
        selectChairClear={(e: any) => setMode("home")}
        zoneSelector={<ZoneSelector selectedDivision={props.selectedDivision} zone={zone} setZone={setZone} />}
        // SelectTemplate
        cardSelectTemplate={
          <CardSelectTemplate
            scheduleTemplates={props.scheduleTemplates}
            disableCreate={chairProviderIdList.length === 0}
            createDSBFromTemplateItems={(
              templateItems: any[],
              templateItemIndexList: number[],
              startDateTime: string,
              endDateTime: string,
              card: string,
              buttonLoadKey: string
            ) =>
              props.onEvent({
                message: "SetScheduling",
                params: {
                  action: "CreateDSBFromTemplateItems",
                  items: templateItems.filter((item: any, index: number) => templateItemIndexList.includes(index)),
                  startDateTime: startDateTime,
                  endDateTime: endDateTime,
                  providers: chairProviderIdList,
                  zone: zone,
                  chair: null,
                  capacity: null,
                  card: card,
                  buttonLoadKey: buttonLoadKey,
                },
              })
            }
            chairOptions={[]}
            defaultChair={null}
            defaultCapacity={null}
            zone={zone}
            selectedDivision={props.selectedDivision}
            buttonLoadCheck={props.buttonLoadCheck}
            setProp={props.setProp}
            card={CARD_CHAIR}
            selectedChairText={selectedChairText}
            hideExceptNewPatient={true}
          />
        }
        // Calendar
        localizer={localizer}
        minTime={new Date(1972, 0, 1, 8, 0, 0)}
        maxTime={new Date(1972, 0, 1, 20, 0, 0)}
        blockList={blockListAndHoliday}
        eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {
          return { style: blockStyle(event) };
        }}
        onRangeChange={(range: any, view: any) => {
          const year = Array.isArray(range) ? [range[0], range.slice(-1)[0]] : [range.start, range.end];
          const uniqueYear = Array.from(new Set([moment(year[0]).format("YYYY"), moment(year[1]).format("YYYY")]));
          if (uniqueYear.toString() !== calendarYear.toString()) {
            setCalendarYear(uniqueYear);
          }
        }}
        onSelectEvent={(event: any) => {
          console.log(event);
          if (event?.holiday) {
            return;
          }

          setDateSelected(formatDateLocale(event.date));
          setTimePeriodSelected(`เวลา ${event.start_time} - ${event.end_time} น.`);
          setChairBookingId(event.dsb_id);
          setSelectedEvent(event);
          setOpenModal(true);
        }}
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)} closeOnDimmerClick={true}>
        <div style={{ padding: "1rem" }}>
          <label style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
            รายการจองเก้าอี้
          </label>
        </div>
        <div style={{ display: "flex", padding: "1rem" }}>
          <label style={{ display: "flex", alignItems: "center", padding: "0rem 5rem 0rem 0rem" }}>
            {dateSelected}
          </label>
          <label style={{ display: "flex", alignItems: "center", padding: "0rem 20rem 0rem 0rem" }}>
            {timePeriodSelected}
          </label>
          <ButtonLoadCheck
            setProp={props.setProp}
            onClick={onPrintReport}
            paramKey={`${CARD_CHAIR}_PRINT_REPORT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_CHAIR}_PRINT_REPORT`]}
            style={{ width: "15em" }}
            color="blue"
            title="พิมพ์รายงานจองเก้าอี้"
          />
        </div>
        <ModChairDetailUX data={chairDetailData} />
      </Modal>
    </div>
  );
};

export default CardChair;
