import React, { useEffect, useState, useMemo, useCallback } from "react";
import CardDrugResuscitationOPDUx from "./CardDrugResuscitationOPDUx";
import CardDrugResuscitationEditUX from "./CardDrugResuscitationEditUX";
import {
  Grid,
  Form,
  Label,
  Segment,
  Divider,
  Button,
  TextArea,
  Input,
  Menu,
  Popup,
  Modal,
  Dimmer,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";
import { CONSULT_ORDER_MODEL } from "HIS/Interface/DPOI";
import SubUserToken from "react-lib/apps/TPD/SubUserToken";
import SubDrugOrderStatusAlert from "./SubDrugOrderStatusAlert";
import CardDrugClaimQA from "./CardDrugClaimQA";
import ReactTable from "react-table-6";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import {
  TimeFreeTextBox24,
  ErrorMessage,
  ModInfo as ModError,
  ModInfo,
} from "react-lib/apps/common";

const CARD_NAME = "CardDrugResuscitationOPD";

type CardDrugResuscitationOPDStateType = {
  runSequence?: any;
  onEvent?: any;
  setProp?: any;
  errorMessage?: any;
  successMessage?: any;
  masterOptions?: any;
  selectedDivision?: any;
  selectedEncounter?: any;
  controller?: any;
  DrugResuscitationSequenceOPD?: any;
  providerEmployeeInfo?: any;
  userTokenize?: any;
  DJANGO?: any;
  record?: any;
  buttonLoadCheck?: any;
};

export type InputNurseProps = {
  error: boolean;
  token: string;
};

export type InputNurseType = {
  nurse1: InputNurseProps;
  nurse2: InputNurseProps;
};
type ElementFormType = {
  nurse1: JSX.Element;
  nurse2: JSX.Element;
};

export const initialInput = {
  nurse1: {
    error: false,
    token: "",
  },
  nurse2: {
    error: false,
    token: "",
  },
};
const CardDrugResuscitationOPDInitial: CardDrugResuscitationOPDStateType = {
  DJANGO: {},
  record: {},
  controller: {},
  onEvent: null,
  runSequence: null,
  setProp: null,
  errorMessage: null,
  successMessage: null,
  masterOptions: null,
  selectedDivision: null,
  selectedEncounter: null,
  DrugResuscitationSequenceOPD: null,
  providerEmployeeInfo: null,
  userTokenize: null,
};

const CardDrugResuscitationOPD: React.FC<CardDrugResuscitationOPDStateType> = (
  props: any
) => {
  const [showInputNurse, setShowInputNurse] =
    useState<InputNurseType>(initialInput);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [editID, setEditID] = useState<any>("");
  const [opitonUnit, setOpitonUnit] = useState<any>([]);
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [openModError, setOpenModError] = useState<boolean>(false);
  const [openDrugNotify, setOpenDrugNotify] = useState<boolean>(false);

  useEffect(() => {
    props.runSequence({
      sequence: "DrugResuscitationOPD",
      card: CARD_NAME,
      action: "clear",
    });
  }, []);

  useEffect(() => {
    if (props.DrugResuscitationSequenceOPD?.drugSaveOrder.length !== 0) {
      if (
        props.DrugResuscitationSequenceOPD?.drugSaveOrder &&
        props.DrugResuscitationSequenceOPD?.drugSaveOrder?.items?.[0]
          ?.drug_order !== null
      ) {
        setOnEdit(true);
      } else {
        setOnEdit(false);
      }
    }
  }, [props.DrugResuscitationSequenceOPD?.drugSaveOrder]);

  useEffect(() => {
    if (props.buttonLoadCheck?.[`${CARD_NAME}_saveEdit`] === "SUCCESS") {
      setOpenEdit(false);
    }
  }, [props.buttonLoadCheck?.[`${CARD_NAME}_saveEdit`]]);

  useEffect(() => {
    if (props.DrugResuscitationSequenceOPD?.showDivisionError) {
      setOpenModError(true);
    }
  }, [props.DrugResuscitationSequenceOPD?.showDivisionError]);

  const handleChangeValue = (key: string) => (e: any, v: any) =>
    props.setProp(`DrugResuscitationSequenceOPD.${key}`, v.value);

  const handleSearchValue = () => {
    props.runSequence({
      sequence: "DrugResuscitationOPD",
      card: CARD_NAME,
      action: "drugSearch",
    });
  };

  const handleAddValue = (item: any, index: any) => {
    props.runSequence({
      sequence: "DrugResuscitationOPD",
      card: CARD_NAME,
      additem: item,
      userNurse: props.providerEmployeeInfo?.employee_info?.full_name,
      action: "addDrug",
      index: index,
    });
  };

  const handleSaveQA = (data: any, index: any) => {
    props.runSequence({
      sequence: "DrugResuscitationOPD",
      card: CARD_NAME,
      action: "saveQA",
      data: data,
      index: index,
      indexDrugAdd: props.DrugResuscitationSequenceOPD?.estimate?.indexDrugAdd,
    });
  };

  const handleSaveDrug = () => {
    if (
      !props.DrugResuscitationSequenceOPD?.drugAdd ||
      props.DrugResuscitationSequenceOPD?.drugAdd?.length === 0
    ) {
      setOpenDrugNotify(true);
    } else {
      props.runSequence({
        sequence: "DrugResuscitationOPD",
        card: CARD_NAME,
        addDrug: props.DrugResuscitationSequenceOPD?.drugAdd,
        action: onEdit ? "edit" : "pick",
      });
    }
  };

  const handleDeleteValue = (idx: number) => {
    let orderItems: any = props.DrugResuscitationSequenceOPD?.drugAdd;
    orderItems.splice(idx, 1);

    props.setProp("DrugResuscitationSequenceOPD.drugAdd", orderItems);
  };

  const drugListData = useMemo(
    () =>
      props.DrugResuscitationSequenceOPD?.listItems?.[0]?.items?.map(
        (item: any, idx: any) => {
          return {
            ...item,
            button: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  name="plus square"
                  color="green"
                  size="large"
                  onClick={() => handleAddValue(item, idx)}
                />
              </div>
            ),
            dosage_form: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.dosage_form}
              </div>
            ),
            size: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.size}
              </div>
            ),
            quantity: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.quantity}
              </div>
            ),
          };
        }
      ),

    [
      props.DrugResuscitationSequenceOPD,
      props.DrugResuscitationSequenceOPD?.listItems,
    ]
  );

  const handleCheckHaveQuestion = useCallback((data: any, index: number) => {
    props.setProp("DrugResuscitationSequenceOPD.estimate", {
      ...data.drugDetail.question,
      indexDrugAdd: index,
    });
  }, []);

  const drugOrderData = useMemo(
    () =>
      props.DrugResuscitationSequenceOPD?.drugAdd?.map(
        (item: any, idx: any) => {
          return {
            ...item,
            box: (
              <>
                <div>{item?.drugDetail?.name}</div>
                <SubDrugOrderStatusAlert
                  onEvent={props.onEvent}
                  stateKey="drugResuscitation"
                  index={idx}
                  item={{
                    ...item?.drugDetail,
                    alerts: {
                      H: item?.drugDetail?.highalert === "True",
                      L: item?.drugDetail?.lasa === "True",
                      I:
                        item?.drugDetail?.drug_interaction_info ||
                        item?.drugDetail?.note_drug_interaction
                          ? {
                              info:
                                item?.drugDetail.drug_interaction_info ||
                                item?.drugDetail.note_drug_interaction,
                            }
                          : null,
                      ...(item?.drugDetail?.alerts || {}),
                    },
                    claim_payload: item?.drugDetail?.estimate?.claim_payload
                      ? item?.drugDetail?.estimate?.claim_payload
                      : null,
                  }}
                  saveClaimByEvent={true}
                  estimate={props.DrugResuscitationSequenceOPD?.estimate || {}}
                  selectedEncounter={props.selectedEncounter}
                  selectedEmr={props.selectedEmr}
                  orderType={"HOME_OPD"}
                  selectedProgressCycle={props.selectedProgressCycle}
                  isFemale={
                    props.selectedEncounter?.patient_gender_name === "Female"
                  }
                  // statusRequest={!statusRequested}
                  // onSaveNote={handleSaveNote}
                  // callback
                  onDrugClaim={() => handleCheckHaveQuestion(item, idx)}
                  onCloseQA={() => {
                    props.setProp("DrugResuscitationSequenceOPD.estimate", {});
                  }}
                  onSaveQA={(e: any) => handleSaveQA(e, idx)}
                  targetStateOrder={"drugOrder"}
                />
              </>
            ),
            button: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  name="trash alternate"
                  color="red"
                  size="large"
                  onClick={() => handleDeleteValue(idx)}
                />
              </div>
            ),
            unit: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item?.unit ===
                "unit ที่ระบุไม่อยู่ในรายการที่อนุญาตให้เลือกได้"
                  ? item?.unit
                  : props.masterOptions?.unit
                      .filter((data: any) => data.value === item?.unit)
                      .map((items: any) => {
                        return items.text;
                      })}
              </div>
            ),
            route: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {props.masterOptions?.route
                  .filter((data: any) => data.value === item?.route)
                  .map((items: any) => {
                    return items.text;
                  })}
              </div>
            ),
            site: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {props.masterOptions?.site
                  .filter((data: any) => data.value === item?.site)
                  .map((items: any) => {
                    return items.text;
                  })}
              </div>
            ),
            frequency: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {props.masterOptions?.frequency
                  .filter((data: any) => data.value === item?.frequency)
                  .map((items: any) => {
                    return items.text;
                  })}
              </div>
            ),
            method: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {props.masterOptions?.method
                  .filter((data: any) => data.value === item?.method)
                  .map((items: any) => {
                    return items.text;
                  })}
              </div>
            ),
            quantity: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.quantity}
              </div>
            ),
            dose: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.dose}
              </div>
            ),
            time: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.time}
              </div>
            ),
            nurse: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.nurse}
              </div>
            ),
          };
        }
      ),

    [
      props.DrugResuscitationSequenceOPD,
      props.DrugResuscitationSequenceOPD?.drugAdd,
    ]
  );

  const handleChangeData = (_event: any, data: any) => {
    props.setProp(
      `DrugResuscitationSequenceOPD.drugEdit.${data.name}`,
      data.value
    );
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    props.setProp(`DrugResuscitationSequenceOPD.drugEdit`, {});
  };

  const handleSaveEdit = () => {
    props.runSequence({
      sequence: "DrugResuscitationOPD",
      card: CARD_NAME,
      action: "saveEdit",
      index: editID,
    });
  };

  const handleSetOpitonUnit = (rowInfo: any) => {
    setOpenEdit(true);
    setEditID(rowInfo?.index);

    let opitonUnit: any = [];

    props.masterOptions?.unit.forEach((item: any) => {
      props.DrugResuscitationSequenceOPD?.drugAdd?.[
        rowInfo?.index
      ]?.drugDetail?.limited_dosage_units
        .filter((unit: any) => unit === item.value)
        .map((data: any) => {
          opitonUnit.push(item);
        });
    });

    if (opitonUnit.length === 0) {
      opitonUnit = props.masterOptions?.unit;
    }

    setOpitonUnit(opitonUnit);

    props.setProp(`DrugResuscitationSequenceOPD`, {
      ...props.DrugResuscitationSequenceOPD,
      drugEdit: {
        ...props.DrugResuscitationSequenceOPD?.drugAdd?.[rowInfo?.index],
        employeeCode: null,
      },
      messageEdit: {},
    });
  };

  const handleCloseModError = () => {
    setOpenModError(false);
    props.setProp(`DrugResuscitationSequenceOPD.showDivisionError`, "");
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (
        props.DrugResuscitationSequenceOPD?.drugEdit?.employeeCode ===
        props.providerEmployeeInfo?.employee_info.code
      ) {
        props.setProp(
          `DrugResuscitationSequenceOPD.drugEdit.employeeCode`,
          props.providerEmployeeInfo?.employee_info.full_name
        );
      }
    }
  };

  const handleChangeCodeDrugsBox = (_event: any, data: any) => {
    props.setProp(`DrugResuscitationSequenceOPD.${data.name}`, data.value);
  };

  return (
    <div>
      <ModError
        type="error"
        open={openModError}
        alertText={
          <ErrorMessage
            error={props.DrugResuscitationSequenceOPD?.showDivisionError}
          />
        }
        onClose={handleCloseModError}
        onApprove={handleCloseModError}
      />

      <CardDrugResuscitationOPDUx
        titleDrug={
          <div>
            <Header>บันทึกใช้ยาสำรอง</Header>
            <Divider />
          </div>
        }
        inputDrugCode={
          <>
            <Input
              action={{
                icon: "search",
                onClick: () => handleSearchValue(),
              }}
              style={{ width: "300px", marginRight: "10px" }}
              onChange={handleChangeValue("selectedCode")}
              value={props?.DrugResuscitationSequenceOPD?.selectedCode || ""}
              onKeyPress={(e: any) => {
                if (e.key === "Enter") {
                  handleSearchValue();
                }
              }}
            ></Input>
          </>
        }
        drugCode={props?.DrugResuscitationSequenceOPD?.listItems?.[0]?.code}
        labDrugs={drugListData}
        orderDrugs={drugOrderData}
        onChangeData={handleChangeCodeDrugsBox}
        codeDrugsBox={props?.DrugResuscitationSequenceOPD?.codeDrugsBox}
        codeDrugsBoxOptions={[
          {
            value: 0,
            key: 0,
            text: "Medication Box",
          },
        ]}
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSaveDrug}
            // data
            paramKey={onEdit ? `${CARD_NAME}_edit` : `${CARD_NAME}_pick`}
            buttonLoadCheck={
              onEdit
                ? props.buttonLoadCheck?.[`${CARD_NAME}_edit`]
                : props.buttonLoadCheck?.[`${CARD_NAME}_pick`]
            }
            // config
            color={onEdit ? "yellow" : "green"}
            title={onEdit ? "Edit" : "Save"}
            size="medium"
          />
        }
        oderDrugsColumnProps={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => {
          return {
            style: {
              backgroundColor:
                !(column?.Header === "" || column?.Header === "Product") &&
                props.DrugResuscitationSequenceOPD?.drugAdd?.[
                  rowInfo?.index
                ] !== undefined
                  ? "#FFFFCC"
                  : "#FFFFFF",
            },
            onClick: () => {
              if (
                !(column?.Header === "" || column?.Header === "Product") &&
                props.DrugResuscitationSequenceOPD?.drugAdd?.[
                  rowInfo?.index
                ] !== undefined
              ) {
                handleSetOpitonUnit(rowInfo);
              }
            },
          };
        }}
      />

      <Modal open={openEdit} onClose={handleCloseEdit} size="large">
        {props.DrugResuscitationSequenceOPD?.messageEdit?.open && (
          <div style={{ padding: "10px" }}>
            <Message color="red">
              <Message.Header>กรอกข้อมูลที่จำเป็น</Message.Header>
              {props.DrugResuscitationSequenceOPD?.messageEdit?.quantity && (
                <p>Quantity</p>
              )}
              {props.DrugResuscitationSequenceOPD?.messageEdit?.dose && (
                <p>Dose</p>
              )}
              {props.DrugResuscitationSequenceOPD?.messageEdit?.time && (
                <p>Time</p>
              )}
              {props.DrugResuscitationSequenceOPD?.messageEdit
                ?.employeeCode && <p>Nurse</p>}
            </Message>
          </div>
        )}
        <CardDrugResuscitationEditUX
          editData={props.DrugResuscitationSequenceOPD?.drugEdit || null}
          masterOptions={props.masterOptions}
          onChangeData={handleChangeData}
          onCancel={handleCloseEdit}
          onSave={handleSaveEdit}
          unitOptions={opitonUnit}
          time={
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <TimeFreeTextBox24
                className="time-custom-react-picker"
                onChange={(time: any) =>
                  props.setProp(
                    `DrugResuscitationSequenceOPD.drugEdit.time`,
                    time
                  )
                }
                value={props.DrugResuscitationSequenceOPD?.drugEdit?.time || ""}
                iconClockStyle={{ margin: "auto 30px" }}
                autoFocus={false}
                checkTimeOverflow={true}
              ></TimeFreeTextBox24>
            </div>
          }
          onSaveEdit={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSaveEdit}
              // data
              paramKey={`${CARD_NAME}_saveEdit`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_saveEdit`]}
              // config
              color={"green"}
              title={"Save"}
              size="medium"
            />
          }
          handleKeyDown={handleKeyDown}
        />
      </Modal>

      <ModInfo
        open={openDrugNotify}
        titleColor={"red"}
        titleName={"แจ้งเตือน"}
        alertText={"ต้องมีรายการในใบยาอย่างน้อย 1 รายการ"}
        onApprove={() => setOpenDrugNotify(false)}
        onClose={() => setOpenDrugNotify(false)}
        closeOnDimmerClick
        style={{ margin: "auto" }}
      />

      {props.DrugResuscitationSequenceOPD?.estimate && (
        <CardDrugClaimQA
          stateKey="drugResuscitation"
          estimate={props.DrugResuscitationSequenceOPD?.estimate || {}}
          orderType={"HOME_OPD"}
          selectedEncounter={props.selectedEncounter}
          selectedEmr={props.selectedEmr}
          selectedProgressCycle={props.selectedProgressCycle}
          onEvent={props.onEvent}
          onCloseQA={() => {
            props.setProp("DrugResuscitationSequenceOPD.estimate", {});
          }}
          onSaveQA={(e: any) =>
            handleSaveQA(e, props.DrugResuscitationSequenceOPD?.estimate?.index)
          }
        />
      )}
    </div>
  );
};

CardDrugResuscitationOPD.defaultProps = CardDrugResuscitationOPDInitial;

export default React.memo(CardDrugResuscitationOPD);
