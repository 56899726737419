import React, { useMemo, useCallback, useState } from "react";
import {
  Checkbox,
  FormField,
  FormGroup,
  Icon,
  Image,
  Input,
  Label,
  Modal,
  Radio,
} from "semantic-ui-react";

// Types
type SubDischargePlanProps = {
  setProp: any;
  // data
  clinicalTermPlanning?: any;
  showStatus?: boolean;
  // options
  masterOptions?: any;
};

const SubDischargePlan = (props: SubDischargePlanProps) => {
  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`ClinicalTermPlanning.${data.name}`, data.checked);
    } else {
      props.setProp(`ClinicalTermPlanning.${data.name}`, data.value);
    }
  };

  return (
    <div className="ui form" style={{ paddingTop: "10px" }}>
      {Object.keys(props.clinicalTermPlanning || {})
        .filter((item: any) => ["disc_plan1", "disc_plan2"].includes(item))
        .map((item: any, number: number) => {
          return (
            <>
              <FormGroup>
                <FormField inline={true} width={5}>
                  <div style={{ fontWeight: "bold" }}>
                    {`${number + 1}.  ${props.clinicalTermPlanning?.[item]?.name}`}
                  </div>
                </FormField>
                <FormField inline={true} width={11}>
                  <div>
                    <Radio
                      checked={props.clinicalTermPlanning?.[item].has_problem === "N"}
                      label="ไม่มีปัญหา"
                      name={`${item}.has_problem`}
                      onChange={handleChangeData}
                      style={{ marginRight: "20px" }}
                      value="N"
                    ></Radio>
                    <Radio
                      checked={props.clinicalTermPlanning?.[item].has_problem === "Y"}
                      label="มีปัญหา"
                      name={`${item}.has_problem`}
                      onChange={handleChangeData}
                      style={{ marginRight: "20px" }}
                      value="Y"
                    ></Radio>
                  </div>
                </FormField>
              </FormGroup>

              {/* disc_plan1 */}
              {item === "disc_plan1" &&
                props.clinicalTermPlanning?.[item]?.items.map((data: any, index: number) => {
                  return (
                    <FormGroup>
                      <FormField inline="true" width={6}>
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            checked={data?.checked}
                            onChange={handleChangeData}
                            style={{ marginTop: "5px" }}
                            name={`${item}.items.${index}.checked`}
                          ></Checkbox>
                          <div style={{ marginTop: "3px", marginLeft: "10px" }}>
                            {`${index + 1}. ${data?.name}`}
                          </div>
                        </div>
                      </FormField>
                      <FormField
                        inline={true}
                        width={1}
                        style={{ ...(!data?.has_description && { display: "none" }) }}
                      >
                        <div>ระบุ</div>
                      </FormField>
                      <FormField
                        inline={true}
                        width={6}
                        style={{ ...(!data?.has_description && { display: "none" }) }}
                      >
                        <Input
                          fluid={true}
                          style={{ width: "100%" }}
                          type="text"
                          value={data?.answer}
                          onChange={handleChangeData}
                          name={`${item}.items.${index}.answer`}
                        ></Input>
                      </FormField>
                    </FormGroup>
                  );
                })}

              {/* disc_plan2 */}
              {item === "disc_plan2" &&
                props.clinicalTermPlanning?.[item]?.items.map((data: any, index: number) => {
                  if ((index + 1) % 2 === 1) {
                    return (
                      <FormGroup>
                        <FormField inline={true} width={3}>
                          <div style={{ display: "flex" }}>
                            <Checkbox
                              checked={props.clinicalTermPlanning?.[item]?.items?.[index]?.checked}
                              style={{ marginTop: "5px" }}
                              onChange={handleChangeData}
                              name={`${item}.items.${index}.checked`}
                            ></Checkbox>
                            <div style={{ marginTop: "3px", marginLeft: "10px" }}>
                              {`${index + 1}. ${
                                props.clinicalTermPlanning?.[item]?.items?.[index]?.name
                              }`}
                            </div>
                          </div>
                        </FormField>
                        <FormField inline={true} width={3}>
                          <div
                            style={{
                              display: "flex",
                              gap: "2rem",
                              ...(!props.clinicalTermPlanning?.[item]?.items?.[index]
                                ?.has_description && { display: "none" }),
                            }}
                          >
                            <div>ระบุ</div>
                            <Input
                              value={props.clinicalTermPlanning?.[item]?.items?.[index]?.answer}
                              onChange={handleChangeData}
                              name={`${item}.items.${index}.answer`}
                            ></Input>
                          </div>
                        </FormField>
                        <FormField inline={true} width={1}></FormField>
                        <FormField
                          inline={true}
                          width={3}
                          style={{
                            ...(!props.clinicalTermPlanning?.[item]?.items?.[index + 1]?.name && {
                              display: "none",
                            }),
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <Checkbox
                              checked={
                                props.clinicalTermPlanning?.[item]?.items?.[index + 1]?.checked
                              }
                              style={{ marginTop: "5px" }}
                              onChange={handleChangeData}
                              name={`${item}.items.${index + 1}.checked`}
                            ></Checkbox>
                            <div style={{ marginTop: "3px", marginLeft: "10px" }}>
                              {`${index + 2}. ${
                                props.clinicalTermPlanning?.[item]?.items?.[index + 1]?.name
                              }`}
                            </div>
                          </div>
                        </FormField>
                        <FormField
                          inline={true}
                          width={3}
                          style={{
                            ...(!props.clinicalTermPlanning?.[item]?.items?.[index + 1]?.name && {
                              display: "none",
                            }),
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "2rem",
                              ...(!props.clinicalTermPlanning?.[item]?.items?.[index + 1]
                                ?.has_description && { display: "none" }),
                            }}
                          >
                            <div>ระบุ</div>
                            <Input
                              value={props.clinicalTermPlanning?.[item]?.items?.[index + 1]?.answer}
                              onChange={handleChangeData}
                              name={`${item}.items.${index + 1}.answer`}
                            ></Input>
                          </div>
                        </FormField>
                      </FormGroup>
                    );
                  }
                })}
            </>
          );
        })}
    </div>
  );
};

SubDischargePlan.displayName = "SubDischargePlan";

export default React.memo(SubDischargePlan);
