import React, { useState, useMemo, useRef, useEffect } from "react";
import { Button, Dimmer, Loader, Modal, SemanticCOLORS } from "semantic-ui-react";

// UX
import ORCheckoutComponent from "./ORCheckoutComponent";
import ORDischargeComponent from "./ORDischargeComponent";
import ORCancelDischargeComponent from "./ORCancelDischargeComponent";
import ModInfo from "react-lib/apps/common/ModInfo";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import DischargeForm from "./DischargeForm";
// DPO
import CardDischargeReact from "react-lib/apps/DPO/CardDischarge";

// Config
import CONFIG from "config/config";

// Types
type PatientStatusActionButtonProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: (e: any) => any;
  // controller
  cardDischargeController: any;
  // data
  isStudentUser?: boolean;
  checkApprove?: Record<string, any>;
  patientData?: Record<string, any>;
  loginVia?: boolean;
  dischargeDoctor?: Record<string, any>;
  approve_by?: number;
  medReconcileIndex?: any;
  selectedShippingOrder?: Record<string, any>[];
  // CommonInterface
  errorMessage?: Record<string, any>;
  orDischargeResult?: Record<string, any>;
  selectedEncounter?: Record<string, any>;
  // selectedProgressCycle?: Record<string, any>;
  selectedAppointment?: Record<string, any>;
  selectedPatient?: Record<string, any>;
  selectedAdmitOrderRoomItem?: Record<string, any>;
  selectedMainOrOrder?: Record<string, any>;
  selectedEmr?: Record<string, any>;
  loadingStatus?: Record<string, any>;
  patientEmr?: Record<string, any>;
  django?: {
    csrf: string;
    authenticated: true;
    user: {
      id: number;
      full_name: string;
      role_types: string[];
      token: string;
    };
    division: number;
  };
  // options
  masterOptions?: Record<string, any[]>;
};

type ValueOf<T> = T[keyof T];

const ALLOWED_ACTIONS = {
  OPD_CANCEL_QUEUE_EXAM: "OPD_CANCEL_QUEUE_EXAM",
  OPD_QUEUE_EXAM: "OPD_QUEUE_EXAM",
  OPD_DISCHARGE: "OPD_DISCHARGE",
  OPD_UNDISCHARGE: "OPD_UNDISCHARGE",
  OPD_CANCEL_CHECK_IN: "OPD_CANCEL_CHECK_IN",
  OPD_CHECK_OUT: "OPD_CHECK_OUT",
  CANCEL_CHECKOUT: "CANCEL_CHECKOUT",
  IPD_CANCEL_CHECK_OUT: "IPD_CANCEL_CHECK_OUT",
  IPD_CHECK_OUT: "IPD_CHECK_OUT",
  IPD_CANCEL_CASHIER_REQUEST: "IPD_CANCEL_CASHIER_REQUEST",
} as const;

const BUTTON_ACTIONS: Record<string, string> = {
  [ALLOWED_ACTIONS.OPD_CANCEL_QUEUE_EXAM]: "นำออกจากคิวรอตรวจ",
  [ALLOWED_ACTIONS.OPD_QUEUE_EXAM]: "ส่งผู้ป่วยเข้าคิวรอตรวจ",
  [ALLOWED_ACTIONS.OPD_DISCHARGE]: "OPD Discharge",
  [ALLOWED_ACTIONS.OPD_UNDISCHARGE]: "ยกเลิก discharge",
  [ALLOWED_ACTIONS.OPD_CANCEL_CHECK_IN]: "ยกเลิกการรับเข้าตรวจ",
  [ALLOWED_ACTIONS.OPD_CHECK_OUT]: "Checkout",
  [ALLOWED_ACTIONS.CANCEL_CHECKOUT]: "ยกเลิก checkout",
  [ALLOWED_ACTIONS.IPD_CANCEL_CHECK_OUT]: "ยกเลิก discharge",
  [ALLOWED_ACTIONS.IPD_CHECK_OUT]: "IPD Checkout",
  [ALLOWED_ACTIONS.IPD_CANCEL_CASHIER_REQUEST]: "ยกเลิก Request Discharge",
} as const;

const DISCHARGE_STATUS = {
  PLAN_DISCHARGE: "Plan Discharged",
  DOCTOR_DISCHARGE: "หมอมีคำสั่งให้ Discharge",
  CASHIER_DISCHARGED: "Cashier Discharged",
};

const BUTTON_DISCHARGE: Record<string, string> = {
  [DISCHARGE_STATUS.PLAN_DISCHARGE]: "Discharge",
  [DISCHARGE_STATUS.DOCTOR_DISCHARGE]: "Request discharge", // พยาบาลอย่างเดียว
  [DISCHARGE_STATUS.CASHIER_DISCHARGED]: "Ward Discharge",
} as const;

const CARD_PATIENT_DISCHARGE = "CardPatientDischarge";
const CARD_PATIENT_PANEL = "CardPatientPanel";

// OPD
// กำลังมาถึง + traige
//  - แพทย์: ไม่มีปุ่ม
//  - พยาบาล: ไม่มีปุ่ม
// ซักประวัติแล้ว
//  - แพทย์: ไม่มีปุ่ม
//  - พยาบาล: ส่งเข้าคิวรอตรวจ, OPD Discharge
//  รอพบแพทย์
//  - แพทย์: ไม่มีปุ่ม
//  - พยาบาล: นำออกจากคิวรอตรวจ
//  กำลังพบแพทย์ (เกิดเมื่อเข้ามาหน้า patient info)
//  - แพทย์: ยกเลิกการรับเข้าตรวจ
//  - พยาบาล: ไม่มีปุ่ม
// กำลังพบแพทย์ + บันทึก patient info
//  - แพทย์: Checkout
//  - พยาบาล: ไม่มีปุ่ม
// ออกจากห้องตรวจ
//  - แพทย์: ยกเลิก checkout <- ยิงอะไรหรอ กลับไปที่ กำลังพบแพทย์ (x), รอผลตรวจ , ได้รับผลตรวจ
//  - พยาบาล: OPD Discharge
// รอผลตรวจ|ได้รับผลตรวจ
//  - แพทย์: ไม่มีปุ่ม
//  - พยาบาล: ส่งเข้าคิวรอตรวจ
// รอพบแพทย์
// - แพทย์: กดคิว + มี patient info -> Update status IN_EXAM
// - พยาบาล: ไม่มีการ Update

// IPD
// Ward Admitted (encounter : Ward Admitted, orderRoomItem.status_labe = "Ward Admitted")
//  - แพทย์: ไม่มีปุ่ม
//  - พยาบาล: ไม่มีปุ่ม

// Plan Discharged (encounter : Ward Admitted, orderRoomItem.status_label = "Plan Discharged")
//  - แพทย์: Discharge
//  - พยาบาล: ไม่มีปุ่ม

// หมอมีคำสั่งให้ Discharge (encounter : หมอมีคำสั่งให้ Discharge, orderRoomItem.status_label = "หมอมีคำสั่งให้ Discharge")
//  - แพทย์: ยกเลิก discharge
//  - พยาบาล: Request discharge

// Cashier Request (encounter : Cashier Request, orderRoomItem.status_label = "Cashier Request")
//  - แพทย์: ไม่มีปุ่ม
//  - พยาบาล: ยกเลิก Request discharge

// Cashier Discharged (encounter : Cashier Discharged, orderRoomItem.status_label = "Cashier Discharged")
//  - แพทย์: Ward Discharge
//  - พยาบาล: ไม่มีปุ่ม

const PatientStatusActionButton = (props: PatientStatusActionButtonProps) => {
  const [openModORCheckout, setOpenModORCheckout] = useState<boolean>(false);
  const [openModORDischarge, setOpenModORDischarge] = useState<boolean>(false);
  const [openModORCancelDischarge, setOpenModORCancelDischarge] = useState<boolean>(false);
  const [openModErr, setOpenModErr] = useState<boolean>(false);

  const [openModDischarge, setOpenModDischarge] = useState<{
    type: any;
  } | null>(null);

  const [openModCancelDischarge, setOpenModCancelDischarge] = useState<{
    type: any;
  } | null>(null);

  const [openModInfo, setOpenModInfo] = useState<any>(null);
  const [openModCheckout, setOpenModCheckout] = useState<any>(null);

  const [approveButtonLoading, setApproveButtonLoading] = useState<boolean>(false);

  const doctorRef = useRef();

  useEffect(() => {
    if (props.isStudentUser) {
      if (props.checkApprove?.can_checkout) {
        setOpenModDischarge({ type: props.checkApprove?.action });
      } else if (props.checkApprove?.action !== "") {
        setOpenModErr(true);
      }
    }
  }, [props.checkApprove]);

  // Memo
  const allowedActions = useMemo(() => {
    const actions: string[] = props.selectedEncounter?.allowed_actions || [];

    if (actions.includes(ALLOWED_ACTIONS.OPD_QUEUE_EXAM)) {
      actions.push(ALLOWED_ACTIONS.CANCEL_CHECKOUT);
    }

    return actions;
  }, [props.selectedEncounter]);

  const statusLabel = useMemo(() => {
    return props.selectedAdmitOrderRoomItem?.status_label;
  }, [props.selectedAdmitOrderRoomItem, props.selectedEncounter]);

  const buttonActions = useMemo(() => {
    const isDoctor =
      props.django?.user?.role_types?.includes("DOCTOR") || CONFIG.RAKSTHAI_ENCOUNTER_STATUS;
    const isNurse = props.django?.user?.role_types?.includes("REGISTERED_NURSE");

    const encounterStatus = props.selectedEncounter?.status || "";

    const isCheckout =
      isDoctor &&
      !allowedActions.includes(ALLOWED_ACTIONS.OPD_QUEUE_EXAM) &&
      !props.selectedMainOrOrder &&
      props.patientEmr?.patient_illness;

    const isOPDCancelCheckout =
      isDoctor &&
      allowedActions.includes(ALLOWED_ACTIONS.OPD_CHECK_OUT) &&
      !["WAIT_RESULT", "RECEIVE_RESULT"].includes(encounterStatus);

    const isOPDDischarge =
      props.selectedAppointment?.type_display !== "นัดหมายผ่าตัด" &&
      ["CHECKED_OUT", "SCREENED"].includes(encounterStatus) &&
      isNurse;

    const isOPDCancelDischarge =
      isNurse && allowedActions.includes(ALLOWED_ACTIONS.OPD_UNDISCHARGE);

    const isOPDQueueExam =
      ["SCREENED", "WAIT_RESULT", "RECEIVE_RESULT"].includes(encounterStatus) && isNurse;
    const isOPDCancelCheckIn = isDoctor && !props.patientEmr?.patient_illness;
    const isOPDCancelQueueExam = isNurse;
    const isIPDCancelCheckout =
      isDoctor && allowedActions.includes(ALLOWED_ACTIONS.IPD_CANCEL_CHECK_OUT);
    const isIPDCancelCashierRequest =
      isNurse && allowedActions.includes(ALLOWED_ACTIONS.IPD_CANCEL_CASHIER_REQUEST);

    return [
      isOPDCancelQueueExam && {
        action: ALLOWED_ACTIONS.OPD_CANCEL_QUEUE_EXAM,
        color: "purple",
      },
      isOPDCancelCheckIn && {
        action: ALLOWED_ACTIONS.OPD_CANCEL_CHECK_IN,
        color: "brown",
      },
      isOPDQueueExam && {
        action: ALLOWED_ACTIONS.OPD_QUEUE_EXAM,
        color: "orange",
      },
      isOPDDischarge && {
        action: ALLOWED_ACTIONS.OPD_DISCHARGE,
        color: "blue",
      },
      isOPDCancelDischarge && {
        action: ALLOWED_ACTIONS.OPD_UNDISCHARGE,
        color: "brown",
      },
      isCheckout && {
        action: ALLOWED_ACTIONS.OPD_CHECK_OUT,
        color: "blue",
      },
      isOPDCancelCheckout && {
        action: ALLOWED_ACTIONS.CANCEL_CHECKOUT,
        color: "orange",
      },
      isIPDCancelCheckout && {
        action: ALLOWED_ACTIONS.IPD_CANCEL_CHECK_OUT,
        color: "orange",
      },
      isIPDCancelCashierRequest && {
        action: ALLOWED_ACTIONS.IPD_CANCEL_CASHIER_REQUEST,
        color: "black",
      },
    ].filter(Boolean) as { action: ValueOf<typeof ALLOWED_ACTIONS>; color: SemanticCOLORS }[];
  }, [
    allowedActions,
    props.django,
    props.selectedAppointment,
    props.selectedMainOrOrder,
    props.selectedEncounter,
    props.selectedEmr,
    props.patientEmr,
  ]);

  const buttonActionDischarge = useMemo(() => {
    const isDoctor = props.django?.user?.role_types?.includes("DOCTOR");
    const isNurse = props.django?.user?.role_types?.includes("REGISTERED_NURSE");

    return [
      isDoctor && {
        action: DISCHARGE_STATUS.PLAN_DISCHARGE,
        color: "blue",
      },
      isNurse && {
        action: DISCHARGE_STATUS.DOCTOR_DISCHARGE,
        color: "blue",
      },
      isNurse && {
        action: DISCHARGE_STATUS.CASHIER_DISCHARGED,
        color: "blue",
      },
    ].find((item) => item && item.action === statusLabel) as
      | { action: string; color: SemanticCOLORS }
      | undefined;
  }, [props.django, statusLabel]);

  const cardDischargeController = useMemo(() => {
    const specificTypes: string[] = props.selectedEncounter?.doctor_orders?.map(
      (item: any) => item.specific_type
    );

    const isWaitResult = (specificTypes || [])?.some((type) =>
      ["imagingorder", "treatmentorder", "centrallaborder"].includes(type)
    );

    return {
      ...props.cardDischargeController,
      hasProgressionCycle: isWaitResult,
      discharge: (
        emr_id: number,
        checkout_cause: any,
        division: number,
        require_diagnosis: boolean,
        approve_by: any
      ) => {
        return handleCheckout({
          emr_id,
          checkout_cause,
          require_diagnosis,
          approve_by,
        });
      },
    };
  }, [props.cardDischargeController, props.selectedEncounter, openModDischarge]);

  // Handler
  const handleAction = (type = "") => {
    type = openModDischarge?.type || type;
    type = type === ALLOWED_ACTIONS.CANCEL_CHECKOUT ? ALLOWED_ACTIONS.OPD_QUEUE_EXAM : type;

    setApproveButtonLoading(true);

    props.runSequence({
      sequence: "Assessment",
      action: type,
      card: CARD_PATIENT_DISCHARGE,
      patientId: props.selectedPatient?.id,
      onSuccess: (message: any) => {
        setApproveButtonLoading(false);

        if (message) {
          setOpenModInfo({ type: "success", message });
        } else {
          handleCloseModDischarge();
        }
      },
      onFailed: (message: any) => {
        setOpenModInfo({ type: "error", message });
      },
    });
  };

  const handleCloseModDischarge = (message: any = {}) => {
    setOpenModDischarge(null);
    setOpenModCancelDischarge(null);
    setOpenModInfo(null);
    setOpenModCheckout(null);

    setApproveButtonLoading(false);

    props.setProp(`errorMessage.${CARD_PATIENT_DISCHARGE}`, null);

    props.setProp("dischargeDoctor", {
      userId: null,
      username: "",
      password: "",
    });

    if (message?.back) {
      handleComplete();
    }
  };

  const handleCloseModCancelDischargeIPD = () => {
    setApproveButtonLoading(false);
    setOpenModCancelDischarge(null);
  };

  const handleCheckout = (params: any = {}) => {
    setApproveButtonLoading(true);

    return props.onEvent({
      message: "HandleSaveMedicalRecordDischarge",
      params: {
        ...params,
        encounter: props.selectedEncounter?.id,
        admitRoom: props.selectedAdmitOrderRoomItem,
        type: openModDischarge?.type,
        card: CARD_PATIENT_DISCHARGE,
        patientId: props.selectedPatient?.id,
        onSuccess: handleCloseModDischarge,
      },
    });
  };

  const handleCancelDischargeIPD = (params: any = {}) => {
    setApproveButtonLoading(true);

    return props.onEvent({
      message: "HandleSaveMedicalRecordDischarge",
      params: {
        ...params,
        encounter: props.selectedEncounter?.id,
        admitRoom: props.selectedAdmitOrderRoomItem,
        type: openModCancelDischarge?.type,
        card: CARD_PATIENT_DISCHARGE,
        patientId: props.selectedPatient?.id,
        onSuccess: handleCloseModCancelDischargeIPD,
      },
    });
  };

  const handleComplete = () => {
    const backElm = document.querySelector(".patient-layout-backward") as HTMLDivElement;

    if (backElm) {
      backElm.click();
    }
  };

  const handleCheckApprove = (action: any) => {
    if (props.isStudentUser) {
      props.onEvent({
        message: "HandleCheckApprove",
        params: { action: action },
      });
    } else {
      setOpenModDischarge({ type: action });
    }
  };

  const handleClearCheckApprove = () => {
    setOpenModErr(false);
    props.setProp("checkApprove", {
      can_checkout: false,
      action: "",
    });
  };

  const handleOpenModDischarge = () => {
    setOpenModDischarge({ type: BUTTON_DISCHARGE[statusLabel] });
  };

  const handleConfirmCheckout = async () => {
    if (CONFIG.RAKSTHAI_ENCOUNTER_STATUS) {
      await props.onEvent({
        message: "UpdateEncounterStatus",
        params: {
          id: props.selectedEncounter?.id,
          emrId: props.selectedEmr?.id,
          current: props.selectedEncounter?.status,
          target: openModCheckout.type,
          refresh: true,
        },
      });

      setOpenModCheckout(null);
    } else {
      handleAction();
    }
  };

  // console.log("PatientStatusActionButton props.selectedEncounter?.id ", props.selectedEncounter?.id)
  // console.log("PatientStatusActionButton props.selectedEncounter ", props.selectedEncounter)
  // console.log("PatientStatusActionButton allowedActions", allowedActions)
  // console.log('PatientStatusActionButton props.selectedOrOrder: ', props.selectedOrOrder);
  // console.log('PatientStatusActionButton props.selectedEmr?.checkout_cause: ', props.selectedEmr?.checkout_cause);
  // console.log('PatientStatusActionButton props.selectedEmr: ', props.selectedEmr);
  // console.log('PatientStatusActionButton props.selectedAdmitOrderRoomItem: ', props.selectedAdmitOrderRoomItem);
  // console.log('PatientStatusActionButton props.django?.user?.role_types: ', props.django?.user?.role_types);

  return (
    <>
      {props.selectedMainOrOrder &&
        ["OPERATION", "HOLDING_ROOM", "OPERATING_ROOM", "PACU"].includes(
          props.selectedMainOrOrder?.status_name
        ) &&
        props.django?.user?.role_types?.includes("DOCTOR") && (
          <Button color="red" size="mini" onClick={() => setOpenModORCheckout(true)}>
            Check out
          </Button>
        )}

      {props.selectedMainOrOrder &&
        props.selectedEmr?.checkout_cause &&
        ["HOLDING_ROOM", "OPERATING_ROOM", "OPERATION", "PACU"].includes(
          props.selectedMainOrOrder?.status_name
        ) &&
        props.django?.user?.role_types?.includes("REGISTERED_NURSE") && (
          <Button color="black" size="mini" onClick={() => setOpenModORDischarge(true)}>
            Discharge
          </Button>
        )}

      {props.selectedMainOrOrder &&
        props.selectedMainOrOrder?.status_name === "COMPLETED" &&
        !props.selectedMainOrOrder?.is_paid && (
          <Button color="red" size="mini" onClick={() => setOpenModORCancelDischarge(true)}>
            ยกเลิก Discharge
          </Button>
        )}

      {props.selectedEncounter?.id &&
        buttonActions.map(
          (item, index) =>
            [
              ...allowedActions,
              // "OPD_CANCEL_CHECK_IN",
              // "OPD_CHECK_OUT",
            ]?.includes(item.action) && (
              <Button
                key={`action-${item.action}`}
                id={`action-${item.action}`}
                size="tiny"
                color={item.color as any}
                style={{
                  ...(ALLOWED_ACTIONS.OPD_DISCHARGE === item.action
                    ? { fontSize: "0.75rem" }
                    : {
                        fontSize: "0.75rem",
                        padding: "0.65rem 0.75rem",
                      }),
                  margin: "0.2rem",
                  minWidth: "8.75rem",
                }}
                onClick={() => {
                  if (ALLOWED_ACTIONS.OPD_CANCEL_CHECK_IN === item.action) {
                    handleAction(item.action);
                  } else if (CONFIG.RAKSTHAI_ENCOUNTER_STATUS && ALLOWED_ACTIONS.OPD_CHECK_OUT) {
                    setOpenModCheckout({ type: item.action });
                  } else if (
                    !CONFIG.CUDENT_CANCEL_CHECKOUT_SUPERVISOR &&
                    item.action === ALLOWED_ACTIONS.CANCEL_CHECKOUT
                  ) {
                    setOpenModCheckout({ type: item.action });
                    setOpenModDischarge({ type: item.action });
                  } else if (
                    [
                      ALLOWED_ACTIONS.IPD_CANCEL_CASHIER_REQUEST,
                      ALLOWED_ACTIONS.IPD_CANCEL_CHECK_OUT,
                    ].includes(item.action as any)
                  ) {
                    setOpenModCancelDischarge({ type: item.action });
                  } else if (ALLOWED_ACTIONS.OPD_UNDISCHARGE === item.action) {
                    handleAction(item.action);
                  } else {
                    handleCheckApprove(item.action);
                  }
                }}
              >
                {BUTTON_ACTIONS[item.action]}
              </Button>
            )
        )}

      {!!buttonActionDischarge && (
        <Button
          color={buttonActionDischarge.color}
          size="mini"
          style={{ margin: "0.2rem 0", paddingLeft: "0.75rem", paddingRight: "0.75rem" }}
          onClick={handleOpenModDischarge}
        >
          {BUTTON_DISCHARGE[statusLabel]}
        </Button>
      )}

      {props?.selectedShippingOrder?.[0]?.tele_pharmacy_status === "REQUESTED" && (
        <Button
          color="teal"
          size="mini"
          style={{ margin: "0.2rem 0" }}
          onClick={() => {
            props.onEvent({
              message: "HandleDischargeConsult",
              params: {
                patientAppointment: props?.selectedShippingOrder?.[0],
                card: CARD_PATIENT_PANEL,
              },
            });
          }}
        >
          จบการให้คำแนะนำ
        </Button>
      )}

      {openModORCheckout && (
        <ORCheckoutComponent
          onEvent={props.onEvent}
          // data
          open={openModORCheckout}
          selectedOrOrder={props.selectedMainOrOrder}
          // options
          masterOptions={props.masterOptions}
          // callback
          onClose={() => setOpenModORCheckout(false)}
        />
      )}

      {openModORDischarge && (
        <ORDischargeComponent
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          open={openModORDischarge}
          selectedOrOrder={props.selectedMainOrOrder}
          orDischargeResult={props.orDischargeResult}
          errorMessage={props.errorMessage}
          // options
          masterOptions={props.masterOptions}
          // callback
          onClose={() => setOpenModORDischarge(false)}
        />
      )}

      {openModORCancelDischarge && (
        <ORCancelDischargeComponent
          onEvent={props.onEvent}
          // data
          open={openModORCancelDischarge}
          selectedOrOrder={props.selectedMainOrOrder}
          onClose={() => setOpenModORCancelDischarge(false)}
        />
      )}

      <ModInfo
        open={!!openModInfo}
        titleName={openModInfo?.type === "success" ? "สำเร็จ" : ""}
        titleColor={openModInfo?.type === "success" ? "green" : "red"}
        onApprove={handleCloseModDischarge}
      >
        {/* <div>{openModInfo?.message}</div> */}
      </ModInfo>

      <Modal
        open={
          !!openModDischarge &&
          (statusLabel === DISCHARGE_STATUS.PLAN_DISCHARGE ||
            openModDischarge.type === ALLOWED_ACTIONS.OPD_CHECK_OUT)
        }
        size={CONFIG.CUDENT_ENCOUNTER_STUDENT ? undefined : "small"}
      >
        <CardDischargeReact
          setProp={props.setProp}
          onEvent={props.onEvent}
          doctorOptions={{ items: [] }}
          userId={props.django?.user?.id}
          dischargeDoctor={props.dischargeDoctor}
          controller={cardDischargeController}
          DJANGO={props.django}
          PATIENT_DATA={props.patientData}
          // config
          hideSupervisorPassword={!CONFIG.CUDENT_ENCOUNTER_STUDENT}
          isCUDent={CONFIG.CUDENT_ENCOUNTER_STUDENT}
          checkMedicalHistory={CONFIG.CUDENT_ENCOUNTER_STUDENT}
          encounterInfo={
            props.selectedEncounter && props.selectedEncounter?.approve_by === null
              ? {
                  ...props.selectedEncounter,
                  approve_by: props.selectedEncounter.doctor,
                  approve_by_name: props.selectedEncounter.doctor_name,
                }
              : props.selectedEncounter
          }
          doctorRef={doctorRef}
          errorMessage={props.errorMessage?.[CARD_PATIENT_DISCHARGE]}
          loginVia={props.loginVia}
          approve_by={props.approve_by}
          hideZone={true}
          // callback
          onDischarged={async () => {
            props.setProp("dischargeDoctor", null);
          }}
          onClose={handleCloseModDischarge}
          medReconcileIndex={props.medReconcileIndex}
        />
      </Modal>

      <ModConfirm
        openModal={!!openModCheckout}
        approveButtonLoading={approveButtonLoading}
        content={
          <div style={{ margin: "0 0 -0.5rem", textAlign: "center" }}>
            ต้องการ “{BUTTON_ACTIONS[openModCheckout?.type]}” หรือไม่
          </div>
        }
        onDeny={() => setOpenModCheckout(null)}
        onApprove={handleConfirmCheckout}
      />

      <ModConfirm
        openModal={!!openModCancelDischarge}
        approveButtonLoading={approveButtonLoading}
        titleName={
          <div style={{ textTransform: "capitalize" }}>{`กรุณายืนยันการ${BUTTON_ACTIONS[
            openModCancelDischarge?.type
          ]?.toLowerCase()}`}</div>
        }
        size="mini"
        denyButtonText="ยกเลิก"
        approveButtonText="ยืนยัน"
        content={
          <div
            style={{ textAlign: "center", margin: "0 0 -1.25rem 0", textTransform: "capitalize" }}
          >
            {`กรุณายืนยันการ${BUTTON_ACTIONS[openModCancelDischarge?.type]?.toLowerCase()}`}
          </div>
        }
        onApprove={() => {
          handleCancelDischargeIPD();
        }}
        onDeny={handleCloseModCancelDischargeIPD}
        titleColor={"blue"}
      />

      <ModConfirm
        openModal={!!openModDischarge && statusLabel === DISCHARGE_STATUS.DOCTOR_DISCHARGE}
        titleName="กรุณายืนยันการ Request Discharge"
        size="mini"
        denyButtonText="ยกเลิก"
        approveButtonText="ยืนยัน"
        approveButtonLoading={approveButtonLoading}
        onApprove={() => {
          handleCheckout();
        }}
        content={
          <div style={{ margin: "-1rem 0" }}>
            <ErrorMessage error={props.errorMessage?.[CARD_PATIENT_DISCHARGE]} />
            ระบบจะทำการ Off รายการ Continue ทั้งหมด ก่อนส่งรายการค่าใช้จ่ายไปการเงิน
          </div>
        }
        onDeny={handleCloseModDischarge}
        titleColor={"blue"}
      />

      <ModConfirm
        openModal={
          !!openModDischarge &&
          [ALLOWED_ACTIONS.OPD_CANCEL_QUEUE_EXAM, ALLOWED_ACTIONS.OPD_QUEUE_EXAM].includes(
            openModDischarge?.type || ""
          )
        }
        titleName="แจ้งเตือน!!!"
        size="mini"
        denyButtonText="ไม่"
        approveButtonText="ใช่"
        onApprove={() => {
          handleAction();
        }}
        content={
          <div style={{ margin: "-1rem 0 -2rem" }}>
            <ErrorMessage error={props.errorMessage?.[CARD_PATIENT_DISCHARGE]} />
            <Dimmer active={props.loadingStatus?.[CARD_PATIENT_DISCHARGE]} inverted>
              <Loader inverted></Loader>
            </Dimmer>
            {ALLOWED_ACTIONS.OPD_CANCEL_QUEUE_EXAM === openModDischarge?.type && (
              <>ต้องการนำผู้ป่วย {props.selectedPatient?.full_name || ""} ออกจากคิวรอตรวจหรือไม่</>
            )}
            {ALLOWED_ACTIONS.OPD_QUEUE_EXAM === openModDischarge?.type && (
              <>ส่งผู้ป่วย {props.selectedPatient?.full_name || ""} เข้าคิวรอตรวจ</>
            )}
          </div>
        }
        onDeny={handleCloseModDischarge}
        titleColor={"blue"}
      />

      <Modal
        open={
          !!openModDischarge &&
          (statusLabel === DISCHARGE_STATUS.CASHIER_DISCHARGED ||
            openModDischarge?.type === "OPD_DISCHARGE")
        }
        onClose={handleCloseModDischarge}
        closeOnDimmerClick
      >
        <DischargeForm
          patientCondition={props.masterOptions?.patientCondition || []}
          patientDischarge={props.masterOptions?.patientDischarge || []}
          cleaningChoice={props.masterOptions?.cleaningChoice || []}
          onSave={handleCheckout}
          errorMessage={props.errorMessage?.[CARD_PATIENT_DISCHARGE]}
          loading={props.loadingStatus?.[CARD_PATIENT_DISCHARGE]}
          showCleaning={statusLabel === DISCHARGE_STATUS.CASHIER_DISCHARGED}
          onComplete={handleComplete}
        />
      </Modal>

      <ModConfirm
        openModal={
          !!openModDischarge &&
          openModDischarge?.type === ALLOWED_ACTIONS.CANCEL_CHECKOUT &&
          CONFIG.CUDENT_CANCEL_CHECKOUT_SUPERVISOR
        }
        // titleName="แจ้งเตือน"
        size="mini"
        denyButtonText="ยกเลิก"
        approveButtonText="ยืนยัน"
        approveButtonLoading={approveButtonLoading}
        onApprove={() => {
          handleAction();
        }}
        content={
          <div style={{ margin: "-1rem 0", lineHeight: 1.75, textAlign: "center" }}>
            <div>เมื่อยกเลิก checkout supervisor</div>
            <div>จำเป็นต้อง approve ใหม่อีกครั้ง</div>
            <div>คุณต้องการแก้ไขหรือไม่</div>
          </div>
        }
        onDeny={handleCloseModDischarge}
        titleColor={"red"}
      />

      <ModInfo
        titleColor="red"
        titleName="ข้อความแจ้งเตือน"
        btnText="ปิดหน้าต่าง"
        size="mini"
        open={openModErr}
        alertText={
          <div style={{ margin: "10px" }}>
            <div>{`ไม่สามารถทำการ Checkout ได้`}</div>
            <div>{`เนื่องจากยังมีการบันทึกหรือคำสั่งแพทย์`}</div>
            <div>{`ที่ยังไม่ถูกตรวจสอบ (Approve)`}</div>
          </div>
        }
        onApprove={() => handleClearCheckApprove()}
      />
    </>
  );
};

export default PatientStatusActionButton;
