import React, { useMemo, useState } from "react";

// Utils
import { Form, FormGroup, Dropdown } from "semantic-ui-react";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Constant
const PATIENT_TYPE: any[] = [
  { key: "OPD และ IPD", value: "OPD และ IPD", text: "OPD และ IPD" },
  { key: "OPD", value: "OPD", text: "OPD" },
  { key: "IPD", value: "IPD", text: "IPD" },
];

const CardPatientPaidServicesReportFilter = (props: any) => {
  // useState
  const [requiredStartDate, setRequiredStartDate] = useState<boolean>(false);

  // UseMemo
  const coverageOptions = useMemo(() => {
    return [
      { key: "ทุกสิทธิ", value: "ทุกสิทธิ", text: "ทุกสิทธิ" },
      ...(props.masterOptions?.coverage || []),
    ];
  }, [props.masterOptions?.coverage]);

  const handlePreviewButton = () => {
    if (requiredStartDate) {
      alert("กรุณาระบุวันที่รับบริการ");
    } else {
      props.runSequence({
        sequence: "ClinicAnnualReport",
        action: "PREVIEW_REPORT",
        menu: "รายงานผู้ป่วยที่ชำระค่าบริการ",
      });
    }
  };

  const handlePrintButton = () => {
    if (requiredStartDate) {
      alert("กรุณาระบุวันที่รับบริการ");
    } else {
      props.runSequence({
        sequence: "ClinicAnnualReport",
        action: "PRINT_REPORT",
        menu: "รายงานผู้ป่วยที่ชำระค่าบริการ",
      });
    }
  };

  return (
    <Form>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label style={{ display: "flex", alignItems: "center" }}>
          วันที่รับบริการ<span style={{ color: "red" }}>*</span>
        </label>
        <DateTextBox
          onChange={(date: string) => {
            props.setProp(
              `ClinicAnnualReportSequence.patientPaidServicesReport.filterStartDate`,
              date
            );
            if (props.ClinicAnnualReportSequence?.patientPaidServicesReport?.filterStartDate.length < 1) {
              setRequiredStartDate(true);
            } else {
              setRequiredStartDate(false);
            }
          }}
          style={{ width: "11rem" }}
          value={props.ClinicAnnualReportSequence?.patientPaidServicesReport?.filterStartDate || ""}
        ></DateTextBox>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 1.2rem 0rem 0rem",
          }}
        >
          ถึง
        </label>
        <DateTextBox
          onChange={(date: string) =>
            props.setProp(
              `ClinicAnnualReportSequence.patientPaidServicesReport.filterEndDate`,
              date
            )
          }
          style={{ width: "11rem" }}
          value={props.ClinicAnnualReportSequence?.patientPaidServicesReport?.filterEndDate || ""}
        ></DateTextBox>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 1rem 0rem 0rem",
          }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handlePreviewButton}
          paramKey={`ClinicAnnualReport_PREVIEW_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`ClinicAnnualReport_PREVIEW_REPORT`]}
          color="yellow"
          style={{ width: "11rem" }}
          title="Preview"
        />
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 4.5rem 0rem 0rem",
          }}
        >
          สิทธิ
        </label>
        <Dropdown
          value={
            props.ClinicAnnualReportSequence?.patientPaidServicesReport?.coverageSelected || ""
          }
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.patientPaidServicesReport.coverageSelected`,
              data.value
            )
          }
          placeholder="เลือกสิทธิ"
          options={coverageOptions}
          search={true}
          selection={true}
          style={{ minWidth: "15.5rem" }}
        ></Dropdown>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 11.8rem 0rem 0rem",
          }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handlePrintButton}
          paramKey={`ClinicAnnualReport_PRINT_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`ClinicAnnualReport_PRINT_REPORT`]}
          color="blue"
          style={{ width: "11rem" }}
          title="พิมพ์"
        />
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1rem", padding: "0rem 0rem 0rem 1rem" }}>
        <label
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0rem 3rem 0rem 0rem",
          }}
        >
          ประเภท
        </label>
        <Dropdown
          selection={true}
          value={
            props.ClinicAnnualReportSequence?.patientPaidServicesReport?.encounterType ||
            "OPD และ IPD"
          }
          onChange={(e: any, data: any) =>
            props.setProp(
              `ClinicAnnualReportSequence.patientPaidServicesReport.encounterType`,
              data.value
            )
          }
          options={PATIENT_TYPE}
          style={{ minWidth: "9rem" }}
        ></Dropdown>
      </FormGroup>
    </Form>
  );
};

export default CardPatientPaidServicesReportFilter;
