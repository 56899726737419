import React, { useState, useEffect, useCallback, useMemo } from "react";
import { InputProps, Modal } from "semantic-ui-react";

// UX
import ModEditDoctorFeeUX from "./ModEditDoctorFeeUX";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import { DFPayrollSerializer } from "./sequence/DoctorFeeSummary";

// Types
type ModEditDoctorFeeProps = {
  onEvent: (e: any) => any;
  setProp: (key: any, value: any, callback?: Function) => any;
  // data
  open: boolean;
  data?: DFPayrollSerializer | null;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // callback
  onClose: () => any;
  onSave: (data: { data: DFPayrollSerializer; btnAction: string }) => any;
};

const BUTTON_ACTIONS = {
  SAVE: "SAVE_DF_PAYROLL",
};

const MOD_EDIT_DOCTOR_FEE = "ModEditDoctorFee";

const ACTION_SEARCH = `${MOD_EDIT_DOCTOR_FEE}_${BUTTON_ACTIONS.SAVE}`;

const ModEditDoctorFee = (props: ModEditDoctorFeeProps) => {
  const [detail, setDetail] = useState<DFPayrollSerializer | null>(null);

  // Effect
  useEffect(() => {
    setDetail(props.data || null);
  }, [props.data]);

  const formatPrice = useCallback((number: string) => {
    return Number(number).toLocaleString("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }, []);

  const handleChangeValue = useCallback(
    (e: any, data: InputProps & { name: keyof DFPayrollSerializer }) => {
      setDetail((detail) => ({ ...detail, [data.name]: data.value }));
    },
    []
  );

  const handleSave = useCallback(() => {
    if (detail) {
      props.onSave({
        data: detail,
        btnAction: ACTION_SEARCH,
      });
    }
  }, [detail]);

  const handleClose = useCallback(() => {
    props.onClose();

    props.setProp(`errorMessage.${ACTION_SEARCH}`, null);
  }, []);

  const errorMessage = useMemo(() => {
    const error = props.errorMessage?.[ACTION_SEARCH] || {};

    return { ...error, error: error?.show ? error.error : null };
  }, [props.errorMessage]);

  const formattedPrice = useMemo(() => {
    const keys =
      "revenue_price,revenue_after_lab_price,management_fee_price,leftover_revenue_price,compensation_percent,final_revenue_price,net_price".split(
        ","
      ) as (keyof DFPayrollSerializer)[];

    return keys.reduce((r, k) => ({ ...r, [k]: detail?.[k] ? formatPrice(detail[k]) : "" }), {});
  }, [detail]);

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${ACTION_SEARCH}.show`, false);
        }}
        error={errorMessage.error}
        success={null}
      />

      <Modal open={props.open} closeOnDimmerClick={true} onClose={handleClose}>
        <ModEditDoctorFeeUX
          // data
          detail={detail}
          formattedPrice={formattedPrice}
          showRequiredField={errorMessage.showRequiredField}
          // callback
          onChangeValue={handleChangeValue}
          onClickCancel={handleClose}
          onClose={handleClose}
          // Element
          ButtonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              paramKey={ACTION_SEARCH}
              buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
              // config
              color={props.data?.remark_adjust_down_price ? "yellow" : "green"}
              name={BUTTON_ACTIONS.SAVE}
              size="medium"
              title={props.data?.remark_adjust_down_price ? "บันทึกและแก้ไข" : "บันทึก"}
            />
          }
        />
      </Modal>
    </>
  );
};

ModEditDoctorFee.displayName = "ModEditDoctorFee";

export default React.memo(ModEditDoctorFee);
