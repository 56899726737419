import WasmController from "react-lib/frameworks/WasmController";
// apis
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataActionLogList from "issara-sdk/apis/FormDataActionLogList_apps_PTM";
import AdmissionList from "issara-sdk/apis/AdmissionList_apps_DPI";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  masterOptions?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}

  // common
  selectedEncounter?: any;
  CreateUpdateEncounterSequence?: any;
  openCardNurseForm?: { card?: string | null; encounter: number | null } | null;
  // sequence
  PreAssessmentSequence?: {
    sequenceIndex?: string | null;
    allowed_actions?: any[];
    id?: number | null;
    edited_form?: any;
    // data
    address?: string;
    bathing_before_illness?: string;
    bathing_present_illness?: string;
    breast?: string;
    chief_complaint?: string;
    cleaning_mounth_and_teeth_before_illness?: string;
    cleaning_mounth_and_teeth_present_illness?: string;
    come_from?: string;
    commute_by?: string;
    commute_with?: string;
    commute_with_remark?: string;
    contact_person?: string;
    contact_person_relation?: string;
    defecating_before_illness?: string;
    defecating_present_illness?: string;
    defecation_per_day?: string;
    defecation_problem?: string;
    defecation_problem_remark?: string;
    dressing_after_illness?: string;
    dressing_before_illness?: string;
    eating_before_illness?: string;
    eating_present_illness?: string;
    eating_problem?: string;
    eating_problem_remark?: string;
    education?: string;
    expectation?: string;
    family_disease?: string;
    family_disease_remark?: string;
    first_disease?: string;
    genital_organ?: string;
    has_been_cured_before?: string;
    has_been_cured_date?: string;
    has_been_cured_remark?: string;
    health?: string;
    health_before_illness?: string;
    health_before_illness_remark?: string;
    health_care?: string;
    health_present_illness?: string;
    health_remark?: string;
    hearing?: string;
    how_to_get_rid_of_problem?: string;
    how_to_get_rid_of_problem_remark?: string;
    illness_affect_ability?: string;
    illness_affect_emotion_and_mind?: string;
    illness_affect_emotion_and_mind_remark?: string;
    illness_affect_image?: string;
    illness_affect_image_remark?: string;
    illness_affect_on_character_and_relation?: string;
    info_given_by?: string;
    marital_status?: string;
    meal?: string;
    meal_for_specific_disease?: string;
    meal_per_day?: string;
    memory_and_response?: string;
    occupation?: string;
    period?: string;
    personal_disease?: string;
    personal_disease_remark?: string;
    phone?: string;
    present_illness?: string;
    present_illness_believe?: string;
    recognition?: string;
    religion?: string;
    rite?: string;
    seeing?: string;
    skin_problem?: string;
    skin_problem_remark?: string;
    sleeping_enough?: string;
    sleeping_per_day?: string;
    sleeping_problem?: string;
    sleeping_problem_remark?: string;
    speaking?: string;
    spiritual_anchor?: string;
    stomach_defecation?: string;
    surgical_history?: string;
    surgical_history_remark?: string;
    touch?: string;
    uncomfortable_thing?: string;
    uncomfortable_thing_remark?: string;
    urination_per_day?: string;
    urination_problem?: string;
    urination_problem_remark?: string;
    walking_before_illness?: string;
    walking_present_illness?: string;
    noStomachDefecation?: boolean;
    haveStomachDefecation?: boolean;
    pregnancy_status?: any;
    pregnancy_period?: any;
    pregnancyPeriodID?: any;
  } | null;

  // options
  preAssessmentOptions?: {
    educationOptions?: any[];
    maritalStatusOptions?: any[];
    religionOptions?: any[];
    careerOptions?: any[];
    raceOptions?: any[];
    nationalityOptions?: any[];
    salaryOptions?: any[];
    contactOptions?: any[];
    painRiskFallOptions?: any[];
    painNursingCareOptions?: any[];
    sensesNursingCareOptions?: any[];
    painLevel?: any[];
    pregnancyPeriodOptions?: any[];
  };

  preAssessmentActionLog?: any;
};

export const StateInitial: State = {
  // sequence
  PreAssessmentSequence: {
    sequenceIndex: null,
    allowed_actions: [],
    id: null,
    // data
    address: "",
    bathing_before_illness: "",
    bathing_present_illness: "",
    breast: "",
    chief_complaint: "",
    cleaning_mounth_and_teeth_before_illness: "",
    cleaning_mounth_and_teeth_present_illness: "",
    come_from: "",
    commute_by: "",
    commute_with: "",
    commute_with_remark: "",
    contact_person: "",
    contact_person_relation: "",
    defecating_before_illness: "",
    defecating_present_illness: "",
    defecation_per_day: "",
    defecation_problem: "",
    defecation_problem_remark: "",
    dressing_after_illness: "",
    dressing_before_illness: "",
    eating_before_illness: "",
    eating_present_illness: "",
    eating_problem: "",
    eating_problem_remark: "",
    education: "",
    expectation: "",
    family_disease: "",
    family_disease_remark: "",
    first_disease: "",
    genital_organ: "",
    has_been_cured_before: "",
    has_been_cured_date: "",
    has_been_cured_remark: "",
    health: "",
    health_before_illness: "",
    health_before_illness_remark: "",
    health_care: "",
    health_present_illness: "",
    health_remark: "",
    hearing: "",
    how_to_get_rid_of_problem: "",
    how_to_get_rid_of_problem_remark: "",
    illness_affect_ability: "",
    illness_affect_emotion_and_mind: "",
    illness_affect_emotion_and_mind_remark: "",
    illness_affect_image: "",
    illness_affect_image_remark: "",
    illness_affect_on_character_and_relation: "",
    info_given_by: "",
    marital_status: "",
    meal: "",
    meal_for_specific_disease: "",
    meal_per_day: "",
    memory_and_response: "",
    occupation: "",
    period: "",
    personal_disease: "",
    personal_disease_remark: "",
    phone: "",
    present_illness: "",
    present_illness_believe: "",
    recognition: "",
    religion: "",
    rite: "",
    seeing: "",
    skin_problem: "",
    skin_problem_remark: "",
    sleeping_enough: "",
    sleeping_per_day: "",
    sleeping_problem: "",
    sleeping_problem_remark: "",
    speaking: "",
    spiritual_anchor: "",
    stomach_defecation: "",
    surgical_history: "",
    surgical_history_remark: "",
    touch: "",
    uncomfortable_thing: "",
    uncomfortable_thing_remark: "",
    urination_per_day: "",
    urination_problem: "",
    urination_problem_remark: "",
    walking_before_illness: "",
    walking_present_illness: "",
    noStomachDefecation: true,
    haveStomachDefecation: false,
    pregnancy_status: 1,
    pregnancy_period: 0,
    pregnancyPeriodID: "",
  },

  // options
  preAssessmentOptions: {
    educationOptions: [],
    maritalStatusOptions: [],
    religionOptions: [],
    careerOptions: [],
    raceOptions: [],
    nationalityOptions: [],
    salaryOptions: [],
    contactOptions: [],
    painRiskFallOptions: [],
    painNursingCareOptions: [],
    sensesNursingCareOptions: [],
    painLevel: [],
    pregnancyPeriodOptions: [],
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  masterData?: { [name: string]: any };
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

const FORM_CODE = "CardPreAssessmentIPD";
const FORM_NAME = "ประเมินอาการผู้ป่วยแรกรับ (IPD)";
const FORM_VERSION = "1.0";

export const Start: Handler = async (controller, params) => {
  // Options
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["education", {}],
        ["maritalStatus", {}],
        ["religion", {}],
        ["career", {}],
        ["race", {}],
        ["nationality", {}],
        ["pain", {}],
        ["location", {}],
        ["characteristic", {}],
        ["relative", {}],
      ],
    },
  });

  const educationOptions = mapOptions(controller.data.masterData?.education, "name");
  const maritalStatusOptions = mapOptions(controller.data.masterData?.maritalStatus, "name");
  const religionOptions = mapOptions(controller.data.masterData?.religion, "name");
  const careerOptions = mapOptions(controller.data.masterData?.career, "name");
  const raceOptions = mapOptions(controller.data.masterData?.race, "name");
  const relativeOptions = mapOptions(controller.data.masterData?.relative, "name");
  const nationalityOptions = mapNationalityOptions(
    controller.data.masterData?.nationality,
    "full_name"
  );

  controller.setState(
    {
      PreAssessmentSequence: { sequenceIndex: "AddOrEdit" },
      preAssessmentOptions: {
        educationOptions: educationOptions,
        // maritalStatusOptions: maritalStatusOptions,
        religionOptions: religionOptions,
        careerOptions: careerOptions,
        raceOptions: raceOptions,
        nationalityOptions: nationalityOptions,
        maritalStatusOptions: [
          { key: "1", value: "โสด", text: "โสด" },
          { key: "2", value: "สมรส", text: "สมรส" },
          { key: "3", value: "หมั้น", text: "หมั้น" },
          { key: "4", value: "หย่า", text: "หย่า" },
          { key: "5", value: "หม้าย", text: "หม้าย" },
          { key: "6", value: "แยกกันอยู่", text: "แยกกันอยู่" },
        ],
        salaryOptions: [
          { key: "1", value: "ต่ำว่า 15,000 บาท", text: "ต่ำว่า 15,000 บาท" },
          {
            key: "2",
            value: "15,000 - 25,000 บาท",
            text: "15,000 - 25,000 บาท",
          },
          { key: "3", value: "25000 - 35,000 บาท", text: "25000 - 35,000 บาท" },
          {
            key: "4",
            value: "35,000 - 50,000 บาท",
            text: "35,000 - 50,000 บาท",
          },
          {
            key: "5",
            value: "50,000 - 75,000 บาท",
            text: "50,000 - 75,000 บาท",
          },
          {
            key: "6",
            value: "75,000 - 100,000 บาท",
            text: "75,000 - 100,000 บาท",
          },
          {
            key: "7",
            value: "มากกว่า 100,000 บาท",
            text: "มากกว่า 100,000 บาท",
          },
        ],
        contactOptions: [
          ...relativeOptions,
          { key: "ผู้ดูแล", value: "ผู้ดูแล", text: "ผู้ดูแล" },
          { key: "เพื่อน", value: "เพื่อน", text: "เพื่อน" },
        ],
        painRiskFallOptions: [
          { key: "1", value: "ความเสี่ยงต่ำ", text: "ความเสี่ยงต่ำ" },
          { key: "2", value: "ความเสี่ยงสูง", text: "ความเสี่ยงสูง" },
        ],
        painNursingCareOptions: fallRiskNursingOptions,
        sensesNursingCareOptions: nursingCareOptions,
        painLevel: [
          { key: "0", value: "0", text: "0" },
          { key: "1", value: "1", text: "1" },
          { key: "2", value: "2", text: "2" },
          { key: "3", value: "3", text: "3" },
          { key: "4", value: "4", text: "4" },
          { key: "5", value: "5", text: "5" },
          { key: "6", value: "6", text: "6" },
          { key: "7", value: "7", text: "7" },
          { key: "8", value: "8", text: "8" },
          { key: "9", value: "9", text: "9" },
          { key: "10", value: "10", text: "10" },
        ],
        pregnancyPeriodOptions: [
          { key: 1, value: 0, text: "ไม่ระบุ" },
          { key: 2, value: 1, text: "ไตรมาสที่ 1" },
          { key: 3, value: 2, text: "ไตรมาสที่ 2" },
          { key: 4, value: 3, text: "ไตรมาสที่ 3" },
        ],
      },
    },
    () => {
      AddOrEdit(controller, { ...params, action: "FETCH_LATEST" });
    }
  );
};

export const AddOrEdit: Handler = async (controller, params) => {
  var state = controller.getState();

  if (!state.PreAssessmentSequence || !state.selectedEncounter) {
    return;
  }

  if (params?.action === "FETCH_LATEST") {
    const [respFetch, errFetch, netwFetch] = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: params.encounterId || state.selectedEncounter?.id,
        form_code: FORM_CODE,
        form_version: FORM_VERSION,
      },
    });

    if (respFetch) {
      const [pregnancyRes, pregnancyErr, pregnancyNet] = await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: params.encounterId || state.selectedEncounter?.id,
          form_code: "CardPregnancyAssessment",
          form_version: "1.0",
        },
        extra: { division: controller.data.division },
      });

      if (respFetch?.data?.provisional_diagnosis) {
        controller.setState({
          PreAssessmentSequence: {
            ...respFetch?.data,
            sequenceIndex: "AddOrEdit",
            allowed_actions: respFetch?.allowed_actions,
            id: respFetch?.id,
            edit_user_name: respFetch?.edit_user_name,
            edited_utc: respFetch?.edited_utc,
            pregnancy_status: pregnancyRes?.data?.pregnancy_status,
            pregnancy_period: pregnancyRes?.data?.pregnancy_period,
            pregnancyPeriodID: pregnancyRes?.id,
            provisional_diagnosis: respFetch?.data?.provisional_diagnosis,
          },
        });
      } else {
        controller.setState({
          PreAssessmentSequence: {
            ...respFetch?.data,
            sequenceIndex: "AddOrEdit",
            allowed_actions: respFetch?.allowed_actions,
            id: respFetch?.id,
            edit_user_name: respFetch?.edit_user_name,
            edited_utc: respFetch?.edited_utc,
            pregnancy_status: pregnancyRes?.data?.pregnancy_status,
            pregnancy_period: pregnancyRes?.data?.pregnancy_period,
            pregnancyPeriodID: pregnancyRes?.id,
            provisional_diagnosis: admissionRes?.provisional_diagnosis,
          },
        });

        await getAdmissionFormDetail(controller, params.emrId);
      }
    } else {
      clearForm(controller, params.emrId);
    }
  } else if (["SAVE", "CONFIRM", "UNCONFIRM"].includes(params?.action)) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    if (state.PreAssessmentSequence?.pregnancyPeriodID) {
      // Edit CardPregnancyAssessment
      const pregnancyAssessment = await FormDataDetail.update({
        pk: state.PreAssessmentSequence?.pregnancyPeriodID,
        data: {
          form_code: "CardPregnancyAssessment",
          form_name: "CardPregnancyAssessment",
          form_version: "1.0",
          encounter: state.selectedEncounter?.id,
          action: "CONFIRM",
          data: {
            pregnancy_status: state.PreAssessmentSequence?.pregnancy_status,
            pregnancy_period: state.PreAssessmentSequence?.pregnancy_period,
          },
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      controller.setState({
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
          pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_status,
        },
      });
    } else {
      // Create CardPregnancyAssessment
      const pregnancyAssessment = await FormDataList.create({
        data: {
          form_code: "CardPregnancyAssessment",
          form_name: "CardPregnancyAssessment",
          form_version: "1.0",
          encounter: state.selectedEncounter?.id,
          action: "CONFIRM",
          data: {
            pregnancy_status: state.PreAssessmentSequence?.pregnancy_status || 1,
            pregnancy_period: state.PreAssessmentSequence?.pregnancy_period || 0,
          },
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      controller.setState({
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
          pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_status,
        },
      });
    }

    let dataSeq = {
      ...state.PreAssessmentSequence,
      provisional_diagnosis: params.provisional_diagnosis,
    };
    delete dataSeq["sequenceIndex"];
    delete dataSeq["allowed_actions"];
    delete dataSeq["id"];

    let data = {
      form_code: FORM_CODE,
      form_name: FORM_NAME,
      form_version: FORM_VERSION,
      encounter: state.selectedEncounter.id,
      action: params.action,
      data: dataSeq,
      remark: params?.note,
    };

    let respAction = null;
    let errAction = null;
    let netwAction = null;

    if (state.PreAssessmentSequence?.id) {
      [respAction, errAction, netwAction] = await FormDataDetail.update({
        pk: state.PreAssessmentSequence.id,
        data: data,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });
    } else {
      [respAction, errAction, netwAction] = await FormDataList.create({
        data: data,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });
    }
    if (errAction) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errAction },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
        // PreAssessmentSequence: {
        //   sequenceIndex: "AddOrEdit",
        //   allowed_actions: respAction.allowed_actions,
        //   id: respAction.id,
        //   ...respAction.data,
        // },
      });
      AddOrEdit(controller, { action: "FETCH_LATEST" });
    }
  } else if (params?.action === "CANCEL") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });
    const [respCancel, errCancel, netwCancel] = await FormDataDetail.update({
      pk: state.PreAssessmentSequence.id,
      data: {
        form_code: FORM_CODE,
        form_name: FORM_NAME,
        form_version: FORM_VERSION,
        encounter: state.selectedEncounter.id,
        action: params.action,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (errCancel) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errCancel },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
      AddOrEdit(controller, { action: "FETCH_LATEST" });
    }
  } else if (params?.action === "getActionLog") {
    const [resLog, errLog] = await FormDataActionLogList.list({
      pk: state.PreAssessmentSequence.id,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    controller.setState({
      preAssessmentActionLog: resLog?.items,
    });
  } else if (params?.action === "checkStomachDefecation") {
    if (params?.type === "noStomachDefecation") {
      controller.setState({
        PreAssessmentSequence: {
          ...state.PreAssessmentSequence,
          noStomachDefecation: true,
          haveStomachDefecation: false,
        },
      });
    } else if (params?.type === "haveStomachDefecation") {
      controller.setState({
        PreAssessmentSequence: {
          ...state.PreAssessmentSequence,
          haveStomachDefecation: true,
          noStomachDefecation: false,
        },
      });
    }
  }
};

// utilities
const mapOptions = (list: any[], valueKey = "id") => {
  return list.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item.name,
  }));
};

const mapNationalityOptions = (list: any[], valueKey = "id") => {
  return list.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item[valueKey],
  }));
};

const clearForm = async (controller: any, emrId) => {
  var state = controller.getState();

  const pregnancyAssessment = await FormDataLatest.retrieve({
    apiToken: controller.apiToken,
    params: {
      encounter: state.selectedEncounter?.id,
      form_code: "CardPregnancyAssessment",
      form_version: "1.0",
    },
    extra: { division: controller.data.division },
  });

  controller.setState({
    PreAssessmentSequence: {
      sequenceIndex: "AddOrEdit",
      allowed_actions: [],
      id: null,
      // data
      address: "",
      bathing_before_illness: "ทำได้เอง",
      bathing_present_illness: "ทำได้เอง",
      breast: "ปกติ",
      chief_complaint: "",
      cleaning_mounth_and_teeth_before_illness: "ทำได้เอง",
      cleaning_mounth_and_teeth_present_illness: "ทำได้เอง",
      come_from: "OPD",
      commute_by: "เดินมา",
      commute_with: "ญาติ",
      commute_with_remark: "",
      contact_person: "",
      contact_person_relation: "",
      defecating_before_illness: "ทำได้เอง",
      defecating_present_illness: "ทำได้เอง",
      defecation_per_day: "",
      defecation_problem: "ปกติ",
      defecation_problem_remark: "",
      dressing_after_illness: "ทำได้เอง",
      dressing_before_illness: "ทำได้เอง",
      eating_before_illness: "ทำได้เอง",
      eating_present_illness: "ทำได้เอง",
      eating_problem: "ไม่มี",
      eating_problem_remark: "",
      education: "",
      expectation: "หาย",
      family_disease: "ไม่มี",
      family_disease_remark: "",
      first_disease: "",
      genital_organ: "ปกติ",
      has_been_cured_before: "ไม่มี",
      has_been_cured_date: "",
      has_been_cured_remark: "",
      health: "แข็งแรง",
      health_before_illness: "ดี",
      health_before_illness_remark: "",
      health_care: "ไปสถานพยาบาล",
      health_present_illness: "รุนแรง",
      health_remark: "",
      hearing: "ปกติ",
      how_to_get_rid_of_problem: "ปรึกษา",
      how_to_get_rid_of_problem_remark: "",
      illness_affect_ability: "ไม่มี",
      illness_affect_emotion_and_mind: "ไม่มี",
      illness_affect_emotion_and_mind_remark: "",
      illness_affect_image: "ไม่มี",
      illness_affect_image_remark: "",
      illness_affect_on_character_and_relation: "ครอบครัว",
      info_given_by: "",
      marital_status: "",
      meal: "อาหารอ่อน",
      meal_for_specific_disease: "",
      meal_per_day: "",
      memory_and_response: "ปกติ",
      occupation: "",
      period: "ไม่มี",
      personal_disease: "ไม่มี",
      personal_disease_remark: "",
      phone: "",
      present_illness: "",
      present_illness_believe: "",
      recognition: "ตรง",
      religion: "",
      rite: "",
      seeing: "ปกติ",
      skin_problem: "ไม่มี",
      skin_problem_remark: "",
      sleeping_enough: "เพียงพอ",
      sleeping_per_day: "",
      sleeping_problem: "ไม่มี",
      sleeping_problem_remark: "",
      speaking: "ปกติ",
      spiritual_anchor: "",
      stomach_defecation: "",
      surgical_history: "ไม่มี",
      surgical_history_remark: "",
      touch: "ปกติ",
      uncomfortable_thing: "ไม่มี",
      uncomfortable_thing_remark: "",
      urination_per_day: "",
      urination_problem: "ปกติ",
      urination_problem_remark: "",
      walking_before_illness: "ทำได้เอง",
      walking_present_illness: "ทำได้เอง",
      cost_problem: "ไม่มีปัญหา",
      health_given_by: "ผู้ป่วย",
      sleep_pill: "ไม่ใช้",
      recognition_before: "ปกติ",
      recognition_present: "ปกติ",
      memory_and_response_before: "ปกติ",
      memory_and_response_present: "ปกติ",
      hearing_before: "ปกติ",
      hearing_present: "ปกติ",
      seeing_before: "ปกติ",
      seeing_present: "ปกติ",
      touch_before: "ปกติ",
      touch_present: "ปกติ",
      pain_status: "ปวด",
      illness_affect_on_character_and_relation_status: "ไม่มี",
      problems_fertility: "ไม่มีปัญหา",
      decisions: "ตัดสินใจเองได้",
      stress_relief_consult: false,
      stress_relief_hobby: false,
      stress_relief_seclude: false,
      stress_relief_drug: false,
      stress_relief_other: false,
      care_yourself_at_home: "ได้",
      cause_health: false,
      support_family: false,
      doing_religion: "ไม่ต้องการ",
      risk_fall: "ไม่มีความเสี่ยง",
      noStomachDefecation: true,
      haveStomachDefecation: false,
      pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status || 1,
      pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period,
      pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
    },
  });

  await getAdmissionFormDetail(controller, emrId);
};

const getAdmissionFormDetail = async (controller: any, emrId: number) => {
  const [resp, err, netw] = await AdmissionList.retrieve({
    pk: emrId,
    apiToken: controller.apiToken,
  });

  const state = controller.getState();

  if (!err) {
    controller.setState({
      PreAssessmentSequence: {
        ...state.PreAssessmentSequence,
        important_symptoms: resp.admission_form?.chief_complaint || "",
        current_illnesses: resp.admission_form?.present_illness || "",
        provisional_diagnosis: resp?.provisional_diagnosis,
      },
    });
  }
};

export const nursingCareOptions = [
  { key: "1", value: "NA", text: "NA" },
  { key: "2", value: "ไม่จำเป็น", text: "ไม่จำเป็น" },
  {
    key: "3",
    value: "จัดท่าเพื่อลดความเจ็บปวด",
    text: "จัดท่าเพื่อลดความเจ็บปวด",
  },
  { key: "4", value: "วาง Hot/Cold pack", text: "วาง Hot/Cold pack" },
  {
    key: "5",
    value: "รายงานแพทย์ pain score > 3",
    text: "รายงานแพทย์ pain score > 3",
  },
  { key: "6", value: "มี RX", text: "มี RX" },
  { key: "7", value: "ไม่มี RX", text: "ไม่มี RX" },
  { key: "8", value: "อื่นๆ", text: "อื่นๆ" },
];

export const fallRiskNursingOptions = [
  { key: "NON", value: "ไม่จำเป็น", text: "ไม่จำเป็น" },
  {
    key: "RSK",
    value: "ปฏิบัติตามการให้การพยาบาลผู้ป่วยที่ประเมินแล้วมีความเสี่ยงต่อการพลัดตกหกล้ม",
    text: "ปฏิบัติตามการให้การพยาบาลผู้ป่วยที่ประเมินแล้วมีความเสี่ยงต่อการพลัดตกหกล้ม",
  },
  {
    key: "TRS",
    value: "จัดให้ผู้ป่วยนั่งรถนั่งหรือนอนเตียง Transfer",
    text: "จัดให้ผู้ป่วยนั่งรถนั่งหรือนอนเตียง Transfer",
  },
  {
    key: "ORA",
    value: "ติดสัญลักษณ์แสดงผู้ป่วยที่เสี่ยงต่อการพลัดตกหกล้ม",
    text: "ติดสัญลักษณ์แสดงผู้ป่วยที่เสี่ยงต่อการพลัดตกหกล้ม",
  },
  {
    key: "SPL",
    value: "แจ้งผู้ป่วยและญาติระวังพลัดตกหกล้ม",
    text: "แจ้งผู้ป่วยและญาติระวังพลัดตกหกล้ม",
  },
  {
    key: "LIM",
    value: "จัดให้ผู้ป่วยอยู่ในบริเวณที่กำหนด",
    text: "จัดให้ผู้ป่วยอยู่ในบริเวณที่กำหนด",
  },
  {
    key: "STD",
    value: "Standard Fall Precaution (Score 0-6)",
    text: "Standard Fall Precaution (Score 0-6)",
  },
  {
    key: "SFP",
    value: "Strict Fall Precaution (Score 7-18)",
    text: "Strict Fall Precaution (Score 7-18)",
  },
  {
    key: "HSFP",
    value: "Highly Strict Fall Precaution (Score -18)",
    text: "Highly Strict Fall Precaution (Score -18)",
  },
  { key: "OTH", value: "อื่นๆ", text: "อื่นๆ" },
];

export const pregnancyPeriodOptions = [
  { key: 1, value: 0, text: "ไม่ระบุ" },
  { key: 2, value: 1, text: "ไตรมาสที่ 1" },
  { key: 3, value: 2, text: "ไตรมาสที่ 2" },
  { key: 4, value: 3, text: "ไตรมาสที่ 3" },
];

export const alcoholOptions = [
  { key: "NA", value: "NA", text: "NA" },
  { key: "NO", value: "NO", text: "ไม่ดื่ม" },
  { key: "YES", value: "YES", text: "ดื่ม" },
  { key: "Past", value: "Past", text: "มีประวัติการดื่ม" },
];

export const alcoholSpecificOptions = [
  { key: 1, value: 1, text: "บางครั้ง" },
  { key: 2, value: 2, text: "ประจำ" },
];

export const tobaccoOptions = [
  { key: "NA", value: "NA", text: "NA" },
  { key: "NO", value: "NO", text: "ไม่สูบ" },
  { key: "YES", value: "YES", text: "สูบ" },
  { key: "Past", value: "Past", text: "มีประวัติการสูบ" },
];

export const tobaccoSpecificOptions = [
  { key: 1, value: 1, text: "บางครั้ง" },
  { key: 2, value: 2, text: "ประจำ" },
];