import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Button, Checkbox, Icon, Input, Popup, Image, Form } from "semantic-ui-react";
// MUI

import htmlParse from "html-react-parser";

// UX
import CardOpenEncounterUX from "./CardOpenEncounterUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModAuthen from "react-lib/apps/common/ModAuthen";

// Interface
import { DOCTOR_ORDER_MAPPING_ICON } from "../REG/REGInterface";

import { formatDatetime } from "react-lib/utils/dateUtils";

// Config
import CONFIG from "config/config";

const IMAGES = {
  //icon
  mobilePhoneLock: "/static/images/regSearch/mobilePhoneLock.png",
  call_message_yellow: "/static/images/regSearch/call-message-yellow.png",
};

const BUTTON_ACTIONS = {
  open_en_app: "open_encounter_appointment",
  sync_en_app: "open_encounter_sync_appointment",
  print_visit_slip_form: "print_visit_slip_form",
  update: "UPDATE",
  save: "SAVE",
};

const CARD_OPEN_ENCOUNTER = "CreateUpdateEncounter";

const ACTION_OPEN_EN_APP = `${CARD_OPEN_ENCOUNTER}_${BUTTON_ACTIONS.open_en_app}`;
const ACTION_SYNC_EN_APP = `${CARD_OPEN_ENCOUNTER}_${BUTTON_ACTIONS.sync_en_app}`;
const ACTION_UPDATE = `${CARD_OPEN_ENCOUNTER}_${BUTTON_ACTIONS.update}`;
const ACTION_SAVE = `${CARD_OPEN_ENCOUNTER}_${BUTTON_ACTIONS.save}`;
const ACTION_PRINT_VISIT_SLIP = `${CARD_OPEN_ENCOUNTER}_${BUTTON_ACTIONS.print_visit_slip_form}`;

const CardOpenEncounter = (props: any) => {
  // Mod
  const [openDialog, setOpenDialog] = useState(false);
  const [openErrorMessageDegree, setOpenErrorMessageDegree] = useState(false);
  const [openErrorVerified, setOpenErrorVerified] = useState(false);
  const [note, setNote] = useState("");
  const [showEncounterEditor, setShowEncounterEditor] = useState(false);
  const [pregnancyData, setPregnancyData] = useState({
    pregnancy_period: 0,
    pregnancy_status: 1,
  });

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "CreateUpdateEncounter",
      card: "CreateUpdateEncounter",
      form: props.form,
      isEditEncounter: props.isEditEncounter,
      editEncounterId: props.editEncounterId,
      restart: true,
      isNoneFilterDate: true
    });

    if (props.isEditEncounter) {
      setShowEncounterEditor(true);
    }
  }, []);

  useEffect(() => {
    setPregnancyData({
      pregnancy_status:
        props.CreateUpdateEncounterSequence?.pregnancy_status || 1,
      pregnancy_period:
        props.CreateUpdateEncounterSequence?.pregnancy_period || 0,
    });
  }, [
    props.CreateUpdateEncounterSequence?.pregnancy_status,
    props.CreateUpdateEncounterSequence?.pregnancy_period,
  ]);

  useEffect(() => {
    if (
      props.successMessage?.[CARD_OPEN_ENCOUNTER] &&
      !props.isEditEncounter &&
      showEncounterEditor
    ) {
      setShowEncounterEditor(false);

      props.setProp(`successMessage.${CARD_OPEN_ENCOUNTER}`, null);
    }
  }, [props.successMessage, props.isEditEncounter]);

  useEffect(() => {
    return () => {
      props.setProp(`successMessage.${CARD_OPEN_ENCOUNTER}`, null);
      props.setProp(`errorMessage.${CARD_OPEN_ENCOUNTER}`, null);
      props.setProp(`CreateUpdateEncounterSequence.selectedEncounterItems`, []);
    };
  }, []);

  // Memo Callback
  const selectedEncounter = useMemo(() => {
    return props.CreateUpdateEncounterSequence?.selectedEncounter || {};
  }, [props.CreateUpdateEncounterSequence?.selectedEncounter]);

  const printableList = useMemo(() => {
    return (props.CreateUpdateEncounterSequence?.encounterList || []).filter(
      (item: any) => item.status !== "CANCELED"
    );
  }, [props.CreateUpdateEncounterSequence?.encounterList]);

  // Callback
  const handleOpenEncounterAppointment = useCallback(() => {
    props.runSequence({
      sequence: "CreateUpdateEncounter",
      action: "open_encounter_appointment",
      card: CARD_OPEN_ENCOUNTER,
    });
  }, []);

  const handleSyncEncounterAppointment = useCallback(() => {
    props.runSequence({
      sequence: "CreateUpdateEncounter",
      action: "open_encounter_sync_appointment",
      card: CARD_OPEN_ENCOUNTER,
    });
  }, []);

  const handleEditEncounter = () => {
    if (
      props.CreateUpdateEncounterSequence?.student &&
      !props.CreateUpdateEncounterSequence?.student_degree
    ) {
      setOpenErrorMessageDegree(true);
    } else {
      props.runSequence({
        sequence: "CreateUpdateEncounter",
        action: "update",
        pregnancy: pregnancyData,
        card: CARD_OPEN_ENCOUNTER,
        buttonLoadKey: ACTION_UPDATE,
        onUpdated: props.onUpdated,
      });
    }
  }

  const handleSaveEncounter = useCallback(() => {
    if (
      props.CreateUpdateEncounterSequence?.student &&
      !props.CreateUpdateEncounterSequence?.student_degree
    ) {
      setOpenErrorMessageDegree(true);
    } else if (selectedEncounter?.id !== undefined) {
      props.runSequence({
        sequence: "CreateUpdateEncounter",
        action: "clearData",
      });
    } else {
      props.runSequence({
        sequence: "CreateUpdateEncounter",
        action: "create",
        pregnancy: pregnancyData,
        card: CARD_OPEN_ENCOUNTER,
        buttonLoadKey: ACTION_SAVE,
      });
    }
  }, [props.CreateUpdateEncounterSequence?.student, pregnancyData]);

  const handleRowProps = useCallback(
    (_state: any, rowInfo: any, _column: any, _instance: any) => {
      return {
        style: {
          backgroundColor:
            selectedEncounter &&
            rowInfo?.original?.id &&
            rowInfo?.original?.id === selectedEncounter?.id
              ? "#d6ecf3"
              : "white",
        },
        onClick: async () => {
          await props.runSequence({
            sequence: "CreateUpdateEncounter",
            action: "select",
            item: rowInfo?.original,
          });

          setShowEncounterEditor(true);
        },
      };
    },
    [selectedEncounter]
  );

  const handleOpenEncounter = useCallback(() => {
    if (props.patient?.profile_status === "NOT_VERIFIED") {
      setOpenErrorVerified(true);
    } else {
      props.runSequence({
        sequence: "CreateUpdateEncounter",
        action: "clearData",
      });

      setShowEncounterEditor(true);
    }
  }, [props.patient]);

  const handleCheckEncounterAppointment = useCallback(
    (item: any) => (e: any) => {
      props.runSequence({
        sequence: "CreateUpdateEncounter",
        action: "checked_encounter_appointment",
        appointmentId: item.id,
      });
    },
    []
  );

  const changeEncounter = useCallback(
    (key: string) => (e: any, v: any) => {
      if (key === "student" && v.value === "") {
        props.setProp(`CreateUpdateEncounterSequence`, {
          ...props.CreateUpdateEncounterSequence,
          student: "",
          student_degree: null,
        });
      } else {
        props.setProp(`CreateUpdateEncounterSequence.${key}`, v.value);
      }
    },
    []
  );

  const changePregnancy = useCallback(
    (key: string) => (e: any, v: any) => {
      setPregnancyData((pregnancyData) => ({
        ...pregnancyData,
        ...(key === "pregnancy_status" && { pregnancy_status: v.value }),
        ...(key === "pregnancy_period" && { pregnancy_period: v.value }),
      }));
    },
    []
  );

  const handleCheck = useCallback(
    (e: any, data: any) => {
      e.stopPropagation();

      let ids = [
        ...(props.CreateUpdateEncounterSequence?.selectedEncounterItems || []),
      ];

      if (data.checked) {
        ids.push(data.name);
      } else {
        ids = ids.filter((id) => id !== data.name);
      }

      props.setProp(
        "CreateUpdateEncounterSequence.selectedEncounterItems",
        ids
      );
    },
    [props.CreateUpdateEncounterSequence?.selectedEncounterItems]
  );

  const handleCheckAll = useCallback(
    (_e: any, data: any) => {
      let ids = [];

      if (data.checked) {
        ids = printableList.map((item: any) => item.id);
      }

      props.setProp(
        "CreateUpdateEncounterSequence.selectedEncounterItems",
        ids
      );
    },
    [printableList]
  );

  // Memo
  const disabledEditEncounter = useMemo(() => {
    return (
      Object.keys(selectedEncounter.approve_info || {}).length &&
      CONFIG.RAKSTHAI_ENCOUNTER_STATUS
    );
  }, [selectedEncounter]);

  const generateOrder = (orders: any[]) => {
    let sortingOrderType: any = [];

    Object.keys(DOCTOR_ORDER_MAPPING_ICON).forEach((order_type: any) => {
      // find match order type and status
      let target = orders.filter((order) => order.type.includes(order_type));
      if (target?.length > 0) {
        sortingOrderType.push({
          image: DOCTOR_ORDER_MAPPING_ICON[order_type]["appointment_or_cancel"],
          items: target?.map((i) => i.summary_detail),
          ...(order_type === "doctorconsultorder" ? { icon: "user md" } : {}),
        });
      }
    });
    console.log("generate Order: ", sortingOrderType);
    return sortingOrderType;
  };

  const isShowPregnancy = useMemo(() => {
    return (
      props.selectedPatient?.gender === "F" &&
      props.selectedPatient?.age >= 15 &&
      props.selectedPatient?.age <= 50
    );
  }, [props.selectedPatient]);

  const headersEncounter = useMemo(() => {
    return [
      <Checkbox
        key="encounter-checkbox"
        checked={
          printableList.length ===
            props.CreateUpdateEncounterSequence?.selectedEncounterItems
              ?.length && !!printableList.length
        }
        onChange={handleCheckAll}
      />,
      "Encounter [Episode]",
      "Division",
      "Doctor",
      "Status",
      "Started",
      "Create",
      "Update",
      "Del",
    ].filter((item:any)=> !props.useApiDashboard ? !["Update","Create"].includes(item) :  item !== "Started" )
  }, [
    printableList,
    props.CreateUpdateEncounterSequence?.selectedEncounterItems,
  ]);

  const encounterList = useMemo(
    () =>
      (props.CreateUpdateEncounterSequence?.encounterList || []).map(
        (item: any, _idx: any) => {
          const patientCaseOptions = props.masterOptions?.patientCase || [];
          const isTelemed =
            patientCaseOptions
              .find((acc: any) => acc.value === item.patient_case)
              ?.text?.toLowerCase() === "telemed";
          const episodeNo = item.episode_no ? ` [${item.episode_no}]` : "";

          return {
            ...item,
            _doctor: (
              <div style={{ lineHeight: "19px" }}>
                <div>
                  {`${item?.doctor_name || ""}  ${
                    item?.student && item?.student !== null ? " /" : ""
                  }`}
                </div>

                {item?.student ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={"/static/images/order_images/student.png"}
                      style={{ width: "1.3em", height: "1.3em", marginRight: "5px" }}
                    />
                    {item?.student_name}
                  </div>
                ) : null}
              </div>
            ),
            _checked: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.status !== "CANCELED" && (
                  <Checkbox
                    checked={props.CreateUpdateEncounterSequence?.selectedEncounterItems?.includes(
                      item.id
                    )}
                    name={item.id}
                    onChange={handleCheck}
                  />
                )}
              </div>
            ),
            number: (
              <div style={{ display: "flex", alignItems: "center" }}>
                {item.number}
                {episodeNo}
                {isTelemed && (
                  <img
                    src={IMAGES.call_message_yellow}
                    alt="call message yellow"
                    style={{ width: "17.5px", marginLeft: "1rem" }}
                  />
                )}
              </div>
            ),
            delete: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="red"
                  content="ยกเลิก"
                  size="mini"
                  onClick={() => {
                    handleOpenDialog();
                  }}
                />
              </div>
            ),
            canceledDate: formatDatetime(item.canceled_date, true),
          };
        }
      ),
    [
      props.CreateUpdateEncounterSequence,
      props.CreateUpdateEncounterSequence?.encounterList,
      props.CreateUpdateEncounterSequence?.selectedEncounterItems,
    ]
  );

  const appointmentList = useMemo(() => {

    let list: any[] = []

    if (props.useApiDashboard) {
      list =
        (props.patientListMyBplus || []).filter(
          (item: any) => props.selectedPatient?.hn === item.hn
        ) || [];
    } else {
      list = (props.appointmentEncounterList || []).filter((item: any) => {
        const hasAppointmentDetails = item.date && item.start_time && item.doctor;
        const isPackageAppointment = item.appointment_type === 3;

        return hasAppointmentDetails || isPackageAppointment;
      });
    }

    return (list || []).map((item: any) => {
      const generatedOrder = props.useApiDashboard
      ? ""
      : generateOrder(item.children).filter(Boolean);

      const detail = item.is_telemed ? "นัดปรึกษาแพทย์ออนไลน์" : item.detail;

      return {
        ...item,
        _checked: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              disabled={props.patient?.profile_status === "NOT_VERIFIED"}
              checked={(props.selectedAppointmentItems || [])?.includes(
                item?.id
              )}
              onChange={handleCheckEncounterAppointment(item)}
            />
          </div>
        ),
          order:
            item.children?.length > 0 ? (
              <div style={{ display: "grid", gridTemplateColumns: "40% 40%" }}>
                {generatedOrder.map((item: any, _index: number) => {
                  return (
                  <div
                    key={"order" + item.image}
                    style={{ paddingLeft: "5px" }}
                  >
                      <Popup
                        on="click"
                        trigger={
                          item.image ? (
                            <img
                              src={item.image}
                              alt="order-icon"
                              style={{ width: "30px", height: "30px" }}
                            />
                          ) : (
                            item.icon && (
                              <div
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  backgroundColor: "white",
                                  borderRadius: "500rem",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: "#cccccc",
                                    borderRadius: "500rem",
                                    margin: "auto",
                                    paddingTop: "6px",
                                  }}
                                >
                                  <Icon
                                    name={item.icon}
                                    inverted
                                    style={{ width: "30px", height: "30px" }}
                                  />
                                </div>
                              </div>
                            )
                          )
                        }
                      >
                        {item.items.map((order: any) => htmlParse(order))}
                      </Popup>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            ),
          detail: (
            <div>
              {props.patient?.profile_status === "NOT_VERIFIED" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Image height={25} src={IMAGES.mobilePhoneLock} style={{ marginLeft: "10px" }} />
                  <div style={{ color: "red", fontSize: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>ยินยันประวัติ</div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      ก่อนเปิด Encounter
                    </div>
                  </div>
                </div>
              ) : (
                detail
              )}
            </div>
          ),
        date: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{item.date}</span>
            {item.is_telemed && (
              <img
                src={IMAGES.call_message_yellow}
                alt="call message yellow"
                style={{ width: "17.5px", marginLeft: "0.5rem" }}
              />
            )}
          </div>
        ),
      };
    });
  }, [props.appointmentEncounterList, props.selectedAppointmentItems, props.patientListMyBplus,props.selectedPatient]);

  const episodeOptions = useMemo(() => {
    return (props.CreateUpdateEncounterSequence?.episodeList || []).map((item: any) => {
      const episodeType = props.masterOptions.episodeType?.find(
        (option) => option.value === item.episode_type
      )?.text || "";
      const doctorName = item.doctor_name ? `, ${item.doctor_name}` : "";
      const finishText = item.is_finished ? "(Finish) " : "";

      return {
        key: item.id,
        text: `${finishText}${item.episode_no} ${item.name} [${episodeType}]${doctorName}`,
        value: item.id,
        content: (
          <div>
            {finishText}
            <strong>{item.episode_no}</strong> {item.name} [{episodeType}]{doctorName}
          </div>
        ),
      };
    });
  }, [props.masterOptions, props.CreateUpdateEncounterSequence?.episodeList]);

  // Handler
  const handleDeleteEncounter = ({ username, password }: any = {}) => {
    props.runSequence({
      sequence: "CreateUpdateEncounter",
      action: "delete",
      username: username,
      password: password,
      note: note,
    });

    handleCloseDialog();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNote("");
  };

  const handlePrintVisitSlipForm = () => {
    props.runSequence({
      sequence: "CreateUpdateEncounter",
      action: "print_visit_slip_form",
      card: CARD_OPEN_ENCOUNTER,
    });
  };

  console.log("CardOpenEncounter", props);

  return (
    <div style={{ paddingBottom: "2rem", ...(props.cardStyle || {}) }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.runSequence({
            sequence: "CreateUpdateEncounter",
            action: "clear",
          });
        }}
        error={props.errorMessage?.[CARD_OPEN_ENCOUNTER]}
        success={null}
      />

      <ModAuthen
        titleName={"ระบุเหตุผลยกเลิก Encounter"}
        titleColor={"blue"}
        open={openDialog}
        onCloseWithDimmerClick={handleCloseDialog}
        onApprove={handleDeleteEncounter}
        onDeny={handleCloseDialog}
      >
        <Form.Field label="เหตุผลการยกเลิก" style={{ marginBottom: "0.5rem" }} />
        <Form.Field>
          <Input fluid={true} value={note} onChange={(e) => setNote(e.target.value)} />
        </Form.Field>
      </ModAuthen>

      <CardOpenEncounterUX
        // edit encounter
        isEditEncounter={props.isEditEncounter}
        useApiDashboard={props.useApiDashboard}
        // Data & Fields
        // appointment data
        appointmentData={appointmentList}
        headersEncounter={headersEncounter}
        // encounter
        showEncounterEditor={showEncounterEditor}
        onOpenEncounter={handleOpenEncounter}
        encounterList={encounterList}
        isShowPregnancy={isShowPregnancy}
        hideStudent={!CONFIG.CUDENT_ENCOUNTER_STUDENT}
        encounterData={props.CreateUpdateEncounterSequence}
        pregnancyData={pregnancyData}
        // options
        divisionOptions={props.masterOptions?.divisionNameCode || []}
        doctorOptions={props.masterOptions?.doctor || []}
        patientCaseOptions={props.masterOptions?.patientCase || []}
        episodeOptions={episodeOptions}
        // callback
        rowProps={handleRowProps}
        changeEncounter={changeEncounter}
        changePregnancy={changePregnancy}
        // Element
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSaveEncounter}
            // data
            paramKey={ACTION_SAVE}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SAVE]}
            // config
            color={selectedEncounter?.id ? "teal" : "green"}
            title={selectedEncounter?.id !== undefined ? "ล้างหน้าจอเพิ่ม Encounter" : "SAVE"}
            style={{ width: `100%`, minWidth: "max-content" }}
          />
        }
        buttonEdit={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEditEncounter}
            // data
            paramKey={ACTION_UPDATE}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_UPDATE]}
            disabled={disabledEditEncounter}
            // config
            color="orange"
            title="แก้ไข Encounter"
            style={{ width: `100%` }}
          />
        }
        appointmentRegisterButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={
              props.useApiDashboard
                ? handleSyncEncounterAppointment
                : handleOpenEncounterAppointment
            }
            // data
            paramKey={props.useApiDashboard ? ACTION_SYNC_EN_APP : ACTION_OPEN_EN_APP}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                props.useApiDashboard ? ACTION_SYNC_EN_APP : ACTION_OPEN_EN_APP
              ]
            }
            disabled={
              props.useApiDashboard
                ? !appointmentList?.length
                : !props.selectedAppointmentItems?.length
            }
            // config
            color="blue"
            name={BUTTON_ACTIONS.open_en_app}
            size="medium"
            title="ลงทะเบียนจากนัดหมาย"
          />
        }
        buttonVisitSlip={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrintVisitSlipForm}
            // data
            paramKey={ACTION_PRINT_VISIT_SLIP}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_PRINT_VISIT_SLIP]}
            disabled={!props.CreateUpdateEncounterSequence?.selectedEncounterItems?.length}
            // config
            color="yellow"
            name={BUTTON_ACTIONS.print_visit_slip_form}
            size="medium"
            title="พิมพ์บัตรเข้ารับการรักษา"
          />
        }
        noResultsMessageEpisode={<div style={{ textAlign: "center" }}>No episode items</div>}
      />

      <ModInfo
        open={openErrorVerified}
        titleColor={"red"}
        titleName={"ข้อความแจ้งเตือน"}
        btnText={"ตกลง"}
        buttonColor="green"
        onApprove={() => setOpenErrorVerified(false)}
        onClose={() => setOpenErrorVerified(false)}
      >
        <div style={{ padding: "0.5rem 0", fontWeight: "bold", lineHeight: 1.5 }}>
          <div>กรุณายืนยันประวัติผู้ป่วยก่อนเปิด Encounter</div>
        </div>
      </ModInfo>

      <ModInfo
        open={openErrorMessageDegree}
        titleColor={"red"}
        titleName={"ข้อความแจ้งเตือน"}
        btnText={"ปิดหน้าต่าง"}
        onApprove={() => setOpenErrorMessageDegree(false)}
        onClose={() => setOpenErrorMessageDegree(false)}
      >
        <div style={{ padding: "0.5rem 0", fontWeight: "bold", lineHeight: 1.5 }}>
          <div>ไม่สามารถทำการเปิด Encounter ได้</div>
          <div>กรุณาทำการระบุระดับของนิสิต/นักศึกษาแพทย์</div>
        </div>
      </ModInfo>
    </div>
  );
};

CardOpenEncounter.displayName = "CardOpenEncounter";

export default React.memo(CardOpenEncounter);
