import React, { useState, useEffect, useMemo, CSSProperties } from "react";
import { Button, Grid, Icon, Input, List, ListItem } from "semantic-ui-react";

// Common
import { DateTextBox, ModConfirm, ModInfo } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import CardSettingAppointmentReminderUX from "react-lib/apps/HISV3/HCU/CardSettingAppointmentReminderUX";

// Interface
import { RunSequence } from "./sequence/SettingPackage";
import { State } from "./sequence/SettingQueue";

// Types
type CardSettingAppointmentReminderProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  SettingQueueSequence?: State["SettingQueueSequence"];
  masterOptions?: Record<string, any>;
};

const styles = {
  cardError: {
    padding: "5px 15px",
    background: "#FADEDE",
    border: "1px solid #F3AFAE",
    borderRadius: "5px",
    boxShadow: " 0px 1px 2px",
    margin: "10px",
  } as CSSProperties,
};

export const CARD_SETTING_APPOINTMENT_REMINDER =
  "CardSettingAppointmentReminder";

const CardSettingAppointmentReminder = (
  props: CardSettingAppointmentReminderProps
) => {
  const [openAppointmentReminder, setOpenAppointmentReminder] =
    useState<boolean>(false);
  const [showErrorRequired, setShowErrorRequired] = useState<boolean>(false);

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "SettingQueue",
      restart: true,
    });
  }, []);

  useEffect(() => {
    if (props.SettingQueueSequence?.appointmentReminderOrder?.closeModal) {
      handleCloseAppointmentReminder();
    }
  }, [props.SettingQueueSequence?.appointmentReminderOrder?.closeModal]);

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(
      `SettingQueueSequence.appointmentReminderOrder.${key}`,
      v.value
    );
    handleSearchAppointmentReminder();
  };

  const handleChangeValueConfirmDay = (_event: any, data: any) => {
    props.setProp(
      `SettingQueueSequence.appointmentReminderOrder.confirmDay`,
      data.value
    );
  };

  const handleChangeSelectAllDivision = (_event: any, data: any) => {
    props.setProp(`SettingQueueSequence.appointmentReminderOrder`, {
      ...props.SettingQueueSequence?.appointmentReminderOrder,
      selectAllDivision: data.checked,
      selectDivision: [],
    });
  };

  const appointmentReminderQueue = useMemo(() => {
    return (
      props.SettingQueueSequence?.appointmentReminderOrder?.appointmentOrder ||
      []
    ).map((item: any) => ({
      ...item,
      division: (
        <div>
          {item?.divisions.map((division: any, number: any) => {
            if (item?.divisions.length === 1) {
              return props.masterOptions?.division?.find(
                (options: any) => options.value === division
              )?.text;
            } else {
              return `${
                props.masterOptions?.division?.find(
                  (options: any) => options.value === division
                )?.text
              } ${number + 1 !== item.divisions.length ? "," : ""} `;
            }
          })}
        </div>
      ),
      confirmDay: <div>{item.app_confirm_day}</div>,
      incoming: (
        <div>
          {item?.app_incoming?.hours.map((hours: any, number: any) => {
            if (item?.app_incoming?.hours.length === 1) {
              return hours;
            } else {
              return `${hours} ${
                number + 1 !== item.app_incoming?.hours.length ? "," : ""
              } `;
            }
          })}
        </div>
      ),
      edit: (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            setOpenAppointmentReminder(true);
            props.setProp(`SettingQueueSequence.appointmentReminderOrder`, {
              ...props.SettingQueueSequence?.appointmentReminderOrder,
              selectDivision: item?.divisions,
              confirmDay: item?.app_confirm_day,
              incomingData: item?.app_incoming?.hours,
              selectAppointmentReminderID: item?.id,
            });
          }}
        >
          <Icon name="edit outline" color="yellow" size="large" />
        </div>
      ),
      delete: (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            props.runSequence({
              sequence: "SettingQueue",
              action: "deleteAppointmentReminder",
              divisions: item?.divisions,
              app_confirm_day: item?.app_confirm_day,
              app_incoming: item?.app_incoming?.hours,
              selectAppointmentReminderID: item?.id,
            });
          }}
        >
          <Icon name="minus circle" color="red" size="large" />
        </div>
      ),
    }));
  }, [
    props.SettingQueueSequence?.appointmentReminderOrder?.appointmentOrder,
    props.masterOptions?.division,
  ]);

  const handleSearchAppointmentReminder = () => {
    let appointmentReminderData =
      props.SettingQueueSequence?.appointmentReminderOrder;

    props.runSequence({
      sequence: "SettingQueue",
      action: "searchAppointmentReminder",
      divisions: appointmentReminderData?.searchDivision,
    });
  };

  const handleSaveAppointmentReminder = () => {
    let appointmentReminderData =
      props.SettingQueueSequence?.appointmentReminderOrder;

    props.setProp(
      `SettingQueueSequence.appointmentReminderOrder.errorMessageAppointment`,
      null
    );

    // handleCloseAppointmentReminder();

    if (
      appointmentReminderData?.confirmDay == null ||
      appointmentReminderData?.confirmDay === 0
    ) {
      setShowErrorRequired(true);
      return;
    }

    props.runSequence({
      sequence: "SettingQueue",
      action: "saveAppointmentReminder",
      is_all_division: appointmentReminderData?.selectAllDivision,
      divisions: appointmentReminderData?.selectDivision,
      app_confirm_day: appointmentReminderData?.confirmDay,
      app_incoming: appointmentReminderData?.incomingData,
    });
  };

  const handleCloseError = () => {
    setShowErrorRequired(false);
  };

  const handleCloseAppointmentReminder = () => {
    setOpenAppointmentReminder(false);
    props.setProp(`SettingQueueSequence.appointmentReminderOrder`, {
      ...props.SettingQueueSequence?.appointmentReminderOrder,
      selectDivision: [],
      selectAllDivision: false,
      confirmDay: 0,
      incomingData: [0],
      selectAppointmentReminderID: null,
      errorMessageAppointment: null,
      closeModal: false,
    });
  };

  const handleShowDivisionError = () => {
    const errorList: any =
      props.SettingQueueSequence?.appointmentReminderOrder
        ?.errorMessageAppointment;

    const listName = errorList.map(
      (item: any, index: any) => item.division_name
    );
    return listName;
  };

  return (
    <div style={{ height: "90vh", overflow: "auto" }}>
      <CardSettingAppointmentReminderUX
        masterOptions={props.masterOptions}
        appointmentReminderOrder={
          props.SettingQueueSequence?.appointmentReminderOrder
        }
        handleChangeValue={handleChangeValue}
        handleChangeSelectAllDivision={handleChangeSelectAllDivision}
        handleChangeValueConfirmDay={handleChangeValueConfirmDay}
        onOpenAppointmentReminder={() => {
          setOpenAppointmentReminder(true);
          props.setProp(`SettingQueueSequence.appointmentReminderOrder`, {
            ...props.SettingQueueSequence?.appointmentReminderOrder,
            selectDivision: [],
            selectAllDivision: false,
            confirmDay: 0,
            incomingData: [0],
            selectAppointmentReminderID: null,
            errorMessageAppointment: null,
            closeModal: false,
          });
        }}
        onCloseAppointmentReminder={handleCloseAppointmentReminder}
        openAppointmentReminder={openAppointmentReminder}
        appointmentReminderQueue={appointmentReminderQueue}
        onSearchAppointmentReminder={handleSearchAppointmentReminder}
        onSaveAppointmentReminder={handleSaveAppointmentReminder}
        incomingData={
          <div>
            {props.SettingQueueSequence?.appointmentReminderOrder?.incomingData.map(
              (item: any, number: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      ...(number !== 0 && { marginTop: "20px" }),
                    }}
                  >
                    <div>
                      <Input
                        min="0"
                        onChange={(e: any, data: any) => {
                          props.setProp(
                            `SettingQueueSequence.appointmentReminderOrder.incomingData.${number}`,
                            data.value
                          );
                        }}
                        style={{ marginRight: "20px" }}
                        type="number"
                        value={item}
                      ></Input>
                    </div>
                    <label
                      style={{
                        fontWeight: "bold",
                        margin: "10px 20px 0px 0px",
                      }}
                    >
                      ชั่วโมง
                    </label>
                    <div style={{ display: "flex" }}>
                      <Button
                        icon="plus"
                        color="green"
                        onClick={() => {
                          props.setProp(
                            `SettingQueueSequence.appointmentReminderOrder.incomingData`,
                            [
                              ...props.SettingQueueSequence
                                ?.appointmentReminderOrder?.incomingData,
                              0,
                            ]
                          );
                        }}
                      ></Button>
                      <Button
                        icon="minus"
                        color="red"
                        style={{ ...(number === 0 && { display: "none" }) }}
                        onClick={() => {
                          let cloneIncomingData =
                            props.SettingQueueSequence?.appointmentReminderOrder
                              ?.incomingData;
                          cloneIncomingData.splice(number, 1);

                          props.setProp(
                            `SettingQueueSequence.appointmentReminderOrder.incomingData`,
                            cloneIncomingData
                          );
                        }}
                      ></Button>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        }
        errorRemind={
          <div style={{ marginBottom: "2rem" }}>
            {props.SettingQueueSequence?.appointmentReminderOrder
              ?.errorMessageAppointment && (
              <div style={styles.cardError}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "red",
                      marginRight: "10px",
                    }}
                  >
                    {"บันทึกไม่สำเสร็จ"}
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    {
                      "มีรายการแผนกที่บันทึกการตั้งค่าแจ้งเตือนไว้ในระบบแล้ว ได้แก่"
                    }
                  </div>
                </div>
                <div>
                  <List bulleted>
                    <ListItem style={{ fontSize: "12px" }}>
                      {handleShowDivisionError().join(" , ")}
                    </ListItem>
                  </List>
                </div>
              </div>
            )}
          </div>
        }
      />

      <ModInfo
        open={showErrorRequired}
        titleColor="red"
        titleName="บันทึกไม่สำเร็จ"
        btnText="ปิด"
        children={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
              lineHeight: 1.75,
              textAlign: "left",
            }}
          >
            <label style={{ fontSize: "14px" }}>{"จำเป็นต้องระบุ"}</label>
            <label style={{ color: "red", fontSize: "14px" }}>
              {"จำนวนวันแจ้งยืนยันนัดหมาย และ ไม่อนุญาตให้ระบุจำนวนวันเป็น 0"}
            </label>
          </div>
        }
        onApprove={handleCloseError}
        onClose={handleCloseError}
      ></ModInfo>
    </div>
  );
};

export default React.memo(CardSettingAppointmentReminder);
