import React from "react";
import { Button, Header } from "semantic-ui-react";
import moment from "moment";
import * as Util from "../../../utils/tsUtils";
import { useHistory } from "react-router-dom";
import {Redirect} from 'react-router-dom';
interface Props {
  detail?: {
    id?: number | string;
    price?: number | string;
    edited?: string;
    status?: string;
  };
}

const SubPatientPaymentStatus = React.forwardRef((props: Props, ref) => {
  const history = useHistory();

  console.log(props)

  return (
    <div className="sub-patient-payment">
      <div className="content-row">
        <b>เวลาที่เข้ารับการรักษา</b>
        <div>
          {Util.convertToBEDate({
            date: moment(props.detail.edited).format("DD/MM/YYYY HH:mm"),
            startFormat: "DD/MM/YYYY HH:mm",
            endFormat: "DD/MM/YYYY HH:mm"
          })}
        </div>
      </div>
      <br />
      <div className="content-row">
        <b>ยอดที่ต้องชำระ</b>
        <div>{`${Util.formatComma(props.detail.price)} ฿`}</div>
      </div>
      {/* <br />
      <div className="content-row">
        <b>สถานะการจัดส่งยา</b>
        <b className="price-text">{props.detail.status_label}</b>
      </div> */}
      <br />
      {props.detail.receipt_url && <div className="pay-btn">
        <Button
          content="Download เอกสาร"
          compact
          onClick={() => {
            // return <Redirect  to={props.detail.receipt_url} />
            window.location = props.detail.receipt_url
            // history.push(props.detail.receipt_url)
            // window.open(props.detail.receipt_url)
          }}
        />
      </div>}
      {props.detail.status_name === "CANCELED" && <div className="pay-btn">
        <Button
          content="ไม่ต้องชำระ"
          compact
          onClick={() => {
            history.push(`/paymentDetail/${props.detail.id}/`);
          }}
        />
      </div>}
      {}
    </div>
  );
});

export default React.memo(SubPatientPaymentStatus);
