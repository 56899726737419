import React, { useEffect, useMemo, useState } from "react";
import CardDrugOrderListUX from "react-lib/apps/HISV3/TPD/CardDrugOrderListUX";
import CardAuditDrugOrderUX from "react-lib/apps/HISV3/TPD/CardAuditDrugOrderUX";

const CardDrugOrderList = (props: any) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPrescriptionCode, setSelectedPrescriptionCode] = useState(null)
  const [prescription, setPrescription] = useState<any[]>([]);
  useEffect(() => {
    props.runSequence({ sequence: "DrugOrderList", restart: true });
    return () => {
      props.runSequence({ sequence: "DrugOrderList", clear: true });
    };
  }, []);

  const getTrPropsDrugOrderList = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      onClick: (event: any) => {
        console.log('getTrPropsDrugOrderList event: ', event);
        console.log('getTrPropsDrugOrderList rowInfo: ', rowInfo);
        let prescriptionCode = rowInfo?.original?.prescription_code;
        console.log("getTrPropsDrugOrderList prescription_code: ", prescriptionCode);
        // setSelectedRow(rowId);
        setSelectedPrescriptionCode(prescriptionCode)
      },

      className: rowInfo?.original?.prescription_code === selectedPrescriptionCode ? "blueSelectedRow" : "",
      style: { cursor: "pointer" },
    };
  };

  useEffect(() => {
    // Call API
    // if (selectedRow) {
    //   props.runSequence({
    //     sequence: "DrugOrderList",
    //     action: "selectDrugId",
    //     rowId: selectedRow,
    //   });
    // }
  }, [selectedPrescriptionCode]);

  useEffect(() => {
    console.log('useEffect props.DrugOrderListSequence?.drugOrders: ', props.DrugOrderListSequence?.drugOrders);
    if (
      Array.isArray(props.DrugOrderListSequence?.drugOrders) ||
      props.DrugOrderListSequence?.drugOrders?.length > 0
    ) {
      // setPrescription
      let allPrescriptionCode: any[] = (
        props.DrugOrderListSequence?.drugOrders || []
      )?.map((item: any) => item.prescription_code);
      let uniquePrescriptionCode = Array.from(new Set(allPrescriptionCode));


      uniquePrescriptionCode = uniquePrescriptionCode.map((prescriptionCode: any) => {

        // วันที่ออกใบยา, requested
        // สถานะใบยา, prescription_code
        // สถานะใบยา, status
        // ประเภทคนไข้, patient
        // แพทย์ที่สั่ง, order_by_name
        // แผนก , order_div_name
        // ห้องยา , requester_name

        let collected = props.DrugOrderListSequence?.drugOrders?.filter( (drugs: any) => drugs.prescription_code === prescriptionCode) 
        let orderDateList = collected?.map((i: any) => i.order_date)
        orderDateList = Array.from(new Set(orderDateList))?.join(",")
        let statusList = "-"
        let patinentList = "-"
        let orderByNameList = collected?.map((i: any) => i.doctor)
        orderByNameList = Array.from(new Set(orderByNameList))?.join(",")
        let orderDivNameList = "-"
        let requesterNameList = "-"

        return {
          prescription_code: prescriptionCode,
          requested: orderDateList,
          status: statusList,
          patient: patinentList,
          order_by_name: orderByNameList,
          order_div_name: orderDivNameList,
          requester_name: requesterNameList,
          items: props.DrugOrderListSequence?.drugOrders?.filter(
            (i: any) => i.prescription_code === prescriptionCode
          ),
        };
      });

      console.log('useEffect uniquePrescriptionCode: ', uniquePrescriptionCode);
      setPrescription(uniquePrescriptionCode);
    }
  }, [props.DrugOrderListSequence?.drugOrders]);


  console.log('CardDrugOrderList props.DrugOrderListSequence?.drugs?.items: ',  props.DrugOrderListSequence?.drugs?.items);
  console.log('CardDrugOrderList props.DrugOrderListSequence?.drugs: ',  props.DrugOrderListSequence?.drugs);
  return (
    <>
      <CardDrugOrderListUX
        data={prescription}
        getTrProps={getTrPropsDrugOrderList}
      />
      <CardAuditDrugOrderUX
        data={prescription?.find((pre : any) => pre?.prescription_code === selectedPrescriptionCode)?.items || []}
      />
    </>
  );
};

export default CardDrugOrderList;
