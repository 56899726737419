import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Label,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDrugResuscitationAdderUx = (props: any) => {
    return(
      <div
        style={{margin: "10px", backgroundColor: "#F3F3F3", height:"90%"}}>
        <div
          style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
          
          <label
            className="header"
            style={{fontWeight: "bold", fontSize: "20px", marginLeft: "4px"}}>
            {props.containerName === "adder" ? "สร้างใบจัดกล่องยาสำรอง":  "ใบจัดกล่องยาสำรอง" }
          </label>
          <div
            style={{flex:1}}>
            
          </div>
          <Label
            color="red"
            style={{ display: props.DrugResuscitationSequence?.selectedBox?.is_expired ? "flex" : "none"}}>
            {props.DrugResuscitationSequence?.selectedBox?.is_expired && "Drug Expired"}
          </Label>
          <Label
            color={props.DrugResuscitationSequence?.selectedBox?.status_name === "ACTIVE" ?  "green" : "grey"}
            style={{width:"auto", marginLeft: "auto", ...(!props.DrugResuscitationSequence?.selectedBox?.status_name && {display: "none"})}}>
            {props.DrugResuscitationSequence?.selectedBox?.status_name || ""}
          </Label>
        </div>
        <div>
          
          <hr>
          </hr>
        </div>
        <div
          style={{display: "flex", alignItems: "center", margin: "14px 0px"}}>
          
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 0px 0px 10px"}}>
            เลขที่ตัวล็อคกล่อง
          </label>
          <label
            style={{ color: "red", margin: "0px 10px 0px 0px"}}>
            *
          </label>
          <Input
            name="break_code"
            onChange={props.handleChangeData}
            value={props.DrugResuscitationSequence?.break_code  || ""}>
          </Input>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            เลขที่กล่อง
          </label>
          <Input
            name="container_code"
            onChange={props.handleChangeData}
            readOnly={props.DrugResuscitationSequence?.selectedBox?.status_name === "APPROVED" }
            value={props.DrugResuscitationSequence?.container_code  || ""}>
          </Input>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            ชื่อกล่องยา
          </label>
          <Input
            name="container_name"
            onChange={props.handleChangeData}
            readOnly={props.DrugResuscitationSequence?.selectedBox?.status_name === "APPROVED" }
            style={{margin: "0px 10px 0px 0px"}}
            value={props.DrugResuscitationSequence?.container_name  || ""}>
          </Input>
          <div>
            {props.buttonSaveBox}
          </div>
          <div
            style={{flex:1}}>
            
          </div>
          <Button
            color="blue"
            disabled={props.containerName === "adder"}
            onClick={props.addDrugList}
            size="small"
            style={{ ...( props.DrugResuscitationSequence?.selectedBox?.status_name === "ACTIVE" && {display: "none"} )  }}>
            เพิ่มรายการยา
          </Button>
        </div>
        <div
          style={{display: props.hideDrugList ? "none": "", border:"1px solid #ccc", borderRadius: "8px", padding: "0px 8px 4px 10px"}}>
          
          <div
            style={{display: "flex", alignItems: "center", margin: "14px 0px 0px 14px", fontWeight: "bold", fontSize: "16px"}}>
            รายการยาสำรอง
            <Label
              color="yellow"
              style={{margin: "0px 0px 0px 14px", ...(!props.DrugResuscitationSequence?.selectedBox?.code && {display: "none"}) }}>
              {props.DrugResuscitationSequence?.selectedBox?.code || ""}
            </Label>
          </div>
          <div
            style={{display: "flex", alignItems: "center", margin: "10px 0px 0px 14px", ...( props.DrugResuscitationSequence?.selectedBox?.status_name === "ACTIVE" && {display: "none"} )}}>
            
            <label
              style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 0px 0px 0px"}}>
              ค้นหายา
            </label>
            <label
              style={{ color: "red", margin: "0px 10px 0px 0px"}}>
              *
            </label>
            <div
              style={{flex:1}}>
              
              <Input
                fluid={true}
                onChange={props.onDrugSearchTextChange}
                value={props.data?.drugSelected?.full_name || props.data?.drugSearch || ""}>
              </Input>
              <div
                style={{ display: props.showSearchResult ? "block" : "none", position: "absolute", zIndex: 100, width: "80%", height: "400px" } }>
                
                <Table
                  data={props.drugSearchResult}
                  getTrProps={props.drugRowProps}
                  headers="Generic Name, Drug Name,Form,Strength,Contain,หน่วยขาย"
                  keys="generic_name,drug_name,dosage_form_name,strength,container,stock_unit_name"
                  minRows={8}
                  showPagination="false"
                  style={{ height: "250px" }}>
                </Table>
              </div>
            </div>
            <label
              style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 0px 0px 10px"}}>
              จำนวนยาทั้งหมดในกล่อง
            </label>
            <label
              style={{ color: "red", margin: "0px 10px 0px 0px"}}>
              *
            </label>
            <div>
              {props.inputQty}
            </div>
            <label
              style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
              วันหมดอายุ
            </label>
            <DateTextBox
              onChange={props.onChangDateExpire}
              style={{margin: "0px 10px 0px 0px"}}
              value={props.data?.expire}>
            </DateTextBox>
            <Button
              color="green"
              disabled={props.data?.qty && props.data?.drugSelected?.full_name ? false : true}
              onClick={props.addToListClick}
              size="small">
              เพิ่มรายการ
            </Button>
            <div
              style={{flex:0}}>
              
            </div>
          </div>
          <div
            style={{ height: "40%", position: "relative", padding: "15px" }}>
            
            <Table
              columns={props.columnsDrugItem}
              data={props.drugItems}
              minRows={8}
              showPagination={false}
              style={{fontSize: "medium", height: "25vh"}}>
            </Table>
          </div>
          <div
            style={{display: "flex", alignItems: "center", margin: "14px 0px"}}>
            
            <Button
              onClick={props.handleGetDrugActionLog}
              size="small"
              style={{ display: props.DrugResuscitationSequence?.selectedBox?.status_name==="INACTIVE" ? "none" : "flex"}}>
              ประวัติการจ่ายยา
            </Button>
            <Button
              onClick={props.printClick}
              size="small">
              พิมพ์สติกเกอร์
            </Button>
            <div
              style={{flex:1}}>
              
            </div>
            <label
              style={{ display: props.showSendBox ? "flex" : "none"}}>
              ระบุแผนกที่รับกล่องยา
            </label>
            <label
              style={{ color: "red", margin: "0px 10px 0px 0px", display: props.showSendBox ? "flex" : "none"}}>
              *
            </label>
            <Dropdown
              onChange={props.handleChangeValue}
              options={props?.divisionOptions}
              search={true}
              selection={true}
              style={{ display: props.showSendBox ? "flex" : "none"}}
              value={props.DrugResuscitationSequence?.destinationDivision}>
            </Dropdown>
            <Button
              color="blue"
              disabled={props.DrugResuscitationSequence?.destinationDivision ? false : true}
              onClick={props.sendBox}
              size="small"
              style={{display: props.showSendBox ? "flex" : "none", margin: "0px 10px 0px 5px"}}>
              จ่ายกล่องยา
            </Button>
            <Button
              color="blue"
              onClick={props.returnBox}
              size="small"
              style={{display: props.showReturnBox ? "flex" : "none", margin: "0px 10px 0px 5px", ...( props.DrugResuscitationSequence?.selectedBox?.status_name === "ACTIVE" && {display: "none"} )}}>
              คืนกล่องยา
            </Button>
            <div>
              {props.buttonSaveDrugItem}
            </div>
            <div>
              {props.buttonReplace}
            </div>
            <Button
              color="red"
              onClick={props.addDrugList}
              size="small"
              style={{ ...( props.DrugResuscitationSequence?.selectedBox?.status_name === "ACTIVE" && {display: "none"} ) }}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardDrugResuscitationAdderUx

export const screenPropsDefault = {}

/* Date Time : Mon Jun 10 2024 15:35:42 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", backgroundColor: \"#F3F3F3\", height:\"90%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "hr",
      "parent": 6,
      "props": {
      },
      "seq": 7,
      "void": true
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px 0px 14px\", ...( props.DrugResuscitationSequence?.selectedBox?.status_name === \"ACTIVE\" && {display: \"none\"} )}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Input",
      "parent": 54,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onDrugSearchTextChange"
        },
        "value": {
          "type": "code",
          "value": "props.data?.drugSelected?.full_name || props.data?.drugSearch || \"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"40%\", position: \"relative\", padding: \"15px\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 14,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsDrugItem"
        },
        "data": {
          "type": "code",
          "value": "props.drugItems"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "code",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"medium\", height: \"25vh\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.containerName === \"adder\" ? \"สร้างใบจัดกล่องยาสำรอง\":  \"ใบจัดกล่องยาสำรอง\" "
        },
        "className": {
          "type": "value",
          "value": "header"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"20px\", marginLeft: \"4px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.is_expired && \"Drug Expired\""
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugResuscitationSequence?.selectedBox?.is_expired ? \"flex\" : \"none\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหายา"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 0px 0px 0px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนยาทั้งหมดในกล่อง"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 0px 0px 10px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "วันหมดอายุ"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการ"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.qty && props.data?.drugSelected?.full_name ? false : true"
        },
        "onClick": {
          "type": "code",
          "value": "props.addToListClick"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:0}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", margin: \"0px 10px 0px 0px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยาสำรอง"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"14px 0px 0px 14px\", fontWeight: \"bold\", fontSize: \"16px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"14px 0px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ตัวล็อคกล่อง"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 0px 0px 10px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", margin: \"0px 10px 0px 0px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 29,
      "props": {
        "name": {
          "type": "value",
          "value": "break_code"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.break_code  || \"\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่กล่อง"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Input",
      "parent": 29,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "container_code"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.status_name === \"APPROVED\" "
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.container_code  || \"\""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อกล่องยา"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Input",
      "parent": 29,
      "props": {
        "name": {
          "type": "value",
          "value": "container_name"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.status_name === \"APPROVED\" "
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 0px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.container_name  || \"\""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Button",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการยา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.containerName === \"adder\""
        },
        "onClick": {
          "type": "code",
          "value": "props.addDrugList"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ ...( props.DrugResuscitationSequence?.selectedBox?.status_name === \"ACTIVE\" && {display: \"none\"} )  }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", margin: \"0px 10px 0px 0px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"14px 0px\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์สติกเกอร์"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.printClick"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.addDrugList"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ ...( props.DrugResuscitationSequence?.selectedBox?.status_name === \"ACTIVE\" && {display: \"none\"} ) }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideDrugList ? \"none\": \"\", border:\"1px solid #ccc\", borderRadius: \"8px\", padding: \"0px 8px 4px 10px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showSearchResult ? \"block\" : \"none\", position: \"absolute\", zIndex: 100, width: \"80%\", height: \"400px\" } "
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 56,
      "name": "Table",
      "parent": 55,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugSearchResult"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Generic Name, Drug Name,Form,Strength,Contain,หน่วยขาย"
        },
        "keys": {
          "type": "value",
          "value": "generic_name,drug_name,dosage_form_name,strength,container,stock_unit_name"
        },
        "minRows": {
          "type": "code",
          "value": "8"
        },
        "showPagination": {
          "type": "value",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"250px\" }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 57,
      "name": "DateTextBox",
      "parent": 8,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangDateExpire"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 0px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.expire"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "จ่ายกล่องยา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.destinationDivision ? false : true"
        },
        "onClick": {
          "type": "code",
          "value": "props.sendBox"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showSendBox ? \"flex\" : \"none\", margin: \"0px 10px 0px 5px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Dropdown",
      "parent": 41,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props?.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showSendBox ? \"flex\" : \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.destinationDivision"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการจ่ายยา"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.handleGetDrugActionLog"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugResuscitationSequence?.selectedBox?.status_name===\"INACTIVE\" ? \"none\" : \"flex\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.status_name || \"\""
        },
        "color": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.status_name === \"ACTIVE\" ?  \"green\" : \"grey\""
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\", marginLeft: \"auto\", ...(!props.DrugResuscitationSequence?.selectedBox?.status_name && {display: \"none\"})}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนกล่องยา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.returnBox"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showReturnBox ? \"flex\" : \"none\", margin: \"0px 10px 0px 5px\", ...( props.DrugResuscitationSequence?.selectedBox?.status_name === \"ACTIVE\" && {display: \"none\"} )}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveDrugItem"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReplace"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Label",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.code || \"\""
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 0px 0px 14px\", ...(!props.DrugResuscitationSequence?.selectedBox?.code && {display: \"none\"}) }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุแผนกที่รับกล่องยา"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showSendBox ? \"flex\" : \"none\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", margin: \"0px 10px 0px 0px\", display: props.showSendBox ? \"flex\" : \"none\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveBox"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.inputQty"
        }
      },
      "seq": 23,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugResuscitationAdderUx",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
