import React, { useEffect } from "react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import SearchBox from "react-lib/appcon/common/SearchBox";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI ADM
import CardInpatientDiagnosisUX from "./CardInpatientDiagnosisUX";

const CARD_KEY: string = "CardInpatientDiagnosis";

type CardInpatientDiagnosisProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // seq
  runSequence?: any;
  InpatientDiagnosisSequence?: any;

  // SearchBox
  searchedItemList?: any;
};

const CardInpatientDiagnosis = (props: CardInpatientDiagnosisProps) => {
  useEffect(() => {
    props.runSequence({
      sequence: "InpatientDiagnosis",
      restart: true,
    });
  }, []);

  // filter
  const handleSelectedIcd10 = (item: any) => {
    let data: any = {
      selectedIcd10: item,
      icd10: item?.icd10_id || null,
    };
    if (item?.icdcode) {
      data.checkedIcd10Code = true;
    }
    if (item?.icdterm) {
      data.checkedIcd10Term = true;
    }
    props.runSequence({
      sequence: "InpatientDiagnosis",
      action: "SET_FILTER",
      data: data,
    });
  };

  const handleChangeFilter = (_event: any, data: any) => {
    let value: any = data.value;
    if (data.type === "checkbox") {
      value = data.checked;
    }
    props.runSequence({
      sequence: "InpatientDiagnosis",
      action: "SET_FILTER",
      data: { [data.name]: value },
    });
  };

  // button
  const handelSearch = () => {
    props.runSequence({
      sequence: "InpatientDiagnosis",
      action: "FETCH",
      cardKey: CARD_KEY,
    });
  };

  const handleClear = () => {
    props.runSequence({
      sequence: "InpatientDiagnosis",
      action: "CLEAR_FILTER",
    });
  };

  const handelPrint = () => {
    props.runSequence({
      sequence: "InpatientDiagnosis",
      action: "PRINT",
      cardKey: CARD_KEY,
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
      />

      <CardInpatientDiagnosisUX
        // function
        onChangeFilter={handleChangeFilter}
        onClear={handleClear}
        // seq
        InpatientDiagnosisSequence={props.InpatientDiagnosisSequence}
        // component
        startDateTextBox={
          <DateTextBox
            value={
              props.InpatientDiagnosisSequence?.diagFilter?.start_discharge_date
            }
            fluid={true}
            inputStyle={{ width: "130px" }}
            onChange={(date: string) => {
              handleChangeFilter(null, {
                name: "start_discharge_date",
                value: date,
              });
            }}
          />
        }
        endDateTextBox={
          <DateTextBox
            value={
              props.InpatientDiagnosisSequence?.diagFilter?.end_discharge_date
            }
            fluid={true}
            inputStyle={{ width: "130px" }}
            onChange={(date: string) => {
              handleChangeFilter(null, {
                name: "end_discharge_date",
                value: date,
              });
            }}
          />
        }
        icd10CodeSearchBox={
          <SearchBox
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={
              props.InpatientDiagnosisSequence?.diagFilter?.selectedIcd10 ||
              null
            }
            setSelectedItem={handleSelectedIcd10}
            type="ICD10Code"
            toDisplay={(item: any) => item.icdcode}
            disabled={
              !props.InpatientDiagnosisSequence?.diagFilter?.checkedIcd10Code
            }
            style={{ width: "250px" }}
            fluid={true}
          />
        }
        icd10TermSearchBox={
          <SearchBox
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={
              props.InpatientDiagnosisSequence?.diagFilter?.selectedIcd10 ||
              null
            }
            setSelectedItem={handleSelectedIcd10}
            type="ICD10Term"
            toDisplay={(item: any) => item.icdterm}
            disabled={
              !props.InpatientDiagnosisSequence?.diagFilter?.checkedIcd10Term
            }
            style={{ width: "250px" }}
            fluid={true}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handelSearch}
            // data
            paramKey={`${CARD_KEY}_FETCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_FETCH`]}
            // config
            color="blue"
            size="small"
            title="แสดง"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handelPrint}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="green"
            disabled={
              !(props.InpatientDiagnosisSequence?.diagStatList?.length > 0)
            }
            size="small"
            title="พิมพ์รายงาน"
          />
        }
      />
    </>
  );
};

export default React.memo(CardInpatientDiagnosis);
