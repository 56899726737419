// Utils
import { adToBeWithSetFormat } from "react-lib/utils/dateUtils";

// Types
type FormSalarySlipProps = {
  // header
  doctor_name: string;
  doctor_position: string;
  seq: number;

  // รายได้
  items: {
    compensation_percent: number;
    final_revenue_price: number;
    lab_price: number;
    left_over_revenue: number;
    management_fee: number;
    management_fee_percent: number;
    name: string;
    revenue_after_lab: number;
    revenue_price: number;
  }[];

  // summary
  adjust_down_price: number;
  adjust_up_price: number;
  fee_price: number;
  final_revenue_price: number;
  income_tax_price: number;
  net_income_price: number;
  overdue_price: number;
  sum_income_price: number;
  showSummary: boolean;

  // footer
  end_date: string;
  start_date: string;
};

const getSummarySalary = (props: FormSalarySlipProps) => {
  if (props.showSummary) {
    return [
      {
        marginTop: 10,
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              {
                border: [false, true, false, true],
                margin: [20, 2.5, 20, 0],
                stack: [
                  [
                    { label: "รายได้งวดนี้", value: props.final_revenue_price },
                    { label: "ปรับลด", value: props.adjust_down_price },
                  ],
                  [
                    { label: "ค้างจ่าย", value: props.overdue_price },
                    { label: "หักภาษี", value: props.income_tax_price },
                  ],
                  [
                    { label: "ปรับเพิ่ม", value: props.adjust_up_price },
                    { label: "หักค่าธรรมเนียม", value: props.fee_price },
                  ],
                  [
                    { label: "รายได้", value: props.sum_income_price },
                    { label: "รับสุทธิ", value: props.net_income_price },
                  ],
                ].map((item, index) => ({
                  columns: [
                    {
                      columns: [
                        { text: item[0].label, width: "*" },
                        {
                          alignment: "right",
                          text: item[0].value.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "decimal",
                          }),
                          width: "auto",
                        },
                      ],
                      width: "45%",
                    },
                    {
                      text: "",
                      width: "12.5%",
                    },
                    {
                      columns: [
                        { text: item[1].label, width: "*" },
                        {
                          alignment: "right",
                          text: item[1].value.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "decimal",
                          }),
                          width: "auto",
                        },
                      ],
                      width: "42.5%",
                    },
                  ],
                  marginTop: index > 0 ? 2.5 : 0,
                })),
              },
            ],
          ],
        },
      },
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              {
                border: [false, false, false, true],
                margin: [20, 2.5, 20, 0],
                stack: [
                  {
                    label: "งวดประจำวันที่",
                    value: `${adToBeWithSetFormat(
                      props.start_date,
                      "",
                      "DD MMMM YYYY",
                      "th"
                    )} - ${adToBeWithSetFormat(props.end_date, "", "DD MMMM YYYY", "th")}`,
                  },
                  {
                    label: "หน่วยการเงินโรงพยาบาลคณะฯ",
                    value: "โทร 02-2188838-40 ( น.ส หทัยภรณ์ อินทร์โน )",
                  },
                ].map((item, index) => ({
                  columns: [
                    {
                      text: item.label,
                      width: "30%",
                    },
                    {
                      text: item.value,
                      width: "*",
                    },
                  ],
                  marginTop: index > 0 ? 2.5 : 0,
                })),
              },
            ],
          ],
        },
      },
    ]
  } else {
    return [
      {
        text: ` `,
        preserverLeadingSpaces: true,
      }
    ]
  }
};

const FONT_SIZE = 16;

const FormSalarySlip = (props: FormSalarySlipProps) => {
  console.log("FormSalarySlip", props);

  return {
    defaultStyle: {
      font: "THSarabunNew",
      fontSize: FONT_SIZE,
      lineHeight: 1,
    },
    pageMargins: [15, 95, 15, 22.5],
    pageSize: "A4",
    styles: {
      fieldHeader: {
        fontSize: 17,
      },
      footerValue: {
        fontSize: 16,
      },
      miniField: {
        fontSize: 12,
      },
      tableHeader: {
        alignment: "center",
        bold: false,
      },
    },
    header: () => ({
      margin: [15, 6.5, 15, 0],
      stack: [
        {
          table: {
            headerRows: 1,
            widths: ["100%"],
            body: [
              [
                {
                  border: [false, false, false, true],
                  columns: [
                    {
                      stack: [
                        {
                          bold: true,
                          fontSize: 18,
                          style: "fieldHeader",
                          text: "ใบจ่ายเงินเดือน",
                        },
                        {
                          marginTop: 2.5,
                          style: "fieldHeader",
                          text: "วันที่จ่าย",
                        },
                      ],
                      width: "auto",
                    },
                    {
                      marginLeft: 140,
                      stack: [
                        {
                          bold: true,
                          style: "fieldHeader",
                          text: "คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                        },
                        {
                          marginTop: 5,
                          preserveLeadingSpaces: true,
                          text: [
                            { text: `ตำแหน่ง${Array.from({ length: 10 }).fill("").join(" ")}` },
                            { text: props.doctor_position },
                          ],
                        },
                        {
                          marginTop: 2.5,
                          style: "fieldHeader",
                          text: `ชื่อ - นามสกุล ${props.doctor_name}`,
                        },
                      ],
                      width: "*",
                    },
                    {
                      stack: [
                        { text: "\u00A0" },
                        { marginTop: 5, text: "\u00A0" },
                        { margin: [0, 5, 30, 0], text: `ลำดับที่ ${props.seq}` },
                      ],
                      width: "auto",
                    },
                  ],
                  marginTop: 5,
                },
              ],
            ],
          },
        },
      ],
    }),
    content: [
      ...props.items.map((item, pIndex) => ({
        margin: [25, pIndex === 0 ? 0 : 15, 25, 0],
        stack: [
          { label: item.name, value: item.revenue_price }, // ประเภทลงตรวจแพทย์
          { label: "หักค่าแลป", value: item.lab_price },
          { label: "รายได้หลังหักค่าแลป", value: item.revenue_after_lab },
          {
            label: `หักค่าบริหาร${Array.from({ length: 10 }).fill("").join(" ")}20${Array.from({
              length: 5,
            })
              .fill("")
              .join(" ")}%`,
            value: item.management_fee_percent,
          },
          { label: "รายได้หลังหักค่าบริหาร", value: item.left_over_revenue },
          {
            label: `ส่วนแบ่ง${Array.from({ length: 15 }).fill("").join(" ")}${
              item.compensation_percent
            }${Array.from({
              length: 5,
            })
              .fill("")
              .join(" ")}%`,
            value: item.final_revenue_price,
          },
        ].map((item, cIdex) => ({
          columns: [
            { text: cIdex > 0 ? "" : "รายได้", width: 45 },
            { preserveLeadingSpaces: true, text: item.label, width: "*" },
            {
              text: item.value.toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "decimal",
              }),
              width: "auto",
            },
          ],
          marginTop: cIdex > 0 ? 6.5 : 0,
        })),
      })),
      // Summary
      ...getSummarySalary(props),
    ],
  };
};

export default FormSalarySlip;
