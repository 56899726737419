import React from "react";
import { CARD_DRUG_CONT_ID } from "./CardDrugContinuePlan";
import ModSpecialDirectionUX from "./ModSpecialDirectionUX";

type ModSpecialDirectionProps = {
  // function
  onEvent: any;

  // seq
  DrugSelectSequence?: any;

  // data
  hideDuration?: boolean;
  hideWeekday?: boolean;
  hideAdminTime?: boolean;
  hideIntraveneous?: boolean;
};

const ModSpecialDirectionInitial: ModSpecialDirectionProps = {
  // funtion
  onEvent: () => null,

  // seq
  DrugSelectSequence: {},

  // data
  hideDuration: false,
  hideWeekday: false,
  hideAdminTime: false,
  hideIntraveneous: false,
};

const ModSpecialDirection: React.FC<ModSpecialDirectionProps> = (
  props: any
) => {
  const handleChangeCheckbox = (_event: any, data: any) => {
    let result: any[] = [...(props.DrugSelectSequence?.admin_time || [])];

    if (data.checked) {
      result.push(data.name);
    } else {
      const index: number = result.indexOf(data.name);
      if (index >= 0) {
        result.splice(index, 1);
      }
    }
    props.onEvent({
      message: "PreviewDrugContinuePlan",
      params: {
        name: "admin_time",
        value: result,
        card: CARD_DRUG_CONT_ID,
      },
    });
  };

  const handleChangeInput = (_event: any, data: any) => {
    props.onEvent({
      message: "PreviewDrugContinuePlan",
      params: {
        name: data.name,
        value: data.value,
        card: CARD_DRUG_CONT_ID,
      },
    });
  };

  const handleCloseMod = () => {
    props.onEvent({
      message: "SetModSpecialDirectionConfig",
      params: { name: "open", value: false },
    });
  };

  return (
    <ModSpecialDirectionUX
      // function
      onClose={handleCloseMod}
      onChangeCheckbox={handleChangeCheckbox}
      onChangeInput={handleChangeInput}
      // seq
      DrugSelectSequence={props.DrugSelectSequence}
      // data
      hideDuration={props.hideDuration}
      hideWeekday={props.hideWeekday}
      hideAdminTime={props.hideAdminTime}
      hideIntraveneous={props.hideIntraveneous}
    />
  );
};

ModSpecialDirection.defaultProps = ModSpecialDirectionInitial;

export default React.memo(ModSpecialDirection);
