import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Icon, Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import CardSelectDateTimeOperationUX from "./CardSelectDateTimeOperationUX";
import ModSelectOperatingDateTime from "react-lib/apps/HISV3/ORM/ModSelectOperatingDateTime";
import CardOperatingCalendar, {
  getTimeSlotRange, roundTime,
} from "./CardOperatingCalendar";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";
import ModInfo from "react-lib/apps/common/ModInfo";

const CARD_SELECT_DATE_TIME_OPERATION = "CardSelectDateTimeOperation";

const CURRENT_DATE = moment().format("YYYY-MM-DD");
const START_WEEK_DATE = moment().startOf("week").toDate()
const END_WEEK_DATE = moment().endOf("week").toDate();

const CardSelectDateTimeOperation = (props: any) => {
  // ORM
  // Mod
  const [openModOperatingDateTime, setOpenModOperatingDateTime] =
    useState<boolean>(false);
  const [openModDetail, setOpenModDetail] = useState<boolean>(false);
  const [modError, setModError] = useState<string>("");
  // Filter
  const [doctor, setDoctor] = useState<any>(null);
  const [anesthesiologist, setAnesthesiologist] = useState<any>(null);
  const [room, setRoom] = useState<any[]>([]);
  const [filterDoctor, setFilterDocter] = useState<boolean>(true);
  const [filterAnesthetic, setFilterAnesthetoc] = useState<boolean>(true);
  // loading
  const [loadingTimeSlot, setLoadingTimeSlot] = useState<boolean>(false);
  const [operatingId, setOperatingId] = useState<any>(null);
  const [selectedOperatingDetail, setSelectedOperatingDetail] =
    useState<any>(null);
  // ---
  const [calendarView, setCalendarView] = useState<"day" | "week" | "month">(
    "week"
  );
  const [rangeDate, setRangeDate] = useState<{
    start: Date;
    end: Date;
  }>({
    start: START_WEEK_DATE,
    end: END_WEEK_DATE,
  });

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "OperatingDateTime", restart: true });
    return () => {
      props.runSequence({ sequence: "OperatingDateTime", clear: true });
    };
  }, []);

  useEffect(() => {
    if (
      props.OperatingDateTimeSequence?.sequenceIndex === "Action" &&
      props.selectedDoctor
    ) {
      const params: any = {
        doctor: props.selectedDoctor,
        anesthesiologist: null,
        room: [],
        start_date: moment(rangeDate.start).format("YYYY-MM-DD"),
        end_date: moment(rangeDate.end).format("YYYY-MM-DD"),
      };

      setDoctor(props.selectedDoctor || null);

      if (props.selectedAnesthesiologist) {
        params["anesthesiologist"] = props.selectedAnesthesiologist;
        setAnesthesiologist(props.selectedAnesthesiologist);
      } else {
        setAnesthesiologist(null);
      }

      if (props.selectedOperatingRoom) {
        params["room"] = [props.selectedOperatingRoom];
        setRoom([props.selectedOperatingRoom]);
      } else {
        setRoom([]);
      }

      let operatingId =
        props.preOrderList?.find((item: any) => item.type === "operatingorder")
          ?.id || null;

      props.runSequence({
        sequence: "OperatingDateTime",
        action: "getDSBBlock",
        card: CARD_SELECT_DATE_TIME_OPERATION,
        ...params,
        operatingId,
      });

      setOperatingId(operatingId);
    } else {
      setDoctor(null);
    }
  }, [
    props.selectedDoctor,
    props.selectedAnesthesiologist,
    props.selectedOperatingRoom,
    props.preOrderList,
    props.OperatingDateTimeSequence?.sequenceIndex,
  ]);
  // Callback
  const handleCloseModOperatingDateTime = useCallback(() => {
    setOpenModOperatingDateTime(false);
  }, []);

  const handleOnCancelSelectOperatingTime = useCallback(() => {
    let params = {
      doctor: props.selectedDoctor,
      anesthesiologist: null,
      room: null,
      start_date: moment(rangeDate.start).format("YYYY-MM-DD"),
      end_date: moment(rangeDate.end).format("YYYY-MM-DD"),
    };

    setDoctor(props.selectedDoctor || null);

    if (props.selectedAnesthesiologist) {
      params["anesthesiologist"] = props.selectedAnesthesiologist;
      setAnesthesiologist(props.selectedAnesthesiologist);
    } else {
      setAnesthesiologist(null);
    }

    if (props.selectedOperatingRoom) {
      params["room"] = props.selectedOperatingRoom;
      setRoom([props.selectedOperatingRoom]);
    } else {
      setRoom([]);
    }

    setLoadingTimeSlot(true);

    props.runSequence({
      sequence: "OperatingDateTime",
      action: "unsetAndClose",
      card: props.card,
      callback: () => {
        setOpenModOperatingDateTime(false);

        props.runSequence({
          sequence: "OperatingDateTime",
          action: "getDSBBlock",
          card: CARD_SELECT_DATE_TIME_OPERATION,
          ...params,
          operatingId,
          onSuccess: () => {
            setLoadingTimeSlot(false);
          },
        });
      },
    });
  }, [
    props.selectedDoctor,
    rangeDate,
    props.selectedAnesthesiologist,
    props.selectedOperatingRoom,
    operatingId,
  ]);

  // Memo
  const selectedDoctorSchedule = useMemo(() => {
    return props.OperatingDateTimeSequence?.selectedDoctorSchedule;
  }, [props.OperatingDateTimeSequence?.selectedDoctorSchedule]);

  const disabledFilter = useMemo(() => {
    return (
      !!props.selectedOrOrder?.operating_room && !props.selectOperatingDSBChange
    );
  }, [props.selectOperatingDSBChange, props.selectedOrOrder]);

  const orOrderType = useMemo(() => {
    return (
      props.masterOptions?.orType?.find(
        (option: any) => option.value === props.selectedOrOrder?.type
      )?.text || ""
    );
  }, [props.masterOptions?.orType, props.selectedOrOrder]);

  const opdEncounterExpire = useMemo(() => {
    return (
      props.OperatingDateTimeSequence?.constance?.core_OPD_ENCOUNTER_EXPIRE || 0
    );
  }, [props.OperatingDateTimeSequence?.constance]);

  const operatingCase = useMemo(() => {
    const caseDetail = props.selectedOrOrder?.case || {};
    let operatingCase = "";

    if (caseDetail.is_ipd_case) {
      operatingCase = "IPD";
    } else if (caseDetail.is_one_day_case) {
      operatingCase = "One day";
    } else if (caseDetail.is_opd_case) {
      operatingCase = "OPD";
    }

    return operatingCase;
  }, [props.selectedOrOrder?.case]);

  // Handler
  const handleChangeOption = (name: string) => (e: any, data: any) => {
    let params = {
      doctor: doctor,
      anesthesiologist: anesthesiologist,
      room: room,
      start_date: moment(rangeDate.start).format("YYYY-MM-DD"),
      end_date: moment(rangeDate.end).format("YYYY-MM-DD"),
      [name]: data.value,
    };

    if (name === "doctor") {
      setDoctor(data.value);
    } else if (name === "anesthesiologist") {
      setAnesthesiologist(data.value);
    }

    setLoadingTimeSlot(true);

    props.runSequence({
      sequence: "OperatingDateTime",
      action: "getDSBBlock",
      card: CARD_SELECT_DATE_TIME_OPERATION,
      ...params,
      operatingId,
      onSuccess: () => setLoadingTimeSlot(false),
    });
  };

  const handleChangeOptions = (e: any, v: any) => {
    let params = {
      doctor: doctor,
      anesthesiologist: anesthesiologist,
      start_date: moment(rangeDate.start).format("YYYY-MM-DD"),
      end_date: moment(rangeDate.end).format("YYYY-MM-DD"),
    };

    setRoom(v.value);

    setLoadingTimeSlot(true);

    props.runSequence({
      sequence: "OperatingDateTime",
      action: "getDSBBlock",
      card: CARD_SELECT_DATE_TIME_OPERATION,
      ...params,
      room: v.value,
      operatingId,
      onSuccess: () => setLoadingTimeSlot(false),
    });
  };

  const handleCloseModWarning = () => {
    setModError("");
  };

  const handleCloseModDetail = () => {
    setOpenModDetail(false);
  };

  const handleCloseModDoctorSchedule = () => {
    props.setProp("OperatingDateTimeSequence.selectedDoctorSchedule", null);
  };

  const handleSelectEvent = async (event: any) => {
    // #if (event.operating_detail === props.selectedOperatingDetail || event.operating_detail === props.selectedOrOrder?.operating_detail) {
    if (event.operating_detail) {
      await props.setProp("OperatingDateTimeSequence.estimateEndDate", null);

      props.runSequence({
        sequence: "OperatingDateTime",
        action: "selectEvent",
        card: CARD_SELECT_DATE_TIME_OPERATION,
        data: { ...event },
      });

      setSelectedOperatingDetail(event.operating_detail);
      setOpenModOperatingDateTime(true);
    } else if (event.is_doctor_schedule) {
      props.runSequence({
        sequence: "OperatingDateTime",
        action: "selectDoctorSchedule",
        card: CARD_SELECT_DATE_TIME_OPERATION,
        data: { ...event },
      });
    } else if (calendarView === "month") {
      setLoadingTimeSlot(true);

      // console.log('CardSelectDateTimeOpeeration event.start: ', event.start);
      const start = moment(event.start).startOf("week").toDate();
      const end = moment(event.start).endOf("week").toDate();

      setRangeDate({ start, end });
      setCalendarView("week");
      setTimeout(() => {
        setLoadingTimeSlot(false);
      }, 100);
    }
  };

  const handleSelectSlot = async (info: any) => {
    // by month ไม่ให้กด นัด slot
    if (calendarView === "month") {
      return;
    }

    const isSelect =
      info.slots?.length === 2 && ["select", "click"].includes(info.action);

    const showDetail =
      props.selectedAppointment &&
      props.selectedAppointment.division_service_block &&
      !props.selectOperatingDSBChange;

    if (isSelect && !showDetail) {
      if (!doctor) {
        return setModError("กรุณาระบุแพทย์ผ่าตัด สำหรับระบุนัดหมายผ่าตัด");
      } else if (!room.length) {
        return setModError("กรุณาระบุห้องผ่าตัด สำหรับระบุนัดหมายผ่าตัด");
      }
    }

    const { encounterDatetime, encounterType } = props.selectedOrOrder || {};

    if (encounterDatetime && encounterType === "OPD") {
      const timeSlotRange = getTimeSlotRange(
        encounterDatetime,
        opdEncounterExpire
      );

      const isBetween = moment(info.start).isBetween(
        timeSlotRange.start,
        timeSlotRange.end
      );

      const isTimePast =
        moment(info.end).diff(roundTime(moment()), "minutes") <= 0;

      if (!isBetween || isTimePast) {
        return;
      }
    }

    if (isSelect && doctor) {
      // let findExistsDSB = (props.operatingBlock || []).find((item: any, index: number) =>
      //   item.operating_detail === props.selectedOperatingDetail)
      // console.log("find index: ", info)
      let isTimePast = true;

      if (info.start && encounterType !== "OPD") {
        let selectDate = moment(info.start).format("YYYY-MM-DD");
        // #let currentDate = new Date(moment().format("YYYY-MM-DD"));
        isTimePast =
          selectDate > CURRENT_DATE ||
          (selectDate === CURRENT_DATE &&
            moment(info.start).diff(moment(), "minutes") >= 0);
      }

      const selectable =
        ((props.selectedAppointment &&
          !props.selectedAppointment.division_service_block) ||
          props.selectOperatingDSBChange) &&
        !loadingTimeSlot &&
        isTimePast;

      if (selectable) {
        await props.setProp("OperatingDateTimeSequence.estimateEndDate", null);
        await props.setProp("OperatingDateTimeSequence.operatingRoom", null);

        props.runSequence({
          sequence: "OperatingDateTime",
          action: "selectDateTime",
          card: CARD_SELECT_DATE_TIME_OPERATION,
          doctor: doctor,
          anesthesia: anesthesiologist,
          room: room,
          data: {
            ...info,
            operatingId,
          },
        });
        setSelectedOperatingDetail(props.selectedOperatingDetail);
        setOpenModOperatingDateTime(true);
      } else if (showDetail) {
        setOpenModDetail(true);
      }
    }
  };

  const handleChangeDate = (date: { start: Date; end: Date }, view: string) => {
    props.runSequence({
      sequence: "OperatingDateTime",
      action: "getDSBBlock",
      card: CARD_SELECT_DATE_TIME_OPERATION,
      doctor: props.selectedDoctor,
      anesthesiologist: anesthesiologist,
      room: room,
      start_date: moment(date.start).format("YYYY-MM-DD"),
      end_date: moment(date.end).format("YYYY-MM-DD"),
      operatingId,
      view,
      onSuccess: () => setLoadingTimeSlot(false),
    });
  };

  console.log("CardSelectDateTimeOperation: ", props);

  return (
    <div id="CardSelectDateTimeOperation">
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(
            `errorMessage.${CARD_SELECT_DATE_TIME_OPERATION}`,
            null
          );
        }}
        error={
          props.errorMessage?.[CARD_SELECT_DATE_TIME_OPERATION]?.message
            ? props.errorMessage?.[CARD_SELECT_DATE_TIME_OPERATION]?.message
            : props.errorMessage?.[CARD_SELECT_DATE_TIME_OPERATION]
        }
        success={null}
      />
      <CardSelectDateTimeOperationUX
        operationDoctor={doctor}
        ansDoctor={anesthesiologist}
        orOrderType={orOrderType}
        operatingCase={operatingCase}
        // ORRoom={room}
        disabledDoctor={true}
        disabledAnesthesia={true}
        disabledOperatingRoom={true}
        disabledDoctorOptions={disabledFilter}
        disabledAnesthesiaOptions={disabledFilter}
        // disabledDoctorOptions={!props.selectOperatingDSBChange}
        operationDoctorOptions={props.masterOptions?.doctor || []}
        ansDoctorOptions={props.masterOptions?.doctor || []}
        handleChangeOption={handleChangeOption}
        // Component
        doctorLabel={
          <div style={{ display: "flex", marginBottom: "5px" }}>
            <div
              style={{
                backgroundColor: "#FFE1A9",
                padding: "2px 4px",
                borderRadius: "2px",
              }}
            >
              แพทย์ผ่าตัด
            </div>
            <Icon
              name={filterDoctor ? "eye" : "low vision"}
              style={{
                paddingLeft: "5px",
                cursor: "pointer",
                marginTop: "3px",
              }}
              onClick={() => {
                setFilterDocter(!filterDoctor);
              }}
            />
          </div>
        }
        anesthesiaLabel={
          <div style={{ display: "flex", marginBottom: "5px" }}>
            <div
              style={{
                backgroundColor: "#E3BFF3",
                padding: "2px 4px",
                borderRadius: "2px",
              }}
            >
              วิสัญญีแพทย์
            </div>
            <Icon
              name={filterAnesthetic ? "eye" : "low vision"}
              style={{
                paddingLeft: "5px",
                cursor: "pointer",
                marginTop: "3px",
              }}
              onClick={() => {
                setFilterAnesthetoc(!filterAnesthetic);
              }}
            />
          </div>
        }
        operatingRoomLabel={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                backgroundColor: "#E95959",
                padding: "2px 4px",
                borderRadius: "2px",
              }}
            >
              ห้องผ่าตัด
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "5px",
              }}
            >
              <Icon
                name="circle"
                style={{ color: "#E95959", marginTop: "-3px" }}
              />
              <div>Major</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "5px",
              }}
            >
              <Icon
                name="circle"
                style={{ color: "#FFC5C5", marginTop: "-3px" }}
              />
              <div>Minor</div>
            </div>
          </div>
        }
        operatingRoomOptions={
          <DropdownOptions
            value={room}
            multiple={true}
            search={true}
            checked={true}
            inline={true}
            options={props.operatingLocationRoom || []}
            onChange={handleChangeOptions}
            showSubOptions={true}
            disabled={disabledFilter}
            // fluid={true}
            style={{ width: "100%" }}
          />
        }
        selectCalendar={
          <CardOperatingCalendar
            // data
            selectedOperatingDetail={props.selectedOperatingDetail}
            selectedOrOrder={props.selectedOrOrder}
            selectOperatingDSBChange={props.selectOperatingDSBChange}
            operatingLocationRoom={props.operatingLocationRoom}
            operatingBlock={props.operatingBlock}
            calendarView={calendarView}
            rangeDate={rangeDate}
            opdEncounterExpire={opdEncounterExpire}
            // Filter
            doctor={doctor}
            anesthesiologist={anesthesiologist}
            filterDoctor={filterDoctor}
            filterAnesthetic={filterAnesthetic}
            room={room}
            // callback
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            onChangeDate={handleChangeDate}
            onChangeCalendarView={setCalendarView}
            onChangeRangeDate={setRangeDate}
          />
        }
      />

      <Modal
        open={openModOperatingDateTime}
        style={{
          padding: "5vh 0",
          background: "transparent",
          boxShadow: "none",
        }}
        // size="large"
        onClose={() => setOpenModOperatingDateTime(false)}
      >
        <ModSelectOperatingDateTime
          onEvent={props.onEvent}
          setProp={props.setProp}
          runSequence={props.runSequence}
          masterOptions={props.masterOptions}
          card={CARD_SELECT_DATE_TIME_OPERATION}
          doctor={doctor}
          anesthesia={anesthesiologist}
          operatingRoom={room}
          onPostpone={props.onPostpone}
          opdEncounterExpire={opdEncounterExpire}
          encounterDatetime={props.selectedOrOrder?.encounterDatetime}
          encounterType={props.selectedOrOrder?.encounterType}
          isTargetOperatingDetail={
            selectedOperatingDetail === props.selectedOperatingDetail
          }
          room={room}
          onCancel={handleOnCancelSelectOperatingTime}
          OperatingDateTimeSequence={props.OperatingDateTimeSequence}
          estimateTimeText={props.estimateTimeText}
          selectOperatingDSBChange={props.selectOperatingDSBChange}
          onClose={handleCloseModOperatingDateTime}
          onUpdated={props.onUpdated}
        />
      </Modal>

      <ModInfo
        open={openModDetail}
        titleName="รายการนี้มีนัดหมายแล้ว"
        titleColor="red"
        style={{ width: "fit-content" }}
        buttonColor="red"
        btnText="ปิด"
        closeOnDimmerClick={true}
        onApprove={handleCloseModDetail}
        onClose={handleCloseModDetail}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "-1rem",
          }}
        >
          <div
            style={{ padding: "20px", fontSize: "1.15rem", lineHeight: 1.75 }}
          >
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px" }}>HN: </strong>
              <span>{props.selectedAppointment?.patient_hn}</span>
              <strong style={{ padding: "0px 5px 0px 15px" }}>
                ชื่อ-สกุล:
              </strong>
              <span>
                {`${
                  props.selectedAppointment?.patient_pre_name
                    ? props.selectedAppointment?.patient_pre_name + " "
                    : ""
                }${props.selectedAppointment?.patient_first_name} ${
                  props.selectedAppointment?.patient_last_name
                }`}
              </span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px" }}>ห้องผ่าตัด:</strong>
              <span>{`${props.selectedOrOrder?.operating_room_no} (${props.selectedOrOrder?.primary_doctor_name})`}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px" }}>วัน เวลานัดหมาย:</strong>
              <span>{props.selectedAppointment?.estimated_at}</span>
            </div>
          </div>
        </div>
      </ModInfo>

      <ModInfo
        open={!!selectedDoctorSchedule}
        titleName="ข้อมูลตารางออกตรวจแพทย์"
        titleColor="yellow"
        style={{ width: "fit-content" }}
        buttonColor="yellow"
        btnText="ปิด"
        closeOnDimmerClick={true}
        onApprove={handleCloseModDoctorSchedule}
        onClose={handleCloseModDoctorSchedule}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "-1rem",
          }}
        >
          <div
            style={{
              padding: "20px",
              fontSize: "1.15rem",
              lineHeight: 1.75,
              textAlign: "left",
            }}
          >
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                แพทย์
              </strong>
              <span>{selectedDoctorSchedule?.doctor_name}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                วันที่
              </strong>
              <span>
                {moment(selectedDoctorSchedule?.start_datetime)
                  .clone()
                  .locale("th")
                  .format(`DD MMMM`)}{" "}
                {+moment(selectedDoctorSchedule?.start_datetime).format(
                  "YYYY"
                ) + 543}
              </span>
              <strong style={{ padding: "0 2rem 0 3rem" }}>เวลา</strong>
              <span>
                {moment(selectedDoctorSchedule?.start_datetime).format("HH:mm")}
              </span>
              <strong style={{ padding: "0 2rem" }}>ถึง</strong>
              <span>
                {moment(selectedDoctorSchedule?.end_datetime).format("HH:mm")}
              </span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                แผนก
              </strong>
              <span>{selectedDoctorSchedule?.division_name}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                จำนวนผู้ป่วย
              </strong>
              <span>{`${selectedDoctorSchedule?.patient_count} คน`}</span>
            </div>
          </div>
        </div>
      </ModInfo>

      <ModInfo
        open={!!modError}
        titleName="แจ้งเตือน !"
        titleColor="red"
        size="mini"
        buttonColor="red"
        btnText="ปิด"
        closeOnDimmerClick={true}
        onApprove={handleCloseModWarning}
        onClose={handleCloseModWarning}
      >
        <div
          style={{
            margin: "1.75rem 0rem 0.75rem",
          }}
        >
          {modError}
        </div>
      </ModInfo>
    </div>
  );
};

CardSelectDateTimeOperation.displayName = "CardSelectDateTimeOperation";

export default React.memo(CardSelectDateTimeOperation);
