import React, { useMemo, useCallback, useState } from "react";
import { Image, Modal } from "semantic-ui-react";

// UX
import CardSubAllergyHistory, {
  getDrugStatus,
  getFoodNOtherStatus,
} from "react-lib/apps/HISV3/TPD/CardSubAllergyHistory";

// Interface
import { ADR_TYPES } from "react-lib/apps/HISV3/TPD/sequence/Allergy";

// Types
type CardSubAllergyLogProps = {
  setProp?: any;
  // seq
  runSequence: any;
  AllergySequence?: any;
  // data
  DJANGO?: any;
  selectedDivision?: any;
  noResultsMessage?: string;
  // options
  masterOptions?: any;
};

const CardSubAllergyLog = (props: CardSubAllergyLogProps) => {
  const [modAllergyHistory, setModAllergyHistory] = useState<any>("");

  // Callback
  const filterADR = useCallback((adrList: any[], type: any) => {
    return adrList.filter((item) => item.type_name_name === type);
  }, []);

  const handleCenterModal = useCallback(
    (ref: any) => {
      !!ref &&
        ((
          ref.closest(".ui.page.modals.visible.active") as any
        ).style.justifyContent = "center");
    },

    []
  );

  // Memo
  const groupADR = useMemo(() => {
    const adrList: any[] = props.AllergySequence?.adrList || [];

    return {
      drug: filterADR(adrList, ADR_TYPES.DRUG),
      food: filterADR(adrList, ADR_TYPES.FOOD),
      other: filterADR(adrList, ADR_TYPES.OTHER),
    };
  }, [props.AllergySequence?.adrList]);

  const statusLabel = useMemo(() => {
    return {
      drug: getDrugStatus(groupADR.drug),
      food: getFoodNOtherStatus(groupADR.food, "food"),
      other: getFoodNOtherStatus(groupADR.other, "other"),
    };
  }, [groupADR]);

  // Handler
  const handleOpenModAllergyHistory = (type: any) => () => {
    setModAllergyHistory(type);
  };

  const handleCloseModAllergyHistory = () => {
    setModAllergyHistory("");
  };

  const handleShowInactive = (e: any, { checked }: any) => {
    props.setProp("AllergySequence.showInactive", checked);

    props.runSequence({
      sequence: "Allergy",
      action: "inactive",
      checked: checked,
    });
  };

  return (
    <>
      {!!props.AllergySequence?.adrList &&
      !props.AllergySequence?.adrList?.length ? (
        <div>{props.noResultsMessage}</div>
      ) : (
        <div
          style={{
            display: props?.AllergySequence?.adrList ? "flex" : "none",
            justifyContent: "row",
            alignItems: "center",
          }}
        >
          <div style={{ padding: "2px" }}>
            {statusLabel.other.icon && (
              <Image
                src={statusLabel.other.icon}
                style={{ width: "35px", height: "35px" }}
                onClick={handleOpenModAllergyHistory("other")}
              />
            )}
          </div>
          <div style={{ padding: "2px" }}>
            {statusLabel.food.icon && (
              <Image
                src={statusLabel.food.icon}
                style={{ width: "35px", height: "35px" }}
                onClick={handleOpenModAllergyHistory("food")}
              />
            )}
          </div>
          <div style={{ padding: "2px" }}>
            {statusLabel.drug.icon && (
              <Image
                src={statusLabel.drug.icon}
                style={{ width: "35px", height: "35px" }}
                onClick={handleOpenModAllergyHistory("drug")}
              />
            )}
          </div>
        </div>
      )}

      <Modal
        open={!!modAllergyHistory}
        closeOnDimmerClick={true}
        onClose={handleCloseModAllergyHistory}
      >
        <div ref={handleCenterModal}>
          <CardSubAllergyHistory
            // seq
            runSequence={props.runSequence}
            AllergySequence={props.AllergySequence}
            type={modAllergyHistory}
            ardList={(groupADR as any)[modAllergyHistory]}
            DJANGO={props.DJANGO}
            selectedDivision={props.selectedDivision}
            // options
            masterOptions={props.masterOptions}
            // config
            modal={true}
            // callback
            onShowInactive={handleShowInactive}
          />
        </div>
      </Modal>
    </>
  );
};

CardSubAllergyLog.displayName = "CardSubAllergyLog";

export default React.memo(CardSubAllergyLog);
