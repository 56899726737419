import React from "react";

export interface CardFootOutlineType {
  width: number;
  height: number;
  viewBox: string;
  organs: [{ x: number; y: number; width: number; height: number }];
}

const CardFootOutline = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox={props.viewBox}>
      <g
      transform="translate(0.400000,450.0000) scale(0.25800000,-0.25800000)"
      fill="#000000"
      stroke="none"
      >
        <g>
        <g>
          <path
            d="M175,175c-14.583,42.363 -2.708,117.342 -12.5,175c-12.5,73.613 -53.471,
            206.946 -62.5,266.667c-4.588,30.337 6.25,61.804 8.333,91.666c2.034,29.13 -1.387,
            71.529 4.167,87.5c3.321,9.55 22.692,16.1 29.167,8.334c6.946,-8.334 6.946,
            -48.613 12.5,-58.334c3.445,-6.029 19.471,-6.808 20.833,0c1.387,6.946 -10.542,
            27.3 -12.5,41.667c-2.083,15.279 -6.25,40.971 0,50c6.25,9.029 29.863,13.887 37.5,
            4.167c7.637,-9.721 4.863,-52.78 8.333,-62.5c1.48,-4.138 11.238,-0.042 12.5,
            4.166c2.084,6.946 2.78,25.696 0,37.5c-2.779,11.804 -15.279,22.917 -16.666,
            33.334c-1.338,10.02 1.387,24.304 8.333,29.166c6.946,4.863 27.821,9.646 33.333,0c8.334,
            -14.583 11.113,-72.916 16.667,-87.5c1.979,-5.191 15.879,-5.5 16.667,0c1.387,
            9.721 -6.946,39.584 -8.334,58.334c-1.333,18.004 -6.945,44.445 0,54.166c6.946,
            9.721 32.638,8.334 41.667,4.167c9.029,-4.167 11.688,-18.621 12.5,-29.167c1.388,
            -18.054 -5.554,-64.583 -4.167,-79.166c0.475,-4.984 7.684,-9.709 12.5,-8.334c4.863,
            1.388 16.175,8.825 16.667,16.667c0.696,11.112 -9.721,34.721 -12.5,50c-2.496,
            13.733 -6.25,28.471 -4.167,41.667c2.084,13.195 6.25,30.554 16.667,37.5c10.417,
            6.945 31.279,9.016 45.833,4.166c14.584,-4.862 36.988,-16.175 41.667,-33.333c6.25,
            -22.917 -2.083,-78.471 -4.167,-104.167c-1.366,-16.841 -9.629,-33.154 -8.333,
            -50c1.388,-18.054 16.163,-38.116 16.667,-58.333c0.696,-27.779 -5.996,-72.571 -12.5,
            -108.333c-6.946,-38.196 -23.396,-79.805 -29.167,-120.834c-6.25,-44.446 -8.333,
            -100 -8.333,-145.833c-0,-43.146 10.416,-96.529 8.333,-129.167c-1.483,-23.233 -8.333,
            -49.304 -20.833,-66.666c-12.5,-17.363 -32.904,-32.013 -54.167,-37.5c-21.529,
            -5.555 -53.112,-7.992 -75,4.166c-25,13.888 -63.167,44.796 -75,79.167Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
        <path
          d="M416.667,880.208c-0,-7.475 -6.067,-13.541 -13.542,-13.541l-31.25,-0c-7.475,
          -0 -13.542,6.066 -13.542,13.541l0,27.084c0,7.475 6.067,13.541 13.542,13.541l31.25,0c7.475,
          0 13.542,-6.066 13.542,-13.541l-0,-27.084Z"
          style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
        />
        <g>
          <path
            d="M312.5,880.136c0,-3.656 -2.967,-6.622 -6.621,-6.622l-20.091,-0c-3.655,
            -0 -6.621,2.966 -6.621,6.622l-0,13.244c-0,3.655 2.966,6.622 6.621,6.622l20.091,
            -0c3.654,-0 6.621,-2.967 6.621,-6.622l0,-13.244Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
        <g>
          <path
            d="M241.667,862.427c-0,-3.08 -2.5,-5.58 -5.582,-5.58l-18.004,0c-3.081,0 -5.581,
            2.5 -5.581,5.58l0,11.161c0,3.081 2.5,5.581 5.581,5.581l18.004,-0c3.082,-0 5.582,
            -2.5 5.582,-5.581l-0,-11.161Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
        <g>
          <path
            d="M191.667,819.719c-0,-2.505 -2.035,-4.538 -4.539,-4.538l-11.756,-0c-2.504,
            -0 -4.539,2.033 -4.539,4.538l0,9.078c0,2.505 2.035,4.538 4.539,4.538l11.756,
            0c2.504,0 4.539,-2.033 4.539,-4.538l-0,-9.078Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
        <g>
          <path
            d="M137.5,785.344c0,-1.93 -1.567,-3.497 -3.497,-3.497l-13.839,0c-1.93,0 -3.497,
            1.567 -3.497,3.497l-0,6.994c-0,1.931 1.567,3.497 3.497,3.497l13.839,0c1.93,0 3.497,
            -1.566 3.497,-3.497l0,-6.994Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M757.263,175c14.583,42.363 2.708,117.342 12.5,175c12.5,73.613 53.47,
            206.946 62.5,266.667c4.587,30.337 -6.25,61.804 -8.334,91.666c-2.033,29.13 1.388,
            71.529 -4.167,87.5c-3.32,9.55 -22.691,16.1 -29.166,8.334c-6.946,-8.334 -6.946,
            -48.613 -12.5,-58.334c-3.446,-6.029 -19.471,-6.808 -20.833,0c-1.388,6.946 10.541,
            27.3 12.5,41.667c2.083,15.279 6.25,40.971 -0,50c-6.25,9.029 -29.863,13.887 -37.5,
            4.167c-7.638,-9.721 -4.863,-52.78 -8.334,-62.5c-1.479,-4.138 -11.237,-0.042 -12.5,
            4.166c-2.083,6.946 -2.779,25.696 0,37.5c2.779,11.804 15.279,22.917 16.667,33.334c1.337,
            10.02 -1.388,24.304 -8.334,29.166c-6.945,4.863 -27.82,9.646 -33.333,0c-8.333,-14.583 -11.112,
            -72.916 -16.667,-87.5c-1.979,-5.191 -15.879,-5.5 -16.666,0c-1.388,9.721 6.946,
            39.584 8.333,58.334c1.333,18.004 6.946,44.445 0,54.166c-6.946,9.721 -32.637,
            8.334 -41.667,4.167c-9.029,-4.167 -11.687,-18.621 -12.5,-29.167c-1.387,-18.054 5.555,
            -64.583 4.167,-79.166c-0.475,-4.984 -7.683,-9.709 -12.5,-8.334c-4.862,1.388 -16.175,
            8.825 -16.667,16.667c-0.695,11.112 9.721,34.721 12.5,50c2.496,13.733 6.25,28.471 4.167,
            41.667c-2.083,13.195 -6.25,30.554 -16.667,37.5c-10.416,6.945 -31.279,9.016 -45.833,
            4.166c-14.583,-4.862 -36.987,-16.175 -41.667,-33.333c-6.25,-22.917 2.084,-78.471 4.167,
            -104.167c1.367,-16.841 9.629,-33.154 8.334,-50c-1.388,-18.054 -16.163,-38.116 -16.667,
            -58.333c-0.696,-27.779 5.996,-72.571 12.5,-108.333c6.946,-38.196 23.396,-79.805 29.167,
            -120.834c6.25,-44.446 8.333,-100 8.333,-145.833c-0,-43.146 -10.417,-96.529 -8.333,
            -129.167c1.483,-23.233 8.333,-49.304 20.833,-66.666c12.5,-17.363 32.904,-32.013 54.167,
            -37.5c21.529,-5.555 53.112,-7.992 75,4.166c25,13.888 63.166,44.796 75,79.167Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
        <path
          d="M515.596,880.208c-0,-7.475 6.067,-13.541 13.542,-13.541l31.25,-0c7.474,-0 13.541,
          6.066 13.541,13.541l0,27.084c0,7.475 -6.067,13.541 -13.542,13.541l-31.25,0c-7.475,
          0 -13.541,-6.066 -13.541,-13.541l-0,-27.084Z"
          style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
        />
        <g>
          <path
            d="M619.763,880.136c-0,-3.656 2.966,-6.622 6.621,-6.622l20.09,-0c3.655,-0 6.622,
            2.966 6.622,6.622l-0,13.244c-0,3.655 -2.967,6.622 -6.622,6.622l-20.09,-0c-3.655,
            -0 -6.621,-2.967 -6.621,-6.622l-0,-13.244Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
        <g>
          <path
            d="M690.596,862.427c-0,-3.08 2.5,-5.58 5.581,-5.58l18.004,0c3.082,0 5.582,
            2.5 5.582,5.58l-0,11.161c-0,3.081 -2.5,5.581 -5.582,5.581l-18.004,-0c-3.081,
            -0 -5.581,-2.5 -5.581,-5.581l-0,-11.161Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
        <g>
          <path
            d="M740.596,819.719c-0,-2.505 2.034,-4.538 4.539,-4.538l11.755,-0c2.505,-0 4.539,
            2.033 4.539,4.538l0,9.078c0,2.505 -2.034,4.538 -4.539,4.538l-11.755,0c-2.505,0 -4.539,
            -2.033 -4.539,-4.538l-0,-9.078Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
        <g>
          <path
            d="M794.762,785.344c0,-1.93 1.567,-3.497 3.497,-3.497l13.84,
            0c1.93,0 3.497,1.567 3.497,3.497l-0,6.994c-0,1.931 -1.567,3.497 -3.497,3.497l-13.84,
            0c-1.93,0 -3.497,-1.566 -3.497,-3.497l0,-6.994Z"
            style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
          />
        </g>
      </g>
      <g>
        <path
          d="M794.763,1848.07c14.583,-42.363 2.708,-117.342 12.5,-175c12.5,-73.613 53.47,
          -206.946 62.5,-266.667c4.587,-30.337 -6.25,-61.804 -8.334,-91.667c-2.033,-29.129 1.388,
          -71.529 -4.167,-87.5c-3.32,-9.55 -22.691,-16.1 -29.166,-8.333c-6.946,8.333 -6.946,
          48.613 -12.5,58.333c-3.446,6.03 -19.471,6.809 -20.833,0c-1.388,-6.945 10.541,
          -27.3 12.5,-41.666c2.083,-15.279 6.249,-40.971 -0.001,-50c-6.25,-9.029 -29.862,
          -13.888 -37.5,-4.167c-7.637,9.721 -4.862,52.779 -8.333,62.5c-1.479,4.138 -11.237,
          0.042 -12.5,-4.167c-2.083,-6.945 -2.779,-25.695 0,-37.5c2.779,-11.804 15.279,
          -22.916 16.667,-33.333c1.337,-10.021 -1.388,-24.304 -8.334,-29.167c-6.945,
          -4.862 -27.82,-9.645 -33.333,0c-8.333,14.584 -11.112,72.917 -16.667,87.5c-1.979,
          5.192 -15.879,5.5 -16.666,0c-1.388,-9.72 6.946,-39.583 8.333,-58.333c1.333,
          -18.004 6.946,-44.446 0,-54.167c-6.946,-9.72 -32.637,-8.333 -41.667,-4.166c-9.029,
          4.166 -11.687,18.621 -12.5,29.166c-1.387,18.055 5.555,64.584 4.167,79.167c-0.475,
          4.983 -7.683,9.708 -12.5,8.333c-4.862,-1.387 -16.175,-8.825 -16.667,-16.666c-0.695,
          -11.113 9.721,-34.721 12.5,-50c2.496,-13.734 6.25,-28.471 4.167,-41.667c-2.083,
          -13.196 -6.25,-30.554 -16.667,-37.5c-10.416,-6.946 -31.279,-9.017 -45.833,-4.167c-14.583,
          4.863 -36.987,16.175 -41.667,33.334c-6.25,22.916 2.084,78.471 4.167,104.166c1.367,
          16.842 9.629,33.155 8.333,50c-1.387,18.055 -16.162,38.117 -16.666,58.334c-0.696,
          27.779 5.996,72.571 12.5,108.333c6.946,38.196 23.396,79.804 29.167,120.833c6.25,
          44.446 8.333,100 8.333,145.834c-0,43.146 -10.417,96.529 -8.333,129.166c1.483,
          23.234 8.333,49.305 20.833,66.667c12.5,17.363 32.904,32.013 54.167,37.5c21.529,
          5.554 53.112,7.992 75,-4.167c25,-13.887 63.166,-44.795 75,-79.166Z"
          style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
        />
      </g>
      <g>
        <path
          d="M187.5,1848.07c-14.583,-42.363 -2.708,-117.342 -12.5,-175c-12.5,
          -73.613 -53.471,-206.946 -62.5,-266.667c-4.587,-30.337 6.25,-61.804 8.333,
          -91.667c2.034,-29.129 -1.387,-71.529 4.167,-87.5c3.321,-9.55 22.692,
          -16.1 29.167,-8.333c6.946,8.333 6.946,48.613 12.5,58.333c3.446,6.03 19.471,
          6.809 20.833,0c1.388,-6.945 -10.542,-27.3 -12.5,-41.666c-2.083,-15.279 -6.25,
          -40.971 0,-50c6.25,-9.029 29.863,-13.888 37.5,-4.167c7.638,9.721 4.863,52.779 8.333,
          62.5c1.48,4.138 11.238,0.042 12.5,-4.167c2.084,-6.945 2.78,-25.695 0,-37.5c-2.779,
          -11.804 -15.279,-22.916 -16.666,-33.333c-1.338,-10.021 1.387,-24.304 8.333,
          -29.167c6.946,-4.862 27.821,-9.645 33.333,0c8.334,14.584 11.113,72.917 16.667,
          87.5c1.979,5.192 15.879,5.5 16.667,0c1.387,-9.72 -6.946,-39.583 -8.334,
          -58.333c-1.333,-18.004 -6.945,-44.446 0,-54.167c6.946,-9.72 32.638,-8.333 41.667,
          -4.166c9.029,4.166 11.688,18.621 12.5,29.166c1.388,18.055 -5.554,64.584 -4.167,
          79.167c0.475,4.983 7.684,9.708 12.5,8.333c4.863,-1.387 16.175,-8.825 16.667,
          -16.666c0.696,-11.113 -9.721,-34.721 -12.5,-50c-2.496,-13.734 -6.25,-28.471 -4.167,
          -41.667c2.084,-13.196 6.25,-30.554 16.667,-37.5c10.417,-6.946 31.279,-9.017 45.833,
          -4.167c14.584,4.863 36.988,16.175 41.667,33.334c6.25,22.916 -2.083,78.471 -4.167,
          104.166c-1.366,16.842 -9.629,33.155 -8.333,50c1.388,18.055 16.163,38.117 16.667,
          58.334c0.696,27.779 -5.996,72.571 -12.5,108.333c-6.946,38.196 -23.396,79.804 -29.167,
          120.833c-6.25,44.446 -8.333,100 -8.333,145.834c-0,43.146 10.416,96.529 8.333,
          129.166c-1.483,23.234 -8.333,49.305 -20.833,66.667c-12.5,17.363 -32.904,
          32.013 -54.167,37.5c-21.529,5.554 -53.112,7.992 -75,-4.167c-25,-13.887 -63.167,
          -44.795 -75,-79.166Z"
          style={{fill:"none", stroke:"#000", strokeWidth:"5.21px"}}
        />
      </g>
      </g>
      {props.mode === "Add"
        ? props.positionOptions.map((item, index) => (
            <circle
              key={index}
              cx={item.x}
              cy={item.y}
              r={10}
              style={{ fill: "grey", stroke: "black" }}
              onClick={(e) => {
                props.onEvent({
                  message: "click",
                  params: {
                    name: "position",
                    value: index,
                  },
                });
              }}
            />
          ))
        : props.position.map((item, index) => (
            <circle
              key={index}
              cx={item.x}
              cy={item.y}
              r={10}
              style={{
                fill:
                  props.mode === "Edit" && index === props.editId
                    ? "green"
                    : "yellow",
                stroke: "red",
              }}
              onClick={(e) => {
                props.onEvent({
                  message: "click",
                  params: {
                    name: "position",
                    value: index,
                  },
                });
              }}
            />
          ))}
    </svg>
  );
};

export const CardFootOutlineInitialData: CardFootOutlineType = {
  width: 450,
  height: 500,
  viewBox: "0 0 450 500",
  organs: [
    {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
  ],
};

CardFootOutline.defaultProps = CardFootOutlineInitialData;

export default CardFootOutline;
