import React, { useState } from "react";
import { Button } from "semantic-ui-react";

const Zone = (props: any) => {
  return(
    <div>
      <div 
        style={{cursor: "pointer"}}
        onClick={(e: any) => props.changeZone(props.zone)}>
        {props.zone.name}
      </div>
      <div style={{marginLeft: "10px"}}>
        {props.zone.children.map((child: any, index: number) => (
          <Zone key={index} zone={child} changeZone={props.changeZone}></Zone>
        ))}
      </div>
    </div>)
}

const ZoneSelector = (props :any) => {
  const [open, setOpen] = useState(false);

  return(
    <div>
      <div 
        style={{
          width: "100%", display: "flex", border: "solid #eeeeee 1px",
          justifyContent: "space-between", alignItems: "center",
        }}
        onClick={(e: any) => setOpen(!open)}>
        <div style={{padding: "5px"}}>เลือกโซน: {props.zone?.name || "ไม่ระบุ"}</div>
        <div style={{flex: 1}}/>
        {props.zone !== null && <Button 
          size="tiny" icon="delete" color="red" 
          onClick={(e: any) => {
            e.stopPropagation();
            props.setZone(null);
            setOpen(false);
          }}
        />}
        <Button 
          size="tiny" icon="angle down" color="blue" 
        />  
      </div>
      {open &&
      <div 
        style={{
          width: "90%", height: "300px", border:  "solid #eeeeee 1px",
          position: "absolute", top: "100px", zIndex: 100,
          backgroundColor: "#eeeeee", overflow: "auto"
        }}>
        {(props.selectedDivision?.children || []).map((child: any, index: number) => 
        <Zone
          key={index}
          zone={child}
          changeZone={(zone: any) => {
            props.setZone(zone);
            setOpen(false);
          }}
        />)}
      </div>}
    </div>
  )
}

export default ZoneSelector;