import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

const ModalPrintResult = (props: any) => {

  let result = ''
  let colorResult = ''
  if (props.isSuccess) {
    result = 'การพิมพ์เอกสารเสร็จเรียบร้อย'
    colorResult = 'teal'
  } else {
    result = 'การพิมพ์เอกสารล้มเหลว'
    colorResult = 'red'
  }

  return ( 
    <Modal open={props.active}>
      <Header color={colorResult as any} icon='print' content={result} />
      <Modal.Content >
      ​ <p> {result} </p>
      </Modal.Content>
      <Modal.Actions>
      <Button onClick={props.onButtonClick} color='green'> OK </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalPrintResult;