import React from "react";
import { Modal, Segment } from "semantic-ui-react";
// UI
import CardConsultCancelDetailUX from "./CardConsultCancelDetailUX";

type CardConsultCancelDetailProps = {
  onCloseMod?: any;
  isOpen?: boolean;
  cancel_note?: string;
  cancel_user?: string;
};

const CardConsultCancelDetail = (props: CardConsultCancelDetailProps) => {
  return (
    <Modal closeIcon size="tiny" open={props.isOpen} onClose={props.onCloseMod}>
      <Segment inverted className="red" style={{ margin: "0px" }}>
        นัดหมายถูกยกเลิก
      </Segment>
      <Modal.Content>
        <CardConsultCancelDetailUX
          cancel_note={props.cancel_note}
          cancel_user={props.cancel_user}
        />
      </Modal.Content>
    </Modal>
  );
};

export default React.memo(CardConsultCancelDetail);
