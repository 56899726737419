import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  CSSProperties,
  MutableRefObject,
} from "react";

// Framework
import TabPenta from "react-lib/frameworks/TabPenta";
import { Modal } from "semantic-ui-react";

// UX
import CardDiseaseSegmentGroupUX from "./CardDiseaseSegmentGroupUX";
import ModUDSegmentGroup, { DetailType } from "./ModUDSegmentGroup";
import CardUDSegmentResult from "./CardUDSegmentResult";
import CardUDSegmentMember, {
  CARD_UD_SEGMENT_MEMBER,
} from "./CardUDSegmentMember";
import {
  RunSequence,
  SegmentGroupType,
  State,
} from "./sequence/UnderlyingDisease";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Types
type CardUnderlyingDiseaseProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // controller
  drugOrderQueueController: any;
  // seq
  runSequence: RunSequence;
  UnderlyingDiseaseSequence?: State["UnderlyingDiseaseSequence"];
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
};

// Constant
const ICONS = {
  segment: "/static/images/segment-icon.png",
  member: "/static/images/member-icon.png",
};

const COLORS = {
  grey: "rgb(204, 204, 204)",
  divider: "rgba(228, 218, 218, 0.5)",
  label: "#979797",
  blue: "#2185d0",
  card_active: "#d6ecf3",
};

const styles = {
  card: {
    padding: "15px 10px",
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: "pointer",
  } as CSSProperties,
  icon_button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
};

export const CARD_UNDERLYING_DISEASE = "CardUnderlyingDisease";

const CardUnderlyingDisease = (props: CardUnderlyingDiseaseProps) => {
  const [openModNewSegment, setOpenModNewSegment] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const segmentListRef = useRef() as MutableRefObject<HTMLDivElement>;


  useEffect(() => {
    props.runSequence({
      sequence: "UnderlyingDisease",
      restart: true,
      action: "FETCH_SEGMENT_GROUP",
    });
  }, []);

  // Use Memo

  // Handler
  const handleSelected = (data: any) => (e: any) => {
    props.runSequence({
      sequence: "UnderlyingDisease",
      data,
      action: "SELECT_SEGMENT_GROUP",
      // fetchPatientSegment: true,
      card: CARD_UD_SEGMENT_MEMBER,
      fetchPatientSegment: selectedTabIndex === 1,
    });
  };

  const handleNewSegment = () => {
    setOpenModNewSegment(true);
  };

  const handleCloseModNewSegment = () => {
    setOpenModNewSegment(false);
  };

  const handleSuccess = (data: DetailType, card: string) => {
    handleCloseModNewSegment();
  };

  const handleChangeInput = (e: any, data: any) => {
    setSearchText(data.value);
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "UnderlyingDisease",
      action: "FETCH_SEGMENT_GROUP",
      name: searchText,
      fetchPatientSegment: selectedTabIndex === 1,
    });
  };

  return (
    <div className="UnderlyingDisease" style={{ height: "95vh" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_UNDERLYING_DISEASE}`, null);
        }}
        error={props.errorMessage?.[CARD_UNDERLYING_DISEASE]}
        success={null}
      />

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "22.5% 1fr",
          height: "100%",
        }}
      >
        {/* Segment Group List */}
        <div style={{ borderRight: `1px solid ${COLORS.grey}` }}>
          <CardDiseaseSegmentGroupUX
            // data
            searchText={searchText}
            // callback
            onNewSegment={handleNewSegment}
            onChangeInput={handleChangeInput}
            onSearch={handleSearch}
            // Element
            SegmentList={
              <div
                ref={segmentListRef}
                style={{
                  width: "100%",
                  overflow: "auto",
                  height: "calc(100vh - 185px)",
                }}
              >
                {(props.UnderlyingDiseaseSequence?.segmentGroupList || []).map(
                  (item) => (
                    <div
                      style={{
                        ...styles.card,
                        backgroundColor:
                          props.UnderlyingDiseaseSequence?.segmentGroupDetail
                            ?.id === item.id
                            ? COLORS.card_active
                            : "",
                      }}
                      onClick={handleSelected(item)}
                    >
                      {item.name}
                    </div>
                  )
                )}
              </div>
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <TabPenta
            selectedTabIndex={selectedTabIndex}
            key={"segment-tab"}
            tabWidth="75px"
            disabled={!props.UnderlyingDiseaseSequence?.segmentGroupDetail}
            activeStyle={
              !!props.UnderlyingDiseaseSequence?.segmentGroupDetail
                ? { backgroundColor: COLORS.blue }
                : { backgroundColor: "" }
            }
            activeItemStyle={
              !!props.UnderlyingDiseaseSequence?.segmentGroupDetail && {
                filter: "brightness(0) invert(1)",
              }
            }
            onChangeSelectedTabName={(name: string, idx: number) => {
              setSelectedTabIndex(idx);
            }}
            data={[
              {
                key: "Segment",
                name: (
                  <div style={styles.icon_button}>
                    <img
                      src={ICONS.segment}
                      alt={`segment.icon`}
                      style={{ width: "100%", marginRight: "-0.5rem" }}
                    />
                    <label
                      style={{ color: COLORS.label, marginTop: "-0.25rem" }}
                    >
                      Segment
                    </label>
                  </div>
                ),
                render: () => (
                  <>
                    <CardUDSegmentResult
                      setProp={props.setProp}
                      // CommonInterface
                      buttonLoadCheck={props.buttonLoadCheck}
                      // data
                      segment={
                        props.UnderlyingDiseaseSequence?.segmentGroupDetail
                      }
                      subSegmentList={
                        props.UnderlyingDiseaseSequence?.subSegmentList
                      }
                      patientSegmentList={
                        props.UnderlyingDiseaseSequence?.patientSegmentList
                      }
                      // seq
                      runSequence={props.runSequence}
                    />
                  </>
                ),
              },
              {
                key: "Member",
                name: (
                  <div
                    style={{ ...styles.icon_button, padding: "7px 10px 4px" }}
                  >
                    <img
                      src={ICONS.member}
                      alt={`member.icon`}
                      style={{ width: "100%" }}
                    />
                    <label
                      style={{ color: COLORS.label, marginTop: "0.25rem" }}
                    >
                      Member
                    </label>
                  </div>
                ),
                render: () => (
                  <>
                    <CardUDSegmentMember
                      setProp={props.setProp}
                      // seq
                      runSequence={props.runSequence}
                      // controller
                      drugOrderQueueController={props.drugOrderQueueController}
                      // CommonInterface
                      buttonLoadCheck={props.buttonLoadCheck}
                      // data
                      subSegmentList={
                        props.UnderlyingDiseaseSequence?.subSegmentList
                      }
                      segmentGroupCode={
                        props.UnderlyingDiseaseSequence?.segmentGroupDetail
                          ?.code || ""
                      }
                      patientSegmentList={
                        props.UnderlyingDiseaseSequence?.patientSegmentList
                      }
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>

      <Modal
        open={openModNewSegment}
        closeOnDimmerClick={true}
        closeIcon={true}
        size="small"
        // callback
        onClose={handleCloseModNewSegment}
      >
        <ModUDSegmentGroup
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          // data
          errorKey={CARD_UNDERLYING_DISEASE}
          // callback
          onSuccess={handleSuccess}
          onCancel={handleCloseModNewSegment}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardUnderlyingDisease);
