import React, { useState, useEffect } from "react";
import { Modal, Input, InputProps, DropdownProps } from "semantic-ui-react";
import CardRoomSearchUX from "./CardRoomSearchUX";

const CardRoomSearch = (props: any) => {
  const [input, setInput] = useState<string>("");
  const [roomNo, setRoomNo] = useState<string>("");
  const [wardType, setWardType] = useState<any>("");
  const [roomType, setRoomType] = useState<any>("");
  const [roomStatus, setRoomStatus] = useState<any>("");
  const [selectedRoom, setSelectedRoom] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [roomList, setRoomList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setRoomNo(input);
    }
  }, [open]);

  useEffect(() => {
    setInput(props.defaultValue);
    handleKeyPressInput({ key: "Enter" }, props.defaultValue);
  }, []);

  const handleChangeInput = (e: any, v: InputProps) => {
    setInput(v.value);
    props.onEnter?.({});
  };

  const handleKeyPressInput = async (e: any, no: string = "") => {
    const value = no || input;

    if (e.key === "Enter" && value) {
      const result = await props.getRoomList({
        can_reserve: true,
        room_no__icontains: value,
      });

      const room = result[0]?.items?.[0];

      if (!room?.id) {
        props.onEnter?.({});

        if (!no) {
          handleOpenCard();
        }
      } else {
        props.onEnter?.(room);
      }
    }
  };

  const handleOpenCard = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };
  const handleChangeRoomNo = (e: any, v: InputProps) => {
    setRoomNo(v.value);
  };

  const handleChangeWardType = (e: any, v: DropdownProps) => {
    setWardType(v.value);
  };

  const handleChangeRoomType = (e: any, v: DropdownProps) => {
    setRoomType(v.value);
  };

  const handleChangeRoomStatus = (e: any, v: DropdownProps) => {
    setRoomStatus(v.value);
  };

  const handleSearch = async () => {
    setLoading(true);

    const result = await props.getRoomList({
      can_reserve: true,
      room_no__icontains: roomNo,
      ward_type: wardType || undefined,
      room_type: roomType || undefined,
      status_name: roomStatus || undefined,
    });

    setLoading(false);

    setRoomList(result[0]?.items || []);
  };

  const handleClear = () => {
    setRoomNo("");
    setWardType("");
    setRoomStatus("");
    setRoomType("");
    setRoomList([]);
    setSelectedRoom(null);
  };

  const handleSelect = () => {
    if (selectedRoom) {
      props.onSelect?.(selectedRoom);
      setInput(selectedRoom?.room_no);
      handleClose();
    }
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    style: {
      backgroundColor:
        selectedRoom?.id && rowInfo?.original?.id === selectedRoom?.id
          ? "#cccccc"
          : "white",
    },
    onClick: (e: any) => {
      setSelectedRoom(rowInfo?.original);
    },
    onDoubleClick: (e: any) => {
      props.onSelect?.(rowInfo?.original);
      setInput(rowInfo?.original?.room_no);
      handleClose();
    },
  });

  return (
    <div>
      <Input
        value={input}
        disabled={props.disabled}
        onChange={handleChangeInput}
        onKeyPress={handleKeyPressInput}
        action={{
          icon: "search",
          onClick: (event: any) => {
            handleOpenCard();
          },
          type: "button",
        }}
        fluid={props.fluid}
      />
      <Modal open={open} onClose={handleClose} closeOnDimmerClick>
        <CardRoomSearchUX
          // data
          roomNo={roomNo}
          wardType={wardType}
          roomType={roomType}
          roomStatus={roomStatus}
          roomList={roomList}
          loading={loading}
          // options
          wardTypeOptions={props.wardTypeOptions}
          roomTypeOptions={props.roomTypeOptions}
          roomStatusOptions={props.roomStatusOptions}
          // callback
          onChangeRoomNo={handleChangeRoomNo}
          onChangeWardType={handleChangeWardType}
          onChangeRoomType={handleChangeRoomType}
          onChangeRoomStatus={handleChangeRoomStatus}
          onSearch={handleSearch}
          onClear={handleClear}
          onClose={handleClose}
          onSelect={handleSelect}
          onGetTrProps={handleGetTrProps}
        />
      </Modal>
    </div>
  );
};

export default CardRoomSearch;
