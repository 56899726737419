import React, { useState, useEffect } from 'react';
import CardLayout from '../common/CardLayout';
import { Accordion, Icon, Divider } from 'semantic-ui-react'

const CardConsultNote = (props) => {
  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    props.onGetConsultNote({})
  }, [])

  const generateAccordion = () => {
    if(props.consultNoteData.length === 0){
      return 'No Data.'
    }
    return props.consultNoteData.map((items, index) => {
      return (
        <Accordion fluid styled>
          <Accordion.Title
            style={{ color: 'black', fontSize: 16 }}
            index={index}
            active={activeIndex === index}
            onClick={handleClick}
          >
            <Icon name='dropdown' /> Consult Note {items.ordered_doctor_name} - {items.consulted_doctor_name}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index} style={{ lineHeight: 2 }}>
            <text style={{ fontWeight: 'bold' }}> ผู้ขอ Consult:</text>&ensp;{items.ordered_doctor_name}
            &emsp; <text style={{ fontWeight: 'bold' }}> Division:</text> &ensp;{items.ordered_div_name}
            <br /><text style={{ fontWeight: 'bold' }}> รายละเอียด:</text>&ensp;{items.note}
            <br /><text style={{ fontWeight: 'bold' }}> วันเวลาที่สั่ง:</text>&ensp;{items.order_time}
            <br /><text style={{ fontWeight: 'bold' }}> ความเร่งด่วน:</text>&ensp;{items.urgency_name}
            <div>
              <Divider />
              <text style={{ fontWeight: 'bold' }}> ตอบกลับ Consult:</text>  {' '} {items.consulted_doctor_name}
              &emsp; <text style={{ fontWeight: 'bold' }}> Division:</text> {' '} {items.division_name}
              < br /> <text style={{ fontWeight: 'bold' }}> Current illness:</text>
              <br />{items.current_illness}
              <br /> <text style={{ fontWeight: 'bold' }}> Suggestion:</text>
              <br />{items.suggestion}
              <br /> <text style={{ fontWeight: 'bold' }}> Remark:</text>{' '} {items.note}
              <br /> <text style={{ fontWeight: 'bold' }}> รักษาต่อเนื่อง:</text>{' '} {items.need_continuous_medication}
            </div>
          </Accordion.Content>
        </Accordion>
      )
    })
  }

  return (
    <CardLayout
      titleText='Consult Note'
      headerColor='blue'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.consultNoteLoading}
      enableMargin={props.enableMargin}
    >
      {generateAccordion()}
    </CardLayout>
  )
}

CardConsultNote.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  consultNoteData: [],
  consultNoteLoading: false,
  onGetConsultNote: () => { },
  enableMargin: true
};

export default CardConsultNote;
