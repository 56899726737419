import JsBarcode from "jsbarcode";

import moment from "moment";

// Utils
import { formatDate } from "../../utils/dateUtils";
import { truncateString } from "react-lib/apps/HISV3/common/CommonInterface";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import { getLogoReportNResize } from "react-lib/apps/HISV3/common/CommonInterface";
import CONFIG from "config/config";

const FORM_NAME = "ORAppointmentForm";
const LOGO_HEIGHT = 30;

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

const ORAppointmentForm = async (props: any) => {
  console.log("ORAppointmentForm props: ", props);

  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    height: 30,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  const logoResize = await getLogoReportNResize(LOGO_HEIGHT, 1, FORM_NAME);

  return {
    pageSize: "A5",
    pageOrientation: "landscape",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 13,
    },
    pageMargins: (props.patient_full_name || "").length > 24 ? [10, 138, 10, 100] : [10, 118, 10, 100],
    styles: {
      facultyHeader: {
        fontSize: 20,
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 15,
      },
      fieldValue: {
        fontSize: 15,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 12.5,
      },
      miniFieldValue: {
        fontSize: 13,
      },
    },
    header: {
      margin: [10, 5, 10, 0],
      stack: [
        {
          columns: [
            !CONFIG.HIDE_COMPANY_LOGO_PRINT
              ? {
                  margin: [10, 0, 0, 0],
                  width: logoResize.width,
                  height: LOGO_HEIGHT,
                  image: "logo",
                  alignment: "left",
                }
              : null,
            !CONFIG.HIDE_COMPANY_LOGO_PRINT
              ? {
                  margin: [-10, 5, 0, 0],
                  width: "*",
                  stack:
                    !CONFIG.HIDE_COMPANY_LOGO_PRINT && CONFIG.COMPANY === "CU"
                      ? [
                          {
                            text: "โรงพยาบาลคณะทันตแพทย์ จุฬาลงกรณ์มหาวิทยาลัย",
                            style: "facultyHeader",
                            alignment: "center",
                          },
                          {
                            text: props.title || "ใบนัดหมายผ่าตัด",
                            style: "facultyHeader",
                            alignment: "center",
                          },
                        ]
                      : [
                          {
                            text: " ",
                            style: "facultyHeader",
                            alignemt: "center",
                            preserveLeadingSpaces: true,
                          },
                          {
                            text: props.title || "ใบนัดหมายผ่าตัด",
                            style: "facultyHeader",
                            alignment: "center",
                          },
                        ],
                }
              : null,
          ],
        },
        {
          margin: [0, 5, 0, 0],
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  margin: [20, 3, 20, 3],
                  border: [true, true, true, false],
                  stack: [
                    {
                      stack: [
                        {
                          field_1: "HN",
                          value_1: props.patient_hn || "",
                          field_2: "ชื่อ-นามสกุล",
                          value_2: props.patient_full_name || "",
                        },
                        {
                          field_1: "วันเดือนปีเกิด",
                          value_1: props?.patient_birthday || "",
                          field_2: "สิทธิการรักษา",
                          value_2: props.patient_coverage || "",
                        },
                      ].map((item) => ({
                        columns: [
                          { width: "16.5%", text: item.field_1, style: "fieldKey" },
                          { width: "4%", text: ":", style: "fieldValue" },
                          { width: "28%", text: item.value_1, style: "fieldValue" },
                          { width: "16.5%", text: item.field_2, style: "fieldKey" },
                          { width: "4%", text: ":", style: "fieldValue" },
                          { width: "28%", text: item.value_2, style: "fieldValue" },
                        ],
                      })),
                    },
                  ],
                },
              ],
            ],
          },
        },
      ],
    },
    content: [
      {
        margin: [0, 0, 0, 0],
        table: {
          widths: ["100%"],
          body: [
            [
              {
                margin: [20, 0, 20, 3],
                stack: [
                  {
                    stack: [
                      {
                        columns: [
                          { width: "16.5%", text: "วันที่นัดหมาย", style: "fieldKey" },
                          { width: "4%", text: ":", style: "fieldValue" },
                          { width: "28%", text: props.appointment_date || "", style: "fieldValue" },
                          { width: "16.5%", text: "เวลานัดหมาย", style: "fieldKey" },
                          { width: "4%", text: ":", style: "fieldValue" },
                          { width: "20%", text: props.appointment_time || "", style: "fieldValue" },
                          // issue 67782
                          CONFIG.COMPANY === "CU"
                            ? {
                                image: textToBase64BarcodeBlob(`P01${props.content_id || ""}`),
                                width: 45,
                                height: 22.5,
                                alignment: "right",
                              }
                            : { text: "" },
                        ],
                      },
                    ].concat(
                      [
                        // { field_1: "วันที่นัดหมาย", value_1: props.appointment_date || "", field_2: "เวลานัดหมาย", value_2: props.appointment_time || "" },
                        {
                          field_1: "แพทย์",
                          value_1: props.doctor || "",
                          field_2: "แผนก",
                          value_2: props.division || "",
                        },
                        {
                          field_1: "ประเภทการนัดหมาย",
                          value_1: props.appointment_type || "",
                          field_2: "ประเภทผ่าตัด",
                          value_2: props.operating_type || "",
                        },
                      ].map((item) => ({
                        columns: [
                          { width: "16.5%", text: item.field_1, style: "fieldKey" },
                          { width: "4%", text: ":", style: "fieldValue" },
                          { width: "28%", text: item.value_1, style: "fieldValue" },
                          { width: "16.5%", text: item.field_2, style: "fieldKey" },
                          { width: "4%", text: ":", style: "fieldValue" },
                          { width: "28%", text: item.value_2, style: "fieldValue" },
                        ],
                      }))
                    ),
                  },
                  {
                    columns: !props.hide_note
                      ? [
                          { width: "16.5%", text: "หมายเหตุ", style: "fieldKey" },
                          { width: "4%", text: ":", style: "fieldValue" },
                          { width: "75%", text: props.note || "", style: "fieldValue" },
                        ]
                      : [],
                  },
                  {
                    stack: [
                      { text: "คำแนะนำในการปฏิบัติตัว", style: "fieldKey", width: "*" },
                      {
                        margin: [5.5, 0, 0, 0],
                        ul: (props.remark || []).map((note: string) => ({
                          text: note,
                          style: "miniFieldValue",
                        })),
                      },
                    ],
                  },
                  {
                    margin: [10, 0, 0, 0],
                    text: [
                      { text: props.npo, style: "miniFieldKey", width: "*" },
                      {
                        text: props.npoDetail,
                        style: "miniFieldValue",
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [0, currentPage === 1 ? 0 : 77.5, 0, 0],
        stack: [
          currentPage === 1
            ? {
                columns: [
                  {
                    width: "60%",
                    margin: [20, 25, 0, 0],
                    stack: [
                      // issue 67782
                      CONFIG.COMPANY === "CU"
                        ? {
                            text: "*กรุณายื่นบัตรนัดที่จุดคัดกรองทุกครั้งที่เข้ารับบริการ",
                            style: "miniFieldValue",
                          }
                        : { text: " ", preserveLeadingSpaces: true },
                      {
                        text: "*กรุณาลงทะเบียนก่อนเวลานัดหมายอย่างน้อย 30 นาที",
                        style: "miniFieldValue",
                      },
                    ],
                  },
                  {
                    width: "20%",
                    alignment: "center",
                    margin: [0, 10, 0, 0],
                    stack:
                      !props.hide_before_anesthesia && CONFIG.COMPANY === "CU" // issue 67782
                        ? [
                            { margin: [0, 0, 0, 0], width: 50, image: "before_anesthesia" },
                            { text: "คำแนะนำก่อนดมยา", style: "miniFieldValue" },
                          ]
                        : [{ text: " ", preserveLeadingSpaces: true }],
                  },
                  {
                    width: "20%",
                    alignment: "center",
                    margin: [0, 10, 0, 0],
                    stack:
                      CONFIG.COMPANY === "CU" // issue 67782
                        ? [
                            { margin: [0, 0, 0, 0], width: 50, image: "before_treatment" },
                            { text: "ข้อมูลก่อนเข้ารักษา", style: "miniFieldValue" },
                          ]
                        : [{ text: " ", preserveLeadingSpaces: true }],
                  },
                ],
              }
            : { text: "" },
          {
            columns: [
              {
                margin: [20, 0, 0, 0],
                text: [
                  { text: "ผู้ออกใบนัด: ", style: "miniFieldValue" },
                  { text: props.staff || "", style: "miniFieldValue" },
                ],
              },
              {
                alignment: "right",
                margin: [0, 0, 20, 0],
                text: [
                  { text: `วัน-เวลาที่พิมพ์: ${formatDate(moment())} `, style: "miniFieldValue" },
                  { text: moment().format("[[]HH:mm[]]"), style: "miniFieldValue" },
                ],
              },
            ],
          },
        ],
      };
    },
    images: {
      ...images,
      before_anesthesia: origin + "/static/images/before_anesthesia.png",
      before_treatment: origin + "/static/images/before_treatment.jpg",
    },
  };
};

export default ORAppointmentForm;
