import React, {
  useState,
  useEffect,
  useReducer,
  forwardRef,
  useRef,
  useImperativeHandle
} from "react";
import { Input, Modal, Search } from "semantic-ui-react";
import CardDoctorSearch from "./CardDoctorSearch";
import PropTypes from "prop-types";
import _ from "../../compat/lodashplus";

const DoctorCompleteBox = forwardRef((props, ref) => {

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [value, setValue] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(props.defaultdoctor)

  useImperativeHandle(ref, () => ({
    getSelectedDoctor: () => {
      return selectedDoctor
    },
  }));
  
  // useEffect( () => {
  //   console.log('')
  //   setValue(selectedDoctor.name_code)
  // },[selectedDoctor])

  // Set Name from props 
  useEffect( () => {
    setSelectedDoctor(props.defaultdoctor)
    if (props.defaultdoctor && props.defaultdoctor.name_code) {
      // console.log('DoctorCompleteBox setValue')
      setValue(props.defaultdoctor.name_code)
    }
  },[props.defaultdoctor])

  const handleResultSelect = (e:any, { result }:any) => {
    if (result && result.title) {
      setValue(result.title);
      setResults([result])
    }
    setSelectedDoctor(result)
  };

  const handleSearchChange = (e:any, { value }:any) => {
    setValue(value)
    if (selectedDoctor !== null) {
      setSelectedDoctor(null)
    }
    if (value.length > 2) {
      // Prepare start searching 
      setIsLoading(true)
    } else {
      if (results != []) {
        setResults([])
      }
      return 
    }

    setTimeout(async () => {
      if (value.length < 1) {
        setIsLoading(false);
        setResults([]);
        setValue("");
        return  
      }

      let [data, error, numPage] = await props.controller.searchDoctorName({nameCode: value})
      const re = new RegExp(_.escapeRegExp(value), "i");
      const isMatch = result => re.test(result.name_code);
    
      let found = _.filter(data, isMatch)

      if (found && found.length > 0) {
        found = found.map(item => {
          delete item["common"]
          delete item["is_active"]
          return item
        })
      }
      setIsLoading(false);

      if (props.isDoctorOptions) {
        setResults(data);
      } else {
        setResults(found);
      }
      
    }, 300);
  };

  // For Search when doctorname came from props
  const handleOnFocus = (e:any, data:any) => {
    if (results && (results.length == 0) && value.length > 2) {
      handleSearchChange(e, data )
    }
  }

  console.log('DoctorCompleteBox props.', props)
  return (
    <Search 
      fluid
      placeholder={props.placeholder}
      loading={isLoading}
      onResultSelect={handleResultSelect}
      onSearchChange={_.debounce(handleSearchChange, 500, { leading: true })}
      onFocus={handleOnFocus}
      results={results}
      value={value}
      {...props}
    />
  );
});

DoctorCompleteBox.defaultProps = {
  // defaultValue: '',
  // specialitiesList: [],
  // doctorList: [],
  // doctorListLoading: false,
  // doctorListNumberOfPage: 0,
  // currentPage: 0,
  // onPageChange: () => {},
  // getSpecialitiesList: () => { },
  // getSearchDoctorList: ()=> {},
  // onSelectDoctor: () => { },

  controller: null,
  minCharacters: 3,
  defaultdoctor: {},
  placeholder: "เว้นว่างเพื่อหาทุกแพทย์",
  isDoctorOptions: false,
};

DoctorCompleteBox.propTypes = {

  // dispatchAction: PropTypes.object,
  // initialValue: PropTypes.string,
  // defaultValue: PropTypes.string,
  // specialitiesList: PropTypes.array,
  // doctorList: PropTypes.array,
  // doctorListLoading: PropTypes.bool,
  // doctorListNumberOfPage: PropTypes.number,
  // currentPage: PropTypes.number, 
  // onPageChange: PropTypes.func,
  // getSpecialitiesList: PropTypes.func,
  // getSearchDoctorList: PropTypes.func,
  // onSelectDoctor: PropTypes.func,

  controller: PropTypes.object,
  defaultdoctor: PropTypes.object,
  minCharacters: PropTypes.number,
  placeholder: PropTypes.string,
  isDoctorOptions: PropTypes.bool,
};


export default React.memo(DoctorCompleteBox);
