import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  Button,
  Checkbox,
  Dimmer,
  Dropdown,
  Form,
  FormField,
  FormGroup,
  Grid,
  Input,
  Loader,
  Modal,
  Popup,
  Radio,
  TextArea,
} from "semantic-ui-react";

import TimeComboBox from "react-lib/apps/common/TimeComboBox";

// Styles
import "./CardPostAnestheticRecoveryScore.scss";

const styles = {
  labelBold: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  labelTitle: {
    background: "#2b3f51",
    color: "#fff",
  },
  labelHeader: {
    background: "#bdbdbd",
  },
  labelOdd: {
    background: "#fafafb",
  },
  labelEvent: {
    background: "#fff",
  },
};

type CardPostAnestheticRecoveryScoreProps = {
  // function
  setProp: any;

  // seq
  PACURecordSequence?: any;
  masterOptions?: any;
  runSequence?: any;
  buttonLoadCheck?: any;
};

const CARD_POST_ANESTHETIC_RECOVERY_SCORE = "CardPostAnestheticRecoveryScore";

const CardPostAnestheticRecoveryScore = (
  props: CardPostAnestheticRecoveryScoreProps
) => {
  const [openDischarge, setOpenDischarge] = useState<boolean>(false);

  const anesPACUScoreRef = useRef<any>(null);

  useEffect(() => {
    props.runSequence({
      sequence: "PACURecord",
      action: "getPostAnesthetic",
      card: CARD_POST_ANESTHETIC_RECOVERY_SCORE,
    });
  }, []);

  useEffect(() => {
    anesPACUScoreRef.current = props.PACURecordSequence?.anesPACUScore;
  }, [props.PACURecordSequence]);

  const disabledColumnIndex = useMemo(() => {
    const anesPACUScore = props.PACURecordSequence?.anesPACUScore;
    const anesDischargeScore = props.PACURecordSequence?.anesDischargeScore;

    const buttonPACUScore: any[] = anesPACUScore?.buttonPACUScore || [];
    const buttonDischargeScore: any[] = anesDischargeScore?.buttonDischargeScore || [];
    const pacuScore: any[] = anesPACUScore?.PACUScore || [];
    const dischargeScore: any[] = anesDischargeScore?.dischargeScore || [];

    const length = Object.keys(buttonPACUScore).length;

    const lists = Array.from({ length })
      .fill("")
      .map((_, index) => index)
      .filter((idx) => !buttonPACUScore[idx]?.editUser);

    const passPacuIndex = pacuScore.findIndex((value: any) => value >= 9);

    const passDischargeIndex = dischargeScore.findIndex((value: any) => value >= 9);

    const isPass = !!buttonPACUScore[passPacuIndex] && !!buttonDischargeScore[passDischargeIndex];

    if (!isPass) {
      lists.shift();
    }

    return lists;
  }, [props.PACURecordSequence]);

  const handleChangeData = async (index: number, data: any, type: any) => {
    let postAnesthetic = props.PACURecordSequence?.[type];
    let postData = {
      ...postAnesthetic,
    };

    const oldValue = postData[data.name][index];

    postData[data.name][index] = data.value;

    let sumScore = 0;
    let sumClear = 5;
    Object.keys(postAnesthetic)?.map((item: any) => {
      if (
        ![
          "id",
          "time",
          "PACUScore",
          "buttonPACUScore",
          "dischargeScore",
          "buttonDischargeScore",
          "totalDischargeScore",
        ].includes(item)
      ) {
        if (typeof postData[item][index] === "number") {
          sumScore = sumScore + (postData[item][index] - 1);
        } else {
          sumClear = sumClear - 1;
        }
      }
    });

    if (type === "anesPACUScore") {
      postData["PACUScore"][index] = sumClear === 0 ? null : sumScore;
    } else if (type === "anesDischargeScore") {
      postData["dischargeScore"][index] = sumClear === 0 ? null : sumScore;
    }

    await props.setProp(`PACURecordSequence.${type}`, postData);

    handleUpdateDataChanged({ oldValue, type, index, ...data });
  };

  const handleChangeTime = async (index: number, time: any) => {
    const postData = {
      ...anesPACUScoreRef.current,
    };

    const oldValue = postData.time?.[index];

    postData["time"][index] = time;

    await props.setProp("PACURecordSequence.anesPACUScore", postData);

    handleUpdateDataChanged({
      oldValue,
      type: "anesPACUScore",
      index,
      name: "time",
      value: time,
    });
  };

  const handleUpdateDataChanged = (data: any) => {
    const dataChanged =
      props.PACURecordSequence[`${data.type}Changed`]?.[data.index] || {};
    const tempValue = dataChanged?.[data.name]?.[0];

    if (typeof tempValue !== "undefined") {
      data.oldValue = tempValue;
    }

    if (data.oldValue === data.value) {
      delete dataChanged[data.name];
    } else {
      dataChanged[data.name] = [data.oldValue, data.value];
    }

    props.setProp(`PACURecordSequence.${data.type}Changed.${data.index}`, {
      ...dataChanged,
    });
  };

  return (
    <div
      id="CardPostAnestheticRecoveryScore"
      style={{
        width: "100%",
        height: "100%",
        padding: "10px",
        position: "relative",
      }}
    >
      <div
        style={{
          backgroundColor: "#EDF6F9",
          width: "100%",
          padding: "10px",
          borderRadius: 3,
          border: "solid 0.5px ",
          borderColor: "#5DBCD2",
        }}
      >
        <Dimmer
          active={
            !!props.buttonLoadCheck?.[CARD_POST_ANESTHETIC_RECOVERY_SCORE]
          }
          inverted
        >
          <Loader />
        </Dimmer>
        <Grid celled verticalAlign="middle">
          {subjectsPACUScore.map((subj: any, sindex: number) => {
            return (
              <Grid.Row key={sindex} style={subj.style}>
                <Grid.Column width={2}>
                  <label style={styles.labelBold}>{subj.title}</label>
                </Grid.Column>
                {Array.from({ length: 7 }).map((_item: any, index: number) => {
                  return (
                    <Grid.Column width={2} key={`${sindex}-${index}`}>
                      {subj?.typeForm === "time" ? (
                        <TimeComboBox
                          disabled={disabledColumnIndex.includes(index)}
                          defaultValue={
                            props.PACURecordSequence?.anesPACUScore?.[
                              subj.key
                            ]?.[index] || ""
                          }
                          onTextChange={(time: any) => {
                            handleChangeTime(index, time);
                          }}
                        />
                      ) : subj?.key === "PACUScore" ? (
                        <div
                          className={
                            props.PACURecordSequence?.anesPACUScore
                              ?.PACUScore?.[index] === null
                              ? ""
                              : props.PACURecordSequence?.anesPACUScore
                                  ?.PACUScore?.[index] < 9
                              ? "scoreBad"
                              : props.PACURecordSequence?.anesPACUScore
                                  ?.PACUScore?.[index] >= 9 && "scoreGood"
                          }
                        >
                          <Input
                            fluid
                            readonly
                            name={subj.key}
                            value={
                              typeof props.PACURecordSequence?.anesPACUScore
                                ?.PACUScore?.[index] === "number"
                                ? props.PACURecordSequence?.anesPACUScore
                                    ?.PACUScore?.[index]
                                : ""
                            }
                            disabled={disabledColumnIndex.includes(index)}
                          />
                        </div>
                      ) : subj?.key === "buttonPACUScore" ? (
                        <div
                          style={{
                            ...(props.PACURecordSequence?.anesPACUScore
                              ?.PACUScore?.[index] === null &&
                              !props.PACURecordSequence?.anesPACUScore?.time?.[
                                index
                              ] && {
                                display: "none",
                              }),
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="green"
                              disabled={
                                !Object.keys(
                                  props.PACURecordSequence
                                    ?.anesPACUScoreChanged?.[index] || {}
                                ).length
                              }
                              onClick={() => {
                                props.runSequence({
                                  sequence: "PACURecord",
                                  action: "SavePACUScore",
                                  index: index,
                                });
                              }}
                            >
                              SAVE
                            </Button>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {
                              props.PACURecordSequence?.anesPACUScore
                                ?.buttonPACUScore?.[index]?.editUser
                            }
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {
                              props.PACURecordSequence?.anesPACUScore
                                ?.buttonPACUScore?.[index]?.editDate
                            }
                          </div>
                        </div>
                      ) : (
                        subj?.typeForm === "dropdown" && (
                          <div>
                            {props.PACURecordSequence?.anesPACUScore?.[
                              subj.key
                            ]?.[index] ? (
                              <Popup
                                trigger={
                                  <div className="fixDropDown">
                                    <Dropdown
                                      fluid
                                      name={subj.key}
                                      options={subj?.options}
                                      search={true}
                                      selection={true}
                                      clearable={true}
                                      value={
                                        props.PACURecordSequence
                                          ?.anesPACUScore?.[subj.key]?.[
                                          index
                                        ] || ""
                                      }
                                      disabled={disabledColumnIndex.includes(
                                        index
                                      )}
                                      onChange={(_event: any, data: any) =>
                                        handleChangeData(
                                          index,
                                          data,
                                          "anesPACUScore"
                                        )
                                      }
                                    ></Dropdown>
                                  </div>
                                }
                                content={
                                  <div>
                                    {
                                      subj?.options?.filter(
                                        (item: any) =>
                                          item.value ===
                                          props.PACURecordSequence
                                            ?.anesPACUScore?.[subj.key]?.[index]
                                      )?.[0]?.text
                                    }
                                  </div>
                                }
                                on="hover"
                                position="top right"
                              />
                            ) : (
                              <div className="fixDropDown">
                                <Dropdown
                                  fluid
                                  name={subj.key}
                                  options={subj?.options}
                                  search={true}
                                  selection={true}
                                  clearable={true}
                                  value={
                                    props.PACURecordSequence?.anesPACUScore?.[
                                      subj.key
                                    ]?.[index] || ""
                                  }
                                  disabled={disabledColumnIndex.includes(index)}
                                  onChange={(_event: any, data: any) =>
                                    handleChangeData(
                                      index,
                                      data,
                                      "anesPACUScore"
                                    )
                                  }
                                ></Dropdown>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            );
          })}
          {subjectsDischargeScore.map((subj: any, sindex: number) => {
            return (
              <Grid.Row key={sindex} style={subj.style}>
                <Grid.Column width={2}>
                  <label style={styles.labelBold}>{subj.title}</label>
                </Grid.Column>
                {[...Array(7)].map((_item: any, index: number) => {
                  return (
                    <Grid.Column width={2} key={index}>
                      {subj?.key === "dischargeScore" ? (
                        <div
                          className={
                            props.PACURecordSequence?.anesDischargeScore
                              ?.dischargeScore?.[index] === null
                              ? ""
                              : props.PACURecordSequence?.anesDischargeScore
                                  ?.dischargeScore?.[index] < 9
                              ? "scoreBad"
                              : props.PACURecordSequence?.anesDischargeScore
                                  ?.dischargeScore?.[index] >= 9 && "scoreGood"
                          }
                        >
                          <Input
                            fluid
                            readonly
                            name={subj.key}
                            value={
                              typeof props.PACURecordSequence
                                ?.anesDischargeScore?.dischargeScore?.[
                                index
                              ] === "number"
                                ? props.PACURecordSequence?.anesDischargeScore
                                    ?.dischargeScore?.[index]
                                : ""
                            }
                            disabled={disabledColumnIndex.includes(index)}
                          />
                        </div>
                      ) : subj?.key === "buttonDischargeScore" ? (
                        <div
                          style={{
                            ...(props.PACURecordSequence?.anesDischargeScore
                              ?.dischargeScore?.[index] === null && {
                              display: "none",
                            }),
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="green"
                              disabled={
                                !Object.keys(
                                  props.PACURecordSequence
                                    ?.anesDischargeScoreChanged?.[index] || {}
                                ).length
                              }
                              onClick={() => {
                                props.runSequence({
                                  sequence: "PACURecord",
                                  action: "SaveDischargeScore",
                                  index: index,
                                });
                              }}
                            >
                              SAVE
                            </Button>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {
                              props.PACURecordSequence?.anesDischargeScore
                                ?.buttonDischargeScore?.[index]?.editUser
                            }
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {
                              props.PACURecordSequence?.anesDischargeScore
                                ?.buttonDischargeScore?.[index]?.editDate
                            }
                          </div>
                        </div>
                      ) : (
                        subj?.typeForm === "dropdown" && (
                          <div>
                            {props.PACURecordSequence?.anesDischargeScore?.[
                              subj.key
                            ]?.[index] ? (
                              <Popup
                                trigger={
                                  <div className="fixDropDown">
                                    <Dropdown
                                      fluid
                                      name={subj.key}
                                      options={subj?.options}
                                      search={true}
                                      selection={true}
                                      clearable={true}
                                      value={
                                        props.PACURecordSequence
                                          ?.anesDischargeScore?.[subj.key]?.[
                                          index
                                        ] || ""
                                      }
                                      disabled={disabledColumnIndex.includes(
                                        index
                                      )}
                                      onChange={(_event: any, data: any) =>
                                        handleChangeData(
                                          index,
                                          data,
                                          "anesDischargeScore"
                                        )
                                      }
                                    ></Dropdown>
                                  </div>
                                }
                                content={
                                  <div>
                                    {
                                      subj?.options?.filter(
                                        (item: any) =>
                                          item.value ===
                                          props.PACURecordSequence
                                            ?.anesDischargeScore?.[subj.key]?.[
                                            index
                                          ]
                                      )?.[0]?.text
                                    }
                                  </div>
                                }
                                on="hover"
                                position="top right"
                              />
                            ) : (
                              <div className="fixDropDown">
                                <Dropdown
                                  fluid
                                  name={subj.key}
                                  options={subj?.options}
                                  search={true}
                                  selection={true}
                                  clearable={true}
                                  value={
                                    props.PACURecordSequence
                                      ?.anesDischargeScore?.[subj.key]?.[
                                      index
                                    ] || ""
                                  }
                                  disabled={disabledColumnIndex.includes(index)}
                                  onChange={(_event: any, data: any) =>
                                    handleChangeData(
                                      index,
                                      data,
                                      "anesDischargeScore"
                                    )
                                  }
                                ></Dropdown>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            );
          })}
          {/* <Grid.Row style={styles.labelOdd}>
            <Grid.Column width={3}>
              <label style={styles.labelBold}>Total discharge score</label>
            </Grid.Column>
            <Grid.Column width={13}>
              <div
                className={
                  props.PACURecordSequence?.postAnestheticRecoveryScoreData
                    ?.dischargeScore?.[numberTotal] === null
                    ? ""
                    : props.PACURecordSequence?.postAnestheticRecoveryScoreData
                        ?.dischargeScore?.[numberTotal] < 9
                    ? "scoreBad"
                    : props.PACURecordSequence?.postAnestheticRecoveryScoreData
                        ?.dischargeScore?.[numberTotal] >= 9 && "scoreGood"
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Input
                      readonly
                      value={
                        typeof props.PACURecordSequence
                          ?.postAnestheticRecoveryScoreData?.dischargeScore?.[
                          numberTotal
                        ] === "number"
                          ? props.PACURecordSequence
                              ?.postAnestheticRecoveryScoreData
                              ?.dischargeScore?.[numberTotal]
                          : ""
                      }
                    />
                  </div>

                  <div style={{ margin: "0px 10px" }}>
                    {props.PACURecordSequence?.postAnestheticRecoveryScoreData
                      ?.dischargeScore?.[numberTotal] === null ? (
                      ""
                    ) : props.PACURecordSequence
                        ?.postAnestheticRecoveryScoreData?.dischargeScore?.[
                        numberTotal
                      ] < 9 ? (
                      <p style={{ color: "red" }}>NOT PASS</p>
                    ) : (
                      props.PACURecordSequence?.postAnestheticRecoveryScoreData
                        ?.dischargeScore?.[numberTotal] >= 9 && (
                        <p style={{ color: "green" }}>PASS</p>
                      )
                    )}
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row> */}
        </Grid>
        <Form>
          <FormGroup inline={true}>
            <FormField inline={true} width={2}>
              <label style={styles.labelBold}>Total discharge score</label>
            </FormField>
            <FormField inline={true} width={2}>
              <div style={{ width: "500px" }}>
                <div
                  className={
                    props.PACURecordSequence?.anesDischargeScore
                      ?.totalDischargeScore?.score === null
                      ? ""
                      : props.PACURecordSequence?.anesDischargeScore
                          ?.totalDischargeScore?.score < 9
                      ? "scoreBad"
                      : props.PACURecordSequence?.anesDischargeScore
                          ?.totalDischargeScore?.score >= 9 && "scoreGood"
                  }
                >
                  <Input
                    readonly
                    value={
                      props.PACURecordSequence?.anesDischargeScore
                        ?.totalDischargeScore?.score
                    }
                  />
                </div>
              </div>
            </FormField>
            <FormField inline={true} width={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 10px",
                }}
              >
                {props.PACURecordSequence?.anesDischargeScore
                  ?.totalDischargeScore?.score === null ? (
                  ""
                ) : props.PACURecordSequence?.anesDischargeScore
                    ?.totalDischargeScore?.score < 9 ? (
                  <div>
                    <p style={{ color: "red" }}>NOT PASS</p>{" "}
                    {`ที่ช่วงเวลา ${props.PACURecordSequence?.anesDischargeScore?.totalDischargeScore?.time}`}
                  </div>
                ) : (
                  props.PACURecordSequence?.anesDischargeScore
                    ?.totalDischargeScore?.score >= 9 && (
                    <div>
                      <p style={{ color: "green" }}>PASS</p>{" "}
                      {`ที่ช่วงเวลา ${props.PACURecordSequence?.anesDischargeScore?.totalDischargeScore?.time}`}
                    </div>
                  )
                )}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup inline={true}>
            <FormField
              inline={true}
              width={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <Checkbox
                  label="Transfer to "
                  disabled={
                    props.PACURecordSequence?.anesDischargeScore
                      ?.totalDischargeScore?.score < 9
                  }
                  checked={props.PACURecordSequence?.anesTransfer?.transfer}
                  onChange={(e, { checked }) => {
                    props.setProp(
                      "PACURecordSequence.anesTransfer.transfer",
                      !props.PACURecordSequence?.anesTransfer?.transfer
                    );
                  }}
                ></Checkbox>
              </div>
            </FormField>
            <FormField inline={true} width={4}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "20px" }}>
                  <Radio
                    label="Ward"
                    disabled={!props.PACURecordSequence?.anesTransfer?.transfer}
                    checked={
                      props.PACURecordSequence?.anesTransfer?.transfer &&
                      props.PACURecordSequence?.anesTransfer?.transferType ===
                        "ward"
                    }
                    onChange={() => {
                      props.setProp(
                        "PACURecordSequence.anesTransfer.transferType",
                        "ward"
                      );
                    }}
                  ></Radio>
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Dropdown
                    clearable={true}
                    disabled={
                      props.PACURecordSequence?.anesTransfer?.transfer ===
                        false ||
                      props.PACURecordSequence?.anesTransfer?.transferType !==
                        "ward"
                    }
                    onChange={(e, { value }) =>
                      props.setProp(
                        "PACURecordSequence.anesTransfer.wardSelect",
                        value
                      )
                    }
                    options={props.masterOptions?.divisionWard}
                    search={true}
                    selection={true}
                    value={
                      props.PACURecordSequence?.anesTransfer?.transfer &&
                      props.PACURecordSequence?.anesTransfer?.transferType ===
                        "ward" &&
                      props.PACURecordSequence?.anesTransfer?.wardSelect
                    }
                  ></Dropdown>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <Radio
                    label="ICU"
                    disabled={!props.PACURecordSequence?.anesTransfer?.transfer}
                    checked={
                      props.PACURecordSequence?.anesTransfer?.transfer &&
                      props.PACURecordSequence?.anesTransfer?.transferType ===
                        "icu"
                    }
                    onChange={() => {
                      props.setProp(
                        "PACURecordSequence.anesTransfer.transferType",
                        "icu"
                      );
                    }}
                  ></Radio>
                </div>
              </div>
            </FormField>
            {/* <FormField inline={true} width={1}>
              <div>
                <Radio
                  label="ICU"
                  disabled={!props.PACURecordSequence?.anesTransfer?.transfer}
                  checked={
                    props.PACURecordSequence?.anesTransfer?.transfer &&
                    props.PACURecordSequence?.anesTransfer?.transferType ===
                      "icu"
                  }
                  onChange={() => {
                    props.setProp(
                      "PACURecordSequence.anesTransfer.transferType",
                      "icu"
                    );
                  }}
                ></Radio>
              </div>
            </FormField> */}
            <FormField
              inline={true}
              width={10}
              style={{
                display: "none",
                ...((props.PACURecordSequence?.anesTransfer?.transferType ===
                  "icu" ||
                  props.PACURecordSequence?.anesTransfer?.transferType ===
                    "ward") &&
                  props.PACURecordSequence?.anesTransfer?.transfer && {
                    display: "flex",
                    justifyContent: "flex-end",
                  }),
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      marginLeft: "10px",
                      color: "white",
                      background: "#0147A3",
                    }}
                    onClick={() => {
                      props.runSequence({
                        sequence: "PACURecord",
                        action: "SaveAnesTransfer",
                      });
                    }}
                  >
                    Transfer
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {props.PACURecordSequence?.anesTransfer?.edit_user_name}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {props.PACURecordSequence?.anesTransfer?.edited}
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup inline={true}>
            <FormField inline={true} width={2}></FormField>
            {/* <FormField inline={true} width={1}>
              <div>
                <Radio
                  label="Discharge"
                  disabled={!props.PACURecordSequence?.anesTransfer?.transfer}
                  anesTransfer
                  checked={
                    props.PACURecordSequence?.anesTransfer?.transfer &&
                    props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge"
                  }
                  onChange={() => {
                    props.setProp(
                      "PACURecordSequence.anesTransfer.transferType",
                      "discharge"
                    );
                  }}
                ></Radio>
              </div>
            </FormField> */}
            <FormField inline={true} width={13} style={{ display: "flex" }}>
              <div style={{ minWidth: "87px" }}>
                <Radio
                  label="Discharge"
                  disabled={!props.PACURecordSequence?.anesTransfer?.transfer}
                  anesTransfer
                  checked={
                    props.PACURecordSequence?.anesTransfer?.transfer &&
                    props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge"
                  }
                  onChange={() => {
                    props.setProp(
                      "PACURecordSequence.anesTransfer.transferType",
                      "discharge"
                    );
                  }}
                ></Radio>
              </div>
              <div
                style={{ display: "flex", margin: "0px 10px", width: "12%" }}
              >
                นัดหมายติดตามอาการ<p style={{ color: "red" }}>*</p>
              </div>
              <div style={{ margin: "15px" }}>
                <Checkbox
                  toggle
                  label={
                    props.PACURecordSequence?.anesTransfer?.checkAppointment &&
                    props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge"
                      ? "YES"
                      : "NO"
                  }
                  disabled={
                    props.PACURecordSequence?.anesTransfer?.transfer ===
                      false ||
                    props.PACURecordSequence?.anesTransfer?.transferType !==
                      "discharge"
                  }
                  checked={
                    props.PACURecordSequence?.anesTransfer?.transfer &&
                    props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge" &&
                    props.PACURecordSequence?.anesTransfer?.checkAppointment
                  }
                  onChange={(e, { checked }) => {
                    props.setProp(
                      "PACURecordSequence.anesTransfer.checkAppointment",
                      !props.PACURecordSequence?.anesTransfer?.checkAppointment
                    );
                  }}
                ></Checkbox>
              </div>
              <div style={{ width: "70%" }}>
                <TextArea
                  rows={2}
                  disabled={
                    props.PACURecordSequence?.anesTransfer?.transfer ===
                      false ||
                    (props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge" &&
                      !props.PACURecordSequence?.anesTransfer
                        ?.checkAppointment) ||
                    props.PACURecordSequence?.anesTransfer?.transferType !==
                      "discharge"
                  }
                  value={
                    props.PACURecordSequence?.anesTransfer?.transfer ===
                      false ||
                    (props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge" &&
                      !props.PACURecordSequence?.anesTransfer
                        ?.checkAppointment) ||
                    props.PACURecordSequence?.anesTransfer?.transferType !==
                      "discharge"
                      ? ""
                      : props.PACURecordSequence?.anesTransfer?.appointmentData
                  }
                  onChange={(e, { value }) =>
                    props.setProp(
                      "PACURecordSequence.anesTransfer.appointmentData",
                      value
                    )
                  }
                  style={{ resize: "none" }}
                ></TextArea>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}
              style={{ display: "flex", justifyContent: "flex-end" }}
            ></FormField>
          </FormGroup>
          <FormGroup inline={true}>
            <FormField inline={true} width={2}></FormField>
            {/* <FormField inline={true} width={1}></FormField> */}
            <FormField inline={true} width={13} style={{ display: "flex" }}>
              <div style={{ minWidth: "87px" }} />
              <div
                style={{ display: "flex", margin: "0px 10px", width: "12%" }}
              >
                ยากลับบ้าน<p style={{ color: "red" }}>*</p>
              </div>
              <div style={{ margin: "15px" }}>
                <Checkbox
                  toggle
                  label={
                    props.PACURecordSequence?.anesTransfer?.checkHome &&
                    props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge"
                      ? "YES"
                      : "NO"
                  }
                  disabled={
                    props.PACURecordSequence?.anesTransfer?.transfer ===
                      false ||
                    props.PACURecordSequence?.anesTransfer?.transferType !==
                      "discharge"
                  }
                  checked={
                    props.PACURecordSequence?.anesTransfer?.transfer &&
                    props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge" &&
                    props.PACURecordSequence?.anesTransfer?.checkHome
                  }
                  onChange={(e, { checked }) => {
                    props.setProp(
                      "PACURecordSequence.anesTransfer.checkHome",
                      !props.PACURecordSequence?.anesTransfer?.checkHome
                    );
                  }}
                ></Checkbox>
              </div>
              <div style={{ width: "70%" }}>
                <TextArea
                  rows={2}
                  disabled={
                    props.PACURecordSequence?.anesTransfer?.transfer ===
                      false ||
                    (props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge" &&
                      !props.PACURecordSequence?.anesTransfer?.checkHome) ||
                    props.PACURecordSequence?.anesTransfer?.transferType !==
                      "discharge"
                  }
                  value={
                    props.PACURecordSequence?.anesTransfer?.transfer ===
                      false ||
                    (props.PACURecordSequence?.anesTransfer?.transferType ===
                      "discharge" &&
                      !props.PACURecordSequence?.anesTransfer?.checkHome) ||
                    props.PACURecordSequence?.anesTransfer?.transferType !==
                      "discharge"
                      ? ""
                      : props.PACURecordSequence?.anesTransfer?.homeData
                  }
                  onChange={(e, { value }) =>
                    props.setProp(
                      "PACURecordSequence.anesTransfer.homeData",
                      value
                    )
                  }
                  style={{ resize: "none" }}
                ></TextArea>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}
              style={{ display: "flex", justifyContent: "flex-end" }}
            ></FormField>
          </FormGroup>
          <FormGroup inline={true}>
            <FormField inline={true} width={2}></FormField>
            {/* <FormField inline={true} width={1}></FormField> */}
            <FormField inline={true} width={12} style={{ display: "flex" }}>
              <div style={{ minWidth: "87px" }} />
              <div
                style={{ display: "flex", margin: "0px 10px", width: "12%" }}
              >
                บันทึกคำแนะนำ<p style={{ color: "red" }}>*</p>
              </div>
              <div style={{ width: "70%" }}>
                <TextArea
                  rows={3}
                  disabled={
                    props.PACURecordSequence?.anesTransfer?.transfer ===
                      false ||
                    props.PACURecordSequence?.anesTransfer?.transferType !==
                      "discharge"
                  }
                  value={
                    props.PACURecordSequence?.anesTransfer?.transfer ===
                      false ||
                    props.PACURecordSequence?.anesTransfer?.transferType !==
                      "discharge"
                      ? ""
                      : props.PACURecordSequence?.anesTransfer?.instructionsData
                  }
                  onChange={(e, { value }) =>
                    props.setProp(
                      "PACURecordSequence.anesTransfer.instructionsData",
                      value
                    )
                  }
                  style={{ resize: "none" }}
                ></TextArea>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}
              style={{
                display: "none",
                ...(props.PACURecordSequence?.anesTransfer?.transferType ===
                  "discharge" &&
                  props.PACURecordSequence?.anesTransfer?.transfer && {
                    display: "flex",
                    justifyContent: "flex-end",
                  }),
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      marginLeft: "10px",
                      color: "white",
                      background: "#0147A3",
                    }}
                    onClick={() => {
                      props.runSequence({
                        sequence: "PACURecord",
                        action: "SaveAnesTransfer",
                      });
                    }}
                  >
                    Discharge
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {props.PACURecordSequence?.anesTransfer?.edit_user_name}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {props.PACURecordSequence?.anesTransfer?.edited}
                </div>
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>

      <Modal
        open={openDischarge}
        size={"small"}
        onClose={() => setOpenDischarge(false)}
      >
        <Modal.Header style={{ backgroundColor: "#0147A3" }}></Modal.Header>
        <Modal.Content>
          {/* <div style={{ display: "flex" }}>
            Transfer/Discharge by:
            <div style={{ margin: "0px 10px" }}>
              {props.PACURecordSequence?.postAnestheticRecoveryScoreData
                ?.buttonDischargeScore?.[numberTotal]?.editUser || ""}
            </div>
            <div style={{ margin: "0px 10px" }}>
              {props.PACURecordSequence?.postAnestheticRecoveryScoreData
                ?.buttonDischargeScore?.[numberTotal]?.editDate || ""}
            </div>
          </div> */}
        </Modal.Content>
      </Modal>
    </div>
  );
};

const subjectsPACUScore: any[] = [
  { key: "time", title: "Time", style: styles.labelTitle, typeForm: "time" },
  {
    key: "activity",
    title: "Activity",
    style: styles.labelOdd,
    typeForm: "dropdown",
    options: [
      { key: "4extremities", value: 3, text: "4 Extremities", score: 2 },
      { key: "2extremities", value: 2, text: "2 Extremities", score: 1 },
      { key: "0extremities", value: 1, text: "0 Extremities", score: 0 },
    ],
  },
  {
    key: "respiration",
    title: "Respiration",
    style: styles.labelEvent,
    typeForm: "dropdown",
    options: [
      {
        key: "beingAble",
        value: 3,
        text: "Being able to breathe and cough easily",
        score: 2,
      },
      {
        key: "dyspnea",
        value: 2,
        text: "Dyspnea, superficial, limited respiration",
        score: 1,
      },
      { key: "Apnea", value: 1, text: "Apnea", score: 0 },
    ],
  },
  {
    key: "circulation",
    title: "Circulation",
    style: styles.labelOdd,
    typeForm: "dropdown",
    options: [
      {
        key: "20",
        value: 3,
        text: "BP ± 20 mmHg Preanesthetic period",
        score: 2,
      },
      {
        key: "20-50",
        value: 2,
        text: "BP ± 20-50 mmHg Preanesthetic period",
        score: 1,
      },
      {
        key: "50",
        value: 1,
        text: "BP ±50 mmHg Preanesthetic period",
        score: 0,
      },
    ],
  },
  {
    key: "consciousness",
    title: "Consciousness",
    style: styles.labelEvent,
    typeForm: "dropdown",
    options: [
      {
        key: "fullyAwake",
        value: 3,
        text: "Fully awake",
        score: 2,
      },
      {
        key: "awakening",
        value: 2,
        text: "Awakening by verbal stimuli",
        score: 1,
      },
      { key: "noResponse", value: 1, text: "No response", score: 0 },
    ],
  },
  {
    key: "spO2Colour",
    title: "SpO2 / Colour",
    style: styles.labelOdd,
    typeForm: "dropdown",
    options: [
      {
        key: "temperature",
        value: 3,
        text: "At room temperature > 92%",
        score: 2,
      },
      {
        key: "inhalation",
        value: 2,
        text: "O2 inhalation is required when SpO2 < 90%",
        score: 1,
      },
      { key: "saturation", value: 1, text: "< 90% O2  saturation", score: 0 },
    ],
  },
  {
    key: "PACUScore",
    title: "Modified Aldrete Score",
    style: styles.labelHeader,
    typeForm: "input",
  },
  { key: "buttonPACUScore", title: "", typeForm: "button" },
];

const subjectsDischargeScore: any[] = [
  {
    key: "vitalSign",
    title: "Vital Sign",
    style: styles.labelOdd,
    typeForm: "dropdown",
    options: [
      {
        key: "20%",
        value: 3,
        text: "< 20% of preoperative value",
        score: 2,
      },
      {
        key: "20-40%",
        value: 2,
        text: "20-40% of preoperative value%",
        score: 1,
      },
      { key: "40%", value: 1, text: "> 40% of preoperative value", score: 0 },
    ],
  },
  {
    key: "ambulation",
    title: "Ambulation",
    style: styles.labelEvent,
    typeForm: "dropdown",
    options: [
      {
        key: "steady",
        value: 3,
        text: "Steady gait; no dizziness",
        score: 2,
      },
      {
        key: "with",
        value: 2,
        text: "With assistance",
        score: 1,
      },
      {
        key: "noAmbulation",
        value: 1,
        text: "No ambulation; dizziness",
        score: 0,
      },
    ],
  },
  {
    key: "nauseaVomiting",
    title: "Nausea / Vomiting",
    style: styles.labelOdd,
    typeForm: "dropdown",
    options: [
      {
        key: "minimal",
        value: 3,
        text: "Minimal",
        score: 2,
      },
      {
        key: "moderate",
        value: 2,
        text: "Moderate",
        score: 1,
      },
      { key: "severe", value: 1, text: "Severe", score: 0 },
    ],
  },
  {
    key: "pain",
    title: "Pain (Numberic Rating Scale)",
    style: styles.labelEvent,
    typeForm: "dropdown",
    options: [
      {
        key: "minimal",
        value: 3,
        text: "Minimal",
        score: 2,
      },
      {
        key: "moderate",
        value: 2,
        text: "Moderate",
        score: 1,
      },
      { key: "severe", value: 1, text: "Severe", score: 0 },
    ],
  },
  {
    key: "bleeding",
    title: "Bleeding",
    style: styles.labelOdd,
    typeForm: "dropdown",
    options: [
      {
        key: "minimal",
        value: 3,
        text: "Minimal",
        score: 2,
      },
      {
        key: "moderate",
        value: 2,
        text: "Moderate",
        score: 1,
      },
      { key: "severe", value: 1, text: "Severe", score: 0 },
    ],
  },
  {
    key: "dischargeScore",
    title: "Discharge Score",
    style: styles.labelHeader,
    typeForm: "input",
  },
  { key: "buttonDischargeScore", title: "", typeForm: "button" },
];

export default React.memo(CardPostAnestheticRecoveryScore);
