import React, {
  KeyboardEvent,
  useState,
  useRef,
  MutableRefObject,
  useEffect,
  useCallback,
} from "react";
import ModReceiptCode from "./ModReceiptCode";
// Config
import CONFIG from "config/config";

// Types
type CardReceiptNumberProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;

  // CommonInterface
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  // data
  bilReceiptCodeDetail?: {
    code: string;
    BIL_RECEIPT_SYSTEM_PREFIX: string;
    BIL_RECEIPT_PREFIX_SIZE: number;
    BIL_RECEIPT_RUNNING_SIZE: number;
  };
};

const CardReceiptNumber = (props: CardReceiptNumberProps) => {
  return (
    <div style={{ padding: "10px" }}>
      <div>ตั้งเลขที่ใบเสร็จ</div>

      {!CONFIG.BIL_AUTO_RUN_RECEIPT && (
        <ModReceiptCode
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          bilReceiptCodeDetail={props.bilReceiptCodeDetail}
          // CommonInterface
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          // config
          isDefault={true}
        />
      )}
    </div>
  );
};

export default React.memo(CardReceiptNumber);
