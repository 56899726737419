import FormCheckboxImages from "./FormCheckbox";
export default function toPDFMakeData(props: any) {
  return {
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        decorationStyle: ``,
        text: `ใบรับรองแพทย์`,
        decoration: ``,
        decorationColor: ``,
        alignment: `center`,
        fontSize: `18`,
        preserveLeadingSpaces: true,
        bold: `true`,
        width: `auto`,
        color: ``,
        pageBreak: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            text: `ส่วนที่ 1 `,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: `true`,
            width: `auto`,
            decoration: ``,
          },
          {
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            text: ``,
            width: 10,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            text: `ของผู้รับใบรองสุขภาพ`,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: `true`,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: `15`,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
            text: `ข้าพเจ้า นาย/นาง/นางสาว `,
            pageBreak: ``,
          },
          {
            bold: false,
            decoration: ``,
            width: 10,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            fontSize: `15`,
            pageBreak: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                bold: false,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                color: ``,
                width: `auto`,
                text: props.items?.patientInfo?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                fontSize: `15`,
                alignment: `left`,
              },
              {
                bold: false,
                fontSize: `15`,
                color: ``,
                width: `auto`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                text: `..................................................................................................................................................................`,
                alignment: `left`,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                decoration: ``,
                pageBreak: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            width: `auto`,
            fontSize: `15`,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            text: `สถานที่อยู่ (ที่สามารถติดต่อได้) `,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            color: ``,
            width: 10,
            text: ``,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            fontSize: `15`,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                decorationColor: ``,
                text: `${props.items?.checkAddressNo} ${props.items?.checkAddressName} ${props.items?.checkAddressTown} ${props.items?.checkAddressRoad} ${props.items?.checkAddressCity} ${props.items?.checkAddressDistrict} ${props.items?.checkAddressProvince}`,
                alignment: `left`,
                width: `auto`,
                fontSize: `15`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                bold: false,
                color: ``,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                decoration: ``,
              },
              {
                decorationStyle: ``,
                width: `auto`,
                decoration: ``,
                pageBreak: ``,
                fontSize: `15`,
                preserveLeadingSpaces: true,
                bold: false,
                color: ``,
                text: `...........................................................................................................................................................`,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                alignment: `left`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `หมายเลขบัตรประจำตัวประชาชน `,
            alignment: `left`,
            width: `auto`,
            fontSize: `15`,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: `15`,
            decorationStyle: ``,
            text: ``,
            width: 10,
            decoration: ``,
            pageBreak: ``,
            color: ``,
          },
          {
            stack: [
              {
                color: ``,
                alignment: `left`,
                decoration: ``,
                decorationColor: ``,
                text: !props.items?.formatIdentification
                  ? props.items?.patientInfo?.citizen_passport === "" ||
                    props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                    ? " "
                    : props.items?.patientInfo?.citizen_passport
                  : props.items?.formatIdentification,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                width: `auto`,
                bold: false,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                fontSize: `15`,
              },
              {
                alignment: `left`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                decoration: ``,
                text: `.......................................................................................................................................................`,
                fontSize: `15`,
                bold: false,
              },
            ],
          },
        ],
      },
      {
        fontSize: `15`,
        width: `auto`,
        pageBreak: ``,
        decoration: ``,
        text: `ข้าพเจ้าขอใบรับรองสุขภาพ โดยมีประวัติสุขภาพดังนี้`,
        bold: false,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            text: `1. โรคประจำตัว `,
            width: `auto`,
            fontSize: `15`,
            bold: false,
            decoration: ``,
          },
          {
            text: `						`,
            bold: false,
            color: ``,
            decoration: ``,
            fontSize: `15`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 97,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            image: props.items?.isFirstOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            fontSize: `15`,
            color: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ` ไม่มี `,
            width: `auto`,
          },
          {
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            decorationColor: ``,
            fontSize: `15`,
            color: ``,
            width: 10,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
          },
          {
            image: props.items?.isFirstOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            text: ` มี (ระบุ) `,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            fontSize: `15`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            color: ``,
            width: 10,
            bold: false,
            decoration: ``,
            fontSize: `15`,
          },
          {
            stack: [
              {
                bold: false,
                fontSize: `15`,
                decorationColor: ``,
                text:
                  props.items?.isFirstOption === "yes"
                    ? props.items?.explainFirst &&
                      props.items?.explainFirst !== null &&
                      props.items?.explainFirst !== ""
                      ? props.items?.explainFirst
                      : " "
                    : " ",
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                color: ``,
                decorationStyle: ``,
                alignment: `left`,
                decoration: ``,
                preserveLeadingSpaces: true,
                width: `auto`,
              },
              {
                decorationColor: ``,
                decoration: ``,
                fontSize: `15`,
                bold: false,
                color: ``,
                preserveLeadingSpaces: true,
                text: `........................................................................................................`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                alignment: `left`,
                decorationStyle: ``,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: `15`,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            text: `2. อุบัติเหตุ และ ผ่าตัด `,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            width: 70.5,
            color: ``,
            text: `					`,
            fontSize: `15`,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            image: props.items?.isSecondOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationColor: ``,
            text: ` ไม่มี `,
            alignment: `left`,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: `15`,
            bold: false,
            pageBreak: ``,
            width: `auto`,
          },
          {
            fontSize: `15`,
            width: 10,
            text: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            image: props.items?.isSecondOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
            fontSize: `15`,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            text: ` มี (ระบุ) `,
          },
          {
            decoration: ``,
            bold: false,
            alignment: `left`,
            width: 10,
            fontSize: `15`,
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                color: ``,
                decoration: ``,
                text:
                  props.items?.isSecondOption === "yes"
                    ? props.items?.explainSecond &&
                      props.items?.explainSecond !== null &&
                      props.items?.explainSecond !== ""
                      ? props.items?.explainSecond
                      : " "
                    : " ",
                preserveLeadingSpaces: true,
                width: `auto`,
                decorationColor: ``,
                pageBreak: ``,
                fontSize: `15`,
                alignment: `left`,
                bold: false,
              },
              {
                text: `........................................................................................................`,
                pageBreak: ``,
                bold: false,
                color: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                width: `auto`,
                decoration: ``,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                fontSize: `15`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationColor: ``,
            fontSize: `15`,
            alignment: `left`,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            text: `3. เคยเข้ารับการรักษาในโรงพยาบาล`,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            width: 17,
            bold: false,
            fontSize: `15`,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
          },
          {
            image: props.items?.isThirdOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: `15`,
            width: `auto`,
            bold: false,
            color: ``,
            text: ` ไม่มี`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            width: 10,
            text: ``,
            decorationColor: ``,
            fontSize: `15`,
            pageBreak: ``,
            color: ``,
          },
          {
            image: props.items?.isThirdOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: `15`,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            text: ` มี (ระบุ)`,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            fontSize: `15`,
            alignment: `left`,
            text: ``,
            width: 10,
            bold: false,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                decoration: ``,
                width: `auto`,
                decorationStyle: ``,
                bold: false,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
                color: ``,
                text:
                  props.items?.isThirdOption === "yes"
                    ? props.items?.explainThird &&
                      props.items?.explainThird !== null &&
                      props.items?.explainThird !== ""
                      ? props.items?.explainThird
                      : " "
                    : " ",
                fontSize: `15`,
              },
              {
                width: `auto`,
                decorationStyle: ``,
                alignment: `left`,
                color: ``,
                margin: [0, 0, 0, 0],
                decoration: ``,
                text: `........................................................................................................`,
                preserveLeadingSpaces: true,
                fontSize: `15`,
                decorationColor: ``,
                bold: false,
                pageBreak: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            bold: false,
            fontSize: `15`,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            text: `4. โรคลมชัก * `,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `						`,
            decoration: ``,
            decorationStyle: ``,
            width: 105,
            fontSize: `15`,
            alignment: `left`,
          },
          {
            image: props.items?.isFourthOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: `15`,
            decoration: ``,
            decorationStyle: ``,
            text: ` ไม่มี `,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
          },
          {
            text: ``,
            fontSize: `15`,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 10,
            decoration: ``,
            bold: false,
            color: ``,
          },
          {
            image: props.items?.isFourthOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            fontSize: `15`,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            text: ` มี (ระบุ) `,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: `15`,
            alignment: `left`,
            width: 10,
            bold: false,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                decoration: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                alignment: `left`,
                fontSize: `15`,
                width: `auto`,
                bold: false,
                decorationColor: ``,
                color: ``,
                text:
                  props.items?.isFourthOption === "yes"
                    ? props.items?.explainFourth &&
                      props.items?.explainFourth !== null &&
                      props.items?.explainFourth !== ""
                      ? props.items?.explainFourth
                      : " "
                    : " ",
              },
              {
                margin: [0, 0, 0, 0],
                color: ``,
                decoration: ``,
                fontSize: `15`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                text: `........................................................................................................`,
                bold: false,
                decorationColor: ``,
                alignment: `left`,
                decorationStyle: ``,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            fontSize: `15`,
            decorationColor: ``,
            decoration: ``,
            text: `5. ประวัติอื่นที่สำคัญ `,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            text: `					`,
            width: 79,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: `15`,
            color: ``,
          },
          {
            image: props.items?.isFifthOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            pageBreak: ``,
            color: ``,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            text: ` ไม่มี `,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            fontSize: `15`,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            text: ``,
            fontSize: `15`,
            bold: false,
            decoration: ``,
            width: 10,
            preserveLeadingSpaces: true,
          },
          {
            image: props.items?.isFifthOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            color: ``,
            decorationColor: ``,
            text: ` มี (ระบุ) `,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: `15`,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
            bold: false,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            fontSize: `15`,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ``,
            width: 10,
            alignment: `left`,
            color: ``,
          },
          {
            stack: [
              {
                fontSize: `14`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                width: `auto`,
                alignment: `left`,
                color: ``,
                text:
                  props.items?.isFifthOption === "yes"
                    ? props.items?.explainFifth &&
                      props.items?.explainFifth !== null &&
                      props.items?.explainFifth !== ""
                      ? props.items?.explainFifth
                      : " "
                    : " ",
                pageBreak: ``,
                bold: false,
                margin: [0, 0, 0, -15],
                decoration: ``,
                decorationColor: ``,
              },
              {
                bold: false,
                margin: [0, 0, 0, 0],
                decoration: ``,
                preserveLeadingSpaces: true,
                color: ``,
                pageBreak: ``,
                decorationColor: ``,
                alignment: `left`,
                fontSize: `15`,
                text: `........................................................................................................`,
                decorationStyle: ``,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: `red`,
            width: `auto`,
            text: `*ในกรณีโรคลมชัก ให้แนบประวัติการรักษาจากแพทย์ผู้รักษาว่าท่านปลอดจากอาการชักมากกว่า ๑ ปี เพื่ออนุญาตให้ขับรถได้`,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            fontSize: `13`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            italics: true,
            pageBreak: ``,
          },
        ],
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        color: ``,
        decorationStyle: ``,
        decorationColor: ``,
        bold: false,
        fontSize: 15,
        decoration: ``,
        alignment: `left`,
        pageBreak: ``,
        text: ` `,
      },
      {
        columns: [
          {
            width: `auto`,
            bold: `true`,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            text: `ส่วนที่ 2 `,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            text: ``,
            alignment: `left`,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 10,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            width: `auto`,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            text: `ของแพทย์`,
            preserveLeadingSpaces: true,
            bold: true,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            text: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 18,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            text: `สถานที่ตรวจ`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            width: 10,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
          },
          {
            stack: [
              {
                decorationColor: ``,
                text: props.items?.hospitalName,
                color: ``,
                margin: [0, 0, 0, -15],
                decoration: ``,
                width: `auto`,
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                bold: false,
              },
              {
                decoration: ``,
                preserveLeadingSpaces: true,
                color: ``,
                alignment: `left`,
                width: `auto`,
                margin: [0, 0, 0, 0],
                fontSize: 15,
                decorationColor: ``,
                decorationStyle: ``,
                text: `...........................................................`,
                pageBreak: ``,
                bold: false,
              },
            ],
          },
          {
            decorationColor: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            text: `วันที่`,
            decorationStyle: ``,
            margin: [10, 0, 0, 0],
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            text: ``,
            pageBreak: ``,
            decoration: ``,
            width: 10,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
          },
          {
            stack: [
              {
                bold: false,
                decoration: ``,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                width: 20,
                alignment: `left`,
                margin: [12, 0, 0, -15],
                color: ``,
                pageBreak: ``,
                decorationStyle: ``,
                fontSize: 15,
                text: props.items?.examDay,
              },
              {
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                text: `..................`,
                pageBreak: ``,
                decorationStyle: ``,
                bold: false,
                alignment: `left`,
                decoration: ``,
                fontSize: 15,
                decorationColor: ``,
                width: `auto`,
                color: ``,
              },
            ],
          },
          {
            preserveLeadingSpaces: true,
            text: `เดือน`,
            decoration: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [-90, 0, 0, 0],
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 10,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                decoration: ``,
                margin: [14, 0, 0, -15],
                pageBreak: ``,
                fontSize: 15,
                decorationStyle: ``,
                alignment: `left`,
                bold: false,
                color: ``,
                text: props.items?.examMonth,
                width: `auto`,
                decorationColor: ``,
                preserveLeadingSpaces: true,
              },
              {
                alignment: `left`,
                decorationStyle: ``,
                color: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decoration: ``,
                width: `auto`,
                pageBreak: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                decorationColor: ``,
                text: `...........................`,
              },
            ],
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            margin: [-70, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            text: `พ.ศ.`,
            decoration: ``,
          },
          {
            width: 10,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            text: ``,
            color: ``,
          },
          {
            stack: [
              {
                text: props.items?.examYear,
                decorationStyle: ``,
                decoration: ``,
                bold: false,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [14, 0, 0, -15],
                fontSize: 15,
                width: `auto`,
                decorationColor: ``,
                color: ``,
              },
              {
                preserveLeadingSpaces: true,
                bold: false,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                alignment: `left`,
                fontSize: 15,
                pageBreak: ``,
                decorationColor: ``,
                decoration: ``,
                width: `auto`,
                color: ``,
                text: `......................`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            text: `(1) ข้าพเจ้า นายแพทย์/แพทย์หญิง `,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            width: 5,
            decoration: ``,
            text: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                fontSize: 15,
                decoration: ``,
                decorationColor: ``,
                width: `auto`,
                pageBreak: ``,
                bold: false,
                margin: [0, 0, 0, -15],
                color: ``,
                alignment: `left`,
                text: props.items?.doctorName,
                preserveLeadingSpaces: true,
              },
              {
                text: `......................................................................................................................................................`,
                decoration: ``,
                decorationColor: ``,
                bold: false,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                width: `auto`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                alignment: `left`,
                fontSize: 15,
                color: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            text: `      ใบอนุญาตประกอบวิชาชีพเวชกรรมเลขที`,
            bold: false,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            width: 10,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                fontSize: 15,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                bold: false,
                decorationStyle: ``,
                color: ``,
                text: props.items?.doctorCertNumber ? props.items?.doctorCertNumber : " ",
                decorationColor: ``,
                width: `auto`,
                alignment: `left`,
                preserveLeadingSpaces: true,
                decoration: ``,
              },
              {
                decorationColor: ``,
                color: ``,
                decoration: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                bold: false,
                decorationStyle: ``,
                text: `....................................`,
                margin: [0, 0, 0, 0],
                fontSize: 15,
                alignment: `left`,
                width: `auto`,
              },
            ],
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            width: `auto`,
            text: `สถานพยาบาลชื่อ`,
            margin: [-50, 0, 0, 0],
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            width: 10,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
          },
          {
            stack: [
              {
                color: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                decorationColor: ``,
                decorationStyle: ``,
                decoration: ``,
                text: props.items?.hospitalName,
                bold: false,
                fontSize: 15,
                width: `auto`,
              },
              {
                decorationStyle: ``,
                decorationColor: ``,
                alignment: `left`,
                text: `...........................................................`,
                color: ``,
                preserveLeadingSpaces: true,
                width: `auto`,
                fontSize: 15,
                decoration: ``,
                bold: false,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: `      ที่อยู่ `,
            decorationColor: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            width: 10,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                bold: false,
                width: `auto`,
                color: ``,
                decorationStyle: ``,
                alignment: `left`,
                text: props.items?.hospitalAddress,
                decoration: ``,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                decorationColor: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
              },
              {
                alignment: `left`,
                pageBreak: ``,
                decorationStyle: ``,
                text: `.............................................................................................................................................................................................`,
                preserveLeadingSpaces: true,
                bold: false,
                decorationColor: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                decoration: ``,
                width: `auto`,
                fontSize: 15,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            text: `      ได้ตรวจร่างกาย นาย/นาง/นางสาว`,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            width: `auto`,
          },
          {
            decoration: ``,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 10,
            fontSize: 15,
          },
          {
            stack: [
              {
                text: props.items?.patientInfo?.full_name,
                alignment: `left`,
                pageBreak: ``,
                color: ``,
                decorationColor: ``,
                width: `auto`,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                fontSize: 15,
                decoration: ``,
                preserveLeadingSpaces: true,
                bold: false,
              },
              {
                decoration: ``,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                bold: false,
                color: ``,
                text: `.............................................................................................................................................`,
                fontSize: 15,
                width: `auto`,
                preserveLeadingSpaces: true,
                alignment: `left`,
                decorationColor: ``,
                pageBreak: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `      แล้วเมื่อวันที่ `,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            bold: false,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            width: 10,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                text: props.items?.examDay,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `center`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
              {
                text: `....................`,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `center`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
            ],
            width: `auto`,
          },
          {
            bold: false,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            width: 10,
          },
          {
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `เดือน`,
          },
          {
            decoration: ``,
            color: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 10,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
          },
          {
            stack: [
              {
                text: props.items?.examMonth,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `center`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
              {
                text: `................................`,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
            ],
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            width: 10,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            text: `พ.ศ.`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
          },
          {
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            width: 10,
            color: ``,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
          },
          {
            stack: [
              {
                text: props.items?.examYear,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `center`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
              {
                text: `................................`,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
            ],
            width: `auto`,
          },
          {
            color: ``,
            bold: false,
            width: 10,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            text: ``,
          },
          {
            text: `มีรายละเอียดดังนี้`,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            alignment: `left`,
            text: `      น้ำหนักตัว `,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            text: ``,
            width: 10,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
          },
          {
            stack: [
              {
                color: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                margin: [0, 0, 0, -15],
                decoration: ``,
                bold: false,
                width: `auto`,
                alignment: `center`,
                text: props.items?.patientWeight ? props.items?.patientWeight : "-",
                decorationColor: ``,
                decorationStyle: ``,
                pageBreak: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                width: `auto`,
                bold: false,
                decoration: ``,
                text: `.................`,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                decorationStyle: ``,
                color: ``,
                decorationColor: ``,
              },
            ],
            width: `auto`,
          },
          {
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            alignment: `left`,
            text: ` กก. ความสูง`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            color: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 10,
            decorationColor: ``,
          },
          {
            stack: [
              {
                fontSize: 15,
                decorationColor: ``,
                pageBreak: ``,
                color: ``,
                bold: false,
                alignment: `center`,
                decorationStyle: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                decoration: ``,
                text: props.items?.patientHeight ? props.items?.patientHeight : "-",
              },
              {
                decoration: ``,
                fontSize: 15,
                width: `auto`,
                decorationColor: ``,
                bold: false,
                alignment: `left`,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                text: `.............`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                color: ``,
              },
            ],
            width: `auto`,
          },
          {
            bold: false,
            fontSize: 15,
            text: ` เซนติเมตร ความดันโลหิต`,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
          },
          {
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            bold: false,
            width: 10,
          },
          {
            stack: [
              {
                alignment: `center`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                color: ``,
                decoration: ``,
                bold: false,
                decorationColor: ``,
                text: props.items?.patientPressure ? props.items?.patientPressure : "-",
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                width: `auto`,
              },
              {
                margin: [0, 0, 0, 0],
                decoration: ``,
                pageBreak: ``,
                decorationStyle: ``,
                bold: false,
                text: `............`,
                fontSize: 15,
                alignment: `left`,
                color: ``,
                preserveLeadingSpaces: true,
                width: `auto`,
                decorationColor: ``,
              },
            ],
            width: `auto`,
          },
          {
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ` มม. ปรอท ชีพจร`,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            decoration: ``,
          },
          {
            bold: false,
            decoration: ``,
            width: 10,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                fontSize: 15,
                decorationColor: ``,
                color: ``,
                pageBreak: ``,
                decorationStyle: ``,
                width: `auto`,
                decoration: ``,
                margin: [0, 0, 0, -15],
                alignment: `center`,
                text: props.items?.patientPulse ? props.items?.patientPulse : "-",
                bold: false,
              },
              {
                pageBreak: ``,
                bold: false,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decoration: ``,
                decorationStyle: ``,
                text: `.............`,
                margin: [0, 0, 0, 0],
                alignment: `left`,
              },
            ],
            width: `auto`,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            text: ` ครั้ง/นาที`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            text: `      สภาพร่างกายทั่วไปอยู่ในเกณฑ์ `,
            bold: false,
          },
          {
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 10,
            bold: false,
            pageBreak: ``,
            text: ``,
          },
          {
            image: props.items?.summaryCheck === "normal" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            text: ` ปกติ `,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            bold: false,
          },
          {
            width: 10,
            alignment: `left`,
            bold: false,
            color: ``,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            image: props.items?.summaryCheck === "abnormal" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            decoration: ``,
            color: ``,
            text: ` ผิดปกติ (ระบุ)`,
            pageBreak: ``,
          },
          {
            bold: false,
            width: 5,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ``,
            color: ``,
          },
          {
            stack: [
              {
                bold: false,
                decorationStyle: ``,
                pageBreak: ``,
                decoration: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                alignment: `left`,
                width: `auto`,
                margin: [0, 0, 0, -15],
                color: ``,
                text:
                  props.items?.summaryCheck === "abnormal"
                    ? props.items?.explainSummary &&
                      props.items?.explainSummary !== null &&
                      props.items?.explainSummary !== ""
                      ? props.items?.explainSummary
                      : " "
                    : " ",
              },
              {
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                preserveLeadingSpaces: true,
                color: ``,
                decorationStyle: ``,
                text: `....................................................................................................`,
                width: `auto`,
                fontSize: 15,
                margin: [0, 0, 0, 0],
                bold: false,
                decorationColor: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            color: ``,
            alignment: `left`,
            width: 40,
            pageBreak: ``,
            text: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            text: ` 	ขอรับรองว่า บุคคลดังกล่าว ไม่เป็นผู้มีร่างกายทุพพลภาพจนไม่สามารถปฏิบัติหน้าที่ได้ ไม่ปรากฏอาการของโรคจิต`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            color: ``,
            pageBreak: `before`,
            alignment: `left`,
          },
        ],
      },
      {
        pageBreak: ``,
        color: ``,
        fontSize: 15,
        alignment: `left`,
        text: `      หรือจิตฟั่นเฟือน หรือปัญญาอ่อน ไม่ปรากฏอาการของการติดยาเสพติดให้โทษ และอาการของโรคพิษสุราเรื้อรัง และไม่`,
        decorationStyle: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        decoration: ``,
        bold: false,
        decorationColor: ``,
      },
      {
        alignment: `left`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        bold: false,
        fontSize: 15,
        color: ``,
        decoration: ``,
        decorationColor: ``,
        decorationStyle: ``,
        text: `      ปรากฏอาการและอาการแสดงของโรคต่อไปนี้`,
      },
      {
        columns: [
          {
            decoration: ``,
            width: 30,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: ` 1. โรคเรื้อนในระยะติดต่อ หรือในระยะที่ปรากฏอาการเป็นที่รังเกียจแก่สังคม`,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            width: 30,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            text: ` 2. วัณโรคในระยะอันตราย`,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            width: 30,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            text: ` 3. โรคเท้าช้างในระยะที่ปรากฏอาการเป็นที่รังเกียจแก่สังคม`,
            width: `auto`,
            bold: false,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            width: 30,
            decoration: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            bold: false,
            text: ` 4. อื่นๆ (ถ้ามี)  ..........................................................................................................................................................................`,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            text: `(2) สรุปความเห็นและข้อแนะนำของแพทย์ `,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            color: ``,
            bold: false,
            text: ``,
            decoration: ``,
            fontSize: 15,
            width: 10,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                decoration: ``,
                decorationColor: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                bold: false,
                width: `auto`,
                text: props.items?.comment ? props.items?.comment : " ",
                margin: [0, 0, 0, -15],
                fontSize: 15,
                alignment: `left`,
                color: ``,
              },
              {
                decorationColor: ``,
                text: `.........................................................................................................................................`,
                width: `auto`,
                color: ``,
                pageBreak: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                bold: false,
                fontSize: 15,
                decoration: ``,
                decorationStyle: ``,
              },
            ],
          },
        ],
      },
      {
        alignment: `left`,
        text: ` `,
        decorationColor: ``,
        fontSize: 15,
        decorationStyle: ``,
        pageBreak: ``,
        width: `auto`,
        decoration: ``,
        preserveLeadingSpaces: true,
        bold: false,
        color: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            width: 258,
            fontSize: 15,
            text: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            text: `ลงชื่อ  `,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            width: 150,
            bold: false,
            fontSize: 15,
            color: ``,
            alignment: `center`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `....................................................`,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            text: `แพทย์ผู้ตรวจร่างกาย`,
            decorationStyle: ``,
            color: ``,
            bold: false,
          },
        ],
      },
    ],
    pageSize: `A4`,
    pageOrientation: `portrait`,
    header: props.header,
    footer: props.footer,
    images: {
      checked: FormCheckboxImages.checked,
      unchecked: FormCheckboxImages.unchecked,
    },
  };
}
