import React, { useState, SyntheticEvent, useEffect } from "react";
import {
  Button,
  Icon,
  SemanticSIZES,
  Modal,
  Segment,
  Form,
  TextArea,
} from "semantic-ui-react";

type EditNoteColumnProps = {
  value: string;
  onDataChanged?: (note: string) => void;
  size?: SemanticSIZES;
};

const EditNoteColumn: React.FC<EditNoteColumnProps> =
  React.memo<EditNoteColumnProps>((props) => {
    const [note, setNote] = useState<any>("");
    const [openModEdit, setOpenModEdit] = useState<boolean>(false);

    useEffect(() => {
      // console.log(" props.value", props.value)
      setNote(props.value);
    }, [props.value]);

    const handleOpenNote = (e: SyntheticEvent) => {
      handlePreventDefault(e);

      setOpenModEdit(true);
      setNote(props.value);
    };

    const handleClose = (e: SyntheticEvent) => {
      setOpenModEdit(false);
      setNote("");
    };

    const handlePreventDefault = (e?: SyntheticEvent) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const handleEdit = (e: SyntheticEvent) => {
      handlePreventDefault(e);

      props.onDataChanged?.(note);
      handleClose(e);
    };

    return (
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <Button
            color="red"
            icon="minus"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenModConfirm(true);
            }}
            size={props.size}
          ></Button> */}
          <Button
            icon
            color={props.value ? "orange" : "grey"}
            size={props.size}
            onClick={handleOpenNote}
          >
            <Icon name="comment alternate" />
          </Button>
        </div>
        <Modal
          size="mini"
          open={openModEdit}
          onClose={handleClose}
          closeOnDimmerClick
        >
          <Segment>
            <Form>
              <Form.Group inline>
                <Form.Field inline width={16}>
                  <TextArea
                    value={note}
                    rows={5}
                    onChange={(e, v) => setNote(v.value)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline style={{ marginBottom: 0 }}>
                <Form.Field inline width={8}>
                  <Button fluid color="orange" onClick={handleEdit}>
                    Edit
                  </Button>
                </Form.Field>
                <Form.Field inline width={8} style={{ marginRight: 0 }}>
                  <Button fluid color="grey" onClick={handleClose}>
                    Cancel
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        </Modal>
      </>
    );
  });

EditNoteColumn.defaultProps = {
  size: "mini",
} as EditNoteColumnProps;

export default EditNoteColumn;
