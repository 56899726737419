import React, { useState, useEffect, useMemo } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import moment from "moment";
import { parseDate, formatADtoBEString } from "react-lib/utils/dateUtils";
import { DATE_FORMAT, timeSlotList } from "react-lib/apps/Scheduling/common/Utils";
import CardSummaryDoctorUX from "./CardSummaryDoctorUX";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { CSVLink, CSVDownload } from "react-csv";




const dateDefault = moment(new Date())?.format(DATE_FORMAT);

const startExportDateDefault = formatADtoBEString(moment(dateDefault).format(DATE_FORMAT));
const endExportDateDefault = formatADtoBEString(moment(dateDefault).format(DATE_FORMAT));

const CardSummaryDoctor = (props: any) => {
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [startDate, setStartDate] = useState(dateDefault);
  const [endDate, setEndDate] = useState(dateDefault);
  const [startExportDate, setStartExportDate] = useState(startExportDateDefault);
  const [endExportDate, setEndExportDate] = useState(endExportDateDefault);

  useEffect(() => {
    props.onEvent({ message: "GetSummaryDoctor", params: {} });
  }, []);

  const dataSummaryDoctor = useMemo(() => {
    return (props.summaryDoctorList || []).map((item: any, index: number) => ({
      ...item,
      name: `${item.doctor__first_name} ${item.doctor__last_name}`,
      encounter: item.count,
      date: `${startExportDate} ถึง ${endExportDate}`,
    }));
  }, [props.summaryDoctorList]);









  const mapProviderOptions = (items: any) => {
    return items.map((item: any) => ({
      key: item.provider_id,
      value: item.first_name + " " + item.last_name + " [" + item.code + "]",
      text: item.first_name + " " + item.last_name + " [" + item.code + "]",
    }));
  };

  const changeDate = (key: string) => (value: any) => {
    const newDateString = (parseDate(value, true) as moment.Moment).format(DATE_FORMAT);
    if (key === "startDate") {
      setStartDate(newDateString);
      setStartExportDate(value);
    } else if (key === "endDate") {
      setEndDate(newDateString);
      setEndExportDate(value);
    }
  };

  //Export .CSV
  const dataExport = useMemo(() => {
    const title = [`รายงานสรุปผลการปฏิบัติงานทันตแพทย์ ตั้งแต่วันที่ ${dataSummaryDoctor?.[0]?.date}`,"",""];
    const header = ["ชื่อแพทย์ / ทันตแพทย์","จำนวน encounter"]
    const data = dataSummaryDoctor.map((item: any)=>[item.name, item.encounter])

    return [title, header, ...data]
  }, [dataSummaryDoctor])



  return (
    <>
      <CardSummaryDoctorUX
        startDate={formatADtoBEString(moment(startDate).format(DATE_FORMAT))}
        changeDate={changeDate}
        endDate={formatADtoBEString(moment(endDate).format(DATE_FORMAT))}
        dataSummaryDoctor={dataSummaryDoctor}
        searchDoctorBox={
          <SearchBoxDropdown
            type="Provider"
            id="1"
            style={{ width: "100%" }}
            fluid={true}
            label=""
            toDisplay={(item: any) => `${item.first_name} ${item.last_name} [${item.code}]`}
            onEvent={props.onEvent}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={selectedDoctor}
            setSelectedItem={(value: any, key: any, obj: any) => {
              if (value === null && key === null) {
                setSelectedDoctor(null);
                return;
              }
              let doctor = props.searchedItemListWithKey?.Provider_1?.find((item: any) => item.provider_id === key);
              setSelectedDoctor(doctor);
            }}
            useWithKey={true}
            mapOptions={mapProviderOptions}
          />
        }
        OnSearch={() => {
          props.onEvent({
            message: "GetSummaryDoctor",
            params: {
              action: "search",
              data: {
                doctor: selectedDoctor,
                startDate: startDate,
                endDate: endDate,
              },
            },
          });
        }}
        buttonExportCSV={
          <CSVLink 

          data={dataExport}  filename={"รายงานสรุปผลการปฏิบัติงานทันตแพทย์.csv"} target="_blank">
            <Button color="orange">
              <div style={{ display: "flex" }}>
                <Icon name="download" />
                <div style={{ marginRight: "3px" }}> Export .CSV </div>
              </div>
            </Button>
          </CSVLink>
        }
      />

    </>
  );
};

export default CardSummaryDoctor;
