import React, { useState, useMemo, useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { formatComma } from "react-lib/utils/tsUtils";

// UX
import CardPayingUX from "./CardPayingUX";
import SubRepeaterPayMethodUX from "./SubRepeaterPayMethodUX";

// Common
import { ModConfirm, ModInfo } from "react-lib/apps/common";

// ---
import { CARD_MEDICAL_FEE } from "./CardMedicalFee";

// Utils

type CardPayingProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  BillPaymentSequence?: Record<string, any> | null;
  // data
  patientInfo?: Record<string, any>;
  citizenDetail: {
    text: string;
    patientId: string;
  };
  defaultAmount?: Partial<{
    price: number;
    reimbursable: number;
    cashonly: number;
    discount: number;
    payable: number;
  }> | null;
  patientOptionData: {
    options: {
      key: string;
      value: string;
      text: any;
    }[];
    value: string;
  };
  invoiceData: {
    items: any[];
    ids: any[];
    orderBy?: string;
  };
  invoiceItemByItems?: any[];
  // CommonInterface
  errorMessage?: Record<string, any>;
  // BillingHistory
  invoiceId?: number;
  showFilter?: boolean;
  // #historyInvoiceData?: any;
  // config
  openPaying?: boolean;
  simpleReceipt?: boolean;
  // callback
  onSuccess?: () => any;
  isDeposit?: boolean;
  walletType?: any;
  masterOptions?: Record<string, any[]>;
};

type ModType = {
  message: any;
  status: "success" | "confirm" | "error_payment";
};

const DOCTOR_CODE_REGEX = /\s*\([^)]+\)$/;

const CardPaying = (props: CardPayingProps) => {
  // Mod
  const [openModInfo, setOpenModInfo] = useState<ModType | null>(null);
  // Loading
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //
  const [sumAmount, setSumAmount] = useState();
  const [defaultAmount, setDefaultAmount] = useState();
  const [changeText, setChangeText] = useState("0.00");

  const formatPaymentText = (data: any) => {
    return formatComma(Number(data).toFixed(2).replaceAll(",", ""));
  };

  // Use Effect
  useEffect(() => {
    if (props.BillPaymentSequence?.sumAmount) {
      setIsLoading(false);

      let sumAmountData: any;

      (Object.keys(props.BillPaymentSequence?.sumAmount) || []).map((item: any) => {
        sumAmountData = {
          ...sumAmountData,
          [item]: formatPaymentText(props.BillPaymentSequence?.sumAmount?.[item]),
        };
      });

      setSumAmount(sumAmountData);
    } else {
      setIsLoading(true);
    }
  }, [props.BillPaymentSequence?.sumAmount]);

  useEffect(() => {
    if (props.defaultAmount) {
      setIsLoading(false);

      let defaultAmountData: any;

      (Object.keys(props.defaultAmount) || []).map((item: any) => {
        defaultAmountData = {
          ...defaultAmountData,
          [item]: formatPaymentText(props.defaultAmount?.[item]),
        };
      });

      setDefaultAmount(defaultAmountData);
    }
  }, [props.defaultAmount]);

  useEffect(() => {
    let rawOweText = props.BillPaymentSequence?.rawOweText || "";
    let rawTotalText = props.BillPaymentSequence?.rawTotalText || "";

    setChangeText(
      Number(rawOweText) > Number(rawTotalText)
        ? "0.00"
        : formatPaymentText(Number(rawTotalText) - Number(rawOweText) || 0)
    );
  }, [props.BillPaymentSequence?.rawTotalText]);

  useEffect(() => {
    if (!!props.errorMessage?.[CARD_MEDICAL_FEE] && props.openPaying) {
      setIsLoading(false);
    }
  }, [props.openPaying, props.errorMessage]);

  //  Use Memo
  const masterAccountOptions = useMemo(() => {
    return (props.BillPaymentSequence?.masterAccountList || []).map((item: any) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
  }, [props.BillPaymentSequence?.masterAccountList]);

  const payments = useMemo(() => {
    return (props.BillPaymentSequence?.paymentMethodList || []).filter(
      (item: any) => item.type !== "1" && item.type !== "6"
    );
  }, [props.BillPaymentSequence?.paymentMethodList]);

  const masterEDCOptions = useMemo(() => {
    return (props.BillPaymentSequence?.masterEDCList || []).map((item: any) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
  }, [props.BillPaymentSequence?.masterEDCList]);

  const depositTotal = useMemo(() => {
    const total = props.BillPaymentSequence?.depositTotal || "0";

    const totalString: string = (total || "0").replaceAll(",", "");
    const cashDeposit = props.BillPaymentSequence?.cashDepositText || "0";

    const result = (parseFloat(totalString) - parseFloat(cashDeposit)).toFixed(2);

    return result
      ? Number(result).toLocaleString("en-US", {
          style: "decimal",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : "";
  }, [props.BillPaymentSequence?.depositTotal, props.BillPaymentSequence?.cashDepositText]);

  const doctorNameOptions = useMemo(() => {
    return (props.masterOptions?.doctor || []).map((item: any) => {
      const doctorName = (item.text || "").replace(DOCTOR_CODE_REGEX, "");

      return {
        key: item.key,
        value: item.value,
        text: doctorName,
      };
    });
  }, [props.masterOptions?.doctor]);

  const handlePay = (data: any) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillPayment",
        action: "pay",
        patientNameLang: props.patientOptionData?.value,
        callback: setOpenModInfo,
        loading: setIsLoading,
        invoice: props.invoiceId ? props.invoiceId : null,
        invoiceData: props.invoiceData,
        confirmData: data.confirmData,
        simpleReceipt: props.simpleReceipt,
        isDeposit: props.isDeposit,
        walletType: props.walletType,
        showFilter: props.showFilter,
        invoiceItemByItems: props.invoiceItemByItems,
        isPayment: true
      },
    });
  };

  const handleAddPaymentMethod = (type: number) => {
    props.onEvent({
      message: "HandleSetPaymentMethodList",
      params: {
        list: [
          ...(props.BillPaymentSequence?.paymentMethodList || []),
          { type, paymentNo: "", paymentTarget: 0, value: 0 },
        ],
      },
    });
  };

  const handleChangeInput = (e: any, v: any) => {
    props.onEvent({
      message: "HandleChangePaymentMethodList",
      params: { e, v },
    });
  };

  const handleKeyDownCouponNumber = (e: any, v: any) => {
    props.onEvent({
      message: "HandleKeyDownCouponNumber",
      params: { e, v, idList: props.invoiceData.ids },
    });
  };

  const handleRemovePaymentMethod = (index: number) => {
    const filter = props.BillPaymentSequence?.paymentMethodList.filter(
      (_: any, idx: number) => idx !== index
    );
    props.onEvent({
      message: "HandleSetPaymentMethodList",
      params: { list: filter },
    });
  };

  const handleCloseModSuccess = async () => {
    setIsLoading(false);
    setOpenModInfo(null);

    await props.setProp("BillPaymentSequence", null);

    props.onSuccess?.();
  };

  const handleChangeAutoEDC = (_e: any, data: any) => {
    let value = typeof data.checked === "boolean" ? data.checked : data.value;
    const detail = props.BillPaymentSequence?.autoEDC || {};

    if (data.name === "check" && value) {
      detail.traceId = "";
      detail.approvalCode = "";
    }

    detail[data.name] = value;

    props.setProp("BillPaymentSequence.autoEDC", { ...detail });
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(null);
  };

  const handleConfirm = () => {
    handlePay({ confirmData: openModInfo?.message });
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const handleEnterCashAmountText = (e: any) => {
    if (e.key === "Enter")
      props.onEvent({
        message: "RunSequence",
        params: { sequence: "BillPayment" },
      });
  };

  const handleEnterCashDepositText = async (e: any) => {
    if (e.key === "Enter") {
      handleBlurCashDepositText(e);
    }
  };

  const handleBlurCashAmountText = (e: any) => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "BillPayment", isUpdateCashAmount: true },
    });
  };

  const handleBlurCashDepositText = async (e: any) => {
    const total: string = (props.BillPaymentSequence?.depositTotal || "0").replaceAll(",", "");

    const sumTotal =
      Number(props.BillPaymentSequence?.cashAmountText || 0) +
      Number(props?.BillPaymentSequence?.transferText || 0) +
      Number(props.BillPaymentSequence?.cardText || 0) +
      Number(props?.BillPaymentSequence?.chequeText || 0) +
      Number(props.BillPaymentSequence?.couponText || 0) +
      Number(props.BillPaymentSequence?.installmentText || 0);

    let cashDeposit = props.BillPaymentSequence?.cashDepositText;
    let cashOwe = Number(props.BillPaymentSequence?.sumAmount?.payable) - Number(sumTotal);
    let maxCash = Number(total) > cashOwe ? cashOwe : Number(total);

    if (Number(cashDeposit) > Number(total)) {
      alert("ยอดเงินเกินยอดคงเหลือเงินมัดจำ");
      await props.setProp("BillPaymentSequence.cashDepositText", maxCash);
    } else if (Number(cashDeposit) > Number(props.BillPaymentSequence?.sumAmount?.payable)) {
      alert("ยอดเกินจำนวนค้างชำระ");
      await props.setProp("BillPaymentSequence.cashDepositText", maxCash);
    }

    props.onEvent({
      message: "RunSequence",
      params: { sequence: "BillPayment", isUpdateCashDeposit: true },
    });
  };

  const handleChangeDoctorName = (e: any, data: any) => {
    props.setProp("BillPaymentSequence.doctorName", data.value);
  };

  return (
    <>
      <CardPayingUX
        // data
        isDeposit={props.isDeposit}
        // #68165
        // isDeposit={true}
        patientNameAndHN={props.patientInfo?.name_code}
        citizenID={props.citizenDetail.text}
        sumAmount={sumAmount || defaultAmount || {}}
        cashDepositText={props.BillPaymentSequence?.cashDepositText || ""}
        cashAmountText={props.BillPaymentSequence?.cashAmountText || ""}
        transferText={formatPaymentText(props?.BillPaymentSequence?.transferText || "")}
        cardText={formatPaymentText(props.BillPaymentSequence?.cardText || "")}
        chequeText={formatPaymentText(props.BillPaymentSequence?.chequeText || "")}
        couponText={formatPaymentText(props.BillPaymentSequence?.couponText || "")}
        installmentText={formatPaymentText(props.BillPaymentSequence?.installmentText || "")}
        totalText={formatPaymentText(props.BillPaymentSequence?.totalText || "")}
        changeText={changeText}
        rawTotalText={props.BillPaymentSequence?.rawTotalText || ""}
        oweText={formatPaymentText(props.BillPaymentSequence?.oweText || "")}
        rawOweText={props.BillPaymentSequence?.rawOweText || ""}
        roundOffText={formatPaymentText(props.BillPaymentSequence?.roundOffText || "")}
        patientValue={props.patientOptionData?.value}
        // AutoEDC
        check={props.BillPaymentSequence?.autoEDC?.check}
        approvalType={props.BillPaymentSequence?.autoEDC?.approvalType}
        traceId={props.BillPaymentSequence?.autoEDC?.traceId}
        approvalCode={props.BillPaymentSequence?.autoEDC?.approvalCode}
        showAutoEDC={props.BillPaymentSequence?.requireApprovalCodeCheck?.require_approval_code}
        doctorName={props.BillPaymentSequence?.doctorName || ""}
        // options
        patientOptions={props.patientOptionData?.options}
        doctorNameOptions={doctorNameOptions}
        // callback
        onChangeCashAmountText={(e: any) => {
          if (isNaN(e.target.value) || parseFloat(e.target.value) < 0) {
            alert("กรุณากรอกตัวเลข");
          } else {
            props.setProp("BillPaymentSequence.cashAmountText", e.target.value);
          }
        }}
        depositTotal={depositTotal}
        onChangeCashDepositText={(e: any) => {
          if (isNaN(e.target.value) || parseFloat(e.target.value) < 0) {
            alert("กรุณากรอกตัวเลข");
          } else {
            props.setProp("BillPaymentSequence.cashDepositText", e.target.value);
          }
        }}
        onChangeDoctorName={handleChangeDoctorName}
        onChangeAutoEDC={handleChangeAutoEDC}
        onEnterCashAmountText={handleEnterCashAmountText}
        onEnterCashDepositText={handleEnterCashDepositText}
        onBlurCashAmountText={handleBlurCashAmountText}
        onBlurCashDepositText={handleBlurCashDepositText}
        cannotPay={props?.BillPaymentSequence?.cannotPay}
        onPay={handlePay}
        onAddPaymentMethod={handleAddPaymentMethod}
        onChangePatient={(e: any, v: any) => {
          props.setProp("billingPatientLanguage.value", v.value);
        }}
        // Element
        Loader={
          <Dimmer active={isLoading && openModInfo?.status !== "confirm"} inverted>
            <Loader />
          </Dimmer>
        }
        // ErrorMessage={
        // <ErrorMessage error={props.BillPaymentSequence?.payErrorMessage} />
        // }
        SubRepeaterPayMethod={
          <>
            {payments.map((item: any, index: number) => (
              <SubRepeaterPayMethodUX
                key={"sub-repeater-paymethod" + item.id}
                type={item.type}
                paymentNo={item.paymentNo}
                paymentTarget={item.paymentTarget}
                value={item.value}
                couponMessage={item.couponMessage}
                masterAccountOptions={masterAccountOptions}
                masterEDCOptions={masterEDCOptions}
                onChange={(e: any, v: any) => {
                  if (v.name === "value" && (isNaN(v.value) || parseFloat(v.value) < 0)) {
                    alert("กรุณากรอกตัวเลข");
                  } else {
                    handleChangeInput(e, { ...v, index: index + 2 });
                  }
                }}
                onKeyDownCouponNumber={(e: any, v: any) =>
                  handleKeyDownCouponNumber(e, { ...v, index: index + 2 })
                }
                onClose={() => handleRemovePaymentMethod(index + 2)}
                handleKeyDownNumber={handleKeyDownNumber}
              />
            ))}
          </>
        }
      />

      {openModInfo?.status === "success" && (
        <ModInfo
          open={true}
          titleColor={"green"}
          titleName={"บันทึกสำเร็จ"}
          alertText={
            <div style={{ padding: "5px 0" }}>
              {(openModInfo?.message || [])?.map((item: any) => {
                return (
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <li>{item}</li>
                  </div>
                );
              })}
            </div>
          }
          onApprove={handleCloseModSuccess}
        />
      )}

      <ModInfo
        open={openModInfo?.status === "error_payment"}
        titleColor={"red"}
        titleName={"แจ้งเตือน!! ไม่สามารถรับชำระเงินได้"}
        alertText={
          <div style={{ padding: "5px 0" }}>
            <p style={{ textAlign: "center", marginBottom: "0.5rem" }}>
              <strong>
                การยืนยันสิทธิไม่สำเร็จ
                <br />
                เนื่องจากไม่พบรหัส Approval Code
                <br />
                กรุณาตรวจสอบอีกครั้ง
                <br />
              </strong>
            </p>
            <b>
              <p style={{ color: "red" }}>
                Approval Code: -<br />
                ยอดชำระ: {props.BillPaymentSequence?.edcTransactionAmount?.total_amount} บาท
              </p>
            </b>
          </div>
        }
        onApprove={handleCloseModInfo}
      />

      <ModConfirm
        openModal={openModInfo?.status === "confirm"}
        titleName="ยืนยันการชำระเงิน!"
        titleColor="blue"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButtonColor="green"
        approveButtonText="ยืนยัน"
        content={
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "-0.75rem 0px -1.5rem",
            }}
          >
            <Dimmer active={isLoading} inverted>
              <Loader />
            </Dimmer>
            <p style={{ textAlign: "center", marginBottom: "0.5rem" }}>
              <strong>
                กรุณาตรวจสอบ Approval Code และยอดชำระอีกครั้งก่อนกดยืนยัน
                <br />
              </strong>
            </p>
            <b>
              <p style={{ color: "darkblue" }}>
                Approval Code: {openModInfo?.message?.approvalCode}
                <br />
                ยอดชำระ: {props.BillPaymentSequence?.edcTransactionAmount?.total_amount} บาท
              </p>
            </b>
          </div>
        }
        onApprove={handleConfirm}
        onDeny={handleCloseModInfo}
        onCloseWithDimmerClick={handleCloseModInfo}
      />
    </>
  );
};

CardPaying.displayName = "CardPaying";

export default React.memo(CardPaying);
