import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import {
  Header,
  Divider,
  Form,
  Dropdown,
  Button,
  Checkbox,
  Input,
  Icon,
  Dimmer,
  Loader,
  Radio,
  SemanticCOLORS
} from "semantic-ui-react"
import moment from "moment"
import Tab from "react-lib/frameworks/Tab"
import { useHistory } from "react-router-dom";

// QUE
import TimeDoctor from "react-lib/apps/QUE/TimeDoctor";
import TimeDoctorEdit from "react-lib/apps/QUE/TimeDoctorEdit";

// Controller
import DashboardController from "react-lib/apps/IsHealth/Dashboard/DashboardController";
import PRXManager from "react-lib/apis/manager/PRXManager"
import CoreManager from "react-lib/apis/manager/CoreManager"

//Common
import { TimeFreeTextBox24, ModConfirm, ErrorMessage, ModInfo, } from "react-lib/apps/common"

type ManageScheduleProps = {
  apiToken: string;
  division: number;
  show: string;
  controller: DashboardController & {
    prxManager: PRXManager;
    coreManager: CoreManager;
  };
  queueController: any;
  providerId: number;
  onClickDaySlot: () => any
  match: {
    params: {
      id: string;
      weekStartSerial: string
    }
  }
}

type AgencyTableProps = {
  division: number;
  apiToken: string;
  controller: DashboardController & {
    prxManager: PRXManager;
    coreManager: CoreManager;
  };
}

type ChangeInputParams = {
  key: string,
  value: string,
  by: "id" | "index",
  byValue: string | number
}

type DeleteOrderParams = {
  by: "id" | "index",
  byValue: string | number
}

type CreateTimeToFormProps = {
  index: number | string;
  item: {
    start_time: string;
    end_time: string;
    id?: number;
    weekday?: string
  }
  items: any[]
  selectedList: any
  handleChangeInput: (params: ChangeInputParams) => any
  maximumNumberPeople: any
  handleDeleteOrder: (params: DeleteOrderParams) => any
  handleAddTime: (weekday: string) => any
  by: "id" | "index"
  patientPerSlot?: number
}

type ModInfoType = {
  open: boolean,
  error?: any,
  title?: string,
  color?: SemanticCOLORS
}



const WEEK_DAY_THAI = {
  MONDAY: "จันทร์",
  TUESDAY: "อังคาร",
  WEDNESDAY: "พุธ",
  THURSDAY: "พฤหัสบดี",
  FRIDAY: "ศุกร์",
  SATURDAY: "เสาร์",
  SUNDAY: "อาทิตย์"
}

const modInfoInitial: ModInfoType = {
  open: false,
  error: null,
  title: "",
  color: undefined
}

const SERVICE_TYPE_OPTIONS = [
  {
    key: 1,
    text: "Vaccine Covid",
    value: "covid_vaccine"
  },
  {
    key: 2,
    text: "OPD Telemed",
    value: "opd_telemed"
  }
]

const CreateTimeToForm: React.FC<CreateTimeToFormProps> = (props) => {

  return <Form.Field
    inline
    style={{ marginBottom: "15px" }}
    key={'time' + props.index}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <label>เวลา : </label>
      <TimeFreeTextBox24
        style={{
          display: "flex",
          alignItems: "left",
          margin: "0 15px"
        }}
        className="time-custom-react-picker"
        value={props.item.start_time}
        onChange={(time: any) => props.handleChangeInput(
          {
            key: "start_time",
            value: time,
            by: props.by,
            byValue: props.by === "id" ? props.item.id : props.index
          })}
        autoFocus={false}
        checkTimeOverflow
      />
      <label>ถึง</label>
      <TimeFreeTextBox24
        style={{
          display: "flex",
          alignItems: "left",
          margin: "0 15px"
        }}
        className="time-custom-react-picker"
        value={props.item.end_time}
        onChange={(time: any) => props.handleChangeInput({
          key: "end_time",
          value: time,
          by: props.by,
          byValue: props.by === "id" ? props.item.id : props.index
        })}
        autoFocus={false}
        checkTimeOverflow
      />
      <label>จำนวนคนที่รับได้</label>
      <Input
        style={{ margin: "0 15px" }}
        value={props.maximumNumberPeople(
          props.item.start_time, props.item.end_time, props.by === "id" ? props.patientPerSlot : null)}
        readOnly
      />
      <Button color="red"
        onClick={() => props.handleDeleteOrder({
          by: props.by,
          byValue: props.by === "id" ? props.item.id : props.index
        })}
      >
        <Icon name="minus" />
        Delete
      </Button>
      {
        props.index === props.items?.length - 1 &&
        <Button
          className="primary"
          style={{ marginLeft: "5px" }}
          onClick={() => props.handleAddTime(props.by === "id" ? props.item.weekday : "")}
        >
          <Icon name="plus" />Add
        </Button>
      }
    </div>
  </Form.Field>


}




const AgencyTable: React.FC<AgencyTableProps> = (props) => {
  console.log(props);
  //loading
  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false)

  const [openModConfirm, setOpenModConfirm] = useState({
    open: false,
    error: null,
  })
  const [openModConfirmEdit, setOpenModConfirmEdit] = useState(false)
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial)

  //list
  const [createdList, setCreatedList] = useState([])
  const [dateList, setDateList] = useState([])

  //selected
  const [selectedList, setSelectedList] = useState(null)
  const [currentAction, setCurrentAction] = useState<"create" | "edit">("create")
  const [selectedKey, setSelectedKey] = useState("")

  //options
  const [diagRuleOptions, setDiagRuleOptions] = useState([])
  // const [serviceTypeOptions, setServiceTypeOptions] = useState([])

  const [numberPeople, setNumberPeople] = useState<any>(0)
  const [workOnDay, setWorkOnDay] = useState({})
  const [diagRuleId, setDiagRuleId] = useState<any>("")

  const isMounted = useRef(false)
  const divContentRef = useRef<HTMLDivElement>()


  // ---------- API GET -----
  const getCreatedList = useCallback(async (id = null) => {
    setIsLoadingSchedule(true)

    setSelectedList(null)
    setNumberPeople(0)
    setCurrentAction("create")
    setDateList([])
    setWorkOnDay({})

    // const [res, err] = props.controller
    const [res, error] = await props.controller.prxManager.getListSchedule({
      apiToken: props.apiToken,
      divisionId: props.division
    })
    const data = res?.items
    setCreatedList(data)

    // if (!isMounted.current || isSelect) {
    if (id) {
      const findItem = data.find((item) => item.id === id)
      onSetSelectList(findItem)
    } else {
      onSetSelectList(data?.[0])
    }
    if (data?.[0]) {
      setCurrentAction("edit")
    }
    isMounted.current = true
    // }
    setIsLoadingSchedule(false)

  }, [props.apiToken, props.controller.prxManager, props.division])

  const createDateList = useCallback(
    () => {
      let list = []
      if (selectedList) {

        list = Object.keys(workOnDay).map((item, index) => {
          const startDate = moment(selectedList.start_datetime).add(index, "days")
          return {
            dayName: startDate.format('dddd'),
            date: startDate.format('DD MMM YYYY'),
            key: item
          }
        })
      }
      setDateList(list)
    },
    [selectedList, workOnDay],
  )
  useEffect(() => {
    if (props.division) {
      getCreatedList()
    }
    // getServiceType()
  }, [getCreatedList, props.division])

  useEffect(() => {
    createDateList()
  }, [createDateList, workOnDay])

  useEffect(() => {
    const getDiagRule = async () => {
      let [resDiagRule, error] = await props.controller.prxManager.getDiagRule({
        apiToken: props.apiToken,
        division: props.division,
        active: true
      })

      const mapOption = (item, index) => ({ key: index, value: item.id, text: item.name })
      const options = resDiagRule?.items ? resDiagRule.items.map(mapOption) : []

      setDiagRuleOptions(options)

    }
    if (props.division) {
      getDiagRule()
    }
  }, [props.division])

  const onSetSelectList = (data: { id?: number; provider_type_category?: string; start_datetime?: string; end_datetime?: string; patient_per_slot?: string; items?: any[]; work_on_monday: any; work_on_tuesday: any; work_on_wednesday: any; work_on_thursday: any; work_on_friday: any; work_on_saturday: any; work_on_sunday: any; max_patient?: any }) => {
    if (data) {
      setNumberPeople(data.patient_per_slot)
      setWorkOnDay({
        workOnMonday: data.work_on_monday,
        workOnTuesday: data.work_on_tuesday,
        workOnWednesday: data.work_on_wednesday,
        workOnThursday: data.work_on_thursday,
        workOnFriday: data.work_on_friday,
        workOnSaturday: data.work_on_saturday,
        workOnSunday: data.work_on_sunday
      })
      setSelectedList(data)

      setSelectedKey("")
    }
  }

  //ISOString
  const createRangeDate = (date: moment.MomentInput) => {

    const locale = moment.locale()
    moment.locale("en")

    let checkDate = ""
    let day = -1
    let addDate = moment(date)

    checkDate = addDate.format("dddd")

    while (checkDate !== "Monday") {
      addDate = moment(date).add(day, "days")
      checkDate = addDate.format("dddd")
      day -= 1
    }

    moment.locale(locale)
    const startDatetime = addDate.toISOString()
    const endDatetime = addDate.add(6, "days").toISOString()

    return [startDatetime, endDatetime]
  }

  const handleAddList = async () => {
    if (!createdList) {
      return
    }
    if (createdList.some(item => !item.id)) {
      setOpenModInfo({
        open: true,
        title: "กรุณาบันทึกข้อมูลก่อนเพิ่มรายการ",
        color: "yellow"
      })
      return
    }
    const cloneList = [...createdList]
    const sortDate = cloneList.sort((a, b) => ('' + b.end_datetime).localeCompare(a.end_datetime))
    let date = sortDate[0] ? moment(sortDate[0]?.end_datetime).add(1, "days").toISOString() : moment().toISOString()
    const [startDatetime, endDatetime] = createRangeDate(date)
    const data = {
      provider_type_category: "",
      start_datetime: startDatetime,
      end_datetime: endDatetime,
      patient_per_slot: "",
      items: [],
      work_on_monday: false,
      work_on_tuesday: false,
      work_on_wednesday: false,
      work_on_thursday: false,
      work_on_friday: false,
      work_on_saturday: false,
      work_on_sunday: false,
    }
    setCreatedList([...createdList, data])
    onSetSelectList(data)
    setNumberPeople(0)
    setCurrentAction("create")
    setDateList([])
  }

  const handleClickList = (item: any) => {
    if (item.id === selectedList.id) {
      return
    }
    if (item.id) {
      setCurrentAction("edit")
    } else {
      setCurrentAction("create")

    }
    onSetSelectList(item)
  }

  const handleDeleteAll = async () => {
    setOpenModConfirm({
      open: true,
      error: ""
    })
  }

  const handleConfirmDeleteAll = async () => {
    const cloneData = { ...selectedList }
    cloneData.items = []
    setSelectedList(cloneData)
    handleCloseModConfirm()
  }


  const handleDeleteOrder = async (params: DeleteOrderParams) => {
    const { by, byValue } = params
    const cloneData = { ...selectedList }
    let setValue = []
    if (by === "id") {
      setValue = cloneData.items.map((item: any, index: any) => (byValue === item.id ? { ...item, active: false } : item))
    } else {
      setValue = cloneData.items.filter((item: any, index: any) => (byValue !== index))
    }
    console.log(byValue, setValue)
    cloneData.items = setValue
    setSelectedList(cloneData)
  }

  const handleAddTime = (weekday = "") => {

    const cloneData = { ...selectedList }
    if (!cloneData.items) {
      cloneData.items = []
    }
    if (weekday) {
      cloneData.items.push({
        start_time: "",
        end_time: "",
        weekday,
        is_weekday: true,
        id: new Date().toString(),
        patient_per_slot: weekDayData[weekday].patient_per_slot || 0,
        active: true
      })
    }
    else {
      cloneData.items.push({
        start_time: "",
        end_time: "",
      })
    }
    console.log(cloneData)
    setSelectedList(cloneData)
  }

  const handleChangeInput = (params: ChangeInputParams) => {
    const { key, value, by, byValue } = params
    const cloneData = { ...selectedList }

    const setValue = cloneData.items?.map((item: any, index: any) =>
      (byValue === (by === "id" ? item.id : index) ? { ...item, [key]: value } : item))
    cloneData.items = setValue
    setSelectedList(cloneData)
  }

  const handleCloseModConfirm = () => {
    setOpenModConfirm({
      open: false,
      error: null,
    })
  }

  const handlePrevRangeDate = () => {
    const { start_datetime } = selectedList
    setSelectedList({
      ...selectedList,
      start_datetime: moment(start_datetime).add(-7, "days").toISOString(),
      end_datetime: moment(start_datetime).add(-1, "days").toISOString()
    })
    setWorkOnDay({ ...workOnDay })
  }

  const handleNextRangeDate = () => {
    const { end_datetime } = selectedList
    setSelectedList({
      ...selectedList,
      start_datetime: moment(end_datetime).add(1, "days").toISOString(),
      end_datetime: moment(end_datetime).add(7, "days").toISOString()
    })
    setWorkOnDay({ ...workOnDay })
  }

  /**
   * ตัด workon ออก เพื่อ get ชื่อวัน
   * @example workOnMonday => MONDAY 
   */
  const subStrWorkOn = (selectedKey) => {
    return selectedKey.replace(/workOn/i, "").toUpperCase()
  }

  const handleSaveSchedule = async (confirmed = false) => {
    if (selectedList) {

      const { provider_type_category, start_datetime, end_datetime, id, diag_rule, items = [] } = selectedList

      let data: any = {
        apiToken: props.apiToken,
        patientPerSlot: +numberPeople,
        diagRule: diag_rule,
        items
      }
      if (id) {
        data.id = id
      }

      if (currentAction === "edit" && !selectedKey) {
        setOpenModInfo({
          open: true,
          color: "yellow",
          title: "กรุณาเลือกวันที่ให้บริการก่อนบันทึก"
        })
        return
      }

      if (currentAction === "create") {
        data = {
          providerTypeCategory: provider_type_category,
          startDate: moment(start_datetime).format("YYYY-MM-DD"),
          endDate: moment(end_datetime).format("YYYY-MM-DD"),
          division: props.division,
          ...data,
          ...workOnDay,
        }
      } else if (selectedKey) {
        const week = weekDayData[subStrWorkOn(selectedKey)]
        const items = (week.items || []).map((item) =>
          item.is_weekday ? { start_time: item.start_time, end_time: item.end_time } : item)
        data[selectedKey] = true
        data.confirmed = confirmed
        data.items = items || []
        data.patientPerSlot = +week.patient_per_slot
      }


      const [res, error] = await props.controller.prxManager.postCreateUpdateSchedule(data)

      if (error) {
        setOpenModInfo({
          open: true,
          error
        })

        return
      }

      if (!res.success && currentAction === "edit") {
        setOpenModConfirmEdit(true)
        return
      }

      setOpenModInfo({
        open: true,
        title: "บันทึกสำเร็จ",
        color: "green"
      })
      setOpenModConfirmEdit(false)
      getCreatedList(res.id)
      setSelectedKey(null)
    }
  }

  const handleChangePatientPerSlot = (day, value) => {

    let cloneSelectedList = { ...selectedList }

    cloneSelectedList.items = cloneSelectedList.items.map((item) => (
      item.weekday === day ? { ...item, patient_per_slot: value } : item
    ))

    if (cloneSelectedList.items.findIndex(item => item.weekday === day) === -1) {
      cloneSelectedList.items.push({
        patient_per_slot: value,
        is_disabled: true,
        weekday: day
      })
    }

    setSelectedList(cloneSelectedList)

  }

  const handleCloseModInfo = () => [
    setOpenModInfo(modInfoInitial)
  ]

  const handleConfirmEdit = () => {
    handleSaveSchedule(true)
  }

  const maximumNumberPeople = useCallback(
    (start, end, patientPerSlot = null) => {
      if (!start || !end) {
        return ""
      }
      const startTime = start.split(":")
      const endTime = end.split(":")
      const hour: number = +endTime[0] - +startTime[0]
      const min: number = +endTime[1] - +startTime[1]

      const minPerPeople = (patientPerSlot === null ? +numberPeople : +patientPerSlot) / 15
      if (hour < 0) {
        return ""
      }
      if (hour === 0 && min < 0) {
        return ""
      }

      const sum = hour * 60 + min

      if (sum % 15 !== 0) {
        return ""
      }
      const result = Math.floor(sum * minPerPeople)
      return result === NaN ? "" : result
      // return Math.floor(sum * minPerPeople)??""
    },
    [numberPeople],
  )

  const weekDayData = useMemo(() => {
    if (!selectedList?.id) {
      return null
    }
    const days = Object.keys(WEEK_DAY_THAI)
    const week = {}
    console.log(selectedList?.items, "650")
    for (const day of days) {
      const filterWeekday = selectedList?.items?.filter(item => item.weekday === day) || []
      week[day] = {}
      week[day].items = filterWeekday.filter(item => !item.is_disabled)
      week[day].patient_per_slot = filterWeekday[0]?.patient_per_slot || 0
    }
    console.log(week)
    return week
  }, [selectedList])

  const peoplePerDay: number = useMemo(() => {
    return selectedList?.items?.reduce((result: number, item: { start_time: any; end_time: any; patient_per_slot: number, weekday: string }) =>
      //  เลือก radio button and key radio button = weekday หรือ !weekDayData (อยู่ใน action สร้างรายวัน)
      result += ((selectedKey && subStrWorkOn(selectedKey) === item.weekday || !weekDayData) ?
        (maximumNumberPeople(item.start_time, item.end_time, !weekDayData ? null : item.patient_per_slot) || 0) : 0)
      , 0) || 0
  }, [selectedList, selectedKey, weekDayData, maximumNumberPeople])

  return <>
    <Dimmer active={isLoadingSchedule} inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
    <div style={{ display: "grid", gridTemplateColumns: "270px auto", height: "100vh" }}>

      {/* Left */}
      <div style={{}} className="list-create">

        <div className="header-list">
          <label>
            รายการที่สร้างแล้ว
          </label>
          <Button icon="plus" circular onClick={handleAddList} />
        </div>
        <div className="group-item">

          {
            !createdList?.[0] &&
            <div style={{ textAlign: "center", marginTop: "20px" }}>ไม่มีรายการ</div>
          }
          {
            createdList?.map((item, index) =>
              <div
                key={"list" + index}
                className={`item ${selectedList?.id === item?.id ? " active" : ""}`}
                onClick={() => handleClickList(item)}

              >
                <div style={{
                  fontWeight: "bold"
                }}>
                  {moment(item.start_datetime).format("DD MMMM")} - {moment(item.end_datetime).format("DD MMMM")}
                </div>
                <div
                  style={{ display: "flex", alignItems: "baseline", fontWeight: "bold" }}
                >
                  ( จำนวนคนที่รับบริการได้
                  <div className="primary number">{item.max_patient || "ไม่ระบุ"}  </div>
                  คน)
                </div>
                <div
                  className="primary"
                  style={{
                    marginTop: "5px"
                  }}>
                  ประเภทบริการ : {SERVICE_TYPE_OPTIONS.find((option) =>
                    item.provider_type_category === option.value)?.text}
                </div>
                <div className="primary">
                  {item.description ? `( ${item.description} )` : ""}
                </div>
              </div>
            )
          }
        </div>
      </div>

      {/* Center */}
      {
        selectedList &&
        <div ref={divContentRef} style={{ padding: "30px 0 70px 15px", overflowY: "auto" }}>
          <Header>การจัดการตาราง</Header>
          <Divider />

          <Form className="table-management">
            <Form.Field inline>
              <label className="label">
                เลือกประเภทบริการ :
              </label>
              <Dropdown
                value={selectedList?.provider_type_category}
                options={SERVICE_TYPE_OPTIONS}
                search
                selection
                onChange={(e, { value }) => setSelectedList({
                  ...selectedList,
                  provider_type_category: value
                })}
              />
            </Form.Field>

            <Form.Field inline style={{ display: "flex", alignItems: "center" }}>
              <label className="label" >
                แบบประเมิน :
              </label>
              <Dropdown
                value={selectedList?.diag_rule || ""}
                options={diagRuleOptions}
                search
                selection
                onChange={(e, { value }) => setSelectedList({
                  ...selectedList,
                  diag_rule: value
                })}
                fluid
                style={{ width: "30%" }}
              />
            </Form.Field>

            <Form.Field inline
              style={{
                paddingTop: "5px"
              }}
            >
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <label className="label">วันที่ : </label>
                <Button
                  className="light"
                  icon="angle left"
                  onClick={handlePrevRangeDate}
                />
                <div
                  style={{ marginLeft: "5px", marginRight: "5px", color: "#828282" }}
                >
                  {moment(selectedList?.start_datetime).format("DD MMMM")} - {moment(selectedList?.end_datetime).format("DD MMMM")}
                </div>
                <Button
                  className="light"
                  icon="angle right"
                  onClick={handleNextRangeDate}
                />
                <Button
                  className={currentAction === "create" ? "primary" : "grey"}
                  style={{ marginLeft: "5px" }}
                  onClick={() => setCurrentAction("create")}
                  disabled={selectedList?.id ? true : false}
                >
                  สร้างตารางกลุ่ม
                </Button>
                <Button
                  className={currentAction === "create" ? "grey" : "primary"}
                  style={{ marginLeft: "10px" }}
                  onClick={() => setCurrentAction("edit")}
                  disabled={selectedList?.id ? false : true}
                >
                  แก้ไขตารางรายวัน
                </Button>
              </div>
            </Form.Field>

            <Form.Field inline
              style={{
                paddingTop: "10px"
              }}>
              <div className="list-date">
                <label className="label">วันที่ให้บริการ : </label>
                {
                  dateList.map((list, index) =>
                    <div
                      key={"date" + index}
                      className="group-item"
                    >
                      <div style={{ marginBottom: "15px" }}>
                        {
                          currentAction === "create" ?
                            <Checkbox
                              checked={workOnDay[list.key]}
                              onChange={(e, { checked }) => setWorkOnDay({
                                ...workOnDay,
                                [list.key]: checked
                              })}
                            /> :
                            <Radio
                              name='radioGroup'
                              value={list.key}
                              checked={list.key === selectedKey}
                              onChange={() => setSelectedKey(list.key)}
                            />
                        }
                      </div>
                      <div className="item">
                        <div>{list.dayName}</div>
                        <div>{list.date}</div>
                      </div>
                    </div>
                  )
                }
                {weekDayData && selectedKey &&
                  <Button
                    color="yellow"
                    style={{ marginLeft: "20px" }}
                    onClick={() => setSelectedKey("")}
                  >เคลียร์</Button>
                }
              </div>
            </Form.Field>
          </Form>

          <div style={{ display: 'grid', gridTemplateColumns: "130px auto 15%", marginTop: "35px" }}>
            <div className="label">ตั้งค่าตาราง : </div>
            {!selectedKey && <div className="font-primary" style={{ fontWeight: "bold" }}>
              จำนวนคนที่รับได้ รวมทั้งหมด {peoplePerDay || (!selectedKey ? selectedList?.max_patient : "") || "ไม่ระบุ"} คน
            </div>}
            <div>
              {
                !weekDayData &&
                <Button color="red" onClick={handleDeleteAll}>Delete All</Button>
              }
            </div>
          </div>
          <Divider />

          <Form>
            {
              !weekDayData &&
              <Form.Field inline>
                <label>จำนวนคนที่รับได้ใน {15} นาที</label>
                <Input
                  style={{ margin: "0 25px" }}
                  value={numberPeople || ""}
                  onChange={(e, { value }) => setNumberPeople(value)}
                />
                <label>คน</label>


              </Form.Field>
            }
            {
              Object.keys(weekDayData || {})?.map((day, dayIndex) =>
                selectedKey && day !== subStrWorkOn(selectedKey) ? null :
                  <>

                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: "130px auto 15%",
                        marginTop: "35px",
                        marginBottom: selectedKey ? "10px" : "",
                        alignItems: "baseline"
                      }}>
                      <div >
                        <Header as="h4" style={{ marginTop: dayIndex !== 0 ? "-5px" : "" }} >{WEEK_DAY_THAI[day]}</Header>
                      </div>
                      {selectedKey && <div className="font-primary" style={{ fontWeight: "bold" }}>
                        จำนวนคนที่รับได้ รวมทั้งหมด {peoplePerDay || (!selectedKey ? selectedList?.max_patient : "") || "ไม่ระบุ"} คน (ต่อวัน)
                      </div>}
                    </div>


                    <Form.Field inline>
                      <label>จำนวนคนที่รับได้ใน {15} นาที</label>
                      <Input
                        style={{ margin: "0 25px" }}
                        value={weekDayData[day]?.patient_per_slot || ""}
                        onChange={(e, { value }) => handleChangePatientPerSlot(day, value)}
                      />
                      <label>คน</label>


                    </Form.Field>
                    {
                      weekDayData[day]?.items?.[0] ? weekDayData[day]?.items?.map((item: { id: number; start_time: any; end_time: any; active: boolean }, index: string | number) =>
                        item.active && <CreateTimeToForm
                          index={index}
                          item={item}
                          selectedList={selectedList}
                          handleChangeInput={handleChangeInput}
                          maximumNumberPeople={maximumNumberPeople}
                          handleDeleteOrder={handleDeleteOrder}
                          handleAddTime={handleAddTime}
                          by="id"
                          patientPerSlot={weekDayData[day]?.patient_per_slot}
                          items={weekDayData[day]?.items}

                        />

                      ) : <>
                        {/* <Form> */}
                        {/* <Form.Group inline>
                          <Form.Field width={10}>
                            ไม่มีข้อมูล
                          </Form.Field> */}
                        <Form.Field inline style={{ display: "flex", alignItems: "center", marginTop: "-13px" }}>
                          <div style={{ width: "47rem" }}>ไม่มีข้อมูล</div>
                          <Button
                            className="primary"
                            // style={{ marginRight: "1" }}
                            onClick={() => handleAddTime(day)}
                          >
                            <Icon name="plus" />Add
                          </Button>
                        </Form.Field>
                        {/* </Form.Group> */}
                        {/* </Form> */}
                      </>
                    }
                    <Divider />
                  </>

              )
            }
            {
              !weekDayData && selectedList?.items?.map((item: { start_time: any; end_time: any, active: boolean }, index: string | number) =>
                <CreateTimeToForm
                  index={index}
                  item={item}
                  selectedList={selectedList}
                  handleChangeInput={handleChangeInput}
                  maximumNumberPeople={maximumNumberPeople}
                  handleDeleteOrder={handleDeleteOrder}
                  handleAddTime={handleAddTime}
                  by="index"
                  items={selectedList?.items}
                />

              )
            }
          </Form>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: "35px", marginBottom: "-35px" }}
          >
            {
              !selectedList?.items?.[0] && !weekDayData &&
              <Button
                className="primary"
                style={{ marginLeft: "-50px" }}
                onClick={() => handleAddTime()}
              >
                <Icon name="plus" />
                Add
              </Button>
            }
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: "50px", }}
          >
            <Button
              className="primary"
              style={{ marginLeft: "-50px" }}
              onClick={() => handleSaveSchedule(false)}
            >
              บันทึก
            </Button>
          </div>

          <ModConfirm
            openModal={openModConfirm.open}
            titleName="ต้องการลบรายการทั้งหมดหรือไม่"
            size="mini"
            denyButtonColor="orange"
            denyButtonText="ยกเลิก"
            approveButtonColor="blue"
            approveButtonText="ตกลง"
            content={
              <ErrorMessage error={openModConfirm.error} />
            }
            onApprove={() => {
              handleConfirmDeleteAll()
            }}
            onDeny={handleCloseModConfirm}
            onCloseWithDimmerClick={handleCloseModConfirm}
          />

          <ModConfirm
            openModal={openModConfirmEdit}
            titleName="ยืนยันการแก้ไข"
            size="mini"
            denyButtonColor="orange"
            denyButtonText="ยกเลิก"
            approveButtonColor="blue"
            approveButtonText="ตกลง"
            onApprove={() => {
              handleConfirmEdit()
            }}
            onDeny={() => setOpenModConfirmEdit(false)}
            onCloseWithDimmerClick={() => setOpenModConfirmEdit(false)}
          />

          <ModInfo
            open={openModInfo.open}
            titleColor={openModInfo.color || "red"}
            titleName={openModInfo.title}
            onApprove={handleCloseModInfo}
            onClose={handleCloseModInfo}
          >
            <ErrorMessage error={openModInfo.error} />
          </ModInfo>
        </div>
      }

    </div>
  </>

}
const DoctorSchedule: React.FunctionComponent<ManageScheduleProps> = (props) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="main-doctor-schedule">
      {props.show === "DoctorSchedule" ?
      <div style={{ height: "100vh" }}>
        {props.match.params.id && props.match.params.weekStartSerial ?
        <TimeDoctorEdit
          {...props}
          controller={props.queueController}
          apiToken={props.apiToken}
          providerId={props.providerId}
        /> :
        <TimeDoctor
          {...props}
          controller={props.queueController}
          apiToken={props.apiToken}
          providerId={props.providerId}
          onClickDaySlot={props.onClickDaySlot}
        />}
      </div>:
      
      <AgencyTable
        apiToken={props.apiToken}
        division={props.division}
        controller={props.controller}
      />}
    </div>
  )
}

export default DoctorSchedule

