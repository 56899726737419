import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Modal } from "semantic-ui-react";

// Common
import DeleteColumn from "react-lib/appcon/common/DeleteColumn";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// UX
import CardRejectOrderUX from "./CardRejectOrderUX";
import CardDrugOrder from "react-lib/apps/HISV3/TPD/CardDrugOrder";
import CardSubAllergyLog from "react-lib/apps/HISV3/TPD/CardSubAllergyLog";
import ModActionLog from "../TPD/ModActionLog";

// Interface
import { FilterType } from "./sequence/RejectOrder";

const BUTTON_ACTIONS = {
  search: "search",
};

const CARD_DRUG_ORDER_ID = "CardDrugOrder";
const CARD_REJECT_ORDER = "CardRejectOrder";

const ACTION_SEARCH = `${CARD_REJECT_ORDER}_${BUTTON_ACTIONS.search}`;

const CardRejectOrder = (props: any) => {
  const [openModDrug, setOpenModDrug] = useState<boolean>(false);
  const [openActionLog, setOpenActionLog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "RejectOrder",
      restart: true,
      card: ACTION_SEARCH,
    });
  }, []);

  useEffect(() => {
    if (selectedRow?.patient) {
      props.runSequence({
        sequence: "Allergy",
        form: CARD_REJECT_ORDER,
        patient: selectedRow.patient,
        restart: true,
        noEmr: true,
      });
    }

    return () => {
      props.runSequence({ sequence: "Allergy", clear: true });
    };
  }, [selectedRow?.patient]);

  // Callback
  const handleClose = useCallback(async () => {
    setOpenModDrug(false);

    await props.setProp("selectedEncounter", null);
    await props.setProp("selectedEmr", null);

    handleSearch();
  }, []);

  const handleViewEmr = useCallback(
    (item: any) => () => {
      props.runSequence({
        sequence: "RejectOrder",
        action: "select_drug",
        item: item,
        card: CARD_DRUG_ORDER_ID,
      });

      setOpenModDrug(true);
      setSelectedRow(item);
    },
    []
  );

  const handleActionLog = useCallback(
    (item: any) => () => {
      props.runSequence({
        sequence: "RejectOrder",
        action: "action_log",
        item: item,
        card: CARD_DRUG_ORDER_ID,
      });

      setOpenActionLog(true);
    },
    []
  );

  // Memo
  const rejectOrderList = useMemo(() => {
    return (props.RejectOrderSequence?.RejectOrderList || [])?.map(
      (item: any) => ({
        ...item,
        id: item.order_id,
        drugType: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{item.model_verbose_name}</div>
            <div
              style={{
                display: item.need_approve ? "flex" : "none",
                alignItems: "center",
                color: "red",
              }}
            >
              <img
                aria-hidden="true"
                alt="need approve icon"
                src={"/static/images/order_images/need_approve.png"}
              />
              {` NEED APPROVE`}
            </div>
          </div>
        ),
        emr: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              aria-hidden="true"
              alt="view emr icon"
              src={"/static/images/drugOrder/view_emr.png"}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={handleViewEmr(item)}
            />
            <img
              aria-hidden="true"
              alt="approve log icon"
              src={"/static/images/drugOrder/approve_log.png"}
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={handleActionLog(item)}
            />
          </div>
        ),
      })
    );
  }, [props.RejectOrderSequence?.RejectOrderList]);

  const drugOrderActionLog = useMemo(() => {
    const items = props.RejectOrderSequence?.drugOrderActionLog || [];

    return items.map((item: any, idx: any) => {
      return {
        ...item,
        name: (
          <div style={{ display: "flex" }}>
            {item.name}
            {item.is_student && (
              <img
                src={"/static/images/order_images/student.png"}
                alt="student icon"
                style={{ width: "1.3em", height: "1.3em", marginLeft: "10px" }}
              />
            )}
          </div>
        ),
      };
    });
  }, [props.RejectOrderSequence?.drugOrderActionLog]);

  // Handler
  const handleChangeValue = (key: keyof FilterType) => (e: any, v: any) => {
    props.setProp(`RejectOrderSequence.${key}`, v.value);
  };

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.order_id &&
          rowInfo?.original?.order_id === selectedRow?.order_id
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        setSelectedRow(rowInfo?.original);
      },
    };
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "RejectOrder",
      action: "search",
      card: ACTION_SEARCH,
    });
  };

  return (
    <>
      <CardRejectOrderUX
        rejectOrderList={rejectOrderList}
        getTrProps={handleRowProps}
        handleChangeValue={handleChangeValue}
        RejectOrderSequence={props.RejectOrderSequence}
        // Element
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={ACTION_SEARCH}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
            // config
            color={"blue"}
            size="medium"
            title="ค้นหา"
          />
        }
      />

      <Modal
        closeIcon
        style={{ width: "95%", padding: "1rem 1rem", margin: "2rem 0 0" }}
        open={openModDrug}
        onClose={handleClose}
      >
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            <div style={{ marginRight: "20px" }}>
              {selectedRow?.model_verbose_name?.replace("รายการสั่ง", "")}
            </div>

            <div style={{ marginRight: "15px" }}>
              {`[${selectedRow?.hn}]  ${selectedRow?.patient_name}`}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ paddingRight: "3px" }}>ADR: </span>
              <CardSubAllergyLog
                AllergySequence={props.AllergySequence}
                runSequence={props?.runSequence}
                noResultsMessage="ไม่มีข้อมูลการบันทึก"
              />{" "}
            </div>

            <div style={{ flex: "1" }} />
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              {`Status : ${props.drugOrder?.status_label}`}
            </div>
            <div>
              <DeleteColumn
                value={true}
                icon="trash"
                size="mini"
                nameButton="Delete"
                titleName={`ต้องการยกเลิกใบสั่งยา นี้ใช่หรือไม่`}
                titleColor="orange"
                onConfirm={() => {
                  props.runSequence({
                    sequence: "RejectOrder",
                    action: "cancel",
                    item: selectedRow,
                    card: CARD_REJECT_ORDER,
                  });
                }}
              />
            </div>
          </div>

          <CardDrugOrder
            hiddenMenuOrderType={true}
            hiddenDrugList={true}
            isCardRejectOrder={true}
            orderTypeCardRejectOrder={props.RejectOrderSequence?.orderType}
            openModDrug={openModDrug}
            setOpenModDrug={setOpenModDrug}
            disabledVerbalMedOrder={props.disabledVerbalMedOrder}
            orderTypes={props.orderTypes}
            setProp={props.setProp}
            onEvent={props.onEvent}
            runSequence={props.runSequence}
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedProgressCycle={props.selectedProgressCycle}
            // selectedDrugItem to edit
            selectedDrugItemDetail={props.selectedDrugItemDetail}
            selectedDrugItemDetailOptions={props.selectedDrugItemDetailOptions}
            drugSearchText={props.drugSearchText}
            drugSearchResult={props.drugSearchResult}
            DrugSelectSequence={props.DrugSelectSequence}
            selectedDrug={props.selectedDrug}
            drugOrderList={props.drugOrderList}
            drugOrderListLoading={props.drugOrderListLoading}
            drugOrder={props.drugOrder}
            renderDrugOrderItems={props.renderDrugOrderItems}
            drugDose={props.drugDose}
            drugQty={props.drugQty}
            drugDuration={props.drugDuration}
            drugDescription={props.drugDescription}
            drugPrn={props.drugPrn}
            // modal doctor certificate check
            modDoctorCertificate={props.modDoctorCertificate}
            DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
            modConfirmDeleteDrug={props.modConfirmDeleteDrug}
            modReMedication={props.modReMedication}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            modDrugInteraction={props.modDrugInteraction}
            currentDoctor={props.currentDoctor}
            drugOrderItemEstimate={props.drugOrderItemEstimate}
            drugPermission={props.drugPermission}
            searchedItemList={props.searchedItemList}
            drugOrderItemMasterData={{
              masterUnits: props.masterOptions.unit,
              masterRoutes: props.masterOptions.route,
              masterSites: props.masterOptions.site,
              masterFrequencies: props.masterOptions.frequency,
              masterMethods: props.masterOptions.method,
            }}
            divisionForOrderDivOptions={props.masterOptions.divisionForOrderDiv}
            duplicateReasonsOptions={props.masterOptions.duplicateReasons}
            masterData={props.masterData}
            // SolventSelectionConfig
            modSolventSelectionConfig={props.modSolventSelectionConfig}
            selectedVerbalDrugOrderWorking={
              props.selectedVerbalDrugOrderWorking
            }
            django={props.django}
            isStudentUser={!!props.drugOrder?.student}
          />
        </>
      </Modal>

      <ModActionLog
        open={openActionLog}
        data={drugOrderActionLog}
        headers="User,Action,Note,Division,Date-Time"
        // callback
        onClose={() => setOpenActionLog(false)}
      />
    </>
  );
};

CardRejectOrder.displayName = "CardRejectOrder";

export default React.memo(CardRejectOrder);
