import React, { useMemo } from "react";
import { Divider, Modal } from "semantic-ui-react";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// Interface
import { SeparatedString } from "../common/CommonInterface";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";

// Types
type ModActionLogProps = {
  open: boolean;
  data: DataType[];
  headers: HeaderType;
  // callback
  onClose: () => any;
};

type DataType = {
  name: string;
  action: string;
  division: string;
  datetime: string;
  note: string;
};

type NestedType<T = TableKeys, P = ""> = T extends string
  ? `${T & string}${Exclude<TableKeys, P | T> extends never
      ? ""
      : T extends P
      ? never
      : `,${NestedType<Exclude<TableKeys, P>, P | T>}`}`
  : never;

type HeaderType = SeparatedString<NestedType, ",">;

type TableKeys = keyof typeof TABLES;

// Const
const TABLES = {
  User: "name",
  Action: "action",
  Note: "note",
  Division: "division",
  "Date-Time": "datetime",
};

const ModActionLog = (props: ModActionLogProps) => {
  // Memo
  const items = useMemo(() => {
    return props.data.map((item) => {
      const isValid = moment(item.datetime).isValid();

      return {
        ...item,
        datetime: !isValid
          ? item.datetime
          : formatDatetime(moment(item.datetime), true),
      };
    });
  }, [props.data]);

  const keys = useMemo(() => {
    const headerArray = props.headers.split(",") as TableKeys[];

    return headerArray.map((header) => TABLES[header]).join(",");
  }, [props.headers]);

  return (
    <Modal
      open={props.open}
      closeOnDimmerClick={true}
      style={{ padding: "1rem" }}
      onClose={props.onClose}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            padding: "2.5px 0",
          }}
        >
          ประวัติการทำงาน
        </div>
      </div>

      <Divider style={{ margin: "1rem 0 0.5rem" }} />

      <Table
        keys={keys}
        headers={props.headers}
        data={items}
        showPagination={false}
        minRows={9}
        style={{ height: "300px" }}
      />
    </Modal>
  );
};

ModActionLog.displayName = "ModActionLog";

export default React.memo(ModActionLog);
