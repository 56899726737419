import React, { CSSProperties, useEffect, useMemo } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// UX
import CardMedicationErrorStatisticsUX from "./CardMedicationErrorStatisticsUX";

type CardMedicationErrorStatisticsType = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  // data
  medErrorStatistics?: {
    open: true;
    start_date: string;
    end_date: string;
    items: any[];
  };
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
};

// Const
const CenterStyle = {
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
} as CSSProperties;

const BUTTON_ACTIONS = {
  SEARCH: "statistics_search",
};

const CARD_MEDICATION_ERROR_STATISTICS = "CardMedicationErrorStatistics";

const CardMedicationErrorStatistics = (
  props: CardMedicationErrorStatisticsType
) => {
  useEffect(() => {
    return () => {
      const clear = async () => {
        await props.setProp("MedErrorListSequence.medErrorStatistics", {});
        props.setProp("MedErrorListSequence.sequenceIndex", "START");
      };

      clear();
    };
  }, []);

  const items = useMemo(() => {
    return (props.medErrorStatistics?.items || []).map(
      (item: any, index: number) => ({
        ...item,
        no: <div style={CenterStyle}>{index + 1}</div>,
        stakeholder_name: (
          <div style={CenterStyle}>{item.stakeholder_name}</div>
        ),
        type_name: <div style={CenterStyle}>{item.type_name}</div>,
        drug_name: (
          <div style={CenterStyle}>{`[${item.drug_code || ""}][${
            item.drug_name || ""
          }]`}</div>
        ),
        qty: <div style={CenterStyle}>{item.qty}</div>,
      })
    );
  }, [props.medErrorStatistics?.items]);

  const number = useMemo(() => {
    return (props.medErrorStatistics?.items || []).reduce(
      (result: any, item: any) => result + item.qty,
      0
    );
  }, [props.medErrorStatistics?.items || []]);

  const handleExportExcel = () => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "export_excel_statistics",
    });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "statistics_search",
      card: CARD_MEDICATION_ERROR_STATISTICS,
    });
  };

  const handleChange = (e: any, data: any) => {
    props.setProp(
      `MedErrorListSequence.medErrorStatistics.${data.name}`,
      data.value
    );
  };

  const handleBack = () => {
    props.setProp("MedErrorListSequence.medErrorStatistics", {});
  };

  return (
    <div>
      <CardMedicationErrorStatisticsUX
        // data
        medErrorStatistics={props.medErrorStatistics}
        items={items}
        number={number}
        // callback
        onChange={handleChange}
        onExportExcel={handleExportExcel}
        onBack={handleBack}
        // Element
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_MEDICATION_ERROR_STATISTICS}_${BUTTON_ACTIONS.SEARCH}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_MEDICATION_ERROR_STATISTICS}_${BUTTON_ACTIONS.SEARCH}`
              ]
            }
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.SEARCH}
            size="small"
            title="ค้นหา"
          />
        }
      />
    </div>
  );
};

export default React.memo(CardMedicationErrorStatistics);
