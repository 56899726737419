import React, { useState, useMemo } from "react";
import { Button, Dropdown, Form, Input } from "semantic-ui-react";

// UX
import CardMedicationUX from "./CardMedicationUX";
import TimeComboBox from "../../common/TimeComboBox";
import EmployeeToken from "../../common/EmployeeToken";
import { ACTION, checkHideButton } from "./CardOtherForm";
import PreOPButtonAction from "./PreOPButtonAction";

const Medication = {
  medicine_name: "",
  rount: "",
  time: "",
  code: "",
};

export const OR_MEDICATION = "orMedication";
const CARD_OR_MEDICATION = "CardORMedication";

const CardORMedication = (props: any) => {
  const [medicationData, setMedicationData] = useState([Medication]);
  const [medicationOtherData, setMedicationOtherData] = useState([Medication]);

  const handlerAddMedication = (type: any) => {
    const items: any[] = props.data?.data?.[type] || [];

    items.push(JSON.parse(JSON.stringify(Medication)));
    handleChangeData(null, { name: type, value: items });
  };

  const handlerDeleteMedication = (index: any, type: any) => {
    const items: any[] = props.data?.data?.[type] || [];

    if (items.length > 1) {
      items.splice(index, 1);
      handleChangeData(null, { name: type, value: items });
    }
  };

  const handleChangeMedication = (
    value: any,
    index: any,
    key: any,
    type: any
  ) => {
    const items: any[] = props.data?.data?.[type] || [];

    items[index][key] = value;
    handleChangeData(null, { name: type, value: items });
  };

  const handleSave = (action: string) => (e: any, v: any) => {
    const data = props.data?.data || {};

    const mapItems = (item: any) => ({
      code: item.code || "",
      medicine_name: item.medicine_name || "",
      rount: item.rount || "",
      time: item.time || "",
    });

    data.med_items = data.med_items.map(mapItems);
    data.other_med_items = data.other_med_items.map(mapItems);

    handleChangeData(null, {
      name: "",
      value: data,
      callback: () => {
        props.onSave(action)(e, {
          ...v,
          formCode: CARD_OR_MEDICATION,
          formName: "ORMedication",
          key: OR_MEDICATION,
        });
      },
    });
  };

  const handleChangeData = (e: any, v: any) => {
    props.onChangeData?.(e, v);
  };

  return (
    <>
      <CardMedicationUX
        // data
        status={props.data?.status}
        isOrMedication={props.data?.data?.is_or_medication}
        // callback
        onChangeData={handleChangeData}
        // config
        config={{
          hideStatus: checkHideButton(props.data, "STATUS"),
        }}
        // Element
        medication={
          <Form style={{ marginTop: "0.5rem" }}>
            {(props.data?.data?.med_items || [{}])?.map(
              (item: any, index: any) => {
                return (
                  <MedicationData
                    item={item}
                    index={index}
                    type={"med_items"}
                    // options
                    orMedicationOptions={props.masterOptions?.orMedication}
                    routeOptions={props.masterOptions?.route}
                    // config
                    disabled={!props.data?.data?.is_or_medication}
                    // callback
                    onAddMedication={handlerAddMedication}
                    onDeleteMedication={handlerDeleteMedication}
                    onChangeMedication={handleChangeMedication}
                    onEnterToken={props.onEnterToken}
                  />
                );
              }
            )}
          </Form>
        }
        medicationOther={
          <Form>
            {(props.data?.data?.other_med_items || [{}])?.map(
              (item: any, index: any) => {
                return (
                  <MedicationData
                    item={item}
                    index={index}
                    type={"other_med_items"}
                    // options
                    orMedicationOptions={props.masterOptions?.orMedication}
                    routeOptions={props.masterOptions?.route}
                    // config
                    disabled={!props.data?.data?.is_or_medication}
                    // callback
                    onAddMedication={handlerAddMedication}
                    onDeleteMedication={handlerDeleteMedication}
                    onChangeMedication={handleChangeMedication}
                    onEnterToken={props.onEnterToken}
                  />
                );
              }
            )}
          </Form>
        }
        ButtonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [OR_MEDICATION]: props.data }}
            type="save"
            cardKey={OR_MEDICATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.SAVE)}
          />
        }
        ButtonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [OR_MEDICATION]: props.data }}
            type="confirm"
            cardKey={OR_MEDICATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.CONFIRM)}
          />
        }
        ButtonUnConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [OR_MEDICATION]: props.data }}
            type="unconfirm"
            cardKey={OR_MEDICATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.UNCONFIRM)}
          />
        }
      />
    </>
  );
};

/* ------------------------------------------------------ */

/*                    MedicationData;                   */

/* ------------------------------------------------------ */
const MedicationData = (props: any) => {
  const name = useMemo(() => {
    return props.item?.code
      ? decodeURIComponent(atob(props.item.code.split(".")[1]))
      : "";
  }, [props.item?.code]);

  const handleChange = (key: string) => (e: any, data?: any) => {
    const value = typeof e === "string" ? e : data.value;
    props.onChangeMedication(value, props.index, key, props.type);
  };

  const handleEnterToken = (key: string) => (value: string) => {
    props.onEnterToken(value, props.index, key, props.type);
  };

  return (
    <Form.Group inline>
      <Form.Field inline width={3}>
        <Input
          selection={true}
          fluid={true}
          options={props.orMedicationOptions}
          value={props.item.medicine_name}
          disabled={props.disabled}
          readOnly={props.disabled}
          style={{ width: "100%" }}
          onChange={handleChange("medicine_name")}
        ></Input>
      </Form.Field>
      <Form.Field inline width={4}>
        <div>Rount</div>
        <Dropdown
          selection={true}
          fluid={true}
          options={props.routeOptions}
          value={props.item.rount}
          disabled={props.disabled}
          style={{ width: "100%" }}
          onChange={handleChange("rount")}
        ></Dropdown>
      </Form.Field>
      <Form.Field inline width={3}>
        <div>Time</div>
        <TimeComboBox
          defaultValue={props.item.time}
          disabled={props.disabled}
          fluid={true}
          style={{ width: "100%" }}
          onTextChange={handleChange("time")}
        ></TimeComboBox>
      </Form.Field>
      <Form.Field inline width={4}>
        <div style={{ minWidth: "max-content" }}>Given by</div>
        <EmployeeToken
          placeholder="Code"
          disabled={props.disabled}
          onEnterToken={handleEnterToken("code")}
          setProp={props.setProp}
          // onClearToken={async () => {
          //   await props.setProp("intraTransferForm.transferringTokenize", {});
          //   await props.setProp(
          //     "intraTransferForm.transferring_personnel",
          //     ""
          //   );
          // }}
          error={props.item?.error}
          loading={props.item?.loading}
          employeeName={name}
          inputStyle={{ width: "100%" }}
        />
      </Form.Field>
      <Form.Field style={{ display: "flex" }}>
        {props.index !== 0 && (
          <Button
            color="red"
            icon="minus"
            size="mini"
            disabled={props.disabled}
            style={{ margin: "0px 20px" }}
            onClick={() => props.onDeleteMedication(props.index, props.type)}
          ></Button>
        )}
        <Button
          color="blue"
          icon="plus"
          size="mini"
          disabled={props.disabled}
          style={props.index === 0 ? { margin: "0px 20px" } : {}}
          onClick={() => props.onAddMedication(props.type)}
        ></Button>
      </Form.Field>
    </Form.Group>
  );
};

export default React.memo(CardORMedication);
