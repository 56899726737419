import React, { useEffect, useState, useMemo } from "react";
import SubAddressUX from "./SubAddressUX";
import { Button, Checkbox, Icon } from "semantic-ui-react";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { HOME_ADDRESS } from "./sequence/SetPatient";

const CardAddress = (props: any) => {
  const [loadding, setLoadding] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [openSnackMessage, setOpenSnackMessage] = useState(false);

  // Effect
  useEffect(() => {
    if (
      props.errorMessage?.SetPatient !== null &&
      props.errorMessage?.SetPatient !== undefined
    ) {
      setLoadding(false);
      setOpenSnackMessage(true);
      setTimeout(() => {
        setOpenSnackMessage(false);
        props.runSequence({
          sequence: "SetPatient",
          action: "clear",
        });
      }, 2000);
    }
    if (
      props.successMessage?.SetPatient !== null &&
      props.successMessage?.SetPatient !== undefined
    ) {
      setLoadding(false);
      setCompleted(true);
      setTimeout(() => {
        setCompleted(false);
      }, 2000);
      props.runSequence({
        sequence: "SetPatient",
        action: "clear",
      });
    }
  }, [props.errorMessage?.SetPatient, props.successMessage?.SetPatient]);

  // Memo
  const homeAddress = useMemo(() => {
    return props.patient?.home_address || {};
  }, [props.patient?.home_address]);

  const presentAddress = useMemo(() => {
    return props.patient?.present_address || {};
  }, [props.patient?.present_address]);

  const relativeAddress = useMemo(() => {
    return props.patient?.relative_address || {};
  }, [props.patient?.relative_address]);

  const hasAddressDetail = useMemo(() => {
    return Object.keys(HOME_ADDRESS).some((key) => homeAddress[key]);
  }, [homeAddress]);

  const isUseHomeAddress = useMemo(() => {
    return Object.keys(HOME_ADDRESS).every((key) => {
      const homeValue = homeAddress[key];
      const presentValue = presentAddress[key];
      const compare =
        homeValue || presentValue ? homeValue === presentValue : true;
      return compare && hasAddressDetail;
    });
  }, [homeAddress, presentAddress, hasAddressDetail]);

  // Handler
  const handleChangeAddress =
    (address: string) => (key: string) => async (e: any, v: any) => {
      const addressDetail = props.patient?.[`${address}_address`] || {};

      const detail = {
        ...addressDetail,
        [key]: v.value,
      };

      if (isUseHomeAddress && address === "home") {
        await props.setProp(
          `SetPatientSequence.patient.present_address.${key}`,
          v.value
        );
      }

      if (key === "city") {
        let city = props.masterData?.city?.filter(
          (item: any) => item.id === v.value
        ) || []
        if (city.length > 0) {
          detail['zipcode'] = city[0].zipcode
        }
        
      }

      props.setProp(`SetPatientSequence.patient.${address}_address`, detail);
    };

  const handleCopyHomeAddress = (e: any, v: any) => {
    let homeAddressKey = { ...HOME_ADDRESS, zipcode: "" };
    const detail = Object.keys(homeAddressKey).reduce(
      (result, key) => ({
        ...result,
        [key]: v.checked ? homeAddress[key] : (homeAddressKey as any)[key],
      }),
      {}
    );

    props.setProp("SetPatientSequence.patient.present_address", {
      ...presentAddress,
      ...detail,
    });
  };

  return (
    <div style={{ margin: "10px 10px 3rem" }}>
      {openSnackMessage ? (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            setOpenSnackMessage(false);
            props.runSequence({
              sequence: "SetPatient",
              action: "clear",
            });
          }}
          error={props.errorMessage?.SetPatient}
          success={props.successMessage?.SetPatient}
        />
      ) : (
        ""
      )}
      <div>ที่อยู่ตามทะเบียนบ้าน</div>
      <SubAddress
        idPrefix="Home"
        address={homeAddress}
        changeAddress={handleChangeAddress("home")}
        masterOptions={props.masterOptions}
      />
      <hr />
      <div style={{ display: "flex" }}>
        <span>ที่อยู่ปัจจุบัน</span>
        <Checkbox
          id="CardAddress-Checkbox-isUseHomeAddress"
          checked={isUseHomeAddress}
          toggle={true}
          disabled={!hasAddressDetail}
          style={{ margin: "0px 0.5rem 0px 3.5rem", transform: "scale(0.9)" }}
          onChange={handleCopyHomeAddress}
        />
        <label>เหมือนที่อยู่ตามทะเบียนบ้าน</label>
      </div>
      <SubAddress
        idPrefix="Present"
        address={presentAddress}
        disabled={isUseHomeAddress}
        changeAddress={handleChangeAddress("present")}
        masterOptions={props.masterOptions}
      />
      <hr />
      <div>ที่อยู่ผู้ติดต่อ</div>
      <SubAddress
        idPrefix="Relative"
        address={relativeAddress}
        changeAddress={handleChangeAddress("relative")}
        masterOptions={props.masterOptions}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "10px",
        }}
      >
        <Button
          color="green"
          loading={loadding}
          onClick={(e: any) => {
            setLoadding(true);
            props.runSequence({ sequence: "SetPatient", action: "save" });
          }}
        >
          {completed ? <Icon name="check"></Icon> : "SAVE"}
        </Button>
      </div>
    </div>
  );
};

const SubAddress = (props: any) => {
  return (
    <SubAddressUX
      idPrefix={props.idPrefix}
      no={props.address?.no || ""}
      name={props.address?.name || ""}
      type={props.address?.type || ""}
      homeTypeOptions={props.masterOptions?.homeType}
      town={props.address?.town || ""}
      street={props.address?.street || ""}
      road={props.address?.road || ""}
      province={props.address?.province || ""}
      provinceOptions={props.masterOptions?.province || []}
      district={props.address?.district || ""}
      districtOptions={
        props.masterOptions?.district?.filter(
          (item: any) => item.relate === props.address?.province
        ) || []
      }
      city={props.address?.city || ""}
      cityOptions={
        props.masterOptions?.city?.filter(
          (item: any) => item.relate === props.address?.district
        ) || []
      }
      zipcode={props.address?.zipcode || ""}
      tel_home={props.address?.tel_home || ""}
      tel_home_suffix={props.address?.tel_home_suffix || ""}
      tel_office={props.address?.tel_office || ""}
      tel_office_suffix={props.address?.tel_office_suffix || ""}
      tel_mobile={props.address?.tel_mobile || ""}
      email={props.address?.email || ""}
      channel={parseInt(props.address?.channel || "0")}
      contactOptions={props.masterOptions?.contact || []}
      note={props.address?.note || ""}
      disabled={props.disabled}
      changeAddress={props.changeAddress}
    />
  );
};

export default CardAddress;
