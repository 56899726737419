import React, { useMemo } from "react";
import { Icon } from "semantic-ui-react";

import moment from "moment";

import { beStringToAdString } from "../../../utils/dateUtils";

type ORRequestMessageProps = {
  methodId?: number;
  anesthesiaMethodList?: any[];
  startDate?: string; // DD-MM-YYYY (BE.)
  startTime?: string; // HH:mm
  estimateHour?: string;
  estimateMinute?: string;
};

const ORRequestMessage: React.FunctionComponent<ORRequestMessageProps> = (
  props
) => {
  const detail = useMemo(() => {
    const data = (props.anesthesiaMethodList || []).find(
      (item: any) => item.id === props.methodId
    );

    if (!props.startDate || !props.startTime || !data?.id) {
      return {};
    }

    const dateFormat = "DD/MM/YYYY HH:mm";
    const timeBeforeOperation = data.estimate_time_before_operation;
    const timeAfterOperation = data.estimate_time_after_operation;

    const date = beStringToAdString(props.startDate) || "";
    const dateTime = `${date} ${props.startTime}`;
    const selectedStartDate = moment(dateTime, dateFormat);
    const selectedEndDate = moment(dateTime, dateFormat);

    var estimationHour = Math.abs(parseInt(props.estimateHour || "") || 0) * 60;
    var estimationMinute = Math.abs(parseInt(props.estimateMinute || "") || 0);
    var estimationAnesthesiaTime = timeBeforeOperation + timeAfterOperation;

    selectedEndDate.add(
      estimationHour + estimationAnesthesiaTime + estimationMinute,
      "minutes"
    );

    var duration = moment.duration(selectedEndDate.diff(selectedStartDate));

    return {
      before: timeBeforeOperation,
      after: timeAfterOperation,
      hours: duration.hours(),
      minute: duration.minutes(),
    };
  }, [
    props.anesthesiaMethodList,
    props.methodId,
    props.startDate,
    props.startTime,
    props.estimateHour,
    props.estimateMinute,
  ]);

  return (
    <>
      {typeof detail.before !== "undefined" && (
        <div
          style={{
            backgroundColor: "#D6ECF3",
            padding: "0.75rem 1rem",
            margin: "0rem 0rem 0.15rem 1.5rem",
            fontWeight: "normal",
            fontSize: "1.1rem",
            borderRadius: "5px",
          }}
        >
          <Icon
            name="info circle"
            color="blue"
            style={{ margin: "0 1rem 0 0" }}
          />
          <label>
            เวลาเตรียมผู้ป่วย ก่อนการผ่าตัด {detail.before} นาที หลังผ่าตัด{" "}
            {detail.after} นาที เวลารวมทั้งหมด {detail.hours} ชม.{" "}
            {detail.minute} นาที
          </label>
        </div>
      )}
    </>
  );
};

export default React.memo(ORRequestMessage);
