import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Segment,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardMedicalFeeHistoryUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%" }}>
        <Segment
          className="segment-no-shadow">
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              สรุปค่ารักษาพยาบาลผู้ป่วย
            </div>
            <div
              style={{cursor: "pointer"}}>
              
            </div>
          </div>
          <hr>
          </hr>
          <div>
            
            <div>
              
              <Table
                data={props.MedicalFeeHistoryList}
                getTrProps={props.getTrProps}
                headers="ประเภท,เลขที่,วันที่เปิด,สิทธิการรักษา,รวม,ชำระแล้ว,ค้างชำระ,เบิกได้,ส่วนลด,สถานะ"
                keys="type,number,created,coverages,total,paid,unpaid,covered,discount,status"
                minRows="14"
                showPagination={false}
                style={{height:"calc(100dvh - 12.5rem)"}}>
              </Table>
            </div>
          </div>
          <div
            style={{display:"flex",marginTop:"10px"}}>
            
            <Button
              icon="redo"
              onClick={props.onRefresh}>
            </Button>
            <div
              style={{flex:"1"}}>
              
            </div>
            <div
              style={{marginRight:"5px"}}>
              {props.buttonPrint}
            </div>
            <Button
              color="yellow"
              disabled={props.selectedRow ? false : true}
              onClick={props.onEdit}
              style={{display: props.hideButtonEdit? "none": ""}}>
              แก้ไขสิทธิส่วนลด
            </Button>
          </div>
        </Segment>
      </div>
    )
}


export default CardMedicalFeeHistoryUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Thu Aug 01 2024 15:01:08 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Segment",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "segment-no-shadow"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "สรุปค่ารักษาพยาบาลผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 6,
      "void": true
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 16,
      "props": {
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.MedicalFeeHistoryList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "ประเภท,เลขที่,วันที่เปิด,สิทธิการรักษา,รวม,ชำระแล้ว,ค้างชำระ,เบิกได้,ส่วนลด,สถานะ"
        },
        "keys": {
          "type": "value",
          "value": "type,number,created,coverages,total,paid,unpaid,covered,discount,status"
        },
        "minRows": {
          "type": "value",
          "value": "14"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"calc(100dvh - 12.5rem)\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginTop:\"10px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Button",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขสิทธิส่วนลด"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.selectedRow ? false : true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onEdit"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideButtonEdit? \"none\": \"\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Button",
      "parent": 18,
      "props": {
        "icon": {
          "type": "value",
          "value": "redo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRefresh"
        }
      },
      "seq": 19,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardMedicalFeeHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 75
}

*********************************************************************************** */
