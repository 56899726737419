import React, { useEffect, useMemo, useState, useRef } from "react";
import { formatDatetime } from "react-lib/utils/dateUtils";
import CardSupplyOrderHistoryUX from "./CardSupplyOrderHistoryUX";
import CardPatientSearchBox from "../../TPD/CardPatientSearchBox";

import {
  SearchBox,
  EmployeeToken,
} from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const CARD_SUPPLY_ORDER_HISTORY = "CardSupplyOrderHistory";
const CardSupplyOrderHistory = (props: any) => {
  const isMounted = React.useRef(false);

  const patientRef = useRef<any>();
  const doctorRef = useRef<any>();
  const touchCountRef = useRef(0);
  const touchTimerRef = useRef<any>(null);

  useEffect(() => {
    if (props.runSequence) {
      props?.runSequence({ sequence: "SupplyOrderHistory" });

      isMounted.current = true;

      return () => {
        props?.runSequence({ sequence: "SupplyOrderHistory", clear: true });

        isMounted.current = false;
      };
    }
  }, []);

  const supplyOrderHistoryList = useMemo(() => {
    return (props.SupplyOrderHistorySequence?.supplyOrderHistoryList || []).map(
      (item: any) => ({
        ...item,
        orderTime: item.order_time ? formatDatetime(item.order_time) : "",
        orderPerformTime: item.order_perform_time
          ? formatDatetime(item.order_perform_time)
          : "",
        // Issue 63994
        statusMod: item.status !== "PRINTED" ? item.status : item.supply_transfer_status
      })
    );
  }, [props.SupplyOrderHistorySequence?.supplyOrderHistoryList]);

  const handleGetDoctorOptions = async ({ searchText }: any) => {
    const [data, error] = await props.controller.getDoctor({
      name_code: searchText,
    });

    return [data, error];
  };

  const handleSelectedDoctor = (item: any) => {
    props.setProp("SupplyOrderHistorySequence", {
      ...props.SupplyOrderHistorySequence,
      checkedDoctor: true,
      selectedDoctor: item.id,
    });
  };

  const handleOnEnterPatientSearch = (
    id: number,
    hn: string,
    full_name: string
  ) => {
    if (isMounted.current) {
      props.setProp("SupplyOrderHistorySequence", {
        ...props.SupplyOrderHistorySequence,
        checkedPatient: true,
        selectedPatient: id,
      });
    }
  };

  const handleChangeValue =
    (key: string, keyChecked: string) => (e: any, v: any) => {
      props.setProp("SupplyOrderHistorySequence", {
        ...props.SupplyOrderHistorySequence,
        [keyChecked]: true,
        [key]: v.value,
      });
    };
  const handleChangeChecked =
    (key: string) =>
    (e: any, { checked }: any) => {
      props.setProp(`SupplyOrderHistorySequence.${key}`, checked);
    };

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(`SupplyOrderHistorySequence.${key}`, date);
  };

  const handleDoubleClick = (rowInfo: any) => {
    props.runSequence({
      sequence: "SupplyOrderHistory",
      action: "select",
      orderId: rowInfo?.original?.id,
    });

    props?.forward();
  };

  const handleTouchStart = (rowInfo: any) => {
    touchCountRef.current++;

    if (touchCountRef.current === 1) {
      touchTimerRef.current = setTimeout(function () {
        touchCountRef.current = 0;
      }, 300);
    } else if (touchCountRef.current === 2) {
      clearTimeout(touchTimerRef.current);
      touchCountRef.current = 0;

      handleDoubleClick(rowInfo);
    }
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    onTouchStart: () => handleTouchStart(rowInfo),
    onDoubleClick: (e: any) => handleDoubleClick(rowInfo),
  });

  return (
    <div>
      <CardSupplyOrderHistoryUX
        supplyOrderHistoryList={supplyOrderHistoryList}
        masterOptions={props.masterOptions}
        handleChangeValue={handleChangeValue}
        handleChangeChecked={handleChangeChecked}
        handleChangeDate={handleChangeDate}
        handleGetTrProps={handleGetTrProps}
        selectedPatient={props.SupplyOrderHistorySequence?.selectedPatient}
        selectedDoctor={props.SupplyOrderHistorySequence?.selectedDoctor}
        selectedType={props.SupplyOrderHistorySequence?.selectedType}
        selectedStatus={props.SupplyOrderHistorySequence?.selectedStatus}
        selectedOrderDivision={props.SupplyOrderHistorySequence?.selectedOrderDivision}
        selectedPerformDivision={props.SupplyOrderHistorySequence?.selectedPerformDivision}
        selectedOrderStartDate={props.SupplyOrderHistorySequence?.selectedOrderStartDate}
        selectedOrderEndDate={props.SupplyOrderHistorySequence?.selectedOrderEndDate}
        selectedPerformStartDate={props.SupplyOrderHistorySequence?.selectedPerformStartDate}
        selectedPerformEndDate={props.SupplyOrderHistorySequence?.selectedPerformEndDate}
        selectedUser={props.SupplyOrderHistorySequence?.selectedUser}
        checkedPatient={props.SupplyOrderHistorySequence?.checkedPatient}
        checkedDoctor={props.SupplyOrderHistorySequence?.checkedDoctor}
        checkedType={props.SupplyOrderHistorySequence?.checkedType}
        checkedStatus={props.SupplyOrderHistorySequence?.checkedStatus}
        checkedOrderDivision={props.SupplyOrderHistorySequence?.checkedOrderDivision}
        checkedPerformDivision={props.SupplyOrderHistorySequence?.checkedPerformDivision}
        checkedOrderDate={props.SupplyOrderHistorySequence?.checkedOrderDate}
        checkedPerformDate={props.SupplyOrderHistorySequence?.checkedPerformDate}
        checkedUser={props.SupplyOrderHistorySequence?.checkedUser}
        doctorSearch={
          <SearchBox
            ref={doctorRef}
            inputStyle={{ width: "100%", margin: "0px" }}
            onGetSearchOptions={handleGetDoctorOptions}
            textField="name_code"
            onSelectOption={handleSelectedDoctor}
          />
        }
        patientSearch={
          <CardPatientSearchBox
            // controller
            controller={props.controller}
            // callback
            onEnterPatientSearch={handleOnEnterPatientSearch}
          />
        }
        employeeCode={
          <EmployeeToken
            onEnterToken={(code) => {
              props.runSequence({
                sequence: "SupplyOrderHistory",
                action: "searchToken",
                code: code,
              });
            }}
            onClearToken={() => {
              props.setProp("SupplyOrderHistorySequence", {
                ...props.SupplyOrderHistorySequence,
                selectedUserToken: "",
                selectedUser: "",
                loading: false,
                error: null,
              });
            }}
            placeholder="Code"
            fluid={true}
            error={props.SupplyOrderHistorySequence?.error}
            loading={props.SupplyOrderHistorySequence?.loading}
            employeeCode={props?.SupplyOrderHistorySequence?.selectedUserToken || ""}
            employeeName={props?.SupplyOrderHistorySequence?.selectedUser || ""}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "SupplyOrderHistory",
                action: "search",
                card: CARD_SUPPLY_ORDER_HISTORY,
                buttonLoadKey: `${CARD_SUPPLY_ORDER_HISTORY}_SEARCH`,
              });
            }}
            // data
            paramKey={`${CARD_SUPPLY_ORDER_HISTORY}_SEARCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SUPPLY_ORDER_HISTORY}_SEARCH`]}
            // config
            style={{ with: "100%", height: "100%" }}
            size="small"
            name="SEARCH"
            title="ค้นหา"
            color="blue"
          />
        }
        onClear={() => {
          patientRef.current?.clear?.();

          doctorRef.current?.clear?.();

          props.runSequence({
            sequence: "SupplyOrderHistory",
            action: "clear",
          });
        }}
      />
    </div>
  );
};

export default CardSupplyOrderHistory;
