import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Button
} from 'semantic-ui-react'

const CardSummaryWaitingQueueUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        
        <div
          style={{display:"flex",marginBottom:"10px"}}>
          {}
          <div
            style={{marginLeft:"10px",display:"flex",alignItems:"center"}}>
            คลินิก
          </div>
          <div
            style={{marginLeft:"10px",width:"200px"}}>
            {props?.divisionSearch}
          </div>
          <div
            style={{marginLeft:"10px",display:"flex",alignItems:"center"}}>
            วันที่รักษา
          </div>
          <div
            style={{marginLeft:"10px"}}>
            
            <DateTextBox
              onChange={props.changeDate("startDate")}
              value={props.startDate}>
            </DateTextBox>
          </div>
          <div
            style={{marginLeft:"10px",display:"flex",alignItems:"center"}}>
            ถึง
          </div>
          <div
            style={{marginLeft:"10px"}}>
            
            <DateTextBox
              onChange={props.changeDate("endDate")}
              value={props.endDate}>
            </DateTextBox>
          </div>
          <div
            style={{flex:"1"}}>
            
          </div>
          <Button
            color="blue"
            onClick={props.OnSearch}>
            ค้นหา
          </Button>
          <div>
            {props.buttonExportCSV}
          </div>
        </div>
        <div>
          
          <Table
            data={props?.dataSummaryQueue}
            headers="คลินิก,ระยะเวลาเฉลี่ยลงทะเบียน ถึง เข้าห้องพบแพทย์ (นาที), ระยะเวลาเฉลี่ยเข้าห้องแพทย์ ถึง ออกจากห้องเเพทย์ (นาที), ระยะเวลาเฉลี่ยออกห้องแพทย์ - ชำระเงินเสร็จสิ้น (นาที)"
            keys="division__name,avg_check_in,avg_check_out,avg_paid_timg"
            minRows="15"
            showPagination={false}
            style={{height: "600px"}}>
          </Table>
        </div>
      </div>
    )
}

export default CardSummaryWaitingQueueUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Thu Aug 25 2022 10:24:29 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginBottom:\"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.dataSummaryQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "คลินิก,ระยะเวลาเฉลี่ยลงทะเบียน ถึง เข้าห้องพบแพทย์ (นาที), ระยะเวลาเฉลี่ยเข้าห้องแพทย์ ถึง ออกจากห้องเเพทย์ (นาที), ระยะเวลาเฉลี่ยออกห้องแพทย์ - ชำระเงินเสร็จสิ้น (นาที)"
        },
        "keys": {
          "type": "value",
          "value": "division__name,avg_check_in,avg_check_out,avg_paid_timg"
        },
        "minRows": {
          "type": "value",
          "value": "15"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"600px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิก"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.divisionSearch"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",width:\"200px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่รักษา"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 8,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"startDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 11,
      "name": "DateTextBox",
      "parent": 10,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"endDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnSearch"
        },
        "size": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonExportCSV"
        }
      },
      "seq": 15,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSummaryWaitingQueueUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
