import React, { CSSProperties, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { Icon, Input, InputProps } from "semantic-ui-react";

// Styles
const styles = {
  searchInput: { margin: "0.35rem 0.25rem" } as CSSProperties,
};

const Tab = (props: any) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showTab, setShowTab] = useState(false);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (props.data?.length > 0) {
      // if (props.selectedTab?.key) {
      //   console.log("!! Tab have key");
      //   let idx = props?.data?.findIndex(
      //     (item: any) => item.key === props.selectedTab?.key
      //   );
      //   console.log("!! Tab idx: ", idx);
      //   // setSelectedTabIndex(idx);
      // } else {
      if (Number.isInteger(props.selectedTabIndex)) {
        setSelectedTabIndex(props.selectedTabIndex);
      }
      // }
    }
  }, [props.data, props.selectedTabIndex]);

  // useImperativeHandle(ref, () => ({
  //   getMedReconcileIndex: () => {
  //     return
  //   },
  // }));
  useEffect(() => {
    // console.log(" find MedReconcile Index Tab ");
    const medReconcileIdx = props?.data?.findIndex((item: any) => item.name === "Med Reconcile");
    // console.log("find MedReconcile Index  idx ", idx);
    // console.log('props.medReconcileIndex: ', props.medReconcileIndex);

    if (medReconcileIdx !== props.medReconcileIndex && props.setProp) {
      props.setProp("medReconcileIndex", medReconcileIdx);
    }
  }, [props.data, props.medReconcileIndex]);

  const getTextContent = useCallback((name: React.ReactElement | string): string => {
    const extractText = (node: React.ReactNode): string => {
      if (typeof node === "string") {
        return node;
      }

      if (React.isValidElement(node)) {
        const children = React.Children.toArray(node.props.children);

        return children.map((child) => extractText(child)).join("");
      }

      return "";
    };

    return extractText(name);
  }, []);

  const handleTabClick = useCallback(
    (key: string) => {
      const data: Record<string, any>[] = props.data || [];

      if (props.disabled) {
        return;
      }

      if (props.mobileScreen) {
        setShowTab(!showTab);
      }

      const index = data.findIndex((item) => item.key === key);

      setSelectedTabIndex(index);

      props?.onTabChange?.(index);
    },
    [props.data, props.disabled, props.mobileScreen, props.onTabChange, showTab]
  );

  const handleChangeSearch = useCallback((e: SyntheticEvent, data: InputProps) => {
    setSearchText(data.value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearchText("");
  }, []);

  const filteredData = useMemo(() => {
    const data: Record<string, any>[] = props.data || [];

    return data.filter((item) =>
      getTextContent(item.name).toLowerCase().includes(searchText.toLowerCase())
    );
  }, [props.data, searchText]);

  const inputIcon = useMemo(
    () => (searchText ? <Icon name="close" link onClick={handleClearSearch} /> : "search"),
    [searchText]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: props.mobileScreen ? "column" : "row",
        height: props.mobileScreen ? "auto" : "100%",
        width: "100%",
      }}
    >
      <div
        className="tab-menu --framework"
        style={{
          width: props.mobileScreen ? "100%" : props.tabWidth || "250px",
          maxHeight: "95vh",
          display: "flex",
          flexDirection: "column",
          border: "solid #cccccc 1px",
          overflowY: "scroll",
          marginBottom: props.marginBottom || "0px",
          marginLeft: props.hideTabMenu ? `-${props.tabWidth || "250px"}` : 0,
          transition: "margin-left 0.15s ease 0s",
        }}
      >
        {props.showSearchInput && (
          <Input
            autoComplete="off"
            icon={inputIcon}
            placeholder="Search menu"
            size="small"
            style={styles.searchInput}
            value={searchText}
            fluid
            onChange={handleChangeSearch}
          />
        )}
        {filteredData.map((item: any, index: number) => {
          const showTabContent =
            !props.mobileScreen || showTab || (!showTab && selectedTabIndex === index);

          return (
            showTabContent && (
              <div
                id={`tab-${item.key}`}
                key={index}
                style={{
                  backgroundColor:
                    props.data[selectedTabIndex]?.key === item.key ? "#d6ecf3" : "white",
                  padding: "5px",
                  cursor: "pointer",
                }}
                onClick={() => handleTabClick(item.key)}
              >
                {item.name}
              </div>
            )
          );
        })}
      </div>
      <div
        style={{
          flex: 1,
          maxHeight: "calc(100dvh - 60px)",
          overflowY: "auto",
          width: props.contentWidth,
        }}
      >
        {props?.data[selectedTabIndex]?.render() || ""}
      </div>
    </div>
  );
};

export default Tab;
