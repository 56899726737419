import moment from "moment";
import { formatDate, formatISOtoHHmm } from "react-lib/utils/dateUtils";
import ComponyLogoForm from "../HISV3/common/CompanyLogoForm";
import CONFIG from "config/config";

const FORM_NAME = `FormChairBooking`;

const FormChairBooking = async (props: any) => {
  console.log(`FormChairBooking Props: `, props);

  const companyLogoForm = await ComponyLogoForm({
    font: "THSarabunNew",
    height: 30,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  const generateTableBody = (data: any, columns: any) => {
    let body: any = [];

    // Header of Table
    body.push([
      { text: "ลำดับ", bold: true, alignment: "center" },
      { text: "เก้าอี้", bold: true, alignment: "center" },
      { text: "ชื่อนิสิต", bold: true, alignment: "center" },
      { text: "เวลา", bold: true, alignment: "center" },
      { text: "HN", bold: true, alignment: "center" },
      { text: "ชื่อผู้ป่วย", bold: true, alignment: "center" },
      { text: "เบอร์โทรติดต่อ", bold: true, alignment: "center" },
      { text: "งานที่ทำ", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any, index: number) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          switch (column) {
            case "seq":
              dataRow.push({ text: (index+1).toString() });
              break;
            case "period":
              dataRow.push({
                text: row["dsb"].doctor_start_time + ` - ` + row["dsb"].doctor_end_time,
              });
              break;
            case "patient_hn":
              if (row["service_appointments"].length > 0) {
                row["service_appointments"].forEach((subItem: any) => {
                  dataRow.push({ text: subItem.patient_hn });
                });
              } else {
                dataRow.push({ text: "-" });
              }
              break;
            case "patient_name":
              if (row["service_appointments"].length > 0) {
                row["service_appointments"].forEach((subItem: any) => {
                  dataRow.push({
                    text:
                      subItem.patient_pre_name +
                      subItem.patient_first_name +
                      ` ` +
                      subItem.patient_last_name,
                  });
                });
              } else {
                dataRow.push({ text: "-" });
              }
              break;
            case "patient_tel":
              if (row["service_appointments"].length > 0) {
                row["service_appointments"].forEach((subItem: any) => {
                  dataRow.push({ text: subItem.patient_tel != null ? subItem.patient_tel : "-" });
                });
              } else {
                dataRow.push({ text: "-" });
              }
              break;
            case "task":
              if (row["service_appointments"].length > 0) {
                row["service_appointments"].forEach((subItem: any) => {
                  console.log(`FormChairBooking SubItem: `, subItem);
                  dataRow.push({ text: subItem.extra ? subItem.extra?.jobToDo : "-" });
                });
              } else {
                dataRow.push({ text: "-" });
              }
              break;
            default:
              if (row[column] !== null && row[column] !== undefined) {
                dataRow.push({ text: row[column].toString() });
              } else {
                dataRow.push({ text: "-" });
              }
              break;
          }
        });
        body.push(dataRow);
      });
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["auto", "auto", "15%", "8%", "auto", "15%", "auto", "*"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };

  return {
    header: (currentPage: any, pageCount: any) => {
      return {
        table: {
          widths: ["39%", "43%", "18%"],
          body: [
            [
              {
                columns: [
                  ...(!CONFIG.HIDE_COMPANY_LOGO_PRINT
                    ? [
                        {
                          image: "logo",
                          width: 80,
                          height: 30,
                        },
                      ]
                    : []),
                  {
                    stack: [
                      ...(!CONFIG.HIDE_COMPANY_LOGO_PRINT
                        ? [
                            {
                              text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                              fontSize: 12,
                              bold: true,
                            },
                            {
                              text: "Chulalongkorn University Faculty of Dentistry Hospital",
                              fontSize: 12,
                              bold: true,
                            },
                          ]
                        : []),
                    ],
                  },
                ],
                // ...companyLogoForm,
                border: [true, true, false, true],
              },
              {
                stack: [
                  {
                    text: "รายงานการจองเก้าอี้",
                    fontSize: 15,
                    bold: true,
                    alignment: "center",
                  },
                  {
                    text: props.division_name,
                    fontSize: 15,
                    bold: true,
                    alignment: "center",
                  },
                  {
                    text: `${props.date} (${props.period})`,
                    fontSize: 15,
                    alignment: "center",
                  },
                ],
                border: [false, true, false, true],
              },
              {
                stack: [
                  {
                    text: `ผู้พิมพ์ : ${props.staff}`,
                    fontSize: 15,
                    alignment: "right",
                  },
                  {
                    text: `วันที่พิมพ์ : ${formatDate(moment())} [${moment().format("HH:mm")}]`,
                    fontSize: 15,
                    alignment: "right",
                  },
                  {
                    text: `หน้า ` + currentPage.toString() + `/` + pageCount.toString(),
                    fontSize: 15,
                    alignment: "right",
                  },
                ],
                border: [false, true, true, true],
              },
            ],
          ],
        },
        layout: {
          paddingLeft: (i: any, node: any) => {
            return 10;
          },
          paddingRight: (i: any, node: any) => {
            return 10;
          },
          paddingTop: (i: any, node: any) => {
            return 5;
          },
          paddingBottom: (i: any, node: any) => {
            return 5;
          },
        },
        margin: [15, 15, 15, 0],
      };
    },
    content: [
      tableReport(props.data || [], [
        "seq",
        "chair_name",
        "doctor_provider_name",
        "period",
        "patient_hn",
        "patient_name",
        "patient_tel",
        "task",
      ]),
    ],
    pageSizes: "A4",
    pageOrientation: "landscape",
    pageMargins: [15, 100, 15, 15],
    defaultStyle: {
      font: font,
      lineHeight: 1,
      // fontSize: 12, // default of font size
    },
    images: {
      ...images,
    },
  };
};

export default FormChairBooking;
