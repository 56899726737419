import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Checkbox, Form, Input } from "semantic-ui-react";

const styles = {
  rightAlign: {
    marginRight: "0px",
    marginLeft:"auto"
  },
  rightMargin: {
    marginRight: "-25px",
  },
};

export interface CardMuscleToneViewDataType {
  data: {
    isNormal: boolean;
    normal: string;
    isSpasticity: boolean;
    spasticity: string;
    isFlaccid: boolean;
    flaccid: string;
    isNA: boolean;
  };
  readonly: boolean;
}

const CardMuscleTone: FC<CardMuscleToneViewDataType> = forwardRef(
  (props, ref) => {
    const [muscleTone, setMuscleTone] = useState<any>(defaultData);

    useEffect(() => {
      setMuscleTone(props.data);
    }, [props.data]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        return muscleTone;
      },
    }));

    const handleChangeMuscleTone = (event, data) => {
      if (!props.readonly) {
        if (data.type === "checkbox") {
          if (data.checked) {
            setMuscleTone({
              ...muscleTone,
              isNA: false,
              [data.name]: data.checked,
            });
          } else {
            setMuscleTone({ ...muscleTone, [data.name]: data.checked });
          }
        } else {
          setMuscleTone({ ...muscleTone, [data.name]: data.value });
        }
      }
    };

    const handleChangeMuscleToneNA = (event, data) => {
      if (!props.readonly) {
        if (data.checked) {
          setMuscleTone({
            ...muscleTone,
            isNormal: false,
            isSpasticity: false,
            isFlaccid: false,
            isNA: true,
          });
        } else {
          setMuscleTone({ ...muscleTone, [data.name]: data.checked });
        }
      }
    };

    return (
      <Form>
        <Form.Group inline>
          <Form.Field width={2} style={styles.rightMargin}>
            <Checkbox
              disabled={muscleTone.isNA}
              label="Normal"
              name="isNormal"
              readOnly={props.readonly}
              checked={muscleTone.isNormal}
              onChange={handleChangeMuscleTone}
            />
          </Form.Field>
          <Form.Field width={3}>
            <Input
              disabled={!muscleTone.isNormal}
              name="normal"
              readOnly={props.readonly}
              value={muscleTone.normal}
              onChange={handleChangeMuscleTone}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Checkbox
              style={styles.rightAlign}
              disabled={muscleTone.isNA}
              label="Spasticity"
              name="isSpasticity"
              readOnly={props.readonly}
              checked={muscleTone.isSpasticity}
              onChange={handleChangeMuscleTone}
            />
          </Form.Field>
          <Form.Field width={3}>
            <Input
              disabled={!muscleTone.isSpasticity}
              name="spasticity"
              readOnly={props.readonly}
              value={muscleTone.spasticity}
              onChange={handleChangeMuscleTone}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Checkbox
              style={styles.rightAlign}
              disabled={muscleTone.isNA}
              readOnly={props.readonly}
              label="Flaccid"
              name="isFlaccid"
              checked={muscleTone.isFlaccid}
              onChange={handleChangeMuscleTone}
            />
          </Form.Field>
          <Form.Field width={3}>
            <Input
              disabled={!muscleTone.isFlaccid}
              readOnly={props.readonly}
              name="flaccid"
              value={muscleTone.flaccid}
              onChange={handleChangeMuscleTone}
            />
          </Form.Field>
          <Form.Field width={1}>
            <Checkbox
              style={styles.rightAlign}
              label="N/A"
              name="isNA"
              checked={muscleTone.isNA}
              readOnly={props.readonly}
              onChange={handleChangeMuscleToneNA}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
);

const defaultData = {
  isNormal: true,
  normal: "",
  isSpasticity: false,
  spasticity: "",
  isFlaccid: false,
  flaccid: "",
  isNA: false,
};

export const CardMuscleToneInitialViewData: CardMuscleToneViewDataType = {
  data: defaultData,
  readonly: false
};

CardMuscleTone.defaultProps = CardMuscleToneInitialViewData;

export default CardMuscleTone;
