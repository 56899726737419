import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { Button, Form, Radio, Dropdown, Input, Icon } from "semantic-ui-react";
import _ from "react-lib/compat/lodashplus";

import * as CONSTANT from "react-lib/utils/constant";
import CardLayout from "react-lib/apps/common/CardLayout";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";
import { SearchBox } from "react-lib/apps/common";
import ModMedReconcileAlert from "../common/ModMedReconcileAlert";

type CardDischargeCNMIProps = {
  controller: any;// PropTypes.object,
  DJANGO: any;// PropTypes.object,
  PATIENT_DATA: any;// PropTypes.object,
  isIPD: boolean;// PropTypes.bool,
  require_diagnosis: boolean;// PropTypes.bool,
  onDischarged: any;// PropTypes.func,
  onClose: any;// PropTypes.func,
  doctorOptions: any;// PropTypes.object,
  encounterInfo: any;// PropTypes.object,
  doctorRef: any;// PropTypes.object,
  onEvent: any;// PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  setProp: any;// PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  dischargeDoctor: any;// PropTypes.object,
  userId: any;// PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  userFullname: string;// PropTypes.string,
  approve_by: any;// PropTypes.number,
  loginVia: boolean;// PropTypes.bool,
  isCUDent: boolean;// PropTypes.bool,
  hideSupervisorPassword: boolean;// PropTypes.bool,
  onCheckout: any;// PropTypes.func,
  errorMessage: any;// PropTypes.any,
  titleText: string;// PropTypes.string,
  closeable: boolean;// PropTypes.bool,
  checkMedicalHistory: boolean;// PropTypes.bool,
  medReconcileIndex: number;// PropTypes.number,
  searchValueField: string;// PropTypes.string,
  hideZone: boolean;// PropTypes.bool,
}

const CardDischarge = (props: CardDischargeCNMIProps) => {

	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState<any>(null);

	const [openModalChangeZone, setOpenModalChangeZone] = useState(false);
	const [showPassword, setShowPassword] = useState<boolean>(false)

	const [targetOrder, setTargetOrder] = useState("");
	const [choices, setChoices] = useState<any[]>([]);
	const [targetZone, setTargetZone] = useState<any>(null);
	const [zones, setZones] = useState<any[]>([]);
	const [modMedReconcile, setModMedReconcile] = useState<any>({open: false, message: "", med_reconcile_id: null})

	const isInitiated = useRef(false);

	const initialZone = async (divisionId: number) => {
		const [data, error] = await props.controller.getZone(divisionId);
		setZones([DEFAULT_ZONE, ...data])
	}

	const initialCheckoutCause = async () => {
		const [data, error] = await props.controller.getChoicesCheckout();
		if (error) {
			console.log("Error: ", error);
		}

		let hasProgressionCycle = props.controller.hasProgressionCycle
		let dValue = hasProgressionCycle ? CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT : CONSTANT.CHECKOUT_CAUSE.BY_APPROVAL;
		let choices = data.filter(function (item: any) {
			// Set enable/disable
			if (item.value === CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT && !hasProgressionCycle) {
				item.disabled = true;
			} else {
				item.disabled = false;
			}
			// Filter item
			if (props.isIPD && HIDE_ON_IPD.includes(item.value)) {
				return false;
			}
			if (!props.isIPD && HIDE_ON_OPD.includes(item.value)) {
				return false;
			}
			return true;
		});
		setTargetOrder(dValue);
		setChoices(choices);
		// setLoading(false);
	};

	const handleDoctorFeeOrder = async (emrId: number) => {
		const [hasWarning, message] = await props.controller.checkDoctorFeeOrder(emrId);
		if (hasWarning) {
			setErrorMessage({ "Warning DoctorFee": [message] });
		}
	};

	const discharge = async (checkoutCause: string) => {
		const division = Cookies.get("division_id");
		const [isSuccess, errorMessage] = await props.controller.discharge(props.PATIENT_DATA.EMR.emr_id, checkoutCause, division, props.require_diagnosis, props.approve_by);
		if (!isSuccess) {
			if(errorMessage && Array.isArray(errorMessage) && errorMessage?.length > 0 && errorMessage[0].includes("Med Reconcile")){
				setModMedReconcile({
					open: true,
					message: errorMessage[0],
					med_reconcile_id: errorMessage[0].slice(errorMessage[0].indexOf("[")+1, errorMessage[0].indexOf("]"))
				})

				props.setProp("loginVia", false);
				return;
			}else{
				setErrorMessage(errorMessage);
				props.setProp("loginVia", false);
				return;
			}
		}
		props.setProp('loginVia', false);
		setErrorMessage(null);
		props.onDischarged(checkoutCause);
	};

	/**
	 * Handle "Radio"
	 * @param {*} e
	 * @param {*} param1
	 */
	const handleChanged = (e: any, { value }: any) => {
		setTargetOrder(value);
	};

	/**
	 * Handle "Dropdown" of division's zone
	 * @param {*} e
	 * @param {*} data
	 */
	const handleZoneSelected = (e: any, { value }: any) => {
		if (value != DEFAULT_ZONE.value) {
			for (var item of zones) {
				if (item.value === value) {
					setTargetZone(item)
					break;
				}
			}
		} else {
			setTargetZone(null)
		}
	}

	/**
	 *
	 * @param {*} zoneId
	 */
	const handleChangeZone = async () => {
		if (!targetZone) {
			return;
		}
		if (targetZone.value === DEFAULT_ZONE.value) {
			return;
		}

		let encounterId = props.PATIENT_DATA.ENCOUNTER.encounter_id;
		let zoneId = targetZone.id;
		let isSuccess = await props.controller.changeZone(encounterId, zoneId);

		// Close modal
		setOpenModalChangeZone(false);
		if (isSuccess) {
			// Discharge
			let checkoutCause = targetOrder;
			discharge(checkoutCause);
		}
	}

	/**
	 * Handle "Check out" Button
	 */
	const handleCUDentCheckout = async () => {
		if (props.approve_by === null) {
			setErrorMessage("กรุณาเลือก Dentist/Instructor");
			return;
		}

		if (props.dischargeDoctor?.password === "" && (props.userId != props.dischargeDoctor.userId)) {
			setErrorMessage("กรุณาระบุรหัสผ่าน");
			return;
		}

		setErrorMessage(null)

		setLoading(true)

		await props.onEvent({
      message: "CheckUserLogin",
      params: {
        username: props.dischargeDoctor?.username,
        password: props.dischargeDoctor?.password,
        checkMedicalHistory: props.checkMedicalHistory,
				userId: props.dischargeDoctor?.userId,
        bypass: !!props.dischargeDoctor?.bypass,
      },
    });

		setLoading(false);
	};

	const handleCheckoutSave = async () => {
		var checkoutCause = targetOrder;
		if (checkoutCause === CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT) {
			if (targetZone && (targetZone.value !== DEFAULT_ZONE.value)) {
				setOpenModalChangeZone(true);
				return;
			}
		}
		// Discharge
		discharge(checkoutCause);
	}

	const handleCheckoutSaveCase = async () => {
		if (props.onCheckout) {
			props.onCheckout({
        emr_id: props.PATIENT_DATA.EMR.emr_id,
        checkout_cause: targetOrder,
        require_diagnosis: props.require_diagnosis,
        approve_by: props.approve_by,
      });
		}
		else if (props.isCUDent) {
			handleCUDentCheckout();
		} else {
			handleCheckoutSave();
		}
	}

	const generateOption = (option: any) => {
		return (
			<Radio
				disabled={option.disabled}
				label={option.text}
				name={option.value}
				value={option.value}
				checked={targetOrder === option.value}
				onChange={handleChanged.bind(this)}
			/>
		);
	};

	const generateRadioList = () => {
		return choices.map((item, index) => <Form.Field key={item.id}>{generateOption(item)}</Form.Field>);
	};

	const handleGetSearchOptions = async ({ searchText = "" }) => {
    return await props.onEvent({
      message: "GetDoctorSearch",
      params: { search: searchText, exclude_student: true },
    });
  };

	const handleOnSelectedDoctor = async ({ item, id }: any) => {
		await props.setProp(`approve_by`, id);
		await props.onEvent({
      message: "GetUsernameForDischarge",
      params: { pk: item.user, checkBypass: true, doctor: item.id, },
    });
	};

	const handleSetPassword = (e: any, { value }: any) => {
		props.setProp(`dischargeDoctor`, { ...props.dischargeDoctor, password: value });
	};

	useEffect(() => {
		return () => {
      isInitiated.current = false;
    };
	}, []);

	useEffect(() => {
		if (
      props.controller &&
      props.PATIENT_DATA &&
      props.DJANGO &&
      !isInitiated.current
    ) {
      isInitiated.current = true;

      initialCheckoutCause();
      initialZone(props.DJANGO.division.id);
      handleDoctorFeeOrder(props.PATIENT_DATA.EMR.emr_id);
    }
  }, [props.controller, props.PATIENT_DATA, props.DJANGO]);

  useEffect(() => {

    const initialDischargeDoctor =  async () => {
      setLoading(true);

      // setTimeout(async () => {
      // props.setProp(`approve_by`, props.doctorRef.current?.getId());

      const doctorId = props.encounterInfo?.doctor;

      await props.onEvent({
        message: "GetUsernameForDischarge",
        params: {
          ...(props.encounterInfo?.doctor && { doctor: doctorId }),
          ...(!props.encounterInfo?.doctor && props.userId && { pk: props.userId, doctor: doctorId }),
          checkBypass: true,
        },
      });

      setLoading(false);
    // })
    }

    if (props.userId) {
			initialDischargeDoctor()
    }

	}, []);

	useEffect(() => {
		// console.log("useEffect CardDischarge props.dischargeDoctor?.userId ", props.dischargeDoctor?.userId)
		if (props.dischargeDoctor?.userId) {
			props.setProp(`approve_by`, props.dischargeDoctor?.userId);
		}
	}, [props.dischargeDoctor?.userId])

	useEffect(() => {
		if (props.loginVia === true) {
			handleCheckoutSave();
		}
	}, [props.loginVia]);

	console.log("props.encounterInfo", props.encounterInfo, targetOrder);
	console.log("CardDischarge props: ", props);

	return (
    <CardLayout
      titleText={props.titleText ?? "Check Out"}
      headerColor="blue"
      loading={loading}
      toggleable={false}
      closeable={props.closeable}
      onClose={props.onClose}
    >
      {/* Handle error */}
      <ErrorMessage error={errorMessage || props.errorMessage} />

      {/* Confirm change zone */}
      <ModConfirmCNMI
        openModal={openModalChangeZone}
        size={"mini"}
        titleName={"ยืนยัน"}
        content={<p>ต้องการย้ายผู้ป่วยไปยัง {targetZone ? targetZone.text : "..."} ใช่หรือไม่</p>}
        onApprove={handleChangeZone}
        onDeny={() => {
          setOpenModalChangeZone(false);
        }}
        onCloseWithDimmerClick={() => {
          setOpenModalChangeZone(false);
        }}
      />

      {/* Checkout cause */}
      <Form style={{ marginLeft: "32px", marginRight: "32px" }}>
        {generateRadioList()}

        {/* Change zone */}
        {targetOrder === CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT && !props.hideZone && (
          <Form.Group inline style={{ marginTop: "16px" }}>
            <Form.Field disabled={zones.length === 1}>
              <label>ย้ายไปยัง zone</label>
            </Form.Field>
            <Form.Field width={6} disabled={zones.length === 1}>
              <Dropdown
                className="inline-label"
                fluid
                selection
                search
                options={zones}
                placeholder={"ไม่เลือก"}
                style={{ width: "100%" }}
                onChange={handleZoneSelected.bind(this)}
              />
            </Form.Field>
          </Form.Group>
        )}

        {/* Dentist/Instructor && password */}
        <Form>
          <Form.Group inline>
            {!props.hideSupervisorPassword && (
              <>
                <Form.Field width={7}>
                  <label style={{ fontWeight: "normal" }}>Dentist/Instructor</label>
                  <SearchBox
                    ref={props.doctorRef}
                    defaultId={props.dischargeDoctor?.userId}
                    defaultText={props.dischargeDoctor?.fullName}
                    defaultOptions={props.doctorOptions.items}
                    onGetSearchOptions={handleGetSearchOptions}
                    onSelectOption={handleOnSelectedDoctor}
                    textField="full_name"
                    valueField={props.searchValueField}
                    fluid={true}
                    contentStyle={{ padding: 0 }}
                    onGetInputText={() => {
                      if (props.dischargeDoctor?.bypass) {
                        props.setProp("dischargeDoctor.bypass", false);
                      }
                    }}
                  />
                </Form.Field>
                {typeof props.dischargeDoctor?.userId !== "undefined" &&
                  !props.dischargeDoctor?.bypass && (
                    <Form.Field width={6}>
                      <label style={{ paddingLeft: "1rem", fontWeight: "normal" }}>Password</label>
                      <Input
                        type={showPassword ? "" : "password"}
                        placeholder="Password"
                        onChange={handleSetPassword}
                        style={{ marginLeft: "5px" }}
                        value={props.dischargeDoctor?.password}
                        icon={
                          <Icon
                            name={showPassword ? "eye" : "eye slash"}
                            link={true}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        }
                      />
                    </Form.Field>
                  )}
              </>
            )}

            <Form.Field style={{ flex: 1 }}></Form.Field>
            <Form.Field>
              {/* Checkout button */}
              <Button
                color={"green"}
                style={{ minWidth: "max-content" }}
                onClick={handleCheckoutSaveCase.bind(this)}
              >
                Check out
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Form>

      {/* Mod MedReconcile */}
      <ModMedReconcileAlert
        medReconcileId={modMedReconcile.med_reconcile_id}
        open={modMedReconcile.open}
        onApprove={() => {
          props.onEvent({
            message: "OpenMedReconcileFromWarning",
            params: {
              medReconcileCheck: { med_reconciliation: modMedReconcile.med_reconcile_id },
              selectedRecordViewIndex: props.medReconcileIndex,
            },
          });

          setModMedReconcile({ open: false, message: "", med_reconcile_id: null });
          props.onClose?.();

          // find med reconcile Index
          // if (props.medReconcileIndex !== -1) {
          //   props.setProp("selectedRecordViewIndex", props.medReconcileIndex)
          // }
        }}
      >
        {modMedReconcile.message}
      </ModMedReconcileAlert>
    </CardLayout>
  );
};

const HIDE_ON_IPD = [CONSTANT.CHECKOUT_CAUSE.ADMIT, CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT];

const HIDE_ON_OPD = [CONSTANT.CHECKOUT_CAUSE.DISAPPEAR];

const DEFAULT_ZONE = {
	id: 0,
	text: "ไม่เลือก",
	value: "NOT_SELECTED"
}

CardDischarge.defaultProps = {
  controller: null,
  DJANGO: null,
  PATIENT_DATA: null,
  isIPD: false,
  require_diagnosis: true,
  onDischarged: (checkoutCause: string) => {},
  onClose: () => {},
  doctorOptions: null,
  encounterInfo: null,
  doctorRef: null,
  onEvent: null,
  setProp: null,
  dischargeDoctor: null,
  userId: null,
  userFullname: '',
  approve_by: null,
  loginVia: false,
  isCUDent: false,
  hideSupervisorPassword: false,
  onCheckout: null,
  errorMessage: null,
  closeable: true,
	hideZone: false,
};

// CardDischarge.propTypes = {
//   controller: PropTypes.object,
//   DJANGO: PropTypes.object,
//   PATIENT_DATA: PropTypes.object,
//   isIPD: PropTypes.bool,
//   require_diagnosis: PropTypes.bool,
//   onDischarged: PropTypes.func,
//   onClose: PropTypes.func,
//   doctorOptions: PropTypes.object,
//   encounterInfo: PropTypes.object,
//   doctorRef: PropTypes.object,
//   onEvent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
//   setProp: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
//   dischargeDoctor: PropTypes.object,
//   userId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
//   userFullname: PropTypes.string,
//   approve_by: PropTypes.number,
//   loginVia: PropTypes.bool,
//   isCUDent: PropTypes.bool,
//   hideSupervisorPassword: PropTypes.bool,
//   onCheckout: PropTypes.func,
//   errorMessage: PropTypes.any,
//   titleText: PropTypes.string,
//   closeable: PropTypes.bool,
//   checkMedicalHistory: PropTypes.bool,
//   medReconcileIndex: PropTypes.number,
//   searchValueField: PropTypes.string,
//   hideZone: PropTypes.bool,
// };

export default CardDischarge;
