import config from "config/config";
import React, { CSSProperties, useState, useEffect, useMemo } from "react";
// CSS
import { Icon, SemanticICONS } from "semantic-ui-react";

// Interface
import {
  Event as MobEvent,
  State as MobState,
} from "../../../../patient-lib/MobTransformInterface";

type TFBottomNavigationProps = {
  onEvent: (e: MobEvent) => any;
  history: any;
  activeItem: "profile" | "appointment" | "chat" | "payment" | "other" | "shippinglist";
} & Pick<MobState, "initialInnerScreen">;

const ICON = {
  chat: "/images/tuh/chat-icon.png",
  qrscanner: "/images/tuh/icon-qrscanner.svg",

};

const NAVIGATION_LIST = [
  {
    name: "โปรไฟล์",
    icon: "user" as SemanticICONS,
    route: "profile",
  },
  {
    name: "นัดหมาย",
    icon: "calendar alternate" as SemanticICONS,
    route: "appointment",
  },
  {
    name: "แสกน",
    src: ICON.qrscanner,
    route: "ConfirmShipping",
  },
  {
    name: "รับยา",
    svg: <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.92 24.5285L13.52 26.5555C12.4502 28.0237 10.8462 29.0127 9.0539 29.3091C7.2616 29.6056 5.42459 29.1858 3.93899 28.1402C2.45338 27.0947 1.4382 25.5071 1.11233 23.7199C0.786467 21.9328 1.17603 20.0891 2.19702 18.5865L7.37002 11.2465L12.539 3.90652C13.6005 2.41658 15.2084 1.40726 17.0116 1.09902C18.8148 0.790786 20.6668 1.20868 22.1629 2.26143C23.659 3.31418 24.6776 4.91622 24.9964 6.7176C25.3151 8.51899 24.908 10.3733 23.864 11.8755L22.885 13.2755" stroke="#0147A3" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
    <path d="M7.37 11.2465L15.759 17.1885" stroke="#0147A3" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
    <path d="M23.38 30.4716C28.1291 30.4716 31.979 26.6217 31.979 21.8726C31.979 17.1235 28.1291 13.2736 23.38 13.2736C18.6309 13.2736 14.781 17.1235 14.781 21.8726C14.781 26.6217 18.6309 30.4716 23.38 30.4716Z" stroke="#0147A3" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
    <path d="M14.781 21.8726H31.908" stroke="#0147A3" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
    </svg>,
    route: "shippinglist",
  },
  // {
  //   name: "การชำระเงิน",
  //   icon: "payment" as SemanticICONS,
  //   route: "payment",
  // },
  // {
  //   name: "อื่นๆ",
  //   icon: "ellipsis horizontal" as SemanticICONS,
  //   route: "other",
  // },
];

const TFBottomNavigation: React.FunctionComponent<TFBottomNavigationProps> = (
  props
) => {
  const [activeMenu, setActiveMenu] = useState<string>("");

  const navigation = useMemo(()=>{
    const configTELEPHAR:any[] = config.ENABLE_ISHEALTH_TELEPHAR? []:["shippinglist", "ConfirmShipping"]
    const navigation = NAVIGATION_LIST.filter((item:any)=> !configTELEPHAR.includes(item.route) )
    const number = Math.floor(navigation.length/2)
    const chart =  {
      name: "แชท",
      src: ICON.chat,
      route: "chat",
    }
    if (navigation.find((item:any)=> item.route === "chat") == null) {
      navigation.splice(number,0,chart)
    }
    return navigation
  },[NAVIGATION_LIST,config.ENABLE_ISHEALTH_TELEPHAR])

  useEffect(() => {
    setActiveMenu(props.activeItem);
  }, [props.activeItem]);

  useEffect(() => {
    function updateSize() {
      console.log(props.initialInnerScreen, window.innerWidth,window.innerHeight);
      if (window.innerWidth !== props.initialInnerScreen?.width) {
        props.onEvent({
          message: "HandleSetInitialInnerScreen",
          params: {
            height: window.innerHeight,
            width: window.innerWidth,
          },
        });
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [props.initialInnerScreen]);

  useEffect(() => {
    const handleOrientationchange = () => {
      console.log("orientation",window.orientation ,window.innerWidth, window.innerHeight);
      props.onEvent({
        message: "HandleSetInitialInnerScreen",
        params: {
          height: window.innerHeight,
          width: window.innerWidth,
        },
      });
    };
    window.addEventListener("orientationchange", handleOrientationchange);
    return () =>
      window.removeEventListener("orientationchange", handleOrientationchange);
  }, [])
 

  /* ----------------------- Handle ----------------------- */
  const handleClick = (route: string) => {
    if (route !== activeMenu) {
      props.history.push(`/tuh-transform/${route}`);
    } 
    setActiveMenu(route);
  };

  return (
    <div
      className="box-navigation"
      style={{ top: `${(props.initialInnerScreen?.height || 0) - 54}px` }}
    >
      {navigation.map((item, index) =>(
        <div
          key={"menu-" + index}
          className={`${item.route === "chat" ? "main-menu" : `${item.route === "shippinglist" ? "icon-svg" : ""}` }${
            item.route === activeMenu ? " active" : ""
          }`}
          onClick={() => handleClick(item.route)}
        >
          <div>
            {item.icon ? (
              <Icon name={item.icon} />
            ) : item.src ? (
              <img src={item.src} alt={`${item.route}.icon`} />
            ) : <div className="iconsvg">
              {(item.svg) }
            </div>
          
          }
          </div>
          <div>{item.name}</div>
        </div>
      ))}
    </div>
  );
};

TFBottomNavigation.defaultProps = {
  history: {},
} as TFBottomNavigationProps;

export default React.memo(TFBottomNavigation);
