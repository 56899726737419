import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataDetailByEncounter from "issara-sdk/apis/FormDataDetailByEncounter_apps_PTM";
import moment from "moment";
import WasmController from "react-lib/frameworks/WasmController";
import { beToAd, formatDate } from "react-lib/utils";
import { fallRiskNursingOptions, nursingCareOptions } from "react-lib/apps/HISV3/ADM/sequence/PreAssessment";
// apis

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  masterOptions?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}

  // common
  selectedEncounter?: any;

  // sequence
  ReAssessmentSequence?: {
    sequenceIndex?: string | null;
    id?: number | null;
    // data
    sleepingEnough?: string;
    sleepingPerDay?: string;
    sleepingProblem?: string;
    sleepingProblemRemark?: string;
    // data
    painData?: {
      saveData?: any;
      saveTime?: any;
    };
    riskFallData?: {
      saveData?: any;
      saveTime?: any;
    };
    sleepData?: {
      saveData?: any;
      saveTime?: any;
    };
    // history
    painDataHistory?: {
      startDate?: any;
      endDate?: any;
      data?: any;
    };
    riskFallDataHistory?: {
      startDate?: any;
      endDate?: any;
      data?: any;
    };
    sleepDataHistory?: {
      startDate?: any;
      endDate?: any;
      data?: any;
    };

    painStatus?: any;
    assessPainLevel?: any;
    painLevel?: any;
    painArea?: any;
    painNature?: any;
    sensesNursingCare?: any;
    riskFall?: any;
    riskFallRemark?: any;
    nursingCare?: any;
    sleepPill?: any;
    sleepPillRemark?: any;

    reAssessmentPainID?: any;
    reAssessmentRiskFallIPD?: any;
    reAssessmentSleepIPD?: any;
  } | null;

  // options
  reAssessmentOptions?: {
    painRiskFallOptions?: any[];
    painNursingCareOptions?: any[];
    sensesNursingCareOptions?: any[];
    painLevel?: any[];
  };
};

export const StateInitial: State = {
  // sequence
  ReAssessmentSequence: {
    sequenceIndex: null,
    id: null,
    // data
    sleepingEnough: "",
    sleepingPerDay: "",
    sleepingProblem: "",
    sleepingProblemRemark: "",
    painDataHistory: {
      startDate: "",
      endDate: "",
      data: [],
    },
    riskFallDataHistory: {
      startDate: "",
      endDate: "",
      data: [],
    },
    sleepDataHistory: {
      startDate: "",
      endDate: "",
      data: [],
    },
  },

  // options
  reAssessmentOptions: {
    painRiskFallOptions: [],
    painNursingCareOptions: [],
    sensesNursingCareOptions: [],
    painLevel: [],
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  masterData?: { [name: string]: any };
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  var state = controller.getState();
  // Options
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["education", {}],
        ["maritalStatus", {}],
        ["religion", {}],
        ["career", {}],
        ["race", {}],
        ["nationality", {}],
        ["pain", {}],
        ["location", {}],
        ["characteristic", {}],
      ],
    },
  });

  const [fetchPain, fetchRiskFall, fetchSleep] = await Promise.allSettled([
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        form_code: "CardReAssessmentPainIPD",
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    }),
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        form_code: "CardReAssessmentRiskFallIPD",
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    }),
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        form_code: "CardReAssessmentSleepIPD",
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    }),
  ]);

  // Promise.allSettled return {status, value}
  let fetchPainIPD = fetchPain.value;
  let fetchRiskFallIPD = fetchRiskFall.value;
  let fetchSleepIPD = fetchSleep.value;

  const toDay = moment();
  const formatDateToDay = formatDate(toDay);
  const sevenDaysAgo = formatDate(toDay.clone().subtract(7, "days"));
  const timeToDay = toDay.format("HH:mm");

  controller.setState({
    ReAssessmentSequence: {
      sequenceIndex: "AddOrEdit",
      reAssessmentPainID: fetchPainIPD?.[0]?.pk,
      reAssessmentRiskFallIPD: fetchRiskFallIPD?.[0]?.pk,
      reAssessmentSleepIPD: fetchSleepIPD?.[0]?.pk,

      sleepingPerDay: "",
      sleepingEnough: "",
      sleepingProblem: "",
      sleepingProblemRemark: "",
      sleepPill: "",
      sleepPillRemark: "",

      riskFall: "",
      riskFallRemark: "",
      nursingCare: "",

      painStatus: "",
      assessPainLevel: "",
      painLevel: "",
      painArea: "",
      painNature: "",
      sensesNursingCare: "",
      painData: {
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      riskFallData: {
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      sleepData: {
        saveData: formatDateToDay,
        saveTime: timeToDay,
      },
      // history
      painDataHistory: {
        startDate: sevenDaysAgo,
        endDate: formatDateToDay,
        data: [],
      },
      riskFallDataHistory: {
        startDate: sevenDaysAgo,
        endDate: formatDateToDay,
        data: [],
      },
      sleepDataHistory: {
        startDate: sevenDaysAgo,
        endDate: formatDateToDay,
        data: [],
      },
    },
    reAssessmentOptions: {
      painRiskFallOptions: [
        { key: "1", value: "ความเสี่ยงต่ำ", text: "ความเสี่ยงต่ำ" },
        { key: "2", value: "ความเสี่ยงสูง", text: "ความเสี่ยงสูง" },
      ],
      painNursingCareOptions: fallRiskNursingOptions,
      sensesNursingCareOptions: nursingCareOptions,
      painLevel: [
        { key: "0", value: "0", text: "0" },
        { key: "1", value: "1", text: "1" },
        { key: "2", value: "2", text: "2" },
        { key: "3", value: "3", text: "3" },
        { key: "4", value: "4", text: "4" },
        { key: "5", value: "5", text: "5" },
        { key: "6", value: "6", text: "6" },
        { key: "7", value: "7", text: "7" },
        { key: "8", value: "8", text: "8" },
        { key: "9", value: "9", text: "9" },
        { key: "10", value: "10", text: "10" },
      ],
    },
  });

  AddOrEdit(controller, { ...params, action: "getDataHistory" });
};

export const AddOrEdit: Handler = async (controller, params) => {
  const state = controller.getState();
  const dataReAssessment = state.ReAssessmentSequence;

  if (["savePain", "saveRiskFall", "saveSleep"].includes(params?.action)) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    let formCode = "";
    let formName = "";
    let reAssessmenID = "";
    let newData = {};
    let clearData = {};
    const toDay = moment();
    const formatDateToDay = formatDate(toDay);
    const timeToDay = toDay.format("HH:mm");

    if (params.action === "savePain") {
      formCode = "CardReAssessmentPainIPD";
      formName = "ประเมินความเจ็บปวดผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentPainID;

      newData = {
        painStatus: dataReAssessment?.painStatus,
        assessPainLevel: dataReAssessment?.assessPainLevel,
        painLevel: dataReAssessment?.painLevel,
        painArea: dataReAssessment?.painArea,
        painNature: dataReAssessment?.painNature,
        sensesNursingCare: dataReAssessment?.sensesNursingCare,
        saveData: dataReAssessment?.painData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.painData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        painData: {
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        painStatus: "",
        assessPainLevel: "",
        painLevel: "",
        painArea: "",
        painNature: "",
        sensesNursingCare: "",
      };
    } else if (params.action === "saveRiskFall") {
      formCode = "CardReAssessmentRiskFallIPD";
      formName = "ประเมินความเสี่ยงต่อการพลัดตกหกล้มผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentRiskFallIPD;

      newData = {
        riskFall: dataReAssessment?.riskFall,
        riskFallRemark: dataReAssessment?.riskFallRemark,
        nursingCare: dataReAssessment?.nursingCare,
        saveData: dataReAssessment?.riskFallData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.riskFallData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        riskFallData: {
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        riskFall: "",
        riskFallRemark: "",
        nursingCare: "",
      };
    } else if (params.action === "saveSleep") {
      formCode = "CardReAssessmentSleepIPD";
      formName = "ประเมินการพักผ่อนนอนหลับผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentSleepIPD;

      newData = {
        sleepingPerDay: dataReAssessment?.sleepingPerDay,
        sleepingEnough: dataReAssessment?.sleepingEnough,
        sleepingProblem: dataReAssessment?.sleepingProblem,
        sleepingProblemRemark: dataReAssessment?.sleepingProblemRemark,
        sleepPill: dataReAssessment?.sleepPill,
        sleepPillRemark: dataReAssessment?.sleepPillRemark,
        saveData: dataReAssessment?.sleepData?.saveData || formatDateToDay,
        saveTime: dataReAssessment?.sleepData?.saveTime || timeToDay,
        editUserName: state.django.user.full_name,
      };

      clearData = {
        ...dataReAssessment,
        sleepData: {
          saveData: formatDateToDay,
          saveTime: timeToDay,
        },
        sleepingPerDay: "",
        sleepingEnough: "",
        sleepingProblem: "",
        sleepingProblemRemark: "",
        sleepPill: "",
        sleepPillRemark: "",
      };
    }

    const [respFetch, errFetch, netwFetch] = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        form_code: formCode,
        form_version: 1.0,
      },
      extra: { division: controller.data.division },
    });

    let item = [];
    if (respFetch?.data?.item) {
      item = [...respFetch?.data?.item, newData];
    } else {
      item = [newData];
    }

    let data: any = {
      form_code: formCode,
      form_name: formName,
      form_version: 1.0,
      encounter: state.selectedEncounter.id,
      action: "SAVE",
      data: { item: item },
    };

    if (reAssessmenID) {
      const [respAction, errAction, netwAction] = await FormDataDetail.update({
        pk: reAssessmenID,
        data: data,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      if (errAction) {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "SUCCESS",
          },
          ReAssessmentSequence: clearData,
        });
      }
    } else {
      const [respAction, errAction, netwAction] = await FormDataList.create({
        data: data,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      if (errAction) {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "SUCCESS",
          },
          ReAssessmentSequence: clearData,
        });

        AddOrEdit(controller, { ...params, action: "getDataHistory" });
      }
    }
  } else if (params?.action === "getDataHistory") {
    const [fetchPain, fetchRiskFall, fetchSleep] = await Promise.allSettled([
      FormDataDetailByEncounter.retrieve({
        pk: state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentPainIPD" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentRiskFallIPD" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: state?.selectedEncounter.pk,
        params: { form_data_code: "CardReAssessmentSleepIPD" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
    ]);

    // Promise.allSettled return {status, value}
    let painHistory = fetchPain.value;
    let riskFallHistory = fetchRiskFall.value;
    let sleepHistory = fetchSleep.value;

    controller.setState({
      ReAssessmentSequence: {
        ...state.ReAssessmentSequence,
        painDataHistory: {
          ...state.ReAssessmentSequence?.painDataHistory,
          data: painHistory?.[0]?.data?.item || [],
        },
        riskFallDataHistory: {
          ...state.ReAssessmentSequence?.riskFallDataHistory,
          data: riskFallHistory?.[0]?.data?.item || [],
        },
        sleepDataHistory: {
          ...state.ReAssessmentSequence?.sleepDataHistory,
          data: sleepHistory?.[0]?.data?.item || [],
        },
      },
    });
  }
  if (params?.action === "deleteDataHistory") {
    let formCodeType = "";
    let formNameType = "";
    let reAssessmenID = "";

    if (params?.formCode === "PAIN") {
      formCodeType = "CardReAssessmentPainIPD";
      formNameType = "ประเมินความเจ็บปวดผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentPainID;
    } else if (params?.formCode === "RISK_FALL") {
      formCodeType = "CardReAssessmentRiskFallIPD";
      formNameType = "ประเมินความเสี่ยงต่อการพลัดตกหกล้มผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentRiskFallIPD;
    } else if (params?.formCode === "SLEEP") {
      formCodeType = "CardReAssessmentSleepIPD";
      formNameType = "ประเมินการพักผ่อนนอนหลับผู้ป่วยหลังออกจากห้องตรวจ (IPD)";
      reAssessmenID = dataReAssessment?.reAssessmentSleepIPD;
    }

    const [resp, err, netw] = await FormDataDetail.update({
      pk: reAssessmenID,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
      data: {
        form_code: formCodeType,
        form_name: formNameType,
        form_version: 1.0,
        encounter: state.selectedEncounter.id,
        action: "SAVE",
        data: params.data, // {item, remark}
      },
    });
    if (err) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.card]: err,
        },
      });
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: "Success." },
      });
      AddOrEdit(controller, { ...params, action: "getDataHistory" });
    }
  }

  if (!state.ReAssessmentSequence || !state.selectedEncounter) {
    return;
  }
};
