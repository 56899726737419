import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Icon,
  Checkbox,
  Input,
  Radio,
  FormGroup,
  FormField
} from 'semantic-ui-react'

const CardDischargePlanningUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              className="ui label teal ribbon">
              DISCHARGE PLANNING INFORMATION
            </div>
          </div>
          <div
            style={{display: "flex"}}>
            
            <Label
              color="teal"
              style={{display: props.showStatus ? "" : "none"}}>
              Draft
            </Label>
            <div
              style={{cursor: "pointer", margin: "-5px -5px 0 15px", display: props.closeIcon ? "" : "none"}}>
              
              <Icon
                className="red large"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          className="ui form"
          style={{paddingTop:"10px"}}>
          
          <FormGroup>
            <FormField
              inline={true}
              width={16}>
              <Label>
                 การประเมินปัญหาและความต้องการของผู้ป่วยที่มีผลกระทบต่อสุขภาพ
              </Label>
            </FormField>
          </FormGroup>
          <div>
            {props.subDischargePlan}
          </div>
          <FormGroup>
            <FormField
              inline={true}
              width={16}>
              <div
                style={{ fontWeight: "bold"}}>
                { `3.  ${props.ClinicalTermPlanning?.disc_plan4?.name}`}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ gridRow: "32/33", gridColumn: "1/8",display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan4?.items[0]?.checked}
                  name="disc_plan4.items.0.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={{marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan4?.items[0]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ gridRow: "32/33", gridColumn: "9/16",display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan4?.items[1]?.checked}
                  name="disc_plan4.items.1.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={{marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan4?.items[1]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ gridRow: "32/33", gridColumn: "17/24",display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan4?.items[2]?.checked}
                  name="disc_plan4.items.2.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={{marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan4?.items[2]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan4?.items[3]?.checked}
                  name="disc_plan4.items.3.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={{marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan4?.items[3]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display:"flex",gap:"20px"}}>
                
                <div>
                  ระบุ
                </div>
                <Input
                  name="disc_plan4.items.3.answer"
                  onChange={props.handleChangeDataPlanning}
                  type="text"
                  value={props.ClinicalTermPlanning?.disc_plan4?.items[3]?.answer}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField>
              <div
                style={{ display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan4?.items[4]?.checked}
                  name="disc_plan4.items.4.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={{marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan4?.items[4]?.name}
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={16}>
              <div
                style={{fontWeight: "bold"}}>
                { `4.  ${props.ClinicalTermPlanning?.disc_plan5?.name}`}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ gridRow: "35/36", gridColumn: "1/8",display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan5?.items[0]?.checked}
                  name="disc_plan5.items.0.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={{marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan5?.items[0]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan5?.items[1]?.checked}
                  name="disc_plan5.items.1.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={{marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan5?.items[1]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display:"flex",gap:"20px"}}>
                
                <div>
                  ระบุ
                </div>
                <Input
                  name="disc_plan5.items.1.answer"
                  onChange={props.handleChangeDataPlanning}
                  style={{width:"100%"}}
                  value={props.ClinicalTermPlanning?.disc_plan5?.items[1]?.answer}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField>
              <div
                style={{ display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan5.items[2]?.checked}
                  name="disc_plan5.items.2.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={{marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan5?.items[2]?.name}
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{ fontWeight: "bold"}}>
                { `5.  ${props.ClinicalTermPlanning?.disc_plan6?.name}`}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={11}>
              <div>
                
                <Radio
                  checked={props.ClinicalTermPlanning?.disc_plan6?.has_problem === "N"}
                  label="ไม่จำเป็น"
                  name="disc_plan6.has_problem"
                  onChange={props.handleChangeDataPlanning}
                  style={{ marginRight: "20px" }}
                  value="N">
                </Radio>
                <Radio
                  checked={props.ClinicalTermPlanning?.disc_plan6?.has_problem === "Y"}
                  label="จำเป็น"
                  name="disc_plan6.has_problem"
                  onChange={props.handleChangeDataPlanning}
                  style={{ marginRight: "20px" }}
                  value="Y">
                </Radio>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ gridRow: "38/39", gridColumn: "1/9",display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan6?.items[0]?.checked}
                  name="disc_plan6.items.0.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={ {marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan6?.items[0]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ gridRow: "38/39", gridColumn: "10/17",display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan6?.items[1]?.checked}
                  name="disc_plan6.items.1.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={ {marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan6?.items[1]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ gridRow: "38/39", gridColumn: "18/25",display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan6?.items[2]?.checked}
                  name="disc_plan6.items.2.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={ {marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan6?.items[2]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ gridRow: "38/39", gridColumn: "26/30",display:"flex"}}>
                
                <Checkbox
                  checked={props.ClinicalTermPlanning?.disc_plan6?.items[3]?.checked}
                  name="disc_plan6.items.3.checked"
                  onChange={props.handleChangeDataPlanning}
                  style={{marginTop:"5px"}}>
                </Checkbox>
                <div
                  style={ {marginTop:"3px",marginLeft:"10px"}}>
                  {props.ClinicalTermPlanning?.disc_plan6?.items[3]?.name}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display:"flex",gap:"20px"}}>
                
                <div>
                  ระบุ 
                </div>
                <Input
                  name="disc_plan6.items.3.answer"
                  onChange={props.handleChangeDataPlanning}
                  value={props.ClinicalTermPlanning?.disc_plan6?.items[3]?.answer}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardDischargePlanningUX

export const screenPropsDefault = {"drugSearch":"[Drug Search Box]"}

/* Date Time : Thu Mar 28 2024 11:23:00 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "DISCHARGE PLANNING INFORMATION"
        },
        "className": {
          "type": "value",
          "value": "ui label teal ribbon"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Draft"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showStatus ? \"\" : \"none\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", margin: \"-5px -5px 0 15px\", display: props.closeIcon ? \"\" : \"none\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Icon",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Label",
      "parent": 317,
      "props": {
        "children": {
          "type": "value",
          "value": " การประเมินปัญหาและความต้องการของผู้ป่วยที่มีผลกระทบต่อสุขภาพ"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 260,
      "name": "div",
      "parent": 397,
      "props": {
        "children": {
          "type": "code",
          "value": " `3.  ${props.ClinicalTermPlanning?.disc_plan4?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\"}"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "div",
      "parent": 398,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/33\", gridColumn: \"1/8\",display:\"flex\"}"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": null,
      "id": 262,
      "name": "div",
      "parent": 399,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/33\", gridColumn: \"9/16\",display:\"flex\"}"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 400,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/33\", gridColumn: \"17/24\",display:\"flex\"}"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": null,
      "id": 264,
      "name": "div",
      "parent": 401,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": null,
      "id": 265,
      "name": "div",
      "parent": 403,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "div",
      "parent": 404,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display:\"flex\"}"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "Checkbox",
      "parent": 261,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[0]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan4.items.0.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "Checkbox",
      "parent": 262,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[1]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan4.items.1.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "Checkbox",
      "parent": 263,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[2]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan4.items.2.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "Checkbox",
      "parent": 264,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[3]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan4.items.3.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "Checkbox",
      "parent": 267,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[4]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan4.items.4.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 273,
      "name": "Input",
      "parent": 403,
      "props": {
        "name": {
          "type": "value",
          "value": "disc_plan4.items.3.answer"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[3]?.answer"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "div",
      "parent": 261,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[0]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "div",
      "parent": 262,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[1]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "div",
      "parent": 263,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[2]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "div",
      "parent": 264,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[3]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "div",
      "parent": 267,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[4]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": null,
      "id": 279,
      "name": "div",
      "parent": 406,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"35/36\", gridColumn: \"1/8\",display:\"flex\"}"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 407,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 409,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": null,
      "id": 283,
      "name": "div",
      "parent": 413,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display:\"flex\"}"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 284,
      "name": "div",
      "parent": 279,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[0]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 285,
      "name": "div",
      "parent": 280,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[1]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "div",
      "parent": 283,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[2]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 287,
      "name": "Checkbox",
      "parent": 279,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[0]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan5.items.0.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "Checkbox",
      "parent": 280,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[1]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan5.items.1.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "Checkbox",
      "parent": 283,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5.items[2]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan5.items.2.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Input",
      "parent": 409,
      "props": {
        "name": {
          "type": "value",
          "value": "disc_plan5.items.1.answer"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[1]?.answer"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "div",
      "parent": 405,
      "props": {
        "children": {
          "type": "code",
          "value": " `4.  ${props.ClinicalTermPlanning?.disc_plan5?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "div",
      "parent": 414,
      "props": {
        "children": {
          "type": "code",
          "value": " `5.  ${props.ClinicalTermPlanning?.disc_plan6?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\"}"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": null,
      "id": 293,
      "name": "div",
      "parent": 415,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Radio",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.has_problem === \"N\""
        },
        "label": {
          "type": "value",
          "value": "ไม่จำเป็น"
        },
        "name": {
          "type": "value",
          "value": "disc_plan6.has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "N"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "Radio",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.has_problem === \"Y\""
        },
        "label": {
          "type": "value",
          "value": "จำเป็น"
        },
        "name": {
          "type": "value",
          "value": "disc_plan6.has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "Y"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": null,
      "id": 296,
      "name": "div",
      "parent": 416,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"1/9\",display:\"flex\"}"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": null,
      "id": 297,
      "name": "div",
      "parent": 417,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"10/17\",display:\"flex\"}"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "div",
      "parent": 418,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"18/25\",display:\"flex\"}"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "div",
      "parent": 419,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"26/30\",display:\"flex\"}"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": null,
      "id": 300,
      "name": "div",
      "parent": 421,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ "
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "Checkbox",
      "parent": 296,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[0]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan6.items.0.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 303,
      "name": "Checkbox",
      "parent": 297,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[1]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan6.items.1.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "Checkbox",
      "parent": 298,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[2]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan6.items.2.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "Checkbox",
      "parent": 299,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[3]?.checked"
        },
        "name": {
          "type": "value",
          "value": "disc_plan6.items.3.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": null,
      "id": 306,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[0]?.name"
        },
        "style": {
          "type": "code",
          "value": " {marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": null,
      "id": 307,
      "name": "div",
      "parent": 297,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[1]?.name"
        },
        "style": {
          "type": "code",
          "value": " {marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": null,
      "id": 308,
      "name": "div",
      "parent": 298,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[2]?.name"
        },
        "style": {
          "type": "code",
          "value": " {marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": null,
      "id": 309,
      "name": "div",
      "parent": 299,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[3]?.name"
        },
        "style": {
          "type": "code",
          "value": " {marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "Input",
      "parent": 421,
      "props": {
        "name": {
          "type": "value",
          "value": "disc_plan6.items.3.answer"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDataPlanning"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[3]?.answer"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": null,
      "id": 311,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"10px\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 312,
      "name": "FormGroup",
      "parent": 311,
      "props": {
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 317,
      "name": "FormField",
      "parent": 312,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 356,
      "name": "FormGroup",
      "parent": 311,
      "props": {
      },
      "seq": 357,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 357,
      "name": "FormGroup",
      "parent": 311,
      "props": {
      },
      "seq": 358,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 358,
      "name": "FormGroup",
      "parent": 311,
      "props": {
      },
      "seq": 359,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 359,
      "name": "FormGroup",
      "parent": 311,
      "props": {
      },
      "seq": 360,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 360,
      "name": "FormGroup",
      "parent": 311,
      "props": {
      },
      "seq": 410,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 397,
      "name": "FormField",
      "parent": 356,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 397,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 398,
      "name": "FormField",
      "parent": 357,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 398,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 399,
      "name": "FormField",
      "parent": 357,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 399,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 400,
      "name": "FormField",
      "parent": 357,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 400,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 401,
      "name": "FormField",
      "parent": 357,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 401,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 402,
      "name": "FormField",
      "parent": 357,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 402,
      "void": false
    },
    {
      "from": null,
      "id": 403,
      "name": "div",
      "parent": 402,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"20px\"}"
        }
      },
      "seq": 403,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 404,
      "name": "FormField",
      "parent": 358,
      "props": {
      },
      "seq": 404,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 405,
      "name": "FormField",
      "parent": 359,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 405,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 406,
      "name": "FormField",
      "parent": 360,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 406,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 407,
      "name": "FormField",
      "parent": 360,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 407,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 408,
      "name": "FormField",
      "parent": 360,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 408,
      "void": false
    },
    {
      "from": null,
      "id": 409,
      "name": "div",
      "parent": 408,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"20px\"}"
        }
      },
      "seq": 409,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 410,
      "name": "FormGroup",
      "parent": 311,
      "props": {
      },
      "seq": 411,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 411,
      "name": "FormGroup",
      "parent": 311,
      "props": {
      },
      "seq": 412,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 412,
      "name": "FormGroup",
      "parent": 311,
      "props": {
      },
      "seq": 422,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 413,
      "name": "FormField",
      "parent": 410,
      "props": {
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 413,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 414,
      "name": "FormField",
      "parent": 411,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 414,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 415,
      "name": "FormField",
      "parent": 411,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 415,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 416,
      "name": "FormField",
      "parent": 412,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 416,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 417,
      "name": "FormField",
      "parent": 412,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 417,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 418,
      "name": "FormField",
      "parent": 412,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 418,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 419,
      "name": "FormField",
      "parent": 412,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 419,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 420,
      "name": "FormField",
      "parent": 412,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 420,
      "void": false
    },
    {
      "from": null,
      "id": 421,
      "name": "div",
      "parent": 420,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"20px\"}"
        }
      },
      "seq": 421,
      "void": false
    },
    {
      "from": null,
      "id": 422,
      "name": "div",
      "parent": 311,
      "props": {
        "children": {
          "type": "code",
          "value": "props.subDischargePlan"
        }
      },
      "seq": 356,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDischargePlanningUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "drugSearch": "[Drug Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
