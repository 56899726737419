import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Icon, Pagination } from "semantic-ui-react";

import { RowInfo } from "react-table-6";

// UX
import CardSettingTRTOptionsUX from "./CardSettingTRTOptionsUX";

// Interface
import {
  BTN_ACTS,
  CARD_SETTING_TRT_OPTIONS,
  RunSequence,
  SetProp,
  State,
  PickedProps,
  LIST_LIMIT,
  MasterOptionsType,
  FilterType,
  DentalTreatmentPackageSerializer,
  ACTIONS,
  REQUIRED_FIELDS,
} from "./sequence/SettingTRTOptions";
import { getErrorMsgLabel } from "../common/CommonInterface";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

// Types
type CardSettingTRTOptionsProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  SettingTRTOptionsSequence: State["SettingTRTOptionsSequence"];
  // options
  masterOptions?: MasterOptionsType;
} & PickedProps;

const CardSettingTRTOptions = (props: CardSettingTRTOptionsProps) => {
  const [modDelete, setModDelete] = useState<DentalTreatmentPackageSerializer | null>(null);

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "SettingTRTOptions",
      restart: true,
    });
  }, []);

  // Memo Callback
  const trtOptionDetail = useMemo(() => {
    return props.SettingTRTOptionsSequence?.trtOptionDetail;
  }, [props.SettingTRTOptionsSequence?.trtOptionDetail]);

  // Callback
  const findDivision = useCallback(
    (division: number) => {
      return (props.masterOptions?.division || []).find((option) => option.value === division)
        ?.text;
    },
    [props.masterOptions?.division]
  );

  const handleGetTrProps = useCallback(
    (_state: any, rowInfo: RowInfo) => {
      return {
        className:
          trtOptionDetail?.id && trtOptionDetail?.id === rowInfo?.original.id
            ? "blueSelectedRow"
            : "",
      };
    },
    [trtOptionDetail]
  );

  const handleEdit = useCallback(
    (data: DentalTreatmentPackageSerializer) => {
      props.setProp("SettingTRTOptionsSequence", {
        ...props.SettingTRTOptionsSequence,
        trtOptionDetail: { ...data },
        showRequiredField: null,
      });
    },
    [props.SettingTRTOptionsSequence]
  );

  const handleDelete = useCallback((data: DentalTreatmentPackageSerializer) => {
    setModDelete(data);
  }, []);

  // Memo
  const errorMsgLabel = useMemo(() => {
    const error = props.errorMessage?.[CARD_SETTING_TRT_OPTIONS];

    return getErrorMsgLabel(error, REQUIRED_FIELDS);
  }, [props.errorMessage]);

  const filterTrtOptions = useMemo(() => {
    return props.SettingTRTOptionsSequence?.filter || {};
  }, [props.SettingTRTOptionsSequence?.filter]);

  const treatmentItems = useMemo(() => {
    const items = props.SettingTRTOptionsSequence?.trtOptionsList?.items || [];

    return items.map((item, index) => {
      const divisionNames = item.divisions
        .map((division) => findDivision(division))
        .filter(Boolean);

      return {
        ...item,
        divisionName: divisionNames.join(", "),
        _action: (
          <RenderButtonAction
            data={item}
            index={index}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            // callback
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        ),
      };
    });
  }, [props.SettingTRTOptionsSequence?.trtOptionsList, findDivision, handleEdit, handleDelete]);

  // Handler
  const handlePageChange = (_e: any, data: any) => {
    handleSearch(data.activePage);
  };

  const handleSearch = (page: number) => {
    props.runSequence({
      sequence: "SettingTRTOptions",
      action: ACTIONS.SEARCH,
      activePage: typeof page === "number" ? page : 1,
    });
  };

  const handleChangeFilter = (e: any, data: any) => {
    const name = data.name as keyof FilterType;

    const filter = { ...filterTrtOptions };

    filter[name] = data.value;

    props.setProp(`SettingTRTOptionsSequence.filter`, { ...filter });
  };

  const handleChangeValue = (
    e: any,
    data: { value: any; name: keyof DentalTreatmentPackageSerializer }
  ) => {
    const name = data.name;

    const detail = { ...trtOptionDetail };

    detail[name] = data.value;

    props.setProp(`SettingTRTOptionsSequence.trtOptionDetail`, { ...detail });
  };

  const handleClose = () => {
    props.setProp("SettingTRTOptionsSequence.trtOptionDetail", null);
  };

  const handleAddNew = () => {
    props.setProp("SettingTRTOptionsSequence", {
      ...props.SettingTRTOptionsSequence,
      trtOptionDetail: {},
      showRequiredField: null,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "SettingTRTOptions",
      action: ACTIONS.SAVE,
      card: CARD_SETTING_TRT_OPTIONS,
    });
  };

  const handleCloseModDelete = () => {
    setModDelete(null);
  };

  const handleConfirmDelete = () => {
    if (modDelete?.id) {
      props.runSequence({
        sequence: "SettingTRTOptions",
        action: ACTIONS.DELETE,
        card: CARD_SETTING_TRT_OPTIONS,
        trtOptionId: modDelete?.id,
        onSuccess: handleCloseModDelete,
      });
    }
  };

  console.log("CardSettingTRTOptions", props);

  return (
    <div style={{ height: "calc(-3rem + 100vh)", overflow: "auto" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_SETTING_TRT_OPTIONS}`, null);
        }}
        error={errorMsgLabel}
        success={null}
      />

      <CardSettingTRTOptionsUX
        // data
        trtOptionsList={treatmentItems}
        filter={filterTrtOptions}
        showDetail={!!trtOptionDetail}
        trtOptionDetail={trtOptionDetail}
        showRequiredField={props.SettingTRTOptionsSequence?.showRequiredField}
        // options
        divisionOptions={props.masterOptions?.division}
        // table
        onGetTrProps={handleGetTrProps}
        // callback
        onChangeFilter={handleChangeFilter}
        onChangeValue={handleChangeValue}
        onClickCancel={handleClose}
        onClickClose={handleClose}
        onAddNew={handleAddNew}
        // Element
        FDivisionDropdownOptions={
          <DropdownOptions
            value={filterTrtOptions.divisions || []}
            name="divisions"
            multiple={true}
            search={true}
            checked={true}
            inline={true}
            options={props.masterOptions?.division}
            onChange={handleChangeFilter}
            fluid={true}
            style={{ width: "100%" }}
          />
        }
        DDivisionDropdownOptions={
          <DropdownOptions
            value={trtOptionDetail?.divisions || []}
            name="divisions"
            placeholder="กรณีไม่ระบุแผนก หมายถึง แสดงรายการให้ทุกแผนกเห็น"
            multiple={true}
            search={true}
            filter={true}
            options={props.masterOptions?.division}
            onChange={handleChangeValue}
            fluid={true}
            style={{ width: "100%" }}
          />
        }
        Pagination={
          <Pagination
            activePage={props.SettingTRTOptionsSequence?.trtOptionsList?.activePage || 0}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={Math.ceil(
              (props.SettingTRTOptionsSequence?.trtOptionsList?.total || 0) / LIST_LIMIT
            )}
            size="mini"
            // callback
            onPageChange={handlePageChange}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // // data
            paramKey={BTN_ACTS.SEARCH}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEARCH]}
            // config
            color={"blue"}
            name={ACTIONS.SEARCH}
            size="small"
            title="ค้นหา"
          />
        }
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // // data
            paramKey={BTN_ACTS.SAVE}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SAVE]}
            // config
            color={trtOptionDetail?.id ? "yellow" : "green"}
            name={ACTIONS.SAVE}
            size="small"
            title={trtOptionDetail?.id ? "แก้ไข" : "บันทึก"}
          />
        }
      />

      <ModConfirm
        openModal={!!modDelete}
        titleName="แจ้งเตือน"
        titleColor="red"
        size="mini"
        denyButtonText="ยกเลิก"
        // callback
        onDeny={handleCloseModDelete}
        onCloseWithDimmerClick={handleCloseModDelete}
        // ELement
        content={
          <div
            style={{
              margin: "0rem 1.5rem -1rem 0rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            ต้องการลบรายการใช่หรือไม่
          </div>
        }
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmDelete}
            // data
            paramKey={BTN_ACTS.DELETE}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DELETE]}
            // config
            color={"green"}
            name={ACTIONS.DELETE}
            size="medium"
            title={"ตกลง"}
            basic={true}
          />
        }
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                   RenderButtonAction;                  */

/* ------------------------------------------------------ */
const RenderButtonAction = (props: any) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button
        size="mini"
        icon="edit"
        color="yellow"
        // loading={!!props.buttonLoadCheck?.[`${ACTION_SELECT}_${props.index}`]}
        style={{ padding: "0.5rem", marginRight: "2rem" }}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();

          props.onEdit?.(props.data, props.index);
        }}
      />
      <Button
        size="mini"
        icon="trash alternate"
        color="red"
        style={{ padding: "0.5rem" }}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();

          props.onDelete?.(props.data, props.index);
        }}
      />
    </div>
  );
};

CardSettingTRTOptions.displayName = "CardSettingTRTOptions";

export default React.memo(CardSettingTRTOptions);
