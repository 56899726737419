import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CardLayout from "../common/CardLayout";
import { Modal, Button, Form, Input } from "semantic-ui-react";
import PureReactTable from "../common/PureReactTable";
import * as utils from "react-lib/utils";

const SummarySentClaim = React.memo(({ sentClaim, onLoadSummary = id => {}, onClose = () => {} }) => {
	const [summaryLoading, setSummaryLoading] = useState(true);
	const [sentSummary, setSentSummary] = useState(null);

	const handleSentSummary = async sentClaimId => {
		if (onLoadSummary) {
			const data = await onLoadSummary(sentClaimId);
			console.log(data);
			setSentSummary(data);
			setSummaryLoading(false);
		}
	};

	useEffect(() => {
		if (sentClaim) {
			handleSentSummary(sentClaim.id);
		}
	}, [sentClaim]);

	return (
		<CardLayout
			titleText={`งวดส่ง ${sentClaim ? sentClaim.sent_no : ""}`}
			headerColor={"purple"}
			toggleable={false}
			closeable={true}
			onClose={onClose}
		>
			<Form>
				<Form.Group inline style={{ flexDirection: "row", justifyContent: "center" }}>
					<Form.Field>
						<label>จำนวนข้อมูลที่ส่งเบิกได้</label>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "15%" }}
						loading={summaryLoading}
						value={sentSummary ? sentSummary.total_rows : ""}
					/>
					<Form.Field style={STYLE.FIELD_MARGIN}>
						<label>ยอดเบิก</label>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "15%" }}
						loading={summaryLoading}
						value={sentSummary ? sentSummary.total_sent_claim_price : ""}
					/>

					<Form.Field style={STYLE.FIELD_MARGIN}>
						<label>ยอดผู้ป่วยจ่าย</label>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "15%" }}
						loading={summaryLoading}
						value={sentSummary ? sentSummary.total_paid_price : ""}
					/>

					<Form.Field style={STYLE.FIELD_MARGIN}>
						<label>ยอดร่วมจ่าย</label>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "15%" }}
						loading={summaryLoading}
						value={sentSummary ? sentSummary.total_other_pay_price : ""}
					/>
				</Form.Group>
			</Form>
		</CardLayout>
	);
});

const CardDownloadPreviousSentClaimFile = props => {
	const isMounted = useRef(true);
	const [loading, setLoading] = useState(false);
	const [openSummary, setOpenSummary] = useState(false);
	const [sentClaimSelected, setSentClaimSelected] = useState(null);
	// Table
	const [data, setData] = useState([]);

	/** Handle Modal */
	const showSummary = () => {
		setOpenSummary(true);
	};

	const hideSummary = () => {
		setOpenSummary(false);
	};
	/*************************** */
	/** Handle "Download" button */
	const downloadSentClaimFile = async url => {
		if (url === "") {
			return;
		}
		const isSuccess = await props.controller.generateSentClaimTransactionFile(url);
	};

	/** Handle "ดูยอดรวม" button */
	const handleSummaryModal = item => {
		setSentClaimSelected(item);
		showSummary();
	};

	/** Initial data */
	const loadSentClaimTransaction = async arTransactionId => {
		setLoading(true);
		var items = [];
		const [data, error] = await props.controller.loadSentClaimTransaction(arTransactionId);
		if (!isMounted.current) {
			return;
		}
		if (data) {
			items = data.items;
		}
		setData(items);
		setLoading(false);
	};

	/** Load summary with sent claim id */
	const loadSummary = async sentClaimId => {
		var result = null;
		const [data, error] = await props.controller.loadSentClaimSummary(props.arTransactionId, sentClaimId);
		if (!isMounted.current) {
			return;
		}
		if (data) {
			result = data.items;
		}
		return result;
	};

	useEffect(() => {
		if (props.controller && props.arTransactionId) {
			loadSentClaimTransaction(props.arTransactionId);
		}
	}, [props.controller, props.arTransactionId]);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const columns = [
		{ Header: "งวดส่ง", accessor: "sent_no", width: 250 },
		{
			Header: "วันเวลาส่ง",
			width: 250,
			Cell: props => {
				let data = props.original;
				return <label>{utils.formatToBEDatetime({ date: data.sent_at_datetime })}</label>;
			},
		},
		{ Header: "ผู้ส่ง", accessor: "sent_by_name", width: 250 },
		{ Header: "Zip File Name", accessor: "zip_file_name" },
		{ Header: "สถานะการส่งเบิก", accessor: "status_name" },
		{
			Header: "",
			width: 150,
			Cell: props => {
				let data = props.original;
				return (
					<div style={{ textAlign: "center" }}>
						<Button
							style={{ display: "inline-block" }}
							compact
							size={"mini"}
							color="violet"
							onClick={handleSummaryModal.bind(this, data)}
						>
							ดูยอดรวม
						</Button>
					</div>
				);
			},
		},
		{
			Header: "",
			width: 150,
			Cell: props => {
				let data = props.original;
				return (
					<div style={{ textAlign: "center" }}>
						<Button
							compact
							size={"mini"}
							color="green"
							onClick={downloadSentClaimFile.bind(this, data.sent_claim_zip_file)}
						>
							Download
						</Button>
					</div>
				);
			},
		},
	];

	return (
		<CardLayout
			titleText={props.cardTitle}
			headerColor={"orange"}
			loading={loading}
			toggleable={false}
			closeable={true}
			onClose={props.onClose}
		>
			<Modal size={"fullscreen"} open={openSummary} onClose={hideSummary}>
				<SummarySentClaim sentClaim={sentClaimSelected} onLoadSummary={loadSummary} onClose={hideSummary} />
			</Modal>
			<PureReactTable manual={false} data={data} columns={columns} />
		</CardLayout>
	);
};

const STYLE = {
	FIELD_MARGIN: { marginLeft: "24px" },
};

CardDownloadPreviousSentClaimFile.defaultProps = {
	cardTitle: "Download Zip File ย้อนหลัง",
	controller: null,
	arTransactionId: null,
	onClose: () => {},
};

CardDownloadPreviousSentClaimFile.propTypes = {
	cardTitle: PropTypes.string,
	controller: PropTypes.object,
	arTransactionId: PropTypes.number,
	onClose: PropTypes.func,
};

export default React.memo(CardDownloadPreviousSentClaimFile);
