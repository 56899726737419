import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import CardLayout from "react-lib/apps/common/CardLayout";
import SubFormViewer from "react-lib/apps/PTM/SubFormViewer";
import { Label } from "semantic-ui-react";

const CardPainAssessment = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [painAssessmentData, setPainAssessmentData] = React.useState([]);
  const [subFormViewData, setSubFormViewerData] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    refresh: ({ encounterId } = {}) => {
      refresh({ encounterId });
    },
  }));

  React.useEffect(() => {
    refresh({ encounterId: props.encounterId });
  }, [props.encounterId]);

  React.useEffect(() => {
    formatFormViewData();
  }, [painAssessmentData]);

  const refresh = ({ encounterId } = {}) => {
    getFormDataLatest({ encounterId });
  };

  const painAssessmentOptions = (props.painOptions || []).map((item: any) => ({
    ...item,
    key: item?.key.toString(),
    value: item?.value.toString(),
  }));

  const formatFormViewData = () => {
    let newArr = [...painAssessmentData];
    newArr.forEach((item) => {
      item.pain_assessment_tool = painAssessmentOptions.find(
        (row: any) => row.value.toString() === item?.pain_assessment_tool
      )?.text;
    });
    setSubFormViewerData(newArr);
  };

  const getFormDataLatest = async ({ encounterId }) => {
    setIsLoading(true);
    const [data, error] = await props.controller.getFormDataLatest({
      encounterId: encounterId
        ? encounterId
        : props.patientData.ENCOUNTER.encounter_id,
      formCode: "CardPainAssessment",
      formVersion: "0.1",
    });
    if (data) {
      let newData = data.data.map((item: any) => {
        let newNursing = "";

        item?.nursing.map((nursingData: any) => {
          if (newNursing === "") {
            newNursing = `${NURSING[nursingData]}`;
          } else {
            newNursing = `${newNursing}, ${NURSING[nursingData]}`;
          }
        });

        return {
          ...item,
          nursing: newNursing
        }
      });

      setStatus(data.status);
      setPainAssessmentData(newData);
    }
    setIsLoading(false);
  };

  let statusColor = "black";
  if (status === CONSTANT.FORM_DATA_STATUS.DRAFT) {
    statusColor = "yellow";
  } else if (status === CONSTANT.FORM_DATA_STATUS.EDITED) {
    statusColor = "olive";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CONFIRMED) {
    statusColor = "green";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CANCELLED) {
    statusColor = "red";
  }

  let labelStatus = <Label color={statusColor}>{status}</Label>;

  return (
    <CardLayout
      headerColor="grey"
      loading={isLoading}
      titleText="Pain Assessment"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      {props.showSummary ? (
        <SubFormViewer
          fieldList={rmdFieldList}
          fieldMap={rmdFieldMap}
          data={subFormViewData}
        />
      ) : (
        "TODO ---> Implement ShowSummary = false"
      )}
    </CardLayout>
  );
});

const NURSING = {
  NA: "NA",
  NON: "ไม่จำเป็น",
  REL: "จัดท่าเพื่อลดความเจ็บปวด",
  HCP: "วาง Hot/Cold pack",
  REP: "รายงานแพทย์ pain score > 3",
  YRX: "มี Rx",
  NRX: "ไม่มี Rx",
  Other: "อื่น ๆ",
};

const rmdFieldList = [
  "user_fullname",
  "date",
  "pain_score",
  "pain_assessment_tool",
  "location",
  "nursing",
];

const rmdFieldMap = {
  user_fullname: "ผู้บันทึก/แก้ไข",
  pain_score: "Pain Score",
  pain_assessment_tool: "Pain Assessment Tool",
  date: "Date Time",
  location: "Pain Location",
  nursing: "การให้การพยาบาลผู้ป่วย",
};

CardPainAssessment.defaultProps = {
  onClose: () => {},
  controller: {},
  encounterId: null,
  showSummary: false,
};

CardPainAssessment.propTypes = {
  onClose: PropTypes.func,
  controller: PropTypes.object,
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSummary: PropTypes.bool,
};

export default React.memo(CardPainAssessment);
