import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button
} from 'semantic-ui-react'

const ModCheckADRUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{ padding: "10px", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", color: "white", backgroundColor: "red", gridRow: "1/4", gridColumn: "1/31", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
          {`พบประวัติการแพ้ยา [${props.adrType}]`}
        </div>
        <div
          style={{ padding: "10px" }}>
          {props.adrDetail}
        </div>
        <div
          className="ui form"
          style={{ display: props.needNote ?  "block":"none" }}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(12, 1fr)","gridTemplateColumns":"repeat(30, 1fr)"}}>
            <div
              style={{ width: "100%", textAlign: "center", display: !props.needNote ?  "none":"block" , gridRow: "2/4", gridColumn: "2/30" }}>
              กรุณาระบุหมายเหตุการใช้ยา
            </div>
            <div
              className={props.noteError? "field error":""}
              style={{ gridRow: "5/8", gridColumn: "2/30", display: !props.needNote ?  "none":"block"   }}>
              <Input
                fluid={true}
                onChange={props.changeNote}
                size="mini"
                value={props.confirmADRNote}>
              </Input>
            </div>
            <div
              style={{ gridRow: "9/12", gridColumn: "6/12", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <Button
                color="red"
                onClick={props.confirmOrder}
                size="tiny"
                style={{ display: !props.needNote ?  "none":"block", width: "100%", height: "100%" }}>
                ยืนยันสั่ง
              </Button>
            </div>
            <div
              style={{ gridRow: "9/12", gridColumn: "20/26", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <Button
                onClick={props.cancelOrder}
                size="tiny"
                style={{ display: !props.needNote ?  "none":"block", width: "100%", height: "100%" }}>
                ไม่สั่ง
              </Button>
            </div>
            <div
              style={{ gridRow: "9/12", gridColumn: "13/19",  }}>
            </div>
          </div>
        </div>
        <div
          style={{ padding: "10px", display: props.needNote ?  "none":"block", textAlign: "center" }}>
          
          <Button
            color="red"
            onClick={props.acknowledge}
            style={{ width: "100%", height: "100%" }}>
            ตกลง
          </Button>
        </div>
      </div>
    )
}


export default ModCheckADRUX

export const screenPropsDefault = {"adrDetail":"รายละเอียด","adrType":"LOCKED","needNote":true}

/* Date Time : Tue Jan 16 2024 16:26:08 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", width: \"100%\", display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 30,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(12, 1fr)\",\"gridTemplateColumns\":\"repeat(30, 1fr)\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาระบุหมายเหตุการใช้ยา"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", textAlign: \"center\", display: !props.needNote ?  \"none\":\"block\" , gridRow: \"2/4\", gridColumn: \"2/30\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "className": {
          "type": "code",
          "value": "props.noteError? \"field error\":\"\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/8\", gridColumn: \"2/30\", display: !props.needNote ?  \"none\":\"block\"   }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/12\", gridColumn: \"6/12\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/12\", gridColumn: \"20/26\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 10,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNote"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.confirmADRNote"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Button",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันสั่ง"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.confirmOrder"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.needNote ?  \"none\":\"block\", width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่สั่ง"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "icon": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.cancelOrder"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.needNote ?  \"none\":\"block\", width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/12\", gridColumn: \"13/19\",  }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.adrDetail"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "`พบประวัติการแพ้ยา [${props.adrType}]`"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", fontWeight: \"bold\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", color: \"white\", backgroundColor: \"red\", gridRow: \"1/4\", gridColumn: \"1/31\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", display: props.needNote ?  \"none\":\"block\", textAlign: \"center\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Button",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "ตกลง"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.acknowledge"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.needNote ?  \"block\":\"none\" }"
        }
      },
      "seq": 26,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 35,
  "isMounted": true,
  "memo": false,
  "name": "ModCheckADRUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "adrDetail": "รายละเอียด",
    "adrType": "LOCKED",
    "needNote": true
  },
  "width": 25
}

*********************************************************************************** */
