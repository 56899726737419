import { TDocumentDefinitions } from "pdfmake/interfaces";

import { splitStringNewLine } from "react-lib/apps/HISV3/common/CommonInterface";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

const FormPriceChangeOverviewSummaryReportMonthly = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props Form Price Change Summary Monthly: ", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.priceChangeOverviewData?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานสรุปภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์ (ประจำเดือน)",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `ประเภท : ${props.priceChangeOverviewData?.params?.encounter_type_label}`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `คลินิก: ${props.priceChangeOverviewData?.params?.division_name} วันที่ ${props.priceChangeOverviewData?.params?.start_date} ถึง ${props.priceChangeOverviewData?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const localeStringOption = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const generateTableBody = (data: any, columns: any) => {
    let body = [];

    // Header of Table
    body.push([
      { text: "ปี", bold: true, alignment: "center" },
      { text: "เดือน", bold: true, alignment: "center" },
      { text: "ชื่อ-สกุลแพทย์", bold: true, alignment: "center" },
      { text: "รหัส", bold: true, alignment: "center" },
      { text: "รายการ", bold: true, alignment: "center" },
      { text: "จำนวน (qty)", bold: true, alignment: "center" },
      { text: "ยอดรวมราคาขาย", bold: true, alignment: "center" },
      { text: "ยอดรวมราคา (ที่แพทย์แก้ไข)", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            const itemNameList = row["item_name"].map((text: string) => {
              return splitStringNewLine(text, { fontSize: 12, width: 507 });
            });
            const itemCodeList = itemNameList.map((texts: string[], index: number) => {
              return [row["item_code"][index], ...Array(texts.length - 1).fill("\u00A0")];
            });
            const itemQtyList = itemNameList.map((texts: string[], index: number) => {
              return [row["item_quantity"][index], ...Array(texts.length - 1).fill("\u00A0")];
            });
            const originPriceList = itemNameList.map((texts: string[], index: number) => {
              return [
                row["original_price"].map((item: number) =>
                  item.toLocaleString("en-US", localeStringOption)
                )[index],
                ...Array(texts.length - 1).fill("\u00A0"),
              ];
            });
            const adjustedPriceList = itemNameList.map((texts: string[], index: number) => {
              return [
                row["adjusted_price"].map((item: number) =>
                  item.toLocaleString("en-US", localeStringOption)
                )[index],
                ...Array(texts.length - 1).fill("\u00A0"),
              ];
            });
            switch (column) {
              case "item_code":
                dataRow.push({
                  stack: itemCodeList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text };
                      }),
                    };
                  }),
                });
                break;
              case "item_name":
                dataRow.push({
                  stack: itemNameList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text, noWrap: true };
                      }),
                    };
                  }),
                });
                break;
              case "item_quantity":
                dataRow.push({
                  stack: itemQtyList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text, alignment: "right" };
                      }),
                    };
                  }),
                });
                break;
              case "original_price":
                dataRow.push({
                  stack: originPriceList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text, alignment: "right" };
                      }),
                    };
                  }),
                });
                break;
              case "adjusted_price":
                dataRow.push({
                  stack: adjustedPriceList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text, alignment: "right" };
                      }),
                    };
                  }),
                });
                break;
              default:
                dataRow.push({ text: row[column].toString() });
                break;
            }
          } else {
            dataRow.push({ text: "-" });
          }
        });
        body.push(dataRow);
      });
    }

    // Total Row
    body.push([
      { text: "รวมทั้งหมด", colSpan: 5, bold: true, alignment: "center" },
      {},
      {},
      {},
      {},
      {
        text: props.priceChangeOverviewData?.params?.total_quantity,
        bold: true,
        alignment: "right",
      },
      {
        text: props.priceChangeOverviewData?.params?.total_original_price.toLocaleString(
          "th-TH",
          localeStringOption
        ),
        bold: true,
        alignment: "right",
      },
      {
        text: props.priceChangeOverviewData?.params?.total_adjusted_price.toLocaleString(
          "th-TH",
          localeStringOption
        ),
        bold: true,
        alignment: "right",
      },
    ]);

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["auto", "auto", "14%", "5%", "*", "auto", "14%", "14%"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.priceChangeOverviewData?.fields || [], [
        "year",
        "month",
        "doctor_name",
        "item_code",
        "item_name",
        "item_quantity",
        "original_price",
        "adjusted_price",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
  };
};

export default FormPriceChangeOverviewSummaryReportMonthly;
